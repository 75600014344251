import React, {FC, useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';

import {CREATE_BOARD_OVERVIEW} from 'appRedux/actions/workflow';
import {
    CreateBoardOverviewRequestTypes,
    UpdateBoardOverviewRequestTypes,
    BoardHasStatusItemTypes,
} from 'appRedux/actions/workflow/types';

import {initialValuesForOverview} from 'components/Forms/BoardForm/validation';
import OverviewBoardForm from 'components/Forms/BoardForm/OverviewBoardForm';

interface CreateListBoardFormType {
    closeModal: () => void;
}

const CreateOverviewBoardForm: FC<CreateListBoardFormType> = ({closeModal}) => {
    const dispatch = useDispatch();

    const [statuses, setStatuses] = useState<BoardHasStatusItemTypes[]>([]);

    const createBoard = useCallback(data => dispatch({type: CREATE_BOARD_OVERVIEW.REQUEST, payload: data}), [dispatch]);

    const onSubmitClick = (values: CreateBoardOverviewRequestTypes | UpdateBoardOverviewRequestTypes, {setErrors}) => {
        createBoard({
            ...values,
            boardHasStatuses: statuses,
            setErrors,
            callback: closeModal,
        });
    };

    return (
        <OverviewBoardForm
            statuses={statuses}
            setStatuses={setStatuses}
            initialValues={initialValuesForOverview}
            onSubmitClick={onSubmitClick}
        />
    );
};

export default CreateOverviewBoardForm;
