import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RequesterCaseHasBudgetTypes} from 'appRedux/actions/requestCase/types';

import DescriptionPopover from 'components/DescriptionPopover/DescriptionPopover';

import {getBudgetAmountValue} from 'helpers/resourceFieldsHelper';

interface BudgetTitleType {
    currentBudget: RequesterCaseHasBudgetTypes;
}

const BudgetTitle: FC<BudgetTitleType> = ({currentBudget}) => {
    const [t] = useTranslation();

    const {title, description, type, availableAmount} = currentBudget;

    const availableAmountLabel = getBudgetAmountValue(type, availableAmount);

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    mb: 0.5,
                }}
            >
                <Typography sx={{fontWeight: 700, mr: 1}}>{`${t('orguser.resourceFields.title')}:`}</Typography>
                <Typography>{title}</Typography>
                {description && <DescriptionPopover text={description} />}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <Typography sx={{fontWeight: 700, mr: 1}}>{`${t(
                    'orguser.resourceFields.availableAmount',
                )}:`}</Typography>
                <Typography>{availableAmountLabel}</Typography>
            </Box>
        </Box>
    );
};

export default BudgetTitle;
