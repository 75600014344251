import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import DeleteAdminConfirmationDescription from 'components/AdminScreenComponents/DeleteAdminConfirmationDescription';

interface DeleteAdminConfirmationModalType {
    isShowModal: boolean;
    toggleModal: () => void;
    customTitle?: string;
    description?: string;
    descriptionWithAlert?: boolean;
    deletingRestrict?: boolean;
    onClickAction: () => void;
    customButton?: string;
}

const DeleteAdminConfirmationModal: FC<DeleteAdminConfirmationModalType> = ({
    isShowModal,
    toggleModal,
    description,
    descriptionWithAlert,
    customTitle,
    deletingRestrict,
    onClickAction,
    customButton,
}) => {
    const [t] = useTranslation();
    return (
        <ModalWrapper
            title={
                deletingRestrict
                    ? t('common.deletePopup.deletingRestrict')
                    : t(customTitle ?? 'messages.other.areYouSureRemove')
            }
            isShowModal={isShowModal}
            toggleModal={toggleModal}
        >
            {description && (
                <DeleteAdminConfirmationDescription
                    description={description}
                    descriptionWithAlert={descriptionWithAlert}
                />
            )}
            {deletingRestrict ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <AgentSaveButton isSubmit={false} title={t('common.buttons.close')} onClick={toggleModal} />
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <AgentInverseButton title={t('common.buttons.cancel')} onClick={toggleModal} />
                    <AgentSaveButton
                        onClick={onClickAction}
                        isSubmit={false}
                        title={t(customButton ?? 'common.buttons.delete')}
                    />
                </Box>
            )}
        </ModalWrapper>
    );
};

export default DeleteAdminConfirmationModal;
