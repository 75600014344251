import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import Typography from '@mui/material/Typography';

interface StatusButtonType {
    title: string;
    color: string;
    onClick: () => void;
}

const StatusButton: FC<StatusButtonType> = ({title, color, onClick}) => {
    const [t] = useTranslation();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                cursor: 'pointer',
                border: `2px solid ${color}`,
                borderRadius: 24,
                mr: 1,
                mt: 1,
                p: 0.5,
            }}
            title={t('orguser.requesterCase.changeStatus')}
            onClick={onClick}
        >
            <TripOriginIcon sx={{color: color}} />
            <Typography sx={{fontWeight: 600, ml: 1, mr: 1}}>{title}</Typography>
        </Box>
    );
};

export default StatusButton;
