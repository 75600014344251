import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const PlaySvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.5 14C3.36739 14 3.24021 13.9473 3.14645 13.8535C3.05268 13.7597 3 13.6326 3 13.5V2.49996C2.99999 2.41307 3.02263 2.32768 3.06568 2.2522C3.10873 2.17672 3.17071 2.11377 3.2455 2.06954C3.32029 2.02531 3.40532 2.00134 3.4922 1.99998C3.57908 1.99863 3.66481 2.01994 3.74095 2.06181L13.7409 7.56181C13.8194 7.60495 13.8848 7.66837 13.9304 7.74544C13.976 7.82251 14 7.9104 14 7.99994C14 8.08947 13.976 8.17736 13.9304 8.25443C13.8848 8.3315 13.8194 8.39492 13.7409 8.43806L3.74095 13.9381C3.66713 13.9787 3.58425 13.9999 3.5 14ZM4 3.34541V12.6544L12.4624 7.99996L4 3.34541Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default PlaySvgIcon;
