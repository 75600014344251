import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';
import {useParams, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Form, Formik} from 'formik';

import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';

import {RootReducer} from 'appRedux/reducers';
import {FormTagType} from 'appRedux/actions/forms/types';
import {RequesterCaseTagTypes} from 'appRedux/actions/requestCase/types';
import {REQUEST_CASE_CHANGE_TAG_MULTIPLE} from 'appRedux/actions/admin';

import {AlertContext} from 'contexts/alert/context';
import {AdminContext} from 'contexts/admin/context';
import {RouteContext} from 'contexts/route/context';

import TagChip from 'components/TagChip/TagChip';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {getParameterFromUrl, getSearchFromUrl} from 'components/AdminScreenComponents/translationsHelper';

import {getCasesStatus} from 'pages/agent/tableView/helper';

import {VIEW_MODE_BOARD} from 'helpers/filter';

import {
    PARAMETER_TAG,
    PARAMETER_TAB,
    DEFAULT_PAGE,
    PARAMETER_FORM,
    PARAMETER_STATUS,
    PARAMETER_SEARCH,
    PARAMETER_VIEW_MODE,
    PARAMETER_FIRST_LETTER,
    ITEMS_PER_PAGE_DESKTOP,
} from 'config/index';

interface RequesterTagFormType {
    closeModal: () => void;
}

const AddTagForm: FC<RequesterTagFormType> = ({closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const {uuid} = useParams();

    const {showAlert} = useContext(AlertContext);
    const {selectedIds} = useContext(AdminContext);
    const {isBoardListCasesPage} = useContext(RouteContext);

    const {
        admin: {isLoading, requesterCasesList, formFieldFilters},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const formTags = get(formFieldFilters, 'tags', []);
    const selectedCases = requesterCasesList?.filter(item => item.caseId && selectedIds.includes(item.caseId)) || [];

    const currentTab = searchParams.get(PARAMETER_TAB);
    const statusFromUrl = searchParams.get(PARAMETER_STATUS);
    const formFromUrl = searchParams.get(PARAMETER_FORM);
    const searchFromUrl = searchParams.get(PARAMETER_SEARCH);
    const tagsFromUrl = searchParams.get(PARAMETER_TAG);
    const modeFromUrl = searchParams.get(PARAMETER_VIEW_MODE) ?? VIEW_MODE_BOARD;
    const firstLetterFromUrl = searchParams.get(PARAMETER_FIRST_LETTER) ?? '';

    const initialValues = {
        ids: selectedIds,
        board: uuid,
        isAlphabetList: Number(isBoardListCasesPage),
        isPinnedList: false,
        viewMode: modeFromUrl,
        firstLetter: firstLetterFromUrl,
        page: DEFAULT_PAGE,
        perPage: ITEMS_PER_PAGE_DESKTOP,
        status: getCasesStatus(statusFromUrl, currentTab),
        form: getParameterFromUrl(formFromUrl),
        tags: getParameterFromUrl(tagsFromUrl),
        search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
        currentPageStatus: getCasesStatus(statusFromUrl, currentTab),
    };

    const updateTagsToRequesterCases = useCallback(
        data => dispatch({type: REQUEST_CASE_CHANGE_TAG_MULTIPLE.REQUEST, payload: data}),
        [dispatch],
    );

    const [addTags, setAddTags] = useState<number[]>([]);
    const [deleteTags, setDeleteTags] = useState<number[]>([]);

    const handleTagSelect = (tag?: FormTagType) => {
        setAddTags(prev => {
            if (!tag?.id) return prev;
            return [...prev, tag.id];
        });
        setDeleteTags(prev => {
            return prev.filter(id => id !== tag?.id);
        });
    };

    const handleTagDelete = (tag?: FormTagType) => {
        setDeleteTags(prev => {
            if (!tag?.id) return prev;
            return [...prev, tag.id];
        });
        setAddTags(prev => {
            return prev.filter(id => id !== tag?.id);
        });
    };

    const onSubmitClicked = () => {
        updateTagsToRequesterCases({
            ...initialValues,
            cases: selectedCases.map(currentCase => currentCase.caseId),
            addTags,
            deleteTags,
            callback: onClose,
            showAlert,
        });
    };

    const onClose = () => {
        closeModal();
        setAddTags([]);
        setDeleteTags([]);
    };

    return (
        <Formik initialValues={{}} onSubmit={onSubmitClicked}>
            <Form>
                <FormControl fullWidth>
                    <RadioGroup
                        row
                        sx={{
                            p: 1,
                        }}
                    >
                        {formTags &&
                            formTags.map((item: FormTagType, index: number) => {
                                if (!item.id) return null;

                                const isDeleted = deleteTags.some(id => id === item.id);
                                const isAdded = addTags.some(id => id === item.id);

                                const someSelected = selectedCases.some(currentCase => {
                                    const requesterTags: RequesterCaseTagTypes[] = get(currentCase, 'tags', []);
                                    return !!requesterTags.find(requesterItem => requesterItem.tag === item.title);
                                });

                                const allSelected = selectedCases.every(currentCase => {
                                    const requesterTags: RequesterCaseTagTypes[] = get(currentCase, 'tags', []);
                                    return !!requesterTags.find(requesterItem => requesterItem.tag === item.title);
                                });

                                const hasValue = (someSelected && !isDeleted) || isAdded;
                                const disabled = (allSelected || isAdded) && !isDeleted;

                                return (
                                    <FormControlLabel
                                        key={`color-${index}`}
                                        value={hasValue}
                                        disabled={disabled}
                                        control={<Radio sx={{mb: 1.5, display: 'none'}} />}
                                        label={
                                            <TagChip
                                                id={String(index)}
                                                title={item.title}
                                                color={item.color}
                                                hasOpacity={disabled}
                                                disabled={disabled}
                                                onClickAction={() => handleTagSelect(item)}
                                                onDelete={hasValue ? () => handleTagDelete(item) : undefined}
                                            />
                                        }
                                    />
                                );
                            })}
                    </RadioGroup>
                </FormControl>
                <Box
                    sx={{
                        mt: 3,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <AgentInverseButton title={t('common.buttons.cancel')} onClick={onClose} />
                    <AgentSaveButton isLoading={isLoading} title={t('common.buttons.confirm')} />
                </Box>
            </Form>
        </Formik>
    );
};

export default AddTagForm;
