import React, {FC, useCallback, useContext} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import {MenuItem} from '@mui/material';

import {INVITE_CREATE} from 'appRedux/actions/invites';
import {InviteCreateRequestType, InviteFormsTypes} from 'appRedux/actions/invites/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {inviteValidationSchema} from 'components/Forms/Invites/InviteForm/validation';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import FormikSelectInput from 'components/AgentScreenComponents/_form/FormBuilderSelectInput';

interface InviteFormType {
    forms: InviteFormsTypes[];
    setIsOpened: (value: boolean) => void;
}

const InviteForm: FC<InviteFormType> = ({forms, setIsOpened}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const createInvite = useCallback(payload => dispatch({type: INVITE_CREATE.REQUEST, payload}), [dispatch]);

    const {
        instance: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Formik
            initialValues={{
                title: '',
                id: forms[0].formId,
            }}
            validationSchema={inviteValidationSchema}
            onSubmit={values => {
                createInvite({
                    ...values,
                    callback: () => {
                        setIsOpened(false);
                    },
                    showAlert,
                });
            }}
        >
            {(formik: FormikProps<InviteCreateRequestType>) => {
                return (
                    <Form>
                        <Box sx={{mr: 1, mb: 2}}>
                            <Field
                                as="select"
                                required
                                name="id"
                                placeholder={t('orguser.invites.parameters.form')}
                                options
                                component={FormikSelectInput}
                                fullwidth
                            >
                                {forms.map(item => (
                                    <MenuItem key={`key-${item.formId}`} value={item.formId}>
                                        {item.formTitle}
                                    </MenuItem>
                                ))}
                            </Field>
                        </Box>
                        <Box sx={{mr: 1, mb: 2}}>
                            <Field
                                required
                                name="title"
                                placeholder={t('orguser.invites.parameters.title')}
                                component={FormikTextInput}
                                fullwidth
                            />
                        </Box>
                        <AgentSaveButton isLoading={isLoading} isSubmit />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default InviteForm;
