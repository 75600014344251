import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';

import {RootReducer} from 'appRedux/reducers';

import ChatSvgIcon from 'assets/icons/ChatSvgIcon';

import {MediaContext} from 'contexts/media/context';

import {routes} from 'config/index';
import {theme} from 'config/theme';

const ChatButton: FC = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const {
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {isMobile} = useContext(MediaContext);

    const {notReadMessages, id} = currentCase;

    const redirectToChat = () => {
        navigate(`${routes.REQUEST}/${id}/chat`);
    };

    if (isMobile) {
        return (
            <IconButton sx={{ml: 1, backgroundColor: theme.palette.info.main}} onClick={redirectToChat}>
                <Badge badgeContent={notReadMessages} color="error" invisible={notReadMessages === 0}>
                    <ChatSvgIcon />
                </Badge>
            </IconButton>
        );
    }
    return (
        <Button
            data-id="button#chat"
            onClick={redirectToChat}
            sx={{
                ml: 1,
                minWidth: 100,
                height: 40,
                borderRadius: 20,
                backgroundColor: theme.palette.info.main,
                '&:hover': {
                    backgroundColor: theme.palette.info.main,
                },
            }}
        >
            <Badge badgeContent={notReadMessages} color="error" invisible={notReadMessages === 0}>
                <ChatSvgIcon />
            </Badge>
            <Typography sx={{textTransform: 'none', color: theme.palette.background.default, ml: 1}}>
                {t('common.buttons.chat')}
            </Typography>
        </Button>
    );
};

export default ChatButton;
