import React, {FC, useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {FORM_SECTION_ADD} from 'appRedux/actions/forms';

import SectionForm from 'components/Forms/FormBuilder/SectionForm/Form';

import {formSectionInitialValues} from 'components/Forms/FormBuilder/SectionForm/validation';

interface CreateSectionFormType {
    pageId: number;
    toggleModal: () => void;
}

const CreateSectionForm: FC<CreateSectionFormType> = ({pageId, toggleModal}) => {
    const dispatch = useDispatch();

    const submitCreateForm = useCallback(data => dispatch({type: FORM_SECTION_ADD.REQUEST, payload: data}), [dispatch]);

    return (
        <SectionForm
            entityId={pageId}
            toggleModal={toggleModal}
            initialValues={formSectionInitialValues}
            onSubmitClicked={submitCreateForm}
            isCreate
        />
    );
};

export default CreateSectionForm;
