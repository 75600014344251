import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import {DEFAULT_FILTER_DATE_FROM, DEFAULT_FILTER_DATE_TO} from 'config/index';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

interface FilterDaterangePropsTypes {
    handleFilterChange: any;
    defaultValue?: string;
    label?: string;
    from?: {
        parameter: string;
    };
    to?: {
        parameter: string;
    };
}

const styles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 2,
    width: 286,
    '.react-daterange-picker': {
        zIndex: 999,
    },
    '.react-daterange-picker__wrapper': {
        border: 'none',
        borderBottom: '1px solid rgb(118, 118, 118)',
        padding: '4px 0 3px 0',
        '&:hover::after': {
            display: 'block',
            width: '100%',
            borderBottom: '2px solid rgba(0, 0, 0, 0.87)',
            content: '" "',
            position: 'absolute',
            bottom: '0',
        },
    },
    '.react-daterange-picker__clear-button': {
        color: 'rgba(0, 0, 0, 0.54)',
    },
    '.react-daterange-picker__inputLabel': {
        height: '100%',
        padding: '0 2px',
        fontSize: '13px',
        color: 'rgba(0, 0, 0, 0.6)',
        lineHeight: 1,
    },
    '.react-daterange-picker__button__icon': {
        stroke: 'rgba(0, 0, 0, 0.54)',
    },
    '.react-daterange-picker__calendar': {
        width: '310px',
    },
    '.react-daterange-picker__calendar .react-calendar': {
        borderRadius: '4px',
        border: 'none',
        zIndex: 9999,
        boxShadow:
            '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
    },
    '.react-daterange-picker__wrapperLabel': {
        lineHeight: 0,
        width: '100%',
    },
    '.react-calendar__viewContainer': {
        background: '#fffef2',
        borderRadius: '4px',
    },
    '.react-calendar__month-view__weekdays__weekday': {
        fontWeight: 100,
        textTransform: 'none',
    },
    '.react-daterange-picker *': {
        textDecoration: 'none',
    },
    '.react-calendar__navigation button': {
        fontSize: '16px',
        color: 'rgba(0, 0, 0, 0.54)',
    },
    '.react-calendar__navigation__label .react-calendar__navigation__label__labelText': {
        color: '#000',
    },
    '.overlay': {
        display: 'block',
        width: '100%',
        height: '100%',
        position: 'fixed',
        background: 'rgba(0, 0, 0, 0.5)',
        content: '" "',
        top: '0',
        left: '0',
        zIndex: 100,
    },
};

const DatarangeFilter: FC<FilterDaterangePropsTypes> = ({defaultValue, from, to, label = '', handleFilterChange}) => {
    const [t] = useTranslation();
    const [searchParams] = useSearchParams();

    const defaultFilterValueFrom = get(from, 'defaultValue', null) ?? DEFAULT_FILTER_DATE_FROM;
    const defaultFilterValueTo = get(to, 'defaultValue', null) ?? DEFAULT_FILTER_DATE_TO;

    const fromParameter = from && searchParams.get(from.parameter);
    const toParameter = to && searchParams.get(to.parameter);

    const [value, setFilterValue] = useState<any>([
        fromParameter ? new Date(parseInt(fromParameter)) : defaultFilterValueFrom,
        toParameter ? new Date(parseInt(toParameter)) : defaultFilterValueTo,
    ]);

    const [isOpen, setIsOpenValue] = useState<boolean>(false);

    const toggleIsOpen = () => {
        setIsOpenValue(previous => !previous);
    };

    const handleChange = (value: string | Date) => {
        if (Array.isArray(value) && value[0] && value[1]) {
            setFilterValue(value);
            handleFilterChange(value);
        }
    };

    useEffect(() => {
        setFilterValue([
            fromParameter ? new Date(parseInt(fromParameter)) : defaultFilterValueFrom,
            toParameter ? new Date(parseInt(toParameter)) : defaultFilterValueTo,
        ]);
    }, [searchParams]);

    return (
        <Box sx={styles}>
            {isOpen && <div className="overlay"></div>}
            <FormControl className="react-daterange-picker__wrapperLabel">
                <FormLabel className="react-daterange-picker__inputLabel">{t(label)}</FormLabel>
            </FormControl>
            <DateRangePicker
                onChange={handleChange}
                value={value}
                onCalendarOpen={toggleIsOpen}
                onCalendarClose={toggleIsOpen}
            />
        </Box>
    );
};

export default DatarangeFilter;
