import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {InviteAdditionalFormTypes, SubFormRequestTypes} from 'appRedux/actions/requestCase/types';

import CreateAdditionalFormRequestButton from 'pages/agent/requesterPage/additionalForms/CreateAdditionalFormRequestButton';
import CreateSubFormRequestButton from 'pages/agent/requesterPage/additionalForms/CreateSubFormRequestButton';
import InviteAdditionalFormRow from 'pages/agent/requesterPage/additionalForms/InviteAdditionalFormRow';
import SubFormRequestRow from 'pages/agent/requesterPage/additionalForms/SubFormRequestRow';

interface AdditionalFormsComponentType {
    invites: InviteAdditionalFormTypes[];
    subFormRequests: SubFormRequestTypes[];
    hasActiveAdditionalForms: boolean;
    hasActiveSubForms: boolean;
}

const AdditionalForms: FC<AdditionalFormsComponentType> = ({
    invites,
    subFormRequests,
    hasActiveAdditionalForms,
    hasActiveSubForms,
}) => {
    const [t] = useTranslation();
    return (
        <Box>
            {invites &&
                invites.length > 0 &&
                invites.map((invite: InviteAdditionalFormTypes) => {
                    return <InviteAdditionalFormRow key={`invite-${invite.uuid}`} item={invite} />;
                })}
            {subFormRequests &&
                subFormRequests.length > 0 &&
                subFormRequests.map((subFormRequest: SubFormRequestTypes) => {
                    return <SubFormRequestRow key={`subForm-${subFormRequest.uuid}`} item={subFormRequest} />;
                })}
            {subFormRequests.length === 0 && invites.length === 0 && (
                <Typography sx={{mt: 1, mb: 2}}>{t('orguser.requesterCase.additionalForms.noRequests')}</Typography>
            )}
            {(hasActiveAdditionalForms || hasActiveSubForms) && (
                <Box sx={{mt: 2}}>
                    {hasActiveAdditionalForms && <CreateAdditionalFormRequestButton />}
                    {hasActiveSubForms && <CreateSubFormRequestButton />}
                </Box>
            )}
        </Box>
    );
};

export default AdditionalForms;
