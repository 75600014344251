import React, {FC, Dispatch, SetStateAction, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FORM_FAQ_PRIORITY} from 'appRedux/actions/forms';
import {FormFaqItemTypes} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';

import DraggableBlockRow from 'components/AdminScreenComponents/DraggableBlockRow';
import EmptyArea from 'components/EmptyArea/EmptyArea';
import MarkdownWithShowAll from 'components/TextWithShowAll/MarkdownWithShowAll';

import UpdateFormFaqButton from 'pages/admin/updateForm/partials/FormFaq/UpdateFormFaqButton';
import DeleteFormFaqButton from 'pages/admin/updateForm/partials/FormFaq/DeleteFormFaqButton';

import {theme} from 'config/theme';

interface FormFaqRowItem {
    item: FormFaqItemTypes;
    dragItem?: string;
    setDragItem: Dispatch<SetStateAction<string>>;
    dropTo?: number;
    setDropTo: Dispatch<SetStateAction<number>>;
}

const FormFaqRow: FC<FormFaqRowItem> = ({item, dragItem, setDragItem, dropTo, setDropTo}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {question, answer} = item;

    const changePriority = useCallback(data => dispatch({type: FORM_FAQ_PRIORITY.REQUEST, payload: data}), [dispatch]);

    const onDragEnd = () => {
        changePriority({
            uuid: dragItem,
            priority: dropTo,
            showAlert,
            callback: () => setDragItem(''),
        });
    };

    return (
        <DraggableBlockRow
            item={item}
            dragItem={dragItem}
            setDragItem={setDragItem}
            dropTo={dropTo}
            setDropTo={setDropTo}
            onDragEnd={onDragEnd}
        >
            <Box
                sx={{
                    border: `1px solid ${theme.palette.info.main}`,
                    borderRadius: 4,
                    p: 1.5,
                    mb: 2,
                    ml: 2,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 1,
                    }}
                >
                    <Typography sx={{fontWeight: 700}}>{question}</Typography>
                    <EmptyArea />
                    <UpdateFormFaqButton item={item} />
                    <DeleteFormFaqButton item={item} />
                </Box>
                <MarkdownWithShowAll text={answer} />
            </Box>
        </DraggableBlockRow>
    );
};

export default FormFaqRow;
