import React, {FC, useCallback, useContext} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import {INVITE_SEND} from 'appRedux/actions/invites';
import {InviteFormsTypes, SendInviteRequestType} from 'appRedux/actions/invites/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {inviteValidationSchema} from 'components/Forms/Invites/SendInviteForm/validation';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import FormikSelectInput from 'components/AgentScreenComponents/_form/FormBuilderSelectInput';

interface SendInviteFormType {
    forms: InviteFormsTypes[];
    setIsOpened: (value: boolean) => void;
}

const SendInviteForm: FC<SendInviteFormType> = ({forms, setIsOpened}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const sendInvite = useCallback(payload => dispatch({type: INVITE_SEND.REQUEST, payload}), [dispatch]);

    const {
        instance: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Formik
            initialValues={{
                email: '',
                formId: forms[0].formId,
            }}
            validationSchema={inviteValidationSchema}
            onSubmit={values => {
                sendInvite({
                    ...values,
                    callback: () => {
                        setIsOpened(false);
                    },
                    showAlert,
                });
            }}
        >
            {(formik: FormikProps<SendInviteRequestType>) => {
                return (
                    <Form>
                        <Box sx={{mr: 1, mb: 2}}>
                            <Field
                                as="select"
                                required
                                name="formId"
                                placeholder={t('orguser.invites.parameters.form')}
                                options
                                component={FormikSelectInput}
                                fullwidth
                            >
                                {forms.map(item => (
                                    <MenuItem key={`key-${item.formId}`} value={item.formId}>
                                        {item.formTitle}
                                    </MenuItem>
                                ))}
                            </Field>
                        </Box>
                        <Box sx={{mr: 1, mb: 2}}>
                            <Field
                                required
                                name="email"
                                placeholder={t('orguser.invites.parameters.email')}
                                component={FormikTextInput}
                                fullwidth
                            />
                        </Box>
                        <AgentSaveButton isLoading={isLoading} isSubmit />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default SendInviteForm;
