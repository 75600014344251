import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import {WorkflowStatusEventItemTypes} from 'appRedux/actions/workflow/types';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import WorkflowEventAttachGroupForm from 'components/Forms/Workflow/workflowEventGroup/WorkflowEventAttachGroupForm';

interface AttachGroupButtonType {
    item: WorkflowStatusEventItemTypes;
}

const AttachGroupButton: FC<AttachGroupButtonType> = ({item}) => {
    const [t] = useTranslation();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    return (
        <Box>
            <AgentSaveButton
                isSubmit={false}
                title={t('orguser.workflows.workflowStatusEventParameters.attachGroup')}
                onClick={toggleModal}
                icon={<PlusSvgIcon isActive />}
            />
            <ModalWrapper
                isShowModal={showModal}
                toggleModal={toggleModal}
                title={t('orguser.workflows.workflowStatusEventParameters.attachGroup')}
            >
                <WorkflowEventAttachGroupForm item={item} closeForm={() => setShowModal(false)} />
            </ModalWrapper>
        </Box>
    );
};

export default AttachGroupButton;
