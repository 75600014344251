import {
    GET_ORGANIZATION_PUBLIC_KEYS,
    REMOVE_ORGANIZATION_PUBLIC_KEYS,
    GET_PUBLIC_KEYS_FOR_CASE_KEYS_GENERATION,
    GET_PUBLIC_KEYS_FOR_OTHER_AGENT,
    CREATE_CASE_KEY,
    GET_CASE_KEYS,
    CREATE_PUBLIC_KEY,
    UPDATE_PUBLIC_KEY,
    GET_USER_PUBLIC_KEYS,
    GET_USER_SAVED_KEYS,
    REMOVE_PUBLIC_KEY,
    GET_AGENTS_WITH_CASE_ACCESS,
    CREATE_CASE_ACCESS_REQUEST,
    DELETE_CASE_ACCESS_REQUEST,
    GET_CASE_ACCESS_REQUESTS,
    HANDLE_CASE_ACCESS_REQUEST,
    REGENERATE_CASE_KEYS,
    SAVE_USER_KEYS,
    REMOVE_USER_SAVED_KEYS,
} from 'appRedux/actions/crypto';

import {
    PublicKeyOrganizationTypes,
    PublicKeysForCaseKeysTypes,
    PublicKeyTypes,
    CaseAccessRequestTypes,
    AgentsWithCaseAccessTypes,
} from 'appRedux/actions/crypto/types';

export const initialState: CryptoState = {
    isLoading: false,
    organizationPublicKeys: [],
    publicKeysForGeneration: [],
    currentUserPublicKeys: null,
    agentsWithCaseAccess: [],
    caseAccessRequests: [],
    caseKeys: [],
    errors: undefined,
};

export interface CryptoState {
    isLoading: boolean;
    organizationPublicKeys: PublicKeyOrganizationTypes[];
    publicKeysForGeneration: PublicKeysForCaseKeysTypes[];
    currentUserPublicKeys: PublicKeyTypes[] | null;
    agentsWithCaseAccess: AgentsWithCaseAccessTypes[];
    caseAccessRequests: CaseAccessRequestTypes[];
    caseKeys: string[];
    errors: string[] | undefined;
}

const crypto = (
    state = initialState,
    action: IActionType<
        | PublicKeyOrganizationTypes[]
        | PublicKeyTypes[]
        | PublicKeysForCaseKeysTypes[]
        | CaseAccessRequestTypes[]
        | AgentsWithCaseAccessTypes[]
        | string[]
        | IErrors
    >,
): CryptoState => {
    switch (action.type) {
        case GET_ORGANIZATION_PUBLIC_KEYS.REQUEST:
        case REMOVE_ORGANIZATION_PUBLIC_KEYS.REQUEST:
        case GET_PUBLIC_KEYS_FOR_CASE_KEYS_GENERATION.REQUEST:
        case GET_PUBLIC_KEYS_FOR_OTHER_AGENT.REQUEST:
        case GET_CASE_KEYS.REQUEST:
        case CREATE_CASE_KEY.REQUEST:
        case CREATE_PUBLIC_KEY.REQUEST:
        case UPDATE_PUBLIC_KEY.REQUEST:
        case GET_AGENTS_WITH_CASE_ACCESS.REQUEST:
        case GET_CASE_ACCESS_REQUESTS.REQUEST:
        case CREATE_CASE_ACCESS_REQUEST.REQUEST:
        case HANDLE_CASE_ACCESS_REQUEST.REQUEST:
        case DELETE_CASE_ACCESS_REQUEST.REQUEST:
        case REMOVE_PUBLIC_KEY.REQUEST:
        case GET_USER_SAVED_KEYS.REQUEST:
        case SAVE_USER_KEYS.REQUEST:
        case REMOVE_USER_SAVED_KEYS.REQUEST:
        case REGENERATE_CASE_KEYS.REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case GET_USER_PUBLIC_KEYS.REQUEST: {
            return {
                ...state,
                isLoading: true,
                currentUserPublicKeys: null,
            };
        }

        case GET_ORGANIZATION_PUBLIC_KEYS.SUCCESS:
        case REMOVE_ORGANIZATION_PUBLIC_KEYS.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                organizationPublicKeys: action.payload as PublicKeyOrganizationTypes[],
            };
        }

        case GET_PUBLIC_KEYS_FOR_OTHER_AGENT.SUCCESS:
        case GET_PUBLIC_KEYS_FOR_CASE_KEYS_GENERATION.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                publicKeysForGeneration: action.payload as PublicKeysForCaseKeysTypes[],
            };
        }

        case GET_CASE_KEYS.SUCCESS:
        case CREATE_CASE_KEY.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                caseKeys: action.payload as string[],
            };
        }

        case CREATE_PUBLIC_KEY.SUCCESS:
        case UPDATE_PUBLIC_KEY.SUCCESS:
        case GET_USER_PUBLIC_KEYS.SUCCESS:
        case REMOVE_PUBLIC_KEY.SUCCESS:
        case REGENERATE_CASE_KEYS.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                currentUserPublicKeys: action.payload as PublicKeyTypes[],
            };
        }

        case GET_AGENTS_WITH_CASE_ACCESS.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                agentsWithCaseAccess: action.payload as AgentsWithCaseAccessTypes[],
            };
        }

        case GET_CASE_ACCESS_REQUESTS.SUCCESS:
        case DELETE_CASE_ACCESS_REQUEST.SUCCESS:
        case HANDLE_CASE_ACCESS_REQUEST.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                caseAccessRequests: action.payload as CaseAccessRequestTypes[],
            };
        }

        case CREATE_CASE_ACCESS_REQUEST.SUCCESS:
        case GET_USER_SAVED_KEYS.SUCCESS:
        case SAVE_USER_KEYS.SUCCESS:
        case REMOVE_USER_SAVED_KEYS.SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case GET_ORGANIZATION_PUBLIC_KEYS.ERROR:
        case REMOVE_ORGANIZATION_PUBLIC_KEYS.ERROR:
        case GET_PUBLIC_KEYS_FOR_OTHER_AGENT.ERROR:
        case GET_PUBLIC_KEYS_FOR_CASE_KEYS_GENERATION.ERROR:
        case GET_CASE_KEYS.ERROR:
        case CREATE_CASE_KEY.ERROR:
        case CREATE_PUBLIC_KEY.ERROR:
        case UPDATE_PUBLIC_KEY.ERROR:
        case GET_USER_PUBLIC_KEYS.ERROR:
        case GET_AGENTS_WITH_CASE_ACCESS.ERROR:
        case GET_CASE_ACCESS_REQUESTS.ERROR:
        case CREATE_CASE_ACCESS_REQUEST.ERROR:
        case HANDLE_CASE_ACCESS_REQUEST.ERROR:
        case DELETE_CASE_ACCESS_REQUEST.ERROR:
        case REMOVE_PUBLIC_KEY.ERROR:
        case REGENERATE_CASE_KEYS.ERROR:
        case GET_USER_SAVED_KEYS.ERROR:
        case SAVE_USER_KEYS.ERROR:
        case REMOVE_USER_SAVED_KEYS.ERROR: {
            return {
                ...state,
                isLoading: false,
                errors: action.payload as any,
            };
        }

        default:
            return state;
    }
};

export default crypto;
