import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {CreateOrganizationRequestTypes} from 'appRedux/actions/organization/types';
import {OrganizationTranslationsUpdateFormRequestTypes} from 'appRedux/actions/organizationLanguage/types';
import {ORGANIZATION_TRANSLATIONS_UPDATE} from 'appRedux/actions/organizationLanguage';

import {AlertContext} from 'contexts/alert/context';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import {organizationTranslationsValidationSchema} from 'components/Forms/OrganizationForm/validation';
import MdxEditorField from 'components/MdxEditorField/MdxEditorField';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {getTranslationByKeyword} from 'components/Forms/OrganizationForm/helper';

interface OrganizationTranslationFormType {
    organizationId: number;
    initialValues: CreateOrganizationRequestTypes;
    currentTab: string;
}

const OrganizationTranslationForm: FC<OrganizationTranslationFormType> = ({
    organizationId,
    initialValues,
    currentTab,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        admin: {isLoading, organizationTranslations},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const updateOrganizationTranslations = useCallback(
        data => dispatch({type: ORGANIZATION_TRANSLATIONS_UPDATE.REQUEST, payload: data}),
        [dispatch],
    );

    const {name, description} = initialValues;

    return (
        <Box>
            <Typography sx={{fontWeight: 600, mb: 2}}>{`${t('common.translateTo')}: ${currentTab}`}</Typography>
            <Formik
                initialValues={{
                    name: getTranslationByKeyword(organizationTranslations, 'name', name),
                    description: getTranslationByKeyword(organizationTranslations, 'description', description),
                }}
                enableReinitialize
                validationSchema={organizationTranslationsValidationSchema}
                onSubmit={(values, {setErrors}) => {
                    updateOrganizationTranslations({
                        id: organizationId,
                        lang: currentTab,
                        ...values,
                        showAlert,
                        setErrors,
                    });
                }}
            >
                {(formik: FormikProps<OrganizationTranslationsUpdateFormRequestTypes>) => {
                    return (
                        <Form>
                            <Box>
                                <Field
                                    required
                                    name="name"
                                    placeholder={t('superadmin.organizations.organizationName')}
                                    component={FormikTextInput}
                                />
                            </Box>
                            <Box>
                                <MdxEditorField
                                    name="description"
                                    value={formik.values.description ? String(formik.values.description) : ''}
                                    label={t('superadmin.organizations.description')}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    pt: 3,
                                }}
                            >
                                <AgentSaveButton isSubmit isLoading={isLoading} />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default OrganizationTranslationForm;
