import React, {FC} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import {RuleRequestType} from 'appRedux/actions/rules/types';

import {validationSchema} from 'components/Forms/RuleForm/validation';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import MdxEditorField from 'components/MdxEditorField/MdxEditorField';

interface RuleFormType {
    type: number;
    language: number;
    text: string;
    onSubmitClicked: (values: RuleRequestType) => void;
}

const RuleForm: FC<RuleFormType> = ({type, language, text, onSubmitClicked}) => {
    const [t] = useTranslation();
    return (
        <Formik
            initialValues={{
                type,
                language,
                text,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<RuleRequestType>) => {
                return (
                    <Form>
                        <MdxEditorField
                            name="text"
                            value={formik.values.text ? String(formik.values.text) : ''}
                            label={t('superadmin.rules.text')}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default RuleForm;
