import React, {FC, ReactNode} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {ClientTranslationUpdatedType} from 'appRedux/actions/clientTranslation/types';

import DescriptionPopover from 'components/DescriptionPopover/DescriptionPopover';

import FieldTranslation from 'pages/agent/requesterPage/translation/FieldTranslation';

interface InformationWrapperType {
    sectionId: number;
    title: string;
    description: string;
    children: ReactNode;
    field: FormFieldTypes;
    showTranslation: boolean;
    editTranslation: boolean;
    clientTranslations?: ClientTranslationUpdatedType;
    setClientTranslations?: (value: ClientTranslationUpdatedType) => void;
    currentValue: string;
}

const InformationWrapper: FC<InformationWrapperType> = ({
    children,
    editTranslation,
    showTranslation,
    sectionId,
    field,
    title,
    description,
    clientTranslations,
    setClientTranslations,
    currentValue,
}) => {
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <Typography sx={{fontWeight: 600}}>{title}</Typography>
                {description && description !== '' && <DescriptionPopover text={description} />}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    backgroundColor: `rgba(34, 34, 34, 0.05)`,
                    borderRadius: 2,
                    mt: 1,
                    mb: 2,
                    p: 2,
                }}
            >
                {children}
            </Box>
            {setClientTranslations && (
                <FieldTranslation
                    field={field}
                    currentValue={currentValue}
                    sectionId={sectionId}
                    clientTranslations={clientTranslations}
                    setClientTranslations={setClientTranslations}
                    editTranslation={editTranslation}
                    showTranslation={showTranslation}
                />
            )}
        </Box>
    );
};

export default InformationWrapper;
