import React, {FC, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import JSZip from 'jszip';
import {useSelector} from 'react-redux';
import moment from 'moment';
import {saveAs} from '@progress/kendo-file-saver';

import Typography from '@mui/material/Box';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';

import {RootReducer} from 'appRedux/reducers';
import {RequesterCaseAttachmentsForArchiveTypes} from 'appRedux/actions/requestCase/types';

import {CaseKeyContext} from 'contexts/caseKey/context';
import {AlertContext} from 'contexts/alert/context';

import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {SelectedAttachmentType, getFilesForDownload} from 'pages/agent/requesterPage/partials/helper';

import {getEncryptedFileData} from 'helpers/cryptoApiHelper';

import {theme} from 'config/theme';
import {DATE_TIME_FORMAT, ALERT_TYPE_ERROR} from 'config/index';

const DownloadArchiveButton: FC = () => {
    const [t] = useTranslation();

    const {
        requestCase: {currentCase, caseAttachments},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {showAlert} = useContext(AlertContext);
    const {unwrappedCaseKey} = useContext(CaseKeyContext);

    const [filesList, setFilesList] = useState<RequesterCaseAttachmentsForArchiveTypes[]>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedAttachments, setSelectedAttachments] = useState<SelectedAttachmentType[]>([]);
    const [, setIsUpdated] = useState<boolean>(false);

    const selectAttachment = async (attachment: RequesterCaseAttachmentsForArchiveTypes) => {
        const {uuid, src, encryptPrefix, fileName} = attachment;

        if (selectedAttachments.some(item => item.uuid === uuid)) {
            for (let i = 0, n = selectedAttachments.length; i < n; i++) {
                if (selectedAttachments[i].uuid === uuid) {
                    selectedAttachments.splice(i, 1);
                }
            }
        } else {
            if (encryptPrefix && unwrappedCaseKey) {
                const decryptedFileSource = await getEncryptedFileData(src, encryptPrefix, unwrappedCaseKey);
                if (decryptedFileSource) {
                    selectedAttachments.push({
                        uuid,
                        fileSource: decryptedFileSource,
                        fileName,
                    });
                }
            }
        }
        setSelectedAttachments(selectedAttachments);
        setIsUpdated(previous => !previous);
    };

    const toggleOpenModal = () => {
        setOpenModal(previous => !previous);
    };

    const onDownloadClick = () => {
        try {
            const zip = new JSZip();
            selectedAttachments.forEach(attachment => {
                const idx = attachment.fileSource.indexOf('base64,') + 'base64,'.length;
                const content = attachment.fileSource.substring(idx);
                zip.file(attachment.fileName, content, {base64: true});
            });
            zip.generateAsync({type: 'blob'}).then(function (content) {
                saveAs(content, `Requester case #${currentCase.id} - ${moment().format(DATE_TIME_FORMAT)}.zip`);
            });
        } catch (e) {
            showAlert(ALERT_TYPE_ERROR);
        }
        setOpenModal(false);
    };

    useEffect(() => {
        const timer = setTimeout(async () => {
            const allFiles: RequesterCaseAttachmentsForArchiveTypes[] = await getFilesForDownload(
                caseAttachments,
                unwrappedCaseKey,
            );
            setFilesList(allFiles);
        }, 200);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <AgentInverseButton
                title={t('orguser.requesterCase.documents.downloadArchive')}
                onClick={() => setOpenModal(true)}
            />
            <ModalWrapper
                title={t('orguser.requesterCase.documents.downloadArchive')}
                isShowModal={openModal}
                toggleModal={toggleOpenModal}
            >
                <Box>
                    {filesList &&
                        filesList.map((attachment: RequesterCaseAttachmentsForArchiveTypes) => {
                            return (
                                <Box
                                    key={`attachment-${attachment.uuid}`}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        mb: 2,
                                    }}
                                >
                                    <Checkbox
                                        sx={{p: 0, '& svg': {color: theme.palette.info.main}}}
                                        onChange={() => selectAttachment(attachment)}
                                        checked={selectedAttachments.some(item => item.uuid === attachment.uuid)}
                                    />
                                    <Typography sx={{ml: 1}}>{attachment.fileName}</Typography>
                                </Box>
                            );
                        })}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            p: 1,
                        }}
                    >
                        <AgentSaveButton
                            isSubmit={false}
                            isDisabled={
                                !selectedAttachments || (selectedAttachments && selectedAttachments.length === 0)
                            }
                            onClick={onDownloadClick}
                            title={t('common.buttons.download')}
                        />
                    </Box>
                </Box>
            </ModalWrapper>
        </>
    );
};

export default DownloadArchiveButton;
