import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {ResourceFieldSlotItemTypes} from 'appRedux/actions/resourceFields/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import UpdateResourceFieldSlotForm from 'components/Forms/ResourceField/UpdateResourceFieldSlotForm';

interface UpdateResourceFieldSlotButtonType {
    item: ResourceFieldSlotItemTypes;
}

const UpdateResourceFieldSlotButton: FC<UpdateResourceFieldSlotButtonType> = ({item}) => {
    const [t] = useTranslation();

    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    return (
        <>
            <IconButton onClick={() => setIsShowModal(true)} title={t('common.buttons.update')}>
                <EditSvgIcon />
            </IconButton>
            <ModalWrapper
                isShowModal={isShowModal}
                toggleModal={toggleModal}
                title={`${t('orguser.resourceFields.updateResourceField')} (${t('orguser.resourceFields.types.slot')})`}
            >
                <UpdateResourceFieldSlotForm item={item} closeModal={() => setIsShowModal(false)} />
            </ModalWrapper>
        </>
    );
};

export default UpdateResourceFieldSlotButton;
