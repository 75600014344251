import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Markdown from 'react-markdown';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import InfoSolidSvgIcon from 'assets/icons/buttons/InfoSolidSvgIcon';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

const AdvancedModeDetails: FC = () => {
    const [t] = useTranslation();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    return (
        <>
            <IconButton title={t('common.account.isAdvancedMode')} onClick={() => setShowModal(true)}>
                <InfoSolidSvgIcon />
            </IconButton>
            <ModalWrapper title={t('common.account.isAdvancedMode')} isShowModal={showModal} toggleModal={toggleModal}>
                <Markdown>{t('common.account.advancedModeExplanation')}</Markdown>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 2,
                    }}
                >
                    <AgentSaveButton
                        isSubmit={false}
                        title={t('common.buttons.close')}
                        onClick={() => setShowModal(false)}
                    />
                </Box>
            </ModalWrapper>
        </>
    );
};

export default AdvancedModeDetails;
