import React, {FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {FormConfigurationRequestTypes} from 'appRedux/actions/forms/types';
import {FORM_UPLOAD_LOGO, FORM_LOGO_GET, FORM_LOGO_DELETE} from 'appRedux/actions/forms';
import {RootReducer} from 'appRedux/reducers';

import CommonLogoForm from 'components/Forms/CommonLogoForm/CommonLogoForm';

interface FormLogoFormType {
    formId: number;
    initialValues: FormConfigurationRequestTypes;
    setFormLogoSource: (value: string) => void;
}

const FormLogoForm: FC<FormLogoFormType> = ({formId, initialValues, setFormLogoSource}) => {
    const dispatch = useDispatch();

    const {title, icon} = initialValues;

    const {
        admin: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getFormLogoUrl = useCallback(data => dispatch({type: FORM_LOGO_GET.REQUEST, payload: data}), [dispatch]);

    const submitUploadFile = useCallback(data => dispatch({type: FORM_UPLOAD_LOGO.REQUEST, payload: data}), [dispatch]);

    const deleteFormLogo = useCallback(data => dispatch({type: FORM_LOGO_DELETE.REQUEST, payload: data}), [dispatch]);

    return (
        <CommonLogoForm
            id={formId}
            title={title}
            initialLogo={icon}
            isLoading={isLoading}
            getLogoUrl={getFormLogoUrl}
            submitUploadFile={submitUploadFile}
            deleteFormLogo={deleteFormLogo}
            setFormLogoSource={setFormLogoSource}
        />
    );
};

export default FormLogoForm;
