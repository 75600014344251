import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {RootReducer} from 'appRedux/reducers';
import {GET_REQUESTER_CASE} from 'appRedux/actions/requestCase';
import {FORM_BY_CASE_INFORMATION} from 'appRedux/actions/forms';
import {ORGANIZATION_PDF_TEMPLATES_LIST} from 'appRedux/actions/organization';

import CaseKeyContextWrapper from 'contexts/caseKey';
import {AlertContext} from 'contexts/alert/context';

import GeneralErrorMessage from 'components/GeneralErrorMessage/GeneralErrorMessage';

import PdfViewContent from 'pages/agent/pdfExport/PdfViewContent';

const PdfExportView: FC = () => {
    const dispatch = useDispatch();
    const {caseId} = useParams();
    const {showAlert} = useContext(AlertContext);

    const getFormByCaseInformation = useCallback(
        data => dispatch({type: FORM_BY_CASE_INFORMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequestCaseInformation = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const getOrganizationPdfTemplatesList = useCallback(
        data => dispatch({type: ORGANIZATION_PDF_TEMPLATES_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        errors: {generalError},
        requestCase: {
            currentCase: {isEncryptInfo},
        },
        admin: {organizationList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    useEffect(() => {
        getFormByCaseInformation({id: caseId});
        getRequestCaseInformation({id: caseId});
        myOrganization &&
            getOrganizationPdfTemplatesList({
                id: myOrganization.id,
                showAlert,
            });
    }, [caseId, myOrganization?.id]);

    if (generalError) {
        return <GeneralErrorMessage />;
    }

    if (isEncryptInfo)
        return (
            <CaseKeyContextWrapper caseId={Number(caseId)}>
                <PdfViewContent />
            </CaseKeyContextWrapper>
        );

    return <PdfViewContent />;
};

export default PdfExportView;
