import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';

import {OrganizationQuotasItemBasic} from 'appRedux/actions/organization/types';
import {TOGGLE_ORGANIZATION_QUOTA_HARD, DELETE_ORGANIZATION_QUOTA} from 'appRedux/actions/organization';

import {AlertContext} from 'contexts/alert/context';

import {CustomSwitcher} from 'components/AgentScreenComponents/_form/CustomElements';
import DeleteAdminConfirmationButton from 'components/AdminScreenComponents/DeleteAdminConfirmationButton';

import {OrganizationQuotasArrayType} from 'pages/admin/updateOrganization/partials/OrganizationQuotas/helper';
import CreateOrganizationQuotaButton from 'pages/admin/updateOrganization/partials/OrganizationQuotas/CreateOrganizationQuotaButton';
import EditOrganizationQuotaButton from 'pages/admin/updateOrganization/partials/OrganizationQuotas/EditOrganizationQuotaButton';

interface OrganizationQuotasRowType {
    quota?: OrganizationQuotasItemBasic;
    item: OrganizationQuotasArrayType;
}

const OrganizationQuotasRow: FC<OrganizationQuotasRowType> = ({quota, item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [isQuotaHard, setIsQuotaHard] = useState<boolean>(quota ? quota.isHard : false);

    const toggleShowModal = () => {
        setShowModal(previous => !previous);
    };

    const deleteOrganizationQuota = useCallback(
        data => dispatch({type: DELETE_ORGANIZATION_QUOTA.REQUEST, payload: data}),
        [dispatch],
    );

    const toggleIsHardQuota = useCallback(
        data => dispatch({type: TOGGLE_ORGANIZATION_QUOTA_HARD.REQUEST, payload: data}),
        [dispatch],
    );

    const handleToggleIsHard = () => {
        if (quota) {
            setIsQuotaHard(!isQuotaHard);
            toggleIsHardQuota({
                uuid: quota.uuid,
                showAlert,
            });
        }
    };

    const onClickDelete = () => {
        if (quota) {
            deleteOrganizationQuota({
                uuid: quota.uuid,
                showAlert,
                callback: () => setShowModal(false),
            });
        }
    };

    return (
        <TableRow>
            <TableCell>
                <Typography>{t(item.title)}</Typography>
            </TableCell>
            <TableCell>
                <Typography>{quota && quota.value ? quota.value : t('common.none')}</Typography>
            </TableCell>
            <TableCell>
                {quota ? <CustomSwitcher checked={quota.isHard} onClick={handleToggleIsHard} /> : t('common.none')}
            </TableCell>
            <TableCell>
                {quota ? (
                    <>
                        <EditOrganizationQuotaButton item={item} quota={quota} />
                        <DeleteAdminConfirmationButton
                            isShowModal={showModal}
                            disabled={false}
                            toggleModal={toggleShowModal}
                            onClickAction={onClickDelete}
                        />
                    </>
                ) : (
                    <CreateOrganizationQuotaButton item={item} />
                )}
            </TableCell>
        </TableRow>
    );
};

export default OrganizationQuotasRow;
