import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {OrganizationLanguageTypes} from 'appRedux/actions/organizationLanguage/types';
import {ORGANIZATION_LANGUAGE_ACTIVATE, ORGANIZATION_LANGUAGE_REMOVE} from 'appRedux/actions/organizationLanguage';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import DeleteAdminConfirmationButton from 'components/AdminScreenComponents/DeleteAdminConfirmationButton';
import {CustomSwitcher} from 'components/AgentScreenComponents/_form/CustomElements';

interface OrganizationLanguageRowType {
    item: OrganizationLanguageTypes;
}

const OrganizationLanguageRow: FC<OrganizationLanguageRowType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {languageTitle, isActive, uuid} = item;

    const {showAlert} = useContext(AlertContext);
    const {isOrganizationEditEnabled} = useContext(PermissionContext);

    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [languageState, setLanguageState] = useState<boolean>(isActive);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    const toggleOrganizationLanguageStatus = useCallback(
        data => dispatch({type: ORGANIZATION_LANGUAGE_ACTIVATE.REQUEST, payload: data}),
        [dispatch],
    );

    const deleteOrganizationLanguage = useCallback(
        data => dispatch({type: ORGANIZATION_LANGUAGE_REMOVE.REQUEST, payload: data}),
        [dispatch],
    );

    const toggleStatus = (event: any) => {
        setLanguageState(event.target.checked);
        toggleOrganizationLanguageStatus({
            uuid,
            showAlert,
        });
    };

    const onClickDelete = () => {
        deleteOrganizationLanguage({
            uuid,
            showAlert,
            callback: () => setIsShowModal(false),
        });
    };

    return (
        <Grid container flexDirection="row" alignItems="center" sx={{mb: 0.5}}>
            <Grid item sm={2}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <Typography variant="body2" sx={{fontWeight: 300}}>
                        {languageTitle}
                    </Typography>
                </Box>
            </Grid>
            <Grid item sm={3}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <CustomSwitcher checked={languageState} onClick={toggleStatus} />
                    <Typography variant="body2" sx={{fontWeight: 300, ml: 2}}>
                        {t('orguser.forms.formLanguageSwitcher')}
                    </Typography>
                </Box>
            </Grid>
            <Grid item sm={6}></Grid>
            <Grid
                item
                sm={1}
                sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}
            >
                <DeleteAdminConfirmationButton
                    disabled={!isOrganizationEditEnabled}
                    isShowModal={isShowModal}
                    toggleModal={toggleModal}
                    onClickAction={onClickDelete}
                />
            </Grid>
        </Grid>
    );
};

export default OrganizationLanguageRow;
