import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import CreateSubFormRequestForm from 'components/Forms/RequesterCase/CreateSubFormRequestForm';

const CreateSubFormRequestButton: FC = () => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <>
            <AgentSaveButton
                isSubmit={false}
                title={t('orguser.requesterCase.additionalForms.attachSubForm')}
                onClick={toggleIsOpened}
            />
            <ModalWrapper
                title={t('orguser.requesterCase.additionalForms.attachSubForm')}
                isShowModal={isOpened}
                toggleModal={toggleIsOpened}
            >
                <CreateSubFormRequestForm closeModal={() => setIsOpened(false)} />
            </ModalWrapper>
        </>
    );
};

export default CreateSubFormRequestButton;
