import React, {FC} from 'react';

const Avatar_05: FC = () => {
    return (
        <svg viewBox="0 0 61.7998 61.7998" xmlns="http://www.w3.org/2000/svg">
            <title />
            <g data-name="Layer 2" id="Layer_2">
                <g data-name="—ÎÓÈ 1" id="_ÎÓÈ_1">
                    <circle cx="30.8999" cy="30.8999" fill="#ffc200" r="30.8999" />
                    <path d="M24.861 39.625l12.301.116V52.03l-12.301-.116V39.625z" fill="#f9dca4" fillRule="evenodd" />
                    <path
                        d="M37.164 39.719v3.406a8.733 8.733 0 0 1-.039 1.166c-.053.415-1.314-.064-1.464.388-4.343 3.524-10.243-.74-10.8-3.383z"
                        fillRule="evenodd"
                        opacity="0.11"
                    />
                    <path
                        d="M30.935 10.85c20.245 0 12.355 33.549 0 33.549-11.67 0-20.245-33.548 0-33.548z"
                        fill="#ffe8be"
                        fillRule="evenodd"
                    />
                    <path
                        d="M18.815 25.834c-2.816 1.229-.422 7.05 1.35 7.024a29.337 29.337 0 0 1-1.35-7.024z"
                        fill="#f9dca4"
                        fillRule="evenodd"
                    />
                    <path
                        d="M43.309 25.806c2.815 1.228.45 7.078-1.32 7.052a29.499 29.499 0 0 0 1.32-7.052z"
                        fill="#f9dca4"
                        fillRule="evenodd"
                    />
                    <path
                        d="M30.92 11.073c8.298 0 11.775 5.9 11.988 12.716.292 9.332-5.398 20.387-11.987 20.387-6.24 0-12.087-11.433-11.732-20.86.248-6.615 3.628-12.242 11.732-12.242z"
                        fill="#ffe8be"
                        fillRule="evenodd"
                    />
                    <path
                        d="M19.573 30.206s.217-8.582 7.93-8.908 9.343-4.454 9.343-4.454a30.732 30.732 0 0 0 6.492 8.99c5.806-10.92-4.97-16.378-4.97-16.378s.235-6.347-7.605-6.246-6.519 6.68-6.519 6.68-11.842 6.41-4.671 20.316z"
                        fill="#969696"
                        fillRule="evenodd"
                    />
                    <path
                        d="M52.797 52.701c-.484-1.122-1.787-3.851-2.822-4.5-4.398-2.756-8.086-3.155-12.813-5.3.238 10.814-12.055 10.672-12.302-.294-4.974 2.234-9.567 3.463-14.228 6.293-.948.576-1.508 2.475-1.915 3.508a30.896 30.896 0 0 0 44.08.293z"
                        fill="#434955"
                        fillRule="evenodd"
                    />
                    <path
                        d="M24.846 42.614l6.229 8.308c-3.93 7.28-11.765 5.49-13.534-5.712 4.308-1.64 7.305-2.596 7.305-2.596z"
                        fill="#ffffff"
                        fillRule="evenodd"
                    />
                    <path
                        d="M20.793 26.956h8.818a.44.44 0 0 1 .44.429 5.775 5.775 0 0 1 .009.809h1.68a5.832 5.832 0 0 1 .009-.81.44.44 0 0 1 .44-.428h8.818a.44.44 0 0 1 .44.44c0 .014.07 5.155-5.092 5.155-2.962 0-4.044-1.678-4.426-3.128H29.87c-.382 1.45-1.464 3.128-4.427 3.128-5.161 0-5.09-5.14-5.09-5.155a.44.44 0 0 1 .439-.44z"
                        fill="#e6e6e6"
                        fillRule="evenodd"
                    />
                    <path
                        d="M37.188 42.614l-6.14 8.308c3.872 7.28 11.595 5.49 13.34-5.712-4.247-1.64-7.2-2.596-7.2-2.596z"
                        fill="#ffffff"
                        fillRule="evenodd"
                    />
                    <path
                        d="M20.793 26.956h8.818a.44.44 0 0 1 .44.429 5.775 5.775 0 0 1 .009.809h1.68a5.832 5.832 0 0 1 .009-.81.44.44 0 0 1 .44-.428h8.818a.44.44 0 0 1 .44.44c0 .014.07 5.155-5.092 5.155-2.962 0-4.044-1.678-4.426-3.128H29.87c-.382 1.45-1.464 3.128-4.427 3.128-5.161 0-5.09-5.14-5.09-5.155a.44.44 0 0 1 .439-.44zm19.747.88h-7.934a3.5 3.5 0 0 0 3.75 3.836 3.977 3.977 0 0 0 4.184-3.837zm-11.347 0H21.26a3.977 3.977 0 0 0 4.184 3.836 3.5 3.5 0 0 0 3.75-3.837z"
                        fill="#434955"
                        fillRule="evenodd"
                    />
                    <circle cx="31.04047" cy="55.66817" fill="#e6e6e6" r="0.83905" />
                    <circle cx="31.04047" cy="58.7874" fill="#e6e6e6" r="0.83907" />
                </g>
            </g>
        </svg>
    );
};

export default Avatar_05;
