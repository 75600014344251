import React, {FC} from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseHasBudgetTypes} from 'appRedux/actions/requestCase/types';

import {
    TYPE_BUDGET_DEFINITION_REQUESTER,
    TYPE_BUDGET_INPUT_CALENDAR,
} from 'components/Forms/FormBuilder/FieldForm/helper';

import {getBudgetAmountValue} from 'helpers/resourceFieldsHelper';

import {DATE_FORMAT} from 'config/index';

interface RequesterCaseHasBudgetAmountType {
    field: FormFieldTypes;
    currentBudget: RequesterCaseHasBudgetTypes;
}

const RequesterCaseHasBudgetAmount: FC<RequesterCaseHasBudgetAmountType> = ({field, currentBudget}) => {
    const [t] = useTranslation();

    const {startAt, endAt, startUserAt, endUserAt, type, amountUser, amount} = currentBudget;

    const isDefinedByRequester = field.typeBudgetDefinition === TYPE_BUDGET_DEFINITION_REQUESTER;
    const isInputCalendar =
        field.resourceFieldBudgetInformation &&
        field.resourceFieldBudgetInformation.type === TYPE_BUDGET_INPUT_CALENDAR;

    const startDate = isDefinedByRequester ? startUserAt : startAt;
    const endDate = isDefinedByRequester ? endUserAt : endAt;

    const amountUserLabel = getBudgetAmountValue(type, amountUser);
    const amountLabel = getBudgetAmountValue(type, amount);

    const isRequesterDefinition = field.typeBudgetDefinition === TYPE_BUDGET_DEFINITION_REQUESTER;

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    mt: 1,
                    mb: 1,
                }}
            >
                <Typography sx={{fontWeight: 700, mr: 1}}>
                    {`${t(
                        isRequesterDefinition
                            ? 'orguser.resourceFields.askedAmountRequester'
                            : 'orguser.resourceFields.amount',
                    )}:`}
                </Typography>
                <Typography>{isRequesterDefinition ? amountUserLabel : amountLabel}</Typography>
            </Box>
            {isInputCalendar && startDate && endDate && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        mt: 1,
                    }}
                >
                    <Typography sx={{fontWeight: 700, mr: 1}}>
                        {`${t(
                            isRequesterDefinition
                                ? 'orguser.resourceFields.askedDateRange'
                                : 'requester.casePage.dateRange',
                        )}:`}
                    </Typography>
                    <Typography>{`${moment(startDate).format(DATE_FORMAT)} - ${moment(endDate).format(
                        DATE_FORMAT,
                    )}`}</Typography>
                </Box>
            )}
        </Box>
    );
};

export default RequesterCaseHasBudgetAmount;
