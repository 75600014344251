import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Formik, FormikProps} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';

import {AlertContext} from 'contexts/alert/context';

import {ORGANIZATION_ADMIN_RELATE_OTHER_ORGANIZATION} from 'appRedux/actions/organizationAdmin';
import {
    OrganizationAdminListTypes,
    RelateOrganizationAdminOtherOrganizationRequestTypes,
} from 'appRedux/actions/organizationAdmin/types';
import {RootReducer} from 'appRedux/reducers';
import {GET_ROLES_LIST_OTHER_ORGANIZATION} from 'appRedux/actions/roles';
import {RoleTypes} from 'appRedux/actions/roles/types';
import {OrganizationItemBasic} from 'appRedux/actions/organization/types';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationRelateOtherOrganizationSchema} from 'components/Forms/OrganizationAdminForm/validation';

import {theme} from 'config/theme';

interface RelateAdminToOrganizationFormType {
    item: OrganizationAdminListTypes;
    closeModal: () => void;
}

const RelateAdminToOrganizationForm: FC<RelateAdminToOrganizationFormType> = ({item, closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {id} = useParams();

    const {showAlert} = useContext(AlertContext);

    const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
    const [, setIsUpdated] = useState<boolean>(false);

    const {
        admin: {organizationList, rolesOtherOrganization},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onSubmitClicked = useCallback(
        data => dispatch({type: ORGANIZATION_ADMIN_RELATE_OTHER_ORGANIZATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getRolesListOtherOrganization = useCallback(
        data => dispatch({type: GET_ROLES_LIST_OTHER_ORGANIZATION.REQUEST, payload: data}),
        [dispatch],
    );

    const onOrganizationSelected = (event: SelectChangeEvent) => {
        const organizationId = Number(event.target.value);
        getRolesListOtherOrganization({
            id: organizationId,
            showAlert,
            callback: () => {
                setSelectedRoles([]);
                setIsUpdated(previous => !previous);
            },
        });
    };

    const onRoleChecked = (event: SelectChangeEvent) => {
        const roleId = Number(event.target.value);
        if (selectedRoles.includes(roleId)) {
            for (let i = 0, n = selectedRoles.length; i < n; i++) {
                const itemId = selectedRoles[i];
                if (itemId === roleId) {
                    selectedRoles.splice(i, 1);
                    setSelectedRoles(selectedRoles);
                    break;
                }
            }
        } else {
            selectedRoles.push(roleId);
            setSelectedRoles(selectedRoles);
        }
        setIsUpdated(previous => !previous);
    };

    return (
        <Formik
            initialValues={{
                id: item.id,
                user: Number(item.admin.id),
                organization: '',
                roles: selectedRoles,
            }}
            enableReinitialize
            validationSchema={validationRelateOtherOrganizationSchema}
            onSubmit={values => {
                onSubmitClicked({
                    ...values,
                    id: item.id,
                    callback: closeModal,
                    showAlert,
                });
            }}
        >
            {(formik: FormikProps<RelateOrganizationAdminOtherOrganizationRequestTypes>) => {
                return (
                    <Form>
                        <FormControl sx={{mt: 1}} required fullWidth>
                            <InputLabel>{t('superadmin.users.selectOrganization')}</InputLabel>
                            <Select
                                label={t('superadmin.users.selectOrganization')}
                                name={'organization'}
                                value={formik.values.organization}
                                onChange={e => {
                                    formik.handleChange(e);
                                    onOrganizationSelected(e);
                                }}
                                IconComponent={KeyboardArrowDownIcon}
                                fullWidth
                            >
                                {organizationList &&
                                    organizationList.map((organization: OrganizationItemBasic, index: number) => {
                                        if (organization.isActive && organization.id !== Number(id)) {
                                            return (
                                                <MenuItem key={`organization-${index}`} value={organization.id}>
                                                    <Typography sx={{fontWeight: 600}}>{organization.name}</Typography>
                                                </MenuItem>
                                            );
                                        }
                                        return null;
                                    })}
                            </Select>
                        </FormControl>
                        {formik.values.organization && rolesOtherOrganization.length > 0 && (
                            <FormGroup sx={{mt: 3}}>
                                <Typography sx={{fontWeight: 700, mb: 1}}>
                                    {t('superadmin.users.selectRoles')}
                                </Typography>
                                {rolesOtherOrganization &&
                                    rolesOtherOrganization.map((role: RoleTypes) => {
                                        return (
                                            <FormControlLabel
                                                key={`role-checkbox-${role.uuid}`}
                                                control={
                                                    <Checkbox
                                                        value={role.id}
                                                        onChange={onRoleChecked}
                                                        sx={{
                                                            '& svg': {color: theme.palette.info.main},
                                                        }}
                                                    />
                                                }
                                                label={role.title}
                                            />
                                        );
                                    })}
                            </FormGroup>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton isDisabled={selectedRoles.length == 0} isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default RelateAdminToOrganizationForm;
