import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootReducer} from 'appRedux/reducers';
import {ORGANIZATION_LIST} from 'appRedux/actions/organization';

import {AdminContext} from 'contexts/admin/context';
import {RouteContext} from 'contexts/route/context';
import {PermissionContext} from 'contexts/permission/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

import OrganizationWorkflows from 'pages/admin/workflows/OrganizationWorkflows';

import {getLanguageById} from 'helpers/translationsHelper';

const Workflows: FC = () => {
    const dispatch = useDispatch();

    const {myOrganization} = useContext(AdminContext);
    const {setPageTitle, setTopBarTitle, setBackLink, setBackTab, setBackLinkLabel} = useContext(RouteContext);
    const {isOrganizationEditEnabled} = useContext(PermissionContext);

    const {
        admin: {languageList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getOrganizationList = useCallback(() => dispatch({type: ORGANIZATION_LIST.REQUEST}), [dispatch]);

    useEffect(() => {
        setPageTitle('');
        setTopBarTitle('');
        setBackLink('');
        setBackTab('');
        setBackLinkLabel('');
    }, []);

    useEffect(() => {
        getOrganizationList();
    }, []);

    if (myOrganization && isOrganizationEditEnabled) {
        const currentOrganizationDefaultLanguage = getLanguageById(languageList, myOrganization.language);
        return (
            <>
                <ClientSectionWrapper>
                    <OrganizationWorkflows
                        organizationId={myOrganization.id}
                        currentOrganizationLanguage={currentOrganizationDefaultLanguage}
                    />
                </ClientSectionWrapper>
            </>
        );
    }
    return null;
};

export default Workflows;
