import {
    routes,
    userRoles,
    TYPE_MULTI_ROLE_UNDEFINED,
    TYPE_MULTI_ROLE_ORGANIZATION_USER,
    TYPE_MULTI_ROLE_REQUESTER,
} from 'config/index';

export const LOGIN_FIRST_STEP = 'LOGIN_FIRST_STEP';
export const LOGIN_SECOND_STEP = 'LOGIN_SECOND_STEP';

export const getRedirectRoute = (roles: string[], organizationMultiRoleType: number, defaultMode: number) => {
    if (roles.length > 1) {
        if (
            defaultMode === TYPE_MULTI_ROLE_UNDEFINED &&
            organizationMultiRoleType === TYPE_MULTI_ROLE_ORGANIZATION_USER
        ) {
            return routes.SELECT_DEFAULT_ROLE;
        }
        switch (organizationMultiRoleType) {
            case TYPE_MULTI_ROLE_ORGANIZATION_USER:
                if (roles.includes[userRoles.ROLE_AGENT]) {
                    return routes.AGENT;
                }
                return routes.ADMIN;
            case TYPE_MULTI_ROLE_REQUESTER:
                return routes.REQUESTS_LIST;
            default:
                break;
        }
    }

    let redirectRoute = routes.START;
    const role = roles[0];
    switch (role) {
        case userRoles.ROLE_SUPER_ADMIN: {
            redirectRoute = routes.SUPER_ADMIN;
            break;
        }
        case userRoles.ROLE_ADMIN: {
            redirectRoute = routes.ADMIN;
            break;
        }
        case userRoles.ROLE_REQUEST: {
            redirectRoute = routes.REQUESTS_LIST;
            break;
        }
        case userRoles.ROLE_AGENT: {
            redirectRoute = routes.AGENT;
            break;
        }
        default:
            break;
    }
    return redirectRoute;
};
