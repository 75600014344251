import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {ResourceFieldCalendarSlotFormTypes} from 'appRedux/actions/resourceFields/types';
import {CREATE_RESOURCE_FIELD_CALENDAR_SLOT} from 'appRedux/actions/resourceFields';

import {AlertContext} from 'contexts/alert/context';

import ResourceFieldCalendarSlotForm from 'components/Forms/ResourceField/ResourceFieldCalendarSlotForm';

interface CreateResourceFieldCalendarFormType {
    uuid: string;
    closeModal: () => void;
}

const CreateResourceFieldCalendarSlotForm: FC<CreateResourceFieldCalendarFormType> = ({uuid, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const createResourceFieldCalendarSlot = useCallback(
        data => dispatch({type: CREATE_RESOURCE_FIELD_CALENDAR_SLOT.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: ResourceFieldCalendarSlotFormTypes) => {
        createResourceFieldCalendarSlot({
            ...values,
            uuid,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <ResourceFieldCalendarSlotForm
            initialValues={{
                name: '',
                duration: 0,
            }}
            onSubmitClicked={onSubmitClicked}
        />
    );
};

export default CreateResourceFieldCalendarSlotForm;
