import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootReducer} from 'appRedux/reducers';
import {GET_ORGANIZATION_PUBLIC_KEYS} from 'appRedux/actions/crypto';

import {AlertContext} from 'contexts/alert/context';

import DataGridWrapper from 'components/AdminScreenComponents/DataGridWrapper';

import OrganizationPublicKeysDataGrid from 'pages/admin/publicKeys/OrganizationPublicKeysDataGrid';

const OrganizationPublicKeys: FC = () => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const getUserPublicKeys = useCallback(
        data => dispatch({type: GET_ORGANIZATION_PUBLIC_KEYS.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        crypto: {organizationPublicKeys},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        getUserPublicKeys({
            showAlert,
        });
    }, []);

    return (
        <DataGridWrapper
            itemsList={organizationPublicKeys}
            itemsTotalNumber={organizationPublicKeys ? organizationPublicKeys.length : 0}
        >
            {organizationPublicKeys && <OrganizationPublicKeysDataGrid keysList={organizationPublicKeys} />}
        </DataGridWrapper>
    );
};

export default OrganizationPublicKeys;
