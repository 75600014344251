import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {FormFieldTypes, ResourceFieldBudgetInformationType} from 'appRedux/actions/forms/types';
import {RequesterCaseHasBudgetTypes} from 'appRedux/actions/requestCase/types';

import UpdateRequesterCaseBudgetForm from 'components/Forms/RequesterCaseBudgetForm/UpdateRequesterCaseBudgetForm';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import {theme} from 'config/theme';

interface BudgetAmountEditButtonType {
    caseId: number;
    currentBudget: RequesterCaseHasBudgetTypes;
    resourceFieldBudgetInformation: ResourceFieldBudgetInformationType;
    field: FormFieldTypes;
}

const BudgetAmountEditButton: FC<BudgetAmountEditButtonType> = ({
    caseId,
    currentBudget,
    field,
    resourceFieldBudgetInformation,
}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <>
            <IconButton
                sx={{
                    ml: 2,
                    backgroundColor: theme.palette.info.main,
                    '&:hover': {
                        backgroundColor: theme.palette.info.main,
                    },
                }}
                onClick={() => setIsOpened(true)}
                title={t('orguser.resourceFields.updateAmount')}
            >
                <EditSvgIcon isActive />
            </IconButton>
            <ModalWrapper
                title={t('orguser.resourceFields.updateAmount')}
                isShowModal={isOpened}
                toggleModal={toggleModal}
            >
                <UpdateRequesterCaseBudgetForm
                    caseId={caseId}
                    currentBudget={currentBudget}
                    field={field}
                    resourceFieldBudgetInformation={resourceFieldBudgetInformation}
                    closeModal={() => setIsOpened(false)}
                />
            </ModalWrapper>
        </>
    );
};

export default BudgetAmountEditButton;
