import {FormFieldTypes, FormPageTypes, FormPopupTypes, FormSectionTypes} from 'appRedux/actions/forms/types';
import {FormHasLanguageTypes} from 'appRedux/actions/forms/types';

export const CONTENT_FIELD = 'field';
export const CONTENT_POPUP = 'popup';
export const CONTENT_SECTION = 'section';

export const DROP_PLACEHOLDDER_HEIGHT = 35;

export const OPTIONS_SEPARATOR = ';';
export const RELATED_SECTIONS_SEPARATOR = ';';
export const DATE_SEPARATOR = '&';

export const FORM_FIELD_TEXT_INPUT = 0;
export const FORM_FIELD_TEXTAREA = 1;
export const FORM_FIELD_DATEPICKER = 2;
export const FORM_FIELD_DROPDOWN = 3;
export const FORM_FIELD_CHECKBOXES = 4;
export const FORM_FIELD_RADIOBUTTONS = 5;
export const FORM_FIELD_UPLOADER = 6;
export const FORM_FIELD_DATE_RANGE = 7;
export const FORM_FIELD_RANGE = 8;
export const FORM_FIELD_RATING = 9;
export const FORM_FIELD_SIGNATURE = 10;
export const FORM_FIELD_IMAGE_CHECK = 11;
export const FORM_FIELD_IMAGE_RADIO = 12;
export const FORM_FIELD_RESOURCE_FIELD = 13;

export const VALIDATION_TYPE_NONE = 0;
export const VALIDATION_TYPE_NOT_EMPTY = 1;
export const VALIDATION_TYPE_EMAIL = 2;
export const VALIDATION_TYPE_PHONE = 3;
export const VALIDATION_TYPE_URL = 4;
export const VALIDATION_TYPE_NUMBER = 5;
export const VALIDATION_TYPE_IBAN = 6;
export const VALIDATION_TYPE_BIC = 7;
export const VALIDATION_TYPE_DECIMAL = 8;
export const VALIDATION_TYPE_TIME = 9;

export const TEXTAREA_DEFAULT_LENGTH = 160;

export const formTypes = {
    [FORM_FIELD_TEXT_INPUT]: 'orguser.forms.fieldType.textField',
    [FORM_FIELD_TEXTAREA]: 'orguser.forms.fieldType.textArea',
    [FORM_FIELD_DATEPICKER]: 'orguser.forms.fieldType.datepicker',
    [FORM_FIELD_DROPDOWN]: 'orguser.forms.fieldType.dropdown',
    [FORM_FIELD_CHECKBOXES]: 'orguser.forms.fieldType.checkboxes',
    [FORM_FIELD_RADIOBUTTONS]: 'orguser.forms.fieldType.radioButtons',
    [FORM_FIELD_UPLOADER]: 'orguser.forms.fieldType.fileUploader',
    [FORM_FIELD_DATE_RANGE]: 'orguser.forms.fieldType.dateRange',
    [FORM_FIELD_RANGE]: 'orguser.forms.fieldType.range',
    [FORM_FIELD_RATING]: 'orguser.forms.fieldType.rating',
    [FORM_FIELD_SIGNATURE]: 'orguser.forms.fieldType.signature',
    [FORM_FIELD_IMAGE_CHECK]: 'orguser.forms.fieldType.imageCheck',
    [FORM_FIELD_IMAGE_RADIO]: 'orguser.forms.fieldType.imageRadio',
    [FORM_FIELD_RESOURCE_FIELD]: 'orguser.forms.fieldType.resourceField',
};

export const formValidationTypes = {
    [VALIDATION_TYPE_NONE]: 'orguser.forms.validation.validationNone',
    [VALIDATION_TYPE_NOT_EMPTY]: 'orguser.forms.validation.validationNotEmpty',
    [VALIDATION_TYPE_EMAIL]: 'orguser.forms.validation.validationEmail',
    [VALIDATION_TYPE_PHONE]: 'orguser.forms.validation.validationPhone',
    [VALIDATION_TYPE_URL]: 'orguser.forms.validation.validationUrl',
    [VALIDATION_TYPE_NUMBER]: 'orguser.forms.validation.validationNumber',
    [VALIDATION_TYPE_IBAN]: 'orguser.forms.validation.validationIban',
    [VALIDATION_TYPE_BIC]: 'orguser.forms.validation.validationBic',
    [VALIDATION_TYPE_DECIMAL]: 'orguser.forms.validation.validationDecimal',
    [VALIDATION_TYPE_TIME]: 'orguser.forms.validation.validationTime',
};

export const allowedCardFields = [
    FORM_FIELD_TEXT_INPUT,
    FORM_FIELD_DATEPICKER,
    FORM_FIELD_DROPDOWN,
    FORM_FIELD_CHECKBOXES,
    FORM_FIELD_RADIOBUTTONS,
    FORM_FIELD_IMAGE_CHECK,
    FORM_FIELD_IMAGE_RADIO,
];

export type SectionContentType = {
    index: number;
    priority: number;
    type: string;
};

export const sortByPriority = (reviews: SectionContentType[]) => {
    const sorter = (prev: SectionContentType, next: SectionContentType) => {
        return Number(prev.priority) - Number(next.priority);
    };
    return reviews.sort(sorter);
};

export const getSectionContent = (fields: FormFieldTypes[], popups: FormPopupTypes[]) => {
    const result: SectionContentType[] = [];
    fields.forEach((item: FormFieldTypes, index: number) => {
        result.push({
            index: index,
            priority: item.priority,
            type: CONTENT_FIELD,
        });
    });
    popups.forEach((item: FormPopupTypes, index: number) => {
        result.push({
            index: index,
            priority: item.priority,
            type: CONTENT_POPUP,
        });
    });

    return sortByPriority(result);
};

export const getFieldTitle = (item: FormFieldTypes) => {
    switch (item.type) {
        case FORM_FIELD_TEXT_INPUT:
            return 'orguser.forms.fieldType.textField';
        case FORM_FIELD_TEXTAREA:
            return 'orguser.forms.fieldType.textArea';
        case FORM_FIELD_DATEPICKER:
            return 'orguser.forms.fieldType.datepicker';
        case FORM_FIELD_DROPDOWN:
            return 'orguser.forms.fieldType.dropdown';
        case FORM_FIELD_CHECKBOXES:
            return 'orguser.forms.fieldType.checkboxes';
        case FORM_FIELD_RADIOBUTTONS:
            return 'orguser.forms.fieldType.radioButtons';
        case FORM_FIELD_UPLOADER:
            return 'orguser.forms.fieldType.fileUploader';
        case FORM_FIELD_DATE_RANGE:
            return 'orguser.forms.fieldType.dateRange';
        case FORM_FIELD_RANGE:
            return 'orguser.forms.fieldType.range';
        case FORM_FIELD_RATING:
            return 'orguser.forms.fieldType.rating';
        case FORM_FIELD_SIGNATURE:
            return 'orguser.forms.fieldType.signature';
        case FORM_FIELD_IMAGE_CHECK:
            return 'orguser.forms.fieldType.imageCheck';
        case FORM_FIELD_IMAGE_RADIO:
            return 'orguser.forms.fieldType.imageRadio';
        case FORM_FIELD_RESOURCE_FIELD:
            return 'orguser.forms.fieldType.resourceField';
    }
    return '';
};

export const getSectionById = (pages: FormPageTypes[], sectionId: number) => {
    for (let i = 0, n = pages.length; i < n; i++) {
        const sections = pages[i].sections;
        const currentSection = sections.find(section => section.id === sectionId);
        if (currentSection) {
            const {title, description, tooltip, isRequired} = currentSection;
            return {
                title,
                description,
                tooltip,
                isRequired,
            };
        }
    }
    return {};
};

export const getFieldById = (pages: FormPageTypes[], fieldId: number): FormFieldTypes | null => {
    for (let i = 0, n = pages.length; i < n; i++) {
        const sections = pages[i].sections;
        for (let j = 0, l = sections.length; j < l; j++) {
            const fields = sections[j].fields;
            const currentField = fields.find(field => field.id === fieldId);
            if (currentField) {
                return currentField;
            }
        }
    }
    return null;
};

export const getPopupById = (pages: FormPageTypes[], fieldId: number) => {
    for (let i = 0, n = pages.length; i < n; i++) {
        const sections = pages[i].sections;
        for (let j = 0, l = sections.length; j < l; j++) {
            const popups = sections[j].popups;
            const currentField = popups.find(popup => popup.id === fieldId);
            if (currentField) {
                const {title, description, tooltip, isRequired} = currentField;
                return {
                    title,
                    description,
                    tooltip,
                    isRequired,
                };
            }
        }
    }
    return {};
};

export const getPopupFieldById = (pages: FormPageTypes[], fieldId: number): FormFieldTypes | null => {
    for (let i = 0, n = pages.length; i < n; i++) {
        const sections = pages[i].sections;
        for (let j = 0, l = sections.length; j < l; j++) {
            const popups = sections[j].popups;
            for (let y = 0, m = popups.length; y < m; y++) {
                const fields = popups[y].fields;
                const currentField = fields.find(field => field.id === fieldId);
                if (currentField) {
                    return currentField;
                }
            }
        }
    }
    return null;
};

export const isSelectionFields = (type: number) => {
    return (
        [
            FORM_FIELD_RADIOBUTTONS,
            FORM_FIELD_CHECKBOXES,
            FORM_FIELD_DROPDOWN,
            FORM_FIELD_IMAGE_CHECK,
            FORM_FIELD_IMAGE_RADIO,
        ].indexOf(type) !== -1
    );
};

export const getPlaceholdersCountForSection = (section: FormSectionTypes) => {
    return (
        1 +
        section.fields.length +
        section.popups.length +
        (section.popups?.reduce((acc, item) => acc + item.fields.length, 0) || 0)
    );
};

export const getPlaceholdersCountForPage = (sections: FormSectionTypes[]) => {
    return sections.reduce((acc, section) => acc + getPlaceholdersCountForSection(section), 0) - 1;
};

export const getLanguageTabLabels = (items: FormHasLanguageTypes[], organizationLanguageId: number): string[] => {
    const result = ['preview'];
    items.forEach(item => {
        if (organizationLanguageId !== item.language.id) {
            result.push(item.language.name);
        }
    });
    return result;
};
