import React, {FC} from 'react';

import TripOriginIcon from '@mui/icons-material/TripOrigin';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface CurrentStatusLabelType {
    title: string;
    color: string;
}

const CurrentStatusLabel: FC<CurrentStatusLabelType> = ({title, color}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
        >
            <TripOriginIcon sx={{color: color}} />
            <Typography sx={{fontWeight: 600, ml: 1, mr: 1}}>{title}</Typography>
        </Box>
    );
};

export default CurrentStatusLabel;
