import React, {FC} from 'react';

interface MenuIconType {
    isActive: boolean;
}

const CaseFiltersSvgIcon: FC<MenuIconType> = ({isActive}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.1875 5H18.75V6.25H16.1875C15.875 7.6875 14.625 8.75 13.125 8.75C11.625 8.75 10.375 7.6875 10.0625 6.25H1.25V5H10.0625C10.375 3.5625 11.625 2.5 13.125 2.5C14.625 2.5 15.875 3.5625 16.1875 5ZM11.25 5.625C11.25 6.6875 12.0625 7.5 13.125 7.5C14.1875 7.5 15 6.6875 15 5.625C15 4.5625 14.1875 3.75 13.125 3.75C12.0625 3.75 11.25 4.5625 11.25 5.625ZM3.8125 15H1.25V13.75H3.8125C4.125 12.3125 5.375 11.25 6.875 11.25C8.375 11.25 9.625 12.3125 9.9375 13.75H18.75V15H9.9375C9.625 16.4375 8.375 17.5 6.875 17.5C5.375 17.5 4.125 16.4375 3.8125 15ZM8.75 14.375C8.75 13.3125 7.9375 12.5 6.875 12.5C5.8125 12.5 5 13.3125 5 14.375C5 15.4375 5.8125 16.25 6.875 16.25C7.9375 16.25 8.75 15.4375 8.75 14.375Z"
                fill={isActive ? 'white' : '#222222'}
            />
        </svg>
    );
};

export default CaseFiltersSvgIcon;
