import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RefreshIcon from '@mui/icons-material/Refresh';

import CopySvgIcon from 'assets/icons/buttons/CopySvgIcon';
import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';
import RedirectSvgIcon from 'assets/icons/buttons/RedirectSvgIcon';

import DeleteAdminConfirmationButton from 'components/AdminScreenComponents/DeleteAdminConfirmationButton';

import {theme} from 'config/theme';

interface ButtonsPanelType {
    isFirst?: boolean;
    isLast?: boolean;
    isCopyDisabled?: boolean;
    onEditClick: () => void;
    onCopyClick: () => void;
    onReplaceClick?: () => void;
    onDeleteClick: () => void;
    onUpClick?: () => void;
    onDownClick?: () => void;
    onRecalculateClick?: () => void;
    viewOnly?: boolean;
    showReplaceButton?: boolean;
}

const ButtonsPanel: FC<ButtonsPanelType> = ({
    isFirst,
    onUpClick,
    onDownClick,
    isLast,
    isCopyDisabled,
    onEditClick,
    onCopyClick,
    onReplaceClick,
    onDeleteClick,
    onRecalculateClick,
    viewOnly = false,
    showReplaceButton = false,
}) => {
    const [t] = useTranslation();

    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    const onClickAction = () => {
        onDeleteClick();
        toggleModal();
    };

    if (viewOnly) return null;

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
            <IconButton onClick={onEditClick} title={t('common.buttons.update')}>
                <EditSvgIcon />
            </IconButton>
            {onRecalculateClick && (
                <IconButton onClick={onRecalculateClick} title={t('common.buttons.refresh')}>
                    <RefreshIcon fontSize="small" />
                </IconButton>
            )}
            <DeleteAdminConfirmationButton
                disabled={false}
                isShowModal={isShowModal}
                toggleModal={toggleModal}
                onClickAction={onClickAction}
            />
            {!isCopyDisabled && (
                <IconButton onClick={onCopyClick} title={t('common.buttons.copy')}>
                    <CopySvgIcon />
                </IconButton>
            )}
            {showReplaceButton && onReplaceClick && (
                <IconButton onClick={onReplaceClick} title={t('common.buttons.move')}>
                    <RedirectSvgIcon />
                </IconButton>
            )}
            {!isFirst && (
                <IconButton onClick={onUpClick} title={t('common.buttons.up')}>
                    <KeyboardArrowUpIcon fontSize="small" sx={{color: theme.palette.info.contrastText}} />
                </IconButton>
            )}
            {!isLast && (
                <IconButton onClick={onDownClick} title={t('common.buttons.down')}>
                    <KeyboardArrowDownIcon fontSize="small" sx={{color: theme.palette.info.contrastText}} />
                </IconButton>
            )}
        </Box>
    );
};

export default ButtonsPanel;
