import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import InformationWrapper from 'pages/agent/requesterPage/wrappers/InformationWrapper';
import CurrentBudgetInformation from 'pages/agent/requesterPage/resourceFields/CurrentBudgetInformation';

import {getFormTranslatedLabel} from 'helpers/translationsHelper';

interface RequesterCaseHasBudgetInformationType {
    field: FormFieldTypes;
    keywordTitle: string;
    keywordDescription: string;
}

const RequesterCaseHasBudgetInformation: FC<RequesterCaseHasBudgetInformationType> = ({
    field,
    keywordDescription,
    keywordTitle,
}) => {
    const [t] = useTranslation();

    const {
        admin: {
            formInfo: {translations},
        },
        requestCase: {currentCaseBudgets},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const currentBudget = currentCaseBudgets ? currentCaseBudgets.find(budget => budget.formField === field.id) : null;

    return (
        <InformationWrapper
            title={getFormTranslatedLabel(translations, keywordTitle, field.label)}
            description={getFormTranslatedLabel(translations, keywordDescription, field.description)}
        >
            {currentBudget ? (
                <CurrentBudgetInformation field={field} currentBudget={currentBudget} />
            ) : (
                <Typography>{t('common.none')}</Typography>
            )}
        </InformationWrapper>
    );
};

export default RequesterCaseHasBudgetInformation;
