import React, {FC} from 'react';

interface MenuIconType {
    isActive?: boolean;
}

const MessagesSvgIcon: FC<MenuIconType> = ({isActive}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.0875 18.75L10 18.125L12.5 13.75H16.25C16.5815 13.75 16.8995 13.6183 17.1339 13.3839C17.3683 13.1495 17.5 12.8315 17.5 12.5V5C17.5 4.66848 17.3683 4.35054 17.1339 4.11612C16.8995 3.8817 16.5815 3.75 16.25 3.75H3.75C3.41848 3.75 3.10054 3.8817 2.86612 4.11612C2.6317 4.35054 2.5 4.66848 2.5 5V12.5C2.5 12.8315 2.6317 13.1495 2.86612 13.3839C3.10054 13.6183 3.41848 13.75 3.75 13.75H9.375V15H3.75C3.08696 15 2.45107 14.7366 1.98223 14.2678C1.51339 13.7989 1.25 13.163 1.25 12.5V5C1.25 4.33696 1.51339 3.70107 1.98223 3.23223C2.45107 2.76339 3.08696 2.5 3.75 2.5H16.25C16.913 2.5 17.5489 2.76339 18.0178 3.23223C18.4866 3.70107 18.75 4.33696 18.75 5V12.5C18.75 13.163 18.4866 13.7989 18.0178 14.2678C17.5489 14.7366 16.913 15 16.25 15H13.225L11.0875 18.75ZM15 6.25H5V7.5H15V6.25ZM5 10H11.25V11.25H5V10Z"
                fill={isActive ? 'white' : '#222222'}
            />
        </svg>
    );
};

export default MessagesSvgIcon;
