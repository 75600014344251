import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {ORGANIZATION_PDF_TEMPLATES_LIST} from 'appRedux/actions/organization';

import {AdminContext} from 'contexts/admin/context';
import {PermissionContext} from 'contexts/permission/context';
import {AlertContext} from 'contexts/alert/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

import OrganizationPdfTemplates from 'pages/admin/organizationPdfTemplates/OrganizationPdfTemplates';

const PdfTemplates: FC = () => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {myOrganization} = useContext(AdminContext);
    const {isOrganizationViewEnabled} = useContext(PermissionContext);

    const getOrganizationPdfTemplatesList = useCallback(
        data => dispatch({type: ORGANIZATION_PDF_TEMPLATES_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        if (myOrganization) {
            getOrganizationPdfTemplatesList({
                id: myOrganization.id,
                showAlert,
            });
        }
    }, [myOrganization]);

    if (!myOrganization) return null;

    if (myOrganization && isOrganizationViewEnabled) {
        return (
            <>
                <ClientSectionWrapper>
                    <OrganizationPdfTemplates />
                </ClientSectionWrapper>
            </>
        );
    }

    return null;
};

export default PdfTemplates;
