import {fork, put, take, call} from 'redux-saga/effects';

import {
    WorkflowListRequestTypes,
    WorkflowStatusListRequestTypes,
    WorkflowStatusEventsListRequestTypes,
    WorkflowResponseTypes,
    WorkflowStatusResponseTypes,
    WorkflowStatusEventResponseTypes,
    WorkflowStatusCurrentRequestTypes,
    WorkflowStatusCurrentResponseTypes,
    CreateWorkflowRequestTypes,
    CloneWorkflowRequestTypes,
    CreateWorkflowStatusRequestTypes,
    CreateWorkflowStatusEventRequestTypes,
    UpdateWorkflowRequestTypes,
    UpdateWorkflowStatusRequestTypes,
    UpdateWorkflowStatusInternalNameRequestTypes,
    DeleteWorkflowRequestTypes,
    DeleteWorkflowStatusRequestTypes,
    ChangePriorityWorkflowStatusRequestTypes,
    UpdateWorkflowStatusPermissionsRequestTypes,
    DeleteWorkflowStatusEventRequestTypes,
    ToggleActivityWorkflowStatusEventRequestTypes,
    BoardsListRequestTypes,
    CreateBoardRequestTypes,
    CreateBoardListRequestTypes,
    CreateBoardOverviewRequestTypes,
    UpdateBoardRequestTypes,
    UpdateBoardListRequestTypes,
    DeleteBoardRequestTypes,
    ToggleBoardRequestTypes,
    BoardsListResponseTypes,
    CreateWorkflowTemplateRequestTypes,
    UpdateWorkflowTemplateRequestTypes,
    CreateWorkflowStatusTranslationRequestTypes,
    UpdateWorkflowStatusTranslationRequestTypes,
    UploadWorkflowAttachmentTypes,
    DeleteWorkflowAttachmentTypes,
    DownloadWorkflowAttachmentRequestTypes,
    WorkflowAttachmentDownloadResponseType,
    CreateWorkflowStatusRuleRequestTypes,
    ToggleWorkflowStatusRuleRequestTypes,
    RemoveWorkflowStatusRuleRequestTypes,
    AttachWorkflowStatusRuleApproverRequestTypes,
    RemoveWorkflowStatusRuleApproverRequestTypes,
    AttachWorkflowStatusRuleApproversGroupRequestTypes,
    RemoveWorkflowStatusRuleApproversGroupRequestTypes,
    WorkflowGraphRequestTypes,
    WorkflowGraphResponseTypes,
    WorkflowEventAttachAgentRequestTypes,
    WorkflowEventDeleteAgentRequestTypes,
    WorkflowEventAttachGroupRequestTypes,
    WorkflowEventDeleteGroupRequestTypes,
    CreateBoardHasStatusRequestTypes,
    UpdateBoardOverviewRequestTypes,
    CreateWorkflowStatusTaskRequestTypes,
    UpdateWorkflowStatusRuleRequestTypes,
    ActivateWorkflowStatusTaskRequestTypes,
    DeleteWorkflowStatusTaskRequestTypes,
    CreateWorkflowTaskEmailTemplateRequestTypes,
    UpdateWorkflowTaskEmailTemplateRequestTypes,
    WorkflowStatusEmailTemplateAgentAddingRequestType,
    WorkflowStatusEmailTemplateAgentRemovingRequestType,
    CreateSwimlaneBoardRequestTypes,
} from 'appRedux/actions/workflow/types';
import {
    CREATE_WORKFLOW,
    createWorkflow,
    DELETE_WORKFLOW,
    deleteWorkflow,
    CLONE_WORKFLOW,
    cloneWorkflow,
    GET_WORKFLOWS_LIST,
    getWorkflowLists,
    UPDATE_WORKFLOW,
    updateWorkflow,
    CREATE_WORKFLOW_STATUS,
    createWorkflowStatus,
    GET_WORKFLOW_GRAPH,
    getWorkflowGraph,
    GET_WORKFLOWS_STATUS_LIST,
    getWorkflowStatusesLists,
    UPDATE_WORKFLOW_STATUS,
    updateWorkflowStatus,
    UPDATE_WORKFLOW_STATUS_INTERNAL_NAME,
    updateWorkflowStatusInternalName,
    DELETE_WORKFLOW_STATUS,
    deleteWorkflowStatus,
    GET_WORKFLOWS_STATUS_EVENTS_LIST,
    getWorkflowStatusEventsLists,
    CREATE_WORKFLOW_STATUS_EVENTS,
    createWorkflowStatusEvent,
    DELETE_WORKFLOW_STATUS_EVENTS,
    deleteWorkflowStatusEvent,
    TOGGLE_ACTIVITY_WORKFLOW_STATUS_EVENTS,
    toggleActivityWorkflowStatusEvent,
    GET_CURRENT_WORKFLOW_STATUS,
    getCurrentWorkflowStatus,
    GET_BOARDS_LIST,
    getBoardsLists,
    CREATE_BOARD,
    createBoard,
    CREATE_BOARD_LIST,
    createBoardList,
    CREATE_BOARD_OVERVIEW,
    createBoardOverview,
    UPDATE_BOARD,
    updateBoard,
    UPDATE_BOARD_LIST_STATUS,
    updateBoardListStatus,
    DELETE_BOARD,
    deleteBoard,
    TOGGLE_BOARD_STATUS,
    toggleBoardStatus,
    CREATE_BOARD_HAS_STATUS,
    createBoardHasStatus,
    TOGGLE_BOARD_HAS_STATUS,
    toggleBoardHasStatus,
    CHANGE_WORKFLOW_STATUS_PRIORITY,
    changeWorkflowStatusPriority,
    changeBoardPriority,
    CHANGE_BOARD_PRIORITY,
    CREATE_WORKFLOW_TEMPLATE,
    createWorkflowTemplate,
    UPDATE_WORKFLOW_TEMPLATE,
    updateWorkflowTemplate,
    CREATE_WORKFLOW_STATUS_TRANSLATION,
    createWorkflowStatusTranslation,
    UPDATE_WORKFLOW_STATUS_TRANSLATION,
    updateWorkflowStatusTranslation,
    UPDATE_WORKFLOW_STATUS_PERMISSIONS,
    updateWorkflowStatusPermissions,
    UPLOAD_WORKFLOW_ATTACHMENT,
    uploadWorkflowAttachment,
    DELETE_WORKFLOW_ATTACHMENT,
    deleteWorkflowAttachment,
    DOWNLOAD_WORKFLOW_ATTACHMENT,
    downloadWorkflowAttachment,
    CREATE_WORKFLOW_STATUS_RULE,
    createWorkflowStatusRule,
    UPDATE_WORKFLOW_STATUS_RULE,
    updateWorkflowStatusRule,
    TOGGLE_WORKFLOW_STATUS_RULE,
    toggleWorkflowStatusRule,
    DELETE_WORKFLOW_STATUS_RULE,
    deleteWorkflowStatusRule,
    ATTACH_WORKFLOW_RULE_APPROVER,
    attachWorkflowStatusRuleApprover,
    DELETE_WORKFLOW_RULE_APPROVER,
    deleteWorkflowStatusRuleApprover,
    ATTACH_WORKFLOW_RULE_APPROVERS_GROUP,
    attachWorkflowStatusRuleApproversGroup,
    DELETE_WORKFLOW_RULE_APPROVERS_GROUP,
    deleteWorkflowStatusRuleApproversGroup,
    ATTACH_WORKFLOW_EVENT_AGENT,
    attachWorkflowEventAgent,
    DELETE_WORKFLOW_EVENT_AGENT,
    deleteWorkflowEventAgent,
    ATTACH_WORKFLOW_EVENT_GROUP,
    attachWorkflowEventGroup,
    DELETE_WORKFLOW_EVENT_GROUP,
    deleteWorkflowEventGroup,
    UPDATE_BOARD_OVERVIEW,
    updateBoardOverview,
    CREATE_WORKFLOW_STATUS_TASK,
    createWorkflowStatusTask,
    UPDATE_WORKFLOW_STATUS_TASK,
    updateWorkflowStatusTask,
    ACTIVATE_WORKFLOW_STATUS_TASK,
    activateWorkflowStatusTask,
    DELETE_WORKFLOW_STATUS_TASK,
    deleteWorkflowStatusTask,
    CREATE_WORKFLOW_TASK_EMAIL_TEMPLATE,
    createWorkflowTaskEmailTemplate,
    UPDATE_WORKFLOW_TASK_EMAIL_TEMPLATE,
    updateWorkflowTaskEmailTemplate,
    ADD_WORKFLOW_TASK_EMAIL_TEMPLATE_AGENT,
    addWorkflowStatusEmailTemplateAgent,
    REMOVE_WORKFLOW_TASK_EMAIL_TEMPLATE_AGENT,
    removeWorkflowStatusEmailTemplateAgent,
    CREATE_BOARD_SWIMLANE,
    createBoardSwimlane,
    updateBoardSwimlane,
    UPDATE_BOARD_SWIMLANE,
    getFavoriteBoardsLists,
    GET_FAVORITE_BOARDS_LIST,
    CHANGE_FAVORITE_BOARD_PRIORITY,
    changeFavoriteBoardPriority,
    TOGGLE_BOARD_FAVORITE,
    toggleBoardFavorite,
} from 'appRedux/actions/workflow';

import {http} from 'services/http';

import {getStatusTranslationErrorMessage} from 'helpers/sagasHelper';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS, PARAMETER_LANGUAGE} from 'config/index';

function* watchGetWorkflowsList() {
    while (true) {
        const {
            payload: {organizationId, showAlert},
        }: IActionType<WorkflowListRequestTypes> = yield take(GET_WORKFLOWS_LIST.REQUEST);
        try {
            const data: WorkflowResponseTypes = yield call(http, `organization/${organizationId}/workflows`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getWorkflowLists.success(data.results));
            } else {
                yield put(getWorkflowLists.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getWorkflowLists.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowCreation() {
    while (true) {
        const {
            payload: {showAlert, organizationId, callback, setErrors, ...values},
        }: IActionType<CreateWorkflowRequestTypes> = yield take(CREATE_WORKFLOW.REQUEST);
        try {
            const data: WorkflowResponseTypes = yield call(http, `workflow/${organizationId}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createWorkflow.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createWorkflow.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
                setErrors && setErrors(data.errors);
            }
        } catch (e) {
            yield put(createWorkflow.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowUpdating() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, setErrors, ...values},
        }: IActionType<UpdateWorkflowRequestTypes> = yield take(UPDATE_WORKFLOW.REQUEST);
        try {
            const data: WorkflowResponseTypes = yield call(http, `workflow/${uuid}/edit`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateWorkflow.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateWorkflow.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
                setErrors && setErrors(data.errors);
            }
        } catch (e) {
            yield put(updateWorkflow.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowRemoving() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback},
        }: IActionType<DeleteWorkflowRequestTypes> = yield take(DELETE_WORKFLOW.REQUEST);
        try {
            const data: WorkflowResponseTypes = yield call(http, `workflow/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteWorkflow.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteWorkflow.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteWorkflow.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowCloning() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback},
        }: IActionType<CloneWorkflowRequestTypes> = yield take(CLONE_WORKFLOW.REQUEST);
        try {
            const data: WorkflowResponseTypes = yield call(http, `workflow/${uuid}/clone`, {
                method: 'POST',
            });
            if (data.success) {
                yield put(cloneWorkflow.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(cloneWorkflow.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(cloneWorkflow.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchGetWorkflowGraph() {
    while (true) {
        const {
            payload: {uuid, callback, lang, showAlert},
        }: IActionType<WorkflowGraphRequestTypes> = yield take(GET_WORKFLOW_GRAPH.REQUEST);
        try {
            const data: WorkflowGraphResponseTypes = yield call(
                http,
                `workflow/${uuid}/graph?${PARAMETER_LANGUAGE}=${lang}`,
                {
                    method: 'GET',
                },
            );
            if (data.success) {
                yield put(getWorkflowGraph.success(data.result));
                callback && callback(data.result);
            } else {
                yield put(getWorkflowGraph.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getWorkflowGraph.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchGetWorkflowStatusesList() {
    while (true) {
        const {
            payload: {uuid, showAlert},
        }: IActionType<WorkflowStatusListRequestTypes> = yield take(GET_WORKFLOWS_STATUS_LIST.REQUEST);
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/${uuid}/statuses`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getWorkflowStatusesLists.success(data.results));
            } else {
                yield put(getWorkflowStatusesLists.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getWorkflowStatusesLists.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchGetCurrentWorkflowStatus() {
    while (true) {
        const {
            payload: {uuid, showAlert},
        }: IActionType<WorkflowStatusCurrentRequestTypes> = yield take(GET_CURRENT_WORKFLOW_STATUS.REQUEST);
        try {
            const data: WorkflowStatusCurrentResponseTypes = yield call(http, `workflow/status/${uuid}/current`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getCurrentWorkflowStatus.success(data.result));
            } else {
                yield put(getCurrentWorkflowStatus.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getCurrentWorkflowStatus.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusCreation() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, setErrors, ...values},
        }: IActionType<CreateWorkflowStatusRequestTypes> = yield take(CREATE_WORKFLOW_STATUS.REQUEST);
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/status/${uuid}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createWorkflowStatus.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createWorkflowStatus.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
                setErrors && setErrors(data.errors);
            }
        } catch (e) {
            yield put(createWorkflowStatus.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusUpdating() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, ...values},
        }: IActionType<UpdateWorkflowStatusRequestTypes> = yield take(UPDATE_WORKFLOW_STATUS.REQUEST);
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/status/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateWorkflowStatus.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateWorkflowStatus.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateWorkflowStatus.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusInternalNameUpdating() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, setErrors, ...values},
        }: IActionType<UpdateWorkflowStatusInternalNameRequestTypes> = yield take(
            UPDATE_WORKFLOW_STATUS_INTERNAL_NAME.REQUEST,
        );
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/status/${uuid}/internal`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateWorkflowStatusInternalName.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                setErrors && setErrors(data.errors);
                yield put(updateWorkflowStatusInternalName.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateWorkflowStatusInternalName.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusRemoving() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback},
        }: IActionType<DeleteWorkflowStatusRequestTypes> = yield take(DELETE_WORKFLOW_STATUS.REQUEST);
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/status/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteWorkflowStatus.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteWorkflowStatus.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(deleteWorkflowStatus.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusChangePriority() {
    while (true) {
        const {
            payload: {showAlert, uuid, priority, callback},
        }: IActionType<ChangePriorityWorkflowStatusRequestTypes> = yield take(CHANGE_WORKFLOW_STATUS_PRIORITY.REQUEST);
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/status/${uuid}/priority/${priority}`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(changeWorkflowStatusPriority.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(changeWorkflowStatusPriority.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(changeWorkflowStatusPriority.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusRuleCreation() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, ...values},
        }: IActionType<CreateWorkflowStatusRuleRequestTypes> = yield take(CREATE_WORKFLOW_STATUS_RULE.REQUEST);
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/rule/${uuid}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createWorkflowStatusRule.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createWorkflowStatusRule.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createWorkflowStatusRule.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusRuleUpdating() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, ...values},
        }: IActionType<CreateWorkflowStatusRuleRequestTypes> = yield take(UPDATE_WORKFLOW_STATUS_RULE.REQUEST);
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/rule/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateWorkflowStatusRule.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateWorkflowStatusRule.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(updateWorkflowStatusRule.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusRuleActivation() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback},
        }: IActionType<ToggleWorkflowStatusRuleRequestTypes> = yield take(TOGGLE_WORKFLOW_STATUS_RULE.REQUEST);
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/rule/${uuid}/activate`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(toggleWorkflowStatusRule.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(toggleWorkflowStatusRule.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(toggleWorkflowStatusRule.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusRuleRemoving() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback},
        }: IActionType<RemoveWorkflowStatusRuleRequestTypes> = yield take(DELETE_WORKFLOW_STATUS_RULE.REQUEST);
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/rule/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteWorkflowStatusRule.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteWorkflowStatusRule.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteWorkflowStatusRule.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusTaskCreation() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, ...values},
        }: IActionType<CreateWorkflowStatusTaskRequestTypes> = yield take(CREATE_WORKFLOW_STATUS_TASK.REQUEST);
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/task/${uuid}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createWorkflowStatusTask.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createWorkflowStatusTask.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createWorkflowStatusTask.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusTaskUpdating() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, ...values},
        }: IActionType<UpdateWorkflowStatusRuleRequestTypes> = yield take(UPDATE_WORKFLOW_STATUS_TASK.REQUEST);
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/task/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateWorkflowStatusTask.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateWorkflowStatusTask.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(updateWorkflowStatusTask.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusTaskActivation() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback},
        }: IActionType<ActivateWorkflowStatusTaskRequestTypes> = yield take(ACTIVATE_WORKFLOW_STATUS_TASK.REQUEST);
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/task/${uuid}/activate`, {
                method: 'POST',
            });
            if (data.success) {
                yield put(activateWorkflowStatusTask.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(activateWorkflowStatusTask.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(activateWorkflowStatusTask.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusTaskRemoving() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback},
        }: IActionType<DeleteWorkflowStatusTaskRequestTypes> = yield take(DELETE_WORKFLOW_STATUS_TASK.REQUEST);
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/task/${uuid}/remove`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteWorkflowStatusTask.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteWorkflowStatusTask.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteWorkflowStatusTask.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowTaskEmailTemplateCreation() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, ...values},
        }: IActionType<CreateWorkflowTaskEmailTemplateRequestTypes> = yield take(
            CREATE_WORKFLOW_TASK_EMAIL_TEMPLATE.REQUEST,
        );
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/template/${uuid}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createWorkflowTaskEmailTemplate.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createWorkflowTaskEmailTemplate.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createWorkflowTaskEmailTemplate.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowTaskEmailTemplateUpdating() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, ...values},
        }: IActionType<UpdateWorkflowTaskEmailTemplateRequestTypes> = yield take(
            UPDATE_WORKFLOW_TASK_EMAIL_TEMPLATE.REQUEST,
        );
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/template/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateWorkflowTaskEmailTemplate.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateWorkflowTaskEmailTemplate.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateWorkflowTaskEmailTemplate.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusRuleApproverAttaching() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, ...values},
        }: IActionType<AttachWorkflowStatusRuleApproverRequestTypes> = yield take(
            ATTACH_WORKFLOW_RULE_APPROVER.REQUEST,
        );
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/approver/${uuid}/attach`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(attachWorkflowStatusRuleApprover.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(attachWorkflowStatusRuleApprover.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(attachWorkflowStatusRuleApprover.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusRuleApproverRemoving() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback},
        }: IActionType<RemoveWorkflowStatusRuleApproverRequestTypes> = yield take(
            DELETE_WORKFLOW_RULE_APPROVER.REQUEST,
        );
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/approver/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteWorkflowStatusRuleApprover.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteWorkflowStatusRuleApprover.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteWorkflowStatusRuleApprover.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusRuleApproversGroupAttaching() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, ...values},
        }: IActionType<AttachWorkflowStatusRuleApproversGroupRequestTypes> = yield take(
            ATTACH_WORKFLOW_RULE_APPROVERS_GROUP.REQUEST,
        );
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/approver/group/${uuid}/attach`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(attachWorkflowStatusRuleApproversGroup.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(attachWorkflowStatusRuleApproversGroup.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(attachWorkflowStatusRuleApproversGroup.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusRuleApproversGroupRemoving() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback},
        }: IActionType<RemoveWorkflowStatusRuleApproversGroupRequestTypes> = yield take(
            DELETE_WORKFLOW_RULE_APPROVERS_GROUP.REQUEST,
        );
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/approver/group/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteWorkflowStatusRuleApproversGroup.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteWorkflowStatusRuleApproversGroup.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteWorkflowStatusRuleApproversGroup.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchGetWorkflowStatusEventsList() {
    while (true) {
        const {
            payload: {uuid, showAlert},
        }: IActionType<WorkflowStatusEventsListRequestTypes> = yield take(GET_WORKFLOWS_STATUS_EVENTS_LIST.REQUEST);
        try {
            const data: WorkflowStatusEventResponseTypes = yield call(http, `workflow/events/${uuid}/list`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getWorkflowStatusEventsLists.success(data.results));
            } else {
                yield put(getWorkflowStatusEventsLists.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getWorkflowStatusEventsLists.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusEventCreation() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, ...values},
        }: IActionType<CreateWorkflowStatusEventRequestTypes> = yield take(CREATE_WORKFLOW_STATUS_EVENTS.REQUEST);
        try {
            const data: WorkflowStatusEventResponseTypes = yield call(http, `workflow/events/${uuid}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createWorkflowStatusEvent.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createWorkflowStatusEvent.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(createWorkflowStatusEvent.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusEventRemoving() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback},
        }: IActionType<DeleteWorkflowStatusEventRequestTypes> = yield take(DELETE_WORKFLOW_STATUS_EVENTS.REQUEST);
        try {
            const data: WorkflowStatusEventResponseTypes = yield call(http, `workflow/events/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteWorkflowStatusEvent.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteWorkflowStatusEvent.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(deleteWorkflowStatusEvent.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusEventToggleActivity() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback},
        }: IActionType<ToggleActivityWorkflowStatusEventRequestTypes> = yield take(
            TOGGLE_ACTIVITY_WORKFLOW_STATUS_EVENTS.REQUEST,
        );
        try {
            const data: WorkflowStatusEventResponseTypes = yield call(http, `workflow/events/${uuid}/status`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(toggleActivityWorkflowStatusEvent.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(toggleActivityWorkflowStatusEvent.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(toggleActivityWorkflowStatusEvent.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowAttachmentUploading() {
    while (true) {
        const {
            payload: {uuid, file, setErrors, callback, showAlert, title, language},
        }: IActionType<UploadWorkflowAttachmentTypes> = yield take(UPLOAD_WORKFLOW_ATTACHMENT.REQUEST);
        try {
            const formData = new FormData();
            formData.append('media', file);
            formData.append('title', title);
            formData.append('language', String(language));
            const data: WorkflowStatusEventResponseTypes = yield call(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                http,
                `workflow/attachment/${uuid}/upload`,
                {
                    method: 'POST',
                    body: formData,
                },
            );
            if (data.success) {
                yield put(uploadWorkflowAttachment.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                setErrors && setErrors(data.errors);
                yield put(uploadWorkflowAttachment.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(uploadWorkflowAttachment.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowAttachmentDeleting() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert},
        }: IActionType<DeleteWorkflowAttachmentTypes> = yield take(DELETE_WORKFLOW_ATTACHMENT.REQUEST);
        try {
            const data: WorkflowStatusEventResponseTypes = yield call(http, `workflow/attachment/${uuid}/delete`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(deleteWorkflowAttachment.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteWorkflowAttachment.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(deleteWorkflowAttachment.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowAttachmentDownloading() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert},
        }: IActionType<DownloadWorkflowAttachmentRequestTypes> = yield take(DOWNLOAD_WORKFLOW_ATTACHMENT.REQUEST);
        try {
            const data: WorkflowAttachmentDownloadResponseType = yield call(
                http,
                `workflow/attachment/${uuid}/download`,
                {
                    method: 'GET',
                },
            );
            if (data.fileLink) {
                yield put(downloadWorkflowAttachment.success(data));
                callback && callback(data.fileLink);
            } else {
                yield put(downloadWorkflowAttachment.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(downloadWorkflowAttachment.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchBoardsListGetting() {
    while (true) {
        const {
            payload: {callback, showAlert},
        }: IActionType<BoardsListRequestTypes> = yield take(GET_BOARDS_LIST.REQUEST);
        try {
            const data: BoardsListResponseTypes = yield call(http, `boards`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getBoardsLists.success(data.results));
                callback && callback();
            } else {
                yield put(getBoardsLists.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getBoardsLists.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFavoriteBoardsListGetting() {
    while (true) {
        const {
            payload: {callback, showAlert},
        }: IActionType<BoardsListRequestTypes> = yield take(GET_FAVORITE_BOARDS_LIST.REQUEST);
        try {
            const data: BoardsListResponseTypes = yield call(http, `favorite/boards`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getFavoriteBoardsLists.success(data.results));
                callback && callback();
            } else {
                yield put(getFavoriteBoardsLists.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getFavoriteBoardsLists.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchBoardCreating() {
    while (true) {
        const {
            payload: {showAlert, callback, ...values},
        }: IActionType<CreateBoardRequestTypes> = yield take(CREATE_BOARD.REQUEST);
        try {
            const data: BoardsListResponseTypes = yield call(http, `board/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createBoard.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createBoard.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createBoard.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchBoardListCreating() {
    while (true) {
        const {
            payload: {showAlert, callback, ...values},
        }: IActionType<CreateBoardListRequestTypes> = yield take(CREATE_BOARD_LIST.REQUEST);
        try {
            const data: BoardsListResponseTypes = yield call(http, `board/list/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createBoardList.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createBoardList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createBoardList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchBoardOverviewCreating() {
    while (true) {
        const {
            payload: {showAlert, callback, ...values},
        }: IActionType<CreateBoardOverviewRequestTypes> = yield take(CREATE_BOARD_OVERVIEW.REQUEST);
        try {
            const data: BoardsListResponseTypes = yield call(http, `board/overview/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createBoardOverview.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createBoardOverview.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createBoardOverview.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchBoardSwimlaneCreating() {
    while (true) {
        const {
            payload: {showAlert, callback, ...values},
        }: IActionType<CreateSwimlaneBoardRequestTypes> = yield take(CREATE_BOARD_SWIMLANE.REQUEST);
        try {
            const data: BoardsListResponseTypes = yield call(http, `board/swimlane/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createBoardSwimlane.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createBoardSwimlane.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createBoardSwimlane.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchBoardUpdating() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, ...values},
        }: IActionType<UpdateBoardRequestTypes> = yield take(UPDATE_BOARD.REQUEST);
        try {
            const data: BoardsListResponseTypes = yield call(http, `board/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateBoard.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateBoard.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateBoard.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchBoardOverviewUpdating() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, ...values},
        }: IActionType<UpdateBoardOverviewRequestTypes> = yield take(UPDATE_BOARD_OVERVIEW.REQUEST);
        try {
            const data: BoardsListResponseTypes = yield call(http, `board/overview/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateBoardOverview.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateBoardOverview.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateBoardOverview.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchBoardListStatusUpdating() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, ...values},
        }: IActionType<UpdateBoardListRequestTypes> = yield take(UPDATE_BOARD_LIST_STATUS.REQUEST);
        try {
            const data: BoardsListResponseTypes = yield call(http, `board/list/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateBoardListStatus.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateBoardListStatus.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateBoardListStatus.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchBoardSwimlaneUpdating() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, ...values},
        }: IActionType<UpdateBoardListRequestTypes> = yield take(UPDATE_BOARD_SWIMLANE.REQUEST);
        try {
            const data: BoardsListResponseTypes = yield call(http, `board/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateBoardSwimlane.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateBoardSwimlane.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateBoardSwimlane.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchBoardStatusChanging() {
    while (true) {
        const {
            payload: {callback, uuid, showAlert},
        }: IActionType<ToggleBoardRequestTypes> = yield take(TOGGLE_BOARD_STATUS.REQUEST);
        try {
            const data: BoardsListResponseTypes = yield call(http, `board/${uuid}/active`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(toggleBoardStatus.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(toggleBoardStatus.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(toggleBoardStatus.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchBoardFavoriteChanging() {
    while (true) {
        const {
            payload: {callback, uuid, showAlert},
        }: IActionType<ToggleBoardRequestTypes> = yield take(TOGGLE_BOARD_FAVORITE.REQUEST);
        try {
            const data: BoardsListResponseTypes = yield call(http, `board/${uuid}/favorite`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(toggleBoardFavorite.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(toggleBoardFavorite.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(toggleBoardFavorite.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchBoardHasStatusCreation() {
    while (true) {
        const {
            payload: {callback, uuid, showAlert, ...values},
        }: IActionType<CreateBoardHasStatusRequestTypes> = yield take(CREATE_BOARD_HAS_STATUS.REQUEST);
        try {
            const data: BoardsListResponseTypes = yield call(http, `board/status/${uuid}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createBoardHasStatus.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(createBoardHasStatus.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createBoardHasStatus.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchBoardHasStatusChanging() {
    while (true) {
        const {
            payload: {callback, uuid, showAlert},
        }: IActionType<ToggleBoardRequestTypes> = yield take(TOGGLE_BOARD_HAS_STATUS.REQUEST);
        try {
            const data: BoardsListResponseTypes = yield call(http, `board/status/${uuid}/active`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(toggleBoardHasStatus.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(toggleBoardHasStatus.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(toggleBoardHasStatus.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchBoardRemoving() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, ...values},
        }: IActionType<DeleteBoardRequestTypes> = yield take(DELETE_BOARD.REQUEST);
        try {
            const data: BoardsListResponseTypes = yield call(http, `board/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteBoard.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteBoard.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteBoard.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowTemplateCreation() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, ...values},
        }: IActionType<CreateWorkflowTemplateRequestTypes> = yield take(CREATE_WORKFLOW_TEMPLATE.REQUEST);
        try {
            const data: WorkflowStatusEventResponseTypes = yield call(http, `template/translation/${uuid}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createWorkflowTemplate.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createWorkflowTemplate.error({message: 'Something went wrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createWorkflowTemplate.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowTemplateUpdating() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, ...values},
        }: IActionType<UpdateWorkflowTemplateRequestTypes> = yield take(UPDATE_WORKFLOW_TEMPLATE.REQUEST);
        try {
            const data: WorkflowStatusEventResponseTypes = yield call(http, `template/translation/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateWorkflowTemplate.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateWorkflowTemplate.error({message: 'Something went wrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateWorkflowTemplate.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusTranslationCreation() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, setErrors, ...values},
        }: IActionType<CreateWorkflowStatusTranslationRequestTypes> = yield take(
            CREATE_WORKFLOW_STATUS_TRANSLATION.REQUEST,
        );
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `status/translation/${uuid}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createWorkflowStatusTranslation.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createWorkflowStatusTranslation.error({message: 'Something went wrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, getStatusTranslationErrorMessage(data.errors));
            }
        } catch (e) {
            yield put(createWorkflowStatusTranslation.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusTranslationUpdating() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, setErrors, ...values},
        }: IActionType<UpdateWorkflowStatusTranslationRequestTypes> = yield take(
            UPDATE_WORKFLOW_STATUS_TRANSLATION.REQUEST,
        );
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `status/translation/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateWorkflowStatusTranslation.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateWorkflowStatusTranslation.error({message: 'Something went wrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, getStatusTranslationErrorMessage(data.errors));
            }
        } catch (e) {
            yield put(updateWorkflowStatusTranslation.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusPermissionsUpdating() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, ...values},
        }: IActionType<UpdateWorkflowStatusPermissionsRequestTypes> = yield take(
            UPDATE_WORKFLOW_STATUS_PERMISSIONS.REQUEST,
        );
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/${uuid}/permission`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateWorkflowStatusPermissions.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateWorkflowStatusPermissions.error({message: 'Something went wrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateWorkflowStatusPermissions.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchBoardPriorityChange() {
    while (true) {
        const {
            payload: {showAlert, uuid, priority, callback},
        }: IActionType<DeleteBoardRequestTypes> = yield take(CHANGE_BOARD_PRIORITY.REQUEST);
        try {
            const data: BoardsListResponseTypes = yield call(http, `board/${uuid}/priority/${priority}`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(changeBoardPriority.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(changeBoardPriority.error({message: 'Something went wrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(changeBoardPriority.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFavoriteBoardPriorityChange() {
    while (true) {
        const {
            payload: {showAlert, uuid, priority, callback},
        }: IActionType<DeleteBoardRequestTypes> = yield take(CHANGE_FAVORITE_BOARD_PRIORITY.REQUEST);
        try {
            const data: BoardsListResponseTypes = yield call(http, `favorite/board/${uuid}/priority/${priority}`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(changeFavoriteBoardPriority.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(changeFavoriteBoardPriority.error({message: 'Something went wrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(changeFavoriteBoardPriority.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowEventAgentAttaching() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert, ...values},
        }: IActionType<WorkflowEventAttachAgentRequestTypes> = yield take(ATTACH_WORKFLOW_EVENT_AGENT.REQUEST);
        try {
            const data: WorkflowStatusEventResponseTypes = yield call(http, `workflow/agent/${uuid}/attach`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(attachWorkflowEventAgent.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(attachWorkflowEventAgent.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(attachWorkflowEventAgent.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowEventAgentRemoving() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert},
        }: IActionType<WorkflowEventDeleteAgentRequestTypes> = yield take(DELETE_WORKFLOW_EVENT_AGENT.REQUEST);
        try {
            const data: WorkflowStatusEventResponseTypes = yield call(http, `workflow/agent/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteWorkflowEventAgent.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteWorkflowEventAgent.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(deleteWorkflowEventAgent.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowEventGroupAttaching() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert, ...values},
        }: IActionType<WorkflowEventAttachGroupRequestTypes> = yield take(ATTACH_WORKFLOW_EVENT_GROUP.REQUEST);
        try {
            const data: WorkflowStatusEventResponseTypes = yield call(http, `workflow/group/${uuid}/attach`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(attachWorkflowEventGroup.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(attachWorkflowEventGroup.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(attachWorkflowEventGroup.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowEventGroupRemoving() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert},
        }: IActionType<WorkflowEventDeleteGroupRequestTypes> = yield take(DELETE_WORKFLOW_EVENT_GROUP.REQUEST);
        try {
            const data: WorkflowStatusEventResponseTypes = yield call(http, `workflow/group/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteWorkflowEventGroup.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteWorkflowEventGroup.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(deleteWorkflowEventGroup.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusEmailTemplateAgentAdding() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert, ...values},
        }: IActionType<WorkflowStatusEmailTemplateAgentAddingRequestType> = yield take(
            ADD_WORKFLOW_TASK_EMAIL_TEMPLATE_AGENT.REQUEST,
        );
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/template/agent/${uuid}/add`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(addWorkflowStatusEmailTemplateAgent.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(addWorkflowStatusEmailTemplateAgent.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(addWorkflowStatusEmailTemplateAgent.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchWorkflowStatusEmailTemplateAgentRemoving() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert},
        }: IActionType<WorkflowStatusEmailTemplateAgentRemovingRequestType> = yield take(
            REMOVE_WORKFLOW_TASK_EMAIL_TEMPLATE_AGENT.REQUEST,
        );
        try {
            const data: WorkflowStatusResponseTypes = yield call(http, `workflow/template/agent/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(removeWorkflowStatusEmailTemplateAgent.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(removeWorkflowStatusEmailTemplateAgent.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(removeWorkflowStatusEmailTemplateAgent.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchGetWorkflowsList),
    fork(watchWorkflowCreation),
    fork(watchWorkflowUpdating),
    fork(watchWorkflowRemoving),
    fork(watchWorkflowCloning),
    fork(watchGetWorkflowStatusesList),
    fork(watchGetWorkflowGraph),
    fork(watchGetCurrentWorkflowStatus),
    fork(watchWorkflowStatusCreation),
    fork(watchWorkflowStatusUpdating),
    fork(watchWorkflowStatusInternalNameUpdating),
    fork(watchWorkflowStatusRemoving),
    fork(watchWorkflowStatusChangePriority),
    fork(watchWorkflowStatusRuleCreation),
    fork(watchWorkflowStatusRuleUpdating),
    fork(watchWorkflowStatusRuleActivation),
    fork(watchWorkflowStatusRuleRemoving),
    fork(watchWorkflowStatusTaskCreation),
    fork(watchWorkflowStatusTaskUpdating),
    fork(watchWorkflowStatusTaskActivation),
    fork(watchWorkflowStatusTaskRemoving),
    fork(watchWorkflowTaskEmailTemplateCreation),
    fork(watchWorkflowTaskEmailTemplateUpdating),
    fork(watchWorkflowStatusRuleApproverAttaching),
    fork(watchWorkflowStatusRuleApproverRemoving),
    fork(watchWorkflowStatusRuleApproversGroupAttaching),
    fork(watchWorkflowStatusRuleApproversGroupRemoving),
    fork(watchGetWorkflowStatusEventsList),
    fork(watchWorkflowStatusEventCreation),
    fork(watchWorkflowStatusEventRemoving),
    fork(watchWorkflowStatusEventToggleActivity),
    fork(watchWorkflowAttachmentUploading),
    fork(watchWorkflowAttachmentDeleting),
    fork(watchWorkflowAttachmentDownloading),
    fork(watchBoardsListGetting),
    fork(watchFavoriteBoardsListGetting),
    fork(watchFavoriteBoardPriorityChange),
    fork(watchBoardFavoriteChanging),
    fork(watchBoardCreating),
    fork(watchBoardListCreating),
    fork(watchBoardOverviewCreating),
    fork(watchBoardSwimlaneCreating),
    fork(watchBoardUpdating),
    fork(watchBoardOverviewUpdating),
    fork(watchBoardListStatusUpdating),
    fork(watchBoardSwimlaneUpdating),
    fork(watchBoardStatusChanging),
    fork(watchBoardHasStatusCreation),
    fork(watchBoardHasStatusChanging),
    fork(watchBoardRemoving),
    fork(watchWorkflowTemplateCreation),
    fork(watchWorkflowTemplateUpdating),
    fork(watchWorkflowStatusTranslationCreation),
    fork(watchWorkflowStatusTranslationUpdating),
    fork(watchWorkflowStatusPermissionsUpdating),
    fork(watchBoardPriorityChange),
    fork(watchWorkflowEventAgentAttaching),
    fork(watchWorkflowEventAgentRemoving),
    fork(watchWorkflowEventGroupAttaching),
    fork(watchWorkflowEventGroupRemoving),
    fork(watchWorkflowStatusEmailTemplateAgentAdding),
    fork(watchWorkflowStatusEmailTemplateAgentRemoving),
];
