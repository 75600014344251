import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const PermissionSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" fill="white" fillOpacity="0.01" />
            <path d="M2 10V11H4.293L1 14.293L1.707 15L5 11.707V14H6V10H2Z" fill={getIconColor(isActive, isDisabled)} />
            <path d="M15 15H11V11H15V15ZM12 14H14V12H12V14Z" fill={getIconColor(isActive, isDisabled)} />
            <path d="M10 10H6V6H10V10ZM7 9H9V7H7V9Z" fill={getIconColor(isActive, isDisabled)} />
            <path
                d="M12 8.5H11V7.5H12C12.5304 7.5 13.0391 7.28929 13.4142 6.91421C13.7893 6.53914 14 6.03043 14 5.5C14 4.96957 13.7893 4.46086 13.4142 4.08579C13.0391 3.71071 12.5304 3.5 12 3.5H6V2.5H12C12.7956 2.5 13.5587 2.81607 14.1213 3.37868C14.6839 3.94129 15 4.70435 15 5.5C15 6.29565 14.6839 7.05871 14.1213 7.62132C13.5587 8.18393 12.7956 8.5 12 8.5Z"
                fill={getIconColor(isActive, isDisabled)}
            />
            <path d="M5 5H1V1H5V5ZM2 4H4V2H2V4Z" fill={getIconColor(isActive, isDisabled)} />
        </svg>
    );
};

export default PermissionSvgIcon;
