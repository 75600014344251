import React, {FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import {GET_PASSWORD_RESET_ATTEMPTS} from 'appRedux/actions/superadmin';
import {RootReducer} from 'appRedux/reducers';
import {LoginAttemptTypes} from 'appRedux/actions/superadmin/types';

import SecureInformation from 'components/SecureInformation/SecureInformation';

const PasswordResetAttempts: FC = () => {
    const dispatch = useDispatch();

    const {
        superadmin: {passwordResets},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getAllAttempts = useCallback(
        data => dispatch({type: GET_PASSWORD_RESET_ATTEMPTS.REQUEST, payload: data}),
        [dispatch],
    );

    const itemsList: LoginAttemptTypes[] = get(passwordResets, 'data', []);
    const itemsNumber = get(passwordResets, 'count', 0);

    return <SecureInformation getAllAttempts={getAllAttempts} itemsList={itemsList} itemsNumber={itemsNumber} />;
};

export default PasswordResetAttempts;
