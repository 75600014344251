import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import {MediaContext} from 'contexts/media/context';

import {routes} from 'config/index';
import {theme} from 'config/theme';

const FaqPageButton: FC = () => {
    const [t] = useTranslation();
    const {form} = useParams();
    const navigate = useNavigate();

    const {isMobile} = useContext(MediaContext);

    const redirectToFaq = () => {
        navigate(`${routes.REQUEST}/${form}/faq`);
    };

    if (isMobile) {
        return (
            <IconButton
                title={t('common.buttons.faq')}
                sx={{ml: 2, width: 40, height: 40, backgroundColor: theme.palette.info.main}}
                onClick={redirectToFaq}
            >
                <HelpOutlineIcon sx={{color: theme.palette.background.default}} />
            </IconButton>
        );
    }
    return (
        <Button
            data-id="button#faq"
            onClick={redirectToFaq}
            sx={{
                ml: 2,
                minWidth: 100,
                height: 40,
                borderRadius: 20,
                backgroundColor: theme.palette.info.main,
                '&:hover': {
                    backgroundColor: theme.palette.info.main,
                },
            }}
        >
            <HelpOutlineIcon sx={{color: theme.palette.background.default}} />
            <Typography sx={{textTransform: 'none', color: theme.palette.background.default, ml: 1}}>
                {t('common.buttons.faq')}
            </Typography>
        </Button>
    );
};

export default FaqPageButton;
