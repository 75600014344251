import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {Alert} from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

interface InfectedFilePopupType {
    showPopup: boolean;
    setShowPopup: (value: boolean) => void;
    attemptsNumber: number | null;
    toggleIsOpened: () => void;
}

const InfectedFilePopup: FC<InfectedFilePopupType> = ({showPopup, setShowPopup, attemptsNumber, toggleIsOpened}) => {
    const [t] = useTranslation();
    return (
        <ModalWrapper
            isShowModal={showPopup}
            title={t('messages.warning.infectedFileTitle')}
            toggleModal={toggleIsOpened}
        >
            <Alert severity={'error'}>
                <Typography sx={{fontWeight: 600}}>
                    {t('messages.warning.infectedFileExplanation').replace('%ATTEMPTS_NUMBER%', String(attemptsNumber))}
                </Typography>
            </Alert>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 2,
                }}
            >
                <AgentSaveButton
                    isSubmit={false}
                    title={t('common.buttons.close')}
                    onClick={() => setShowPopup(false)}
                />
            </Box>
        </ModalWrapper>
    );
};

export default InfectedFilePopup;
