import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import Markdown from 'react-markdown';
import get from 'lodash/get';
import {useParams} from 'react-router-dom';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Box';
import Box from '@mui/material/Box';

import {WorkflowStatusEventItemTypes} from 'appRedux/actions/workflow/types';
import {TOGGLE_ACTIVITY_WORKFLOW_STATUS_EVENTS} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import {CustomSwitcher} from 'components/AgentScreenComponents/_form/CustomElements';

import CreateWorkflowStatusEventButton from 'pages/admin/updateWorkflowEvents/CreateWorkflowStatusEventButton';
import EditWorkflowStatusEventButton from 'pages/admin/updateWorkflowEvents/EditWorkflowStatusEventButton';
import DeleteWorkflowStatusEventButton from 'pages/admin/updateWorkflowEvents/DeleteWorkflowStatusEventButton';
import {
    WORKFLOW_EVENT_CHAT_NOTIFICATION_CHANGE_TO_STATUS,
    WORKFLOW_EVENT_CHAT_NOTIFICATION_LEAVE_STATUS,
    WORKFLOW_EVENT_WEBHOOK_CHANGE_TO_STATUS,
    WORKFLOW_EVENT_WEBHOOK_LEAVE_STATUS,
    getTableSubjectValue,
    getTranslationAbsentLabel,
} from 'pages/admin/updateWorkflowEvents/helper';
import WorkflowEventAgentLabel from 'pages/admin/updateWorkflowEvents/workflowEventAgent/WorkflowEventAgentLabel';

import {EMPTY_DATA} from 'config/index';

interface WorkflowStatusEventTableRowType {
    statusTitle: string;
    eventTypeLabel: string;
    typeLabel: string;
    type: number;
    item?: WorkflowStatusEventItemTypes;
    forFixedAgent?: boolean;
    currentLanguage: string;
}

const WorkflowStatusEventTableRow: FC<WorkflowStatusEventTableRowType> = ({
    eventTypeLabel,
    typeLabel,
    type,
    item,
    statusTitle,
    forFixedAgent,
    currentLanguage,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {uuid} = useParams();

    const {showAlert} = useContext(AlertContext);

    const [isFormActive, setIsFormActive] = useState<boolean>(Boolean(item && item.isActive));

    const changeEventStatus = useCallback(
        data => dispatch({type: TOGGLE_ACTIVITY_WORKFLOW_STATUS_EVENTS.REQUEST, payload: data}),
        [dispatch],
    );

    const handleChangeStatus = () => {
        if (item) {
            setIsFormActive(!isFormActive);
            changeEventStatus({
                uuid: item.uuid,
                showAlert,
            });
        }
    };

    const forEmailTemplate = ![
        WORKFLOW_EVENT_CHAT_NOTIFICATION_CHANGE_TO_STATUS,
        WORKFLOW_EVENT_CHAT_NOTIFICATION_LEAVE_STATUS,
        WORKFLOW_EVENT_WEBHOOK_LEAVE_STATUS,
        WORKFLOW_EVENT_WEBHOOK_CHANGE_TO_STATUS,
    ].includes(type);

    const forWebhookTemplate = [WORKFLOW_EVENT_WEBHOOK_LEAVE_STATUS, WORKFLOW_EVENT_WEBHOOK_CHANGE_TO_STATUS].includes(
        type,
    );

    return (
        <TableRow>
            <TableCell>
                <Typography>{t(eventTypeLabel)}</Typography>
            </TableCell>
            <TableCell>
                {forFixedAgent ? (
                    <WorkflowEventAgentLabel label={typeLabel} item={item} />
                ) : (
                    <Typography>{t(typeLabel)}</Typography>
                )}
            </TableCell>
            <TableCell>
                {forEmailTemplate || forWebhookTemplate ? (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <Typography sx={{fontWeight: 700, mr: 1}}>{`${t(
                                forEmailTemplate
                                    ? 'orguser.workflows.workflowStatusEventParameters.subject'
                                    : 'orguser.workflows.workflowStatusEventParameters.url',
                            )}:`}</Typography>
                            <Typography>
                                {getTableSubjectValue(t, currentLanguage, item, forWebhookTemplate)}
                            </Typography>
                        </Box>
                    </>
                ) : (
                    <>
                        {item ? (
                            <Markdown>
                                {get(item.translations, [currentLanguage, 'text'], getTranslationAbsentLabel(t))}
                            </Markdown>
                        ) : (
                            <Typography>{EMPTY_DATA}</Typography>
                        )}
                    </>
                )}
            </TableCell>
            <TableCell>
                {item ? (
                    <CustomSwitcher checked={item.isActive} onClick={handleChangeStatus} />
                ) : (
                    <Typography>{t('common.none')}</Typography>
                )}
            </TableCell>
            <TableCell>
                {item ? (
                    <>
                        <EditWorkflowStatusEventButton
                            forEmailTemplate={forEmailTemplate}
                            forWebhookTemplate={forWebhookTemplate}
                            item={item}
                            eventTypeLabel={eventTypeLabel}
                            typeLabel={typeLabel}
                            statusTitle={statusTitle}
                            statusUuid={String(uuid)}
                        />
                        <DeleteWorkflowStatusEventButton item={item} />
                    </>
                ) : (
                    <CreateWorkflowStatusEventButton
                        type={type}
                        eventTypeLabel={eventTypeLabel}
                        typeLabel={typeLabel}
                        statusTitle={statusTitle}
                        forEmailTemplate={forEmailTemplate}
                        forWebhookTemplate={forWebhookTemplate}
                    />
                )}
            </TableCell>
        </TableRow>
    );
};

export default WorkflowStatusEventTableRow;
