import React, {FC} from 'react';

import {FormBaseInformationTypes} from 'appRedux/actions/auth/types';

import FormPaused from 'components/RegistrationForm/FormPaused';
import AlreadyLogin from 'components/RegistrationForm/AlreadyLogin';
import StartForm from 'components/RegistrationForm/StartForm';
import FormDeactivated from 'components/RegistrationForm/FormDeactivated';
import FormInviteOnly from 'components/RegistrationForm/FormInviteOnly';
import AnonymousForm from 'components/RegistrationForm/AnonymousForm';

interface ContentSwitcherType {
    alreadyLogin: boolean;
    userId: number | null;
    formInformation: FormBaseInformationTypes;
    isRegistrationByInvite?: boolean;
}

const ContentSwitcher: FC<ContentSwitcherType> = ({alreadyLogin, userId, formInformation, isRegistrationByInvite}) => {
    const {id, isPaused, isActive, isClosed, isOnlyInvite, pauseReason, isAnonymousRequestPossible} = formInformation;
    if (isClosed && !alreadyLogin) {
        return null;
    }
    if (isPaused) {
        return <FormPaused formId={id} reason={pauseReason} alreadyLogin={alreadyLogin} />;
    }
    if (!isActive) {
        return <FormDeactivated alreadyLogin={alreadyLogin} />;
    }
    if (isOnlyInvite && !isRegistrationByInvite) {
        return <FormInviteOnly alreadyLogin={alreadyLogin} />;
    }
    if (isAnonymousRequestPossible) {
        return <AnonymousForm formInformation={formInformation} />;
    }
    if (alreadyLogin && userId) {
        return <AlreadyLogin formInformation={formInformation} userId={userId} />;
    }

    return <StartForm />;
};

export default ContentSwitcher;
