import React, {FC, useContext} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';

import {RootReducer} from 'appRedux/reducers';

import MessagesSvgIcon from 'assets/icons/menu/MessagesSvgIcon';

import {PermissionContext} from 'contexts/permission/context';

import {getNotReadMessagesNumber, getUserNameLabel} from 'pages/agent/requesterPage/partials/helper';

import {checkIsCaseEditEnabled} from 'helpers/permissions';

import {MAIN_CLIENT_COLOR, ERROR_TEXT_COLOR, theme} from 'config/theme';
import {DATE_FORMAT_CLIENT} from 'config/index';

interface RequesterNameBlockType {
    toggleIsChatOpened: () => void;
}

const RequesterNameBlock: FC<RequesterNameBlockType> = ({toggleIsChatOpened}) => {
    const [t] = useTranslation();
    const {caseId, formId} = useParams();

    const {isCaseEditEnabled, filtersCaseEditAllowed} = useContext(PermissionContext);

    const {
        requestCase: {isCaseLoading, currentCase},
        profile: {profile},
        requestChat: {contacts},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    if (isCaseLoading) {
        return (
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                <Skeleton variant="rectangular" width={200} height={15} sx={{fontSize: '1rem'}} />
                <Skeleton variant="rectangular" width={250} height={15} sx={{fontSize: '1rem', ml: 1}} />
                <Skeleton variant="circular" sx={{ml: 2}} width={45} height={45} />
            </Box>
        );
    }

    const {userName, caseLabel, deletedAt, isAnonymousAccount} = currentCase;

    const currentUserEmail = get(profile, 'email', null);
    const agentEmail = get(currentCase, 'agentEmail', null);

    const notReadMessages =
        contacts && Array.isArray(contacts) ? getNotReadMessagesNumber(contacts, Number(caseId)) : null;

    const isEditEnabled = checkIsCaseEditEnabled(isCaseEditEnabled, filtersCaseEditAllowed, Number(formId));

    return (
        <Box
            sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', mt: '2px'}}
        >
            <Box>
                <Typography sx={{fontSize: 40, fontWeight: 300, lineHeight: '48px'}}>
                    {getUserNameLabel(t, userName, caseLabel, isAnonymousAccount)}
                </Typography>
                {deletedAt && (
                    <Typography sx={{mt: 1, fontSize: 16, color: ERROR_TEXT_COLOR}}>{`(${t(
                        'orguser.requesterCase.deleted',
                    )} - ${moment(deletedAt).format(DATE_FORMAT_CLIENT)})`}</Typography>
                )}
            </Box>
            {!deletedAt && isEditEnabled && (
                <Badge
                    badgeContent={notReadMessages}
                    invisible={!notReadMessages}
                    sx={{
                        mt: '2px',
                        '& .MuiBadge-badge': {
                            top: 4,
                            right: 4,
                            backgroundColor: MAIN_CLIENT_COLOR,
                            color: theme.palette.background.default,
                        },
                    }}
                >
                    {agentEmail === currentUserEmail && (
                        <IconButton
                            sx={{
                                ml: 2,
                                width: 40,
                                height: 40,
                                borderColor: theme.palette.info.main,
                                borderWidth: 1.5,
                                borderStyle: 'solid',
                            }}
                            onClick={toggleIsChatOpened}
                        >
                            <MessagesSvgIcon isActive={false} />
                        </IconButton>
                    )}
                </Badge>
            )}
        </Box>
    );
};

export default RequesterNameBlock;
