import React, {FC, useRef} from 'react';
import {Link, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {KeyboardArrowRight} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';

import CancelSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import ChatFilesContextWrapper from 'contexts/chatFiles';

import RequesterCaseChat from 'components/RequesterChat/RequesterCaseChat';

import MessagesList from 'pages/agent/requesterPage/messenger/MessagesList';

import {theme} from 'config/theme';
import {routes} from 'config/index';

interface MessengerType {
    toggleIsOpened: () => void;
}

const Messenger: FC<MessengerType> = ({toggleIsOpened}) => {
    const [t] = useTranslation();
    const {caseId} = useParams();
    const wrapperRef = useRef<HTMLDivElement>();

    return (
        <Box
            sx={{
                zIndex: 1300,
                position: 'fixed',
                bottom: 0,
                right: 30,
                height: '70vh',
                width: '26vw',
                backgroundColor: theme.palette.background.default,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                boxShadow: 24,
            }}
            ref={wrapperRef}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: 2,
                }}
            >
                <Link to={`${routes.REQUESTER_CASE}/${caseId}/chat`}>
                    <Button>
                        <Typography sx={{color: theme.palette.info.main, textTransform: 'none'}}>
                            {t('orguser.chat.showAllMessages')}
                        </Typography>
                        <KeyboardArrowRight sx={{color: theme.palette.info.main}} />
                    </Button>
                </Link>
                <IconButton sx={{p: 0}} onClick={toggleIsOpened}>
                    <CancelSvgIcon />
                </IconButton>
            </Box>
            <ChatFilesContextWrapper>
                <MessagesList />
            </ChatFilesContextWrapper>
            <Box
                sx={{
                    width: '100%',
                    position: 'absolute',
                    bottom: 0,
                    p: 2,
                    backgroundColor: theme.palette.background.default,
                }}
            >
                <RequesterCaseChat caseId={caseId} wrapperRef={wrapperRef} />
            </Box>
        </Box>
    );
};

export default Messenger;
