import React, {FC} from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {FormAllowListTypes} from 'appRedux/actions/forms/types';

import FormAllowListEmailDeleteButton from 'pages/admin/updateForm/partials/FormAllowlist/FormAllowListEmailDeleteButton';

interface FormAllowListItemType {
    item: FormAllowListTypes;
}

const FormAllowListItem: FC<FormAllowListItemType> = ({item}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mb: 1,
            }}
        >
            <Typography sx={{width: 300, mr: 2}}>{item.value}</Typography>
            <FormAllowListEmailDeleteButton item={item} />
        </Box>
    );
};

export default FormAllowListItem;
