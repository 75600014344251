import {StyleSheet, Font} from '@react-pdf/renderer';
import {ADMIN_LIGHT_GRAY_COLOR, FONT_FAMILY_OPEN_SANS, theme} from 'config/theme';

Font.register({
    family: FONT_FAMILY_OPEN_SANS,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fonts: [
        {
            src: require('./fonts/OpenSans-Regular.ttf'),
        },
        {
            src: require('./fonts/OpenSans-SemiBold.ttf'),
            fontWeight: 'bold',
        },
        {
            src: require('./fonts/OpenSans-Light.ttf'),
            fontWeight: 'light',
        },
    ],
});

export const pdfStyles = StyleSheet.create({
    page: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        boxShadow: '1px 2px 6px -1px rgba(0, 0, 0, 0.2)',
        fontSize: 12,
        padding: '55px 35px 45px 40px',
        width: '595px',
        minHeight: '842px',
        fontFamily: FONT_FAMILY_OPEN_SANS,
        backgroundColor: 'white',
        marginBottom: '8px',
    },
    attachmentsPage: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        boxShadow: '1px 2px 6px -1px rgba(0, 0, 0, 0.2)',
        fontSize: 12,
        padding: '0',
        width: '595px',
        minHeight: '842px',
        marginBottom: '8px',
    },
    template: {
        position: 'absolute',
        top: '0px',
        right: '0px',
        left: '0px',
        height: '842px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    header: {
        position: 'absolute',
        top: '25px',
        right: '35px',
        left: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerText: {
        textAlign: 'center',
        fontSize: 11,
    },
    footer: {
        position: 'absolute',
        bottom: '25px',
        right: '35px',
        left: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    footerText: {
        textAlign: 'center',
        fontSize: 11,
    },
    title: {
        position: 'relative',
        textAlign: 'center',
        marginTop: '40px',
        marginBottom: '24px',
        fontSize: 18,
        fontWeight: 'bold',
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    pageHeader: {
        margin: '10px 0',
        fontSize: 14,
        padding: '8px 16px',
        backgroundColor: theme.palette.secondary.contrastText,
        fontWeight: 'bold',
    },
    sectionHeader: {
        textAlign: 'center',
        marginTop: '10px',
        fontSize: 14,
        fontWeight: 'bold',
    },
    field: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        margin: '4px 0 8px',
        minHeight: '20px',
    },
    fieldTitle: {fontWeight: 'bold'},
    fieldValue: {
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        textAlign: 'justify',
        marginTop: '8px',
        padding: '12px',
        borderRadius: '8px',
        backgroundColor: ADMIN_LIGHT_GRAY_COLOR,
        width: '100%',
    },
    popupTitle: {
        marginLeft: '8px',
        fontWeight: 'bold',
        margin: '4px 0 8px',
    },
    popupContent: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: ADMIN_LIGHT_GRAY_COLOR,
        margin: '0 0 8px 8px',
        padding: '12px 12px 4px',
        borderRadius: '12px',
    },
    popupFieldValue: {
        textAlign: 'justify',
        padding: '12px 0',
        width: '100%',
    },
    divider: {
        width: '100%',
        margin: '0 0 10px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
    },
    attachmentHeader: {
        position: 'absolute',
        top: '30px',
        right: '35px',
        left: '40px',
        margin: '0',
        fontSize: 12,
        padding: '4px 16px',
        backgroundColor: theme.palette.secondary.contrastText,
        fontWeight: 'bold',
    },
    image: {
        maxHeight: '100%',
        maxWidth: '100%',
        minHeight: '100px',
    },
    attachmentImage: {
        position: 'relative',
        margin: '75px 35px 45px 40px',
        maxHeight: 'calc(100% - 120px)',
        maxWidth: 'calc(100% - 75px)',
        minHeight: '100px',
    },
    attachmentPdf: {
        position: 'absolute',
        top: '0px',
        right: '0px',
        left: '0px',
        height: '842px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    sectionComments: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '8px',
    },
    commentsTitle: {
        margin: '0 0 8px',
        fontWeight: 'bold',
    },
    comment: {
        width: '100%',
        backgroundColor: ADMIN_LIGHT_GRAY_COLOR,
        borderRadius: '16px',
        margin: '0 0 8px',
        padding: '8px 16px',
        display: 'flex',
        flexDirection: 'column',
    },
    commentAuthor: {
        marginBottom: '4px',
        fontWeight: 'bold',
    },
    commentText: {
        marginBottom: '8px',
        fontSize: 12,
    },
    commentExtras: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    commentTime: {
        fontSize: 11,
        fontWeight: 'bold',
    },
    commentEdited: {
        marginLeft: '16px',
        fontWeight: 'light',
        fontSize: 11,
    },
    fieldImage: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100px',
        gap: '8px',
    },
    fieldSignature: {
        position: 'relative',
        display: 'flex',
        height: '100px',
        flexDirection: 'column',
        gap: '8px',
    },
});
