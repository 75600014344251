import {
    GeneralTemplateTypes,
    GeneralTemplateRequestType,
    GeneralTemplateUpdateRequestType,
    GeneralDefaultTemplateRequestType,
    GeneralTemplateDefaultTypes,
} from 'appRedux/actions/generalTemplates/types';

import {requestTypeCreator} from 'helpers/index';

export const GENERAL_TEMPLATE_LIST = requestTypeCreator('GENERAL_TEMPLATE_LIST');
export const GENERAL_TEMPLATE_LIST_DEFAULT = requestTypeCreator('GENERAL_TEMPLATE_LIST_DEFAULT');
export const GENERAL_TEMPLATE_UPDATE = requestTypeCreator('GENERAL_TEMPLATE_UPDATE');

export const getGeneralTemplatesList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GENERAL_TEMPLATE_LIST.ERROR,
    }),
    request: (payload: GeneralTemplateRequestType): IActionType<GeneralTemplateRequestType> => ({
        payload,
        type: GENERAL_TEMPLATE_LIST.REQUEST,
    }),
    success: (payload: GeneralTemplateTypes[]): IActionType<GeneralTemplateTypes[]> => ({
        payload,
        type: GENERAL_TEMPLATE_LIST.SUCCESS,
    }),
};

export const getGeneralTemplatesListDefault = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GENERAL_TEMPLATE_LIST_DEFAULT.ERROR,
    }),
    request: (payload: GeneralDefaultTemplateRequestType): IActionType<GeneralDefaultTemplateRequestType> => ({
        payload,
        type: GENERAL_TEMPLATE_LIST_DEFAULT.REQUEST,
    }),
    success: (payload: GeneralTemplateDefaultTypes): IActionType<GeneralTemplateDefaultTypes> => ({
        payload,
        type: GENERAL_TEMPLATE_LIST_DEFAULT.SUCCESS,
    }),
};

export const updateGeneralTemplate = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GENERAL_TEMPLATE_UPDATE.ERROR,
    }),
    request: (payload: GeneralTemplateUpdateRequestType): IActionType<GeneralTemplateUpdateRequestType> => ({
        payload,
        type: GENERAL_TEMPLATE_UPDATE.REQUEST,
    }),
    success: (payload: GeneralTemplateTypes[]): IActionType<GeneralTemplateTypes[]> => ({
        payload,
        type: GENERAL_TEMPLATE_UPDATE.SUCCESS,
    }),
};
