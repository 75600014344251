import React, {FC, Ref} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {WorkflowTemplateFormTypes, WorkflowTemplateTypes} from 'appRedux/actions/workflow/types';
import {RootReducer} from 'appRedux/reducers';

import {
    validationSchemaWorkflowTemplate,
    validationSchemaWorkflowTemplateForEmail,
} from 'components/Forms/Workflow/validation';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import MdxEditorField from 'components/MdxEditorField/MdxEditorField';
import TranslationSelector from 'components/Forms/Workflow/workflowStatusEvent/TranslationSelector';
import {customButtons, getCustomButtonsForWebhook} from 'components/Forms/Workflow/workflowStatusEvent/helper';
import CodeMirrorField from 'components/CodeMirrorField/CodeMirrorField';

import {theme} from 'config/theme';

interface FormType {
    formRef: Ref<any> | null;
    initialValues: WorkflowTemplateFormTypes;
    url?: string;
    onSubmitClicked: (values: WorkflowTemplateFormTypes) => void;
    translations: WorkflowTemplateTypes[];
    forEmailTemplate?: boolean;
    forWebhookTemplate?: boolean;
    codeMirrorText: string;
    workflowStatusUuid: string;
    setCodeMirrorText: (value: string) => void;
    language: number;
    setLanguage: (value: number) => void;
}

const WorkflowTemplateForm: FC<FormType> = ({
    formRef,
    initialValues,
    url,
    onSubmitClicked,
    translations,
    forEmailTemplate,
    forWebhookTemplate,
    codeMirrorText,
    workflowStatusUuid,
    setCodeMirrorText,
    language,
    setLanguage,
}) => {
    const [t] = useTranslation();

    const {
        workflow: {workflowStatusesList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            validationSchema={() =>
                forEmailTemplate ? validationSchemaWorkflowTemplateForEmail(t) : validationSchemaWorkflowTemplate(t)
            }
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<WorkflowTemplateFormTypes>) => {
                return (
                    <Form>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                mt: 2,
                                flexWrap: 'wrap-reverse',
                            }}
                        >
                            {forWebhookTemplate && url && (
                                <Box
                                    sx={{
                                        m: 0,
                                        mt: 1,
                                        p: 2,
                                        minWidth: '300px',
                                        flexGrow: 1,
                                        backgroundColor: theme.palette.info.contrastText,
                                        borderRadius: 2,
                                    }}
                                >
                                    <Typography sx={{fontSize: 14}}>{url}</Typography>
                                </Box>
                            )}
                            {forEmailTemplate && (
                                <Box sx={{m: 0, minWidth: '300px', flexGrow: 1}}>
                                    <Field
                                        required
                                        name="subject"
                                        placeholder={t('orguser.workflows.workflowStatusEventParameters.subject')}
                                        component={FormikTextInput}
                                    />
                                </Box>
                            )}
                            <Box
                                sx={{
                                    ml: 'auto',
                                    pl: 3,
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <TranslationSelector
                                    forEmailTemplate={forEmailTemplate}
                                    translations={translations}
                                    language={language}
                                    setLanguage={setLanguage}
                                />
                            </Box>
                        </Box>
                        {forWebhookTemplate ? (
                            <CodeMirrorField
                                name="text"
                                label={t('orguser.workflows.workflowStatusEventParameters.text')}
                                value={initialValues.text ? initialValues.text : ''}
                                customButtons={getCustomButtonsForWebhook(workflowStatusesList, workflowStatusUuid)}
                                codeMirrorText={codeMirrorText}
                                setCodeMirrorText={setCodeMirrorText}
                            />
                        ) : (
                            <MdxEditorField
                                name="text"
                                key={`template-translate-language-${language}`}
                                label={t('orguser.workflows.workflowStatusEventParameters.text')}
                                value={initialValues.text ? initialValues.text : ''}
                                customButtons={customButtons}
                            />
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default WorkflowTemplateForm;
