import React, {DragEvent, ReactNode, useRef} from 'react';

import Box from '@mui/material/Box';

import {ADMIN_LIGHT_GRAY_COLOR} from 'config/theme';

interface FormVersionCardFieldsWrapperPropsType {
    setDropTo: React.Dispatch<React.SetStateAction<number | 'delete' | null>>;
    active: boolean;
    dragFieldId?: string;
    onDrop?: () => void;
    children: ReactNode;
}

function FormVersionCardFieldsWrapper({
    setDropTo,
    active,
    dragFieldId,
    onDrop,
    children,
}: FormVersionCardFieldsWrapperPropsType) {
    const dropRef = useRef<HTMLDivElement>();

    const onDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
    };

    const onDragEnter = () => {
        setTimeout(() => {
            setDropTo && setDropTo('delete');
        }, 0);
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        onDrop && onDrop();
    };

    return (
        <Box
            ref={dropRef}
            sx={{
                p: '6px',
                pb: '2px',
                borderRadius: 2,
                border: active ? `2px dashed ${ADMIN_LIGHT_GRAY_COLOR}` : '2px solid transparent',
                minHeight: '150px',
            }}
            onDragEnter={typeof dragFieldId === 'string' ? onDragEnter : undefined}
            onDragOver={typeof dragFieldId === 'string' ? onDragOver : undefined}
            onDrop={typeof dragFieldId === 'string' ? handleDrop : undefined}
        >
            {children}
        </Box>
    );
}

export default FormVersionCardFieldsWrapper;
