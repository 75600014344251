import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

import MainLogo from 'assets/mainLogo';

import {FormForRequesterItemBasic} from 'appRedux/actions/forms/types';
import {CREATE_REQUESTER_CASE} from 'appRedux/actions/requestCase';

import {AlertContext} from 'contexts/alert/context';
import {MediaContext} from 'contexts/media/context';
import {CryptoContext} from 'contexts/crypto/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';
import MarkdownWithShowAll from 'components/TextWithShowAll/MarkdownWithShowAll';
import CustomLogo from 'components/StartScreenComponents/CustomLogo';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {routes} from 'config/index';

interface FormListItemType {
    item: FormForRequesterItemBasic;
}

const FormListItem: FC<FormListItemType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isMobile} = useContext(MediaContext);
    const {regenerateKeysAfterLogin} = useContext(CryptoContext);

    const {id, title, description, logo, button, isEncryptInfo} = item;

    const createRequesterCase = useCallback(
        data => dispatch({type: CREATE_REQUESTER_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const redirectToForm = async (caseId: number) => {
        const redirectTo = isEncryptInfo
            ? `${routes.REQUEST}/${caseId}/keysGenerate/${id}`
            : `${routes.REQUEST}/${caseId}/form/${id}`;
        await regenerateKeysAfterLogin(redirectTo);
    };

    const createNewRequesterCase = () => {
        createRequesterCase({
            formId: id,
            showAlert,
            callback: redirectToForm,
        });
    };

    return (
        <ClientSectionWrapper>
            <Typography sx={{fontSize: 24, fontWeight: 700}}>{title}</Typography>
            <Divider sx={{mb: 2, mt: 2}} />
            <Grid container>
                <Grid item xs={12} sm={3}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            p: 2,
                        }}
                    >
                        <Box sx={{mb: 2}}>
                            {logo ? (
                                <CustomLogo logoSource={logo} maxWidth={200} maxHeight={200} />
                            ) : (
                                <MainLogo maxWidth={200} maxHeight={200} />
                            )}
                        </Box>
                        {!isMobile && (
                            <AgentSaveButton
                                isSubmit={false}
                                title={button ? button : t('common.registrationForm.requestHelp')}
                                onClick={createNewRequesterCase}
                            />
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <MarkdownWithShowAll text={description} />
                    {isMobile && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton
                                isSubmit={false}
                                title={button ? button : t('common.registrationForm.requestHelp')}
                                onClick={createNewRequesterCase}
                            />
                        </Box>
                    )}
                </Grid>
            </Grid>
        </ClientSectionWrapper>
    );
};

export default FormListItem;
