import React, {FC} from 'react';

import Box from '@mui/material/Box';

import {ResourceFieldCalendarSlotItemTypes} from 'appRedux/actions/resourceFields/types';

import CalendarSlotsListItem from 'pages/admin/updateResourceField/calendarSlot/CalendarSlotsListItem';

interface CalendarSlotsListType {
    items: ResourceFieldCalendarSlotItemTypes[];
}

const CalendarSlotsList: FC<CalendarSlotsListType> = ({items}) => {
    return (
        <Box sx={{mt: 3}}>
            {items &&
                items.map((item: ResourceFieldCalendarSlotItemTypes) => {
                    return <CalendarSlotsListItem key={`calendar-slot-${item.uuid}`} item={item} />;
                })}
        </Box>
    );
};

export default CalendarSlotsList;
