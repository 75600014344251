import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import CaseWatcherForm from 'pages/agent/requesterPage/watchers/CaseWatcherForm';

import {theme} from 'config/theme';

const AddWatcherButton: FC = () => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <>
            <Button sx={{height: 24, p: 0, mb: 1}} onClick={toggleIsOpened}>
                <PlusSvgIcon />
                <Typography
                    sx={{
                        color: theme.palette.info.main,
                        fontSize: 12,
                        textTransform: 'none',
                    }}
                >
                    {t('orguser.requesterCase.watchers.addWatcher')}
                </Typography>
            </Button>
            <ModalWrapper
                title={t('orguser.requesterCase.watchers.addWatcher')}
                isShowModal={isOpened}
                toggleModal={() => setIsOpened(false)}
            >
                <CaseWatcherForm closeModal={() => setIsOpened(false)} />
            </ModalWrapper>
        </>
    );
};

export default AddWatcherButton;
