import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import {Box, Tooltip, Typography} from '@mui/material';

import SaveSvgIcon from 'assets/icons/buttons/SaveSvgIcon';
import EditSaveSvgIcon from 'assets/icons/buttons/EditSaveSvgIcon';

import {PublicKeyTypes} from 'appRedux/actions/crypto/types';
import {ExportCryptoKeysTypes} from 'appRedux/actions/auth/types';
import {GET_USER_PUBLIC_KEYS, SAVE_USER_KEYS} from 'appRedux/actions/crypto';

import {AlertContext} from 'contexts/alert/context';
import {CryptoContext} from 'contexts/crypto/context';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import CryptoPasswordForm from 'components/PublicKeysList/CryptoPasswordForm';

import {
    encryptStringWithPasswordKey,
    exportPrivateKey,
    exportPublicKey,
    getPasswordKeyAndIv,
} from 'helpers/cryptoApiHelper';

import {ALERT_TYPE_ERROR} from 'config/index';

interface SaveKeyButtonType {
    item: PublicKeyTypes;
}

const SaveKeyButton: FC<SaveKeyButtonType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {isKeyStored} = item;

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {keys} = useContext(CryptoContext);

    const saveUserKeys = useCallback(data => dispatch({type: SAVE_USER_KEYS.REQUEST, payload: data}), [dispatch]);
    const getUserPublicKeys = useCallback(
        data => dispatch({type: GET_USER_PUBLIC_KEYS.REQUEST, payload: data}),
        [dispatch],
    );

    const handleSave = async (values: ExportCryptoKeysTypes) => {
        if (!keys) return;

        try {
            const publicKey = await exportPublicKey(keys.publicKey);
            const privateKey = await exportPrivateKey(keys.privateKey);

            const keysToExport = {
                name: keys.name,
                publicKey,
                privateKey,
                uuid: keys.uuid,
            };
            const keysString = JSON.stringify(keysToExport);

            const {key, iv} = await getPasswordKeyAndIv(values.keyPassword);
            const ecnrypted = await encryptStringWithPasswordKey(keysString, key, iv);

            saveUserKeys({
                encryptedKey: ecnrypted,
                uuid: keys.uuid,
                showAlert,
                callback: () => {
                    toggleIsOpened();
                    getUserPublicKeys({showAlert});
                },
            });
        } catch (e) {
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    };

    return (
        <>
            <Tooltip
                title={t(`common.publicKeys.${isKeyStored ? 'editSavedCryptoKeysLabel' : 'saveCryptoKeysLabel'}`)}
                placement={'top'}
                arrow
                disableInteractive
            >
                <IconButton onClick={toggleIsOpened}>{isKeyStored ? <EditSaveSvgIcon /> : <SaveSvgIcon />}</IconButton>
            </Tooltip>
            <ModalWrapper
                title={t(`common.publicKeys.${isKeyStored ? 'editSavedCryptoKeysLabel' : 'saveCryptoKeysLabel'}`)}
                isShowModal={isOpened}
                toggleModal={toggleIsOpened}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'start',
                        py: 2,
                        width: '100%',
                    }}
                >
                    <Typography align="center" variant="body2" sx={{mt: 2}}>
                        {t(
                            `common.publicKeys.${
                                isKeyStored ? 'editSavedCryptoKeysDescription' : 'saveCryptoKeysDescription'
                            }`,
                        )}
                    </Typography>
                    <CryptoPasswordForm handleSubmit={handleSave} onClose={toggleIsOpened} />
                </Box>
            </ModalWrapper>
        </>
    );
};

export default SaveKeyButton;
