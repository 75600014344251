import React, {FC, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import {Grid, Typography} from '@mui/material';

import {RootReducer} from 'appRedux/reducers';
import {FORM_TRANSLATIONS, FORM_TRANSLATION_UPDATE} from 'appRedux/actions/forms';
import {FormHasLanguageTypes, FormPageTypes} from 'appRedux/actions/forms/types';

import {TranslationFormType} from 'components/Forms/FormBuilder/TranslationForm/validation';
import MarkdownWithShowAll from 'components/TextWithShowAll/MarkdownWithShowAll';

import PageTranslateInput from 'pages/admin/updateForm/partials/FormPreview/PageTranslateInput';

import {
    FormTranslationIntentResultType,
    FormTranslationType,
    FormTranslationIntent,
    getPageItemsToTranslate,
    getLanguageById,
} from 'helpers/translationsHelper';

import {theme} from 'config/theme';
import {EMPTY_DATA} from 'config/index';

interface FormPageTranslateType {
    page: FormPageTypes;
    formId: number;
    currentFormLanguage: FormHasLanguageTypes;
    isActive?: boolean;
    viewOnly: boolean;
}

const FormPageTranslate: FC<FormPageTranslateType> = ({page, formId, viewOnly, currentFormLanguage, isActive}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {
        admin: {formsList, formInfo, organizationList, languageList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const lang = currentFormLanguage.language.name;

    const getTranslations = useCallback(data => dispatch({type: FORM_TRANSLATIONS.REQUEST, payload: data}), [dispatch]);

    const currentForm = formsList ? formsList.find(item => item.id === Number(formId)) : null;

    const updateFormTranslation = useCallback(
        data => dispatch({type: FORM_TRANSLATION_UPDATE.REQUEST, payload: data}),
        [dispatch],
    );

    const handleSaveTranslation = (values: TranslationFormType) => {
        if (values && values.trans !== EMPTY_DATA) {
            updateFormTranslation({
                ...values,
                id: formId,
                callback: () => getTranslations({id: formId, lang}),
            });
        }
    };

    useEffect(() => {
        if (isActive) {
            getTranslations({id: formId, lang});
        }
    }, [formId, lang, isActive]);

    const translations: FormTranslationIntentResultType[] = currentForm
        ? getPageItemsToTranslate(currentForm, formInfo, page)
        : [];

    if (!isActive || !myOrganization) return null;

    const renderTranslations = (item: FormTranslationIntentResultType, index: number) => {
        if ((item as FormTranslationIntent).children) {
            const intentItem = item as FormTranslationIntent;
            return (
                <Box sx={{mb: 1}} key={`formTranslationTable-intent-${intentItem.title}-${lang}-${index}`}>
                    <Typography fontWeight={600}>{t(intentItem.title)}:</Typography>
                    <Box sx={{pl: 2, mt: 1}}>{intentItem.children.map(renderTranslations)}</Box>
                </Box>
            );
        }

        const translationItem = item as FormTranslationType;
        const {description, english} = translationItem;

        const organizationLanguage = getLanguageById(languageList, myOrganization.language);

        return (
            <Box sx={{mb: 2}} key={`formTranslationTable-row-${lang}-${index}`}>
                <Typography fontWeight={600}>{t(description)}:</Typography>
                <Grid container alignItems={'center'} ml={1} width="calc(100% - 8px)">
                    <Grid item sm={3} sx={{py: 1}}>
                        {organizationLanguage && <Typography variant="body2">{organizationLanguage.lang}</Typography>}
                    </Grid>
                    <Grid item sm={9} sx={{overflow: 'hidden'}}>
                        {english && english !== EMPTY_DATA ? (
                            <MarkdownWithShowAll text={english} />
                        ) : (
                            <Typography sx={{fontSize: 14}}>{t('common.none')}</Typography>
                        )}
                    </Grid>
                    <Grid item sm={3}>
                        <Typography variant="body2">{`${t('common.translation')} - ${lang?.toUpperCase()}`}</Typography>
                    </Grid>
                    <Grid item sm={9}>
                        <PageTranslateInput
                            item={translationItem}
                            lang={lang}
                            handleSaveTranslation={handleSaveTranslation}
                            viewOnly={viewOnly}
                            multiline
                        />
                    </Grid>
                </Grid>
            </Box>
        );
    };

    return (
        <Box sx={{borderColor: theme.palette.info.main, borderWidth: 2, borderRadius: 2, borderStyle: 'solid', p: 1}}>
            {Array.isArray(translations) && translations.map(renderTranslations)}
        </Box>
    );
};

export default FormPageTranslate;
