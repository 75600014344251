import {NotificationType} from 'appRedux/actions/notifications/types';
import {
    GET_NOTIFICATIONS_LIST,
    CREATE_NOTIFICATION,
    REVIEW_NOTIFICATION,
    REMOVE_NOTIFICATION,
    REMOVE_ALL_NOTIFICATIONS,
} from 'appRedux/actions/notifications';

export const initialState: NotificationState = {
    isLoading: false,
    isNotificationLoading: false,
    notifications: [],
    error: undefined,
};

export interface NotificationState {
    isLoading: boolean;
    isNotificationLoading: boolean;
    notifications: NotificationType[];
    error: IErrors | undefined;
}

const notifications = (state = initialState, action: IActionType<NotificationType[] | IErrors>): NotificationState => {
    switch (action.type) {
        case GET_NOTIFICATIONS_LIST.REQUEST:
        case CREATE_NOTIFICATION.REQUEST:
        case REVIEW_NOTIFICATION.REQUEST:
        case REMOVE_NOTIFICATION.REQUEST:
        case REMOVE_ALL_NOTIFICATIONS.REQUEST: {
            return {
                ...state,
                isNotificationLoading: true,
                error: undefined,
            };
        }

        case GET_NOTIFICATIONS_LIST.SUCCESS:
        case CREATE_NOTIFICATION.SUCCESS:
        case REVIEW_NOTIFICATION.SUCCESS:
        case REMOVE_NOTIFICATION.SUCCESS:
        case REMOVE_ALL_NOTIFICATIONS.SUCCESS: {
            return {
                ...state,
                isNotificationLoading: false,
                notifications: action.payload as NotificationType[],
            };
        }

        case GET_NOTIFICATIONS_LIST.ERROR:
        case CREATE_NOTIFICATION.ERROR:
        case REVIEW_NOTIFICATION.ERROR:
        case REMOVE_NOTIFICATION.ERROR:
        case REMOVE_ALL_NOTIFICATIONS.ERROR: {
            return {
                ...state,
                isNotificationLoading: false,
                error: (action.payload as IErrors) || {},
            };
        }

        default:
            return state;
    }
};

export default notifications;
