import React, {FC, useState} from 'react';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {SelectChangeEvent} from '@mui/material/Select';

import {BoardHasStatusItemTypes, WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';

import {theme} from 'config/theme';

interface WorkflowStatusesSelectorsTypes {
    statuses: BoardHasStatusItemTypes[];
    setStatuses: (values: BoardHasStatusItemTypes[]) => void;
    workflowStatuses: WorkflowStatusItemTypes[];
}

const WorkflowStatusesSelectors: FC<WorkflowStatusesSelectorsTypes> = ({setStatuses, statuses, workflowStatuses}) => {
    const [, setIsUpdated] = useState<boolean>(false);

    const onStatusChecked = (event: SelectChangeEvent) => {
        const workflowStatusId = Number(event.target.value);
        const isChecked = statuses.some(item => item.workflowStatus === workflowStatusId);
        if (isChecked) {
            for (let i = 0, n = statuses.length; i < n; i++) {
                const userHasRole = statuses[i];
                if (userHasRole.workflowStatus === workflowStatusId) {
                    statuses.splice(i, 1);
                    setStatuses(statuses);
                    setIsUpdated(previous => !previous);
                    break;
                }
            }
        } else {
            setStatuses([
                ...statuses,
                {
                    workflowStatus: workflowStatusId,
                },
            ]);
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}
        >
            {workflowStatuses &&
                workflowStatuses.map((item: WorkflowStatusItemTypes) => {
                    const {uuid, title, id} = item;
                    const isChecked = statuses.some(item => item.workflowStatus === id);
                    return (
                        <FormControlLabel
                            key={`workflow-status-checkbox-${uuid}`}
                            sx={{
                                width: '24%',
                            }}
                            control={
                                <Checkbox
                                    name={'workflowStatuses'}
                                    value={id}
                                    checked={isChecked}
                                    onChange={onStatusChecked}
                                    sx={{
                                        pt: 0.5,
                                        pb: 0.5,
                                        '& svg': {color: theme.palette.info.main},
                                    }}
                                />
                            }
                            label={title}
                        />
                    );
                })}
        </Box>
    );
};

export default WorkflowStatusesSelectors;
