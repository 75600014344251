import React, {FC} from 'react';

import Box from '@mui/material/Box';

import {ResourceFieldBookframeItemTypes} from 'appRedux/actions/resourceFields/types';

import BookframesListItem from 'pages/admin/updateResourceField/bookframes/BookframesListItem';

interface BookframesListType {
    items: ResourceFieldBookframeItemTypes[];
}

const BookframesList: FC<BookframesListType> = ({items}) => {
    return (
        <Box sx={{mt: 3}}>
            {items &&
                items.map((item: ResourceFieldBookframeItemTypes) => {
                    return <BookframesListItem key={`boolframe-${item.uuid}`} item={item} />;
                })}
        </Box>
    );
};

export default BookframesList;
