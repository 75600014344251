import React, {FC, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {GET_WORKFLOW_GRAPH} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

interface WorkflowGraphType {
    uuid: string;
    currentLanguage: string;
}

const WorkflowGraph: FC<WorkflowGraphType> = ({uuid, currentLanguage}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const wrapperRef = useRef<HTMLDivElement>();

    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [minHeight, setMinHeight] = useState(0);

    const getWorkflowGraph = useCallback(
        data => dispatch({type: GET_WORKFLOW_GRAPH.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        workflow: {workflowStatusesList, isGraphLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        getWorkflowGraph({
            uuid,
            lang: currentLanguage,
            showAlert,
            callback: setImageSrc,
        });
    }, [uuid, currentLanguage, workflowStatusesList, setMinHeight]);

    const handleImgLoad = () => {
        // get height of first and only child
        const minHeight = (wrapperRef.current?.children[0] as HTMLElement)?.offsetHeight;
        minHeight && setMinHeight(minHeight);
    };

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 1,
                mb: 3,
                minHeight: minHeight + 'px',
            }}
            ref={wrapperRef}
        >
            {isGraphLoading ? (
                <Typography>{t('common.pleaseWait')}</Typography>
            ) : imageSrc ? (
                <img src={imageSrc} height={'100%'} width={'auto'} onLoad={handleImgLoad} />
            ) : null}
        </Box>
    );
};

export default WorkflowGraph;
