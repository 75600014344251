import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const SecuritySvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.14013 16.6919L10 18.75L13.8599 16.6919C14.9603 16.1065 15.8806 15.2325 16.5219 14.1636C17.1632 13.0948 17.5013 11.8715 17.5 10.625V2.5C17.4997 2.16859 17.3678 1.85085 17.1335 1.6165C16.8992 1.38216 16.5814 1.25035 16.25 1.25H3.75C3.41859 1.25035 3.10085 1.38216 2.8665 1.6165C2.63216 1.85085 2.50035 2.16859 2.5 2.5V10.625C2.49867 11.8715 2.8368 13.0948 3.4781 14.1636C4.1194 15.2325 5.03967 16.1065 6.14013 16.6919ZM3.75 10.625V2.5H16.25V10.625C16.2511 11.6449 15.9744 12.6458 15.4497 13.5203C14.9249 14.3949 14.1719 15.11 13.2715 15.5889L10 17.3333L6.7285 15.5889C5.82808 15.11 5.07509 14.3949 4.55034 13.5203C4.0256 12.6458 3.74892 11.6449 3.75 10.625ZM7.13125 8.75L8.75 10.3688L12.8687 6.25L13.75 7.13125L8.75 12.1313L6.25 9.63125L7.13125 8.75Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default SecuritySvgIcon;
