import React, {FC, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {FormVersionTypes} from 'appRedux/actions/forms/types';

import {PermissionContext} from 'contexts/permission/context';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import UpdateFormVersionForm from 'components/Forms/FormBuilder/FormVersion/UpdateFormVersionForm';

interface EditFormVersionButtonType {
    item: FormVersionTypes;
}

const EditFormVersionButton: FC<EditFormVersionButtonType> = ({item}) => {
    const [t] = useTranslation();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    const {isFormEditEnabled} = useContext(PermissionContext);

    return (
        <>
            <IconButton
                title={t('common.buttons.update')}
                disabled={!isFormEditEnabled}
                onClick={toggleModal}
                sx={{ml: 2, mt: '-5px'}}
            >
                <EditSvgIcon />
            </IconButton>
            <ModalWrapper isShowModal={showModal} toggleModal={toggleModal} title={t('orguser.forms.updateVersion')}>
                <UpdateFormVersionForm item={item} closeModal={() => setShowModal(false)} />
            </ModalWrapper>
        </>
    );
};

export default EditFormVersionButton;
