import React, {FC} from 'react';

interface IconTypeSvgType {
    isActive?: boolean;
}

const IconType_08: FC<IconTypeSvgType> = ({isActive}) => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 5H9V2C8.99956 1.60231 8.84139 1.22103 8.56018 0.939822C8.27897 0.658612 7.89769 0.500437 7.5 0.5H6.4336C6.19314 0.500964 5.96099 0.588141 5.77934 0.745698C5.59768 0.903254 5.47855 1.12074 5.4436 1.35865L5.02095 4.3169L3.2324 7H0V14H10.5C11.4279 13.999 12.3176 13.6299 12.9737 12.9737C13.6299 12.3176 13.999 11.4279 14 10.5V7C13.9994 6.46975 13.7885 5.96139 13.4136 5.58644C13.0386 5.2115 12.5303 5.0006 12 5ZM3 13H1V8H3V13ZM13 10.5C12.9992 11.1628 12.7356 11.7983 12.2669 12.2669C11.7983 12.7356 11.1628 12.9992 10.5 13H4V7.65135L5.9789 4.6831L6.4336 1.5H7.5C7.63258 1.50011 7.75969 1.55282 7.85344 1.64656C7.94718 1.74031 7.99989 1.86742 8 2V6H12C12.2651 6.00033 12.5193 6.10579 12.7067 6.29326C12.8942 6.48072 12.9997 6.73489 13 7V10.5Z"
                fill={isActive ? '#ffffff' : '#222222'}
            />
        </svg>
    );
};

export default IconType_08;
