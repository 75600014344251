import React, {FC} from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {DEFAULT_NONE} from 'config/index';

interface ActivityInformationItemType {
    title: string;
    value: string | null;
}

const ActivityInformationItem: FC<ActivityInformationItemType> = ({title, value}) => {
    return (
        <Grid container sx={{mb: 2}}>
            <Grid item sm={3}>
                <Typography sx={{fontWeight: 600}}>{title}</Typography>
            </Grid>
            <Grid item sm={9}>
                <Typography sx={{overflowX: 'hidden'}}>{value ? value : DEFAULT_NONE}</Typography>
            </Grid>
        </Grid>
    );
};

export default ActivityInformationItem;
