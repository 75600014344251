import {fork, put, take, call} from 'redux-saga/effects';

import {http} from 'services/http';

import {
    ORGANIZATION_PRIVACY_POLICY_LIST,
    getOrganizationPrivacyPolicyList,
    CREATE_ORGANIZATION_PRIVACY_POLICY,
    createOrganizationPrivacyPolicy,
    UPDATE_ORGANIZATION_PRIVACY_POLICY,
    updateOrganizationPrivacyPolicy,
    TOGGLE_ORGANIZATION_PRIVACY_POLICY_DEFAULT,
    toggleOrganizationPrivacyPolicyDefault,
    CREATE_ORGANIZATION_PRIVACY_POLICY_VERSION,
    createOrganizationPrivacyPolicyVersion,
} from 'appRedux/actions/organization';
import {
    OrganizationPrivacyPolicyRequestTypes,
    OrganizationPrivacyPolicyResponseTypes,
    OrganizationPrivacyPolicyCreateRequestTypes,
    OrganizationPrivacyPolicyUpdateRequestTypes,
    OrganizationPrivacyPolicyToggleDefaultRequestTypes,
    OrganizationPrivacyPolicyVersionCreateRequestTypes,
} from 'appRedux/actions/organization/types';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchOrganizationPrivacyPoliciesListRequest() {
    while (true) {
        const {
            payload: {id, language},
        }: IActionType<OrganizationPrivacyPolicyRequestTypes> = yield take(ORGANIZATION_PRIVACY_POLICY_LIST.REQUEST);
        try {
            const data: OrganizationPrivacyPolicyResponseTypes = yield call(
                http,
                `organization/policy/${id}/list/${language}`,
                {
                    method: 'GET',
                },
            );
            if (data.success) {
                yield put(getOrganizationPrivacyPolicyList.success(data.results));
            } else {
                yield put(getOrganizationPrivacyPolicyList.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getOrganizationPrivacyPolicyList.error({message: String(e)}));
        }
    }
}

function* watchOrganizationPrivacyPolicyCreation() {
    while (true) {
        const {
            payload: {id, showAlert, callback, ...values},
        }: IActionType<OrganizationPrivacyPolicyCreateRequestTypes> = yield take(
            CREATE_ORGANIZATION_PRIVACY_POLICY.REQUEST,
        );
        try {
            const data: OrganizationPrivacyPolicyResponseTypes = yield call(http, `organization/policy/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createOrganizationPrivacyPolicy.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(createOrganizationPrivacyPolicy.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(createOrganizationPrivacyPolicy.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationPrivacyPolicyUpdating() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback, ...values},
        }: IActionType<OrganizationPrivacyPolicyUpdateRequestTypes> = yield take(
            UPDATE_ORGANIZATION_PRIVACY_POLICY.REQUEST,
        );
        try {
            const data: OrganizationPrivacyPolicyResponseTypes = yield call(
                http,
                `organization/policy/${uuid}/update`,
                {
                    method: 'POST',
                    body: JSON.stringify(values),
                },
            );
            if (data.success) {
                yield put(updateOrganizationPrivacyPolicy.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(updateOrganizationPrivacyPolicy.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(updateOrganizationPrivacyPolicy.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationPrivacyPolicyDefaultToggling() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback, ...values},
        }: IActionType<OrganizationPrivacyPolicyToggleDefaultRequestTypes> = yield take(
            TOGGLE_ORGANIZATION_PRIVACY_POLICY_DEFAULT.REQUEST,
        );
        try {
            const data: OrganizationPrivacyPolicyResponseTypes = yield call(
                http,
                `organization/policy/${uuid}/default`,
                {
                    method: 'POST',
                    body: JSON.stringify(values),
                },
            );
            if (data.success) {
                yield put(toggleOrganizationPrivacyPolicyDefault.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(toggleOrganizationPrivacyPolicyDefault.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(toggleOrganizationPrivacyPolicyDefault.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationPrivacyPolicyVersionCreation() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback, ...values},
        }: IActionType<OrganizationPrivacyPolicyVersionCreateRequestTypes> = yield take(
            CREATE_ORGANIZATION_PRIVACY_POLICY_VERSION.REQUEST,
        );
        try {
            const data: OrganizationPrivacyPolicyResponseTypes = yield call(
                http,
                `organization/policy/version/${uuid}/create`,
                {
                    method: 'POST',
                    body: JSON.stringify(values),
                },
            );
            if (data.success) {
                yield put(createOrganizationPrivacyPolicyVersion.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(createOrganizationPrivacyPolicyVersion.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(createOrganizationPrivacyPolicyVersion.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchOrganizationPrivacyPoliciesListRequest),
    fork(watchOrganizationPrivacyPolicyCreation),
    fork(watchOrganizationPrivacyPolicyUpdating),
    fork(watchOrganizationPrivacyPolicyDefaultToggling),
    fork(watchOrganizationPrivacyPolicyVersionCreation),
];
