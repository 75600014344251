import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ProgressSvgIcon from 'assets/icons/buttons/ProgressSvgIcon';

import {AGENT_LIST, GET_WAITING_APPROVE_CASES} from 'appRedux/actions/admin';
import {RequesterCaseResponseTypes} from 'appRedux/actions/admin/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import RequesterCaseBlock from 'components/RequesterCaseBlocks/RequesterCaseBlock';

interface CasesWaitingApproveListType {
    isPinnedListUpdated: boolean;
    togglePinnedListUpdated: () => void;
}

const CasesWaitingApproveList: FC<CasesWaitingApproveListType> = ({isPinnedListUpdated, togglePinnedListUpdated}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isCaseViewEnabled} = useContext(PermissionContext);

    const getCasesWaitingApprove = useCallback(
        data => dispatch({type: GET_WAITING_APPROVE_CASES.REQUEST, payload: data}),
        [dispatch],
    );

    const getAgents = useCallback(() => dispatch({type: AGENT_LIST.REQUEST}), [dispatch]);

    const {
        admin: {casesWaitingApproveList},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        if (isCaseViewEnabled) {
            getAgents();
            getCasesWaitingApprove({
                showAlert,
            });
        }
    }, [isPinnedListUpdated]);

    const currentUserEmail = get(profile, 'email', null);

    if (!isCaseViewEnabled || (casesWaitingApproveList && casesWaitingApproveList.length === 0)) {
        return null;
    }
    return (
        <Box sx={{mt: 4}}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    mb: 3.5,
                }}
            >
                <Typography sx={{fontWeight: 600, mr: 1}}>{t('orguser.dashboard.casesWaitingApprove')}</Typography>
                <ProgressSvgIcon />
            </Box>
            {casesWaitingApproveList &&
                casesWaitingApproveList.map((item: RequesterCaseResponseTypes) => {
                    return (
                        <RequesterCaseBlock
                            key={`waiting-approve-case-${item.caseId}`}
                            item={item}
                            currentUserEmail={currentUserEmail}
                            permittedUuids={get(item, 'permittedUuids', [])}
                            toggleAction={togglePinnedListUpdated}
                            pinnedCasesList
                        />
                    );
                })}
        </Box>
    );
};

export default CasesWaitingApproveList;
