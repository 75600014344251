import * as yup from 'yup';

export const validation = (t: (trans: string) => string, availableAmount: number) =>
    yup.object({
        amount: yup
            .number()
            .required('messages.validation.isRequired')
            .min(1, t('messages.validation.minimalAmountValue').replace('%NUMBER%', String(1)))
            .max(
                availableAmount,
                t('messages.validation.maximalAmountValue').replace('%NUMBER%', String(availableAmount)),
            ),
    });

export const validationForClientMode = (t: (trans: string) => string, availableAmount: number) =>
    yup.object({
        amountUser: yup
            .number()
            .required('messages.validation.isRequired')
            .min(1, t('messages.validation.minimalAmountValue').replace('%NUMBER%', String(1)))
            .max(
                availableAmount,
                t('messages.validation.maximalAmountValue').replace('%NUMBER%', String(availableAmount)),
            ),
    });
