import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {ResourceFieldBudgetInformationType} from 'appRedux/actions/forms/types';

import {getResourceFieldBudgetTypeLabel} from 'components/Forms/ResourceField/helper';

import {getBudgetAmountValue} from 'helpers/resourceFieldsHelper';

interface BudgetAvailabilityInformationType {
    resourceFieldBudgetInformation: ResourceFieldBudgetInformationType;
}

const BudgetAvailabilityInformation: FC<BudgetAvailabilityInformationType> = ({resourceFieldBudgetInformation}) => {
    const [t] = useTranslation();

    const {type, amount} = resourceFieldBudgetInformation;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
        >
            <Typography sx={{fontWeight: 700, fontSize: 16, mr: 1}}>{`${t('requester.casePage.availableBudget')} (${t(
                getResourceFieldBudgetTypeLabel(type),
            ).toLowerCase()}):`}</Typography>
            <Typography sx={{fontSize: 14}}>{getBudgetAmountValue(type, amount)}</Typography>
        </Box>
    );
};

export default BudgetAvailabilityInformation;
