import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {GET_FILE_URL} from 'appRedux/actions/profile';
import {CHAT_DOCUMENT_URL} from 'appRedux/actions/requestChat';

import {RouteContext} from 'contexts/route/context';
import {CaseKeyContext} from 'contexts/caseKey/context';

import {CommonFileType, FILE_CHAT} from 'pages/agent/requesterPage/partials/helper';

import {getEncryptedFileData} from 'helpers/cryptoApiHelper';

import {theme} from 'config/theme';
import ThumbItem, {ThumbSize} from 'components/ClientScreenComponents/ThumbItem';

interface FileThumbListItemType {
    file: CommonFileType;
    downloadFile: (url: string, fileName?: string) => void;
    getModalContent: (file: CommonFileType) => void;
    toggleIsModalOpened: () => void;
    deleteNotPossible?: boolean;
}

const FileThumbListItem: FC<FileThumbListItemType> = ({
    file,
    getModalContent,
    deleteNotPossible,
    downloadFile,
    toggleIsModalOpened,
}) => {
    const dispatch = useDispatch();

    const {isClientMode} = useContext(RouteContext);
    const {unwrappedCaseKey} = useContext(CaseKeyContext);

    const getFileUrl = useCallback(data => dispatch({type: GET_FILE_URL.REQUEST, payload: data}), [dispatch]);

    const getChatFileUrl = useCallback(data => dispatch({type: CHAT_DOCUMENT_URL.REQUEST, payload: data}), [dispatch]);

    const {type, title, extension, uuid, thumbLink, encryptPrefix} = file;

    const onPreviewClicked = (file: CommonFileType) => {
        toggleIsModalOpened();
        getModalContent(file);
    };

    const downloadEncryptedFile = async (url: string) => {
        if (encryptPrefix) {
            const decryptedFileSource = await getEncryptedFileData(url, encryptPrefix, unwrappedCaseKey);
            if (decryptedFileSource) {
                downloadFile(decryptedFileSource, `${title}.${extension}`);
            }
        }
    };

    const handleDownloadFile = async () => {
        const getFileAction = type === FILE_CHAT ? getChatFileUrl : getFileUrl;
        getFileAction({
            uuid,
            callback: encryptPrefix ? await downloadEncryptedFile : downloadFile,
        });
    };

    const [thumbSrc, setThumbSrc] = useState<string | null | undefined>();

    useEffect(() => {
        const downloadEncryptedThumb = async (url: string) => {
            return await getEncryptedFileData(url, 'data:image/jpeg;base64,', unwrappedCaseKey);
        };

        const getThumbLink = async () => {
            if (thumbLink) return unwrappedCaseKey ? downloadEncryptedThumb(thumbLink) : thumbLink;
            return '';
        };

        getThumbLink().then(link => {
            setThumbSrc(link);
        });
    }, [thumbLink]);

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <ThumbItem
                uuid={uuid}
                currentFile={file}
                selectFile={onPreviewClicked}
                downloadFile={handleDownloadFile}
                isUserOwner={isClientMode && !deleteNotPossible}
                thumbSrc={thumbSrc}
                thumbSize={ThumbSize.MEDIUM}
            />

            <Typography
                onClick={() => onPreviewClicked(file)}
                sx={{
                    ml: 'initial',
                    fontWeight: 300,
                    cursor: 'pointer',
                    color: theme.palette.primary.main,
                }}
            >
                {title}
            </Typography>
        </Box>
    );
};

export default FileThumbListItem;
