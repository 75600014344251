import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import CreateWorkflowStatusForm from 'components/Forms/Workflow/workflowStatus/CreateWorkflowStatusForm';

interface AddWorkflowStatusType {
    uuid: string;
    currentLanguage: string;
}

const AddWorkflowStatus: FC<AddWorkflowStatusType> = ({uuid, currentLanguage}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <>
            <AgentSaveButton isSubmit={false} title={t('orguser.workflows.addStatus')} onClick={toggleIsOpened} />
            <ModalWrapper isShowModal={isOpened} title={t('orguser.workflows.addStatus')} toggleModal={toggleIsOpened}>
                <CreateWorkflowStatusForm
                    workflowUuid={uuid}
                    closeModal={() => setIsOpened(false)}
                    currentLanguage={currentLanguage}
                />
            </ModalWrapper>
        </>
    );
};

export default AddWorkflowStatus;
