import React, {FC, useCallback, useContext} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import {FormVersionStructureFormType, FormVersionTypes} from 'appRedux/actions/forms/types';
import {UPDATE_FORM_VERSION} from 'appRedux/actions/forms';

import {AlertContext} from 'contexts/alert/context';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import {formVersionValidationSchema} from 'components/Forms/FormBuilder/FormVersion/validation';

import {preventSendForm} from 'helpers/index';

interface UpdateFormVersionFormType {
    item: FormVersionTypes;
    closeModal: () => void;
    toRelease?: boolean;
}

const UpdateFormVersionForm: FC<UpdateFormVersionFormType> = ({item, closeModal, toRelease}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {uuid, title, isActive, description} = item;

    const updateFormVersion = useCallback(
        data => dispatch({type: UPDATE_FORM_VERSION.REQUEST, payload: data}),
        [dispatch],
    );

    return (
        <Box>
            <Formik
                initialValues={{
                    title,
                    description,
                    isActive,
                }}
                validationSchema={formVersionValidationSchema}
                onSubmit={values => {
                    updateFormVersion({
                        uuid,
                        toRelease: toRelease,
                        ...values,
                        showAlert,
                        callback: closeModal,
                    });
                }}
            >
                {(formik: FormikProps<FormVersionStructureFormType>) => {
                    return (
                        <Form onKeyDown={preventSendForm}>
                            <Box sx={{mr: 1, mb: 2}}>
                                <Field
                                    required
                                    name="title"
                                    label={t('orguser.forms.title')}
                                    component={FormikTextInput}
                                />
                            </Box>
                            <Box sx={{mr: 1, mb: 2}}>
                                <Field
                                    required
                                    name="description"
                                    label={t('orguser.forms.description')}
                                    component={FormikTextInput}
                                    rows={4}
                                    multiline
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mt: 2,
                                }}
                            >
                                <AgentInverseButton title={t('common.buttons.cancel')} onClick={closeModal} />
                                <AgentSaveButton isSubmit />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default UpdateFormVersionForm;
