import React, {FC, useCallback, useContext} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

import {AgentsWithCaseAccessTypes, CaseAccessRequestCreateFormType} from 'appRedux/actions/crypto/types';
import {CREATE_CASE_ACCESS_REQUEST} from 'appRedux/actions/crypto';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AvatarImage from 'components/UserAvatar/AvatarImage';
import {getAvatarTitle, getUserName} from 'components/TopBarMenu/helper';

import {routes} from 'config/index';

interface CaseAccessRequestFormType {
    caseId: number;
    closeModal: () => void;
}

const CaseAccessRequestForm: FC<CaseAccessRequestFormType> = ({caseId, closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        requestCase: {
            currentCase: {formPrefix, id, userName},
        },
        crypto: {isLoading, agentsWithCaseAccess},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onSubmitClicked = useCallback(
        data => dispatch({type: CREATE_CASE_ACCESS_REQUEST.REQUEST, payload: data}),
        [dispatch],
    );

    return (
        <Formik
            initialValues={{
                agent: 0,
                notificationText: t('orguser.accessRequests.caseAccessRequestCreatedNotification')
                    .replace('%USER%', getUserName(profile))
                    .replace('%CASE%', `${formPrefix}-${id} | ${userName}`),
                relatedLink: `${routes.CASE_ACCESS_REQUEST}/%UUID%/edit`,
            }}
            onSubmit={values => {
                onSubmitClicked({
                    ...values,
                    caseId,
                    callback: closeModal,
                    showAlert,
                });
            }}
        >
            {(formik: FormikProps<CaseAccessRequestCreateFormType>) => {
                return (
                    <Form>
                        <FormControl fullWidth>
                            <Select
                                name="agent"
                                fullWidth
                                value={formik.values.agent}
                                variant="standard"
                                onChange={formik.handleChange}
                                sx={{
                                    '& div': {
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                    },
                                }}
                                IconComponent={KeyboardArrowDownIcon}
                            >
                                {agentsWithCaseAccess &&
                                    agentsWithCaseAccess.map((agent: AgentsWithCaseAccessTypes, index: number) => {
                                        const {avatarType, avatarLink, firstName, lastName, email} = agent;
                                        const avatarTitle = getAvatarTitle(firstName, lastName, email);
                                        return (
                                            <MenuItem
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    mb: 1,
                                                }}
                                                key={`agent-${index}`}
                                                value={agent.id}
                                            >
                                                <AvatarImage
                                                    size={25}
                                                    avatarType={avatarType}
                                                    avatarLink={avatarLink}
                                                    avatarTitle={avatarTitle}
                                                />
                                                <Typography sx={{ml: 1, fontWeight: 600}}>
                                                    {firstName && lastName ? `${firstName} ${lastName}` : email}
                                                </Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                            <Box
                                sx={{
                                    mt: 3,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <AgentInverseButton title={t('common.buttons.cancel')} onClick={closeModal} />
                                <AgentSaveButton isLoading={isLoading} isSubmit title={t('common.buttons.confirm')} />
                            </Box>
                        </FormControl>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CaseAccessRequestForm;
