import React, {FC} from 'react';

import Box from '@mui/material/Box';

import LoginForms from 'pages/start/login/partials/LoginForms';
import LoginPageWrapper from 'pages/start/login/partials/LoginPageWrapper';

const LoginPageContent: FC = () => {
    return (
        <LoginPageWrapper>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    mt: 4,
                }}
            >
                <LoginForms />
            </Box>
        </LoginPageWrapper>
    );
};

export default LoginPageContent;
