import {requestTypeCreator} from 'helpers/index';

import {
    RequesterCaseCommentsRequestTypes,
    RequesterCaseAddCommentRequestTypes,
    RequesterCaseUpdateCommentRequestTypes,
    RequesterCaseRemoveCommentRequestTypes,
    RequesterCaseCommentsTypes,
} from 'appRedux/actions/comments/types';

export const GET_REQUESTER_CASE_COMMENTS = requestTypeCreator('GET_REQUESTER_CASE_COMMENTS');
export const ADD_REQUESTER_CASE_COMMENT = requestTypeCreator('ADD_REQUESTER_CASE_COMMENT');
export const UPDATE_REQUESTER_CASE_COMMENT = requestTypeCreator('UPDATE_REQUESTER_CASE_COMMENT');
export const REMOVE_REQUESTER_CASE_COMMENT = requestTypeCreator('REMOVE_REQUESTER_CASE_COMMENT');

export const getRequesterCaseComments = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_REQUESTER_CASE_COMMENTS.ERROR,
    }),
    request: (payload: RequesterCaseCommentsRequestTypes): IActionType<RequesterCaseCommentsRequestTypes> => ({
        payload,
        type: GET_REQUESTER_CASE_COMMENTS.REQUEST,
    }),
    success: (payload: RequesterCaseCommentsTypes[]): IActionType<RequesterCaseCommentsTypes[]> => ({
        payload,
        type: GET_REQUESTER_CASE_COMMENTS.SUCCESS,
    }),
};

export const addRequesterCaseComment = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ADD_REQUESTER_CASE_COMMENT.ERROR,
    }),
    request: (payload: RequesterCaseAddCommentRequestTypes): IActionType<RequesterCaseAddCommentRequestTypes> => ({
        payload,
        type: ADD_REQUESTER_CASE_COMMENT.REQUEST,
    }),
    success: (payload: RequesterCaseCommentsTypes[]): IActionType<RequesterCaseCommentsTypes[]> => ({
        payload,
        type: ADD_REQUESTER_CASE_COMMENT.SUCCESS,
    }),
};

export const updateRequesterCaseComment = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_REQUESTER_CASE_COMMENT.ERROR,
    }),
    request: (
        payload: RequesterCaseUpdateCommentRequestTypes,
    ): IActionType<RequesterCaseUpdateCommentRequestTypes> => ({
        payload,
        type: UPDATE_REQUESTER_CASE_COMMENT.REQUEST,
    }),
    success: (payload: RequesterCaseCommentsTypes[]): IActionType<RequesterCaseCommentsTypes[]> => ({
        payload,
        type: UPDATE_REQUESTER_CASE_COMMENT.SUCCESS,
    }),
};

export const deleteRequesterCaseComment = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REMOVE_REQUESTER_CASE_COMMENT.ERROR,
    }),
    request: (
        payload: RequesterCaseRemoveCommentRequestTypes,
    ): IActionType<RequesterCaseRemoveCommentRequestTypes> => ({
        payload,
        type: REMOVE_REQUESTER_CASE_COMMENT.REQUEST,
    }),
    success: (payload: RequesterCaseCommentsTypes[]): IActionType<RequesterCaseCommentsTypes[]> => ({
        payload,
        type: REMOVE_REQUESTER_CASE_COMMENT.SUCCESS,
    }),
};
