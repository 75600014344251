import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {RequesterCaseTagTypes} from 'appRedux/actions/requestCase/types';
import {REQUEST_CASE_REMOVE_TAG} from 'appRedux/actions/requestCase';

import {AlertContext} from 'contexts/alert/context';

import TagChipWithDeleting from 'components/TagChip/TagChipWithDeleting';

interface TagItemType {
    item: RequesterCaseTagTypes;
    isDeleteEnabled: boolean;
}

const TagItem: FC<TagItemType> = ({item, isDeleteEnabled}) => {
    const dispatch = useDispatch();

    const deleteTagToRequesterCase = useCallback(
        data => dispatch({type: REQUEST_CASE_REMOVE_TAG.REQUEST, payload: data}),
        [dispatch],
    );

    const {id, tag, color} = item;

    const {showAlert} = useContext(AlertContext);

    const onDeleteClick = () => {
        deleteTagToRequesterCase({
            id,
            showAlert,
        });
    };

    return (
        <TagChipWithDeleting
            id={String(id)}
            title={tag}
            color={color}
            onDeleteClick={isDeleteEnabled ? onDeleteClick : undefined}
        />
    );
};

export default TagItem;
