import * as yup from 'yup';

export const createOrganizationSsoValidationSchema = yup.object({
    authServer: yup.string().required('Auth server is required'),
    realm: yup.string().required('Realm is required'),
    client: yup.string().required('Client ID is required'),
    clientSecret: yup.string().required('Client secret is required'),
});

export const updateOrganizationSsoValidationSchema = yup.object({
    authServer: yup.string().required('Auth server is required'),
    realm: yup.string().required('Realm is required'),
    client: yup.string().required('Client ID is required'),
});

export const updateOrganizationSsoClientSecretValidationSchema = yup.object({
    clientSecret: yup.string().required('Client secret is required'),
});
