import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import {RootReducer} from 'appRedux/reducers';

import {
    getResourceFieldBudgetTypeLabel,
    getResourceFieldBudgetResetIntervalTypeLabel,
} from 'components/Forms/ResourceField/helper';

import {getBudgetAmountValue} from 'helpers/resourceFieldsHelper';

const BudgetInformation: FC = () => {
    const [t] = useTranslation();

    const {
        resourceFields: {currentBudgetItem},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    if (!currentBudgetItem) return null;

    const {title, description, type, amount, resetInterval} = currentBudgetItem;

    return (
        <Box>
            <Grid container sx={{mb: 2}}>
                <Grid item sm={3}>
                    <Typography sx={{fontWeight: 600}}>{t('orguser.resourceFields.title')}</Typography>
                </Grid>
                <Grid item sm={9}>
                    <Typography>{title}</Typography>
                </Grid>
            </Grid>
            <Grid container sx={{mb: 2}}>
                <Grid item sm={3}>
                    <Typography sx={{fontWeight: 600}}>{t('orguser.resourceFields.description')}</Typography>
                </Grid>
                <Grid item sm={9}>
                    <Typography>{description}</Typography>
                </Grid>
            </Grid>
            <Grid container sx={{mb: 2}}>
                <Grid item sm={3}>
                    <Typography sx={{fontWeight: 600}}>{t('orguser.resourceFields.type')}</Typography>
                </Grid>
                <Grid item sm={9}>
                    <Typography>{t(getResourceFieldBudgetTypeLabel(type))}</Typography>
                </Grid>
            </Grid>
            <Grid container sx={{mb: 2}}>
                <Grid item sm={3}>
                    <Typography sx={{fontWeight: 600}}>{t('orguser.resourceFields.amount')}</Typography>
                </Grid>
                <Grid item sm={9}>
                    <Typography>{getBudgetAmountValue(type, amount)}</Typography>
                </Grid>
            </Grid>
            <Grid container sx={{mb: 2}}>
                <Grid item sm={3}>
                    <Typography sx={{fontWeight: 600}}>{t('orguser.resourceFields.resetInterval')}</Typography>
                </Grid>
                <Grid item sm={9}>
                    <Typography>{t(getResourceFieldBudgetResetIntervalTypeLabel(resetInterval))}</Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default BudgetInformation;
