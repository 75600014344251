import React, {FC, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import get from 'lodash/get';

import {FORM_BASE_INFORMATION, FORM_BASE_INFORMATION_AUTH} from 'appRedux/actions/auth';
import {SET_ERRORS} from 'appRedux/actions/errors';
import {RootReducer} from 'appRedux/reducers';

import RegistrationForm from 'components/RegistrationForm/RegistrationForm';

import RegistrationOrLoginViaSso from 'pages/start/registration/RegistrationOrLoginViaSso';

const StartScreen: FC = () => {
    const dispatch = useDispatch();
    const {form} = useParams();

    const refreshErrors = useCallback(data => dispatch({type: SET_ERRORS.GENERAL_ERROR, payload: data}), [dispatch]);

    const getFormBaseInformation = useCallback(
        data => dispatch({type: FORM_BASE_INFORMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getFormBaseInformationAuth = useCallback(
        data => dispatch({type: FORM_BASE_INFORMATION_AUTH.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        auth,
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        refreshErrors(null);
        if (profile) {
            getFormBaseInformationAuth({
                url: form,
            });
        } else {
            getFormBaseInformation({
                url: form,
            });
        }
    }, []);

    const organization = get(auth, 'organization', false);

    if (organization && !profile) {
        const {isAutoSsoEnabled, isClosed} = organization;
        return isAutoSsoEnabled && isClosed ? <RegistrationOrLoginViaSso form={String(form)} /> : <RegistrationForm />;
    }
    return <RegistrationForm />;
};

export default StartScreen;
