import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {OrganizationPdfTemplatesTypes} from 'appRedux/actions/organization/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import UpdatePdfTemplateForm from 'components/Forms/OrganizationPdfTemplateForm/UpdatePdfTemplateForm';

import {theme} from 'config/theme';

interface UploadPdfTemplateInfoType {
    currentTemplate: OrganizationPdfTemplatesTypes;
}

const UploadPdfTemplateInfo: FC<UploadPdfTemplateInfoType> = ({currentTemplate}) => {
    const [t] = useTranslation();

    const [isFormVisible, setIsFormVisible] = useState<boolean>(false);

    const toggleIsFormVisible = () => {
        setIsFormVisible(previous => !previous);
    };

    const {title, description} = currentTemplate;

    return (
        <Box>
            <ModalWrapper
                title={t('superadmin.organizations.pdfTemplates.updatePdfTemplate')}
                isShowModal={isFormVisible}
                toggleModal={toggleIsFormVisible}
            >
                <UpdatePdfTemplateForm currentTemplate={currentTemplate} closeModal={toggleIsFormVisible} />
            </ModalWrapper>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <Box>
                    <Typography sx={{fontWeight: 600, mr: 2}}>{`${t(
                        'superadmin.organizations.pdfTemplates.title',
                    )}:`}</Typography>
                    <Typography sx={{fontWeight: 600, mr: 2}}>{`${t(
                        'superadmin.organizations.pdfTemplates.description',
                    )}:`}</Typography>
                </Box>
                <Box>
                    <Typography>{title}</Typography>
                    <Typography>{description}</Typography>
                </Box>
                <IconButton
                    title={t('common.buttons.update')}
                    onClick={toggleIsFormVisible}
                    sx={{
                        ml: 4,
                        backgroundColor: theme.palette.info.main,
                        '&:hover': {
                            backgroundColor: theme.palette.info.main,
                        },
                    }}
                >
                    <EditSvgIcon isActive />
                </IconButton>
            </Box>
        </Box>
    );
};

export default UploadPdfTemplateInfo;
