import React, {FC, useContext} from 'react';
import parse from 'html-react-parser';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {MediaContext} from 'contexts/media/context';

import {theme} from 'config/theme';

interface TextWrappedLabelType {
    text: string;
    color?: string;
}

const TextWrappedLabel: FC<TextWrappedLabelType> = ({text, color}) => {
    const {isMobile} = useContext(MediaContext);

    const textStyle = {
        mb: 1,
        fontSize: isMobile ? 12 : 14,
        lineHeight: '22px',
        color: color ?? theme.palette.info.main,
    };

    if (!text) {
        return null;
    }
    if (!text.includes('\n')) {
        return (
            <Typography align="justify" sx={textStyle}>
                {parse(text)}
            </Typography>
        );
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {String(text)
                .split('\n')
                .map((textRow, index) => {
                    if (textRow.includes('<li>')) {
                        return null;
                    }
                    return (
                        <Typography align="justify" key={`label-${index}`} sx={textStyle}>
                            {parse(textRow)}
                        </Typography>
                    );
                })}
        </Box>
    );
};

export default TextWrappedLabel;
