import React, {FC, useContext} from 'react';
import {useSelector} from 'react-redux';
import get from 'lodash/get';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RequesterCaseTagTypes} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';
import {FormTagType} from 'appRedux/actions/forms/types';

import {PermissionContext} from 'contexts/permission/context';

import AddTagModalForm from 'pages/agent/requesterPage/tags/AddTagModalForm';
import TagItem from 'pages/agent/requesterPage/tags/TagItem';

import {checkIsCaseEditEnabled} from 'helpers/permissions';

const TagsList: FC = () => {
    const [t] = useTranslation();
    const {formId} = useParams();

    const {isCaseEditEnabled, filtersCaseEditAllowed} = useContext(PermissionContext);

    const {
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const formTags: FormTagType[] = get(currentCase, 'formTags', []);

    const tags: RequesterCaseTagTypes[] = get(currentCase, 'tags', []);

    const isEditEnabled = checkIsCaseEditEnabled(isCaseEditEnabled, filtersCaseEditAllowed, Number(formId));

    if (formTags && formTags.length > 0) {
        return (
            <Box sx={{m: 2, mb: 3, pl: 1}}>
                {tags &&
                    tags.map((item: RequesterCaseTagTypes, index: number) => {
                        return <TagItem key={`requester-tag-${index}`} item={item} isDeleteEnabled={isEditEnabled} />;
                    })}
                {isEditEnabled && <AddTagModalForm />}
            </Box>
        );
    }
    return (
        <Box sx={{m: 2, mb: 3, pl: 1}}>
            <Typography>{t('orguser.requesterCase.tags.formWithoutTags')}</Typography>
        </Box>
    );
};

export default TagsList;
