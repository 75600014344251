import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import IconButton from '@mui/material/IconButton';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {NotificationType} from 'appRedux/actions/notifications/types';
import {REMOVE_NOTIFICATION} from 'appRedux/actions/notifications';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';

interface DeleteNotificationButtonType {
    item: NotificationType;
}

const DeleteNotificationButton: FC<DeleteNotificationButtonType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    const {uuid} = item;

    const removeNotification = useCallback(
        data => dispatch({type: REMOVE_NOTIFICATION.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickAction = () => {
        removeNotification({
            uuid,
            showAlert,
            callback: () => setIsShowModal(false),
        });
    };

    return (
        <>
            <IconButton onClick={toggleModal} title={t('common.buttons.delete')}>
                <DeleteSvgIcon />
            </IconButton>
            <DeleteAdminConfirmationModal
                isShowModal={isShowModal}
                toggleModal={toggleModal}
                onClickAction={onClickAction}
            />
        </>
    );
};

export default DeleteNotificationButton;
