import * as yup from 'yup';

import {REGEX_FOR_TITLES, REGEX_FOR_DESCRIPTION} from 'components/Forms/FormBuilder/helper';

export const formPageInitialValues = {
    title: '',
    description: '',
};

const PAGE_TITLE_MINIMAL_LENGTH = 2;
const PAGE_TITLE_MAXIMAL_LENGTH = 100;

export const formPageValidationSchema = (t: (trans: string) => string) =>
    yup.object({
        title: yup
            .string()
            .required('messages.validation.isRequired')
            .matches(REGEX_FOR_TITLES, 'messages.validation.valueStructureValidationLatinNumbersSpecialAllowed')
            .min(
                PAGE_TITLE_MINIMAL_LENGTH,
                t('messages.validation.valueMinimalLength').replace('%NUMBER%', String(PAGE_TITLE_MINIMAL_LENGTH)),
            )
            .max(
                PAGE_TITLE_MAXIMAL_LENGTH,
                t('messages.validation.valueMaximalLength').replace('%NUMBER%', String(PAGE_TITLE_MAXIMAL_LENGTH)),
            ),
        description: yup
            .string()
            .matches(REGEX_FOR_DESCRIPTION, 'messages.validation.valueStructureValidationLatinNumbersSpecialAllowed')
            .nullable(),
    });

export const formValidationSchemaNotOnlyLatin = (t: (trans: string) => string) =>
    yup.object({
        title: yup
            .string()
            .required('messages.validation.isRequired')
            .min(
                PAGE_TITLE_MINIMAL_LENGTH,
                t('messages.validation.valueMinimalLength').replace('%NUMBER%', String(PAGE_TITLE_MINIMAL_LENGTH)),
            )
            .max(
                PAGE_TITLE_MAXIMAL_LENGTH,
                t('messages.validation.valueMaximalLength').replace('%NUMBER%', String(PAGE_TITLE_MAXIMAL_LENGTH)),
            ),
        description: yup.string().nullable(),
    });
