import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link as RouterLink} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import ResetPasswordForm from 'pages/start/resetPassword/ResetPasswordForm';
import LoginPageWrapper from 'pages/start/login/partials/LoginPageWrapper';

import {theme} from 'config/theme';
import {routes} from 'config/index';

const ResetPassword = () => {
    const [t] = useTranslation();
    return (
        <LoginPageWrapper>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    mt: 4,
                }}
            >
                <ResetPasswordForm />
                <Link
                    data-id={`link#passwordreset-login`}
                    to={routes.START}
                    sx={{mt: 1, textDecoration: 'none'}}
                    variant="body2"
                    component={RouterLink}
                >
                    <Typography
                        sx={{
                            color: theme.palette.info.light,
                        }}
                    >
                        {t('common.loginForm.login')}
                    </Typography>
                </Link>
            </Box>
        </LoginPageWrapper>
    );
};

export default ResetPassword;
