import * as yup from 'yup';

export const initialValues = {
    value: '',
    isDomain: false,
};

export const validationSchema = yup.object({
    value: yup.string().email('messages.validation.emailIncorrect').required('messages.validation.emailRequired'),
});

export const validationSchemaDomain = yup.object({
    value: yup.string().required('messages.validation.domainRequired'),
});
