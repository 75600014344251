import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {CREATE_BOARD_SWIMLANE} from 'appRedux/actions/workflow';
import {CreateSwimlaneBoardRequestTypes} from 'appRedux/actions/workflow/types';

import {AlertContext} from 'contexts/alert/context';

import SwimlaneBoardForm from 'components/Forms/BoardForm/SwimlaneBoardForm';
import {initialValuesForSwimlane} from 'components/Forms/BoardForm/validation';

interface CreateSwimlaneBoardFormType {
    closeModal: () => void;
}

const CreateSwimlaneBoardForm: FC<CreateSwimlaneBoardFormType> = ({closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const createBoard = useCallback(data => dispatch({type: CREATE_BOARD_SWIMLANE.REQUEST, payload: data}), [dispatch]);

    const onSubmitClick = (values: CreateSwimlaneBoardRequestTypes) => {
        createBoard({
            ...values,
            showAlert,
            callback: closeModal,
        });
    };

    return <SwimlaneBoardForm initialValues={initialValuesForSwimlane} onSubmitClick={onSubmitClick} isCreate />;
};

export default CreateSwimlaneBoardForm;
