import React, {FC, useCallback, useContext, useEffect} from 'react';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {GET_PINNED_CASES, AGENT_LIST} from 'appRedux/actions/admin';
import {RequesterCaseResponseTypes} from 'appRedux/actions/admin/types';

import PinSvgIcon from 'assets/icons/buttons/PinSvgIcon';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import RequesterCaseBlock from 'components/RequesterCaseBlocks/RequesterCaseBlock';

interface PinnedCasesListType {
    isApproveWaitingListUpdated: boolean;
    toggleApproveListUpdated: () => void;
}

const PinnedCasesList: FC<PinnedCasesListType> = ({isApproveWaitingListUpdated, toggleApproveListUpdated}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isCaseViewEnabled} = useContext(PermissionContext);

    const getPinnedCases = useCallback(data => dispatch({type: GET_PINNED_CASES.REQUEST, payload: data}), [dispatch]);

    const getAgents = useCallback(() => dispatch({type: AGENT_LIST.REQUEST}), [dispatch]);

    const {
        admin: {pinnedRequesterCasesList},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        if (isCaseViewEnabled) {
            getAgents();
            getPinnedCases({
                showAlert,
            });
        }
    }, [isApproveWaitingListUpdated]);

    const currentUserEmail = get(profile, 'email', null);

    if (!isCaseViewEnabled) {
        return null;
    }
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    mb: 3.5,
                }}
            >
                <Typography sx={{fontWeight: 600, mr: 1}}>{t('orguser.dashboard.yourPinnedCases')}</Typography>
                <PinSvgIcon />
            </Box>
            {pinnedRequesterCasesList &&
                pinnedRequesterCasesList.map((item: RequesterCaseResponseTypes) => {
                    return (
                        <RequesterCaseBlock
                            key={`pinned-case-${item.caseId}`}
                            item={item}
                            currentUserEmail={currentUserEmail}
                            permittedUuids={get(item, 'permittedUuids', [])}
                            toggleAction={toggleApproveListUpdated}
                            pinnedCasesList
                        />
                    );
                })}
        </Box>
    );
};

export default PinnedCasesList;
