import React, {useState} from 'react';

import LoginFirstStep from 'pages/start/login/partials/LoginFirstStep';
import LoginSecondStep from 'pages/start/login/partials/LoginSecondStep';
import {LOGIN_FIRST_STEP, LOGIN_SECOND_STEP} from 'pages/start/login/helper';

const LoginForms = () => {
    const [loginStep, setLoginStep] = useState<string>(LOGIN_FIRST_STEP);
    const [email, setEmail] = useState<string>('');

    return (
        <>
            {loginStep === LOGIN_FIRST_STEP && <LoginFirstStep setLoginStep={setLoginStep} setEmail={setEmail} />}
            {loginStep === LOGIN_SECOND_STEP && <LoginSecondStep email={email} />}
        </>
    );
};

export default LoginForms;
