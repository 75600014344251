import React, {FC, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';

import {RequesterCaseApproveTypes, StatusChangeApproveUpdateFormType} from 'appRedux/actions/requestCase/types';
import {UPDATE_STATUS_CHANGE_APPROVE_REQUEST} from 'appRedux/actions/requestCase';

import {AlertContext} from 'contexts/alert/context';

import CommentTestField from 'components/Forms/CommentForm/CommentTextField';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {commentValidation} from 'components/Forms/RequesterCase/validation';
import {
    CASE_STATUS_CHANGE_APPROVED,
    CASE_STATUS_CHANGE_REJECTED,
    CASE_STATUS_CHANGE_DEFAULT,
} from 'components/Forms/RequesterCase/helper';

interface ComponentType {
    statusChangeRequest: RequesterCaseApproveTypes;
}

const StatusChangingApproveForm: FC<ComponentType> = ({statusChangeRequest}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const formRef = useRef<FormikProps<StatusChangeApproveUpdateFormType> | null>(null);
    const commentFieldInput = useRef<HTMLInputElement | null>(null);

    const [approveStatus, setApproveStatus] = useState<number>(CASE_STATUS_CHANGE_DEFAULT);

    const {showAlert} = useContext(AlertContext);

    const {uuid} = statusChangeRequest;

    const updateApproveRequests = useCallback(
        data => dispatch({type: UPDATE_STATUS_CHANGE_APPROVE_REQUEST.REQUEST, payload: data}),
        [dispatch],
    );

    const saveComment = (value: number) => {
        setApproveStatus(value);
        if (formRef && formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const onSubmitClicked = (values: StatusChangeApproveUpdateFormType) => {
        updateApproveRequests({
            uuid,
            showAlert,
            ...values,
            status: approveStatus,
        });
    };

    useEffect(() => {
        if (commentFieldInput && commentFieldInput.current) {
            commentFieldInput.current.focus();
        }
    }, []);

    return (
        <Box sx={{width: '100%', mt: 2}}>
            <Formik
                innerRef={formRef}
                initialValues={{
                    comment: '',
                    status: CASE_STATUS_CHANGE_DEFAULT,
                }}
                validationSchema={() => commentValidation(t)}
                onSubmit={onSubmitClicked}
                enableReinitialize
            >
                {(formik: FormikProps<StatusChangeApproveUpdateFormType>) => {
                    return (
                        <Form>
                            <Field
                                inputRef={commentFieldInput}
                                required
                                name="comment"
                                label={t('orguser.requesterCase.comments.comment')}
                                placeholder={t('orguser.requesterCase.comments.addComment')}
                                component={CommentTestField}
                                multiline
                                rows={4}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mt: 3,
                                }}
                            >
                                <AgentSaveButton
                                    isSubmit={false}
                                    onClick={() => saveComment(CASE_STATUS_CHANGE_APPROVED)}
                                    title={t('common.buttons.approve')}
                                />
                                <AgentSaveButton
                                    isSubmit={false}
                                    onClick={() => saveComment(CASE_STATUS_CHANGE_REJECTED)}
                                    title={t('common.buttons.reject')}
                                    isDanger
                                />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default StatusChangingApproveForm;
