import React, {DragEvent, useRef} from 'react';

import Box from '@mui/material/Box';

import {ADMIN_LIGHT_GRAY_COLOR, CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface FormVersionCardFieldPropsType<T extends string | number | null> {
    fieldId: T;
    priority?: number;
    label?: string | null;
    viewOnly?: boolean;
    active?: boolean;
    cardField: boolean;
    setDragItem: React.Dispatch<React.SetStateAction<T>>;
    setDropTo?: React.Dispatch<React.SetStateAction<number | 'delete' | null>>;
    dragFieldId?: string | number | null;
    onDrop?: () => void;
    onDragEnd?: () => void;
}

function FormVersionCardField<T extends string | number | null>({
    fieldId,
    priority,
    label,
    viewOnly,
    active,
    cardField,
    setDragItem,
    setDropTo,
    dragFieldId,
    onDrop,
    onDragEnd,
}: FormVersionCardFieldPropsType<T>) {
    const dropRef = useRef<HTMLDivElement>();
    const dragEndTimeoutRef = useRef<NodeJS.Timeout>();

    const onDrag = () => {
        dragEndTimeoutRef.current && clearTimeout(dragEndTimeoutRef.current);
        dragEndTimeoutRef.current = setTimeout(() => {
            setDragItem(null as T);
        }, 100);
    };

    const onDragStart = () => {
        setDragItem(fieldId);
        if (priority !== undefined) {
            setTimeout(() => {
                setDropTo && setDropTo(priority);
            }, 0);
        }
    };

    const onDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
    };

    const onDragEnter = () => {
        if (priority !== undefined) {
            setTimeout(() => {
                setDropTo && setDropTo(priority);
            }, 0);
        }
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        onDrop && onDrop();
    };

    return (
        <Box
            ref={dropRef}
            sx={{
                width: '100%',
                mb: 1,
                border: `1px solid ${ADMIN_LIGHT_GRAY_COLOR}`,
                p: 1,
                textAlign: 'center',
                borderRadius: 2,
                cursor: 'grab',
                opacity: active ? 0.5 : 1,
                background: active ? CLIENT_BACKGROUND_COLOR : 'white',
                fontWeight: 600,
                display: 'block',
                maxWidth: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
            draggable={!viewOnly}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDrag={onDrag}
            onDrop={typeof dragFieldId === 'string' || cardField ? handleDrop : undefined}
            onDragEnter={typeof dragFieldId === 'string' || cardField ? onDragEnter : undefined}
            onDragOver={typeof dragFieldId === 'string' || cardField ? onDragOver : undefined}
            id={`draggable-form-card-field-${fieldId}`}
        >
            {label}
        </Box>
    );
}

export default FormVersionCardField;
