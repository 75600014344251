import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {createStyles, WithStyles, withStyles} from '@material-ui/core';

import {RequesterCaseBudgetFormTypes} from 'appRedux/actions/requestCase/types';

import {RouteContext} from 'contexts/route/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {DATEPICKER_FORMAT} from 'config/index';
import {theme, ERROR_TEXT_COLOR} from 'config/theme';

interface RequesterCaseBudgetInputCalendarFormType {
    availableAmount: number;
    initialValues: RequesterCaseBudgetFormTypes;
    onSubmitClicked: (values: RequesterCaseBudgetFormTypes) => void;
    isDisabled?: boolean;
    isCreate?: boolean;
}

const styles = () => {
    const commonStyles = {
        fontSize: 16,
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        padding: 0,
        height: 42,
        width: '100%',
        '&:hover': {
            borderColor: '#222222',
            borderWidth: 1.5,
        },
        '&:focus': {
            outline: 'none',
            borderColor: '#222222',
            borderWidth: 2,
        },
    };

    return createStyles({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        datepicker: {
            ...commonStyles,
            backgroundColor: `rgba(34, 34, 34, 0.05)`,
            borderBottomColor: `rgba(34, 34, 34, 0.05)`,
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        datepickerClient: {
            ...commonStyles,
            backgroundColor: '#ffffff',
            borderBottomColor: '#c4c4c4',
        },
    });
};

const getInitialDate = (initialValues: RequesterCaseBudgetFormTypes, isClientMode: boolean) => {
    const {startAt, endAt, startUserAt, endUserAt} = initialValues;
    const startDate = isClientMode ? startUserAt : startAt;
    const endDate = isClientMode ? endUserAt : endAt;

    if (!startDate && !endDate) return [new Date(), new Date()];

    const dateStringArray = [startDate, endDate];
    return dateStringArray.map(string => {
        const dateInFormat = moment(string).format('YYYY-MM-DD');
        const dateObject = new Date(dateInFormat);
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth();
        const date = dateObject.getDate();

        return new Date(year, month, date);
    });
};

const RequesterCaseBudgetInputCalendarForm: FC<
    RequesterCaseBudgetInputCalendarFormType & WithStyles<typeof styles>
> = ({classes, availableAmount, initialValues, onSubmitClicked, isDisabled, isCreate}) => {
    const [t] = useTranslation();

    const formRef = useRef<FormikProps<RequesterCaseBudgetFormTypes> | null>(null);

    const {isClientMode} = useContext(RouteContext);

    const [isError, setIsError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [dateRange, setDateRange] = useState<Date[]>(getInitialDate(initialValues, isClientMode));
    const [amount, setAmount] = useState<number>(0);
    const [, setIsUpdated] = useState<boolean>(false);

    const onChange = (index: number) => date => {
        if (date) {
            setIsError(false);
            setDateRange(prev => {
                const newValue = [...prev];
                newValue[index] = date;
                return newValue;
            });
        }
    };

    const checkAndSubmit = () => {
        const startDate = moment(dateRange[0]);
        const endDate = moment(dateRange[1]);
        const dateDifference = endDate.diff(startDate, 'days');
        if (dateDifference < 1) {
            setIsError(true);
            setErrorMessage(t('messages.validation.dateDifference'));
        } else if (dateDifference > availableAmount) {
            setIsError(true);
            setErrorMessage(t('messages.validation.dateDifferenceMoreAvailableAmount'));
        } else if (formRef && formRef.current) {
            setAmount(dateDifference);
            formRef.current.handleSubmit();
        }
    };

    useEffect(() => {
        setDateRange(getInitialDate(initialValues, isClientMode));
        setIsUpdated(previous => !previous);
    }, [initialValues]);

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            enableReinitialize
            onSubmit={values => {
                if (isClientMode) {
                    onSubmitClicked({
                        ...values,
                        amountUser: amount,
                        startUserAt: moment(dateRange[0]).format('YYYY-MM-DD'),
                        endUserAt: moment(dateRange[1]).format('YYYY-MM-DD'),
                    });
                } else {
                    onSubmitClicked({
                        ...values,
                        amount,
                        startAt: moment(dateRange[0]).format('YYYY-MM-DD'),
                        endAt: moment(dateRange[1]).format('YYYY-MM-DD'),
                    });
                }
            }}
        >
            {(formik: FormikProps<RequesterCaseBudgetFormTypes>) => {
                return (
                    <Form>
                        <Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: 'flex-end',
                                    mt: 3,
                                    mb: 2,
                                    '& .react-datepicker-wrapper': {
                                        flex: 1,
                                    },
                                }}
                            >
                                <Box>
                                    <Typography
                                        sx={{
                                            color: isError ? ERROR_TEXT_COLOR : theme.palette.info.main,
                                            fontSize: 12,
                                        }}
                                    >
                                        {t('requester.casePage.budgetDateRange.startAt')}
                                    </Typography>
                                    <DatePicker
                                        disabled={isDisabled}
                                        className={isClientMode ? classes.datepickerClient : classes.datepicker}
                                        selected={dateRange[0]}
                                        onChange={onChange(0)}
                                        dateFormat={DATEPICKER_FORMAT}
                                        showYearDropdown
                                    />
                                </Box>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        mb: '2px',
                                        mx: 2,
                                    }}
                                >
                                    &ndash;
                                </Typography>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: isError ? ERROR_TEXT_COLOR : theme.palette.info.main,
                                            fontSize: 12,
                                        }}
                                    >
                                        {t('requester.casePage.budgetDateRange.endAt')}
                                    </Typography>
                                    <DatePicker
                                        disabled={isDisabled}
                                        className={isClientMode ? classes.datepickerClient : classes.datepicker}
                                        selected={dateRange[1]}
                                        onChange={onChange(1)}
                                        onBlur={checkAndSubmit}
                                        dateFormat={DATEPICKER_FORMAT}
                                        showYearDropdown
                                    />
                                </Box>
                            </Box>
                            {isError && errorMessage && (
                                <Typography sx={{color: ERROR_TEXT_COLOR, fontSize: 14, mt: 1}}>
                                    {errorMessage}
                                </Typography>
                            )}
                        </Box>
                        {!isCreate && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 2,
                                }}
                            >
                                <AgentSaveButton isSubmit />
                            </Box>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default withStyles(styles)(RequesterCaseBudgetInputCalendarForm);
