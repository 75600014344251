import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import Typography from '@mui/material/Typography';

import {
    ClientTranslationTypes,
    ClientTranslationUpdatedItemType,
    ClientTranslationUpdatedType,
} from 'appRedux/actions/clientTranslation/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import ClientTranslationForm from 'components/Forms/ClientTranslationForm/ClientTranslationForm';

interface EditTranslationButtonType {
    fieldId: number;
    popupItem?: number;
    sectionId: number;
    initialText: string;
    currentTranslations: ClientTranslationUpdatedItemType[];
    currentTranslation?: ClientTranslationTypes;
    setClientTranslations: (value: ClientTranslationUpdatedType) => void;
    addTranslation?: boolean;
}

const EditTranslationButton: FC<EditTranslationButtonType> = ({
    fieldId,
    popupItem,
    sectionId,
    initialText,
    currentTranslations,
    currentTranslation,
    setClientTranslations,
    addTranslation,
}) => {
    const [t] = useTranslation();
    const {caseId} = useParams();

    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    const updateCallback = () => {
        setIsShowModal(false);
        const otherTranslations = currentTranslations.filter(
            (item: ClientTranslationUpdatedItemType) => item.field !== fieldId,
        );
        setClientTranslations({
            section: sectionId,
            translations: otherTranslations,
        });
    };

    return (
        <>
            <Typography sx={{fontWeight: 600, fontSize: 12, cursor: 'pointer', mr: 2}} onClick={toggleModal}>
                {t(addTranslation ? 'common.buttons.translate' : 'common.buttons.update')}
            </Typography>
            <ModalWrapper
                title={t(
                    addTranslation
                        ? 'orguser.requesterCase.translation.addTranslation'
                        : 'orguser.requesterCase.translation.editTranslations',
                )}
                isShowModal={isShowModal}
                toggleModal={toggleModal}
            >
                <Typography sx={{fontSize: 12, mb: 2}}>{initialText}</Typography>
                <ClientTranslationForm
                    fieldId={fieldId}
                    popupItem={popupItem}
                    initialText={initialText}
                    caseId={Number(caseId)}
                    currentTranslation={currentTranslation}
                    closeModal={updateCallback}
                />
            </ModalWrapper>
        </>
    );
};

export default EditTranslationButton;
