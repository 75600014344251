import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {GridColDef} from '@mui/x-data-grid';

import {DraftRemoveItemTypes} from 'appRedux/actions/superadmin/types';

import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';

import {DATE_TIME_FORMAT} from 'config/index';

interface DraftRemovesDataGridType {
    itemsList: DraftRemoveItemTypes[];
}

const DraftRemovesDataGrid: FC<DraftRemovesDataGridType> = ({itemsList}) => {
    const [t] = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'organization',
            headerName: t('superadmin.draftRemoves.organization'),
            minWidth: 200,
        },
        {
            field: 'form',
            headerName: t('superadmin.draftRemoves.form'),
            minWidth: 200,
            valueGetter: ({row}) => `${row.form} (${row.version})`,
        },
        {
            field: 'requester',
            headerName: t('superadmin.draftRemoves.requester'),
            minWidth: 200,
            valueGetter: ({row}) => `${row.requester} (${row.email})`,
        },
        {
            field: 'requestCreatedAt',
            headerName: t('superadmin.draftRemoves.requestCreatedAt'),
            width: 200,
            valueGetter: ({value}) => moment(value).format(DATE_TIME_FORMAT),
        },
        {
            field: 'removedAt',
            headerName: t('superadmin.draftRemoves.removedAt'),
            width: 200,
            valueGetter: ({value}) => moment(value).format(DATE_TIME_FORMAT),
        },
    ];

    return (
        <CustomDataGrid
            rows={itemsList}
            columns={columns}
            getRowId={row => row.uuid}
            checkboxSelection={false}
            enablePaging
            paginationMode="server"
        />
    );
};

export default DraftRemovesDataGrid;
