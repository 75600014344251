import React, {FC, useContext} from 'react';
import {useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';

import {RootReducer} from 'appRedux/reducers';

import {MediaContext} from 'contexts/media/context';

import {theme} from 'config/theme';

interface LoadingButtonType {
    label: string | JSX.Element | JSX.Element[];
    'data-id'?: string;
}

const LoadingStartButton: FC<LoadingButtonType> = ({label, 'data-id': data_id}) => {
    const {isMobile} = useContext(MediaContext);

    const {auth} = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                mt: 1,
                mb: 2,
            }}
        >
            <LoadingButton
                type="submit"
                data-id={data_id}
                fullWidth
                variant="contained"
                loading={auth.isLoading}
                disabled={auth.isLoading}
                disableRipple
                sx={{
                    width: isMobile ? '60vw' : 180,
                    height: 48,
                    backgroundColor: theme.palette.info.main,
                    borderRadius: 24,
                    '&:hover': {
                        backgroundColor: theme.palette.info.main,
                    },
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 600,
                        textTransform: 'none',
                        color: theme.palette.background.default,
                    }}
                >
                    {label}
                </Typography>
            </LoadingButton>
        </Box>
    );
};

export default LoadingStartButton;
