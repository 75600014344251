import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import {Typography} from '@mui/material';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {ClientTranslationUpdatedType} from 'appRedux/actions/clientTranslation/types';
import {RootReducer} from 'appRedux/reducers';

import {FORM_FIELD_TEXT_INPUT, FORM_FIELD_TEXTAREA} from 'pages/admin/updateForm/partials/FormStructure/helper';
import ClientTranslation from 'pages/agent/requesterPage/translation/ClientTranslation';
import {getCurrentTranslation} from 'pages/agent/requesterPage/partials/helper';
import TranslationButton from 'pages/agent/requesterPage/translation/TranslationButton';

interface FieldTranslationType {
    field: FormFieldTypes;
    sectionId: number;
    showTranslation: boolean;
    editTranslation: boolean;
    clientTranslations?: ClientTranslationUpdatedType;
    setClientTranslations: (value: ClientTranslationUpdatedType) => void;
    currentValue: string;
    popupItem?: number;
}

const FieldTranslation: FC<FieldTranslationType> = ({
    field,
    currentValue,
    clientTranslations,
    setClientTranslations,
    sectionId,
    editTranslation,
    showTranslation,
    popupItem,
}) => {
    const [t] = useTranslation();

    const {
        requestCase: {
            currentCase: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    if (!showTranslation || !field) return null;

    if (field.type === FORM_FIELD_TEXT_INPUT || field.type === FORM_FIELD_TEXTAREA) {
        const currentTranslation = translations
            ? getCurrentTranslation(translations, sectionId, field.id, popupItem)
            : undefined;
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    width: '100%',
                }}
            >
                <TranslationButton currentTranslation={currentTranslation} />
                <ClientTranslation
                    sectionId={sectionId}
                    fieldId={field.id}
                    editTranslation={editTranslation}
                    clientTranslations={clientTranslations}
                    currentTranslation={currentTranslation}
                    setClientTranslations={setClientTranslations}
                    currentValue={currentValue}
                    popupItem={popupItem}
                />
            </Box>
        );
    } else {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    width: '100%',
                }}
            >
                <TranslationButton />
                <Box
                    sx={{
                        backgroundColor: `rgba(34, 34, 34, 0.05)`,
                        borderRadius: 2,
                        width: '100%',
                        p: 2,
                        mb: 3,
                    }}
                >
                    <Typography sx={{fontSize: 12}}>
                        {t('orguser.requesterCase.translation.noTranslatableFields')}
                    </Typography>
                </Box>
            </Box>
        );
    }
};

export default FieldTranslation;
