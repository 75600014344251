import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import CreateResourceFieldForm from 'components/Forms/ResourceField/CreateResourceFieldForm';

interface CreateWorkflowType {
    organizationId: number;
}

const CreateResourceField: FC<CreateWorkflowType> = ({organizationId}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                mb: 2,
            }}
        >
            <AgentInverseButton title={t('orguser.resourceFields.addResourceField')} onClick={toggleIsOpened} />
            <ModalWrapper
                isShowModal={isOpened}
                title={t('orguser.resourceFields.addResourceField')}
                toggleModal={toggleIsOpened}
            >
                <CreateResourceFieldForm organizationId={organizationId} closeModal={() => setIsOpened(false)} />
            </ModalWrapper>
        </Box>
    );
};

export default CreateResourceField;
