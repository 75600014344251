import {call, fork, put, take} from 'redux-saga/effects';

import {
    GET_ORGANIZATION_PUBLIC_KEYS,
    getOrganizationPublicKeys,
    REMOVE_ORGANIZATION_PUBLIC_KEYS,
    removeOrganizationPublicKey,
    GET_PUBLIC_KEYS_FOR_CASE_KEYS_GENERATION,
    getAllPublicKeysForCaseKeysGeneration,
    GET_PUBLIC_KEYS_FOR_OTHER_AGENT,
    getAllPublicKeysForOtherAgent,
    GET_CASE_KEYS,
    getCaseKeys,
    CREATE_CASE_KEY,
    createCaseKey,
    CREATE_PUBLIC_KEY,
    createPublicKey,
    UPDATE_PUBLIC_KEY,
    updatePublicKey,
    GET_USER_PUBLIC_KEYS,
    getUserPublicKeys,
    GET_USER_SAVED_KEYS,
    getUserSavedKeys,
    REMOVE_PUBLIC_KEY,
    removePublicKey,
    CREATE_CASE_ACCESS_REQUEST,
    createCaseAccessRequest,
    GET_AGENTS_WITH_CASE_ACCESS,
    getAgentsWithCaseAccess,
    GET_CASE_ACCESS_REQUESTS,
    getCaseAccessRequests,
    HANDLE_CASE_ACCESS_REQUEST,
    handleCaseAccessRequest,
    DELETE_CASE_ACCESS_REQUEST,
    deleteCaseAccessRequest,
    regenerateCaseKeys,
    REGENERATE_CASE_KEYS,
    REMOVE_USER_SAVED_KEYS,
    removeUserSavedKeys,
    saveUserKeys,
    SAVE_USER_KEYS,
} from 'appRedux/actions/crypto';

import {
    PublicKeyOrganizationRemoveRequestTypes,
    PublicKeysOrganizationListRequestTypes,
    PublicKeysOrganizationListResponseTypes,
    PublicKeyCreateRequestTypes,
    PublicKeyUpdateRequestTypes,
    PublicKeyResponseTypes,
    PublicKeysListRequestTypes,
    PublicKeyRemoveRequestTypes,
    PublicKeysForCaseKeysRequestTypes,
    PublicKeysForOtherAgentRequestTypes,
    PublicKeysForCaseKeysResponseTypes,
    CaseKeyCreateRequestTypes,
    CaseKeysRequestTypes,
    CaseKeyResponseTypes,
    CaseAccessRequestListType,
    CaseAccessRequestCreateType,
    CaseAccessRequestCreateResponseType,
    CaseAccessRequestHandleType,
    CaseAccessRequestDeleteType,
    CaseAccessRequestResponseTypes,
    AgentsWithCaseAccessResponseTypes,
    AgentsWithCaseAccessRequestType,
    SavedKeysListRequestTypes,
    SavedKeyResponseTypes,
} from 'appRedux/actions/crypto/types';

import {http} from 'services/http';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchPublicKeysGetting() {
    while (true) {
        const {
            payload: {showAlert},
        }: IActionType<PublicKeysOrganizationListRequestTypes> = yield take(GET_ORGANIZATION_PUBLIC_KEYS.REQUEST);
        try {
            const data: PublicKeysOrganizationListResponseTypes = yield call(http, `public/list`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getOrganizationPublicKeys.success(data.results));
            } else if (data.errors) {
                yield put(getOrganizationPublicKeys.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getOrganizationPublicKeys.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchPublicKeysRemoving() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, ...values},
        }: IActionType<PublicKeyOrganizationRemoveRequestTypes> = yield take(REMOVE_ORGANIZATION_PUBLIC_KEYS.REQUEST);
        try {
            const data: PublicKeysOrganizationListResponseTypes = yield call(http, `public/${uuid}/delete`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(removeOrganizationPublicKey.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(removeOrganizationPublicKey.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(removeOrganizationPublicKey.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchPublicKeyForCaseKeysGenerationGetting() {
    while (true) {
        const {
            payload: {showAlert, callback},
        }: IActionType<PublicKeysForCaseKeysRequestTypes> = yield take(
            GET_PUBLIC_KEYS_FOR_CASE_KEYS_GENERATION.REQUEST,
        );
        try {
            const data: PublicKeysForCaseKeysResponseTypes = yield call(http, `public/generation`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getAllPublicKeysForCaseKeysGeneration.success(data.results));
                callback && callback(data.results);
            } else if (data.errors) {
                yield put(getAllPublicKeysForCaseKeysGeneration.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getAllPublicKeysForCaseKeysGeneration.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchPublicKeyForOtherAgentGetting() {
    while (true) {
        const {
            payload: {showAlert, userId, callback},
        }: IActionType<PublicKeysForOtherAgentRequestTypes> = yield take(GET_PUBLIC_KEYS_FOR_OTHER_AGENT.REQUEST);
        try {
            const data: PublicKeysForCaseKeysResponseTypes = yield call(http, `public/${userId}/keys`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getAllPublicKeysForOtherAgent.success(data.results));
                callback && callback(data.results);
            } else if (data.errors) {
                yield put(getAllPublicKeysForOtherAgent.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getAllPublicKeysForOtherAgent.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchPublicKeyCreation() {
    while (true) {
        const {
            payload: {callback, ...values},
        }: IActionType<PublicKeyCreateRequestTypes> = yield take(CREATE_PUBLIC_KEY.REQUEST);
        try {
            const data: PublicKeyResponseTypes = yield call(http, `public/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                const result = data.results.find(r => r.data === values.data);
                if (!result?.uuid) yield put(createPublicKey.error({message: 'messages.error.somethingWentWrong'}));
                yield put(createPublicKey.success(data.results));
                result && callback && callback(result.uuid);
            } else if (data.errors) {
                yield put(createPublicKey.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(createPublicKey.error({message: String(e)}));
        }
    }
}

function* watchCaseKeysGetting() {
    while (true) {
        const {
            payload: {callback, id, showAlert},
        }: IActionType<CaseKeysRequestTypes> = yield take(GET_CASE_KEYS.REQUEST);
        try {
            const data: CaseKeyResponseTypes = yield call(http, `case/${id}/keys`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getCaseKeys.success(data.results));
                callback && callback(data.results);
            } else if (data.errors) {
                yield put(getCaseKeys.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getCaseKeys.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchCaseKeyCreation() {
    while (true) {
        const {
            payload: {callback, id, showAlert, ...values},
        }: IActionType<CaseKeyCreateRequestTypes> = yield take(CREATE_CASE_KEY.REQUEST);
        try {
            const data: CaseKeyResponseTypes = yield call(http, `case/${id}/key/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createCaseKey.success(data.results));
                callback && callback();
            } else if (data.errors) {
                yield put(createCaseKey.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createCaseKey.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchPublicKeyUpdating() {
    while (true) {
        const {
            payload: {callback, uuid, showAlert, ...values},
        }: IActionType<PublicKeyUpdateRequestTypes> = yield take(UPDATE_PUBLIC_KEY.REQUEST);
        try {
            const data: PublicKeyResponseTypes = yield call(http, `public/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updatePublicKey.success(data.results));

                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else if (data.errors) {
                yield put(updatePublicKey.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updatePublicKey.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchUserPublicKeysListGetting() {
    while (true) {
        const {
            payload: {showAlert},
        }: IActionType<PublicKeysListRequestTypes> = yield take(GET_USER_PUBLIC_KEYS.REQUEST);
        try {
            const data: PublicKeyResponseTypes = yield call(http, `public/current`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getUserPublicKeys.success(data.results));
            } else if (data.errors) {
                yield put(getUserPublicKeys.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getUserPublicKeys.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchPublicKeyRemoving() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, ...values},
        }: IActionType<PublicKeyRemoveRequestTypes> = yield take(REMOVE_PUBLIC_KEY.REQUEST);
        try {
            const data: PublicKeyResponseTypes = yield call(http, `public/${uuid}/delete`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(removePublicKey.success(data.results));
                callback && callback();
            } else if (data.errors) {
                yield put(removePublicKey.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(removePublicKey.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchUserSavedKeysGetting() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback},
        }: IActionType<SavedKeysListRequestTypes> = yield take(GET_USER_SAVED_KEYS.REQUEST);
        try {
            const data: SavedKeyResponseTypes = yield call(http, `encrypted/key/${uuid}/get`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getUserSavedKeys.success());
                callback && callback(data.result);
            } else if (data.errors) {
                yield put(getUserPublicKeys.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getUserSavedKeys.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchUserSavedKeysSaving() {
    while (true) {
        const {
            payload: {showAlert, uuid, encryptedKey, callback},
        }: IActionType<SavedKeysListRequestTypes> = yield take(SAVE_USER_KEYS.REQUEST);
        try {
            const data: SavedKeyResponseTypes = yield call(http, `encrypted/key/${uuid}/save`, {
                method: 'POST',
                body: JSON.stringify({encryptedKey}),
            });
            console.log('data:', data);
            if (data.success) {
                yield put(saveUserKeys.success());
                callback && callback(data.result);
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(saveUserKeys.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(saveUserKeys.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchUserSavedKeysRemoving() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback},
        }: IActionType<SavedKeysListRequestTypes> = yield take(REMOVE_USER_SAVED_KEYS.REQUEST);
        try {
            const data: SavedKeyResponseTypes = yield call(http, `encrypted/key/${uuid}/clear`, {
                method: 'POST',
            });
            if (data.success) {
                yield put(removeUserSavedKeys.success());
                callback && callback(data.result);
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(removeUserSavedKeys.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(removeUserSavedKeys.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchRegenerateCaseKeys() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, ...values},
        }: IActionType<PublicKeyRemoveRequestTypes> = yield take(REGENERATE_CASE_KEYS.REQUEST);
        try {
            const data: PublicKeyResponseTypes = yield call(http, `public/${uuid}/regenerate`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(regenerateCaseKeys.success(data.results));
                callback && callback();
            } else if (data.errors) {
                yield put(regenerateCaseKeys.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(regenerateCaseKeys.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchCaseAccessRequestsGetting() {
    while (true) {
        const {
            payload: {showAlert},
        }: IActionType<CaseAccessRequestListType> = yield take(GET_CASE_ACCESS_REQUESTS.REQUEST);
        try {
            const data: CaseAccessRequestResponseTypes = yield call(http, `access/requests`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getCaseAccessRequests.success(data.results));
            } else if (data.errors) {
                yield put(getCaseAccessRequests.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getCaseAccessRequests.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchAgentsWithCaseAccessGetting() {
    while (true) {
        const {
            payload: {caseId, showAlert},
        }: IActionType<AgentsWithCaseAccessRequestType> = yield take(GET_AGENTS_WITH_CASE_ACCESS.REQUEST);
        try {
            const data: AgentsWithCaseAccessResponseTypes = yield call(http, `case/${caseId}/agents`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getAgentsWithCaseAccess.success(data.results));
            } else if (data.errors) {
                yield put(getAgentsWithCaseAccess.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getAgentsWithCaseAccess.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchCaseAccessRequestCreation() {
    while (true) {
        const {
            payload: {showAlert, caseId, callback, ...values},
        }: IActionType<CaseAccessRequestCreateType> = yield take(CREATE_CASE_ACCESS_REQUEST.REQUEST);
        try {
            const data: CaseAccessRequestCreateResponseType = yield call(http, `case/${caseId}/access`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createCaseAccessRequest.success());
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else if (data.errors) {
                yield put(createCaseAccessRequest.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createCaseAccessRequest.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchCaseAccessRequestHandling() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback, ...values},
        }: IActionType<CaseAccessRequestHandleType> = yield take(HANDLE_CASE_ACCESS_REQUEST.REQUEST);
        try {
            const data: CaseAccessRequestResponseTypes = yield call(http, `access/request/${uuid}/handle`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(handleCaseAccessRequest.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else if (data.errors) {
                yield put(handleCaseAccessRequest.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(handleCaseAccessRequest.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchCaseAccessRequestDeleting() {
    while (true) {
        const {
            payload: {showAlert, uuid, callback},
        }: IActionType<CaseAccessRequestDeleteType> = yield take(DELETE_CASE_ACCESS_REQUEST.REQUEST);
        try {
            const data: CaseAccessRequestResponseTypes = yield call(http, `access/request/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteCaseAccessRequest.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else if (data.errors) {
                yield put(deleteCaseAccessRequest.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteCaseAccessRequest.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchPublicKeysGetting),
    fork(watchPublicKeysRemoving),
    fork(watchPublicKeyForCaseKeysGenerationGetting),
    fork(watchPublicKeyForOtherAgentGetting),
    fork(watchCaseKeysGetting),
    fork(watchCaseKeyCreation),
    fork(watchPublicKeyCreation),
    fork(watchPublicKeyUpdating),
    fork(watchUserPublicKeysListGetting),
    fork(watchUserSavedKeysGetting),
    fork(watchUserSavedKeysSaving),
    fork(watchUserSavedKeysRemoving),
    fork(watchPublicKeyRemoving),
    fork(watchCaseAccessRequestsGetting),
    fork(watchAgentsWithCaseAccessGetting),
    fork(watchCaseAccessRequestCreation),
    fork(watchCaseAccessRequestHandling),
    fork(watchCaseAccessRequestDeleting),
    fork(watchRegenerateCaseKeys),
];
