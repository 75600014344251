import React, {FC} from 'react';
import {CommonSvgIconType, getIconColor} from './helper';

const InfoSolidSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 1C6.61553 1 5.26215 1.41054 4.111 2.17971C2.95986 2.94888 2.06265 4.04213 1.53284 5.32122C1.00303 6.6003 0.864402 8.00776 1.1345 9.36563C1.4046 10.7235 2.07128 11.9708 3.05025 12.9497C4.02922 13.9287 5.2765 14.5954 6.63436 14.8655C7.99223 15.1356 9.3997 14.997 10.6788 14.4672C11.9579 13.9373 13.0511 13.0401 13.8203 11.889C14.5895 10.7378 15 9.38447 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85651 1 8 1ZM8 4C8.14833 4 8.29334 4.04399 8.41667 4.1264C8.54001 4.20881 8.63614 4.32594 8.6929 4.46299C8.74967 4.60003 8.76452 4.75083 8.73558 4.89632C8.70665 5.0418 8.63521 5.17544 8.53033 5.28033C8.42544 5.38522 8.2918 5.45665 8.14631 5.48559C8.00083 5.51453 7.85003 5.49968 7.71298 5.44291C7.57594 5.38614 7.4588 5.29001 7.37639 5.16668C7.29398 5.04334 7.25 4.89834 7.25 4.75C7.25 4.55109 7.32901 4.36032 7.46967 4.21967C7.61032 4.07902 7.80108 4 8 4ZM10 12.0625H6V10.9375H7.4375V8.0625H6.5V6.9375H8.5625V10.9375H10V12.0625Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default InfoSolidSvgIcon;
