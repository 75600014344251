import React, {FC} from 'react';

import Box from '@mui/material/Box';

import {ResourceFieldExceptionItemTypes} from 'appRedux/actions/resourceFields/types';

import ExceptionsListItem from 'pages/admin/updateResourceField/exception/ExceptionsListItem';

interface ExceptionsListType {
    items: ResourceFieldExceptionItemTypes[];
}

const ExceptionsList: FC<ExceptionsListType> = ({items}) => {
    return (
        <Box sx={{mt: 3}}>
            {items &&
                items.map((item: ResourceFieldExceptionItemTypes) => {
                    return <ExceptionsListItem key={`exception-${item.uuid}`} item={item} />;
                })}
        </Box>
    );
};

export default ExceptionsList;
