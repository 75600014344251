import React, {FC, useCallback, useContext, useState} from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Markdown from 'react-markdown';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {FormItemBasic} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';
import {FORM_PAUSE_OFF} from 'appRedux/actions/forms';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import PauseForm from 'components/Forms/FormBuilder/PauseForm/PauseForm';
import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import {DATE_TIME_FORMAT} from 'config/index';

interface FormPauseSectionType {
    currentForm: FormItemBasic;
}

const FormPauseSection: FC<FormPauseSectionType> = ({currentForm}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    const {showAlert} = useContext(AlertContext);

    const {id} = currentForm;

    const {
        admin: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const formPauseOff = useCallback(data => dispatch({type: FORM_PAUSE_OFF.REQUEST, payload: data}), [dispatch]);

    const onPlayClicked = () => {
        formPauseOff({
            id,
            showAlert,
        });
    };

    return (
        <ClientSectionWrapper title={t('orguser.forms.thisFormIsPaused')}>
            <Grid container sx={{mb: 2}}>
                <Grid item sm={2}>
                    <Typography>{t('orguser.forms.pauseReason')}</Typography>
                </Grid>
                <Grid item sm={10}>
                    {currentForm.pauseReason && <Markdown>{currentForm.pauseReason}</Markdown>}
                </Grid>
            </Grid>
            <Grid container sx={{mb: 2}}>
                <Grid item sm={2}>
                    <Typography>{t('orguser.forms.pausedAt')}</Typography>
                </Grid>
                <Grid item sm={10}>
                    <Typography>{moment(currentForm.pausedAt).format(DATE_TIME_FORMAT)}</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item sm={2}></Grid>
                <Grid item sm={10}>
                    <AgentInverseButton
                        isLoading={isLoading}
                        title={t('common.buttons.update')}
                        onClick={toggleModal}
                    />
                    <AgentSaveButton
                        isDanger
                        isLoading={isLoading}
                        isSubmit={false}
                        title={t('orguser.forms.pauseOff')}
                        onClick={onPlayClicked}
                    />
                </Grid>
            </Grid>
            <ModalWrapper
                title={t('orguser.forms.formPauseOn')}
                isShowModal={isShowModal}
                toggleModal={toggleModal}
                isWideModal
            >
                <PauseForm form={currentForm} toggleModal={toggleModal} />
            </ModalWrapper>
        </ClientSectionWrapper>
    );
};

export default FormPauseSection;
