import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import QRCode from 'react-qr-code';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import {GET_TWO_FACTOR_QR_CODE, TWO_FACTOR_STATUS_SWITCH} from 'appRedux/actions/profile';
import {TwoFactorConfirmationTypes} from 'appRedux/actions/auth/types';
import {TWO_FACTOR_ACTIVATION} from 'appRedux/actions/auth';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {MediaContext} from 'contexts/media/context';

import GoogleAuthCodeForm from 'components/Forms/SettingsForms/TwoFactorForm/GoogleAuthCodeForm';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import TwoFactorActivated from 'components/Forms/SettingsForms/TwoFactorForm/TwoFactorActivated';
import TwoFactorCancelling from 'components/Forms/SettingsForms/TwoFactorForm/TwoFactorCancelling';

import {QR_CODE_SIZE, QR_CODE_WIDTH, QR_CODE_HEIGHT} from 'config/index';

interface TwoFactorFormType {
    closeModal?: () => void;
}

const TwoFactorForm: FC<TwoFactorFormType> = ({closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isMobile} = useContext(MediaContext);

    const [recoveryCodes, setRecoveryCodes] = useState<string[]>([]);

    const {
        profile: {twoFactor, profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isTwoFactorActivated = get(profile, 'isTwoFactorActivated', false);
    const isGoogleAuthCodeGenerated = get(profile, 'isGoogleAuthCodeGenerated', false);

    const getQrCode = useCallback(() => dispatch({type: GET_TWO_FACTOR_QR_CODE.REQUEST}), [dispatch]);

    const [isQrCodeGenerated, setIsQrCodeGenerated] = useState<boolean>(isGoogleAuthCodeGenerated);

    const changeTwoFactorStatus = useCallback(
        data => dispatch({type: TWO_FACTOR_STATUS_SWITCH.REQUEST, payload: data}),
        [dispatch],
    );

    const handleChangeStatus = () => {
        setIsQrCodeGenerated(previous => !previous);
        changeTwoFactorStatus({showAlert});
    };

    const qrCodeValue = twoFactor ? twoFactor.qrCodeContent : null;

    useEffect(() => {
        if (profile && profile.isGoogleAuthCodeGenerated) {
            getQrCode();
        }
        if (profile && !profile.isGoogleAuthCodeGenerated) {
            setIsQrCodeGenerated(false);
        }
    }, [profile]);

    const activateTwoFactor = useCallback(
        data => dispatch({type: TWO_FACTOR_ACTIVATION.REQUEST, payload: data}),
        [dispatch],
    );

    const handleSubmit = (values: TwoFactorConfirmationTypes) => {
        activateTwoFactor({
            ...values,
            showAlert,
            callback: (codes: string[]) => {
                setRecoveryCodes(codes);
                closeModal && closeModal();
            },
        });
    };

    if (isTwoFactorActivated) {
        return (
            <TwoFactorActivated
                recoveryCodes={recoveryCodes}
                setRecoveryCodes={setRecoveryCodes}
                setIsQrCodeGenerated={setIsQrCodeGenerated}
            />
        );
    }

    return (
        <Grid container>
            {!isQrCodeGenerated && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <AgentSaveButton onClick={handleChangeStatus} title={t('common.security.twoFactorAuthLabel')} />
                </Box>
            )}
            {isQrCodeGenerated && qrCodeValue && (
                <>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                p: 2,
                                width: isMobile ? '90%' : '70%',
                            }}
                        >
                            <Typography align="center" variant="body2" sx={{mt: 2}}>
                                {t('common.security.twoFactorAuthDescription')}
                            </Typography>
                            <Typography align="center" variant="body2" sx={{fontWeight: 600, mt: 2}}>
                                {t('common.security.twoFactorAuthCheckSettings')}
                            </Typography>
                            <Alert severity={'error'} sx={{mt: 2}}>
                                <Typography>{t('common.security.twoFactorActivationWarning')}</Typography>
                            </Alert>
                            <GoogleAuthCodeForm isActivate handleSubmit={handleSubmit} />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                p: 2,
                            }}
                        >
                            <QRCode
                                size={QR_CODE_SIZE}
                                style={{height: 'auto', maxWidth: QR_CODE_WIDTH, maxHeight: QR_CODE_HEIGHT}}
                                value={qrCodeValue}
                                viewBox={`0 0 ${QR_CODE_SIZE} ${QR_CODE_SIZE}`}
                            />
                            <TwoFactorCancelling />
                        </Box>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default TwoFactorForm;
