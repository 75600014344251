import React, {FC} from 'react';

const Avatar_10: FC = () => {
    return (
        <svg viewBox="-0.39 0 62.5708 62.5708" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <clipPath id="clip-path">
                    <path
                        clipRule="evenodd"
                        d="M52.797 52.701c-.576-1.384-.505-2.763-4.626-4.83a2.862 2.862 0 0 0-.74-.357c-.488-.116-1.36-.286-1.792-.372-1.167 7.133-19.287 16.863-28.956-.047-1.04.244-3.219.752-3.801.907-4.093 1.819-3.625 2.926-4.165 4.406a30.896 30.896 0 0 0 44.08.293z"
                        fill="none"
                    />
                </clipPath>
                <clipPath id="clip-path-2">
                    <path
                        clipRule="evenodd"
                        d="M24.258 39.172a1.633 1.633 0 0 0-.186 1.22 8.903 8.903 0 0 1-.45 3.562c-2.458 4.146 10.967 11.122 16.245 1.57.69-1.034-2.328-1.983-1.358-5.17a.895.895 0 0 0-.834-1.238s-5.344 4.481-13.417.056z"
                        fill="none"
                    />
                </clipPath>
            </defs>
            <title />
            <g data-name="Layer 2" id="Layer_2">
                <g data-name="—ÎÓÈ 1" id="_ÎÓÈ_1">
                    <path
                        d="M31.129 8.432c21.281 0 12.988 35.266 0 35.266-12.266 0-21.281-35.266 0-35.266z"
                        fill="#ffe8be"
                        fillRule="evenodd"
                    />
                    <circle cx="30.8999" cy="30.8999" fill="#485a69" r="30.8999" />
                    <path
                        d="M52.797 52.701c-.608-1.462-.494-2.918-5.365-5.187-2.293-.542-8.21-1.319-9.328-3.4-.566-1.052-.43-2.535-.43-5.292l-12.93-.143c0 2.778.109 4.259-.524 5.299-1.19 1.957-8.935 3.384-11.338 4.024-4.093 1.819-3.625 2.925-4.165 4.406a30.896 30.896 0 0 0 44.08.293z"
                        fill="#f9dca4"
                        fillRule="evenodd"
                    />
                    <path
                        d="M52.797 52.701c-.576-1.384-.505-2.763-4.626-4.83a2.862 2.862 0 0 0-.74-.357c-.488-.116-1.36-.286-1.792-.372-1.167 7.133-19.287 16.863-28.956-.047-1.04.244-3.219.752-3.801.907-4.093 1.819-3.625 2.926-4.165 4.406a30.896 30.896 0 0 0 44.08.293z"
                        fill="#9f82bb"
                        fillRule="evenodd"
                    />
                    <g clipPath="url(#clip-path)" fill="#ffffff">
                        <circle cx="15.1239" cy="50.84395" r="1.5609" />
                        <circle cx="16.67482" cy="57.07985" r="1.56097" />
                        <circle cx="24.05245" cy="57.3965" r="1.5609" />
                        <circle cx="31.82595" cy="61.00985" r="1.5609" />
                        <circle cx="39.6363" cy="56.9044" r="1.56095" />
                        <circle cx="47.05002" cy="57.0654" r="1.56097" />
                        <circle cx="47.5752" cy="50.5551" r="1.5609" />
                    </g>
                    <path
                        d="M45.238 36.967a6.24 6.24 0 0 0 7.535-2.61l3.7 1.753s-2.61 6.137-8.318 4.84c-2.126-.483-3.471-1.05-4.699-1.282-3.998-.755-5.39 3.056-1.005 4.111 2.415.58 5.555-.042 6.318-1.478 1.36 1.826-.883 4.965-2.885 4.89-5.495-.208-8.256-2.587-8.222-5.69.035-3.234 2.413-6.215 7.576-4.534z"
                        fill="#e9573e"
                        fillRule="evenodd"
                    />
                    <path
                        d="M24.258 39.172a1.633 1.633 0 0 0-.186 1.22 8.903 8.903 0 0 1-.45 3.562c-2.458 4.146 10.967 11.122 16.245 1.57.69-1.034-2.328-1.983-1.358-5.17a.895.895 0 0 0-.834-1.238s-5.344 4.481-13.417.056z"
                        fill="#e9573e"
                        fillRule="evenodd"
                    />
                    <g clipPath="url(#clip-path-2)">
                        <path
                            d="M37.677 38.778l1.691.851c.04.87.089 4.6.702 5.537-1.807 4.15-14.135 3.408-15.874-6.55z"
                            fillRule="evenodd"
                            opacity="0.11"
                        />
                    </g>
                    <path
                        d="M19.11 24.183c-2.958 1.29-.442 7.41 1.42 7.383a30.847 30.847 0 0 1-1.42-7.383z"
                        fill="#f9dca4"
                        fillRule="evenodd"
                    />
                    <path
                        d="M43.507 24.182c2.96 1.292.443 7.411-1.419 7.384a30.827 30.827 0 0 0 1.419-7.384z"
                        fill="#f9dca4"
                        fillRule="evenodd"
                    />
                    <path
                        d="M31.114 8.666c8.722 0 12.377 6.2 12.601 13.367.307 9.81-5.675 21.43-12.6 21.43-6.56 0-12.706-12.018-12.333-21.928.26-6.953 3.814-12.869 12.332-12.869z"
                        fill="#ffe8be"
                        fillRule="evenodd"
                    />
                    <path
                        d="M38.361 16.587c-.174.728-3.174 6.273-7.684 8.137.756-1.317 1.97-2.892 2.058-4.4-2.162 3.173-10.983 6.337-16.2 4.79C15.364 5.79 30.182 6.256 31.183 6.31c1.002-.054 15.857-.34 14.686 18.982-5.835-1.09-7.83-5.543-7.508-8.705z"
                        fill="#ecbe6a"
                        fillRule="evenodd"
                    />
                    <path
                        d="M16.754 19.778c1.271-18.485 27.928-17.772 28.88 0-4.396-12.378-25.125-12.187-28.88 0z"
                        fill="#e9573e"
                        fillRule="evenodd"
                    />
                    <path
                        d="M43.66 24.657s-2.289 10.706-9.639 14.83c23.528-6.236 15.887-32.31 6.597-32.324-2.883-7.863-24.45-5.7-27.45 9.45s9.923 21.763 10.947 22.372a31.976 31.976 0 0 1-3.785-7.438 4.952 4.952 0 0 1-2.151-2.747 6.622 6.622 0 0 1-.212-3.412c-.726-.131-1.284-.275-1.284-.275a27.284 27.284 0 0 1 .06-5.017c.39-3.894 1.789-9.096 6.83-11.947 3.523-1.992 11.119-2.55 15.692.328 5.362 3.374 6.821 10.69 6.452 16.627z"
                        fill="#d6a956"
                        fillRule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Avatar_10;
