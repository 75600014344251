import React, {FC} from 'react';

import {FormFieldTypes} from 'appRedux/actions/forms/types';

import ResourceFieldBudget from 'pages/client/form/fieldTypes/resourceFields/ResourceFieldBudget';
import ResourceFieldSlot from 'pages/client/form/fieldTypes/resourceFields/ResourceFieldSlot';

interface FormResourceFieldInformationType {
    formId: number;
    field: FormFieldTypes;
}

const FormResourceFieldInformation: FC<FormResourceFieldInformationType> = ({formId, field}) => {
    const {resourceFieldSlot, resourceFieldBudget} = field;

    if (resourceFieldSlot) {
        return <ResourceFieldSlot formId={formId} field={field} />;
    }
    if (resourceFieldBudget) {
        return <ResourceFieldBudget formId={formId} field={field} />;
    }
    return null;
};

export default FormResourceFieldInformation;
