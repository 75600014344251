import {fork, put, take, call} from 'redux-saga/effects';

import {
    ORGANIZATION_INTEGRATION_TOKENS_LIST,
    getOrganizationIntegrationTokensList,
    ORGANIZATION_INTEGRATION_TOKEN_CREATE,
    createOrganizationIntegrationToken,
    ORGANIZATION_INTEGRATION_TOKEN_UPDATE,
    updateOrganizationIntegrationToken,
    ORGANIZATION_INTEGRATION_TOKEN_DELETE,
    deleteOrganizationIntegrationToken,
} from 'appRedux/actions/organizationIntegrationTokens';
import {
    OrganizationIntegrationTokenRequestTypes,
    OrganizationIntegrationTokenCreateRequestTypes,
    OrganizationIntegrationTokenUpdateRequestTypes,
    OrganizationIntegrationTokenDeleteRequestTypes,
    OrganizationIntegrationTokenTypes,
    OrganizationIntegrationTokenResponseTypes,
} from 'appRedux/actions/organizationIntegrationTokens/types';

import {http} from 'services/http';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchOrganizationIntegrationTokensListRequest() {
    while (true) {
        const {
            payload: {callback, id, showAlert},
        }: IActionType<OrganizationIntegrationTokenRequestTypes> = yield take(
            ORGANIZATION_INTEGRATION_TOKENS_LIST.REQUEST,
        );
        try {
            const data: OrganizationIntegrationTokenResponseTypes = yield call(http, `integration/tokens/${id}/list`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getOrganizationIntegrationTokensList.success(data.results));
            } else if (data.errors) {
                yield put(getOrganizationIntegrationTokensList.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(getOrganizationIntegrationTokensList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationIntegrationTokenCreation() {
    while (true) {
        const {
            payload: {callback, id, showAlert, ...values},
        }: IActionType<OrganizationIntegrationTokenCreateRequestTypes> = yield take(
            ORGANIZATION_INTEGRATION_TOKEN_CREATE.REQUEST,
        );
        try {
            const data: OrganizationIntegrationTokenResponseTypes = yield call(http, `integration/token/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createOrganizationIntegrationToken.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else if (data.errors) {
                yield put(createOrganizationIntegrationToken.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(createOrganizationIntegrationToken.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationIntegrationTokenUpdating() {
    while (true) {
        const {
            payload: {callback, uuid, showAlert, ...values},
        }: IActionType<OrganizationIntegrationTokenUpdateRequestTypes> = yield take(
            ORGANIZATION_INTEGRATION_TOKEN_UPDATE.REQUEST,
        );
        try {
            const data: OrganizationIntegrationTokenResponseTypes = yield call(
                http,
                `integration/token/${uuid}/update`,
                {
                    method: 'POST',
                    body: JSON.stringify(values),
                },
            );
            if (data.success) {
                yield put(updateOrganizationIntegrationToken.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else if (data.errors) {
                yield put(updateOrganizationIntegrationToken.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(updateOrganizationIntegrationToken.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationIntegrationTokenDeleting() {
    while (true) {
        const {
            payload: {callback, uuid, showAlert},
        }: IActionType<OrganizationIntegrationTokenDeleteRequestTypes> = yield take(
            ORGANIZATION_INTEGRATION_TOKEN_DELETE.REQUEST,
        );
        try {
            const data: OrganizationIntegrationTokenResponseTypes = yield call(
                http,
                `integration/token/${uuid}/delete`,
                {
                    method: 'DELETE',
                },
            );
            if (data.success) {
                yield put(deleteOrganizationIntegrationToken.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(deleteOrganizationIntegrationToken.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(deleteOrganizationIntegrationToken.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchOrganizationIntegrationTokensListRequest),
    fork(watchOrganizationIntegrationTokenCreation),
    fork(watchOrganizationIntegrationTokenUpdating),
    fork(watchOrganizationIntegrationTokenDeleting),
];
