import React, {FC, ReactNode, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import EmptyArea from 'components/EmptyArea/EmptyArea';
import DeleteAdminConfirmationButton from 'components/AdminScreenComponents/DeleteAdminConfirmationButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import {theme} from 'config/theme';

interface CalendarDetailsItemType {
    title: string;
    subtitle: string;
    modalTitle: string;
    isShowUpdateModal: boolean;
    toggleUpdateModal: () => void;
    onClickDelete: () => void;
    updateForm: ReactNode;
}

const CalendarDetailsItem: FC<CalendarDetailsItemType> = ({
    title,
    subtitle,
    modalTitle,
    isShowUpdateModal,
    toggleUpdateModal,
    updateForm,
    onClickDelete,
}) => {
    const [t] = useTranslation();

    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    return (
        <Box
            sx={{
                border: `1px solid ${theme.palette.info.main}`,
                borderRadius: 4,
                p: 1.5,
                pt: 1,
                pb: 1,
                mb: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box sx={{cursor: 'pointer'}} title={t('common.buttons.update')} onClick={toggleUpdateModal}>
                    <Typography sx={{fontWeight: 700, mb: 0.5}}>{title}</Typography>
                    <Typography>{subtitle}</Typography>
                </Box>
                <EmptyArea onClick={toggleUpdateModal} />
                <DeleteAdminConfirmationButton
                    disabled={false}
                    isShowModal={isShowModal}
                    toggleModal={toggleModal}
                    onClickAction={onClickDelete}
                />
            </Box>
            <ModalWrapper isShowModal={isShowUpdateModal} toggleModal={toggleUpdateModal} title={modalTitle}>
                {updateForm}
            </ModalWrapper>
        </Box>
    );
};

export default CalendarDetailsItem;
