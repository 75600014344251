import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Typography from '@mui/material/Typography';

import {DELETE_CLIENT_TRANSLATION} from 'appRedux/actions/clientTranslation';
import {
    ClientTranslationTypes,
    ClientTranslationUpdatedItemType,
    ClientTranslationUpdatedType,
} from 'appRedux/actions/clientTranslation/types';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';

interface DeleteTranslationButtonType {
    fieldId: number;
    sectionId: number;
    currentTranslations: ClientTranslationUpdatedItemType[];
    currentTranslation: ClientTranslationTypes;
    setClientTranslations: (value: ClientTranslationUpdatedType) => void;
}

const DeleteTranslationButton: FC<DeleteTranslationButtonType> = ({
    fieldId,
    sectionId,
    currentTranslation,
    currentTranslations,
    setClientTranslations,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    const deleteClientTranslations = useCallback(
        data => dispatch({type: DELETE_CLIENT_TRANSLATION.REQUEST, payload: data}),
        [dispatch],
    );

    const onDeleteClick = () => {
        if (currentTranslation) {
            deleteClientTranslations({
                uuid: currentTranslation.uuid,
                showAlert,
                callback: () => {
                    setIsShowModal(false);
                    const otherTranslations = currentTranslations.filter(
                        (item: ClientTranslationUpdatedItemType) => item.field !== fieldId,
                    );
                    setClientTranslations({
                        section: sectionId,
                        translations: otherTranslations,
                    });
                },
            });
        }
    };

    return (
        <>
            <Typography sx={{fontWeight: 600, fontSize: 12, cursor: 'pointer'}} onClick={toggleModal}>
                {t('common.buttons.delete')}
            </Typography>
            <DeleteAdminConfirmationModal
                toggleModal={toggleModal}
                isShowModal={isShowModal}
                onClickAction={onDeleteClick}
            />
        </>
    );
};

export default DeleteTranslationButton;
