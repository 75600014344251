import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';

import {ResourceFieldSlotItemTypes} from 'appRedux/actions/resourceFields/types';
import {DELETE_RESOURCE_FIELD_SLOT} from 'appRedux/actions/resourceFields';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationButton from 'components/AdminScreenComponents/DeleteAdminConfirmationButton';

interface DeleteResourceFieldSlotButtonType {
    item: ResourceFieldSlotItemTypes;
}

const DeleteResourceFieldCalendarButton: FC<DeleteResourceFieldSlotButtonType> = ({item}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    const {uuid} = item;

    const removeResourceFieldSlot = useCallback(
        data => dispatch({type: DELETE_RESOURCE_FIELD_SLOT.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        removeResourceFieldSlot({
            uuid,
            callback: toggleModal,
            showAlert,
        });
    };

    return (
        <DeleteAdminConfirmationButton
            disabled={false}
            isShowModal={isShowModal}
            toggleModal={toggleModal}
            onClickAction={onClickDelete}
        />
    );
};

export default DeleteResourceFieldCalendarButton;
