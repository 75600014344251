import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import get from 'lodash/get';

import Box from '@mui/material/Box';

import {UPDATE_ORGANIZATION_SSO_CREDENTIALS} from 'appRedux/actions/organization';
import {OrganizationSsoUpdateFormTypes} from 'appRedux/actions/organization/types';
import {OrganizationSsoHasRolesTypes} from 'appRedux/actions/organizationAdmin/types';
import {RoleTypes} from 'appRedux/actions/roles/types';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import FormBuilderCheckboxReverse from 'components/AgentScreenComponents/_form/FormBuilderCheckboxReverse';
import {updateOrganizationSsoValidationSchema} from 'components/Forms/OrganizationSsoCredentialsForm/validation';
import UpdateOrganizationSsoClientSecretButton from 'components/Forms/OrganizationSsoCredentialsForm/UpdateOrganizationSsoClientSecretButton';
import RolesSelectors from 'components/Forms/OrganizationAdminForm/partials/RolesSelectors';
import RedirectUrlLink from 'components/Forms/OrganizationSsoCredentialsForm/partials/RedirectUrlLink';

interface OrganizationSsoCredentialsFormType {
    initialValues: OrganizationSsoUpdateFormTypes;
}

const OrganizationSsoUpdateForm: FC<OrganizationSsoCredentialsFormType> = ({initialValues}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [organizationSsoHasRoles, setOrganizationSsoHasRoles] = useState<OrganizationSsoHasRolesTypes[]>([]);

    const submitUpdateOrganizationSsoCredentials = useCallback(
        data => dispatch({type: UPDATE_ORGANIZATION_SSO_CREDENTIALS.REQUEST, payload: data}),
        [dispatch],
    );

    const organizationSsoId = get(initialValues, 'id', null);

    useEffect(() => {
        const initialRoles: RoleTypes[] = get(initialValues, 'roles', []);
        const results: OrganizationSsoHasRolesTypes[] = [];
        initialRoles.forEach((item: RoleTypes) => {
            results.push({
                role: item.id,
            });
        });
        setOrganizationSsoHasRoles(results);
    }, [initialValues]);

    if (!organizationSsoId) return null;

    const redirectUrl = get(initialValues, 'redirectUrl', null);

    return (
        <Formik
            initialValues={{
                authServer: initialValues ? initialValues.authServer : '',
                realm: initialValues ? initialValues.realm : '',
                client: initialValues ? initialValues.client : '',
                isActive: initialValues ? initialValues.isActive : false,
                organizationSsoHasRoles: organizationSsoHasRoles,
            }}
            validationSchema={updateOrganizationSsoValidationSchema}
            onSubmit={(values, {setErrors}) => {
                submitUpdateOrganizationSsoCredentials({
                    ...values,
                    id: organizationSsoId,
                    organizationSsoHasRoles,
                    setErrors,
                    showAlert,
                });
            }}
        >
            {(formik: FormikProps<OrganizationSsoUpdateFormTypes>) => {
                return (
                    <Form>
                        <Box>
                            <Field
                                name="authServer"
                                label={t('superadmin.organizations.authServer')}
                                type="text"
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box>
                            <Field
                                name="realm"
                                label={t('superadmin.organizations.realm')}
                                type="text"
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box>
                            <Field
                                name="client"
                                label={t('superadmin.organizations.clientId')}
                                type="text"
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box sx={{mb: 3, mt: 3}}>
                            <Field
                                type="checkbox"
                                name="isActive"
                                label={t('superadmin.organizations.isActive')}
                                component={FormBuilderCheckboxReverse}
                            />
                        </Box>
                        {redirectUrl && <RedirectUrlLink redirectUrl={redirectUrl} />}
                        <RolesSelectors
                            title={t('superadmin.organizations.organizationSsoRoles')}
                            fieldName={'organizationSsoHasRoles'}
                            userHasRoles={organizationSsoHasRoles}
                            setUserHasRoles={setOrganizationSsoHasRoles}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                            <UpdateOrganizationSsoClientSecretButton organizationSsoId={organizationSsoId} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default OrganizationSsoUpdateForm;
