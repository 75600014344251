import React, {FC, ReactNode, useLayoutEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';
import {ChatContactTypes} from 'appRedux/actions/requestChat/types';

import RequesterCaseChat from 'components/RequesterChat/RequesterCaseChat';

import ChatContactsList from 'pages/agent/chat/ChatContactsList';
import ChatHeader from 'pages/agent/chat/ChatHeader';
import AgentChatTopBar from 'pages/agent/chat/AgentChatTopBar';

import {CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface AgentChatWrapperContentType {
    children: ReactNode;
}

const AgentChatWrapperContent: FC<AgentChatWrapperContentType> = ({children}) => {
    const contentWrapperRef = useRef<HTMLDivElement>();

    const [chatBoxWidth, setChatBoxWidth] = useState<number>();

    useLayoutEffect(() => {
        if (contentWrapperRef && contentWrapperRef.current) {
            setChatBoxWidth(contentWrapperRef.current.offsetWidth);
        }

        const onResize = () => {
            setChatBoxWidth(contentWrapperRef.current?.offsetWidth);
        };
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [contentWrapperRef]);

    const {requestCase} = useParams();

    const {
        requestChat: {contacts},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const item = Array.isArray(contacts)
        ? contacts.find((item: ChatContactTypes) => item?.caseId === Number(requestCase))
        : undefined;

    return (
        <Box sx={{width: '100%', position: 'relative', height: 'calc(100vh - 120px)'}} ref={contentWrapperRef}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'end',
                    heigth: '48px',
                    gap: '20px',
                }}
            >
                <AgentChatTopBar />
                {item && <ChatHeader item={item} />}
            </Box>

            <Box
                sx={{
                    position: 'absolute',
                }}
            >
                <Box sx={{width: chatBoxWidth ? '330px' : 'auto', height: 'calc(100vh - 180px)'}}>
                    <ChatContactsList />
                </Box>
            </Box>
            <Box
                sx={{
                    width: chatBoxWidth ? chatBoxWidth - 350 : 'auto',
                    ml: 'auto',
                    height: 'calc(100vh - 248px)',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                }}
            >
                {children}
                <Box
                    sx={{
                        width: chatBoxWidth ? chatBoxWidth - 350 : 'auto',
                        position: 'fixed',
                        top: 'auto',
                        bottom: 0,
                        p: 2,
                        background: CLIENT_BACKGROUND_COLOR, //`linear-gradient(to top, ${CLIENT_BACKGROUND_COLOR}, 75%, transparent)`,
                    }}
                >
                    <RequesterCaseChat wrapperRef={contentWrapperRef} />
                </Box>
            </Box>
        </Box>
    );
};

export default AgentChatWrapperContent;
