import React, {FC, useCallback, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {UPDATE_ROLE} from 'appRedux/actions/roles';
import {RoleCommonRequestTypes} from 'appRedux/actions/roles/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import RoleForm from 'components/Forms/RoleForm/RoleForm';

interface UpdateRoleFormType {
    organizationId: number;
    initialValues: RoleCommonRequestTypes;
}

const UpdateRoleForm: FC<UpdateRoleFormType> = ({initialValues, organizationId}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        admin: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const submitUpdateForm = useCallback(data => dispatch({type: UPDATE_ROLE.REQUEST, payload: data}), [dispatch]);

    const onSubmitClicked = (values: RoleCommonRequestTypes) => {
        submitUpdateForm({
            ...values,
            showAlert,
        });
    };

    return (
        <RoleForm
            initialValues={initialValues}
            organizationId={organizationId}
            isLoading={isLoading}
            onSubmitClicked={onSubmitClicked}
        />
    );
};

export default UpdateRoleForm;
