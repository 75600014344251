import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const RedirectSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" fill="white" fillOpacity="0.01" />
            <path
                d="M6 5H12.0926L10.2989 3.20705L11 2.5L14 5.5L11 8.5L10.2989 7.7927L12.0911 6H6C5.20435 6 4.44129 6.31607 3.87868 6.87868C3.31607 7.44129 3 8.20435 3 9C3 9.79565 3.31607 10.5587 3.87868 11.1213C4.44129 11.6839 5.20435 12 6 12H10V13H6C4.93913 13 3.92172 12.5786 3.17157 11.8284C2.42143 11.0783 2 10.0609 2 9C2 7.93913 2.42143 6.92172 3.17157 6.17157C3.92172 5.42143 4.93913 5 6 5Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default RedirectSvgIcon;
