import React, {FC, Ref, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {UPDATE_WORKFLOW_TEMPLATE} from 'appRedux/actions/workflow';
import {
    WorkflowStatusEventItemTypes,
    WorkflowTemplateFormTypes,
    WorkflowTemplateTypes,
} from 'appRedux/actions/workflow/types';

import {AlertContext} from 'contexts/alert/context';

import WorkflowTemplateForm from 'components/Forms/Workflow/workflowTemplate/WorkflowTemplateForm';

interface UpdateWorkflowTemplateFormType {
    formRef: Ref<any> | null;
    item: WorkflowStatusEventItemTypes;
    currentTemplate: WorkflowTemplateTypes;
    closeModal: () => void;
    forEmailTemplate?: boolean;
    forWebhookTemplate?: boolean;
    statusUuid: string;
    language: number;
    setLanguage: (value: number) => void;
}

const UpdateWorkflowTemplateForm: FC<UpdateWorkflowTemplateFormType> = ({
    formRef,
    item,
    currentTemplate,
    closeModal,
    forEmailTemplate,
    forWebhookTemplate,
    statusUuid,
    language,
    setLanguage,
}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [codeMirrorText, setCodeMirrorText] = useState(currentTemplate.text);

    const updateWorkflowTemplate = useCallback(
        data => dispatch({type: UPDATE_WORKFLOW_TEMPLATE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: WorkflowTemplateFormTypes) => {
        updateWorkflowTemplate({
            ...values,
            text: forWebhookTemplate ? codeMirrorText : values.text,
            uuid: currentTemplate.uuid,
            showAlert,
            callback: closeModal,
        });
    };

    useEffect(() => {
        setCodeMirrorText(currentTemplate.text);
    }, [language]);

    return (
        <WorkflowTemplateForm
            formRef={formRef}
            initialValues={{
                subject: forEmailTemplate ? currentTemplate.subject : '',
                text: currentTemplate.text,
                language,
            }}
            url={item.url}
            onSubmitClicked={onSubmitClicked}
            translations={item.translations ? item.translations : []}
            forEmailTemplate={forEmailTemplate}
            forWebhookTemplate={forWebhookTemplate}
            codeMirrorText={codeMirrorText}
            workflowStatusUuid={statusUuid}
            setCodeMirrorText={setCodeMirrorText}
            language={language}
            setLanguage={setLanguage}
        />
    );
};

export default UpdateWorkflowTemplateForm;
