import moment from 'moment';

import {MainProfileResponseTypes} from 'appRedux/actions/profile/types';

export const getDaysBeforeLocking = (profile?: MainProfileResponseTypes): number => {
    if (!profile) return 0;
    const currentDate = moment();
    const lockedDate = moment(profile.lockedAt);
    return lockedDate.diff(currentDate, 'days');
};
