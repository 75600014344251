import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import CreatePdfTemplateForm from 'components/Forms/OrganizationPdfTemplateForm/CreatePdfTemplateForm';

interface AddPdfTemplateButtonType {
    organizationId: number;
}

const AddPdfTemplateButton: FC<AddPdfTemplateButtonType> = ({organizationId}) => {
    const [t] = useTranslation();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                mb: 2,
            }}
        >
            <AgentInverseButton
                icon={<PlusSvgIcon />}
                title={t('superadmin.organizations.pdfTemplates.addPdfTemplate')}
                onClick={() => setShowModal(true)}
            />
            <ModalWrapper
                isShowModal={showModal}
                title={t('superadmin.organizations.pdfTemplates.addPdfTemplate')}
                toggleModal={toggleModal}
            >
                <CreatePdfTemplateForm organizationId={organizationId} closeModal={() => setShowModal(false)} />
            </ModalWrapper>
        </Box>
    );
};

export default AddPdfTemplateButton;
