import React, {FC} from 'react';
import get from 'lodash/get';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import {FormPopupTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseItemType} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import PopupFieldPrintContent from 'pages/agent/print/partials/PopupFieldPrintContent';

import {getPopupKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

interface PopupPrintContentType {
    formId: number;
    pageId: number;
    sectionId: number;
    popup: FormPopupTypes;
    result: RequesterCaseItemType;
}

const PopupPrintContent: FC<PopupPrintContentType> = ({formId, popup, pageId, sectionId, result}) => {
    const [t] = useTranslation();

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {title, id, fields} = popup;

    const keyword = getPopupKeyword(formId, id, 'title');

    const pageKeyword = `page-${pageId}`;
    const sectionKeyword = `section-${sectionId}`;
    const popupKeyword = `popup-${id}`;

    const popupItems = get(result, [pageKeyword, sectionKeyword, popupKeyword], []);

    return (
        <Box sx={{mt: 2, mb: 1}}>
            <Grid sx={{mb: 1}} container>
                <Grid item sm={3}>
                    <Typography sx={{fontSize: 16, fontWeight: 600}}>
                        {`${getFormTranslatedLabel(translations, keyword, title)}:`}
                    </Typography>
                </Grid>
                <Grid item sm={9}></Grid>
            </Grid>
            {popupItems.map((item: RequesterCaseItemType, index: number) => {
                return (
                    <Box key={`field-popup-${index}`}>
                        <PopupFieldPrintContent
                            item={item}
                            pageId={pageId}
                            sectionId={sectionId}
                            popupId={id}
                            fields={fields}
                        />
                        <Divider sx={{mt: 2, mb: 2}} />
                    </Box>
                );
            })}
        </Box>
    );
};

export default PopupPrintContent;
