import {
    ChatMessageTypes,
    ChatUploadDocumentRequestTypes,
    ChatMessageRequestTypes,
    ChatDocumentResponseType,
    ChatMessagesRequestTypes,
    ChatContactTypes,
    MessageTypes,
    ChatMessageUpdateRequestTypes,
    ChatMessagesResponseTypes,
} from 'appRedux/actions/requestChat/types';

import {requestTypeCreator} from 'helpers/index';

export const GET_CHAT_MESSAGES = requestTypeCreator('GET_CHAT_MESSAGES');
export const GET_PREV_CHAT_MESSAGES = requestTypeCreator('GET_PREV_CHAT_MESSAGES');
export const GET_NEW_CHAT_MESSAGES = requestTypeCreator('GET_NEXT_CHAT_MESSAGES', {
    REQUEST_BACKGROUND: 'REQUEST_BACKGROUND',
});

export const REFRESH_CHAT_MESSAGES = requestTypeCreator('REFRESH_CHAT_MESSAGES');

export const UPLOAD_CHAT_DOCUMENT = requestTypeCreator('UPLOAD_CHAT_DOCUMENT');
export const UPDATE_CHAT_MESSAGE = requestTypeCreator('UPDATE_CHAT_MESSAGE');
export const DELETE_CHAT_MESSAGE = requestTypeCreator('DELETE_CHAT_MESSAGE');
export const CHAT_DOCUMENT_URL = requestTypeCreator('CHAT_DOCUMENT_URL');
export const CHAT_AGENT_CONTACTS = requestTypeCreator('CHAT_AGENT_CONTACTS', {
    REQUEST_BACKGROUND: 'REQUEST_BACKGROUND',
});

export const getChatMessages = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_CHAT_MESSAGES.ERROR,
    }),
    request: (payload: ChatMessagesRequestTypes): IActionType<ChatMessagesRequestTypes> => ({
        payload,
        type: GET_CHAT_MESSAGES.REQUEST,
    }),
    success: (payload: ChatMessagesResponseTypes): IActionType<ChatMessagesResponseTypes> => ({
        payload,
        type: GET_CHAT_MESSAGES.SUCCESS,
    }),
};

export const getPrevChatMessages = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_PREV_CHAT_MESSAGES.ERROR,
    }),
    request: (payload: ChatMessagesRequestTypes): IActionType<ChatMessagesRequestTypes> => ({
        payload,
        type: GET_PREV_CHAT_MESSAGES.REQUEST,
    }),
    success: (payload: ChatMessagesResponseTypes): IActionType<ChatMessagesResponseTypes> => ({
        payload,
        type: GET_PREV_CHAT_MESSAGES.SUCCESS,
    }),
};

export const getNewChatMessages = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_NEW_CHAT_MESSAGES.ERROR,
    }),
    request: (payload: ChatMessagesRequestTypes): IActionType<ChatMessagesRequestTypes> => ({
        payload,
        type: GET_NEW_CHAT_MESSAGES.REQUEST,
    }),
    requestBackground: (payload: ChatMessagesRequestTypes): IActionType<ChatMessagesRequestTypes> => ({
        payload,
        type: GET_NEW_CHAT_MESSAGES.REQUEST_BACKGROUND,
    }),
    success: (payload: ChatMessageTypes): IActionType<ChatMessageTypes> => ({
        payload,
        type: GET_NEW_CHAT_MESSAGES.SUCCESS,
    }),
};

export const refreshChatMessages = {
    request: () => ({
        type: REFRESH_CHAT_MESSAGES.REQUEST,
    }),
};

export const uploadChatDocument = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPLOAD_CHAT_DOCUMENT.ERROR,
    }),
    request: (payload: ChatUploadDocumentRequestTypes): IActionType<ChatUploadDocumentRequestTypes> => ({
        payload,
        type: UPLOAD_CHAT_DOCUMENT.REQUEST,
    }),
    success: (payload: ChatMessageTypes): IActionType<ChatMessageTypes> => ({
        payload,
        type: UPLOAD_CHAT_DOCUMENT.SUCCESS,
    }),
};

export const chatMessageUpdate = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_CHAT_MESSAGE.ERROR,
    }),
    request: (payload: ChatMessageUpdateRequestTypes): IActionType<ChatMessageUpdateRequestTypes> => ({
        payload,
        type: UPDATE_CHAT_MESSAGE.REQUEST,
    }),
    success: (payload: MessageTypes): IActionType<MessageTypes> => ({
        payload,
        type: UPDATE_CHAT_MESSAGE.SUCCESS,
    }),
};

export const chatMessageDelete = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_CHAT_MESSAGE.ERROR,
    }),
    request: (payload: ChatMessageRequestTypes): IActionType<ChatMessageRequestTypes> => ({
        payload,
        type: DELETE_CHAT_MESSAGE.REQUEST,
    }),
    success: (payload: string): IActionType<string> => ({
        payload,
        type: DELETE_CHAT_MESSAGE.SUCCESS,
    }),
};

export const chatDocumentUrl = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CHAT_DOCUMENT_URL.ERROR,
    }),
    request: (payload: ChatMessageRequestTypes): IActionType<ChatMessageRequestTypes> => ({
        payload,
        type: CHAT_DOCUMENT_URL.REQUEST,
    }),
    success: (payload: ChatDocumentResponseType): IActionType<ChatDocumentResponseType> => ({
        payload,
        type: CHAT_DOCUMENT_URL.SUCCESS,
    }),
};

export const getAgentChatContacts = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CHAT_AGENT_CONTACTS.ERROR,
    }),
    request: () => ({
        type: CHAT_AGENT_CONTACTS.REQUEST,
    }),
    requestBackground: () => ({
        type: CHAT_AGENT_CONTACTS.REQUEST_BACKGROUND,
    }),
    success: (payload: ChatContactTypes[]): IActionType<ChatContactTypes[]> => ({
        payload,
        type: CHAT_AGENT_CONTACTS.SUCCESS,
    }),
};
