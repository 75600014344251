import {throttle} from 'lodash';

export const registerServiceWorker = async () => {
    if ('serviceWorker' in navigator) {
        try {
            const registration = await navigator.serviceWorker.register(`/service-worker.js`, {
                scope: '/',
            });

            if (registration.installing) {
                console.log('Service worker installing');
            } else if (registration.waiting) {
                console.log('Service worker installed');
            } else if (registration.active) {
                console.log('Service worker active');
            }

            const onClick = throttle(() => {
                console.log('onClick');
                registration.update();
            }, 10000);

            document.body.addEventListener('click', onClick, true);

            registration.onupdatefound = () => {
                console.log('update found');
                const installingWorker = registration.installing as ServiceWorker;
                installingWorker.onstatechange = () => {
                    console.log('state changed', installingWorker.state);

                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            // New update available
                            console.log('new update available');
                            const event = new CustomEvent('SW-codeUpdated', {detail: registration});
                            window.dispatchEvent(event);
                            unRegisterServiceWorker();
                        }
                    }
                };
            };
        } catch (error) {
            console.error(`Registration failed with: \n ${error}`);
        }
    }
};

export const unRegisterServiceWorker = async () => {
    if ('serviceWorker' in navigator) {
        try {
            await navigator.serviceWorker.ready.then(registration => {
                registration.unregister();

                if (caches) {
                    // Service worker cache should be cleared with caches.delete()
                    caches.keys().then(async names => {
                        await Promise.all(names.map(name => caches.delete(name)));
                    });
                }
            });
        } catch (error) {
            console.error(`Unegistration failed with ${error}`);
        }
    }
};
