import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import footerMobileLogo from 'assets/images/footerMobileLogo.png';

import {CLIENT_BACKGROUND_COLOR} from 'config/theme';

const FooterMobile: FC = () => {
    const [t] = useTranslation();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                pt: 4,
                pb: 3,
                backgroundColor: CLIENT_BACKGROUND_COLOR,
            }}
        >
            <Typography sx={{mr: 2, fontSize: 12}}>{t('common.poweredBy')}</Typography>
            <img src={footerMobileLogo} alt={'Footer logo'} />
        </Box>
    );
};

export default FooterMobile;
