import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {FormFaqItemTypes} from 'appRedux/actions/forms/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import UpdateFaqForm from 'components/Forms/FormBuilder/FaqForm/UpdateFaqForm';

interface UpdateFormFaqButtonType {
    item: FormFaqItemTypes;
}

const UpdateFormFaqButton: FC<UpdateFormFaqButtonType> = ({item}) => {
    const [t] = useTranslation();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    return (
        <>
            <IconButton onClick={() => setShowModal(true)}>
                <EditSvgIcon />
            </IconButton>
            <ModalWrapper isShowModal={showModal} toggleModal={toggleModal} title={t('orguser.forms.faq.updateFaq')}>
                <UpdateFaqForm item={item} closeModal={() => setShowModal(false)} />
            </ModalWrapper>
        </>
    );
};

export default UpdateFormFaqButton;
