import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FormBaseInformationTypes} from 'appRedux/actions/auth/types';

import MarkdownWithShowAll from 'components/TextWithShowAll/MarkdownWithShowAll';

import {theme} from 'config/theme';

interface PrivacyPoliceType {
    form: FormBaseInformationTypes;
}

const PrivacyPolice: FC<PrivacyPoliceType> = ({form}) => {
    const [t] = useTranslation();
    const {privacyPolice} = form;

    return (
        <Box sx={{width: '100%', mt: 3, p: 2, borderRadius: 2, backgroundColor: theme.palette.warning.contrastText}}>
            <Typography variant="body1" sx={{fontWeight: 600, mb: 1}}>
                {t('common.registrationForm.handleData')}
            </Typography>
            <MarkdownWithShowAll data-e2e-id="formPrivacyPolicy" text={privacyPolice} />
        </Box>
    );
};

export default PrivacyPolice;
