import {
    InviteTypes,
    InviteDeleteRequestType,
    InviteCreateRequestType,
    SendInviteRequestType,
} from 'appRedux/actions/invites/types';

import {requestTypeCreator} from 'helpers/index';

export const INVITES_GET_LIST = requestTypeCreator('INVITES_GET_LIST');
export const INVITE_CREATE = requestTypeCreator('INVITES_CREATE');
export const INVITE_DELETE = requestTypeCreator('INVITE_DELETE');
export const INVITE_SEND = requestTypeCreator('INVITE_SEND');

export const getInvitesList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: INVITES_GET_LIST.ERROR,
    }),
    request: () => ({
        type: INVITES_GET_LIST.REQUEST,
    }),
    success: (payload: InviteTypes[]): IActionType<InviteTypes[]> => ({
        payload,
        type: INVITES_GET_LIST.SUCCESS,
    }),
};

export const createInvite = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: INVITE_CREATE.ERROR,
    }),
    request: (payload: InviteCreateRequestType): IActionType<InviteCreateRequestType> => ({
        payload,
        type: INVITE_CREATE.REQUEST,
    }),
    success: (payload: InviteTypes[]): IActionType<InviteTypes[]> => ({
        payload,
        type: INVITE_CREATE.SUCCESS,
    }),
};

export const sendInvite = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: INVITE_SEND.ERROR,
    }),
    request: (payload: SendInviteRequestType): IActionType<SendInviteRequestType> => ({
        payload,
        type: INVITE_SEND.REQUEST,
    }),
    success: (payload: InviteTypes[]): IActionType<InviteTypes[]> => ({
        payload,
        type: INVITE_SEND.SUCCESS,
    }),
};

export const deleteInvite = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: INVITE_DELETE.ERROR,
    }),
    request: (payload: InviteDeleteRequestType): IActionType<InviteDeleteRequestType> => ({
        payload,
        type: INVITE_DELETE.REQUEST,
    }),
    success: (payload: InviteTypes[]): IActionType<InviteTypes[]> => ({
        payload,
        type: INVITE_DELETE.SUCCESS,
    }),
};
