import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import CloseSvgIcon from 'assets/icons/buttons/CloseSvgIcon';
import RollbackSvgIcon from 'assets/icons/buttons/RollbackSvgIcon';
import CheckSvgIcon from 'assets/icons/buttons/CheckSvgIcon';

import {IGNORE_CASE_HAS_TASK} from 'appRedux/actions/requestCase';
import {CaseHasTasksTypes} from 'appRedux/actions/requestCase/types';

import {AlertContext} from 'contexts/alert/context';

import {MAIN_CLIENT_COLOR} from 'config/theme';

interface RemoveRelatedTaskButtonType {
    item: CaseHasTasksTypes;
}

const IgnoreRelatedTaskButton: FC<RemoveRelatedTaskButtonType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {uuid, isIgnored, isCompleted, isIgnoringPossible} = item;

    const {showAlert} = useContext(AlertContext);

    const ignoreCaseHasTask = useCallback(
        data => dispatch({type: IGNORE_CASE_HAS_TASK.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickAction = () => {
        ignoreCaseHasTask({
            uuid,
            showAlert,
        });
    };

    if (isCompleted) {
        return (
            <IconButton
                title={t('orguser.workflows.tasks.executed')}
                sx={{
                    backgroundColor: MAIN_CLIENT_COLOR,
                    '&:hover': {
                        backgroundColor: MAIN_CLIENT_COLOR,
                    },
                }}
            >
                <CheckSvgIcon isActive />
            </IconButton>
        );
    }
    return (
        <IconButton
            title={t(isIgnored ? 'common.buttons.rollback' : 'common.buttons.ignore')}
            onClick={onClickAction}
            disabled={!isIgnoringPossible}
        >
            {isIgnored ? <RollbackSvgIcon /> : <CloseSvgIcon isDisabled={!isIgnoringPossible} />}
        </IconButton>
    );
};

export default IgnoreRelatedTaskButton;
