import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {RequesterCaseApproveTypes} from 'appRedux/actions/requestCase/types';
import {UNSET_STATUS_CHANGE_APPROVE_REQUEST} from 'appRedux/actions/requestCase';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface RemoveResolutionButtonType {
    item: RequesterCaseApproveTypes;
}

const RemoveResolutionButton: FC<RemoveResolutionButtonType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpen(previous => !previous);
    };

    const unsetApproveRequest = useCallback(
        data => dispatch({type: UNSET_STATUS_CHANGE_APPROVE_REQUEST.REQUEST, payload: data}),
        [dispatch],
    );

    const onRemoveClick = () => {
        unsetApproveRequest({
            uuid: item.uuid,
            showAlert,
            callback: () => setIsOpen(false),
        });
    };

    return (
        <>
            <AgentSaveButton isSubmit={false} onClick={toggleModal} title={t('common.buttons.delete')} />
            <DeleteAdminConfirmationModal
                toggleModal={toggleModal}
                isShowModal={isOpen}
                onClickAction={onRemoveClick}
            />
        </>
    );
};

export default RemoveResolutionButton;
