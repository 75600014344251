import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {RoleFilterRequestTypes} from 'appRedux/actions/roles/types';

import {getFilteredForms} from 'components/Forms/RoleForm/helper';

import PermissionItemWrapper from 'pages/admin/roles/PermissionItemWrapper';

interface PermissionItemType {
    title: string;
    hasView?: boolean;
    hasCreate?: boolean;
    hasUpdate?: boolean;
    hasDelete?: boolean;
    filterType?: number;
    roleFilters?: RoleFilterRequestTypes[];
}

const PermissionItem: FC<PermissionItemType> = ({
    title,
    hasView,
    hasCreate,
    hasDelete,
    hasUpdate,
    filterType,
    roleFilters,
}) => {
    const [t] = useTranslation();

    const {
        admin: {formsList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const permissionsArray: string[] = [];
    if (hasView) {
        permissionsArray.push(t('superadmin.permissions.view'));
    }
    if (hasCreate) {
        permissionsArray.push(t('superadmin.permissions.create'));
    }
    if (hasUpdate) {
        permissionsArray.push(t('superadmin.permissions.update'));
    }
    if (hasDelete) {
        permissionsArray.push(t('superadmin.permissions.delete'));
    }

    const filtersArray =
        formsList && filterType && roleFilters ? getFilteredForms(formsList, filterType, roleFilters) : [];

    return (
        <PermissionItemWrapper title={title}>
            <Typography>{permissionsArray.length > 0 ? permissionsArray.join(', ') : t('common.none')}</Typography>
            {filtersArray && filtersArray.length > 0 && (
                <Typography sx={{ml: 1, mr: 1}}>{`(${filtersArray})`}</Typography>
            )}
            {roleFilters && permissionsArray.length > 0 && filtersArray.length === 0 && (
                <Typography sx={{ml: 1, mr: 1}}>{`(${t('superadmin.permissions.forAllForms')})`}</Typography>
            )}
        </PermissionItemWrapper>
    );
};

export default PermissionItem;
