import React, {FC, useCallback, useContext} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import {TWO_FACTOR_CONFIRMATION} from 'appRedux/actions/auth';
import {TwoFactorConfirmationTypes} from 'appRedux/actions/auth/types';
import {GET_USER_INFO} from 'appRedux/actions/profile';

import {AlertContext} from 'contexts/alert/context';
import {CryptoContext} from 'contexts/crypto/context';
import {MediaContext} from 'contexts/media/context';

import GoogleAuthCodeForm from 'components/Forms/SettingsForms/TwoFactorForm/GoogleAuthCodeForm';
import EmptyArea from 'components/EmptyArea';

import {getRedirectRoute} from 'pages/start/login/helper';

import {LOCAL_STORAGE_REDIRECT_AFTER_LOGIN} from 'services/localStorage';

import {routes} from 'config/index';
import {theme} from 'config/theme';

interface LoginSecondStepType {
    email: string;
}

const LoginSecondStep: FC<LoginSecondStepType> = ({email}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {regenerateKeysAfterLogin} = useContext(CryptoContext);
    const {setLanguageAfterLogin} = useContext(MediaContext);

    const confirmTwoFactor = useCallback(
        data => dispatch({type: TWO_FACTOR_CONFIRMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getUserInfo = useCallback(data => dispatch({type: GET_USER_INFO.REQUEST, payload: data}), [dispatch]);

    const navigationCallback = () => {
        getUserInfo({
            callback: async (
                roles: string[],
                organizationMultiRoleType: number,
                languageId: number,
                defaultMode: number,
            ) => {
                setLanguageAfterLogin(languageId);
                const redirectAfterLogin = localStorage.getItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN);
                if (redirectAfterLogin) {
                    localStorage.removeItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN);
                    await regenerateKeysAfterLogin(redirectAfterLogin);
                } else {
                    await regenerateKeysAfterLogin(getRedirectRoute(roles, organizationMultiRoleType, defaultMode));
                }
            },
        });
    };

    const handleSubmit = (values: TwoFactorConfirmationTypes) => {
        confirmTwoFactor({
            ...values,
            showAlert,
            callback: navigationCallback,
        });
    };

    return (
        <Box
            sx={{
                minHeight: 200,
                width: '100%',
            }}
        >
            <GoogleAuthCodeForm handleSubmit={handleSubmit} />
            <Box
                sx={{
                    mt: 3,
                    width: '100%',
                }}
            >
                <Link
                    to={routes.RESET_PASSWORD}
                    sx={{textDecoration: 'none'}}
                    variant="body2"
                    component={RouterLink}
                    data-id="link#2fa-passwordreset"
                >
                    <Typography
                        align="center"
                        sx={{
                            color: theme.palette.info.light,
                        }}
                    >
                        {t('common.loginForm.resetPasswordTwoFactor')}
                    </Typography>
                </Link>
            </Box>
            <EmptyArea />
        </Box>
    );
};

export default LoginSecondStep;
