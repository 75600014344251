import React, {FC, useContext} from 'react';
import {View} from '@react-pdf/renderer';

import {PreparedTemplatePdfAttachmentTypes} from 'appRedux/actions/organization/types';

import {pdfStyles} from 'pages/agent/pdfExport/pdfPartials/PdfDocumentStyles';
import {PdfDocumentContext} from 'pages/agent/pdfExport/pdfPartials/pdfDocumentContext';
import {AttachmentComponent} from 'pages/agent/pdfExport/pdfPartials/ImagePdfComponent';

interface TemplatePdfContentType {
    template: PreparedTemplatePdfAttachmentTypes;
}

const TemplatePdfContent: FC<TemplatePdfContentType> = ({template}) => {
    const {fileData} = template;

    const {preview} = useContext(PdfDocumentContext);

    console.log('TemplatePdfContent', preview);

    return (
        <View fixed style={pdfStyles.template}>
            {typeof fileData === 'string' && <AttachmentComponent src={fileData} preview={preview} isPdf={true} />}
        </View>
    );
};

export default TemplatePdfContent;
