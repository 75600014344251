import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Formik, Form, FormikProps} from 'formik';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {ORGANIZATION_LANGUAGE_ADD} from 'appRedux/actions/organizationLanguage';
import {OrganizationLanguageCreateFormTypes} from 'appRedux/actions/organizationLanguage/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {
    organizationAddLanguageInitialValues,
    organizationAddLanguageValidationSchema,
} from 'components/Forms/OrganizationHasLanguageForm/validation';

import {preventSendForm} from 'helpers/index';

import {theme} from 'config/theme';

interface AddLanguageFormType {
    organizationId: number;
    organizationLanguage: LanguageItemTypes;
    closeModal: () => void;
}

const AddLanguageForm: FC<AddLanguageFormType> = ({organizationId, closeModal, organizationLanguage}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        admin: {
            languageList,
            formInfo: {languages},
            organizationLanguages,
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const submitForm = useCallback(
        data => dispatch({type: ORGANIZATION_LANGUAGE_ADD.REQUEST, payload: data}),
        [dispatch],
    );

    const handleSubmit = (values: OrganizationLanguageCreateFormTypes) => {
        submitForm({
            ...values,
            id: organizationId,
            callback: closeModal,
            showAlert,
        });
    };

    return (
        <Box>
            <Formik
                initialValues={organizationAddLanguageInitialValues}
                validationSchema={organizationAddLanguageValidationSchema}
                onSubmit={handleSubmit}
            >
                {(formik: FormikProps<OrganizationLanguageCreateFormTypes>) => {
                    return (
                        <Form onKeyDown={preventSendForm}>
                            <Box sx={{mt: 1, mb: 3}}>
                                <FormControl required fullWidth>
                                    <Select
                                        sx={{
                                            backgroundColor: theme.palette.warning.contrastText,
                                        }}
                                        variant="filled"
                                        name="language"
                                        required
                                        onChange={formik.handleChange}
                                        IconComponent={KeyboardArrowDownIcon}
                                    >
                                        {languageList &&
                                            languageList.map((item: LanguageItemTypes) => {
                                                const {lang, name, id, enabled} = item;
                                                const isLanguageAlreadyExists = organizationLanguages
                                                    ? organizationLanguages.some(
                                                          organizationLanguage =>
                                                              organizationLanguage.languageId === id,
                                                      )
                                                    : false;
                                                if (
                                                    name === organizationLanguage.name ||
                                                    isLanguageAlreadyExists ||
                                                    !enabled
                                                ) {
                                                    return null;
                                                }
                                                return (
                                                    <MenuItem key={`organization-language-${lang}`} value={id}>
                                                        <Typography>{`${name} - ${lang}`}</Typography>
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                                <AgentSaveButton
                                    isSubmit
                                    title={t('common.buttons.add')}
                                    icon={<AddIcon sx={{color: theme.palette.background.default}} />}
                                />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default AddLanguageForm;
