import React, {FC, Ref, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';

import {WorkflowTaskEmailTemplatesItemTypes, WorkflowTaskEmailTemplateFormTypes} from 'appRedux/actions/workflow/types';
import {UPDATE_WORKFLOW_TASK_EMAIL_TEMPLATE} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import WorkflowTaskEmailTemplateForm from 'components/Forms/Workflow/workflowStatusTaskTemplate/WorkflowTaskEmailTemplateForm';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface UpdateWorkflowTaskEmailTemplateFormType {
    formRef: Ref<any> | null;
    item: WorkflowTaskEmailTemplatesItemTypes;
    submitStatusEventForm: () => void;
}

const UpdateWorkflowTaskEmailTemplateForm: FC<UpdateWorkflowTaskEmailTemplateFormType> = ({
    item,
    formRef,
    submitStatusEventForm,
}) => {
    const dispatch = useDispatch();

    const {language, text, subject} = item;

    const {showAlert} = useContext(AlertContext);

    const updateWorkflowTaskEmailTemplate = useCallback(
        data => dispatch({type: UPDATE_WORKFLOW_TASK_EMAIL_TEMPLATE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: WorkflowTaskEmailTemplateFormTypes) => {
        updateWorkflowTaskEmailTemplate({
            uuid: item.uuid,
            showAlert,
            ...values,
        });
    };

    return (
        <Box>
            <WorkflowTaskEmailTemplateForm
                formRef={formRef}
                initialValues={{
                    language,
                    subject,
                    text,
                }}
                onSubmitClicked={onSubmitClicked}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 1,
                }}
            >
                <AgentSaveButton isSubmit={false} onClick={submitStatusEventForm} />
            </Box>
        </Box>
    );
};

export default UpdateWorkflowTaskEmailTemplateForm;
