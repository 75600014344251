import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

import {RootReducer} from 'appRedux/reducers';
import {ORGANIZATION_QUOTAS_LIST} from 'appRedux/actions/organization';

import {AlertContext} from 'contexts/alert/context';

import {
    organizationQuotasList,
    OrganizationQuotasArrayType,
} from 'pages/admin/updateOrganization/partials/OrganizationQuotas/helper';
import OrganizationQuotasRow from 'pages/admin/updateOrganization/partials/OrganizationQuotas/OrganizationQuotasRow';

const OrganizationQuotas: FC = () => {
    const [t] = useTranslation();
    const {id} = useParams();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        admin: {organizationQuotas},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getOrganizationQuotas = useCallback(
        data => dispatch({type: ORGANIZATION_QUOTAS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        getOrganizationQuotas({
            id,
            showAlert,
        });
    }, []);

    return (
        <Table>
            <TableHead>
                <TableCell>
                    <Typography sx={{fontWeight: 600}}>{t('superadmin.organizations.type')}</Typography>
                </TableCell>
                <TableCell>
                    <Typography sx={{fontWeight: 600}}>{t('superadmin.organizations.value')}</Typography>
                </TableCell>
                <TableCell>
                    <Typography sx={{fontWeight: 600}}>{t('superadmin.organizations.isHard')}</Typography>
                </TableCell>
                <TableCell width={100}>
                    <Typography sx={{fontWeight: 600}}>{t('superadmin.organizations.action')}</Typography>
                </TableCell>
            </TableHead>
            <TableBody>
                {organizationQuotasList.map((item: OrganizationQuotasArrayType) => {
                    const quota = organizationQuotas.find(organizationQuota => organizationQuota.type === item.type);
                    return <OrganizationQuotasRow key={`quota-${item.title}`} quota={quota} item={item} />;
                })}
            </TableBody>
        </Table>
    );
};

export default OrganizationQuotas;
