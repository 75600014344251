import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FORM_FAQ_LIST} from 'appRedux/actions/forms';
import {FormFaqItemTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import ClientScreenWrapper from 'components/ClientScreenComponents/ClientScreenWrapper/ClientScreenWrapper';
import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

import FormFaqItemRow from 'pages/client/formFaq/FormFaqItemRow';
import LanguageDropdownSwitcher from 'pages/client/form/partials/LanguageDropdownSwitcher';

import {getLanguageById} from 'helpers/translationsHelper';

const FormFaqPage: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {form} = useParams();
    const navigate = useNavigate();

    const {showAlert} = useContext(AlertContext);

    const getFormFaqInformation = useCallback(
        data => dispatch({type: FORM_FAQ_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const redirectToForm = () => {
        navigate(-1);
    };

    const {
        admin: {isLoading, formFaq, languageList},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        if (profile) {
            const language = getLanguageById(languageList, profile.languageId);
            getFormFaqInformation({
                id: Number(form),
                language: language.name,
                showAlert,
            });
        }
    }, [form, profile]);

    return (
        <ClientScreenWrapper>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 2,
                    mb: 1,
                }}
            >
                <Typography align="center" sx={{fontSize: 24, fontWeight: 700, mb: 2}}>
                    {t('requester.faq.pageTitle')}
                </Typography>
            </Box>
            <Box sx={{mb: 2}}>
                <LanguageDropdownSwitcher />
            </Box>
            <ClientSectionWrapper>
                <Typography
                    sx={{
                        cursor: 'pointer',
                        fontWeight: 700,
                    }}
                    onClick={redirectToForm}
                >
                    {t('requester.faq.backToForm')}
                </Typography>
                <Box sx={{mt: 3}}>
                    {formFaq && formFaq.length > 0 ? (
                        formFaq.map((item: FormFaqItemTypes) => {
                            return <FormFaqItemRow key={`form-faq-uuid`} item={item} />;
                        })
                    ) : !isLoading ? (
                        <Typography>{t('common.none')}</Typography>
                    ) : null}
                </Box>
            </ClientSectionWrapper>
        </ClientScreenWrapper>
    );
};

export default FormFaqPage;
