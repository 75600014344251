import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';

import {FormAllowListTypes} from 'appRedux/actions/forms/types';
import {FORM_ALLOW_LIST_DELETE} from 'appRedux/actions/forms';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationButton from 'components/AdminScreenComponents/DeleteAdminConfirmationButton';

interface FormWhiteListEmailDeleteButtonType {
    item: FormAllowListTypes;
}

const FormAllowListEmailDeleteButton: FC<FormWhiteListEmailDeleteButtonType> = ({item}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpened(previous => !previous);
    };

    const {uuid} = item;

    const deleteWhileListEmail = useCallback(
        data => dispatch({type: FORM_ALLOW_LIST_DELETE.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        deleteWhileListEmail({
            uuid,
            showAlert,
            callback: () => setIsOpened(false),
        });
    };

    return (
        <DeleteAdminConfirmationButton
            isShowModal={isOpened}
            closeIcon
            disabled={false}
            toggleModal={toggleModal}
            onClickAction={onClickDelete}
        />
    );
};

export default FormAllowListEmailDeleteButton;
