import React, {ChangeEvent, FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as EmailValidator from 'email-validator';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';
import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';
import CheckSvgIcon from 'assets/icons/buttons/CheckSvgIcon';
import CloseSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import EmptyArea from 'components/EmptyArea';

import {theme, ERROR_TEXT_COLOR} from 'config/theme';

interface EmailOptionType {
    emails: string[];
    setEmails: (value: string[]) => void;
    email: string;
    onDeleteClick: (value: string) => void;
    toggleIsUpdated: () => void;
}

const EmailOption: FC<EmailOptionType> = ({emails, setEmails, email, toggleIsUpdated, onDeleteClick}) => {
    const [t] = useTranslation();

    const [onEditClicked, setOnEditClicked] = useState<boolean>(false);
    const [value, setValue] = useState<string>(email);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const toggleOnEditClicked = () => {
        setOnEditClicked(previous => !previous);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = String(e.target.value);
        setValue(inputValue);
    };

    const onCloseClicked = () => {
        setOnEditClicked(false);
        setErrorMessage(null);
    };

    const onSubmitClick = () => {
        if (value) {
            const isAlreadyExists = emails.some(item => item === value);
            if (!EmailValidator.validate(value)) {
                setErrorMessage('messages.validation.emailIncorrect');
            } else if (isAlreadyExists) {
                setErrorMessage('messages.validation.emailAlreadyExists');
            } else {
                const results: string[] = [];
                emails.forEach(item => {
                    if (item === email) {
                        results.push(value);
                    } else {
                        results.push(item);
                    }
                });
                setEmails(results);
                setOnEditClicked(false);
                toggleIsUpdated();
            }
        }
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: theme.palette.warning.contrastText,
                    borderRadius: 2,
                    mb: 1,
                    p: 1,
                    pl: 1,
                }}
            >
                {onEditClicked ? (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 1,
                        }}
                    >
                        <TextField
                            sx={{width: 350}}
                            fullWidth
                            variant="standard"
                            value={value}
                            onChange={handleChange}
                        />
                        <EmptyArea />
                        <IconButton onClick={onSubmitClick}>
                            <CheckSvgIcon />
                        </IconButton>
                        <IconButton onClick={onCloseClicked}>
                            <CloseSvgIcon />
                        </IconButton>
                    </Box>
                ) : (
                    <>
                        <Typography>{email}</Typography>
                        <EmptyArea />
                        <IconButton onClick={toggleOnEditClicked}>
                            <EditSvgIcon />
                        </IconButton>
                        <IconButton onClick={() => onDeleteClick(email)}>
                            <DeleteSvgIcon />
                        </IconButton>
                    </>
                )}
            </Box>
            {errorMessage && (
                <Typography sx={{fontSize: 12, color: ERROR_TEXT_COLOR, mt: 0.5, mb: 1.5}}>
                    {t(errorMessage)}
                </Typography>
            )}
        </>
    );
};

export default EmailOption;
