import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Formik, Form, Field, FormikProps} from 'formik';
import {useLocation, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

import {HelpStartRequestFormTypes} from 'appRedux/actions/auth/types';
import {HELP_START} from 'appRedux/actions/auth';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {CryptoContext} from 'contexts/crypto/context';
import {MediaContext} from 'contexts/media/context';

import LoadingStartButton from 'components/StartScreenComponents/LoadingStartButton';
import ClientFormikTextInput from 'components/StartScreenComponents/ClientFormTextInput';
import {startValidationSchema, startStepInitialValues} from 'components/RegistrationForm/validation';
import LoginIfAlreadyInCase from 'components/RegistrationForm/LoginIfAlreadyInCase';
import BackToRegister from 'components/RegistrationForm/BackToRegister';

import LoginForms from 'pages/start/login/partials/LoginForms';

import {preventSendForm} from 'helpers/index';

import {routes} from 'config/index';

const StartForm: FC = () => {
    const [t] = useTranslation();
    const schema = () => startValidationSchema(t);
    const dispatch = useDispatch();
    const {form} = useParams();
    const {hash} = useLocation();

    const {showAlert} = useContext(AlertContext);
    const {regenerateKeysAfterLogin} = useContext(CryptoContext);
    const {language} = useContext(MediaContext);

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);

    const {auth} = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isEncryptInfo = get(auth, ['form', 'isEncryptInfo'], false);
    const formButtonText = get(auth, ['form', 'formButtonText'], '');

    const [isLogin, setIsLogin] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(previous => !previous);
    };

    const toggleShowRepeatPassword = () => {
        setShowRepeatPassword(previous => !previous);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const redirectToForm = async (userId: number, caseId: number, formId: number) => {
        const redirectTo = isEncryptInfo
            ? `${routes.REQUEST}/${caseId}/keysGenerate/${formId}`
            : `${routes.REQUEST}/${caseId}/form/${formId}`;
        await regenerateKeysAfterLogin(redirectTo);
    };

    const startRequest = useCallback(data => dispatch({type: HELP_START.REQUEST, payload: data}), [dispatch]);

    if (isLogin) {
        return (
            <>
                <LoginForms />
                <BackToRegister
                    onClick={() => {
                        setIsLogin(false);
                    }}
                />
            </>
        );
    }

    return (
        <Formik
            initialValues={startStepInitialValues}
            validationSchema={schema}
            onSubmit={(values, {setErrors}) => {
                startRequest({
                    ...values,
                    setErrors,
                    form,
                    invite: String(hash).replace('#', ''),
                    language: language,
                    callbackRegistration: redirectToForm,
                    showAlert,
                });
            }}
        >
            {(formik: FormikProps<HelpStartRequestFormTypes>) => {
                return (
                    <Box sx={{width: '100%'}}>
                        <Form onKeyDown={preventSendForm}>
                            <Field
                                name="nickname"
                                label={t('common.registrationForm.nickname')}
                                placeholder={t('common.registrationForm.nickname')}
                                component={ClientFormikTextInput}
                            />
                            <Field
                                name="email"
                                data-e2e-id="emailField"
                                label={t('common.registrationForm.email')}
                                placeholder={t('common.registrationForm.email')}
                                component={ClientFormikTextInput}
                            />
                            <Field
                                name="password.first"
                                data-e2e-id="passwordFirstField"
                                label={t('common.registrationForm.password')}
                                placeholder={t('common.registrationForm.password')}
                                id="password-first"
                                type={showPassword ? 'text' : 'password'}
                                component={ClientFormikTextInput}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={toggleShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Field
                                name="password.second"
                                data-e2e-id="passwordSecondField"
                                label={t('common.registrationForm.confirmPassword')}
                                placeholder={t('common.registrationForm.confirmPassword')}
                                id="password-second"
                                type={showRepeatPassword ? 'text' : 'password'}
                                component={ClientFormikTextInput}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={toggleShowRepeatPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 2,
                                }}
                            >
                                <LoadingStartButton
                                    label={formButtonText ? formButtonText : t('common.registrationForm.requestHelp')}
                                />
                                <LoginIfAlreadyInCase
                                    onClick={() => {
                                        setIsLogin(true);
                                    }}
                                />
                            </Box>
                        </Form>
                    </Box>
                );
            }}
        </Formik>
    );
};

export default StartForm;
