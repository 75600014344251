import React from 'react';
import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import IconButton from '@mui/material/IconButton';

import {MAIN_CLIENT_COLOR} from 'config/theme';

const BackBtn = () => {
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <IconButton
                onClick={e => {
                    e.preventDefault();
                    navigate(-1);
                }}
                sx={{
                    position: 'sticky',
                    top: '20px',
                    backgroundColor: MAIN_CLIENT_COLOR,
                    color: '#FFF',
                    '&:hover': {
                        backgroundColor: MAIN_CLIENT_COLOR,
                    },
                }}
            >
                <KeyboardArrowLeftIcon fontSize="large" />
            </IconButton>
        </Box>
    );
};

export default BackBtn;
