import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {View, Text} from '@react-pdf/renderer';

import {RequesterCaseCommentsTypes} from 'appRedux/actions/comments/types';

import {DATE_TIME_LABEL_FORMAT} from 'config/index';
import {pdfStyles} from './PdfDocumentStyles';

interface CommentPdfRowType {
    item: RequesterCaseCommentsTypes;
}

const CommentPdfRow: FC<CommentPdfRowType> = ({item}) => {
    const [t] = useTranslation();

    const {text, dateAndTime, author, isUpdated} = item;

    return (
        <View style={pdfStyles.comment} wrap={false}>
            <View style={pdfStyles.commentAuthor}>
                <Text>{author}</Text>
            </View>
            <View style={pdfStyles.commentText}>
                <Text>{text}</Text>
            </View>
            <View style={pdfStyles.commentExtras}>
                <Text style={pdfStyles.commentTime}>{moment.unix(dateAndTime).format(DATE_TIME_LABEL_FORMAT)}</Text>
                {isUpdated && <Text style={pdfStyles.commentEdited}>{t('orguser.requesterCase.edited')}</Text>}
            </View>
        </View>
    );
};

export default CommentPdfRow;
