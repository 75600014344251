import React, {FC, useLayoutEffect, useRef} from 'react';

import Box from '@mui/material/Box';

const DragScrollAreas: FC = () => {
    const scrollInterval = useRef<any>();

    const handleMouseEnter = (isUp: boolean) => () => {
        // Start the scroll when mouse enters the div
        scrollInterval.current = requestAnimationFrame(scroll(isUp));
    };

    const handleMouseLeave = () => {
        // Stop the scroll when mouse leaves the div
        scrollInterval && cancelAnimationFrame(scrollInterval.current);
    };

    const scroll = (isUp: boolean) => () => {
        // Calculate the amount to scroll per frame (100px per second)
        const scrollAmount = 200 / 60; // 100px per second at 60 FPS
        window.scrollBy(0, isUp ? -scrollAmount : scrollAmount);

        // Continue scrolling recursively
        scrollInterval.current = requestAnimationFrame(scroll(isUp));
    };

    useLayoutEffect(() => {
        return () => {
            scrollInterval && cancelAnimationFrame(scrollInterval.current);
        };
    });

    return (
        <>
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: 180,
                    zIndex: 100000,
                }}
                onDragEnter={handleMouseEnter(true)}
                onDragLeave={handleMouseLeave}
            />
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: 80,
                }}
                onDragEnter={handleMouseEnter(false)}
                onDragLeave={handleMouseLeave}
            />
        </>
    );
};

export default DragScrollAreas;
