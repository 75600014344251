import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {ResourceFieldBookframeFormTypes} from 'appRedux/actions/resourceFields/types';
import {CREATE_RESOURCE_FIELD_BOOKFRAME} from 'appRedux/actions/resourceFields';

import {AlertContext} from 'contexts/alert/context';

import ResourceFieldBookframeForm from 'components/Forms/ResourceField/ResourceFieldBookframeForm';

interface CreateResourceFieldBookframeFormType {
    uuid: string;
    closeModal: () => void;
}

const CreateResourceFieldBookframeForm: FC<CreateResourceFieldBookframeFormType> = ({uuid, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const createResourceFieldBookframe = useCallback(
        data => dispatch({type: CREATE_RESOURCE_FIELD_BOOKFRAME.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: ResourceFieldBookframeFormTypes) => {
        createResourceFieldBookframe({
            ...values,
            uuid,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <ResourceFieldBookframeForm
            initialValues={{
                weekday: 1,
                startTime: '09:00',
                endTime: '12:00',
            }}
            onSubmitClicked={onSubmitClicked}
        />
    );
};

export default CreateResourceFieldBookframeForm;
