import {
    NotificationType,
    NotificationRequestType,
    NotificationRemoveAllRequestType,
} from 'appRedux/actions/notifications/types';

import {requestTypeCreator} from 'helpers/index';

export const GET_NOTIFICATIONS_LIST = requestTypeCreator('GET_NOTIFICATIONS_LIST');
export const CREATE_NOTIFICATION = requestTypeCreator('CREATE_NOTIFICATION');
export const REVIEW_NOTIFICATION = requestTypeCreator('REVIEW_NOTIFICATION');
export const REMOVE_NOTIFICATION = requestTypeCreator('REMOVE_NOTIFICATION');
export const REMOVE_ALL_NOTIFICATIONS = requestTypeCreator('REMOVE_ALL_NOTIFICATIONS');

export const getNotificationsList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_NOTIFICATIONS_LIST.ERROR,
    }),
    request: () => ({
        type: GET_NOTIFICATIONS_LIST.REQUEST,
    }),
    success: (payload: NotificationType[]): IActionType<NotificationType[]> => ({
        payload,
        type: GET_NOTIFICATIONS_LIST.SUCCESS,
    }),
};

export const createNotification = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_NOTIFICATION.ERROR,
    }),
    request: (payload: NotificationRequestType): IActionType<NotificationRequestType> => ({
        payload,
        type: CREATE_NOTIFICATION.REQUEST,
    }),
    success: (payload: NotificationType[]): IActionType<NotificationType[]> => ({
        payload,
        type: CREATE_NOTIFICATION.SUCCESS,
    }),
};

export const reviewNotification = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REVIEW_NOTIFICATION.ERROR,
    }),
    request: (payload: NotificationRequestType): IActionType<NotificationRequestType> => ({
        payload,
        type: REVIEW_NOTIFICATION.REQUEST,
    }),
    success: (payload: NotificationType[]): IActionType<NotificationType[]> => ({
        payload,
        type: REVIEW_NOTIFICATION.SUCCESS,
    }),
};

export const removeNotification = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REMOVE_NOTIFICATION.ERROR,
    }),
    request: (payload: NotificationRequestType): IActionType<NotificationRequestType> => ({
        payload,
        type: REMOVE_NOTIFICATION.REQUEST,
    }),
    success: (payload: NotificationType[]): IActionType<NotificationType[]> => ({
        payload,
        type: REMOVE_NOTIFICATION.SUCCESS,
    }),
};

export const removeAllNotifications = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REMOVE_ALL_NOTIFICATIONS.ERROR,
    }),
    request: (payload: NotificationRemoveAllRequestType): IActionType<NotificationRemoveAllRequestType> => ({
        payload,
        type: REMOVE_ALL_NOTIFICATIONS.REQUEST,
    }),
    success: (payload: NotificationType[]): IActionType<NotificationType[]> => ({
        payload,
        type: REMOVE_ALL_NOTIFICATIONS.SUCCESS,
    }),
};
