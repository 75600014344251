import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import AccountSvgIcon from 'assets/icons/topBarMenu/AccountSvgIcon';
import SecuritySvgIcon from 'assets/icons/topBarMenu/SecuritySvgIcon';
import LogoutSvgIcon from 'assets/icons/topBarMenu/LogoutSvgIcon';
import HelpRequestSvgIcon from 'assets/icons/HelpRequestSvgIcon';

import {RouteContext} from 'contexts/route/context';

import {theme} from 'config/theme';
import {routes} from 'config/index';

interface AccountMobileMenuType {
    redirectToLink: (value: string) => void;
}

const AccountMobileMenu: FC<AccountMobileMenuType> = ({redirectToLink}) => {
    const [t] = useTranslation();

    const {onLogoutClicked} = useContext(RouteContext);

    const mainMenuItems = [
        {
            visible: true,
            icon: AccountSvgIcon,
            label: t('common.menu.account'),
            onClickAction: () => redirectToLink(routes.ACCOUNT),
        },
        {
            visible: true,
            icon: SecuritySvgIcon,
            label: t('common.menu.security'),
            onClickAction: () => redirectToLink(routes.SECURITY),
        },
        {
            visible: true,
            icon: HelpRequestSvgIcon,
            label: t('common.menu.myCases'),
            onClickAction: () => redirectToLink(routes.REQUESTS_LIST),
        },
        {
            visible: true,
            icon: LogoutSvgIcon,
            label: t('common.menu.logout'),
            onClickAction: () => onLogoutClicked(),
        },
    ];

    return (
        <Box>
            {mainMenuItems.map(item => {
                const Icon = item.icon;
                if (!item.visible) {
                    return null;
                }
                return (
                    <Box
                        key={`mainMenu-${item.label}`}
                        onClick={item.onClickAction}
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton sx={{width: 40, height: 40}}>
                            <Icon isActive />
                        </IconButton>
                        <Typography
                            sx={{
                                ml: 1,
                                color: theme.palette.background.default,
                                fontWeight: 600,
                            }}
                        >
                            {item.label}
                        </Typography>
                    </Box>
                );
            })}
        </Box>
    );
};

export default AccountMobileMenu;
