import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const ClearSaveSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="crossSaveMask" x="0" y="0" width="16" height="16" fill="white">
                    <rect x="0" y="0" width="16" height="16" fill="white" />
                    <path d="M14 2L2 14" stroke="black" strokeWidth="4" strokeLinecap="round" />
                </mask>

                <path
                    d="M13.855 4.64498L11.355 2.14498C11.3083 2.09864 11.2529 2.06197 11.192 2.03709C11.131 2.01221 11.0658 1.9996 11 1.99998H3C2.73478 1.99998 2.48043 2.10533 2.29289 2.29287C2.10536 2.48041 2 2.73476 2 2.99998V13C2 13.2652 2.10536 13.5195 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H13C13.2652 14 13.5196 13.8946 13.7071 13.7071C13.8946 13.5195 14 13.2652 14 13V4.99998C14.0004 4.93417 13.9878 4.86894 13.9629 4.80802C13.938 4.74711 13.9013 4.6917 13.855 4.64498ZM6 2.99998H10V4.99998H6V2.99998ZM10 13H6V8.99998H10V13ZM11 13V8.99998C11 8.73476 10.8946 8.48041 10.7071 8.29287C10.5196 8.10534 10.2652 7.99998 10 7.99998H6C5.73478 7.99998 5.48043 8.10534 5.29289 8.29287C5.10536 8.48041 5 8.73476 5 8.99998V13H3V2.99998H5V4.99998C5 5.26519 5.10536 5.51955 5.29289 5.70708C5.48043 5.89462 5.73478 5.99998 6 5.99998H10C10.2652 5.99998 10.5196 5.89462 10.7071 5.70708C10.8946 5.51955 11 5.26519 11 4.99998V3.20498L13 5.20498V13H11Z"
                    fill={getIconColor(isActive, isDisabled)}
                    mask="url(#crossSaveMask)"
                />

                <path
                    d="M14 2L2 14"
                    stroke={getIconColor(isActive, isDisabled)}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
            </svg>
        </svg>
    );
};

export default ClearSaveSvgIcon;
