import React, {FC, MouseEvent, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {get} from 'lodash';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';

import {RootReducer} from 'appRedux/reducers';
import {OrganizationLanguageTypes} from 'appRedux/actions/organizationLanguage/types';
import {WorkflowTemplateTypes} from 'appRedux/actions/workflow/types';

import {AdminContext} from 'contexts/admin/context';

import AgentSelectButton from 'components/AgentScreenComponents/_buttons/AgentSelectButton';

import {getLanguageById, showLanguageSelector} from 'helpers/translationsHelper';

import {theme} from 'config/theme';

interface TranslationSelectorType {
    translations: WorkflowTemplateTypes[];
    language: number;
    setLanguage: (value: number) => void;
    forEmailTemplate?: boolean;
}

const TranslationSelector: FC<TranslationSelectorType> = ({translations, forEmailTemplate, language, setLanguage}) => {
    const [t] = useTranslation();

    const {myOrganization} = useContext(AdminContext);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const {
        admin: {languageList, organizationLanguages},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = value => {
        setLanguage(value);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const organizationLanguage = myOrganization ? getLanguageById(languageList, myOrganization.language) : null;

    if (!myOrganization || !organizationLanguage) return null;

    const activeOrganizationLanguages = organizationLanguages
        ? organizationLanguages.filter(item => item.isActive && item.languageName !== organizationLanguage.name)
        : [];

    const currentLanguage = getLanguageById(languageList, language);
    const translationPresentedOrganizationLanguage = get(translations, [currentLanguage.name], null);

    if (!showLanguageSelector(activeOrganizationLanguages, organizationLanguage)) {
        return null;
    }
    return (
        <>
            <AgentSelectButton
                title={`${t('orguser.forms.selectLanguage')}: ${currentLanguage.name}`}
                onClick={handleClick}
                open={open}
            />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        mt: 1,
                        pl: 2,
                        pr: 2,
                        pt: 1,
                        pb: 1,
                        width: 290,
                        borderRadius: 2,
                        overflow: 'visible',
                        boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                        backgroundColor: theme.palette.background.default,
                        '& ul': {
                            padding: 0,
                        },
                        overflowX: 'hidden',
                    },
                }}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            >
                <MenuItem
                    value={organizationLanguage.name}
                    key={`form-language-${organizationLanguage.name}`}
                    onClick={() => handleChange(organizationLanguage.id)}
                >
                    <Badge color="error" variant={'dot'} invisible={translationPresentedOrganizationLanguage}>
                        <Typography>{organizationLanguage.lang}</Typography>
                    </Badge>
                </MenuItem>
                {activeOrganizationLanguages &&
                    activeOrganizationLanguages.map((item: OrganizationLanguageTypes) => {
                        const {languageTitle, languageName, languageId} = item;
                        const currentTemplate: WorkflowTemplateTypes = get(translations, [languageName], null);
                        const translationPresented =
                            organizationLanguage.name === languageName
                                ? true
                                : forEmailTemplate
                                ? Boolean(currentTemplate && currentTemplate.subject && currentTemplate.text)
                                : Boolean(currentTemplate && currentTemplate.text);
                        return (
                            <MenuItem
                                value={languageName}
                                key={`organization-language-${languageName}`}
                                onClick={() => handleChange(languageId)}
                            >
                                <Badge color="error" variant={'dot'} invisible={translationPresented}>
                                    <Typography>{languageTitle}</Typography>
                                </Badge>
                            </MenuItem>
                        );
                    })}
            </Menu>
        </>
    );
};

export default TranslationSelector;
