import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Typography from '@mui/material/Typography';

import {OPEN_ID_LOGIN} from 'appRedux/actions/auth';

import {AlertContext} from 'contexts/alert/context';

import LoginPageWrapper from 'pages/start/login/partials/LoginPageWrapper';

interface RegistrationOrLoginViaSsoType {
    form: string;
}

const RegistrationOrLoginViaSso: FC<RegistrationOrLoginViaSsoType> = ({form}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const loginSsoRequest = useCallback(data => dispatch({type: OPEN_ID_LOGIN.REQUEST, payload: data}), [dispatch]);

    useEffect(() => {
        loginSsoRequest({
            isRegistration: true,
            form,
            showAlert,
            callback: (redirectTo: string) => {
                window.location.href = redirectTo;
            },
        });
    }, []);

    return (
        <LoginPageWrapper>
            <Typography>{t('common.pleaseWait')}</Typography>
        </LoginPageWrapper>
    );
};

export default RegistrationOrLoginViaSso;
