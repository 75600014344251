import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import SendMailSvgIcon from 'assets/icons/buttons/SendMailSvgIcon';

import {REQUESTER_CASE_EMAIL_REQUEST} from 'appRedux/actions/requestCase';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {MediaContext} from 'contexts/media/context';

import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import AgentIconButton from 'components/AgentScreenComponents/_buttons/AgentIconButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {ALERT_TYPE_SUCCESS} from 'config/index';

const RequesterCaseEmailRequestButton: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {caseId} = useParams();

    const {showAlert} = useContext(AlertContext);
    const {isLargeDesktop} = useContext(MediaContext);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [requesterEmail, setRequesterEmail] = useState<string | null>(null);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    const requesterCaseEmailRequest = useCallback(
        data => dispatch({type: REQUESTER_CASE_EMAIL_REQUEST.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        requestCase: {isEmailRequestLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onShowEmailClick = () => {
        setShowModal(true);
        requesterCaseEmailRequest({
            id: Number(caseId),
            showAlert,
            callback: setRequesterEmail,
        });
    };

    const copyEmailToBuffer = () => {
        if (requesterEmail) {
            navigator.clipboard.writeText(requesterEmail);
            showAlert(ALERT_TYPE_SUCCESS, t('message.emailCopiedToBuffer'));
        }
    };

    const InverseButton = isLargeDesktop ? AgentInverseButton : AgentIconButton;

    return (
        <>
            <InverseButton
                icon={<SendMailSvgIcon />}
                title={t('orguser.requesterCase.showRequesterEmail')}
                onClick={onShowEmailClick}
            />
            <ModalWrapper
                isShowModal={showModal}
                title={t('orguser.requesterCase.requesterCaseEmailRequest')}
                toggleModal={toggleModal}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {isEmailRequestLoading && <Typography>{t('common.pleaseWait')}</Typography>}
                    {requesterEmail && (
                        <Alert
                            severity={'success'}
                            sx={{
                                width: '100%',
                                mb: 1,
                            }}
                        >
                            <Typography>{requesterEmail}</Typography>
                        </Alert>
                    )}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: requesterEmail ? 'space-between' : 'center',
                        alignItems: 'center',
                        mt: 2,
                    }}
                >
                    <AgentSaveButton
                        isSubmit={false}
                        title={t('common.buttons.close')}
                        onClick={() => setShowModal(false)}
                    />
                    <AgentSaveButton
                        isSubmit={false}
                        title={t('common.buttons.copyEmailToBuffer')}
                        onClick={copyEmailToBuffer}
                    />
                </Box>
            </ModalWrapper>
        </>
    );
};

export default RequesterCaseEmailRequestButton;
