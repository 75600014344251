import React, {FC} from 'react';

import {Tooltip, TooltipProps} from '@mui/material';

const CustomTooltip: FC<TooltipProps> = ({children, ...tooltipProps}) => {
    return (
        <Tooltip
            componentsProps={{
                tooltip: {
                    sx: {
                        fontSize: 18,
                        backgroundColor: 'rgb(97, 97, 97)',
                    },
                },
            }}
            disableInteractive
            {...tooltipProps}
        >
            {children}
        </Tooltip>
    );
};

export default CustomTooltip;
