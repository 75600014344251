import React, {FC} from 'react';

import AddIcon from '@mui/icons-material/Add';
import {IconButton} from '@mui/material';

import {theme} from 'config/theme';

const AgentAddIconButton: FC = () => {
    return (
        <IconButton
            size="small"
            sx={{
                backgroundColor: theme.palette.info.main,
                color: theme.palette.background.default,
                '&:hover': {
                    backgroundColor: theme.palette.info.main,
                    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
                },
            }}
        >
            <AddIcon />
        </IconButton>
    );
};

export default AgentAddIconButton;
