import React, {FC, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import SaveSvgIcon from 'assets/icons/buttons/SaveSvgIcon';

import {RootReducer} from 'appRedux/reducers';
import {AgentSavedFilterFormTypes} from 'appRedux/actions/agentFilters/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import SavedFilterForm from 'components/Forms/SavedFilterForm/SavedFilterForm';
import AgentIconButton from 'components/AgentScreenComponents/_buttons/AgentIconButton';
import {getSavedFilterModalTitle} from 'components/BlockView/helper';

interface SaveFilterButtonType {
    uuid: string;
}

const SaveFilterButton: FC<SaveFilterButtonType> = ({uuid}) => {
    const [t] = useTranslation();

    const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
    const [updatedFilter, setUpdatedFilter] = useState<AgentSavedFilterFormTypes | null>(null);

    const {
        workflow: {boards},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const toggleIsModalOpened = () => {
        setIsModalOpened(previous => !previous);
    };

    const board = boards && boards.find(item => item.uuid === uuid);

    if (!board) return null;
    return (
        <>
            <AgentIconButton onClick={toggleIsModalOpened} icon={<SaveSvgIcon />} />
            <ModalWrapper isShowModal={isModalOpened} toggleModal={toggleIsModalOpened}>
                <Box sx={{p: 3, pt: 2}}>
                    <Typography sx={{fontSize: 24, lineHeight: '32px', mb: 2}}>
                        {getSavedFilterModalTitle(t, updatedFilter)}
                    </Typography>
                    <SavedFilterForm
                        uuid={uuid}
                        boardId={board.id}
                        updatedFilter={updatedFilter}
                        setUpdatedFilter={setUpdatedFilter}
                        closeModal={() => setIsModalOpened(false)}
                    />
                </Box>
            </ModalWrapper>
        </>
    );
};

export default SaveFilterButton;
