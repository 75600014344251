import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import Grid from '@mui/material/Grid';

import {RootReducer} from 'appRedux/reducers';
import {FormPageTypes} from 'appRedux/actions/forms/types';

import PdfEditor from 'pages/agent/pdfExport/PdfEditor';

import BackBtn from 'components/BackBtn/BackBtn';

const PdfViewContent: FC = () => {
    const {
        admin: {formInfo},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const pages: FormPageTypes[] = get(formInfo, 'pages', []);

    return (
        <Grid container>
            <Grid item sm={2}>
                <BackBtn />
            </Grid>
            <Grid item sm={8}>
                <PdfEditor pages={pages} />
            </Grid>
            <Grid item sm={2}></Grid>
        </Grid>
    );
};

export default PdfViewContent;
