import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import {GridColDef} from '@mui/x-data-grid';

import {GET_GROUPS_LIST} from 'appRedux/actions/groups';
import {ORGANIZATION_ADMIN_LIST} from 'appRedux/actions/organizationAdmin';
import {GET_ROLES_LIST} from 'appRedux/actions/roles';
import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';
import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';
import {RouteContext} from 'contexts/route/context';

import DataGridWrapper from 'components/AdminScreenComponents/DataGridWrapper';
import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';

import AddGroupButton from 'pages/admin/groups/AddGroupButton';
import getGroupsColumns from 'pages/admin/groups/getGroupsColumns';

const GroupsList: FC = () => {
    const dispatch = useDispatch();

    const {myOrganization} = useContext(AdminContext);
    const {showAlert} = useContext(AlertContext);
    const {isOrganizationEditEnabled, isRoleEditEnabled} = useContext(PermissionContext);
    const {refreshPageInformation, setPageTitle, setTopBarTitle, setBackLink, setBackTab, setBackLinkLabel} =
        useContext(RouteContext);

    const {
        groups: {groups},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getGroupList = useCallback(data => dispatch({type: GET_GROUPS_LIST.REQUEST, payload: data}), [dispatch]);

    const getRolesList = useCallback(data => dispatch({type: GET_ROLES_LIST.REQUEST, payload: data}), [dispatch]);

    const getOrganizationAdminsList = useCallback(
        data => dispatch({type: ORGANIZATION_ADMIN_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        refreshPageInformation();
        if (myOrganization && isOrganizationEditEnabled && isRoleEditEnabled) {
            getGroupList({
                id: myOrganization.id,
                showAlert,
            });
            getRolesList({
                id: myOrganization.id,
                showAlert,
            });
            getOrganizationAdminsList({
                id: myOrganization.id,
                showAlert,
            });
        }
    }, []);

    useEffect(() => {
        setPageTitle('');
        setTopBarTitle('');
        setBackLink('');
        setBackTab('');
        setBackLinkLabel('');
    }, []);

    const columns: GridColDef[] | null = getGroupsColumns();

    if (!myOrganization) return null;

    return (
        <Box>
            <AddGroupButton organizationId={myOrganization.id} />
            <DataGridWrapper itemsList={groups} itemsTotalNumber={groups ? groups.length : 0}>
                {groups && columns && (
                    <CustomDataGrid
                        rows={groups}
                        columns={columns}
                        getRowId={row => row.uuid}
                        checkboxSelection={false}
                        enablePaging
                    />
                )}
            </DataGridWrapper>
        </Box>
    );
};

export default GroupsList;
