import React, {FC, RefObject} from 'react';
import {MDXEditorMethods} from '@mdxeditor/editor';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface CustomMdxEditorButtonType {
    editorRef: RefObject<MDXEditorMethods>;
    title: string;
    markdown: string;
}

const CustomMdxEditorButton: FC<CustomMdxEditorButtonType> = ({title, markdown, editorRef}) => {
    const onCustomButtonClicked = () => {
        editorRef.current?.insertMarkdown(markdown);
    };

    return <AgentSaveButton title={title} isSubmit={false} onClick={onCustomButtonClicked} />;
};

export default CustomMdxEditorButton;
