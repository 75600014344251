import React, {FC} from 'react';
import {CommonSvgIconType, getIconColor} from './helper';

const AddFileSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 13.9999H8V14.9999H4C3.73502 14.9992 3.48111 14.8936 3.29374 14.7062C3.10637 14.5188 3.00077 14.2649 3 13.9999V1.99993C3.00077 1.73495 3.10637 1.48104 3.29374 1.29367C3.48111 1.1063 3.73502 1.00069 4 0.999928H9C9.06572 0.99812 9.13104 1.01059 9.19147 1.03649C9.2519 1.06239 9.30599 1.10109 9.35 1.14993L12.85 4.64993C12.8988 4.69395 12.9375 4.74804 12.9634 4.80847C12.9893 4.86889 13.0018 4.93421 13 4.99993V7.99993H12V5.99993H9C8.73502 5.99916 8.48111 5.89356 8.29374 5.70619C8.10637 5.51882 8.00077 5.26491 8 4.99993V1.99993H4V13.9999ZM11.8 4.99993L9 2.19993V4.99993H11.8ZM13 11.9999H15V12.9999H13V14.9999H12V12.9999H10V11.9999H12V9.99994H13V11.9999Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default AddFileSvgIcon;
