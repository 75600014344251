import React, {FC} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';

import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';

import {FormFaqFormTypes} from 'appRedux/actions/forms/types';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import MdxEditorSmallField from 'components/MdxEditorField/MdxEditorSmallField';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationSchema} from 'components/Forms/FormBuilder/FaqForm/validation';

interface FaqFormTypes {
    initialValues: FormFaqFormTypes;
    onSubmitAction: (values: FormFaqFormTypes) => void;
}

const FaqForm: FC<FaqFormTypes> = ({initialValues, onSubmitAction}) => {
    const [t] = useTranslation();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={values => {
                onSubmitAction({
                    ...values,
                });
            }}
        >
            {(formik: FormikProps<FormFaqFormTypes>) => {
                return (
                    <Form>
                        <Box>
                            <Field
                                required
                                name="question"
                                placeholder={t('orguser.forms.faq.question')}
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box sx={{mt: 1}}>
                            <MdxEditorSmallField
                                name="answer"
                                value={formik.values.answer ? String(formik.values.answer) : ''}
                                label={t('orguser.forms.faq.answer')}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: 2,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default FaqForm;
