import React, {FC, useCallback, useEffect, useState} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

import {FORM_FILTERS_GET, FORM_FILTERS_CLEAR} from 'appRedux/actions/forms';
import {PanelFormTypes, PanelHasStatusItemTypes, PanelHasFieldItemTypes} from 'appRedux/actions/analytics/types';
import {FormFieldFilterType} from 'appRedux/actions/forms/types';
import {WorkflowItemTypes} from 'appRedux/actions/workflow/types';
import {RootReducer} from 'appRedux/reducers';

import {FilterOptionType} from 'components/AdminScreenComponents/types';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import MdxEditorSmallField from 'components/MdxEditorField/MdxEditorSmallField';
import {validationPanelSchemaPanelCreate} from 'components/Forms/Dashboard/validation';
import {
    panelTypesArray,
    panelDatefilterTypeArray,
    panelDisplayTypeArray,
    PanelOptionType,
    DISPLAY_TYPE_BAR_CHART,
    TYPE_CASE_CHECKBOX_ANALYSIS,
    TYPE_CASE_STATUS_TIME_ANALYSIS,
    TYPE_CASE_STATUS_DISTRIBUTION,
} from 'components/Forms/Dashboard/helper';
import WorkflowStatusesSelectors from 'components/Forms/Dashboard/WorkflowStatusesSelectors';
import FormFieldFilterSelectors from 'components/Forms/Dashboard/FormFieldFilterSelectors';
import FormBuilderCheckboxReverse from 'components/AgentScreenComponents/_form/FormBuilderCheckboxReverse';

import {getFormVersionsList} from 'pages/agent/tableView/helper';

interface WorkflowFormType {
    initialValues: PanelFormTypes;
    statuses: PanelHasStatusItemTypes[];
    setStatuses: (values: PanelHasStatusItemTypes[]) => void;
    panelFields: PanelHasFieldItemTypes[];
    setPanelFields: (values: PanelHasFieldItemTypes[]) => void;
    onSubmitClicked: (values: PanelFormTypes) => void;
}

const PanelForm: FC<WorkflowFormType> = ({
    initialValues,
    onSubmitClicked,
    statuses,
    setStatuses,
    panelFields,
    setPanelFields,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const [selectedWorkflowId, setSelectedWorkflowId] = useState<number | null>(
        initialValues.workflow !== 0 ? initialValues.workflow : null,
    );

    const getFormFieldFilters = useCallback(
        data => dispatch({type: FORM_FILTERS_GET.REQUEST, payload: data}),
        [dispatch],
    );

    const clearFormFieldFilters = useCallback(() => dispatch({type: FORM_FILTERS_CLEAR.REQUEST}), [dispatch]);

    const {
        admin: {formsList, formFieldFilters},
        workflow: {organizationWorkflows},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const formVersions: FilterOptionType[] = formsList ? getFormVersionsList(formsList) : [];
    const selectedWorkflow = organizationWorkflows.find(item => item.id === selectedWorkflowId);
    const selectedWorkflowStatuses = selectedWorkflow ? selectedWorkflow.workflowStatuses : [];
    const fieldFilters: FormFieldFilterType[] = get(formFieldFilters, 'filters', []);

    useEffect(() => {
        if (initialValues.type === TYPE_CASE_CHECKBOX_ANALYSIS && initialValues.version) {
            getFormFieldFilters({
                id: initialValues.version,
            });
        }
    }, []);

    useEffect(() => {
        return () => {
            clearFormFieldFilters();
        };
    }, []);

    const filteredFormVersions: FilterOptionType[] = formVersions
        ? formVersions.filter(versionItem => versionItem.workflowId === selectedWorkflowId)
        : [];

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={() => validationPanelSchemaPanelCreate(t)}
            onSubmit={onSubmitClicked}
            enableReinitialize
        >
            {(formik: FormikProps<PanelFormTypes>) => {
                const formLabel =
                    formik.values.type === TYPE_CASE_CHECKBOX_ANALYSIS
                        ? t('orguser.analytics.panels.formTitle')
                        : `${t('orguser.analytics.panels.formTitle')} (${t('common.optional')})`;
                return (
                    <Form>
                        <Field
                            required
                            name="title"
                            placeholder={t('orguser.analytics.panels.title')}
                            component={FormikTextInput}
                        />
                        <FormControl sx={{mt: 2, mb: 1}} required fullWidth>
                            <InputLabel>{t('orguser.analytics.panels.type')}</InputLabel>
                            <Select
                                label={t('orguser.analytics.panels.type')}
                                name={'type'}
                                value={formik.values.type}
                                onChange={e => {
                                    formik.handleChange(e);
                                    const value = e.target.value;
                                    if (Number(value) === TYPE_CASE_STATUS_TIME_ANALYSIS) {
                                        setStatuses([]);
                                        formik.setFieldValue('displayType', DISPLAY_TYPE_BAR_CHART);
                                    }
                                    if (Number(value) === TYPE_CASE_CHECKBOX_ANALYSIS) {
                                        clearFormFieldFilters();
                                        setPanelFields([]);
                                        const versionId = Number(formik.values.version);
                                        if (versionId !== 0) {
                                            getFormFieldFilters({
                                                id: versionId,
                                            });
                                        }
                                    }
                                }}
                                IconComponent={KeyboardArrowDownIcon}
                                fullWidth
                            >
                                {panelTypesArray.map((item: PanelOptionType, index: number) => {
                                    return (
                                        <MenuItem key={`panel-type-${index}`} value={item.name}>
                                            <Typography sx={{fontWeight: 600}}>{t(item.label)}</Typography>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <FormControl sx={{mt: 2, mb: 1}} required fullWidth>
                            <InputLabel>{t('orguser.analytics.panels.workflow')}</InputLabel>
                            <Select
                                label={t('orguser.analytics.panels.workflow')}
                                name={'workflow'}
                                value={formik.values.workflow}
                                onChange={e => {
                                    formik.handleChange(e);
                                    const value = e.target.value;
                                    setSelectedWorkflowId(Number(value));
                                    formik.setFieldValue('version', null);
                                    if (Number(formik.values.type) === TYPE_CASE_CHECKBOX_ANALYSIS) {
                                        clearFormFieldFilters();
                                        setPanelFields([]);
                                    }
                                }}
                                IconComponent={KeyboardArrowDownIcon}
                                fullWidth
                            >
                                {organizationWorkflows &&
                                    organizationWorkflows.map((workflowItem: WorkflowItemTypes) => {
                                        const {uuid} = workflowItem;
                                        return (
                                            <MenuItem key={`panel-workflow-${uuid}`} value={workflowItem.id}>
                                                <Typography sx={{fontWeight: 600}}>{workflowItem.title}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        {formik.values.workflow !== 0 && (
                            <FormControl
                                sx={{mt: 2, mb: 1}}
                                required={formik.values.type === TYPE_CASE_CHECKBOX_ANALYSIS}
                                fullWidth
                            >
                                <InputLabel>{formLabel}</InputLabel>
                                <Select
                                    label={formLabel}
                                    name={'version'}
                                    value={Number(formik.values.version)}
                                    onChange={e => {
                                        formik.handleChange(e);
                                        if (Number(formik.values.type) === TYPE_CASE_CHECKBOX_ANALYSIS) {
                                            clearFormFieldFilters();
                                            setPanelFields([]);
                                            const versionId = Number(e.target.value);
                                            getFormFieldFilters({
                                                id: versionId,
                                            });
                                        }
                                    }}
                                    required={formik.values.type === TYPE_CASE_CHECKBOX_ANALYSIS}
                                    IconComponent={KeyboardArrowDownIcon}
                                    fullWidth
                                    displayEmpty
                                >
                                    {formik.values.type !== TYPE_CASE_CHECKBOX_ANALYSIS && (
                                        <MenuItem key={`panel-form-all`} value={0}>
                                            <Typography sx={{fontWeight: 600}}>{t('common.all')}</Typography>
                                        </MenuItem>
                                    )}
                                    {filteredFormVersions &&
                                        filteredFormVersions.map((versionItem: FilterOptionType, index: number) => {
                                            const {name, label} = versionItem;
                                            return (
                                                <MenuItem key={`panel-form-${index}`} value={name}>
                                                    <Typography sx={{fontWeight: 600}}>{label}</Typography>
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        )}
                        {formik.values.type === TYPE_CASE_STATUS_TIME_ANALYSIS && (
                            <>
                                <Box sx={{mb: 3, mt: 3}}>
                                    <Field
                                        type="checkbox"
                                        name="isIncludeCurrentStatus"
                                        label={t('orguser.analytics.panels.isIncludeCurrentStatus')}
                                        component={FormBuilderCheckboxReverse}
                                    />
                                </Box>
                                <Box sx={{mb: 2, mt: 1}}>
                                    <WorkflowStatusesSelectors
                                        statuses={statuses}
                                        setStatuses={setStatuses}
                                        workflowStatuses={selectedWorkflowStatuses}
                                    />
                                </Box>
                            </>
                        )}
                        {formik.values.type === TYPE_CASE_CHECKBOX_ANALYSIS && fieldFilters.length > 0 && (
                            <Box sx={{mb: 2, mt: 1}}>
                                <FormFieldFilterSelectors
                                    panelFields={panelFields}
                                    setPanelFields={setPanelFields}
                                    fieldFilters={fieldFilters}
                                />
                            </Box>
                        )}
                        <MdxEditorSmallField
                            name="description"
                            value={formik.values.description ? String(formik.values.description) : ''}
                            label={t('orguser.analytics.panels.description')}
                        />
                        <FormControl sx={{mt: 2}} required fullWidth>
                            <InputLabel>{t('orguser.analytics.panels.datefilterType')}</InputLabel>
                            <Select
                                label={t('orguser.analytics.panels.datefilterType')}
                                name={'datefilterType'}
                                value={formik.values.datefilterType}
                                onChange={formik.handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                fullWidth
                            >
                                {panelDatefilterTypeArray.map((item: PanelOptionType, index: number) => {
                                    return (
                                        <MenuItem key={`panel-datefilterType-${index}`} value={item.name}>
                                            <Typography sx={{fontWeight: 600}}>{t(item.label)}</Typography>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        {formik.values.type === TYPE_CASE_STATUS_DISTRIBUTION && (
                            <FormControl sx={{mt: 2}} required fullWidth>
                                <InputLabel>{t('orguser.analytics.panels.displayType')}</InputLabel>
                                <Select
                                    label={t('orguser.analytics.panels.displayType')}
                                    name={'displayType'}
                                    value={formik.values.displayType}
                                    onChange={formik.handleChange}
                                    IconComponent={KeyboardArrowDownIcon}
                                    fullWidth
                                >
                                    {panelDisplayTypeArray.map((item: PanelOptionType, index: number) => {
                                        return (
                                            <MenuItem key={`panel-displayType-${index}`} value={item.name}>
                                                <Typography sx={{fontWeight: 600}}>{t(item.label)}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton
                                isSubmit
                                isDisabled={
                                    (formik.values.type === TYPE_CASE_CHECKBOX_ANALYSIS &&
                                        (!formik.values.version || panelFields.length === 0)) ||
                                    (formik.values.type === TYPE_CASE_STATUS_TIME_ANALYSIS && statuses.length === 0)
                                }
                            />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default PanelForm;
