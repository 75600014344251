import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import RequiredSvgIcon from 'assets/icons/section/RequiredSvgIcon';

import {ERROR_TEXT_COLOR} from 'config/theme';

const TranslationRequiredButton: FC = () => {
    const [t] = useTranslation();
    return (
        <Tooltip title={t('common.translationRequired')}>
            <IconButton
                sx={{
                    backgroundColor: ERROR_TEXT_COLOR,
                    '&:hover': {
                        backgroundColor: ERROR_TEXT_COLOR,
                    },
                }}
            >
                <RequiredSvgIcon isActive />
            </IconButton>
        </Tooltip>
    );
};

export default TranslationRequiredButton;
