import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {BoardStatusTypes} from 'appRedux/actions/workflow/types';
import {TOGGLE_BOARD_HAS_STATUS} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import {CustomSwitcher} from 'components/AgentScreenComponents/_form/CustomElements';

interface BoardStatusRowType {
    item: BoardStatusTypes;
}

const BoardStatusRow: FC<BoardStatusRowType> = ({item}) => {
    const dispatch = useDispatch();

    const {title, isActive, uuid} = item;

    const {showAlert} = useContext(AlertContext);

    const [isBoardActive, setIsBoardActive] = useState<boolean>(isActive);

    const changeBoardHasStatus = useCallback(
        data => dispatch({type: TOGGLE_BOARD_HAS_STATUS.REQUEST, payload: data}),
        [dispatch],
    );

    const handleChangeStatus = () => {
        changeBoardHasStatus({
            uuid,
            showAlert,
            callback: () => {
                setIsBoardActive(previous => !previous);
            },
        });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mb: 2,
            }}
        >
            <CustomSwitcher checked={isBoardActive} onClick={handleChangeStatus} />
            <Typography sx={{ml: 2}}>{title}</Typography>
        </Box>
    );
};

export default BoardStatusRow;
