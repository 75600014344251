import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {Field, Form, Formik, FormikProps} from 'formik';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

import {UPDATE_CLIENT_TRANSLATION_ITEM} from 'appRedux/actions/clientTranslation';
import {ClientTranslationTypes, ClientTranslationUpdatedItemType} from 'appRedux/actions/clientTranslation/types';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';

interface ClientTranslationFormType {
    fieldId: number;
    popupItem?: number;
    initialText: string;
    caseId: number;
    currentTranslation?: ClientTranslationTypes;
    closeModal: () => void;
}

const ClientTranslationForm: FC<ClientTranslationFormType> = ({
    caseId,
    fieldId,
    popupItem,
    initialText,
    currentTranslation,
    closeModal,
}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const updateClientTranslationItem = useCallback(
        data => dispatch({type: UPDATE_CLIENT_TRANSLATION_ITEM.REQUEST, payload: data}),
        [dispatch],
    );

    return (
        <Formik<ClientTranslationUpdatedItemType>
            initialValues={{
                field: fieldId,
                popupItem: Number(popupItem),
                initialText: initialText,
                translation: currentTranslation ? currentTranslation.translation : '',
            }}
            onSubmit={(values: ClientTranslationUpdatedItemType) => {
                updateClientTranslationItem({
                    id: caseId,
                    ...values,
                    showAlert,
                    callback: () => {
                        closeModal();
                    },
                });
            }}
        >
            {(formik: FormikProps<ClientTranslationUpdatedItemType>) => {
                return (
                    <Form>
                        <Box sx={{mt: 1}}>
                            <FormControl fullWidth>
                                <Field
                                    required
                                    rows={6}
                                    multiline
                                    name="translation"
                                    component={FormikTextInput}
                                    fullwidth
                                />
                            </FormControl>
                            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mt: 2}}>
                                <AgentSaveButton isSubmit />
                            </Box>
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ClientTranslationForm;
