import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {OrganizationQuotasTypes, OrganizationQuotasItemBasic} from 'appRedux/actions/organization/types';
import {UPDATE_ORGANIZATION_QUOTA} from 'appRedux/actions/organization';

import {AlertContext} from 'contexts/alert/context';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import OrganizationQuotaForm from 'components/Forms/OrganizationQuotaForm/OrganizationQuotaForm';

import {OrganizationQuotasArrayType} from 'pages/admin/updateOrganization/partials/OrganizationQuotas/helper';

interface CreateOrganizationQuotaButtonType {
    item: OrganizationQuotasArrayType;
    quota: OrganizationQuotasItemBasic;
}

const EditOrganizationQuotaButton: FC<CreateOrganizationQuotaButtonType> = ({item, quota}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {uuid, type, value, isHard} = quota;

    const {showAlert} = useContext(AlertContext);

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleShowModal = () => {
        setShowModal(previous => !previous);
    };

    const submitUpdateOrganizationQuota = useCallback(
        data => dispatch({type: UPDATE_ORGANIZATION_QUOTA.REQUEST, payload: data}),
        [dispatch],
    );

    const updateOrganizationQuota = (values: OrganizationQuotasTypes) => {
        submitUpdateOrganizationQuota({
            uuid,
            ...values,
            callback: () => setShowModal(false),
            showAlert,
        });
    };

    return (
        <>
            <IconButton title={t('common.buttons.update')} onClick={toggleShowModal}>
                <EditSvgIcon />
            </IconButton>
            <ModalWrapper
                title={t('superadmin.organizations.updateOrganizationQuota')}
                isShowModal={showModal}
                toggleModal={toggleShowModal}
            >
                <OrganizationQuotaForm
                    typeLabel={item.title}
                    initialValues={{
                        type,
                        value,
                        isHard,
                    }}
                    onSubmitClicked={updateOrganizationQuota}
                />
            </ModalWrapper>
        </>
    );
};

export default EditOrganizationQuotaButton;
