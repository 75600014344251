import React, {FC} from 'react';
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';

import {PanelItemsTypes} from 'appRedux/actions/analytics/types';

import {tagColors} from 'config/theme';

interface CircleStatisticChartType {
    item: PanelItemsTypes;
}

const CircleStatisticChart: FC<CircleStatisticChartType> = ({item}) => {
    return (
        <ResponsiveContainer width="100%" height={300}>
            <PieChart width={300} height={300}>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={item.cases}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    label
                >
                    {item.cases.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={tagColors[index % 20]} />
                    ))}
                </Pie>
                <Tooltip />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default CircleStatisticChart;
