import React, {FC} from 'react';
import {FieldProps} from 'formik';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

import {CustomSwitcher} from 'components/AgentScreenComponents/_form/CustomElements';

interface FormBuilderCheckboxTypes {
    name: string;
    label: string;
}

const FormBuilderCheckbox: FC<FieldProps & FormBuilderCheckboxTypes> = ({
    field,
    form: {touched, errors},
    name,
    label,
    ...props
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
        >
            <Typography variant="body2" sx={{minWidth: 180, mr: 2}}>
                {label}
            </Typography>
            <FormControl>
                <CustomSwitcher id={name} {...field} {...props} />
            </FormControl>
        </Box>
    );
};

export default FormBuilderCheckbox;
