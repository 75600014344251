import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import IconButton from '@mui/material/IconButton';

import CloseSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {InstanceSettingBasic} from 'appRedux/actions/instance/types';
import {INSTANCE_SETTING_DELETE_FILE, INSTANCE_SETTING_DELETE_FAVICON} from 'appRedux/actions/instance';

import {AlertContext} from 'contexts/alert/context';

import DeleteClientConfirmationModal from 'components/ClientScreenComponents/DeleteClientConfirmationModal';

import {theme} from 'config/theme';

interface DeleteInstanceFileType {
    item: InstanceSettingBasic;
    isFavicon?: boolean;
}

const DeleteInstanceFile: FC<DeleteInstanceFileType> = ({item, isFavicon}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {id} = item;

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const deleteInstanceFile = useCallback(
        payload => dispatch({type: INSTANCE_SETTING_DELETE_FILE.REQUEST, payload}),
        [dispatch],
    );

    const deleteInstanceFavicon = useCallback(
        payload => dispatch({type: INSTANCE_SETTING_DELETE_FAVICON.REQUEST, payload}),
        [dispatch],
    );

    const onDeleteClick = () => {
        const deleteAction = isFavicon ? deleteInstanceFavicon : deleteInstanceFile;
        deleteAction({
            id,
            callback: () => {
                setIsOpened(false);
            },
            showAlert,
        });
    };

    return (
        <>
            <IconButton
                sx={{
                    ml: 1,
                    width: 30,
                    height: 30,
                    backgroundColor: theme.palette.info.main,
                    '&:hover': {
                        backgroundColor: theme.palette.info.main,
                    },
                }}
                title={t('common.buttons.delete')}
                onClick={toggleIsOpened}
            >
                <CloseSvgIcon isActive />
            </IconButton>
            {isOpened && (
                <DeleteClientConfirmationModal
                    isShowModal={isOpened}
                    toggleModal={toggleIsOpened}
                    onClickAction={onDeleteClick}
                />
            )}
        </>
    );
};

export default DeleteInstanceFile;
