import React, {FC, ReactNode, RefObject, useContext} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import {FormPopupTypes} from 'appRedux/actions/forms/types';

import {ClientFormContext} from 'contexts/clientForm/context';

import ElementDescription from 'pages/client/form/partials/ElementDescription';

import {getPopupKeyword} from 'helpers/translationsHelper';

interface FormPopupWrapperType {
    formId: number;
    popup: FormPopupTypes;
    children: ReactNode;
    wrapperRef: RefObject<HTMLDivElement | undefined>;
    previewMode?: boolean;
}

const FormPopupWrapper: FC<FormPopupWrapperType> = ({popup, formId, wrapperRef, previewMode, children}) => {
    const [t] = useTranslation();
    const {errorPopup} = useContext(ClientFormContext);

    const isError = popup.id === errorPopup;

    return (
        <Box ref={wrapperRef}>
            <FormControl fullWidth error={isError} sx={{mt: 1, mb: 1}}>
                <ElementDescription
                    keyword={getPopupKeyword(formId, popup.id, 'description')}
                    description={popup.description}
                    previewMode={previewMode}
                />
                {children}
                {isError && <FormHelperText>{t('messages.error.errorAtLeastOne')}</FormHelperText>}
            </FormControl>
        </Box>
    );
};

export default FormPopupWrapper;
