import React, {FC, useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {FormTagType} from 'appRedux/actions/forms/types';
import {FORM_TAG_DELETE, FORM_TAG_UPDATE} from 'appRedux/actions/forms';

import TagChipWithDeleting from 'components/TagChip/TagChipWithDeleting';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import TagForm from 'components/Forms/FormBuilder/TagForm/TagForm';
import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';

interface TagItemType {
    item: FormTagType;
}

const TagItem: FC<TagItemType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {id, title, color} = item;

    const [isShowModal, setShowModal] = useState<boolean>(false);
    const [isShowDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    const toggleDeleteModal = () => {
        setShowDeleteModal(previous => !previous);
    };

    const updateFormTag = useCallback(data => dispatch({type: FORM_TAG_UPDATE.REQUEST, payload: data}), [dispatch]);

    const deleteFormTag = useCallback(data => dispatch({type: FORM_TAG_DELETE.REQUEST, payload: data}), [dispatch]);

    const onClickDelete = () => {
        deleteFormTag({
            id,
            callback: () => setShowDeleteModal(false),
        });
    };

    return (
        <>
            <TagChipWithDeleting
                id={String(id)}
                title={title}
                color={color}
                onUpdateClick={toggleModal}
                onDeleteClick={toggleDeleteModal}
            />
            <ModalWrapper isShowModal={isShowModal} toggleModal={toggleModal} title={t('orguser.forms.updateFormTag')}>
                <TagForm
                    id={Number(id)}
                    initialValues={{
                        title,
                        color,
                    }}
                    isShowModal={isShowModal}
                    setShowModal={setShowModal}
                    toggleModal={toggleModal}
                    callback={toggleModal}
                    onSubmitClicked={updateFormTag}
                />
            </ModalWrapper>
            {isShowDeleteModal && (
                <DeleteAdminConfirmationModal
                    toggleModal={toggleDeleteModal}
                    isShowModal={isShowDeleteModal}
                    onClickAction={onClickDelete}
                />
            )}
        </>
    );
};

export default TagItem;
