import React, {FC, RefObject, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {get} from 'lodash';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import UploadSvgIcon from 'assets/icons/large/UploadSvgIcon';

import {CLIENT_BACKGROUND_COLOR, theme} from 'config/theme';

interface FileDropAreaType {
    onDropFile: (file: File) => void;
    wrapperRef: RefObject<HTMLDivElement | undefined>;
    fullPage?: boolean;
}

const FileDropArea: FC<FileDropAreaType> = ({onDropFile, wrapperRef, fullPage}) => {
    const [t] = useTranslation();

    const [showDropZone, setShowDropZone] = useState<boolean>(false);

    const timeout = useRef<NodeJS.Timeout>();

    const showDropzone = () => {
        setShowDropZone(true);
        timeout.current && clearTimeout(timeout.current);
    };

    function hideDropzone(this: any, e?: DragEvent) {
        e?.preventDefault();
        timeout.current = setTimeout(() => {
            setShowDropZone(false);
        }, 500);
    }

    useEffect(() => {
        wrapperRef.current?.addEventListener('dragover', showDropzone);
        wrapperRef.current?.addEventListener('dragleave', hideDropzone);
        wrapperRef.current?.addEventListener('dragend', hideDropzone);

        return () => {
            wrapperRef.current?.removeEventListener('dragover', showDropzone);
            wrapperRef.current?.removeEventListener('dragleave', hideDropzone);
            wrapperRef.current?.addEventListener('dragend', hideDropzone);
        };
    }, []);

    const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    return (
        <>
            {showDropZone && (
                <Box
                    sx={{
                        position: fullPage ? 'fixed' : 'absolute',
                        top: fullPage ? 60 : 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        height: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        border: `2px dashed ${theme.palette.info.dark}`,
                        backgroundColor: CLIENT_BACKGROUND_COLOR,
                        borderRadius: 1,
                        my: 0.5,
                        color: theme.palette.info.dark,
                        zIndex: 10000,
                    }}
                    component="div"
                    id="drop_zone"
                    onDragOver={dragOverHandler}
                    onDrop={e => {
                        showDropzone();
                        e.preventDefault();
                        const item: DataTransferItem | null = get(e, ['dataTransfer', 'items', '0'], null);
                        if (item && item.kind === 'file') {
                            const file = item.getAsFile();

                            if (file) {
                                onDropFile && onDropFile(file);
                            }
                        }
                        hideDropzone();
                    }}
                >
                    <UploadSvgIcon />
                    <Typography sx={{ml: 2}}>{t('requester.casePage.dropFileHere')}</Typography>
                </Box>
            )}
        </>
    );
};

export default FileDropArea;
