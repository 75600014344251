import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import InputLabel from '@mui/material/InputLabel';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import {CurrentFormWorkflowStatusesTypes} from 'appRedux/actions/forms/types';
import {WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';

interface WorkflowSelectionRowType {
    selectedWorkflowId: number;
    isNewWorkflowSelected: boolean;
    item: CurrentFormWorkflowStatusesTypes;
    workflowStatuses: WorkflowStatusItemTypes[];
    updateSelectedStatuses: (oldValue: number, newValue: number) => void;
}

const WorkflowSelectionRow: FC<WorkflowSelectionRowType> = ({
    selectedWorkflowId,
    isNewWorkflowSelected,
    item,
    workflowStatuses,
    updateSelectedStatuses,
}) => {
    const [t] = useTranslation();

    const {id, casesNumber, title, color} = item;

    const onStatusChanged = (e: SelectChangeEvent) => {
        const value = Number(e.target.value);
        setStatus(value);
        updateSelectedStatuses(id, value);
    };

    const [status, setStatus] = useState<number | null>(null);

    useEffect(() => {
        setStatus(null);
    }, [selectedWorkflowId]);

    return (
        <Grid sx={{mb: 2}} container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item sm={5}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <TripOriginIcon sx={{color: color}} />
                    <Typography sx={{fontWeight: 600, ml: 1}}>{title}</Typography>
                </Box>
            </Grid>
            <Grid item sm={5}>
                <FormControl required fullWidth>
                    <InputLabel>{t('orguser.workflows.workflowStatusParameters.status')}</InputLabel>
                    <Select
                        label={t('orguser.workflows.workflowStatusParameters.status')}
                        name={'workflow'}
                        value={String(status)}
                        disabled={!isNewWorkflowSelected}
                        onChange={onStatusChanged}
                        IconComponent={KeyboardArrowDownIcon}
                        sx={{
                            '& div': {
                                display: 'flex',
                            },
                        }}
                        fullWidth
                    >
                        {workflowStatuses &&
                            workflowStatuses.map((item: WorkflowStatusItemTypes) => {
                                return (
                                    <MenuItem
                                        key={`workflow-item-row-${item.uuid}`}
                                        value={item.id}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            pl: 1,
                                        }}
                                    >
                                        <TripOriginIcon sx={{color: item.color}} />
                                        <Typography sx={{ml: 1, ontWeight: 600}}>{item.title}</Typography>
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item sm={2}>
                <Typography sx={{fontWeight: 600}} align={'center'}>
                    {casesNumber}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default WorkflowSelectionRow;
