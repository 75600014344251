import React, {FC} from 'react';
import {FieldProps} from 'formik';
import get from 'lodash/get';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

import {CustomSwitcher} from 'components/AgentScreenComponents/_form/CustomElements';
import FormFieldErrorMessage from 'components/AdminScreenComponents/FormFieldErrorMessage';

interface FormBuilderCheckboxTypes {
    name: string;
    label: string;
}

const FormBuilderCheckboxReverse: FC<FieldProps & FormBuilderCheckboxTypes> = ({
    field,
    form: {touched, errors},
    name,
    label,
    ...props
}) => {
    const fieldName = name || field.name;
    const isTouched = get(touched, fieldName, false);
    const errorMessage = get(errors, fieldName, false);
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    mr: 2,
                }}
            >
                <FormControl sx={{mr: 2}}>
                    <CustomSwitcher id={name} {...field} {...props} />
                </FormControl>
                <Typography variant="body2">{label}</Typography>
            </Box>
            {isTouched && errorMessage && <FormFieldErrorMessage errorMessage={String(errorMessage)} />}
        </Box>
    );
};

export default FormBuilderCheckboxReverse;
