import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseHasBudgetTypes} from 'appRedux/actions/requestCase/types';

import {
    TYPE_BUDGET_DEFINITION_REQUESTER,
    TYPE_BUDGET_DEFINITION_AGENT,
    TYPE_BUDGET_DEFINITION_BOTH,
    TYPE_BUDGET_DEFINITION_FIXED,
} from 'components/Forms/FormBuilder/FieldForm/helper';
import {getResourceFieldBudgetTypeLabel} from 'components/Forms/ResourceField/helper';
import CreateRequesterCaseBudgetForm from 'components/Forms/RequesterCaseBudgetForm/CreateRequesterCaseBudgetForm';

import BudgetAskedRequester from 'pages/client/form/fieldTypes/resourceFields/BudgetAskedRequester';

import {getBudgetAmountValue} from 'helpers/resourceFieldsHelper';

interface BudgetDefinitionInformationType {
    budgetType: number;
    field: FormFieldTypes;
    currentBudget?: RequesterCaseHasBudgetTypes;
}

const BudgetDefinitionInformation: FC<BudgetDefinitionInformationType> = ({budgetType, field, currentBudget}) => {
    const [t] = useTranslation();
    const {requestCase} = useParams();

    const {typeBudgetDefinition, budgetAmount, resourceFieldBudgetInformation} = field;

    if (
        resourceFieldBudgetInformation &&
        (typeBudgetDefinition === TYPE_BUDGET_DEFINITION_REQUESTER ||
            typeBudgetDefinition === TYPE_BUDGET_DEFINITION_BOTH)
    ) {
        const alertTitle =
            typeBudgetDefinition === TYPE_BUDGET_DEFINITION_REQUESTER
                ? t('requester.casePage.budgetDefinitionRequesterExplanation')
                : t('requester.casePage.budgetDefinitionBothExplanation');
        return (
            <Box>
                <Alert sx={{mt: 2, mb: 1}} severity={'info'}>
                    <Typography sx={{fontSize: 14}}>{alertTitle}</Typography>
                </Alert>
                {currentBudget ? (
                    <BudgetAskedRequester
                        caseId={Number(requestCase)}
                        currentBudget={currentBudget}
                        field={field}
                        resourceFieldBudgetInformation={resourceFieldBudgetInformation}
                    />
                ) : (
                    <CreateRequesterCaseBudgetForm
                        caseId={Number(requestCase)}
                        field={field}
                        resourceFieldBudgetInformation={resourceFieldBudgetInformation}
                    />
                )}
            </Box>
        );
    }
    if (typeBudgetDefinition === TYPE_BUDGET_DEFINITION_AGENT) {
        return (
            <Alert sx={{mt: 2, mb: 2}} severity={'info'}>
                <Typography sx={{fontSize: 14}}>{t('requester.casePage.budgetDefinitionAgentExplanation')}</Typography>
            </Alert>
        );
    }
    if (typeBudgetDefinition === TYPE_BUDGET_DEFINITION_FIXED && budgetAmount && resourceFieldBudgetInformation) {
        return (
            <Alert sx={{mt: 2, mb: 1}} severity={'info'}>
                <Typography sx={{fontSize: 14}}>
                    {t('requester.casePage.budgetDefinitionFixedExplanation')
                        .replace('%NUMBER%', String(getBudgetAmountValue(budgetType, budgetAmount)))
                        .replace('%UNITS%', t(getResourceFieldBudgetTypeLabel(resourceFieldBudgetInformation.type)))}
                </Typography>
            </Alert>
        );
    }

    return null;
};

export default BudgetDefinitionInformation;
