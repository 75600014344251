import {ERROR_400, ERROR_401, ERROR_403, ERROR_404} from 'services/http';

export const getErrorDescription = (error: string, agentCasePages: boolean[]) => {
    if (error.includes(ERROR_401)) {
        return 'messages.error.errorDescription401';
    } else if (error.includes(ERROR_403)) {
        return 'messages.error.errorDescription403';
    } else if (error.includes(ERROR_404)) {
        return agentCasePages.includes(true) ? 'messages.error.caseNotFound' : 'messages.error.errorDescription404';
    } else if (error.includes(ERROR_400)) {
        return 'messages.error.errorDescription400';
    }
    return 'messages.error.somethingWentWrong';
};
