import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {GridColDef} from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';

import {GeneralTemplateTypes} from 'appRedux/actions/generalTemplates/types';

import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';

import TemplateEditButton from 'pages/admin/generalTemplate/TemplateEditButton';
import {getTemplateTypeLabel} from 'pages/admin/generalTemplate/helper';
import GeneralTemplateSubjectAndText from 'pages/admin/generalTemplate/GeneralTemplateSubjectAndText';

interface GeneralTemplatesDataGridType {
    language: number;
    items: GeneralTemplateTypes[];
}

const GeneralTemplatesDataGrid: FC<GeneralTemplatesDataGridType> = ({language, items}) => {
    const [t] = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'type',
            headerName: t('superadmin.mailer.eventType'),
            width: 300,
            valueGetter: ({value}) => getTemplateTypeLabel(t, value),
        },
        {
            field: 'text',
            headerName: t('superadmin.mailer.templateText'),
            renderCell: ({row}) => {
                const {subject, text, type} = row;
                if (row.uuid) {
                    return <GeneralTemplateSubjectAndText subject={subject} text={text} />;
                }
                return <Typography sx={{fontSize: 14}}>{t('orguser.mailer.noneTemplateDefaultLanguage')}</Typography>;
            },
        },
        {
            field: 'isActive',
            headerName: t('superadmin.mailer.isActive'),
            width: 80,
            valueGetter: ({value}) => t(value ? 'common.yes' : 'common.no'),
        },
        {
            field: 'action',
            headerName: t('superadmin.mailer.action'),
            width: 80,
            renderCell: ({row}) => <TemplateEditButton language={language} item={row} />,
        },
    ];

    return (
        <CustomDataGrid
            rows={items}
            columns={columns}
            columnVisibilityModel={{invite: false}}
            getRowId={row => row.type}
            checkboxSelection={false}
            enablePaging
        />
    );
};

export default GeneralTemplatesDataGrid;
