import {WorkflowStatusTaskItemTypes} from 'appRedux/actions/workflow/types';

import {WORKFLOW_STATUS_TASK_TYPE_EMAIL_SENDING} from 'components/Forms/Workflow/workflowStatusTask/helper';

export const checkEmailSendingTaskCompleted = (task: WorkflowStatusTaskItemTypes): boolean => {
    const {templates} = task;
    return task.type === WORKFLOW_STATUS_TASK_TYPE_EMAIL_SENDING && templates.length === 0;
};

export const checkStatusHasNotCompletedTasks = (tasks: WorkflowStatusTaskItemTypes[]): boolean => {
    for (let i = 0, n = tasks.length; i < n; i++) {
        const task = tasks[i];
        if (task.type === WORKFLOW_STATUS_TASK_TYPE_EMAIL_SENDING) {
            return task.templates.length === 0;
        }
    }
    return false;
};
