import React, {FC} from 'react';
import {CommonSvgIconType, getIconColor} from './helper';

const SendMailSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 11.5H7V12.5H2C1.73478 12.5 1.48043 12.3946 1.29289 12.2071C1.10536 12.0196 1 11.7652 1 11.5V3.5C1 3.23478 1.10536 2.98043 1.29289 2.79289C1.48043 2.60536 1.73478 2.5 2 2.5H14C14.2652 2.5 14.5196 2.60536 14.7071 2.79289C14.8946 2.98043 15 3.23478 15 3.5V8.5H14V3.955L8.285 7.91C8.2013 7.96806 8.10187 7.99918 8 7.99918C7.89813 7.99918 7.7987 7.96806 7.715 7.91L2 3.955V11.5ZM8 6.89L12.9 3.5H3.1L8 6.89ZM8.29501 10.205L10.085 12L8.29001 13.795L9.00001 14.5L11.5 12L9.00001 9.5L8.29501 10.205ZM11.795 10.205L13.585 12L11.79 13.795L12.5 14.5L15 12L12.5 9.5L11.795 10.205Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default SendMailSvgIcon;
