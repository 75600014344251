export const checkIsFormEditingEnabled = (
    isFormEditEnabled: boolean,
    filterFormsEditAllowed: number[],
    formId: number,
): boolean => {
    return isFormEditEnabled && filterFormsEditAllowed.includes(formId);
};

export const checkIsFormUpdatingEnabled = (
    isFormEditEnabled: boolean,
    filterFormsEditAllowed: number[],
    formId: number,
): boolean => {
    return isFormEditEnabled && filterFormsEditAllowed.includes(formId);
};

export const checkIsFormDeletingEnabled = (
    isFormDeleteEnabled: boolean,
    filterFormsDeleteAllowed: number[],
    formId: number,
): boolean => {
    return isFormDeleteEnabled && filterFormsDeleteAllowed.includes(formId);
};

export const checkIsCaseEditEnabled = (
    isCaseEditEnabled: boolean,
    filtersCaseEditAllowed: number[],
    formId: number,
): boolean => {
    return isCaseEditEnabled && filtersCaseEditAllowed.includes(formId);
};
