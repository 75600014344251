import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import Divider from '@mui/material/Divider';

import {GET_RESOURCE_FIELDS_LIST} from 'appRedux/actions/resourceFields';
import {GET_WORKFLOWS_LIST, GET_WORKFLOWS_STATUS_LIST} from 'appRedux/actions/workflow';
import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';
import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';
import UpdateResourceFieldForm from 'components/Forms/ResourceField/UpdateResourceFieldForm';

import ResourceFieldDetails from 'pages/admin/updateResourceField/ResourceFieldDetails';

const UpdateResourceFieldPage: FC = () => {
    const dispatch = useDispatch();
    const {uuid} = useParams();

    const {myOrganization} = useContext(AdminContext);
    const {showAlert} = useContext(AlertContext);
    const {refreshPageInformation} = useContext(RouteContext);

    const {
        resourceFields: {resourceFields},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getResourceFieldsList = useCallback(
        data => dispatch({type: GET_RESOURCE_FIELDS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const getWorkflowsList = useCallback(
        data => dispatch({type: GET_WORKFLOWS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const getWorkflowStatuses = useCallback(
        data => dispatch({type: GET_WORKFLOWS_STATUS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const currentItem = resourceFields.find(item => item.uuid === uuid);

    useEffect(() => {
        refreshPageInformation();
        if (myOrganization) {
            getResourceFieldsList({
                id: myOrganization.id,
                showAlert,
            });
            getWorkflowsList({
                organizationId: myOrganization.id,
                showAlert,
            });
        }
    }, []);

    useEffect(() => {
        if (currentItem) {
            getWorkflowStatuses({
                uuid: currentItem.workflowUuid,
                showAlert,
            });
        }
    }, [currentItem]);

    if (!myOrganization || !currentItem) return null;

    return (
        <ClientSectionWrapper>
            <UpdateResourceFieldForm item={currentItem} />
            <Divider sx={{mt: 3, mb: 3}} />
            <ResourceFieldDetails item={currentItem} />
        </ClientSectionWrapper>
    );
};

export default UpdateResourceFieldPage;
