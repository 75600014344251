import {fork, put, take, call} from 'redux-saga/effects';

import {
    GET_LOGIN_ATTEMPTS,
    getLoginAttemptsList,
    GET_PASSWORD_RESET_ATTEMPTS,
    getPasswordResetAttemptsList,
    GET_DRAFT_REMOVES,
    getDraftRemoves,
    GET_LOGIN_ATTEMPTS_STATISTIC,
    getLoginAttemptsStatistic,
    GET_PASSWORD_RESET_STATISTIC,
    getPasswordResetAttemptsStatistic,
    GET_MAIL_SENDING_STATISTIC,
    getMailSendingStatistic,
} from 'appRedux/actions/superadmin';

import {
    LoginAttemptRequest,
    LoginAttemptResponseTypes,
    DraftRemovesRequestTypes,
    DraftRemovesResponseTypes,
    LoginAttemptsStatisticRequestTypes,
    LoginAttemptsStatisticResponseTypes,
    PasswordResetAttemptsStatisticRequestTypes,
    MailSendingStatisticRequestTypes,
} from 'appRedux/actions/superadmin/types';

import {http} from 'services/http';

import {getRouteWithParameters} from 'helpers/sagasHelper';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS, PARAMETER_LANGUAGE} from 'config/index';

function* watchGetLoginAttemptsList() {
    while (true) {
        const {
            payload: {page, perPage, status, role, search},
        }: IActionType<LoginAttemptRequest> = yield take(GET_LOGIN_ATTEMPTS.REQUEST);
        try {
            const route = getRouteWithParameters('attempts/login', {
                page,
                per_page: perPage,
                status,
                role,
                search,
            });
            const data: LoginAttemptResponseTypes = yield call(http, route, {
                method: 'GET',
            });
            if (data) {
                yield put(getLoginAttemptsList.success(data));
            }
        } catch (e) {
            yield put(getLoginAttemptsList.error({message: String(e)}));
        }
    }
}

function* watchGetPasswordResetAttemptsList() {
    while (true) {
        const {
            payload: {page, perPage, status, role, search},
        }: IActionType<LoginAttemptRequest> = yield take(GET_PASSWORD_RESET_ATTEMPTS.REQUEST);
        try {
            const route = getRouteWithParameters('attempts/reset', {
                page,
                per_page: perPage,
                status,
                role,
                search,
            });
            const data: LoginAttemptResponseTypes = yield call(http, route, {
                method: 'GET',
            });
            if (data) {
                yield put(getPasswordResetAttemptsList.success(data));
            }
        } catch (e) {
            yield put(getPasswordResetAttemptsList.error({message: String(e)}));
        }
    }
}

function* watchDraftRemovesRequest() {
    while (true) {
        const {
            payload: {search, page, perPage, organization, form, sortBy, sortType, showAlert},
        }: IActionType<DraftRemovesRequestTypes> = yield take(GET_DRAFT_REMOVES.REQUEST);
        try {
            const route = getRouteWithParameters('draft/removes', {
                page,
                per_page: perPage,
                search,
                organization,
                form,
                sortBy,
                sortType,
            });
            const {success, errors, results}: DraftRemovesResponseTypes = yield call(http, route, {
                method: 'GET',
            });
            if (success) {
                yield put(getDraftRemoves.success(results));
            } else {
                yield put(getDraftRemoves.error({message: errors || 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, errors);
            }
        } catch (e) {
            yield put(getDraftRemoves.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchLoginAttemptsStatisticRequest() {
    while (true) {
        const {
            payload: {showAlert, isSuccess, days},
        }: IActionType<LoginAttemptsStatisticRequestTypes> = yield take(GET_LOGIN_ATTEMPTS_STATISTIC.REQUEST);
        try {
            const route = getRouteWithParameters('attempts/statistic', {
                isSuccess,
                days,
            });
            const data: LoginAttemptsStatisticResponseTypes = yield call(http, route, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getLoginAttemptsStatistic.success(data.results));
            } else {
                yield put(
                    getLoginAttemptsStatistic.error({message: data.errors || 'messages.error.somethingWentWrong'}),
                );
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(getLoginAttemptsStatistic.error({message: String(e)}));
        }
    }
}

function* watchPasswordResetAttemptsStatisticRequest() {
    while (true) {
        const {
            payload: {showAlert, days},
        }: IActionType<PasswordResetAttemptsStatisticRequestTypes> = yield take(GET_PASSWORD_RESET_STATISTIC.REQUEST);
        try {
            const route = getRouteWithParameters('resets/statistic', {
                days,
            });
            const data: LoginAttemptsStatisticResponseTypes = yield call(http, route, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getPasswordResetAttemptsStatistic.success(data.results));
            } else {
                yield put(
                    getPasswordResetAttemptsStatistic.error({
                        message: data.errors || 'messages.error.somethingWentWrong',
                    }),
                );
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(getPasswordResetAttemptsStatistic.error({message: String(e)}));
        }
    }
}

function* watchMailSendingStatisticRequest() {
    while (true) {
        const {
            payload: {showAlert, days},
        }: IActionType<MailSendingStatisticRequestTypes> = yield take(GET_MAIL_SENDING_STATISTIC.REQUEST);
        try {
            const route = getRouteWithParameters('mail/sending/statistic', {
                days,
            });
            const data: LoginAttemptsStatisticResponseTypes = yield call(http, route, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getMailSendingStatistic.success(data.results));
            } else {
                yield put(
                    getMailSendingStatistic.error({
                        message: data.errors || 'messages.error.somethingWentWrong',
                    }),
                );
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(getMailSendingStatistic.error({message: String(e)}));
        }
    }
}

export default [
    fork(watchGetLoginAttemptsList),
    fork(watchGetPasswordResetAttemptsList),
    fork(watchDraftRemovesRequest),
    fork(watchLoginAttemptsStatisticRequest),
    fork(watchPasswordResetAttemptsStatisticRequest),
    fork(watchMailSendingStatisticRequest),
];
