import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Typography from '@mui/material/Box';
import Box from '@mui/material/Box';

import {GET_AGENTS_WITH_CASE_ACCESS, CREATE_CASE_ACCESS_REQUEST} from 'appRedux/actions/crypto';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import CaseAccessRequestForm from 'components/Forms/CaseAccessRequestForm/CaseAccessRequestForm';
import {getUserName} from 'components/TopBarMenu/helper';

import {routes} from 'config/index';

interface CaseAccessRequestBlockType {
    caseId: number;
    formPrefix?: string;
}

const CaseAccessRequestBlock: FC<CaseAccessRequestBlockType> = ({caseId, formPrefix}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isClientMode} = useContext(RouteContext);

    const [isAccessCreated, setIsAccessCreated] = useState<boolean>(false);
    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getAgentsWithCaseAccessList = useCallback(
        data => dispatch({type: GET_AGENTS_WITH_CASE_ACCESS.REQUEST, payload: data}),
        [dispatch],
    );

    const createCaseAccessRequest = useCallback(
        data => dispatch({type: CREATE_CASE_ACCESS_REQUEST.REQUEST, payload: data}),
        [dispatch],
    );

    const onGetAccessClicked = () => {
        createCaseAccessRequest({
            notificationText: t('common.registrationForm.caseAccessRequestCreatedNotification')
                .replace('%USER%', getUserName(profile))
                .replace('%CASE%', `${formPrefix}-${caseId} | ${getUserName(profile)}`),
            relatedLink: `${routes.CASE_ACCESS_REQUEST}/%UUID%/edit`,
            caseId,
            showAlert,
            callback: () => setIsAccessCreated(true),
        });
    };

    useEffect(() => {
        if (!isClientMode) {
            getAgentsWithCaseAccessList({
                caseId,
                showAlert,
            });
        }
    }, [caseId, isClientMode]);

    return (
        <ClientSectionWrapper>
            <Typography>
                {t(
                    isAccessCreated
                        ? 'common.registrationForm.caseAccessRequestCreated'
                        : 'common.registrationForm.informationEncryptedMessage',
                )}
            </Typography>
            {!isAccessCreated && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt: 2,
                    }}
                >
                    {isClientMode ? (
                        <AgentSaveButton
                            title={t('common.buttons.accessRequest')}
                            isSubmit={false}
                            onClick={onGetAccessClicked}
                        />
                    ) : (
                        <>
                            <AgentSaveButton
                                title={t('common.buttons.accessRequest')}
                                isSubmit={false}
                                onClick={toggleIsOpened}
                            />
                            <ModalWrapper
                                title={t('common.buttons.accessRequest')}
                                isShowModal={isOpened}
                                toggleModal={toggleIsOpened}
                            >
                                <CaseAccessRequestForm
                                    caseId={caseId}
                                    closeModal={() => {
                                        setIsOpened(false);
                                        setIsAccessCreated(true);
                                    }}
                                />
                            </ModalWrapper>
                        </>
                    )}
                </Box>
            )}
        </ClientSectionWrapper>
    );
};

export default CaseAccessRequestBlock;
