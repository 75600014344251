import React, {FC, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import {DEFAULT_PAGE, PARAMETER_PER_PAGE, PARAMETER_PAGE} from 'config/index';

interface PaginationType {
    pagesNumber: number;
    setCurrentPage: (value: number) => void;
}

const Pagination: FC<PaginationType> = ({pagesNumber, setCurrentPage}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const currentPage = searchParams.get(PARAMETER_PAGE) || DEFAULT_PAGE;
    const currentPerPage = searchParams.get(PARAMETER_PER_PAGE);

    const redirectToFirstPage = () => {
        setCurrentPage(DEFAULT_PAGE);
        searchParams.set(PARAMETER_PAGE, String(DEFAULT_PAGE));
        setSearchParams(searchParams);
    };

    const redirectToPreviousPage = () => {
        const previousPage = Number(currentPage) - 1;
        setCurrentPage(previousPage);
        searchParams.set(PARAMETER_PAGE, String(previousPage));
        setSearchParams(searchParams);
    };

    const redirectToNextPage = () => {
        const nextPage = Number(currentPage) + 1;
        setCurrentPage(nextPage);
        searchParams.set(PARAMETER_PAGE, String(nextPage));
        setSearchParams(searchParams);
    };

    const redirectToLastPage = () => {
        setCurrentPage(pagesNumber);
        searchParams.set(PARAMETER_PAGE, String(pagesNumber));
        setSearchParams(searchParams);
    };

    useEffect(() => {
        setCurrentPage(Number(currentPage));
        if (currentPerPage) {
            searchParams.set(PARAMETER_PER_PAGE, String(currentPerPage));
            setSearchParams(searchParams);
        }
    }, [currentPage]);

    return (
        <>
            <IconButton onClick={redirectToFirstPage} disabled={Number(currentPage) === DEFAULT_PAGE}>
                <KeyboardDoubleArrowLeftIcon />
            </IconButton>
            <IconButton onClick={redirectToPreviousPage} disabled={Number(currentPage) === DEFAULT_PAGE}>
                <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton onClick={redirectToNextPage} disabled={Number(currentPage) === pagesNumber}>
                <KeyboardArrowRightIcon />
            </IconButton>
            <IconButton onClick={redirectToLastPage} disabled={Number(currentPage) === pagesNumber}>
                <KeyboardDoubleArrowRightIcon />
            </IconButton>
        </>
    );
};

export default Pagination;
