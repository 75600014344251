import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import SettingsSvgIcon from 'assets/icons/buttons/SettingsSvgIcon';
import SettingsCheckSvgIcon from 'assets/icons/buttons/SettingsCheckSvgIcon';

import {WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import WorkflowStatusInternalNameForm from 'components/Forms/Workflow/workflowStatus/WorkflowStatusInternalNameForm';

interface InternalNameButtonType {
    item: WorkflowStatusItemTypes;
}

const InternalNameButton: FC<InternalNameButtonType> = ({item}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <>
            <IconButton title={t('orguser.workflows.updateStatusInternalName')} onClick={toggleIsOpened}>
                {item.internalName ? <SettingsCheckSvgIcon /> : <SettingsSvgIcon />}
            </IconButton>
            <ModalWrapper
                isShowModal={isOpened}
                toggleModal={toggleIsOpened}
                title={t('orguser.workflows.updateStatusInternalName')}
            >
                <WorkflowStatusInternalNameForm item={item} closeModal={() => setIsOpened(false)} />
            </ModalWrapper>
        </>
    );
};

export default InternalNameButton;
