import React, {FC} from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import {getTagColor} from 'pages/admin/updateForm/partials/FormTags/helper';

interface StatusLabelType {
    status: string;
    color: string;
}

const StatusLabel: FC<StatusLabelType> = ({status, color}) => {
    const textColor = getTagColor(color);

    return (
        <Button
            sx={{
                cursor: 'initial',
                minWidth: 120,
                height: 40,
                borderRadius: 20,
                backgroundColor: color,
                '&:hover': {
                    backgroundColor: color,
                },
            }}
        >
            <Typography sx={{textTransform: 'none', color: textColor, ml: 1, mr: 1}}>{status}</Typography>
        </Button>
    );
};

export default StatusLabel;
