import React, {FC} from 'react';
import get from 'lodash/get';
import {FieldProps, FormikProps} from 'formik';
import CurrencyInput from 'react-currency-input-field';

import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {createStyles, WithStyles, withStyles} from '@material-ui/core';

import FormFieldErrorMessage from 'components/AdminScreenComponents/FormFieldErrorMessage';

const styles = () =>
    createStyles({
        input: {
            WebkitBoxShadow: `0 0 0 1000px rgba(0,0,0,0.001) inset`,
            '&:-webkit-autofill': {
                WebkitBoxShadow: `0 0 0 1000px rgba(0,0,0,0.001) inset`,
            },
        },
    });

interface FormikTextInputTypes extends WithStyles<typeof styles> {
    fieldName: string;
    initialValue: number;
    formik: FormikProps<any>;
    label?: string;
    placeholder?: string;
    required?: boolean;
    onBlur?: () => void;
}

const DecimalInput: FC<FormikTextInputTypes> = ({
    label,
    fieldName,
    placeholder,
    initialValue,
    required,
    formik,
    onBlur,
}) => {
    const isTouched = get(formik, ['touched', fieldName], false);
    const errorMessage = get(formik, ['errors', fieldName], false);

    return (
        <Box sx={{width: '100%'}}>
            <FormControl
                fullWidth
                sx={{
                    mt: 1,
                    mb: 1,
                    '& input': {
                        height: 57,
                        border: `1px solid rgba(0, 0, 0, 0.38)`,
                        borderRadius: 1,
                        p: 1.5,
                        fontSize: 16,
                    },
                }}
            >
                {label && <Typography sx={{fontWeight: 700, fontSize: 14, mb: 1}}>{`${label}:`}</Typography>}
                <CurrencyInput
                    required={required}
                    name={fieldName}
                    placeholder={placeholder}
                    defaultValue={0.01 * initialValue}
                    decimalScale={2}
                    decimalSeparator="."
                    onChange={e => {
                        formik.setFieldValue(fieldName, e.target.value);
                    }}
                    onBlur={onBlur && onBlur}
                />
            </FormControl>
            {isTouched && errorMessage && <FormFieldErrorMessage errorMessage={String(errorMessage)} />}
        </Box>
    );
};

export default withStyles(styles)(DecimalInput);
