import React, {FC, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {GET_REQUESTER_CASE} from 'appRedux/actions/requestCase';

import CaseKeyContextWrapper from 'contexts/caseKey';

import ClientScreenWrapper from 'components/ClientScreenComponents/ClientScreenWrapper/ClientScreenWrapper';

import ClientChatPageContent from 'pages/client/chat/ClientChatPageContent';

const ClientChatPage: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {requestCase} = useParams();

    const getRequestCaseInformation = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        requestCase: {
            currentCase: {isFormChatActivated, isChatActivatedByAgent, isEncryptInfo},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        getRequestCaseInformation({id: Number(requestCase)});
    }, []);

    const isChatDisabled = !isFormChatActivated && !isChatActivatedByAgent;

    if (isChatDisabled) {
        return (
            <ClientScreenWrapper>
                <Typography
                    variant="h4"
                    align="center"
                    sx={{
                        mt: 3,
                        mb: 4,
                    }}
                >
                    {t('requester.chat.chatDisabled')}
                </Typography>
            </ClientScreenWrapper>
        );
    }

    // return isEncryptInfo ? (
    return (
        <CaseKeyContextWrapper caseId={isEncryptInfo ? Number(requestCase) : null}>
            <ClientChatPageContent />
        </CaseKeyContextWrapper>
    );
    // ) : (
    //     <ClientChatPageContent />
    // );
};

export default ClientChatPage;
