import React, {FC} from 'react';

interface IconTypeSvgType {
    isActive?: boolean;
}

const IconType_07: FC<IconTypeSvgType> = ({isActive}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.11101 13.8203C5.26216 14.5895 6.61553 15 8 15C9.85652 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8C15 6.61553 14.5895 5.26216 13.8203 4.11101C13.0511 2.95987 11.9579 2.06266 10.6788 1.53285C9.3997 1.00303 7.99224 0.86441 6.63437 1.13451C5.2765 1.4046 4.02922 2.07129 3.05026 3.05026C2.07129 4.02922 1.4046 5.2765 1.13451 6.63437C0.86441 7.99224 1.00303 9.3997 1.53285 10.6788C2.06266 11.9579 2.95987 13.0511 4.11101 13.8203ZM4.66658 3.01119C5.65328 2.3519 6.81332 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8C14 9.18669 13.6481 10.3467 12.9888 11.3334C12.3295 12.3201 11.3925 13.0892 10.2961 13.5433C9.19975 13.9974 7.99335 14.1162 6.82946 13.8847C5.66558 13.6532 4.59648 13.0818 3.75736 12.2426C2.91825 11.4035 2.3468 10.3344 2.11529 9.17054C1.88378 8.00666 2.0026 6.80026 2.45673 5.7039C2.91085 4.60755 3.67989 3.67047 4.66658 3.01119ZM8 4C7.85166 4 7.70666 4.04399 7.58332 4.1264C7.45999 4.20881 7.36386 4.32594 7.30709 4.46299C7.25033 4.60003 7.23547 4.75083 7.26441 4.89632C7.29335 5.0418 7.36478 5.17544 7.46967 5.28033C7.57456 5.38522 7.7082 5.45665 7.85368 5.48559C7.99917 5.51453 8.14997 5.49968 8.28701 5.44291C8.42406 5.38614 8.54119 5.29001 8.6236 5.16668C8.70601 5.04334 8.75 4.89834 8.75 4.75C8.75 4.55109 8.67098 4.36032 8.53033 4.21967C8.38968 4.07902 8.19891 4 8 4ZM8.5 11V7H6.5V8H7.5V11H6V12H10V11H8.5Z"
                fill={isActive ? '#ffffff' : '#222222'}
            />
        </svg>
    );
};

export default IconType_07;
