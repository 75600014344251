import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import {OrganizationPrivacyPolicyItemTypes} from 'appRedux/actions/organization/types';
import {TOGGLE_ORGANIZATION_PRIVACY_POLICY_DEFAULT} from 'appRedux/actions/organization';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface ToggleDefaultButtonType {
    item: OrganizationPrivacyPolicyItemTypes;
    currentLanguage: string;
}

const ToggleDefaultButton: FC<ToggleDefaultButtonType> = ({item, currentLanguage}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {isDefault, uuid} = item;

    const toggleOrganizationPrivacyDefault = useCallback(
        data => dispatch({type: TOGGLE_ORGANIZATION_PRIVACY_POLICY_DEFAULT.REQUEST, payload: data}),
        [dispatch],
    );

    const onToggleClick = () => {
        toggleOrganizationPrivacyDefault({
            uuid,
            lang: currentLanguage,
            showAlert,
        });
    };

    return (
        <AgentSaveButton
            title={t('superadmin.organizations.privacyPolicy.default')}
            isSubmit={false}
            isDanger={isDefault}
            onClick={onToggleClick}
        />
    );
};

export default ToggleDefaultButton;
