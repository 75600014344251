import React, {FC} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import {routes} from 'config/index';
import {theme} from 'config/theme';

interface LoginIfAlreadyInCaseType {
    alreadyLogin?: boolean;
    onClick?: () => void;
}

const LoginIfAlreadyInCase: FC<LoginIfAlreadyInCaseType> = ({alreadyLogin, onClick}) => {
    const [t] = useTranslation();

    if (alreadyLogin) {
        return (
            <RouterLink to={routes.REQUESTS_LIST}>
                <Typography align="center" sx={{mt: 2}}>
                    {t('common.registrationForm.goToOtherCases')}
                </Typography>
            </RouterLink>
        );
    }
    return (
        <Link
            to={!onClick ? routes.START : ''}
            sx={{mt: 1, textDecoration: 'none'}}
            variant="body2"
            component={RouterLink}
            onClick={e => {
                if (!onClick) return;
                e.preventDefault();
                onClick();
            }}
        >
            <Typography
                sx={{
                    color: theme.palette.info.light,
                }}
            >
                {t('common.registrationForm.loginIfAlreadyInCase')}
            </Typography>
        </Link>
    );
};

export default LoginIfAlreadyInCase;
