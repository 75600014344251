import React, {FC, MouseEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import AgentAddMultipleButton from 'components/AgentScreenComponents/_buttons/AgentAddMultipleButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import InviteOrganizationAdminModal from 'components/OrganizationAdminsList/InviteOrganizationAdminModal';
import AddOrganizationAdminModal from 'components/OrganizationAdminsList/AddOrganizationAdminModal';

import {theme} from 'config/theme';

interface AddUserDropdownType {
    organizationId: number;
}

const AddUserDropdown: FC<AddUserDropdownType> = ({organizationId}) => {
    const [t] = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const [showAddModal, setShowAddModal] = useState<boolean>(false);

    const toggleAddModal = () => {
        setShowAddModal(previous => !previous);
    };

    const [showInviteModal, setShowInviteModal] = useState<boolean>(false);

    const toggleInviteModal = () => {
        setShowInviteModal(previous => !previous);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                mb: 2,
            }}
        >
            <AgentAddMultipleButton
                title={t('superadmin.users.addOrganizationUser')}
                onClick={handleClick}
                open={open}
            />
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        mt: 1,
                        pl: 2,
                        pr: 2,
                        pt: 1,
                        pb: 1,
                        minWidth: 250,
                        borderRadius: 2,
                        overflow: 'visible',
                        boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                        backgroundColor: theme.palette.background.default,
                        '& ul': {
                            padding: 0,
                        },
                        overflowX: 'hidden',
                    },
                }}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            >
                <MenuItem onClick={toggleAddModal}>
                    <Typography>{t('superadmin.users.addOrganizationUser')}</Typography>
                </MenuItem>
                <MenuItem onClick={toggleInviteModal}>
                    <Typography>{t('superadmin.users.inviteOrganizationUser')}</Typography>
                </MenuItem>
            </Menu>
            <ModalWrapper
                isShowModal={showAddModal}
                toggleModal={toggleAddModal}
                title={t('superadmin.users.addOrganizationUser')}
            >
                <AddOrganizationAdminModal organizationId={organizationId} closeModal={() => setShowAddModal(false)} />
            </ModalWrapper>
            <ModalWrapper
                isShowModal={showInviteModal}
                toggleModal={toggleInviteModal}
                title={t('superadmin.users.inviteOrganizationUser')}
            >
                <InviteOrganizationAdminModal
                    organizationId={organizationId}
                    closeModal={() => setShowInviteModal(false)}
                />
            </ModalWrapper>
        </Box>
    );
};

export default AddUserDropdown;
