import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import RequiredSvgIcon from 'assets/icons/section/RequiredSvgIcon';
import CompletedSvgIcon from 'assets/icons/section/CompletedSvgIcon';

import {RequesterCaseApproveTypes} from 'appRedux/actions/requestCase/types';

import {CASE_STATUS_CHANGE_APPROVED, CASE_STATUS_CHANGE_DEFAULT} from 'components/Forms/RequesterCase/helper';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AvatarImage from 'components/UserAvatar/AvatarImage';
import {buttonOutlineStyles, labelOutlineStyles} from 'components/AgentScreenComponents/_buttons/common';
import {getAvatarTitle} from 'components/TopBarMenu/helper';

import RemoveResolutionButton from 'pages/agent/requesterPage/formInformation/approver/RemoveResolutionButton';

import {DATE_TIME_LABEL_FORMAT} from 'config/index';

interface ApproverResolutionButtonType {
    item: RequesterCaseApproveTypes;
    isCurrentUserResolution: boolean;
}

const ApproverResolutionButton: FC<ApproverResolutionButtonType> = ({item, isCurrentUserResolution}) => {
    const [t] = useTranslation();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpen(previous => !previous);
    };

    const {status, comment, avatarType, avatarLink, firstName, lastName, email, changeRequestStatus, updatedAt} = item;

    const isApproved = status === CASE_STATUS_CHANGE_APPROVED;

    const title = isApproved
        ? 'orguser.requesterCase.approves.requestApproved'
        : 'orguser.requesterCase.approves.requestRejected';

    return (
        <>
            <IconButton title={t(title)} onClick={toggleModal}>
                {isApproved ? <CompletedSvgIcon /> : <RequiredSvgIcon />}
            </IconButton>
            <ModalWrapper isShowModal={isOpen} toggleModal={toggleModal} title={t(title)}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Button sx={{...buttonOutlineStyles, pl: 0.5, mb: 1, width: 'max-content'}}>
                        <AvatarImage
                            size={25}
                            avatarType={avatarType}
                            avatarLink={avatarLink}
                            avatarTitle={getAvatarTitle(firstName, lastName, email)}
                        />
                        <Typography sx={{...labelOutlineStyles, ml: 1}}>
                            {firstName && lastName ? `${firstName} ${lastName}` : email}
                        </Typography>
                    </Button>
                    <Typography sx={{fontSize: 12}}>{moment(updatedAt).format(DATE_TIME_LABEL_FORMAT)}</Typography>
                </Box>
                <Alert severity={isApproved ? 'success' : 'error'}>
                    <Typography sx={{fontSize: 12}}>{comment}</Typography>
                </Alert>
                {isCurrentUserResolution && !item.isApproved && changeRequestStatus === CASE_STATUS_CHANGE_DEFAULT && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            mt: 2,
                        }}
                    >
                        <RemoveResolutionButton item={item} />
                    </Box>
                )}
            </ModalWrapper>
        </>
    );
};

export default ApproverResolutionButton;
