import React, {ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Box';

import {GET_CASE_KEYS} from 'appRedux/actions/crypto';
import {RootReducer} from 'appRedux/reducers';

import {CaseKeyContextType, CaseKeyContext} from 'contexts/caseKey/context';
import {AlertContext} from 'contexts/alert/context';
import {CryptoContext} from 'contexts/crypto/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';
import CaseAccessRequestBlock from 'components/CaseAccessRequest/CaseAccessRequestBlock';

import {unwrapKey} from 'helpers/cryptoApiHelper';

interface ContextType {
    caseId: number | null;
    children: ReactNode;
    isRequesterCaseDrawer?: boolean;
    formPrefix?: string;
}

const CaseKeyContextWrapper: React.FC<ContextType> = ({caseId, isRequesterCaseDrawer, formPrefix, children}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {keys, readWrapperKeys} = useContext(CryptoContext);

    const [isChecking, setIsChecking] = useState<boolean>(true);
    const [isReviewPossible, setIsReviewPossible] = useState<boolean>(false);
    const [caseKey, setCaseKey] = useState<CryptoKey | null>(null);
    const [encodedCaseKey, setEncodedCaseKey] = useState<string>('');

    const getCaseKeys = useCallback(data => dispatch({type: GET_CASE_KEYS.REQUEST, payload: data}), [dispatch]);

    const {
        auth: {organization},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const orgId = get(organization, 'id', null);
    const userId = get(profile, 'id', null);

    const checkCaseKey = async (caseKeys: string[]) => {
        if (keys) {
            for (const item of caseKeys) {
                try {
                    const unwrappedCaseKey = await unwrapKey(item, keys.privateKey);
                    if (unwrappedCaseKey) {
                        setEncodedCaseKey(item);
                        setCaseKey(unwrappedCaseKey);
                        setIsReviewPossible(true);
                        break;
                    }
                } catch (e) {
                    setIsReviewPossible(false);
                }
            }
            setIsChecking(false);
        }
    };

    useEffect(() => {
        return () => {
            setIsChecking(true);
            setIsReviewPossible(false);
        };
    }, []);

    useEffect(() => {
        if (!caseId) {
            setCaseKey(null);
            return;
        }

        if (!keys && userId && orgId) {
            readWrapperKeys(userId, orgId);
        }
        if (userId && keys) {
            getCaseKeys({
                id: caseId,
                showAlert,
                callback: checkCaseKey,
            });
        }
    }, [caseId, keys]);

    const context: CaseKeyContextType = {
        unwrappedCaseKey: caseKey,
        encodedCaseKey,
    };

    if (!isReviewPossible && caseId) {
        if (!isChecking && isRequesterCaseDrawer) {
            return (
                <ClientSectionWrapper>
                    <Typography>{t('messages.validation.informationEncryptedMessage')}</Typography>
                </ClientSectionWrapper>
            );
        } else if (!isChecking) {
            return <CaseAccessRequestBlock caseId={caseId} formPrefix={formPrefix} />;
        }
        return null;
    }
    return <CaseKeyContext.Provider value={context}>{children}</CaseKeyContext.Provider>;
};

export default CaseKeyContextWrapper;
