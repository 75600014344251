declare global {
    interface Document {
        msExitFullscreen?: () => Promise<void>;
        mozCancelFullScreen?: () => Promise<void>;
        webkitExitFullscreen?: () => Promise<void>;
    }
    interface Window {
        fullScreen?: boolean;
    }
}

export type RequestFullscreenElement =
    | (HTMLElement & {
          mozRequestFullScreen(): Promise<void>;
          webkitRequestFullScreen(): Promise<void>;
          webkitRequestFullscreen(): Promise<void>;
          msRequestFullscreen(): Promise<void>;
      })
    | null;

export const requestFullscreen = (element: RequestFullscreenElement) => {
    if (!element) return;

    if (element.requestFullscreen) return element.requestFullscreen();
    if (element.webkitRequestFullScreen) return element.webkitRequestFullScreen();
    if (element.webkitRequestFullscreen) return element.webkitRequestFullscreen();
    if (element.mozRequestFullScreen) return element.mozRequestFullScreen();
    if (element.msRequestFullscreen) return element.msRequestFullscreen();
    // alert("Fullscreen is not supported on your device.");
    throw new Error('Fullscreen is not supported');
};

export const exitFullScreen = () => {
    if (document.exitFullscreen) return document.exitFullscreen();
    if (document.msExitFullscreen) return document.msExitFullscreen();
    if (document.mozCancelFullScreen) return document.mozCancelFullScreen();
    if (document.webkitExitFullscreen) return document.webkitExitFullscreen();
    // alert("Fullscreen is not supported on your device.");
};

export const checkIfFullscreen = () =>
    window.fullScreen || (window.innerWidth == screen.width && window.innerHeight == screen.height);
