import React, {FC} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import {FormConfigurationRequestTypes} from 'appRedux/actions/forms/types';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import {subFormValidationSchema} from 'components/Forms/FormBuilder/ConfigurationForm/validation';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import MdxEditorField from 'components/MdxEditorField/MdxEditorField';

interface ConfigurationFormType {
    initialValues: FormConfigurationRequestTypes;
    onSubmitClicked: (values: FormConfigurationRequestTypes) => void;
    isLoading: boolean;
    isCreate?: boolean;
}

const ConfigurationForm: FC<ConfigurationFormType> = ({initialValues, isLoading, isCreate, onSubmitClicked}) => {
    const [t] = useTranslation();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={() => subFormValidationSchema(t)}
            onSubmit={(values, {setErrors}) => {
                onSubmitClicked({
                    ...values,
                    setErrors,
                });
            }}
        >
            {(formik: FormikProps<FormConfigurationRequestTypes>) => {
                return (
                    <Form>
                        <Box>
                            <Field
                                required
                                name="title"
                                placeholder={t('orguser.forms.title')}
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box>
                            <MdxEditorField
                                name="description"
                                value={formik.values.description ? String(formik.values.description) : ''}
                                label={t('orguser.forms.description')}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: 3,
                            }}
                        >
                            <AgentSaveButton
                                isSubmit
                                isLoading={isLoading}
                                title={t(isCreate ? 'orguser.forms.addNewForm' : 'common.buttons.saveButton')}
                            />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ConfigurationForm;
