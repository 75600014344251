import React, {FC, useCallback, useContext, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import {OrganizationQuotasTypes} from 'appRedux/actions/organization/types';
import {CREATE_ORGANIZATION_QUOTA} from 'appRedux/actions/organization';

import {AlertContext} from 'contexts/alert/context';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import OrganizationQuotaForm from 'components/Forms/OrganizationQuotaForm/OrganizationQuotaForm';

import {OrganizationQuotasArrayType} from 'pages/admin/updateOrganization/partials/OrganizationQuotas/helper';

interface CreateOrganizationQuotaButtonType {
    item: OrganizationQuotasArrayType;
}

const CreateOrganizationQuotaButton: FC<CreateOrganizationQuotaButtonType> = ({item}) => {
    const [t] = useTranslation();
    const {id} = useParams();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleShowModal = () => {
        setShowModal(previous => !previous);
    };

    const submitCreateOrganizationQuota = useCallback(
        data => dispatch({type: CREATE_ORGANIZATION_QUOTA.REQUEST, payload: data}),
        [dispatch],
    );

    const createOrganizationQuota = (values: OrganizationQuotasTypes) => {
        submitCreateOrganizationQuota({
            id: Number(id),
            ...values,
            callback: () => setShowModal(false),
            showAlert,
        });
    };

    return (
        <>
            <IconButton title={t('common.buttons.create')} onClick={toggleShowModal}>
                <PlusSvgIcon />
            </IconButton>
            <ModalWrapper
                title={t('superadmin.organizations.createOrganizationQuota')}
                isShowModal={showModal}
                toggleModal={toggleShowModal}
            >
                <OrganizationQuotaForm
                    typeLabel={item.title}
                    initialValues={{
                        type: item.type,
                        value: 0,
                        isHard: false,
                    }}
                    onSubmitClicked={createOrganizationQuota}
                />
            </ModalWrapper>
        </>
    );
};

export default CreateOrganizationQuotaButton;
