import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import {AUTO_CLIENT_TRANSLATION} from 'appRedux/actions/clientTranslation';

import {AlertContext} from 'contexts/alert/context';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';

interface AutoTranslationMenuItemType {
    sectionId: number;
    setShowTranslation: (value: boolean) => void;
    setEditTranslation: (value: boolean) => void;
    handleClose: () => void;
}

const AutoTranslationMenuItem: FC<AutoTranslationMenuItemType> = ({
    sectionId,
    setShowTranslation,
    setEditTranslation,
    handleClose,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {caseId} = useParams();

    const {showAlert} = useContext(AlertContext);

    const [openModal, setOpenModal] = useState<boolean>(false);

    const toggleModal = () => {
        setOpenModal(previous => !previous);
    };

    const autoGoogleTranslate = useCallback(
        data => dispatch({type: AUTO_CLIENT_TRANSLATION.REQUEST, payload: data}),
        [dispatch],
    );

    const onTranslateAutoClick = () => {
        autoGoogleTranslate({
            id: Number(caseId),
            section: sectionId,
            showAlert,
            callback: () => {
                setOpenModal(false);
                setEditTranslation(false);
                setShowTranslation(true);
            },
        });
        handleClose();
    };

    return (
        <>
            <MenuItem onClick={() => setOpenModal(true)}>
                <Typography>{t('orguser.requesterCase.translation.autoTranslation')}</Typography>
            </MenuItem>
            <ModalWrapper isShowModal={openModal} toggleModal={toggleModal} title={t('messages.warning.attention')}>
                <Typography>{t('orguser.requesterCase.translation.googleTranslationWarning')}</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 3,
                    }}
                >
                    <AgentInverseButton
                        title={t('common.buttons.cancel')}
                        onClick={() => {
                            setOpenModal(false);
                            handleClose();
                        }}
                    />
                    <AgentSaveButton
                        isSubmit={false}
                        title={t('common.buttons.translate')}
                        onClick={onTranslateAutoClick}
                    />
                </Box>
            </ModalWrapper>
        </>
    );
};

export default AutoTranslationMenuItem;
