import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {FormikProps} from 'formik';

import Typography from '@mui/material/Typography';
import {SelectChangeEvent} from '@mui/material/Select';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import PlusSvgIcon from 'assets/icons/small/PlusSvgIcon';

import {RootReducer} from 'appRedux/reducers';
import {FormItemBasic} from 'appRedux/actions/forms/types';
import {RoleFilterRequestTypes, RoleCommonRequestTypes} from 'appRedux/actions/roles/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import {getFilteredForms} from 'components/Forms/RoleForm/helper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {theme} from 'config/theme';

interface AddFormForFiltersType {
    filterType: number;
    organizationId: number;
    roleFilters: RoleFilterRequestTypes[];
    setRoleFilters: (value: RoleFilterRequestTypes[]) => void;
    formik: FormikProps<RoleCommonRequestTypes>;
}

const AddFormForFilters: FC<AddFormForFiltersType> = ({
    formik,
    filterType,
    organizationId,
    roleFilters,
    setRoleFilters,
}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [, setIsUpdated] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {
        admin: {formsList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onFormChecked = (event: SelectChangeEvent) => {
        const formId = event.target.value;
        const isChecked = roleFilters.some((roleFilter: RoleFilterRequestTypes) => {
            return roleFilter.form === Number(formId) && roleFilter.type === filterType;
        });
        if (isChecked) {
            for (let i = 0, n = roleFilters.length; i < n; i++) {
                const item = roleFilters[i];
                if (item && item.form === Number(formId) && item.type === filterType) {
                    roleFilters.splice(i, 1);
                    setRoleFilters(roleFilters);
                    setIsUpdated(previous => !previous);
                    break;
                }
            }
        } else {
            setRoleFilters([
                ...roleFilters,
                {
                    form: Number(formId),
                    type: filterType,
                },
            ]);
        }
    };

    const filtersListRow =
        formsList && filterType && roleFilters ? getFilteredForms(formsList, filterType, roleFilters) : null;

    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                {filtersListRow && <Typography sx={{mr: 1}}>{filtersListRow}</Typography>}
                <AgentInverseButton
                    title={t('orguser.roles.addForm')}
                    icon={<PlusSvgIcon />}
                    onClick={toggleIsOpened}
                />
            </Box>
            <ModalWrapper isShowModal={isOpened} title={t('orguser.roles.selectForm')} toggleModal={toggleIsOpened}>
                <FormGroup>
                    {formsList &&
                        formsList.map((item: FormItemBasic, index: number) => {
                            if (organizationId === Number(item.organizationId)) {
                                const isChecked = roleFilters.some((roleFilter: RoleFilterRequestTypes) => {
                                    return roleFilter.form === item.id && roleFilter.type === filterType;
                                });
                                const prefix = item.handlePrefix ? item.handlePrefix : item.url;
                                return (
                                    <FormControlLabel
                                        key={`form-checkbox-${index}`}
                                        control={
                                            <Checkbox
                                                value={item.id}
                                                checked={isChecked}
                                                onChange={onFormChecked}
                                                sx={{
                                                    '& svg': {color: theme.palette.info.main},
                                                }}
                                            />
                                        }
                                        label={`${item.title} (${prefix})`}
                                    />
                                );
                            }
                            return null;
                        })}
                </FormGroup>
                <Box
                    sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mt: 2}}
                >
                    <AgentSaveButton
                        isSubmit={false}
                        onClick={() => {
                            formik.submitForm();
                            setIsOpened(false);
                        }}
                    />
                </Box>
            </ModalWrapper>
        </>
    );
};

export default AddFormForFilters;
