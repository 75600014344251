import * as yup from 'yup';

export const loginInitialValues = {
    username: '',
    password: '',
};

export const loginValidationSchema = (t: (trans: string) => string) =>
    yup.object({
        username: yup.string().required(t('messages.validation.emailOrUsernameIsRequired')),
        password: yup.string().required(t('messages.validation.passwordIsRequired')),
    });
