import React from 'react';

import {OrganizationItemBasic} from 'appRedux/actions/organization/types';

export type AdminContextType = {
    searchValue: string;
    setSearchValue: (value: string) => void;
    filtersNumber: number;
    setFiltersNumber: (value: number) => void;
    questionSectionId: number | null;
    setQuestionSectionId: (value: number | null) => void;
    questionSectionName: string | null;
    setQuestionSectionName: (value: string | null) => void;
    isSelectClicked: boolean;
    setIsSelectClicked: (value: boolean) => void;
    toggleIsSelectClicked: () => void;
    selectedIds: number[];
    setSelectedIds: (value: number[]) => void;
    toggleIsUpdated: () => void;
    showFiltersPanel: boolean;
    setShowFiltersPanel: (value: boolean) => void;
    toggleFiltersPanel: () => void;
    selectAllFiltered: boolean;
    setSelectAllFiltered: (value: boolean) => void;
    isDragStart: boolean;
    toggleDragStart: () => void;
    setIsDragStart: (value: boolean) => void;
    dragType: string | null;
    setDragType: (value: string | null) => void;
    dropType: string | null;
    setDropType: (value: string | null) => void;
    activeRow: number | null;
    setActiveRow: (value: number | null) => void;
    itemsPerPage: number;
    currentPage: number;
    totalItems: number | null;
    setTotalItems: (value: number | null) => void;
    draggedCaseInitialStatus: string | null;
    setDraggedCaseInitialStatus: (value: string | null) => void;
    draggedCasePermittedUuids: string[];
    setDraggedCasePermittedUuids: (value: string[]) => void;
    myOrganization: OrganizationItemBasic | null;
};

export const AdminContext = React.createContext({} as AdminContextType);
