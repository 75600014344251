import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Link as RouterLink} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import RecoveryPasswordForm from 'pages/start/recoveryPassword/RecoveryPasswordForm';
import LoginPageWrapper from 'pages/start/login/partials/LoginPageWrapper';

import {theme} from 'config/theme';
import {routes} from 'config/index';

interface RecoveryPasswordType {
    isRecovery?: boolean;
}

const RecoveryPassword: FC<RecoveryPasswordType> = ({isRecovery}) => {
    const [t] = useTranslation();

    return (
        <LoginPageWrapper>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    mt: 4,
                }}
            >
                <RecoveryPasswordForm isRecovery={isRecovery} />
                <Link to={routes.START} sx={{mt: 2, textDecoration: 'none'}} component={RouterLink}>
                    <Typography
                        sx={{
                            color: theme.palette.info.light,
                        }}
                    >
                        {t('common.loginForm.login')}
                    </Typography>
                </Link>
            </Box>
        </LoginPageWrapper>
    );
};

export default RecoveryPassword;
