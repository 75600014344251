import {
    GET_LOGIN_ATTEMPTS,
    GET_PASSWORD_RESET_ATTEMPTS,
    GET_DRAFT_REMOVES,
    GET_LOGIN_ATTEMPTS_STATISTIC,
    GET_PASSWORD_RESET_STATISTIC,
    GET_MAIL_SENDING_STATISTIC,
} from 'appRedux/actions/superadmin';

import {
    LoginAttemptResponseTypes,
    DraftRemoveDataResponseTypes,
    LoginAttemptsStatisticItemTypes,
} from 'appRedux/actions/superadmin/types';

export interface SuperadminState {
    isLoading: boolean;
    draftRemoves: DraftRemoveDataResponseTypes;
    logins: LoginAttemptResponseTypes | undefined;
    passwordResets: LoginAttemptResponseTypes | undefined;
    loginAttemptsStatistic: LoginAttemptsStatisticItemTypes[];
    passwordResetAttemptsStatistic: LoginAttemptsStatisticItemTypes[];
    mailSendingStatistic: LoginAttemptsStatisticItemTypes[];
    error: IErrors | undefined;
}

export const initialState: SuperadminState = {
    isLoading: false,
    draftRemoves: {
        count: 0,
        data: [],
    },
    logins: {
        count: 0,
        data: [],
    },
    passwordResets: {
        count: 0,
        data: [],
    },
    loginAttemptsStatistic: [],
    passwordResetAttemptsStatistic: [],
    mailSendingStatistic: [],
    error: undefined,
};

const superadmin = (
    state = initialState,
    action: IActionType<
        DraftRemoveDataResponseTypes | LoginAttemptResponseTypes | LoginAttemptsStatisticItemTypes[] | IErrors
    >,
): SuperadminState => {
    switch (action.type) {
        case GET_LOGIN_ATTEMPTS.REQUEST:
        case GET_PASSWORD_RESET_ATTEMPTS.REQUEST:
        case GET_DRAFT_REMOVES.REQUEST:
        case GET_LOGIN_ATTEMPTS_STATISTIC.REQUEST:
        case GET_PASSWORD_RESET_STATISTIC.REQUEST:
        case GET_MAIL_SENDING_STATISTIC.REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        }

        case GET_DRAFT_REMOVES.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                draftRemoves: action.payload as DraftRemoveDataResponseTypes,
            };
        }

        case GET_LOGIN_ATTEMPTS.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                logins: action.payload as LoginAttemptResponseTypes,
                error: undefined,
            };
        }

        case GET_PASSWORD_RESET_ATTEMPTS.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                passwordResets: action.payload as LoginAttemptResponseTypes,
                error: undefined,
            };
        }

        case GET_LOGIN_ATTEMPTS_STATISTIC.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                loginAttemptsStatistic: action.payload as LoginAttemptsStatisticItemTypes[],
                error: undefined,
            };
        }

        case GET_PASSWORD_RESET_STATISTIC.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                passwordResetAttemptsStatistic: action.payload as LoginAttemptsStatisticItemTypes[],
                error: undefined,
            };
        }

        case GET_MAIL_SENDING_STATISTIC.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                mailSendingStatistic: action.payload as LoginAttemptsStatisticItemTypes[],
                error: undefined,
            };
        }

        case GET_LOGIN_ATTEMPTS.ERROR:
        case GET_PASSWORD_RESET_ATTEMPTS.ERROR:
        case GET_DRAFT_REMOVES.ERROR:
        case GET_LOGIN_ATTEMPTS_STATISTIC.ERROR:
        case GET_PASSWORD_RESET_STATISTIC.ERROR:
        case GET_MAIL_SENDING_STATISTIC.ERROR: {
            return {
                ...state,
                isLoading: false,
                error: (action.payload as IErrors) || {},
            };
        }

        default:
            return state;
    }
};

export default superadmin;
