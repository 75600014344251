import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {RootReducer} from 'appRedux/reducers';
import {FORM_WORKFLOW_GET_STATUSES} from 'appRedux/actions/forms';

import {AlertContext} from 'contexts/alert/context';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import FormWorkflowUpdateForm from 'components/Forms/FormBuilder/FormWorkflowUpdateForm/FormWorkflowUpdateForm';

import {theme} from 'config/theme';

interface UpdateFormWorkflowButtonType {
    isLocked?: boolean;
    currentWorkflowId: number;
}

const UpdateFormWorkflowButton: FC<UpdateFormWorkflowButtonType> = ({currentWorkflowId, isLocked}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {id} = useParams();

    const {showAlert} = useContext(AlertContext);

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    const getCurrentWorkflowStatuses = useCallback(
        data => dispatch({type: FORM_WORKFLOW_GET_STATUSES.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        getCurrentWorkflowStatuses({
            id: Number(id),
            showAlert,
        });
    }, [id]);

    return (
        <>
            <IconButton
                sx={{
                    backgroundColor: theme.palette.info.main,
                    ml: 2,
                    '&:hover': {
                        backgroundColor: theme.palette.info.main,
                    },
                }}
                title={t('orguser.forms.updateWorkflowForm.title')}
                onClick={toggleModal}
                disabled={isLoading || isLocked}
            >
                <EditSvgIcon isActive isDisabled={isLoading} />
            </IconButton>
            <ModalWrapper
                isShowModal={showModal}
                toggleModal={toggleModal}
                title={t('orguser.forms.updateWorkflowForm.title')}
                isWideModal
            >
                <FormWorkflowUpdateForm currentWorkflowId={currentWorkflowId} closeForm={() => setShowModal(false)} />
            </ModalWrapper>
        </>
    );
};

export default UpdateFormWorkflowButton;
