import {fork, put, take, call} from 'redux-saga/effects';

import {
    GET_PANELS_LIST,
    CREATE_PANEL,
    UPDATE_PANEL,
    DELETE_PANEL,
    SET_HOMEPAGE_PANEL,
    getPanelsList,
    createPanel,
    updatePanel,
    deletePanel,
    setHomepagePanel,
    GET_DASHBOARDS_LIST,
    CREATE_DASHBOARD,
    UPDATE_DASHBOARD,
    DELETE_DASHBOARD,
    CHANGE_DASHBOARD_PRIORITY,
    getDashboardsList,
    createDashboard,
    updateDashboard,
    deleteDashboard,
    changeDashboardPriority,
} from 'appRedux/actions/analytics';
import {
    PanelsListRequestTypes,
    PanelItemsResponseTypes,
    PanelCreateRequestTypes,
    PanelUpdateRequestTypes,
    PanelDeleteRequestTypes,
    PanelHomepageRequestTypes,
    DashboardsListRequestTypes,
    DashboardItemsResponseTypes,
    DashboardCreateRequestTypes,
    DashboardUpdateRequestTypes,
    DashboardDeleteRequestTypes,
    ChangeDashboardPriorityRequestTypes,
} from 'appRedux/actions/analytics/types';

import {http} from 'services/http';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchGetPanelsList() {
    while (true) {
        const {
            payload: {callback, showAlert},
        }: IActionType<PanelsListRequestTypes> = yield take(GET_PANELS_LIST.REQUEST);
        try {
            const data: PanelItemsResponseTypes = yield call(http, `panels`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getPanelsList.success(data.results));
                callback && callback();
            } else {
                yield put(getPanelsList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getPanelsList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchPanelCreation() {
    while (true) {
        const {
            payload: {callback, showAlert, ...values},
        }: IActionType<PanelCreateRequestTypes> = yield take(CREATE_PANEL.REQUEST);
        try {
            const data: PanelItemsResponseTypes = yield call(http, `panel/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createPanel.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createPanel.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createPanel.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchPanelUpdating() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert, ...values},
        }: IActionType<PanelUpdateRequestTypes> = yield take(UPDATE_PANEL.REQUEST);
        try {
            const data: PanelItemsResponseTypes = yield call(http, `panel/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updatePanel.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updatePanel.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updatePanel.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchPanelRemoving() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert},
        }: IActionType<PanelDeleteRequestTypes> = yield take(DELETE_PANEL.REQUEST);
        try {
            const data: PanelItemsResponseTypes = yield call(http, `panel/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deletePanel.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deletePanel.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deletePanel.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchHomepagePanelSetting() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert},
        }: IActionType<PanelHomepageRequestTypes> = yield take(SET_HOMEPAGE_PANEL.REQUEST);
        try {
            const data: PanelItemsResponseTypes = yield call(http, `panel/${uuid}/homepage`, {
                method: 'POST',
            });
            if (data.success) {
                yield put(setHomepagePanel.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(setHomepagePanel.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(setHomepagePanel.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchGetDashboardsList() {
    while (true) {
        const {
            payload: {callback, showAlert},
        }: IActionType<DashboardsListRequestTypes> = yield take(GET_DASHBOARDS_LIST.REQUEST);
        try {
            const data: DashboardItemsResponseTypes = yield call(http, `dashboards`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getDashboardsList.success(data.results));
                callback && callback();
            } else {
                yield put(getDashboardsList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getDashboardsList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchDashboardCreation() {
    while (true) {
        const {
            payload: {callback, showAlert, ...values},
        }: IActionType<DashboardCreateRequestTypes> = yield take(CREATE_DASHBOARD.REQUEST);
        try {
            const data: DashboardItemsResponseTypes = yield call(http, `dashboard/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createDashboard.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createDashboard.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createDashboard.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchDashboardUpdating() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert, ...values},
        }: IActionType<DashboardUpdateRequestTypes> = yield take(UPDATE_DASHBOARD.REQUEST);
        try {
            const data: DashboardItemsResponseTypes = yield call(http, `dashboard/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateDashboard.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateDashboard.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateDashboard.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchDashboardRemoving() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert},
        }: IActionType<DashboardDeleteRequestTypes> = yield take(DELETE_DASHBOARD.REQUEST);
        try {
            const data: DashboardItemsResponseTypes = yield call(http, `dashboard/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteDashboard.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteDashboard.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteDashboard.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchDashboardPriorityChanging() {
    while (true) {
        const {
            payload: {uuid, priority, callback, showAlert},
        }: IActionType<ChangeDashboardPriorityRequestTypes> = yield take(CHANGE_DASHBOARD_PRIORITY.REQUEST);
        try {
            const data: DashboardItemsResponseTypes = yield call(http, `dashboard/${uuid}/priority/${priority}`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(changeDashboardPriority.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(changeDashboardPriority.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(changeDashboardPriority.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchGetPanelsList),
    fork(watchPanelCreation),
    fork(watchPanelUpdating),
    fork(watchPanelRemoving),
    fork(watchHomepagePanelSetting),
    fork(watchGetDashboardsList),
    fork(watchDashboardCreation),
    fork(watchDashboardUpdating),
    fork(watchDashboardRemoving),
    fork(watchDashboardPriorityChanging),
];
