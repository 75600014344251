import React, {FC, useEffect, useRef} from 'react';
import {
    MDXEditor,
    toolbarPlugin,
    imagePlugin,
    headingsPlugin,
    listsPlugin,
    quotePlugin,
    thematicBreakPlugin,
    linkPlugin,
    linkDialogPlugin,
    frontmatterPlugin,
    markdownShortcutPlugin,
    BoldItalicUnderlineToggles,
    UndoRedo,
    Separator,
    ListsToggle,
    InsertThematicBreak,
    MDXEditorMethods,
} from '@mdxeditor/editor';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import {theme, CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface CustomMdxEditorTypes {
    mdxEditorClassName: any;
    label?: string;
    value?: string;
    onChange: (value: string) => void;
    onBlur?: () => void;
}

const CustomMdxEditor: FC<CustomMdxEditorTypes> = ({mdxEditorClassName, label, value = '', onChange, onBlur}) => {
    const mdxRef = useRef<MDXEditorMethods>(null);

    useEffect(() => {
        mdxRef.current && mdxRef.current.setMarkdown(value);
    }, [value]);

    return (
        <Box>
            {label && <Typography sx={{fontWeight: 600, mb: 1}}>{`${label}:`}</Typography>}
            <Box
                sx={{
                    borderColor: CLIENT_BACKGROUND_COLOR,
                    backgroundColor: theme.palette.background.default,
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderRadius: 2,
                    p: 1,
                    pt: 2,
                }}
            >
                <MDXEditor
                    ref={mdxRef}
                    markdown={value || ''}
                    contentEditableClassName={mdxEditorClassName}
                    plugins={[
                        toolbarPlugin({
                            toolbarContents: () => (
                                <Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            gap: 0.5,
                                            mb: 1,
                                            '& div': {
                                                display: 'flex',
                                            },
                                            '& div[role="separator"]': {
                                                width: 3,
                                            },
                                            '& button': {
                                                backgroundColor: theme.palette.info.main,
                                                border: `1px solid ${theme.palette.info.main}`,
                                                borderRadius: 16,
                                                height: 32,
                                                minWidth: 60,
                                                marginRight: 0.5,
                                            },
                                            '& button[disabled]': {
                                                backgroundColor: CLIENT_BACKGROUND_COLOR,
                                                border: `1px solid ${CLIENT_BACKGROUND_COLOR}`,
                                            },
                                            '& button[title="Underline"]': {
                                                display: 'none',
                                            },
                                            '& g': {
                                                color: theme.palette.background.default,
                                            },
                                            '& button[disabled] g': {
                                                color: theme.palette.info.light,
                                            },
                                        }}
                                    >
                                        <UndoRedo />
                                        <Separator />
                                        <BoldItalicUnderlineToggles />
                                        <Separator />
                                        <ListsToggle options={['number', 'bullet']} />
                                        <Separator />
                                        <InsertThematicBreak />
                                        <Separator />
                                    </Box>

                                    <Divider sx={{mt: 1}} />
                                </Box>
                            ),
                        }),
                        headingsPlugin(),
                        imagePlugin(),
                        listsPlugin(),
                        quotePlugin(),
                        thematicBreakPlugin(),
                        linkPlugin(),
                        linkDialogPlugin(),
                        frontmatterPlugin(),
                        markdownShortcutPlugin(),
                    ]}
                    onChange={onChange}
                    onBlur={onBlur}
                />
            </Box>
        </Box>
    );
};

export default CustomMdxEditor;
