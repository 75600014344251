import * as yup from 'yup';

import {REGEX_FOR_TOOLTIP, REGEX_FOR_DESCRIPTION, REGEX_FOR_TITLES} from 'components/Forms/FormBuilder/helper';

export const formSectionInitialValues = {
    title: '',
    description: '',
    tooltip: '',
    isRequired: true,
};

const SECTION_TITLE_MINIMAL_LENGTH = 2;
const SECTION_TITLE_MAXIMAL_LENGTH = 100;

const SECTION_TOOLTIP_MINIMAL_LENGTH = 2;
const SECTION_TOOLTIP_MAXIMAL_LENGTH = 100;

export const formSectionValidationSchema = (t: (trans: string) => string) =>
    yup.object({
        title: yup
            .string()
            .required('messages.validation.isRequired')
            .matches(REGEX_FOR_TITLES, 'messages.validation.valueStructureValidationLatinNumbersSpecialAllowed')
            .min(
                SECTION_TITLE_MINIMAL_LENGTH,
                t('messages.validation.valueMinimalLength').replace('%NUMBER%', String(SECTION_TITLE_MINIMAL_LENGTH)),
            )
            .max(
                SECTION_TITLE_MAXIMAL_LENGTH,
                t('messages.validation.valueMaximalLength').replace('%NUMBER%', String(SECTION_TITLE_MAXIMAL_LENGTH)),
            ),
        description: yup
            .string()
            .nullable()
            .matches(REGEX_FOR_DESCRIPTION, 'messages.validation.valueStructureValidationLatinNumbersSpecialAllowed'),
        tooltip: yup
            .string()
            .nullable()
            .matches(REGEX_FOR_TOOLTIP, 'messages.validation.valueStructureValidationLatinNumbersSpecialAllowed')
            .min(
                SECTION_TOOLTIP_MINIMAL_LENGTH,
                t('messages.validation.valueMinimalLength').replace('%NUMBER%', String(SECTION_TOOLTIP_MINIMAL_LENGTH)),
            )
            .max(
                SECTION_TOOLTIP_MAXIMAL_LENGTH,
                t('messages.validation.valueMaximalLength').replace('%NUMBER%', String(SECTION_TOOLTIP_MAXIMAL_LENGTH)),
            ),
    });

export const formSectionValidationSchemaNotOnlyLatin = (t: (trans: string) => string) =>
    yup.object({
        title: yup
            .string()
            .required('messages.validation.isRequired')
            .min(
                SECTION_TITLE_MINIMAL_LENGTH,
                t('messages.validation.valueMinimalLength').replace('%NUMBER%', String(SECTION_TITLE_MINIMAL_LENGTH)),
            )
            .max(
                SECTION_TITLE_MAXIMAL_LENGTH,
                t('messages.validation.valueMaximalLength').replace('%NUMBER%', String(SECTION_TITLE_MAXIMAL_LENGTH)),
            ),
        description: yup.string().nullable(),
        tooltip: yup
            .string()
            .nullable()
            .min(
                SECTION_TOOLTIP_MINIMAL_LENGTH,
                t('messages.validation.valueMinimalLength').replace('%NUMBER%', String(SECTION_TOOLTIP_MINIMAL_LENGTH)),
            )
            .max(
                SECTION_TOOLTIP_MAXIMAL_LENGTH,
                t('messages.validation.valueMaximalLength').replace('%NUMBER%', String(SECTION_TOOLTIP_MAXIMAL_LENGTH)),
            ),
    });
