import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import {BoardItemTypes, BoardStatusTypes} from 'appRedux/actions/workflow/types';
import {CHANGE_BOARD_PRIORITY, TOGGLE_BOARD_STATUS} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import {CustomSwitcher} from 'components/AgentScreenComponents/_form/CustomElements';
import {getBoardIconByType, getBoardTypeLabel, BOARD_TYPE_OVERVIEW} from 'components/Forms/BoardForm/helper';
import DraggableTableRow from 'components/AdminScreenComponents/DraggableTableRow';

import DeleteBoardButton from 'pages/admin/boardsList/DeleteBoardButton';
import EditBoardButton from 'pages/admin/boardsList/EditBoardButton';

interface BoardTableRowType {
    item: BoardItemTypes;
    dragItem?: string;
    setDragItem: React.Dispatch<React.SetStateAction<string>>;
    dropTo?: number;
    setDropTo: React.Dispatch<React.SetStateAction<number>>;
    isEditEnabled: boolean;
}

const BoardTableRow: FC<BoardTableRowType> = ({item, dragItem, setDragItem, dropTo, setDropTo, isEditEnabled}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {title, workflow, uuid, type, statuses, iconType, isActive} = item;

    const {showAlert} = useContext(AlertContext);

    const [isBoardActive, setIsBoardActive] = useState<boolean>(isActive);

    const changeStatus = useCallback(data => dispatch({type: TOGGLE_BOARD_STATUS.REQUEST, payload: data}), [dispatch]);

    const changePriority = useCallback(
        data => dispatch({type: CHANGE_BOARD_PRIORITY.REQUEST, payload: data}),
        [dispatch],
    );

    const handleChangeStatus = () => {
        changeStatus({
            uuid,
            showAlert,
            callback: () => {
                setIsBoardActive(previous => !previous);
            },
        });
    };

    const onDragEnd = () => {
        changePriority({
            uuid: dragItem,
            priority: dropTo,
            showAlert,
            callback: () => setDragItem(''),
        });
    };

    const Icon = getBoardIconByType(iconType);

    const rowCells = (
        <>
            <TableCell>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <Icon />
                    <Typography sx={{ml: 2}}>{title}</Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Typography>{workflow ? workflow : t('common.none')}</Typography>
            </TableCell>
            <TableCell>
                <Typography>{t(getBoardTypeLabel(type))}</Typography>
            </TableCell>
            <TableCell>
                {statuses &&
                    statuses.map((status: BoardStatusTypes) => {
                        if (status.isActive) {
                            return (
                                <Typography key={`board-${status.uuid}-status-${status.statusType}`} sx={{mb: 0.5}}>
                                    {type === BOARD_TYPE_OVERVIEW && (
                                        <span style={{fontWeight: 600}}>{`${status.workflowTitle} - `}</span>
                                    )}
                                    <span style={{fontWeight: 600, marginRight: 5}}>{status.title}</span>
                                    {!status.isInitial &&
                                        `(${t(
                                            `orguser.workflows.workflowStatusParameters.statusTypes.${String(
                                                status.statusType,
                                            )}`,
                                        )})`}
                                </Typography>
                            );
                        }
                        return null;
                    })}
            </TableCell>
            {isEditEnabled && (
                <>
                    <TableCell>
                        <CustomSwitcher
                            checked={isBoardActive}
                            onClick={handleChangeStatus}
                            disabled={!isEditEnabled}
                        />
                    </TableCell>
                    <TableCell>
                        <EditBoardButton item={item} />
                        <DeleteBoardButton item={item} />
                    </TableCell>
                </>
            )}
        </>
    );

    return isEditEnabled ? (
        <DraggableTableRow
            item={item}
            dragItem={dragItem}
            setDragItem={setDragItem}
            dropTo={dropTo}
            setDropTo={setDropTo}
            onDragEnd={onDragEnd}
        >
            {rowCells}
        </DraggableTableRow>
    ) : (
        <TableRow>{rowCells}</TableRow>
    );
};

export default BoardTableRow;
