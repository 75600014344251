import {RouteContext} from 'contexts/route/context';
import React, {FC, useContext} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';
import {PermissionContext} from 'contexts/permission/context';

import {
    ORG_USERS_LIST,
    WORKFLOWS_LIST,
    INVITES_LIST,
    BOARDS_LIST,
    FORMS_LIST,
    PANELS_LIST,
    DASHBOARDS_LIST,
    PDF_TEMPLATES,
    RESOURCE_FIELDS,
} from 'components/AgentScreenComponents/helper';
import AddUserDropdown from 'components/OrganizationAdminsList/AddUserDropdown';
import AddInvite from 'components/Invites/AddInvite';

import CreateWorkflow from 'pages/admin/workflows/CreateWorkflow';
import AddBoardDropdown from 'pages/admin/boardsList/AddBoardDropdown';
import AddFormDropdown from 'pages/admin/formsList/AddFormDropdown';
import AddDashboardButton from 'pages/admin/analytics/dashboards/AddDashboardButton';
import AddPanelButton from 'pages/admin/analytics/panels/AddPanelButton';

import {isSuperAdminUser} from 'helpers/roles';
import AddPdfTemplateButton from 'pages/admin/organizationPdfTemplates/AddPdfTemplateButton';
import CreateResourceField from 'pages/admin/resourceFields/CreateResourceField';

const AgentPageActions: FC = () => {
    const {id} = useParams();

    const {myOrganization} = useContext(AdminContext);
    const {currentTab} = useContext(RouteContext);
    const {isOrganizationEditEnabled, isUserCreateEnabled, isWorkWithBoardEnabled, isDashboardEditEnabled} =
        useContext(PermissionContext);

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    let actionsJSX: any;

    if (!myOrganization) return null;

    const organizationId = id ? id : myOrganization.id;

    switch (currentTab) {
        case ORG_USERS_LIST:
            actionsJSX = isUserCreateEnabled && <AddUserDropdown organizationId={Number(organizationId)} />;
            break;
        case WORKFLOWS_LIST:
            actionsJSX = myOrganization && isOrganizationEditEnabled && (
                <CreateWorkflow organizationId={myOrganization.id} />
            );
            break;
        case BOARDS_LIST:
            actionsJSX = isWorkWithBoardEnabled && <AddBoardDropdown />;
            break;
        case FORMS_LIST:
            actionsJSX = profile && !isSuperAdminUser(profile) && <AddFormDropdown />;
            break;
        case INVITES_LIST:
            actionsJSX = <AddInvite />;
            break;
        case DASHBOARDS_LIST:
            actionsJSX = isDashboardEditEnabled && <AddDashboardButton />;
            break;
        case PANELS_LIST:
            actionsJSX = isDashboardEditEnabled && <AddPanelButton />;
            break;
        case RESOURCE_FIELDS:
            actionsJSX = myOrganization && isOrganizationEditEnabled && (
                <CreateResourceField organizationId={myOrganization.id} />
            );
            break;
        case PDF_TEMPLATES:
            actionsJSX = myOrganization && isOrganizationEditEnabled && (
                <AddPdfTemplateButton organizationId={myOrganization.id} />
            );
            break;
        default:
            actionsJSX = '';
    }

    return <>{actionsJSX}</>;
};

export default AgentPageActions;
