import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import SendInviteForm from 'components/Forms/Invites/SendInviteForm/SendInviteForm';

import {InviteFormsTypes} from 'appRedux/actions/invites/types';

interface CreateInviteByEmailType {
    forms: InviteFormsTypes[];
    isButton?: boolean;
}

const CreateInviteByEmail: FC<CreateInviteByEmailType> = ({forms, isButton}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <>
            {isButton ? (
                <AgentSaveButton isSubmit={false} title={t('orguser.invites.inviteByEmail')} onClick={toggleIsOpened} />
            ) : (
                <Typography
                    sx={{
                        cursor: 'pointer',
                        fontWeight: 600,
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    }}
                    onClick={toggleIsOpened}
                >
                    {t('orguser.invites.inviteByEmail')}
                </Typography>
            )}
            <ModalWrapper
                title={t('orguser.invites.createInvite')}
                isShowModal={isOpened}
                toggleModal={() => setIsOpened(false)}
                isWideModal={false}
            >
                <SendInviteForm forms={forms} setIsOpened={setIsOpened} />
            </ModalWrapper>
        </>
    );
};

export default CreateInviteByEmail;
