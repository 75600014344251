import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';

import Typography from '@mui/material/Typography';

import {LOGIN_REDIRECT} from 'appRedux/actions/auth';
import {GET_USER_INFO} from 'appRedux/actions/profile';

import {AlertContext} from 'contexts/alert/context';
import {MediaContext} from 'contexts/media/context';

import EmptyArea from 'components/EmptyArea';

import {getRedirectRoute, LOGIN_FIRST_STEP, LOGIN_SECOND_STEP} from 'pages/start/login/helper';
import LoginSecondStep from 'pages/start/login/partials/LoginSecondStep';
import LoginPageWrapper from 'pages/start/login/partials/LoginPageWrapper';

import {
    LOCAL_STORAGE_LOGOUT_TIME,
    LOCAL_STORAGE_PERSIST_ROOT,
    LOCAL_STORAGE_REDIRECT_AFTER_LOGIN,
    LOCAL_STORAGE_REFRESH_TOKEN,
    LOCAL_STORAGE_TOKEN,
} from 'services/localStorage';

const LoginRedirect: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {hash} = useLocation();

    const {showAlert} = useContext(AlertContext);
    const {setLanguageAfterLogin} = useContext(MediaContext);

    const [loginStep, setLoginStep] = useState<string>(LOGIN_FIRST_STEP);
    const [email, setEmail] = useState<string>('');

    const loginRedirectRequest = useCallback(
        data => dispatch({type: LOGIN_REDIRECT.REQUEST, payload: data}),
        [dispatch],
    );

    const getUserInfo = useCallback(data => dispatch({type: GET_USER_INFO.REQUEST, payload: data}), [dispatch]);

    const navigationCallback = () => {
        getUserInfo({
            callback: (roles: string[], organizationMultiRoleType: number, languageId: number, defaultMode: number) => {
                setLanguageAfterLogin(languageId);
                const redirectAfterLogin = localStorage.getItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN);
                if (redirectAfterLogin) {
                    navigate(redirectAfterLogin);
                    localStorage.removeItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN);
                } else {
                    const redirectRoute = getRedirectRoute(roles, organizationMultiRoleType, defaultMode);
                    navigate(redirectRoute, {replace: true});
                }
            },
        });
    };

    const showTwoFactorForm = (email: string) => {
        setLoginStep(LOGIN_SECOND_STEP);
        setEmail(email);
    };

    useEffect(() => {
        localStorage.removeItem(LOCAL_STORAGE_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_PERSIST_ROOT);
        localStorage.removeItem(LOCAL_STORAGE_LOGOUT_TIME);
        loginRedirectRequest({
            token: String(hash).replace('#', ''),
            showAlert,
            callback: navigationCallback,
            callbackTwoFactor: showTwoFactorForm,
        });
    }, []);

    return (
        <LoginPageWrapper>
            {loginStep === LOGIN_FIRST_STEP && <Typography>{t('common.loginForm.loginRedirectMessage')}</Typography>}
            {loginStep === LOGIN_SECOND_STEP && <LoginSecondStep email={email} />}
            <EmptyArea />
        </LoginPageWrapper>
    );
};

export default LoginRedirect;
