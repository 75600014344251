import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';
import {get} from 'lodash';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {
    GET_WORKFLOWS_STATUS_EVENTS_LIST,
    REFRESH_CURRENT_WORKFLOW_STATUS,
    GET_CURRENT_WORKFLOW_STATUS,
    GET_WORKFLOWS_STATUS_LIST,
} from 'appRedux/actions/workflow';
import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';
import {PermissionContext} from 'contexts/permission/context';
import {RouteContext} from 'contexts/route/context';
import {AlertContext} from 'contexts/alert/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

import WorkflowStatusEventsTable from 'pages/admin/updateWorkflowEvents/WorkflowStatusEventsTable';
import WorkflowStatusSelect from 'pages/admin/updateWorkflowEvents/WorkflowStatusSelect';

import {getLanguageById} from 'helpers/translationsHelper';

import {PARAMETER_LANGUAGE} from 'config/index';

const UpdateWorkflowStatusEventsComponent: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {uuid} = useParams();
    const [searchParams] = useSearchParams();

    const {showAlert} = useContext(AlertContext);
    const {myOrganization} = useContext(AdminContext);
    const {isOrganizationEditEnabled} = useContext(PermissionContext);
    const {setBackLink, setBackLinkLabel, setTopBarTitle, setTopBarSubtitle} = useContext(RouteContext);

    const {
        admin: {languageList},
        workflow: {workflowStatusEvents, currentWorkflowStatus},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getCurrentWorkflowStatusInfo = useCallback(
        data => dispatch({type: GET_CURRENT_WORKFLOW_STATUS.REQUEST, payload: data}),
        [dispatch],
    );

    const refreshCurrentWorkflowStatusInfo = useCallback(
        () => dispatch({type: REFRESH_CURRENT_WORKFLOW_STATUS.REQUEST}),
        [dispatch],
    );

    const getWorkflowStatusEvents = useCallback(
        data => dispatch({type: GET_WORKFLOWS_STATUS_EVENTS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const getWorkflowStatuses = useCallback(
        data => dispatch({type: GET_WORKFLOWS_STATUS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        getCurrentWorkflowStatusInfo({
            uuid,
            showAlert,
        });
        getWorkflowStatusEvents({
            uuid,
            showAlert,
        });
    }, [uuid]);

    useEffect(() => {
        currentWorkflowStatus?.workflowUuid &&
            getWorkflowStatuses({
                uuid: currentWorkflowStatus.workflowUuid,
                showAlert,
            });
    }, [currentWorkflowStatus?.workflowUuid]);

    useEffect(() => {
        setBackLink(null);
        setBackLinkLabel(t('common.links.backToWorkflowStatuses'));
        setTopBarTitle('');
        setTopBarSubtitle('');
    }, []);

    useEffect(() => {
        return () => {
            refreshCurrentWorkflowStatusInfo();
        };
    }, []);

    if (!isOrganizationEditEnabled || !currentWorkflowStatus || !myOrganization) {
        return null;
    }

    const currentOrganizationLanguage = getLanguageById(languageList, myOrganization.language);
    const currentLanguage = searchParams.get(PARAMETER_LANGUAGE)
        ? searchParams.get(PARAMETER_LANGUAGE)
        : currentOrganizationLanguage.name;

    const statusTitle =
        (currentLanguage && get(currentWorkflowStatus.translations, [currentLanguage, 'translation'], null)) ||
        currentWorkflowStatus.title;

    return (
        <ClientSectionWrapper>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                }}
            >
                <Box sx={{mb: 3, minWidth: '50%'}}>
                    <Grid container sx={{mb: 1, alignItems: 'center'}}>
                        <Grid item sm={3}>
                            <Typography sx={{fontWeight: 600}}>
                                {t('orguser.workflows.workflowStatusParameters.status')}
                            </Typography>
                        </Grid>
                        <Grid item sm={9}>
                            {currentLanguage && (
                                <WorkflowStatusSelect
                                    currentWorkflowStatus={currentWorkflowStatus}
                                    currentLanguage={currentLanguage}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <WorkflowStatusEventsTable
                items={workflowStatusEvents}
                statusTitle={statusTitle}
                currentLanguage={String(currentLanguage)}
            />
        </ClientSectionWrapper>
    );
};

export default UpdateWorkflowStatusEventsComponent;
