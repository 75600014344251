import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import {RecoveryPasswordRequestTypes} from 'appRedux/actions/auth/types';
import {RECOVERY_PASSWORD} from 'appRedux/actions/auth';

import {AlertContext} from 'contexts/alert/context';

import ClientFormikTextInput from 'components/StartScreenComponents/ClientFormTextInput';
import LoadingStartButton from 'components/StartScreenComponents/LoadingStartButton';

import {initialValues, validationSchema} from 'pages/start/recoveryPassword/validation';

import {preventSendForm} from 'helpers/index';

import {routes} from 'config/index';

interface RecoveryPasswordFormType {
    isRecovery?: boolean;
}

const RecoveryPasswordForm: FC<RecoveryPasswordFormType> = ({isRecovery}) => {
    const [t] = useTranslation();
    const schema = () => validationSchema(t);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {hash} = useLocation();

    const {showAlert} = useContext(AlertContext);

    const onSubmitAction = useCallback(data => dispatch({type: RECOVERY_PASSWORD.REQUEST, payload: data}), [dispatch]);

    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);

    const toggleShowNewPassword = () => {
        setShowNewPassword(previous => !previous);
    };

    const toggleShowRepeatPassword = () => {
        setShowRepeatPassword(previous => !previous);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const redirectToLogin = () => {
        const redirect = setTimeout(() => {
            navigate(routes.START);
        }, 500);
        return () => clearTimeout(redirect);
    };

    return (
        <Box sx={{width: '100%'}}>
            <Typography align={'center'} sx={{mb: 2, fontWeight: 600}}>
                {t('common.recoveryPassword.createNewPassword')}
            </Typography>
            <Formik
                initialValues={{
                    ...initialValues,
                    token: String(hash).replace('#', ''),
                }}
                validationSchema={schema}
                onSubmit={(values: RecoveryPasswordRequestTypes, {setErrors}) => {
                    onSubmitAction({
                        ...values,
                        setErrors,
                        showAlert,
                        callback: redirectToLogin,
                    });
                }}
            >
                {(formik: FormikProps<RecoveryPasswordRequestTypes>) => {
                    return (
                        <Form onKeyDown={preventSendForm}>
                            {isRecovery && (
                                <Alert icon={false} severity="info">
                                    <Typography sx={{fontSize: 12}}>
                                        {t('common.recoveryPassword.allSessionInvalidated')}
                                    </Typography>
                                </Alert>
                            )}
                            <Field
                                type={showNewPassword ? 'text' : 'password'}
                                name="plainPassword.first"
                                label={t('common.recoveryPassword.newPassword')}
                                placeholder={t('common.recoveryPassword.newPassword')}
                                component={ClientFormikTextInput}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={toggleShowNewPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Field
                                type={showRepeatPassword ? 'text' : 'password'}
                                name="plainPassword.second"
                                label={t('common.recoveryPassword.repeatPassword')}
                                placeholder={t('common.recoveryPassword.repeatPassword')}
                                component={ClientFormikTextInput}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={toggleShowRepeatPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 4,
                                }}
                            >
                                <LoadingStartButton
                                    label={t(
                                        isRecovery
                                            ? 'common.recoveryPassword.recover'
                                            : 'common.recoveryPassword.setPassword',
                                    )}
                                />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default RecoveryPasswordForm;
