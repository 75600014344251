import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import SearchIcon from '@mui/icons-material/Search';

import CancelSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {PARAMETER_SEARCH} from 'config/index';

import {MINIMAL_SEARCH_LENGTH} from 'helpers/search';

const Search: FC = () => {
    const [t] = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const currentSearchValue = searchParams.get(PARAMETER_SEARCH);

    const [value, setValue] = useState<string>(currentSearchValue ?? '');

    const onIconClicked = () => {
        if (currentSearchValue) {
            setValue('');
            searchParams.set(PARAMETER_SEARCH, '');
            setSearchParams(searchParams);
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    useEffect(() => {
        if (!currentSearchValue) {
            setValue('');
        }
    }, [currentSearchValue]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (value) {
                searchParams.set(PARAMETER_SEARCH, value);
                setSearchParams(searchParams);
            } else {
                searchParams.delete(PARAMETER_SEARCH);
                setSearchParams(searchParams);
            }
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [value]);

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2}}>
            <FormControl fullWidth variant="standard">
                <Input
                    fullWidth
                    inputProps={{className: 'search'}}
                    placeholder={t('common.searchFieldPlaceholder')}
                    onChange={handleSearchChange}
                    value={value}
                    endAdornment={
                        <InputAdornment position="start" onClick={onIconClicked} sx={{cursor: 'pointer'}}>
                            {currentSearchValue && currentSearchValue.length >= MINIMAL_SEARCH_LENGTH ? (
                                <CancelSvgIcon />
                            ) : (
                                <SearchIcon />
                            )}
                        </InputAdornment>
                    }
                />
            </FormControl>
        </Box>
    );
};

export default Search;
