export const RESOURCE_FIELD_TYPE_CALENDAR = 0;
export const RESOURCE_FIELD_TYPE_SLOT_TICKET = 1;
export const RESOURCE_FIELD_TYPE_BUDGET = 2;

export interface ResourceFieldOptionType {
    label: string;
    value: number;
}

export const resourceFieldTypes: ResourceFieldOptionType[] = [
    {
        label: 'orguser.resourceFields.types.calendar',
        value: RESOURCE_FIELD_TYPE_CALENDAR,
    },
    {
        label: 'orguser.resourceFields.types.slot',
        value: RESOURCE_FIELD_TYPE_SLOT_TICKET,
    },
    {
        label: 'orguser.resourceFields.types.budget',
        value: RESOURCE_FIELD_TYPE_BUDGET,
    },
];

export const getResourceFieldTypeLabel = (type: number): string => {
    const currentResult = resourceFieldTypes.find(item => item.value === type);
    return currentResult ? currentResult.label : 'common.none';
};

export const RESOURCE_FIELD_SCOPE_GLOBAL = 0;
export const RESOURCE_FIELD_SCOPE_FORM = 1;
export const RESOURCE_FIELD_SCOPE_USER = 2;

export const resourceFieldScopeTypes: ResourceFieldOptionType[] = [
    {
        label: 'orguser.resourceFields.scopeTypes.global',
        value: RESOURCE_FIELD_SCOPE_GLOBAL,
    },
    {
        label: 'orguser.resourceFields.scopeTypes.form',
        value: RESOURCE_FIELD_SCOPE_FORM,
    },
    {
        label: 'orguser.resourceFields.scopeTypes.user',
        value: RESOURCE_FIELD_SCOPE_USER,
    },
];

export const getResourceFieldScopeTypeLabel = (type: number): string => {
    const currentResult = resourceFieldScopeTypes.find(item => item.value === type);
    return currentResult ? currentResult.label : 'common.none';
};

export const isImpossibleForSelectedScopeType = (typeScope: number, type: number): boolean => {
    if (typeScope === RESOURCE_FIELD_SCOPE_FORM && type === RESOURCE_FIELD_TYPE_SLOT_TICKET) return true;
    if (
        typeScope === RESOURCE_FIELD_SCOPE_USER &&
        (type === RESOURCE_FIELD_TYPE_CALENDAR || type === RESOURCE_FIELD_TYPE_SLOT_TICKET)
    )
        return true;
    return false;
};

export const RESOURCE_FIELD_BUDGET_TYPE_MONEY = 0;
export const RESOURCE_FIELD_BUDGET_TYPE_DAYS = 1;

export const resourceFieldBudgetTypes: ResourceFieldOptionType[] = [
    {
        label: 'orguser.resourceFields.budgetTypes.money',
        value: RESOURCE_FIELD_BUDGET_TYPE_MONEY,
    },
    {
        label: 'orguser.resourceFields.budgetTypes.days',
        value: RESOURCE_FIELD_BUDGET_TYPE_DAYS,
    },
];

export const getResourceFieldBudgetTypeLabel = (type: number): string => {
    const currentResult = resourceFieldBudgetTypes.find(item => item.value === type);
    return currentResult ? currentResult.label : 'common.none';
};

export const RESOURCE_FIELD_BUDGET_RESET_INTERVAL_WEEK = 0;
export const RESOURCE_FIELD_BUDGET_RESET_INTERVAL_MONTH = 1;
export const RESOURCE_FIELD_BUDGET_RESET_INTERVAL_QUATER = 2;
export const RESOURCE_FIELD_BUDGET_RESET_INTERVAL_HALF_YEAR = 3;
export const RESOURCE_FIELD_BUDGET_RESET_INTERVAL_YEAR = 4;

export const resourceFieldBudgetResetIntervalTypes: ResourceFieldOptionType[] = [
    {
        label: 'orguser.resourceFields.resetIntervalTypes.week',
        value: RESOURCE_FIELD_BUDGET_RESET_INTERVAL_WEEK,
    },
    {
        label: 'orguser.resourceFields.resetIntervalTypes.month',
        value: RESOURCE_FIELD_BUDGET_RESET_INTERVAL_MONTH,
    },
    {
        label: 'orguser.resourceFields.resetIntervalTypes.quater',
        value: RESOURCE_FIELD_BUDGET_RESET_INTERVAL_QUATER,
    },
    {
        label: 'orguser.resourceFields.resetIntervalTypes.halfYear',
        value: RESOURCE_FIELD_BUDGET_RESET_INTERVAL_HALF_YEAR,
    },
    {
        label: 'orguser.resourceFields.resetIntervalTypes.year',
        value: RESOURCE_FIELD_BUDGET_RESET_INTERVAL_YEAR,
    },
];

export const getResourceFieldBudgetResetIntervalTypeLabel = (type: number): string => {
    const currentResult = resourceFieldBudgetResetIntervalTypes.find(item => item.value === type);
    return currentResult ? currentResult.label : 'common.none';
};

export const BOOKFRAME_WEEKDAY_SUNDAY = 0;
export const BOOKFRAME_WEEKDAY_MONDAY = 1;
export const BOOKFRAME_WEEKDAY_TUESDAY = 2;
export const BOOKFRAME_WEEKDAY_WEDNESDAY = 3;
export const BOOKFRAME_WEEKDAY_THURSDAY = 4;
export const BOOKFRAME_WEEKDAY_FRIDAY = 5;
export const BOOKFRAME_WEEKDAY_SATURDAY = 6;

export const resourceFieldWeekdayTypes: ResourceFieldOptionType[] = [
    {
        label: 'common.weekdays.sunday',
        value: BOOKFRAME_WEEKDAY_SUNDAY,
    },
    {
        label: 'common.weekdays.monday',
        value: BOOKFRAME_WEEKDAY_MONDAY,
    },
    {
        label: 'common.weekdays.tuesday',
        value: BOOKFRAME_WEEKDAY_TUESDAY,
    },
    {
        label: 'common.weekdays.wednesday',
        value: BOOKFRAME_WEEKDAY_WEDNESDAY,
    },
    {
        label: 'common.weekdays.thursday',
        value: BOOKFRAME_WEEKDAY_THURSDAY,
    },
    {
        label: 'common.weekdays.friday',
        value: BOOKFRAME_WEEKDAY_FRIDAY,
    },
    {
        label: 'common.weekdays.saturday',
        value: BOOKFRAME_WEEKDAY_SATURDAY,
    },
];

export const getResourceFieldWeekdayLabel = (type: number): string => {
    const currentResult = resourceFieldWeekdayTypes.find(item => item.value === type);
    return currentResult ? currentResult.label : 'common.none';
};

export const CALENDAR_EXCEPTION_CHANGED_TIMES = 0;
export const CALENDAR_EXCEPTION_BLOCKED = 1;

export const resourceFieldCalendarExceptionTypes: ResourceFieldOptionType[] = [
    {
        label: 'orguser.resourceFields.exceptionTypes.changedTimes',
        value: CALENDAR_EXCEPTION_CHANGED_TIMES,
    },
    {
        label: 'orguser.resourceFields.exceptionTypes.blocked',
        value: CALENDAR_EXCEPTION_BLOCKED,
    },
];

export const getCalendarExceptionLabel = (type: number): string => {
    const currentResult = resourceFieldCalendarExceptionTypes.find(item => item.value === type);
    return currentResult ? currentResult.label : 'common.none';
};
