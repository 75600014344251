import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const ListSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 3H14V4H5V3ZM5 12H14V13H5V12ZM14 7.5H5V8.5H14V7.5ZM2 7.5H3V8.5H2V7.5ZM3 3H2V4H3V3ZM2 12H3V13H2V12Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default ListSvgIcon;
