import React, {FC, ReactNode, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import DomainSvgIcon from 'assets/icons/buttons/DomainSvgIcon';

import OrganizationChangeDomainForm from 'components/Forms/OrganizationChangeDomainForm/OrganizationChangeDomainForm';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

interface ChangeDomainNameButtonType {
    id: number;
    domainName: string;
    isTable?: boolean;
    children?: ReactNode;
}

const ChangeDomainNameButton: FC<ChangeDomainNameButtonType> = ({id, domainName, isTable, children}) => {
    const [t] = useTranslation();

    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    return (
        <>
            {isTable ? (
                <IconButton title={t('superadmin.organizations.changeDomain')} onClick={toggleModal}>
                    {children ? children : <DomainSvgIcon />}
                </IconButton>
            ) : (
                <AgentSaveButton
                    isLoading={false}
                    isSubmit={false}
                    title={t('superadmin.organizations.changeDomain')}
                    onClick={toggleModal}
                />
            )}
            <ModalWrapper
                isShowModal={isShowModal}
                toggleModal={toggleModal}
                title={t('superadmin.organizations.changeDomain')}
            >
                <Alert severity="warning" sx={{mb: 2}}>
                    <Typography>{t('superadmin.organizations.changeDomainExplanation')}</Typography>
                </Alert>
                <OrganizationChangeDomainForm domainName={domainName ?? ''} id={id} toggleModal={toggleModal} />
            </ModalWrapper>
        </>
    );
};

export default ChangeDomainNameButton;
