import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import {OrganizationPrivacyPolicyItemTypes} from 'appRedux/actions/organization/types';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import CreateOrganizationPrivacyPolicyForm from 'components/Forms/OrganizationPrivacyPolicyForm/CreateOrganizationPrivacyPoliceVersionForm';

import {theme} from 'config/theme';

interface AddPrivacyPolicyVersionButtonType {
    item: OrganizationPrivacyPolicyItemTypes;
    organizationLanguage: LanguageItemTypes;
}

const AddPrivacyPolicyVersionButton: FC<AddPrivacyPolicyVersionButtonType> = ({item, organizationLanguage}) => {
    const [t] = useTranslation();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    return (
        <>
            <IconButton
                title={t('superadmin.organizations.privacyPolicy.addVersion')}
                onClick={() => setShowModal(true)}
                sx={{
                    backgroundColor: theme.palette.info.main,
                    '&:hover': {
                        backgroundColor: theme.palette.info.main,
                    },
                    mr: 1,
                }}
            >
                <PlusSvgIcon isActive />
            </IconButton>
            <ModalWrapper
                isShowModal={showModal}
                isWideModal
                title={t('superadmin.organizations.privacyPolicy.addVersion')}
                toggleModal={toggleModal}
            >
                <CreateOrganizationPrivacyPolicyForm
                    item={item}
                    closeModal={() => setShowModal(false)}
                    organizationLanguage={organizationLanguage}
                />
            </ModalWrapper>
        </>
    );
};

export default AddPrivacyPolicyVersionButton;
