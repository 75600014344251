import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const DownloadSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 11H11.75C12.5518 10.995 13.3234 10.6937 13.9165 10.1541C14.5096 9.6145 14.8823 8.87472 14.9629 8.07697C15.0436 7.27922 14.8265 6.47982 14.3534 5.83246C13.8803 5.1851 13.1846 4.73547 12.4 4.57003C12.1842 3.56329 11.6296 2.66102 10.8288 2.01376C10.0281 1.36651 9.02963 1.01343 8.00001 1.01343C6.97039 1.01343 5.9719 1.36651 5.17116 2.01376C4.37042 2.66102 3.81586 3.56329 3.60001 4.57003C2.81545 4.73547 2.11974 5.1851 1.64663 5.83246C1.17353 6.47982 0.956428 7.27922 1.03707 8.07697C1.11771 8.87472 1.49041 9.6145 2.0835 10.1541C2.67659 10.6937 3.44821 10.995 4.25001 11H4.50001V10H4.25001C3.65327 10.0239 3.07149 9.80974 2.63266 9.40466C2.19382 8.99958 1.93388 8.43677 1.91001 7.84003C1.88614 7.24329 2.1003 6.66151 2.50538 6.22268C2.91045 5.78384 3.47327 5.5239 4.07001 5.50003H4.50001L4.51001 5.09003C4.62093 4.24825 5.03407 3.4755 5.67246 2.91572C6.31085 2.35594 7.13095 2.0473 7.98001 2.0473C8.82906 2.0473 9.64917 2.35594 10.2876 2.91572C10.9259 3.4755 11.3391 4.24825 11.45 5.09003L11.5 5.50003H11.93C12.5267 5.5239 13.0896 5.78384 13.4946 6.22268C13.8997 6.66151 14.1139 7.24329 14.09 7.84003C14.0661 8.43677 13.8062 8.99958 13.3674 9.40466C12.9285 9.80974 12.3467 10.0239 11.75 10H11.5V11ZM8.5 7.00003V13.085L9.795 11.795L10.5 12.5L8 15L5.5 12.5L6.205 11.795L7.5 13.085V7.00003H8.5Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default DownloadSvgIcon;
