import {fork, put, take, call} from 'redux-saga/effects';

import {
    GET_RESOURCE_FIELDS_LIST,
    getResourceFieldsList,
    GET_RESOURCE_FIELDS_WORKFLOW,
    getResourceFieldsListByWorkflow,
    CREATE_RESOURCE_FIELD,
    createResourceField,
    UPDATE_RESOURCE_FIELD,
    updateResourceField,
    DELETE_RESOURCE_FIELD,
    deleteResourceField,
    GET_RESOURCE_FIELD_BUDGET_LIST,
    getResourceFieldBudgetList,
    GET_RESOURCE_FIELD_BUDGET_INFO,
    getResourceFieldBudgetInfo,
    GET_RESOURCE_FIELD_BUDGET_CASES,
    getResourceFieldBudgetCases,
    CREATE_RESOURCE_FIELD_BUDGET,
    createResourceFieldBudget,
    UPDATE_RESOURCE_FIELD_BUDGET,
    updateResourceFieldBudget,
    DELETE_RESOURCE_FIELD_BUDGET,
    deleteResourceFieldBudget,
    GET_RESOURCE_FIELD_BUDGET_ORGANIZATION,
    getResourceFieldBudgetOrganizationList,
    GET_RESOURCE_FIELD_SLOT_ORGANIZATION,
    getResourceFieldSlotOrganizationList,
    GET_RESOURCE_FIELD_SLOT_LIST,
    getResourceFieldSlotList,
    GET_RESOURCE_FIELD_SLOT_INFO,
    getResourceFieldSlotInfo,
    GET_RESOURCE_FIELD_SLOT_CASES,
    getResourceFieldSlotCases,
    CREATE_RESOURCE_FIELD_SLOT,
    createResourceFieldSlot,
    UPDATE_RESOURCE_FIELD_SLOT,
    updateResourceFieldSlot,
    DELETE_RESOURCE_FIELD_SLOT,
    deleteResourceFieldSlot,
    GET_RESOURCE_FIELD_CALENDAR_LIST,
    getResourceFieldCalendarList,
    GET_RESOURCE_FIELD_CALENDAR_INFO,
    getResourceFieldCalendarInfo,
    CREATE_RESOURCE_FIELD_CALENDAR,
    createResourceFieldCalendar,
    UPDATE_RESOURCE_FIELD_CALENDAR,
    updateResourceFieldCalendar,
    DELETE_RESOURCE_FIELD_CALENDAR,
    deleteResourceFieldCalendar,
    CREATE_RESOURCE_FIELD_BOOKFRAME,
    createResourceFieldBookframe,
    UPDATE_RESOURCE_FIELD_BOOKFRAME,
    updateResourceFieldBookframe,
    DELETE_RESOURCE_FIELD_BOOKFRAME,
    deleteResourceFieldBookframe,
    CREATE_RESOURCE_FIELD_EXCEPTION,
    createResourceFieldException,
    UPDATE_RESOURCE_FIELD_EXCEPTION,
    updateResourceFieldException,
    DELETE_RESOURCE_FIELD_EXCEPTION,
    deleteResourceFieldException,
    CREATE_RESOURCE_FIELD_CALENDAR_SLOT,
    createResourceFieldCalendarSlot,
    UPDATE_RESOURCE_FIELD_CALENDAR_SLOT,
    updateResourceFieldCalendarSlot,
    DELETE_RESOURCE_FIELD_CALENDAR_SLOT,
    deleteResourceFieldCalendarSlot,
} from 'appRedux/actions/resourceFields';
import {
    ResourceFieldsListRequestTypes,
    ResourceFieldCreateRequestTypes,
    ResourceFieldUpdateRequestTypes,
    ResourceFieldDeleteRequestTypes,
    ResourceFieldsResponseTypes,
    ResourceFieldBudgetListRequestTypes,
    ResourceFieldBudgetInfoRequestTypes,
    ResourceFieldBudgetCasesRequestTypes,
    ResourceFieldBudgetCreateRequestTypes,
    ResourceFieldBudgetUpdateRequestTypes,
    ResourceFieldBudgetDeleteRequestTypes,
    ResourceFieldBudgetResponseTypes,
    ResourceFieldBudgetCasesResponseTypes,
    ResourceFieldBudgetInfoResponseTypes,
    ResourceFieldBudgetOrganizationRequestTypes,
    ResourceFieldSlotOrganizationRequestTypes,
    ResourceFieldSlotListRequestTypes,
    ResourceFieldSlotInfoRequestTypes,
    ResourceFieldSlotCasesRequestTypes,
    ResourceFieldSlotCreateRequestTypes,
    ResourceFieldSlotUpdateRequestTypes,
    ResourceFieldSlotDeleteRequestTypes,
    ResourceFieldSlotResponseTypes,
    ResourceFieldSlotInfoResponseTypes,
    ResourceFieldSlotCasesResponseTypes,
    ResourceFieldCalendarListRequestTypes,
    ResourceFieldCalendarInfoRequestTypes,
    ResourceFieldCalendarCreateRequestTypes,
    ResourceFieldCalendarUpdateRequestTypes,
    ResourceFieldCalendarDeleteRequestTypes,
    ResourceFieldCalendarResponseTypes,
    ResourceFieldCalendarInfoResponseTypes,
    ResourceFieldBookframeCreateRequestTypes,
    ResourceFieldBookframeUpdateRequestTypes,
    ResourceFieldBookframeDeleteRequestTypes,
    ResourceFieldExceptionCreateRequestTypes,
    ResourceFieldExceptionUpdateRequestTypes,
    ResourceFieldExceptionDeleteRequestTypes,
    ResourceFieldCalendarSlotCreateRequestTypes,
    ResourceFieldCalendarSlotUpdateRequestTypes,
    ResourceFieldCalendarSlotDeleteRequestTypes,
} from 'appRedux/actions/resourceFields/types';

import {http} from 'services/http';

import {getRouteWithParameters} from 'helpers/sagasHelper';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchResourceFieldsGetting() {
    while (true) {
        const {
            payload: {showAlert, callback, id},
        }: IActionType<ResourceFieldsListRequestTypes> = yield take(GET_RESOURCE_FIELDS_LIST.REQUEST);
        try {
            const data: ResourceFieldsResponseTypes = yield call(http, `resource/field/${id}/list`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getResourceFieldsList.success(data.results));
                callback && callback();
            } else {
                yield put(getResourceFieldsList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getResourceFieldsList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldsByWorkflowGetting() {
    while (true) {
        const {
            payload: {showAlert, callback, id},
        }: IActionType<ResourceFieldsListRequestTypes> = yield take(GET_RESOURCE_FIELDS_WORKFLOW.REQUEST);
        try {
            const data: ResourceFieldsResponseTypes = yield call(http, `resource/field/${id}/workflow`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getResourceFieldsListByWorkflow.success(data.results));
                callback && callback();
            } else {
                yield put(getResourceFieldsListByWorkflow.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getResourceFieldsListByWorkflow.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldCreation() {
    while (true) {
        const {
            payload: {showAlert, callback, id, ...values},
        }: IActionType<ResourceFieldCreateRequestTypes> = yield take(CREATE_RESOURCE_FIELD.REQUEST);
        try {
            const data: ResourceFieldsResponseTypes = yield call(http, `resource/field/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createResourceField.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createResourceField.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createResourceField.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldUpdating() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<ResourceFieldUpdateRequestTypes> = yield take(UPDATE_RESOURCE_FIELD.REQUEST);
        try {
            const data: ResourceFieldsResponseTypes = yield call(http, `resource/field/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateResourceField.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateResourceField.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateResourceField.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldDeleting() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid},
        }: IActionType<ResourceFieldDeleteRequestTypes> = yield take(DELETE_RESOURCE_FIELD.REQUEST);
        try {
            const data: ResourceFieldsResponseTypes = yield call(http, `resource/field/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteResourceField.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteResourceField.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(deleteResourceField.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldBudgetListGetting() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid},
        }: IActionType<ResourceFieldBudgetListRequestTypes> = yield take(GET_RESOURCE_FIELD_BUDGET_LIST.REQUEST);
        try {
            const data: ResourceFieldBudgetResponseTypes = yield call(http, `resource/budget/${uuid}/list`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getResourceFieldBudgetList.success(data.results));
                callback && callback();
            } else {
                yield put(getResourceFieldBudgetList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getResourceFieldBudgetList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldBudgetInfoGetting() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid},
        }: IActionType<ResourceFieldBudgetInfoRequestTypes> = yield take(GET_RESOURCE_FIELD_BUDGET_INFO.REQUEST);
        try {
            const data: ResourceFieldBudgetInfoResponseTypes = yield call(http, `resource/budget/${uuid}/info`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getResourceFieldBudgetInfo.success(data.result));
                callback && callback();
            } else {
                yield put(getResourceFieldBudgetInfo.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getResourceFieldBudgetInfo.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldBudgetCasesGetting() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, page, perPage},
        }: IActionType<ResourceFieldBudgetCasesRequestTypes> = yield take(GET_RESOURCE_FIELD_BUDGET_CASES.REQUEST);
        try {
            const route = getRouteWithParameters(`resource/budget/${uuid}/cases`, {
                page: page,
                per_page: perPage,
            });

            const data: ResourceFieldBudgetCasesResponseTypes = yield call(http, route, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getResourceFieldBudgetCases.success(data.results));
                callback && callback();
            } else {
                yield put(getResourceFieldBudgetCases.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getResourceFieldBudgetCases.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldBudgetCreation() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<ResourceFieldBudgetCreateRequestTypes> = yield take(CREATE_RESOURCE_FIELD_BUDGET.REQUEST);
        try {
            const data: ResourceFieldBudgetResponseTypes = yield call(http, `resource/budget/${uuid}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createResourceFieldBudget.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createResourceFieldBudget.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createResourceFieldBudget.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldBudgetUpdating() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<ResourceFieldBudgetUpdateRequestTypes> = yield take(UPDATE_RESOURCE_FIELD_BUDGET.REQUEST);
        try {
            const data: ResourceFieldBudgetResponseTypes = yield call(http, `resource/budget/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateResourceFieldBudget.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateResourceFieldBudget.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateResourceFieldBudget.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldBudgetDeleting() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid},
        }: IActionType<ResourceFieldBudgetDeleteRequestTypes> = yield take(DELETE_RESOURCE_FIELD_BUDGET.REQUEST);
        try {
            const data: ResourceFieldBudgetResponseTypes = yield call(http, `resource/budget/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteResourceFieldBudget.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteResourceFieldBudget.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteResourceFieldBudget.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldBudgetOrganizationListGetting() {
    while (true) {
        const {
            payload: {showAlert, id, callback},
        }: IActionType<ResourceFieldBudgetOrganizationRequestTypes> = yield take(
            GET_RESOURCE_FIELD_BUDGET_ORGANIZATION.REQUEST,
        );
        try {
            const data: ResourceFieldBudgetResponseTypes = yield call(http, `resource/budget/${id}/organization`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getResourceFieldBudgetOrganizationList.success(data.results));
                callback && callback();
            } else {
                yield put(getResourceFieldBudgetOrganizationList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getResourceFieldBudgetOrganizationList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldSlotOrganizationListGetting() {
    while (true) {
        const {
            payload: {showAlert, id, callback},
        }: IActionType<ResourceFieldSlotOrganizationRequestTypes> = yield take(
            GET_RESOURCE_FIELD_SLOT_ORGANIZATION.REQUEST,
        );
        try {
            const data: ResourceFieldSlotResponseTypes = yield call(http, `resource/slot/${id}/organization`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getResourceFieldSlotOrganizationList.success(data.results));
                callback && callback();
            } else {
                yield put(getResourceFieldSlotOrganizationList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getResourceFieldSlotOrganizationList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldSlotListGetting() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid},
        }: IActionType<ResourceFieldSlotListRequestTypes> = yield take(GET_RESOURCE_FIELD_SLOT_LIST.REQUEST);
        try {
            const data: ResourceFieldSlotResponseTypes = yield call(http, `resource/slot/${uuid}/list`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getResourceFieldSlotList.success(data.results));
                callback && callback();
            } else {
                yield put(getResourceFieldSlotList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getResourceFieldSlotList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldSlotInfoGetting() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid},
        }: IActionType<ResourceFieldSlotInfoRequestTypes> = yield take(GET_RESOURCE_FIELD_SLOT_INFO.REQUEST);
        try {
            const data: ResourceFieldSlotInfoResponseTypes = yield call(http, `resource/slot/${uuid}/info`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getResourceFieldSlotInfo.success(data.result));
                callback && callback();
            } else {
                yield put(getResourceFieldSlotInfo.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getResourceFieldSlotInfo.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldSlotCasesGetting() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, page, perPage},
        }: IActionType<ResourceFieldSlotCasesRequestTypes> = yield take(GET_RESOURCE_FIELD_SLOT_CASES.REQUEST);
        try {
            const route = getRouteWithParameters(`resource/slot/${uuid}/cases`, {
                page: page,
                per_page: perPage,
            });

            const data: ResourceFieldSlotCasesResponseTypes = yield call(http, route, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getResourceFieldSlotCases.success(data.results));
                callback && callback();
            } else {
                yield put(getResourceFieldSlotCases.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getResourceFieldSlotCases.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldSlotCreation() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<ResourceFieldSlotCreateRequestTypes> = yield take(CREATE_RESOURCE_FIELD_SLOT.REQUEST);
        try {
            const data: ResourceFieldSlotResponseTypes = yield call(http, `resource/slot/${uuid}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createResourceFieldSlot.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createResourceFieldSlot.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createResourceFieldSlot.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldSlotUpdating() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<ResourceFieldSlotUpdateRequestTypes> = yield take(UPDATE_RESOURCE_FIELD_SLOT.REQUEST);
        try {
            const data: ResourceFieldSlotResponseTypes = yield call(http, `resource/slot/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateResourceFieldSlot.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateResourceFieldSlot.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateResourceFieldSlot.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldSlotDeleting() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid},
        }: IActionType<ResourceFieldSlotDeleteRequestTypes> = yield take(DELETE_RESOURCE_FIELD_SLOT.REQUEST);
        try {
            const data: ResourceFieldSlotResponseTypes = yield call(http, `resource/slot/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteResourceFieldSlot.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteResourceFieldSlot.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(deleteResourceFieldSlot.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldCalendarListGetting() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid},
        }: IActionType<ResourceFieldCalendarListRequestTypes> = yield take(GET_RESOURCE_FIELD_CALENDAR_LIST.REQUEST);
        try {
            const data: ResourceFieldCalendarResponseTypes = yield call(http, `resource/calendar/${uuid}/list`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getResourceFieldCalendarList.success(data.results));
                callback && callback();
            } else {
                yield put(getResourceFieldCalendarList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getResourceFieldCalendarList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldCalendarInfoGetting() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid},
        }: IActionType<ResourceFieldCalendarInfoRequestTypes> = yield take(GET_RESOURCE_FIELD_CALENDAR_INFO.REQUEST);
        try {
            const data: ResourceFieldCalendarInfoResponseTypes = yield call(http, `resource/calendar/${uuid}/info`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getResourceFieldCalendarInfo.success(data.results));
                callback && callback();
            } else {
                yield put(getResourceFieldCalendarInfo.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getResourceFieldCalendarInfo.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldCalendarCreation() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<ResourceFieldCalendarCreateRequestTypes> = yield take(CREATE_RESOURCE_FIELD_CALENDAR.REQUEST);
        try {
            const data: ResourceFieldCalendarResponseTypes = yield call(http, `resource/calendar/${uuid}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createResourceFieldCalendar.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createResourceFieldCalendar.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createResourceFieldCalendar.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldCalendarUpdating() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<ResourceFieldCalendarUpdateRequestTypes> = yield take(UPDATE_RESOURCE_FIELD_CALENDAR.REQUEST);
        try {
            const data: ResourceFieldCalendarInfoResponseTypes = yield call(http, `resource/calendar/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateResourceFieldCalendar.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateResourceFieldCalendar.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateResourceFieldCalendar.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldCalendarDeleting() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid},
        }: IActionType<ResourceFieldCalendarDeleteRequestTypes> = yield take(DELETE_RESOURCE_FIELD_CALENDAR.REQUEST);
        try {
            const data: ResourceFieldCalendarResponseTypes = yield call(http, `resource/calendar/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteResourceFieldCalendar.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteResourceFieldCalendar.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteResourceFieldCalendar.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldCalendarBookframeCreation() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<ResourceFieldBookframeCreateRequestTypes> = yield take(CREATE_RESOURCE_FIELD_BOOKFRAME.REQUEST);
        try {
            const data: ResourceFieldCalendarInfoResponseTypes = yield call(http, `resource/bookframe/${uuid}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createResourceFieldBookframe.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createResourceFieldBookframe.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createResourceFieldBookframe.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldCalendarBookframeUpdating() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<ResourceFieldBookframeUpdateRequestTypes> = yield take(UPDATE_RESOURCE_FIELD_BOOKFRAME.REQUEST);
        try {
            const data: ResourceFieldCalendarInfoResponseTypes = yield call(http, `resource/bookframe/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateResourceFieldBookframe.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateResourceFieldBookframe.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateResourceFieldBookframe.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldCalendarBookframeDeleting() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid},
        }: IActionType<ResourceFieldBookframeDeleteRequestTypes> = yield take(DELETE_RESOURCE_FIELD_BOOKFRAME.REQUEST);
        try {
            const data: ResourceFieldCalendarInfoResponseTypes = yield call(http, `resource/bookframe/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteResourceFieldBookframe.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteResourceFieldBookframe.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteResourceFieldBookframe.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldCalendarExceptionCreation() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<ResourceFieldExceptionCreateRequestTypes> = yield take(CREATE_RESOURCE_FIELD_EXCEPTION.REQUEST);
        try {
            const data: ResourceFieldCalendarInfoResponseTypes = yield call(http, `resource/exception/${uuid}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createResourceFieldException.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createResourceFieldException.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createResourceFieldException.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldCalendarExceptionUpdating() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<ResourceFieldExceptionUpdateRequestTypes> = yield take(UPDATE_RESOURCE_FIELD_EXCEPTION.REQUEST);
        try {
            const data: ResourceFieldCalendarInfoResponseTypes = yield call(http, `resource/exception/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateResourceFieldException.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateResourceFieldException.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateResourceFieldException.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldCalendarExceptionDeleting() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid},
        }: IActionType<ResourceFieldExceptionDeleteRequestTypes> = yield take(DELETE_RESOURCE_FIELD_EXCEPTION.REQUEST);
        try {
            const data: ResourceFieldCalendarInfoResponseTypes = yield call(http, `resource/exception/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteResourceFieldException.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteResourceFieldException.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteResourceFieldException.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldCalendarCalendarSlotCreation() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<ResourceFieldCalendarSlotCreateRequestTypes> = yield take(
            CREATE_RESOURCE_FIELD_CALENDAR_SLOT.REQUEST,
        );
        try {
            const data: ResourceFieldCalendarInfoResponseTypes = yield call(
                http,
                `resource/calendar/slot/${uuid}/create`,
                {
                    method: 'POST',
                    body: JSON.stringify(values),
                },
            );
            if (data.success) {
                yield put(createResourceFieldCalendarSlot.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createResourceFieldCalendarSlot.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createResourceFieldCalendarSlot.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldCalendarCalendarSlotUpdating() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<ResourceFieldCalendarSlotUpdateRequestTypes> = yield take(
            UPDATE_RESOURCE_FIELD_CALENDAR_SLOT.REQUEST,
        );
        try {
            const data: ResourceFieldCalendarInfoResponseTypes = yield call(
                http,
                `resource/calendar/slot/${uuid}/update`,
                {
                    method: 'POST',
                    body: JSON.stringify(values),
                },
            );
            if (data.success) {
                yield put(updateResourceFieldCalendarSlot.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateResourceFieldCalendarSlot.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateResourceFieldCalendarSlot.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchResourceFieldCalendarCalendarSlotDeleting() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid},
        }: IActionType<ResourceFieldCalendarSlotDeleteRequestTypes> = yield take(
            DELETE_RESOURCE_FIELD_CALENDAR_SLOT.REQUEST,
        );
        try {
            const data: ResourceFieldCalendarInfoResponseTypes = yield call(
                http,
                `resource/calendar/slot/${uuid}/delete`,
                {
                    method: 'DELETE',
                },
            );
            if (data.success) {
                yield put(deleteResourceFieldCalendarSlot.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteResourceFieldCalendarSlot.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteResourceFieldCalendarSlot.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchResourceFieldsGetting),
    fork(watchResourceFieldsByWorkflowGetting),
    fork(watchResourceFieldCreation),
    fork(watchResourceFieldUpdating),
    fork(watchResourceFieldDeleting),
    fork(watchResourceFieldBudgetListGetting),
    fork(watchResourceFieldBudgetInfoGetting),
    fork(watchResourceFieldBudgetCasesGetting),
    fork(watchResourceFieldBudgetCreation),
    fork(watchResourceFieldBudgetUpdating),
    fork(watchResourceFieldBudgetDeleting),
    fork(watchResourceFieldBudgetOrganizationListGetting),
    fork(watchResourceFieldSlotOrganizationListGetting),
    fork(watchResourceFieldSlotListGetting),
    fork(watchResourceFieldSlotInfoGetting),
    fork(watchResourceFieldSlotCasesGetting),
    fork(watchResourceFieldSlotCreation),
    fork(watchResourceFieldSlotUpdating),
    fork(watchResourceFieldSlotDeleting),
    fork(watchResourceFieldCalendarListGetting),
    fork(watchResourceFieldCalendarInfoGetting),
    fork(watchResourceFieldCalendarCreation),
    fork(watchResourceFieldCalendarUpdating),
    fork(watchResourceFieldCalendarDeleting),
    fork(watchResourceFieldCalendarBookframeCreation),
    fork(watchResourceFieldCalendarBookframeUpdating),
    fork(watchResourceFieldCalendarBookframeDeleting),
    fork(watchResourceFieldCalendarExceptionCreation),
    fork(watchResourceFieldCalendarExceptionUpdating),
    fork(watchResourceFieldCalendarExceptionDeleting),
    fork(watchResourceFieldCalendarCalendarSlotCreation),
    fork(watchResourceFieldCalendarCalendarSlotUpdating),
    fork(watchResourceFieldCalendarCalendarSlotDeleting),
];
