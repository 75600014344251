import React, {FC, Ref} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {WorkflowTaskEmailTemplateFormTypes} from 'appRedux/actions/workflow/types';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import MdxEditorSmallField from 'components/MdxEditorField/MdxEditorSmallField';
import {customButtons} from 'components/Forms/Workflow/workflowStatusTask/helper';
import {validationSchemaWorkflowTaskEmailTemplate} from 'components/Forms/Workflow/validation';

interface ComponentType {
    formRef: Ref<any> | null;
    initialValues: WorkflowTaskEmailTemplateFormTypes;
    onSubmitClicked: (values: WorkflowTaskEmailTemplateFormTypes) => void;
}

const WorkflowTaskEmailTemplateForm: FC<ComponentType> = ({formRef, initialValues, onSubmitClicked}) => {
    const [t] = useTranslation();

    return (
        <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            validationSchema={() => validationSchemaWorkflowTaskEmailTemplate(t)}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<WorkflowTaskEmailTemplateFormTypes>) => {
                return (
                    <Form>
                        <Box sx={{m: 0, minWidth: '250px', flexGrow: 1}}>
                            <Typography sx={{fontWeight: 600}}>{`${t(
                                'orguser.workflows.tasks.emailTemplate.subject',
                            )}:`}</Typography>
                            <Field
                                required
                                name="subject"
                                placeholder={t('orguser.workflows.tasks.emailTemplate.subject')}
                                component={FormikTextInput}
                            />
                        </Box>
                        <MdxEditorSmallField
                            name="text"
                            label={t('orguser.workflows.tasks.emailTemplate.text')}
                            value={initialValues.text}
                            customButtons={customButtons}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default WorkflowTaskEmailTemplateForm;
