import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {PanelItemsTypes} from 'appRedux/actions/analytics/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import PanelUpdateForm from 'components/Forms/Dashboard/PanelUpdateForm';

interface EditPanelButtonType {
    item: PanelItemsTypes;
}

const EditPanelButton: FC<EditPanelButtonType> = ({item}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <>
            <IconButton title={t('common.buttons.update')} onClick={toggleModal}>
                <EditSvgIcon />
            </IconButton>
            <ModalWrapper
                isShowModal={isOpened}
                toggleModal={toggleModal}
                title={t('orguser.analytics.panels.updatePanel')}
                isWideModal
            >
                <PanelUpdateForm item={item} closeModal={() => setIsOpened(false)} />
            </ModalWrapper>
        </>
    );
};

export default EditPanelButton;
