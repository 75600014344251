import {useEffect} from 'react';
import * as Sentry from '@sentry/react';
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from 'react-router-dom';

if (sentry_dsn) {
    Sentry.init({
        dsn: sentry_dsn,
        release: herupu_release,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
        ],
    });
}
