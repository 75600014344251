import React from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import {GridColDef} from '@mui/x-data-grid';

import MarkdownWithShowAll from 'components/TextWithShowAll/MarkdownWithShowAll';

import RelatedCasesButton from 'pages/admin/updateResourceField/slot/RelatedCasesButton';
import DeleteResourceFieldSlotButton from 'pages/admin/updateResourceField/slot/DeleteResourceFieldSlotButton';
import UpdateResourceFieldSlotButton from 'pages/admin/updateResourceField/slot/UpdateResourceFieldSlotButton';

const getResourceFieldSlotColumns = (): GridColDef[] | null => {
    const [t] = useTranslation();

    return [
        {
            field: 'title',
            headerName: t('orguser.resourceFields.title'),
            minWidth: 200,
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{value}</Typography>;
            },
        },
        {
            field: 'description',
            headerName: t('orguser.resourceFields.description'),
            minWidth: 200,
            flex: 1,
            renderCell: ({value}) => {
                return <MarkdownWithShowAll text={value} isTable />;
            },
        },
        {
            field: 'eventName',
            headerName: t('orguser.resourceFields.eventName'),
            minWidth: 200,
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{value}</Typography>;
            },
        },
        {
            field: 'qty',
            headerName: t('orguser.resourceFields.qty'),
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{value}</Typography>;
            },
        },
        {
            field: 'reservedQty',
            headerName: t('orguser.resourceFields.statusBindingTypes.statusReservation'),
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{value}</Typography>;
            },
        },
        {
            field: 'bookedQty',
            headerName: t('orguser.resourceFields.statusBindingTypes.statusBooking'),
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{value}</Typography>;
            },
        },
        {
            field: 'clearedQty',
            headerName: t('orguser.resourceFields.statusBindingTypes.statusClearing'),
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{value}</Typography>;
            },
        },
        {
            field: 'actions',
            headerName: t('common.action'),
            align: 'right',
            maxWidth: 90,
            flex: 1,
            renderCell: ({row}) => {
                return (
                    <>
                        <UpdateResourceFieldSlotButton item={row} />
                        <RelatedCasesButton item={row} />
                        <DeleteResourceFieldSlotButton item={row} />
                    </>
                );
            },
        },
    ];
};

export default getResourceFieldSlotColumns;
