import {PARAMETER_PAGE, PARAMETER_PER_PAGE} from 'config/index';

/**
 * This function extracts page and perPage URL parameters from filter href
 *
 * @param pathname
 * @param href
 */
export const getFilterHrefForSaving = (pathname: string, href: string): string => {
    const hrefArray = href.split('&');
    const hrefArrayFiltered = hrefArray.filter(
        item => !item.includes(`${PARAMETER_PAGE}=`) && !item.includes(`${PARAMETER_PER_PAGE}=`),
    );
    const parametersString = hrefArrayFiltered.join('&');
    return parametersString.length > 0 && parametersString[0] !== '?' ? `?${parametersString}` : parametersString;
};
