import React, {FC, ReactNode, useContext} from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {MediaContext} from 'contexts/media/context';

interface PageSwitcherWrapperType {
    children: ReactNode;
}

const PageSwitcherWrapper: FC<PageSwitcherWrapperType> = ({children}) => {
    const {isMobile} = useContext(MediaContext);

    if (isMobile) {
        return <Box sx={{ml: 5, mr: 5}}>{children}</Box>;
    }
    return (
        <Grid container>
            <Grid item sm={1}></Grid>
            <Grid item sm={10}>
                {children}
            </Grid>
            <Grid item sm={1}></Grid>
        </Grid>
    );
};

export default PageSwitcherWrapper;
