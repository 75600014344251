import React, {FC} from 'react';

import Box from '@mui/material/Box';

import {ActivityElementType} from 'appRedux/actions/requestCase/types';
import {FormPageTypes} from 'appRedux/actions/forms/types';

import {FieldsDifferenceType, getFieldDifferences} from 'pages/agent/activityPage/helper';
import ActivityInformationResultsItem from 'pages/agent/activityPage/ActivityInformationResultsItem';

interface ActivityInformationResultsType {
    pages: FormPageTypes[];
    isEncryptInfo?: boolean;
    element: ActivityElementType;
    encodedCaseKey?: string;
}

const ActivityInformationResults: FC<ActivityInformationResultsType> = ({
    element,
    pages,
    isEncryptInfo,
    encodedCaseKey,
}) => {
    const results = getFieldDifferences(pages, element);

    return (
        <Box sx={{mb: 1}}>
            {results &&
                results.map((item: FieldsDifferenceType, index: number) => {
                    return (
                        <ActivityInformationResultsItem
                            key={`result-${index}`}
                            item={item}
                            isEncryptInfo={isEncryptInfo}
                            encodedCaseKey={encodedCaseKey}
                        />
                    );
                })}
        </Box>
    );
};

export default ActivityInformationResults;
