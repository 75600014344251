import React, {FC, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';
import {useSelector} from 'react-redux';

import {GridColDef} from '@mui/x-data-grid';
import {WithStyles, withStyles, createStyles} from '@material-ui/core';

import {RequesterCaseResponseTypes} from 'appRedux/actions/admin/types';
import {RootReducer} from 'appRedux/reducers';

import {MediaContext} from 'contexts/media/context';
import {AdminContext} from 'contexts/admin/context';
import {PermissionContext} from 'contexts/permission/context';

import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';
import RequesterCaseContextMenu from 'components/AgentScreenComponents/_caseBlock/RequesterCaseContextMenu';
import RequesterCaseDrawer from 'components/AgentScreenComponents/_caseBlock/RequesterCaseDrawer/RequesterCaseDrawer';

import getFormsColumns from 'pages/agent/tableView/getRequestersColumns';

import {theme} from 'config/theme';

interface RequestersDataGridType extends WithStyles<typeof styles> {
    items: RequesterCaseResponseTypes[];
}

const styles = () =>
    createStyles({
        notRead: {
            '& p': {
                fontWeight: 'bold',
            },
        },
        activeRow: {
            backgroundColor: theme.palette.warning.contrastText,
        },
    });

const RequestersDataGrid: FC<RequestersDataGridType> = ({items, classes}) => {
    const [t] = useTranslation();

    const {isSelectClicked, activeRow, setActiveRow} = useContext(AdminContext);
    const permissions = useContext(PermissionContext);
    const {isLargeDesktop} = useContext(MediaContext);

    const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
    const [anchorHorizontal, setAnchorHorizontal] = useState<number | null>(null);
    const [anchorVertical, setAnchorVertical] = useState<number | null>(null);
    const [selectedCase, setSelectedCase] = useState<RequesterCaseResponseTypes | null>(null);

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const currentUserEmail = get(profile, 'email', null);

    const columns: GridColDef[] = getFormsColumns({
        t,
        currentUserEmail,
        permissions,
        isLargeDesktop,
        items,
    });

    const onRightClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        if (!event.currentTarget) return;

        const rowId = Number((event.currentTarget as HTMLDivElement).getAttribute('data-id'));
        const record = items.find(row => row.caseId === rowId);
        if (!record) return;
        setSelectedCase(record);
        setShowContextMenu(true);

        const left = event.pageX;
        const top = event.pageY;
        setAnchorHorizontal(left);
        setAnchorVertical(top);
    };

    const activeRowData = activeRow && items.find(row => row.caseId === activeRow);

    return (
        <>
            <CustomDataGrid
                rows={items}
                columns={columns}
                getRowId={row => row.caseId}
                checkboxSelection={isSelectClicked}
                enablePaging
                paginationMode="server"
                disableColumnFilter
                getRowClassName={params =>
                    `${params.row.notReadMsg || params.row.notReadCase ? classes?.notRead : ''} ${
                        activeRow === params.row.caseId ? classes?.activeRow : ''
                    }`
                }
                onRowClick={params => {
                    console.log('setActiveRow', params, params.row.caseId);
                    setActiveRow(params.row.caseId);
                    setSelectedCase(params.row);
                }}
                slotProps={{
                    row: {
                        onContextMenu: e => onRightClick(e),
                    },
                }}
                sx={{cursor: 'pointer'}}
            />
            {activeRowData && <RequesterCaseDrawer caseId={activeRowData.caseId} />}
            {selectedCase && (
                <RequesterCaseContextMenu
                    showContextMenu={showContextMenu}
                    setShowContextMenu={setShowContextMenu}
                    anchorHorizontal={anchorHorizontal}
                    anchorVertical={anchorVertical}
                    permittedUuids={get(selectedCase, 'permittedUuids', [])}
                    item={selectedCase}
                    isOverviewBoard
                />
            )}
        </>
    );
};

export default withStyles(styles)(RequestersDataGrid);
