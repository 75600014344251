import React, {FC, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {get, isEmpty} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {
    FormItemBasic,
    FormSnippetUpdateRequestType,
    FormSnippetUpdateWithLogoRequestType,
} from 'appRedux/actions/forms/types';
import {
    GET_FORM_SNIPPET,
    UPDATE_FORM_SNIPPET,
    GET_FORM_SNIPPET_LOGO,
    UPDATE_FORM_WITH_LOGO_SNIPPET,
    GET_FORM_SNIPPET_CODE,
} from 'appRedux/actions/forms';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import {validationSchema, validationSchemaNotOnlyLatin} from 'components/Forms/FormBuilder/SnippetForm/validation';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import MdxEditorField from 'components/MdxEditorField/MdxEditorField';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import SnippetExample from 'components/Forms/FormBuilder/SnippetForm/SnippetExample';
import FormBuilderCheckboxReverse from 'components/AgentScreenComponents/_form/FormBuilderCheckboxReverse';
import RemoveSnippetLogoButton from 'components/Forms/FormBuilder/SnippetForm/RemoveSnippetLogoButton';

import {getBase64Uri} from 'pages/client/form/fieldTypes/helper';

import {preventSendForm} from 'helpers/index';
import {checkIsLanguageWithLatinLetters} from 'helpers/translationsHelper';

import {ERROR_TEXT_COLOR} from 'config/theme';
import {ALERT_TYPE_SUCCESS} from 'config/index';

interface FormSnippetType {
    logoSource: string | null;
    currentForm: FormItemBasic;
}

const SnippetForm: FC<FormSnippetType> = ({currentForm, logoSource}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const ref = useRef<HTMLDivElement | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const [blobURL, setBlobURL] = useState<string | null>(null);

    const {showAlert} = useContext(AlertContext);

    const getFormSnippet = useCallback(data => dispatch({type: GET_FORM_SNIPPET.REQUEST, payload: data}), [dispatch]);

    const getFormSnippetLogo = useCallback(
        data => dispatch({type: GET_FORM_SNIPPET_LOGO.REQUEST, payload: data}),
        [dispatch],
    );

    const getFormSnippetCode = useCallback(
        data => dispatch({type: GET_FORM_SNIPPET_CODE.REQUEST, payload: data}),
        [dispatch],
    );

    const uploadFormSnippet = useCallback(
        data => dispatch({type: UPDATE_FORM_SNIPPET.REQUEST, payload: data}),
        [dispatch],
    );

    const uploadFormWithLogoSnippet = useCallback(
        data => dispatch({type: UPDATE_FORM_WITH_LOGO_SNIPPET.REQUEST, payload: data}),
        [dispatch],
    );

    const onLabelClick = () => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const {
        admin: {formInfo, languageList, organizationList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const isLanguageWithLatinLetters = myOrganization
        ? checkIsLanguageWithLatinLetters(languageList, myOrganization.language)
        : false;

    const {id, title, description, formButtonText} = currentForm;

    const snippet = get(formInfo, 'snippet', null);

    const initialValues = {
        id,
        title: snippet ? snippet.title : title,
        description: snippet ? snippet.description : String(description),
        slogan: snippet ? snippet.slogan : formButtonText,
        isDark: snippet ? snippet.isDark : false,
        file: '',
    };

    const handleSubmit = (values: FormSnippetUpdateRequestType | FormSnippetUpdateWithLogoRequestType) => {
        if (values.file) {
            uploadFormWithLogoSnippet({
                ...values,
                showAlert,
            });
        } else {
            uploadFormSnippet({
                ...values,
                showAlert,
            });
        }
    };

    const copyCodeToBuffer = () => {
        getFormSnippetCode({
            id,
            showAlert,
            callback: (value: string) => {
                navigator.clipboard.writeText(value);
                showAlert(ALERT_TYPE_SUCCESS, t('messages.success.codeCopiedToBuffer'));
            },
        });
    };

    useEffect(() => {
        getFormSnippet({
            id,
            showAlert,
        });
        getFormSnippetLogo({
            id,
            callback: setBlobURL,
        });
    }, []);

    const schema = isLanguageWithLatinLetters ? validationSchemaNotOnlyLatin(t) : validationSchema(t);

    return (
        <Box>
            <Formik
                initialValues={{...initialValues}}
                validationSchema={schema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {(formik: FormikProps<FormSnippetUpdateRequestType | FormSnippetUpdateWithLogoRequestType>) => {
                    return (
                        <Form onKeyDown={preventSendForm}>
                            <Grid container spacing={3}>
                                <Grid item sm={6}>
                                    <Field
                                        required
                                        name="title"
                                        label={t('orguser.forms.formSnippet.sectionTitle')}
                                        placeholder={t('orguser.forms.formSnippet.sectionTitlePlaceholder')}
                                        component={FormikTextInput}
                                    />
                                    <MdxEditorField
                                        name="description"
                                        value={formik.values.description ? String(formik.values.description) : ''}
                                        label={t('orguser.forms.formSnippet.sectionDescription')}
                                    />
                                    <Box sx={{mt: 3}}>
                                        <Field
                                            type="checkbox"
                                            name="isDark"
                                            label={t('orguser.forms.formSnippet.darkMode')}
                                            component={FormBuilderCheckboxReverse}
                                        />
                                    </Box>
                                    <Box sx={{mt: 4, mb: 1}}>
                                        <TextField
                                            inputRef={fileInputRef}
                                            sx={{display: 'none'}}
                                            name="file"
                                            type="file"
                                            onChange={value => {
                                                formik.setFieldValue(
                                                    'file',
                                                    get(value, ['currentTarget', 'files', '0'], null),
                                                );
                                                getBase64Uri(get(value, ['currentTarget', 'files', '0'], null)).then(
                                                    result => {
                                                        setBlobURL(result);
                                                    },
                                                );
                                            }}
                                        />
                                        <AgentSaveButton
                                            isSubmit={false}
                                            icon={<EditSvgIcon isActive />}
                                            title={t('common.buttons.uploadLogo')}
                                            onClick={onLabelClick}
                                        />
                                        {!isEmpty(formik.errors) && (
                                            <Typography
                                                sx={{
                                                    mt: 2,
                                                    color: ERROR_TEXT_COLOR,
                                                    fontSize: 12,
                                                }}
                                            >
                                                {t(String(formik.errors.file))}
                                            </Typography>
                                        )}
                                    </Box>
                                    <Field
                                        name="slogan"
                                        label={t('orguser.forms.formSnippet.buttonText')}
                                        placeholder={t('orguser.forms.formSnippet.buttonText')}
                                        component={FormikTextInput}
                                        required
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <SnippetExample
                                        snippetRef={ref}
                                        logoSource={blobURL ?? logoSource}
                                        currentForm={currentForm}
                                        values={formik.values}
                                    />
                                </Grid>
                            </Grid>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 3,
                                }}
                            >
                                <AgentSaveButton isSubmit />
                                {snippet && snippet.logo && (
                                    <RemoveSnippetLogoButton formId={currentForm.id} setBlobURL={setBlobURL} />
                                )}
                                {snippet && (
                                    <AgentInverseButton
                                        title={t('common.buttons.copyCodeToBuffer')}
                                        onClick={copyCodeToBuffer}
                                    />
                                )}
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default SnippetForm;
