import React, {FC, ReactNode, useCallback, useContext, useState} from 'react';
import 'react-circular-progressbar/dist/styles.css';
import {buildStyles, CircularProgressbarWithChildren} from 'react-circular-progressbar';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Badge, IconButton, Tooltip} from '@mui/material';

import CancelSvgIcon from 'assets/icons/buttons/CloseSvgIcon';
import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';
import MessagesSvgIcon from 'assets/icons/menu/MessagesSvgIcon';
import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import {FormVersionCardType, FormVersionTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';
import {FORM_VERSION_CARD_UPDATE} from 'appRedux/actions/forms';

import {AlertContext} from 'contexts/alert/context';

import EmptyArea from 'components/EmptyArea';
import DeleteClientConfirmationModal from 'components/ClientScreenComponents/DeleteClientConfirmationModal';
import AvatarImage from 'components/UserAvatar/AvatarImage';

import {ADMIN_LIGHT_GRAY_COLOR, ERROR_TEXT_COLOR, MAIN_CLIENT_COLOR, theme} from 'config/theme';

interface FormVersionCardBlockType {
    item: FormVersionTypes;
    children: ReactNode;
    handleDelete: () => void;
}

const FormVersionCardBlock: FC<FormVersionCardBlockType> = ({item, children, handleDelete}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {showAlert} = useContext(AlertContext);

    const {
        admin: {formsList, formInfo},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;
    const {card} = formInfo;

    const updateFormVersionCard = useCallback(
        data => dispatch({type: FORM_VERSION_CARD_UPDATE.REQUEST, payload: data}),
        [dispatch],
    );

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const toggleShowDeleteModal = () => {
        setShowDeleteModal(prev => !prev);
    };

    const currentForm = (formsList || []).find(form => form.id === item.formId);

    const handleUpdateFormVersionCard = (updates: Partial<FormVersionCardType>) => {
        card &&
            updateFormVersionCard({
                uuid: card.uuid,
                values: {
                    ...card,
                    ...updates,
                },
                showAlert,
            });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                position: 'relative',
                backgroundColor: theme.palette.background.default,
                border: `1px solid ${ADMIN_LIGHT_GRAY_COLOR}`,
                boxShadow: `0px 1px 2px 1px ${ADMIN_LIGHT_GRAY_COLOR}`,
                overflow: 'hidden',
                borderRadius: 2,
                minHeight: 65,
                width: '45%',
                mb: 1,
                px: 2,
                py: 1.5,
                '&:hover': {
                    backgroundColor: theme.palette.background.default,
                },
            }}
        >
            <Box sx={{width: '100%'}}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            width: 38,
                            height: 38,
                            '& div div:not(.MuiAvatar-root)': {
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'end',
                            },
                        }}
                    >
                        <CircularProgressbarWithChildren
                            value={50}
                            strokeWidth={6}
                            styles={{
                                root: {
                                    verticalAlign: 'middle',
                                },
                                ...buildStyles({
                                    pathColor: theme.palette.info.main,
                                    trailColor: theme.palette.warning.light,
                                }),
                            }}
                        >
                            <AvatarImage size={32} avatarLink={''} avatarType={4} avatarTitle={'Requester'} />
                        </CircularProgressbarWithChildren>
                    </Box>
                    <Box
                        sx={{
                            ml: 1,
                            width: 'auto',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                                display: 'block',
                                maxWidth: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {`${currentForm?.handlePrefix}-1`}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            ml: 1,
                            pl: 0.75,
                            py: 0.25,
                            width: 'auto',
                            border: `1px solid ${ADMIN_LIGHT_GRAY_COLOR}`,
                            borderRadius: 2,
                            cursor: !card?.isShowRequesterName ? 'pointer' : 'default',
                            display: 'flex',
                            alignItems: 'center',
                            color: card?.isShowRequesterName
                                ? theme.palette.info.main
                                : theme.palette.info.contrastText,
                            svg: {
                                path: {
                                    fill: card?.isShowRequesterName
                                        ? theme.palette.info.main
                                        : theme.palette.info.contrastText,
                                },
                            },
                            ':hover': {
                                ...(!card?.isShowRequesterName
                                    ? {
                                          color: theme.palette.info.light,
                                          border: `1px solid ${theme.palette.info.contrastText}`,
                                          svg: {path: {fill: theme.palette.info.light}},
                                      }
                                    : {}),
                            },
                        }}
                        onClick={() =>
                            !card?.isShowRequesterName && handleUpdateFormVersionCard({isShowRequesterName: true})
                        }
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                                display: 'block',
                                maxWidth: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {t(`orguser.forms.formCards.requesterName`)}
                        </Typography>
                        {card?.isShowRequesterName ? (
                            <IconButton
                                size="small"
                                sx={{mx: 0.25}}
                                onClick={() => handleUpdateFormVersionCard({isShowRequesterName: false})}
                            >
                                <CancelSvgIcon />
                            </IconButton>
                        ) : (
                            <IconButton size="small" sx={{mx: 0.25}} disabled>
                                <PlusSvgIcon />
                            </IconButton>
                        )}
                    </Box>

                    <EmptyArea />

                    <Tooltip title={t('common.buttons.delete')}>
                        <IconButton
                            onClick={() => setShowDeleteModal(true)}
                            sx={{
                                ml: 1,
                                backgroundColor: ERROR_TEXT_COLOR,
                                '&:hover': {
                                    backgroundColor: ERROR_TEXT_COLOR,
                                },
                            }}
                        >
                            <Badge color="error" variant="dot" invisible={true}>
                                <DeleteSvgIcon isActive />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                    <DeleteClientConfirmationModal
                        isShowModal={showDeleteModal}
                        toggleModal={toggleShowDeleteModal}
                        onClickAction={handleDelete}
                    />
                </Box>

                <Box
                    sx={{
                        mt: 1,
                        width: '100%',
                    }}
                >
                    {children}
                </Box>

                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', minHeight: '16px'}}>
                    <IconButton
                        sx={{
                            width: 32,
                            height: 32,
                            backgroundColor: MAIN_CLIENT_COLOR,
                            '&:hover': {
                                backgroundColor: MAIN_CLIENT_COLOR,
                            },
                        }}
                    >
                        <Badge color="error" variant="dot" invisible={true}>
                            <MessagesSvgIcon isActive />
                        </Badge>
                    </IconButton>
                    <EmptyArea />
                    <AvatarImage size={32} avatarLink={''} avatarType={1} avatarTitle={'Agent'} />
                </Box>
            </Box>
        </Box>
    );
};

export default FormVersionCardBlock;
