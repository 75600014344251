import React, {FC, ReactNode} from 'react';

import Box from '@mui/material/Box';

import {CommonFileType} from 'pages/agent/requesterPage/partials/helper';

import {CLIENT_BACKGROUND_COLOR, theme} from 'config/theme';
import GalleryPreview from './GalleryPreview';

interface MobileGalleryType {
    selectedFile: CommonFileType;
    thumbLinks: (string | null | undefined)[];
    allFiles: CommonFileType[];
    getModalContent?: (file: CommonFileType) => void;
    children: ReactNode;
}

const MobileGallery: FC<MobileGalleryType> = ({selectedFile, allFiles, thumbLinks, getModalContent, children}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                flex: '1 1 0px',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'start',
                gap: 1,
                p: 2,
                backgroundColor: theme.palette.info.dark,
            }}
        >
            {getModalContent && allFiles.length > 1 && (
                <Box
                    sx={{
                        position: 'relative',
                        width: '100vw',
                        overflowX: 'auto',
                        overflowY: 'hidden',
                        '-ms-overflow-style': 'none',
                        'scrollbar-width': 'none',
                        '::-webkit-scrollbar': {
                            display: 'none',
                        },
                        mx: -2,
                        px: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: '90px',
                            width: 'fit-content',
                        }}
                    >
                        {allFiles.map((file, i) => (
                            <GalleryPreview
                                key={file.uuid}
                                file={file}
                                thumbLink={thumbLinks[i]}
                                getModalContent={getModalContent}
                                isOpened={selectedFile.uuid === file.uuid}
                                sx={{
                                    width: '75px',
                                    height: '75px',
                                    marginBottom: 1,
                                    marginRight: 1,
                                    mt: '3px',
                                }}
                            />
                        ))}
                    </Box>
                </Box>
            )}
            <Box
                sx={{
                    position: 'relative',
                    flex: '1 1 100px',
                    height: 'calc(80vh - 100px)',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: CLIENT_BACKGROUND_COLOR,
                    borderRadius: 2,
                    overflow: 'hidden',
                    width: '100%',
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default MobileGallery;
