import React, {FC} from 'react';

interface MenuIconType {
    isActive: boolean;
}

const DashboardSvgIcon: FC<MenuIconType> = ({isActive}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.25 1.25H2.5V17.5H18.75V18.75H2.5C2.16848 18.75 1.85054 18.6183 1.61612 18.3839C1.3817 18.1495 1.25 17.8315 1.25 17.5V1.25ZM14.375 5.625H18.75V10H17.5V7.75625L12.3187 12.9437C12.2606 13.0023 12.1915 13.0488 12.1154 13.0806C12.0392 13.1123 11.9575 13.1286 11.875 13.1286C11.7925 13.1286 11.7108 13.1123 11.6346 13.0806C11.5585 13.0488 11.4894 13.0023 11.4313 12.9437L8.75 10.2563L4.63125 14.375L3.75 13.4937L8.30625 8.93125C8.36435 8.87267 8.43348 8.82617 8.50964 8.79444C8.5858 8.76271 8.66749 8.74638 8.75 8.74638C8.83251 8.74638 8.9142 8.76271 8.99036 8.79444C9.06652 8.82617 9.13565 8.87267 9.19375 8.93125L11.875 11.6187L16.6187 6.875H14.375V5.625Z"
                fill={isActive ? 'white' : '#222222'}
            />
        </svg>
    );
};

export default DashboardSvgIcon;
