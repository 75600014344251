import React, {ChangeEvent, FC, useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Formik, FormikProps} from 'formik';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import IconButton from '@mui/material/IconButton';

import CloseSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {WorkflowStatusFormTypes} from 'appRedux/actions/workflow/types';
import {UPDATE_WORKFLOW_STATUS} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import TagChip from 'components/TagChip/TagChip';

import {tagColors} from 'config/theme';

interface WorkflowStatusColorFormType {
    uuid: string;
    isForRequester: boolean;
    initialColor: string;
    initialValues: WorkflowStatusFormTypes;
    closeForm: () => void;
}

const WorkflowStatusColorForm: FC<WorkflowStatusColorFormType> = ({
    uuid,
    initialValues,
    isForRequester,
    initialColor,
    closeForm,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const colorField = isForRequester ? 'colorRequester' : 'color';

    const [currentColor, setCurrentColor] = useState<string>('');

    const onColorSelect = (e: ChangeEvent<{value: string}>) => {
        setCurrentColor(e.target.value);
    };

    const updateWorkflowStatus = useCallback(
        data => dispatch({type: UPDATE_WORKFLOW_STATUS.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: WorkflowStatusFormTypes) => {
        updateWorkflowStatus({
            ...values,
            uuid,
            showAlert,
        });
    };

    useEffect(() => {
        setCurrentColor(initialColor);
    }, [initialColor]);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={values =>
                onSubmitClicked({
                    ...values,
                    [colorField]: currentColor,
                })
            }
            enableReinitialize
        >
            {(formik: FormikProps<WorkflowStatusFormTypes>) => {
                return (
                    <Form>
                        <Box sx={{pt: 2, pb: 1}}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mb: 1,
                                }}
                            >
                                <Typography sx={{fontWeight: 700}}>{`${t(
                                    'orguser.workflows.workflowStatusParameters.selectColor',
                                )}:`}</Typography>
                                <IconButton title={t('common.buttons.close')} onClick={closeForm}>
                                    <CloseSvgIcon />
                                </IconButton>
                            </Box>
                            <RadioGroup
                                value={currentColor}
                                name="color"
                                row
                                sx={{
                                    p: 1,
                                    mb: 1,
                                }}
                            >
                                {tagColors.map((tagColor: string, index: number) => {
                                    return (
                                        <FormControlLabel
                                            key={`color-${index}`}
                                            value={tagColor}
                                            control={
                                                <Radio
                                                    sx={{mb: 1.5, display: 'none'}}
                                                    checked={currentColor === tagColor}
                                                    onChange={e => {
                                                        onColorSelect(e);
                                                        formik.submitForm();
                                                    }}
                                                />
                                            }
                                            label={
                                                <TagChip
                                                    id={String(index)}
                                                    title={tagColor}
                                                    color={tagColor}
                                                    hasOpacity={currentColor === tagColor}
                                                />
                                            }
                                        />
                                    );
                                })}
                            </RadioGroup>
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default WorkflowStatusColorForm;
