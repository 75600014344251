import React, {FC, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';

import {WorkflowStatusTranslationFormTypes} from 'appRedux/actions/workflow/types';

import TabTextInput from 'components/AgentScreenComponents/_form/TabTextInput';
import {
    validationSchemaWorkflowStatusTranslation,
    validationSchemaWorkflowStatusTranslationRequester,
} from 'components/Forms/Workflow/validation';

interface FormType {
    currentColor: string;
    formField: string;
    initialValues: WorkflowStatusTranslationFormTypes;
    onSubmitClicked: (values: WorkflowStatusTranslationFormTypes) => void;
}

const WorkflowStatusTranslationForm: FC<FormType> = ({currentColor, formField, initialValues, onSubmitClicked}) => {
    const [t] = useTranslation();

    const fieldInput = useRef<HTMLInputElement | null>(null);

    const initialTranslation = initialValues[formField];

    useEffect(() => {
        if (fieldInput && fieldInput.current) {
            fieldInput.current.focus();
        }
    }, []);

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={() =>
                formField === 'translation'
                    ? validationSchemaWorkflowStatusTranslation(t)
                    : validationSchemaWorkflowStatusTranslationRequester(t)
            }
            onSubmit={values => {
                onSubmitClicked({
                    ...values,
                });
            }}
        >
            {(formik: FormikProps<WorkflowStatusTranslationFormTypes>) => {
                const isValueChanged = initialTranslation !== formik.values[formField];
                return (
                    <Form>
                        <Field
                            required
                            inputRef={fieldInput}
                            name={formField}
                            placeholder={t('orguser.workflows.workflowStatusParameters.titleRequester')}
                            component={TabTextInput}
                            currentColor={currentColor}
                            onBlur={() => {
                                if (isValueChanged) {
                                    formik.submitForm();
                                }
                            }}
                            onKeyDown={e => {
                                if (e.keyCode == 13) {
                                    if (isValueChanged) {
                                        formik.submitForm();
                                    }
                                }
                            }}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default WorkflowStatusTranslationForm;
