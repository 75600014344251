import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const RuleSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.9121 13.3535L8 15L11.0879 13.3534C11.9683 12.8851 12.7045 12.1859 13.2175 11.3309C13.7306 10.4758 14.0011 9.49717 14 8.5V2C13.9997 1.73487 13.8943 1.48069 13.7068 1.29321C13.5193 1.10574 13.2651 1.00029 13 1H3C2.73488 1.00029 2.48069 1.10574 2.29322 1.29321C2.10574 1.48069 2.00029 1.73487 2 2V8.5C1.99892 9.49719 2.26941 10.4758 2.78246 11.3309C3.2955 12.186 4.03173 12.8852 4.9121 13.3535ZM3 8.5V2H13V8.5C13.0009 9.3159 12.7795 10.1166 12.3597 10.8163C11.9399 11.5159 11.3375 12.088 10.6172 12.4712L8 13.8666L5.3828 12.4712C4.66246 12.088 4.06007 11.5159 3.64028 10.8163C3.22048 10.1166 2.99914 9.3159 3 8.5ZM11 5H5V6H11V5ZM11 8H5V9H11V8Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default RuleSvgIcon;
