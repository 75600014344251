import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';
import {CREATE_BOARD_HAS_STATUS} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import {CustomSwitcher} from 'components/AgentScreenComponents/_form/CustomElements';

interface BoardStatusNewRowType {
    boardUuid: string;
    item: WorkflowStatusItemTypes;
}

const BoardStatusNewRow: FC<BoardStatusNewRowType> = ({item, boardUuid}) => {
    const dispatch = useDispatch();

    const {title, id} = item;

    const {showAlert} = useContext(AlertContext);

    const [isBoardActive, setIsBoardActive] = useState<boolean>(false);

    const createBoardHasStatus = useCallback(
        data => dispatch({type: CREATE_BOARD_HAS_STATUS.REQUEST, payload: data}),
        [dispatch],
    );

    const addBoardHasStatus = () => {
        createBoardHasStatus({
            uuid: boardUuid,
            workflowStatus: id,
            showAlert,
            callback: () => {
                setIsBoardActive(true);
            },
        });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mb: 2,
            }}
        >
            <CustomSwitcher checked={isBoardActive} onClick={addBoardHasStatus} />
            <Typography sx={{ml: 2}}>{title}</Typography>
        </Box>
    );
};

export default BoardStatusNewRow;
