import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Markdown from 'react-markdown';

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {RuleTypes} from 'appRedux/actions/rules/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

interface VersionPreviewButtonType {
    item: RuleTypes;
    versionTitle: string;
}

const VersionPreviewButton: FC<VersionPreviewButtonType> = ({item, versionTitle}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <>
            <IconButton title={t('common.buttons.preview')} onClick={toggleIsOpened}>
                <EditSvgIcon />
            </IconButton>
            <ModalWrapper isWideModal isShowModal={isOpened} toggleModal={toggleIsOpened} title={versionTitle}>
                <Box
                    sx={{
                        '& p': {
                            fontSize: 14,
                            fontWeight: 300,
                            mt: 0,
                        },
                    }}
                >
                    <Markdown>{item.text}</Markdown>
                </Box>
            </ModalWrapper>
        </>
    );
};

export default VersionPreviewButton;
