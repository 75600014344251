import React, {FC, useContext, useEffect} from 'react';

import {AdminContext} from 'contexts/admin/context';
import {PermissionContext} from 'contexts/permission/context';
import {RouteContext} from 'contexts/route/context';

import OrganizationTeam from 'components/OrganizationAdminsList/OrganizationTeam';

import {checkSubdomainCorrect} from 'helpers/security';

const OrgUsers: FC = () => {
    const {myOrganization} = useContext(AdminContext);
    const {setPageTitle, setTopBarTitle, setBackLink, setBackTab, setBackLinkLabel} = useContext(RouteContext);
    const {isUserViewEnabled} = useContext(PermissionContext);

    const isSubdomainCorrect = checkSubdomainCorrect(myOrganization);

    useEffect(() => {
        setPageTitle('');
        setTopBarTitle('');
        setBackLink('');
        setBackTab('');
        setBackLinkLabel('');
    }, []);

    if (myOrganization && isSubdomainCorrect && isUserViewEnabled) {
        return <OrganizationTeam organizationId={myOrganization.id} />;
    }
    return null;
};

export default OrgUsers;
