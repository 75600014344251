import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ContentPasteGoOutlinedIcon from '@mui/icons-material/ContentPasteGoOutlined';
import MarkAsUnreadOutlinedIcon from '@mui/icons-material/MarkAsUnreadOutlined';
import KeyIcon from '@mui/icons-material/Key';
import LockIcon from '@mui/icons-material/Lock';
import HttpIcon from '@mui/icons-material/HttpOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LoginIcon from '@mui/icons-material/Login';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import ListAltIcon from '@mui/icons-material/ListAlt';
import {Link as MuiLink} from '@mui/material';

import {OrganizationItemBasic} from 'appRedux/actions/organization/types';
import {CHANGE_ORGANIZATION_STATUS, DELETE_ORGANIZATION} from 'appRedux/actions/organization';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';
import UserRoleSvgIcon from 'assets/icons/buttons/UserRoleSvgIcon';
import WordPressSvgIcon from 'assets/icons/buttons/WordPressSvgIcon';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationButton from 'components/AdminScreenComponents/DeleteAdminConfirmationButton';
import CustomTooltip from 'components/CustomTooltip/CustomTooltip';
import LargeCustomSwitcher from 'components/AgentScreenComponents/_buttons/LargeCustomSwitcher';

import ChangeDomainNameButton from 'pages/admin/organizations/OrganizationList/ChangeDomainNameButton';
import OrganizaionLogo from 'pages/admin/organizations/OrganizationList/OrganizaionLogo';

import useParsedTranslation from 'hooks/useParsedTranslation';

import {routes, EMPTY_DATA} from 'config/index';
import {theme} from 'config/theme';

interface OrganizationTileType {
    item: OrganizationItemBasic;
}

const OrganizationTile: FC<OrganizationTileType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        id,
        name,
        domainName,
        isExportAllowed,
        isEncryptionPossible,
        isMultipleRequestPossible,
        isForcedTwoFactor,
        isInviteRegistration,
        isActive,
        isClosed,
        isRequesterModeEnabled,
        isAutoLoginSso,
        isShowFormsList,
        isIntegrationFormEnabled,
    } = item;

    const [isOrganizationActive, setIsOrganizationActive] = useState<boolean>(isActive);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    const changeOrganizationStatus = useCallback(
        data => dispatch({type: CHANGE_ORGANIZATION_STATUS.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        setIsOrganizationActive(isActive);
    }, [isActive]);

    const deleteOrganization = useCallback(
        data => dispatch({type: DELETE_ORGANIZATION.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        deleteOrganization({
            id,
            showAlert,
        });
    };

    const handleChangeStatus = () => {
        setIsOrganizationActive(!isOrganizationActive);
        changeOrganizationStatus({
            organizationId: id,
            showAlert,
        });
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 2,
                    backgroundColor: theme.palette.background.default,
                    p: 2,
                }}
            >
                <Box sx={{display: 'flex', flexDirection: 'row', mb: 2}}>
                    <Box
                        sx={{
                            width: 90,
                            height: 90,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            img: {
                                maxWidth: '100%',
                                maxHeight: '100%',
                            },
                        }}
                    >
                        <OrganizaionLogo image={item.navBarLogo} />
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', py: 0, px: 1.5}}>
                        <Typography align="justify" variant="h5" sx={{fontWeight: 300, mb: 0.5, mx: 0.5}}>
                            {useParsedTranslation(t, String(name))}
                        </Typography>
                        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <CustomTooltip title={t('superadmin.organizations.changeDomain')}>
                                <Box>
                                    <ChangeDomainNameButton id={id} domainName={domainName ?? ''} isTable>
                                        <HttpIcon fontSize="small" />
                                    </ChangeDomainNameButton>
                                </Box>
                            </CustomTooltip>
                            <Typography align="justify" variant="body1" sx={{fontWeight: 300, mx: 0.5}}>
                                {domainName ?? EMPTY_DATA}
                            </Typography>
                            {domainName && (
                                <MuiLink href={`http://${domainName}`} target="_blank">
                                    <IconButton>
                                        <OpenInNewIcon fontSize="small" />
                                    </IconButton>
                                </MuiLink>
                            )}
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', ml: 'auto'}}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                mb: 1,
                            }}
                        >
                            <Link to={`${routes.UPDATE_ORGANIZATION}/${id}/roles`}>
                                <IconButton title={t('common.menu.roles')}>
                                    <UserRoleSvgIcon />
                                </IconButton>
                            </Link>
                            <Link to={`${routes.UPDATE_ORGANIZATION}/${id}`}>
                                <IconButton title={t('common.buttons.update')}>
                                    <EditSvgIcon />
                                </IconButton>
                            </Link>
                            <DeleteAdminConfirmationButton
                                isShowModal={isShowModal}
                                disabled={isActive}
                                toggleModal={toggleModal}
                                onClickAction={onClickDelete}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                mb: 2,
                            }}
                        >
                            <LargeCustomSwitcher checked={isOrganizationActive} onClick={handleChangeStatus} />
                        </Box>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row', color: theme.palette.info.main, gap: 3}}>
                    {isInviteRegistration && (
                        <CustomTooltip title={t('superadmin.organizations.registrationByInvite')}>
                            <MarkAsUnreadOutlinedIcon />
                        </CustomTooltip>
                    )}
                    {isExportAllowed && (
                        <CustomTooltip title={t('superadmin.organizations.exportAllowed')}>
                            <ContentPasteGoOutlinedIcon />
                        </CustomTooltip>
                    )}
                    {isMultipleRequestPossible && (
                        <CustomTooltip title={t('superadmin.organizations.multipleRequests')}>
                            <FileCopyOutlinedIcon />
                        </CustomTooltip>
                    )}
                    {isForcedTwoFactor && (
                        <CustomTooltip title={t('superadmin.organizations.forcedTwoFactorAuth')}>
                            <KeyIcon />
                        </CustomTooltip>
                    )}
                    {isEncryptionPossible && (
                        <CustomTooltip title={t('superadmin.organizations.caseEncryptionPossible')}>
                            <LockIcon />
                        </CustomTooltip>
                    )}
                    {isRequesterModeEnabled && (
                        <CustomTooltip title={t('superadmin.organizations.requesterModeEnabled')}>
                            <PeopleAltIcon />
                        </CustomTooltip>
                    )}
                    {isAutoLoginSso && (
                        <CustomTooltip title={t('superadmin.organizations.autoLoginSso')}>
                            <LoginIcon />
                        </CustomTooltip>
                    )}
                    {isClosed && (
                        <CustomTooltip title={t('superadmin.organizations.closedOrganization')}>
                            <PrivacyTipIcon />
                        </CustomTooltip>
                    )}
                    {isShowFormsList && (
                        <CustomTooltip title={t('superadmin.organizations.isShowFormsList')}>
                            <ListAltIcon />
                        </CustomTooltip>
                    )}
                    {isIntegrationFormEnabled && (
                        <CustomTooltip title={t('superadmin.organizations.isIntegrationFormEnabled')}>
                            <WordPressSvgIcon />
                        </CustomTooltip>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default OrganizationTile;
