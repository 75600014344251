import React, {FC} from 'react';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {CLIENT_BACKGROUND_COLOR, MAIN_CLIENT_COLOR} from 'config/theme';

interface TextAreaProgressCircleType {
    leftChars: number;
    progress: number;
}

const TextAreaProgressCircle: FC<TextAreaProgressCircleType> = ({leftChars, progress}) => {
    const [t] = useTranslation();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                position: 'absolute',
                top: -5,
                right: 5,
            }}
        >
            <Typography sx={{fontSize: 12}}>
                {t('requester.casePage.charsLeftLabel').replace('%NUMBER%', String(leftChars))}
            </Typography>
            <Box sx={{width: 20, height: 20, ml: 1}}>
                <CircularProgressbar
                    value={progress}
                    strokeWidth={20}
                    styles={{
                        ...buildStyles({
                            pathColor: MAIN_CLIENT_COLOR,
                            trailColor: CLIENT_BACKGROUND_COLOR,
                        }),
                    }}
                />
            </Box>
        </Box>
    );
};

export default TextAreaProgressCircle;
