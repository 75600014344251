import React, {FC} from 'react';

import Menu from '@mui/material/Menu';

import MenuContent from 'components/TopBarMenu/MenuContent';

import {theme} from 'config/theme';

interface ContextMenuType {
    open: boolean;
    anchorEl: Element | (() => Element) | null | undefined;
    handleClose: () => void;
}

const ContextMenu: FC<ContextMenuType> = ({open, anchorEl, handleClose}) => {
    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    mt: 1,
                    width: 287,
                    borderRadius: 2,
                    overflow: 'visible',
                    top: 10,
                    boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                    backgroundColor: theme.palette.background.default,
                    '& ul': {
                        padding: 0,
                    },
                },
            }}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        >
            <MenuContent />
        </Menu>
    );
};

export default ContextMenu;
