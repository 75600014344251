import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const GoogleSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.695 6.90998H8.105V9.22499H11.325C11.025 10.7 9.77 11.545 8.105 11.545C7.1648 11.545 6.26312 11.1715 5.5983 10.5067C4.93349 9.84186 4.56 8.94018 4.56 7.99998C4.56 7.05979 4.93349 6.15811 5.5983 5.49329C6.26312 4.82847 7.1648 4.45498 8.105 4.45498C8.91063 4.45612 9.69121 4.73515 10.315 5.24498L12.06 3.49998C11.3315 2.85748 10.4578 2.40147 9.51414 2.17113C8.57047 1.94079 7.58498 1.94301 6.64235 2.1776C5.69972 2.4122 4.82815 2.87215 4.10252 3.51793C3.37689 4.16372 2.8189 4.97603 2.47649 5.88506C2.13409 6.79409 2.01752 7.77266 2.13678 8.73669C2.25605 9.70072 2.6076 10.6214 3.16121 11.4196C3.71483 12.2177 4.45396 12.8696 5.31509 13.319C6.17623 13.7685 7.13362 14.0022 8.105 14C11.105 14 13.83 11.82 13.83 7.99998C13.827 7.63267 13.7817 7.26692 13.695 6.90998Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default GoogleSvgIcon;
