import {
    RESEND_EMAIL,
    GET_USER_INFO,
    SET_LANGUAGE,
    GET_FILE_URL,
    PROFILE_ERROR_CLEAR,
    UPDATE_GENERAL_INFORMATION,
    UPDATE_NICKNAME_ANONYMOUS,
    UPDATE_ANONYMOUS_WITHOUT_ACCOUNT,
    UPDATE_NICKNAME,
    UPDATE_DEFAULT_ROLE,
    UPDATE_PASSWORD,
    TWO_FACTOR_STATUS_SWITCH,
    GET_TWO_FACTOR_QR_CODE,
    SET_STANDARD_AVATAR,
    GET_AVATAR,
    UPLOAD_AVATAR,
    DELETE_AVATAR,
    DELETE_USER_ACCOUNT,
    USER_ACCOUNT_EXPORT_LIST,
    USER_ACCOUNT_EXPORT_GENERATE,
    USER_ACCOUNT_EXPORT_DOWNLOAD,
} from 'appRedux/actions/profile';
import {
    MainProfileResponseTypes,
    TwoFactorActivateTypes,
    UploadAvatarResponseTypes,
    QrCodeResponseTypes,
    UserAccountExportTypes,
} from 'appRedux/actions/profile/types';
import {OrganizationItemBasic} from 'appRedux/actions/organization/types';

export const initialState: ProfileState = {
    isLoading: false,
    profile: undefined,
    avatarLink: null,
    twoFactor: undefined,
    accountExport: null,
    errors: undefined,
};

export interface ProfileState {
    isLoading: boolean;
    profile: MainProfileResponseTypes | undefined;
    avatarLink: string | null;
    twoFactor: QrCodeResponseTypes | undefined;
    accountExport: UserAccountExportTypes | null;
    errors: IErrors | undefined;
}

const profile = (
    state = initialState,
    action: IActionType<
        | MainProfileResponseTypes
        | TwoFactorActivateTypes
        | UploadAvatarResponseTypes
        | QrCodeResponseTypes
        | UserAccountExportTypes
        | OrganizationItemBasic[]
        | IErrors
    >,
): ProfileState => {
    switch (action.type) {
        case GET_USER_INFO.REQUEST:
        case RESEND_EMAIL.REQUEST:
        case SET_LANGUAGE.REQUEST:
        case GET_FILE_URL.REQUEST:
        case UPDATE_GENERAL_INFORMATION.REQUEST:
        case UPDATE_NICKNAME.REQUEST:
        case UPDATE_NICKNAME_ANONYMOUS.REQUEST:
        case UPDATE_ANONYMOUS_WITHOUT_ACCOUNT.REQUEST:
        case UPDATE_DEFAULT_ROLE.REQUEST:
        case GET_TWO_FACTOR_QR_CODE.REQUEST:
        case TWO_FACTOR_STATUS_SWITCH.REQUEST:
        case SET_STANDARD_AVATAR.REQUEST:
        case GET_AVATAR.REQUEST:
        case UPLOAD_AVATAR.REQUEST:
        case DELETE_AVATAR.REQUEST:
        case DELETE_USER_ACCOUNT.REQUEST:
        case UPDATE_PASSWORD.REQUEST:
        case USER_ACCOUNT_EXPORT_LIST.REQUEST:
        case USER_ACCOUNT_EXPORT_GENERATE.REQUEST:
        case USER_ACCOUNT_EXPORT_DOWNLOAD.REQUEST: {
            return {
                ...state,
                isLoading: true,
                errors: undefined,
            };
        }

        case GET_TWO_FACTOR_QR_CODE.SUCCESS:
            return {
                ...state,
                isLoading: false,
                twoFactor: action.payload as QrCodeResponseTypes,
            };

        case PROFILE_ERROR_CLEAR.REQUEST:
            return {
                ...state,
                errors: undefined,
            };

        case GET_USER_INFO.SUCCESS:
        case RESEND_EMAIL.SUCCESS:
        case SET_LANGUAGE.SUCCESS:
        case UPDATE_GENERAL_INFORMATION.SUCCESS:
        case UPDATE_NICKNAME.SUCCESS:
        case UPDATE_ANONYMOUS_WITHOUT_ACCOUNT.SUCCESS:
        case UPDATE_DEFAULT_ROLE.SUCCESS:
        case UPDATE_NICKNAME_ANONYMOUS.SUCCESS:
        case TWO_FACTOR_STATUS_SWITCH.SUCCESS:
        case SET_STANDARD_AVATAR.SUCCESS:
        case UPDATE_PASSWORD.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                profile: {...state.profile, ...(action.payload as MainProfileResponseTypes)},
            };
        }

        case GET_AVATAR.SUCCESS:
        case UPLOAD_AVATAR.SUCCESS: {
            return {
                ...state,
                ...(action.payload as UploadAvatarResponseTypes),
                isLoading: false,
            };
        }

        case GET_USER_INFO.ACTIVATE: {
            return {
                ...state,
                isLoading: false,
                profile: {...state.profile, ...(action.payload as MainProfileResponseTypes)},
            };
        }

        case GET_USER_INFO.CLEAR: {
            return {
                ...state,
                isLoading: false,
                profile: undefined,
            };
        }

        case DELETE_AVATAR.SUCCESS: {
            return {
                ...state,
                profile: {...state.profile, ...(action.payload as MainProfileResponseTypes)},
                avatarLink: null,
                isLoading: false,
            };
        }

        case USER_ACCOUNT_EXPORT_LIST.SUCCESS:
        case USER_ACCOUNT_EXPORT_GENERATE.SUCCESS: {
            return {
                ...state,
                accountExport: action.payload as UserAccountExportTypes,
                isLoading: false,
            };
        }

        case DELETE_USER_ACCOUNT.SUCCESS:
        case GET_FILE_URL.SUCCESS:
        case USER_ACCOUNT_EXPORT_DOWNLOAD.SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case GET_USER_INFO.ERROR:
        case RESEND_EMAIL.ERROR:
        case GET_FILE_URL.ERROR:
        case GET_TWO_FACTOR_QR_CODE.ERROR:
        case TWO_FACTOR_STATUS_SWITCH.ERROR:
        case SET_LANGUAGE.ERROR:
        case UPDATE_GENERAL_INFORMATION.ERROR:
        case UPDATE_NICKNAME.ERROR:
        case UPDATE_NICKNAME_ANONYMOUS.ERROR:
        case UPDATE_DEFAULT_ROLE.ERROR:
        case UPDATE_ANONYMOUS_WITHOUT_ACCOUNT.ERROR:
        case SET_STANDARD_AVATAR.ERROR:
        case UPLOAD_AVATAR.ERROR:
        case GET_AVATAR.ERROR:
        case DELETE_AVATAR.ERROR:
        case DELETE_USER_ACCOUNT.ERROR:
        case UPDATE_PASSWORD.ERROR:
        case USER_ACCOUNT_EXPORT_LIST.ERROR:
        case USER_ACCOUNT_EXPORT_GENERATE.ERROR:
        case USER_ACCOUNT_EXPORT_DOWNLOAD.ERROR: {
            return {
                ...state,
                isLoading: false,
                errors: (action.payload as IErrors) || {},
            };
        }

        default:
            return state;
    }
};

export default profile;
