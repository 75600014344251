import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const MinusSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 7.5H4V8.5H12V7.5Z" fill={getIconColor(isActive, isDisabled)} />
        </svg>
    );
};

export default MinusSvgIcon;
