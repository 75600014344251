import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {OrganizationIntegrationTokenTypes} from 'appRedux/actions/organizationIntegrationTokens/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import UpdateOrganizationIntegrationTokenForm from 'components/Forms/OrganizationIntegrationTokenForm/UpdateOrganizationIntegrationTokenForm';

interface EditOrganizationTokenButtonType {
    item: OrganizationIntegrationTokenTypes;
}

const EditOrganizationTokenButton: FC<EditOrganizationTokenButtonType> = ({item}) => {
    const [t] = useTranslation();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    return (
        <>
            <IconButton
                title={t('superadmin.organizations.organizationTokens.updateOrganizationToken')}
                onClick={() => setShowModal(true)}
            >
                <EditSvgIcon />
            </IconButton>
            <ModalWrapper
                isShowModal={showModal}
                title={t('superadmin.organizations.organizationTokens.updateOrganizationToken')}
                toggleModal={toggleModal}
            >
                <UpdateOrganizationIntegrationTokenForm item={item} closeModal={() => setShowModal(false)} />
            </ModalWrapper>
        </>
    );
};

export default EditOrganizationTokenButton;
