import React, {FC, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {get} from 'lodash';

import Box from '@mui/material/Box';
import {IconButton, Typography} from '@mui/material';

import {RootReducer} from 'appRedux/reducers';
import {
    DELETE_ORGANIZATION_PDF_ATTACHMENT,
    ORGANIZATION_PDF_TEMPLATES_LIST,
    UPLOAD_ORGANIZATION_PDF_ATTACHMENT,
} from 'appRedux/actions/organization';
import {OrganizationPdfAttachmentTypes} from 'appRedux/actions/organization/types';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {AdminContext} from 'contexts/admin/context';
import {RouteContext} from 'contexts/route/context';
import {PermissionContext} from 'contexts/permission/context';
import {AlertContext} from 'contexts/alert/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';
import {WORKFLOWS_LIST} from 'components/AgentScreenComponents/helper';

import ChangeLanguageButton from 'pages/admin/updateWorkflow/partials/ChangeLanguageButton';
import UploadOptionFile from 'pages/admin/updatePdfTemplate/UploadPdfTemplateFile';
import AttachmentPagePreview from 'pages/admin/updatePdfTemplate/AttachmentPagePreview';
import UploadPdfTemplateInfo from 'pages/admin/updatePdfTemplate/UploadPdfTemplateInfo';

import {getLanguageById} from 'helpers/translationsHelper';

import {PARAMETER_LANGUAGE, PARAMETER_TAB, routes} from 'config/index';

const UpdatePdfTemplate: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {uuid} = useParams();
    const [searchParams] = useSearchParams();

    const {myOrganization} = useContext(AdminContext);
    const {showAlert} = useContext(AlertContext);
    const {isOrganizationEditEnabled} = useContext(PermissionContext);
    const {setBackLink, setBackLinkLabel, setTopBarTitle, setTopBarSubtitle, isAgentPage} = useContext(RouteContext);

    const getOrganizationPdfTemplatesList = useCallback(
        data => dispatch({type: ORGANIZATION_PDF_TEMPLATES_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const updateOrganizationPdfAttachment = useCallback(
        data => dispatch({type: UPLOAD_ORGANIZATION_PDF_ATTACHMENT.REQUEST, payload: data}),
        [dispatch],
    );

    const deleteOrganizationPdfAttachment = useCallback(
        data => dispatch({type: DELETE_ORGANIZATION_PDF_ATTACHMENT.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {languageList, organizationPdfTemplates},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const currentOrganizationDefaultLanguage = useMemo(
        () => (myOrganization ? getLanguageById(languageList, myOrganization.language) : null),
        [myOrganization, languageList],
    );

    const currentLanguage = useMemo(() => {
        const languageFromUrl = searchParams.get(PARAMETER_LANGUAGE);
        return languageFromUrl
            ? languageList.find(item => item.name === languageFromUrl)
            : currentOrganizationDefaultLanguage;
    }, [languageList, currentOrganizationDefaultLanguage, searchParams]);

    const currentTemplate = useMemo(
        () => organizationPdfTemplates.find(template => template.uuid === uuid),
        [uuid, organizationPdfTemplates],
    );

    useEffect(() => {
        if (myOrganization) {
            getOrganizationPdfTemplatesList({
                id: myOrganization.id,
                showAlert,
            });
        }
    }, [myOrganization]);

    useEffect(() => {
        const path = isAgentPage ? routes.AGENT : routes.ADMIN;
        setBackLink(`${path}?${PARAMETER_TAB}=${WORKFLOWS_LIST}`);
        setBackLinkLabel(t('common.links.backToWorkflows'));
        setTopBarTitle('');
        setTopBarSubtitle('');
    }, []);

    const [frontPage, setFrontPage] = useState<OrganizationPdfAttachmentTypes | null>(null);
    const [contentPage, setContentPage] = useState<OrganizationPdfAttachmentTypes | null>(null);
    const [attachments, setAttachments] = useState<OrganizationPdfAttachmentTypes[]>([]);

    useEffect(() => {
        const currentAttachments = currentLanguage && currentTemplate?.attachments[currentLanguage.name];

        if (currentAttachments) {
            console.log('currentAttachments', currentAttachments);
            setFrontPage(currentAttachments.find(attachment => attachment.isFrontPage) || null);
            setContentPage(currentAttachments.find(attachment => attachment.isContentPage) || null);
            setAttachments(
                currentAttachments.filter(attachment => !attachment.isFrontPage && !attachment.isContentPage),
            );
        } else {
            setFrontPage(null);
            setContentPage(null);
            setAttachments([]);
        }
    }, [currentLanguage, currentTemplate]);

    const uploadFrontPage = async (file: File) => {
        updateOrganizationPdfAttachment({
            uuid,
            file,
            title: file.name,
            isFrontPage: true,
            language: currentLanguage?.id,
            showAlert,
        });

        const reader = new FileReader();
        reader.readAsDataURL(file as Blob);
        reader.addEventListener('load', async e => {
            const fileContent = get(e, ['currentTarget', 'result'], null) as string | null;
            fileContent && setFrontPage({link: fileContent, title: file.name});
        });
    };

    const uploadContentPage = async (file: File) => {
        updateOrganizationPdfAttachment({
            uuid,
            file,
            title: file.name,
            isContentPage: true,
            language: currentLanguage?.id,
            showAlert,
        });

        const reader = new FileReader();
        reader.readAsDataURL(file as Blob);
        reader.addEventListener('load', async e => {
            const fileContent = get(e, ['currentTarget', 'result'], null) as string | null;
            fileContent && setContentPage({link: fileContent, title: file.name});
        });
    };

    const uploadAttachment = async (file: File) => {
        updateOrganizationPdfAttachment({
            uuid,
            file,
            title: file.name,
            language: currentLanguage?.id,
            showAlert,
        });
    };

    const deleteAttachment = (uuid?: string) => async () => {
        if (!uuid) return;
        deleteOrganizationPdfAttachment({
            uuid,
            showAlert,
        });
    };

    if (!isOrganizationEditEnabled || !currentOrganizationDefaultLanguage || !currentLanguage || !currentTemplate) {
        return null;
    }

    return (
        <ClientSectionWrapper>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 3,
                }}
            >
                <UploadPdfTemplateInfo currentTemplate={currentTemplate} />
                <ChangeLanguageButton organizationLanguage={currentOrganizationDefaultLanguage} />
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    mx: '5%',
                    mb: 5,
                }}
            >
                <Box sx={{width: '45%'}}>
                    <UploadOptionFile
                        title={t('Front page template')}
                        uploadFile={uploadFrontPage}
                        fileName={frontPage?.title}
                        deleteFile={deleteAttachment(frontPage?.uuid)}
                    />
                    <AttachmentPagePreview attachment={frontPage} />
                </Box>
                <Box sx={{width: '45%'}}>
                    <UploadOptionFile
                        title={t('Content page template')}
                        uploadFile={uploadContentPage}
                        fileName={contentPage?.title}
                        deleteFile={deleteAttachment(contentPage?.uuid)}
                    />
                    <AttachmentPagePreview attachment={contentPage} />
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    mx: '5%',
                    mb: 3,
                }}
            >
                <UploadOptionFile title={t('Additional PDF attachments')} uploadFile={uploadAttachment} />
                {attachments.map(attachment => (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            mb: 1,
                        }}
                        key={attachment.uuid}
                    >
                        <Typography>{attachment.title}</Typography>
                        <IconButton data-id={`button#option-file-delete`} onClick={deleteAttachment(contentPage?.uuid)}>
                            <DeleteSvgIcon />
                        </IconButton>
                    </Box>
                ))}
            </Box>
        </ClientSectionWrapper>
    );
};

export default UpdatePdfTemplate;
