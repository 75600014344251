import {fork, put, take, call} from 'redux-saga/effects';

import {http} from 'services/http';

import {
    GET_NOTIFICATIONS_LIST,
    getNotificationsList,
    CREATE_NOTIFICATION,
    createNotification,
    REVIEW_NOTIFICATION,
    reviewNotification,
    REMOVE_NOTIFICATION,
    removeNotification,
    REMOVE_ALL_NOTIFICATIONS,
    removeAllNotifications,
} from 'appRedux/actions/notifications';
import {
    NotificationResponseType,
    NotificationRequestType,
    NotificationRemoveAllRequestType,
} from 'appRedux/actions/notifications/types';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchGetNotificationsList() {
    while (true) {
        yield take(GET_NOTIFICATIONS_LIST.REQUEST);
        try {
            const data: NotificationResponseType = yield call(http, `notifications`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getNotificationsList.success(data.results));
            } else if (data.errors) {
                yield put(getNotificationsList.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getNotificationsList.error({message: String(e)}));
        }
    }
}

function* watchNotificationCreation() {
    while (true) {
        const {
            payload: {showAlert, callback, ...values},
        }: IActionType<NotificationRequestType> = yield take(CREATE_NOTIFICATION.REQUEST);
        try {
            const data: NotificationResponseType = yield call(http, `notification/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createNotification.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS, 'messages.success.translationRequestCreated');
            } else if (data.errors) {
                yield put(createNotification.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createNotification.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchReviewNotification() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid},
        }: IActionType<NotificationRequestType> = yield take(REVIEW_NOTIFICATION.REQUEST);
        try {
            const data: NotificationResponseType = yield call(http, `notification/${uuid}/review`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(reviewNotification.success(data.results));
                callback && callback();
            } else if (data.errors) {
                yield put(reviewNotification.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(reviewNotification.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchNotificationRemoving() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid},
        }: IActionType<NotificationRequestType> = yield take(REMOVE_NOTIFICATION.REQUEST);
        try {
            const data: NotificationResponseType = yield call(http, `notification/${uuid}/remove`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(removeNotification.success(data.results));
                callback && callback();
            } else if (data.errors) {
                yield put(removeNotification.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(removeNotification.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchAllNotificationsRemoving() {
    while (true) {
        const {
            payload: {showAlert, callback},
        }: IActionType<NotificationRemoveAllRequestType> = yield take(REMOVE_ALL_NOTIFICATIONS.REQUEST);
        try {
            const data: NotificationResponseType = yield call(http, `notifications/remove`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(removeAllNotifications.success(data.results));
                callback && callback();
            } else if (data.errors) {
                yield put(removeAllNotifications.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(removeAllNotifications.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchGetNotificationsList),
    fork(watchNotificationCreation),
    fork(watchReviewNotification),
    fork(watchNotificationRemoving),
    fork(watchAllNotificationsRemoving),
];
