import React, {FC, useEffect, useRef} from 'react';

import Box from '@mui/material/Box';

import {CommonFileType} from 'pages/agent/requesterPage/partials/helper';

import {theme} from 'config/theme';
import {SxProps, Typography} from '@mui/material';

interface GalleryPreviewType {
    isOpened: boolean;
    file: CommonFileType;
    thumbLink: string | null | undefined;
    getModalContent: (file: CommonFileType) => void;
    sx: SxProps;
}

const GalleryPreview: FC<GalleryPreviewType> = ({isOpened, file, thumbLink, getModalContent, sx}) => {
    const itemRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (isOpened)
            itemRef.current && itemRef.current.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
    }, [isOpened]);
    return (
        <Box
            ref={itemRef}
            key={file.uuid}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 2,
                overflow: 'hidden',
                cursor: 'pointer',
                backgroundColor: theme.palette.info.light,
                boxShadow: isOpened ? '0 0 5px white' : 'none',
                border: isOpened ? '1px solid white' : 'none',
                ...sx,
            }}
            onClick={() => getModalContent(file)}
        >
            {thumbLink ? (
                <img src={thumbLink || ''} style={{width: '100%'}} />
            ) : (
                <Typography
                    sx={{
                        color: 'white',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '130px',
                        overflow: 'hidden',
                    }}
                >
                    {file.title}
                </Typography>
            )}
        </Box>
    );
};

export default GalleryPreview;
