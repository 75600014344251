import React, {FC} from 'react';

interface MenuIconType {
    isActive: boolean;
}

const ActiveCasesSvgIcon: FC<MenuIconType> = ({isActive}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.98125 3.75L9.11875 5.88125L9.48125 6.25H17.5V16.25H2.5V3.75H6.98125ZM6.98125 2.5H2.5C2.16848 2.5 1.85054 2.6317 1.61612 2.86612C1.3817 3.10054 1.25 3.41848 1.25 3.75V16.25C1.25 16.5815 1.3817 16.8995 1.61612 17.1339C1.85054 17.3683 2.16848 17.5 2.5 17.5H17.5C17.8315 17.5 18.1495 17.3683 18.3839 17.1339C18.6183 16.8995 18.75 16.5815 18.75 16.25V6.25C18.75 5.91848 18.6183 5.60054 18.3839 5.36612C18.1495 5.1317 17.8315 5 17.5 5H10L7.86875 2.86875C7.75246 2.75177 7.61418 2.65896 7.46185 2.59567C7.30953 2.53239 7.14619 2.49987 6.98125 2.5V2.5Z"
                fill={isActive ? 'white' : '#222222'}
                fillOpacity="0.9"
            />
        </svg>
    );
};

export default ActiveCasesSvgIcon;
