import React, {FC} from 'react';

interface MenuIconType {
    isActive: boolean;
}

const DeclinedCasesSvgIcon: FC<MenuIconType> = ({isActive}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.64324 1.90165C7.70807 1.46446 8.8491 1.24301 10.0002 1.25017C11.1512 1.24301 12.2923 1.46446 13.3571 1.90165C14.4219 2.33885 15.3894 2.98309 16.2033 3.79704C17.0172 4.61098 17.6615 5.57841 18.0987 6.64324C18.5359 7.70807 18.7573 8.8491 18.7502 10.0002C18.7573 11.1512 18.5359 12.2923 18.0987 13.3571C17.6615 14.4219 17.0172 15.3894 16.2033 16.2033C15.3894 17.0172 14.4219 17.6615 13.3571 18.0987C12.2923 18.5359 11.1512 18.7573 10.0002 18.7502C8.8491 18.7573 7.70807 18.5359 6.64324 18.0987C5.57841 17.6615 4.61098 17.0172 3.79704 16.2033C2.98309 15.3894 2.33885 14.4219 1.90165 13.3571C1.46446 12.2923 1.24301 11.1512 1.25017 10.0002C1.24301 8.8491 1.46446 7.70807 1.90165 6.64324C2.33885 5.57841 2.98309 4.61098 3.79704 3.79704C4.61098 2.98309 5.57841 2.33885 6.64324 1.90165ZM5.83339 16.2362C7.06676 17.0603 8.51681 17.5002 10.0002 17.5002C11.9875 17.4943 13.8918 16.7023 15.297 15.297C16.7023 13.8918 17.4943 11.9875 17.5002 10.0002C17.5002 8.51681 17.0603 7.06676 16.2362 5.83339C15.4121 4.60002 14.2407 3.63873 12.8703 3.07107C11.4998 2.50341 9.99185 2.35489 8.53699 2.64428C7.08213 2.93367 5.74576 3.64797 4.69687 4.69687C3.64797 5.74576 2.93367 7.08213 2.64428 8.53699C2.35489 9.99185 2.50341 11.4998 3.07107 12.8703C3.63873 14.2407 4.60002 15.4121 5.83339 16.2362ZM5.62523 6.59729L6.59732 5.6252L14.3752 13.4031L13.4031 14.3752L5.62523 6.59729Z"
                fill={isActive ? '#ffffff' : '#222222'}
            />
        </svg>
    );
};

export default DeclinedCasesSvgIcon;
