import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import {FONT_FAMILY_OPEN_SANS} from 'config/theme';

interface DeleteClientConfirmationModalType {
    isShowModal: boolean;
    toggleModal: () => void;
    customTitle?: string;
    description?: string;
    deletingRestrict?: boolean;
    onClicked?: boolean;
    onClickAction: () => void;
}

const DeleteClientConfirmationModal: FC<DeleteClientConfirmationModalType> = ({
    isShowModal,
    toggleModal,
    customTitle,
    description,
    deletingRestrict,
    onClicked,
    onClickAction,
}) => {
    const [t] = useTranslation();
    return (
        <ModalWrapper
            title={
                deletingRestrict
                    ? t('common.deletePopup.deletingRestrict')
                    : t(customTitle ?? 'messages.other.areYouSureRemove')
            }
            isShowModal={isShowModal}
            toggleModal={toggleModal}
        >
            {description && (
                <Typography variant="body1" sx={{mb: 3, fontFamily: FONT_FAMILY_OPEN_SANS}}>
                    {description}
                </Typography>
            )}
            {deletingRestrict ? (
                <AgentSaveButton isSubmit={false} title={t('common.buttons.close')} onClick={toggleModal} />
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <AgentInverseButton
                        title={t('common.buttons.cancel')}
                        onClick={toggleModal}
                        isLoading={onClicked}
                    />
                    <AgentSaveButton
                        onClick={onClickAction}
                        isLoading={onClicked}
                        isSubmit={false}
                        title={t('common.buttons.delete')}
                    />
                </Box>
            )}
        </ModalWrapper>
    );
};

export default DeleteClientConfirmationModal;
