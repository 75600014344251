import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';
import {FormAllowListTypes} from 'appRedux/actions/forms/types';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

import FormAllowListEmailCreateButton from 'pages/admin/updateForm/partials/FormAllowlist/FormAllowListEmailCreateButton';
import FormAllowListItem from 'pages/admin/updateForm/partials/FormAllowlist/FormAllowListItem';

const FormAllowListComponent: FC = () => {
    const [t] = useTranslation();

    const {
        admin: {formInfo},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const allowListEmails: FormAllowListTypes[] = get(formInfo, 'emails', []);

    return (
        <ClientSectionWrapper title={t('orguser.forms.allowList.allowListTitle')}>
            {allowListEmails &&
                allowListEmails.map((item: FormAllowListTypes) => {
                    return <FormAllowListItem key={`form-allow-list-${item.uuid}`} item={item} />;
                })}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    mt: 2,
                }}
            >
                <FormAllowListEmailCreateButton title={t('orguser.forms.allowList.addEmail')} />
                <FormAllowListEmailCreateButton title={t('orguser.forms.allowList.addDomain')} forDomain />
            </Box>
        </ClientSectionWrapper>
    );
};

export default FormAllowListComponent;
