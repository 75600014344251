import {call, fork, put, take} from 'redux-saga/effects';
import get from 'lodash/get';

import {
    UPDATE_CLIENT_TRANSLATION,
    UPDATE_CLIENT_TRANSLATION_ITEM,
    DELETE_CLIENT_TRANSLATION,
    AUTO_CLIENT_TRANSLATION,
    updateClientTranslations,
    updateClientTranslationItem,
    deleteClientTranslations,
    autoClientTranslations,
} from 'appRedux/actions/clientTranslation';
import {
    ClientTranslationUpdateRequestTypes,
    ClientTranslationUpdateResponseTypes,
    ClientTranslationUpdateItemRequestTypes,
    ClientTranslationDeleteRequestTypes,
    ClientAutoTranslationRequestType,
    ClientTranslationTypes,
} from 'appRedux/actions/clientTranslation/types';

import {http} from 'services/http';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchClientTranslationsUpdating() {
    while (true) {
        const {
            payload: {id, callback, showAlert, ...values},
        }: IActionType<ClientTranslationUpdateRequestTypes> = yield take(UPDATE_CLIENT_TRANSLATION.REQUEST);
        try {
            const data: ClientTranslationUpdateResponseTypes = yield call(http, `client/translations/${id}/edit`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                const results: ClientTranslationTypes[] = get(data, 'results', []);
                yield put(updateClientTranslations.success(results));
                callback();
                showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateClientTranslations.error({message: 'messages.error.somethingWentWrong'}));
                showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateClientTranslations.error({message: String(e)}));
            showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchClientTranslationItemUpdating() {
    while (true) {
        const {
            payload: {id, callback, showAlert, ...values},
        }: IActionType<ClientTranslationUpdateItemRequestTypes> = yield take(UPDATE_CLIENT_TRANSLATION_ITEM.REQUEST);
        try {
            const data: ClientTranslationUpdateResponseTypes = yield call(http, `client/translation/${id}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                const results: ClientTranslationTypes[] = get(data, 'results', []);
                yield put(updateClientTranslationItem.success(results));
                callback();
                showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateClientTranslationItem.error({message: 'messages.error.somethingWentWrong'}));
                showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateClientTranslationItem.error({message: String(e)}));
            showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchClientTranslationsDeleting() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert},
        }: IActionType<ClientTranslationDeleteRequestTypes> = yield take(DELETE_CLIENT_TRANSLATION.REQUEST);
        try {
            const data: ClientTranslationUpdateResponseTypes = yield call(http, `client/translations/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                const results: ClientTranslationTypes[] = get(data, 'results', []);
                yield put(deleteClientTranslations.success(results));
                showAlert(ALERT_TYPE_SUCCESS);
                callback();
            } else {
                yield put(deleteClientTranslations.error({message: 'messages.error.somethingWentWrong'}));
                showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteClientTranslations.error({message: String(e)}));
            showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchAutoClientTranslation() {
    while (true) {
        const {
            payload: {callback, showAlert, id, ...values},
        }: IActionType<ClientAutoTranslationRequestType> = yield take(AUTO_CLIENT_TRANSLATION.REQUEST);
        try {
            const data: ClientTranslationUpdateResponseTypes = yield call(http, `client/translations/${id}/auto`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                const results: ClientTranslationTypes[] = get(data, 'results', []);
                yield put(autoClientTranslations.success(results));
                showAlert(ALERT_TYPE_SUCCESS);
                callback();
            } else {
                yield put(autoClientTranslations.error({message: 'messages.error.somethingWentWrong'}));
                showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(autoClientTranslations.error({message: String(e)}));
            showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchClientTranslationsUpdating),
    fork(watchClientTranslationItemUpdating),
    fork(watchClientTranslationsDeleting),
    fork(watchAutoClientTranslation),
];
