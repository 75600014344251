import React, {FC, ReactNode, useContext} from 'react';

import Box from '@mui/material/Box';

import {MediaContext} from 'contexts/media/context';

import {CommonFileType} from 'pages/agent/requesterPage/partials/helper';

import {CLIENT_BACKGROUND_COLOR, theme} from 'config/theme';
import GalleryPreview from './GalleryPreview';

interface DesktopGalleryType {
    selectedFile: CommonFileType;
    thumbLinks: (string | null | undefined)[];
    allFiles: CommonFileType[];
    getModalContent?: (file: CommonFileType) => void;
    children: ReactNode;
}

const DesktopGallery: FC<DesktopGalleryType> = ({selectedFile, allFiles, thumbLinks, getModalContent, children}) => {
    const {isLargeDesktop, isTablet} = useContext(MediaContext);

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                flex: '1 1 0px',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'start',
                gap: 0.5,
                p: 2,
                backgroundColor: theme.palette.info.dark,
            }}
        >
            {getModalContent && allFiles.length > 1 && (
                <Box
                    sx={{
                        position: 'relative',
                        width: isTablet ? '120px' : '170px',
                        height: 'calc(100vh - 60px)',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        paddingY: 2,
                        my: -2,
                        '-ms-overflow-style': 'none',
                        'scrollbar-width': 'none',
                        '::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}
                >
                    {allFiles.map((file, i) => (
                        <GalleryPreview
                            key={file.uuid}
                            file={file}
                            thumbLink={thumbLinks[i]}
                            getModalContent={getModalContent}
                            isOpened={selectedFile.uuid === file.uuid}
                            sx={{
                                width: isTablet ? '100px' : '150px',
                                height: isTablet ? '80px' : '120px',
                                marginBottom: isTablet ? 1 : 2,
                                ml: '3px',
                            }}
                        />
                    ))}
                </Box>
            )}
            <Box
                sx={{
                    position: 'relative',
                    flex: '1 1 0px',
                    height: 'calc(100vh - 100px)',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: CLIENT_BACKGROUND_COLOR,
                    borderRadius: 2,
                    overflow: 'hidden',
                    maxWidth: isLargeDesktop ? '95%' : '100%',
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default DesktopGallery;
