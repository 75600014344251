import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {OrganizationPdfTemplatesTypes} from 'appRedux/actions/organization/types';

import DeletePdfTemplateButton from 'pages/admin/organizationPdfTemplates/DeletePdfTemplateButton';

import {routes} from 'config/index';

interface PdfTemplatesRowType {
    item: OrganizationPdfTemplatesTypes;
    currentUserLanguage?: string;
    currentOrganizationLanguage?: LanguageItemTypes;
}

const PdfTemplatesRow: FC<PdfTemplatesRowType> = ({item}) => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const {title, description, uuid} = item;

    const redirectToWorkflow = () => {
        navigate(`${routes.PDF_TEMPLATE}/${uuid}/edit`);
    };

    return (
        <TableRow>
            <TableCell>
                <Typography>{title}</Typography>
            </TableCell>
            <TableCell>{description}</TableCell>
            <TableCell width={150}>
                <IconButton title={t('common.buttons.update')} onClick={redirectToWorkflow}>
                    <EditSvgIcon />
                </IconButton>

                <DeletePdfTemplateButton item={item} />
            </TableCell>
        </TableRow>
    );
};

export default PdfTemplatesRow;
