import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {ORGANIZATION_TEMPLATE_CREATE} from 'appRedux/actions/organization';
import {OrganizationTemplateFormType} from 'appRedux/actions/organization/types';

import {AlertContext} from 'contexts/alert/context';

import OrganizationTemplateForm from 'components/Forms/OrganizationTemplateForm/OrganizationTemplateForm';

interface ComponentFormType {
    organizationId: number;
    currentLanguage: number;
    type: number;
    subject: string;
    text: string;
    closeModal: () => void;
}

const OrganizationTemplateCreateForm: FC<ComponentFormType> = ({
    currentLanguage,
    type,
    subject,
    text,
    organizationId,
    closeModal,
}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const createOrganizationTemplates = useCallback(
        data => dispatch({type: ORGANIZATION_TEMPLATE_CREATE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: OrganizationTemplateFormType) => {
        createOrganizationTemplates({
            ...values,
            id: organizationId,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <OrganizationTemplateForm
            onSubmitClicked={onSubmitClicked}
            initialValues={{
                type,
                subject,
                text,
                language: currentLanguage,
            }}
        />
    );
};

export default OrganizationTemplateCreateForm;
