import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import CreateOrganizationIntegrationTokenForm from 'components/Forms/OrganizationIntegrationTokenForm/CreateOrganizationIntegrationTokenForm';

interface AddOrganizationTokenType {
    organizationId: number;
}

const AddOrganizationToken: FC<AddOrganizationTokenType> = ({organizationId}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                mb: 2,
            }}
        >
            <AgentInverseButton
                icon={<PlusSvgIcon />}
                title={t('superadmin.organizations.organizationTokens.addOrganizationToken')}
                onClick={() => setShowModal(true)}
            />
            <ModalWrapper
                isShowModal={showModal}
                title={t('superadmin.organizations.organizationTokens.addOrganizationToken')}
                toggleModal={toggleModal}
            >
                <CreateOrganizationIntegrationTokenForm
                    organizationId={organizationId}
                    closeModal={() => setShowModal(false)}
                />
            </ModalWrapper>
        </Box>
    );
};

export default AddOrganizationToken;
