import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {ResourceFieldCalendarFormTypes} from 'appRedux/actions/resourceFields/types';
import {CREATE_RESOURCE_FIELD_CALENDAR} from 'appRedux/actions/resourceFields';

import {AlertContext} from 'contexts/alert/context';

import ResourceFieldCalendarForm from 'components/Forms/ResourceField/ResourceFieldCalendarForm';

interface CreateResourceFieldCalendarFormType {
    uuid: string;
    closeModal: () => void;
}

const CreateResourceFieldCalendarForm: FC<CreateResourceFieldCalendarFormType> = ({uuid, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const createResourceFieldCalendar = useCallback(
        data => dispatch({type: CREATE_RESOURCE_FIELD_CALENDAR.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: ResourceFieldCalendarFormTypes) => {
        createResourceFieldCalendar({
            ...values,
            uuid,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <ResourceFieldCalendarForm
            initialValues={{
                title: '',
                description: '',
            }}
            onSubmitClicked={onSubmitClicked}
        />
    );
};

export default CreateResourceFieldCalendarForm;
