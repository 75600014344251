import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import {RootReducer} from 'appRedux/reducers';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import UpdateUsernameForm from 'components/Forms/SettingsForms/UpdateUsernameForm/UpdateUsernameForm';
import UpdateUsernameFormAnonymous from 'components/Forms/SettingsForms/UpdateUsernameForm/UpdateUsernameFormAnonymous';

import {LOCAL_STORAGE_NICKNAME_POPUP_STATUS} from 'services/localStorage';

interface UpdateUsernamePopupTypes {
    isAnonymousAccount?: boolean;
}

const UpdateUsernamePopup: FC<UpdateUsernamePopupTypes> = ({isAnonymousAccount}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {
        profile: {isLoading, profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        const isNicknamePresented = get(profile, 'nickname', null);
        const isSsoLogin = get(profile, 'isSsoLogin', false);
        const nicknamePopupStatus = localStorage.getItem(LOCAL_STORAGE_NICKNAME_POPUP_STATUS);
        if (!isLoading && !nicknamePopupStatus && !isNicknamePresented && !isSsoLogin) {
            setIsOpened(true);
            localStorage.setItem(LOCAL_STORAGE_NICKNAME_POPUP_STATUS, '1');
        }
    }, []);

    return (
        <ModalWrapper
            title={t(isAnonymousAccount ? 'common.account.createUserAccount' : 'common.account.updateUsername')}
            isShowModal={isOpened}
            toggleModal={isAnonymousAccount ? undefined : toggleIsOpened}
        >
            {isAnonymousAccount ? (
                <UpdateUsernameFormAnonymous closeModal={() => setIsOpened(false)} />
            ) : (
                <UpdateUsernameForm closeModal={() => setIsOpened(false)} />
            )}
        </ModalWrapper>
    );
};

export default UpdateUsernamePopup;
