import * as yup from 'yup';

export const initialValues = {
    title: '',
    iconType: 0,
    workflow: 0,
};

export const initialValuesForList = {
    title: '',
    iconType: 0,
    workflow: 0,
    status: 0,
};

export const initialValuesForOverview = {
    title: '',
    iconType: 0,
    workflow: null,
    boardHasStatuses: [],
};

export const initialValuesForSwimlane = {
    title: '',
    iconType: 0,
    workflow: 0,
    typeSwimlane: 0,
};

export const validationSchema = yup.object({
    title: yup.string().required('messages.validation.isRequired'),
    iconType: yup.number().required('messages.validation.isRequired'),
    workflow: yup.string().required('messages.validation.isRequired'),
});

export const validationSchemaForList = yup.object({
    title: yup.string().required('messages.validation.isRequired'),
    iconType: yup.number().required('messages.validation.isRequired'),
    workflow: yup.string().required('messages.validation.isRequired'),
    status: yup.string().required('messages.validation.isRequired'),
});

export const validationSchemaForOverview = yup.object({
    title: yup.string().required('messages.validation.isRequired'),
    iconType: yup.number().required('messages.validation.isRequired'),
});

export const validationSchemaForSwimlane = yup.object({
    title: yup.string().required('messages.validation.isRequired'),
    iconType: yup.number().required('messages.validation.isRequired'),
    workflow: yup.string().required('messages.validation.isRequired'),
    typeSwimlane: yup.number().required('messages.validation.isRequired'),
});

export const validationUpdateSchema = yup.object({
    title: yup.string().required('messages.validation.isRequired'),
    iconType: yup.number().required('messages.validation.isRequired'),
});

export const validationSchemaForStatusList = yup.object({
    status: yup.string().required('messages.validation.isRequired'),
});
