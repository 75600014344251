import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import {WorkflowStatusRuleItemTypes} from 'appRedux/actions/workflow/types';

import WorkflowRuleApproversGroupForm from 'components/Forms/Workflow/workflowStatusRule/WorkflowRuleApproversGroupForm';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import ApproverGroupsList from 'pages/admin/updateWorkflow/partials/rules/groups/ApproverGroupsList';

interface ApproverGroupsType {
    item: WorkflowStatusRuleItemTypes;
}

const ApproverGroups: FC<ApproverGroupsType> = ({item}) => {
    const [t] = useTranslation();

    const [showApproverForm, setShowApproverForm] = useState<boolean>(false);

    const {groups} = item;

    return (
        <Box>
            <ApproverGroupsList items={groups} />
            {showApproverForm ? (
                <WorkflowRuleApproversGroupForm item={item} closeForm={() => setShowApproverForm(false)} />
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 2,
                    }}
                >
                    <AgentSaveButton
                        isSubmit={false}
                        icon={<PlusSvgIcon isActive />}
                        title={t('orguser.workflows.rules.addApproversGroup')}
                        onClick={() => setShowApproverForm(true)}
                    />
                </Box>
            )}
        </Box>
    );
};

export default ApproverGroups;
