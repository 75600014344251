import React, {FC} from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {createStyles, WithStyles, withStyles} from '@material-ui/styles';

import EmptyArea from 'components/EmptyArea';

import ButtonsPanel from 'pages/admin/updateForm/partials/FormStructure/ButtonsPanel';

const styles = () =>
    createStyles({
        title: {
            fontWeight: 600,
        },
    });

interface TopPanelType extends WithStyles<typeof styles> {
    isFirst?: boolean;
    isLast?: boolean;
    priority?: number;
    isRequired?: boolean;
    typeTitle: string;
    title: string;
    validationType?: string;
    entityId: number;
    onEditClick: () => void;
    onCopyClick: () => void;
    onReplaceClick?: () => void;
    onDeleteClick: () => void;
    onUpClick?: () => void;
    onDownClick?: () => void;
    onRecalculateClick?: () => void;
    viewOnly: boolean;
    showReplaceButton?: boolean;
}

const TopPanel: FC<TopPanelType> = ({
    classes,
    priority,
    typeTitle,
    title,
    validationType,
    isFirst,
    isLast,
    isRequired,
    onEditClick,
    onCopyClick,
    onReplaceClick,
    onDeleteClick,
    onUpClick,
    onDownClick,
    onRecalculateClick,
    viewOnly,
    showReplaceButton,
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                pl: 2,
                pr: 1,
            }}
        >
            {priority ? (
                <Typography variant="body2" sx={{py: 1}}>
                    {`${typeTitle} ${priority} - `}{' '}
                    <span className={classes.title}>{`${title} ${isRequired ? '*' : ''} ${
                        validationType ? ` - ${validationType}` : ''
                    }`}</span>
                </Typography>
            ) : (
                <Typography variant="body2" sx={{py: 1}}>
                    {`${typeTitle} - `}{' '}
                    <span className={classes.title}>{`${title} ${isRequired ? '*' : ''} ${
                        validationType ? `(${validationType})` : ''
                    }`}</span>
                </Typography>
            )}
            <EmptyArea />
            <ButtonsPanel
                isFirst={isFirst}
                isLast={isLast}
                onEditClick={onEditClick}
                onCopyClick={onCopyClick}
                onDeleteClick={onDeleteClick}
                onReplaceClick={onReplaceClick}
                onUpClick={onUpClick}
                onDownClick={onDownClick}
                onRecalculateClick={onRecalculateClick}
                viewOnly={viewOnly}
                showReplaceButton={showReplaceButton}
            />
        </Box>
    );
};

export default withStyles(styles)(TopPanel);
