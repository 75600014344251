import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import {FORM_LANGUAGE_DELETE, FORM_TRANSLATION_EXPORT, FORM_LANGUAGE_STATUS} from 'appRedux/actions/forms';
import {FormHasLanguageTypes} from 'appRedux/actions/forms/types';

import ExportSvgIcon from 'assets/icons/buttons/ExportSvgIcon';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import DeleteAdminConfirmationButton from 'components/AdminScreenComponents/DeleteAdminConfirmationButton';
import {CustomSwitcher} from 'components/AgentScreenComponents/_form/CustomElements';

import ImportTranslationsButton from 'pages/admin/updateForm/partials/FormLanguages/ImportTranslationsButton';

interface LanguageRowType {
    formId: number;
    formTitle: string;
    item: FormHasLanguageTypes;
}

const LanguageRow: FC<LanguageRowType> = ({formId, formTitle, item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isTranslationEditEnabled, isTranslationDeleteEnabled} = useContext(PermissionContext);

    const {
        id,
        isActive,
        language: {name, lang},
    } = item;

    const [languageState, setLanguageState] = useState<boolean>(isActive);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    const exportTranslations = useCallback(
        data => dispatch({type: FORM_TRANSLATION_EXPORT.REQUEST, payload: data}),
        [dispatch],
    );

    const toggleFormLanguageStatus = useCallback(
        data => dispatch({type: FORM_LANGUAGE_STATUS.REQUEST, payload: data}),
        [dispatch],
    );

    const deleteFormLanguage = useCallback(
        data => dispatch({type: FORM_LANGUAGE_DELETE.REQUEST, payload: data}),
        [dispatch],
    );

    const downloadFile = (blob: Blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Form_${formId}_translations_${name}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const toggleStatus = (event: any) => {
        setLanguageState(event.target.checked);
        toggleFormLanguageStatus({
            id,
            showAlert,
        });
    };

    const onTranslateExportClick = () => {
        exportTranslations({
            id: formId,
            lang: name,
            showAlert,
            callbackDownload: downloadFile,
        });
    };

    const onClickDelete = () => {
        deleteFormLanguage({
            id,
            showAlert,
            callback: () => setIsShowModal(false),
        });
    };

    return (
        <Grid container flexDirection="row" alignItems="center" sx={{mb: 0.5}}>
            <Grid item sm={2}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <Typography variant="body2" sx={{fontWeight: 300}}>
                        {lang}
                    </Typography>
                </Box>
            </Grid>
            <Grid item sm={3}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <CustomSwitcher checked={languageState} onClick={toggleStatus} />
                    <Typography variant="body2" sx={{fontWeight: 300, ml: 2}}>
                        {t('orguser.forms.formLanguageSwitcher')}
                    </Typography>
                </Box>
            </Grid>
            <Grid item sm={3}>
                <IconButton
                    title={t('orguser.forms.exportTranslations')}
                    onClick={onTranslateExportClick}
                    disabled={!isTranslationEditEnabled}
                >
                    <ExportSvgIcon isDisabled={!isTranslationEditEnabled} />
                </IconButton>
                <ImportTranslationsButton formId={formId} formTitle={formTitle} language={name} lang={lang} />
                <DeleteAdminConfirmationButton
                    disabled={!isTranslationDeleteEnabled}
                    isShowModal={isShowModal}
                    toggleModal={toggleModal}
                    onClickAction={onClickDelete}
                />
            </Grid>
        </Grid>
    );
};

export default LanguageRow;
