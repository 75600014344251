import React, {FC, useCallback, useContext} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import CloseSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {WorkflowStatusItemTypes, CreateWorkflowStatusRuleFormTypes} from 'appRedux/actions/workflow/types';
import {CREATE_WORKFLOW_STATUS_RULE} from 'appRedux/actions/workflow';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import {WorkflowRuleTypes, workflowRuleTypes} from 'components/Forms/Workflow/workflowStatusRule/helper';

import {theme} from 'config/theme';

interface WorkflowStatusRuleFormType {
    item: WorkflowStatusItemTypes;
    closeForm: () => void;
}

const WorkflowStatusRuleForm: FC<WorkflowStatusRuleFormType> = ({item, closeForm}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        workflow: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onSubmitClicked = useCallback(
        data => dispatch({type: CREATE_WORKFLOW_STATUS_RULE.REQUEST, payload: data}),
        [dispatch],
    );

    const {uuid, rules} = item;

    const onSaveClicked = (values: CreateWorkflowStatusRuleFormTypes) => {
        onSubmitClicked({
            ...values,
            showAlert,
            uuid,
            callback: closeForm,
        });
    };

    return (
        <Formik
            initialValues={{
                type: 0,
            }}
            onSubmit={onSaveClicked}
        >
            {(formik: FormikProps<CreateWorkflowStatusRuleFormTypes>) => {
                return (
                    <Form>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <FormControl sx={{mt: 1}} required fullWidth>
                                <InputLabel>{t('orguser.workflows.rules.type')}</InputLabel>
                                <Select
                                    label={t('orguser.workflows.rules.type')}
                                    name={'type'}
                                    value={formik.values.type}
                                    onChange={e => {
                                        formik.handleChange(e);
                                        formik.submitForm();
                                    }}
                                    IconComponent={KeyboardArrowDownIcon}
                                    fullWidth
                                    required
                                >
                                    <MenuItem value={0}>
                                        <Typography>{t('common.notSelected')}</Typography>
                                    </MenuItem>
                                    {workflowRuleTypes.map((item: WorkflowRuleTypes, index: number) => {
                                        if (!rules.some(rule => rule.type === item.type)) {
                                            return (
                                                <MenuItem key={`workflow-${index}`} value={item.type}>
                                                    <Typography sx={{fontWeight: 600}}>{t(item.label)}</Typography>
                                                </MenuItem>
                                            );
                                        }
                                        return null;
                                    })}
                                </Select>
                            </FormControl>
                            <IconButton
                                sx={{
                                    backgroundColor: theme.palette.info.main,
                                    ml: 2,
                                    '&:hover': {
                                        backgroundColor: theme.palette.info.main,
                                    },
                                }}
                                onClick={closeForm}
                                disabled={isLoading}
                            >
                                <CloseSvgIcon isActive isDisabled={isLoading} />
                            </IconButton>
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default WorkflowStatusRuleForm;
