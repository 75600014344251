import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {FORM_BASE_INVITE_INFORMATION, FORM_BASE_INVITE_INFORMATION_AUTH} from 'appRedux/actions/auth';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import RegistrationForm from 'components/RegistrationForm/RegistrationForm';

const InviteScreen: FC = () => {
    const {hash} = useLocation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const getInviteFormBaseInformation = useCallback(
        data => dispatch({type: FORM_BASE_INVITE_INFORMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getInviteFormBaseInformationAuth = useCallback(
        data => dispatch({type: FORM_BASE_INVITE_INFORMATION_AUTH.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        if (profile) {
            getInviteFormBaseInformationAuth({
                invite: String(hash).replace('#', ''),
                showAlert,
            });
        } else {
            getInviteFormBaseInformation({
                invite: String(hash).replace('#', ''),
                showAlert,
            });
        }
    }, []);

    return <RegistrationForm isRegistrationByInvite />;
};

export default InviteScreen;
