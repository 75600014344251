import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const UserRoleSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.38893 2.42133C6.9778 2.14662 6.49445 2 6 2C5.33696 2 4.70107 2.26339 4.23223 2.73223C3.76339 3.20107 3.5 3.83696 3.5 4.5C3.5 4.99445 3.64662 5.4778 3.92133 5.88893C4.19603 6.30005 4.58648 6.62048 5.04329 6.8097C5.50011 6.99892 6.00277 7.04843 6.48773 6.95196C6.97268 6.8555 7.41814 6.6174 7.76777 6.26777C8.1174 5.91814 8.3555 5.47268 8.45196 4.98773C8.54843 4.50277 8.49892 4.00011 8.3097 3.54329C8.12048 3.08648 7.80005 2.69603 7.38893 2.42133ZM4.0555 1.58986C4.63108 1.20527 5.30777 1 6 1C6.92826 1 7.8185 1.36875 8.47487 2.02513C9.13125 2.6815 9.5 3.57174 9.5 4.5C9.5 5.19223 9.29473 5.86892 8.91014 6.4445C8.52556 7.02007 7.97893 7.46867 7.33939 7.73358C6.69985 7.99848 5.99612 8.0678 5.31718 7.93275C4.63825 7.7977 4.01461 7.46436 3.52513 6.97487C3.03564 6.48539 2.7023 5.86175 2.56725 5.18282C2.4322 4.50388 2.50151 3.80015 2.76642 3.16061C3.03133 2.52107 3.47993 1.97444 4.0555 1.58986ZM14.035 10.5L11 7.5L14.035 4.5L14.75 5.205L12.43 7.5L14.75 9.795L14.035 10.5ZM11 15H10V12.5C10 11.837 9.73661 11.2011 9.26777 10.7322C8.79893 10.2634 8.16304 10 7.5 10H4.5C3.83696 10 3.20107 10.2634 2.73223 10.7322C2.26339 11.2011 2 11.837 2 12.5V15H1V12.5C1 11.5717 1.36875 10.6815 2.02513 10.0251C2.6815 9.36875 3.57174 9 4.5 9H7.5C8.42826 9 9.3185 9.36875 9.97487 10.0251C10.6313 10.6815 11 11.5717 11 12.5V15Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default UserRoleSvgIcon;
