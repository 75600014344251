import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {SelectChangeEvent} from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import {PanelHasStatusItemTypes} from 'appRedux/actions/analytics/types';
import {WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';

import {theme} from 'config/theme';

interface WorkflowStatusesSelectorsTypes {
    statuses: PanelHasStatusItemTypes[];
    setStatuses: (values: PanelHasStatusItemTypes[]) => void;
    workflowStatuses: WorkflowStatusItemTypes[];
}

const WorkflowStatusesSelectors: FC<WorkflowStatusesSelectorsTypes> = ({setStatuses, statuses, workflowStatuses}) => {
    const [t] = useTranslation();
    const [, setIsUpdated] = useState<boolean>(false);

    const onStatusChecked = (event: SelectChangeEvent) => {
        const workflowStatusId = Number(event.target.value);
        const isChecked = statuses.some(item => item.workflowStatus === workflowStatusId);
        if (isChecked) {
            for (let i = 0, n = statuses.length; i < n; i++) {
                const panelHasStatus = statuses[i];
                if (panelHasStatus.workflowStatus === workflowStatusId) {
                    statuses.splice(i, 1);
                    setStatuses(statuses);
                    setIsUpdated(previous => !previous);
                    break;
                }
            }
        } else {
            setStatuses([
                ...statuses,
                {
                    workflowStatus: workflowStatusId,
                },
            ]);
        }
    };

    return (
        <Box
            sx={{
                mt: 2,
                mb: 2,
            }}
        >
            <Typography sx={{fontWeight: 700, mb: 2}}>{`${t('orguser.analytics.panels.selectStatuses')}:`}</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}
            >
                {workflowStatuses &&
                    workflowStatuses.map((item: WorkflowStatusItemTypes) => {
                        const {uuid, title, id} = item;
                        const isChecked = statuses.some(item => item.workflowStatus === id);
                        return (
                            <FormControlLabel
                                key={`workflow-status-checkbox-${uuid}`}
                                sx={{
                                    width: '24%',
                                }}
                                control={
                                    <Checkbox
                                        name={'workflowStatuses'}
                                        value={id}
                                        checked={isChecked}
                                        onChange={onStatusChecked}
                                        sx={{
                                            pt: 0.5,
                                            pb: 0.5,
                                            '& svg': {color: theme.palette.info.main},
                                        }}
                                    />
                                }
                                label={title}
                            />
                        );
                    })}
            </Box>
        </Box>
    );
};

export default WorkflowStatusesSelectors;
