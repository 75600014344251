import React, {FC, useCallback, useContext, useState} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

import {UserNicknameUpdateAnonymousRequestType} from 'appRedux/actions/profile/types';
import {UPDATE_NICKNAME_ANONYMOUS, UPDATE_ANONYMOUS_WITHOUT_ACCOUNT} from 'appRedux/actions/profile';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import {validationSchema} from 'components/Forms/SettingsForms/UpdateUsernameForm/validation';

import {preventSendForm} from 'helpers/index';

interface UpdateUsernameFormAnonymousType {
    closeModal: () => void;
}

const UpdateUsernameFormAnonymous: FC<UpdateUsernameFormAnonymousType> = ({closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);

    const toggleShowNewPassword = () => {
        setShowNewPassword(previous => !previous);
    };

    const toggleShowRepeatPassword = () => {
        setShowRepeatPassword(previous => !previous);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const userId = get(profile, 'id', null);

    const onSubmitAction = useCallback(
        data => dispatch({type: UPDATE_NICKNAME_ANONYMOUS.REQUEST, payload: data}),
        [dispatch],
    );
    const onWithoutAccountAction = useCallback(
        data => dispatch({type: UPDATE_ANONYMOUS_WITHOUT_ACCOUNT.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: UserNicknameUpdateAnonymousRequestType, {setErrors}) => {
        onSubmitAction({
            ...values,
            showAlert,
            setErrors,
            callback: closeModal,
        });
    };

    const onNoAccountNeededClicked = () => {
        onWithoutAccountAction({
            showAlert,
            callback: closeModal,
        });
    };

    if (!userId) return null;

    return (
        <Box>
            <Alert sx={{mb: 1}} severity="info">
                <Typography>{t('common.account.updateUsernameExplanationAnonymous')}</Typography>
            </Alert>
            <Formik
                initialValues={{
                    userId: userId,
                    nickname: '',
                    password: {
                        first: '',
                        second: '',
                    },
                }}
                validationSchema={() => validationSchema(t)}
                onSubmit={onSubmitClicked}
            >
                {(formik: FormikProps<UserNicknameUpdateAnonymousRequestType>) => {
                    return (
                        <Form onKeyDown={preventSendForm}>
                            <Field
                                name="nickname"
                                required
                                label={t('common.account.nickname')}
                                placeholder={t('common.account.nickname')}
                                component={FormikTextInput}
                            />
                            <Field
                                type={showNewPassword ? 'text' : 'password'}
                                name="password.first"
                                label={t('common.security.newPassword')}
                                placeholder={t('common.security.newPassword')}
                                component={FormikTextInput}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={toggleShowNewPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Field
                                type={showRepeatPassword ? 'text' : 'password'}
                                name="password.second"
                                label={t('common.security.repeatPassword')}
                                placeholder={t('common.security.repeatPassword')}
                                component={FormikTextInput}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={toggleShowRepeatPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mt: 4,
                                }}
                            >
                                <AgentInverseButton
                                    title={t('common.account.noAccountNeeded')}
                                    onClick={onNoAccountNeededClicked}
                                />
                                <AgentSaveButton isSubmit />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default UpdateUsernameFormAnonymous;
