import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';

import Box from '@mui/material/Box';

import {FormAllowListFormTypes} from 'appRedux/actions/forms/types';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import {validationSchema, validationSchemaDomain} from 'components/Forms/FormBuilder/WhiteListEmailForm/validation';

import {preventSendForm} from 'helpers/index';

interface FormWhiteListEmailFormType {
    initialValues: FormAllowListFormTypes;
    onSubmitAction: (values: FormAllowListFormTypes, {setErrors}) => void;
    forDomain?: boolean;
}

const FormWhiteListEmailForm: FC<FormWhiteListEmailFormType> = ({initialValues, onSubmitAction, forDomain}) => {
    const [t] = useTranslation();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={forDomain ? validationSchemaDomain : validationSchema}
            onSubmit={onSubmitAction}
        >
            {(formik: FormikProps<FormAllowListFormTypes>) => {
                return (
                    <Form onKeyDown={preventSendForm}>
                        <Box sx={{mb: 4}}>
                            <Field
                                required
                                type={forDomain ? 'text' : 'email'}
                                name="value"
                                placeholder={t(
                                    forDomain ? 'orguser.forms.allowList.emailDomain' : 'orguser.forms.allowList.email',
                                )}
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mt: 1}}>
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default FormWhiteListEmailForm;
