import React, {FC} from 'react';

import {Box, Tooltip} from '@mui/material';

import CheckSvgIcon from 'assets/icons/buttons/CheckSvgIcon';

import {FieldOptionType} from 'appRedux/actions/forms/types';

import {theme} from 'config/theme';

interface ImageOption {
    file: FieldOptionType;
    isChecked: boolean;
}

const ImageOption: FC<ImageOption> = ({file, isChecked}) => {
    return (
        <Tooltip title={file.title} placement="top" arrow>
            <Box
                sx={{
                    overflow: 'hidden',
                    borderRadius: 1,
                    position: 'relative',
                    width: 120,
                    height: 120,
                }}
            >
                <img src={file.fileLink} style={{width: '100%', height: '100%', opacity: isChecked ? 0.8 : 1}} />
                {isChecked && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'absolute',
                            bottom: 4,
                            right: 4,
                            backgroundColor: theme.palette.info.light,
                            borderRadius: '12px',
                            border: `1px solid white`,
                            height: 24,
                            width: 24,
                            pt: '1px',
                        }}
                    >
                        <CheckSvgIcon isActive />
                    </Box>
                )}
            </Box>
        </Tooltip>
    );
};

export default ImageOption;
