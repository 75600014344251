import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import {InstanceSettingBasic} from 'appRedux/actions/instance/types';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import InstanceSettingForm from 'components/Forms/Instance/InstanceSetting/InstanceSettingForm';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import {theme} from 'config/theme';

interface InstanceSettingUpdateButtonType {
    item: InstanceSettingBasic;
}

const InstanceSettingUpdateButton: FC<InstanceSettingUpdateButtonType> = ({item}) => {
    const [t] = useTranslation();

    const [isShowModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    return (
        <>
            <IconButton
                title={t('common.buttons.update')}
                sx={{
                    ml: 1,
                    width: 30,
                    height: 30,
                    backgroundColor: theme.palette.info.main,
                    '&:hover': {
                        backgroundColor: theme.palette.info.main,
                    },
                }}
                onClick={toggleModal}
            >
                <EditSvgIcon isActive />
            </IconButton>
            {isShowModal && (
                <ModalWrapper
                    isShowModal={isShowModal}
                    toggleModal={toggleModal}
                    title={t('superadmin.instance.updateSetting')}
                >
                    <InstanceSettingForm item={item} setShowModal={setShowModal} />
                </ModalWrapper>
            )}
        </>
    );
};

export default InstanceSettingUpdateButton;
