import React, {FC, useContext} from 'react';
import get from 'lodash/get';
import {View, Text} from '@react-pdf/renderer';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseItemType} from 'appRedux/actions/requestCase/types';

import {OPTIONS_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';
import {pdfStyles} from 'pages/agent/pdfExport/pdfPartials/PdfDocumentStyles';
import {PdfDocumentContext} from 'pages/agent/pdfExport/pdfPartials/pdfDocumentContext';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {EMPTY_DATA} from 'config/index';
import getFieldMaxValue from 'helpers/getFieldMaxValue';

interface FieldPdfContentType {
    formId: number;
    pageId: number;
    sectionId: number;
    field: FormFieldTypes;
    result: RequesterCaseItemType;
}

const FieldPdfContent: FC<FieldPdfContentType> = ({formId, pageId, sectionId, field, result}) => {
    const {label, id} = field;

    const {translations} = useContext(PdfDocumentContext);

    const keyword = getFieldKeyword(formId, id, 'label');

    const maxValue = getFieldMaxValue(field.type, field.options);

    const pageKeyword = `page-${pageId}`;
    const sectionKeyword = `section-${sectionId}`;
    const fieldKeyword = `field-${id}`;

    const value = get(result, [pageKeyword, sectionKeyword, fieldKeyword], EMPTY_DATA);

    return (
        <View style={pdfStyles.field} wrap={false}>
            <View style={pdfStyles.fieldTitle}>
                <Text>{`${getFormTranslatedLabel(translations, keyword, label)}:`}</Text>
            </View>
            <View style={pdfStyles.fieldValue}>
                <Text>
                    {String(value).replaceAll(OPTIONS_SEPARATOR, ', ')}
                    {maxValue || ''}
                </Text>
            </View>
        </View>
    );
};

export default FieldPdfContent;
