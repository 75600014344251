import {call, fork, put, take} from 'redux-saga/effects';
import get from 'lodash/get';
import {http} from 'services/http';

import {
    FORM_PAGE_ADD,
    FORM_PAGE_UPDATE,
    FORM_PAGE_DELETE,
    FORM_PAGE_COPY,
    FORM_PAGE_UP,
    FORM_PAGE_DOWN,
    addFormPage,
    updateFormPage,
    deleteFormPage,
    copyFormPage,
    formPageUpPriority,
    formPageDownPriority,
} from 'appRedux/actions/forms';
import {
    FormChangeStatusType,
    FormAddPageRequest,
    FormItemBasic,
    FormHasLanguageTypes,
    CommonFormBuilderRequest,
} from 'appRedux/actions/forms/types';
import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchFormPageAdding() {
    while (true) {
        const {
            payload: {id, callback, showAlert, ...values},
        }: IActionType<FormAddPageRequest> = yield take(FORM_PAGE_ADD.REQUEST);
        try {
            const data: FormHasLanguageTypes[] = yield call(http, `page/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data) {
                const pages = get(data, 'pages', []);
                yield put(addFormPage.success(pages));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(addFormPage.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(addFormPage.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormPageUpdating() {
    while (true) {
        const {
            payload: {id, callback, showAlert, ...values},
        }: IActionType<FormAddPageRequest> = yield take(FORM_PAGE_UPDATE.REQUEST);
        try {
            const data: FormHasLanguageTypes[] = yield call(http, `page/${id}/edit`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data) {
                const pages = get(data, 'pages', []);
                yield put(updateFormPage.success(pages));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateFormPage.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateFormPage.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormPageDeleting() {
    while (true) {
        const {
            payload: {id, callback, showAlert},
        }: IActionType<CommonFormBuilderRequest> = yield take(FORM_PAGE_DELETE.REQUEST);
        try {
            const data: FormHasLanguageTypes[] = yield call(http, `page/${id}/delete`, {
                method: 'DELETE',
            });
            if (data) {
                const pages = get(data, 'pages', []);
                yield put(deleteFormPage.success(pages));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteFormPage.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteFormPage.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormPageCopying() {
    while (true) {
        const {
            payload: {id, showAlert, ...values},
        }: IActionType<CommonFormBuilderRequest> = yield take(FORM_PAGE_COPY.REQUEST);
        try {
            const data: FormHasLanguageTypes[] = yield call(http, `page/${id}/copy`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data) {
                const pages = get(data, 'pages', []);
                yield put(copyFormPage.success(pages));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(copyFormPage.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(copyFormPage.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormPageUpPriority() {
    while (true) {
        const {
            payload: {id, showAlert},
        }: IActionType<FormChangeStatusType> = yield take(FORM_PAGE_UP.REQUEST);
        try {
            const data: FormItemBasic[] = yield call(http, `page/${id}/up`, {
                method: 'POST',
                body: JSON.stringify({}),
            });
            if (data) {
                const forms = get(data, 'pages', []);
                yield put(formPageUpPriority.success(forms));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(formPageUpPriority.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formPageUpPriority.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormPageDownPriority() {
    while (true) {
        const {
            payload: {id, showAlert},
        }: IActionType<FormChangeStatusType> = yield take(FORM_PAGE_DOWN.REQUEST);
        try {
            const data: FormItemBasic[] = yield call(http, `page/${id}/down`, {
                method: 'POST',
                body: JSON.stringify({}),
            });
            if (data) {
                const forms = get(data, 'pages', []);
                yield put(formPageDownPriority.success(forms));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(formPageDownPriority.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formPageDownPriority.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchFormPageAdding),
    fork(watchFormPageUpdating),
    fork(watchFormPageDeleting),
    fork(watchFormPageCopying),
    fork(watchFormPageUpPriority),
    fork(watchFormPageDownPriority),
];
