import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';

import Box from '@mui/material/Box';

import {TwoFactorConfirmationTypes} from 'appRedux/actions/auth/types';

import {validationSchema} from 'components/Forms/SettingsForms/TwoFactorForm/validation';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';

interface GoogleAuthCodeFormType {
    handleSubmit: (value: TwoFactorConfirmationTypes) => void;
    isActivate?: boolean;
}

const GoogleAuthCodeForm: FC<GoogleAuthCodeFormType> = ({handleSubmit, isActivate}) => {
    const [t] = useTranslation();
    return (
        <Box
            sx={{
                width: '100%',
            }}
        >
            <Formik
                initialValues={{
                    code: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(formik: FormikProps<TwoFactorConfirmationTypes>) => {
                    return (
                        <Form>
                            <Field
                                name="code"
                                label={t('common.security.verificationCode')}
                                placeholder={123456}
                                component={FormikTextInput}
                            />
                            <Box
                                sx={{
                                    mt: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <AgentSaveButton
                                    isSubmit
                                    title={t(isActivate ? 'common.buttons.activate' : 'common.buttons.confirm')}
                                />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default GoogleAuthCodeForm;
