import React from 'react';

const ChatSentSvgIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7 11.75L3.5 8.27L4.295 7.5L7 10.175L12.705 4.5L13.5 5.29L7 11.75Z"
                fill="#07D889"
                fillOpacity="0.5"
            />
        </svg>
    );
};

export default ChatSentSvgIcon;
