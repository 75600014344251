import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';

import WorkflowRulesListItem from 'pages/admin/updateWorkflow/partials/rules/WorkflowRulesListItem';

interface WorkflowRulesListType {
    item: WorkflowStatusItemTypes;
    hasNotCompletedRules: boolean;
}

const WorkflowRulesList: FC<WorkflowRulesListType> = ({item, hasNotCompletedRules}) => {
    const [t] = useTranslation();

    const {rules} = item;

    return (
        <Box>
            <Typography sx={{fontWeight: 600, mb: 2}}>{`${t('orguser.workflows.rules.rulesList')}:`}</Typography>
            {rules &&
                rules.map(rule => {
                    return (
                        <WorkflowRulesListItem
                            key={`rule-${item.uuid}`}
                            item={rule}
                            hasNotCompletedRules={hasNotCompletedRules}
                        />
                    );
                })}
        </Box>
    );
};

export default WorkflowRulesList;
