import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import {RootReducer} from 'appRedux/reducers';
import {FormBaseInformationTypes} from 'appRedux/actions/auth/types';
import {CREATE_ANONYMOUS_REQUESTER_CASE} from 'appRedux/actions/auth';

import {MediaContext} from 'contexts/media/context';
import {AlertContext} from 'contexts/alert/context';
import {CryptoContext} from 'contexts/crypto/context';

import {getFormKeyword, getParsedTranslatedLabel} from 'helpers/translationsHelper';

import {theme} from 'config/theme';
import {routes} from 'config/index';

interface AnonymousFormType {
    formInformation: FormBaseInformationTypes;
}

const AnonymousForm: FC<AnonymousFormType> = ({formInformation}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {isMobile} = useContext(MediaContext);
    const {showAlert} = useContext(AlertContext);
    const {regenerateKeysAfterLogin} = useContext(CryptoContext);

    const createAnonymousRequesterCase = useCallback(
        data => dispatch({type: CREATE_ANONYMOUS_REQUESTER_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const {id, formButtonText, isEncryptInfo} = formInformation;

    const {
        requestCase: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const redirectToForm = async (userId: number, caseId: number, formId: number) => {
        const redirectTo = isEncryptInfo
            ? `${routes.REQUEST}/${caseId}/keysGenerate/${formId}`
            : `${routes.REQUEST}/${caseId}/form/${formId}`;
        await regenerateKeysAfterLogin(redirectTo);
    };

    const createNewRequesterCase = () => {
        createAnonymousRequesterCase({
            formId: id,
            showAlert,
            callbackRegistration: redirectToForm,
        });
    };

    return (
        <Box>
            <Typography
                sx={{
                    fontWeight: 600,
                    mt: 2,
                    mb: 2,
                }}
            >
                {t('common.registrationForm.thisFormIsAnonymous')}
            </Typography>
            <LoadingButton
                variant="contained"
                loading={isLoading}
                disabled={isLoading}
                disableRipple
                onClick={createNewRequesterCase}
                sx={{
                    mt: 2,
                    mb: 2,
                    width: isMobile ? '60vw' : 180,
                    height: 48,
                    backgroundColor: theme.palette.info.main,
                    borderRadius: 24,
                    '&:hover': {
                        backgroundColor: theme.palette.info.main,
                    },
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 600,
                        textTransform: 'none',
                        color: theme.palette.background.default,
                    }}
                >
                    {formButtonText
                        ? getParsedTranslatedLabel(t, getFormKeyword(id, 'button'), formButtonText)
                        : t('common.registrationForm.requestHelp')}
                </Typography>
            </LoadingButton>
        </Box>
    );
};

export default AnonymousForm;
