import React, {FC} from 'react';

interface IconTypeSvgType {
    isActive?: boolean;
}

const IconType_06: FC<IconTypeSvgType> = ({isActive}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.805 14.9601C5.70267 14.9169 5.61764 14.8408 5.56332 14.744C5.509 14.6471 5.48848 14.5349 5.505 14.4251L6.415 8.50006H4C3.92343 8.50212 3.84742 8.48656 3.77782 8.45459C3.70823 8.42262 3.6469 8.37508 3.59859 8.31565C3.55027 8.25623 3.51626 8.18649 3.49916 8.11183C3.48207 8.03718 3.48235 7.95959 3.5 7.88506L5 1.38506C5.02639 1.27319 5.09052 1.17384 5.18159 1.10371C5.27266 1.03359 5.3851 0.996985 5.5 1.00006H10.5C10.5747 0.999803 10.6485 1.01629 10.716 1.0483C10.7835 1.08032 10.8429 1.12705 10.89 1.18506C10.9377 1.24372 10.9715 1.31245 10.9888 1.38606C11.0062 1.45968 11.0066 1.53627 10.99 1.61006L10.125 5.50006H12.5C12.5937 5.49987 12.6856 5.52602 12.7651 5.57553C12.8447 5.62504 12.9088 5.69591 12.95 5.78006C12.9858 5.86083 12.9996 5.94965 12.99 6.03748C12.9803 6.12531 12.9475 6.20901 12.895 6.28006L6.395 14.7801C6.35107 14.8452 6.29241 14.899 6.22378 14.9373C6.15516 14.9755 6.07849 14.997 6 15.0001C5.93309 14.9988 5.86699 14.9852 5.805 14.9601ZM8.875 6.50006L9.875 2.00006H5.9L4.63 7.50006H7.585L6.79 12.6401L11.5 6.50006H8.875Z"
                fill={isActive ? '#ffffff' : '#222222'}
            />
        </svg>
    );
};

export default IconType_06;
