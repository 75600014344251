import React, {FC, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {useParams} from 'react-router';
import get from 'lodash/get';
import {useBottomScrollListener} from 'react-bottom-scroll-listener';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {REQUESTER_CASES_LIST} from 'appRedux/actions/admin';
import {RequesterCaseResponseTypes} from 'appRedux/actions/admin/types';
import {RootReducer} from 'appRedux/reducers';
import {BoardItemTypes} from 'appRedux/actions/workflow/types';

import {AdminContext} from 'contexts/admin/context';
import {RouteContext} from 'contexts/route/context';

import {getParameterFromUrl, getSearchFromUrl} from 'components/AdminScreenComponents/translationsHelper';
import {getPerPageForRequest} from 'components/BlockView/helper';
import MultipleActionButtonsPanel from 'components/AgentScreenComponents/_caseBlock/MultipleActionsButtonsPanel/MultipleActionButtonsPanel';
import RequestCaseListBlock from 'components/RequesterCaseBlocks/RequestCaseListBlock';

import Alphabet from 'pages/agent/listView/Alphabet';
import {getCurrentCasesNumber} from 'pages/agent/listView/helper';

import {VIEW_MODE_LIST} from 'helpers/filter';
import {getVisibleHeight} from 'helpers/getVisibleHeight';

import {
    PARAMETER_PAGE,
    PARAMETER_PER_PAGE,
    PARAMETER_TAG,
    PARAMETER_FORM,
    PARAMETER_AGENT,
    PARAMETER_SEARCH,
    PARAMETER_FIRST_LETTER,
    PARAMETER_SORT_BY,
    PARAMETER_SORT_TYPE,
    PARAMETER_LAST_CREATED,
    PARAMETER_LAST_UPDATED,
} from 'config/index';
import {SET_CURRENT_BOARD} from 'appRedux/actions/workflow';

const CustomBoardCasesList: FC = () => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const {uuid} = useParams();

    const {itemsPerPage, showFiltersPanel, filtersNumber} = useContext(AdminContext);
    const {setTopBarTitle, setCustomBoardTitle, setTopBarSubtitle, setBackLinkLabel, setBackLink} =
        useContext(RouteContext);

    const [currentBoard, setCurrentBoard] = useState<BoardItemTypes | null>(null);

    const pageFromUrl = searchParams.get(PARAMETER_PAGE);
    const perPageFromUrl = searchParams.get(PARAMETER_PER_PAGE);
    const agentFromUrl = searchParams.get(PARAMETER_AGENT);
    const formFromUrl = searchParams.get(PARAMETER_FORM);
    const searchFromUrl = searchParams.get(PARAMETER_SEARCH);
    const lastCreatedFromUrl = searchParams.get(PARAMETER_LAST_CREATED);
    const lastUpdatedFromUrl = searchParams.get(PARAMETER_LAST_UPDATED);
    const tagsFromUrl = searchParams.get(PARAMETER_TAG);
    const firstLetterFromUrl = searchParams.get(PARAMETER_FIRST_LETTER);
    const sortByFromUrl = searchParams.get(PARAMETER_SORT_BY);
    const sortTypeFromUrl = searchParams.get(PARAMETER_SORT_TYPE);

    const getRequesterCasesCustomListBoard = useCallback(
        data => dispatch({type: REQUESTER_CASES_LIST.REQUEST_CUSTOM_BOARD_ALPHABET, payload: data}),
        [dispatch],
    );

    const updateRequesterCasesCustomListBoard = useCallback(
        data => dispatch({type: REQUESTER_CASES_LIST.REQUEST_CUSTOM_BOARD_ALPHABET_UPDATE, payload: data}),
        [dispatch],
    );

    const setCurrentBoardState = useCallback(data => dispatch({type: SET_CURRENT_BOARD, payload: data}), [dispatch]);

    const {
        admin: {requesterCasesNumber, requesterCasesAlphabetList},
        profile: {profile},
        workflow: {isLoading, boards},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        getRequesterCasesCustomListBoard({
            uuid,
            perPage: getPerPageForRequest(perPageFromUrl, VIEW_MODE_LIST, itemsPerPage),
            agent: getParameterFromUrl(agentFromUrl),
            form: getParameterFromUrl(formFromUrl),
            tags: getParameterFromUrl(tagsFromUrl),
            lastCreated: getParameterFromUrl(lastCreatedFromUrl),
            lastUpdated: getParameterFromUrl(lastUpdatedFromUrl),
            firstLetter: firstLetterFromUrl ?? '',
            search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
        });
    }, [
        uuid,
        pageFromUrl,
        perPageFromUrl,
        agentFromUrl,
        formFromUrl,
        searchFromUrl,
        tagsFromUrl,
        lastCreatedFromUrl,
        lastUpdatedFromUrl,
        firstLetterFromUrl,
        sortByFromUrl,
        sortTypeFromUrl,
    ]);

    const permittedUuids = get(requesterCasesAlphabetList, [String(uuid), 'permittedUuids'], []);
    const casesByLetter = get(requesterCasesAlphabetList, [String(uuid), 'results'], []);
    const currentUserEmail = get(profile, 'email', null);

    const currentCasesNumber = getCurrentCasesNumber(requesterCasesAlphabetList, String(uuid));
    const showLoadMore = Number(currentCasesNumber) < Number(requesterCasesNumber);

    const handleContainerOnBottom = () => {
        if (!showLoadMore) return;
        updateRequesterCasesCustomListBoard({
            uuid,
            last: currentCasesNumber,
            perPage: getPerPageForRequest(perPageFromUrl, VIEW_MODE_LIST, itemsPerPage),
            agent: getParameterFromUrl(agentFromUrl),
            form: getParameterFromUrl(formFromUrl),
            tags: getParameterFromUrl(tagsFromUrl),
            lastCreated: getParameterFromUrl(lastCreatedFromUrl),
            lastUpdated: getParameterFromUrl(lastUpdatedFromUrl),
            firstLetter: firstLetterFromUrl ?? '',
            search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
        });
    };

    const containerRef = useBottomScrollListener(handleContainerOnBottom, {
        offset: 100,
        debounce: 0,
    });

    useEffect(() => {
        document.body.style.overflowX = 'hidden';
        document.body.style.overflowY = 'hidden';
    }, []);

    useEffect(() => {
        return () => {
            document.body.style.overflowX = 'auto';
            document.body.style.overflowY = 'auto';
        };
    }, []);

    useEffect(() => {
        setTopBarSubtitle('');
        setBackLink('');
        setBackLinkLabel('');
    }, []);

    useEffect(() => {
        const current = boards.find((item: BoardItemTypes) => {
            return item.uuid === uuid;
        });
        if (current) {
            setTopBarTitle(current.title);
            setCurrentBoard(current);
            setCustomBoardTitle(current.title);
        } else {
            setTopBarTitle('');
        }
    }, [uuid, boards]);

    useEffect(() => {
        const params: [string, string][] = [];

        const current = boards.find((item: BoardItemTypes) => {
            return item.uuid === uuid;
        });

        for (const entry of searchParams.entries()) {
            params.push(entry);
        }
        if (current) {
            setCurrentBoardState({id: current.uuid, params});
        }
    }, [uuid, boards, searchParams]);

    const listWrapperRef = useRef<HTMLDivElement>(null);

    const [listHeight, setListHeight] = useState(0);

    useEffect(() => {
        if (!currentBoard || isLoading) return;

        if (listWrapperRef && listWrapperRef.current) {
            setListHeight(getVisibleHeight(listWrapperRef.current));
        }

        const onResize = () => {
            console.log('resize', getVisibleHeight(listWrapperRef.current));
            setListHeight(getVisibleHeight(listWrapperRef.current));
        };

        window.onresize = onResize;

        return () => {
            window.onresize = () => {
                return;
            };
        };
    }, [listWrapperRef, currentBoard, isLoading, showFiltersPanel, filtersNumber]);

    if (!currentBoard || isLoading) return null;

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, alignItems: 'stretch'}}>
            <Alphabet casesNumber={requesterCasesNumber} />
            <Box ref={listWrapperRef} sx={{height: '100vh'}}>
                <Box
                    sx={{
                        pb: 1,
                        overflowX: 'hidden',
                        overflowY: 'scroll',
                        height: listHeight + 'px',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        '-ms-overflow-style': 'none',
                        'scrollbar-width': 'none',
                    }}
                    ref={containerRef}
                >
                    {Object.entries(casesByLetter).map((item: any, letterIndex: number) => {
                        const letter = item[0];
                        const cases: RequesterCaseResponseTypes[] = item[1];
                        if (cases && cases.length > 0) {
                            return (
                                <Box
                                    key={`letterIndex-${letterIndex}`}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-start',
                                        width: '100%',
                                        mb: 3,
                                    }}
                                >
                                    <Typography sx={{fontSize: 40, fontWeight: 300}}>{letter.toUpperCase()}</Typography>
                                    <Box sx={{width: '95%'}}>
                                        {cases &&
                                            cases.map((caseItem: RequesterCaseResponseTypes, index: number) => {
                                                return (
                                                    <RequestCaseListBlock
                                                        key={`requesterCase-${caseItem.caseId}-${index}`}
                                                        currentUserEmail={currentUserEmail}
                                                        item={caseItem}
                                                        permittedUuids={permittedUuids}
                                                    />
                                                );
                                            })}
                                    </Box>
                                </Box>
                            );
                        }
                        return null;
                    })}
                </Box>
            </Box>
            <MultipleActionButtonsPanel />
        </Box>
    );
};

export default CustomBoardCasesList;
