import React, {FC, ReactNode} from 'react';

import Box from '@mui/material/Box';

import DescriptionPopover from 'components/DescriptionPopover/DescriptionPopover';

interface FieldGdprCheckboxWrapperType {
    explanationText: string;
    children: ReactNode;
}

const FieldGdprCheckboxWrapper: FC<FieldGdprCheckboxWrapperType> = ({explanationText, children}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mb: 1,
                pl: 2,
                mt: 1,
            }}
        >
            {children}
            <DescriptionPopover text={explanationText} />
        </Box>
    );
};

export default FieldGdprCheckboxWrapper;
