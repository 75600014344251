import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import {DELETE_AVATAR} from 'appRedux/actions/profile';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';

import DeleteClientConfirmationModal from 'components/ClientScreenComponents/DeleteClientConfirmationModal';
import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';

import {buttonStyles} from 'components/UserAvatar/helper';

const DeleteAvatar: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {isClientMode} = useContext(RouteContext);
    const {showAlert} = useContext(AlertContext);

    const deleteUserAvatar = useCallback(data => dispatch({type: DELETE_AVATAR.REQUEST, payload: data}), [dispatch]);

    const onClickDelete = () => {
        deleteUserAvatar({
            showAlert,
            callback: () => setIsOpened(false),
        });
    };

    const ConfirmationModal = isClientMode ? DeleteClientConfirmationModal : DeleteAdminConfirmationModal;

    return (
        <>
            <Button data-id="button#avatar-delete" sx={{mt: 2}} onClick={toggleIsOpened}>
                <IconButton>
                    <DeleteSvgIcon />
                </IconButton>
                <Typography sx={buttonStyles}>{t('common.account.deletePhoto')}</Typography>
            </Button>
            {isOpened && (
                <ConfirmationModal toggleModal={toggleIsOpened} isShowModal={isOpened} onClickAction={onClickDelete} />
            )}
        </>
    );
};

export default DeleteAvatar;
