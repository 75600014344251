import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';

import {MenuContentItemType} from 'components/TopBarMenu/helper';

import {theme} from 'config/theme';

interface ComponentType {
    item: MenuContentItemType;
}

const MenuContentItem: FC<ComponentType> = ({item}) => {
    const [t] = useTranslation();
    const {pathname} = useLocation();

    const {icon, label, path, isHidden, onClickAction} = item;

    const MenuIcon = icon;

    const isActive = path && pathname.includes(path);

    if (isHidden) {
        return null;
    }
    return (
        <MenuItem data-id={`button#profile-menu-${item.label}`} sx={{pt: 1, pb: 1}} onClick={onClickAction}>
            <IconButton
                sx={{
                    backgroundColor: isActive ? theme.palette.info.main : theme.palette.background.default,
                }}
            >
                <MenuIcon isActive={isActive} />
            </IconButton>
            <Typography variant="body2" sx={{ml: 1, color: theme.palette.info.main}}>
                {t(`common.menu.${label}`)}
            </Typography>
        </MenuItem>
    );
};

export default MenuContentItem;
