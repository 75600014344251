import React, {FC, useCallback, useContext} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';
import {WorkflowEventAttachGroupFormTypes, WorkflowStatusEventItemTypes} from 'appRedux/actions/workflow/types';
import {GroupItemTypes} from 'appRedux/actions/groups/types';
import {ATTACH_WORKFLOW_EVENT_GROUP} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface WorkflowEventAttachGroupFormType {
    item: WorkflowStatusEventItemTypes;
    closeForm: () => void;
}

const WorkflowEventAttachGroupForm: FC<WorkflowEventAttachGroupFormType> = ({item, closeForm}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {uuid} = item;

    const {
        groups: {groups},
        workflow: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onSubmitClicked = useCallback(
        data => dispatch({type: ATTACH_WORKFLOW_EVENT_GROUP.REQUEST, payload: data}),
        [dispatch],
    );

    const onSaveClicked = (values: WorkflowEventAttachGroupFormTypes) => {
        onSubmitClicked({
            ...values,
            showAlert,
            uuid,
            callback: closeForm,
        });
    };

    const groupsNotAttached = groups
        ? groups.filter(group => !item.groups.some(attachedGroup => Number(attachedGroup.id) === Number(group.id)))
        : [];

    return (
        <Formik
            initialValues={{
                group: 0,
            }}
            onSubmit={onSaveClicked}
        >
            {(formik: FormikProps<WorkflowEventAttachGroupFormTypes>) => {
                return (
                    <Form>
                        <FormControl sx={{mt: 1}} required fullWidth>
                            <InputLabel>{t('orguser.workflows.workflowStatusEventParameters.selectGroup')}</InputLabel>
                            <Select
                                label={t('orguser.workflows.workflowStatusEventParameters.selectGroup')}
                                name={'group'}
                                value={formik.values.group}
                                onChange={formik.handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                sx={{
                                    '& div': {
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                    },
                                }}
                                fullWidth
                            >
                                {groupsNotAttached &&
                                    groupsNotAttached.map((group: GroupItemTypes) => {
                                        const {title, id, uuid} = group;
                                        return (
                                            <MenuItem
                                                key={`group-${uuid}`}
                                                value={id}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    mb: 1,
                                                }}
                                            >
                                                <Typography sx={{ml: 1, fontWeight: 600}}>{title}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton isSubmit isDisabled={isLoading} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default WorkflowEventAttachGroupForm;
