import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import {RootReducer} from 'appRedux/reducers';
import {UpdatePasswordType} from 'appRedux/actions/profile/types';
import {UPDATE_PASSWORD, PROFILE_ERROR_CLEAR} from 'appRedux/actions/profile';

import {AlertContext} from 'contexts/alert/context';

import {initialValues, validationSchema} from 'components/Forms/SettingsForms/UpdatePasswordForm/validation';
import {getErrorMessage} from 'components/Forms/SettingsForms/UpdatePasswordForm/helper';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AgentResetButton from 'components/AgentScreenComponents/_buttons/AgentResetButton';
import FormAlert from 'components/AdminScreenComponents/FormAlert';

import {preventSendForm} from 'helpers/index';

const UpdatePasswordForm: FC = () => {
    const [t] = useTranslation();
    const schema = () => validationSchema(t);
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        profile: {profile, errors},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onSubmitAction = useCallback(data => dispatch({type: UPDATE_PASSWORD.REQUEST, payload: data}), [dispatch]);

    const onErrorRemoving = useCallback(() => dispatch({type: PROFILE_ERROR_CLEAR.REQUEST}), [dispatch]);

    const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const isTwoFactorActivated = get(profile, 'isTwoFactorActivated', false);

    const toggleShowCurrentPassword = () => {
        setShowCurrentPassword(previous => !previous);
    };

    const toggleShowNewPassword = () => {
        setShowNewPassword(previous => !previous);
    };

    const toggleShowRepeatPassword = () => {
        setShowRepeatPassword(previous => !previous);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const onErrorClose = () => {
        setErrorMessage(null);
        onErrorRemoving();
    };

    useEffect(() => {
        if (errors) {
            setErrorMessage(getErrorMessage(errors));
        }
    }, [errors]);

    return (
        <Box>
            <Alert sx={{mb: 1}} severity="info">
                <Typography>{t('common.security.updatePasswordExplanation')}</Typography>
            </Alert>
            {errorMessage && <FormAlert message={errorMessage} onErrorClose={onErrorClose} />}
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(values: UpdatePasswordType, {resetForm}) => {
                    onErrorClose();
                    onSubmitAction({
                        ...values,
                        showAlert,
                        callback: resetForm,
                    });
                }}
            >
                {(formik: FormikProps<UpdatePasswordType>) => {
                    return (
                        <Form onKeyDown={preventSendForm}>
                            <Field
                                data-id={`input#security-password`}
                                type={showCurrentPassword ? 'text' : 'password'}
                                name="currentPassword"
                                label={t('common.security.password')}
                                placeholder={t('common.security.password')}
                                component={FormikTextInput}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={toggleShowCurrentPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Field
                                data-id={`input#security-newpassword`}
                                type={showNewPassword ? 'text' : 'password'}
                                name="password.first"
                                label={t('common.security.newPassword')}
                                placeholder={t('common.security.newPassword')}
                                component={FormikTextInput}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={toggleShowNewPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Field
                                data-id={`input#security-repeatpassword`}
                                type={showRepeatPassword ? 'text' : 'password'}
                                name="password.second"
                                label={t('common.security.repeatPassword')}
                                placeholder={t('common.security.repeatPassword')}
                                component={FormikTextInput}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={toggleShowRepeatPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {isTwoFactorActivated && (
                                <Field
                                    name="code"
                                    label={t('common.security.verificationCode')}
                                    placeholder={123456}
                                    component={FormikTextInput}
                                />
                            )}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    mt: 4,
                                }}
                            >
                                <AgentResetButton data-id={`button#security-password-reset`} />
                                <AgentSaveButton
                                    data-id={`button#security-password-save`}
                                    isSubmit
                                    title={'common.buttons.saveChanges'}
                                />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default UpdatePasswordForm;
