import React, {FC, useEffect} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';
import {
    OrganizationAdminCreateTypes,
    OrganizationAdminBasicTypes,
    OrganizationAdminListTypes,
    UserHasRolesTypes,
} from 'appRedux/actions/organizationAdmin/types';
import {RoleTypes} from 'appRedux/actions/roles/types';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import {validationSchema} from 'components/Forms/OrganizationAdminForm/validation';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import OrganizationUsersNumberQuotaAlert from 'components/Forms/OrganizationAdminForm/partials/OrganizationUsersNumberQuotaAlert';
import RolesSelectors from 'components/Forms/OrganizationAdminForm/partials/RolesSelectors';

interface OrganizationAdminFormType {
    initialValues: OrganizationAdminBasicTypes;
    userHasRoles: UserHasRolesTypes[];
    setUserHasRoles: (values: UserHasRolesTypes[]) => void;
    onSubmitClicked: (values: OrganizationAdminCreateTypes) => void;
    item?: OrganizationAdminListTypes;
}

const OrganizationAdminForm: FC<OrganizationAdminFormType> = ({
    initialValues,
    userHasRoles,
    setUserHasRoles,
    item,
    onSubmitClicked,
}) => {
    const [t] = useTranslation();
    const schema = () => validationSchema(t);

    const organizationAdminRoles: RoleTypes[] = get(item, ['admin', 'roles'], []);

    const {
        admin: {isLoading},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        const roleIds: UserHasRolesTypes[] = [];
        organizationAdminRoles.forEach(item => {
            roleIds.push({
                role: item.id,
            });
        });
        setUserHasRoles(roleIds);
    }, []);

    const currentUserEmail = get(profile, 'email', null);

    return (
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmitClicked}>
            {(formik: FormikProps<OrganizationAdminCreateTypes>) => {
                return (
                    <Form>
                        <OrganizationUsersNumberQuotaAlert />
                        <Field
                            name="firstName"
                            label={t('superadmin.users.firstName')}
                            type="text"
                            component={FormikTextInput}
                        />
                        <Field
                            name="lastName"
                            label={t('superadmin.users.lastName')}
                            type="text"
                            component={FormikTextInput}
                        />
                        <Field
                            name="email"
                            label={t('superadmin.users.email')}
                            type="email"
                            component={FormikTextInput}
                        />
                        <RolesSelectors
                            fieldName={'userHasRoles'}
                            userHasRoles={userHasRoles}
                            setUserHasRoles={setUserHasRoles}
                            isCurrentUserEmail={currentUserEmail === initialValues.email}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton isLoading={isLoading} isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default OrganizationAdminForm;
