import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';
import {useSelector} from 'react-redux';

import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';

import CancelSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import EmailNotVerified from 'components/ClientScreenComponents/EmailNotVerified';
import CryptoKeysAccessNeeded from 'components/ClientScreenComponents/CryptoKeysAccessNeeded';
import SessionTimer from 'components/SessionTimer/SessionTimer';

import {getFormKeyword, getParsedTranslatedLabel} from 'helpers/translationsHelper';

import {theme, CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface TopBarMobileType {
    isMenuOpened: boolean;
    toggleMenu: () => void;
}

const TopBarMobile: FC<TopBarMobileType> = ({isMenuOpened, toggleMenu}) => {
    const [t] = useTranslation();

    const {auth, profile} = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isUserVerified = get(profile, ['profile', 'isVerify'], false);
    const formButtonText = get(auth, ['form', 'formButtonText'], '');
    const formInfo = get(auth, 'form', null);
    const formButtonKeyword = formInfo?.id ? getFormKeyword(formInfo?.id, 'formButtonText') : '';

    return (
        <AppBar
            position="sticky"
            sx={{
                boxShadow: 'none',
                backgroundColor: CLIENT_BACKGROUND_COLOR,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 2,
                }}
            >
                <Box sx={{position: 'absolute', top: 18, left: 10, display: 'flex', alignItems: 'center'}}>
                    <SessionTimer mini />
                </Box>
                <Typography sx={{ml: 1, color: theme.palette.info.main, fontWeight: 600}}>
                    {formButtonText
                        ? getParsedTranslatedLabel(t, formButtonKeyword, formButtonText)
                        : t('common.registrationForm.requestHelp')}
                </Typography>
                <Box sx={{position: 'absolute', top: 6, right: 10}}>
                    <IconButton onClick={toggleMenu}>{isMenuOpened ? <CancelSvgIcon /> : <MenuIcon />}</IconButton>
                </Box>
            </Box>
            {!isUserVerified && <EmailNotVerified />}
            <CryptoKeysAccessNeeded />
        </AppBar>
    );
};

export default TopBarMobile;
