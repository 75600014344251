import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {DashboardFormTypes, DashboardHasPanelTypes, DashboardItemsTypes} from 'appRedux/actions/analytics/types';
import {UPDATE_DASHBOARD} from 'appRedux/actions/analytics';

import {AlertContext} from 'contexts/alert/context';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import DashboardForm from 'components/Forms/Dashboard/DashboardForm';

interface EditDashboardButtonType {
    item: DashboardItemsTypes;
}

const EditDashboardButton: FC<EditDashboardButtonType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [selectedPanels, setSelectedPanels] = useState<DashboardHasPanelTypes[]>([]);

    const toggleModal = () => {
        setIsOpened(previous => !previous);
    };

    const {title, description, panels, uuid} = item;

    const updateDashboard = useCallback(data => dispatch({type: UPDATE_DASHBOARD.REQUEST, payload: data}), [dispatch]);

    const onSubmitClicked = (values: DashboardFormTypes) => {
        updateDashboard({
            ...values,
            uuid,
            dashboardHasPanels: selectedPanels,
            showAlert,
            callback: () => setIsOpened(false),
        });
    };

    useEffect(() => {
        const panelIds: DashboardHasPanelTypes[] = [];
        panels.forEach(item => {
            panelIds.push({
                panel: item.id,
            });
        });
        setSelectedPanels(panelIds);
    }, [item]);

    return (
        <>
            <IconButton title={t('common.buttons.update')} onClick={toggleModal}>
                <EditSvgIcon />
            </IconButton>
            <ModalWrapper
                isShowModal={isOpened}
                toggleModal={toggleModal}
                title={t('common.buttons.update')}
                isWideModal
            >
                <DashboardForm
                    initialValues={{
                        title,
                        dashboardHasPanels: selectedPanels,
                        description,
                    }}
                    selectedPanels={selectedPanels}
                    setSelectedPanels={setSelectedPanels}
                    onSubmitClicked={onSubmitClicked}
                />
            </ModalWrapper>
        </>
    );
};

export default EditDashboardButton;
