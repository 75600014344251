import React, {FC, useCallback, useContext, useRef} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

import ExportSvgIcon from 'assets/icons/buttons/ExportSvgIcon';

import {WorkflowStatusEventItemTypes, UploadWorkflowAttachmentTypes} from 'appRedux/actions/workflow/types';
import {UPLOAD_WORKFLOW_ATTACHMENT} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';

import {validationSchemaWorkflowAttachment} from 'components/Forms/Workflow/validation';
import {theme} from 'config/theme';

interface WorkflowAttachmentFormType {
    item: WorkflowStatusEventItemTypes;
    languageId: number;
    closeForm: () => void;
}

const WorkflowAttachmentForm: FC<WorkflowAttachmentFormType> = ({item, languageId, closeForm}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const {showAlert} = useContext(AlertContext);

    const uploadWorkflowAttachment = useCallback(
        data => dispatch({type: UPLOAD_WORKFLOW_ATTACHMENT.REQUEST, payload: data}),
        [dispatch],
    );

    const onLabelClick = () => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <Formik
            initialValues={{
                uuid: item.uuid,
                title: '',
                language: languageId,
                file: '',
            }}
            validationSchema={() => validationSchemaWorkflowAttachment(t)}
            onSubmit={(values, {resetForm}) => {
                resetForm();
                uploadWorkflowAttachment({
                    ...values,
                    showAlert,
                    callback: closeForm,
                });
            }}
        >
            {(formik: FormikProps<UploadWorkflowAttachmentTypes>) => {
                return (
                    <Form>
                        <Box
                            sx={{
                                mb: 2,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Field
                                required
                                name="title"
                                placeholder={t('orguser.workflows.workflowAttachments.title')}
                                component={FormikTextInput}
                            />
                            <TextField
                                inputRef={fileInputRef}
                                sx={{display: 'none'}}
                                name="file"
                                type="file"
                                onChange={value => {
                                    formik.setFieldValue('file', get(value, ['currentTarget', 'files', '0'], null));
                                    formik.submitForm();
                                }}
                            />
                            <IconButton
                                title={t('common.buttons.upload')}
                                sx={{
                                    ml: 1,
                                    backgroundColor: theme.palette.info.main,
                                    '&:hover': {
                                        backgroundColor: theme.palette.info.main,
                                    },
                                }}
                                onClick={onLabelClick}
                            >
                                <ExportSvgIcon isActive />
                            </IconButton>
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default WorkflowAttachmentForm;
