import React, {DragEvent, FC, ReactNode, useRef} from 'react';

import TableRow from '@mui/material/TableRow';
import {Box, TableCell} from '@mui/material';

import DraggableSvgIcon from 'assets/icons/buttons/DraggableSvgIcon';

import {CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface DraggableTableRowType {
    item: any;
    children: ReactNode;
    dragItem?: string;
    setDragItem: React.Dispatch<React.SetStateAction<string>>;
    dropTo?: number;
    setDropTo: React.Dispatch<React.SetStateAction<number>>;
    onDragEnd: () => void;
}

const DraggableTableRow: FC<DraggableTableRowType> = ({
    item,
    dragItem,
    setDragItem,
    dropTo,
    setDropTo,
    onDragEnd,
    children,
}) => {
    const {uuid, priority} = item;

    const dragEndTimeoutRef = useRef<NodeJS.Timeout>();

    const onDragStart = () => {
        setDragItem(uuid);
        setTimeout(() => {
            setDropTo(priority);
        }, 0);
    };

    const onDrag = () => {
        dragEndTimeoutRef.current && clearTimeout(dragEndTimeoutRef.current);
        dragEndTimeoutRef.current = setTimeout(() => {
            setDragItem && setDragItem('');
        }, 1000);
    };

    const onDragEnter = () => {
        setTimeout(() => {
            setDropTo(priority);
        }, 0);
    };

    const onDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
    };

    return (
        <TableRow
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDrag={onDrag}
            onDragEnter={onDragEnter}
            onDragOver={onDragOver}
            draggable
            sx={{
                position: 'relative',
                opacity: dragItem && dropTo === priority ? 0.5 : 1,
                background: dragItem && dropTo === priority ? CLIENT_BACKGROUND_COLOR : 'white',
            }}
        >
            <TableCell sx={{width: 10, pr: 0}}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <DraggableSvgIcon isDisabled={dragItem !== uuid} />
                </Box>
            </TableCell>
            {children}
        </TableRow>
    );
};

export default DraggableTableRow;
