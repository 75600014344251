import React, {FC, useCallback, useContext, useState} from 'react';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {HANDLE_CASE_ACCESS_REQUEST, GET_CASE_KEYS, CREATE_CASE_KEY} from 'appRedux/actions/crypto';
import {CaseAccessRequestTypes, CaseKeysGeneratedTypes} from 'appRedux/actions/crypto/types';
import {RootReducer} from 'appRedux/reducers';

import {CryptoContext, KeysType} from 'contexts/crypto/context';
import {RouteContext} from 'contexts/route/context';
import {AlertContext} from 'contexts/alert/context';

import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {ACCESS_REQUESTS_LIST} from 'components/AgentScreenComponents/helper';

import {
    CASE_ACCESS_REQUEST_APPROVED,
    CASE_ACCESS_REQUEST_CREATED,
    CASE_ACCESS_REQUEST_DENIED,
    getAccessRequestStatusLabel,
} from 'pages/admin/accessRequests/helper';

import {importPublicKey, unwrapExtractableKey, wrapKey} from 'helpers/cryptoApiHelper';

import {PARAMETER_TAB, routes, DATE_TIME_FORMAT} from 'config/index';

interface BottomPanelType {
    item: CaseAccessRequestTypes;
    setShowErrorMessage: (value: boolean) => void;
}

const BottomPanel: FC<BottomPanelType> = ({item, setShowErrorMessage}) => {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {uuid} = useParams();

    const {formId, status, caseId, caseLabel, admin, updatedAt} = item;

    const {showAlert} = useContext(AlertContext);
    const {isAgentPage} = useContext(RouteContext);
    const {keys} = useContext(CryptoContext);

    const [encodedKeys, setEncodedKey] = useState<CaseKeysGeneratedTypes[]>([]);

    const {
        crypto: {publicKeysForGeneration},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const handleCaseAccessRequest = useCallback(
        data => dispatch({type: HANDLE_CASE_ACCESS_REQUEST.REQUEST, payload: data}),
        [dispatch],
    );

    const getCaseKeys = useCallback(data => dispatch({type: GET_CASE_KEYS.REQUEST, payload: data}), [dispatch]);

    const createCaseKeys = useCallback(data => dispatch({type: CREATE_CASE_KEY.REQUEST, payload: data}), [dispatch]);

    const generateCryptoKeys = async (caseKey: string, keys: KeysType) => {
        const unwrappedCaseKey = await unwrapExtractableKey(caseKey, keys.privateKey);
        if (unwrappedCaseKey) {
            for (let i = 0, n = publicKeysForGeneration.length; i < n; i++) {
                try {
                    const {id, data} = publicKeysForGeneration[i];
                    const importedPublicKey = await importPublicKey(data);
                    const wrappedCaseKey = await wrapKey(unwrappedCaseKey, importedPublicKey);
                    encodedKeys.push({
                        public: id,
                        data: wrappedCaseKey,
                    });
                    setEncodedKey(encodedKeys);
                } catch (e) {
                    console.log('Error', e);
                }
            }
        }
    };

    const checkCaseKey = async (caseKeys: string[]) => {
        if (keys && caseKeys && caseKeys.length > 0) {
            await generateCryptoKeys(caseKeys[0], keys);
            if (encodedKeys.length > 0) {
                createCaseKeys({
                    id: caseId,
                    caseKeys: encodedKeys,
                    showAlert,
                    callback: () => {
                        handleCaseAccessRequest({
                            uuid,
                            notificationText: t('orguser.accessRequests.caseAccessRequestAllowedNotification').replace(
                                '%CASE%',
                                caseLabel,
                            ),
                            relatedLink: item.isRequestFromClient
                                ? `${routes.REQUEST}/${caseId}/form/${formId}`
                                : `${routes.REQUESTER_CASE}/${caseId}/form/${formId}`,
                            status: CASE_ACCESS_REQUEST_APPROVED,
                            showAlert,
                        });
                    },
                });
            } else {
                setShowErrorMessage(true);
            }
        }
    };

    const onDenyClicked = () => {
        handleCaseAccessRequest({
            uuid,
            status: CASE_ACCESS_REQUEST_DENIED,
            showAlert,
            callback: () => {
                const path = isAgentPage ? routes.AGENT : routes.ADMIN;
                navigate(`${path}?${PARAMETER_TAB}=${ACCESS_REQUESTS_LIST}`);
            },
        });
    };

    const onAccessClicked = () => {
        getCaseKeys({
            id: item.caseId,
            showAlert,
            callback: checkCaseKey,
        });
    };

    if (status === CASE_ACCESS_REQUEST_CREATED) {
        return (
            <Box>
                <Grid container sx={{mt: 3}}>
                    <Grid item sm={2}>
                        <Typography sx={{fontWeight: 600}}>{`${t('orguser.accessRequests.role')}:`}</Typography>
                    </Grid>
                    <Grid item sm={10}>
                        <Typography>
                            {t(
                                item.isRequestFromClient
                                    ? 'orguser.accessRequests.roles.roleRequester'
                                    : 'orguser.accessRequests.roles.roleOrgUser',
                            )}
                        </Typography>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 3,
                    }}
                >
                    <AgentInverseButton title={t('common.buttons.deny')} onClick={onDenyClicked} />
                    <AgentSaveButton title={t('common.buttons.access')} onClick={onAccessClicked} />
                </Box>
            </Box>
        );
    }
    return (
        <Box>
            <Grid container sx={{mt: 3, mb: 1}}>
                <Grid item sm={2}>
                    <Typography sx={{fontWeight: 600}}>{`${t('orguser.accessRequests.status')}:`}</Typography>
                </Grid>
                <Grid item sm={10}>
                    <Typography>{`${t(getAccessRequestStatusLabel(status))} ${
                        admin ? `by ${admin}` : null
                    }`}</Typography>
                </Grid>
            </Grid>
            <Grid container sx={{mt: 1, mb: 1}}>
                <Grid item sm={2}>
                    <Typography sx={{fontWeight: 600}}>{`${t('orguser.accessRequests.role')}:`}</Typography>
                </Grid>
                <Grid item sm={10}>
                    <Typography>
                        {t(
                            item.isRequestFromClient
                                ? 'orguser.accessRequests.roles.roleRequester'
                                : 'orguser.accessRequests.roles.roleOrgUser',
                        )}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item sm={2}>
                    <Typography sx={{fontWeight: 600}}>{`${t('orguser.accessRequests.updatedAt')}:`}</Typography>
                </Grid>
                <Grid item sm={10}>
                    <Typography>{moment(updatedAt).format(DATE_TIME_FORMAT)}</Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default BottomPanel;
