import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';
import {FormHasLanguageTypes} from 'appRedux/actions/forms/types';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {OrganizationLanguageTypes} from 'appRedux/actions/organizationLanguage/types';

import AddLanguageButton from 'pages/admin/updateForm/partials/FormLanguages/AddLanguageButton';
import LanguageRow from 'pages/admin/updateForm/partials/FormLanguages/LanguageRow';

import {DEFAULT_LANGUAGE} from 'config/index';

interface FormLanguagesType {
    formId: number;
    formTitle: string;
    organizationLanguage: LanguageItemTypes;
    activeOrganizationLanguages: OrganizationLanguageTypes[];
}

const FormLanguages: FC<FormLanguagesType> = ({
    formId,
    formTitle,
    organizationLanguage,
    activeOrganizationLanguages,
}) => {
    const [t] = useTranslation();

    const {
        admin: {
            formInfo: {languages},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const activeFormLanguages = languages
        ? languages.filter(item => {
              const {
                  language: {enabled, name},
                  isAvailableForOrganization,
              } = item;
              return enabled && name !== organizationLanguage.name && isAvailableForOrganization;
          })
        : [];

    return (
        <Box>
            <Typography variant="body2" sx={{fontWeight: 300, mb: 1}}>
                {organizationLanguage && organizationLanguage.name !== DEFAULT_LANGUAGE
                    ? `${organizationLanguage.lang} (${t('common.default')})`
                    : t('orguser.forms.englishDefault')}
            </Typography>
            <Box sx={{pt: 1}}>
                {activeFormLanguages &&
                    activeFormLanguages.map((item: FormHasLanguageTypes, index: number) => {
                        return (
                            <LanguageRow
                                key={`formLanguage-${index}`}
                                formId={formId}
                                formTitle={formTitle}
                                item={item}
                            />
                        );
                    })}
                {activeOrganizationLanguages.length > activeFormLanguages.length && (
                    <Box sx={{mt: 2}}>
                        <AddLanguageButton formId={formId} organizationLanguage={organizationLanguage} />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default FormLanguages;
