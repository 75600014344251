import React, {FC, useState, MouseEvent} from 'react';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import ContextMenu from 'components/TopBarMenu/ContextMenu';
import CurrentUserAvatar from 'components/UserAvatar/CurrentUserAvatar';

import {theme, MAIN_AGENT_COLOR} from 'config/theme';
import {AVATAR_SIZE} from 'config/index';

const TopBarMenu: FC = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {open ? (
                <IconButton
                    onClick={handleClick}
                    data-id="button@topbar-account-close"
                    sx={{
                        width: AVATAR_SIZE,
                        height: AVATAR_SIZE,
                        backgroundColor: MAIN_AGENT_COLOR,
                    }}
                >
                    <CloseIcon sx={{color: theme.palette.background.default}} />
                </IconButton>
            ) : (
                <IconButton
                    data-id="button@topbar-account"
                    sx={{
                        width: AVATAR_SIZE,
                        height: AVATAR_SIZE,
                    }}
                    onClick={handleClick}
                >
                    <CurrentUserAvatar size={AVATAR_SIZE} />
                </IconButton>
            )}
            <ContextMenu open={open} anchorEl={anchorEl} handleClose={handleClose} />
        </>
    );
};

export default TopBarMenu;
