import React, {ReactNode, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';

import {ClientFormContext, ClientFormContextType} from 'contexts/clientForm/context';

import ClientSaveButton from 'components/ClientScreenComponents/ClientSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

interface ContextType {
    children: ReactNode;
}

const ClientFormContextWrapper: React.FC<ContextType> = ({children}) => {
    const [t] = useTranslation();

    const [errorField, setErrorField] = useState<number | null>(null);
    const [errorPopup, setErrorPopup] = useState<number | null>(null);

    const [isSectionCheckedModalOpened, setSectionCheckedModalOpened] = useState<boolean>(false);

    const toggleSectionChecked = () => {
        setSectionCheckedModalOpened(previous => !previous);
    };

    const reloadPage = () => {
        window.location.reload();
    };

    const context: ClientFormContextType = {
        errorField,
        setErrorField,
        errorPopup,
        setErrorPopup,
        toggleSectionChecked,
    };

    return (
        <ClientFormContext.Provider value={context}>
            {children}
            {isSectionCheckedModalOpened && (
                <ModalWrapper
                    title={t('messages.warning.sectionAlreadyChecked')}
                    isShowModal={isSectionCheckedModalOpened}
                    toggleModal={toggleSectionChecked}
                >
                    <Typography sx={{mb: 2}}>{t('messages.warning.sectionAlreadyCheckedExplanation')}</Typography>
                    <ClientSaveButton
                        isLoading={false}
                        isSubmit={false}
                        onClick={reloadPage}
                        title={t('common.buttons.reload')}
                    />
                </ModalWrapper>
            )}
        </ClientFormContext.Provider>
    );
};

export default ClientFormContextWrapper;
