import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {DELETE_RESOURCE_FIELD_CALENDAR_SLOT} from 'appRedux/actions/resourceFields';
import {ResourceFieldCalendarSlotItemTypes} from 'appRedux/actions/resourceFields/types';

import {AlertContext} from 'contexts/alert/context';

import UpdateResourceFieldCalendarSlotForm from 'components/Forms/ResourceField/UpdateResourceFieldCalendarSlotForm';

import CalendarDetailsItem from 'pages/admin/updateResourceField/calendar/CalendarDetailsItem';

interface CalendarSlotsListItemType {
    item: ResourceFieldCalendarSlotItemTypes;
}

const CalendarSlotsListItem: FC<CalendarSlotsListItemType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isShowUpdateModal, setIsShowUpdateModal] = useState<boolean>(false);

    const toggleUpdateModal = () => {
        setIsShowUpdateModal(previous => !previous);
    };

    const {name, duration, uuid} = item;

    const removeCalendarSlot = useCallback(
        data => dispatch({type: DELETE_RESOURCE_FIELD_CALENDAR_SLOT.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        removeCalendarSlot({
            uuid,
            showAlert,
        });
    };

    return (
        <CalendarDetailsItem
            isShowUpdateModal={isShowUpdateModal}
            toggleUpdateModal={toggleUpdateModal}
            title={name}
            subtitle={`${duration} (${t('common.units.minutes')})`}
            onClickDelete={onClickDelete}
            updateForm={
                <UpdateResourceFieldCalendarSlotForm item={item} closeModal={() => setIsShowUpdateModal(false)} />
            }
            modalTitle={`${t('orguser.resourceFields.updateResourceField')} (${t(
                'orguser.resourceFields.calendarTypes.slots',
            )})`}
        />
    );
};

export default CalendarSlotsListItem;
