import * as yup from 'yup';

import {htmlTagsPresented, getUsernameLengthErrorMessage} from 'helpers/validation';

import {MINIMAL_NICKNAME_LENGTH, MAXIMAL_NICKNAME_LENGTH} from 'config/index';

export const REGEX_FOR_NICKNAME = /^[a-zA-Z0-9.\-_]+$/;

export const validationSchema = (t: (trans: string) => string) =>
    yup.object({
        nickname: yup
            .string()
            .required(t('messages.validation.nicknameIsRequired'))
            .matches(REGEX_FOR_NICKNAME, t('messages.validation.nicknameChars'))
            .test('unwanted', t('messages.validation.htmlTagsDenied'), value => htmlTagsPresented(value))
            .min(
                MINIMAL_NICKNAME_LENGTH,
                getUsernameLengthErrorMessage(t, 'messages.validation.nicknameMinLength', MINIMAL_NICKNAME_LENGTH),
            )
            .max(
                MAXIMAL_NICKNAME_LENGTH,
                getUsernameLengthErrorMessage(t, 'messages.validation.nicknameMaxLength', MAXIMAL_NICKNAME_LENGTH),
            ),
    });
