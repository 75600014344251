import {requestTypeCreator} from 'helpers/index';

import {
    RolesListRequestTypes,
    RoleCommonRequestTypes,
    RoleUpdateRequestTypes,
    RoleRequestTypes,
    RoleTypes,
} from 'appRedux/actions/roles/types';

export const GET_ROLES_LIST = requestTypeCreator('GET_ROLES_LIST');
export const GET_ROLES_LIST_OTHER_ORGANIZATION = requestTypeCreator('GET_ROLES_LIST_OTHER_ORGANIZATION');
export const GET_ROLE_INFORMATION = requestTypeCreator('GET_ROLE_INFORMATION');
export const CREATE_ROLE = requestTypeCreator('CREATE_ROLE');
export const UPDATE_ROLE = requestTypeCreator('UPDATE_ROLE');
export const DELETE_ROLE = requestTypeCreator('DELETE_ROLE');
export const TOGGLE_ROLE_STATUS = requestTypeCreator('TOGGLE_ROLE_STATUS');

export const getRolesList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_ROLES_LIST.ERROR,
    }),
    request: (payload: RolesListRequestTypes): IActionType<RolesListRequestTypes> => ({
        type: GET_ROLES_LIST.REQUEST,
        payload,
    }),
    success: (payload: RoleTypes[]): IActionType<RoleTypes[]> => ({
        payload,
        type: GET_ROLES_LIST.SUCCESS,
    }),
};

export const getRolesListOtherOrganization = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_ROLES_LIST_OTHER_ORGANIZATION.ERROR,
    }),
    request: (payload: RolesListRequestTypes): IActionType<RolesListRequestTypes> => ({
        type: GET_ROLES_LIST_OTHER_ORGANIZATION.REQUEST,
        payload,
    }),
    success: (payload: RoleTypes[]): IActionType<RoleTypes[]> => ({
        payload,
        type: GET_ROLES_LIST_OTHER_ORGANIZATION.SUCCESS,
    }),
};

export const getRoleInformation = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_ROLE_INFORMATION.ERROR,
    }),
    request: (payload: RoleRequestTypes): IActionType<RoleRequestTypes> => ({
        type: GET_ROLE_INFORMATION.REQUEST,
        payload,
    }),
    success: (payload: RoleTypes): IActionType<RoleTypes> => ({
        payload,
        type: GET_ROLE_INFORMATION.SUCCESS,
    }),
};

export const createRole = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_ROLE.ERROR,
    }),
    request: (payload: RoleCommonRequestTypes): IActionType<RoleCommonRequestTypes> => ({
        type: CREATE_ROLE.REQUEST,
        payload,
    }),
    success: (payload: RoleTypes): IActionType<RoleTypes> => ({
        payload,
        type: CREATE_ROLE.SUCCESS,
    }),
};

export const updateRole = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_ROLE.ERROR,
    }),
    request: (payload: RoleUpdateRequestTypes): IActionType<RoleUpdateRequestTypes> => ({
        type: UPDATE_ROLE.REQUEST,
        payload,
    }),
    success: (payload: RoleTypes): IActionType<RoleTypes> => ({
        payload,
        type: UPDATE_ROLE.SUCCESS,
    }),
};

export const deleteRole = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_ROLE.ERROR,
    }),
    request: (payload: RoleCommonRequestTypes): IActionType<RoleCommonRequestTypes> => ({
        type: DELETE_ROLE.REQUEST,
        payload,
    }),
    success: (payload: RoleTypes[]): IActionType<RoleTypes[]> => ({
        payload,
        type: DELETE_ROLE.SUCCESS,
    }),
};

export const toggleStatusRole = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: TOGGLE_ROLE_STATUS.ERROR,
    }),
    request: (payload: RoleCommonRequestTypes): IActionType<RoleCommonRequestTypes> => ({
        type: TOGGLE_ROLE_STATUS.REQUEST,
        payload,
    }),
    success: (payload: RoleTypes[]): IActionType<RoleTypes[]> => ({
        payload,
        type: TOGGLE_ROLE_STATUS.SUCCESS,
    }),
};
