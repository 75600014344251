import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import {GET_BOARDS_LIST, SET_CURRENT_BOARD} from 'appRedux/actions/workflow';
import {RootReducer} from 'appRedux/reducers';
import {BoardItemTypes} from 'appRedux/actions/workflow/types';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';
import {RouteContext} from 'contexts/route/context';
import {AdminContext} from 'contexts/admin/context';

import CustomBoardCases from 'pages/agent/blockView/CustomBoardCases';

const BoardCasesComponent: FC = () => {
    const dispatch = useDispatch();
    const {uuid} = useParams();
    const [searchParams] = useSearchParams();

    const {showAlert} = useContext(AlertContext);
    const {isCaseViewEnabled} = useContext(PermissionContext);
    const {setBackLink, setBackLinkLabel, setTopBarTitle, setTopBarSubtitle, setCustomBoardTitle} =
        useContext(RouteContext);
    const {setShowFiltersPanel} = useContext(AdminContext);

    const [currentBoard, setCurrentBoard] = useState<BoardItemTypes | null>(null);

    const getBoardsList = useCallback(data => dispatch({type: GET_BOARDS_LIST.REQUEST, payload: data}), [dispatch]);
    const setCurrentBoardState = useCallback(data => dispatch({type: SET_CURRENT_BOARD, payload: data}), [dispatch]);

    const {
        workflow: {boards},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        setBackLink(null);
        setBackLinkLabel(null);
        setTopBarSubtitle('');
    }, []);

    useEffect(() => {
        const current = boards.find((item: BoardItemTypes) => {
            return item.uuid === uuid;
        });
        if (current) {
            setTopBarTitle(current.title);
            setCurrentBoard(current);
            setCustomBoardTitle(current.title);
        } else {
            setTopBarTitle('');
        }
    }, [uuid, boards]);

    useEffect(() => {
        const params: [string, string][] = [];

        const current = boards.find((item: BoardItemTypes) => {
            return item.uuid === uuid;
        });

        for (const entry of searchParams.entries()) {
            params.push(entry);
        }
        if (current) {
            setCurrentBoardState({id: current.uuid, params});
        }
    }, [uuid, boards, searchParams]);

    useEffect(() => {
        if (isCaseViewEnabled) {
            getBoardsList({
                showAlert,
            });
        }
    }, []);

    useEffect(() => {
        setShowFiltersPanel(false);
    }, [uuid]);

    if (!currentBoard) return null;

    return <CustomBoardCases currentBoard={currentBoard} />;
};

export default BoardCasesComponent;
