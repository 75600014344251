import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {SelectChangeEvent} from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import {PanelHasFieldItemTypes} from 'appRedux/actions/analytics/types';
import {FormFieldFilterType} from 'appRedux/actions/forms/types';

import {theme} from 'config/theme';

interface FormFieldFilterSelectorsTypes {
    panelFields: PanelHasFieldItemTypes[];
    setPanelFields: (values: PanelHasFieldItemTypes[]) => void;
    fieldFilters: FormFieldFilterType[];
}

const FormFieldFilterSelectors: FC<FormFieldFilterSelectorsTypes> = ({panelFields, setPanelFields, fieldFilters}) => {
    const [t] = useTranslation();
    const [, setIsUpdated] = useState<boolean>(false);

    const onFormFieldChecked = (event: SelectChangeEvent) => {
        const fieldId = Number(event.target.value);
        const isChecked = panelFields.some(item => item.field === fieldId);
        if (isChecked) {
            for (let i = 0, n = panelFields.length; i < n; i++) {
                const panelHasStatus = panelFields[i];
                if (panelHasStatus.field === fieldId) {
                    panelFields.splice(i, 1);
                    setPanelFields(panelFields);
                    setIsUpdated(previous => !previous);
                    break;
                }
            }
        } else {
            setPanelFields([
                ...panelFields,
                {
                    field: fieldId,
                },
            ]);
        }
    };

    return (
        <Box
            sx={{
                mt: 2,
                mb: 2,
            }}
        >
            <Typography sx={{fontWeight: 700, mb: 2}}>{`${t('orguser.analytics.panels.selectFields')}:`}</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}
            >
                {fieldFilters &&
                    fieldFilters.map((item: FormFieldFilterType) => {
                        const {label, id} = item;
                        const isChecked = panelFields.some(item => Number(item.field) === Number(id));
                        return (
                            <FormControlLabel
                                key={`form-field-checkbox-${id}`}
                                sx={{
                                    width: '24%',
                                }}
                                control={
                                    <Checkbox
                                        name={'field'}
                                        value={id}
                                        checked={isChecked}
                                        onChange={onFormFieldChecked}
                                        sx={{
                                            pt: 0.5,
                                            pb: 0.5,
                                            '& svg': {color: theme.palette.info.main},
                                        }}
                                    />
                                }
                                label={label}
                            />
                        );
                    })}
            </Box>
        </Box>
    );
};

export default FormFieldFilterSelectors;
