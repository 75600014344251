import React, {FC} from 'react';
import {buildStyles, CircularProgressbarWithChildren} from 'react-circular-progressbar';

import Box from '@mui/material/Box';

import {RequesterCaseResponseTypes} from 'appRedux/actions/admin/types';

import AvatarImage from 'components/UserAvatar/AvatarImage';
import {getAvatarTitle} from 'components/TopBarMenu/helper';

import {theme} from 'config/theme';
import {SxProps} from '@mui/material';

interface AvatarWithProgressType {
    item: RequesterCaseResponseTypes;
    sx?: SxProps;
}

const AvatarWithProgress: FC<AvatarWithProgressType> = ({item, sx}) => {
    const {progress, avatarLink, avatarType, firstName, lastName, email} = item;
    return (
        <Box
            sx={{
                width: 38,
                height: 38,
                '& div div:not(.MuiAvatar-root)': {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'end',
                },
                ...sx,
            }}
        >
            <CircularProgressbarWithChildren
                value={progress}
                strokeWidth={6}
                styles={{
                    root: {
                        verticalAlign: 'middle',
                    },
                    ...buildStyles({
                        pathColor: theme.palette.info.main,
                        trailColor: theme.palette.warning.light,
                    }),
                }}
            >
                <AvatarImage
                    size={32}
                    avatarLink={avatarLink}
                    avatarType={avatarType}
                    avatarTitle={getAvatarTitle(firstName, lastName, email)}
                />
            </CircularProgressbarWithChildren>
        </Box>
    );
};

export default AvatarWithProgress;
