import React, {FC} from 'react';
import get from 'lodash/get';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import WarningSvgIcon from 'assets/icons/buttons/WarningSvgIcon';
import ProgressSvgIcon from 'assets/icons/buttons/ProgressSvgIcon';

import {FormWorkflowStatusTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import HasNotApprovedStatusChangeRequestsButton from 'components/HasNotApprovedStatusChangeRequestsButton/HasNotApprovedStatusChangeRequestsButton';

import ChangeStatusForm from 'pages/agent/requesterPage/formInformation/status/ChangeStatusForm';
import WaitingForApprove from 'pages/agent/requesterPage/formInformation/status/WaitingForApprove';
import RelatedTasksButton from 'pages/agent/requesterPage/formInformation/relatedTasks/RelatedTasksButton';
import CurrentStatusLabel from 'pages/agent/requesterPage/formInformation/status/CurrentStatusLabel';

import {STATUS_OPENING} from 'config/index';

interface RequesterCaseStatusType {
    isEditEnabled: boolean;
}

const RequesterCaseStatus: FC<RequesterCaseStatusType> = ({isEditEnabled}) => {
    const [t] = useTranslation();

    const {
        admin: {formInfo},
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {
        statusColor,
        status,
        statusTitle,
        statusUuid,
        hasNotApprovedStatusRequestChanges,
        hasRejectedStatusChangingRequest,
        tasks,
    } = currentCase;

    const statuses: FormWorkflowStatusTypes[] = get(formInfo, 'statuses', []);

    const currentStatus = statuses.find(item => item.uuid === statusUuid);

    if (status === STATUS_OPENING) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                }}
            >
                <CurrentStatusLabel
                    title={currentStatus ? currentStatus.title : statusTitle}
                    color={currentStatus ? currentStatus.color : statusColor}
                />
                {tasks && tasks.length > 0 && <RelatedTasksButton />}
            </Box>
        );
    }
    if (hasRejectedStatusChangingRequest) {
        return (
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <CurrentStatusLabel
                        title={currentStatus ? currentStatus.title : statusTitle}
                        color={currentStatus ? currentStatus.color : statusColor}
                    />
                    <HasNotApprovedStatusChangeRequestsButton
                        title={t(
                            hasRejectedStatusChangingRequest
                                ? 'orguser.requesterCase.hasRejectedRequests'
                                : 'orguser.requesterCase.hasNotApprovedStatusChangingRequest',
                        )}
                        icon={hasRejectedStatusChangingRequest ? WarningSvgIcon : ProgressSvgIcon}
                    />
                    {tasks && tasks.length > 0 && <RelatedTasksButton />}
                </Box>
                <ChangeStatusForm
                    initialStatus={statusUuid}
                    hasRejectedStatusChangingRequest={hasRejectedStatusChangingRequest}
                />
            </Box>
        );
    }
    if (isEditEnabled && !hasNotApprovedStatusRequestChanges) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                }}
            >
                <ChangeStatusForm
                    initialStatus={statusUuid}
                    hasRejectedStatusChangingRequest={hasRejectedStatusChangingRequest}
                />
                {tasks && tasks.length > 0 && <RelatedTasksButton />}
            </Box>
        );
    }
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                }}
            >
                <CurrentStatusLabel
                    title={currentStatus ? currentStatus.title : statusTitle}
                    color={currentStatus ? currentStatus.color : statusColor}
                />
                {tasks && tasks.length > 0 && <RelatedTasksButton />}
            </Box>
            {hasNotApprovedStatusRequestChanges && <WaitingForApprove isEditEnabled={isEditEnabled} />}
        </>
    );
};

export default RequesterCaseStatus;
