import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';

import {FORM_FAQ_DELETE} from 'appRedux/actions/forms';
import {FormFaqItemTypes} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationButton from 'components/AdminScreenComponents/DeleteAdminConfirmationButton';

interface DeleteFormFaqButtonType {
    item: FormFaqItemTypes;
}

const DeleteFormFaqButton: FC<DeleteFormFaqButtonType> = ({item}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {uuid} = item;

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    const deleteFormFaq = useCallback(data => dispatch({type: FORM_FAQ_DELETE.REQUEST, payload: data}), [dispatch]);

    const onClickDelete = () => {
        deleteFormFaq({
            uuid,
            showAlert,
            callback: () => setShowModal(false),
        });
    };

    return (
        <DeleteAdminConfirmationButton
            disabled={false}
            isShowModal={showModal}
            toggleModal={toggleModal}
            onClickAction={onClickDelete}
        />
    );
};

export default DeleteFormFaqButton;
