import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {ResourceFieldFormTypes, ResourceFieldItemTypes} from 'appRedux/actions/resourceFields/types';
import {UPDATE_RESOURCE_FIELD} from 'appRedux/actions/resourceFields';

import {AlertContext} from 'contexts/alert/context';

import ResourceFieldForm from 'components/Forms/ResourceField/ResourceFieldForm';

interface UpdateResourceFieldFormType {
    item: ResourceFieldItemTypes;
}

const UpdateResourceFieldForm: FC<UpdateResourceFieldFormType> = ({item}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const updateResourceField = useCallback(
        data => dispatch({type: UPDATE_RESOURCE_FIELD.REQUEST, payload: data}),
        [dispatch],
    );

    const {uuid, title, type, workflow, scope, statusBooking, statusClearing, statusReservation} = item;

    const onSubmitClicked = (values: ResourceFieldFormTypes) => {
        updateResourceField({
            ...values,
            uuid,
            showAlert,
        });
    };

    return (
        <ResourceFieldForm
            initialValues={{
                title,
                type,
                scope,
                workflow,
                statusBooking,
                statusClearing,
                statusReservation,
            }}
            onSubmitClicked={onSubmitClicked}
        />
    );
};

export default UpdateResourceFieldForm;
