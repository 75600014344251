import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const BoardSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13 0H7.5C7.23486 0.000264738 6.98066 0.105707 6.79319 0.293186C6.60571 0.480665 6.50026 0.734865 6.5 1V4H1C0.734865 4.00026 0.480665 4.10571 0.293186 4.29319C0.105707 4.48066 0.000264738 4.73486 0 5V13C0.000304367 13.2651 0.105759 13.5193 0.293229 13.7068C0.4807 13.8942 0.734877 13.9997 1 14H6.5C6.76512 13.9997 7.0193 13.8942 7.20677 13.7068C7.39424 13.5193 7.4997 13.2651 7.5 13V10H13C13.2651 9.99964 13.5193 9.89417 13.7067 9.70671C13.8942 9.51925 13.9996 9.26511 14 9V1C13.9997 0.734877 13.8942 0.4807 13.7068 0.293229C13.5193 0.105759 13.2651 0.000304367 13 0ZM13 1V3H7.5V1H13ZM6.50045 10H1V8H6.5L6.50045 10ZM7.5 4H13.0003L13.0006 6H7.5V4ZM6.5 5V7H1V5H6.5ZM1 13V11H6.50055V13H1ZM7.5 9V7H13.0007V9H7.5Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default BoardSvgIcon;
