import React, {FC} from 'react';

const AttachSvgIcon: FC<{color?: string}> = ({color}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.0496 9.45009L6.54961 1.95009C5.29961 0.650092 3.24961 0.650092 1.94961 1.90009C0.649609 3.15009 0.649609 5.25009 1.94961 6.50009L1.99961 6.55009L3.39961 8.00009L4.09961 7.30009L2.64961 5.85009C1.79961 5.00009 1.79961 3.55009 2.64961 2.70009C3.49961 1.85009 4.94961 1.80009 5.79961 2.65009C5.79961 2.65009 5.79961 2.65009 5.84961 2.70009L13.2996 10.1501C14.1996 11.0001 14.1996 12.4501 13.3496 13.3001C12.4996 14.2001 11.0496 14.2001 10.1996 13.3501C10.1996 13.3501 10.1996 13.3501 10.1496 13.3001L6.44961 9.60009C5.94961 9.10009 5.99961 8.30009 6.44961 7.85009C6.94961 7.40009 7.69961 7.40009 8.19961 7.85009L10.2496 9.90009L10.9496 9.20009L8.84961 7.10009C7.94961 6.25009 6.54961 6.30009 5.69961 7.20009C4.89961 8.05009 4.89961 9.40009 5.69961 10.3001L9.44961 14.0501C10.6996 15.3501 12.7496 15.3501 14.0496 14.1001C15.3496 12.8501 15.3496 10.7501 14.0496 9.45009C14.0496 9.50009 14.0496 9.45009 14.0496 9.45009Z"
                fill={color ?? '#222222'}
            />
        </svg>
    );
};

export default AttachSvgIcon;
