import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FormFieldTypes, ResourceFieldBudgetInformationType} from 'appRedux/actions/forms/types';
import {RequesterCaseHasBudgetTypes} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import {TYPE_BUDGET_INPUT_CALENDAR} from 'components/Forms/FormBuilder/FieldForm/helper';

import BudgetAmountEditButton from 'pages/client/form/fieldTypes/resourceFields/BudgetAmountEditButton';

import {getBudgetAmountValue} from 'helpers/resourceFieldsHelper';

import {DATE_LABEL_FORMAT, STATUS_OPENING, STATUS_PREPARED} from 'config/index';

interface BudgetAskedRequesterType {
    caseId: number;
    currentBudget: RequesterCaseHasBudgetTypes;
    resourceFieldBudgetInformation: ResourceFieldBudgetInformationType;
    field: FormFieldTypes;
}

const BudgetAskedRequester: FC<BudgetAskedRequesterType> = ({
    caseId,
    field,
    currentBudget,
    resourceFieldBudgetInformation,
}) => {
    const [t] = useTranslation();

    const {
        requestCase: {
            currentCase: {status},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isCalendarInputType = field.typeBudgetInput === TYPE_BUDGET_INPUT_CALENDAR;

    const {amountUser, startUserAt, endUserAt} = currentBudget;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mt: 2,
                mb: 2,
            }}
        >
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        mb: isCalendarInputType ? 1 : 0,
                    }}
                >
                    <Typography sx={{fontWeight: 700, mr: 1}}>{`${t(
                        'orguser.resourceFields.askedAmount',
                    )}:`}</Typography>
                    <Typography>{getBudgetAmountValue(resourceFieldBudgetInformation.type, amountUser)}</Typography>
                </Box>
                {isCalendarInputType && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <Typography sx={{fontWeight: 700, mr: 1}}>{`${t(
                            'orguser.resourceFields.askedDateRange',
                        )}:`}</Typography>
                        <Typography>
                            {`${moment(startUserAt).format(DATE_LABEL_FORMAT)} - ${moment(endUserAt).format(
                                DATE_LABEL_FORMAT,
                            )}`}
                        </Typography>
                    </Box>
                )}
            </Box>
            {(status === STATUS_OPENING || status === STATUS_PREPARED) && (
                <BudgetAmountEditButton
                    caseId={caseId}
                    field={field}
                    currentBudget={currentBudget}
                    resourceFieldBudgetInformation={resourceFieldBudgetInformation}
                />
            )}
        </Box>
    );
};

export default BudgetAskedRequester;
