import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const ImportSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.295 6.295L13 7L8 12L3 7L3.705 6.295L7.5 10.085V1H8.5V10.085L12.295 6.295ZM13 14V12H14V14C14 14.2652 13.8946 14.5196 13.7071 14.7071C13.5196 14.8946 13.2652 15 13 15H3C2.73478 15 2.48043 14.8946 2.29289 14.7071C2.10536 14.5196 2 14.2652 2 14V12H3V14H13Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default ImportSvgIcon;
