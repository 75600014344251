import React, {FC} from 'react';
import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Box';

import {CreateOrganizationRequestTypes} from 'appRedux/actions/organization/types';

import OrganizationLogoForm from 'components/Forms/OrganizationForm/OrganizationLogoForm';

import {ORGANIZATION_MEDIA_MAIN_LOGO, ORGANIZATION_MEDIA_NAVBAR_LOGO, ORGANIZATION_MEDIA_FAVICON} from 'config/index';

interface OrganizationMediaFormsType {
    id: number;
    organization: CreateOrganizationRequestTypes;
}

const OrganizationMediaForms: FC<OrganizationMediaFormsType> = ({id, organization}) => {
    const [t] = useTranslation();

    return (
        <>
            <Box>
                <Typography sx={{fontWeight: 600, ml: 3, mb: 1}}>{`${t(
                    'superadmin.organizations.mainLogo',
                )}:`}</Typography>
                <OrganizationLogoForm id={id} initialValues={organization} type={ORGANIZATION_MEDIA_MAIN_LOGO} />
            </Box>
            <Box sx={{mt: 2}}>
                <Typography sx={{fontWeight: 600, ml: 3, mb: 1}}>{`${t(
                    'superadmin.organizations.navBarLogo',
                )}:`}</Typography>
                <OrganizationLogoForm id={id} initialValues={organization} type={ORGANIZATION_MEDIA_NAVBAR_LOGO} />
            </Box>
            <Box sx={{mt: 2}}>
                <Typography sx={{fontWeight: 600, ml: 3, mb: 1}}>{`${t(
                    'superadmin.organizations.favicon',
                )}:`}</Typography>
                <OrganizationLogoForm id={id} initialValues={organization} type={ORGANIZATION_MEDIA_FAVICON} />
            </Box>
        </>
    );
};

export default OrganizationMediaForms;
