import React, {FC} from 'react';
import get from 'lodash/get';
import {useSelector} from 'react-redux';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';

import TopBarMenu from 'components/TopBarMenu/TopBarMenu';
import EmailNotVerified from 'components/ClientScreenComponents/EmailNotVerified';
import CryptoKeysAccessNeeded from 'components/ClientScreenComponents/CryptoKeysAccessNeeded';
import SessionTimer from 'components/SessionTimer/SessionTimer';
import EmptyArea from 'components/EmptyArea';
import TopBarSidebarMenu from 'components/ClientScreenComponents/TopBarSidebarMenu/TopBarSidebarMenu';

import {theme} from 'config/theme';
import {DEFAULT_TOP_BAR_LABEL} from 'config/index';

const TopBar: FC = () => {
    const {
        profile,
        auth: {organization},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isUserVerified = get(profile, ['profile', 'isVerify'], false);

    return (
        <AppBar position="sticky">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: theme.palette.info.main,
                    height: 60,
                    pl: 4,
                    pr: 5,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                        alignItems: 'center',
                        gap: 3,
                    }}
                >
                    <TopBarSidebarMenu />
                    <Typography
                        variant="body1"
                        sx={{
                            color: theme.palette.background.default,
                            fontWeight: 600,
                        }}
                    >
                        {organization?.name ?? DEFAULT_TOP_BAR_LABEL}
                    </Typography>
                </Box>
                <EmptyArea />
                <SessionTimer inverse />
                <TopBarMenu />
            </Box>
            {!isUserVerified && <EmailNotVerified />}
            <CryptoKeysAccessNeeded />
        </AppBar>
    );
};

export default TopBar;
