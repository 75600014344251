import React, {ReactNode} from 'react';
import {useSelector} from 'react-redux';
import {Navigate, useLocation} from 'react-router-dom';
import get from 'lodash/get';
import {useTranslation} from 'react-i18next';
import Countdown from 'react-countdown';
import {useNavigate} from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';

import StartScreenWrapper from 'components/StartScreenComponents/StartScreenWrapper';
import {isUserHasRole} from 'components/PrivateRoute/helper';
import EmptyArea from 'components/EmptyArea';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {LOCAL_STORAGE_REDIRECT_AFTER_LOGIN} from 'services/localStorage';

const COUNTDOWN_TIME = 15;

const PrivateRoute = ({children, roles}: {children: ReactNode; roles: string[]}) => {
    const [t] = useTranslation();
    const location = useLocation();
    const {pathname, search} = useLocation();
    const navigate = useNavigate();

    const {
        auth: {authData, isLoading},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isTwoFactorActivated = get(profile, 'isTwoFactorActivated', false);
    const isTwoFactorCompleted = get(authData, 'isTwoFactorCompleted', false);
    const token = get(authData, 'token', null);

    const userHasRequiredRole = profile ? isUserHasRole(profile.roles, roles) : false;
    const isAuthenticated = (!isTwoFactorActivated && token) || (isTwoFactorActivated && isTwoFactorCompleted && token);
    const isMultiRole = profile ? profile.roles.length > 1 : false;

    if (!isLoading && !isAuthenticated) {
        localStorage.setItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN, `${pathname}${search}`);
        return <Navigate to="/" state={{from: location}} />;
    }
    if (isAuthenticated && !userHasRequiredRole && !isMultiRole) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const renderer = ({seconds, completed}) => {
            if (completed) {
                navigate('/');
            }
            return (
                <Typography align="center" sx={{mt: 1, mb: 4}}>
                    {t('messages.error.countdownRedirectText').replace('%NUMBER%', seconds)}
                </Typography>
            );
        };

        return (
            <StartScreenWrapper>
                <Box
                    sx={{
                        minHeight: 400,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <EmptyArea />
                    <Typography sx={{fontWidth: 600, fontSize: 16}}>
                        {t('messages.error.errorDescription403')}
                    </Typography>
                    <Countdown date={Date.now() + COUNTDOWN_TIME * 1000} renderer={renderer} />
                    <AgentSaveButton
                        isBigButton
                        isSubmit={false}
                        onClick={() => navigate('/')}
                        title={t('common.buttons.redirect')}
                    />
                    <EmptyArea />
                </Box>
            </StartScreenWrapper>
        );
    }

    return <>{children}</>;
};

export default PrivateRoute;
