import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import moment from 'moment/moment';
import {useTranslation} from 'react-i18next';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';

import {RuleTypes} from 'appRedux/actions/rules/types';

import {AdminContext} from 'contexts/admin/context';

import {
    TableHeadTypography,
    TableBodyTypography,
    TableCellWithoutBorder,
} from 'components/AgentScreenComponents/_form/CustomElements';
import VersionPreviewButton from 'components/Rules/VersionPreviewButton';
import MarkdownWithShowAll from 'components/TextWithShowAll/MarkdownWithShowAll';

import {DATE_TIME_FORMAT} from 'config/index';

interface RulesTableType {
    itemsTotalNumber: number;
    rules: RuleTypes[];
}

const VERSION_COLUMN_WIDTH = 350;

const RulesTable: FC<RulesTableType> = ({rules, itemsTotalNumber}) => {
    const [t] = useTranslation();

    const ref = useRef<HTMLTableRowElement | null>(null);

    const {itemsPerPage, currentPage} = useContext(AdminContext);

    const [textWidth, setTextWidth] = useState<number>(0);

    useEffect(() => {
        if (ref && ref.current) {
            setTextWidth(ref.current.offsetWidth - VERSION_COLUMN_WIDTH);
        }
    }, []);

    return (
        <Table size="small">
            <TableHead>
                <TableRow ref={ref}>
                    <TableCellWithoutBorder width={textWidth}>
                        <TableHeadTypography text={t('superadmin.rules.text')} />
                    </TableCellWithoutBorder>
                    <TableCellWithoutBorder width={VERSION_COLUMN_WIDTH}>
                        <TableHeadTypography text={t('superadmin.rules.version')} />
                    </TableCellWithoutBorder>
                    <TableCellWithoutBorder>
                        <TableHeadTypography text={t('superadmin.rules.action')} />
                    </TableCellWithoutBorder>
                </TableRow>
            </TableHead>
            <TableBody>
                {rules &&
                    rules.map((item: RuleTypes, index: number) => {
                        const {type, text, createdAt} = item;
                        const versionTitle = `${t('superadmin.rules.version')} ${
                            itemsTotalNumber - (currentPage - 1) * itemsPerPage - index
                        } - ${moment(createdAt).format(DATE_TIME_FORMAT)}`;
                        return (
                            <TableRow key={`rule-${type}-${index}`} sx={{height: 40}}>
                                <TableCellWithoutBorder>
                                    <MarkdownWithShowAll text={text} />
                                </TableCellWithoutBorder>
                                <TableCellWithoutBorder width={VERSION_COLUMN_WIDTH}>
                                    <TableBodyTypography text={versionTitle} />
                                </TableCellWithoutBorder>
                                <TableCellWithoutBorder>
                                    <VersionPreviewButton item={item} versionTitle={versionTitle} />
                                </TableCellWithoutBorder>
                            </TableRow>
                        );
                    })}
            </TableBody>
        </Table>
    );
};

export default RulesTable;
