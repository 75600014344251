import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';

import Typography from '@mui/material/Typography';

import {USER_VERIFICATION} from 'appRedux/actions/auth';

import {AlertContext} from 'contexts/alert/context';

import LoginPageWrapper from 'pages/start/login/partials/LoginPageWrapper';

const Verification: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    const {showAlert} = useContext(AlertContext);

    const userVerificationRequest = useCallback(
        data => dispatch({type: USER_VERIFICATION.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        userVerificationRequest({
            search: location.search,
            showAlert,
            callback: () => {
                setTimeout(() => {
                    window.location.href = '/';
                }, 2000);
            },
        });
    }, []);

    return (
        <LoginPageWrapper>
            <Typography sx={{fontSize: 18, fontWeight: 600, mt: 2, mb: 1}}>{t('common.pleaseWait')}</Typography>
        </LoginPageWrapper>
    );
};

export default Verification;
