import React, {FC} from 'react';

import Box from '@mui/material/Box';

import {WorkflowEventGroupTypes} from 'appRedux/actions/workflow/types';

import AttachedGroupListItem from 'pages/admin/updateWorkflowEvents/workflowEventGroup/AttachedGroupListItem';

interface AttachedGroupsListType {
    items: WorkflowEventGroupTypes[];
}

const AttachedGroupsList: FC<AttachedGroupsListType> = ({items}) => {
    return (
        <Box sx={{mt: 2, mb: 1}}>
            {items &&
                items.map((group: WorkflowEventGroupTypes) => {
                    return <AttachedGroupListItem item={group} key={`attached-group-${group.uuid}`} />;
                })}
        </Box>
    );
};

export default AttachedGroupsList;
