import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';

import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {FormMultipleChangeStatusType} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';
import {AdminContext} from 'contexts/admin/context';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';

interface ActiveFormsNumberQuotaAlertPopupType {
    isOpened: boolean;
    setIsOpened: (value: boolean) => void;
    toggleIsOpened: () => void;
    isQuotaHard: boolean;
    changeStatusMultiple: (values: FormMultipleChangeStatusType) => void;
}

const ActiveFormsNumberQuotaAlertMultiplePopup: FC<ActiveFormsNumberQuotaAlertPopupType> = ({
    isOpened,
    setIsOpened,
    toggleIsOpened,
    isQuotaHard,
    changeStatusMultiple,
}) => {
    const [t] = useTranslation();

    const {showAlert} = useContext(AlertContext);
    const {selectedIds, setSelectedIds} = useContext(AdminContext);

    const clearAllSelections = () => {
        setSelectedIds([]);
    };

    return (
        <ModalWrapper isShowModal={isOpened} toggleModal={toggleIsOpened} title={t('orguser.forms.activateAll')}>
            <Alert severity={'error'}>
                <Typography sx={{fontWeight: 600, mb: 1}}>
                    {t(
                        isQuotaHard
                            ? 'messages.error.errorMaximalActiveFormsNumberReached'
                            : 'messages.error.organizationQuotaActiveFormsNumberReached',
                    )}
                </Typography>
                {!isQuotaHard && <Typography>{t('messages.warning.additionalCostWillBeGenerated')}</Typography>}
            </Alert>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: isQuotaHard ? 'center' : 'space-between',
                    alignItems: 'center',
                    mt: 2,
                }}
            >
                {isQuotaHard ? (
                    <AgentSaveButton
                        isSubmit={false}
                        title={'close'}
                        onClick={() => {
                            setIsOpened(false);
                            clearAllSelections();
                        }}
                    />
                ) : (
                    <>
                        <AgentInverseButton
                            title={t('common.buttons.cancel')}
                            onClick={() => {
                                setIsOpened(false);
                                clearAllSelections();
                            }}
                        />
                        <AgentSaveButton
                            isSubmit={false}
                            title={t('common.buttons.activate')}
                            onClick={() => {
                                setIsOpened(false);
                                changeStatusMultiple({
                                    ids: selectedIds,
                                    isActive: true,
                                    callback: () => {
                                        clearAllSelections();
                                        setIsOpened(false);
                                    },
                                    showAlert,
                                });
                            }}
                        />
                    </>
                )}
            </Box>
        </ModalWrapper>
    );
};

export default ActiveFormsNumberQuotaAlertMultiplePopup;
