import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {ResourceFieldFormTypes} from 'appRedux/actions/resourceFields/types';
import {CREATE_RESOURCE_FIELD} from 'appRedux/actions/resourceFields';

import {AlertContext} from 'contexts/alert/context';

import ResourceFieldForm from 'components/Forms/ResourceField/ResourceFieldForm';

interface CreateResourceFieldFormType {
    organizationId: number;
    closeModal: () => void;
}

const CreateResourceFieldForm: FC<CreateResourceFieldFormType> = ({organizationId, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const createResourceField = useCallback(
        data => dispatch({type: CREATE_RESOURCE_FIELD.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: ResourceFieldFormTypes) => {
        createResourceField({
            ...values,
            id: organizationId,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <ResourceFieldForm
            initialValues={{
                title: '',
                type: 0,
                scope: 0,
                workflow: null,
                statusReservation: null,
                statusBooking: null,
                statusClearing: null,
            }}
            onSubmitClicked={onSubmitClicked}
            isCreate
        />
    );
};

export default CreateResourceFieldForm;
