import React, {FC} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

import {CreateSwimlaneBoardRequestTypes, WorkflowItemTypes} from 'appRedux/actions/workflow/types';
import {RootReducer} from 'appRedux/reducers';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import {validationSchemaForSwimlane} from 'components/Forms/BoardForm/validation';
import {iconTypes, swimlaneTypes} from 'components/Forms/BoardForm/helper';

import {IconImageType, SwimlaneType} from 'pages/agent/savedFilters/helper';

interface EditBoardSwimlaneFormType {
    isCreate?: boolean;
    initialValues: CreateSwimlaneBoardRequestTypes;
    onSubmitClick: (values: CreateSwimlaneBoardRequestTypes) => void;
}

const EditBoardSwimlaneForm: FC<EditBoardSwimlaneFormType> = ({initialValues, isCreate, onSubmitClick}) => {
    const [t] = useTranslation();

    const {
        workflow: {organizationWorkflows},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchemaForSwimlane} onSubmit={onSubmitClick}>
            {(formik: FormikProps<CreateSwimlaneBoardRequestTypes>) => {
                return (
                    <Form>
                        <Box>
                            <Field
                                required
                                name="title"
                                placeholder={t('orguser.boards.parameters.title')}
                                component={FormikTextInput}
                            />
                        </Box>
                        {isCreate && (
                            <FormControl sx={{mt: 1}} required fullWidth>
                                <InputLabel>{t('orguser.boards.parameters.workflow')}</InputLabel>
                                <Select
                                    label={t('orguser.boards.parameters.workflow')}
                                    name={'workflow'}
                                    value={formik.values.workflow}
                                    onChange={formik.handleChange}
                                    IconComponent={KeyboardArrowDownIcon}
                                    fullWidth
                                >
                                    {organizationWorkflows &&
                                        organizationWorkflows.map((item: WorkflowItemTypes, index: number) => {
                                            return (
                                                <MenuItem key={`workflow-${index}`} value={item.id}>
                                                    <Typography sx={{fontWeight: 600}}>{item.title}</Typography>
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        )}
                        <FormControl sx={{mt: 2}} required fullWidth>
                            <InputLabel>{t('orguser.boards.parameters.swimlaneType')}</InputLabel>
                            <Select
                                label={t('orguser.boards.parameters.swimlaneType')}
                                name={'typeSwimlane'}
                                value={formik.values.typeSwimlane}
                                onChange={formik.handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                fullWidth
                            >
                                {swimlaneTypes.map(({type, label}: SwimlaneType) => {
                                    return (
                                        <MenuItem value={type} key={`icon-type-${type}`}>
                                            <Typography sx={{fontWeight: 600}}>{t(label)}</Typography>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <FormControl sx={{mt: 2}} required fullWidth>
                            <InputLabel>{t('orguser.boards.parameters.iconType')}</InputLabel>
                            <Select
                                label={t('orguser.boards.parameters.iconType')}
                                name={'iconType'}
                                value={formik.values.iconType}
                                onChange={formik.handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                fullWidth
                            >
                                {iconTypes.map(({type, icon}: IconImageType) => {
                                    const Icon = icon;
                                    return (
                                        <MenuItem value={type} key={`icon-type-${type}`}>
                                            <IconButton>
                                                <Icon />
                                            </IconButton>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: 3,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default EditBoardSwimlaneForm;
