import {requestTypeCreator} from 'helpers/index';

import {
    LoginAttemptRequest,
    LoginAttemptResponseTypes,
    DraftRemovesRequestTypes,
    DraftRemoveDataResponseTypes,
    LoginAttemptsStatisticRequestTypes,
    LoginAttemptsStatisticItemTypes,
    PasswordResetAttemptsStatisticRequestTypes,
    MailSendingStatisticRequestTypes,
} from 'appRedux/actions/superadmin/types';

export const GET_LOGIN_ATTEMPTS = requestTypeCreator('GET_LOGIN_ATTEMPTS');
export const GET_PASSWORD_RESET_ATTEMPTS = requestTypeCreator('GET_PASSWORD_RESET_ATTEMPTS');
export const GET_DRAFT_REMOVES = requestTypeCreator('GET_DRAFT_REMOVES');
export const GET_LOGIN_ATTEMPTS_STATISTIC = requestTypeCreator('GET_LOGIN_ATTEMPTS_STATISTIC');
export const GET_PASSWORD_RESET_STATISTIC = requestTypeCreator('GET_PASSWORD_RESET_STATISTIC');
export const GET_MAIL_SENDING_STATISTIC = requestTypeCreator('GET_MAIL_SENDING_STATISTIC');

export const getLoginAttemptsList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_LOGIN_ATTEMPTS.ERROR,
    }),
    request: (payload: LoginAttemptRequest): IActionType<LoginAttemptRequest> => ({
        payload,
        type: GET_LOGIN_ATTEMPTS.REQUEST,
    }),
    success: (payload: LoginAttemptResponseTypes): IActionType<LoginAttemptResponseTypes> => ({
        payload,
        type: GET_LOGIN_ATTEMPTS.SUCCESS,
    }),
};

export const getPasswordResetAttemptsList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_PASSWORD_RESET_ATTEMPTS.ERROR,
    }),
    request: (payload: LoginAttemptRequest): IActionType<LoginAttemptRequest> => ({
        payload,
        type: GET_PASSWORD_RESET_ATTEMPTS.REQUEST,
    }),
    success: (payload: LoginAttemptResponseTypes): IActionType<LoginAttemptResponseTypes> => ({
        payload,
        type: GET_PASSWORD_RESET_ATTEMPTS.SUCCESS,
    }),
};

export const getDraftRemoves = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_DRAFT_REMOVES.ERROR,
    }),
    request: (payload: DraftRemovesRequestTypes): IActionType<DraftRemovesRequestTypes> => ({
        payload,
        type: GET_DRAFT_REMOVES.REQUEST,
    }),
    success: (payload: DraftRemoveDataResponseTypes): IActionType<DraftRemoveDataResponseTypes> => ({
        payload,
        type: GET_DRAFT_REMOVES.SUCCESS,
    }),
};

export const getLoginAttemptsStatistic = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_LOGIN_ATTEMPTS_STATISTIC.ERROR,
    }),
    request: (payload: LoginAttemptsStatisticRequestTypes): IActionType<LoginAttemptsStatisticRequestTypes> => ({
        payload,
        type: GET_LOGIN_ATTEMPTS_STATISTIC.REQUEST,
    }),
    success: (payload: LoginAttemptsStatisticItemTypes[]): IActionType<LoginAttemptsStatisticItemTypes[]> => ({
        payload,
        type: GET_LOGIN_ATTEMPTS_STATISTIC.SUCCESS,
    }),
};

export const getPasswordResetAttemptsStatistic = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_PASSWORD_RESET_STATISTIC.ERROR,
    }),
    request: (
        payload: PasswordResetAttemptsStatisticRequestTypes,
    ): IActionType<PasswordResetAttemptsStatisticRequestTypes> => ({
        payload,
        type: GET_PASSWORD_RESET_STATISTIC.REQUEST,
    }),
    success: (payload: LoginAttemptsStatisticItemTypes[]): IActionType<LoginAttemptsStatisticItemTypes[]> => ({
        payload,
        type: GET_PASSWORD_RESET_STATISTIC.SUCCESS,
    }),
};

export const getMailSendingStatistic = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_MAIL_SENDING_STATISTIC.ERROR,
    }),
    request: (payload: MailSendingStatisticRequestTypes): IActionType<MailSendingStatisticRequestTypes> => ({
        payload,
        type: GET_MAIL_SENDING_STATISTIC.REQUEST,
    }),
    success: (payload: LoginAttemptsStatisticItemTypes[]): IActionType<LoginAttemptsStatisticItemTypes[]> => ({
        payload,
        type: GET_MAIL_SENDING_STATISTIC.SUCCESS,
    }),
};
