import React, {FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import {CREATE_ROLE} from 'appRedux/actions/roles';
import {RoleCommonRequestTypes} from 'appRedux/actions/roles/types';
import {RootReducer} from 'appRedux/reducers';

import RoleForm from 'components/Forms/RoleForm/RoleForm';
import {roleInitialValues} from 'components/Forms/RoleForm/validation';

import {routes} from 'config/index';

const CreateRoleForm: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {id} = useParams();

    const {
        admin: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const submitCreateForm = useCallback(data => dispatch({type: CREATE_ROLE.REQUEST, payload: data}), [dispatch]);

    const redirectToUpdate = (uuid: string) => {
        navigate(`${routes.ROLE}/${uuid}/edit`);
    };

    const onSubmitClicked = (values: RoleCommonRequestTypes) => {
        submitCreateForm({
            id,
            ...values,
            callback: redirectToUpdate,
        });
    };

    return (
        <RoleForm
            initialValues={roleInitialValues}
            organizationId={Number(id)}
            isLoading={isLoading}
            onSubmitClicked={onSubmitClicked}
            isCreate
        />
    );
};

export default CreateRoleForm;
