import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';
import WarningSvgIcon from 'assets/icons/buttons/WarningSvgIcon';

import {WorkflowStatusTaskItemTypes} from 'appRedux/actions/workflow/types';

import WorkflowTaskTemplateAgentRow from 'components/Forms/Workflow/workflowStatusTaskTemplate/partials/WorkflowTaskTemplateAgentRow';
import WorkflowTaskHasAgentForm from 'components/Forms/Workflow/workflowStatusTaskTemplate/partials/WorkflowTaskHasAgentForm';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {theme} from 'config/theme';

interface ComponentType {
    item: WorkflowStatusTaskItemTypes;
}

const OtherAgentsList: FC<ComponentType> = ({item}) => {
    const [t] = useTranslation();

    const [showForm, setShowForm] = useState<boolean>(false);

    const toggleShowForm = () => {
        setShowForm(previous => !previous);
    };

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    mt: 2,
                    mb: 2,
                }}
            >
                {item && showForm ? (
                    <WorkflowTaskHasAgentForm item={item} closeForm={() => setShowForm(false)} />
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        {!item && (
                            <Tooltip title={t('orguser.workflows.tasks.addOtherOrguserExplanation')}>
                                <IconButton
                                    sx={{
                                        backgroundColor: theme.palette.info.main,
                                        mr: 1,
                                        mb: 0.5,
                                        '&:hover': {
                                            backgroundColor: theme.palette.info.main,
                                        },
                                    }}
                                >
                                    <WarningSvgIcon isActive />
                                </IconButton>
                            </Tooltip>
                        )}
                        <AgentSaveButton
                            isSubmit={false}
                            isDisabled={!item}
                            onClick={toggleShowForm}
                            title={t('orguser.workflows.tasks.addOtherOrguser')}
                            icon={<PlusSvgIcon isActive />}
                        />
                    </Box>
                )}
            </Box>
            {item && item.otherAgents && item.otherAgents.length > 0 && (
                <Box sx={{mb: 2}}>
                    {item.otherAgents.map(otherAgent => {
                        return (
                            <WorkflowTaskTemplateAgentRow key={`other-agent-${otherAgent.uuid}`} item={otherAgent} />
                        );
                    })}
                </Box>
            )}
        </Box>
    );
};

export default OtherAgentsList;
