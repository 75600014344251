import {FormLanguageTypes} from 'appRedux/actions/forms/types';

import {DEFAULT_LANGUAGE} from 'config/index';

export const getBrowserLanguage = (languages: FormLanguageTypes[], navigatorLang: string): string => {
    if (navigatorLang.includes('fa')) {
        return 'dari';
    }

    for (let i = 0, n = languages.length; i < n; i++) {
        const item: FormLanguageTypes = languages[i];
        if (navigatorLang.includes(item.name) && item.enabled) {
            return item.name;
        }
    }

    return DEFAULT_LANGUAGE;
};
