import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Menu from '@mui/material/Menu';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';

import {FilterOptionType} from 'components/AdminScreenComponents/types';
import AgentSelectButton from 'components/AgentScreenComponents/_buttons/AgentSelectButton';

import {DEFAULT_FILTER_OPTION} from 'config/index';
import {theme} from 'config/theme';

interface FilterPropsTypes {
    optionList: FilterOptionType[];
    handleFilterChange: any;
    parameter: string;
    filterName?: string;
    filterLabel: string;
    defaultValue?: string;
    useFilterLabel?: boolean;
}

const Filter: FC<FilterPropsTypes> = ({
    optionList,
    defaultValue,
    handleFilterChange,
    filterLabel,
    parameter,
    useFilterLabel,
}) => {
    const [t] = useTranslation();

    const defaultFilterValue = String(defaultValue) ?? DEFAULT_FILTER_OPTION;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [filterValue, setFilterValue] = useState<string>(defaultFilterValue);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterValue((event.target as HTMLInputElement).value);
        handleFilterChange(event);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    if (optionList.length === 0) {
        return null;
    }
    return (
        <>
            <AgentSelectButton title={t(useFilterLabel ? filterLabel : parameter)} onClick={handleClick} open={open} />
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        mt: 1,
                        pl: 2,
                        pr: 2,
                        pt: 1,
                        pb: 1,
                        width: 290,
                        borderRadius: 2,
                        overflow: 'visible',
                        boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                        backgroundColor: theme.palette.background.default,
                        '& ul': {
                            padding: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            >
                <RadioGroup value={filterValue} onChange={handleChange}>
                    {optionList.map((option: FilterOptionType, index: number) => {
                        return (
                            <FormControlLabel
                                key={`option-${index}`}
                                value={option.name}
                                control={<Radio />}
                                label={t(option.label)}
                            />
                        );
                    })}
                </RadioGroup>
            </Menu>
        </>
    );
};

export default Filter;
