import React, {FC, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {FormFieldTypes, FormVersionCardFieldType, FormVersionTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';
import {
    FORM_VERSION_CARD_CREATE,
    FORM_VERSION_CARD_DELETE,
    FORM_VERSION_CARD_FIELD_CREATE,
    FORM_VERSION_CARD_FIELD_DELETE,
    FORM_VERSION_CARD_FIELD_UPDATE,
    FORM_VERSION_CARD_GET,
} from 'appRedux/actions/forms';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import FormVersionCardBlock from 'pages/admin/formVersion/FormVersionCardBlock';
import {getAllowedCardFields} from 'pages/admin/formVersion/helper';
import FormVersionCardField from 'pages/admin/formVersion/FormVersionCardField';
import FormVersionCardFieldsWrapper from 'pages/admin/formVersion/FormVersionCardFieldsWrapper';
import FormVersionCardFieldPlaceholder from 'pages/admin/formVersion/FormVersionCardFieldPlaceholder';

import {ADMIN_LIGHT_GRAY_COLOR} from 'config/theme';

interface FormVersionCardType {
    item: FormVersionTypes;
}

const FormVersionCard: FC<FormVersionCardType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {showAlert} = useContext(AlertContext);

    const {isFormEditEnabled} = useContext(PermissionContext);

    const {
        admin: {formInfo},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;
    const {card, pages} = formInfo;

    const createFormVersionCard = useCallback(
        data => dispatch({type: FORM_VERSION_CARD_CREATE.REQUEST, payload: data}),
        [dispatch],
    );

    const deleteFormVersionCard = useCallback(
        data => dispatch({type: FORM_VERSION_CARD_DELETE.REQUEST, payload: data}),
        [dispatch],
    );

    const createFormVersionFieldCard = useCallback(
        data => dispatch({type: FORM_VERSION_CARD_FIELD_CREATE.REQUEST, payload: data}),
        [dispatch],
    );

    const updateFormVersionFieldCard = useCallback(
        data => dispatch({type: FORM_VERSION_CARD_FIELD_UPDATE.REQUEST, payload: data}),
        [dispatch],
    );

    const deleteFormVersionFieldCard = useCallback(
        data => dispatch({type: FORM_VERSION_CARD_FIELD_DELETE.REQUEST, payload: data}),
        [dispatch],
    );

    const getFormVersionCard = useCallback(
        data => dispatch({type: FORM_VERSION_CARD_GET.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        getFormVersionCard({
            uuid: item.uuid,
            showAlert,
        });
    }, [item.uuid]);

    const dragItemRef = useRef<HTMLDivElement | null>(null);

    const [cardFields, setCardFields] = useState<FormVersionCardFieldType[]>([]);
    const [formFields, setFormFields] = useState<FormFieldTypes[]>([]);

    const [dragCardFieldUuid, setDragCardFieldUuid] = useState<string | null>(null);
    const [dragFormFieldId, setDragFormFieldId] = useState<number | null>(null);
    const [dropTo, setDropTo] = useState<number | 'delete' | null>(null);

    useEffect(() => {
        setCardFields(card?.fields || []);
    }, [card?.fields]);

    useEffect(() => {
        const fields = getAllowedCardFields(pages).filter(f => !cardFields.find(cf => cf.fieldId === f.id));
        setFormFields(fields);
    }, [pages, cardFields]);

    const handleCreateFormVersionCard = () => {
        createFormVersionCard({
            uuid: item.uuid,
            values: {
                isShowTags: true,
                isShowUserAvatar: true,
                isShowAgentAvatar: true,
                isShowTasks: true,
                isShowStatusChangeRequests: true,
                isShowChatLink: true,
                isShowRequesterName: true,
            },
            showAlert,
        });
    };

    const handleDeleteFormVersionCard = () => {
        card &&
            deleteFormVersionCard({
                uuid: card.uuid,
                showAlert,
            });
    };

    const clearAll = () => {
        setDragCardFieldUuid(null);
        setDragFormFieldId(null);
        setDropTo(null);
        dragItemRef.current = null;
    };

    const onDragEnd = () => {
        clearAll();
    };

    const onDrop = () => {
        if (dragCardFieldUuid && typeof dropTo === 'number') {
            setCardFields(prev => {
                const currentIndex = prev.findIndex(cf => cf.uuid === dragCardFieldUuid);
                const newArr = [...prev];
                const [movedItem] = newArr.splice(currentIndex, 1);
                newArr.splice(dropTo, 0, movedItem);
                return newArr;
            });

            updateFormVersionFieldCard({
                uuid: dragCardFieldUuid,
                values: {
                    priority: dropTo,
                },
                showAlert,
                callback: clearAll,
            });
        }

        if (dragCardFieldUuid && dropTo === 'delete') {
            setCardFields(prev => prev.filter(cf => cf.uuid !== dragCardFieldUuid));

            deleteFormVersionFieldCard({
                uuid: dragCardFieldUuid,
                showAlert,
                callback: clearAll,
            });
        }

        if (typeof dragFormFieldId === 'number' && typeof dropTo === 'number') {
            setCardFields(prev => {
                const newItem = formFields.find(f => f.id === dragFormFieldId) as FormFieldTypes;

                const newArr = [...prev];
                newArr.splice(dropTo, 0, {
                    uuid: `new-uuid-${dragFormFieldId}`,
                    fieldId: dragFormFieldId,
                    fieldLabel: newItem.label,
                    fieldType: newItem.type,
                    fieldOptions: newItem.fieldOptions,
                    priority: 0,
                });
                return newArr;
            });

            createFormVersionFieldCard({
                uuid: card?.uuid,
                values: {
                    field: dragFormFieldId,
                    priority: dropTo,
                },
                showAlert,
                callback: clearAll,
            });
        }

        clearAll();
    };

    return (
        <Box>
            <Grid container>
                <Grid item sm={12}>
                    {isFormEditEnabled &&
                        (!card ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <AgentSaveButton
                                    title={t('common.buttons.create')}
                                    isSubmit={false}
                                    onClick={handleCreateFormVersionCard}
                                />
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                }}
                            >
                                <FormVersionCardBlock item={item} handleDelete={handleDeleteFormVersionCard}>
                                    {cardFields.map(field => (
                                        <FormVersionCardField<typeof field.uuid | null>
                                            key={field.uuid}
                                            priority={field.priority}
                                            fieldId={field.uuid}
                                            label={field.fieldLabel}
                                            active={field.priority === dropTo && field.uuid !== dragCardFieldUuid}
                                            cardField={true}
                                            setDragItem={setDragCardFieldUuid}
                                            setDropTo={setDropTo}
                                            dragFieldId={dragCardFieldUuid || dragFormFieldId}
                                            onDrop={onDrop}
                                            onDragEnd={onDragEnd}
                                        />
                                    ))}
                                    <FormVersionCardFieldPlaceholder
                                        priority={cardFields.length}
                                        setDropTo={setDropTo}
                                        active={dropTo === cardFields.length}
                                        onDrop={onDrop}
                                    />
                                </FormVersionCardBlock>
                                <Box
                                    sx={{
                                        border: `1px solid ${ADMIN_LIGHT_GRAY_COLOR}`,
                                        boxShadow: `0px 1px 2px 1px ${ADMIN_LIGHT_GRAY_COLOR}`,
                                        overflow: 'hidden',
                                        borderRadius: 2,
                                        minHeight: 65,
                                        width: '45%',
                                        px: 1,
                                        py: 1.5,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            mt: 0.5,
                                            mb: 0.75,
                                            px: 1,
                                        }}
                                    >
                                        {t('orguser.analytics.panels.formFields')}:
                                    </Typography>
                                    <FormVersionCardFieldsWrapper
                                        setDropTo={setDropTo}
                                        active={dragCardFieldUuid !== null && dropTo === 'delete'}
                                        dragFieldId={dragCardFieldUuid || undefined}
                                        onDrop={onDrop}
                                    >
                                        {formFields.map(field => (
                                            <FormVersionCardField<typeof field.id | null>
                                                key={field.id}
                                                fieldId={field.id}
                                                label={field.label}
                                                cardField={false}
                                                setDragItem={setDragFormFieldId}
                                                dragFieldId={dragCardFieldUuid || undefined}
                                                onDrop={onDrop}
                                                onDragEnd={onDragEnd}
                                            />
                                        ))}
                                    </FormVersionCardFieldsWrapper>
                                </Box>
                            </Box>
                        ))}
                </Grid>
            </Grid>
        </Box>
    );
};

export default FormVersionCard;
