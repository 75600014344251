import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {GridColDef} from '@mui/x-data-grid';
import {Box, IconButton, Tooltip} from '@mui/material';

import AttachSvgIcon from 'assets/icons/small/AttachSvgIcon';

import {FormItemBasic} from 'appRedux/actions/forms/types';

import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import FormOrganizationRelateForm from 'components/Forms/FormBuilder/FormOrganizationRelateForm/FormOrganizationRelateForm';

import {truncate} from 'pages/admin/formsList/helper';

import useParsedTranslation from 'hooks/useParsedTranslation';

import {EMPTY_DATA} from 'config/index';

interface FormsSuperAdminDataGridType {
    formsList: FormItemBasic[];
}

const FormsSuperAdminDataGrid: FC<FormsSuperAdminDataGridType> = ({formsList}) => {
    const [t] = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'title',
            headerName: t('orguser.forms.title'),
            minWidth: 400,
            renderCell: ({value}) => (
                <Tooltip
                    title={useParsedTranslation(t, String(value))}
                    arrow
                    componentsProps={{
                        tooltip: {
                            sx: {
                                backgroundColor: 'rgb(97, 97, 97)',
                            },
                        },
                    }}
                >
                    <Box>{truncate(value, 30)}</Box>
                </Tooltip>
            ),
        },
        {
            field: 'organization',
            headerName: t('orguser.forms.organization'),
            type: 'singleSelect',
            valueOptions: [...new Set(formsList.map(({organization}) => organization ?? EMPTY_DATA))],
            minWidth: 150,
            valueGetter: ({value}) => value ?? EMPTY_DATA,
        },
        {
            field: 'formActions',
            headerName: t('orguser.forms.action'),
            width: 120,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                const [isShowModal, setIsShowModal] = useState<boolean>(false);
                const toggleModal = () => {
                    setIsShowModal(previous => !previous);
                };

                return (
                    <>
                        <IconButton title={t('common.buttons.update')} onClick={toggleModal}>
                            <AttachSvgIcon />
                        </IconButton>
                        <ModalWrapper
                            isShowModal={isShowModal}
                            toggleModal={toggleModal}
                            title={t('orguser.forms.relateFormToOrganization')}
                        >
                            <FormOrganizationRelateForm formId={row.id} toggleModal={toggleModal} />
                        </ModalWrapper>
                    </>
                );
            },
        },
    ];

    return (
        <CustomDataGrid
            rows={formsList}
            columns={columns}
            getRowId={row => row.id}
            checkboxSelection={false}
            enablePaging
            isLarge
        />
    );
};

export default FormsSuperAdminDataGrid;
