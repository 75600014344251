import React, {FC, useCallback, useContext} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

import {ORGANIZATION_CHANGE_DOMAIN} from 'appRedux/actions/organization';
import {ChangeOrganizationDomainRequestType} from 'appRedux/actions/organization/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import {validationSchema} from 'components/Forms/OrganizationChangeDomainForm/validation';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface OrganizationChangeDomainFormType {
    id: number;
    domainName: string;
    toggleModal: () => void;
}

const OrganizationChangeDomainForm: FC<OrganizationChangeDomainFormType> = ({domainName, id, toggleModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        admin: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const changeOrganizationDomain = useCallback(
        data => dispatch({type: ORGANIZATION_CHANGE_DOMAIN.REQUEST, payload: data}),
        [dispatch],
    );

    return (
        <Formik
            initialValues={{
                organizationId: id,
                domainName: domainName ?? '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, {setErrors}) => {
                changeOrganizationDomain({
                    ...values,
                    callback: toggleModal,
                    setErrors,
                    showAlert,
                });
            }}
        >
            {(formik: FormikProps<ChangeOrganizationDomainRequestType>) => {
                return (
                    <Form>
                        <Box sx={{mt: 1}}>
                            <FormControl required fullWidth>
                                <Field
                                    required
                                    name="domainName"
                                    placeholder={t('superadmin.organizations.domainName')}
                                    component={FormikTextInput}
                                />
                            </FormControl>
                        </Box>
                        <Box sx={{mt: 1}}>
                            <AgentSaveButton isLoading={isLoading} isSubmit={true} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default OrganizationChangeDomainForm;
