import {requestTypeCreator} from 'helpers/index';

import {
    GroupItemTypes,
    GroupsListRequestTypes,
    GroupCreateRequestTypes,
    GroupUpdateRequestTypes,
    GroupDeleteRequestTypes,
    GroupAttachUserRequestTypes,
    GroupDetachUserRequestTypes,
} from 'appRedux/actions/groups/types';

export const GET_GROUPS_LIST = requestTypeCreator('GET_GROUPS_LIST');
export const CREATE_GROUP = requestTypeCreator('CREATE_GROUP');
export const UPDATE_GROUP = requestTypeCreator('UPDATE_GROUP');
export const DELETE_GROUP = requestTypeCreator('DELETE_GROUP');
export const GROUP_ATTACH_USER = requestTypeCreator('GROUP_ATTACH_USER');
export const GROUP_DETACH_USER = requestTypeCreator('GROUP_DETACH_USER');

export const getGroupsList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_GROUPS_LIST.ERROR,
    }),
    request: (payload: GroupsListRequestTypes): IActionType<GroupsListRequestTypes> => ({
        payload,
        type: GET_GROUPS_LIST.REQUEST,
    }),
    success: (payload: GroupItemTypes[]): IActionType<GroupItemTypes[]> => ({
        payload,
        type: GET_GROUPS_LIST.SUCCESS,
    }),
};

export const createGroup = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_GROUP.ERROR,
    }),
    request: (payload: GroupCreateRequestTypes): IActionType<GroupCreateRequestTypes> => ({
        payload,
        type: CREATE_GROUP.REQUEST,
    }),
    success: (payload: GroupItemTypes[]): IActionType<GroupItemTypes[]> => ({
        payload,
        type: CREATE_GROUP.SUCCESS,
    }),
};

export const updateGroup = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_GROUP.ERROR,
    }),
    request: (payload: GroupUpdateRequestTypes): IActionType<GroupUpdateRequestTypes> => ({
        payload,
        type: UPDATE_GROUP.REQUEST,
    }),
    success: (payload: GroupItemTypes[]): IActionType<GroupItemTypes[]> => ({
        payload,
        type: UPDATE_GROUP.SUCCESS,
    }),
};

export const deleteGroup = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_GROUP.ERROR,
    }),
    request: (payload: GroupDeleteRequestTypes): IActionType<GroupDeleteRequestTypes> => ({
        payload,
        type: DELETE_GROUP.REQUEST,
    }),
    success: (payload: GroupItemTypes[]): IActionType<GroupItemTypes[]> => ({
        payload,
        type: DELETE_GROUP.SUCCESS,
    }),
};

export const attachUserToGroup = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GROUP_ATTACH_USER.ERROR,
    }),
    request: (payload: GroupAttachUserRequestTypes): IActionType<GroupAttachUserRequestTypes> => ({
        payload,
        type: GROUP_ATTACH_USER.REQUEST,
    }),
    success: (payload: GroupItemTypes[]): IActionType<GroupItemTypes[]> => ({
        payload,
        type: GROUP_ATTACH_USER.SUCCESS,
    }),
};

export const detachUserToGroup = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GROUP_DETACH_USER.ERROR,
    }),
    request: (payload: GroupDetachUserRequestTypes): IActionType<GroupDetachUserRequestTypes> => ({
        payload,
        type: GROUP_DETACH_USER.REQUEST,
    }),
    success: (payload: GroupItemTypes[]): IActionType<GroupItemTypes[]> => ({
        payload,
        type: GROUP_DETACH_USER.SUCCESS,
    }),
};
