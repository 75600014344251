import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import {SET_ERRORS} from 'appRedux/actions/errors';
import {RootReducer} from 'appRedux/reducers';

import {RouteContext} from 'contexts/route/context';

import AgentScreenWrapper from 'components/AgentScreenComponents/_wrappers/AgentScreenWrapper';
import GeneralErrorMessage from 'components/GeneralErrorMessage/GeneralErrorMessage';
import TwoFactorChecking from 'components/TwoFactorChecking/TwoFactorChecking';
import {ADMIN_DASHBOARD} from 'components/AgentScreenComponents/helper';
import UpdateUsernamePopup from 'components/UpdateUsernamePopup/UpdateUsernamePopup';
import AgentContent from 'components/AgentScreenComponents/AgentContent';

import {ERROR_401} from 'services/http';

import {PARAMETER_TAB} from 'config/index';

const AgentScreen: FC = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const {setCurrentTab, isSuperAdminPage} = useContext(RouteContext);

    const {
        admin: {organizationList},
        errors: {generalError},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const refreshErrors = useCallback(data => dispatch({type: SET_ERRORS.GENERAL_ERROR, payload: data}), [dispatch]);

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const toggleAdminMenu = (tabName: string, tab?: string) => {
        if (!String(generalError).includes(ERROR_401)) {
            refreshErrors(null);
        }
        if (tab) {
            setCurrentTab(tab);
            setSearchParams({
                [PARAMETER_TAB]: tab,
            });
        } else {
            setCurrentTab(tabName);
            setSearchParams({
                [PARAMETER_TAB]: tabName,
            });
        }
    };

    useEffect(() => {
        setCurrentTab(searchParams.get(PARAMETER_TAB) || ADMIN_DASHBOARD);
    }, [searchParams]);

    const getCurrentModal = () => {
        if (profile && !profile.nickname && !profile.isSsoLogin) {
            return <UpdateUsernamePopup />;
        }
        if (!isSuperAdminPage && myOrganization && myOrganization.isForcedTwoFactor) {
            return <TwoFactorChecking />;
        }
        if (generalError) {
            return <GeneralErrorMessage />;
        }
        return null;
    };

    return (
        <AgentScreenWrapper setCurrentTab={setCurrentTab} toggleAdminMenu={toggleAdminMenu}>
            {getCurrentModal()}
            {!generalError && <AgentContent />}
        </AgentScreenWrapper>
    );
};

export default AgentScreen;
