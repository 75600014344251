import React, {FC, ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {SxProps} from '@mui/material';

import {
    BUTTON_HEIGHT,
    BIG_BUTTON_HEIGHT,
    buttonStyles,
    dangerButtonStyles,
    successButtonStyles,
    buttonDisabledStyles,
    labelStyles,
} from 'components/AgentScreenComponents/_buttons/common';
import AgentCloseIconButton from 'components/AgentScreenComponents/_buttons/AgentCloseIconButton';

interface SubmitButtonType {
    isLoading?: boolean;
    isDisabled?: boolean;
    isDanger?: boolean;
    isSuccess?: boolean;
    title?: string;
    isSubmit?: boolean;
    onClick?: () => void;
    icon?: ReactElement;
    isBigButton?: boolean;
    'data-id'?: string;
    onClose?: () => void;
    sx?: SxProps;
}

const AgentSaveButton: FC<SubmitButtonType> = ({
    isDisabled,
    isBigButton,
    icon,
    title,
    isDanger,
    isSuccess,
    isSubmit,
    onClick,
    onClose,
    'data-id': dataId,
    sx,
}) => {
    const [t] = useTranslation();

    let styles = buttonStyles;
    if (isDanger) {
        styles = dangerButtonStyles;
    } else if (isSuccess) {
        styles = successButtonStyles;
    } else if (isDisabled) {
        styles = buttonDisabledStyles;
    }

    if (isSubmit) {
        return (
            <Button
                data-id={dataId}
                disabled={isDisabled}
                sx={{...styles, height: isBigButton ? BIG_BUTTON_HEIGHT : BUTTON_HEIGHT, ...sx}}
                variant="contained"
                type="submit"
                startIcon={icon ?? null}
            >
                <Typography variant="body2" sx={labelStyles}>
                    {t(title ?? 'common.buttons.saveButton')}
                </Typography>
            </Button>
        );
    }
    return (
        <Button
            data-id={dataId}
            disabled={isDisabled}
            sx={{
                ...styles,
                height: isBigButton ? BIG_BUTTON_HEIGHT : BUTTON_HEIGHT,
                ...sx,
            }}
            variant="contained"
            onClick={onClick}
            startIcon={icon ?? null}
            endIcon={onClose ? <AgentCloseIconButton onClose={onClose} isBigButton={isBigButton} /> : ''}
        >
            <Typography sx={labelStyles}>{t(title ?? 'common.buttons.saveButton')}</Typography>
        </Button>
    );
};

export default AgentSaveButton;
