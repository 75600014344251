import React, {FC} from 'react';

const VideoSvgIcon: FC = () => {
    return (
        <svg fill="currentColor" height="75px" width="75px" viewBox="0 0 24 24">
            <path d="M21.217 6L17 9.377V7a2.002 2.002 0 0 0-2-2H3a2.002 2.002 0 0 0-2 2v10a2.002 2.002 0 0 0 2 2h12a2.002 2.002 0 0 0 2-2v-2.445L21.214 18H23V6zM16 17a1.001 1.001 0 0 1-1 1H3a1.001 1.001 0 0 1-1-1V7a1.001 1.001 0 0 1 1-1h12a1.001 1.001 0 0 1 1 1zm6 0h-.429L17 13.263v-2.605L21.568 7H22z" />
            <path fill="none" d="M0 0h24v24H0z" />
        </svg>
    );
};

export default VideoSvgIcon;
