import React, {FC, useEffect, useState} from 'react';
import get from 'lodash/get';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';
import {FormPageTypes, FormSectionTypes} from 'appRedux/actions/forms/types';

import {theme} from 'config/theme';

interface SectionSelectorType {
    pageId: number | null;
    setCurrentSectionId: (value: number) => void;
    currentSectionId: number;
}

const SectionSelector: FC<SectionSelectorType> = ({currentSectionId, pageId, setCurrentSectionId}) => {
    const [t] = useTranslation();

    const {
        admin: {formInfo},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const pages: FormPageTypes[] = get(formInfo, 'pages', []);
    const currentPage = pages.find(page => page.id === pageId);
    const sections: FormSectionTypes[] = currentPage ? currentPage.sections : [];

    const [option, setOption] = useState<number>(currentSectionId);

    const handleChange = (e: SelectChangeEvent) => {
        const value = Number(e.target.value);
        setOption(value);
        setCurrentSectionId(value);
    };

    useEffect(() => {
        currentSectionId && setOption(currentSectionId);
    }, [currentSectionId]);

    return (
        <Box>
            <Typography variant="body2" sx={{mb: 1}}>
                {t('orguser.forms.formField.selectSection')}
            </Typography>
            <FormControl
                sx={{backgroundColor: theme.palette.warning.contrastText, borderRadius: 3, pl: 3, pr: 3, pb: 2, mb: 2}}
                required
                fullWidth
            >
                <Select sx={{height: 50}} value={String(option)} variant="standard" onChange={handleChange}>
                    {sections.map((item: FormSectionTypes, index: number) => {
                        const {title, id} = item;
                        return (
                            <MenuItem key={`menuItem-${index}`} value={id}>
                                <Typography>{title}</Typography>
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Box>
    );
};

export default SectionSelector;
