import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const PasswordSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.47663 1.48011C9.10433 1.16425 9.7973 0.99982 10.5 1C11.1469 0.999796 11.7862 1.13905 12.3744 1.40829C12.9625 1.67752 13.4858 2.07039 13.9084 2.56013C14.331 3.04987 14.643 3.62497 14.8232 4.24622C15.0035 4.86747 15.0476 5.52028 14.9527 6.16015C14.8578 6.80002 14.6261 7.41191 14.2733 7.95411C13.9206 8.49632 13.455 8.9561 12.9085 9.30211C12.3619 9.64812 11.7472 9.87223 11.1062 9.95917C10.4652 10.0461 9.81302 9.99383 9.19405 9.8059L4 15H1V12L6.19405 6.8059C5.9899 6.13351 5.94609 5.42265 6.06613 4.73029C6.18618 4.03792 6.46674 3.3833 6.88534 2.81889C7.30393 2.25448 7.84892 1.79597 8.47663 1.48011ZM9.4839 8.8489C9.81334 8.94888 10.1557 8.99979 10.5 9C11.2362 9.00008 11.9537 8.76801 12.5504 8.3368C13.1471 7.90559 13.5927 7.2972 13.8236 6.59816C14.0546 5.89913 14.0593 5.14506 13.837 4.44321C13.6147 3.74136 13.1768 3.12748 12.5855 2.68889C11.9942 2.2503 11.2796 2.00933 10.5435 2.00027C9.80733 1.9912 9.08706 2.2145 8.48514 2.6384C7.88321 3.0623 7.43028 3.66521 7.19078 4.36137C6.95127 5.05753 6.93739 5.81149 7.1511 6.516L7.32475 7.08935L6.90115 7.51305L2 12.4142V14H3.5858L4.68935 12.8963L4 12.2071L4.70705 11.5L5.3964 12.1894L6.18935 11.3963L5.5 10.7071L6.20705 10L6.89635 10.6892L8.4869 9.0986L8.9105 8.675L9.4839 8.8489ZM12 5C12 5.55228 11.5523 6 11 6C10.4477 6 10 5.55228 10 5C10 4.44772 10.4477 4 11 4C11.5523 4 12 4.44772 12 5Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default PasswordSvgIcon;
