import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';
import {useSearchParams} from 'react-router-dom';

import {GridColDef} from '@mui/x-data-grid';
import Box from '@mui/material/Box';

import {ORGANIZATION_PRIVACY_POLICY_LIST} from 'appRedux/actions/organization';
import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';
import {PermissionContext} from 'contexts/permission/context';
import {AlertContext} from 'contexts/alert/context';

import DataGridWrapper from 'components/AdminScreenComponents/DataGridWrapper';
import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';
import MarkdownWithShowAll from 'components/TextWithShowAll/MarkdownWithShowAll';
import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

import AddPrivacyPolicyButton from 'pages/admin/organizationPrivacyPolicy/AddPrivacyPolicyButton';
import AddPrivacyPolicyVersionButton from 'pages/admin/organizationPrivacyPolicy/AddPrivacyPolicyVersionButton';
import EditPrivacyPolicyButton from 'pages/admin/organizationPrivacyPolicy/EditPrivacyPolicyButton';
import VersionsListButton from 'pages/admin/organizationPrivacyPolicy/VersionsListButton';
import ToggleDefaultButton from 'pages/admin/organizationPrivacyPolicy/ToggleDefaultButton';
import TranslationSelector from 'pages/admin/organizationPrivacyPolicy/TranslationSelector';

import {getLanguageById} from 'helpers/translationsHelper';

import {PARAMETER_LANGUAGE} from 'config/index';

const OrganizationPrivacyPolicy: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const {showAlert} = useContext(AlertContext);
    const {myOrganization} = useContext(AdminContext);
    const {isOrganizationEditEnabled} = useContext(PermissionContext);

    const getOrganizationPrivacyPolicies = useCallback(
        data => dispatch({type: ORGANIZATION_PRIVACY_POLICY_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {languageList, organizationPrivacyPolicies},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    if (!myOrganization) return null;

    const languageFromUrl = searchParams.get(PARAMETER_LANGUAGE);
    const organizationLanguage = getLanguageById(languageList, myOrganization.language);
    const currentLanguage = languageFromUrl ? languageFromUrl : organizationLanguage.name;

    const columns: GridColDef[] = [
        {
            field: 'title',
            headerName: t('superadmin.organizations.privacyPolicy.template'),
            width: 340,
        },
        {
            field: 'test',
            headerName: t('superadmin.organizations.privacyPolicy.text'),
            renderCell: ({row}) => {
                const {versions} = row;
                const lastVersion = get(versions, 0, null);
                if (lastVersion) {
                    return <MarkdownWithShowAll text={lastVersion.text} />;
                }
                return t('common.none');
            },
        },
        {
            field: 'actions',
            headerName: t('superadmin.organizations.privacyPolicy.action'),
            width: isOrganizationEditEnabled ? 250 : 70,
            renderCell: ({row}) => {
                if (!myOrganization) return null;
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        {isOrganizationEditEnabled && (
                            <>
                                <AddPrivacyPolicyVersionButton
                                    item={row}
                                    organizationLanguage={getLanguageById(languageList, myOrganization.language)}
                                />
                                <EditPrivacyPolicyButton item={row} currentLanguage={currentLanguage} />
                            </>
                        )}
                        <VersionsListButton item={row} />
                        {isOrganizationEditEnabled && (
                            <ToggleDefaultButton item={row} currentLanguage={currentLanguage} />
                        )}
                    </Box>
                );
            },
        },
    ];

    useEffect(() => {
        if (myOrganization && profile) {
            getOrganizationPrivacyPolicies({
                id: myOrganization.id,
                language: languageFromUrl ? languageFromUrl : organizationLanguage.name,
                showAlert,
            });
        }
    }, [languageFromUrl]);

    return (
        <>
            {isOrganizationEditEnabled && (
                <AddPrivacyPolicyButton
                    organizationId={myOrganization.id}
                    alreadyHasPolicies={organizationPrivacyPolicies.length > 0}
                    currentLanguage={currentLanguage}
                />
            )}
            <ClientSectionWrapper>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                    }}
                >
                    <TranslationSelector
                        organizationLanguage={getLanguageById(languageList, myOrganization.language)}
                    />
                </Box>
                <DataGridWrapper
                    itemsList={organizationPrivacyPolicies}
                    itemsTotalNumber={organizationPrivacyPolicies ? organizationPrivacyPolicies.length : 0}
                >
                    {organizationPrivacyPolicies && (
                        <CustomDataGrid
                            rows={organizationPrivacyPolicies}
                            columns={columns}
                            getRowId={row => row.uuid}
                            checkboxSelection={false}
                            enablePaging
                            isLarge
                        />
                    )}
                </DataGridWrapper>
            </ClientSectionWrapper>
        </>
    );
};

export default OrganizationPrivacyPolicy;
