import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {GroupItemTypes} from 'appRedux/actions/groups/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import UpdateGroupForm from 'components/Forms/GroupForm/UpdateGroupForm';

interface EditGroupButtonType {
    item: GroupItemTypes;
}

const EditGroupButton: FC<EditGroupButtonType> = ({item}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <>
            <IconButton title={t('orguser.groups.updateGroup')} onClick={() => setIsOpened(true)}>
                <EditSvgIcon />
            </IconButton>
            <ModalWrapper isShowModal={isOpened} toggleModal={toggleIsOpened} title={t('orguser.groups.updateGroup')}>
                <UpdateGroupForm item={item} closeModal={() => setIsOpened(false)} />
            </ModalWrapper>
        </>
    );
};

export default EditGroupButton;
