import React, {FC, useCallback, useContext, useState} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

import {SET_HOMEPAGE_PANEL} from 'appRedux/actions/analytics';
import {PanelItemsTypes, PanelHomepageFormTypes} from 'appRedux/actions/analytics/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import MarkdownWithShowAll from 'components/TextWithShowAll/MarkdownWithShowAll';

import {CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface SelectAnalyticPanelFormType {
    homepagePanel?: PanelItemsTypes | null;
    closeModal: () => void;
}

const SelectAnalyticPanelForm: FC<SelectAnalyticPanelFormType> = ({homepagePanel, closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [panelDescription, setPanelDescription] = useState<string | null>(
        homepagePanel ? homepagePanel.description : null,
    );

    const {
        analytics: {panels},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const setHomepagePanel = useCallback(
        data => dispatch({type: SET_HOMEPAGE_PANEL.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: PanelHomepageFormTypes) => {
        setHomepagePanel({
            ...values,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <Formik
            initialValues={{
                uuid: homepagePanel ? homepagePanel.uuid : '',
            }}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<PanelHomepageFormTypes>) => {
                return (
                    <Form>
                        <FormControl sx={{mt: 2, mb: 1}} required fullWidth>
                            <InputLabel>{t('orguser.analytics.dashboards.selectPanel')}</InputLabel>
                            <Select
                                label={t('orguser.analytics.dashboards.selectPanel')}
                                name={'uuid'}
                                value={formik.values.uuid}
                                onChange={e => {
                                    formik.handleChange(e);
                                    const selectedPanel = panels.find(panel => panel.uuid === e.target.value);
                                    if (selectedPanel) {
                                        setPanelDescription(selectedPanel.description);
                                    }
                                }}
                                IconComponent={KeyboardArrowDownIcon}
                                fullWidth
                            >
                                {panels.map((panel: PanelItemsTypes) => {
                                    const {title, uuid} = panel;
                                    return (
                                        <MenuItem key={`panel-${uuid}`} value={uuid}>
                                            <Typography sx={{fontWeight: 600}}>{title}</Typography>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        {panelDescription && (
                            <Box
                                sx={{
                                    p: 2,
                                    mt: 2,
                                    backgroundColor: CLIENT_BACKGROUND_COLOR,
                                }}
                            >
                                <MarkdownWithShowAll text={panelDescription} />
                            </Box>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 3,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default SelectAnalyticPanelForm;
