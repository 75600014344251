import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import IconButton from '@mui/material/IconButton';

import {PublicKeyTypes} from 'appRedux/actions/crypto/types';
import {RootReducer} from 'appRedux/reducers';
import {REGENERATE_CASE_KEYS} from 'appRedux/actions/crypto';

import {CryptoContext} from 'contexts/crypto/context';
import {AlertContext} from 'contexts/alert/context';

import CaseAccessRequestSvgIcon from 'assets/icons/buttons/CaseAccessRequestSvgIcon';

import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';

import {importPublicKey, unwrapExtractableKey, wrapKey} from 'helpers/cryptoApiHelper';

interface ShareAccessToKeyButtonType {
    item: PublicKeyTypes;
    currentBrowserKey: string;
}

const ShareAccessToKeyButton: FC<ShareAccessToKeyButtonType> = ({item, currentBrowserKey}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {keys} = useContext(CryptoContext);
    const {showAlert} = useContext(AlertContext);

    const {
        crypto: {currentUserPublicKeys},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {uuid, data, caseKeys} = item;

    const grantAccessToPublicKey = useCallback(
        data => dispatch({type: REGENERATE_CASE_KEYS.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickGrantAccess = () => {
        if (!currentBrowserKey || !keys?.privateKey) return;
        const caseKeysIds = caseKeys?.map(key => key.caseId);
        const myKeys = currentUserPublicKeys?.find(pubKey => pubKey.data === currentBrowserKey);
        const cases = myKeys?.caseKeys?.filter(key => !caseKeysIds?.includes(key.caseId));
        if (!cases?.length) return;
        toggleIsOpened();

        Promise.all(
            cases.map(async ({data: caseData, caseId}) => {
                const upwrappedCaseKey = await unwrapExtractableKey(caseData, keys?.privateKey);
                const publicKey = await importPublicKey(data);
                const wrappedCaseKey = await wrapKey(upwrappedCaseKey, publicKey);

                return {caseId, data: wrappedCaseKey};
            }),
        ).then(items => {
            grantAccessToPublicKey({uuid, items, showAlert});
        });
    };

    return (
        <>
            <IconButton title={t('common.buttons.update')} onClick={toggleIsOpened}>
                <CaseAccessRequestSvgIcon />
            </IconButton>
            <DeleteAdminConfirmationModal
                toggleModal={toggleIsOpened}
                isShowModal={isOpened}
                customTitle="grantAccessTitleQuestion"
                onClickAction={onClickGrantAccess}
                customButton="proceed"
            />
        </>
    );
};

export default ShareAccessToKeyButton;
