import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import {PublicKeyTypes} from 'appRedux/actions/crypto/types';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import UpdatePublicKeyForm from 'components/Forms/UpdatePublicKeyForm/UpdatePublicKeyForm';

interface UpdatePublicKeyButtonType {
    item: PublicKeyTypes;
}

const UpdatePublicKeyButton: FC<UpdatePublicKeyButtonType> = ({item}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <>
            <IconButton title={t('common.buttons.update')} onClick={toggleIsOpened}>
                <EditSvgIcon />
            </IconButton>
            <ModalWrapper
                title={t('common.publicKeys.updatePublicKey')}
                isShowModal={isOpened}
                toggleModal={toggleIsOpened}
            >
                <UpdatePublicKeyForm item={item} closeModal={() => setIsOpened(false)} />
            </ModalWrapper>
        </>
    );
};

export default UpdatePublicKeyButton;
