import React, {FC, useState} from 'react';

import Box from '@mui/material/Box';

import FormFieldTabsSwitcher from 'components/Forms/FormBuilder/FieldForm/partials/FormFieldTabsSwitcher';
import {TAB_FIELD_DATA} from 'components/Forms/FormBuilder/FieldForm/partials/helper';
import FormFieldPopupContent from 'components/Forms/FormBuilder/FieldForm/partials/FormFieldPopupContent';

import {theme} from 'config/theme';

interface UpdateFormFieldType {
    fieldId: number;
    isPopup?: boolean;
}

const UpdateFormField: FC<UpdateFormFieldType> = ({fieldId, isPopup}) => {
    const [currentTab, setCurrentTab] = useState<number>(TAB_FIELD_DATA);

    return (
        <Box>
            <FormFieldTabsSwitcher currentTab={currentTab} setCurrentTab={setCurrentTab} />
            <Box
                sx={{
                    border: `2px solid ${theme.palette.info.main}`,
                    borderRadius: 4,
                    p: 2,
                }}
            >
                <FormFieldPopupContent fieldId={fieldId} isPopup={isPopup} currentTab={currentTab} />
            </Box>
        </Box>
    );
};

export default UpdateFormField;
