import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

import {OrganizationTemplateItemTypes, OrganizationItemBasic} from 'appRedux/actions/organization/types';
import {RootReducer} from 'appRedux/reducers';

import OrganizationTemplateSubjectAndText from 'pages/admin/organizationTemplates/OrganizationTemplateSubjectAndText';
import OrganizationTemplateEditButton from 'pages/admin/organizationTemplates/OrganizationTemplateEditButton';
import AddOrganizationTemplateButton from 'pages/admin/organizationTemplates/AddOrganizationTemplateButton';

interface OrganizationTemplateTableRowType {
    organization: OrganizationItemBasic;
    label: string;
    type: number;
    item?: OrganizationTemplateItemTypes;
    currentLanguageName: string;
}

const OrganizationTemplateTableRow: FC<OrganizationTemplateTableRowType> = ({
    label,
    organization,
    type,
    item,
    currentLanguageName,
}) => {
    const [t] = useTranslation();

    const {
        admin: {organizationTemplates},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const otherLanguageItem = organizationTemplates.find(organizationTemplate => organizationTemplate.type === type);

    return (
        <TableRow>
            <TableCell width={300}>
                <Typography>{t(label)}</Typography>
            </TableCell>
            <TableCell>
                {item ? (
                    <OrganizationTemplateSubjectAndText item={item} organizationId={organization.id} />
                ) : (
                    <Typography sx={{fontSize: 14}}>{t('orguser.mailer.noneTemplateDefaultLanguage')}</Typography>
                )}
            </TableCell>
            <TableCell width={80}>
                {item ? (
                    <OrganizationTemplateEditButton item={item} />
                ) : (
                    <AddOrganizationTemplateButton
                        organization={organization}
                        type={type}
                        label={label}
                        defaultItem={otherLanguageItem}
                        currentLanguageName={currentLanguageName}
                    />
                )}
            </TableCell>
        </TableRow>
    );
};

export default OrganizationTemplateTableRow;
