import moment from 'moment';
import {TFunction} from 'react-i18next';

import {DATE_FORMAT_CLIENT, TIME_FORMAT_CLIENT} from 'config/index';
import {theme, CLIENT_BACKGROUND_COLOR} from 'config/theme';

const today = moment().clone().startOf('day');
const yesterday = moment().clone().subtract(1, 'days').startOf('day');

export const getDateLabel = (t: TFunction, date: string): string => {
    if (moment(date).isSame(yesterday, 'd')) {
        return t('orguser.chat.yesterday');
    }
    if (moment(date).isSame(today, 'd')) {
        return t('orguser.chat.today');
    }
    return moment(date).format(DATE_FORMAT_CLIENT);
};

export const getLastMessageDateLabel = (t: TFunction, date: number): string => {
    if (moment.unix(date).isSame(yesterday, 'd')) {
        return t('orguser.chat.yesterday');
    }
    if (moment.unix(date).isSame(today, 'd')) {
        return moment.unix(date).format(TIME_FORMAT_CLIENT);
    }
    return moment.unix(date).format(DATE_FORMAT_CLIENT);
};

export const getChatMessageBlockColor = (
    isCurrentUserSender: boolean,
    isSystemMessage: boolean,
    isMessenger?: boolean,
): string => {
    if (isSystemMessage) {
        return theme.palette.warning.dark;
    }
    if (isMessenger && isCurrentUserSender) {
        return CLIENT_BACKGROUND_COLOR;
    }
    return isCurrentUserSender ? theme.palette.background.default : theme.palette.info.main;
};

export const getAvatarAreaWidth = (isCurrentUserSender: boolean, isMessenger?: boolean) => {
    if (isMessenger) {
        return isCurrentUserSender ? 1 : 0;
    }
    return isCurrentUserSender ? 2 : 0;
};
