import React, {FC} from 'react';
import {Navigate, useLocation, useParams, useSearchParams} from 'react-router-dom';

interface RedirectProps {
    to: string;
}

const Redirect: FC<RedirectProps> = ({to}) => {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const {hash} = useLocation();

    console.log(params['*']);

    if (hash) {
        return <Navigate to={`${to}${hash}`} replace />;
    }
    return <Navigate to={`${to}/${params['*']}?${searchParams}`} replace />;
};

export default Redirect;
