import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';

import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@mui/material';
import {OrganizationPdfTemplatesTypes} from 'appRedux/actions/organization/types';
import PdfTemplatesRow from './PdfTemplatesRow';

const OrganizationPdfTemplates: FC = () => {
    const [t] = useTranslation();

    const {
        admin: {organizationPdfTemplates},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Box>
            <Table>
                <TableHead>
                    <TableCell>
                        <Typography sx={{fontWeight: 600}}>
                            {t('superadmin.organizations.pdfTemplates.title')}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography sx={{fontWeight: 600}}>
                            {t('superadmin.organizations.pdfTemplates.description')}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography sx={{fontWeight: 600}}>
                            {t('superadmin.organizations.pdfTemplates.action')}
                        </Typography>
                    </TableCell>
                </TableHead>
                <TableBody>
                    {organizationPdfTemplates.length > 0 ? (
                        organizationPdfTemplates.map((item: OrganizationPdfTemplatesTypes, index: number) => {
                            return <PdfTemplatesRow key={`workflow-${index}`} item={item} />;
                        })
                    ) : (
                        <TableRow>
                            <TableCell colSpan={3} style={{textAlign: 'center'}}>
                                <Typography>{t('superadmin.organizations.pdfTemplates.noneTemplate')}</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Box>
    );
};

export default OrganizationPdfTemplates;
