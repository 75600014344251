import moment from 'moment';
import * as PDFJS from 'pdfjs-dist';

import {
    RequesterCaseOptionTypes,
    RequesterCaseHasSlotTypes,
    RequesterCaseHasBudgetTypes,
} from 'appRedux/actions/requestCase/types';

import {base642Buffer} from 'helpers/cryptoApiHelper';

import {DATE_TIME_LABEL_FORMAT} from 'config/index';

globalThis.pdfjsWorker = null;
PDFJS.GlobalWorkerOptions.workerSrc = `/js/pdf.worker.min.js`;

const THUMBNAIL_HEIGHT = 150;
const THUMBNAIL_WIDTH = 150;

export const REQUESTER_CASE_HAS_SLOT_RESERVED = 0;
export const REQUESTER_CASE_HAS_SLOT_BOOKED = 1;
export const REQUESTER_CASE_HAS_SLOT_CLEARED = 2;

export const REQUESTER_CASE_HAS_BUDGET_CREATED = 0;
export const REQUESTER_CASE_HAS_BUDGET_RESERVED = 1;
export const REQUESTER_CASE_HAS_BUDGET_BOOKED = 2;
export const REQUESTER_CASE_HAS_BUDGET_CLEARED = 3;

export const createThumbnail = async (file: File): Promise<string | null> => {
    if (file.type === 'application/pdf') {
        const buffer = await getBuffer(file);
        const pdf = await PDFJS.getDocument(buffer).promise;
        const page = await pdf.getPage(1);
        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');

        if (!canvasContext) return null;
        canvas.width = THUMBNAIL_WIDTH;
        canvas.height = THUMBNAIL_HEIGHT;

        const scaleX = canvas.width / page.view[2];
        const scaleY = canvas.height / page.view[3];
        const scale = Math.max(scaleX, scaleY);
        const viewport = page.getViewport({scale});

        await page.render({canvasContext, viewport}).promise;

        console.log(canvas.toDataURL('image/jpeg'));
        return canvas.toDataURL('image/jpeg');
    } else {
        return new Promise(resolve => {
            const img = document.createElement('img');

            getBase64Uri(file)
                .then(dataUri => {
                    img.src = dataUri;

                    img.addEventListener('load', () => {
                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');

                        canvas.width = THUMBNAIL_WIDTH;
                        canvas.height = THUMBNAIL_HEIGHT;

                        const scaleX = canvas.width / img.width;
                        const scaleY = canvas.height / img.height;
                        const scale = Math.max(scaleX, scaleY);

                        const imgWidth = img.width * scale;
                        const imgHeight = img.height * scale;

                        context?.drawImage(
                            img,
                            canvas.width / 2 - imgWidth / 2,
                            canvas.height / 2 - imgHeight / 2,
                            imgWidth,
                            imgHeight,
                        );

                        canvas.remove();
                        img.remove();

                        console.log(canvas.toDataURL('image/jpeg'));
                        resolve(canvas.toDataURL('image/jpeg'));
                    });
                })
                .catch(() => resolve(null));
        });
    }
};

export const base64ToFile = (base64String: string) => {
    const arrayBuffer = base642Buffer(base64String.split(',')[1]);
    const blob = new Blob([arrayBuffer], {type: 'image/jpeg'});
    return blob;
};

export const getBase64Uri = (file: File): Promise<string> => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.onload = function () {
            typeof reader.result === 'string' ? resolve(reader.result) : reject();
        };
        reader.onerror = function (error) {
            reject(error);
        };
        reader.readAsDataURL(file);
    });
};

const getBuffer = (file: File): Promise<ArrayBuffer> => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.onload = function () {
            reader.result && typeof reader.result !== 'string' ? resolve(reader.result) : reject();
        };
        reader.onerror = function (error) {
            reject(error);
        };
        reader.readAsArrayBuffer(file);
    });
};

export const isRequesterCaseOptionPresented = (
    optionId: number,
    requesterCaseOptions: RequesterCaseOptionTypes[],
): boolean => {
    return requesterCaseOptions.some(item => item.optionId === optionId);
};

export const getInitialSelectedOption = (
    fieldId: number,
    requesterCaseOptions: RequesterCaseOptionTypes[],
): RequesterCaseOptionTypes | undefined => {
    return requesterCaseOptions.find(item => item.fieldId === fieldId);
};

export const getSlotStatusLabel = (status: number): string => {
    switch (status) {
        case REQUESTER_CASE_HAS_SLOT_RESERVED:
            return 'requester.casePage.slotStatus.reserved';
        case REQUESTER_CASE_HAS_SLOT_BOOKED:
            return 'requester.casePage.slotStatus.booked';
        case REQUESTER_CASE_HAS_SLOT_CLEARED:
            return 'requester.casePage.slotStatus.cleared';
    }
    return '';
};

export const getSlotStatusDateTime = (slot: RequesterCaseHasSlotTypes): string => {
    const {status, reservedAt, bookedAt, clearedAt} = slot;
    switch (status) {
        case REQUESTER_CASE_HAS_SLOT_RESERVED:
            return moment(reservedAt).format(DATE_TIME_LABEL_FORMAT);
        case REQUESTER_CASE_HAS_SLOT_BOOKED:
            return bookedAt ? moment(bookedAt).format(DATE_TIME_LABEL_FORMAT) : '';
        case REQUESTER_CASE_HAS_SLOT_CLEARED:
            return clearedAt ? moment(clearedAt).format(DATE_TIME_LABEL_FORMAT) : '';
    }
    return '';
};

export const getBudgetStatusLabel = (status: number): string => {
    switch (status) {
        case REQUESTER_CASE_HAS_BUDGET_CREATED:
            return 'requester.casePage.budgetStatus.created';
        case REQUESTER_CASE_HAS_BUDGET_RESERVED:
            return 'requester.casePage.budgetStatus.reserved';
        case REQUESTER_CASE_HAS_BUDGET_BOOKED:
            return 'requester.casePage.budgetStatus.booked';
        case REQUESTER_CASE_HAS_BUDGET_CLEARED:
            return 'requester.casePage.budgetStatus.cleared';
    }
    return '';
};

export const getBudgetStatusDateTime = (budget: RequesterCaseHasBudgetTypes): string => {
    const {status, createdAt, reservedAt, bookedAt, clearedAt} = budget;
    switch (status) {
        case REQUESTER_CASE_HAS_BUDGET_CREATED:
            return moment(createdAt).format(DATE_TIME_LABEL_FORMAT);
        case REQUESTER_CASE_HAS_BUDGET_RESERVED:
            return reservedAt ? moment(reservedAt).format(DATE_TIME_LABEL_FORMAT) : '';
        case REQUESTER_CASE_HAS_BUDGET_BOOKED:
            return bookedAt ? moment(bookedAt).format(DATE_TIME_LABEL_FORMAT) : '';
        case REQUESTER_CASE_HAS_BUDGET_CLEARED:
            return clearedAt ? moment(clearedAt).format(DATE_TIME_LABEL_FORMAT) : '';
    }
    return '';
};
