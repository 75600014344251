import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

import AttachSvgIcon from 'assets/icons/small/AttachSvgIcon';

import {theme} from 'config/theme';

interface QuestionSectionAttachmentType {
    questionSectionName: string;
    resetSelectedSection: () => void;
}

const QuestionSectionAttachment: FC<QuestionSectionAttachmentType> = ({questionSectionName, resetSelectedSection}) => {
    const [t] = useTranslation();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                ml: 1,
                mb: 2,
            }}
        >
            <AttachSvgIcon />
            <Typography
                sx={{
                    ml: 1,
                    mr: 2,
                    fontWeight: 600,
                    color: theme.palette.info.main,
                }}
            >{`${t('orguser.chat.questionToSection')}: ${questionSectionName}`}</Typography>
            <IconButton onClick={resetSelectedSection}>
                <CloseIcon />
            </IconButton>
        </Box>
    );
};

export default QuestionSectionAttachment;
