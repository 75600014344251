import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {TWO_FACTOR_CANCEL} from 'appRedux/actions/auth';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

const TwoFactorCancelling: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        auth: isLoading,
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const [minutes, setMinutes] = useState<number>();
    const [seconds, setSeconds] = useState<string>('');

    const cancelTwoFactor = useCallback(data => dispatch({type: TWO_FACTOR_CANCEL.REQUEST, payload: data}), [dispatch]);

    useEffect(() => {
        if (profile && profile.secretCodeExpiredTime) {
            const timerUpdateInterval = setInterval(async () => {
                const timeLeft = Number(profile.secretCodeExpiredTime) - moment().unix();
                const minutesLeft = Math.floor(timeLeft / 60);
                const secondsLeft = ('0' + (timeLeft % 60)).slice(-2);
                setMinutes(minutesLeft);
                setSeconds(secondsLeft);

                if (minutesLeft === 0 && Number(secondsLeft) === 0) {
                    onCloseClick();
                }
            }, 1000);

            return () => {
                clearInterval(timerUpdateInterval);
            };
        }
    }, [profile]);

    const onCloseClick = () => {
        cancelTwoFactor({
            showAlert,
        });
    };

    if (!profile) return null;

    const isQrCodeOutdated = Number(profile.secretCodeExpiredTime) < moment().unix();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                pt: isLoading ? 2 : 'initial',
            }}
        >
            {!(Number(minutes) < 0 || minutes === undefined) && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 2,
                        mb: 2,
                    }}
                >
                    <Typography>{`${t('common.security.qrCodeExpiredAt')}:`}</Typography>
                    <Typography sx={{fontWeight: 700, ml: 1}}>{`${minutes}:${seconds}`}</Typography>
                </Box>
            )}
            {isQrCodeOutdated && (
                <Typography sx={{fontWeight: 700, mt: 2, mb: 2}}>{t('common.security.qrCodeOutdated')}</Typography>
            )}
            <AgentSaveButton title={t('common.buttons.cancel')} isSubmit={false} onClick={onCloseClick} />
        </Box>
    );
};

export default TwoFactorCancelling;
