import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import backend from 'i18next-http-backend';
import {http} from 'services/http';

const loadResources = async (locale: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await http(`translations/${locale}`, {method: 'GET'})
        .then(response => {
            return response;
        })
        .catch(error => {
            console.log(error);
        });
};

i18n.use(backend)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: 'en',
        appendNamespaceToCIMode: true,
        backend: {
            loadPath: '{{lng}}|{{ns}}',
            request: function (options, url, payload, callback) {
                try {
                    const [lng] = url.split('|');
                    loadResources(lng).then(response => {
                        callback(null, {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            data: response,
                            status: 200,
                        });
                    });
                } catch (e) {
                    console.error(e);
                    callback(null, {
                        data: '',
                        status: 500,
                    });
                }
            },
        },
        interpolation: {
            escapeValue: false,
        },
    })
    .catch((e: any) => console.log('=============e============', e));

export default i18n;
