import React, {FC, useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {UPDATE_BOARD_OVERVIEW} from 'appRedux/actions/workflow';
import {
    CreateBoardOverviewRequestTypes,
    UpdateBoardOverviewRequestTypes,
    BoardHasStatusItemTypes,
    BoardItemTypes,
    BoardStatusTypes,
} from 'appRedux/actions/workflow/types';

import OverviewBoardForm from 'components/Forms/BoardForm/OverviewBoardForm';

interface CreateListBoardFormType {
    item: BoardItemTypes;
    closeModal: () => void;
}

const UpdateOverviewBoardForm: FC<CreateListBoardFormType> = ({item, closeModal}) => {
    const dispatch = useDispatch();

    const {uuid, title, iconType, statuses} = item;

    const [boardStatuses, setBoardStatuses] = useState<BoardHasStatusItemTypes[]>([]);
    const [, setIsUpdated] = useState<boolean>(false);

    const updateBoard = useCallback(data => dispatch({type: UPDATE_BOARD_OVERVIEW.REQUEST, payload: data}), [dispatch]);

    const onSubmitClick = (values: CreateBoardOverviewRequestTypes | UpdateBoardOverviewRequestTypes, {setErrors}) => {
        updateBoard({
            ...values,
            uuid,
            boardHasStatuses: boardStatuses,
            setErrors,
            callback: closeModal,
        });
    };

    useEffect(() => {
        statuses.forEach((status: BoardStatusTypes) => {
            boardStatuses.push({
                workflowStatus: status.id,
            });
        });
        setBoardStatuses(boardStatuses);
        setIsUpdated(previous => !previous);
    }, [uuid]);

    return (
        <OverviewBoardForm
            statuses={boardStatuses}
            setStatuses={setBoardStatuses}
            initialValues={{
                title,
                iconType,
                workflow: null,
                boardHasStatuses: boardStatuses,
            }}
            onSubmitClick={onSubmitClick}
        />
    );
};

export default UpdateOverviewBoardForm;
