import React, {FC} from 'react';
import moment from 'moment';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RequesterCaseCommentsTypes} from 'appRedux/actions/comments/types';

import {theme} from 'config/theme';
import {DATE_TIME_LABEL_FORMAT} from 'config/index';

interface CommentRowType {
    item: RequesterCaseCommentsTypes;
    inRightPanel?: boolean;
}

const CommentRow: FC<CommentRowType> = ({item, inRightPanel}) => {
    const {section, text, dateAndTime} = item;
    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
            <Box
                sx={{
                    width: '100%',
                    backgroundColor:
                        section || inRightPanel ? `rgba(34, 34, 34, 0.05)` : theme.palette.background.default,
                    borderRadius: 2,
                    mb: 2,
                    p: 2,
                    pt: 1,
                    pb: 1,
                }}
            >
                <Typography sx={{mb: 1}}>{text}</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography sx={{fontWeight: 600, fontSize: 12, mr: 1}}>
                        {moment.unix(dateAndTime).format(DATE_TIME_LABEL_FORMAT)}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default CommentRow;
