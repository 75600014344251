import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import CopySvgIcon from 'assets/icons/buttons/CopySvgIcon';

import {AlertContext} from 'contexts/alert/context';

import {ALERT_TYPE_SUCCESS} from 'config/index';
import {theme} from '../../../../config/theme';

interface RedirectUrlLinkType {
    redirectUrl: string;
}

const RedirectUrlLink: FC<RedirectUrlLinkType> = ({redirectUrl}) => {
    const [t] = useTranslation();

    const {showAlert} = useContext(AlertContext);

    const onCopyClicked = () => {
        navigator.clipboard.writeText(redirectUrl);
        showAlert(ALERT_TYPE_SUCCESS, t('messages.success.linkCopiedToBuffer'));
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mb: 2,
                mt: 2,
            }}
        >
            <Typography sx={{fontWeight: 700, mr: 1}}>{`${t('superadmin.organizations.redirectUrl')}:`}</Typography>
            <Typography>{redirectUrl}</Typography>
            <IconButton
                sx={{
                    ml: 2,
                    backgroundColor: theme.palette.info.main,
                    '&:hover': {
                        backgroundColor: theme.palette.info.main,
                    },
                }}
                title={t('common.buttons.copyToBuffer')}
                onClick={onCopyClicked}
            >
                <CopySvgIcon isActive />
            </IconButton>
        </Box>
    );
};

export default RedirectUrlLink;
