import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const TranslateSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 2.5V3.5H7.4C7.07053 4.91357 6.39729 6.22398 5.44 7.315C6.16331 8.05291 7.0362 8.62745 8 9L7.645 9.92C6.56294 9.48533 5.58242 8.83165 4.765 8C3.93248 8.82042 2.95179 9.47535 1.875 9.93L1.5 9C2.45828 8.59433 3.33306 8.01453 4.08 7.29C3.40416 6.46583 2.89474 5.51831 2.58 4.5H3.63C3.88469 5.25906 4.2724 5.96675 4.775 6.59C5.54601 5.7023 6.09296 4.64268 6.37 3.5H1V2.5H4.5V1H5.5V2.5H9ZM15 14.5H13.925L13.125 12.5H9.7L8.9 14.5H7.825L10.825 7H12L15 14.5ZM11.41 8.22L10.1 11.5H12.725L11.41 8.22Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default TranslateSvgIcon;
