import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import MarkdownWithShowAll from 'components/TextWithShowAll/MarkdownWithShowAll';

interface GeneralTemplateSubjectAndTextType {
    subject: string;
    text: string;
}

const GeneralTemplateSubjectAndText: FC<GeneralTemplateSubjectAndTextType> = ({subject, text}) => {
    const [t] = useTranslation();
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    mt: 1,
                }}
            >
                <Typography sx={{fontWeight: 600, fontSize: 16, mr: 1}}>{`${t('orguser.mailer.subject')}:`}</Typography>
                <Typography>{subject}</Typography>
            </Box>
            <Divider sx={{mt: 2, mb: 2, mr: 3}} />
            <MarkdownWithShowAll text={text} />
        </Box>
    );
};

export default GeneralTemplateSubjectAndText;
