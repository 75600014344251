import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface AddOrganizationUserAlertContentType {
    closeModal: () => void;
}

const AddOrganizationUserAlertContent: FC<AddOrganizationUserAlertContentType> = ({closeModal}) => {
    const [t] = useTranslation();
    return (
        <Box>
            <Alert severity={'error'}>
                <Typography sx={{fontWeight: 600}}>
                    {t('superadmin.users.organizationQuotaOrgUsersNumberReached')}
                </Typography>
            </Alert>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 2,
                }}
            >
                <AgentSaveButton title={'close'} isSubmit={false} onClick={closeModal} />
            </Box>
        </Box>
    );
};

export default AddOrganizationUserAlertContent;
