import {FormItemBasic, FormHasAdditionalTypes} from 'appRedux/actions/forms/types';

export const getFormsForSelection = (
    formsList: FormItemBasic[],
    formHasAdditionalItems: FormHasAdditionalTypes[],
    formId: number,
): FormOptionTypes[] => {
    const results: FormOptionTypes[] = [];
    formsList.forEach(item => {
        const isAlreadyAdded = formHasAdditionalItems.some(addedItem => Number(addedItem.id) === item.id);
        if (item.id !== formId && !isAlreadyAdded) {
            results.push({
                id: item.id,
                label: item.title,
            });
        }
    });
    return results;
};

export interface FormOptionTypes {
    id: number;
    label: string;
}
