import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import moment from 'moment';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import TasksCompletedSvgIcon from 'assets/icons/buttons/TasksCompletedSvgIcon';
import CheckSvgIcon from 'assets/icons/buttons/CheckSvgIcon';

import {CaseHasTasksTypes} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import {
    getWorkflowTaskLabel,
    getTemplateForLabel,
    WORKFLOW_STATUS_TASK_TYPE_CHANGE_STATUS,
    WORKFLOW_STATUS_TASK_TYPE_EMAIL_SENDING,
} from 'components/Forms/Workflow/workflowStatusTask/helper';
import HasNotApprovedStatusChangeRequestsButton from 'components/HasNotApprovedStatusChangeRequestsButton/HasNotApprovedStatusChangeRequestsButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import IgnoreRelatedTaskButton from 'pages/agent/requesterPage/formInformation/relatedTasks/IgnoreRelatedTaskButton';

import {theme} from 'config/theme';
import {DATE_LABEL_FORMAT} from 'config/index';

const RelatedTasksButton: FC = () => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpened(previous => !previous);
    };

    const {
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {tasks} = currentCase;

    const isNotCompletedTasksPresented = tasks.some(task => !task.isCompleted);

    return (
        <>
            <HasNotApprovedStatusChangeRequestsButton
                title={
                    isNotCompletedTasksPresented
                        ? t('orguser.requesterCase.hasNotCompletedTasks')
                        : t('orguser.workflows.tasks.executedAllTasks')
                }
                icon={isNotCompletedTasksPresented ? TasksCompletedSvgIcon : CheckSvgIcon}
                onClickAction={toggleModal}
                isGreenButton={!isNotCompletedTasksPresented}
            />
            <ModalWrapper
                isShowModal={isOpened}
                toggleModal={toggleModal}
                title={t('orguser.requesterCase.notCompletedTasks')}
            >
                {tasks &&
                    tasks.map((task: CaseHasTasksTypes) => {
                        const {
                            type,
                            uuid,
                            newStatusTitle,
                            executedAt,
                            isIgnored,
                            subject,
                            isForAgent,
                            isForOther,
                            isForRequester,
                        } = task;
                        const title =
                            type === WORKFLOW_STATUS_TASK_TYPE_CHANGE_STATUS
                                ? `${t(getWorkflowTaskLabel(type))}: ${newStatusTitle}`
                                : t(getWorkflowTaskLabel(type));
                        return (
                            <Box
                                key={`related-task-${uuid}`}
                                sx={{
                                    border: `1px solid ${theme.palette.info.main}`,
                                    borderRadius: 2,
                                    p: 2,
                                    mb: 2,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography sx={{fontWeight: 700}}>{title}</Typography>
                                    <IgnoreRelatedTaskButton item={task} />
                                </Box>
                                {type === WORKFLOW_STATUS_TASK_TYPE_EMAIL_SENDING && (
                                    <Typography sx={{fontSize: 12, mt: 1}}>{`${t(
                                        'orguser.workflows.tasks.emailTemplate.subject',
                                    )}: ${subject} (${getTemplateForLabel(
                                        t,
                                        isForAgent,
                                        isForOther,
                                        isForRequester,
                                    ).toLowerCase()})`}</Typography>
                                )}
                                <Typography sx={{fontSize: 12}}>{`${t('orguser.requesterCase.executedOn')}: ${moment(
                                    executedAt,
                                ).format(DATE_LABEL_FORMAT)} ${
                                    isIgnored ? `(${t('orguser.requesterCase.ignored')})` : ''
                                }`}</Typography>
                            </Box>
                        );
                    })}
            </ModalWrapper>
        </>
    );
};

export default RelatedTasksButton;
