import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import CreateWorkflowForm from 'components/Forms/Workflow/workflow/CreateWorkflowForm';
import {Box} from '@mui/material';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';

interface CreateWorkflowType {
    organizationId: number;
}

const CreateWorkflow: FC<CreateWorkflowType> = ({organizationId}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                mb: 2,
            }}
        >
            <AgentInverseButton title={t('orguser.workflows.addWorkflow')} onClick={toggleIsOpened} />
            <ModalWrapper
                isShowModal={isOpened}
                title={t('orguser.workflows.addWorkflow')}
                toggleModal={toggleIsOpened}
            >
                <CreateWorkflowForm organizationId={organizationId} closeModal={() => setIsOpened(false)} />
            </ModalWrapper>
        </Box>
    );
};

export default CreateWorkflow;
