import React, {FC} from 'react';

import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';

import CancelSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {getTagColor, isDarkColor} from 'pages/admin/updateForm/partials/FormTags/helper';

interface TagChipWithDeletingType {
    id: string | number;
    title: string;
    color: string;
    onUpdateClick?: () => void;
    onDeleteClick?: () => void;
}

const TagChipWithDeleting: FC<TagChipWithDeletingType> = ({id, title, color, onUpdateClick, onDeleteClick}) => {
    return (
        <Chip
            sx={{
                display: 'inline-flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: 24,
                fontSize: 12,
                borderRadius: 12,
                backgroundColor: color,
                color: getTagColor(color),
                mr: 1,
                mb: 1,
            }}
            key={`tag-chip-${id}`}
            label={title}
            onClick={onUpdateClick}
            onDelete={onDeleteClick}
            deleteIcon={
                <IconButton sx={{ml: 5, p: 0, width: 16, height: 16}} disableRipple>
                    <CancelSvgIcon isActive={!isDarkColor(color)} />
                </IconButton>
            }
        />
    );
};

export default TagChipWithDeleting;
