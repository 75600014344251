import React, {FC} from 'react';

import {Button, Typography} from '@mui/material';
import {theme} from 'config/theme';
import {buttonOutlineStyles, labelOutlineStyles} from 'components/AgentScreenComponents/_buttons/common';
import {useTranslation} from 'react-i18next';

interface LoadMoreButtonType {
    isLoading: boolean;
    onClick: () => void;
}

const LoadMoreButton: FC<LoadMoreButtonType> = ({isLoading, onClick}) => {
    const [t] = useTranslation();

    return (
        <Button
            sx={{
                ...buttonOutlineStyles,
                borderRadius: 2,
                height: 40,
                width: '100%',
                backgroundColor: theme.palette.background.default,
                border: `1px solid ${theme.palette.secondary.contrastText}`,
                '&:hover': {
                    backgroundColor: theme.palette.background.default,
                },
                opacity: isLoading ? 0.5 : 1,
            }}
            disabled={isLoading}
            onClick={onClick}
        >
            <Typography sx={{...labelOutlineStyles, fontSize: 13}}> {t('common.buttons.loadMoreButton')}</Typography>
        </Button>
    );
};

export default LoadMoreButton;
