import {createStyles} from '@material-ui/styles';

const styles = () => {
    const commonDescriptionStyles = {
        width: '100%',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };

    return createStyles({
        description: {
            ...commonDescriptionStyles,
            maxHeight: 110,
            '-webkit-line-clamp': 4,
        },
        descriptionTable: {
            ...commonDescriptionStyles,
            maxHeight: 50,
            '-webkit-line-clamp': 2,
        },
        descriptionMarkdownTable: {
            ...commonDescriptionStyles,
            maxHeight: 55,
            '-webkit-line-clamp': 2,
        },
        descriptionShowAll: {
            width: '100%',
        },
    });
};

export default styles;
