import React, {FC, useContext} from 'react';

import {MediaContext} from 'contexts/media/context';

import ClientModalWrapper from 'components/ModalWrapper/ClientModalWrapper';
import ClientMobileModalWrapper from 'components/ModalWrapper/ClientMobileModalWrapper';

import {ModalWrapperType} from 'components/ModalWrapper/types';

const ModalWrapper: FC<ModalWrapperType> = ({children, ...props}) => {
    const {isMobile} = useContext(MediaContext);

    const Modal = isMobile ? ClientMobileModalWrapper : ClientModalWrapper;

    return <Modal {...props}>{children}</Modal>;
};

export default ModalWrapper;
