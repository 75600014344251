import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';

import {CREATE_DASHBOARD} from 'appRedux/actions/analytics';
import {DashboardFormTypes, DashboardHasPanelTypes} from 'appRedux/actions/analytics/types';

import {AlertContext} from 'contexts/alert/context';

import DashboardForm from 'components/Forms/Dashboard/DashboardForm';
import {initialDashboardValues} from 'components/Forms/Dashboard/validation';

interface DashboardCreateFormType {
    closeModal: () => void;
}

const DashboardCreateForm: FC<DashboardCreateFormType> = ({closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [selectedPanels, setSelectedPanels] = useState<DashboardHasPanelTypes[]>([]);

    const createDashboard = useCallback(data => dispatch({type: CREATE_DASHBOARD.REQUEST, payload: data}), [dispatch]);

    const onSubmitClicked = (values: DashboardFormTypes) => {
        createDashboard({
            ...values,
            dashboardHasPanels: selectedPanels,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <DashboardForm
            initialValues={initialDashboardValues}
            selectedPanels={selectedPanels}
            setSelectedPanels={setSelectedPanels}
            onSubmitClicked={onSubmitClicked}
        />
    );
};

export default DashboardCreateForm;
