import React, {FC, useContext, useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RequesterCaseCommentsTypes} from 'appRedux/actions/comments/types';

import {CryptoContext} from 'contexts/crypto/context';
import {CaseKeyContext} from 'contexts/caseKey/context';

import {decryptStringWithKey} from 'helpers/cryptoApiHelper';

import {theme} from 'config/theme';

interface CommentRowType {
    item: RequesterCaseCommentsTypes;
    inRightPanel?: boolean;
}

const CommentEncryptRow: FC<CommentRowType> = ({item, inRightPanel}) => {
    const {section, text} = item;

    const {keys} = useContext(CryptoContext);
    const {unwrappedCaseKey} = useContext(CaseKeyContext);

    const [value, setValue] = useState<string>('');

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (text && keys && unwrappedCaseKey) {
                const decodedMessage = await decryptStringWithKey(text, unwrappedCaseKey);
                setValue(decodedMessage);
            }
        }, 200);
        return () => clearTimeout(timer);
    }, [text]);

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
            <Box
                sx={{
                    width: '100%',
                    backgroundColor:
                        section || inRightPanel ? `rgba(34, 34, 34, 0.05)` : theme.palette.background.default,
                    borderRadius: 2,
                    mb: 2,
                    p: 2,
                    pt: 1,
                    pb: 1,
                }}
            >
                <Typography sx={{mb: 1}}>{value}</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                    }}
                ></Box>
            </Box>
        </Box>
    );
};

export default CommentEncryptRow;
