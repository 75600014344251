import React, {FC} from 'react';

import Box from '@mui/material/Box';

import ChatFilesContextWrapper from 'contexts/chatFiles';

import ChatMessagesList from 'components/RequesterChat/ChatMessagesList';

const AgentChatPage: FC = () => {
    return (
        <Box sx={{pl: 5, pr: 5}}>
            <ChatFilesContextWrapper>
                <ChatMessagesList />
            </ChatFilesContextWrapper>
        </Box>
    );
};

export default AgentChatPage;
