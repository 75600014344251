import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import {RootReducer} from 'appRedux/reducers';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import TwoFactorForm from 'components/Forms/SettingsForms/TwoFactorForm/TwoFactorForm';
import {getDaysBeforeLocking} from 'components/TwoFactorChecking/helper';

const TwoFactorChecking: FC = () => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {
        profile: {isLoading, profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        const isTwoFactorActivated = get(profile, 'isTwoFactorActivated', false);
        if (!isLoading && !isTwoFactorActivated) {
            setIsOpened(true);
        }
    }, [isLoading, profile]);

    const daysBeforeLocking = getDaysBeforeLocking(profile);

    return (
        <ModalWrapper
            title={t('common.loginForm.twoFactorAuthTitle')}
            isShowModal={isOpened}
            toggleModal={toggleIsOpened}
            isWideModal={false}
        >
            {daysBeforeLocking > 0 && (
                <Alert severity="error" sx={{mb: 2}}>
                    <Typography>
                        {t('common.loginForm.twoFactorForcedExplanation').replace('%DAYS%', String(daysBeforeLocking))}
                    </Typography>
                </Alert>
            )}
            <TwoFactorForm closeModal={() => setIsOpened(false)} />
        </ModalWrapper>
    );
};

export default TwoFactorChecking;
