import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';

import {InviteAdditionalFormTypes} from 'appRedux/actions/requestCase/types';
import {DELETE_ADDITIONAL_FORM_INVITE} from 'appRedux/actions/requestCase';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationButton from 'components/AdminScreenComponents/DeleteAdminConfirmationButton';

interface DeleteAdditionalFormRequestButtonType {
    item: InviteAdditionalFormTypes;
}

const DeleteAdditionalFormRequestButton: FC<DeleteAdditionalFormRequestButtonType> = ({item}) => {
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    const {showAlert} = useContext(AlertContext);

    const {uuid} = item;

    const deleteInvite = useCallback(
        data => dispatch({type: DELETE_ADDITIONAL_FORM_INVITE.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        deleteInvite({
            uuid,
            showAlert,
            callback: () => setShowModal(false),
        });
    };

    return (
        <DeleteAdminConfirmationButton
            disabled={false}
            isShowModal={showModal}
            toggleModal={toggleModal}
            onClickAction={onClickDelete}
        />
    );
};

export default DeleteAdditionalFormRequestButton;
