import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import IconButton from '@mui/material/IconButton';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import {OrganizationItemBasic, OrganizationTemplateItemTypes} from 'appRedux/actions/organization/types';
import {RootReducer} from 'appRedux/reducers';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import OrganizationTemplateCreateForm from 'components/Forms/OrganizationTemplateForm/OrganizationTemplateCreateForm';

import {getLanguageIdByName} from 'helpers/translationsHelper';

import {theme} from 'config/theme';

interface AddOrganizationTemplateButtonType {
    organization: OrganizationItemBasic;
    label: string;
    type: number;
    defaultItem?: OrganizationTemplateItemTypes;
    currentLanguageName: string;
}

const AddOrganizationTemplateButton: FC<AddOrganizationTemplateButtonType> = ({
    organization,
    label,
    type,
    defaultItem,
    currentLanguageName,
}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {
        admin: {languageList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const currentLanguage = getLanguageIdByName(languageList, currentLanguageName, organization.language);

    if (!currentLanguage) return null;

    return (
        <>
            <IconButton
                sx={{
                    backgroundColor: theme.palette.info.main,
                    '&:hover': {
                        backgroundColor: theme.palette.info.main,
                    },
                }}
                onClick={toggleIsOpened}
                title={t('orguser.mailer.createTemplate')}
            >
                <PlusSvgIcon isActive />
            </IconButton>
            <ModalWrapper
                isShowModal={isOpened}
                title={t('orguser.mailer.createTemplate')}
                toggleModal={toggleIsOpened}
            >
                <OrganizationTemplateCreateForm
                    currentLanguage={currentLanguage}
                    type={type}
                    subject={defaultItem ? defaultItem.subject : label}
                    text={defaultItem ? defaultItem.text : ''}
                    closeModal={() => setIsOpened(false)}
                    organizationId={organization.id}
                />
            </ModalWrapper>
        </>
    );
};

export default AddOrganizationTemplateButton;
