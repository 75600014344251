import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const OrgUsersSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
            <rect width="16" height="16" transform="translate(0 0.513184)" fill="white" fillOpacity="0.01" />
            <path
                d="M15 15.5132H14V13.0132C13.9992 12.3504 13.7356 11.7149 13.2669 11.2463C12.7983 10.7776 12.1628 10.5139 11.5 10.5132V9.51318C12.4279 9.51422 13.3176 9.8833 13.9737 10.5394C14.6299 11.1956 14.999 12.0852 15 13.0132V15.5132Z"
                fill={getIconColor(isActive, isDisabled)}
            />
            <path
                d="M11 15.5132H10V13.0132C9.99922 12.3504 9.73558 11.715 9.2669 11.2463C8.79823 10.7776 8.1628 10.514 7.5 10.5132H4.5C3.8372 10.514 3.20177 10.7776 2.7331 11.2463C2.26442 11.715 2.00078 12.3504 2 13.0132V15.5132H1V13.0132C1.00109 12.0853 1.37018 11.1957 2.02632 10.5395C2.68247 9.88337 3.57208 9.51427 4.5 9.51318H7.5C8.42792 9.51427 9.31753 9.88337 9.97368 10.5395C10.6298 11.1957 10.9989 12.0853 11 13.0132V15.5132Z"
                fill={getIconColor(isActive, isDisabled)}
            />
            <path
                d="M10 1.51318V2.51318C10.663 2.51318 11.2989 2.77658 11.7678 3.24542C12.2366 3.71426 12.5 4.35014 12.5 5.01318C12.5 5.67622 12.2366 6.31211 11.7678 6.78095C11.2989 7.24979 10.663 7.51318 10 7.51318V8.51318C10.9283 8.51318 11.8185 8.14443 12.4749 7.48806C13.1313 6.83168 13.5 5.94144 13.5 5.01318C13.5 4.08493 13.1313 3.19469 12.4749 2.53831C11.8185 1.88193 10.9283 1.51318 10 1.51318Z"
                fill={getIconColor(isActive, isDisabled)}
            />
            <path
                d="M6 2.51318C6.49445 2.51318 6.9778 2.65981 7.38893 2.93451C7.80005 3.20921 8.12048 3.59966 8.3097 4.05647C8.49892 4.51329 8.54843 5.01596 8.45196 5.50091C8.3555 5.98586 8.1174 6.43132 7.76777 6.78095C7.41814 7.13058 6.97268 7.36868 6.48773 7.46515C6.00277 7.56161 5.50011 7.5121 5.04329 7.32288C4.58648 7.13366 4.19603 6.81323 3.92133 6.40211C3.64662 5.99099 3.5 5.50764 3.5 5.01318C3.5 4.35014 3.76339 3.71426 4.23223 3.24542C4.70107 2.77658 5.33696 2.51318 6 2.51318ZM6 1.51318C5.30777 1.51318 4.63108 1.71846 4.0555 2.10304C3.47993 2.48762 3.03133 3.03425 2.76642 3.67379C2.50151 4.31333 2.4322 5.01707 2.56725 5.696C2.7023 6.37493 3.03564 6.99857 3.52513 7.48806C4.01461 7.97754 4.63825 8.31088 5.31718 8.44593C5.99612 8.58098 6.69985 8.51167 7.33939 8.24676C7.97893 7.98185 8.52556 7.53325 8.91014 6.95768C9.29473 6.38211 9.5 5.70542 9.5 5.01318C9.5 4.08493 9.13125 3.19469 8.47487 2.53831C7.8185 1.88193 6.92826 1.51318 6 1.51318Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default OrgUsersSvgIcon;
