import React, {FC, useState} from 'react';

import Box from '@mui/material/Box';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';
import DownloadSvgIcon from 'assets/icons/buttons/DownloadSvgIcon';
import XlsSvgIcon from 'assets/icons/large/XlsSvgIcon';
import VideoSvgIcon from 'assets/icons/large/VideoSvgIcon';
import TxtSvgIcon from 'assets/icons/large/TxtSvgIcon';
import DocSvgIcon from 'assets/icons/large/DocSvgIcon';
import CsvSvgIcon from 'assets/icons/large/CsvSvgIcon';
import AdobeSvgIcon from 'assets/icons/large/AdobeSvgIcon';
import FileSvgIcon from 'assets/icons/large/FileSvgIcon';

import DeleteClientConfirmationModal from 'components/ClientScreenComponents/DeleteClientConfirmationModal';
import ThumbIconButton from 'components/RequesterChat/ThumbIconButton';

import {CommonFileType} from 'pages/agent/requesterPage/partials/helper';

import {CLIENT_BACKGROUND_COLOR, theme} from 'config/theme';

export enum ThumbSize {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

interface ThumbItemType {
    uuid: string;
    currentFile?: CommonFileType;
    selectFile: (selected: CommonFileType) => void;
    isUserOwner: boolean;
    downloadFile?: () => void;
    deleteFile?: (callback: () => void) => void;
    thumbSrc?: string | null;
    thumbSize?: ThumbSize;
    isChatMessage?: boolean;
}

const ThumbItem: FC<ThumbItemType> = ({
    uuid,
    currentFile,
    selectFile,
    isUserOwner,
    downloadFile,
    deleteFile,
    thumbSrc,
    thumbSize = ThumbSize.LARGE,
    isChatMessage,
}) => {
    const [onClicked, setOnClicked] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const toggleDeleteModal = () => {
        setShowDeleteModal(previous => !previous);
    };

    const handleDownloadFile = (e: React.MouseEvent) => {
        e.stopPropagation();
        downloadFile && downloadFile();
    };

    const onDeleteClick = () => {
        setOnClicked(true);
        deleteFile && deleteFile(() => setShowDeleteModal(false));
    };

    let thumbIcon;

    switch (currentFile?.extension) {
        case 'txt':
            thumbIcon = <TxtSvgIcon />;
            break;
        case 'doc':
        case 'docx':
            thumbIcon = <DocSvgIcon />;
            break;
        case 'csv':
            thumbIcon = <CsvSvgIcon />;
            break;
        case 'xls':
        case 'xlsx':
            thumbIcon = <XlsSvgIcon />;
            break;
        case 'avi':
        case 'mp4':
        case 'wmv':
        case 'webm':
        case 'mov':
            thumbIcon = <VideoSvgIcon />;
            break;
        case 'pdf':
            thumbIcon = <AdobeSvgIcon />;
            break;
        default:
            thumbIcon = <FileSvgIcon />;
    }

    let thumbSizePx: number;

    switch (thumbSize) {
        case ThumbSize.LARGE:
            thumbSizePx = 150;
            break;
        case ThumbSize.MEDIUM:
            thumbSizePx = 100;
            break;
        case ThumbSize.SMALL:
            thumbSizePx = 50;
            break;
        default:
            thumbSizePx = 150;
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 1,
                    overflow: 'hidden',
                    cursor: currentFile ? 'pointer' : 'default',
                    border: `1px solid ${theme.palette.info.main}`,
                    position: 'relative',
                    height: thumbSizePx + 'px',
                    width: thumbSizePx + 'px',
                    color: theme.palette.info.main,
                    backgroundColor: CLIENT_BACKGROUND_COLOR,
                }}
                onClick={() => {
                    currentFile && selectFile(currentFile);
                }}
            >
                {thumbSrc ? <img src={thumbSrc} style={{width: '100%', pointerEvents: 'none'}} /> : thumbIcon}
                {currentFile && !!downloadFile && (
                    <ThumbIconButton
                        onClick={handleDownloadFile}
                        sx={{
                            position: 'absolute',
                            right: isUserOwner && !isChatMessage ? 50 : 10,
                            bottom: 10,
                        }}
                    >
                        <DownloadSvgIcon />
                    </ThumbIconButton>
                )}
                {!isChatMessage && currentFile && isUserOwner && !!deleteFile && (
                    <ThumbIconButton
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            toggleDeleteModal();
                        }}
                        sx={{
                            position: 'absolute',
                            right: 10,
                            bottom: 10,
                        }}
                    >
                        <DeleteSvgIcon />
                    </ThumbIconButton>
                )}
            </Box>

            <DeleteClientConfirmationModal
                data-id={`#thumb-attachment-${uuid}-delete`}
                isShowModal={showDeleteModal}
                toggleModal={toggleDeleteModal}
                onClickAction={onDeleteClick}
                onClicked={onClicked}
            />
        </>
    );
};

export default ThumbItem;
