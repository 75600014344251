import {TFunction} from 'i18next';

export const getCommentTypeLabel = (t: TFunction, isUpdated: boolean, isPublic: boolean): string => {
    if (isUpdated && isPublic) {
        return `${t('orguser.requesterCase.edited')}, ${t('orguser.requesterCase.public')}`;
    }
    if (isUpdated) {
        return `${t('orguser.requesterCase.edited')}`;
    }
    if (isPublic) {
        return `${t('orguser.requesterCase.public')}`;
    }
    return '';
};
