import React, {ReactElement, MouseEvent, FC} from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import {
    BUTTON_HEIGHT,
    BIG_BUTTON_HEIGHT,
    buttonOutlineStyles,
    labelOutlineStyles,
} from 'components/AgentScreenComponents/_buttons/common';
import AgentCloseIconButton from 'components/AgentScreenComponents/_buttons/AgentCloseIconButton';
import {SxProps} from '@mui/material';

interface SubmitButtonType {
    isLoading?: boolean;
    isDisabled?: boolean;
    title?: string;
    onClick?: (e?: MouseEvent<HTMLElement>) => void;
    icon?: ReactElement;
    isBigButton?: boolean;
    onClose?: () => void;
    sx?: SxProps;
}

const AgentInverseButton: FC<SubmitButtonType> = ({
    isLoading,
    isBigButton,
    isDisabled,
    title,
    icon,
    onClick,
    onClose,
    sx,
}) => {
    return (
        <Button
            disabled={isLoading || isDisabled}
            sx={{...buttonOutlineStyles, height: isBigButton ? BIG_BUTTON_HEIGHT : BUTTON_HEIGHT, ...sx}}
            onClick={onClick}
            startIcon={icon ?? null}
            endIcon={onClose ? <AgentCloseIconButton onClose={onClose} isBigButton={isBigButton} isOutlined /> : ''}
        >
            <Typography sx={labelOutlineStyles}>{title}</Typography>
        </Button>
    );
};

export default AgentInverseButton;
