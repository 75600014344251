import React, {FC, useContext} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FormPageTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseItemType} from 'appRedux/actions/requestCase/types';

import {MediaContext} from 'contexts/media/context';

import {theme, MAIN_CLIENT_COLOR} from 'config/theme';
import {STATUS_OPENING} from 'config/index';

interface PageSwitcherType {
    currentPage: number;
    setCurrentPage: (value: number) => void;
    clientInformation: RequesterCaseItemType;
    pages: FormPageTypes[];
    status: string;
}

const getButtonColor = (isActive: boolean, isCompleted: boolean) => {
    if (isActive) {
        return MAIN_CLIENT_COLOR;
    }
    if (isCompleted) {
        return theme.palette.primary.contrastText;
    }
    return 'rgba(34, 34, 34, 0.2)';
};

const getButtonLabelColor = (isActive: boolean, isCompleted: boolean) => {
    if (isCompleted || isActive) {
        return theme.palette.background.default;
    }
    return 'rgba(34, 34, 34, 0.4)';
};

const PageSwitcher: FC<PageSwitcherType> = ({clientInformation, currentPage, setCurrentPage, pages, status}) => {
    const {isMobile, isMiddle, isTablet} = useContext(MediaContext);

    const onPageNumberClicked = (pageNumber: number, isCompleted: boolean) => {
        if (isCompleted) {
            setCurrentPage(pageNumber);
        }
    };

    const renderPageIcon = (page: FormPageTypes, index: number) => {
        const isActive = index + 1 === currentPage;
        let isCompleted = true;

        if (status === STATUS_OPENING) {
            if (clientInformation) {
                isCompleted = `page-${page.id}` in clientInformation;
            } else {
                isCompleted = false;
            }
        }

        return (
            <Box
                data-id={`button#page-${index}`}
                key={`page-${index}`}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: isCompleted ? 'pointer' : 'initial',
                    width: isMobile ? 32 : isTablet ? 48 : 64,
                    height: isMobile ? 32 : isTablet ? 48 : 64,
                    borderRadius: '50%',
                    backgroundColor: getButtonColor(isActive, isCompleted),
                }}
                onClick={() => onPageNumberClicked(index + 1, isCompleted)}
            >
                <Typography
                    sx={{
                        fontSize: isMobile ? 14 : isTablet ? 26 : 36,
                        color: getButtonLabelColor(isActive, isCompleted),
                    }}
                >
                    {index + 1}
                </Typography>
            </Box>
        );
    };

    const renderDotsIconJSX = (key: string) => {
        return (
            <Box sx={{position: 'relative', width: '10px'}} key={key}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: isMobile ? '8px' : isTablet ? '12px' : isMiddle ? '20px' : '25px',
                    }}
                >
                    {[...Array(2)].map((e, index) => (
                        <Box
                            sx={{
                                width: isMobile ? 6 : isTablet ? 8 : 12,
                                height: isMobile ? 6 : isTablet ? 8 : 12,
                                borderRadius: '50%',
                                backgroundColor: theme.palette.info.main,
                            }}
                            key={index}
                        ></Box>
                    ))}
                </Box>
            </Box>
        );
    };

    function getPageWithAdjacent(array: FormPageTypes[]): JSX.Element[] {
        if (!array) return [];

        const currentIndex = currentPage - 1;
        const pagesArrayJSX: JSX.Element[] = [];
        const previousIndex = currentIndex - 1;
        const nextIndex = currentIndex + 1;
        const lastIndex = array.length - 1;

        if (array?.[0]) pagesArrayJSX.push(renderPageIcon(array[0], 0));
        if (previousIndex > 1) pagesArrayJSX.push(renderDotsIconJSX('dots-previous'));
        if (previousIndex > 0) pagesArrayJSX.push(renderPageIcon(array[previousIndex], previousIndex));
        if (currentIndex !== 0) pagesArrayJSX.push(renderPageIcon(array[currentIndex], currentIndex));
        if (nextIndex < array.length) pagesArrayJSX.push(renderPageIcon(array[nextIndex], nextIndex));
        if (nextIndex < array.length - 2) pagesArrayJSX.push(renderDotsIconJSX('dots-next'));
        if (lastIndex > nextIndex) pagesArrayJSX.push(renderPageIcon(array[lastIndex], lastIndex));

        return pagesArrayJSX;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: isMobile ? '15px' : isTablet ? '20px' : isMiddle ? '35px' : '55px',
                mt: 2,
                mb: 2,
            }}
        >
            {pages && (pages.length > 4 ? getPageWithAdjacent(pages) : pages.map(renderPageIcon))}
        </Box>
    );
};

export default PageSwitcher;
