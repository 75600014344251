import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {SET_STANDARD_AVATAR} from 'appRedux/actions/profile';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import {buttonStyles, standardAvatars, StandardAvatarTypes} from 'components/UserAvatar/helper';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import {getAvatarTitle} from 'components/TopBarMenu/helper';

import {MAIN_AGENT_COLOR} from 'config/theme';

const SelectAvatar: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const setStandardAvatar = useCallback(
        data => dispatch({type: SET_STANDARD_AVATAR.REQUEST, payload: data}),
        [dispatch],
    );

    const onAvatarClick = (type: number) => {
        setStandardAvatar({
            type,
            showAlert,
            callback: () => setIsOpened(false),
        });
    };

    const firstName = get(profile, 'firstName', null);
    const lastName = get(profile, 'lastName', null);
    const email = get(profile, 'email', null);

    return (
        <>
            <Button data-id="button#select-avatar" onClick={toggleIsOpened}>
                <IconButton>
                    <EditSvgIcon />
                </IconButton>
                <Typography sx={{...buttonStyles}}>{t('common.account.selectAvatar')}</Typography>
            </Button>
            {isOpened && (
                <ModalWrapper
                    title={t('common.account.selectAvatar')}
                    isShowModal={isOpened}
                    toggleModal={() => setIsOpened(false)}
                >
                    <Grid container>
                        <Grid
                            item
                            xs={6}
                            md={3}
                            key={`avatar-none`}
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Avatar
                                data-id={`button#avatar-0`}
                                sx={{
                                    mb: 2,
                                    width: 120,
                                    height: 120,
                                    backgroundColor: MAIN_AGENT_COLOR,
                                    '&:hover': {
                                        cursor: 'pointer',
                                        opacity: 0.4,
                                    },
                                }}
                                onClick={() => onAvatarClick(0)}
                            >
                                <Typography sx={{fontSize: 47}}>
                                    {getAvatarTitle(firstName, lastName, email)}
                                </Typography>
                            </Avatar>
                        </Grid>
                        {standardAvatars.map((item: StandardAvatarTypes, index: number) => {
                            return (
                                <Grid
                                    item
                                    xs={6}
                                    md={3}
                                    key={`avatar-${index}`}
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Avatar
                                        data-id={`button#avatar-${index}`}
                                        sx={{
                                            mb: 2,
                                            width: 120,
                                            height: 120,
                                            '&:hover': {
                                                cursor: 'pointer',
                                                opacity: 0.4,
                                            },
                                        }}
                                        onClick={() => onAvatarClick(item.type)}
                                    >
                                        {item.image}
                                    </Avatar>
                                </Grid>
                            );
                        })}
                    </Grid>
                </ModalWrapper>
            )}
        </>
    );
};

export default SelectAvatar;
