import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import {RootReducer} from 'appRedux/reducers';
import {AgentSavedFilterTypes} from 'appRedux/actions/agentFilters/types';
import {AGENT_FILTERS_LIST, AGENT_FILTER_TAGS_LIST} from 'appRedux/actions/agentFilters';
import {AGENT_LIST} from 'appRedux/actions/admin';
import {FORMS_LIST} from 'appRedux/actions/forms';
import {GET_BOARDS_LIST} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';
import {PermissionContext} from 'contexts/permission/context';

import SavedFilterInfo from 'pages/agent/savedFilters/SavedFilterInfo';
import CreateNewFilter from 'pages/agent/savedFilters/CreateNewFilter';
import {MAXIMAL_SAVED_FILTERS_NUMBER} from 'pages/agent/savedFilters/helper';

import {theme} from 'config/theme';

const SavedFiltersPage: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {setBackLink, setBackTab, setPageTitle, setTopBarTitle, setBackLinkLabel} = useContext(RouteContext);
    const {isFormViewEnabled} = useContext(PermissionContext);

    const [activeFilter, setActiveFilter] = useState<string | null>(null);

    const getSavedFilters = useCallback(() => dispatch({type: AGENT_FILTERS_LIST.REQUEST}), [dispatch]);
    const getFilterTags = useCallback(() => dispatch({type: AGENT_FILTER_TAGS_LIST.REQUEST}), [dispatch]);
    const getAgents = useCallback(() => dispatch({type: AGENT_LIST.REQUEST}), [dispatch]);
    const getFormsList = useCallback(data => dispatch({type: FORMS_LIST.REQUEST, payload: data}), [dispatch]);
    const getBoardsList = useCallback(data => dispatch({type: GET_BOARDS_LIST.REQUEST, payload: data}), [dispatch]);

    const {
        admin: {savedFilters},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        setPageTitle(t('orguser.savedFilters.caseFilter'));
        setTopBarTitle('');
        setBackLink('');
        setBackTab('');
        setBackLinkLabel('');

        getBoardsList({
            showAlert,
        });
        getSavedFilters();
        getAgents();

        if (isFormViewEnabled) {
            getFilterTags();
            getFormsList({});
        }
    }, []);

    return (
        <Box sx={{backgroundColor: theme.palette.background.default, width: '100%', borderRadius: 4, p: 3}}>
            {savedFilters.length < MAXIMAL_SAVED_FILTERS_NUMBER && (
                <>
                    <CreateNewFilter />
                    <Divider sx={{mt: 3, mb: 2}} />
                </>
            )}
            {savedFilters &&
                savedFilters.map((item: AgentSavedFilterTypes, index: number) => {
                    return (
                        <SavedFilterInfo
                            key={`savedFilter-${index}`}
                            item={item}
                            activeFilter={activeFilter}
                            setActiveFilter={setActiveFilter}
                        />
                    );
                })}
        </Box>
    );
};

export default SavedFiltersPage;
