import {SET_ERRORS} from 'appRedux/actions/errors';
import {MainProfileResponseTypes} from 'appRedux/actions/profile/types';

export interface ErrorsState {
    userFormErrors: {[key: string]: string} | null;
    generalError: {[key: string]: string} | null;
}

export const initialState: ErrorsState = {
    userFormErrors: null,
    generalError: null,
};

const errors = (state = initialState, action: IActionType<MainProfileResponseTypes | IErrors>): ErrorsState => {
    switch (action.type) {
        case SET_ERRORS.USER_FORM_ERRORS: {
            return {
                ...state,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                userFormErrors: action.payload,
            };
        }
        case SET_ERRORS.GENERAL_ERROR: {
            return {
                ...state,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                generalError: action.payload,
            };
        }
        default:
            return state;
    }
};

export default errors;
