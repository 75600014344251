import React, {FC, ReactNode, RefObject, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {ClientFormContext} from 'contexts/clientForm/context';

import ElementDescription from 'pages/client/form/partials/ElementDescription';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

interface FormUploaderWrapperType {
    formId: number;
    field: FormFieldTypes;
    children: ReactNode;
    wrapperRef: RefObject<HTMLDivElement | undefined>;
    previewMode?: boolean;
}

const FormUploaderWrapper: FC<FormUploaderWrapperType> = ({field, wrapperRef, previewMode, formId, children}) => {
    const [t] = useTranslation();

    const {errorField} = useContext(ClientFormContext);

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {label, isRequired, id} = field;

    const keywordLabel = getFieldKeyword(formId, field.id, 'label');

    const isError = id === errorField;

    return (
        <Box ref={wrapperRef} sx={{mb: 1}}>
            <FormControl fullWidth error={isError}>
                <FormLabel sx={{mb: 1}}>{`${
                    previewMode ? label : getFormTranslatedLabel(translations, keywordLabel, label)
                } ${isRequired ? '*' : ''}`}</FormLabel>
                <ElementDescription
                    keyword={getFieldKeyword(formId, field.id, 'description')}
                    description={field.description}
                    previewMode={previewMode}
                />
                {children}
            </FormControl>
        </Box>
    );
};

export default FormUploaderWrapper;
