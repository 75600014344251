import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {Link as MuiLink} from '@mui/material';

import RedirectSvgIcon from 'assets/icons/buttons/RedirectSvgIcon';

import {InviteAdditionalFormTypes} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import EmptyArea from 'components/EmptyArea';

import DeleteAdditionalFormRequestButton from 'pages/agent/requesterPage/additionalForms/DeleteAdditionalFormRequestButton';

import {getFormTranslatedLabel, getFormKeyword} from 'helpers/translationsHelper';

import {routes} from 'config/index';

interface InviteAdditionalFormRowType {
    item: InviteAdditionalFormTypes;
}

const InviteAdditionalFormRow: FC<InviteAdditionalFormRowType> = ({item}) => {
    const [t] = useTranslation();

    const {caseId, formId, formTitle, isSubmitted} = item;

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                mt: 1,
            }}
        >
            <Typography>
                {getFormTranslatedLabel(translations, getFormKeyword(Number(formId), 'title'), formTitle)}
            </Typography>
            <EmptyArea />
            {!isSubmitted ? (
                <DeleteAdditionalFormRequestButton item={item} />
            ) : (
                <MuiLink href={`${routes.REQUESTER_CASE}/${caseId}/form/${formId}`} target="_blank">
                    <IconButton title={t('orguser.requesterCase.additionalForms.redirectToCase')}>
                        <RedirectSvgIcon />
                    </IconButton>
                </MuiLink>
            )}
        </Box>
    );
};

export default InviteAdditionalFormRow;
