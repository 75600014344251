import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import FormFieldTab from 'components/Forms/FormBuilder/FieldForm/partials/FormFieldTab';
import {TAB_FIELD_DATA, TAB_GDPR_SETTINGS, TAB_INTERNAL} from 'components/Forms/FormBuilder/FieldForm/partials/helper';

interface FormFieldTabsSwitcherType {
    currentTab: number;
    setCurrentTab: (value: number) => void;
}

const FormFieldTabsSwitcher: FC<FormFieldTabsSwitcherType> = ({currentTab, setCurrentTab}) => {
    const [t] = useTranslation();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                pl: 2,
                pr: 2,
            }}
        >
            <FormFieldTab
                title={t('orguser.forms.fieldFormTabs.fieldData')}
                tabNumber={TAB_FIELD_DATA}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
            />
            <FormFieldTab
                title={t('orguser.forms.fieldFormTabs.gdpr')}
                tabNumber={TAB_GDPR_SETTINGS}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
            />
            <FormFieldTab
                title={t('orguser.forms.fieldFormTabs.internal')}
                tabNumber={TAB_INTERNAL}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
            />
        </Box>
    );
};

export default FormFieldTabsSwitcher;
