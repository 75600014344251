import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {WorkflowItemTypes, WorkflowFormTypes} from 'appRedux/actions/workflow/types';
import {UPDATE_WORKFLOW} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import WorkflowForm from 'components/Forms/Workflow/workflow/WorkflowForm';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import {theme} from 'config/theme';

interface UpdateWorkflowFormType {
    currentWorkflow: WorkflowItemTypes;
}

const UpdateWorkflowForm: FC<UpdateWorkflowFormType> = ({currentWorkflow}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isFormVisible, setIsFormVisible] = useState<boolean>(false);

    const toggleIsFormVisible = () => {
        setIsFormVisible(previous => !previous);
    };

    const updateWorkflow = useCallback(data => dispatch({type: UPDATE_WORKFLOW.REQUEST, payload: data}), [dispatch]);

    const onSubmitClicked = (values: WorkflowFormTypes) => {
        updateWorkflow({
            ...values,
            uuid,
            showAlert,
            callback: () => setIsFormVisible(false),
        });
    };

    const {title, uuid} = currentWorkflow;

    return (
        <Box>
            <ModalWrapper
                title={t('orguser.workflows.updateWorkflow')}
                isShowModal={isFormVisible}
                toggleModal={toggleIsFormVisible}
            >
                <WorkflowForm
                    initialValues={{
                        title: currentWorkflow.title,
                    }}
                    onSubmitClicked={onSubmitClicked}
                />
            </ModalWrapper>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <Typography sx={{fontWeight: 600, mr: 2}}>{`${t('orguser.workflows.parameters.title')}:`}</Typography>
                <Typography>{title}</Typography>
                <IconButton
                    title={t('common.buttons.update')}
                    onClick={toggleIsFormVisible}
                    sx={{
                        ml: 2,
                        backgroundColor: theme.palette.info.main,
                        '&:hover': {
                            backgroundColor: theme.palette.info.main,
                        },
                    }}
                >
                    <EditSvgIcon isActive />
                </IconButton>
            </Box>
        </Box>
    );
};

export default UpdateWorkflowForm;
