import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import {FormBaseInformationTypes} from 'appRedux/actions/auth/types';
import {RootReducer} from 'appRedux/reducers';
import {CREATE_REQUESTER_CASE, GET_ALL_REQUESTER_CASES} from 'appRedux/actions/requestCase';

import {MediaContext} from 'contexts/media/context';
import {AlertContext} from 'contexts/alert/context';
import {CryptoContext} from 'contexts/crypto/context';

import {isUserAlreadyHasRequest} from 'components/RegistrationForm/helper';

import {getFormKeyword, getParsedTranslatedLabel} from 'helpers/translationsHelper';

import {theme} from 'config/theme';
import {STATUS_OPENING, routes} from 'config/index';

interface AlreadyLoginType {
    userId: number;
    formInformation: FormBaseInformationTypes;
}

const AlreadyLogin: FC<AlreadyLoginType> = ({formInformation, userId}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {hash} = useLocation();
    const navigate = useNavigate();

    const {isMobile} = useContext(MediaContext);
    const {showAlert} = useContext(AlertContext);
    const {regenerateKeysAfterLogin} = useContext(CryptoContext);

    const {id, isEncryptInfo, isMultipleRequestsPossible} = formInformation;

    const {
        auth,
        profile: {profile},
        requestCase: {isLoading, allCases},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const formButtonText = get(auth, ['form', 'formButtonText'], '');
    const formInfo = get(auth, 'form', null);
    const formButtonKeyword = formInfo?.id ? getFormKeyword(formInfo?.id, 'formButtonText') : '';

    const isAnonymousAccount = get(profile, 'isAnonymousAccount', false);

    const getAllRequesterCases = useCallback(
        data => dispatch({type: GET_ALL_REQUESTER_CASES.REQUEST, payload: data}),
        [dispatch],
    );

    const createRequesterCase = useCallback(
        data => dispatch({type: CREATE_REQUESTER_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        getAllRequesterCases({
            id: userId,
        });
    }, []);

    const redirectToForm = async (caseId: number) => {
        const redirectTo = isEncryptInfo
            ? `${routes.REQUEST}/${caseId}/keysGenerate/${id}`
            : `${routes.REQUEST}/${caseId}/form/${id}`;
        await regenerateKeysAfterLogin(redirectTo);
    };

    const createNewRequesterCase = () => {
        createRequesterCase({
            formId: id,
            invite: String(hash).replace('#', ''),
            showAlert,
            callback: redirectToForm,
        });
    };

    const draftCase =
        allCases &&
        Object.values(allCases)
            ?.flat(1)
            ?.filter(
                (requesterCase: any) =>
                    requesterCase.form?.id === id &&
                    requesterCase.isCurrentOrganization &&
                    requesterCase.requestCase.status === STATUS_OPENING,
            )[0];

    const redirectToDraft = () => {
        const caseId = (draftCase.requestCase as any)?.id;
        const redirectTo = `${routes.REQUEST}/${caseId}/form/${id}`;
        return navigate(redirectTo, {replace: true});
    };

    const isMultipleRequestsImpossible = !isMultipleRequestsPossible && isUserAlreadyHasRequest(allCases, id);

    return (
        <Box sx={{mt: 1, mb: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography align="center" sx={{fontWeight: 600, mb: 2}}>
                {t(
                    isMultipleRequestsImpossible
                        ? 'common.registrationForm.youAlreadyRegistered'
                        : draftCase
                        ? 'common.registrationForm.hasDraftPending'
                        : 'common.registrationForm.alreadyLogin',
                )}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: 3,
                    mt: 2,
                    mb: 2,
                }}
            >
                {!isAnonymousAccount && draftCase && (
                    <LoadingButton
                        loading={isLoading}
                        disabled={isLoading}
                        disableRipple
                        onClick={redirectToDraft}
                        sx={{
                            color: theme.palette.background.paper,
                            width: isMobile ? '60vw' : 180,
                            height: 48,
                            backgroundColor: theme.palette.info.main,
                            borderRadius: 24,
                            '&:hover': {
                                backgroundColor: theme.palette.info.main,
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                                textTransform: 'none',
                            }}
                        >
                            {t('common.registrationForm.continueToDraft')}
                        </Typography>
                    </LoadingButton>
                )}
                {!isMultipleRequestsImpossible && !isAnonymousAccount && (
                    <LoadingButton
                        loading={isLoading}
                        disabled={isLoading}
                        disableRipple
                        onClick={createNewRequesterCase}
                        sx={{
                            color: theme.palette.background.paper,
                            width: isMobile ? '60vw' : 180,
                            height: 48,
                            backgroundColor: theme.palette.info.main,
                            borderRadius: 24,
                            '&:hover': {
                                backgroundColor: theme.palette.info.main,
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                                textTransform: 'none',
                            }}
                        >
                            {draftCase
                                ? t('common.registrationForm.createNew')
                                : formButtonText
                                ? getParsedTranslatedLabel(t, formButtonKeyword, formButtonText)
                                : t('common.registrationForm.requestHelp')}
                        </Typography>
                    </LoadingButton>
                )}
            </Box>
            <Link to={routes.REQUESTS_LIST}>
                <Typography align="center">{t('common.registrationForm.goToOtherCases')}</Typography>
            </Link>
        </Box>
    );
};

export default AlreadyLogin;
