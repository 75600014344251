import {KeysType} from 'contexts/crypto/context';

const DB_NAME = 'KeyStore';
export const OBJECT_STORE_NAME = 'keys';

export const openIndexedDB = async (iDB: IDBFactory): Promise<IDBDatabase | null> => {
    let db;
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const DBOpenRequest = iDB.open(DB_NAME, 4);
            DBOpenRequest.onerror = event => {
                console.error(event);
                reject('IndexedDB open issue.');
            };
            DBOpenRequest.onsuccess = async () => {
                // Store the result of opening the database in the db variable
                db = DBOpenRequest.result;
                resolve(db);
            };
            DBOpenRequest.onupgradeneeded = function (this, event) {
                db = (event.target as any)?.result as IDBDatabase;
                db.onerror = () => {
                    reject('IndexedDB can not be created.');
                    console.error('IndexedDB can not be created.');
                };
                // Create an objectStore for this database
                const objectStore = db.createObjectStore(OBJECT_STORE_NAME, {autoIncrement: true});
                // Define what data items the objectStore will contain
                objectStore.createIndex('by_name', 'name', {unique: true});
                resolve(db);
            };
        }, 500);
    });
};

export const readKeysFromIndexedDB = async (db: IDBDatabase, wrapperKeyName: string): Promise<KeysType | null> => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction([OBJECT_STORE_NAME], 'readwrite');
        transaction.onerror = () => {
            reject(`Can't read from indexedDB.`);
        };
        transaction.onabort = () => {
            reject('Read transaction from indexedDB has been aborted.');
        };

        const store = transaction.objectStore(OBJECT_STORE_NAME);

        const request = store.getAll();
        request.onsuccess = function () {
            const matching = request.result && request.result.find(r => r.name === wrapperKeyName && r.uuid);

            console.log(wrapperKeyName, request.result, matching);
            if (matching !== undefined) {
                // A match was found.
                resolve(matching);
            } else {
                // No match was found.
                resolve(null);
            }
        };
    });
};

export const writeKeysToIndexedDB = async (db: IDBDatabase, keyPairRecord: KeysType): Promise<KeysType | null> => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction([OBJECT_STORE_NAME], 'readwrite');
        transaction.onerror = function () {
            reject(`Can't save your keys to indexedDB.`);
        };
        transaction.onabort = function () {
            reject('Write transaction from indexedDB has been aborted.');
        };
        transaction.oncomplete = function () {
            console.log('transaction complete');
            resolve(keyPairRecord);
        };

        const objectStore = transaction.objectStore(OBJECT_STORE_NAME);
        objectStore.add(keyPairRecord);
    });
};

export const deleteKeysFromIndexedDB = async (db: IDBDatabase, wrapperKeyName: string): Promise<KeysType | null> => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction([OBJECT_STORE_NAME], 'readwrite');
        transaction.onerror = () => {
            reject(`Can't delete from indexedDB.`);
        };
        transaction.onabort = () => {
            reject('Delete transaction from indexedDB has been aborted.');
        };

        const store = transaction.objectStore(OBJECT_STORE_NAME);

        const request = store.getAll();
        request.onsuccess = function () {
            const records = request.result;
            const matching = records && records.filter(r => r.name === wrapperKeyName);
            if (matching?.[0] !== undefined) {
                // A match was found.
                const requestKeys = store.getAllKeys();
                requestKeys.onsuccess = function () {
                    const keys = requestKeys.result;
                    Promise.all(
                        matching.map(async match => {
                            const index = records.findIndex(r => r.uuid === match.uuid);
                            const requestDel = store.delete(keys[index]);
                            console.log('delete', match, index);
                            return new Promise(resolveDel => {
                                requestDel.onsuccess = function () {
                                    resolveDel(null);
                                };
                            });
                        }),
                    ).then(() => resolve(null));
                };
            } else {
                // No match was found.
                resolve(null);
            }
        };
    });
};
