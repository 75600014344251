import React, {FC} from 'react';

interface IconTypeSvgType {
    isActive?: boolean;
}

const IconType_04: FC<IconTypeSvgType> = ({isActive}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 1C6.61553 1 5.26215 1.41054 4.111 2.17971C2.95986 2.94888 2.06265 4.04213 1.53284 5.32122C1.00303 6.6003 0.864402 8.00776 1.1345 9.36563C1.4046 10.7235 2.07128 11.9708 3.05025 12.9497C4.02922 13.9287 5.2765 14.5954 6.63436 14.8655C7.99223 15.1356 9.3997 14.997 10.6788 14.4672C11.9579 13.9373 13.0511 13.0401 13.8203 11.889C14.5895 10.7378 15 9.38447 15 8C14.9979 6.14413 14.2597 4.36486 12.9474 3.05256C11.6351 1.74026 9.85587 1.00209 8 1ZM2 8C2.00136 7.31822 2.11975 6.64173 2.35 6H5L7.5 8L6.1 9.84645C6.02779 9.94274 5.99274 10.0618 6.00128 10.1819C6.00982 10.302 6.06138 10.4149 6.1465 10.5L7.5 12V13.9746C6.00169 13.8482 4.60534 13.1642 3.58696 12.0579C2.56858 10.9517 2.00228 9.50363 2 8ZM10.7174 13.3423L11.9902 9.4927C12.0085 9.40166 12.001 9.30735 11.9686 9.22032C11.9362 9.1333 11.8803 9.05701 11.807 9L7.5 5.5L8.5 4.5H11.1396C11.2446 4.50001 11.3469 4.467 11.432 4.40566C11.5171 4.34431 11.5808 4.25774 11.614 4.1582L11.8617 3.415C12.6202 4.05158 13.209 4.86637 13.5753 5.78638C13.9416 6.70639 14.074 7.70289 13.9606 8.68663C13.8472 9.67037 13.4916 10.6106 12.9256 11.4232C12.3596 12.2357 11.6009 12.8952 10.7174 13.3425V13.3423Z"
                fill={isActive ? '#ffffff' : '#222222'}
            />
        </svg>
    );
};

export default IconType_04;
