import React, {FC} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import {theme} from 'config/theme';

interface BackToRegisterType {
    onClick?: () => void;
    to?: string;
}

const BackToRegister: FC<BackToRegisterType> = ({onClick, to}) => {
    const [t] = useTranslation();

    return (
        <Link
            to={to ?? ''}
            sx={{mt: 1, textDecoration: 'none'}}
            variant="body2"
            component={RouterLink}
            onClick={e => {
                if (!onClick) return;
                e.preventDefault();
                onClick();
            }}
        >
            <Typography
                sx={{
                    color: theme.palette.info.light,
                }}
            >
                {t('common.registrationForm.backToRegister')}
            </Typography>
        </Link>
    );
};

export default BackToRegister;
