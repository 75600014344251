import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';

import {BarChart} from '@mui/x-charts/BarChart';
import {axisClasses} from '@mui/x-charts/ChartsAxis';

import {GET_MAIL_SENDING_STATISTIC} from 'appRedux/actions/superadmin';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

import {getAxisYInterval, isNoneAttemptFound} from 'pages/admin/dashboard/statistic/helper';

const MailSendingStatistic: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const getMailSendingStatistic = useCallback(
        data => dispatch({type: GET_MAIL_SENDING_STATISTIC.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        superadmin: {mailSendingStatistic},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        getMailSendingStatistic({
            days: 14,
            showAlert,
        });
    }, []);

    const dataset = mailSendingStatistic.map(params => {
        const {qty, date} = params;
        return {
            qty,
            date,
        };
    });

    const chartSetting = {
        dataset,
        yAxis: [
            {
                label: t('superadmin.dashboard.qty'),
                tickInterval: getAxisYInterval(mailSendingStatistic),
            },
        ],
        series: [{dataKey: 'qty', label: t('superadmin.dashboard.qty'), color: '#6692B0'}],
        height: 400,
        sx: {
            [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
                transform: 'translateX(-10px)',
            },
        },
        slotProps: {
            legend: {
                hidden: true,
            },
        },
    };

    return (
        <ClientSectionWrapper title={t('superadmin.dashboard.mailSendingStatistic')}>
            {mailSendingStatistic && isNoneAttemptFound(mailSendingStatistic) ? (
                <BarChart
                    xAxis={[
                        {scaleType: 'band', dataKey: 'date', tickPlacement: 'middle', tickLabelPlacement: 'middle'},
                    ]}
                    {...chartSetting}
                />
            ) : (
                <Typography>{t('common.none')}</Typography>
            )}
        </ClientSectionWrapper>
    );
};

export default MailSendingStatistic;
