import React, {FC, useCallback, useContext} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';

import {
    WorkflowStatusTaskItemTypes,
    WorkflowStatusTaskFormTypes,
    WorkflowStatusItemTypes,
} from 'appRedux/actions/workflow/types';
import {UPDATE_WORKFLOW_STATUS_TASK} from 'appRedux/actions/workflow';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import {validationSchemaWorkflowTask} from 'components/Forms/Workflow/validation';

import {OPTIONS_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';

interface UpdateWorkflowStatusTaskFormType {
    item: WorkflowStatusTaskItemTypes;
    status: WorkflowStatusItemTypes;
}

const UpdateWorkflowStatusTaskForm: FC<UpdateWorkflowStatusTaskFormType> = ({item, status}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        workflow: {isLoading, workflowStatusesList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onSubmitClicked = useCallback(
        data => dispatch({type: UPDATE_WORKFLOW_STATUS_TASK.REQUEST, payload: data}),
        [dispatch],
    );

    const {uuid, type, period, newStatus} = item;

    const {permittedIds} = status;

    const permittedIdsArray = permittedIds ? permittedIds.split(OPTIONS_SEPARATOR) : [];

    const onSaveClicked = (values: WorkflowStatusTaskFormTypes) => {
        onSubmitClicked({
            ...values,
            showAlert,
            uuid,
        });
    };

    return (
        <Box sx={{mt: 2}}>
            <Typography sx={{fontWeight: 600}}>{`${t('orguser.workflows.tasks.period')}:`}</Typography>
            <Formik
                initialValues={{type, period, newStatus}}
                onSubmit={onSaveClicked}
                validation={validationSchemaWorkflowTask}
            >
                {(formik: FormikProps<WorkflowStatusTaskFormTypes>) => {
                    return (
                        <Form>
                            <FormControl required fullWidth>
                                <Field
                                    required
                                    name="period"
                                    type="number"
                                    placeholder={t('orguser.workflows.tasks.period')}
                                    component={FormikTextInput}
                                    isDisabled={isLoading}
                                    onChange={e => {
                                        formik.handleChange(e);
                                        const value = Number(e.target.value);
                                        if (value > 0) {
                                            formik.submitForm();
                                        }
                                    }}
                                />
                            </FormControl>
                            <FormControl sx={{mt: 1}} required fullWidth>
                                <InputLabel>{t('orguser.workflows.tasks.newStatus')}</InputLabel>
                                <Select
                                    label={t('orguser.workflows.tasks.newStatus')}
                                    name={'newStatus'}
                                    value={formik.values.newStatus}
                                    onChange={e => {
                                        formik.handleChange(e);
                                        formik.submitForm();
                                    }}
                                    IconComponent={KeyboardArrowDownIcon}
                                    fullWidth
                                    required
                                >
                                    {workflowStatusesList.map((status: WorkflowStatusItemTypes) => {
                                        const {uuid, id, title} = status;
                                        if (permittedIdsArray.includes(String(id))) {
                                            return (
                                                <MenuItem key={`workflow-status-${uuid}`} value={id}>
                                                    <Typography sx={{fontWeight: 600}}>{title}</Typography>
                                                </MenuItem>
                                            );
                                        }
                                        return null;
                                    })}
                                </Select>
                            </FormControl>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default UpdateWorkflowStatusTaskForm;
