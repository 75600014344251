import React, {FC, ReactNode, useCallback, useContext, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import HerupuSpinner from 'assets/herupuSpinner';

import {RootReducer} from 'appRedux/reducers';
import {MessageTypes} from 'appRedux/actions/requestChat/types';
import {REFRESH_CHAT_MESSAGES} from 'appRedux/actions/requestChat';

import {WebsocketContext} from 'contexts/websocket/context';

import ChatMessage from 'components/RequesterChat/ChatMessage';
import ChatMessageEncrypt from 'components/RequesterChat/ChatMessageEncrypt';
import {getDateLabel} from 'components/RequesterChat/helper';
import ScrollChatToBottom from 'components/RequesterChat/partials/ScrollChatToBottom';
import useLoadMessagesList from 'components/RequesterChat/useLoadMessagesList';

import {theme} from 'config/theme';

const MessagesList: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {caseId} = useParams();

    const {isShowScrollBottomButton, setIsShowScrollBottomButton, notReadMessagesCounter, setNotReadMessagesCounter} =
        useContext(WebsocketContext);

    const messagesEndRef = useRef<HTMLDivElement>();
    const isScrolledRef = useRef<boolean>(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const refreshChatMessages = useCallback(() => dispatch({type: REFRESH_CHAT_MESSAGES.REQUEST}), [dispatch]);

    const {
        profile,
        requestCase: {
            currentCase: {isEncryptInfo},
        },
        requestChat: {isLoading, oldestMessageId, allMessagesLoaded, contacts},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {messages} = useLoadMessagesList({wrapperRef});

    useEffect(() => {
        if (isScrolledRef.current && messagesEndRef && messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({block: 'start', behavior: 'smooth'});
            return;
        }

        const scrollDelay = setTimeout(() => {
            scrollToBottom();
        }, 750);

        return () => clearTimeout(scrollDelay);
    }, [caseId, messages.length]);

    useEffect(() => {
        setIsShowScrollBottomButton(false);
        setNotReadMessagesCounter(0);
    }, []);

    const prevHeight = useRef<number>(0);

    useEffect(() => {
        const bondRect = wrapperRef.current?.getBoundingClientRect();

        if (bondRect && scrollContainerRef?.current && prevHeight.current && bondRect.height > prevHeight.current) {
            scrollContainerRef.current.scrollTop =
                scrollContainerRef.current.scrollTop + bondRect.height - prevHeight.current;
        }
        prevHeight.current = bondRect ? bondRect.height : 0;
    }, [oldestMessageId, messages.length]);

    const userId = get(profile, ['profile', 'id'], null);

    let senderId = 0;
    let isSystem = false;
    let firstUnreadMessageId: string | null = null;

    const scrollToBottom = () => {
        if (messagesEndRef && messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({behavior: 'smooth'});
            isScrolledRef.current = true;
            setIsShowScrollBottomButton(false);
            setNotReadMessagesCounter(0);
        }
    };

    const showScrollToBottomButton = isShowScrollBottomButton && notReadMessagesCounter > 0;

    useEffect(() => {
        return () => {
            refreshChatMessages();
        };
    }, []);

    return (
        <Box
            ref={scrollContainerRef}
            sx={{overflowY: 'scroll', height: 'calc(70vh - 142px)', display: 'flex', flexDirection: 'column-reverse'}}
        >
            <Box ref={wrapperRef}>
                {messages.length === 0 && (
                    <Typography
                        align="center"
                        sx={{
                            m: 2,
                            fontWeight: 600,
                            color: theme.palette.primary.contrastText,
                        }}
                    >
                        {t('orguser.chat.noMessagesYet')}
                    </Typography>
                )}
                <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50}}>
                    {isLoading && !allMessagesLoaded && <HerupuSpinner />}
                </Box>
                <Box>
                    {messages.map((message: MessageTypes, messageIndex: number) => {
                        const previousMessageSenderSame =
                            senderId === message.senderId && isSystem === message.isSystemMessage;
                        senderId = message.senderId;
                        isSystem = message.isSystemMessage;

                        let chatMessage: ReactNode;

                        if (isEncryptInfo) {
                            chatMessage = (
                                <ChatMessageEncrypt
                                    key={`chat-encrypt-${caseId}-message-${messageIndex}`}
                                    item={message}
                                    userId={userId}
                                    previousMessageSenderSame={previousMessageSenderSame}
                                    isMessenger
                                />
                            );
                        } else {
                            chatMessage = (
                                <ChatMessage
                                    key={`chat-${caseId}-message-${messageIndex}`}
                                    item={message}
                                    userId={userId}
                                    caseId={Number(caseId)}
                                    previousMessageSenderSame={previousMessageSenderSame}
                                    text={message.text}
                                    isMessenger
                                />
                            );
                        }

                        if (messageIndex === 0 || message.createdDate !== messages[messageIndex - 1].createdDate)
                            chatMessage = (
                                <>
                                    <Typography
                                        align="center"
                                        sx={{
                                            m: 2,
                                            fontWeight: 600,
                                            color: theme.palette.primary.contrastText,
                                        }}
                                    >
                                        {getDateLabel(t, message.createdDate)}
                                    </Typography>
                                    {chatMessage}
                                </>
                            );

                        if (
                            senderId !== userId &&
                            !firstUnreadMessageId &&
                            (!message.readAt || new Date().getTime() - new Date(message.readAt).getTime() < 300000)
                        ) {
                            firstUnreadMessageId = message.uuid;
                            chatMessage = (
                                <>
                                    <Box ref={messagesEndRef} sx={{height: 1}} />
                                    {chatMessage}
                                </>
                            );
                        }

                        return chatMessage;
                    })}
                </Box>
                {showScrollToBottomButton && (
                    <ScrollChatToBottom notReadMessages={notReadMessagesCounter} onClickAction={scrollToBottom} />
                )}
            </Box>
        </Box>
    );
};

export default MessagesList;
