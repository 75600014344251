import React, {FC, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {styled} from '@mui/material/styles';

import useParsedTranslation from 'hooks/useParsedTranslation';

import {PARAMETER_SORT_BY, PARAMETER_SORT_TYPE, SORTING_ASC, SORTING_DESC} from 'config/index';
import {MAIN_CLIENT_COLOR} from 'config/theme';

interface TypographyType {
    text: string | number;
    parameter?: string;
    isSortable?: boolean;
}

export const getSortingButton = (parameter: string, isSortable: boolean) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const sortByParameter = searchParams.get(PARAMETER_SORT_BY);
    const sortTypeParameter = searchParams.get(PARAMETER_SORT_TYPE);

    const sortListBy = (type: string) => {
        searchParams.set(PARAMETER_SORT_BY, parameter);
        searchParams.set(PARAMETER_SORT_TYPE, type);
        setSearchParams(searchParams);
    };

    if (isSortable && (!sortTypeParameter || parameter !== sortByParameter)) {
        return <SwapVertIcon sx={{ml: 1, cursor: 'pointer'}} onClick={() => sortListBy(SORTING_ASC)} />;
    }
    if (isSortable && sortTypeParameter === SORTING_ASC) {
        return <KeyboardArrowUpIcon sx={{ml: 1, cursor: 'pointer'}} onClick={() => sortListBy(SORTING_DESC)} />;
    }
    if (isSortable && sortTypeParameter === SORTING_DESC) {
        return <KeyboardArrowDownIcon sx={{ml: 1, cursor: 'pointer'}} onClick={() => sortListBy('')} />;
    }
    return null;
};

export const TableHeadTypography: FC<TypographyType> = ({text, parameter, isSortable}) => {
    const [t] = useTranslation();
    return (
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Typography variant="body2" sx={{fontWeight: 600, lineHeight: '17.6px'}}>
                {t(String(text))}
            </Typography>
            {parameter && isSortable && getSortingButton(parameter, isSortable)}
        </Box>
    );
};

export const TableBodyTypography: FC<TypographyType> = ({text}) => {
    const [t] = useTranslation();
    return (
        <Typography align="justify" variant="body2" sx={{lineHeight: '17.6px', fontWeight: 300, mr: 1}}>
            {useParsedTranslation(t, String(text))}
        </Typography>
    );
};

interface TableCellWithoutBorderType {
    children: ReactNode;
    isRight?: boolean;
    align?: string;
    width?: number | string;
}

export const TableCellWithoutBorder: FC<TableCellWithoutBorderType> = ({children, width, align, isRight}) => {
    return (
        <TableCell
            sx={{
                borderBottom: 'none',
                pb: 0,
                pr: isRight ? 0 : 'initial',
                width: width || 'initial',
            }}
            align={align || isRight ? 'right' : 'left'}
        >
            {children}
        </TableCell>
    );
};

export const CustomSwitcher = styled(Switch)(({theme}) => ({
    width: 28,
    height: 16,
    marginRight: 8,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: theme.palette.background.paper,
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: MAIN_CLIENT_COLOR,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

export const CustomFeatureIcon = styled(Box)(() => ({
    height: 20,
    width: 20,
    marginRight: 8,
    '& svg': {
        width: 20,
        height: 20,
    },
}));
