import {createTheme, ThemeOptions} from '@mui/material/styles';

export const MAIN_CLIENT_COLOR = '#07D889';
export const MAIN_ADMIN_COLOR = '#A3D86F';
export const MAIN_AGENT_COLOR = '#A1869B';

export const CLIENT_BACKGROUND_COLOR = '#f1f0ee';
export const ADMIN_LIGHT_GRAY_COLOR = '#eee';

export const ERROR_TEXT_COLOR = '#CC4941';

export const HIGHLIGHTED_SECTION_COLOR = '#fce17e';

export const FONT_FAMILY_OPEN_SANS = 'OpenSans';

export const TAG_COLOR_1 = '#B8B5B2';
export const TAG_COLOR_2 = '#9B7B6D';
export const TAG_COLOR_3 = '#A1869B';
export const TAG_COLOR_4 = '#6692B0';
export const TAG_COLOR_5 = '#205072';
export const TAG_COLOR_6 = '#329D9C';
export const TAG_COLOR_7 = '#6F927D';
export const TAG_COLOR_8 = '#6EC181';
export const TAG_COLOR_9 = '#CC4941';

export const tagColors = [
    TAG_COLOR_1,
    TAG_COLOR_2,
    TAG_COLOR_3,
    TAG_COLOR_4,
    TAG_COLOR_5,
    TAG_COLOR_6,
    TAG_COLOR_7,
    TAG_COLOR_8,
    TAG_COLOR_9,
];

export const randomColors = [
    '#8633d0',
    '#039c4b',
    '#66d313',
    '#fedf17',
    '#ff0984',
    '#21409a',
    '#04adff',
    '#e48873',
    '#f16623',
    '#f44546',
    '#30ff9c',
    '#030213',
    '#8587a8',
    '#604523',
    '#30ff9c',
    '#542b54',
];

export const theme = createTheme({
    palette: {
        primary: {
            main: '#458AD5',
            light: '#3F47AA',
            dark: '#1472B9',
            contrastText: '#808181',
        },
        warning: {
            main: '#A3D86F',
            light: '#D9D9D9',
            dark: 'rgba(163, 216, 111, 0.23)',
            contrastText: '#F1F0EE',
        },
        info: {
            main: '#222222',
            light: '#808181',
            dark: '#9A9999',
            contrastText: '#B4B4B4',
        },
        secondary: {
            main: '#3D3BA1',
            light: '#242647',
            dark: '#E9F6D8',
            contrastText: '#CFCFCF',
        },
        background: {
            default: '#ffffff',
            paper: '#ffffff',
        },
    },
    typography: {
        fontSize: 14,
        fontFamily: FONT_FAMILY_OPEN_SANS,
    },
} as ThemeOptions);
