import React, {FC, useContext} from 'react';
import {useNavigate} from 'react-router-dom';

import Typography from '@mui/material/Typography';

import {RoleTypes} from 'appRedux/actions/roles/types';

import {PermissionContext} from 'contexts/permission/context';

import {routes} from 'config/index';

interface UserRoleType {
    role: RoleTypes;
}

const UserRole: FC<UserRoleType> = ({role}) => {
    const navigate = useNavigate();

    const {isRoleViewEnabled} = useContext(PermissionContext);

    const {title, uuid} = role;

    const redirectToRole = () => {
        if (isRoleViewEnabled) {
            navigate(`${routes.ROLE}/${uuid}/edit`);
        }
    };

    return (
        <Typography
            sx={{mb: 1, cursor: isRoleViewEnabled ? 'pointer' : 'initial', fontWeight: 700}}
            onClick={redirectToRole}
        >
            {title}
        </Typography>
    );
};

export default UserRole;
