import React, {FC} from 'react';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import {FormPopupTypes, FormFieldTypes} from 'appRedux/actions/forms/types';
import {ClientTranslationUpdatedType} from 'appRedux/actions/clientTranslation/types';

import {ContentItemType} from 'pages/client/form/partials/types';
import {isSelectionFields} from 'pages/admin/updateForm/partials/FormStructure/helper';
import PopupInformationItemRow from 'pages/agent/requesterPage/sections/PopupInformationItemRow';
import PopupInformationSelectableItemRow from 'pages/agent/requesterPage/sections/PopupInformationSelectableItemRow';

import {getFieldKeyword} from 'helpers/translationsHelper';

import {EMPTY_DATA} from 'config/index';

interface PopupInformationItemType {
    formId: number;
    sectionId: number;
    popup: FormPopupTypes;
    item: ContentItemType;
    showTranslation: boolean;
    editTranslation: boolean;
    clientTranslations?: ClientTranslationUpdatedType;
    setClientTranslations?: (value: ClientTranslationUpdatedType) => void;
    popupItem: number;
}

const PopupInformationItem: FC<PopupInformationItemType> = ({
    formId,
    popup,
    item,
    sectionId,
    clientTranslations,
    setClientTranslations,
    showTranslation,
    editTranslation,
    popupItem,
}) => {
    const {fields, title} = popup;

    return (
        <Box sx={{width: '100%', p: 1}}>
            {fields &&
                fields.map((field: FormFieldTypes, index: number) => {
                    const keyword = getFieldKeyword(formId, field.id, 'label');
                    if (isSelectionFields(field.type)) {
                        return (
                            <PopupInformationSelectableItemRow
                                key={`popup-${title}-field-${index}`}
                                formId={formId}
                                field={field}
                                keyword={keyword}
                            />
                        );
                    }
                    const value = get(item, `field-${field.id}`, EMPTY_DATA);
                    return (
                        <PopupInformationItemRow
                            key={`popup-${title}-field-${index}`}
                            keyword={keyword}
                            value={value}
                            field={field}
                            sectionId={sectionId}
                            clientTranslations={clientTranslations}
                            setClientTranslations={setClientTranslations}
                            showTranslation={showTranslation}
                            editTranslation={editTranslation}
                            popupItem={popupItem}
                        />
                    );
                })}
            <Divider sx={{mt: 1, mb: 1}} />
        </Box>
    );
};

export default PopupInformationItem;
