import * as yup from 'yup';

import {QR_CODE_VERIFICATION_MIN_LENGTH, QR_CODE_VERIFICATION_MAX_LENGTH} from 'config/index';

export const validationSchema = yup.object({
    code: yup
        .string()
        .min(
            QR_CODE_VERIFICATION_MIN_LENGTH,
            `Authentication code consists of ${QR_CODE_VERIFICATION_MIN_LENGTH} symbols minimal`,
        )
        .max(
            QR_CODE_VERIFICATION_MAX_LENGTH,
            `Authentication code consists of ${QR_CODE_VERIFICATION_MAX_LENGTH} symbols maximal`,
        )
        .required('Authentication code is required'),
});
