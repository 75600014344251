import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import AttachSvgIcon from 'assets/icons/buttons/AttachSvgIcon';

import {OrganizationIntegrationTokenTypes} from 'appRedux/actions/organizationIntegrationTokens/types';

import {AlertContext} from 'contexts/alert/context';

import {ALERT_TYPE_SUCCESS} from 'config/index';
import {theme} from 'config/theme';

interface CopyTokenButtonType {
    item: OrganizationIntegrationTokenTypes;
}

const CopyTokenButton: FC<CopyTokenButtonType> = ({item}) => {
    const [t] = useTranslation();

    const {showAlert} = useContext(AlertContext);

    const {token} = item;

    const onClickCopyToBuffer = () => {
        if (token) {
            navigator.clipboard.writeText(token);
            showAlert(ALERT_TYPE_SUCCESS, t('messages.success.linkCopiedToBuffer'));
        }
    };

    return (
        <IconButton
            sx={{
                backgroundColor: theme.palette.info.main,
                '&:hover': {
                    backgroundColor: theme.palette.info.main,
                },
            }}
            title={t('common.buttons.copyToBuffer')}
            onClick={onClickCopyToBuffer}
        >
            <AttachSvgIcon isActive />
        </IconButton>
    );
};

export default CopyTokenButton;
