import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import CreateFaqForm from 'components/Forms/FormBuilder/FaqForm/CreateFaqForm';

interface AddFormFaqButtonType {
    formId: number;
    languageId: number;
}

const AddFormFaqButton: FC<AddFormFaqButtonType> = ({formId, languageId}) => {
    const [t] = useTranslation();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    return (
        <Box sx={{mt: 2}}>
            <AgentSaveButton
                title={t('orguser.forms.faq.addFaq')}
                isSubmit={false}
                onClick={() => setShowModal(true)}
            />
            <ModalWrapper isShowModal={showModal} title={t('orguser.forms.faq.addFaq')} toggleModal={toggleModal}>
                <CreateFaqForm formId={formId} languageId={languageId} closeModal={() => setShowModal(false)} />
            </ModalWrapper>
        </Box>
    );
};

export default AddFormFaqButton;
