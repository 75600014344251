import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {RootReducer} from 'appRedux/reducers';
import {CREATE_ORGANIZATION} from 'appRedux/actions/organization';
import {CreateOrganizationRequestTypes} from 'appRedux/actions/organization/types';

import {AlertContext} from 'contexts/alert/context';

import OrganizationForm from 'components/Forms/OrganizationForm/OrganizationForm';
import {createOrganizationInitialValues} from 'components/Forms/OrganizationForm/validation';
import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

import {routes} from 'config/index';

const CreateOrganizationComponent: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {showAlert} = useContext(AlertContext);

    const {
        admin: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const submitCreateOrganization = useCallback(
        data => dispatch({type: CREATE_ORGANIZATION.REQUEST, payload: data}),
        [dispatch],
    );

    const redirectToUpdate = (id: number) => {
        navigate(`${routes.UPDATE_ORGANIZATION}/${id}`);
    };

    const onSubmitClicked = (values: CreateOrganizationRequestTypes) => {
        submitCreateOrganization({
            ...values,
            callback: redirectToUpdate,
            showAlert,
        });
    };
    return (
        <ClientSectionWrapper title={t('superadmin.organizations.configuration')}>
            <OrganizationForm
                initialValues={createOrganizationInitialValues}
                onSubmitClicked={onSubmitClicked}
                isLoading={isLoading}
                isCreate={true}
            />
        </ClientSectionWrapper>
    );
};

export default CreateOrganizationComponent;
