import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {SubFormRequestTypes} from 'appRedux/actions/requestCase/types';
import {DELETE_SUBFORM_REQUEST} from 'appRedux/actions/requestCase';
import {FORM_BY_CASE_INFORMATION} from 'appRedux/actions/forms';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationButton from 'components/AdminScreenComponents/DeleteAdminConfirmationButton';

interface DeleteSubFormRequestButtonType {
    item: SubFormRequestTypes;
}

const DeleteSubFormRequestButton: FC<DeleteSubFormRequestButtonType> = ({item}) => {
    const dispatch = useDispatch();
    const {caseId} = useParams();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    const {showAlert} = useContext(AlertContext);

    const {uuid} = item;

    const deleteSubFormRequest = useCallback(
        data => dispatch({type: DELETE_SUBFORM_REQUEST.REQUEST, payload: data}),
        [dispatch],
    );

    const getFormByCaseInformation = useCallback(
        data => dispatch({type: FORM_BY_CASE_INFORMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        deleteSubFormRequest({
            uuid,
            showAlert,
            callback: () => {
                getFormByCaseInformation({
                    id: caseId,
                    showAlert,
                });
            },
        });
    };

    return (
        <DeleteAdminConfirmationButton
            disabled={false}
            isShowModal={showModal}
            toggleModal={toggleModal}
            onClickAction={onClickDelete}
        />
    );
};

export default DeleteSubFormRequestButton;
