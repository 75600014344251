import React, {FC, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {GridColDef} from '@mui/x-data-grid';

import {ORGANIZATION_LIST} from 'appRedux/actions/organization';
import {RootReducer} from 'appRedux/reducers';
import {OrganizationItemBasic} from 'appRedux/actions/organization/types';

import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';

import getOrganizationStatisticColumns from 'pages/admin/dashboard/organizationStatistic/getOrganizationStatisticColumns';

const OrganizationStatisticDataGrid: FC = () => {
    const dispatch = useDispatch();

    const {
        admin: {organizationList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const organizations: OrganizationItemBasic[] = organizationList ?? [];

    const getOrganizationList = useCallback(() => dispatch({type: ORGANIZATION_LIST.REQUEST}), [dispatch]);

    useEffect(() => {
        getOrganizationList();
    }, []);

    const columns: GridColDef[] = getOrganizationStatisticColumns();

    return <CustomDataGrid rows={organizations} columns={columns} getRowId={row => row.id} checkboxSelection={false} />;
};

export default OrganizationStatisticDataGrid;
