import React, {FC} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import DatePicker from 'react-datepicker';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import {createStyles, withStyles, WithStyles} from '@material-ui/core';

import {ResourceFieldExceptionFormTypes} from 'appRedux/actions/resourceFields/types';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationExceptionSchema} from 'components/Forms/ResourceField/validation';
import {
    resourceFieldCalendarExceptionTypes,
    ResourceFieldOptionType,
    CALENDAR_EXCEPTION_CHANGED_TIMES,
} from 'components/Forms/ResourceField/helper';

import {DATEPICKER_FORMAT} from 'config/index';

interface ResourceFieldExceptionFormType {
    initialValues: ResourceFieldExceptionFormTypes;
    date: Date;
    setDate: (value: Date) => void;
    onSubmitClicked: (values: ResourceFieldExceptionFormTypes) => void;
}

const styles = () =>
    createStyles({
        datepicker: {
            fontSize: 16,
            backgroundColor: '#ffffff',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderBottomColor: '#c4c4c4',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
            padding: 0,
            width: '100%',
            height: 42,
            '&:hover': {
                borderColor: '#222222',
                borderWidth: 1.5,
            },
            '&:focus': {
                outline: 'none',
                borderColor: '#222222',
                borderWidth: 2,
            },
        },
    });

const ResourceFieldExceptionForm: FC<ResourceFieldExceptionFormType & WithStyles<typeof styles>> = ({
    classes,
    date,
    setDate,
    initialValues,
    onSubmitClicked,
}) => {
    const [t] = useTranslation();

    const handleChange = (value: Date): void => {
        setDate(value);
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationExceptionSchema}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<ResourceFieldExceptionFormTypes>) => {
                return (
                    <Form>
                        <DatePicker
                            className={classes.datepicker}
                            selected={date}
                            onChange={handleChange}
                            dateFormat={DATEPICKER_FORMAT}
                            showYearDropdown
                        />
                        <FormControl sx={{mt: 2, mb: 1}} required fullWidth>
                            <InputLabel>{t('orguser.resourceFields.type')}</InputLabel>
                            <Select
                                label={t('orguser.resourceFields.type')}
                                name={'type'}
                                value={formik.values.type}
                                onChange={formik.handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                fullWidth
                            >
                                {resourceFieldCalendarExceptionTypes &&
                                    resourceFieldCalendarExceptionTypes.map((item: ResourceFieldOptionType) => {
                                        const {label, value} = item;
                                        return (
                                            <MenuItem key={`exception-${value}`} value={value}>
                                                <Typography sx={{fontWeight: 600}}>{t(label)}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        {formik.values.type === CALENDAR_EXCEPTION_CHANGED_TIMES && (
                            <>
                                <Field
                                    name="startTime"
                                    type="time"
                                    placeholder={t('orguser.resourceFields.startTime')}
                                    component={FormikTextInput}
                                />
                                <Field
                                    name="endTime"
                                    type="time"
                                    placeholder={t('orguser.resourceFields.endTime')}
                                    component={FormikTextInput}
                                />
                            </>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 3,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default withStyles(styles)(ResourceFieldExceptionForm);
