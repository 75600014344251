import parse from 'html-react-parser';
import {TFunction} from 'i18next';

import {replaceUmlauts} from 'helpers/translationsHelper';

const useParsedTranslation = (t: TFunction, keyword: string) => {
    return parse(replaceUmlauts(t(keyword)));
};

export default useParsedTranslation;
