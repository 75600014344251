import {call, fork, put, take} from 'redux-saga/effects';

import {
    FORM_VERSION_CARD_CREATE,
    FORM_VERSION_CARD_DELETE,
    deleteFormVersionCard,
    createFormVersionCard,
    FORM_VERSION_CARD_GET,
    getFormVersionCard,
    FORM_VERSION_CARDS_GET_ALL,
    getAllFormVersionCards,
    createFormVersionCardField,
    FORM_VERSION_CARD_FIELD_CREATE,
    FORM_VERSION_CARD_FIELD_UPDATE,
    FORM_VERSION_CARD_FIELD_DELETE,
    updateFormVersionCardField,
    deleteFormVersionCardField,
    FORM_VERSION_CARD_UPDATE,
    updateFormVersionCard,
} from 'appRedux/actions/forms';
import {
    AllFormVersionCardResponseType,
    FormVersionCardCreateType,
    FormVersionCardFieldCreateType,
    FormVersionCardRequestType,
    FormVersionCardResponseType,
} from 'appRedux/actions/forms/types';

import {http} from 'services/http';

import {ALERT_TYPE_SUCCESS, ALERT_TYPE_ERROR} from 'config/index';
import {CommonRequestType} from 'appRedux/actions/profile/types';

function* watchFormVersionCardCreation() {
    while (true) {
        const {
            payload: {uuid, values, showAlert, callback},
        }: IActionType<FormVersionCardCreateType> = yield take(FORM_VERSION_CARD_CREATE.REQUEST);
        try {
            const data: FormVersionCardResponseType = yield call(http, `version/card/${uuid}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success && data.result) {
                callback && callback();
                yield put(createFormVersionCard.success(data.result));
            } else {
                yield put(createFormVersionCard.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createFormVersionCard.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormVersionCardUpdating() {
    while (true) {
        const {
            payload: {uuid, values, showAlert, callback},
        }: IActionType<FormVersionCardCreateType> = yield take(FORM_VERSION_CARD_UPDATE.REQUEST);
        try {
            const data: FormVersionCardResponseType = yield call(http, `version/card/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success && data.result) {
                callback && callback();
                yield put(updateFormVersionCard.success(data.result));
            } else {
                yield put(updateFormVersionCard.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateFormVersionCard.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormVersionCardDeleting() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback},
        }: IActionType<FormVersionCardRequestType> = yield take(FORM_VERSION_CARD_DELETE.REQUEST);
        try {
            const data: FormVersionCardResponseType = yield call(http, `version/card/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                callback && callback();
                yield put(deleteFormVersionCard.success());
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteFormVersionCard.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteFormVersionCard.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormVersionCardGetting() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback},
        }: IActionType<FormVersionCardRequestType> = yield take(FORM_VERSION_CARD_GET.REQUEST);
        try {
            const data: FormVersionCardResponseType = yield call(http, `version/card/${uuid}/info`, {
                method: 'GET',
            });
            if (data.success) {
                callback && callback();
                yield put(getFormVersionCard.success(data.result));
            } else {
                yield put(getFormVersionCard.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getFormVersionCard.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchAllFormVersionCardsGetting() {
    while (true) {
        const {
            payload: {showAlert},
        }: IActionType<CommonRequestType> = yield take(FORM_VERSION_CARDS_GET_ALL.REQUEST);
        try {
            const data: AllFormVersionCardResponseType = yield call(http, `organization/cards/list`, {
                method: 'GET',
            });
            if (data.success && data.results) {
                yield put(getAllFormVersionCards.success(data.results));
            } else {
                yield put(getAllFormVersionCards.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getAllFormVersionCards.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormVersionCardFieldCreating() {
    while (true) {
        const {
            payload: {uuid, values, showAlert, callback},
        }: IActionType<FormVersionCardFieldCreateType> = yield take(FORM_VERSION_CARD_FIELD_CREATE.REQUEST);
        try {
            const data: FormVersionCardResponseType = yield call(http, `version/card/field/${uuid}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success && data.result) {
                callback && callback();
                yield put(createFormVersionCardField.success(data.result));
            } else {
                yield put(createFormVersionCardField.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createFormVersionCardField.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormVersionCardFieldUpdating() {
    while (true) {
        const {
            payload: {uuid, values, showAlert, callback},
        }: IActionType<FormVersionCardFieldCreateType> = yield take(FORM_VERSION_CARD_FIELD_UPDATE.REQUEST);
        try {
            const data: FormVersionCardResponseType = yield call(http, `version/card/field/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success && data.result) {
                callback && callback();
                yield put(updateFormVersionCardField.success(data.result));
            } else {
                yield put(updateFormVersionCardField.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateFormVersionCardField.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormVersionCardFieldDeleting() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback},
        }: IActionType<FormVersionCardRequestType> = yield take(FORM_VERSION_CARD_FIELD_DELETE.REQUEST);
        try {
            const data: FormVersionCardResponseType = yield call(http, `version/card/field/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success && data.result) {
                callback && callback();
                yield put(deleteFormVersionCardField.success(data.result));
            } else {
                yield put(deleteFormVersionCardField.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteFormVersionCardField.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchFormVersionCardCreation),
    fork(watchFormVersionCardUpdating),
    fork(watchFormVersionCardDeleting),
    fork(watchFormVersionCardGetting),
    fork(watchAllFormVersionCardsGetting),
    fork(watchFormVersionCardFieldCreating),
    fork(watchFormVersionCardFieldUpdating),
    fork(watchFormVersionCardFieldDeleting),
];
