import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {FormikProps} from 'formik';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';

import {FormFieldRequestType} from 'appRedux/actions/forms/types';
import {ResourceFieldItemTypes} from 'appRedux/actions/resourceFields/types';
import {RootReducer} from 'appRedux/reducers';

import {RESOURCE_FIELD_TYPE_SLOT_TICKET, RESOURCE_FIELD_TYPE_BUDGET} from 'components/Forms/ResourceField/helper';
import ResourceFieldSlotElements from 'components/Forms/FormBuilder/FieldForm/partialsResourceFields/ResourceFieldSlotElements';
import ResourceFieldBudgetElements from 'components/Forms/FormBuilder/FieldForm/partialsResourceFields/ResourceFieldBudgetElements';

import {theme} from 'config/theme';

interface ResourceFieldSlotElementsType {
    formik: FormikProps<FormFieldRequestType>;
    selectedResourceField?: ResourceFieldItemTypes;
    getResourceFieldsSubItemsList: (value: number) => void;
}

const ResourceFieldElements: FC<ResourceFieldSlotElementsType> = ({
    formik,
    selectedResourceField,
    getResourceFieldsSubItemsList,
}) => {
    const [t] = useTranslation();

    const {
        resourceFields: {resourceFields, resourceFieldSlotItems, resourceFieldBudgetItems},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const showResourceFieldSlotsList =
        selectedResourceField &&
        selectedResourceField.type === RESOURCE_FIELD_TYPE_SLOT_TICKET &&
        resourceFieldSlotItems.length > 0;

    const showResourceFieldBudgetsList =
        selectedResourceField &&
        selectedResourceField.type === RESOURCE_FIELD_TYPE_BUDGET &&
        resourceFieldBudgetItems.length > 0;

    return (
        <Box>
            <Box>
                <Typography variant="body2" sx={{mb: 1}}>
                    {t('orguser.forms.formField.resourceField')}
                </Typography>
                <FormControl
                    sx={{
                        backgroundColor: theme.palette.warning.contrastText,
                        borderRadius: 3,
                        pl: 3,
                        pr: 3,
                        pb: 2,
                        mb: 1,
                    }}
                    required
                    fullWidth
                >
                    <Select
                        sx={{
                            height: 50,
                        }}
                        name={'resourceField'}
                        value={formik.values.resourceField}
                        variant="standard"
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={e => {
                            formik.handleChange(e);
                            getResourceFieldsSubItemsList(Number(e.target.value));
                        }}
                    >
                        {resourceFields.map((resourceFieldItem: ResourceFieldItemTypes) => {
                            return (
                                <MenuItem key={`resourceField-${resourceFieldItem.uuid}`} value={resourceFieldItem.id}>
                                    <Typography>{resourceFieldItem.title}</Typography>
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>
            {showResourceFieldSlotsList && <ResourceFieldSlotElements formik={formik} />}
            {showResourceFieldBudgetsList && <ResourceFieldBudgetElements formik={formik} />}
        </Box>
    );
};

export default ResourceFieldElements;
