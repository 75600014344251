import React, {FC} from 'react';

import Box from '@mui/material/Box';

interface EmptyAreaType {
    onClick?: () => void;
}

const EmptyArea: FC<EmptyAreaType> = ({onClick}) => {
    const styles = onClick
        ? {
              flexGrow: 1,
              width: 'auto',
              height: '100%',
          }
        : {
              flexGrow: 1,
          };

    return <Box sx={styles} onClick={onClick}></Box>;
};

export default EmptyArea;
