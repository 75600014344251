import React, {FC, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import PagePendingSvgIcon from 'assets/icons/small/PagePendingSvgIcon';
import PageCheckedSvgIcon from 'assets/icons/small/PageCheckedSvgIcon';

import {RequesterAllCaseItemTypes, RequestCasePageType} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import {isPageChecked} from 'pages/client/requests/helper';

import {RouteContext} from 'contexts/route/context';

import {getPageKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {routes, STATUS_OPENING} from 'config/index';
import {useSelector} from 'react-redux';

interface PagesListItemType {
    index: number;
    item: RequesterAllCaseItemTypes;
    page: RequestCasePageType;
}

const PagesListItem: FC<PagesListItemType> = ({item, page, index}) => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const {setFormPage} = useContext(RouteContext);

    const {form, requestCase, checkedSections} = item;
    const {id, versionId, title, isSubFormPage, subFormTitle, sections} = page;

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const redirectToForm = (pageId: number) => {
        if (requestCase && requestCase.status !== STATUS_OPENING) {
            setFormPage(pageId + 1);
        }
        navigate(`${routes.REQUEST}/${requestCase.id}/form/${form.id}`);
    };

    const pageKeyword = getPageKeyword(form.id, id, versionId, 'title');
    const translatedTitle = getFormTranslatedLabel(translations, pageKeyword, title);

    return (
        <Box
            data-id={`button#requests-case-${requestCase.id}-page-${index}`}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                cursor: 'pointer',
                mt: 2,
                mb: 2,
            }}
            onClick={() => redirectToForm(index)}
        >
            {isPageChecked(sections, checkedSections) ? <PageCheckedSvgIcon /> : <PagePendingSvgIcon />}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    pl: 1,
                }}
            >
                <Typography>{translatedTitle}</Typography>
                {isSubFormPage && (
                    <Typography sx={{fontSize: 12, fontWeight: 700}}>{`(${t(
                        'orguser.forms.subForm',
                    )}: ${subFormTitle})`}</Typography>
                )}
            </Box>
        </Box>
    );
};

export default PagesListItem;
