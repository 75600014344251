import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {View, Text, Page} from '@react-pdf/renderer';

import {pdfStyles} from 'pages/agent/pdfExport/pdfPartials/PdfDocumentStyles';
import {AttachmentComponent} from 'pages/agent/pdfExport/pdfPartials/ImagePdfComponent';
import {PdfDocumentContext} from 'pages/agent/pdfExport/pdfPartials/pdfDocumentContext';

type AttachmentsPagePdfContentType = {
    fileImages: Record<string, string | string[]>;
    printAttachments: Record<string, boolean>;
};

const AttachmentsPagePdfContent: FC<AttachmentsPagePdfContentType> = ({fileImages, printAttachments}) => {
    const [t] = useTranslation();

    const {
        currentCase: {files},
        preview,
    } = useContext(PdfDocumentContext);

    return (
        <>
            {files.map(file => {
                if (!printAttachments[file.uuid]) return <></>;
                const fileImage = fileImages[file.uuid];
                const isImg = typeof fileImage === 'string';

                if (isImg)
                    return (
                        <Page size="A4" style={pdfStyles.attachmentsPage} key={`file-${file.uuid}`}>
                            <View fixed style={pdfStyles.attachmentHeader}>
                                <Text>{`${file.description || t('orguser.requesterCase.attachments')}:`}</Text>
                            </View>
                            <AttachmentComponent src={fileImage} preview={preview} />
                        </Page>
                    );

                return fileImage.map((image, i) => (
                    <Page size="A4" style={pdfStyles.attachmentsPage} key={`file-${file.uuid}-${i}`}>
                        <View fixed style={pdfStyles.template}>
                            <AttachmentComponent src={image} preview={preview} isPdf={true} />
                        </View>
                        <View fixed style={pdfStyles.attachmentHeader}>
                            <Text>{`${file.description || t('orguser.requesterCase.attachments')}:`}</Text>
                        </View>
                    </Page>
                ));
            })}
        </>
    );
};

export default AttachmentsPagePdfContent;
