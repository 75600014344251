import {TFunction} from 'i18next';
import get from 'lodash/get';

import {WorkflowStatusEventItemTypes} from 'appRedux/actions/workflow/types';

export const WORKFLOW_EVENT_EMAIL_NOTIFICATION_CHANGE_TO_STATUS = 1;
export const WORKFLOW_EVENT_CHAT_NOTIFICATION_CHANGE_TO_STATUS = 2;
export const WORKFLOW_EVENT_WEBHOOK_CHANGE_TO_STATUS = 3;
export const WORKFLOW_EVENT_EMAIL_NOTIFICATION_LEAVE_STATUS = 4;
export const WORKFLOW_EVENT_CHAT_NOTIFICATION_LEAVE_STATUS = 5;
export const WORKFLOW_EVENT_WEBHOOK_LEAVE_STATUS = 6;
export const WORKFLOW_EVENT_EMAIL_NOTIFICATION_CHANGE_TO_STATUS_AGENT = 7;
export const WORKFLOW_EVENT_EMAIL_NOTIFICATION_LEAVE_STATUS_AGENT = 8;
export const WORKFLOW_EVENT_EMAIL_NOTIFICATION_CHANGE_TO_STATUS_AGENT_FIXED = 9;
export const WORKFLOW_EVENT_EMAIL_NOTIFICATION_LEAVE_STATUS_AGENT_FIXED = 10;

export const WORKFLOW_ATTACHMENTS_MAXIMAL_NUMBER = 3;

export const getWorkflowEventItem = (
    items: WorkflowStatusEventItemTypes[],
    type: number,
): WorkflowStatusEventItemTypes | undefined => {
    return items.find(item => item.type === type);
};

export const getTableSubjectValue = (
    t: TFunction,
    currentLanguage: string,
    item?: WorkflowStatusEventItemTypes,
    forWebhookTemplate?: boolean,
): string => {
    if (item && item.url && forWebhookTemplate) {
        return item.url;
    } else if (item) {
        const translation = get(item.translations, [currentLanguage, 'subject'], getTranslationAbsentLabel(t));
        return translation;
    }
    return t('common.none');
};

export const getTranslationAbsentLabel = (t: TFunction) => {
    return `(${t('orguser.workflows.workflowTemplates.translationAbsent')})`.toLowerCase();
};
