import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import Markdown from 'react-markdown';

import FormHelperText from '@mui/material/FormHelperText';

import {RootReducer} from 'appRedux/reducers';

import {getFormTranslatedLabel} from 'helpers/translationsHelper';

interface ElementDescriptionType {
    keyword: string;
    description: string;
    previewMode?: boolean;
}

const ElementDescription: FC<ElementDescriptionType> = ({keyword, description, previewMode}) => {
    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <FormHelperText sx={{ml: 0, mt: 1, mb: 1}}>
            <Markdown>
                {previewMode ? description : getFormTranslatedLabel(translations, keyword, description)}
            </Markdown>
        </FormHelperText>
    );
};

export default ElementDescription;
