import React, {FC} from 'react';
import {CommonSvgIconType, getIconColor} from './helper';

const LangSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.6665 6.5H5.5833L5.16675 5.5H2.5L2.0832 6.5H1L3.2916 1H4.375L6.6665 6.5ZM3.83325 2.3L2.91655 4.5H4.75L3.83325 2.3ZM9 5.5H15V6.5H9V5.5ZM15 7.5H9V8.5H15V7.5ZM12 9.5H9V10.5H12V9.5ZM7 10.5V9.5H4.5V8.5H3.5V9.5H1V10.5H5.1074C4.92363 11.2504 4.54087 11.9374 3.99945 12.4886C3.71566 12.1925 3.4724 11.8601 3.27605 11.5H2.1667C2.42087 12.1102 2.78435 12.6688 3.23925 13.1483C2.70322 13.5301 2.11821 13.8379 1.5 14.0635L1.85105 15C2.62041 14.7198 3.34353 14.3261 3.9964 13.8319C4.65176 14.3263 5.37728 14.72 6.14895 15L6.5 14.0635C5.88212 13.8383 5.29734 13.5311 4.7614 13.15C5.46714 12.4202 5.94177 11.4984 6.1258 10.5H7Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default LangSvgIcon;
