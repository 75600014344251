import get from 'lodash/get';

import {WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';

export const getTranslatedWorkflowStatus = (
    workflowStatus: WorkflowStatusItemTypes,
    language: string,
    forRequester?: boolean,
): string => {
    const {title, titleRequester, translations} = workflowStatus;
    const translationForOrgUser = get(translations, [language, 'translation'], null);
    const translationForRequester = get(translations, [language, 'translationRequester'], null);

    if (forRequester && translationForRequester) {
        return translationForRequester;
    }
    if (translationForOrgUser) {
        return translationForOrgUser;
    }
    return forRequester ? titleRequester : title;
};
