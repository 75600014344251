import React, {FC} from 'react';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CircleIcon from '@mui/icons-material/Circle';

import EmptyArea from 'components/EmptyArea/EmptyArea';

import {tagColors, theme} from 'config/theme';

interface CustomItemTooltipType {
    cases: any;
}

const CustomItemTooltip: FC<CustomItemTooltipType> = ({cases, ...info}) => {
    const dataIndex = get(info, 'dataIndex', null);
    if (!dataIndex && dataIndex !== 0) {
        return null;
    }

    const currentField = get(cases, dataIndex, null);
    if (!currentField) return null;

    const title = get(currentField, 'field', null);
    const options = get(currentField, 'options', []);

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.background.default,
                borderRadius: 4,
                p: 2,
                pb: 1,
                pr: 3,
                minWidth: 300,
                boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
            }}
        >
            <Typography sx={{fontWeight: 700}}>{title}</Typography>
            <Divider sx={{mt: 2, mb: 2}} />
            {options &&
                options.map((optionItem: any, index: number) => {
                    const option = get(optionItem, 'option', '');
                    const qty = get(optionItem, 'qty', 0);
                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mb: 1,
                            }}
                            key={`option-${dataIndex}-option-${index}`}
                        >
                            <CircleIcon sx={{color: tagColors[index]}} />
                            <Typography sx={{ml: 1}}>{option}</Typography>
                            <EmptyArea />
                            <Typography>{qty}</Typography>
                        </Box>
                    );
                })}
        </Box>
    );
};

export default CustomItemTooltip;
