import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';

import {UserGroupTypes} from 'appRedux/actions/groups/types';
import {GROUP_DETACH_USER} from 'appRedux/actions/groups';

import {AlertContext} from 'contexts/alert/context';

import AgentChipButton from 'components/AgentScreenComponents/_buttons/AgentChipButton';

interface GroupUserButtonType {
    user: UserGroupTypes;
}

const GroupUserButton: FC<GroupUserButtonType> = ({user}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const detachUserFromGroup = useCallback(
        data => dispatch({type: GROUP_DETACH_USER.REQUEST, payload: data}),
        [dispatch],
    );

    const {avatarType, avatarLink, firstName, lastName, email, uuid} = user;

    const onRemoveClick = () => {
        detachUserFromGroup({
            uuid,
            showAlert,
            callback: () => setIsOpened(false),
        });
    };

    return (
        <AgentChipButton
            isOpen={isOpened}
            setIsOpen={setIsOpened}
            toggleModal={toggleIsOpened}
            avatarType={avatarType}
            avatarLink={avatarLink}
            firstName={firstName}
            lastName={lastName}
            email={email}
            onRemoveClick={onRemoveClick}
        />
    );
};

export default GroupUserButton;
