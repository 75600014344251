import {OrganizationTranslationTypes} from 'appRedux/actions/organizationLanguage/types';

import {ORGANIZATION_MEDIA_NAVBAR_LOGO, ORGANIZATION_MEDIA_FAVICON} from 'config/index';

export const getLogoFormWidth = (logoSource: string | null, type: number): string => {
    if (logoSource && ORGANIZATION_MEDIA_NAVBAR_LOGO === type) {
        return '50%';
    }
    if (logoSource && ORGANIZATION_MEDIA_FAVICON === type) {
        return '25%';
    }
    return '100%';
};

export const getTranslationByKeyword = (
    filteredTranslations: OrganizationTranslationTypes[],
    keyword: string,
    defaultValue?: string,
): string => {
    const translation = filteredTranslations.find(item => item.keyword === keyword);
    if (translation) return translation.trans;
    return defaultValue ? defaultValue : '';
};
