import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const WordPressSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg viewBox="0 0 32 32" width="24px" height="24px">
            <path
                fill={getIconColor(isActive, isDisabled)}
                d="M 16 4 C 9.382813 4 4 9.382813 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 9.382813 22.617188 4 16 4 Z M 16 4.5625 C 22.3125 4.5625 27.4375 9.6875 27.4375 16 C 27.4375 22.3125 22.3125 27.4375 16 27.4375 C 9.6875 27.4375 4.5625 22.3125 4.5625 16 C 4.5625 9.6875 9.6875 4.5625 16 4.5625 Z M 16 5.71875 C 12.402344 5.71875 9.246094 7.546875 7.40625 10.34375 C 7.648438 10.351563 7.867188 10.34375 8.0625 10.34375 C 9.140625 10.34375 10.8125 10.21875 10.8125 10.21875 C 11.367188 10.1875 11.429688 10.996094 10.875 11.0625 C 10.875 11.0625 10.308594 11.15625 9.6875 11.1875 L 13.4375 22.3125 L 15.6875 15.5625 L 14.09375 11.1875 C 13.539063 11.15625 13 11.0625 13 11.0625 C 12.445313 11.03125 12.507813 10.1875 13.0625 10.21875 C 13.0625 10.21875 14.769531 10.34375 15.78125 10.34375 C 16.859375 10.34375 18.53125 10.21875 18.53125 10.21875 C 19.085938 10.1875 19.148438 10.996094 18.59375 11.0625 C 18.59375 11.0625 18.023438 11.15625 17.40625 11.1875 L 21.125 22.25 L 22.15625 18.8125 C 22.675781 17.476563 22.9375 16.351563 22.9375 15.46875 C 22.9375 14.195313 22.488281 13.339844 22.09375 12.65625 C 21.574219 11.808594 21.09375 11.066406 21.09375 10.21875 C 21.09375 9.273438 21.800781 8.40625 22.8125 8.40625 C 22.859375 8.40625 22.890625 8.40625 22.9375 8.40625 C 21.105469 6.726563 18.679688 5.71875 16 5.71875 Z M 25.03125 11.0625 C 25.078125 11.390625 25.09375 11.746094 25.09375 12.125 C 25.09375 13.167969 24.898438 14.34375 24.3125 15.8125 L 21.1875 24.90625 C 24.25 23.121094 26.28125 19.796875 26.28125 16 C 26.28125 14.210938 25.835938 12.527344 25.03125 11.0625 Z M 6.59375 11.8125 C 6.023438 13.089844 5.71875 14.507813 5.71875 16 C 5.71875 20.074219 8.066406 23.582031 11.5 25.25 Z M 16.1875 16.90625 L 13.09375 25.875 C 14.015625 26.144531 14.988281 26.28125 16 26.28125 C 17.199219 26.28125 18.335938 26.09375 19.40625 25.71875 C 19.378906 25.675781 19.363281 25.613281 19.34375 25.5625 Z"
            />
        </svg>
    );
};

export default WordPressSvgIcon;
