import * as yup from 'yup';

import {htmlTagsPresented, getUsernameLengthErrorMessage} from 'helpers/validation';

import {MAXIMAL_FIRSTNAME_LENGTH, MAXIMAL_LASTNAME_LENGTH} from 'config/index';

export const validationSchema = (t: (trans: string) => string) =>
    yup.object({
        firstName: yup
            .string()
            .required(t('messages.validation.isRequired'))
            .test('unwanted', t('messages.validation.htmlTagsDenied'), value => htmlTagsPresented(value))
            .max(
                MAXIMAL_FIRSTNAME_LENGTH,
                getUsernameLengthErrorMessage(t, 'messages.validation.firstNameMaxLength', MAXIMAL_FIRSTNAME_LENGTH),
            ),
        lastName: yup
            .string()
            .required(t('messages.validation.isRequired'))
            .test('unwanted', t('messages.validation.htmlTagsDenied'), value => htmlTagsPresented(value))
            .max(
                MAXIMAL_LASTNAME_LENGTH,
                getUsernameLengthErrorMessage(t, 'messages.validation.firstNameMaxLength', MAXIMAL_FIRSTNAME_LENGTH),
            ),
    });
