import React, {FC} from 'react';

interface MenuIconType {
    isActive: boolean;
}

const ForwardedCasesSvgIcon: FC<MenuIconType> = ({isActive}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.11613 5.88387L6.98225 3.75H2.5V16.25H17.5V6.25H9.48225L9.11613 5.88387ZM2.5 2.5H6.98225C7.31377 2.5 7.63171 2.6317 7.86613 2.86613L10 5H17.5C17.8315 5 18.1495 5.1317 18.3839 5.36612C18.6183 5.60054 18.75 5.91848 18.75 6.25V16.25C18.75 16.5815 18.6183 16.8995 18.3839 17.1339C18.1495 17.3683 17.8315 17.5 17.5 17.5H2.5C2.16848 17.5 1.85054 17.3683 1.61612 17.1339C1.3817 16.8995 1.25 16.5815 1.25 16.25V3.75C1.25 3.41848 1.3817 3.10054 1.61612 2.86612C1.85054 2.6317 2.16848 2.5 2.5 2.5ZM10.3687 9.00625L11.25 8.125L14.375 11.25L11.25 14.375L10.3687 13.4937L11.9812 11.875H6.25V10.625H11.9812L10.3687 9.00625Z"
                fill={isActive ? '#ffffff' : '#222222'}
            />
        </svg>
    );
};

export default ForwardedCasesSvgIcon;
