import React, {FC, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {GET_USER_LOGIN_ATTEMPTS} from 'appRedux/actions/auth';
import {RootReducer} from 'appRedux/reducers';

import TableResponsive from 'components/TableResponsive/TableResponsive';

import {DATE_TIME_FORMAT} from 'config/index';

const UserLoginAttemptsList: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const getUserLoginAttempts = useCallback(() => dispatch({type: GET_USER_LOGIN_ATTEMPTS.REQUEST}), [dispatch]);

    const {
        auth: {loginAttempts},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        getUserLoginAttempts();
    }, []);

    const columns = [
        {title: t('common.account.userAgent'), name: 'userAgent'},
        {title: t('common.account.IP'), name: 'ip'},
        {title: t('common.account.status'), name: 'isSuccess'},
        {title: t('common.account.loginAt'), name: 'loginAt'},
    ];

    const rows = loginAttempts.map(item => ({
        ...item,
        isSuccess: t(item.isSuccess ? 'common.success' : 'common.failure'),
        loginAt: moment(item.loginAt).format(DATE_TIME_FORMAT),
    }));

    return <TableResponsive columns={columns} rows={rows} />;
};

export default UserLoginAttemptsList;
