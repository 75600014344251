import React, {FC, useContext} from 'react';
import {useSelector} from 'react-redux';

import {GridColDef} from '@mui/x-data-grid';

import {RootReducer} from 'appRedux/reducers';

import {PermissionContext} from 'contexts/permission/context';

import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';

import getFormsColumns from 'pages/admin/formsList/getFormsColumns';

const FormsDataGrid: FC = () => {
    const {isFormCreateEnabled} = useContext(PermissionContext);

    const {
        admin: {formsList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const columns: GridColDef[] | null = getFormsColumns();

    if (!formsList || !columns) return null;
    return (
        <CustomDataGrid
            rows={formsList}
            columns={columns}
            getRowId={row => row.id}
            checkboxSelection={isFormCreateEnabled}
            enablePaging
        />
    );
};

export default FormsDataGrid;
