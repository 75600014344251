import {
    ClientTranslationUpdateRequestTypes,
    ClientTranslationUpdateItemRequestTypes,
    ClientTranslationDeleteRequestTypes,
    ClientAutoTranslationRequestType,
    ClientTranslationTypes,
} from 'appRedux/actions/clientTranslation/types';

import {requestTypeCreator} from 'helpers/index';

export const UPDATE_CLIENT_TRANSLATION = requestTypeCreator('UPDATE_CLIENT_TRANSLATION');
export const UPDATE_CLIENT_TRANSLATION_ITEM = requestTypeCreator('UPDATE_CLIENT_TRANSLATION_ITEM');
export const DELETE_CLIENT_TRANSLATION = requestTypeCreator('DELETE_CLIENT_TRANSLATION');
export const AUTO_CLIENT_TRANSLATION = requestTypeCreator('AUTO_CLIENT_TRANSLATION');

export const updateClientTranslations = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_CLIENT_TRANSLATION.ERROR,
    }),
    request: (payload: ClientTranslationUpdateRequestTypes): IActionType<ClientTranslationUpdateRequestTypes> => ({
        payload,
        type: UPDATE_CLIENT_TRANSLATION.REQUEST,
    }),
    success: (payload: ClientTranslationTypes[]): IActionType<ClientTranslationTypes[]> => ({
        payload,
        type: UPDATE_CLIENT_TRANSLATION.SUCCESS,
    }),
};

export const updateClientTranslationItem = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_CLIENT_TRANSLATION_ITEM.ERROR,
    }),
    request: (
        payload: ClientTranslationUpdateItemRequestTypes,
    ): IActionType<ClientTranslationUpdateItemRequestTypes> => ({
        payload,
        type: UPDATE_CLIENT_TRANSLATION_ITEM.REQUEST,
    }),
    success: (payload: ClientTranslationTypes[]): IActionType<ClientTranslationTypes[]> => ({
        payload,
        type: UPDATE_CLIENT_TRANSLATION_ITEM.SUCCESS,
    }),
};

export const deleteClientTranslations = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_CLIENT_TRANSLATION.ERROR,
    }),
    request: (payload: ClientTranslationDeleteRequestTypes): IActionType<ClientTranslationDeleteRequestTypes> => ({
        payload,
        type: DELETE_CLIENT_TRANSLATION.REQUEST,
    }),
    success: (payload: ClientTranslationTypes[]): IActionType<ClientTranslationTypes[]> => ({
        payload,
        type: DELETE_CLIENT_TRANSLATION.SUCCESS,
    }),
};

export const autoClientTranslations = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: AUTO_CLIENT_TRANSLATION.ERROR,
    }),
    request: (payload: ClientAutoTranslationRequestType): IActionType<ClientAutoTranslationRequestType> => ({
        payload,
        type: AUTO_CLIENT_TRANSLATION.REQUEST,
    }),
    success: (payload: ClientTranslationTypes[]): IActionType<ClientTranslationTypes[]> => ({
        payload,
        type: AUTO_CLIENT_TRANSLATION.SUCCESS,
    }),
};
