import React, {FC} from 'react';

interface MenuIconType {
    isActive: boolean;
}

const LogoutSvgIcon: FC<MenuIconType> = ({isActive}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.25 18.75H3.75C3.4186 18.7496 3.10087 18.6178 2.86654 18.3835C2.6322 18.1491 2.50038 17.8314 2.5 17.5V2.5C2.50038 2.1686 2.6322 1.85087 2.86654 1.61654C3.10087 1.3822 3.4186 1.25038 3.75 1.25H11.25C11.5814 1.25038 11.8991 1.3822 12.1335 1.61654C12.3678 1.85087 12.4996 2.1686 12.5 2.5V4.375H11.25V2.5H3.75V17.5H11.25V15.625H12.5V17.5C12.4996 17.8314 12.3678 18.1491 12.1335 18.3835C11.8991 18.6178 11.5814 18.7496 11.25 18.75ZM15.1075 10.625L12.8663 12.8663L13.75 13.75L17.5 10L13.75 6.25L12.8663 7.13375L15.1075 9.375H6.25V10.625H15.1075Z"
                fill={isActive ? 'white' : '#222222'}
            />
        </svg>
    );
};

export default LogoutSvgIcon;
