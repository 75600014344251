import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {GridColDef} from '@mui/x-data-grid';

import {RoleTypes} from 'appRedux/actions/roles/types';
import {RootReducer} from 'appRedux/reducers';

import MarkdownWithShowAll from 'components/TextWithShowAll/MarkdownWithShowAll';

import EditGroupButton from 'pages/admin/groups/EditGroupButton';
import DeleteGroupButton from 'pages/admin/groups/DeleteGroupButton';
import GroupUserButton from 'pages/admin/groups/GroupUserButton';
import AddUserButton from 'pages/admin/groups/AddUserButton';

const getGroupsColumns = (): GridColDef[] | null => {
    const [t] = useTranslation();

    const {admin} = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return [
        {
            field: 'title',
            headerName: t('orguser.groups.title'),
            minWidth: 280,
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{value}</Typography>;
            },
        },
        {
            field: 'description',
            headerName: t('orguser.groups.description'),
            flex: 1,
            renderCell: ({value}) => {
                return <MarkdownWithShowAll text={value} />;
            },
        },
        {
            field: 'users',
            headerName: t('orguser.groups.users'),
            flex: 1,
            renderCell: ({row}) => {
                const {users} = row;
                return (
                    <Box>
                        {users && users.length > 0
                            ? users.map(user => {
                                  return <GroupUserButton key={`group-user-${user.uuid}`} user={user} />;
                              })
                            : t('common.none')}
                        <Box sx={{mt: 1}}>
                            <AddUserButton item={row} />
                        </Box>
                    </Box>
                );
            },
        },
        // {
        //     field: 'roles',
        //     headerName: t('orguser.groups.roles'),
        //     flex: 1,
        //     renderCell: ({row}) => {
        //         const organizationRoles = admin.roles;
        //         const {roles} = row;
        //         return (
        //             <Box>
        //                 {roles.length > 0
        //                     ? organizationRoles.map((role: RoleTypes) => {
        //                           const currentRole = roles.some(itemRole => role.id === itemRole.role);
        //                           if (currentRole) {
        //                               return (
        //                                   <Typography sx={{fontWeight: 700, mb: 1}} key={role.uuid}>
        //                                       {role.title}
        //                                   </Typography>
        //                               );
        //                           }
        //                           return null;
        //                       })
        //                     : t('common.none')}
        //             </Box>
        //         );
        //     },
        // },
        {
            field: 'actions',
            headerName: t('common.action'),
            align: 'right',
            maxWidth: 90,
            flex: 1,
            renderCell: ({row}) => {
                return (
                    <Box>
                        <EditGroupButton item={row} />
                        <DeleteGroupButton item={row} />
                    </Box>
                );
            },
        },
    ];
};

export default getGroupsColumns;
