import {
    INSTANCE_SETTINGS_LIST,
    INSTANCE_SETTING_UPDATE,
    INSTANCE_SETTING_UPLOAD,
    INSTANCE_SETTING_UPLOAD_FAVICON,
    INSTANCE_SETTING_FILES,
    INSTANCE_SETTING_DELETE_FILE,
    INSTANCE_SETTING_DELETE_FAVICON,
} from 'appRedux/actions/instance';

import {InstanceSettingBasic, InstanceSettingsFile} from 'appRedux/actions/instance/types';

export const initialState: InstanceSettingsState = {
    isLoading: false,
    settings: [],
    files: [],
    errors: undefined,
};

export interface InstanceSettingsState {
    isLoading: boolean;
    settings: InstanceSettingBasic[];
    files: InstanceSettingsFile[];
    errors: string[] | undefined;
}

const instance = (
    state = initialState,
    action: IActionType<InstanceSettingBasic[] | InstanceSettingsFile[]>,
): InstanceSettingsState => {
    switch (action.type) {
        case INSTANCE_SETTINGS_LIST.REQUEST:
        case INSTANCE_SETTING_UPDATE.REQUEST:
        case INSTANCE_SETTING_UPLOAD.REQUEST:
        case INSTANCE_SETTING_UPLOAD_FAVICON.REQUEST:
        case INSTANCE_SETTING_DELETE_FAVICON.REQUEST:
        case INSTANCE_SETTING_FILES.REQUEST:
        case INSTANCE_SETTING_DELETE_FILE.REQUEST: {
            return {
                ...state,
                isLoading: true,
                errors: undefined,
            };
        }

        case INSTANCE_SETTINGS_LIST.SUCCESS:
        case INSTANCE_SETTING_UPDATE.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                settings: action.payload as InstanceSettingBasic[],
                errors: undefined,
            };
        }

        case INSTANCE_SETTING_UPLOAD.SUCCESS:
        case INSTANCE_SETTING_UPLOAD_FAVICON.SUCCESS:
        case INSTANCE_SETTING_DELETE_FAVICON.SUCCESS:
        case INSTANCE_SETTING_FILES.SUCCESS:
        case INSTANCE_SETTING_DELETE_FILE.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                files: action.payload as InstanceSettingsFile[],
            };
        }

        case INSTANCE_SETTINGS_LIST.ERROR:
        case INSTANCE_SETTING_UPDATE.ERROR:
        case INSTANCE_SETTING_UPLOAD.ERROR:
        case INSTANCE_SETTING_UPLOAD_FAVICON.ERROR:
        case INSTANCE_SETTING_DELETE_FAVICON.ERROR:
        case INSTANCE_SETTING_FILES.ERROR:
        case INSTANCE_SETTING_DELETE_FILE.ERROR: {
            return {
                ...state,
                isLoading: false,
                errors: action.payload as any,
            };
        }

        default:
            return state;
    }
};

export default instance;
