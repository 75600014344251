import {theme, ERROR_TEXT_COLOR, MAIN_CLIENT_COLOR} from 'config/theme';

export const BUTTON_HEIGHT = 32;
export const BIG_BUTTON_HEIGHT = 40;

const buttonCommonStyles = {
    boxShadow: 'none',
    minWidth: 112,
    height: BUTTON_HEIGHT,
    borderRadius: 16,
    mr: 1,
    mb: 0.5,
    pl: 2,
    pr: 2,
};

export const buttonStyles = {
    ...buttonCommonStyles,
    backgroundColor: theme.palette.info.main,
    '&:hover': {
        backgroundColor: theme.palette.info.main,
    },
};

export const dangerButtonStyles = {
    ...buttonCommonStyles,
    backgroundColor: ERROR_TEXT_COLOR,
    '&:hover': {
        backgroundColor: ERROR_TEXT_COLOR,
    },
};

export const successButtonStyles = {
    ...buttonCommonStyles,
    backgroundColor: MAIN_CLIENT_COLOR,
    '&:hover': {
        backgroundColor: MAIN_CLIENT_COLOR,
    },
};

export const buttonDisabledStyles = {
    ...buttonCommonStyles,
    backgroundColor: theme.palette.warning.contrastText,
    '&:hover': {
        backgroundColor: theme.palette.warning.contrastText,
    },
};

export const buttonOutlineStyles = {
    ...buttonCommonStyles,
    borderColor: theme.palette.info.main,
    borderWidth: 1,
    borderStyle: 'solid',
};

export const buttonTransparentStyles = {
    ...buttonCommonStyles,
    minWidth: 130,
};

export const labelCommonStyles = {
    display: 'block',
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'none',
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
};

export const labelStyles = {
    ...labelCommonStyles,
    color: theme.palette.background.default,
};

export const labelOutlineStyles = {
    ...labelCommonStyles,
    color: theme.palette.info.main,
};
