import React, {FC, useCallback, useContext, useEffect} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

import {RootReducer} from 'appRedux/reducers';
import {BoardItemTypes, UpdateBoardListRequestTypes, WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';
import {GET_WORKFLOWS_LIST, UPDATE_BOARD_LIST_STATUS} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationSchemaForStatusList} from 'components/Forms/BoardForm/validation';

interface EditBoardListStatusFormType {
    item: BoardItemTypes;
}

const EditBoardListStatusForm: FC<EditBoardListStatusFormType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {statuses, uuid, workflowId} = item;

    const {showAlert} = useContext(AlertContext);

    const {
        admin: {organizationList},
        workflow: {isLoading, organizationWorkflows},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const updateBoardListStatus = useCallback(
        data => dispatch({type: UPDATE_BOARD_LIST_STATUS.REQUEST, payload: data}),
        [dispatch],
    );

    const getWorkflowsList = useCallback(
        data => dispatch({type: GET_WORKFLOWS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        if (myOrganization) {
            getWorkflowsList({
                organizationId: myOrganization.id,
                showAlert,
            });
        }
    }, [myOrganization]);

    const currentWorkflow = organizationWorkflows.find(item => item.id === workflowId);
    const workflowStatuses = currentWorkflow ? currentWorkflow.workflowStatuses : [];

    return (
        <Formik
            initialValues={{
                uuid,
                status: statuses && statuses.length > 0 ? statuses[0].id : 0,
            }}
            validationSchema={validationSchemaForStatusList}
            onSubmit={(values, {setErrors}) => {
                updateBoardListStatus({
                    ...values,
                    showAlert,
                    setErrors,
                });
            }}
        >
            {(formik: FormikProps<UpdateBoardListRequestTypes>) => {
                return (
                    <Form>
                        <FormControl sx={{mt: 2}} required fullWidth>
                            <InputLabel>{t('orguser.boards.parameters.status')}</InputLabel>
                            <Select
                                label={t('orguser.boards.parameters.status')}
                                name={'status'}
                                value={formik.values.status}
                                onChange={formik.handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                fullWidth
                            >
                                {workflowStatuses &&
                                    workflowStatuses.map((item: WorkflowStatusItemTypes, index: number) => {
                                        return (
                                            <MenuItem key={`workflow-status-${index}`} value={item.id}>
                                                <Typography sx={{fontWeight: 600}}>{item.title}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: 3,
                            }}
                        >
                            <AgentSaveButton isSubmit isLoading={isLoading} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default EditBoardListStatusForm;
