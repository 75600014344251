import {FieldOptionType, FormTranslationItemTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseOptionTypes} from 'appRedux/actions/requestCase/types';

import {getFieldKeyword, getOptionKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

export const getSelectedOptionsList = (
    fieldOptions: FieldOptionType[],
    selectedOptions: RequesterCaseOptionTypes[],
): FieldOptionType[] => {
    const results: FieldOptionType[] = [];
    fieldOptions.forEach(fieldOption => {
        if (selectedOptions.some(selectedOption => selectedOption.optionId === fieldOption.id)) {
            results.push(fieldOption);
        }
    });
    return results;
};

export const getSelectedOptionsRow = (
    formId: number,
    options: FieldOptionType[],
    translations: FormTranslationItemTypes[],
): string => {
    const results: string[] = [];
    options.forEach(option => {
        const keywordOptionTitle = getOptionKeyword(formId, option.id, 'title');
        const title = getFormTranslatedLabel(translations, keywordOptionTitle, option.title);
        results.push(title);
    });
    return results.join(', ');
};
