import React, {FC, ReactNode, RefObject} from 'react';

import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';

import {FormFieldTypes} from 'appRedux/actions/forms/types';

import ElementDescription from 'pages/client/form/partials/ElementDescription';

import {getFieldKeyword} from 'helpers/translationsHelper';

interface FormFieldWrapperType {
    formId: number;
    children: ReactNode;
    field: FormFieldTypes;
    wrapperRef: RefObject<HTMLDivElement | undefined>;
    isPopup?: boolean;
    previewMode?: boolean;
    'data-id'?: string;
}

const FormFieldWrapper: FC<FormFieldWrapperType> = ({
    wrapperRef,
    formId,
    field,
    previewMode,
    children,
    'data-id': data_id,
}) => {
    return (
        <Box data-id={data_id} ref={wrapperRef}>
            <FormControl fullWidth sx={{mt: 1, mb: 2}}>
                <ElementDescription
                    keyword={getFieldKeyword(formId, field.id, 'description')}
                    description={field.description}
                    previewMode={previewMode}
                />
                {children}
            </FormControl>
        </Box>
    );
};

export default FormFieldWrapper;
