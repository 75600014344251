interface OptionalObject {
    [key: string]: string;
}

const requestTypeCreator = (base: string, optional?: OptionalObject): OptionalObject => {
    if (optional) {
        Object.keys(optional).forEach(key => {
            if (optional[key]) {
                // eslint-disable-next-line no-param-reassign
                optional[key] = `${base}_${key}`;
            }
        });
    }

    return {
        ...optional,
        ERROR: `${base}_ERROR`,
        REQUEST: `${base}_REQUEST`,
        SUCCESS: `${base}_SUCCESS`,
        USER_FORM_ERRORS: `${base}_USER_FORM_ERRORS`,
        GENERAL_ERROR: `${base}_GENERAL_ERROR`,
    };
};

export default requestTypeCreator;
