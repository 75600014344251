import React, {FC} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';

import CancelSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {ModalWrapperType} from 'components/ModalWrapper/types';

import {theme} from 'config/theme';

const ClientMobileModalWrapper: FC<ModalWrapperType> = ({title, children, isShowModal, toggleModal}) => {
    return (
        <Modal open={isShowModal} onClose={toggleModal}>
            <Box
                sx={{
                    width: '100%',
                    pb: 2,
                    backgroundColor: theme.palette.background.default,
                    height: window.innerHeight,
                    minHeight: '100vh',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: title ? 'space-between' : 'flex-end',
                        alignItems: 'flex-start',
                        p: 2,
                    }}
                >
                    <Typography
                        variant="subtitle1"
                        align="left"
                        sx={{mb: 1, mt: -0.5, fontSize: 16, fontWeight: 'bold'}}
                    >
                        {title}
                    </Typography>
                    {toggleModal && (
                        <IconButton sx={{p: 0}} onClick={toggleModal}>
                            <CancelSvgIcon />
                        </IconButton>
                    )}
                </Box>
                <Box sx={{pl: 2, pr: 2, pb: 2, overflowY: 'scroll', height: `calc(100vh - 80px)`}}>{children}</Box>
            </Box>
        </Modal>
    );
};

export default ClientMobileModalWrapper;
