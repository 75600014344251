import React, {FC} from 'react';
import {FieldProps} from 'formik';
import get from 'lodash/get';
import {useTranslation} from 'react-i18next';

import {Box, FormControlLabel, Switch, Tooltip} from '@mui/material';
import Typography from '@mui/material/Typography';

import InfoSolidSvgIcon from 'assets/icons/buttons/InfoSolidSvgIcon';
import {CommonSvgIconType} from 'assets/icons/buttons/helper';

import FormFieldErrorMessage from 'components/AdminScreenComponents/FormFieldErrorMessage';

import {MAIN_CLIENT_COLOR, theme} from 'config/theme';

interface CheckboxIconButtonType {
    name: string;
    label: string;
    Icon: FC<CommonSvgIconType>;
    title: string;
}

const CheckboxIconButton: FC<FieldProps & CheckboxIconButtonType> = ({
    field,
    form: {touched, errors},
    name,
    label,
    Icon,
    title,
    ...props
}) => {
    const [t] = useTranslation();

    const fieldName = name || field.name;
    const isTouched = get(touched, fieldName, false);
    const errorMessage = get(errors, fieldName, false);
    const isDisabled = get(props, 'disabled', false);

    return (
        <FormControlLabel
            sx={{margin: 0}}
            control={<Switch id={name} {...field} {...props} sx={{display: 'none'}} />}
            label={
                <Box title={isDisabled ? t('orguser.forms.disabledOptionExplanation') : ''}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            overflow: 'hidden',
                            borderRadius: 2,
                            position: 'relative',
                            width: 90,
                            height: 80,
                            opacity: isDisabled ? 0.5 : 'initial',
                            border: `2px solid ${field.checked ? MAIN_CLIENT_COLOR : theme.palette.info.main}`,
                            '& > svg': {
                                width: 30,
                                height: 30,
                                path: {
                                    fill: field.checked ? MAIN_CLIENT_COLOR : theme.palette.info.main,
                                },
                            },
                        }}
                    >
                        <Icon />
                        <Typography
                            variant="body2"
                            fontSize={12}
                            color={theme.palette.info.main}
                            textAlign="center"
                            mt={0.4}
                        >
                            {title}
                        </Typography>
                        <Tooltip title={label} placement="top" arrow>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'absolute',
                                    top: 4,
                                    right: 4,
                                    backgroundColor: theme.palette.info.main,
                                    borderRadius: '12px',
                                    height: 16,
                                    width: 16,
                                }}
                            >
                                <InfoSolidSvgIcon isActive />
                            </Box>
                        </Tooltip>
                    </Box>
                    {isTouched && errorMessage && <FormFieldErrorMessage errorMessage={String(errorMessage)} />}
                </Box>
            }
        />
    );
};

export default CheckboxIconButton;
