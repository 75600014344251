import * as yup from 'yup';

import {REGEX_FOR_PASSWORD} from 'components/RegistrationForm/helper';
import {REGEX_FOR_NICKNAME} from 'components/Forms/SettingsForms/UpdateUsernameForm/validation';

import {MINIMAL_PASSWORD_LENGTH, MAXIMAL_PASSWORD_LENGTH, getPasswordLengthErrorMessage} from 'helpers/security';
import {getUsernameLengthErrorMessage, htmlTagsPresented} from 'helpers/validation';

import {MAXIMAL_NICKNAME_LENGTH, MINIMAL_NICKNAME_LENGTH, DEFAULT_LANGUAGE} from 'config/index';

export const startStepInitialValues = {
    nickname: '',
    email: '',
    lang: DEFAULT_LANGUAGE,
    password: {
        first: '',
        second: '',
    },
};

export const startValidationSchema = (t: (trans: string) => string) =>
    yup.object({
        nickname: yup
            .string()
            .required(t('messages.validation.nicknameIsRequired'))
            .matches(REGEX_FOR_NICKNAME, 'Username should consists of latin letters and numbers')
            .test('unwanted', t('messages.validation.htmlTagsDenied'), value => htmlTagsPresented(value))
            .min(
                MINIMAL_NICKNAME_LENGTH,
                getUsernameLengthErrorMessage(t, 'messages.validation.nicknameMinLength', MINIMAL_NICKNAME_LENGTH),
            )
            .max(
                MAXIMAL_NICKNAME_LENGTH,
                getUsernameLengthErrorMessage(t, 'messages.validation.nicknameMaxLength', MAXIMAL_NICKNAME_LENGTH),
            ),
        email: yup
            .string()
            .email(t('messages.validation.emailIncorrect'))
            .required(t('messages.validation.emailRequired')),
        password: yup.object({
            first: yup
                .string()
                .min(
                    MINIMAL_PASSWORD_LENGTH,
                    getPasswordLengthErrorMessage(t, 'messages.validation.passwordMinLength', MINIMAL_PASSWORD_LENGTH),
                )
                .max(
                    MAXIMAL_PASSWORD_LENGTH,
                    getPasswordLengthErrorMessage(t, 'messages.validation.passwordMaxLength', MAXIMAL_PASSWORD_LENGTH),
                )
                .required(t('messages.validation.passwordIsRequired'))
                .matches(REGEX_FOR_PASSWORD, t('messages.validation.passwordShouldContain')),
            second: yup
                .string()
                .min(
                    MINIMAL_PASSWORD_LENGTH,
                    getPasswordLengthErrorMessage(t, 'messages.validation.passwordMinLength', MINIMAL_PASSWORD_LENGTH),
                )
                .max(
                    MAXIMAL_PASSWORD_LENGTH,
                    getPasswordLengthErrorMessage(t, 'messages.validation.passwordMaxLength', MAXIMAL_PASSWORD_LENGTH),
                )
                .required(t('messages.validation.passwordIsRequired'))
                .matches(REGEX_FOR_PASSWORD, t('messages.validation.passwordShouldContain'))
                .oneOf([yup.ref('first'), null], t('messages.validation.passwordShouldMatch')),
        }),
    });
