import {TFunction} from 'react-i18next';

export const htmlTagsPresented = (value?: string): boolean => {
    const checkingExp = /<\/?[a-z][\s\S]*>/i;
    return value ? !checkingExp.test(value) : true;
};

export const getUsernameLengthErrorMessage = (t: TFunction, keyword: string, limit: number): string => {
    return t(keyword).replace('%NUMBER%', String(limit));
};
