import React, {FC} from 'react';

interface SpinnerTypes {
    size?: number | string;
}

const HerupuSpinner: FC<SpinnerTypes> = ({size}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            style={{shapeRendering: 'auto', display: 'block'}}
            width={size || '50'}
            height={size || '50'}
        >
            <style>
                {`@keyframes rollingOpacity {
            0% { opacity: 1; }
            50% { opacity: 0; }
            100% { opacity: 1; }
        }
        @keyframes rolling {
            0% { transform: rotate(0deg); }
            50% { transform: rotate(180deg); }
            100% { transform: rotate(360deg); }
        }
        .rotate {
            transform-origin: center center;
            animation: rolling 40000ms infinite;
            animation-timing-function: linear;
        }
        svg g[data-idx] {
            animation: rollingOpacity 1200ms infinite steps(4);
        }
        svg g[data-idx="12"] { animation-delay: 0ms; }
        svg g[data-idx="11"] { animation-delay: -100ms; }
        svg g[data-idx="10"] { animation-delay: -200ms; }
        svg g[data-idx="9"] { animation-delay: -300ms; }
        svg g[data-idx="8"] { animation-delay: -400ms; }
        svg g[data-idx="7"] { animation-delay: -500ms; }
        svg g[data-idx="6"] { animation-delay: -600ms; }
        svg g[data-idx="5"] { animation-delay: -700ms; }
        svg g[data-idx="4"] { animation-delay: -800ms; }
        svg g[data-idx="3"] { animation-delay: -900ms; }
        svg g[data-idx="2"] { animation-delay: -1000ms; }
        svg g[data-idx="1"] { animation-delay: -1100ms; }`}
            </style>
            <g className="rotate">
                <g transform="matrix(1,0,0,1,0,0)" data-idx="1">
                    <rect fill="#39e0a1" height="24" width="6" rx="3" ry="3" x="22" y="12"></rect>
                </g>
                <g
                    transform="matrix(0.8660254037844387,0.49999999999999994,-0.49999999999999994,0.8660254037844387,31.698729810778058,-18.30127018922194)"
                    data-idx="2"
                >
                    <rect fill="#39e0a1" height="24" width="6" rx="3" ry="3" x="22" y="12"></rect>
                </g>
                <g
                    transform="matrix(0.5000000000000001,0.8660254037844386,-0.8660254037844386,0.5000000000000001,68.30127018922192,-18.30127018922194)"
                    data-idx="3"
                >
                    <rect fill="#39e0a1" height="24" width="6" rx="3" ry="3" x="22" y="12"></rect>
                </g>
                <g transform="matrix(6.123233995736766e-17,1,-1,6.123233995736766e-17,100,0)" data-idx="4">
                    <rect fill="#319d9c" height="24" width="6" rx="3" ry="3" x="22" y="12"></rect>
                </g>
                <g
                    transform="matrix(-0.49999999999999983,0.8660254037844387,-0.8660254037844387,-0.49999999999999983,118.30127018922192,31.698729810778055)"
                    data-idx="5"
                >
                    <rect fill="#319d9c" height="24" width="6" rx="3" ry="3" x="22" y="12"></rect>
                </g>
                <g
                    transform="matrix(-0.8660254037844387,0.49999999999999994,-0.49999999999999994,-0.8660254037844387,118.30127018922194,68.30127018922194)"
                    data-idx="6"
                >
                    <rect fill="#319d9c" height="24" width="6" rx="3" ry="3" x="22" y="12"></rect>
                </g>
                <g transform="matrix(-1,1.2246467991473532e-16,-1.2246467991473532e-16,-1,100,100)" data-idx="7">
                    <rect fill="#39e0a1" height="24" width="6" rx="3" ry="3" x="22" y="12"></rect>
                </g>
                <g
                    transform="matrix(-0.8660254037844386,-0.5000000000000001,0.5000000000000001,-0.8660254037844386,68.30127018922192,118.30127018922194)"
                    data-idx="8"
                >
                    <rect fill="#39e0a1" height="24" width="6" rx="3" ry="3" x="22" y="12"></rect>
                </g>
                <g
                    transform="matrix(-0.5000000000000004,-0.8660254037844384,0.8660254037844384,-0.5000000000000004,31.698729810778104,118.30127018922194)"
                    data-idx="9"
                >
                    <rect fill="#39e0a1" height="24" width="6" rx="3" ry="3" x="22" y="12"></rect>
                </g>
                <g
                    transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,7.105427357601002e-15,100)"
                    data-idx="10"
                >
                    <rect fill="#319d9c" height="24" width="6" rx="3" ry="3" x="22" y="12"></rect>
                </g>
                <g
                    transform="matrix(0.5000000000000001,-0.8660254037844386,0.8660254037844386,0.5000000000000001,-18.30127018922194,68.30127018922192)"
                    data-idx="11"
                >
                    <rect fill="#319d9c" height="24" width="6" rx="3" ry="3" x="22" y="12"></rect>
                </g>
                <g
                    transform="matrix(0.8660254037844384,-0.5000000000000004,0.5000000000000004,0.8660254037844384,-18.30127018922194,31.698729810778104)"
                    data-idx="12"
                >
                    <rect fill="#319d9c" height="24" width="6" rx="3" ry="3" x="22" y="12"></rect>
                </g>
            </g>
        </svg>
    );
};

export default HerupuSpinner;
