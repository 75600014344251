import React, {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {escapeRegExp} from 'lodash';
import {useSearchParams} from 'react-router-dom';

import Typography from '@mui/material/Typography';

import {InviteFormsTypes, InviteTypes} from 'appRedux/actions/invites/types';

import HorizontalLeftFlexbox from 'components/Flexboxes/HorizontalLeftFlexbox';
import CreateNewInvite from 'components/Invites/CreateNewInvite';
import CreateInviteByEmail from 'components/Invites/CreateInviteByEmail';
import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';
import {filterSearchValue, sortInvites} from 'components/Invites/helper';
import DataGridWrapper from 'components/AdminScreenComponents/DataGridWrapper';
import InvitesDataGrid from 'components/Invites/InvitesDataGrid';

import {PARAMETER_AGENT, PARAMETER_FORM, PARAMETER_SEARCH, PARAMETER_SORT_BY, PARAMETER_SORT_TYPE} from 'config/index';

interface InvitesListRowType {
    invites: InviteTypes[];
    inviteForms?: InviteFormsTypes[];
}

const InvitesList: FC<InvitesListRowType> = ({invites, inviteForms}) => {
    const [t] = useTranslation();
    const [searchParams] = useSearchParams();

    const searchFromUrl = searchParams.get(PARAMETER_SEARCH);
    const formFromUrl = searchParams.get(PARAMETER_FORM);
    const agentFromUrl = searchParams.get(PARAMETER_AGENT);
    const sortByParameter = searchParams.get(PARAMETER_SORT_BY) as keyof InviteTypes | null;
    const sortTypeParameter = searchParams.get(PARAMETER_SORT_TYPE);

    const filteredInvites = useMemo(() => {
        let filtered = invites;
        if (formFromUrl) filtered = filtered.filter(invite => invite.formId.toString() === formFromUrl);
        if (agentFromUrl) filtered = filtered.filter(invite => invite.agent === agentFromUrl);
        if (searchFromUrl) {
            const searchTerm = new RegExp(escapeRegExp(searchFromUrl), 'i');
            filtered = filtered.filter(filterSearchValue(searchTerm));
        }
        if (sortTypeParameter && sortByParameter) {
            filtered = filtered.sort(sortInvites(sortByParameter, sortTypeParameter));
        }

        return filtered;
    }, [invites, formFromUrl, agentFromUrl, searchFromUrl, sortByParameter, sortTypeParameter]);

    return invites.length > 0 ? (
        <DataGridWrapper itemsList={invites} itemsTotalNumber={filteredInvites ? filteredInvites.length : 0} hasSearch>
            <InvitesDataGrid invites={filteredInvites} />
        </DataGridWrapper>
    ) : (
        <>
            <ClientSectionWrapper>
                <HorizontalLeftFlexbox>
                    <Typography sx={{mr: 1}}>{t('orguser.invites.noneInvitesForThisForm')}</Typography>
                    {!!inviteForms?.length && (
                        <>
                            <CreateNewInvite forms={inviteForms} />
                            <Typography sx={{ml: 1, mr: 1}}>or</Typography>
                            <CreateInviteByEmail forms={inviteForms} />
                        </>
                    )}
                </HorizontalLeftFlexbox>
            </ClientSectionWrapper>
        </>
    );
};

export default InvitesList;
