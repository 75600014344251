import React, {FC, Ref, useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {get} from 'lodash';

import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {WorkflowStatusEventItemTypes, WorkflowTemplateTypes} from 'appRedux/actions/workflow/types';
import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import UpdateWorkflowTemplateForm from 'components/Forms/Workflow/workflowTemplate/UpdateWorkflowTemplateForm';
import CreateWorkflowTemplateForm from 'components/Forms/Workflow/workflowTemplate/CreateWorkflowTemplateForm';

import WorkflowAttachments from 'pages/admin/updateWorkflowEvents/workflowAttachments/WorkflowAttachments';

import {getLanguageById} from 'helpers/translationsHelper';

import {DEFAULT_LANGUAGE_ID} from 'config/index';

interface EditWorkflowStatusEventButtonType {
    statusTitle: string;
    statusUuid: string;
    item: WorkflowStatusEventItemTypes;
    typeLabel: string;
    eventTypeLabel: string;
    forEmailTemplate?: boolean;
    forWebhookTemplate?: boolean;
}

const EditWorkflowStatusEventButton: FC<EditWorkflowStatusEventButtonType> = ({
    item,
    statusTitle,
    statusUuid,
    eventTypeLabel,
    typeLabel,
    forEmailTemplate,
    forWebhookTemplate,
}) => {
    const [t] = useTranslation();

    const {myOrganization} = useContext(AdminContext);

    const [language, setLanguage] = useState<number>(DEFAULT_LANGUAGE_ID);

    const formRef = useRef<Ref<any>>(null);

    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [, isUpdated] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpened(previous => !previous);
    };

    const {
        admin: {languageList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const submitStatusEventForm = () => {
        if (formRef && formRef.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formRef.current.handleSubmit();
            isUpdated(previous => !previous);
        }
    };

    const currentLanguage = getLanguageById(languageList, language);

    const currentTemplate: WorkflowTemplateTypes = get(item.translations, [currentLanguage.name], null);

    useEffect(() => {
        if (myOrganization) {
            setLanguage(myOrganization.language);
        }
    }, [myOrganization]);

    useEffect(() => {
        if (currentTemplate) {
            setLanguage(currentTemplate.language);
        }
    }, [currentTemplate]);

    if (!myOrganization) return null;

    return (
        <>
            <IconButton title={t('common.buttons.update')} onClick={toggleModal}>
                <EditSvgIcon />
            </IconButton>
            <ModalWrapper
                title={`${t('orguser.workflows.updateWorkflowStatusEventTemplate')} - ${t(
                    eventTypeLabel,
                )} "${statusTitle}" (${t(typeLabel)})`}
                toggleModal={toggleModal}
                isShowModal={isOpened}
                isWideModal
            >
                {currentTemplate ? (
                    <>
                        <UpdateWorkflowTemplateForm
                            item={item}
                            formRef={formRef}
                            language={language}
                            setLanguage={setLanguage}
                            currentTemplate={currentTemplate}
                            statusUuid={statusUuid}
                            closeModal={() => setIsOpened(false)}
                            forEmailTemplate={forEmailTemplate}
                            forWebhookTemplate={forWebhookTemplate}
                        />
                        {forEmailTemplate && (
                            <>
                                <Divider sx={{mt: 2, mb: 2}} />
                                <WorkflowAttachments item={item} languageId={currentLanguage.id} />
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <CreateWorkflowTemplateForm
                            formRef={formRef}
                            item={item}
                            language={language}
                            setLanguage={setLanguage}
                            statusUuid={statusUuid}
                            closeModal={() => setIsOpened(false)}
                            forEmailTemplate={forEmailTemplate}
                            forWebhookTemplate={forWebhookTemplate}
                        />
                        {forEmailTemplate && (
                            <>
                                <Divider sx={{mt: 2, mb: 2}} />
                                <WorkflowAttachments item={item} languageId={currentLanguage.id} />
                            </>
                        )}
                    </>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt: 3,
                    }}
                >
                    <AgentSaveButton isSubmit={false} onClick={submitStatusEventForm} />
                </Box>
            </ModalWrapper>
        </>
    );
};

export default EditWorkflowStatusEventButton;
