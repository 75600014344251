import React, {FC, useContext} from 'react';
import get from 'lodash/get';
import {View, Text} from '@react-pdf/renderer';

import {FormPopupTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseItemType} from 'appRedux/actions/requestCase/types';

import PopupFieldPdfContent from 'pages/agent/pdfExport/pdfPartials/PopupFieldPdfContent';
import {pdfStyles} from 'pages/agent/pdfExport/pdfPartials/PdfDocumentStyles';
import {PdfDocumentContext} from 'pages/agent/pdfExport/pdfPartials/pdfDocumentContext';

import {getPopupKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

interface PopupPdfContentType {
    formId: number;
    pageId: number;
    sectionId: number;
    popup: FormPopupTypes;
    result: RequesterCaseItemType;
}

const PopupPdfContent: FC<PopupPdfContentType> = ({formId, popup, pageId, sectionId, result}) => {
    const {translations} = useContext(PdfDocumentContext);

    const {title, id, fields} = popup;

    const keyword = getPopupKeyword(formId, id, 'title');

    const pageKeyword = `page-${pageId}`;
    const sectionKeyword = `section-${sectionId}`;
    const popupKeyword = `popup-${id}`;

    const popupItems = get(result, [pageKeyword, sectionKeyword, popupKeyword], []);

    return (
        <>
            <View style={pdfStyles.popupTitle}>
                <Text>{`${getFormTranslatedLabel(translations, keyword, title)}:`}</Text>
            </View>
            {popupItems.map((item: RequesterCaseItemType, index: number) => {
                return (
                    <PopupFieldPdfContent
                        item={item}
                        pageId={pageId}
                        sectionId={sectionId}
                        popupId={id}
                        fields={fields}
                        key={`field-popup-${index}`}
                        formId={formId}
                    />
                );
            })}
        </>
    );
};

export default PopupPdfContent;
