import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import DescriptionPopover from 'components/DescriptionPopover/DescriptionPopover';

import InformationWrapper from 'pages/agent/requesterPage/wrappers/InformationWrapper';
import {getSlotStatusLabel, getSlotStatusDateTime} from 'pages/client/form/fieldTypes/helper';

import {getFormTranslatedLabel} from 'helpers/translationsHelper';

interface RequesterCaseHasSlotInformationType {
    field: FormFieldTypes;
    keywordTitle: string;
    keywordDescription: string;
}

const RequesterCaseHasSlotInformation: FC<RequesterCaseHasSlotInformationType> = ({
    field,
    keywordTitle,
    keywordDescription,
}) => {
    const [t] = useTranslation();

    const {
        admin: {
            formInfo: {translations},
        },
        requestCase: {currentCaseSlots},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const currentSlot = currentCaseSlots
        ? currentCaseSlots.find(slot => slot.slotId === field.resourceFieldSlot)
        : null;
    return (
        <InformationWrapper
            title={getFormTranslatedLabel(translations, keywordTitle, field.label)}
            description={getFormTranslatedLabel(translations, keywordDescription, field.description)}
        >
            {currentSlot ? (
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <Typography sx={{fontWeight: 700, mr: 1}}>{`${t('orguser.resourceFields.title')}:`}</Typography>
                        <Typography>{currentSlot.title}</Typography>
                        {currentSlot.description && <DescriptionPopover text={currentSlot.description} />}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            mb: 2,
                        }}
                    >
                        <Typography sx={{fontWeight: 700, mr: 1}}>{`${t(
                            'orguser.resourceFields.eventName',
                        )}:`}</Typography>
                        <Typography>{currentSlot.eventName}</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <Typography sx={{fontWeight: 700, mr: 1}}>{`${t(
                            getSlotStatusLabel(currentSlot.status),
                        )}:`}</Typography>
                        <Typography>{getSlotStatusDateTime(currentSlot)}</Typography>
                    </Box>
                </Box>
            ) : (
                <Typography>{t('common.none')}</Typography>
            )}
        </InformationWrapper>
    );
};

export default RequesterCaseHasSlotInformation;
