import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const PolicySvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.2932 14.7068C2.48068 14.8943 2.73487 14.9997 3 15H8V14H3V2H11V4H12V2C11.9997 1.73487 11.8943 1.48068 11.7068 1.2932C11.5193 1.10572 11.2651 1.00028 11 1H3C2.73487 1.00028 2.48068 1.10572 2.2932 1.2932C2.10572 1.48068 2.00028 1.73487 2 2V14C2.00028 14.2651 2.10572 14.5193 2.2932 14.7068ZM9.5 4H4.5V5H9.5V4ZM14.6384 7.56599C14.8767 8.00632 15.001 8.49929 15 8.99996C14.9998 9.42113 14.9106 9.8375 14.7382 10.2218C14.5659 10.6061 14.3143 10.9496 14 11.23V15L12 14.0537L10 15V11.23C9.62682 10.8962 9.34311 10.4743 9.17474 10.0028C9.00636 9.53127 8.95868 9.02511 9.03602 8.53045C9.11337 8.03578 9.31329 7.56834 9.61756 7.17073C9.92183 6.77312 10.3208 6.45798 10.778 6.25405C11.2353 6.05011 11.7363 5.96386 12.2355 6.00315C12.7346 6.04244 13.2159 6.20603 13.6357 6.47901C14.0554 6.75199 14.4001 7.12567 14.6384 7.56599ZM12 12.9466L13 13.42V11.825C12.3538 12.0584 11.6462 12.0584 11 11.825V13.42L12 12.9466ZM10.8889 10.6629C11.2178 10.8827 11.6044 11 12 11C12.5303 10.9994 13.0386 10.7885 13.4136 10.4135C13.7885 10.0386 13.9994 9.53021 14 8.99996C14 8.6044 13.8827 8.21772 13.6629 7.88882C13.4432 7.55992 13.1308 7.30358 12.7654 7.1522C12.3999 7.00082 11.9978 6.96122 11.6098 7.03839C11.2219 7.11556 10.8655 7.30604 10.5858 7.58575C10.3061 7.86545 10.1156 8.22182 10.0384 8.60978C9.96126 8.99774 10.0009 9.39987 10.1522 9.76533C10.3036 10.1308 10.56 10.4431 10.8889 10.6629ZM4.5 7H8V8H4.5V7Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default PolicySvgIcon;
