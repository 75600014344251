import {fork, put, take, call} from 'redux-saga/effects';

import {
    FORM_FAQ_LIST,
    getFormFaqList,
    FORM_FAQ_CREATE,
    createFormFaq,
    FORM_FAQ_UPDATE,
    updateFormFaq,
    FORM_FAQ_DELETE,
    deleteFormFaq,
    FORM_FAQ_PRIORITY,
    changeFormFaqPriority,
} from 'appRedux/actions/forms';

import {
    FormFaqListRequestTypes,
    FormFaqItemTypes,
    FormFaqCreateRequestTypes,
    FormFaqUpdateRequestTypes,
    FormFaqDeleteRequestTypes,
    FormFaqChangePriorityRequestTypes,
    FormFaqResponseTypes,
} from 'appRedux/actions/forms/types';

import {http} from 'services/http';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchFormFagListGetting() {
    while (true) {
        const {
            payload: {id, language, callback, showAlert},
        }: IActionType<FormFaqListRequestTypes> = yield take(FORM_FAQ_LIST.REQUEST);
        try {
            const data: FormFaqResponseTypes = yield call(http, `faq/${id}/list/${language}`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getFormFaqList.success(data.results));
                callback && callback();
            } else {
                yield put(getFormFaqList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(getFormFaqList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFagCreation() {
    while (true) {
        const {
            payload: {id, showAlert, callback, ...values},
        }: IActionType<FormFaqCreateRequestTypes> = yield take(FORM_FAQ_CREATE.REQUEST);
        try {
            const data: FormFaqResponseTypes = yield call(http, `faq/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createFormFaq.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createFormFaq.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(createFormFaq.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFagUpdating() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback, ...values},
        }: IActionType<FormFaqUpdateRequestTypes> = yield take(FORM_FAQ_UPDATE.REQUEST);
        try {
            const data: FormFaqResponseTypes = yield call(http, `faq/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateFormFaq.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateFormFaq.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(updateFormFaq.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFagDeleting() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback},
        }: IActionType<FormFaqDeleteRequestTypes> = yield take(FORM_FAQ_DELETE.REQUEST);
        try {
            const data: FormFaqResponseTypes = yield call(http, `faq/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteFormFaq.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteFormFaq.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(deleteFormFaq.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFagPriorityChanging() {
    while (true) {
        const {
            payload: {uuid, priority, showAlert, callback, ...values},
        }: IActionType<FormFaqChangePriorityRequestTypes> = yield take(FORM_FAQ_PRIORITY.REQUEST);
        try {
            const data: FormFaqResponseTypes = yield call(http, `faq/${uuid}/priority/${priority}`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(changeFormFaqPriority.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(changeFormFaqPriority.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(changeFormFaqPriority.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchFormFagListGetting),
    fork(watchFormFagCreation),
    fork(watchFormFagUpdating),
    fork(watchFormFagDeleting),
    fork(watchFormFagPriorityChanging),
];
