import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import ReassignAgentForm from 'components/Forms/OrganizationAdminForm/ReassignAgentForm';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

interface ReassignAgentButtonType {
    disabled: boolean;
    isShowModal: boolean;
    organizationId: number;
    toggleModal: () => void;
}

const ReassignAgentButton: FC<ReassignAgentButtonType> = ({disabled, organizationId, isShowModal, toggleModal}) => {
    const [t] = useTranslation();
    return (
        <>
            <IconButton title={t('common.buttons.delete')} disabled={disabled} onClick={toggleModal}>
                <DeleteSvgIcon isDisabled={disabled} />
            </IconButton>
            <ModalWrapper
                title={t('common.deletePopup.deletingRestrict')}
                isShowModal={isShowModal}
                toggleModal={toggleModal}
            >
                <Typography variant="body1" sx={{mb: 3}}>
                    {t('superadmin.users.agentDeletingRestrictDescription')}
                </Typography>
                <ReassignAgentForm organizationId={organizationId} toggleModal={toggleModal} />
            </ModalWrapper>
        </>
    );
};

export default ReassignAgentButton;
