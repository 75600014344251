import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {DEACTIVATE_FORM_VERSION} from 'appRedux/actions/forms';
import {FormVersionTypes} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import CreateNewVersionButton from 'pages/admin/updateForm/partials/FormVersions/CreateNewVersionButton';
import ForceEditButton from 'pages/admin/formVersion/ForceEditButton';

interface ReleasedVersionButtonsPanelType {
    item: FormVersionTypes;
    isForceEditClicked: boolean;
    setIsForceEditClicked: (value: boolean) => void;
}

const ReleasedVersionButtonsPanel: FC<ReleasedVersionButtonsPanelType> = ({
    item,
    isForceEditClicked,
    setIsForceEditClicked,
}) => {
    const dispatch = useDispatch();

    const {uuid, casesQty, formId} = item;

    const {showAlert} = useContext(AlertContext);

    const deactivateFormVersion = useCallback(
        data => dispatch({type: DEACTIVATE_FORM_VERSION.REQUEST, payload: data}),
        [dispatch],
    );

    const onDeactivateClick = () => {
        deactivateFormVersion({
            uuid,
            showAlert,
        });
    };

    return (
        <>
            {casesQty > 0 && (
                <>
                    <CreateNewVersionButton formId={formId} />
                    {!isForceEditClicked && (
                        <ForceEditButton item={item} setIsForceEditClicked={setIsForceEditClicked} />
                    )}
                </>
            )}
            <AgentSaveButton
                title={'common.buttons.deactivate'}
                isSubmit={false}
                onClick={onDeactivateClick}
                isDanger
            />
        </>
    );
};

export default ReleasedVersionButtonsPanel;
