import {OrganizationItemBasic} from 'appRedux/actions/organization/types';

import {FilterOptionType} from 'components/AdminScreenComponents/types';

export const getOrganizationsList = (organizations: OrganizationItemBasic[]): FilterOptionType[] => {
    const results: FilterOptionType[] = [];
    organizations.forEach((item: OrganizationItemBasic) => {
        const {id, name} = item;
        results.push({
            name: String(id),
            label: name,
        });
    });
    return results;
};
