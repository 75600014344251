import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import {DELETE_FORM_SNIPPET_LOGO} from 'appRedux/actions/forms';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';

interface RemoveSnippetLogoButtonType {
    formId: number;
    setBlobURL: (value: string | null) => void;
}

const RemoveSnippetLogoButton: FC<RemoveSnippetLogoButtonType> = ({formId, setBlobURL}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const deleteFormSnippetLogo = useCallback(
        data => dispatch({type: DELETE_FORM_SNIPPET_LOGO.REQUEST, payload: data}),
        [dispatch],
    );

    const onDeleteClick = () => {
        deleteFormSnippetLogo({
            id: formId,
            showAlert,
            callback: () => {
                setIsOpened(false);
                setBlobURL(null);
            },
        });
    };

    return (
        <>
            <AgentInverseButton title={t('orguser.forms.formSnippet.removeLogo')} onClick={toggleIsOpened} />
            <DeleteAdminConfirmationModal
                isShowModal={isOpened}
                toggleModal={toggleIsOpened}
                onClickAction={onDeleteClick}
            />
        </>
    );
};

export default RemoveSnippetLogoButton;
