import get from 'lodash/get';
import {fork, put, take, call} from 'redux-saga/effects';

import {
    RoleCommonRequestTypes,
    RolesListResponseTypes,
    RoleInformationResponseTypes,
    RoleRequestTypes,
} from 'appRedux/actions/roles/types';
import {
    GET_ROLES_LIST,
    getRolesList,
    GET_ROLES_LIST_OTHER_ORGANIZATION,
    getRolesListOtherOrganization,
    CREATE_ROLE,
    createRole,
    UPDATE_ROLE,
    updateRole,
    DELETE_ROLE,
    deleteRole,
    TOGGLE_ROLE_STATUS,
    toggleStatusRole,
    GET_ROLE_INFORMATION,
    getRoleInformation,
} from 'appRedux/actions/roles';

import {http} from 'services/http';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchGetRolesList() {
    while (true) {
        const {
            payload: {id, showAlert},
        }: IActionType<RoleCommonRequestTypes> = yield take(GET_ROLES_LIST.REQUEST);
        try {
            const data: RolesListResponseTypes = yield call(http, `organization/${id}/roles`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getRolesList.success(data.results));
            } else {
                yield put(getRolesList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getRolesList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchGetRolesListOtherOrganization() {
    while (true) {
        const {
            payload: {id, showAlert},
        }: IActionType<RoleCommonRequestTypes> = yield take(GET_ROLES_LIST_OTHER_ORGANIZATION.REQUEST);
        try {
            const data: RolesListResponseTypes = yield call(http, `organization/${id}/roles`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getRolesListOtherOrganization.success(data.results));
            } else {
                yield put(getRolesListOtherOrganization.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getRolesListOtherOrganization.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchGetRoleInformation() {
    while (true) {
        const {
            payload: {uuid, showAlert},
        }: IActionType<RoleRequestTypes> = yield take(GET_ROLE_INFORMATION.REQUEST);
        try {
            const data: RoleInformationResponseTypes = yield call(http, `role/${uuid}/information`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getRoleInformation.success(data.result));
            } else {
                yield put(getRoleInformation.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getRoleInformation.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchRoleCreation() {
    while (true) {
        const {
            payload: {showAlert, callback, id, ...values},
        }: IActionType<RoleCommonRequestTypes> = yield take(CREATE_ROLE.REQUEST);
        try {
            const data: RoleInformationResponseTypes = yield call(http, `role/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success && data.result) {
                yield put(createRole.success(data.result));
                const uuid = get(data, ['result', 'uuid'], null);
                if (uuid && callback) {
                    callback(uuid);
                }
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createRole.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createRole.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchRoleUpdating() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<RoleCommonRequestTypes> = yield take(UPDATE_ROLE.REQUEST);
        try {
            const data: RoleInformationResponseTypes = yield call(http, `role/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateRole.success(data.result));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateRole.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateRole.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchRoleDeleting() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid},
        }: IActionType<RoleCommonRequestTypes> = yield take(DELETE_ROLE.REQUEST);
        try {
            const data: RolesListResponseTypes = yield call(http, `role/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteRole.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteRole.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteRole.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchRoleStatusToggling() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid},
        }: IActionType<RoleCommonRequestTypes> = yield take(TOGGLE_ROLE_STATUS.REQUEST);
        try {
            const data: RolesListResponseTypes = yield call(http, `role/${uuid}/status`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(toggleStatusRole.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(toggleStatusRole.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(toggleStatusRole.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchGetRolesList),
    fork(watchGetRolesListOtherOrganization),
    fork(watchGetRoleInformation),
    fork(watchRoleCreation),
    fork(watchRoleUpdating),
    fork(watchRoleDeleting),
    fork(watchRoleStatusToggling),
];
