import React, {FC, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {MessageTypes, ChatMessageUpdateFormTypes} from 'appRedux/actions/requestChat/types';
import {UPDATE_CHAT_MESSAGE} from 'appRedux/actions/requestChat';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {CaseKeyContext} from 'contexts/caseKey/context';
import {ChatFilesContext} from 'contexts/chatFiles/context';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ThumbItem from 'components/ClientScreenComponents/ThumbItem';

import {encryptStringWithKey, getEncryptedFileData} from 'helpers/cryptoApiHelper';

interface UpdateChatMessageFormType {
    item: MessageTypes;
    userId: number;
    closeForm: () => void;
    isMessageChangingPossible: boolean;
}

const UpdateChatMessageForm: FC<UpdateChatMessageFormType> = ({item, userId, closeForm, isMessageChangingPossible}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {unwrappedCaseKey} = useContext(CaseKeyContext);
    const {selectFile, filesList} = useContext(ChatFilesContext);

    const [thumbLink, setThumbLink] = useState<string | null | undefined>();

    const {
        requestCase: {
            currentCase: {isEncryptInfo},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const updateChatMessage = useCallback(
        data => dispatch({type: UPDATE_CHAT_MESSAGE.REQUEST, payload: data}),
        [dispatch],
    );

    const {text, uuid, thumb, encryptedPrefix, senderId} = item;
    const isCurrentUserSender = senderId === userId;

    const currentFile = useMemo(() => filesList && filesList.find(file => file.uuid === uuid), [filesList, uuid]);

    useEffect(() => {
        let isMounted = true;
        if (thumbLink) return;

        const downloadEncryptedThumb = async (url: string) => {
            return await getEncryptedFileData(url, 'data:image/jpeg;base64,', unwrappedCaseKey);
        };

        const getThumbLink = async () => {
            if (!thumb || (encryptedPrefix && !unwrappedCaseKey)) return '';
            return encryptedPrefix && isEncryptInfo ? downloadEncryptedThumb(thumb) : thumb;
        };
        getThumbLink()
            .then(link => {
                isMounted && setThumbLink(link);
            })
            .catch(err => {
                console.error(err);
            });

        return () => {
            isMounted = false;
        };
    }, [thumb, encryptedPrefix, unwrappedCaseKey]);

    if (!isMessageChangingPossible) {
        return (
            <Box>
                <Typography sx={{mb: 3}}>{t('requester.chat.chatMessageRemovingImpossible')}</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <AgentSaveButton isSubmit={false} title={t('common.buttons.close')} onClick={closeForm} />
                </Box>
            </Box>
        );
    }

    return (
        <Box>
            {thumb && (
                <ThumbItem
                    uuid={uuid}
                    currentFile={currentFile}
                    isUserOwner={isCurrentUserSender}
                    thumbSrc={thumbLink}
                    selectFile={selectFile}
                    isChatMessage
                />
            )}
            <Formik
                initialValues={{
                    text,
                }}
                onSubmit={async values => {
                    const commonPayload = {
                        ...values,
                        uuid,
                        showAlert,
                        callback: closeForm,
                    };

                    if (isEncryptInfo && unwrappedCaseKey) {
                        updateChatMessage({
                            ...commonPayload,
                            text: await encryptStringWithKey(values.text, unwrappedCaseKey),
                        });
                    } else {
                        updateChatMessage({
                            ...commonPayload,
                        });
                    }
                }}
            >
                {(formik: FormikProps<ChatMessageUpdateFormTypes>) => {
                    return (
                        <Form>
                            <Field
                                required
                                name="text"
                                placeholder={t('requester.chat.messageText')}
                                component={FormikTextInput}
                                variant={'outlined'}
                                multiline
                                rows={4}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 2,
                                }}
                            >
                                <AgentSaveButton isSubmit />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default UpdateChatMessageForm;
