import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';

import {ORGANIZATION_TEMPLATES_LIST} from 'appRedux/actions/organization';
import {GET_ORGANIZATION_LANGUAGES_LIST} from 'appRedux/actions/organizationLanguage';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

import {organizationTemplatesArray} from 'pages/admin/organizationTemplates/helper';
import OrganizationTemplateTableRow from 'pages/admin/organizationTemplates/OrganizationTemplateTableRow';
import TranslationSelector from 'pages/admin/organizationTemplates/TranslationSelector';

import {getLanguageById, getLanguageIdByName} from 'helpers/translationsHelper';

import {PARAMETER_LANGUAGE} from 'config/index';

const OrganizationTemplatesList: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const {showAlert} = useContext(AlertContext);

    const getOrganizationTemplates = useCallback(
        data => dispatch({type: ORGANIZATION_TEMPLATES_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const getOrganizationLanguages = useCallback(
        data => dispatch({type: GET_ORGANIZATION_LANGUAGES_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {organizationList, languageList, organizationTemplates},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const currentOrganizationDefaultLanguage = myOrganization
        ? getLanguageById(languageList, myOrganization.language)
        : null;

    useEffect(() => {
        if (myOrganization) {
            getOrganizationTemplates({
                id: myOrganization.id,
                showAlert,
            });
            getOrganizationLanguages({
                id: myOrganization.id,
                showAlert,
            });
        }
    }, [myOrganization]);

    if (!myOrganization || !currentOrganizationDefaultLanguage) return null;

    const currentLanguage = searchParams.get(PARAMETER_LANGUAGE)
        ? searchParams.get(PARAMETER_LANGUAGE)
        : currentOrganizationDefaultLanguage.name;

    return (
        <ClientSectionWrapper>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <TranslationSelector organizationLanguage={currentOrganizationDefaultLanguage} />
            </Box>
            <Table>
                <TableHead>
                    <TableCell width={300}>
                        <Typography sx={{fontWeight: 600}}>{t('orguser.mailer.eventType')}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography sx={{fontWeight: 600}}>{t('orguser.mailer.subjectAndText')}</Typography>
                    </TableCell>
                    <TableCell width={80}>
                        <Typography sx={{fontWeight: 600}}>{t('orguser.mailer.action')}</Typography>
                    </TableCell>
                </TableHead>
                <TableBody>
                    {organizationTemplatesArray.map(item => {
                        const currentTemplate = organizationTemplates.find(
                            organizationTemplate =>
                                organizationTemplate.type === item.type &&
                                organizationTemplate.language ===
                                    getLanguageIdByName(
                                        languageList,
                                        String(currentLanguage),
                                        currentOrganizationDefaultLanguage.id,
                                    ),
                        );
                        return (
                            <OrganizationTemplateTableRow
                                organization={myOrganization}
                                key={`template-${item.type}`}
                                label={item.label}
                                type={item.type}
                                item={currentTemplate}
                                currentLanguageName={String(currentLanguage)}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </ClientSectionWrapper>
    );
};

export default OrganizationTemplatesList;
