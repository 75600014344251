import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {WorkflowStatusItemTypes, WorkflowStatusTranslationFormTypes} from 'appRedux/actions/workflow/types';
import {CREATE_WORKFLOW_STATUS_TRANSLATION} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import WorkflowStatusTranslationForm from 'components/Forms/Workflow/workflowStatusTranslation/WorkflowStatusTranslationForm';

interface CreateWorkflowStatusTranslationFormType {
    currentColor: string;
    formField: string;
    language: LanguageItemTypes;
    item: WorkflowStatusItemTypes;
    closeForm: () => void;
}

const CreateWorkflowStatusTranslationForm: FC<CreateWorkflowStatusTranslationFormType> = ({
    language,
    currentColor,
    formField,
    item,
    closeForm,
}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const createWorkflowStatusTranslation = useCallback(
        data => dispatch({type: CREATE_WORKFLOW_STATUS_TRANSLATION.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: WorkflowStatusTranslationFormTypes) => {
        createWorkflowStatusTranslation({
            ...values,
            uuid: item.uuid,
            showAlert,
            callback: closeForm,
        });
    };

    return (
        <WorkflowStatusTranslationForm
            initialValues={{
                translation: '',
                translationRequester: '',
                language: language.id,
            }}
            formField={formField}
            onSubmitClicked={onSubmitClicked}
            currentColor={currentColor}
        />
    );
};

export default CreateWorkflowStatusTranslationForm;
