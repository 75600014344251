import React, {FC, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {get, throttle} from 'lodash';
import moment from 'moment';

import {Box, Typography} from '@mui/material';

import {REFRESH_USER_TOKEN} from 'appRedux/actions/auth';
import {RootReducer} from 'appRedux/reducers';

import {RouteContext} from 'contexts/route/context';
import {AlertContext} from 'contexts/alert/context';

import {MINUTES_LOGOUT_IDLE_TIME} from 'components/Forms/OrganizationForm/validation';

import {getExpirationAndCurrentTimeDifference, getTokenExpirationTime} from 'helpers/menuHelper';

import {getToken, getRefreshToken, storeLogoutTime, getLogoutTime} from 'services/localStorage';

import {
    REFRESH_TOKEN_AFTER_PENDING,
    REFRESH_TOKEN_NOTIFICATION_INTERVAL,
    CHECK_LOGOUT_TIME_INTERVAL,
} from 'config/index';
import {theme} from 'config/theme';

interface SessionTimerProps {
    inverse?: boolean;
    mini?: boolean;
}

const SessionTimer: FC<SessionTimerProps> = ({inverse, mini}) => {
    const dispatch = useDispatch();

    const {
        auth: {organization},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const minutesToLogout = get(organization, 'logoutIdleTime', MINUTES_LOGOUT_IDLE_TIME);
    const secondsToLogout = minutesToLogout * 60;

    const {onLogoutClicked} = useContext(RouteContext);
    const {showAlert} = useContext(AlertContext);

    const [logoutTime, setLogoutTime] = useState<number>(0);
    const [tokenExpirationPeriod, setTokenExpirationPeriod] = useState<number>(360);

    const isSessionExpired = useRef<boolean>(false);

    const [minutes, setMinutes] = useState<number>();
    const [seconds, setSeconds] = useState<string>('');

    const updateLogoutTime = () => {
        const newTime = moment().unix() + secondsToLogout;
        setLogoutTime(newTime);
        storeLogoutTime(newTime);
    };

    const refreshUserToken = useCallback(payload => dispatch({type: REFRESH_USER_TOKEN.REQUEST, payload}), [dispatch]);

    const handleRefreshToken = async () => {
        const refreshToken = await getRefreshToken();
        if (refreshToken) {
            refreshUserToken({
                refreshToken,
                showAlert,
            });
        } else onLogoutClicked();
    };

    useEffect(() => {
        const checkTokenExpirationTime = async () => {
            const token = await getToken();
            if (!token) return handleRefreshToken();

            const difference = getTokenExpirationTime(String(token));
            setTokenExpirationPeriod(difference);
        };

        checkTokenExpirationTime();
    }, []);

    useEffect(() => {
        updateLogoutTime();
        const onClick = throttle(() => {
            updateLogoutTime();
        }, 5000);

        document.body.addEventListener('click', onClick, true);
        return () => {
            document.body.removeEventListener('click', onClick, true);
        };
    }, []);

    useEffect(() => {
        const checkToken = async () => {
            const token = await getToken();
            if (!token) return handleRefreshToken();

            const differenceSeconds = getExpirationAndCurrentTimeDifference(String(token));
            console.log('checkToken', 'difference', differenceSeconds);

            if (differenceSeconds && differenceSeconds < REFRESH_TOKEN_AFTER_PENDING) {
                handleRefreshToken();
            }
        };

        checkToken();

        const tokenRefreshInterval = setInterval(() => {
            checkToken();
        }, REFRESH_TOKEN_NOTIFICATION_INTERVAL);

        const checkLogoutTimeInterval = setInterval(async () => {
            const storedLogoutTime = await getLogoutTime();
            if (storedLogoutTime) {
                setLogoutTime(storedLogoutTime);
            }
        }, CHECK_LOGOUT_TIME_INTERVAL);

        return () => {
            clearInterval(tokenRefreshInterval);
            clearInterval(checkLogoutTimeInterval);
        };
    }, [secondsToLogout]);

    useEffect(() => {
        if (!logoutTime) return;

        const timeleft = logoutTime - moment().unix();

        const logoutTimeout = setTimeout(() => {
            onLogoutClicked(isSessionExpired.current);
        }, timeleft * 1000);

        const timerUpdateInterval = setInterval(async () => {
            const timeleft = logoutTime - moment().unix();
            if (timeleft < 0) {
                isSessionExpired.current = true;
                return;
            }
            const minutesLeft = Math.floor(timeleft / 60);
            const secondsLeft = ('0' + (timeleft % 60)).slice(-2);
            setMinutes(minutesLeft);
            setSeconds(secondsLeft);
        }, 1000);

        return () => {
            clearTimeout(logoutTimeout);
            clearInterval(timerUpdateInterval);
        };
    }, [logoutTime]);

    useEffect(() => {
        if (!logoutTime) return;

        const timeleft = logoutTime - moment().unix();
        const lastRefreshDelay = timeleft - tokenExpirationPeriod;

        if (lastRefreshDelay < 10) return;

        const lastRefreshTimeout = setTimeout(async () => {
            handleRefreshToken();
        }, lastRefreshDelay * 1000);

        return () => {
            clearTimeout(lastRefreshTimeout);
        };
    }, [logoutTime, tokenExpirationPeriod]);

    return typeof minutes === 'number' && minutes < minutesToLogout - 5 ? (
        <Box sx={{mr: 2}}>
            <Typography
                sx={{
                    color: inverse ? theme.palette.background.default : theme.palette.info.main,
                    fontSize: mini ? '0.9rem' : '1rem',
                }}
            >
                Logout in {minutes}:{seconds}
            </Typography>
        </Box>
    ) : null;
};

export default SessionTimer;
