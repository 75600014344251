import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {FormikProps} from 'formik';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';

import {ResourceFieldSlotItemTypes} from 'appRedux/actions/resourceFields/types';
import {FormFieldRequestType} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {theme} from 'config/theme';

interface ResourceFieldSlotElementsType {
    formik: FormikProps<FormFieldRequestType>;
}

const ResourceFieldSlotElements: FC<ResourceFieldSlotElementsType> = ({formik}) => {
    const [t] = useTranslation();

    const {
        resourceFields: {resourceFieldSlotItems},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Box>
            <Typography variant="body2" sx={{mb: 1}}>
                {t('orguser.forms.formField.resourceFieldSlot')}
            </Typography>
            <FormControl
                sx={{
                    backgroundColor: theme.palette.warning.contrastText,
                    borderRadius: 3,
                    pl: 3,
                    pr: 3,
                    pb: 2,
                    mb: 1,
                }}
                required
                fullWidth
            >
                <Select
                    sx={{
                        height: 50,
                    }}
                    name={'resourceFieldSlot'}
                    value={formik.values.resourceFieldSlot}
                    variant="standard"
                    IconComponent={KeyboardArrowDownIcon}
                    onChange={formik.handleChange}
                >
                    {resourceFieldSlotItems.map((resourceFieldSlot: ResourceFieldSlotItemTypes) => {
                        return (
                            <MenuItem key={`resourceField-${resourceFieldSlot.uuid}`} value={resourceFieldSlot.id}>
                                <Typography>{resourceFieldSlot.title}</Typography>
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Box>
    );
};

export default ResourceFieldSlotElements;
