import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {
    ResourceFieldCalendarSlotFormTypes,
    ResourceFieldCalendarSlotItemTypes,
} from 'appRedux/actions/resourceFields/types';
import {UPDATE_RESOURCE_FIELD_CALENDAR_SLOT} from 'appRedux/actions/resourceFields';

import {AlertContext} from 'contexts/alert/context';

import ResourceFieldCalendarSlotForm from 'components/Forms/ResourceField/ResourceFieldCalendarSlotForm';

interface UpdateResourceFieldCalendarSlotFormType {
    item: ResourceFieldCalendarSlotItemTypes;
    closeModal: () => void;
}

const UpdateResourceFieldCalendarSlotForm: FC<UpdateResourceFieldCalendarSlotFormType> = ({item, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {uuid, name, duration} = item;

    const updateResourceFieldCalendarSlot = useCallback(
        data => dispatch({type: UPDATE_RESOURCE_FIELD_CALENDAR_SLOT.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: ResourceFieldCalendarSlotFormTypes) => {
        updateResourceFieldCalendarSlot({
            ...values,
            uuid,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <ResourceFieldCalendarSlotForm
            initialValues={{
                name,
                duration,
            }}
            onSubmitClicked={onSubmitClicked}
        />
    );
};

export default UpdateResourceFieldCalendarSlotForm;
