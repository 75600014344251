import React, {FC} from 'react';

import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

interface DeleteAdminConfirmationDescriptionType {
    description: string;
    descriptionWithAlert?: boolean;
}

const DeleteAdminConfirmationDescription: FC<DeleteAdminConfirmationDescriptionType> = ({
    description,
    descriptionWithAlert,
}) => {
    if (descriptionWithAlert) {
        return (
            <Alert severity={'error'} sx={{mb: 3}}>
                <Typography variant="body1">{description}</Typography>
            </Alert>
        );
    }
    return (
        <Typography variant="body1" sx={{mb: 3}}>
            {description}
        </Typography>
    );
};

export default DeleteAdminConfirmationDescription;
