import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import Markdown from 'react-markdown';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import {OrganizationTemplateItemTypes} from 'appRedux/actions/organization/types';

interface OrganizationTemplateSubjectAndTextType {
    organizationId: number;
    item: OrganizationTemplateItemTypes;
}

const OrganizationTemplateSubjectAndText: FC<OrganizationTemplateSubjectAndTextType> = ({item, organizationId}) => {
    const [t] = useTranslation();

    const {subject, text} = item;

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    mt: 1,
                }}
            >
                <Typography sx={{fontWeight: 600, fontSize: 16, mr: 1}}>{`${t('orguser.mailer.subject')}:`}</Typography>
                <Typography>{subject}</Typography>
            </Box>
            <Divider sx={{mt: 2, mb: 2, mr: 3}} />
            <Box sx={{display: 'block'}}>
                <Markdown>{text}</Markdown>
            </Box>
        </Box>
    );
};

export default OrganizationTemplateSubjectAndText;
