import React, {FC, useCallback} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import MainLogo from 'assets/mainLogo';

import {SET_ERRORS} from 'appRedux/actions/errors';

import {routes} from 'config/index';

const MainLogoWithLink: FC = () => {
    const dispatch = useDispatch();

    const refreshErrors = useCallback(data => dispatch({type: SET_ERRORS.GENERAL_ERROR, payload: data}), [dispatch]);

    const onLogoClick = () => {
        refreshErrors(null);
    };

    return (
        <Link to={routes.START} style={{textAlign: 'center'}} onClick={onLogoClick}>
            <MainLogo />
        </Link>
    );
};

export default MainLogoWithLink;
