import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import OrganizationSsoClientSecretForm from 'components/Forms/OrganizationSsoCredentialsForm/OrganizationSsoClientSecretForm';

interface UpdateOrganizationSsoClientSecretButtonType {
    organizationSsoId: number;
}

const UpdateOrganizationSsoClientSecretButton: FC<UpdateOrganizationSsoClientSecretButtonType> = ({
    organizationSsoId,
}) => {
    const [t] = useTranslation();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpen(previous => !previous);
    };

    return (
        <>
            <AgentSaveButton
                title={'superadmin.organizations.updateClientSecret'}
                isSubmit={false}
                onClick={toggleModal}
            />
            <ModalWrapper
                title={t('superadmin.organizations.updateClientSecret')}
                isShowModal={isOpen}
                toggleModal={toggleModal}
            >
                <OrganizationSsoClientSecretForm
                    organizationSsoId={organizationSsoId}
                    closeModal={() => setIsOpen(false)}
                />
            </ModalWrapper>
        </>
    );
};

export default UpdateOrganizationSsoClientSecretButton;
