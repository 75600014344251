import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';

import BackBtn from 'components/BackBtn/BackBtn';

import InfoForPrint from 'pages/agent/print/partials/InfoForPrint';

const PrintViewContent: FC = () => {
    const [t] = useTranslation();

    const {
        admin: {
            formInfo: {pages, statuses},
        },
        requestCase: {
            currentCase: {statusTitle, agentEmail, formTitle, statusUuid},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const currentStatus = statuses ? statuses.find(item => item.uuid === statusUuid) : null;

    return (
        <Grid container>
            <Grid item sm={2}>
                <BackBtn />
            </Grid>
            <Grid item sm={8}>
                <Box sx={{pt: 3, pb: 3}}>
                    <Grid container sx={{mb: 2}}>
                        <Grid item sm={2}>
                            <Typography sx={{fontWeight: 600}}>{`${t('orguser.requesterCase.form')}:`}</Typography>
                        </Grid>
                        <Grid item sm={10}>
                            <Typography>{formTitle}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{mb: 2}}>
                        <Grid item sm={2}>
                            <Typography sx={{fontWeight: 600}}>{`${t('orguser.requesterCase.agent')}:`}</Typography>
                        </Grid>
                        <Grid item sm={10}>
                            <Typography>{agentEmail}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{mb: 2}}>
                        <Grid item sm={2}>
                            <Typography sx={{fontWeight: 600}}>{`${t('orguser.requesterCase.status')}:`}</Typography>
                        </Grid>
                        <Grid item sm={10}>
                            <Typography>{currentStatus ? currentStatus.title : statusTitle}</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <InfoForPrint pages={pages} />
            </Grid>
            <Grid item sm={2}></Grid>
        </Grid>
    );
};

export default PrintViewContent;
