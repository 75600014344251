import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import {GeneralTemplateTypes} from 'appRedux/actions/generalTemplates/types';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';
import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import GeneralTemplateForm from 'components/Forms/GeneralTemplateForm/GeneralTemplateForm';

import {theme} from 'config/theme';

interface TemplateEditButtonType {
    language: number;
    item: GeneralTemplateTypes;
}

const TemplateEditButton: FC<TemplateEditButtonType> = ({language, item}) => {
    const [t] = useTranslation();

    const {uuid} = item;

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <>
            <IconButton
                title={uuid ? t('common.buttons.update') : t('common.buttons.add')}
                onClick={toggleIsOpened}
                sx={{
                    backgroundColor: uuid ? 'initial' : theme.palette.info.main,
                    '&:hover': {
                        backgroundColor: uuid ? 'initial' : theme.palette.info.main,
                    },
                }}
            >
                {uuid ? <EditSvgIcon /> : <PlusSvgIcon isActive />}
            </IconButton>
            <ModalWrapper
                title={uuid ? t('superadmin.mailer.updateTemplate') : t('superadmin.mailer.createTemplate')}
                toggleModal={toggleIsOpened}
                isShowModal={isOpened}
                isWideModal
            >
                <GeneralTemplateForm language={language} item={item} closeModal={() => setIsOpened(false)} />
            </ModalWrapper>
        </>
    );
};

export default TemplateEditButton;
