import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Markdown from 'react-markdown';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';

import LoginPageWrapper from 'pages/start/login/partials/LoginPageWrapper';

const PrivacyPolicyPage: FC = () => {
    const [t] = useTranslation();

    const {
        auth: {organization},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    if (!organization) return null;

    const {applicationPrivacyPolicy, organizationPrivacyPolicy} = organization;

    return (
        <LoginPageWrapper>
            {organizationPrivacyPolicy && (
                <Box sx={{mb: 2}}>
                    <Typography sx={{fontSize: 18, fontWeight: 600, mt: 1, mb: 0.5, textAlign: 'center'}}>
                        {t('common.organizationPrivacyPolicy')}
                    </Typography>
                    <Markdown>{organizationPrivacyPolicy}</Markdown>
                </Box>
            )}
            {applicationPrivacyPolicy && (
                <Box sx={{mb: 2}}>
                    <Typography sx={{fontSize: 18, fontWeight: 600, mt: 1, mb: 0.5, textAlign: 'center'}}>
                        {t('common.applicationPrivacyPolicy')}
                    </Typography>
                    <Markdown>{applicationPrivacyPolicy}</Markdown>
                </Box>
            )}
        </LoginPageWrapper>
    );
};

export default PrivacyPolicyPage;
