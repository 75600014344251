import React, {FC, useContext, DragEvent, useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';
import {useSearchParams} from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import {RequesterCaseResponseTypes} from 'appRedux/actions/admin/types';
import {RootReducer} from 'appRedux/reducers';
import {REQUESTER_CASES_LIST} from 'appRedux/actions/admin';
import {FormFieldFilterType} from 'appRedux/actions/forms/types';

import {AdminContext} from 'contexts/admin/context';
import {PermissionContext} from 'contexts/permission/context';

import RequesterCaseBlock from 'components/RequesterCaseBlocks/RequesterCaseBlock';
import {getParameterFromUrl, getSearchFromUrl} from 'components/AdminScreenComponents/translationsHelper';
import DragPlaceholderBlock from 'components/BlockView/DragPlaceholderBlock';
import {
    BoardViewCasesTypes,
    getDragColumnColor,
    SWIMLANE_COLUMN_ITEMS_INCREMENT,
    SwimlaneViewCasesTypes,
} from 'components/BlockView/helper';
import LoadMoreButton from 'components/BlockView/LoadMoreButton';

import {getFormFieldOptions, getRequesterCases} from 'pages/agent/tableView/helper';

import {
    PARAMETER_TAG,
    PARAMETER_FORM,
    PARAMETER_AGENT,
    PARAMETER_SEARCH,
    PARAMETER_LAST_CREATED,
    PARAMETER_LAST_UPDATED,
    PARAMETER_SORT_BY,
    PARAMETER_SORT_TYPE,
    STATUS_OPENING,
} from 'config/index';

interface CasesSwimlaneColumnType {
    item: BoardViewCasesTypes;
    boardUuid: string;
    currentSwimlane: SwimlaneViewCasesTypes;
    isActiveCases?: boolean;
}

const CasesSwimlaneColumn: FC<CasesSwimlaneColumnType> = ({item, boardUuid, currentSwimlane, isActiveCases}) => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const {status, statusType, permittedUuids, cases} = item;

    const {isCaseEditEnabled} = useContext(PermissionContext);
    const {draggedCasePermittedUuids, setDragType, isDragStart, draggedCaseInitialStatus} = useContext(AdminContext);

    const {
        profile: {profile},
        admin: {formFieldFilters, requesterCasesSwimlanes},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getRequesterCasesLoadSwimlane = useCallback(
        data => dispatch({type: REQUESTER_CASES_LIST.REQUEST_LOAD_SWIMLANE, payload: data}),
        [dispatch],
    );

    const agentFromUrl = searchParams.get(PARAMETER_AGENT);
    const formFromUrl = searchParams.get(PARAMETER_FORM);
    const searchFromUrl = searchParams.get(PARAMETER_SEARCH);
    const lastCreatedFromUrl = searchParams.get(PARAMETER_LAST_CREATED);
    const lastUpdatedFromUrl = searchParams.get(PARAMETER_LAST_UPDATED);
    const tagsFromUrl = searchParams.get(PARAMETER_TAG);

    const onDragEnter = (e: DragEvent<HTMLDivElement>, type: string) => {
        if (!isCaseEditEnabled) {
            return;
        }
        setDragType(type);
    };

    const currentUserEmail = get(profile, 'email', null);

    const totalItems = get(item, ['casesNumber'], 0);
    const showLoadMore = cases && cases.length < totalItems;

    const [isLoading, setIsLoading] = useState(false);

    console.log(currentSwimlane);

    const {swimlaneParameter, id} = currentSwimlane;

    const handleContainerOnBottom = () => {
        if (!showLoadMore) return;
        setIsLoading(true);
        getRequesterCasesLoadSwimlane({
            uuid: boardUuid,
            agent: getParameterFromUrl(agentFromUrl),
            form: getParameterFromUrl(formFromUrl),
            tags: getParameterFromUrl(tagsFromUrl),
            lastCreated: getParameterFromUrl(lastCreatedFromUrl),
            lastUpdated: getParameterFromUrl(lastUpdatedFromUrl),
            search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
            perPage: cases.length + SWIMLANE_COLUMN_ITEMS_INCREMENT,
            swimlaneParameter,
            swimlaneId: id,
            callback: () => {
                setIsLoading(false);
            },
        });
    };

    const filters: FormFieldFilterType[] = get(formFieldFilters, 'filters', []);
    const filteredCases = cases
        ? getRequesterCases(
              cases,
              searchParams.get(PARAMETER_SORT_BY),
              searchParams.get(PARAMETER_SORT_TYPE),
              getFormFieldOptions(filters, searchParams),
          )
        : [];

    const isDragPossible = permittedUuids && draggedCasePermittedUuids.includes(status);
    const isColumnHighlighted = isDragStart && draggedCaseInitialStatus !== status;

    return (
        <Grid
            item
            key={`column-${status}`}
            sm={isActiveCases ? 4 : 2}
            sx={{}}
            onDragEnter={e => onDragEnter(e, status)}
            draggable={isCaseEditEnabled && statusType !== STATUS_OPENING}
        >
            <Box
                sx={{
                    backgroundColor: isColumnHighlighted ? getDragColumnColor(isDragPossible) : 'initial',
                    border: isColumnHighlighted ? '1px dashed rgba(34, 34, 34, 0.2)' : 'initial',
                    borderRadius: isColumnHighlighted ? 2 : 'initial',
                    p: 1,
                    ml: 0.5,
                    mr: 0.5,
                }}
            >
                {filteredCases.map((caseItem: RequesterCaseResponseTypes) => {
                    return (
                        <RequesterCaseBlock
                            key={`${status}-${caseItem.caseId}`}
                            item={caseItem}
                            permittedUuids={permittedUuids}
                            currentUserEmail={currentUserEmail}
                        />
                    );
                })}
                {showLoadMore && <LoadMoreButton onClick={handleContainerOnBottom} isLoading={isLoading} />}
                {isCaseEditEnabled && draggedCaseInitialStatus !== status && <DragPlaceholderBlock />}
            </Box>
        </Grid>
    );
};

export default CasesSwimlaneColumn;
