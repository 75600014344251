import get from 'lodash/get';

import {RequesterCaseResponseTypes} from 'appRedux/actions/admin/types';

import {FormTranslationType} from 'helpers/translationsHelper';

export const MINIMAL_SEARCH_LENGTH = 3;

export const searchTableItems = (items: RequesterCaseResponseTypes[], search: string, fields: string[]) => {
    return items && items.length > 0
        ? items.filter(item => {
              if (search.length >= MINIMAL_SEARCH_LENGTH) {
                  for (let i = 0, n = fields.length; i < n; i++) {
                      const field = fields[i];
                      const value: string = get(item, field, '');
                      if (value && value.toLowerCase().indexOf(search.toLowerCase()) >= 0) {
                          return true;
                      }
                  }
                  return false;
              }
              return true;
          })
        : [];
};

export const searchTransFormData = (items: FormTranslationType[], search: string | null) => {
    if (!search) {
        return items;
    }
    return items && items.length > 0
        ? items.filter(trans => {
              return search.length >= MINIMAL_SEARCH_LENGTH
                  ? trans.keyword.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
                        trans.english.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
                        trans.translation.toLowerCase().indexOf(search.toLowerCase()) >= 0
                  : true;
          })
        : [];
};
