import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Formik, FormikProps} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {useParams} from 'react-router';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {RootReducer} from 'appRedux/reducers';
import {RequesterCaseMultipleAssignAgentType, AgentItemBasic} from 'appRedux/actions/admin/types';
import {REQUEST_CASE_ASSIGN_AGENT_MULTIPLE} from 'appRedux/actions/admin';

import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';
import {AdminContext} from 'contexts/admin/context';

import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {getParameterFromUrl, getSearchFromUrl} from 'components/AdminScreenComponents/translationsHelper';
import AvatarImage from 'components/UserAvatar/AvatarImage';
import {getAvatarTitle} from 'components/TopBarMenu/helper';

import {getCasesStatus} from 'pages/agent/tableView/helper';

import {
    PARAMETER_TAG,
    PARAMETER_TAB,
    DEFAULT_PAGE,
    PARAMETER_FORM,
    PARAMETER_STATUS,
    PARAMETER_SEARCH,
    ITEMS_PER_PAGE_DESKTOP,
    PARAMETER_FIRST_LETTER,
    PARAMETER_AGENT,
} from 'config/index';

interface AssignAgentFormType {
    closeModal: () => void;
    pinnedCasesList?: boolean;
}

const AssignAgentForm: FC<AssignAgentFormType> = ({closeModal, pinnedCasesList}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const {uuid} = useParams();

    const {showAlert} = useContext(AlertContext);
    const {isBoardListCasesPage, isBoardOverviewCasesPage} = useContext(RouteContext);
    const {selectedIds, selectAllFiltered, setSelectedIds, setSelectAllFiltered, setIsSelectClicked} =
        useContext(AdminContext);

    const {
        admin: {isLoading, agentList, requesterCasesNumber},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onSubmitMultipleClicked = useCallback(
        data => dispatch({type: REQUEST_CASE_ASSIGN_AGENT_MULTIPLE.REQUEST, payload: data}),
        [dispatch],
    );

    const currentTab = searchParams.get(PARAMETER_TAB);
    const statusFromUrl = searchParams.get(PARAMETER_STATUS);
    const formFromUrl = searchParams.get(PARAMETER_FORM);
    const searchFromUrl = searchParams.get(PARAMETER_SEARCH);
    const tagsFromUrl = searchParams.get(PARAMETER_TAG);
    const firstLetterFromUrl = searchParams.get(PARAMETER_FIRST_LETTER) ?? '';
    const agentFromUrl = searchParams.get(PARAMETER_AGENT) ?? '';

    return (
        <Box sx={{pr: 2}}>
            <Typography sx={{mb: 2}}>
                {t('orguser.requesterCase.changeMultipleCases').replace(
                    '%NUMBER%',
                    String(selectAllFiltered ? requesterCasesNumber : selectedIds.length),
                )}
            </Typography>
            <Formik
                initialValues={{
                    ids: selectedIds,
                    agent: agentFromUrl,
                    newAgent: 0,
                    isAlphabetList: Number(isBoardListCasesPage),
                    isOverviewBoard: Number(isBoardOverviewCasesPage),
                    isPinnedList: Number(pinnedCasesList !== undefined),
                    firstLetter: firstLetterFromUrl,
                    page: DEFAULT_PAGE,
                    perPage: ITEMS_PER_PAGE_DESKTOP,
                    status: getCasesStatus(statusFromUrl, currentTab),
                    form: getParameterFromUrl(formFromUrl),
                    tags: getParameterFromUrl(tagsFromUrl),
                    search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
                    currentPageStatus: getCasesStatus(statusFromUrl, currentTab),
                    board: uuid ? String(uuid) : '',
                }}
                onSubmit={values => {
                    onSubmitMultipleClicked({
                        ...values,
                        callback: () => {
                            closeModal();
                            setSelectAllFiltered(false);
                            setSelectedIds([]);
                            setIsSelectClicked(false);
                        },
                        showAlert,
                    });
                }}
            >
                {(formik: FormikProps<RequesterCaseMultipleAssignAgentType>) => {
                    return (
                        <Form>
                            <FormControl fullWidth>
                                <Select
                                    name="newAgent"
                                    fullWidth
                                    value={formik.values.newAgent}
                                    variant="standard"
                                    onChange={formik.handleChange}
                                    sx={{
                                        '& div': {
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                        },
                                    }}
                                    IconComponent={KeyboardArrowDownIcon}
                                >
                                    {agentList &&
                                        agentList.map((agent: AgentItemBasic, index: number) => {
                                            const {avatarType, avatarLink, firstName, lastName, email} = agent;
                                            const avatarTitle = getAvatarTitle(firstName, lastName, email);
                                            return (
                                                <MenuItem
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-start',
                                                        mb: 1,
                                                    }}
                                                    key={`agent-${index}`}
                                                    value={agent.id}
                                                >
                                                    <AvatarImage
                                                        size={25}
                                                        avatarType={avatarType}
                                                        avatarLink={avatarLink}
                                                        avatarTitle={avatarTitle}
                                                    />
                                                    <Typography sx={{ml: 1, fontWeight: 600}}>
                                                        {firstName && lastName ? `${firstName} ${lastName}` : email}
                                                    </Typography>
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                            <Box
                                sx={{
                                    mt: 3,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <AgentInverseButton title={t('common.buttons.cancel')} onClick={closeModal} />
                                <AgentSaveButton isLoading={isLoading} isSubmit title={t('common.buttons.confirm')} />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default AssignAgentForm;
