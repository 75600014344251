import {FC} from 'react';

import TranslateSvgIcon from 'assets/icons/buttons/TranslateSvgIcon';
import UserRoleSvgIcon from 'assets/icons/buttons/UserRoleSvgIcon';
import NotificationSvgIcon from 'assets/icons/buttons/NotificationSvgIcon';
import {CommonSvgIconType} from 'assets/icons/buttons/helper';

import {NOTIFICATION_TYPE_AGENT_ASSIGNED, NOTIFICATION_TYPE_TRANSLATION_REQUEST} from 'config/index';

export const getIconByNotificationType = (type: number): FC<CommonSvgIconType> => {
    if (type === NOTIFICATION_TYPE_TRANSLATION_REQUEST) {
        return TranslateSvgIcon;
    }
    if (type === NOTIFICATION_TYPE_AGENT_ASSIGNED) {
        return UserRoleSvgIcon;
    }
    return NotificationSvgIcon;
};
