import React, {FC, useCallback, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';

import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import RedirectSvgIcon from 'assets/icons/buttons/RedirectSvgIcon';

import {FormSectionMoveRequestFormType, FormPageTypes} from 'appRedux/actions/forms/types';
import {FORM_SECTION_MOVE} from 'appRedux/actions/forms';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {theme} from 'config/theme';

import {preventSendForm} from 'helpers/index';

interface MoveFormSectionType {
    pageId: number;
    currentSectionId: number;
    closeModal: () => void;
}

const MoveFormSection: FC<MoveFormSectionType> = ({pageId, currentSectionId, closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const formSectionMove = useCallback(data => dispatch({type: FORM_SECTION_MOVE.REQUEST, payload: data}), [dispatch]);

    const {
        admin: {
            formInfo: {pages},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Formik
            initialValues={{
                pageId,
            }}
            onSubmit={(values: FormSectionMoveRequestFormType) => {
                formSectionMove({
                    id: currentSectionId,
                    ...values,
                    showAlert,
                    callback: closeModal,
                });
            }}
        >
            {(formik: FormikProps<FormSectionMoveRequestFormType>) => {
                return (
                    <Form onKeyDown={preventSendForm}>
                        <Box>
                            <Typography variant="body2" sx={{mb: 1}}>
                                {t('orguser.forms.formField.selectPage')}
                            </Typography>
                            <FormControl
                                sx={{
                                    backgroundColor: theme.palette.warning.contrastText,
                                    borderRadius: 3,
                                    pl: 3,
                                    pr: 3,
                                    pb: 2,
                                    mb: 2,
                                }}
                                required
                                fullWidth
                            >
                                <Select
                                    sx={{
                                        height: 50,
                                    }}
                                    name={'pageId'}
                                    value={formik.values.pageId}
                                    variant="standard"
                                    onChange={formik.handleChange}
                                    IconComponent={KeyboardArrowDownIcon}
                                    required
                                >
                                    {pages.map((item: FormPageTypes, index: number) => {
                                        const {title, id} = item;
                                        if (id !== pageId) {
                                            return (
                                                <MenuItem key={`page-${index}`} value={id}>
                                                    <Typography>{title}</Typography>
                                                </MenuItem>
                                            );
                                        }
                                        return null;
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mt: 1}}>
                            <AgentSaveButton
                                isSubmit
                                isDisabled={!formik.values.pageId}
                                icon={<RedirectSvgIcon isActive />}
                                title={t('common.buttons.move')}
                            />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default MoveFormSection;
