import React, {FC, useCallback, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import {FORM_POPUP_UPDATE} from 'appRedux/actions/forms';
import {FormPageTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import PopupForm from 'components/Forms/FormBuilder/PopupForm/Form';
import {formPopupInitialValues} from 'components/Forms/FormBuilder/PopupForm/validation';

import {getPopupById} from 'pages/admin/updateForm/partials/FormStructure/helper';

interface UpdateFormPopupType {
    popupId: number;
    toggleModal: () => void;
}

const UpdateFormPopup: FC<UpdateFormPopupType> = ({popupId, toggleModal}) => {
    const dispatch = useDispatch();

    const {
        admin: {formInfo},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const pages: FormPageTypes[] = get(formInfo, 'pages', []);

    const submitUpdateForm = useCallback(
        data => dispatch({type: FORM_POPUP_UPDATE.REQUEST, payload: data}),
        [dispatch],
    );

    const initialValues = {...formPopupInitialValues, ...getPopupById(pages, popupId)};

    return (
        <PopupForm
            entityId={popupId}
            initialValues={initialValues}
            onSubmitAction={submitUpdateForm}
            toggleModal={toggleModal}
        />
    );
};

export default UpdateFormPopup;
