import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const RelateSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" transform="translate(0 0.513184)" fill="white" fillOpacity="0.01" />
            <path
                d="M9 12.0132H8V11.0132C7.99956 10.6155 7.84139 10.2342 7.56018 9.95301C7.27897 9.6718 6.89769 9.51362 6.5 9.51318H4.5C4.10231 9.51362 3.72103 9.6718 3.43982 9.95301C3.15861 10.2342 3.00044 10.6155 3 11.0132V12.0132H2V11.0132C2.00078 10.3504 2.26442 9.71495 2.7331 9.24628C3.20177 8.77761 3.8372 8.51396 4.5 8.51318H6.5C7.1628 8.51396 7.79823 8.77761 8.2669 9.24628C8.73558 9.71495 8.99922 10.3504 9 11.0132V12.0132Z"
                fill={getIconColor(isActive, isDisabled)}
            />
            <path
                d="M5.5 3.51318C5.79667 3.51318 6.08668 3.60116 6.33336 3.76598C6.58003 3.9308 6.77229 4.16507 6.88582 4.43916C6.99935 4.71325 7.02906 5.01485 6.97118 5.30582C6.9133 5.59679 6.77044 5.86407 6.56066 6.07384C6.35088 6.28362 6.08361 6.42648 5.79264 6.48436C5.50166 6.54224 5.20006 6.51253 4.92597 6.399C4.65189 6.28547 4.41762 6.09321 4.2528 5.84654C4.08797 5.59987 4 5.30986 4 5.01318C4 4.61536 4.15804 4.23383 4.43934 3.95252C4.72064 3.67122 5.10218 3.51318 5.5 3.51318ZM5.5 2.51318C5.00555 2.51318 4.5222 2.65981 4.11107 2.93451C3.69995 3.20921 3.37952 3.59966 3.1903 4.05647C3.00108 4.51329 2.95157 5.01596 3.04804 5.50091C3.1445 5.98586 3.3826 6.43132 3.73223 6.78095C4.08186 7.13058 4.52732 7.36868 5.01227 7.46515C5.49723 7.56161 5.99989 7.5121 6.45671 7.32288C6.91352 7.13366 7.30397 6.81323 7.57867 6.40211C7.85338 5.99099 8 5.50764 8 5.01318C8 4.35014 7.73661 3.71426 7.26777 3.24542C6.79893 2.77658 6.16304 2.51318 5.5 2.51318Z"
                fill={getIconColor(isActive, isDisabled)}
            />
            <path d="M15 13.5132H1V14.5132H15V13.5132Z" fill={getIconColor(isActive, isDisabled)} />
            <path
                d="M11 2.51318V3.51318H13.293L10 6.80618L10.707 7.51318L14 4.22018V6.51318H15V2.51318H11Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default RelateSvgIcon;
