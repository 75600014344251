import React, {FC, ReactNode, useContext, useEffect, useRef, useState} from 'react';

import Box from '@mui/material/Box';

import {RouteContext} from 'contexts/route/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

interface ClientSectionWrapperType {
    id: number;
    title: string;
    isChecked: boolean;
    children: ReactNode;
}

const FormSectionWrapper: FC<ClientSectionWrapperType> = ({children, id, isChecked, title}) => {
    const sectionRef = useRef<HTMLDivElement>();

    const {formSection, setFormSection} = useContext(RouteContext);

    const [isHighlighted, setIsHighlighted] = useState<boolean>(false);

    useEffect(() => {
        if (sectionRef && sectionRef.current) {
            if (Number(formSection) === Number(id)) {
                sectionRef.current.scrollIntoView();
                setFormSection(null);
                setIsHighlighted(true);
            }
        }
    }, [formSection]);

    return (
        <ClientSectionWrapper title={title} isChecked={isChecked} isHighlighted={isHighlighted}>
            <Box sx={{scrollMarginTop: '200px'}} ref={sectionRef}>
                {children}
            </Box>
        </ClientSectionWrapper>
    );
};

export default FormSectionWrapper;
