import React, {FC, useState} from 'react';
import get from 'lodash/get';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import {WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';
import {RootReducer} from 'appRedux/reducers';

import WorkflowStatusColorForm from 'components/Forms/Workflow/workflowStatus/WorkflowStatusColorForm';
import {TAB_FOR_ORG_USER, TAB_FOR_REQUESTER} from 'components/Forms/Workflow/validation';

import WorkflowStatusTab from 'pages/admin/updateWorkflow/partials/tabs/WorkflowStatusTab';

import {getLanguageIdByName} from 'helpers/translationsHelper';

import {theme} from 'config/theme';
import {DEFAULT_LANGUAGE_ID} from 'config/index';

interface WorkflowStatusTabsType {
    item: WorkflowStatusItemTypes;
    currentLanguage: string;
}

const WorkflowStatusTabs: FC<WorkflowStatusTabsType> = ({item, currentLanguage}) => {
    const {title, color, colorRequester, titleRequester, uuid} = item;

    const [tab, setTab] = useState<number | null>(null);
    const [, setIsUpdated] = useState<boolean>(false);

    const {
        admin: {languageList},
        workflow: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onTabChange = (index: number) => {
        setTab(index);
        setIsUpdated(previous => !previous);
    };

    const translationForOrgUser = get(item.translations, [currentLanguage, 'translation'], null);
    const translationForRequester = get(item.translations, [currentLanguage, 'translationRequester'], null);

    const onCloseForm = () => {
        if (!isLoading) {
            setTab(null);
        }
    };

    return (
        <ClickAwayListener onClickAway={onCloseForm}>
            <Box sx={{maxWidth: 800}}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'row'}}>
                    <WorkflowStatusTab
                        item={item}
                        tab={tab}
                        tabIndex={TAB_FOR_ORG_USER}
                        title={translationForOrgUser ? translationForOrgUser : title}
                        onTabChange={onTabChange}
                        currentLanguage={currentLanguage}
                        closeForm={onCloseForm}
                    />
                    <WorkflowStatusTab
                        item={item}
                        tab={tab}
                        tabIndex={TAB_FOR_REQUESTER}
                        title={translationForRequester ? translationForRequester : titleRequester}
                        onTabChange={onTabChange}
                        currentLanguage={currentLanguage}
                        closeForm={onCloseForm}
                    />
                </Box>
                {tab && (
                    <Box
                        sx={{
                            backgroundColor: theme.palette.warning.contrastText,
                            pl: 2,
                            pr: 2,
                            pb: 1,
                            borderTopRightRadius: 10,
                            borderBottomRightRadius: 10,
                            borderBottomLeftRadius: 10,
                            maxWidth: 800,
                        }}
                    >
                        <WorkflowStatusColorForm
                            initialValues={{
                                title,
                                titleRequester,
                                language: getLanguageIdByName(languageList, currentLanguage, DEFAULT_LANGUAGE_ID),
                                color,
                                colorRequester,
                            }}
                            uuid={uuid}
                            isForRequester={tab === TAB_FOR_REQUESTER}
                            initialColor={tab === TAB_FOR_REQUESTER ? colorRequester : color}
                            closeForm={onCloseForm}
                        />
                    </Box>
                )}
            </Box>
        </ClickAwayListener>
    );
};

export default WorkflowStatusTabs;
