import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import {RULE_CREATE} from 'appRedux/actions/rules';
import {RuleRequestType} from 'appRedux/actions/rules/types';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import RuleForm from 'components/Forms/RuleForm/RuleForm';

interface AddRuleButtonType {
    type: number;
    language: number;
    text: string;
}

const AddRuleButton: FC<AddRuleButtonType> = ({type, language, text}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const onSubmitClicked = useCallback(payload => dispatch({type: RULE_CREATE.REQUEST, payload}), [dispatch]);

    const onCreateClick = (values: RuleRequestType) => {
        onSubmitClicked({
            ...values,
            callback: () => setIsOpened(false),
            showAlert,
        });
    };

    return (
        <>
            <AgentSaveButton isSubmit={false} onClick={toggleIsOpened} title={t('superadmin.rules.createNewVersion')} />
            <ModalWrapper
                isWideModal
                isShowModal={isOpened}
                toggleModal={toggleIsOpened}
                title={t('superadmin.rules.createNewVersion')}
            >
                <RuleForm type={type} language={language} text={text} onSubmitClicked={onCreateClick} />
            </ModalWrapper>
        </>
    );
};

export default AddRuleButton;
