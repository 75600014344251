import React, {FC, useContext, ReactNode} from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';

import {MediaContext} from 'contexts/media/context';
import {TableCellWithoutBorder} from 'components/AgentScreenComponents/_form/CustomElements';

import {theme} from 'config/theme';

interface TableResponsiveType {
    columns: {title: string; name: string; align?: string}[];
    rows?: Record<string, string | ReactNode>[];
}

const TableResponsive: FC<TableResponsiveType> = ({columns, rows}) => {
    const {isMobile} = useContext(MediaContext);

    if (isMobile) {
        return (
            <>
                {rows &&
                    rows.map((row, i) => (
                        <Table
                            key={`table-${i}`}
                            sx={{mb: 2, borderTop: i && `1px solid ${theme.palette.info.contrastText}`}}
                        >
                            <TableBody>
                                {columns.map((column, i) => (
                                    <TableRow key={`row-${i}`}>
                                        <TableCellWithoutBorder>
                                            <Typography sx={{fontWeight: 600}}>{column.title}</Typography>
                                        </TableCellWithoutBorder>
                                        <TableCellWithoutBorder>
                                            <Typography>{row[column.name]}</Typography>
                                        </TableCellWithoutBorder>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ))}
            </>
        );
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    {columns.map(column => (
                        <TableCellWithoutBorder key={column.name} align={column.align}>
                            <Typography sx={{fontWeight: 600, pr: 2}}>{column.title}</Typography>
                        </TableCellWithoutBorder>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows?.map((row, index) => (
                    <TableRow key={`session-${index}`}>
                        {columns.map((column, index) => (
                            <TableCellWithoutBorder key={`cell-${index}`} align={column.align}>
                                {typeof row[column.name] === 'string' ? (
                                    <Typography>{row[column.name]}</Typography>
                                ) : (
                                    row[column.name]
                                )}
                            </TableCellWithoutBorder>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default TableResponsive;
