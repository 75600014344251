import React, {FC} from 'react';
import {Link} from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {theme} from 'config/theme';

interface AddButtonType {
    label: string;
    link: string;
}

const AddButton: FC<AddButtonType> = ({label, link}) => {
    return (
        <Link to={link} style={{textDecoration: 'none'}}>
            <AgentSaveButton
                isSubmit={false}
                title={label.toLowerCase()}
                icon={<AddIcon sx={{color: theme.palette.background.default}} />}
            />
        </Link>
    );
};

export default AddButton;
