import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import get from 'lodash/get';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import {REQUEST_CASE_ADD_TAG} from 'appRedux/actions/requestCase';
import {RootReducer} from 'appRedux/reducers';
import {FormTagType} from 'appRedux/actions/forms/types';
import {RequesterCaseTagTypes} from 'appRedux/actions/requestCase/types';

import {AlertContext} from 'contexts/alert/context';

import TagChip from 'components/TagChip/TagChip';

interface RequesterTagFormType {
    callback: () => void;
}

const RequesterTagForm: FC<RequesterTagFormType> = ({callback}) => {
    const dispatch = useDispatch();
    const {caseId} = useParams();

    const {showAlert} = useContext(AlertContext);

    const [requesterStatus, setRequesterStatus] = useState<string>();

    const {
        requestCase: {isLoading, currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const addTagToRequesterCase = useCallback(
        data => dispatch({type: REQUEST_CASE_ADD_TAG.REQUEST, payload: data}),
        [dispatch],
    );

    const handleTagSelect = (tagId?: number) => {
        setRequesterStatus(tagId?.toString());
        if (tagId) {
            addTagToRequesterCase({tag: tagId, id: Number(caseId), showAlert, callback});
        }
    };

    const formTags: FormTagType[] = get(currentCase, 'formTags', []);
    const requesterTags: RequesterCaseTagTypes[] = get(currentCase, 'tags', []);

    return (
        <FormControl fullWidth>
            <RadioGroup
                row
                sx={{
                    p: 1,
                }}
            >
                {formTags &&
                    formTags.map((item: FormTagType, index: number) => {
                        const isAlreadyExists = !!requesterTags.find(requesterItem => requesterItem.tag === item.title);
                        if (!item.id) return null;

                        return (
                            <FormControlLabel
                                key={`color-${index}`}
                                value={isAlreadyExists}
                                disabled={isAlreadyExists}
                                control={
                                    <Radio sx={{mb: 1.5, display: 'none'}} onClick={() => handleTagSelect(item.id)} />
                                }
                                label={
                                    <TagChip
                                        id={String(index)}
                                        title={item.title}
                                        color={item.color}
                                        hasOpacity={!isAlreadyExists}
                                        disabled={isAlreadyExists}
                                    />
                                }
                            />
                        );
                    })}
            </RadioGroup>
        </FormControl>
    );
};

export default RequesterTagForm;
