import React, {FC, useCallback} from 'react';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';

import {FORM_POPUP_ADD} from 'appRedux/actions/forms';

import PopupForm from 'components/Forms/FormBuilder/PopupForm/Form';
import SectionSelector from 'components/Forms/FormBuilder/FieldForm/SectionSelector';
import {formPopupInitialValues} from 'components/Forms/FormBuilder/PopupForm/validation';

interface CreateFormPopupType {
    pageId: number;
    currentSectionId: number;
    setCurrentSectionId: (value: number) => void;
    toggleModal: () => void;
    newItemPriority?: number;
}

const CreateFormPopup: FC<CreateFormPopupType> = ({
    pageId,
    currentSectionId,
    setCurrentSectionId,
    toggleModal,
    newItemPriority,
}) => {
    const dispatch = useDispatch();

    const submitCreateForm = useCallback(data => dispatch({type: FORM_POPUP_ADD.REQUEST, payload: data}), [dispatch]);

    return (
        <Box>
            <SectionSelector
                currentSectionId={currentSectionId}
                pageId={pageId}
                setCurrentSectionId={setCurrentSectionId}
            />
            <PopupForm
                entityId={currentSectionId}
                newItemPriority={newItemPriority}
                initialValues={formPopupInitialValues}
                onSubmitAction={submitCreateForm}
                toggleModal={toggleModal}
                isDisabled={currentSectionId === 0}
                isCreate
            />
        </Box>
    );
};

export default CreateFormPopup;
