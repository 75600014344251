import React, {FC, useContext, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Markdown from 'react-markdown';

import Box from '@mui/material/Box';

import {FormFieldTypes, FormPopupTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {ClientFormContext} from 'contexts/clientForm/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import EmptyArea from 'components/EmptyArea';

import FormFieldSwitcher from 'pages/client/form/partials/FormFieldSwitcher';
import {ContentItemType} from 'pages/client/form/partials/types';
import {isPopupSubmitDisabled, getEmptyPopupRequiredField} from 'pages/client/form/helper';

interface PopupModalFormType {
    toggleModal: () => void;
    initialItems: ContentItemType[];
    itemIndex: number | null;
    formId: number;
    pageId: number;
    sectionId: number;
    popup: FormPopupTypes;
    clientInformation: unknown;
    handleChangePopupInformation: (pageId: number, sectionId: number, popupId: number, value: unknown) => void;
    disabled: boolean;
    onSaveClicked: (isCallAlert?: boolean) => void;
}

const PopupModalForm: FC<PopupModalFormType> = ({
    toggleModal,
    initialItems,
    itemIndex,
    formId,
    pageId,
    sectionId,
    clientInformation,
    handleChangePopupInformation,
    disabled,
    popup,
    onSaveClicked,
}) => {
    const [t] = useTranslation();

    const {setErrorField, setErrorPopup} = useContext(ClientFormContext);

    const {id, description, fields} = popup;

    const [isDisabledSubmit, setIsDisabledSubmit] = useState<boolean>(false);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [popupContent, setPopupContent] = useState<ContentItemType | null>(
        itemIndex !== null ? initialItems[itemIndex] : null,
    );

    const {
        requestCase: {isCaseUpdateLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onPopupFieldChange = (fieldId: number, value: string) => {
        const fieldKeyword = `field-${fieldId}`;
        setPopupContent({
            ...popupContent,
            [fieldKeyword]: value,
        });
    };

    const onSavePopupClicked = () => {
        if (popupContent) {
            if (itemIndex !== null) {
                initialItems[itemIndex] = popupContent;
            } else {
                setErrorPopup && setErrorPopup(null);
                initialItems.push(popupContent);
            }
        }
        handleChangePopupInformation(pageId, sectionId, id, initialItems);
        setIsUpdated(true);
    };

    const onDisabledSaveClicked = () => {
        const emptyFieldId = getEmptyPopupRequiredField(popup, popupContent);
        if (emptyFieldId) {
            setErrorField(emptyFieldId);
        }
    };

    const onModalClose = () => {
        setPopupContent(null);
        toggleModal();
    };

    const isDisabled = isPopupSubmitDisabled(fields, popupContent);

    useEffect(() => {
        if (isUpdated) {
            onSaveClicked(false);
            onModalClose();
            setIsUpdated(false);
        }
    }, [isUpdated]);

    return (
        <Box>
            {description && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        '& p': {
                            px: 1,
                            fontSize: 14,
                            fontWeight: 300,
                        },
                    }}
                >
                    <Markdown>{description}</Markdown>
                </Box>
            )}
            <Box>
                {fields &&
                    fields.map((item: FormFieldTypes, index: number) => {
                        return (
                            <FormFieldSwitcher
                                key={`form-popup-field-${index}`}
                                formId={formId}
                                pageId={pageId}
                                sectionId={sectionId}
                                field={item}
                                clientInformation={clientInformation}
                                handleChangePopupInformation={handleChangePopupInformation}
                                popupContent={popupContent}
                                popupId={popup.id}
                                onPopupFieldChange={onPopupFieldChange}
                                onSaveClicked={onSaveClicked}
                                isPopup
                                setIsDisabledSubmit={setIsDisabledSubmit}
                                disabled={disabled || isCaseUpdateLoading}
                            />
                        );
                    })}
            </Box>
            <EmptyArea />
            {!disabled && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 1,
                        mb: 1,
                        ml: 'auto',
                    }}
                >
                    <AgentSaveButton
                        data-id={`input#form-popup-${formId}-${pageId}-${sectionId}-${popup.id}-save`}
                        isDisabled={isDisabledSubmit}
                        isBigButton
                        onClick={isDisabled ? onDisabledSaveClicked : onSavePopupClicked}
                        isSubmit={false}
                        title={itemIndex !== null ? t('common.buttons.saveButton') : t('common.buttons.add')}
                    />
                </Box>
            )}
        </Box>
    );
};

export default PopupModalForm;
