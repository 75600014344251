import React, {FC, useCallback, useContext, useState} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';

import {CREATE_ORGANIZATION_SSO_CREDENTIALS} from 'appRedux/actions/organization';
import {OrganizationSsoTypes} from 'appRedux/actions/organization/types';
import {OrganizationSsoHasRolesTypes} from 'appRedux/actions/organizationAdmin/types';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import FormBuilderCheckboxReverse from 'components/AgentScreenComponents/_form/FormBuilderCheckboxReverse';
import {createOrganizationSsoValidationSchema} from 'components/Forms/OrganizationSsoCredentialsForm/validation';
import RolesSelectors from 'components/Forms/OrganizationAdminForm/partials/RolesSelectors';

interface OrganizationSsoCredentialsFormType {
    organizationId: number;
}

const OrganizationSsoCreateForm: FC<OrganizationSsoCredentialsFormType> = ({organizationId}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [organizationSsoHasRoles, setOrganizationSsoHasRoles] = useState<OrganizationSsoHasRolesTypes[]>([]);

    const submitUpdateOrganizationSsoCredentials = useCallback(
        data => dispatch({type: CREATE_ORGANIZATION_SSO_CREDENTIALS.REQUEST, payload: data}),
        [dispatch],
    );

    return (
        <Formik
            initialValues={{
                authServer: '',
                realm: '',
                client: '',
                clientSecret: '',
                isActive: false,
                organizationSsoHasRoles: organizationSsoHasRoles,
            }}
            validationSchema={createOrganizationSsoValidationSchema}
            onSubmit={(values, {setErrors}) => {
                submitUpdateOrganizationSsoCredentials({
                    ...values,
                    id: organizationId,
                    organizationSsoHasRoles,
                    setErrors,
                    showAlert,
                });
            }}
        >
            {(formik: FormikProps<OrganizationSsoTypes>) => {
                return (
                    <Form>
                        <Box>
                            <Field
                                name="authServer"
                                label={t('superadmin.organizations.authServer')}
                                type="text"
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box>
                            <Field
                                name="realm"
                                label={t('superadmin.organizations.realm')}
                                type="text"
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box>
                            <Field
                                name="client"
                                label={t('superadmin.organizations.clientId')}
                                type="text"
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box>
                            <Field
                                name="clientSecret"
                                label={t('superadmin.organizations.clientSecret')}
                                type="password"
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box sx={{mb: 3, mt: 3}}>
                            <Field
                                type="checkbox"
                                name="isActive"
                                label={t('superadmin.organizations.isActive')}
                                component={FormBuilderCheckboxReverse}
                            />
                        </Box>
                        <RolesSelectors
                            title={t('superadmin.organizations.organizationSsoRoles')}
                            fieldName={'organizationSsoHasRoles'}
                            userHasRoles={organizationSsoHasRoles}
                            setUserHasRoles={setOrganizationSsoHasRoles}
                        />
                        <Box sx={{mt: 2}}>
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default OrganizationSsoCreateForm;
