import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import {RootReducer} from 'appRedux/reducers';

import AddNewEmail from 'components/Forms/OrganizationAdminForm/partials/AddNewEmail';
import EmailOption from 'components/Forms/OrganizationAdminForm/partials/EmailOption';

import {isMaximalOrganizationUsersNumberReached} from 'pages/admin/updateOrganization/partials/OrganizationQuotas/helper';

import {theme} from 'config/theme';

interface EmailsListType {
    emails: string[];
    setEmails: (value: string[]) => void;
    toggleIsUpdated: () => void;
}

const EmailsList: FC<EmailsListType> = ({emails, setEmails, toggleIsUpdated}) => {
    const [t] = useTranslation();

    const [addNewClicked, setAddNewClicked] = useState<boolean>(false);

    const toggleAddNewClicked = () => {
        setAddNewClicked(previous => !previous);
    };

    const {
        admin: {organizationAdminList, organizationList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const onDeleteClick = (value: string) => {
        const results = emails.filter(item => item !== value);
        setEmails(results);
        toggleIsUpdated();
    };

    return (
        <Box sx={{mt: 2}}>
            {!addNewClicked &&
                myOrganization &&
                !isMaximalOrganizationUsersNumberReached(
                    myOrganization,
                    organizationAdminList.length + emails.length,
                    true,
                ) && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 2,
                        }}
                    >
                        <Typography>{t('superadmin.users.addOrgUserEmail')}</Typography>
                        <IconButton
                            sx={{
                                backgroundColor: theme.palette.info.main,
                                '&:hover': {
                                    backgroundColor: theme.palette.info.main,
                                },
                            }}
                            onClick={toggleAddNewClicked}
                        >
                            <PlusSvgIcon isActive />
                        </IconButton>
                    </Box>
                )}
            <Box>
                {addNewClicked && (
                    <AddNewEmail
                        emails={emails}
                        setEmails={setEmails}
                        toggleIsUpdated={toggleIsUpdated}
                        closeInput={() => setAddNewClicked(false)}
                    />
                )}
                {emails.length > 0 && (
                    <Typography sx={{fontWeight: 600, mt: 1, mb: 1}}>{`${t(
                        'superadmin.users.emailsList',
                    )}:`}</Typography>
                )}
                {emails &&
                    emails.map((email: string, index: number) => {
                        return (
                            <EmailOption
                                emails={emails}
                                setEmails={setEmails}
                                toggleIsUpdated={toggleIsUpdated}
                                email={email}
                                key={`email-${index}`}
                                onDeleteClick={onDeleteClick}
                            />
                        );
                    })}
            </Box>
        </Box>
    );
};

export default EmailsList;
