import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {DELETE_RESOURCE_FIELD} from 'appRedux/actions/resourceFields';
import {ResourceFieldItemTypes} from 'appRedux/actions/resourceFields/types';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationButton from 'components/AdminScreenComponents/DeleteAdminConfirmationButton';

interface DeleteResourceFieldButtonType {
    item: ResourceFieldItemTypes;
}

const DeleteResourceFieldButton: FC<DeleteResourceFieldButtonType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    const {uuid, relatedForms} = item;

    const removeResourceField = useCallback(
        data => dispatch({type: DELETE_RESOURCE_FIELD.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        removeResourceField({
            uuid,
            callback: toggleModal,
            showAlert,
        });
    };

    return (
        <DeleteAdminConfirmationButton
            disabled={false}
            deletingRestrict={relatedForms.length > 0}
            description={relatedForms.length > 0 ? t('orguser.resourceFields.errorRemovingImpossibleRelatedForms') : ''}
            isShowModal={isShowModal}
            toggleModal={toggleModal}
            onClickAction={onClickDelete}
        />
    );
};

export default DeleteResourceFieldButton;
