import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import i18n from 'i18next';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import {UserInformationRequestType} from 'appRedux/actions/profile/types';
import {RootReducer} from 'appRedux/reducers';
import {LANGUAGE_LIST} from 'appRedux/actions/admin';
import {UPDATE_GENERAL_INFORMATION} from 'appRedux/actions/profile';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';

import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';

import {validationSchema} from 'components/Forms/SettingsForms/UserInformationForm/validation';
import AdvancedModeDetails from 'components/Forms/SettingsForms/UserInformationForm/AdvancedModeDetails';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AgentResetButton from 'components/AgentScreenComponents/_buttons/AgentResetButton';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import FormBuilderCheckboxReverse from 'components/AgentScreenComponents/_form/FormBuilderCheckboxReverse';

import {preventSendForm} from 'helpers/index';
import {getLanguageById} from 'helpers/translationsHelper';

import {LOCAL_STORAGE_CURRENT_LANGUAGE} from 'services/localStorage';

const UserInformationForm: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const schema = () => validationSchema(t);

    const {showAlert} = useContext(AlertContext);
    const {isAgentPage, isAdminPage} = useContext(RouteContext);

    const {
        profile: {profile},
        admin: {languageList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const submitUpdateForm = useCallback(
        data => dispatch({type: UPDATE_GENERAL_INFORMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getLanguageList = useCallback(() => dispatch({type: LANGUAGE_LIST.REQUEST}), [dispatch]);

    const enabledLanguages = Array.isArray(languageList)
        ? languageList.filter(item => {
              return item.enabled === true;
          })
        : [];

    const handleSubmit = (values: UserInformationRequestType) => {
        const value = values.language;
        const selectedLanguage = getLanguageById(languageList, value);
        if (selectedLanguage) {
            i18n.changeLanguage(selectedLanguage.name);
            localStorage.setItem(LOCAL_STORAGE_CURRENT_LANGUAGE, selectedLanguage.name);
            submitUpdateForm(values);
        }
    };

    useEffect(() => {
        getLanguageList();
    }, []);

    useEffect(() => {
        if (profile) {
            const value = profile.languageId;
            const selectedLanguage = getLanguageById(languageList, value);
            if (selectedLanguage) {
                i18n.changeLanguage(selectedLanguage.name);
                localStorage.setItem(LOCAL_STORAGE_CURRENT_LANGUAGE, selectedLanguage.name);
            }
        }
    }, [profile]);

    if (!profile) return null;

    const {firstName, lastName, isEmailEnabled, languageId, isAnonymousAccount, isAdvancedMode} = profile;

    return (
        <Formik
            initialValues={{
                firstName,
                lastName,
                isAdvancedMode: isAdvancedMode ?? false,
                isEmailEnabled: isEmailEnabled ?? true,
                language: languageId,
            }}
            validationSchema={schema}
            onSubmit={(values, {setErrors}) => {
                handleSubmit({
                    ...values,
                    setErrors,
                    showAlert,
                });
            }}
        >
            {(formik: FormikProps<UserInformationRequestType>) => {
                return (
                    <Form onKeyDown={preventSendForm}>
                        <Field
                            data-id="input#general-firstname"
                            name="firstName"
                            required
                            label={t('common.account.firstName')}
                            placeholder={t('common.account.firstName')}
                            component={FormikTextInput}
                        />
                        <Field
                            data-id="input#general-lastname"
                            name="lastName"
                            required
                            label={t('common.account.lastName')}
                            placeholder={t('common.account.lastName')}
                            component={FormikTextInput}
                        />
                        <FormControl required fullWidth>
                            <Select
                                data-id="dropdown#general-language"
                                name="language"
                                sx={{
                                    height: 50,
                                    mt: 2,
                                }}
                                value={formik.values.language}
                                variant="standard"
                                onChange={formik.handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                            >
                                {enabledLanguages &&
                                    enabledLanguages.map(({id, name, lang}: LanguageItemTypes) => (
                                        <MenuItem
                                            data-id={`dropdown#general-language-${name}`}
                                            value={id}
                                            key={`dropdown#general-language-${name}`}
                                        >
                                            {`${lang} - ${name}`}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        {isAgentPage && (
                            <Box sx={{mt: 3}}>
                                <Field
                                    type="checkbox"
                                    name="isEmailEnabled"
                                    label={t('common.account.getNotifiedOnAssigment')}
                                    component={FormBuilderCheckboxReverse}
                                />
                            </Box>
                        )}
                        {(isAdminPage || isAgentPage) && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    mt: 3,
                                }}
                            >
                                <Field
                                    type="checkbox"
                                    name="isAdvancedMode"
                                    label={t('common.account.isAdvancedMode')}
                                    component={FormBuilderCheckboxReverse}
                                />
                                <AdvancedModeDetails />
                            </Box>
                        )}
                        {profile && (
                            <Box sx={{mt: 3}}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography sx={{fontWeight: 600}}>{`${t('common.account.nickname')}:`}</Typography>
                                    <Typography sx={{ml: 1}}>{profile.nickname}</Typography>
                                </Box>
                                {!isAnonymousAccount && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            mt: 1,
                                        }}
                                    >
                                        <Typography sx={{fontWeight: 600}}>{`${t(
                                            'common.account.email',
                                        )}:`}</Typography>
                                        <Typography sx={{ml: 1}}>{profile.email}</Typography>
                                    </Box>
                                )}
                            </Box>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                mt: 4,
                            }}
                        >
                            <AgentResetButton data-id={`button#general-reset`} />
                            <AgentSaveButton
                                isSubmit
                                data-id={`button#general-save`}
                                title={'common.buttons.saveChanges'}
                            />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default UserInformationForm;
