import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {ORGANIZATION_INTEGRATION_TOKEN_CREATE} from 'appRedux/actions/organizationIntegrationTokens';
import {OrganizationIntegrationTokenFormTypes} from 'appRedux/actions/organizationIntegrationTokens/types';

import {AlertContext} from 'contexts/alert/context';

import OrganizationIntegrationTokenForm from 'components/Forms/OrganizationIntegrationTokenForm/OrganizationIntegrationTokenForm';

interface ComponentFormType {
    organizationId: number;
    closeModal: () => void;
}

const CreateOrganizationIntegrationTokenForm: FC<ComponentFormType> = ({organizationId, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const createOrganizationIntegrationToken = useCallback(
        data => dispatch({type: ORGANIZATION_INTEGRATION_TOKEN_CREATE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: OrganizationIntegrationTokenFormTypes) => {
        createOrganizationIntegrationToken({
            ...values,
            id: organizationId,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <OrganizationIntegrationTokenForm
            onSubmitClicked={onSubmitClicked}
            initialValues={{
                title: '',
            }}
        />
    );
};

export default CreateOrganizationIntegrationTokenForm;
