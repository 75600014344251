import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import {theme} from 'config/theme';

interface PreviewTabsType {
    tabs: string[];
    currentTab: string;
    setCurrentTab: (value: string) => void;
}

const PreviewTabs: FC<PreviewTabsType> = ({tabs, currentTab, setCurrentTab}) => {
    const [t] = useTranslation();

    const changeTabClicked = (tab: string) => {
        setCurrentTab(tab);
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'row', mx: 1}}>
            {tabs.map((tab: string, index: number) => {
                const isActive = currentTab === tab;
                const tabTitle = tab === 'preview' ? t('common.buttons.preview') : tab;
                return (
                    <Box
                        key={`tab-${index}`}
                        sx={{
                            position: 'relative',
                            cursor: 'pointer',
                            height: 36,
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            backgroundColor: isActive ? theme.palette.info.main : `rgba(34, 34, 34, 0.2)`,
                            mr: 1,
                            pt: 2,
                            pb: 2,
                            flex: '1 1 10px',
                            maxWidth: 120,
                        }}
                        onClick={() => changeTabClicked(tab)}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                pl: 1,
                                pr: 1,
                            }}
                        >
                            <Tooltip
                                title={tabTitle}
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            fontSize: 16,
                                            backgroundColor: 'rgb(97, 97, 97)',
                                        },
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: 200,
                                        fontWeight: 600,
                                        lineHeight: '22px',
                                        color: isActive ? theme.palette.background.default : theme.palette.info.main,
                                    }}
                                >
                                    {tabTitle}
                                </Typography>
                            </Tooltip>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};

export default PreviewTabs;
