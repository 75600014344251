import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {OrganizationTemplateItemTypes, OrganizationTemplateFormType} from 'appRedux/actions/organization/types';
import {ORGANIZATION_TEMPLATE_UPDATE} from 'appRedux/actions/organization';

import {AlertContext} from 'contexts/alert/context';

import OrganizationTemplateForm from 'components/Forms/OrganizationTemplateForm/OrganizationTemplateForm';

interface OrganizationTemplateUpdateFormType {
    item: OrganizationTemplateItemTypes;
    closeModal: () => void;
}

const OrganizationTemplateUpdateForm: FC<OrganizationTemplateUpdateFormType> = ({item, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const updateOrganizationTemplates = useCallback(
        data => dispatch({type: ORGANIZATION_TEMPLATE_UPDATE.REQUEST, payload: data}),
        [dispatch],
    );

    const {type, subject, text, language} = item;

    const onSubmitClicked = (values: OrganizationTemplateFormType) => {
        updateOrganizationTemplates({
            ...values,
            uuid: item.uuid,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <OrganizationTemplateForm
            onSubmitClicked={onSubmitClicked}
            initialValues={{
                type,
                subject,
                text,
                language,
            }}
        />
    );
};

export default OrganizationTemplateUpdateForm;
