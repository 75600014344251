import React, {FC, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {CreateOrganizationRequestTypes} from 'appRedux/actions/organization/types';
import {
    ORGANIZATION_UPLOAD_LOGO,
    ORGANIZATION_UPLOAD_FAVICON,
    ORGANIZATION_LOGO_DELETE,
    ORGANIZATION_FAVICON_DELETE,
} from 'appRedux/actions/organization';
import {RootReducer} from 'appRedux/reducers';

import CommonUploadLogoForm from 'components/Forms/CommonLogoForm/CommonUploadLogoForm';
import IconPreview from 'components/Forms/CommonLogoForm/IconPreview';
import {getLogoFormWidth} from 'components/Forms/OrganizationForm/helper';

import {ORGANIZATION_MEDIA_MAIN_LOGO, ORGANIZATION_MEDIA_NAVBAR_LOGO, ORGANIZATION_MEDIA_FAVICON} from 'config/index';

interface FormLogoFormType {
    id: number;
    type: number;
    initialValues: CreateOrganizationRequestTypes;
}

const OrganizationLogoForm: FC<FormLogoFormType> = ({id, type, initialValues}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {name, mainLogo, navBarLogo, favicon} = initialValues;

    const [logoSource, setLogoSource] = useState<string | null>(null);

    const submitUploadFile = useCallback(
        data => dispatch({type: ORGANIZATION_UPLOAD_LOGO.REQUEST, payload: data}),
        [dispatch],
    );

    const submitUploadFavicon = useCallback(
        data => dispatch({type: ORGANIZATION_UPLOAD_FAVICON.REQUEST, payload: data}),
        [dispatch],
    );

    const deleteOrganizationLogo = useCallback(
        data => dispatch({type: ORGANIZATION_LOGO_DELETE.REQUEST, payload: data}),
        [dispatch],
    );

    const deleteOrganizationFavicon = useCallback(
        data => dispatch({type: ORGANIZATION_FAVICON_DELETE.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        if (type === ORGANIZATION_MEDIA_MAIN_LOGO && mainLogo) {
            setLogoSource(mainLogo);
        }
        if (type === ORGANIZATION_MEDIA_NAVBAR_LOGO && navBarLogo) {
            setLogoSource(navBarLogo);
        }
        if (type === ORGANIZATION_MEDIA_FAVICON && favicon) {
            setLogoSource(favicon);
        }
    }, [mainLogo, navBarLogo, favicon]);

    return (
        <Box
            sx={{
                width: getLogoFormWidth(logoSource, type),
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                pl: 3,
            }}
        >
            {!isLoading ? (
                <IconPreview
                    id={id}
                    type={type}
                    logoSource={logoSource}
                    title={name}
                    setLogoSource={setLogoSource}
                    deleteFormLogo={
                        type === ORGANIZATION_MEDIA_FAVICON ? deleteOrganizationFavicon : deleteOrganizationLogo
                    }
                    isFavicon={type === ORGANIZATION_MEDIA_FAVICON}
                />
            ) : (
                <Typography>{t('common.pleaseWait')}</Typography>
            )}
            {!isLoading && !logoSource && (
                <CommonUploadLogoForm
                    initialValues={{
                        id,
                        file: '',
                    }}
                    identifier="upload-logo"
                    type={type}
                    submitUploadFile={type === ORGANIZATION_MEDIA_FAVICON ? submitUploadFavicon : submitUploadFile}
                    transparentButton
                />
            )}
        </Box>
    );
};

export default OrganizationLogoForm;
