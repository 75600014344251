import React, {FC} from 'react';

import {Box} from '@mui/material';

import CloseSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {BUTTON_HEIGHT, BIG_BUTTON_HEIGHT} from 'components/AgentScreenComponents/_buttons/common';

import {theme} from 'config/theme';

interface SubmitIconButtonType {
    isOutlined?: boolean;
    isBigButton?: boolean;
    onClose: () => void;
}

const AgentCloseIconButton: FC<SubmitIconButtonType> = ({isOutlined, isBigButton, onClose}) => {
    const diameter = (isBigButton ? BIG_BUTTON_HEIGHT : BUTTON_HEIGHT) - (isOutlined ? 8 : 10);
    return (
        <Box
            onClick={event => {
                event.stopPropagation();
                onClose();
            }}
            sx={{
                borderRadius: '50%',
                backgroundColor: isOutlined ? theme.palette.info.main : theme.palette.background.default,
                height: diameter,
                width: diameter,
                mr: -0.5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
            }}
        >
            <CloseSvgIcon isActive={isOutlined} />
        </Box>
    );
};

export default AgentCloseIconButton;
