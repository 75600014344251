import {TFunction} from 'i18next';
import get from 'lodash/get';

import {RequesterCaseResponseTypes, BoardAlphabetResponseType, BoardResponseType} from 'appRedux/actions/admin/types';

import {STATUS_DECLINED, STATUS_FORWARDED, STATUS_HELPED, STATUS_DELETED} from 'config/index';
import {AGENT_TOP_BAR_HEIGHT} from 'components/AgentScreenComponents/helper';
import {FILTERS_PANEL_HEIGHT, SELECTED_FILTERS_PANEL_HEIGHT} from 'components/BlockView/helper';

export const alphabetLetters = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
];

export interface RequesterCasesByLetterType {
    [key: string]: RequesterCaseResponseTypes[];
}

export const getHeaderTop = (showFiltersPanel: boolean, filtersNumber: number): number => {
    let result = AGENT_TOP_BAR_HEIGHT;
    if (showFiltersPanel) {
        result += FILTERS_PANEL_HEIGHT;
    }
    if (filtersNumber > 0 && showFiltersPanel) {
        result += SELECTED_FILTERS_PANEL_HEIGHT;
    }
    return result - 1;
};

export const getCasesByLetter = (items: RequesterCaseResponseTypes[]): RequesterCasesByLetterType => {
    const results: RequesterCasesByLetterType = {};
    alphabetLetters.forEach((letter: string) => {
        results[letter] = items.filter(item => {
            const {lastName} = item;
            return lastName && lastName[0].toLowerCase() === letter;
        });
    });
    return results;
};

export const getRequesterCaseName = (t: TFunction, item: RequesterCaseResponseTypes, isActive?: boolean): string => {
    const {caseLabel, firstName, lastName, email, isAnonymousAccount} = item;
    const username = lastName && firstName ? `${lastName}, ${firstName}` : email;
    if (isAnonymousAccount) {
        return `${caseLabel} (${t('orguser.requesterCase.anonymousAccount')})`;
    }
    return isActive ? caseLabel : username;
};

export const getRequesterCaseUserName = (t: TFunction, item: RequesterCaseResponseTypes): string => {
    const {firstName, lastName, email, isAnonymousAccount} = item;
    const username = lastName && firstName ? `${lastName}, ${firstName}` : email;
    if (isAnonymousAccount) {
        return `${t('orguser.requesterCase.anonymousAccount')}`;
    }
    return username;
};

export const getPageTitle = (status: string) => {
    if (status === STATUS_FORWARDED) {
        return 'orguser.requesterCase.pages.forwardedCases';
    }
    if (status === STATUS_HELPED) {
        return 'orguser.requesterCase.pages.helpedCases';
    }
    if (status === STATUS_DECLINED) {
        return 'orguser.requesterCase.pages.declinedCases';
    }
    if (status === STATUS_DELETED) {
        return 'orguser.requesterCase.pages.deletedCases';
    }
    return 'orguser.requesterCase.pages.activeCases';
};

export const getAgentName = (t: TFunction, item: RequesterCaseResponseTypes): string => {
    const {agentFirstName, agentLastName, agentEmail} = item;
    if (!agentEmail) {
        return t('orguser.requesterCase.notAssigned');
    }
    return agentFirstName && agentLastName ? `${agentFirstName} ${agentLastName}` : agentEmail;
};

export const getCurrentCasesNumber = (requesterCasesAlphabetList: BoardAlphabetResponseType, uuid: string): number => {
    const casesByLetter = get(requesterCasesAlphabetList, [String(uuid), 'results'], []);
    let result = 0;
    Object.entries(casesByLetter).map((item: any) => {
        const cases: RequesterCaseResponseTypes[] = item[1];
        result += cases.length;
    });
    return result;
};

export const getMergedItems = (currentItems: BoardResponseType, newItems: BoardResponseType): BoardResponseType => {
    const results = [];
    alphabetLetters.forEach((letter: string) => {
        const currentItemsByLetter = get(currentItems, letter, []);
        const newItemsByLetter = get(newItems, letter, []);
        if (Array.isArray(currentItemsByLetter) && Array.isArray(newItemsByLetter)) {
            results[letter] = currentItemsByLetter.concat(newItemsByLetter);
        } else {
            results[letter] = currentItemsByLetter;
        }
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return results;
};
