import React, {FC, useContext} from 'react';
import {useLocation, useParams} from 'react-router-dom';

import Box from '@mui/material/Box';

import {RouteContext} from 'contexts/route/context';

import {
    AgentMenuItemTypes,
    isMenuItemActive,
    isMenuSubItemActive,
    isAllSubItemsInactive,
} from 'components/AgentScreenComponents/helper';
import MenuLink from 'components/AgentScreenComponents/MenuLink';

import {isCurrentSubItemActive} from 'helpers/menuHelper';

import {routes} from 'config/index';

interface MenuBlockItemTypes {
    isMenuCollapsed: boolean;
    menuItem: AgentMenuItemTypes;
    toggleAdminMenu: (value: string) => void;
}

const MenuBlockItem: FC<MenuBlockItemTypes> = ({isMenuCollapsed, menuItem, toggleAdminMenu}) => {
    const {pathname, search} = useLocation();
    const {uuid} = useParams();

    const {isAgentPage} = useContext(RouteContext);

    const path = isAgentPage ? routes.AGENT : routes.ADMIN;

    const showSubItems =
        isMenuItemActive(menuItem, pathname, search) || isMenuSubItemActive(menuItem, pathname, search, uuid);

    const isActive = menuItem.subItems
        ? isMenuItemActive(menuItem, pathname, search) &&
          isAllSubItemsInactive(menuItem.subItems, pathname, search, isAgentPage, uuid)
        : showSubItems;

    const subItems = menuItem?.subItems?.filter(subItem => !subItem.isHidden);
    const subItemsOpened = showSubItems && subItems && subItems.length > 0;

    return (
        <Box sx={{mb: 0.25}}>
            <MenuLink
                isActive={isActive}
                isMenuCollapsed={isMenuCollapsed}
                menuItem={menuItem}
                toggleAdminMenu={toggleAdminMenu}
                hasSubItems={subItemsOpened}
            />
            {subItemsOpened && (
                <Box sx={{mt: 0, mb: 2}}>
                    {subItems &&
                        subItems.map((subItem: AgentMenuItemTypes, subIndex: number) => {
                            return (
                                <MenuLink
                                    key={`subItem-${subIndex}`}
                                    isActive={isCurrentSubItemActive(menuItem, subItem, path, pathname, search, uuid)}
                                    isMenuCollapsed={isMenuCollapsed}
                                    menuItem={subItem}
                                    toggleAdminMenu={toggleAdminMenu}
                                    isSubItem
                                />
                            );
                        })}
                </Box>
            )}
        </Box>
    );
};

export default MenuBlockItem;
