import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import {Tooltip} from '@mui/material';

import ClearSaveSvgIcon from 'assets/icons/buttons/ClearSaveSvgIcon';

import {PublicKeyTypes} from 'appRedux/actions/crypto/types';
import {GET_USER_PUBLIC_KEYS, REMOVE_USER_SAVED_KEYS} from 'appRedux/actions/crypto';

import {AlertContext} from 'contexts/alert/context';

import DeleteClientConfirmationModal from 'components/ClientScreenComponents/DeleteClientConfirmationModal';

import {ALERT_TYPE_ERROR} from 'config/index';

interface SaveKeyButtonType {
    item: PublicKeyTypes;
}

const RemoveSavedKeyButton: FC<SaveKeyButtonType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const removeUserKeys = useCallback(
        data => dispatch({type: REMOVE_USER_SAVED_KEYS.REQUEST, payload: data}),
        [dispatch],
    );
    const getUserPublicKeys = useCallback(
        data => dispatch({type: GET_USER_PUBLIC_KEYS.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = async () => {
        try {
            removeUserKeys({
                uuid: item.uuid,
                showAlert,
                callback: () => {
                    toggleIsOpened();
                    getUserPublicKeys({showAlert});
                },
            });
        } catch (e) {
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    };

    return (
        <>
            <Tooltip
                title={t('common.publicKeys.removeSavedCryptoKeysLabel')}
                placement={'top'}
                arrow
                disableInteractive
            >
                <IconButton onClick={toggleIsOpened}>
                    <ClearSaveSvgIcon />
                </IconButton>
            </Tooltip>
            <DeleteClientConfirmationModal
                isShowModal={isOpened}
                toggleModal={toggleIsOpened}
                onClickAction={onClickDelete}
                customTitle="common.publicKeys.removeSavedCryptoKeysTitle"
            />
        </>
    );
};

export default RemoveSavedKeyButton;
