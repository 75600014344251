import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const PrintSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 4.5H12.5V1.5H3.5V4.5H2C1.73478 4.5 1.48043 4.60536 1.29289 4.79289C1.10536 4.98043 1 5.23478 1 5.5V10.5C1 10.7652 1.10536 11.0196 1.29289 11.2071C1.48043 11.3946 1.73478 11.5 2 11.5H3.5V14.5H12.5V11.5H14C14.2652 11.5 14.5196 11.3946 14.7071 11.2071C14.8946 11.0196 15 10.7652 15 10.5V5.5C15 5.23478 14.8946 4.98043 14.7071 4.79289C14.5196 4.60536 14.2652 4.5 14 4.5ZM4.5 2.5H11.5V4.5H4.5V2.5ZM11.5 13.5H4.5V8.5H11.5V13.5ZM14 10.5H12.5V7.5H3.5V10.5H2V5.5H14V10.5Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default PrintSvgIcon;
