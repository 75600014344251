import React, {FC} from 'react';

import {Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

import {PanelItemsTypes} from 'appRedux/actions/analytics/types';

import {TYPE_CASE_STATUS_TIME_ANALYSIS, TYPE_CASE_CHECKBOX_ANALYSIS} from 'components/Forms/Dashboard/helper';

import StatusTimeAnalysisChart from 'pages/admin/analytics/charts/StatusTimeAnalysisChart';
import BarCheckboxAnalysisChart from 'pages/admin/analytics/charts/BarCheckboxAnalysisChart';

import {tagColors} from 'config/theme';

interface BarStatisticChartType {
    item: PanelItemsTypes;
}

const BarStatisticChart: FC<BarStatisticChartType> = ({item}) => {
    if (item.type === TYPE_CASE_STATUS_TIME_ANALYSIS) {
        return <StatusTimeAnalysisChart item={item} />;
    }

    if (item.type === TYPE_CASE_CHECKBOX_ANALYSIS) {
        return <BarCheckboxAnalysisChart item={item} />;
    }

    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart width={300} height={300} data={item.cases}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Bar dataKey="value" fill="#8884d8">
                    {item.cases.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={tagColors[index % 20]} />
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default BarStatisticChart;
