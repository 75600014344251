import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const DomainSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 13H9.40785C9.33329 12.7908 9.21315 12.6009 9.05614 12.4438C8.89912 12.2868 8.70916 12.1666 8.5 12.092V9.5H12C12.2651 9.49967 12.5193 9.39421 12.7067 9.20674C12.8942 9.01928 12.9997 8.76511 13 8.5V2C12.9997 1.73489 12.8942 1.48072 12.7067 1.29326C12.5193 1.10579 12.2651 1.00033 12 1H4C3.73488 1.00033 3.48072 1.10579 3.29326 1.29326C3.10579 1.48072 3.00033 1.73489 3 2V8.5C3.00033 8.76511 3.10579 9.01928 3.29326 9.20674C3.48072 9.39421 3.73488 9.49967 4 9.5H7.5V12.092C7.29084 12.1666 7.10088 12.2868 6.94386 12.4438C6.78685 12.6009 6.66671 12.7908 6.59215 13H2V14H6.59215C6.69404 14.2922 6.8843 14.5454 7.13656 14.7246C7.38882 14.9038 7.69058 15 8 15C8.30942 15 8.61118 14.9038 8.86344 14.7246C9.1157 14.5454 9.30596 14.2922 9.40785 14H14V13ZM12 6H4V4.5H12V6ZM12 2V3.5H4V2H12ZM4 7H12V8.5H4V7ZM8 14C7.90111 14 7.80444 13.9707 7.72221 13.9157C7.63999 13.8608 7.5759 13.7827 7.53806 13.6913C7.50022 13.6 7.49031 13.4994 7.50961 13.4025C7.5289 13.3055 7.57652 13.2164 7.64645 13.1464C7.71637 13.0765 7.80546 13.0289 7.90245 13.0096C7.99945 12.9903 8.09998 13.0002 8.19134 13.0381C8.2827 13.0759 8.36079 13.14 8.41574 13.2222C8.47068 13.3044 8.5 13.4011 8.5 13.5C8.49989 13.6326 8.44718 13.7597 8.35344 13.8534C8.25969 13.9472 8.13258 13.9999 8 14Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default DomainSvgIcon;
