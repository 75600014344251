import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import {buttonOutlineStyles, labelOutlineStyles} from 'components/AgentScreenComponents/_buttons/common';

interface SubmitButtonType {
    isLoading?: boolean;
    title?: string;
    isSubmit?: boolean;
    onClick?: () => void;
    'data-id'?: string;
}

const AgentResetButton: FC<SubmitButtonType> = ({isLoading, title, isSubmit, onClick, 'data-id': dataId}) => {
    const [t] = useTranslation();
    return (
        <Button data-id={dataId} type={'reset'} disabled={isLoading} sx={buttonOutlineStyles} onClick={onClick}>
            <Typography sx={labelOutlineStyles}>{title ?? t('common.buttons.discardChanges')}</Typography>
        </Button>
    );
};

export default AgentResetButton;
