import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';

import {RootReducer} from 'appRedux/reducers';

import CreateRequestTranslationForm from 'components/Forms/CreateRequestTranslationForm/CreateRequestTranslationForm';
import {getUserName} from 'components/TopBarMenu/helper';

import {routes} from 'config/index';

interface RequestTranslationMenuItemType {
    sectionTitle: string;
    casePrefix: string;
    handleClose: () => void;
}

const RequestTranslationMenuItem: FC<RequestTranslationMenuItemType> = ({sectionTitle, casePrefix, handleClose}) => {
    const [t] = useTranslation();
    const {caseId, formId} = useParams();

    const [openModal, setOpenModal] = useState<boolean>(false);

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <>
            <MenuItem onClick={() => setOpenModal(true)}>
                <Typography>{t('orguser.requesterCase.translation.requestTranslation')}</Typography>
            </MenuItem>
            <CreateRequestTranslationForm
                openModal={openModal}
                closeModal={() => {
                    setOpenModal(false);
                    handleClose();
                }}
                text={t('orguser.requesterCase.translation.translationRequestNotification')
                    .replace('%USERNAME%', getUserName(profile))
                    .replace('%CASE%', casePrefix)
                    .replace('%SECTION%', sectionTitle)}
                relatedLink={`${routes.REQUESTER_CASE}/${caseId}/form/${formId}`}
            />
        </>
    );
};

export default RequestTranslationMenuItem;
