import React, {FC, useContext, useRef, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import {FormHelperText, Slider, Stack, Typography} from '@mui/material';

import {RootReducer} from 'appRedux/reducers';

import {ClientFormContext} from 'contexts/clientForm/context';

import FormFieldWrapper from 'pages/client/form/wrappers/FormFieldWrapper';
import {FormFieldPropsType} from 'pages/client/form/partials/types';
import {getInitialFieldValue} from 'pages/client/form/helper';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {ERROR_TEXT_COLOR, theme} from 'config/theme';

const FormRange: FC<FormFieldPropsType> = ({
    formId,
    pageId,
    sectionId,
    field,
    clientInformation,
    onPopupFieldChange,
    handleChangeInformation,
    isPopup,
    popupContent,
    disabled,
    step,
    range,
    previewMode,
    onSaveClicked,
}) => {
    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {id, isRequired, label} = field;
    const [minValue, maxValue] = range && range?.length === 2 ? range.map(opt => Number(opt)) : [];

    const ref = useRef<HTMLDivElement | undefined>();

    const {errorField, setErrorField} = useContext(ClientFormContext);

    const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [value, setValue] = useState<number | number[]>(
        Number(getInitialFieldValue(pageId, sectionId, id, clientInformation, isPopup, popupContent)),
    );

    useEffect(() => {
        setValue(Number(getInitialFieldValue(pageId, sectionId, id, clientInformation, isPopup, popupContent)));
    }, [pageId, sectionId, id, clientInformation, isPopup, popupContent]);

    const handleChange = (e: Event, value: number | number[]) => {
        setValue(value);
        setIsError(false);
        setErrorField && setErrorField(null);
        if (handleChangeInformation) {
            handleChangeInformation(pageId, sectionId, id, String(value));
            setIsInitialRender(false);
            setIsUpdated(previous => !previous);
        }
    };

    const handlePopupFieldChange = (e: Event, value: number | number[]) => {
        setValue(value);
        setIsError(false);
        setErrorField && setErrorField(null);
        if (onPopupFieldChange) {
            onPopupFieldChange(id, String(value));
            setIsInitialRender(false);
            setIsUpdated(previous => !previous);
        }
    };

    useEffect(() => {
        if (id === errorField && ref && ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [errorField]);

    const keywordLabel = getFieldKeyword(formId, id, 'label');

    useEffect(() => {
        if (!isInitialRender) {
            onSaveClicked();
        }
    }, [isUpdated]);

    return (
        <FormFieldWrapper
            data-id={`input#form-textfield-${formId}-${pageId}-${sectionId}-${id}`}
            wrapperRef={ref}
            formId={formId}
            field={field}
            isPopup={isPopup}
            previewMode={previewMode}
        >
            <Box sx={{position: 'relative'}}>
                <Typography
                    sx={{
                        color: isError ? ERROR_TEXT_COLOR : theme.palette.info.main,
                        fontSize: 12,
                        mb: 0.5,
                    }}
                >
                    {`${previewMode ? label : getFormTranslatedLabel(translations, keywordLabel, label)} ${
                        isRequired ? '*' : ''
                    }`}
                </Typography>
                <Stack spacing={2} direction="row" sx={{mb: 1}} alignItems="center">
                    <Typography variant="body2">{minValue || 0}</Typography>
                    <Slider
                        step={step || 1}
                        min={minValue || 0}
                        max={maxValue || 10}
                        value={value}
                        onChange={isPopup ? handlePopupFieldChange : handleChange}
                        disabled={disabled}
                        valueLabelDisplay={'auto'}
                        size="small"
                        color="info"
                    />
                    <Typography variant="body2">{maxValue || 10}</Typography>
                </Stack>
                {isError && <FormHelperText>{isError}</FormHelperText>}
            </Box>
        </FormFieldWrapper>
    );
};

export default FormRange;
