import React, {FC, Ref, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';

import {WorkflowStatusEventItemTypes, WorkflowTemplateFormTypes} from 'appRedux/actions/workflow/types';
import {CREATE_WORKFLOW_TEMPLATE} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import WorkflowTemplateForm from 'components/Forms/Workflow/workflowTemplate/WorkflowTemplateForm';

interface CreateWorkflowTemplateFormType {
    formRef: Ref<any> | null;
    item: WorkflowStatusEventItemTypes;
    closeModal: () => void;
    forEmailTemplate?: boolean;
    forWebhookTemplate?: boolean;
    statusUuid: string;
    language: number;
    setLanguage: (value: number) => void;
}

const CreateWorkflowTemplateForm: FC<CreateWorkflowTemplateFormType> = ({
    formRef,
    language,
    setLanguage,
    item,
    closeModal,
    statusUuid,
    forEmailTemplate,
    forWebhookTemplate,
}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [codeMirrorText, setCodeMirrorText] = useState<string>('');

    const createWorkflowTemplate = useCallback(
        data => dispatch({type: CREATE_WORKFLOW_TEMPLATE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: WorkflowTemplateFormTypes) => {
        createWorkflowTemplate({
            ...values,
            text: forWebhookTemplate ? codeMirrorText : values.text,
            uuid: item.uuid,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <WorkflowTemplateForm
            formRef={formRef}
            initialValues={{
                subject: '',
                text: '',
                language,
            }}
            url={item.url}
            onSubmitClicked={onSubmitClicked}
            translations={item.translations ? item.translations : []}
            forEmailTemplate={forEmailTemplate}
            codeMirrorText={codeMirrorText}
            workflowStatusUuid={statusUuid}
            setCodeMirrorText={setCodeMirrorText}
            forWebhookTemplate={forWebhookTemplate}
            language={language}
            setLanguage={setLanguage}
        />
    );
};

export default CreateWorkflowTemplateForm;
