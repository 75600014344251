import React, {FC, useRef, useState} from 'react';

interface CustomLogoType {
    logoSource: string;
    maxWidth?: number;
    maxHeight?: number;
}

const CustomLogo: FC<CustomLogoType> = ({logoSource, maxWidth, maxHeight}) => {
    const imageRef = useRef<HTMLImageElement>(null);

    const [logoWidth, setLogoWidth] = useState<number>(0);
    const [logoHeight, setLogoHeight] = useState<number>(0);

    const onImageLoad = () => {
        if (imageRef && imageRef.current) {
            setLogoWidth(imageRef.current.width);
            setLogoHeight(imageRef.current.height);
        }
    };

    const isPortraitMode = logoWidth > logoHeight;

    const styles =
        maxHeight && maxWidth
            ? {maxWidth: isPortraitMode ? maxWidth : 'initial', maxHeight: isPortraitMode ? 'initial' : maxHeight}
            : {maxWidth: isPortraitMode ? '100%' : 'initial', maxHeight: isPortraitMode ? 'initial' : '30vh'};

    return <img data-e2e-id="formCustomLogo" src={logoSource} ref={imageRef} onLoad={onImageLoad} style={styles} />;
};

export default CustomLogo;
