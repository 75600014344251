import * as yup from 'yup';

import {REGEX_FOR_DESCRIPTION, REGEX_FOR_TITLES} from 'components/Forms/FormBuilder/helper';

const SNIPPET_TITLE_MINIMAL_LENGTH = 2;
const SNIPPET_TITLE_MAXIMAL_LENGTH = 100;

const SNIPPET_SLOGAN_MINIMAL_LENGTH = 2;
const SNIPPET_SLOGAN_MAXIMAL_LENGTH = 50;

export const validationSchema = (t: (trans: string) => string) =>
    yup.object({
        title: yup
            .string()
            .required('messages.validation.isRequired')
            .matches(REGEX_FOR_TITLES, 'messages.validation.valueStructureValidationLatinNumbersSpecialAllowed')
            .min(
                SNIPPET_TITLE_MINIMAL_LENGTH,
                t('messages.validation.valueMinimalLength').replace('%NUMBER%', String(SNIPPET_TITLE_MINIMAL_LENGTH)),
            )
            .max(
                SNIPPET_TITLE_MAXIMAL_LENGTH,
                t('messages.validation.valueMaximalLength').replace('%NUMBER%', String(SNIPPET_TITLE_MAXIMAL_LENGTH)),
            ),
        description: yup
            .string()
            .nullable()
            .matches(REGEX_FOR_DESCRIPTION, 'messages.validation.valueStructureValidationLatinNumbersSpecialAllowed'),
        slogan: yup
            .string()
            .nullable()
            .matches(REGEX_FOR_TITLES, 'messages.validation.valueStructureValidationLatinNumbersSpecialAllowed')
            .min(
                SNIPPET_SLOGAN_MINIMAL_LENGTH,
                t('messages.validation.valueMinimalLength').replace('%NUMBER%', String(SNIPPET_SLOGAN_MINIMAL_LENGTH)),
            )
            .max(
                SNIPPET_SLOGAN_MAXIMAL_LENGTH,
                t('messages.validation.valueMaximalLength').replace('%NUMBER%', String(SNIPPET_SLOGAN_MAXIMAL_LENGTH)),
            ),
    });

export const validationSchemaNotOnlyLatin = (t: (trans: string) => string) =>
    yup.object({
        title: yup
            .string()
            .required('messages.validation.isRequired')
            .min(
                SNIPPET_TITLE_MINIMAL_LENGTH,
                t('messages.validation.valueMinimalLength').replace('%NUMBER%', String(SNIPPET_TITLE_MINIMAL_LENGTH)),
            )
            .max(
                SNIPPET_TITLE_MAXIMAL_LENGTH,
                t('messages.validation.valueMaximalLength').replace('%NUMBER%', String(SNIPPET_TITLE_MAXIMAL_LENGTH)),
            ),
        description: yup.string().nullable(),
        slogan: yup
            .string()
            .nullable()
            .min(
                SNIPPET_SLOGAN_MINIMAL_LENGTH,
                t('messages.validation.valueMinimalLength').replace('%NUMBER%', String(SNIPPET_SLOGAN_MINIMAL_LENGTH)),
            )
            .max(
                SNIPPET_SLOGAN_MAXIMAL_LENGTH,
                t('messages.validation.valueMaximalLength').replace('%NUMBER%', String(SNIPPET_SLOGAN_MAXIMAL_LENGTH)),
            ),
    });
