import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {UPDATE_ORGANIZATION_PRIVACY_POLICY} from 'appRedux/actions/organization';
import {
    OrganizationPrivacyPolicyFormTypes,
    OrganizationPrivacyPolicyItemTypes,
} from 'appRedux/actions/organization/types';

import {AlertContext} from 'contexts/alert/context';

import OrganizationPrivacyPolicyForm from 'components/Forms/OrganizationPrivacyPolicyForm/OrganizationPrivacyPolicyForm';

interface UpdateOrganizationPrivacyPolicyFormType {
    item: OrganizationPrivacyPolicyItemTypes;
    currentLanguage: string;
    closeModal: () => void;
}

const UpdateOrganizationPrivacyPolicyForm: FC<UpdateOrganizationPrivacyPolicyFormType> = ({
    item,
    currentLanguage,
    closeModal,
}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const updateOrganizationPrivacyPolicy = useCallback(
        data => dispatch({type: UPDATE_ORGANIZATION_PRIVACY_POLICY.REQUEST, payload: data}),
        [dispatch],
    );

    const {title, uuid, isDefault} = item;

    const onSubmitAction = (values: OrganizationPrivacyPolicyFormTypes) => {
        updateOrganizationPrivacyPolicy({
            uuid,
            lang: currentLanguage,
            ...values,
            showAlert,
            callback: closeModal,
        });
    };

    return <OrganizationPrivacyPolicyForm initialValues={{title, isDefault}} onSubmitAction={onSubmitAction} />;
};

export default UpdateOrganizationPrivacyPolicyForm;
