import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const PinSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.295 6.655L15 5.95L10 1L9.345 1.71L9.935 2.3L4.19 7.16L3.33 6.305L2.625 7L5.455 9.84L1 14.29L1.705 15L6.16 10.545L9 13.375L9.695 12.665L8.84 11.81L13.7 6.065L14.295 6.655ZM8.13 11.1L4.9 7.87L10.645 3L13 5.355L8.13 11.1Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default PinSvgIcon;
