import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {ResourceFieldBookframeFormTypes, ResourceFieldBookframeItemTypes} from 'appRedux/actions/resourceFields/types';
import {UPDATE_RESOURCE_FIELD_BOOKFRAME} from 'appRedux/actions/resourceFields';

import {AlertContext} from 'contexts/alert/context';

import ResourceFieldBookframeForm from 'components/Forms/ResourceField/ResourceFieldBookframeForm';

interface UpdateResourceFieldBookframeFormType {
    item: ResourceFieldBookframeItemTypes;
    closeModal: () => void;
}

const UpdateResourceFieldBookframeForm: FC<UpdateResourceFieldBookframeFormType> = ({item, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const updateResourceFieldBookframe = useCallback(
        data => dispatch({type: UPDATE_RESOURCE_FIELD_BOOKFRAME.REQUEST, payload: data}),
        [dispatch],
    );

    const {uuid, weekday, endTime, startTime} = item;

    const onSubmitClicked = (values: ResourceFieldBookframeFormTypes) => {
        updateResourceFieldBookframe({
            ...values,
            uuid,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <ResourceFieldBookframeForm
            initialValues={{
                weekday,
                endTime,
                startTime,
            }}
            onSubmitClicked={onSubmitClicked}
        />
    );
};

export default UpdateResourceFieldBookframeForm;
