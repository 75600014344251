import React, {FC, useState} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import {SelectChangeEvent} from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';

import {DashboardFormTypes, DashboardHasPanelTypes, PanelItemsTypes} from 'appRedux/actions/analytics/types';
import {RootReducer} from 'appRedux/reducers';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import MdxEditorField from 'components/MdxEditorField/MdxEditorField';
import {validationSchemaDashboardCreate} from 'components/Forms/Dashboard/validation';

import {theme} from 'config/theme';

interface WorkflowFormType {
    initialValues: DashboardFormTypes;
    selectedPanels: DashboardHasPanelTypes[];
    setSelectedPanels: (values: DashboardHasPanelTypes[]) => void;
    onSubmitClicked: (values: DashboardFormTypes) => void;
}

const DashboardForm: FC<WorkflowFormType> = ({initialValues, selectedPanels, setSelectedPanels, onSubmitClicked}) => {
    const [t] = useTranslation();

    const [, setIsUpdated] = useState<boolean>(false);

    const {
        analytics: {panels},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onPanelChecked = (event: SelectChangeEvent) => {
        const panelId = Number(event.target.value);
        const isChecked = selectedPanels.some(userHasRole => userHasRole.panel === panelId);
        if (isChecked) {
            for (let i = 0, n = selectedPanels.length; i < n; i++) {
                const selectedPanel = selectedPanels[i];
                if (selectedPanel.panel === panelId) {
                    selectedPanels.splice(i, 1);
                    setSelectedPanels(selectedPanels);
                    setIsUpdated(previous => !previous);
                    break;
                }
            }
        } else {
            setSelectedPanels([
                ...selectedPanels,
                {
                    panel: panelId,
                },
            ]);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={() => validationSchemaDashboardCreate(t)}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<DashboardFormTypes>) => {
                return (
                    <Form>
                        <Field
                            required
                            name="title"
                            placeholder={t('orguser.analytics.dashboards.title')}
                            component={FormikTextInput}
                        />
                        <MdxEditorField
                            name="description"
                            value={formik.values.description ? String(formik.values.description) : ''}
                            label={t('orguser.analytics.dashboards.description')}
                        />
                        <Box>
                            <Typography sx={{fontWeight: 700, mt: 2, mb: 1}}>{`${t(
                                'orguser.analytics.dashboards.selectPanels',
                            )}:`}</Typography>
                            <FormGroup>
                                {panels &&
                                    panels.map((item: PanelItemsTypes, index: number) => {
                                        const isChecked = selectedPanels.some(
                                            selectedPanel => selectedPanel.panel === item.id,
                                        );
                                        return (
                                            <FormControlLabel
                                                key={`panel-checkbox-${index}`}
                                                control={
                                                    <Checkbox
                                                        name={'dashboardHasPanels'}
                                                        value={item.id}
                                                        checked={isChecked}
                                                        onChange={onPanelChecked}
                                                        sx={{
                                                            '& svg': {color: theme.palette.info.main},
                                                        }}
                                                    />
                                                }
                                                label={item.title}
                                            />
                                        );
                                    })}
                            </FormGroup>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default DashboardForm;
