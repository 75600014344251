import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {GET_RESOURCE_FIELD_CALENDAR_INFO} from 'appRedux/actions/resourceFields';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';
import UpdateResourceFieldCalendarForm from 'components/Forms/ResourceField/UpdateResourceFieldCalendarForm';

import AddResourceFieldBookframe from 'pages/admin/updateResourceField/bookframes/AddResourceFieldBookframe';
import AddResourceFieldException from 'pages/admin/updateResourceField/exception/AddResourceFieldException';
import AddResourceFieldCalendarSlot from 'pages/admin/updateResourceField/calendarSlot/AddResourceFieldCalendarSlot';
import BookframesList from 'pages/admin/updateResourceField/bookframes/BookframesList';
import ExceptionsList from 'pages/admin/updateResourceField/exception/ExceptionsList';
import CalendarSlotsList from 'pages/admin/updateResourceField/calendarSlot/CalendarSlotsList';

const UpdateResourceFieldCalendarPage: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {uuid} = useParams();

    const {showAlert} = useContext(AlertContext);

    const {
        resourceFields: {currentCalendarItem},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getResourceFieldsInfo = useCallback(
        data => dispatch({type: GET_RESOURCE_FIELD_CALENDAR_INFO.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        getResourceFieldsInfo({
            uuid,
            showAlert,
        });
    }, [uuid]);

    if (!currentCalendarItem) return null;

    const {bookframes, exceptions, slots} = currentCalendarItem;

    return (
        <ClientSectionWrapper>
            <UpdateResourceFieldCalendarForm item={currentCalendarItem} />
            <Divider sx={{mt: 3, mb: 3}} />
            <Grid container spacing={2}>
                <Grid item sm={12} md={4}>
                    <AddResourceFieldBookframe />
                    {bookframes && bookframes.length > 0 ? (
                        <BookframesList items={bookframes} />
                    ) : (
                        <Typography sx={{mt: 2}}>{t('common.none')}</Typography>
                    )}
                </Grid>
                <Grid item sm={12} md={4}>
                    <AddResourceFieldCalendarSlot />
                    {slots && slots.length > 0 ? (
                        <CalendarSlotsList items={slots} />
                    ) : (
                        <Typography sx={{mt: 2}}>{t('common.none')}</Typography>
                    )}
                </Grid>
                <Grid item sm={12} md={4}>
                    <AddResourceFieldException />
                    {exceptions && exceptions.length > 0 ? (
                        <ExceptionsList items={exceptions} />
                    ) : (
                        <Typography sx={{mt: 2}}>{t('common.none')}</Typography>
                    )}
                </Grid>
            </Grid>
        </ClientSectionWrapper>
    );
};

export default UpdateResourceFieldCalendarPage;
