import {requestTypeCreator} from 'helpers/index';

import {RuleRequestType, RuleTypes, RulesListRequestType} from 'appRedux/actions/rules/types';

export const GET_RULES_LIST = requestTypeCreator('GET_RULES_LIST');
export const RULE_CREATE = requestTypeCreator('RULE_CREATE');

export const getRulesList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_RULES_LIST.ERROR,
    }),
    request: (payload: RulesListRequestType): IActionType<RulesListRequestType> => ({
        type: GET_RULES_LIST.REQUEST,
        payload,
    }),
    success: (payload: RuleTypes[]): IActionType<RuleTypes[]> => ({
        payload,
        type: GET_RULES_LIST.SUCCESS,
    }),
};

export const createRule = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: RULE_CREATE.ERROR,
    }),
    request: (payload: RuleRequestType): IActionType<RuleRequestType> => ({
        payload,
        type: RULE_CREATE.REQUEST,
    }),
    success: (payload: RuleTypes[]): IActionType<RuleTypes[]> => ({
        payload,
        type: RULE_CREATE.SUCCESS,
    }),
};
