import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {CASES_ACTIVITIES_LIST} from 'appRedux/actions/admin';
import {RootReducer} from 'appRedux/reducers';
import {RequesterCaseActivitiesTypes} from 'appRedux/actions/requestCase/types';

import ActivitySvgIcon from 'assets/icons/buttons/ActivitySvgIcon';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import ActivitiesLogItem from 'pages/agent/dashboard/ActivitiesLogItem';

const ActivitiesLog: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isCaseViewEnabled} = useContext(PermissionContext);

    const getAgentCasesActivities = useCallback(
        data => dispatch({type: CASES_ACTIVITIES_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {agentCasesActivitiesList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        if (isCaseViewEnabled) {
            getAgentCasesActivities({
                showAlert,
            });
        }
    }, []);

    if (!isCaseViewEnabled) {
        return null;
    }
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    mb: 3.5,
                }}
            >
                <Typography sx={{fontWeight: 600, mr: 1}}>{t('orguser.dashboard.activityLog')}</Typography>
                <ActivitySvgIcon />
            </Box>
            {agentCasesActivitiesList &&
                agentCasesActivitiesList.map((item: RequesterCaseActivitiesTypes, index: number) => {
                    return <ActivitiesLogItem item={item} key={`activities-log-item-${index}`} />;
                })}
        </Box>
    );
};

export default ActivitiesLog;
