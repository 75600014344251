import React, {FC} from 'react';

const CompletedSvgIcon: FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.16652 3.26957C7.89323 2.11581 9.9233 1.5 12 1.5C14.7848 1.5 17.4555 2.60625 19.4246 4.57538C21.3938 6.54451 22.5 9.21523 22.5 12C22.5 14.0767 21.8842 16.1068 20.7304 17.8335C19.5767 19.5602 17.9368 20.906 16.0182 21.7007C14.0996 22.4955 11.9884 22.7034 9.95156 22.2982C7.91476 21.8931 6.04383 20.8931 4.57538 19.4246C3.10693 17.9562 2.1069 16.0852 1.70176 14.0484C1.29661 12.0116 1.50455 9.90045 2.29927 7.98182C3.09399 6.0632 4.4398 4.42332 6.16652 3.26957ZM6.99987 19.4832C8.47992 20.4722 10.22 21 12 21C14.387 21 16.6761 20.0518 18.364 18.364C20.0518 16.6761 21 14.3869 21 12C21 10.22 20.4722 8.47991 19.4832 6.99987C18.4943 5.51983 17.0887 4.36627 15.4442 3.68508C13.7996 3.0039 11.99 2.82567 10.2442 3.17293C8.49836 3.5202 6.89472 4.37737 5.63604 5.63604C4.37737 6.89471 3.5202 8.49836 3.17294 10.2442C2.82567 11.99 3.0039 13.7996 3.68509 15.4442C4.36628 17.0887 5.51983 18.4943 6.99987 19.4832ZM6.75 12.3097L10.5 16.0605L17.25 9.31125L16.1887 8.25L10.5 13.9395L7.80975 11.25L6.75 12.3097Z"
                fill="#07D889"
            />
        </svg>
    );
};

export default CompletedSvgIcon;
