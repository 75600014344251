import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';

import {RootReducer} from 'appRedux/reducers';
import {RequesterCaseTagTypes} from 'appRedux/actions/requestCase/types';

import TagChip from 'components/TagChip/TagChip';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';

import {CLIENT_BACKGROUND_COLOR} from 'config/theme';
import {routes} from 'config/index';

const CurrentCasePanel: FC = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const {
        requestCase: {isLoading, currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {id, formId, tags, status, caseLabel} = currentCase;

    const redirectToCase = () => {
        navigate(`${routes.REQUESTER_CASE}/${id}/form/${formId}`);
    };

    if (isLoading) {
        return null;
    }
    return (
        <Box sx={{position: 'sticky', top: 96, zIndex: 100, backgroundColor: CLIENT_BACKGROUND_COLOR, pb: 4}}>
            <Grid container flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Grid item sm={4}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            mb: 1,
                        }}
                    >
                        <Tooltip
                            title={caseLabel}
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        fontSize: 16,
                                        backgroundColor: 'rgb(97, 97, 97)',
                                    },
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 28,
                                    fontWeight: 600,
                                    mr: 2,
                                    display: 'block',
                                    maxWidth: '100%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    cursor: 'pointer',
                                }}
                            >
                                {caseLabel}
                            </Typography>
                        </Tooltip>
                        <AgentInverseButton title={t('orguser.requesterCase.linkToCase')} onClick={redirectToCase} />
                    </Box>
                </Grid>
                <Grid item sm={2}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <Typography sx={{fontWeight: 600, mr: 2}}>{`${t('orguser.requesterCase.status')}:`}</Typography>
                        <Typography>{t(`orguser.requesterCase.statusTypes.${status}`)}</Typography>
                    </Box>
                </Grid>
                <Grid item sm={6}>
                    {tags.map((item: RequesterCaseTagTypes, index: number) => {
                        const {id, tag, color} = item;
                        return (
                            <TagChip
                                key={`tag-chip-${index}`}
                                id={String(id)}
                                title={tag}
                                color={color}
                                hasOpacity={true}
                            />
                        );
                    })}
                </Grid>
            </Grid>
        </Box>
    );
};

export default CurrentCasePanel;
