import {fork, put, take, call} from 'redux-saga/effects';

import {
    GeneralTemplateUpdateRequestType,
    GeneralTemplateRequestType,
    GeneralTemplateUpdateResponseType,
    GeneralTemplateDefaultResponseType,
    GeneralDefaultTemplateRequestType,
} from 'appRedux/actions/generalTemplates/types';
import {
    GENERAL_TEMPLATE_LIST,
    getGeneralTemplatesList,
    GENERAL_TEMPLATE_UPDATE,
    updateGeneralTemplate,
    GENERAL_TEMPLATE_LIST_DEFAULT,
    getGeneralTemplatesListDefault,
} from 'appRedux/actions/generalTemplates';

import {http} from 'services/http';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchGeneralTemplatesGetting() {
    while (true) {
        const {
            payload: {showAlert, callback, language},
        }: IActionType<GeneralTemplateRequestType> = yield take(GENERAL_TEMPLATE_LIST.REQUEST);
        try {
            const data: GeneralTemplateUpdateResponseType = yield call(http, `templates/${language}/list`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getGeneralTemplatesList.success(data.results));
                callback && callback();
            } else {
                yield put(getGeneralTemplatesList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            }
        } catch (e) {
            yield put(getGeneralTemplatesList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_SUCCESS);
        }
    }
}

function* watchGeneralDefaultTemplatesGetting() {
    while (true) {
        const {
            payload: {showAlert, callback},
        }: IActionType<GeneralDefaultTemplateRequestType> = yield take(GENERAL_TEMPLATE_LIST_DEFAULT.REQUEST);
        try {
            const data: GeneralTemplateDefaultResponseType = yield call(http, `templates/default`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getGeneralTemplatesListDefault.success(data.results));
                callback && callback();
            } else {
                yield put(getGeneralTemplatesListDefault.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            }
        } catch (e) {
            yield put(getGeneralTemplatesListDefault.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_SUCCESS);
        }
    }
}

function* watchGeneralTemplateUpdating() {
    while (true) {
        const {
            payload: {showAlert, language, callback, ...values},
        }: IActionType<GeneralTemplateUpdateRequestType> = yield take(GENERAL_TEMPLATE_UPDATE.REQUEST);
        try {
            const data: GeneralTemplateUpdateResponseType = yield call(http, `template/${language}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateGeneralTemplate.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(updateGeneralTemplate.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateGeneralTemplate.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchGeneralTemplatesGetting),
    fork(watchGeneralDefaultTemplatesGetting),
    fork(watchGeneralTemplateUpdating),
];
