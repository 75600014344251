import React, {FC, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {RootReducer} from 'appRedux/reducers';

import {CaseKeyContext} from 'contexts/caseKey/context';
import {RouteContext} from 'contexts/route/context';

import FileListItem from 'components/FilesListWithGallery/FileListItem';
import FilesGallery from 'components/FilesListWithGallery/FilesGallery';
import {getFileData} from 'components/FilesListWithGallery/helper';
import FileThumbItem from 'components/FilesListWithGallery/FileThumbItem';

import {CommonFileType, FILE_CHAT} from 'pages/agent/requesterPage/partials/helper';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';
import {getEncryptedFileData} from 'helpers/cryptoApiHelper';
import FileThumbListItem from './FileThumbListItem';

interface FilesListWithGallery {
    caseId: number;
    allFiles: CommonFileType[];
    deleteNotPossible?: boolean;
    sectionId?: number;
    isAttachedFilesList?: boolean;
}

const FilesListWithGallery: FC<FilesListWithGallery> = ({
    caseId,
    allFiles,
    deleteNotPossible,
    isAttachedFilesList,
    sectionId,
}) => {
    const [t] = useTranslation();

    const {unwrappedCaseKey} = useContext(CaseKeyContext);
    const {isClientMode} = useContext(RouteContext);

    const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
    const [documentFormField, setDocumentFormField] = useState<string>();
    const [documentIsImage, setDocumentIsImage] = useState<boolean>(false);
    const [documentIsPdf, setDocumentIsPdf] = useState<boolean>(false);
    const [documentImageSource, setDocumentImageSource] = useState<string>();
    const [file, setFile] = useState<CommonFileType | undefined>();
    const [filesCache, setFilesCache] = useState<Map<string, string>>(new Map());

    const toggleIsModalOpened = () => {
        setIsModalOpened(previous => !previous);
    };

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getModalContent = (file: CommonFileType) => {
        const {uuid, fileLink, formId, fieldId, isImage, extension, encryptPrefix, formFieldLabel, type} = file;
        setFile(file);
        console.log('file', file);

        const keywordTitle = formId && fieldId ? getFieldKeyword(formId, fieldId, 'label') : '';
        const fileSource = filesCache.get(uuid);
        setDocumentImageSource(fileSource || '');
        setDocumentFormField(
            type === FILE_CHAT
                ? t('orguser.requesterCase.documents.fileFromChat')
                : getFormTranslatedLabel(translations, keywordTitle, String(formFieldLabel)),
        );
        setDocumentIsImage(isImage);
        setDocumentIsPdf(extension.toLowerCase().includes('pdf'));

        if (fileLink && !fileSource) {
            if (encryptPrefix) {
                getEncryptedFileData(fileLink, encryptPrefix, unwrappedCaseKey).then(decryptedFileSource => {
                    setDocumentImageSource(decryptedFileSource || '');
                    decryptedFileSource && setFilesCache(prev => prev.set(uuid, decryptedFileSource));
                });
            } else {
                getFileData(fileLink).then(decryptedFileSource => {
                    setDocumentImageSource(decryptedFileSource || '');
                    decryptedFileSource && setFilesCache(prev => prev.set(uuid, decryptedFileSource));
                });
            }
        }
    };

    const downloadFile = (url: string, fileName?: string) => {
        const a = document.createElement('a');
        a.href = url;
        document.body.appendChild(a);
        if (fileName) {
            a.download = fileName;
        }
        a.click();
        document.body.removeChild(a);
    };

    return (
        <>
            {isClientMode
                ? allFiles.map((file: CommonFileType, index: number) => {
                      return (
                          <FileThumbItem
                              file={file}
                              key={`file-${index}`}
                              toggleIsModalOpened={toggleIsModalOpened}
                              getModalContent={getModalContent}
                              downloadFile={downloadFile}
                              deleteNotPossible={deleteNotPossible}
                              sectionId={sectionId}
                          />
                      );
                  })
                : isAttachedFilesList
                ? allFiles.map((file: CommonFileType, index: number) => {
                      return (
                          <FileListItem
                              file={file}
                              caseId={caseId}
                              key={`file-${index}`}
                              toggleIsModalOpened={toggleIsModalOpened}
                              getModalContent={getModalContent}
                              downloadFile={downloadFile}
                              deleteNotPossible={deleteNotPossible}
                              sectionId={sectionId}
                              isAttachedFilesList={isAttachedFilesList}
                          />
                      );
                  })
                : allFiles.map((file: CommonFileType, index: number) => {
                      return (
                          <FileThumbListItem
                              file={file}
                              key={`file-${index}`}
                              toggleIsModalOpened={toggleIsModalOpened}
                              getModalContent={getModalContent}
                              downloadFile={downloadFile}
                              deleteNotPossible={deleteNotPossible}
                          />
                      );
                  })}
            {isModalOpened && file && documentFormField && (
                <FilesGallery
                    isModalOpened={isModalOpened}
                    toggleIsModalOpened={toggleIsModalOpened}
                    documentFormField={documentFormField}
                    documentImageSource={documentImageSource}
                    documentIsImage={documentIsImage}
                    documentIsPdf={documentIsPdf}
                    selectedFile={file}
                    allFiles={allFiles}
                    downloadFile={downloadFile}
                    getModalContent={getModalContent}
                />
            )}
        </>
    );
};

export default FilesListWithGallery;
