import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import {AlertColor} from '@mui/material/Alert/Alert';

interface FormAlertType {
    message: string;
    type?: AlertColor;
    onErrorClose: () => void;
}

const FormAlert: FC<FormAlertType> = ({message, type, onErrorClose}) => {
    const [t] = useTranslation();
    return (
        <Box sx={{width: '100%', mb: 2}}>
            <Alert severity={type ?? 'error'} onClose={onErrorClose}>
                {t(message)}
            </Alert>
        </Box>
    );
};

export default FormAlert;
