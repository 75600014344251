import React, {FC, ReactNode} from 'react';

import Box from '@mui/material/Box';

interface HorizontalLeftFlexboxType {
    children: ReactNode;
}

const HorizontalLeftFlexbox: FC<HorizontalLeftFlexboxType> = ({children}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
        >
            {children}
        </Box>
    );
};

export default HorizontalLeftFlexbox;
