import React, {FC} from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TripOriginIcon from '@mui/icons-material/TripOrigin';

interface ColumnHeaderType {
    title: string;
    color: string;
    subtitle: string | number;
}

const ColumnHeader: FC<ColumnHeaderType> = ({title, color, subtitle}) => {
    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
            <TripOriginIcon sx={{color: color}} />
            <Typography sx={{ml: 1, fontSize: 16, fontWeight: 600}}>{title}</Typography>
            <Typography sx={{ml: 2, fontSize: 12}}>{subtitle}</Typography>
        </Box>
    );
};

export default ColumnHeader;
