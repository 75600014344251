import JSZip from 'jszip';
import moment from 'moment';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {utils, write, WorkBook} from 'xlsx-js-style';
import mime from 'mime';

import {RequesterCaseExportType} from 'appRedux/actions/admin/types';
import {FORM_BY_CASE_INFORMATION} from 'appRedux/actions/forms';
import {GET_REQUESTER_CASE, REQUEST_CASE_ATTACHMENTS_FOR_ARCHIVE} from 'appRedux/actions/requestCase';
import {RootReducer} from 'appRedux/reducers';
import {RequesterCaseAttachmentsForArchiveTypes} from 'appRedux/actions/requestCase/types';
import {REQUEST_CASE_EXPORT} from 'appRedux/actions/admin';
import {GET_CASE_KEYS} from 'appRedux/actions/crypto';

import {AlertContext} from 'contexts/alert/context';
import {CryptoContext} from 'contexts/crypto/context';
import {CaseKeyContext} from 'contexts/caseKey/context';

import {
    blobToBase64,
    fillAdditionalData,
    fillFormData,
} from 'components/AgentScreenComponents/_caseBlock/exportCaseHelpers';

import {DATE_TIME_FORMAT} from 'config/index';
import {getEncryptedFileData, unwrapKey} from 'helpers/cryptoApiHelper';

export const useExportCase = () => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {unwrappedCaseKey} = useContext(CaseKeyContext);
    const {keys} = useContext(CryptoContext);

    const {
        admin: {formInfo},
        requestCase: {currentCase, caseAttachments},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getRequesterCaseInfo = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const getFormByCaseInformation = useCallback(
        data => dispatch({type: FORM_BY_CASE_INFORMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequesterCaseAttachments = useCallback(
        data => dispatch({type: REQUEST_CASE_ATTACHMENTS_FOR_ARCHIVE.REQUEST, payload: data}),
        [dispatch],
    );

    const exportCaseRequest = useCallback(
        data => dispatch({type: REQUEST_CASE_EXPORT.REQUEST, payload: data}),
        [dispatch],
    );

    const getCaseKeys = useCallback(data => dispatch({type: GET_CASE_KEYS.REQUEST, payload: data}), [dispatch]);

    const [pendingId, setPendingId] = useState<number | null>(null);
    const [callback, setCallback] = useState<(() => void) | null>(null);
    const [caseKey, setCaseKey] = useState<CryptoKey | null>(null);

    const checkCaseKey = async (caseKeys: string[]) => {
        if (keys) {
            for (const item of caseKeys) {
                try {
                    const unwrappedKey = await unwrapKey(item, keys.privateKey);
                    if (unwrappedKey) {
                        console.log('setCaseKey', unwrappedKey);

                        setCaseKey(unwrappedKey);
                        break;
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        }
    };

    const downloadZip = (id: number | null) => (blob: string | Blob) => {
        if (typeof blob === 'string') return;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Export case #${id} - ${moment().format(DATE_TIME_FORMAT)}.zip`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const prepareDownload = async () => {
        const cryptoCaseKey = unwrappedCaseKey || caseKey;
        const caseSheet = utils.json_to_sheet([]);
        const metaSheet = utils.json_to_sheet([]);

        await fillFormData(formInfo.pages, currentCase, caseSheet, cryptoCaseKey);
        await fillAdditionalData(currentCase, metaSheet, cryptoCaseKey);

        const caseWorkBook: WorkBook = {
            Sheets: {[`${currentCase.formTitle}`]: caseSheet, [`Additional metadata`]: metaSheet},
            SheetNames: [currentCase.formTitle, 'Additional metadata'],
        };
        const excelBuffer = write(caseWorkBook, {bookType: 'xlsx', type: 'array'});
        const excelBLob = new Blob([excelBuffer], {type: 'xlsx'});

        const zip = new JSZip();
        zip.file(`Export_case_${currentCase.id}.xlsx`, excelBLob);

        await Promise.all(
            caseAttachments.map(async (attachment: RequesterCaseAttachmentsForArchiveTypes) => {
                const {src, encryptPrefix, fileName} = attachment;

                if (encryptPrefix) {
                    if (!cryptoCaseKey) return;
                    const decryptedFileSource = await getEncryptedFileData(src, encryptPrefix, cryptoCaseKey);
                    if (!decryptedFileSource) return;

                    const idx = decryptedFileSource.indexOf('base64,') + 'base64,'.length;
                    const content = decryptedFileSource.substring(idx);

                    const extension = mime.getExtension(
                        encryptPrefix.substring(encryptPrefix.indexOf(':') + 1, encryptPrefix.indexOf(';base64')),
                    );

                    zip.file(`${fileName}.${extension}`, content, {base64: true});
                } else {
                    const response = await fetch(src);
                    const blob = await response.blob();
                    const base64 = await blobToBase64(blob);
                    const idx = base64.indexOf('base64,') + 'base64,'.length;
                    const content = base64.substring(idx);

                    zip.file(fileName, content, {base64: true});
                }
            }),
        );

        zip.generateAsync({type: 'blob'}).then(function (content) {
            downloadZip(currentCase.id)(content);
        });

        callback && callback();
        setCallback(null);
    };

    useEffect(() => {
        if (currentCase.id !== pendingId) return;
        if (!caseKey && !unwrappedCaseKey) return;

        if (currentCase.formId === formInfo.id) {
            console.log('prepareDownload');
            setPendingId(null);
            prepareDownload();
            return;
        } else if (currentCase.formId) {
            getFormByCaseInformation({
                id: currentCase.id,
            });
        }
    }, [pendingId, currentCase, formInfo, caseAttachments, unwrappedCaseKey, caseKey]);

    const exportCase = async ({id, callback}: RequesterCaseExportType, isEncryptInfo?: boolean) => {
        if (!isEncryptInfo)
            return exportCaseRequest({
                id,
                showAlert,
                callbackDownload: downloadZip(id),
                callback,
            });

        setCallback(() => callback);

        if (!unwrappedCaseKey) {
            setPendingId(id);
            getCaseKeys({
                id: id,
                showAlert,
                callback: checkCaseKey,
            });
        }

        if (currentCase.id !== id || currentCase.formId !== formInfo.id) {
            // setLoadingFiles()
            getRequesterCaseAttachments({
                id,
                showAlert,
                // callback: () => set
            });
            getRequesterCaseInfo({id});
            setPendingId(id);
        } else if (unwrappedCaseKey) {
            prepareDownload();
        }
    };

    return exportCase;
};
