import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {get} from 'lodash';
import Markdown from 'react-markdown';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {FormPageTypes, FormSectionTypes} from 'appRedux/actions/forms/types';
import {UPDATE_REQUESTER_CASE, SUBMIT_SUBFORM_REQUEST} from 'appRedux/actions/requestCase';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {ClientFormContext} from 'contexts/clientForm/context';
import {CaseKeyContext} from 'contexts/caseKey/context';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import FormSection from 'pages/client/form/partials/FormSection';
import StatusBar from 'pages/client/form/partials/StatusBar';
import {
    isContinueUnavailable,
    getEmptyRequiredField,
    getEmptyRequiredPopups,
    encryptClientInformation,
    allSubFormsSubmitted,
} from 'pages/client/form/helper';

import {getPageKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';
import {
    getRelatedSectionsIds,
    getNotSelectedRelatedSectionsIds,
    getSelectedRelatedSectionsIds,
    getUnavailableResourceFieldSlots,
    UnavailableResourceFieldSlotsTypes,
    getUnavailableResourceFieldBudgets,
    UnavailableResourceFieldBudgetsTypes,
} from 'helpers/requesterCaseHelper';

import {ERROR_TEXT_COLOR, theme} from 'config/theme';
import {routes, STATUS_OPENING} from 'config/index';

interface FormPageType {
    formId: number;
    isLastPage: boolean;
    page: FormPageTypes;
    currentPage: number;
    setCurrentPage: (value: number) => void;
    handleChangeInformation: (pageId: number, sectionId: number, fieldId: number, value: string) => void;
    handleChangePopupInformation: (pageId: number, sectionId: number, popupId: number, value: unknown) => void;
    handleCompletePage: (pageId: number) => void;
    clientInformation: unknown;
    isActive?: boolean;
    isLastSubFormPage: boolean;
}

const FormPage: FC<FormPageType> = ({
    formId,
    isLastPage,
    page,
    isActive,
    clientInformation,
    handleChangePopupInformation,
    handleChangeInformation,
    handleCompletePage,
    currentPage,
    setCurrentPage,
    isLastSubFormPage,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {requestCase} = useParams();
    const navigate = useNavigate();

    const {showAlert} = useContext(AlertContext);
    const {setErrorField, setErrorPopup} = useContext(ClientFormContext);
    const {unwrappedCaseKey} = useContext(CaseKeyContext);

    const {
        admin: {
            formInfo: {translations},
        },
        requestCase: {
            currentCase: {status, files, isEncryptInfo, noEditForm, signatures, subFormRequests, options},
            currentCaseSlots,
            currentCaseBudgets,
        },
        profile,
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isUserVerified = get(profile, ['profile', 'isVerify'], false);

    const updateRequesterInformation = useCallback(
        data => dispatch({type: UPDATE_REQUESTER_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const submitSubFormRequest = useCallback(
        data => dispatch({type: SUBMIT_SUBFORM_REQUEST.REQUEST, payload: data}),
        [dispatch],
    );

    const {sections, description, title, id, versionId, isSubFormPage} = page;

    const relatedSectionsIds = getRelatedSectionsIds(sections);
    const selectedRelatedSectionsIds = getSelectedRelatedSectionsIds(sections, options);
    const notSelectedRelatedSectionsIds = getNotSelectedRelatedSectionsIds(sections, options);

    const unavailableResourceFieldSlots: UnavailableResourceFieldSlotsTypes[] = getUnavailableResourceFieldSlots(
        sections,
        currentCaseSlots,
    );

    const unavailableResourceFieldBudgets: UnavailableResourceFieldBudgetsTypes[] = getUnavailableResourceFieldBudgets(
        sections,
        currentCaseBudgets,
    );

    const isDisabled =
        unavailableResourceFieldSlots.length > 0 ||
        unavailableResourceFieldBudgets.length > 0 ||
        isContinueUnavailable(
            page,
            clientInformation,
            status,
            files,
            signatures,
            relatedSectionsIds,
            selectedRelatedSectionsIds,
        ) ||
        (isLastPage && !isUserVerified);

    useEffect(() => {
        if (!isDisabled && isActive) {
            handleCompletePage(id);
        }
    }, [isActive, id, isDisabled]);

    const redirectNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const redirectFinalPage = () => {
        navigate(`${routes.FINAL_PAGE}/${requestCase}/form/${formId}`);
    };

    const onContinueClicked = async () => {
        updateRequesterInformation({
            id: requestCase,
            pageId: id,
            result:
                isEncryptInfo && unwrappedCaseKey
                    ? await encryptClientInformation(clientInformation, unwrappedCaseKey)
                    : clientInformation,
            callback: isLastPage ? redirectFinalPage : redirectNextPage,
            showAlert,
        });
        if (isSubFormPage && isLastSubFormPage) {
            const subFormRequest = subFormRequests.find(item => item.versionId === page.versionId && !item.isSubmitted);
            if (subFormRequest) {
                submitSubFormRequest({
                    uuid: subFormRequest.uuid,
                    showAlert,
                });
            }
        }
    };

    const onDisabledContinueClicked = () => {
        const emptyFieldId = getEmptyRequiredField(page, clientInformation, files);
        if (emptyFieldId) {
            setErrorField(emptyFieldId);
        }
        const emptyPopupId = getEmptyRequiredPopups(page, clientInformation);
        if (emptyPopupId) {
            setErrorPopup(emptyPopupId);
        }
        if (unavailableResourceFieldSlots.length > 0) {
            setErrorField(unavailableResourceFieldSlots[0].fieldId);
        }
        if (unavailableResourceFieldBudgets.length > 0) {
            setErrorField(unavailableResourceFieldBudgets[0].fieldId);
        }
    };

    const pageTitleKeyword = getPageKeyword(formId, id, versionId, 'title');
    const pageTitleDescription = getPageKeyword(formId, id, versionId, 'description');

    const [isShowModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    if (!isActive) return null;

    const showContinueButton = status === STATUS_OPENING || !allSubFormsSubmitted(subFormRequests);

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mb: 2,
                    pl: 4,
                    pr: 4,
                    pb: 1,
                }}
            >
                <Typography align="center" sx={{fontSize: 24, mb: 2}}>
                    {getFormTranslatedLabel(translations, pageTitleKeyword, title)}
                </Typography>
                {description && (
                    <Box
                        sx={{
                            width: '100%',
                            '& p': {
                                px: 1,
                                fontSize: 14,
                                fontWeight: 300,
                            },
                        }}
                    >
                        <Markdown>{getFormTranslatedLabel(translations, pageTitleDescription, description)}</Markdown>
                    </Box>
                )}
            </Box>
            <StatusBar />
            {sections &&
                sections.map((section: FormSectionTypes, index: number) => {
                    if (
                        (!section.isRequired && status === STATUS_OPENING) ||
                        notSelectedRelatedSectionsIds.includes(section.id)
                    ) {
                        return null;
                    }
                    return (
                        <FormSection
                            key={`page-${title}-section-${index}`}
                            formId={formId}
                            pageId={id}
                            section={section}
                            clientInformation={clientInformation}
                            handleChangeInformation={handleChangeInformation}
                            handleChangePopupInformation={handleChangePopupInformation}
                        />
                    );
                })}
            {isShowModal && (
                <ModalWrapper isShowModal={isShowModal} toggleModal={toggleModal}>
                    <Box>
                        <Typography align="center" variant="body2" sx={{mt: 0, mb: 4}}>
                            {t('messages.other.areYouSureToSubmit')}
                        </Typography>
                        <Box
                            sx={{
                                mt: 2,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-around',
                            }}
                        >
                            <AgentSaveButton
                                onClick={toggleModal}
                                data-id={`button#form-${formId}-${id}-cancel`}
                                title={t('common.buttons.cancel')}
                            />
                            <AgentSaveButton
                                onClick={onContinueClicked}
                                data-id={`button#form-${formId}-${id}-submit`}
                                title={t('common.buttons.submit')}
                            />
                        </Box>
                    </Box>
                </ModalWrapper>
            )}
            {showContinueButton && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: 3,
                        pt: 1,
                    }}
                >
                    {!isUserVerified && isLastPage && (
                        <Typography sx={{mb: 3, color: ERROR_TEXT_COLOR, fontWeight: 400}}>
                            {t('messages.warning.emailVerificationNeeded')}
                        </Typography>
                    )}
                    <Button
                        data-id={`button#form-${formId}-${id}-continue`}
                        sx={{
                            width: 262,
                            height: 48,
                            borderRadius: 24,
                            backgroundColor: isDisabled ? theme.palette.info.contrastText : theme.palette.info.main,
                            '&:hover': {
                                backgroundColor: isDisabled ? theme.palette.info.contrastText : theme.palette.info.main,
                            },
                        }}
                        onClick={isDisabled ? onDisabledContinueClicked : noEditForm ? toggleModal : onContinueClicked}
                    >
                        <Typography
                            variant="body2"
                            sx={{
                                color: isDisabled ? theme.palette.secondary.light : theme.palette.background.paper,
                                textTransform: 'none',
                                fontWeight: 300,
                            }}
                        >
                            {t(isLastPage ? 'common.buttons.submit' : 'common.buttons.continue')}
                        </Typography>
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default FormPage;
