import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';

import {UPDATE_GROUP} from 'appRedux/actions/groups';
import {GroupFormTypes, GroupItemTypes, GroupHasRoleItemTypes} from 'appRedux/actions/groups/types';

import {AlertContext} from 'contexts/alert/context';

import GroupForm from 'components/Forms/GroupForm/GroupForm';

interface UpdateGroupFormType {
    item: GroupItemTypes;
    closeModal: () => void;
}

const UpdateGroupForm: FC<UpdateGroupFormType> = ({item, closeModal}) => {
    const dispatch = useDispatch();

    const {title, description, uuid, roles} = item;

    const {showAlert} = useContext(AlertContext);

    const [initialGroupHasRoles, setInitialGroupHasRoles] = useState<GroupHasRoleItemTypes[]>(roles);

    const updateGroup = useCallback(data => dispatch({type: UPDATE_GROUP.REQUEST, payload: data}), [dispatch]);

    const onSubmitClicked = (values: GroupFormTypes) => {
        updateGroup({
            ...values,
            groupHasRoles: initialGroupHasRoles,
            uuid,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <GroupForm
            initialValues={{
                title,
                description,
                groupHasRoles: initialGroupHasRoles,
            }}
            onSubmitClicked={onSubmitClicked}
            groupHasRoles={initialGroupHasRoles}
            setGroupHasRoles={setInitialGroupHasRoles}
        />
    );
};

export default UpdateGroupForm;
