import React, {FC, useContext} from 'react';

import Box from '@mui/material/Box';

import {AdminContext} from 'contexts/admin/context';

const DragPlaceholderBlock: FC = () => {
    const {isDragStart} = useContext(AdminContext);

    if (!isDragStart) {
        return null;
    }
    return (
        <Box
            sx={{
                borderRadius: 2,
                minHeight: 65,
                mb: 1,
                p: 2,
                border: '2px dashed rgba(34, 34, 34, 0.2);',
            }}
        ></Box>
    );
};

export default DragPlaceholderBlock;
