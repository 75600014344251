import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {CommonGetLogoUrlTypes, CommonUploadLogoTypes, CommonDeleteLogoTypes} from 'appRedux/actions/forms/types';
import {OrganizationLogoUrlRequestTypes} from 'appRedux/actions/organization/types';

import IconPreview from 'components/Forms/CommonLogoForm/IconPreview';
import CommonUploadLogoForm from 'components/Forms/CommonLogoForm/CommonUploadLogoForm';

interface CommonLogoFormType {
    id: number;
    initialLogo?: string;
    title: string;
    isLoading: boolean;
    getLogoUrl: (value: CommonGetLogoUrlTypes) => void;
    submitUploadFile: (value: CommonUploadLogoTypes) => void;
    deleteFormLogo: (value: CommonDeleteLogoTypes | OrganizationLogoUrlRequestTypes) => void;
    setFormLogoSource?: (value: string) => void;
}

const CommonLogoForm: FC<CommonLogoFormType> = ({
    id,
    title,
    initialLogo,
    isLoading,
    submitUploadFile,
    getLogoUrl,
    deleteFormLogo,
    setFormLogoSource,
}) => {
    const [t] = useTranslation();

    const [logoSource, setLogoSource] = useState<string | null>(null);

    useEffect(() => {
        if (initialLogo) {
            getLogoUrl({
                id,
                callback: (value?: string | number) => {
                    if (value) {
                        setLogoSource(String(value));
                        setFormLogoSource && setFormLogoSource(String(value));
                    }
                },
            });
        }
    }, [initialLogo]);

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                pl: 3,
            }}
        >
            {!isLoading ? (
                <IconPreview
                    id={id}
                    logoSource={logoSource}
                    title={title}
                    setLogoSource={setLogoSource}
                    deleteFormLogo={deleteFormLogo}
                />
            ) : (
                <Typography>{t('common.pleaseWait')}</Typography>
            )}
            {!isLoading && !logoSource && (
                <CommonUploadLogoForm
                    initialValues={{
                        id,
                        file: '',
                    }}
                    identifier="upload-logo"
                    submitUploadFile={submitUploadFile}
                    transparentButton
                />
            )}
        </Box>
    );
};

export default CommonLogoForm;
