import {fork, put, take, call} from 'redux-saga/effects';

import {
    RequesterCaseAddCommentRequestTypes,
    RequesterCaseUpdateCommentRequestTypes,
    RequesterCaseRemoveCommentRequestTypes,
    RequesterCaseCommentsResponseTypes,
    RequesterCaseCommentsRequestTypes,
} from 'appRedux/actions/comments/types';
import {
    GET_REQUESTER_CASE_COMMENTS,
    ADD_REQUESTER_CASE_COMMENT,
    REMOVE_REQUESTER_CASE_COMMENT,
    UPDATE_REQUESTER_CASE_COMMENT,
    getRequesterCaseComments,
    addRequesterCaseComment,
    updateRequesterCaseComment,
    deleteRequesterCaseComment,
} from 'appRedux/actions/comments';

import {http} from 'services/http';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchCommentsGetting() {
    while (true) {
        const {
            payload: {id},
        }: IActionType<RequesterCaseCommentsRequestTypes> = yield take(GET_REQUESTER_CASE_COMMENTS.REQUEST);
        try {
            const data: RequesterCaseCommentsResponseTypes = yield call(http, `case/${id}/comments`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getRequesterCaseComments.success(data.results));
            } else {
                yield put(getRequesterCaseComments.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getRequesterCaseComments.error({message: String(e)}));
        }
    }
}

function* watchCommentAdding() {
    while (true) {
        const {
            payload: {id, callback, showAlert, ...values},
        }: IActionType<RequesterCaseAddCommentRequestTypes> = yield take(ADD_REQUESTER_CASE_COMMENT.REQUEST);
        try {
            const data: RequesterCaseCommentsResponseTypes = yield call(http, `comment/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(addRequesterCaseComment.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(addRequesterCaseComment.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(addRequesterCaseComment.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchCommentUpdating() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert, ...values},
        }: IActionType<RequesterCaseUpdateCommentRequestTypes> = yield take(UPDATE_REQUESTER_CASE_COMMENT.REQUEST);
        try {
            const data: RequesterCaseCommentsResponseTypes = yield call(http, `comment/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateRequesterCaseComment.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateRequesterCaseComment.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateRequesterCaseComment.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchCommentRemoving() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert},
        }: IActionType<RequesterCaseRemoveCommentRequestTypes> = yield take(REMOVE_REQUESTER_CASE_COMMENT.REQUEST);
        try {
            const data: RequesterCaseCommentsResponseTypes = yield call(http, `comment/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteRequesterCaseComment.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteRequesterCaseComment.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteRequesterCaseComment.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchCommentsGetting),
    fork(watchCommentAdding),
    fork(watchCommentUpdating),
    fork(watchCommentRemoving),
];
