import React, {FC} from 'react';

const AdobeSvgIcon: FC = () => {
    return (
        <svg fill="currentColor" height="75px" width="75px" viewBox="0 0 53 53">
            <path
                d="M45.707,10.074l-9.794-9.782C35.725,0.105,35.471,0,35.206,0H8C7.448,0,7,0.447,7,1v51c0,0.553,0.448,1,1,1h37
			c0.552,0,1-0.447,1-1V10.782C46,10.517,45.895,10.263,45.707,10.074z M42.586,10H36V3.414L42.586,10z M9,51V2h25v9
			c0,0.553,0.448,1,1,1h9v39H9z"
            />
            <path
                d="M37.949,33.179c-3.623,0-5.458,0.638-6.316,1.104c-1.92-1.904-3.809-4.688-5.28-7.55c1.607-5.646,1.417-9.334-0.645-10.45
			c-1.187-0.646-2.679-0.165-3.547,1.14c-0.246,0.368-0.993,1.489,0.417,5.598c0.403,1.174,0.964,2.513,1.649,3.902
			c-0.583,1.899-1.413,4.168-2.568,6.863c-0.798,1.862-1.548,3.437-2.242,4.765c-2.781,1.17-5.236,2.461-6.316,3.695
			c-1.095,1.251-1.477,2.854-0.952,3.991c0.333,0.72,1.002,1.146,1.793,1.146c0.01,0,0.021,0,0.031,0
			c1.95-0.028,4.325-2.561,6.866-7.251c3.608-1.456,7.844-2.794,10.386-3.527c1.009,0.854,2.047,1.501,3.084,1.847
			c3.347,1.115,5.654,0.287,6.698-0.859c0.722-0.794,0.921-1.79,0.534-2.666C41.309,34.4,40.494,33.179,37.949,33.179z
			 M13.975,45.415c-0.11-0.146-0.13-0.983,0.631-1.854c0.56-0.639,1.68-1.339,3.109-2.047
			C15.627,44.781,14.294,45.476,13.975,45.415z M23.825,18.531c0.216-0.323,0.527-0.529,0.769-0.529
			c0.059,0,0.114,0.013,0.162,0.038c0.517,0.28,1.123,1.84,0.276,5.824c-0.146-0.358-0.283-0.714-0.411-1.065
			C23.583,19.947,23.702,18.731,23.825,18.531z M22.197,37.452c0.43-0.902,0.864-1.863,1.299-2.88
			c0.797-1.859,1.474-3.603,2.03-5.22c1.172,2.028,2.557,4.024,4.048,5.651C27.773,35.542,24.993,36.412,22.197,37.452z
			 M39.528,36.245c-0.638,0.702-2.303,1.069-4.585,0.31c-0.519-0.173-1.052-0.454-1.592-0.824c0.823-0.265,2.255-0.552,4.599-0.552
			c1.2,0,1.678,0.363,1.763,0.556S39.592,36.175,39.528,36.245z"
            />
        </svg>
    );
};

export default AdobeSvgIcon;
