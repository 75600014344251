import React, {FC, MouseEvent, ReactElement} from 'react';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import {theme} from 'config/theme';

interface ClientOutlinedButtonType {
    open: boolean;
    label: string;
    dataId: string;
    handleClick: (event: MouseEvent<HTMLElement>) => void;
    startIcon?: ReactElement;
}

const ClientOutlinedButton: FC<ClientOutlinedButtonType> = ({open, label, dataId, handleClick, startIcon}) => {
    return (
        <Button
            sx={{
                pl: 2,
                pr: 2,
                minWidth: 100,
                height: 40,
                borderRadius: 20,
                backgroundColor: open ? theme.palette.info.main : theme.palette.background.default,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: open ? theme.palette.background.default : theme.palette.info.main,
                '&:hover': {
                    backgroundColor: open ? theme.palette.info.main : theme.palette.background.default,
                },
            }}
            onClick={handleClick}
            data-id={dataId}
            startIcon={startIcon || null}
            endIcon={
                open ? (
                    <KeyboardArrowUpIcon sx={{color: theme.palette.background.default}} />
                ) : (
                    <KeyboardArrowDownIcon sx={{color: theme.palette.info.main}} />
                )
            }
        >
            <Typography
                sx={{
                    textTransform: 'none',
                    color: open ? theme.palette.background.default : theme.palette.info.main,
                    ml: 1,
                }}
            >
                {label}
            </Typography>
        </Button>
    );
};

export default ClientOutlinedButton;
