import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {ORGANIZATION_INTEGRATION_TOKEN_UPDATE} from 'appRedux/actions/organizationIntegrationTokens';
import {
    OrganizationIntegrationTokenTypes,
    OrganizationIntegrationTokenFormTypes,
} from 'appRedux/actions/organizationIntegrationTokens/types';

import {AlertContext} from 'contexts/alert/context';

import OrganizationIntegrationTokenForm from 'components/Forms/OrganizationIntegrationTokenForm/OrganizationIntegrationTokenForm';

interface ComponentFormType {
    item: OrganizationIntegrationTokenTypes;
    closeModal: () => void;
}

const UpdateOrganizationIntegrationTokenForm: FC<ComponentFormType> = ({item, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {uuid, title} = item;

    const updateOrganizationIntegrationToken = useCallback(
        data => dispatch({type: ORGANIZATION_INTEGRATION_TOKEN_UPDATE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: OrganizationIntegrationTokenFormTypes) => {
        updateOrganizationIntegrationToken({
            ...values,
            uuid,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <OrganizationIntegrationTokenForm
            onSubmitClicked={onSubmitClicked}
            initialValues={{
                title,
            }}
        />
    );
};

export default UpdateOrganizationIntegrationTokenForm;
