import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik} from 'formik';
import {useDispatch} from 'react-redux';

import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

import {DELETE_USER_ACCOUNT} from 'appRedux/actions/profile';

import {RouteContext} from 'contexts/route/context';
import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import {validationSchema} from 'components/Forms/SettingsForms/DeleteAccountForm/validation';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';

import {preventSendForm} from 'helpers/index';

const DeleteAccountForm: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {onLogoutClicked} = useContext(RouteContext);
    const {showAlert} = useContext(AlertContext);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const toggleShowPassword = () => {
        setShowPassword(previous => !previous);
    };

    const toggleShowModal = () => {
        setShowModal(previous => !previous);
    };

    const deleteUserAccount = useCallback(
        data => dispatch({type: DELETE_USER_ACCOUNT.REQUEST, payload: data}),
        [dispatch],
    );

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <>
            <AgentSaveButton title={'common.buttons.delete'} isDanger onClick={toggleShowModal} />
            <ModalWrapper
                isShowModal={showModal}
                title={t('common.account.deleteAccount')}
                toggleModal={toggleShowModal}
            >
                <Formik
                    initialValues={{
                        username: '',
                        password: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={values => {
                        deleteUserAccount({
                            ...values,
                            showAlert,
                            callback: onLogoutClicked,
                        });
                    }}
                >
                    {() => {
                        return (
                            <Form onKeyDown={preventSendForm}>
                                <Alert severity="error" sx={{mt: 1, mb: 1}}>
                                    <Typography>{t('common.account.deleteAccountExplanation')}</Typography>
                                </Alert>
                                <Field
                                    name="username"
                                    label={t('common.account.emailOrNickname')}
                                    placeholder={t('common.account.emailOrNickname')}
                                    component={FormikTextInput}
                                />
                                <Field
                                    name="password"
                                    label={t('common.account.password')}
                                    placeholder={t('common.account.password')}
                                    type={showPassword ? 'text' : 'password'}
                                    component={FormikTextInput}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={toggleShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        pt: 2,
                                    }}
                                >
                                    <AgentSaveButton isSubmit title={'common.buttons.confirm'} />
                                </Box>
                            </Form>
                        );
                    }}
                </Formik>
            </ModalWrapper>
        </>
    );
};

export default DeleteAccountForm;
