import React, {FC, useState} from 'react';

import DeleteAdminConfirmationButton from 'components/AdminScreenComponents/DeleteAdminConfirmationButton';

interface DeleteOptionType {
    index: number;
    onDeleteClick: (index: number) => void;
}

const DeleteOption: FC<DeleteOptionType> = ({index, onDeleteClick}) => {
    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    const handleDeleteClick = (index: number) => {
        onDeleteClick(index);
        toggleModal();
    };

    return (
        <DeleteAdminConfirmationButton
            disabled={false}
            isShowModal={isShowModal}
            toggleModal={toggleModal}
            onClickAction={() => handleDeleteClick(index)}
        />
    );
};

export default DeleteOption;
