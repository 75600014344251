import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';

import {ERROR_TEXT_COLOR} from 'config/theme';

interface FormFieldErrorMessageType {
    errorMessage: string;
}

const FormFieldErrorMessage: FC<FormFieldErrorMessageType> = ({errorMessage}) => {
    const [t] = useTranslation();
    return (
        <Typography sx={{mt: 1, mb: 1, fontSize: 12, color: ERROR_TEXT_COLOR}}>{t(String(errorMessage))}</Typography>
    );
};

export default FormFieldErrorMessage;
