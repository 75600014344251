import get from 'lodash/get';

import {ChatMessageTypes} from 'appRedux/actions/requestChat/types';

export const getNewestMessageId = (messages: ChatMessageTypes, newestMessageId: string): string => {
    const messagesNumber = messages.length;
    if (messagesNumber > 0) {
        return get(messages, [messagesNumber - 1, 'uuid'], '');
    }
    return newestMessageId;
};
