import {FormFieldTypes, FormPageTypes} from 'appRedux/actions/forms/types';
import {allowedCardFields} from 'pages/admin/updateForm/partials/FormStructure/helper';

export const getAllowedCardFields = (pages: FormPageTypes[]) =>
    Array.from(
        pages.reduce((acc, page) => {
            page.sections?.forEach(section => {
                section.fields?.forEach(field => {
                    if (allowedCardFields.includes(field.type)) {
                        acc.push(field);
                    }
                });
            });

            return acc;
        }, [] as FormFieldTypes[]),
    );
