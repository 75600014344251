import React, {FC, MouseEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import AgentAddMultipleButton from 'components/AgentScreenComponents/_buttons/AgentAddMultipleButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import {theme} from 'config/theme';
import CreateBoardForm from 'components/Forms/BoardForm/CreateBoardForm';
import CreateListBoardForm from 'components/Forms/BoardForm/CreateListBoardForm';
import CreateOverviewBoardForm from 'components/Forms/BoardForm/CreateOverviewBoardForm';
import CreateSwimlaneBoardForm from 'components/Forms/BoardForm/CreateSwimlaneBoardForm ';

const AddBoardDropdown: FC = () => {
    const [t] = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const [showAddColumnModal, setShowAddColumnModal] = useState<boolean>(false);

    const toggleAddColumnModal = () => {
        setShowAddColumnModal(previous => !previous);
    };

    const [showAddSwimlaneModal, setShowAddSwimlaneModal] = useState<boolean>(false);

    const toggleAddSwimlaneModal = () => {
        setShowAddSwimlaneModal(previous => !previous);
    };

    const [showAddListModal, setShowAddListModal] = useState<boolean>(false);

    const toggleAddListModal = () => {
        setShowAddListModal(previous => !previous);
    };

    const [showAddOverviewModal, setShowAddOverviewModal] = useState<boolean>(false);

    const toggleAddOverviewModal = () => {
        setShowAddOverviewModal(previous => !previous);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                mb: 2,
            }}
        >
            <AgentAddMultipleButton title={t('orguser.boards.addBoard')} onClick={handleClick} open={open} />
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        mt: 1,
                        pl: 2,
                        pr: 2,
                        pt: 1,
                        pb: 1,
                        minWidth: 250,
                        borderRadius: 2,
                        overflow: 'visible',
                        boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                        backgroundColor: theme.palette.background.default,
                        '& ul': {
                            pColumning: 0,
                        },
                        overflowX: 'hidden',
                    },
                }}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            >
                <MenuItem onClick={toggleAddColumnModal}>
                    <Typography>{t('orguser.boards.addColumnBoard')}</Typography>
                </MenuItem>
                <MenuItem onClick={toggleAddListModal}>
                    <Typography>{t('orguser.boards.addListBoard')}</Typography>
                </MenuItem>
                <MenuItem onClick={toggleAddOverviewModal}>
                    <Typography>{t('orguser.boards.addOverviewBoard')}</Typography>
                </MenuItem>
                <MenuItem onClick={toggleAddSwimlaneModal}>
                    <Typography>{t('orguser.boards.addSwimlaneBoard')}</Typography>
                </MenuItem>
            </Menu>
            <ModalWrapper
                isShowModal={showAddColumnModal}
                toggleModal={toggleAddColumnModal}
                title={t('orguser.boards.addColumnBoard')}
            >
                <CreateBoardForm closeModal={() => setShowAddColumnModal(false)} />
            </ModalWrapper>
            <ModalWrapper
                isShowModal={showAddSwimlaneModal}
                toggleModal={toggleAddSwimlaneModal}
                title={t('orguser.boards.addSwimlaneBoard')}
            >
                <CreateSwimlaneBoardForm closeModal={() => setShowAddSwimlaneModal(false)} />
            </ModalWrapper>
            <ModalWrapper
                isShowModal={showAddListModal}
                toggleModal={toggleAddListModal}
                title={t('orguser.boards.addListBoard')}
            >
                <CreateListBoardForm closeModal={() => setShowAddListModal(false)} />
            </ModalWrapper>
            <ModalWrapper
                isShowModal={showAddOverviewModal}
                toggleModal={toggleAddOverviewModal}
                title={t('orguser.boards.addOverviewBoard')}
                isWideModal
            >
                <CreateOverviewBoardForm closeModal={() => setShowAddOverviewModal(false)} />
            </ModalWrapper>
        </Box>
    );
};

export default AddBoardDropdown;
