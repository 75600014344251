import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';
import CopySvgIcon from 'assets/icons/buttons/CopySvgIcon';

import {WorkflowItemTypes, WorkflowStatusItemTypes, WorkflowFormItemTypes} from 'appRedux/actions/workflow/types';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {CLONE_WORKFLOW} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import DeleteWorkflowButton from 'pages/admin/workflows/DeleteWorkflowButton';
import {getTranslatedWorkflowStatus} from 'pages/admin/workflows/helper';

import {routes, PARAMETER_LANGUAGE} from 'config/index';

interface WorkflowItemRow {
    item: WorkflowItemTypes;
    currentUserLanguage: string;
    currentOrganizationLanguage?: LanguageItemTypes;
}

const WorkflowItemRow: FC<WorkflowItemRow> = ({item, currentUserLanguage, currentOrganizationLanguage}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {isFormEditEnabled} = useContext(PermissionContext);
    const {showAlert} = useContext(AlertContext);

    const removeWorkflow = useCallback(data => dispatch({type: CLONE_WORKFLOW.REQUEST, payload: data}), [dispatch]);

    const {title, workflowStatuses, forms, uuid} = item;

    const redirectToWorkflow = () => {
        if (currentOrganizationLanguage && currentOrganizationLanguage.name === currentUserLanguage) {
            navigate(`${routes.WORKFLOW}/${uuid}/edit`);
        } else {
            navigate(`${routes.WORKFLOW}/${uuid}/edit?${PARAMETER_LANGUAGE}=${currentUserLanguage}`);
        }
    };

    const redirectToForm = (id: number) => {
        if (isFormEditEnabled) {
            navigate(`${routes.FORM}/${id}/edit`);
        }
    };

    const onCloneClicked = () => {
        removeWorkflow({
            uuid,
            showAlert,
        });
    };

    return (
        <TableRow>
            <TableCell>
                <Typography>{title}</Typography>
            </TableCell>
            <TableCell>
                {workflowStatuses &&
                    workflowStatuses.map((workflowStatus: WorkflowStatusItemTypes, index: number) => {
                        return (
                            <Typography key={`status-${uuid}-type-${index}`} sx={{mb: 0.5}}>
                                <span style={{fontWeight: 600, marginRight: 5}}>
                                    {getTranslatedWorkflowStatus(workflowStatus, currentUserLanguage)}
                                </span>
                                {`(${t(String(workflowStatus.statusType))})`}
                            </Typography>
                        );
                    })}
            </TableCell>
            <TableCell>
                {forms.length > 0 ? (
                    forms.map((form: WorkflowFormItemTypes) => {
                        const {id, title} = form;
                        return (
                            <Typography
                                key={`form-${id}`}
                                sx={{mb: 0.5, cursor: isFormEditEnabled ? 'pointer' : 'initial', fontWeight: 700}}
                                onClick={() => redirectToForm(id)}
                            >
                                {title}
                            </Typography>
                        );
                    })
                ) : (
                    <Typography sx={{mb: 0.5}}>{t('common.none')}</Typography>
                )}
            </TableCell>
            <TableCell width={150}>
                <IconButton title={t('common.buttons.update')} onClick={redirectToWorkflow}>
                    <EditSvgIcon />
                </IconButton>
                <IconButton title={t('common.buttons.copy')} onClick={onCloneClicked}>
                    <CopySvgIcon />
                </IconButton>
                <DeleteWorkflowButton item={item} />
            </TableCell>
        </TableRow>
    );
};

export default WorkflowItemRow;
