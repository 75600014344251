import * as yup from 'yup';

import {REGEX_FOR_TOOLTIP, REGEX_FOR_DESCRIPTION, REGEX_FOR_TITLES} from 'components/Forms/FormBuilder/helper';

export const formPopupInitialValues = {
    title: '',
    description: '',
    tooltip: '',
    isRequired: true,
};

const POPUP_TITLE_MINIMAL_LENGTH = 2;
const POPUP_TITLE_MAXIMAL_LENGTH = 100;

const POPUP_TOOLTIP_MINIMAL_LENGTH = 2;
const POPUP_TOOLTIP_MAXIMAL_LENGTH = 100;

export const formPopupValidationSchema = (t: (trans: string) => string) =>
    yup.object({
        title: yup
            .string()
            .required('messages.validation.isRequired')
            .matches(REGEX_FOR_TITLES, 'messages.validation.valueStructureValidationLatinNumbersSpecialAllowed')
            .min(
                POPUP_TITLE_MINIMAL_LENGTH,
                t('messages.validation.valueMinimalLength').replace('%NUMBER%', String(POPUP_TITLE_MINIMAL_LENGTH)),
            )
            .max(
                POPUP_TITLE_MAXIMAL_LENGTH,
                t('messages.validation.valueMaximalLength').replace('%NUMBER%', String(POPUP_TITLE_MAXIMAL_LENGTH)),
            ),
        description: yup
            .string()
            .matches(REGEX_FOR_DESCRIPTION, 'messages.validation.valueStructureValidationLatinNumbersSpecialAllowed')
            .nullable(),
        tooltip: yup
            .string()
            .nullable()
            .matches(REGEX_FOR_TOOLTIP, 'messages.validation.valueStructureValidationLatinNumbersSpecialAllowed')
            .min(
                POPUP_TOOLTIP_MINIMAL_LENGTH,
                t('messages.validation.valueMinimalLength').replace('%NUMBER%', String(POPUP_TOOLTIP_MINIMAL_LENGTH)),
            )
            .max(
                POPUP_TOOLTIP_MAXIMAL_LENGTH,
                t('messages.validation.valueMaximalLength').replace('%NUMBER%', String(POPUP_TOOLTIP_MAXIMAL_LENGTH)),
            ),
    });

export const formPopupValidationSchemaNotOnlyLatin = (t: (trans: string) => string) =>
    yup.object({
        title: yup
            .string()
            .required('messages.validation.isRequired')
            .min(
                POPUP_TITLE_MINIMAL_LENGTH,
                t('messages.validation.valueMinimalLength').replace('%NUMBER%', String(POPUP_TITLE_MINIMAL_LENGTH)),
            )
            .max(
                POPUP_TITLE_MAXIMAL_LENGTH,
                t('messages.validation.valueMaximalLength').replace('%NUMBER%', String(POPUP_TITLE_MAXIMAL_LENGTH)),
            ),
        description: yup.string().nullable(),
        tooltip: yup
            .string()
            .nullable()
            .min(
                POPUP_TOOLTIP_MINIMAL_LENGTH,
                t('messages.validation.valueMinimalLength').replace('%NUMBER%', String(POPUP_TOOLTIP_MINIMAL_LENGTH)),
            )
            .max(
                POPUP_TOOLTIP_MAXIMAL_LENGTH,
                t('messages.validation.valueMaximalLength').replace('%NUMBER%', String(POPUP_TOOLTIP_MAXIMAL_LENGTH)),
            ),
    });
