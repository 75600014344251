import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';
import CloseSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';

interface DeleteConfirmationType {
    disabled: boolean;
    isShowModal: boolean;
    toggleModal: () => void;
    onClickAction: () => void;
    description?: string;
    deletingRestrict?: boolean;
    closeIcon?: boolean;
}

const DeleteAdminConfirmationButton: FC<DeleteConfirmationType> = ({
    disabled,
    isShowModal,
    toggleModal,
    onClickAction,
    description,
    deletingRestrict,
    closeIcon,
}) => {
    const [t] = useTranslation();
    return (
        <>
            <IconButton title={t('common.buttons.delete')} disabled={disabled} onClick={toggleModal}>
                {closeIcon ? <CloseSvgIcon isDisabled={disabled} /> : <DeleteSvgIcon isDisabled={disabled} />}
            </IconButton>
            <DeleteAdminConfirmationModal
                toggleModal={toggleModal}
                isShowModal={isShowModal}
                description={description}
                deletingRestrict={deletingRestrict}
                onClickAction={onClickAction}
            />
        </>
    );
};

export default DeleteAdminConfirmationButton;
