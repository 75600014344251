import React, {FC, useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {FORM_PAGE_ADD} from 'appRedux/actions/forms';

import PageForm from 'components/Forms/FormBuilder/PageForm/Form';
import {formPageInitialValues} from 'components/Forms/FormBuilder/PageForm/validation';

interface CreatePageFormType {
    versionId: number;
    toggleModal: () => void;
}

const CreatePageForm: FC<CreatePageFormType> = ({versionId, toggleModal}) => {
    const dispatch = useDispatch();

    const submitCreateForm = useCallback(data => dispatch({type: FORM_PAGE_ADD.REQUEST, payload: data}), [dispatch]);

    return (
        <PageForm
            entityId={versionId}
            toggleModal={toggleModal}
            onSubmitClicked={submitCreateForm}
            initialValues={formPageInitialValues}
            isCreate
        />
    );
};

export default CreatePageForm;
