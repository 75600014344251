import React, {FC, MouseEvent, ReactNode} from 'react';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Download from '@mui/icons-material/Download';
import Close from '@mui/icons-material/Close';

import {theme} from 'config/theme';

const GALLERY_BUTTON_SIZE = 45;

export interface GalleryWrapperType {
    isShowModal: boolean;
    toggleModal?: () => void;
    title?: string;
    handleDownload?: () => void;
    children: ReactNode;
}

const GalleryWrapper: FC<GalleryWrapperType> = ({children, title, isShowModal, toggleModal, handleDownload}) => {
    const handleClose = (event: any, reason: string) => {
        if (reason && reason == 'backdropClick') return;
        toggleModal && toggleModal();
    };

    const onModalClick = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    };

    return (
        <Modal open={isShowModal} onClose={handleClose} style={{overflow: 'scroll'}} onClick={onModalClick}>
            <Box
                sx={{
                    position: 'fixed',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    width: '100%',
                    minHeight: '100%',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    backgroundColor: theme.palette.background.default,
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flex: '0 0 60px',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: theme.palette.info.main,
                        pl: 4,
                        pr: 5,
                        zIndex: 10,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'start',
                            alignItems: 'center',
                            gap: 3,
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                color: theme.palette.background.default,
                                fontWeight: 600,
                            }}
                        >
                            {title}
                        </Typography>
                        <IconButton
                            data-id="button@topbar-left-menu"
                            sx={{
                                width: GALLERY_BUTTON_SIZE,
                                height: GALLERY_BUTTON_SIZE,
                            }}
                            onClick={handleDownload}
                            disabled={!handleDownload}
                        >
                            <Download sx={{color: theme.palette.background.default}} fontSize="large" />
                        </IconButton>
                    </Box>
                    {toggleModal && (
                        <IconButton
                            onClick={toggleModal}
                            sx={{
                                width: GALLERY_BUTTON_SIZE,
                                height: GALLERY_BUTTON_SIZE,
                            }}
                        >
                            <Close sx={{color: theme.palette.background.default}} fontSize="large" />
                        </IconButton>
                    )}
                </Box>

                {children}
            </Box>
        </Modal>
    );
};

export default GalleryWrapper;
