import React, {FC, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {RouteContext} from 'contexts/route/context';

import {RootReducer} from 'appRedux/reducers';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';
import CreateRoleForm from 'components/Forms/RoleForm/CreateRoleForm';
import {ROLES_LIST} from 'components/AgentScreenComponents/helper';

import {isOrgAdminUser, isSuperAdminUser} from 'helpers/roles';

import {routes, PARAMETER_TAB} from 'config/index';

const CreateRole: FC = () => {
    const [t] = useTranslation();
    const {id} = useParams();

    const {setBackLink, setBackLinkLabel} = useContext(RouteContext);

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        const isSuperAdmin = profile ? isSuperAdminUser(profile) : false;
        if (isSuperAdmin) {
            setBackLink(`${routes.UPDATE_ORGANIZATION}/${id}/roles`);
        } else if (profile) {
            const route = isOrgAdminUser(profile) ? routes.ADMIN : routes.AGENT;
            setBackLink(`${route}?${PARAMETER_TAB}=${ROLES_LIST}`);
        }

        setBackLinkLabel(t('common.links.backToRoles'));
    }, []);

    return (
        <ClientSectionWrapper title={t('orguser.roles.createRole')}>
            <CreateRoleForm />
        </ClientSectionWrapper>
    );
};

export default CreateRole;
