import React, {FC} from 'react';

const XlsSvgIcon: FC = () => {
    return (
        <svg fill="currentColor" height="75px" width="75px" viewBox="0 0 48 48">
            <g>
                <path
                    d="M47.988,21.94c-0.006-0.091-0.023-0.179-0.054-0.266c-0.011-0.032-0.019-0.063-0.033-0.094
			c-0.048-0.103-0.108-0.199-0.191-0.283c-0.001-0.002-0.002-0.003-0.004-0.005L42,15.586V10c0-0.021-0.011-0.04-0.012-0.061
			c-0.006-0.089-0.023-0.175-0.052-0.259c-0.011-0.032-0.019-0.062-0.033-0.093c-0.049-0.106-0.11-0.207-0.196-0.293l-9-9
			c-0.085-0.085-0.186-0.146-0.291-0.195c-0.032-0.015-0.065-0.024-0.099-0.035c-0.081-0.027-0.164-0.044-0.249-0.05
			C32.044,0.012,32.023,0,32,0H7C6.447,0,6,0.448,6,1v14.586l-5.706,5.706c-0.002,0.002-0.002,0.003-0.004,0.005
			c-0.083,0.083-0.143,0.18-0.191,0.283c-0.014,0.03-0.022,0.062-0.033,0.094c-0.03,0.086-0.048,0.174-0.054,0.266
			C0.011,21.961,0,21.979,0,22v19c0,0.552,0.447,1,1,1h5v5c0,0.552,0.447,1,1,1h34c0.553,0,1-0.448,1-1v-5h5c0.553,0,1-0.448,1-1V22
			C48,21.979,47.989,21.961,47.988,21.94z M44.586,21H42v-2.586L44.586,21z M38.586,9H33V3.414L38.586,9z M8,2h23v8
			c0,0.552,0.447,1,1,1h8v5v5H8v-5V2z M6,18.414V21H3.414L6,18.414z M40,46H8v-4h32V46z M46,40H2V23h5h34h5V40z"
                />
                <polygon points="24.374,25.964 22.317,25.964 22.317,38 27.689,38 27.689,36.113 24.374,36.113 		" />
                <path
                    d="M31.021,27.885c0.147-0.136,0.312-0.235,0.493-0.298c0.181-0.062,0.368-0.093,0.561-0.093
			c0.669,0,1.224,0.266,1.666,0.799l1.122-1.462c-0.329-0.385-0.734-0.677-1.215-0.876c-0.482-0.198-1.028-0.297-1.64-0.297
			c-0.419,0-0.833,0.071-1.241,0.212c-0.408,0.142-0.774,0.36-1.097,0.655c-0.323,0.295-0.584,0.666-0.782,1.113
			c-0.198,0.448-0.298,0.984-0.298,1.607c0,0.499,0.065,0.926,0.195,1.283c0.13,0.358,0.306,0.669,0.527,0.935
			c0.221,0.267,0.476,0.496,0.765,0.689c0.289,0.193,0.598,0.368,0.927,0.527c0.521,0.261,0.952,0.544,1.292,0.85
			c0.34,0.306,0.51,0.72,0.51,1.241c0,0.533-0.142,0.946-0.425,1.241c-0.283,0.295-0.64,0.442-1.071,0.442
			c-0.385,0-0.762-0.091-1.131-0.272s-0.683-0.431-0.944-0.748l-1.105,1.496c0.34,0.397,0.793,0.725,1.36,0.986
			c0.567,0.261,1.184,0.391,1.853,0.391c0.465,0,0.907-0.079,1.326-0.238c0.419-0.159,0.785-0.394,1.097-0.706
			c0.312-0.311,0.561-0.694,0.748-1.147s0.28-0.975,0.28-1.564c0-0.51-0.079-0.952-0.238-1.326
			c-0.159-0.374-0.363-0.697-0.612-0.969c-0.249-0.272-0.527-0.504-0.833-0.697c-0.306-0.193-0.606-0.363-0.901-0.51
			c-0.499-0.249-0.901-0.513-1.207-0.791c-0.306-0.277-0.459-0.671-0.459-1.181c0-0.295,0.042-0.55,0.128-0.765
			C30.757,28.197,30.874,28.021,31.021,27.885z"
                />
                <polygon
                    points="20.719,25.964 18.628,25.964 16.86,30.112 15.075,25.964 12.95,25.964 15.67,31.659 12.661,38 14.786,38 
			16.809,33.393 18.815,38 21.008,38 17.982,31.659 		"
                />
            </g>
        </svg>
    );
};

export default XlsSvgIcon;
