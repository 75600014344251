import {fork, put, take, call} from 'redux-saga/effects';

import {http} from 'services/http';

import {
    FORM_POPUP_ADD,
    FORM_POPUP_UPDATE,
    FORM_POPUP_DELETE,
    FORM_POPUP_COPY,
    FORM_POPUP_DOWN,
    FORM_POPUP_UP,
    addFormPopup,
    updateFormPopup,
    deleteFormPopup,
    copyFormPopup,
    formPopupUpPriority,
    formPopupDownPriority,
    FORM_POPUP_MOVE,
    formPopupMoving,
} from 'appRedux/actions/forms';
import {
    FormChangeStatusType,
    CommonFormBuilderRequest,
    FormAddPopupRequestType,
    FormPopupMoveRequestType,
    FormItemBasicResponseTypes,
} from 'appRedux/actions/forms/types';
import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchFormPopupAdding() {
    while (true) {
        const {
            payload: {id, callback, showAlert, ...values},
        }: IActionType<FormAddPopupRequestType> = yield take(FORM_POPUP_ADD.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `popup/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(addFormPopup.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(addFormPopup.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(addFormPopup.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormPopupUpdating() {
    while (true) {
        const {
            payload: {id, callback, showAlert, ...values},
        }: IActionType<FormAddPopupRequestType> = yield take(FORM_POPUP_UPDATE.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `popup/${id}/edit`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateFormPopup.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateFormPopup.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateFormPopup.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormPopupDeleting() {
    while (true) {
        const {
            payload: {id, callback, showAlert},
        }: IActionType<CommonFormBuilderRequest> = yield take(FORM_POPUP_DELETE.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `popup/${id}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteFormPopup.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteFormPopup.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteFormPopup.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormPopupCopying() {
    while (true) {
        const {
            payload: {id, showAlert, ...values},
        }: IActionType<CommonFormBuilderRequest> = yield take(FORM_POPUP_COPY.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `popup/${id}/copy`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(copyFormPopup.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(copyFormPopup.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(copyFormPopup.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormPopupMoving() {
    while (true) {
        const {
            payload: {id, showAlert, callback, ...values},
        }: IActionType<FormPopupMoveRequestType> = yield take(FORM_POPUP_MOVE.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `popup/${id}/move`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(formPopupMoving.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(formPopupMoving.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formPopupMoving.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormPopupUpPriority() {
    while (true) {
        const {
            payload: {id, showAlert},
        }: IActionType<FormChangeStatusType> = yield take(FORM_POPUP_UP.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `popup/${id}/up`, {
                method: 'POST',
            });
            if (data.success) {
                yield put(formPopupUpPriority.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(formPopupUpPriority.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formPopupUpPriority.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormPopupDownPriority() {
    while (true) {
        const {
            payload: {id, showAlert},
        }: IActionType<FormChangeStatusType> = yield take(FORM_POPUP_DOWN.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `popup/${id}/down`, {
                method: 'POST',
            });
            if (data.success) {
                yield put(formPopupDownPriority.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(formPopupDownPriority.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formPopupDownPriority.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchFormPopupAdding),
    fork(watchFormPopupUpdating),
    fork(watchFormPopupDeleting),
    fork(watchFormPopupCopying),
    fork(watchFormPopupMoving),
    fork(watchFormPopupUpPriority),
    fork(watchFormPopupDownPriority),
];
