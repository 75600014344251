import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';

import IconButton from '@mui/material/IconButton';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {UserSessionTypes} from 'appRedux/actions/auth/types';
import {REMOVE_USER_SESSION} from 'appRedux/actions/auth';

import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';

import DeleteClientConfirmationModal from 'components/ClientScreenComponents/DeleteClientConfirmationModal';
import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';

interface UserSessionListRowType {
    item: UserSessionTypes;
}

const UserSessionListRow: FC<UserSessionListRowType> = ({item}) => {
    const dispatch = useDispatch();

    const {isClientMode} = useContext(RouteContext);
    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const removeUserSession = useCallback(
        data => dispatch({type: REMOVE_USER_SESSION.REQUEST, payload: data}),
        [dispatch],
    );

    const {uuid, isCurrentBrowser} = item;

    const onClickDelete = () => {
        removeUserSession({
            uuid,
            showAlert,
        });
    };

    const ConfirmationModal = isClientMode ? DeleteClientConfirmationModal : DeleteAdminConfirmationModal;

    return (
        <>
            {!isCurrentBrowser ? (
                <IconButton onClick={toggleIsOpened}>
                    <DeleteSvgIcon />
                </IconButton>
            ) : (
                '-'
            )}

            {isOpened && (
                <ConfirmationModal
                    customTitle={'common.areTouSureLogout'}
                    toggleModal={toggleIsOpened}
                    isShowModal={isOpened}
                    onClickAction={onClickDelete}
                />
            )}
        </>
    );
};

export default UserSessionListRow;
