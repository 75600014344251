import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {CaseAccessRequestTypes} from 'appRedux/actions/crypto/types';
import {DELETE_CASE_ACCESS_REQUEST} from 'appRedux/actions/crypto';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';

interface CaseAccessRequestDeleteButtonType {
    item: CaseAccessRequestTypes;
}

const CaseAccessRequestDeleteButton: FC<CaseAccessRequestDeleteButtonType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {uuid} = item;

    const removeCaseAccessRequest = useCallback(
        data => dispatch({type: DELETE_CASE_ACCESS_REQUEST.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        removeCaseAccessRequest({
            uuid,
            isCurrent: false,
            showAlert,
        });
    };

    return (
        <>
            <IconButton title={t('common.buttons.delete')} onClick={toggleIsOpened}>
                <DeleteSvgIcon />
            </IconButton>
            <DeleteAdminConfirmationModal
                toggleModal={toggleIsOpened}
                description={t('orguser.accessRequests.accessRequestDeletingExplanation')}
                isShowModal={isOpened}
                onClickAction={onClickDelete}
            />
        </>
    );
};

export default CaseAccessRequestDeleteButton;
