import React, {FC} from 'react';

const MinusSvgIcon: FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 11.25H6V12.75H18V11.25Z" fill="#222222" />
        </svg>
    );
};

export default MinusSvgIcon;
