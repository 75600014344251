import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import {FormVersionTypes} from 'appRedux/actions/forms/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface ForceEditButtonType {
    item: FormVersionTypes;
    setIsForceEditClicked: (value: boolean) => void;
}

const ForceEditButton: FC<ForceEditButtonType> = ({item, setIsForceEditClicked}) => {
    const [t] = useTranslation();

    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleShowModal = () => {
        setIsShowModal(previous => !previous);
    };

    const onForceEditClicked = () => {
        setIsShowModal(true);
    };

    return (
        <>
            <AgentInverseButton title={t('orguser.forms.forceEdit')} onClick={onForceEditClicked} />
            <ModalWrapper title={t('orguser.forms.forceEdit')} isShowModal={isShowModal} toggleModal={toggleShowModal}>
                <Alert severity={'error'}>
                    <Typography>
                        {t('orguser.forms.forceVersionEditExplanation').replace('%CASES%', String(item.casesQty))}
                    </Typography>
                </Alert>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 3,
                    }}
                >
                    <AgentInverseButton title={t('common.buttons.cancel')} onClick={() => setIsShowModal(false)} />
                    <AgentSaveButton
                        title={t('common.buttons.confirm')}
                        isSubmit={false}
                        onClick={() => setIsForceEditClicked(true)}
                    />
                </Box>
            </ModalWrapper>
        </>
    );
};

export default ForceEditButton;
