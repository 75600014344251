import React, {FC} from 'react';
import {Image} from '@react-pdf/renderer';

import {pdfStyles} from 'pages/agent/pdfExport/pdfPartials/PdfDocumentStyles';

interface ImgProps {
    src: string;
    preview?: boolean;
    isPdf?: boolean;
}

export const AttachmentComponent: FC<ImgProps> = ({src, preview, isPdf}) => {
    if (preview) {
        return <img style={isPdf ? pdfStyles.attachmentPdf : pdfStyles.attachmentImage} src={src} />;
    }
    return <Image style={isPdf ? pdfStyles.attachmentPdf : pdfStyles.attachmentImage} src={src} />;
};

export const ImageComponent: FC<ImgProps> = ({src, preview}) => {
    if (preview) {
        return <img style={pdfStyles.image} src={src} />;
    }
    return <Image style={pdfStyles.image} src={src} />;
};

export default ImageComponent;
