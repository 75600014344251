import React, {FC, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import SelectAvatar from 'components/UserAvatar/SelectAvatar';
import CurrentUserAvatar from 'components/UserAvatar/CurrentUserAvatar';

import {UPLOAD_AVATAR} from 'appRedux/actions/profile';
import {RootReducer} from 'appRedux/reducers';

import CommonUploadLogoForm from 'components/Forms/CommonLogoForm/CommonUploadLogoForm';
import DeleteAvatar from 'components/UserAvatar/DeleteAvatar';
import {DEFAULT_AVATAR_TYPE} from 'components/UserAvatar/helper';

const AvatarArea: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const submitUploadFile = useCallback(data => dispatch({type: UPLOAD_AVATAR.REQUEST, payload: data}), [dispatch]);

    const {
        profile: {isLoading, profile, avatarLink},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const avatarType = get(profile, 'avatarType', DEFAULT_AVATAR_TYPE);
    const hasCustomAvatar = get(profile, 'hasCustomAvatar', false);

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
        >
            <CurrentUserAvatar size={95} />
            {!isLoading && !avatarLink && avatarType === 0 && !hasCustomAvatar && (
                <Typography variant="body2" sx={{m: 3, fontWeight: 300}}>
                    {t('common.account.noAvatarSelected')}
                </Typography>
            )}
            {!avatarLink ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <CommonUploadLogoForm
                        initialValues={{
                            file: '',
                        }}
                        submitUploadFile={submitUploadFile}
                        identifier="upload-photo"
                        label={t('common.account.uploadPhoto')}
                        transparentButton
                    />
                    <SelectAvatar />
                </Box>
            ) : (
                <DeleteAvatar />
            )}
        </Box>
    );
};

export default AvatarArea;
