import React, {FC, useCallback, useContext} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';

import {WorkflowStatusItemTypes, WorkflowStatusTaskFormTypes} from 'appRedux/actions/workflow/types';
import {CREATE_WORKFLOW_STATUS_TASK} from 'appRedux/actions/workflow';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import {
    getWorkflowTaskDaysPeriod,
    workflowTasksTypes,
    WorkflowTaskTypes,
    WORKFLOW_STATUS_TASK_TYPE_EMAIL_SENDING,
    WORKFLOW_STATUS_TASK_TYPE_CHANGE_STATUS,
    WORKFLOW_STATUS_TASK_TYPE_REMOVE_CASE,
} from 'components/Forms/Workflow/workflowStatusTask/helper';
import FormBuilderCheckboxReverse from 'components/AgentScreenComponents/_form/FormBuilderCheckboxReverse';

import {OPTIONS_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';

interface WorkflowStatusTaskFormType {
    item: WorkflowStatusItemTypes;
    closeForm: () => void;
}

const WorkflowStatusTaskForm: FC<WorkflowStatusTaskFormType> = ({item, closeForm}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        workflow: {isLoading, workflowStatusesList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onSubmitClicked = useCallback(
        data => dispatch({type: CREATE_WORKFLOW_STATUS_TASK.REQUEST, payload: data}),
        [dispatch],
    );

    const {uuid, tasks, permittedIds} = item;

    const permittedIdsArray = permittedIds ? permittedIds.split(OPTIONS_SEPARATOR) : [];

    const onSaveClicked = (values: WorkflowStatusTaskFormTypes) => {
        onSubmitClicked({
            ...values,
            showAlert,
            uuid,
            callback: closeForm,
        });
    };

    return (
        <Formik
            initialValues={{
                type: 0,
                period: 2,
                isForRequester: false,
                isForAgent: false,
                isForOther: false,
            }}
            onSubmit={onSaveClicked}
        >
            {(formik: FormikProps<WorkflowStatusTaskFormTypes>) => {
                return (
                    <Form>
                        <FormControl sx={{mt: 1}} required fullWidth>
                            <InputLabel>{t('orguser.workflows.tasks.type')}</InputLabel>
                            <Select
                                label={t('orguser.workflows.tasks.type')}
                                name={'type'}
                                value={formik.values.type}
                                onChange={e => {
                                    formik.handleChange(e);
                                    const value = Number(e.target.value);
                                    formik.setFieldValue('period', getWorkflowTaskDaysPeriod(value));
                                }}
                                IconComponent={KeyboardArrowDownIcon}
                                fullWidth
                                required
                            >
                                {workflowTasksTypes.map((item: WorkflowTaskTypes, index: number) => {
                                    if (
                                        tasks.some(task => task.type === item.type) &&
                                        (item.type === WORKFLOW_STATUS_TASK_TYPE_CHANGE_STATUS ||
                                            item.type === WORKFLOW_STATUS_TASK_TYPE_REMOVE_CASE)
                                    ) {
                                        return null;
                                    }
                                    return (
                                        <MenuItem key={`workflow-${index}`} value={item.type}>
                                            <Typography sx={{fontWeight: 600}}>{t(item.label)}</Typography>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <FormControl sx={{mt: 2}} required fullWidth>
                            <Typography sx={{fontWeight: 600}}>{`${t('orguser.workflows.tasks.period')}:`}</Typography>
                            <Field
                                required
                                name="period"
                                type="number"
                                placeholder={t('orguser.workflows.tasks.period')}
                                component={FormikTextInput}
                            />
                        </FormControl>
                        {formik.values.type === WORKFLOW_STATUS_TASK_TYPE_CHANGE_STATUS && (
                            <FormControl sx={{mt: 1}} required fullWidth>
                                <InputLabel>{t('orguser.workflows.tasks.newStatus')}</InputLabel>
                                <Select
                                    label={t('orguser.workflows.tasks.newStatus')}
                                    name={'newStatus'}
                                    value={formik.values.newStatus}
                                    onChange={formik.handleChange}
                                    IconComponent={KeyboardArrowDownIcon}
                                    fullWidth
                                    required
                                >
                                    {workflowStatusesList.map((status: WorkflowStatusItemTypes) => {
                                        const {uuid, id, title} = status;
                                        if (permittedIdsArray.includes(String(id))) {
                                            return (
                                                <MenuItem key={`workflow-status-${uuid}`} value={id}>
                                                    <Typography sx={{fontWeight: 600}}>{title}</Typography>
                                                </MenuItem>
                                            );
                                        }
                                        return null;
                                    })}
                                </Select>
                            </FormControl>
                        )}
                        {formik.values.type === WORKFLOW_STATUS_TASK_TYPE_EMAIL_SENDING && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    mt: 2,
                                    mb: 3,
                                }}
                            >
                                <Field
                                    type="checkbox"
                                    name="isForRequester"
                                    label={t('orguser.workflows.tasks.isForRequester')}
                                    component={FormBuilderCheckboxReverse}
                                />
                                <Field
                                    type="checkbox"
                                    name="isForAgent"
                                    label={t('orguser.workflows.tasks.isForAgent')}
                                    component={FormBuilderCheckboxReverse}
                                />
                                <Field
                                    type="checkbox"
                                    name="isForOther"
                                    label={t('orguser.workflows.tasks.isForOther')}
                                    component={FormBuilderCheckboxReverse}
                                />
                            </Box>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentInverseButton onClick={closeForm} title={t('common.buttons.cancel')} />
                            <AgentSaveButton isSubmit isDisabled={isLoading} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default WorkflowStatusTaskForm;
