import React, {FC, useCallback} from 'react';
import {Formik, Field, Form, FormikProps} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';

import {CREATE_BOARD} from 'appRedux/actions/workflow';
import {CreateBoardRequestTypes, WorkflowItemTypes} from 'appRedux/actions/workflow/types';
import {RootReducer} from 'appRedux/reducers';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import {initialValues, validationSchema} from 'components/Forms/BoardForm/validation';
import {iconTypes} from 'components/Forms/BoardForm/helper';

import {IconImageType} from 'pages/agent/savedFilters/helper';

interface CreateBoardFormType {
    closeModal: () => void;
}

const CreateBoardForm: FC<CreateBoardFormType> = ({closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {
        workflow: {isLoading, organizationWorkflows},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const createBoard = useCallback(data => dispatch({type: CREATE_BOARD.REQUEST, payload: data}), [dispatch]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, {setErrors}) => {
                createBoard({
                    ...values,
                    setErrors,
                    callback: closeModal,
                });
            }}
        >
            {(formik: FormikProps<CreateBoardRequestTypes>) => {
                return (
                    <Form>
                        <Box>
                            <Field
                                required
                                name="title"
                                placeholder={t('orguser.boards.parameters.title')}
                                component={FormikTextInput}
                            />
                        </Box>
                        <FormControl sx={{mt: 1}} required fullWidth>
                            <InputLabel>{t('orguser.boards.parameters.workflow')}</InputLabel>
                            <Select
                                label={t('orguser.boards.parameters.workflow')}
                                name={'workflow'}
                                value={formik.values.workflow}
                                onChange={formik.handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                fullWidth
                            >
                                {organizationWorkflows &&
                                    organizationWorkflows.map((item: WorkflowItemTypes, index: number) => {
                                        return (
                                            <MenuItem key={`workflow-${index}`} value={item.id}>
                                                <Typography sx={{fontWeight: 600}}>{item.title}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        <FormControl sx={{mt: 2}} required fullWidth>
                            <InputLabel>{t('orguser.boards.parameters.iconType')}</InputLabel>
                            <Select
                                label={t('orguser.boards.parameters.iconType')}
                                name={'iconType'}
                                value={formik.values.iconType}
                                onChange={formik.handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                fullWidth
                            >
                                {iconTypes.map(({type, icon}: IconImageType) => {
                                    const Icon = icon;
                                    return (
                                        <MenuItem value={type} key={`icon-type-${type}`}>
                                            <IconButton>
                                                <Icon />
                                            </IconButton>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: 3,
                            }}
                        >
                            <AgentSaveButton isSubmit isLoading={isLoading} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CreateBoardForm;
