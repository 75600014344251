import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {PanelItemsTypes} from 'appRedux/actions/analytics/types';
import {DELETE_PANEL} from 'appRedux/actions/analytics';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';

interface DeletePanelButtonType {
    item: PanelItemsTypes;
}

const DeletePanelButton: FC<DeletePanelButtonType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpened(previous => !previous);
    };

    const deletePanel = useCallback(data => dispatch({type: DELETE_PANEL.REQUEST, payload: data}), [dispatch]);

    const onClickDelete = () => {
        deletePanel({
            uuid: item.uuid,
            showAlert,
            callback: () => setIsOpened(false),
        });
    };

    return (
        <>
            <IconButton title={t('common.buttons.delete')} onClick={toggleModal}>
                <DeleteSvgIcon />
            </IconButton>
            <DeleteAdminConfirmationModal
                toggleModal={toggleModal}
                isShowModal={isOpened}
                onClickAction={onClickDelete}
            />
        </>
    );
};

export default DeletePanelButton;
