import React, {createContext} from 'react';

export const WEBSOCKET_PING_INTERVAL = 50000;

export type WebsocketContextType = {
    isShowScrollBottomButton: boolean;
    setIsShowScrollBottomButton: (value: boolean) => void;
    notReadMessagesCounter: number;
    setNotReadMessagesCounter: (value: number) => void;
};

export const WebsocketContext = createContext({} as WebsocketContextType);
