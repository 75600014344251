const WRAPPER_KEY_NAME = 'RSA-OAEP';
const CRYPTO_KEY_NAME = 'AES-GCM';

export const DEFAULT_KEY_NAME = 'wrapperKey';

const cryptoKeyOptions = {
    //these are the crypto key's algorithm options
    name: CRYPTO_KEY_NAME,
    length: 256, //can be  128, 192, or 256
};

export const generateWrapperKeyPair = async (): Promise<CryptoKeyPair> => {
    const cryptoKey = await window.crypto.subtle.generateKey(
        {
            name: WRAPPER_KEY_NAME,
            modulusLength: 2048, //can be 1024, 2048, or 4096
            publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
            hash: {name: 'SHA-256'}, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
        },
        true, //whether the key is extractable (i.e. can be used in exportKey)
        ['wrapKey', 'unwrapKey'], //must be ["encrypt", "decrypt"] or ["wrapKey", "unwrapKey"]
    );
    return cryptoKey;
};

export const generateCryptoKey = async (): Promise<CryptoKey> => {
    const cryptoKey = await window.crypto.subtle.generateKey(
        cryptoKeyOptions,
        true, //whether the key is extractable (i.e. can be used in exportKey)
        ['encrypt', 'decrypt'], //must be ["encrypt", "decrypt"] or ["wrapKey", "unwrapKey"]
    );
    return cryptoKey;
};

export const wrapKey = async (key: CryptoKey, publicKey: CryptoKey): Promise<string> => {
    const wrappedKey = await window.crypto.subtle.wrapKey(
        'raw', //the export format, must be "raw" (only available sometimes)
        key, //the key you want to wrap, must be able to fit in RSA-OAEP padding
        publicKey, //the public key with "wrapKey" usage flag
        {
            //these are the wrapping key's algorithm options
            name: WRAPPER_KEY_NAME,
        },
    );

    const encryptedString = buffer2base64(wrappedKey);
    return encryptedString;
};

export const unwrapKey = async (str: string, privateKey: CryptoKey): Promise<CryptoKey> => {
    const wrapped = base642Buffer(str);

    const key = await window.crypto.subtle.unwrapKey(
        'raw', //the import format, must be "raw" (only available sometimes)
        wrapped, //the key you want to unwrap
        privateKey, //the private key with "unwrapKey" usage flag
        {
            name: WRAPPER_KEY_NAME,
        },
        cryptoKeyOptions,
        false, //whether the key is extractable (i.e. can be used in exportKey)
        ['encrypt', 'decrypt'], //the usages you want the unwrapped key to have
    );

    return key;
};

export const unwrapExtractableKey = async (str: string, privateKey: CryptoKey): Promise<CryptoKey> => {
    const wrapped = base642Buffer(str);
    return await window.crypto.subtle.unwrapKey(
        'raw', //the import format, must be "raw" (only available sometimes)
        wrapped, //the key you want to unwrap
        privateKey, //the private key with "unwrapKey" usage flag
        {
            name: WRAPPER_KEY_NAME,
        },
        cryptoKeyOptions,
        true, //whether the key is extractable (i.e. can be used in exportKey)
        ['encrypt', 'decrypt'], //the usages you want the unwrapped key to have
    );
};

export const exportPublicKey = async (key: CryptoKey): Promise<string> => {
    const exportedKey = await window.crypto.subtle.exportKey(
        'spki', //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
        key, //the key you want to export
    );

    const encryptedString = buffer2base64(exportedKey);
    return encryptedString;
};

export const importPublicKey = async (str: string): Promise<CryptoKey> => {
    const imported = base642Buffer(str);

    const importedPublicKey = await window.crypto.subtle.importKey(
        'spki', //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
        imported, //the key you want to import
        {
            //these are the algorithm options
            name: WRAPPER_KEY_NAME,
            hash: {name: 'SHA-256'}, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
        },
        true, //whether the key is extractable (i.e. can be used in exportKey)
        ['wrapKey'], //"encrypt" or "wrapKey" for public key import or
    );

    return importedPublicKey;
};

export const exportPrivateKey = async (key: CryptoKey): Promise<string> => {
    const exportedKey = await window.crypto.subtle.exportKey(
        'pkcs8', //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
        key, //the key you want to export
    );

    const encryptedString = buffer2base64(exportedKey);
    return encryptedString;
};

export const importPrivateKey = async (str: string): Promise<CryptoKey> => {
    const imported = base642Buffer(str);

    const importedPublicKey = await window.crypto.subtle.importKey(
        'pkcs8', //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
        imported, //the key you want to import
        {
            //these are the algorithm options
            name: WRAPPER_KEY_NAME,
            hash: {name: 'SHA-256'}, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
        },
        true, //whether the key is extractable (i.e. can be used in exportKey)
        ['unwrapKey'], //"decrypt" or "unwrapKey" for private key imports
    );

    return importedPublicKey;
};

export const encryptDataWithKey = async (data: ArrayBuffer, key: CryptoKey): Promise<ArrayBuffer> => {
    const iv = window.crypto.getRandomValues(new Uint8Array(12));

    const encryptedData = await window.crypto.subtle.encrypt(
        {
            name: CRYPTO_KEY_NAME,
            iv,
            tagLength: 128,
        },
        key,
        data,
    );

    const ivAndEncryptedData = new Uint8Array([...iv, ...new Uint8Array(encryptedData)]);

    return ivAndEncryptedData.buffer;
};

export const decryptDataWithKey = async (data: ArrayBuffer, key: CryptoKey): Promise<ArrayBuffer> => {
    const ivAndEncryptedData = new Uint8Array(data);
    const iv = ivAndEncryptedData.slice(0, 12);
    const encryptedData = ivAndEncryptedData.slice(12).buffer;

    const decryptedData = await window.crypto.subtle.decrypt(
        {
            name: CRYPTO_KEY_NAME,
            iv,
            tagLength: 128,
        },
        key,
        encryptedData,
    );
    return decryptedData;
};

export const encryptStringWithKey = async (str: string, key: CryptoKey): Promise<string> => {
    const data = string2Buffer(str);
    const encryptedData = await encryptDataWithKey(data, key);
    const encryptedString = buffer2base64(encryptedData);
    return encryptedString;
};

export const decryptStringWithKey = async (str: string, key: CryptoKey): Promise<string> => {
    if (!isBase64(str)) return str;
    const data = base642Buffer(str);

    const decryptedData = await decryptDataWithKey(data, key);
    const decryptedString = buffer2String(decryptedData);
    return decryptedString;
};

export const encryptBase64FileWithKey = async (str: string, key: CryptoKey): Promise<ArrayBuffer> => {
    const data = base642Buffer(str);
    const encryptedData = await encryptDataWithKey(data, key);
    // return buffer2base64(encryptedData);
    return encryptedData;
};

export const decryptBase64FileWithKey = async (data: ArrayBuffer, key: CryptoKey): Promise<string> => {
    // const data = base642Buffer(str);
    const decryptedData = await decryptDataWithKey(data, key);
    return buffer2base64(decryptedData);
};

export const encryptNumberWithKey = async (num: number, key: CryptoKey): Promise<string> => {
    const data = number2Buffer(num);
    const encryptedData = await encryptDataWithKey(data, key);
    const encryptedString = buffer2base64(encryptedData);
    return encryptedString;
};

export const decryptNumberWithKey = async (str: string, key: CryptoKey): Promise<number> => {
    const data = base642Buffer(str);

    const decryptedData = await decryptDataWithKey(data, key);
    const decryptedNumber = buffer2Number(decryptedData);
    return decryptedNumber;
};

const string2Buffer = (str: string): ArrayBuffer => {
    const buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
    const bufView = new Uint16Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
};

const buffer2String = (buf: ArrayBuffer): string => {
    return String.fromCharCode.apply(null, new Uint16Array(buf) as unknown as number[]);
};

const number2Buffer = (num: number): ArrayBuffer => {
    const buffer = new ArrayBuffer(4); // Assuming 4 bytes for a 32-bit integer
    const view = new DataView(buffer);
    view.setInt32(0, num);
    return buffer;
};

const buffer2Number = (buf: ArrayBuffer): number => {
    const view = new DataView(buf);
    return view.getInt32(0);
};

export const base642Buffer = (base64: string): ArrayBuffer => {
    const str = window.atob(base64);
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
};

const buffer2base64 = (buf: ArrayBuffer): string => {
    // return window.btoa(String.fromCharCode.apply(null, new Uint8Array(buf) as unknown as number[]));
    // To avoid error: "Maximum call stack size exceeded"
    return window.btoa(
        new Uint8Array(buf).reduce(function (data, byte) {
            return data + String.fromCharCode(byte);
        }, ''),
    );
};

export const getWrappedKeyName = (userId: number | string | null, orgID?: string): string => {
    return userId && orgID
        ? `${DEFAULT_KEY_NAME}_${userId}_${orgID}`
        : userId
        ? `${DEFAULT_KEY_NAME}_${userId}`
        : DEFAULT_KEY_NAME;
};

interface ExtractResults {
    prefix: string | null;
    base64: string | null;
}

export const extractBase64FromFileContent = (fileContent: string): ExtractResults => {
    const fileContentArray = fileContent.split('base64,');
    if (fileContent && fileContentArray.length > 0) {
        return {
            prefix: `${fileContentArray[0]}base64,`,
            base64: fileContentArray[1],
        };
    }
    return {prefix: null, base64: null};
};

export const getEncryptedFileData = async (
    url: string,
    encryptPrefix: string,
    unwrappedCaseKey: CryptoKey | null,
): Promise<string | null> => {
    const response = await fetch(url);

    const blob = await response.blob();
    const data = await blob.arrayBuffer();

    if (data && unwrappedCaseKey) {
        const decryptedData = await decryptBase64FileWithKey(data, unwrappedCaseKey);
        return `${encryptPrefix}${decryptedData}`;
    }
    return null;
};

export const getPasswordKeyWithIv = async (password: string, iv: Uint8Array): Promise<CryptoKey> => {
    const pwUtf8 = new TextEncoder().encode(password);
    const pwHash = await crypto.subtle.digest('SHA-256', pwUtf8);

    const alg = {name: 'AES-GCM', iv};
    const key = await crypto.subtle.importKey('raw', pwHash, alg, false, ['encrypt', 'decrypt']);

    return key;
};

export const getPasswordKeyAndIv = async (password: string): Promise<{key: CryptoKey; iv: Uint8Array}> => {
    const iv = crypto.getRandomValues(new Uint8Array(12));

    const key = await getPasswordKeyWithIv(password, iv);

    return {key, iv};
};

export const encryptStringWithPasswordKey = async (str: string, key: CryptoKey, iv: Uint8Array): Promise<string> => {
    const data = string2Buffer(str);
    const encryptedData = await encryptDataWithKey(data, key);
    // add password key iv:
    const ivAndEncryptedData = new Uint8Array([...iv, ...new Uint8Array(encryptedData)]);
    const encryptedString = buffer2base64(ivAndEncryptedData.buffer);
    return encryptedString;
};

export const decryptStringWithPasswordKey = async (str: string, key: CryptoKey): Promise<string> => {
    const ivAndEncryptedData = base642Buffer(str);
    // remove password key iv:
    const encryptedData = new Uint8Array(ivAndEncryptedData).slice(12).buffer;
    const decryptedData = await decryptDataWithKey(encryptedData, key);
    const decryptedString = buffer2String(decryptedData);
    return decryptedString;
};

const isBase64 = str => {
    if (str === '' || str.trim() === '') {
        return false;
    }
    try {
        atob(str);
        return true;
    } catch (err) {
        return false;
    }
};
