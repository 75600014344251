import React, {FC, MouseEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';

import {RootReducer} from 'appRedux/reducers';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {FormHasLanguageTypes} from 'appRedux/actions/forms/types';

import AgentSelectButton from 'components/AgentScreenComponents/_buttons/AgentSelectButton';

import {theme} from 'config/theme';

interface TranslationSelectorType {
    setSelectedLanguage: (value: string) => void;
    organizationLanguage: LanguageItemTypes;
}

const TranslationSelector: FC<TranslationSelectorType> = ({setSelectedLanguage, organizationLanguage}) => {
    const [t] = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const {
        admin: {
            formInfo: {languages},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (value: string) => {
        setSelectedLanguage(value);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const availableLanguages = languages ? languages.filter(item => item.isAvailableForOrganization) : [];

    if (availableLanguages.length === 0) {
        return null;
    }
    return (
        <>
            <AgentSelectButton title={t('orguser.forms.selectLanguage')} onClick={handleClick} open={open} />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        mt: 1,
                        pl: 2,
                        pr: 2,
                        pt: 1,
                        pb: 1,
                        width: 290,
                        borderRadius: 2,
                        overflow: 'visible',
                        boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                        backgroundColor: theme.palette.background.default,
                        '& ul': {
                            padding: 0,
                        },
                        overflowX: 'hidden',
                    },
                }}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            >
                <MenuItem
                    value={organizationLanguage.name}
                    key={`form-language-${organizationLanguage.name}`}
                    onClick={() => handleChange(organizationLanguage.name)}
                >
                    {organizationLanguage.lang}
                </MenuItem>
                {languages &&
                    languages.map((item: FormHasLanguageTypes) => {
                        const {
                            isAvailableForOrganization,
                            isAllMainTranslationsPresented,
                            language: {lang, name},
                        } = item;
                        if (name !== organizationLanguage.name && isAvailableForOrganization) {
                            return (
                                <MenuItem value={name} key={`form-language-${name}`} onClick={() => handleChange(name)}>
                                    <Badge
                                        color="error"
                                        variant="dot"
                                        invisible={isAllMainTranslationsPresented}
                                        sx={{
                                            '& span': {
                                                right: '-5px',
                                            },
                                        }}
                                    >
                                        <Typography>{lang}</Typography>
                                    </Badge>
                                </MenuItem>
                            );
                        }
                    })}
            </Menu>
        </>
    );
};

export default TranslationSelector;
