import React, {FC, useCallback, useContext} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {REQUESTER_CASES_LIST} from 'appRedux/actions/admin';
import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';

import {alphabetLetters, getHeaderTop} from 'pages/agent/listView/helper';

import {PARAMETER_FIRST_LETTER, PARAMETER_PAGE} from 'config/index';
import {CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface AlphabetType {
    casesNumber: number;
}

const Alphabet: FC<AlphabetType> = ({casesNumber}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const {showFiltersPanel, filtersNumber} = useContext(AdminContext);

    const refreshCasesList = useCallback(() => dispatch({type: REQUESTER_CASES_LIST.REQUEST_EMPTY}), [dispatch]);

    const firstLetterFromUrl = searchParams.get(PARAMETER_FIRST_LETTER);

    const {
        admin: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const setFirstLetter = (letter: string) => {
        refreshCasesList();
        searchParams.set(PARAMETER_FIRST_LETTER, letter);
        searchParams.delete(PARAMETER_PAGE);
        setSearchParams(searchParams);
    };

    const onClearAllClick = () => {
        refreshCasesList();
        searchParams.delete(PARAMETER_FIRST_LETTER);
        setSearchParams(searchParams);
    };

    return (
        <Box
            sx={{
                width: '100%',
                pt: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'sticky',
                zIndex: 100,
                top: getHeaderTop(showFiltersPanel, filtersNumber),
                backgroundColor: CLIENT_BACKGROUND_COLOR,
            }}
        >
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                {alphabetLetters.map((item: string, index: number) => {
                    const isActive = firstLetterFromUrl === item;
                    return (
                        <Typography
                            key={`alphabet-${index}`}
                            sx={{fontWeight: isActive ? 600 : 400, cursor: 'pointer', mr: 2}}
                            onClick={() => setFirstLetter(item)}
                        >
                            {item.toUpperCase()}
                        </Typography>
                    );
                })}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                {!isLoading && (
                    <Typography sx={{mt: 3, mb: 3}}>
                        {casesNumber > 0
                            ? `${casesNumber} ${t('common.cases').toLowerCase()}`
                            : `${t('common.none')} ${t('common.cases').toLowerCase()}`}
                    </Typography>
                )}
                {firstLetterFromUrl && (
                    <Typography sx={{ml: 2, cursor: 'pointer', fontWeight: 600}} onClick={onClearAllClick}>
                        {t('orguser.requesterCase.topbar.clearAll')}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default Alphabet;
