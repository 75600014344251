import * as yup from 'yup';

export const MAXIMAL_UPLOAD_FILE_SIZE = 30000000;

export const SUPPORTED_FORMATS: string[] = [
    'application/pdf',
    'application/x-pdf',
    'application/pdg',
    'application/msword',
    'application/octet-stream',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.oasis.opendocument.text',
    'text/plain',
    'text/rtf',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
    'image/png',
    'image/jpeg',
    'image/heic',
    'image/heif',
    'video/x-msvideo',
    'video/mp4',
    'video/webm',
    'video/x-ms-asf',
    'video/quicktime',
    'video/x-ms-wmv',
    'text/richtext',
];

export const validationSchema = (t: (trans: string) => string) =>
    yup.object({
        file: yup
            .mixed()
            .test('fileSize', t('messages.error.wrongDocumentSizeMessage'), value => {
                return value ? value.size <= MAXIMAL_UPLOAD_FILE_SIZE : true;
            })
            .test('fileFormat', t('messages.error.wrongDocumentTypeMessage'), value => {
                return value ? SUPPORTED_FORMATS.includes(value.type) : true;
            }),
    });
