import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';

import {WorkflowEventGroupTypes} from 'appRedux/actions/workflow/types';
import {DELETE_WORKFLOW_EVENT_GROUP} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import GroupChipButton from 'components/AgentScreenComponents/_buttons/GroupChipButton';

interface AttachedGroupListItemType {
    item: WorkflowEventGroupTypes;
}

const AttachedGroupListItem: FC<AttachedGroupListItemType> = ({item}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpen(previous => !previous);
    };

    const detachGroup = useCallback(
        data => dispatch({type: DELETE_WORKFLOW_EVENT_GROUP.REQUEST, payload: data}),
        [dispatch],
    );

    const {uuid, title, agents} = item;

    const onClickDelete = () => {
        detachGroup({
            uuid,
            showAlert,
            callback: () => setIsOpen(false),
        });
    };

    return (
        <GroupChipButton
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            toggleModal={toggleModal}
            title={title}
            agents={agents}
            onClickDelete={onClickDelete}
        />
    );
};

export default AttachedGroupListItem;
