import * as yup from 'yup';

import {REGEX_FOR_PASSWORD} from 'components/RegistrationForm/helper';

import {getPasswordLengthErrorMessage} from 'helpers/security';

import {CRYPTO_PASSWORD_MIN_LENGTH, CRYPTO_PASSWORD_MAX_LENGTH} from 'config/index';

export const validationSchema = (t: (trans: string) => string) =>
    yup.object({
        keyPassword: yup
            .string()
            .min(
                CRYPTO_PASSWORD_MIN_LENGTH,
                getPasswordLengthErrorMessage(t, 'messages.validation.passwordMinLength', CRYPTO_PASSWORD_MIN_LENGTH),
            )
            .max(
                CRYPTO_PASSWORD_MAX_LENGTH,
                getPasswordLengthErrorMessage(t, 'messages.validation.PasswordMaxLength', CRYPTO_PASSWORD_MAX_LENGTH),
            )
            .required(t('messages.validation.passwordIsRequired'))
            .matches(REGEX_FOR_PASSWORD, t('messages.validation.passwordShouldContain')),
    });
