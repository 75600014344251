import React, {FC} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FormBaseInformationTypes} from 'appRedux/actions/auth/types';

import MarkdownWithShowAll from 'components/TextWithShowAll/MarkdownWithShowAll';

interface FormInformationType {
    form: FormBaseInformationTypes;
}

const FormInformation: FC<FormInformationType> = ({form}) => {
    const {title, description} = form;

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 2}}>
            <Typography data-e2e-id="formTitle" variant="h5" sx={{ml: 2, mr: 2, mb: 2, fontSize: 24}}>
                {title}
            </Typography>
            <MarkdownWithShowAll data-e2e-id={'formDescription'} text={description} />
        </Box>
    );
};

export default FormInformation;
