import React, {FC, useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

import {ChatContactTypes} from 'appRedux/actions/requestChat/types';

import {CryptoContext} from 'contexts/crypto/context';
import {CaseKeyContext} from 'contexts/caseKey/context';

import AvatarImage from 'components/UserAvatar/AvatarImage';
import {getAvatarTitle} from 'components/TopBarMenu/helper';
import {getLastMessageDateLabel} from 'components/RequesterChat/helper';

import {decryptStringWithKey} from 'helpers/cryptoApiHelper';

import {MAIN_CLIENT_COLOR, theme} from 'config/theme';
import {routes} from 'config/index';

interface ChatContactItemType {
    item: ChatContactTypes;
    nextId?: number;
}

const ChatContactItem: FC<ChatContactItemType> = ({item, nextId}) => {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const {requestCase} = useParams();

    const {keys} = useContext(CryptoContext);
    const {unwrappedCaseKey} = useContext(CaseKeyContext);

    const {
        caseId,
        caseLabel,
        firstName,
        lastName,
        isEncryptInfo,
        avatarLink,
        avatarType,
        lastMessageDateAndTime,
        lastMessageText,
        notReadMessages,
    } = item;

    const [text, setText] = useState<string>('');

    const hasNotReadMessages = notReadMessages > 0;
    const isCurrent = caseId === Number(requestCase);
    const isNextCurrent = nextId === Number(requestCase);

    const redirectToContact = (caseId: number) => {
        navigate(`${routes.REQUESTER_CASE}/${caseId}/chat`, {replace: true});
    };

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (isEncryptInfo) {
                if (lastMessageText && keys && unwrappedCaseKey) {
                    try {
                        const decodedMessage = await decryptStringWithKey(lastMessageText, unwrappedCaseKey);
                        setText(decodedMessage);
                    } catch (e) {
                        setText('');
                    }
                }
            } else {
                setText(lastMessageText ?? '');
            }
        }, 200);
        return () => clearTimeout(timer);
    }, [requestCase, isEncryptInfo, keys, lastMessageText, unwrappedCaseKey]);

    return (
        <Box
            onClick={() => redirectToContact(caseId)}
            sx={{
                display: 'flex',
                flex: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                cursor: 'pointer',
                backgroundColor: isCurrent ? theme.palette.info.main : 'inherit',
                p: 2,
                borderBottom: isCurrent || isNextCurrent ? 'none' : `1px solid ${theme.palette.info.contrastText}`,
                borderRadius: isCurrent ? 2 : 0,
                '&:hover': {
                    backgroundColor: isCurrent ? theme.palette.info.main : theme.palette.warning.contrastText,
                },
            }}
        >
            <AvatarImage
                size={48}
                avatarLink={avatarLink}
                avatarType={avatarType}
                avatarTitle={getAvatarTitle(firstName, lastName, '')}
            />
            <Box sx={{pl: 1.5, width: '85%'}}>
                <Box
                    sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}
                >
                    <Tooltip
                        title={caseLabel}
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    fontSize: 16,
                                    backgroundColor: 'rgb(97, 97, 97)',
                                },
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: isCurrent ? theme.palette.background.default : theme.palette.info.main,
                                display: 'block',
                                width: '70%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {caseLabel}
                        </Typography>
                    </Tooltip>
                    {lastMessageDateAndTime && (
                        <Typography
                            sx={{
                                fontSize: 11,
                                fontWeight: 300,
                                color: isCurrent ? theme.palette.background.default : theme.palette.info.main,
                            }}
                        >
                            {getLastMessageDateLabel(t, lastMessageDateAndTime)}
                        </Typography>
                    )}
                </Box>
                <Box
                    sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}
                >
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: 300,
                            color: isCurrent ? theme.palette.background.default : theme.palette.info.main,
                            display: 'block',
                            width: hasNotReadMessages ? '85%' : '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {text}
                    </Typography>
                    {hasNotReadMessages && (
                        <Chip
                            label={notReadMessages}
                            size="small"
                            sx={{backgroundColor: MAIN_CLIENT_COLOR, color: theme.palette.background.default}}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default ChatContactItem;
