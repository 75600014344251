import React, {FC} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {OrganizationQuotasTypes} from 'appRedux/actions/organization/types';
import {RootReducer} from 'appRedux/reducers';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import FormBuilderCheckboxReverse from 'components/AgentScreenComponents/_form/FormBuilderCheckboxReverse';

interface OrganizationQuotaFormType {
    typeLabel: string;
    initialValues: OrganizationQuotasTypes;
    onSubmitClicked: (values: OrganizationQuotasTypes) => void;
}

const OrganizationQuotaForm: FC<OrganizationQuotaFormType> = ({initialValues, typeLabel, onSubmitClicked}) => {
    const [t] = useTranslation();

    const {
        admin: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmitClicked}>
            {(formik: FormikProps<OrganizationQuotasTypes>) => {
                return (
                    <Form>
                        <Box>
                            <Typography>{t(typeLabel)}</Typography>
                        </Box>
                        <Box sx={{mb: 2, mt: 1}}>
                            <Field
                                name="value"
                                label={t('superadmin.organizations.value')}
                                type="number"
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box sx={{mb: 2, mt: 1}}>
                            <Field
                                type="checkbox"
                                name="isHard"
                                label={t('superadmin.organizations.isHard')}
                                component={FormBuilderCheckboxReverse}
                            />
                        </Box>
                        <Box>
                            <AgentSaveButton isLoading={isLoading} isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default OrganizationQuotaForm;
