import * as yup from 'yup';

import {MAXIMAL_UPLOAD_FILE_SIZE, SUPPORTED_FORMATS} from 'pages/client/form/fieldTypes/validation';

import {htmlTagsPresented} from 'helpers/validation';

export const chatMessageValidation = (t: (trans: string) => string) =>
    yup.object({
        text: yup
            .string()
            .test('unwanted', t('messages.validation.htmlTagsDenied'), value => htmlTagsPresented(value))
            .nullable(),
        file: yup
            .mixed()
            .test('fileSize', t('messages.error.wrongDocumentSizeMessage'), value => {
                return value ? value.size <= MAXIMAL_UPLOAD_FILE_SIZE : true;
            })
            .test('fileFormat', t('messages.error.wrongDocumentTypeMessage'), value => {
                return value ? SUPPORTED_FORMATS.includes(value.type) : true;
            }),
    });
