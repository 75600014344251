import * as yup from 'yup';

import {htmlTagsPresented} from 'helpers/validation';

export const commentValidation = (t: (trans: string) => string) =>
    yup.object({
        comment: yup
            .string()
            .required()
            .test('unwanted', t('messages.validation.htmlTagsDenied'), value => htmlTagsPresented(value))
            .nullable(),
    });

export const validationSchema = yup.object({
    text: yup.string().required('messages.validation.isRequired'),
});
