import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {ResourceFieldSlotFormTypes} from 'appRedux/actions/resourceFields/types';
import {CREATE_RESOURCE_FIELD_SLOT} from 'appRedux/actions/resourceFields';

import {AlertContext} from 'contexts/alert/context';

import ResourceFieldSlotForm from 'components/Forms/ResourceField/ResourceFieldSlotForm';

interface CreateResourceFieldSlotFormType {
    uuid: string;
    closeModal: () => void;
}

const CreateResourceFieldSlotForm: FC<CreateResourceFieldSlotFormType> = ({uuid, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const createResourceFieldSlot = useCallback(
        data => dispatch({type: CREATE_RESOURCE_FIELD_SLOT.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: ResourceFieldSlotFormTypes) => {
        createResourceFieldSlot({
            ...values,
            uuid,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <ResourceFieldSlotForm
            initialValues={{
                title: '',
                description: '',
                eventName: '',
                qty: 0,
            }}
            onSubmitClicked={onSubmitClicked}
        />
    );
};

export default CreateResourceFieldSlotForm;
