import React, {FC, useCallback, useContext} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import {GROUP_ATTACH_USER} from 'appRedux/actions/groups';
import {GroupItemTypes, GroupAttachUserFormTypes} from 'appRedux/actions/groups/types';
import {OrganizationAdminListTypes} from 'appRedux/actions/organizationAdmin/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {getAvatarTitle} from 'components/TopBarMenu/helper';
import AvatarImage from 'components/UserAvatar/AvatarImage';

interface AttachUserToGroupFormType {
    item: GroupItemTypes;
    closeModal: () => void;
}

const AttachUserToGroupForm: FC<AttachUserToGroupFormType> = ({item, closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const attachUserToGroup = useCallback(
        data => dispatch({type: GROUP_ATTACH_USER.REQUEST, payload: data}),
        [dispatch],
    );

    const {uuid, users} = item;

    const {
        admin: {organizationAdminList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onSubmitClicked = (values: GroupAttachUserFormTypes) => {
        attachUserToGroup({
            ...values,
            uuid,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <Formik
            enableReinitialize
            initialValues={{
                user: 0,
            }}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<GroupAttachUserFormTypes>) => {
                return (
                    <Form>
                        <FormControl fullWidth>
                            <InputLabel>{t('orguser.groups.orguser')}</InputLabel>
                            <Select
                                fullWidth
                                name="user"
                                variant="standard"
                                IconComponent={KeyboardArrowDownIcon}
                                onChange={formik.handleChange}
                                sx={{
                                    '& div': {
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                    },
                                }}
                            >
                                {organizationAdminList &&
                                    organizationAdminList.map((item: OrganizationAdminListTypes) => {
                                        const {
                                            admin: {id, avatarType, avatarLink, firstName, lastName, email, isLocked},
                                        } = item;
                                        const avatarTitle = getAvatarTitle(firstName, lastName, email);
                                        if (users.some(user => user.id === id) || isLocked) {
                                            return null;
                                        }
                                        return (
                                            <MenuItem
                                                key={email}
                                                value={id}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    mt: 1,
                                                    mb: 1,
                                                }}
                                            >
                                                <AvatarImage
                                                    size={25}
                                                    avatarType={avatarType ? avatarType : 0}
                                                    avatarLink={avatarLink ? avatarLink : null}
                                                    avatarTitle={avatarTitle}
                                                />
                                                <Typography sx={{ml: 1, fontWeight: 600}}>
                                                    {firstName && lastName ? `${firstName} ${lastName}` : email}
                                                </Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default AttachUserToGroupForm;
