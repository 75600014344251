import {call, fork, put, take} from 'redux-saga/effects';

import {
    FORM_VERSION_STATUSES,
    FORM_VERSION_STRUCTURE,
    CREATE_FORM_VERSION,
    UPDATE_FORM_VERSION,
    DEACTIVATE_FORM_VERSION,
    DELETE_FORM_VERSION,
    getFormVersionStatuses,
    getFormStructure,
    createFormStructure,
    updateFormStructure,
    deactivateFormStructure,
    deleteFormStructure,
} from 'appRedux/actions/forms';
import {
    FormVersionStatusesRequestType,
    FormVersionStatusesResponseType,
    FormStructureResponseTypes,
    FormVersionStructureRequestType,
    FormVersionStructureUpdateType,
    FormVersionStructureCreateType,
    FormVersionStructureDeactivateType,
    FormVersionStructureRemoveType,
} from 'appRedux/actions/forms/types';

import {http} from 'services/http';

import {ALERT_TYPE_SUCCESS, ALERT_TYPE_ERROR} from 'config/index';

function* watchFormVersionStatuses() {
    while (true) {
        const {
            payload: {id, showAlert},
        }: IActionType<FormVersionStatusesRequestType> = yield take(FORM_VERSION_STATUSES.REQUEST);
        try {
            const data: FormVersionStatusesResponseType = yield call(http, `version/${id}/statuses`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getFormVersionStatuses.success(data.results));
            } else {
                yield put(getFormVersionStatuses.error({message: 'Something went wrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getFormVersionStatuses.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormStructureByVersion() {
    while (true) {
        const {
            payload: {uuid, showAlert},
        }: IActionType<FormVersionStructureRequestType> = yield take(FORM_VERSION_STRUCTURE.REQUEST);
        try {
            const data: FormStructureResponseTypes = yield call(http, `version/${uuid}/structure`, {
                method: 'GET',
            });
            if (data.success && data.pages) {
                yield put(getFormStructure.success(data.pages));
            } else {
                yield put(getFormStructure.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getFormStructure.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormVersionCreation() {
    while (true) {
        const {
            payload: {id, showAlert, callback},
        }: IActionType<FormVersionStructureCreateType> = yield take(CREATE_FORM_VERSION.REQUEST);
        try {
            const data: FormStructureResponseTypes = yield call(http, `form/${id}/version/create`, {
                method: 'POST',
            });
            if (data.success && data.versions) {
                yield put(createFormStructure.success(data.versions));
                if (callback && data.redirectUuid) {
                    showAlert && showAlert(ALERT_TYPE_SUCCESS);
                    callback(data.redirectUuid);
                }
            } else {
                yield put(createFormStructure.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createFormStructure.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormVersionUpdation() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback, ...values},
        }: IActionType<FormVersionStructureUpdateType> = yield take(UPDATE_FORM_VERSION.REQUEST);
        try {
            const data: FormStructureResponseTypes = yield call(http, `form/${uuid}/version/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success && data.versions) {
                yield put(updateFormStructure.success(data.versions));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(updateFormStructure.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateFormStructure.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormVersionDeactivation() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback},
        }: IActionType<FormVersionStructureDeactivateType> = yield take(DEACTIVATE_FORM_VERSION.REQUEST);
        try {
            const data: FormStructureResponseTypes = yield call(http, `form/${uuid}/version/deactivate`, {
                method: 'GET',
            });
            if (data.success && data.versions) {
                yield put(deactivateFormStructure.success(data.versions));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(deactivateFormStructure.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deactivateFormStructure.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormVersionDeleting() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback},
        }: IActionType<FormVersionStructureRemoveType> = yield take(DELETE_FORM_VERSION.REQUEST);
        try {
            const data: FormStructureResponseTypes = yield call(http, `form/${uuid}/version/delete`, {
                method: 'DELETE',
            });
            if (data.success && data.versions) {
                yield put(deleteFormStructure.success(data.versions));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(deleteFormStructure.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteFormStructure.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchFormVersionStatuses),
    fork(watchFormStructureByVersion),
    fork(watchFormVersionCreation),
    fork(watchFormVersionUpdation),
    fork(watchFormVersionDeactivation),
    fork(watchFormVersionDeleting),
];
