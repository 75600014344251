import get from 'lodash/get';
import {TFunction} from 'react-i18next';

import {
    FormTotalInformationTypes,
    FormFieldTypes,
    FormPopupTypes,
    FormItemBasic,
    FormPageTypes,
    FormSectionTypes,
    FormTranslationItemTypes,
} from 'appRedux/actions/forms/types';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';
import {OrganizationLanguageTypes} from 'appRedux/actions/organizationLanguage/types';

import {
    FORM_FIELD_CHECKBOXES,
    FORM_FIELD_DROPDOWN,
    FORM_FIELD_IMAGE_CHECK,
    FORM_FIELD_IMAGE_RADIO,
    FORM_FIELD_RADIOBUTTONS,
} from 'pages/admin/updateForm/partials/FormStructure/helper';

import useParsedTranslation from 'hooks/useParsedTranslation';

import {EMPTY_DATA, DEFAULT_LANGUAGE_ID, DEFAULT_LANGUAGE, DEFAULT_LANGUAGE_LABEL} from 'config/index';

export type FormTranslationType = {
    keyword: string;
    description: string;
    english: string;
    translation: string;
    isRichText?: boolean;
};

const getCurrentTranslation = (translations: FormTranslationItemTypes[], keyword: string): string => {
    const item = translations.find(item => item.keyword === keyword);
    return item ? item.trans : '';
};

export const getFormKeyword = (formId: number, element: string): string => {
    return `form-${formId}-${element}`;
};

export const getPageKeyword = (formId: number, pageId: number, versionId: number, element: string): string => {
    return `form-${formId}-page-${pageId}-version-${versionId}-${element}`;
};

export const getSectionKeyword = (formId: number, sectionId: number, element: string): string => {
    return `form-${formId}-section-${sectionId}-${element}`;
};

export const getFieldKeyword = (formId: number, fieldId: number, element: string): string => {
    return `form-${formId}-field-${fieldId}-${element}`;
};

export const getOptionKeyword = (formId: number, optionId: number, element: string): string => {
    return `form-${formId}-option-${optionId}-${element}`;
};

export const getPopupKeyword = (formId: number, popupId: number, element: string): string => {
    return `form-${formId}-popup-${popupId}-${element}`;
};

export interface FormTranslationIntent {
    title: string;
    children: (FormTranslationType | FormTranslationIntent)[];
}

export type FormTranslationIntentResultType = FormTranslationType | FormTranslationIntent;

export const addFormattedFieldItems = (
    result: FormTranslationIntentResultType[],
    fields: FormFieldTypes[],
    formId: number,
    translations: FormTranslationItemTypes[],
): void => {
    fields.map((field: FormFieldTypes, fieldIndex: number) => {
        const fieldResult: FormTranslationIntentResultType[] = [];
        const fieldDescription = `Field${fieldIndex + 1}: "${field.label}"`;

        const keywordLabel = getFieldKeyword(formId, field.id, 'label');
        const keywordDescription = getFieldKeyword(formId, field.id, 'description');
        const keywordTooltip = getFieldKeyword(formId, field.id, 'tooltip');

        fieldResult.push({
            keyword: keywordLabel,
            description: `Label`,
            english: field.label || EMPTY_DATA,
            translation: getCurrentTranslation(translations, keywordLabel),
        });
        fieldResult.push({
            keyword: keywordDescription,
            description: `Description`,
            english: field.description || EMPTY_DATA,
            translation: getCurrentTranslation(translations, keywordDescription),
            isRichText: true,
        });
        fieldResult.push({
            keyword: keywordTooltip,
            description: `Tooltip`,
            english: field.tooltip || EMPTY_DATA,
            translation: getCurrentTranslation(translations, keywordTooltip),
        });

        if (
            field.type === FORM_FIELD_DROPDOWN ||
            field.type === FORM_FIELD_RADIOBUTTONS ||
            field.type === FORM_FIELD_CHECKBOXES ||
            field.type === FORM_FIELD_IMAGE_CHECK ||
            field.type === FORM_FIELD_IMAGE_RADIO
        ) {
            field.fieldOptions?.forEach((option, i) => {
                const keywordOptionTitle = getOptionKeyword(formId, option.id, 'title');
                fieldResult.push({
                    keyword: keywordOptionTitle,
                    description: `Option ${i + 1}`,
                    english: option.title || EMPTY_DATA,
                    translation: getCurrentTranslation(translations, keywordOptionTitle),
                });
                if (option.text) {
                    const keywordOptionText = getOptionKeyword(formId, option.id, 'text');
                    fieldResult.push({
                        keyword: keywordOptionText,
                        description: `Option ${i + 1} Text`,
                        english: option.text || EMPTY_DATA,
                        translation: getCurrentTranslation(translations, keywordOptionText),
                        isRichText: true,
                    });
                }
            });
        }

        result.push({
            title: `${fieldDescription}`,
            children: fieldResult,
        });
    });
};

export const getPageFormattedItemsResult =
    (form: FormItemBasic, formInfo: FormTotalInformationTypes) =>
    (page: FormPageTypes, pageIndex?: number): FormTranslationIntentResultType[] => {
        const result: FormTranslationIntentResultType[] = [];

        const translations: FormTranslationItemTypes[] = get(formInfo, 'translations', []);

        const pageDescription = pageIndex ? `Page${pageIndex}: "${page.title}" - ` : '';

        const keywordTitle = getPageKeyword(form.id, page.id, page.versionId, 'title');
        const keywordDescription = getPageKeyword(form.id, page.id, page.versionId, 'description');

        result.push({
            keyword: keywordTitle,
            description: `${pageDescription}Title`,
            english: page.title || EMPTY_DATA,
            translation: getCurrentTranslation(translations, keywordTitle),
        });
        result.push({
            keyword: keywordDescription,
            description: `${pageDescription}Description`,
            english: page.description || EMPTY_DATA,
            translation: getCurrentTranslation(translations, keywordDescription),
            isRichText: true,
        });

        const sections: FormSectionTypes[] = get(page, 'sections', []);

        const pageSections: FormTranslationIntent[] = sections.map(
            (section: FormSectionTypes, sectionIndex: number) => {
                const sectionResult: FormTranslationIntentResultType[] = [];
                const sectionDescription = `Section${sectionIndex + 1}: "${section.title}"`;

                const keywordTitle = getSectionKeyword(form.id, section.id, 'title');
                const keywordDescription = getSectionKeyword(form.id, section.id, 'description');
                const keywordTooltip = getSectionKeyword(form.id, section.id, 'tooltip');

                sectionResult.push({
                    keyword: keywordTitle,
                    description: `Title`,
                    english: section.title || EMPTY_DATA,
                    translation: getCurrentTranslation(translations, keywordTitle),
                });
                sectionResult.push({
                    keyword: keywordDescription,
                    description: `Description`,
                    english: section.description || EMPTY_DATA,
                    translation: getCurrentTranslation(translations, keywordDescription),
                    isRichText: true,
                });
                sectionResult.push({
                    keyword: keywordTooltip,
                    description: `Tooltip`,
                    english: section.tooltip || EMPTY_DATA,
                    translation: getCurrentTranslation(translations, keywordTooltip),
                });

                const fields: FormFieldTypes[] = get(section, 'fields', []);

                addFormattedFieldItems(sectionResult, fields, form.id, translations);

                const popups: FormPopupTypes[] = get(section, 'popups', []);

                popups.map((popup: FormPopupTypes, popupIndex: number) => {
                    const popupResult: FormTranslationType[] = [];
                    const popupDescription = `Popup${popupIndex + 1}: "${popup.title}"`;

                    const keywordTitle = getPopupKeyword(form.id, popup.id, 'title');
                    const keywordDescription = getPopupKeyword(form.id, popup.id, 'description');
                    const keywordTooltip = getPopupKeyword(form.id, popup.id, 'tooltip');

                    popupResult.push({
                        keyword: keywordTitle,
                        description: `Title`,
                        english: popup.title || EMPTY_DATA,
                        translation: getCurrentTranslation(translations, keywordTitle),
                    });
                    popupResult.push({
                        keyword: keywordDescription,
                        description: `Description`,
                        english: popup.description || EMPTY_DATA,
                        translation: getCurrentTranslation(translations, keywordDescription),
                        isRichText: true,
                    });
                    popupResult.push({
                        keyword: keywordTooltip,
                        description: `Tooltip`,
                        english: popup.tooltip || EMPTY_DATA,
                        translation: getCurrentTranslation(translations, keywordTooltip),
                    });

                    const popupFields: FormFieldTypes[] = get(popup, 'fields', []);

                    addFormattedFieldItems(popupResult, popupFields, form.id, translations);

                    sectionResult.push({
                        title: `${popupDescription}`,
                        children: popupResult,
                    });
                });

                return {
                    title: `${pageDescription}${sectionDescription}`,
                    children: sectionResult,
                };
            },
        );

        return result.concat(pageSections);
    };

export const getPageItemsToTranslate = (
    form: FormItemBasic,
    formInfo: FormTotalInformationTypes,
    page: FormPageTypes,
): FormTranslationIntentResultType[] => {
    return getPageFormattedItemsResult(form, formInfo)(page);
};

export const getParsedTranslatedLabel = (t: TFunction<'translation'>, keyword: string, defaultValue: string) => {
    const translation = t(keyword);
    return translation !== keyword && translation !== ''
        ? useParsedTranslation(t, keyword)
        : useParsedTranslation(t, defaultValue);
};

export const getFormTranslatedLabel = (
    translations: FormTranslationItemTypes[],
    keyword: string,
    defaultValue: string,
): string => {
    const translation = Array.isArray(translations) ? translations.find(item => item.keyword === keyword) : null;
    return translation ? translation.trans : defaultValue;
};

export const replaceUmlauts = (value: string) => {
    const replacesArray = [
        {
            search: '&Uuml;',
            replace: 'Ü',
        },
        {
            search: '&uuml;',
            replace: 'ü',
        },
        {
            search: '&Auml;',
            replace: 'Ä',
        },
        {
            search: '&auml;',
            replace: 'ä',
        },
        {
            search: '&Ouml;',
            replace: 'Ö',
        },
        {
            search: '&ouml;',
            replace: 'ö',
        },
        {
            search: '&szlig;',
            replace: 'ß',
        },
    ];

    let result = value;
    replacesArray.forEach(item => {
        result = result.replace(item.search, item.replace);
    });
    return result;
};

export const ignoreHtmlInString = (value: string): string => {
    return value.replace(/<[^>]*>/g, '');
};

const languagesWithLatinLetters: string[] = ['ua', 'ru'];

export const getLanguageById = (languages: LanguageItemTypes[], languageId: number): LanguageItemTypes => {
    const currentLanguage = languages.find(item => item.id === languageId);
    if (!currentLanguage) {
        return {
            id: DEFAULT_LANGUAGE_ID,
            enabled: true,
            lang: DEFAULT_LANGUAGE_LABEL,
            name: DEFAULT_LANGUAGE,
        };
    }
    return currentLanguage;
};

export const getLanguageIdByName = (
    languages: LanguageItemTypes[],
    languageName: string,
    defaultValue: number,
): number => {
    const language = languages.find(item => item.name === languageName);
    return language ? language.id : defaultValue;
};

export const checkIsLanguageWithLatinLetters = (languages: LanguageItemTypes[], languageId: number): boolean => {
    const organizationLanguage = getLanguageById(languages, languageId);
    if (organizationLanguage) {
        return languagesWithLatinLetters.indexOf(organizationLanguage.name) !== -1;
    }
    return false;
};

export const checkWorkflowStatusHasTranslations = (
    workflowStatusesList: WorkflowStatusItemTypes[],
    languageId: number,
): boolean => {
    let counter = 0;
    workflowStatusesList.forEach(status => {
        const translations = Object.values(status.translations);
        const currentLanguageTranslations = translations.some(translation => translation.language === languageId);
        if (currentLanguageTranslations) {
            counter++;
        }
    });
    return counter === workflowStatusesList.length;
};

export const showLanguageSelector = (
    activeOrganizationLanguages: OrganizationLanguageTypes[],
    organizationLanguage: LanguageItemTypes,
): boolean => {
    if (activeOrganizationLanguages.length === 0) {
        return false;
    }
    if (activeOrganizationLanguages.length === 1) {
        const firstActiveOrganizationLanguage: OrganizationLanguageTypes | null = get(
            activeOrganizationLanguages,
            0,
            null,
        );
        return Boolean(
            firstActiveOrganizationLanguage && firstActiveOrganizationLanguage.languageId !== organizationLanguage.id,
        );
    }
    return true;
};
