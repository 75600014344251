import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';

import Button from '@mui/material/Button';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import {AdminContext} from 'contexts/admin/context';

import {theme} from 'config/theme';

interface CommonCheckboxType {
    itemsNumber: number;
    hasSelectAllFiltered?: boolean;
    selectAllCheckboxes?: (value?: boolean) => void;
}

const buttonStyles = {
    minWidth: 20,
    height: 40,
    pl: 1,
    pr: 0.5,
    backgroundColor: theme.palette.background.default,
    '&:hover': {
        backgroundColor: theme.palette.background.default,
    },
};

const CommonCheckbox: FC<CommonCheckboxType> = ({itemsNumber, hasSelectAllFiltered, selectAllCheckboxes}) => {
    const [t] = useTranslation();

    const {selectedIds, setSelectAllFiltered} = useContext(AdminContext);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const isAllSelected = selectedIds.length === itemsNumber;

    const onSelectClicked = (value: boolean) => {
        setSelectAllFiltered(value);
        if (!isAllSelected && selectAllCheckboxes) {
            selectAllCheckboxes(value);
        }
        handleClose();
    };

    const CheckboxIcon = isAllSelected ? CheckBoxIcon : CheckBoxOutlineBlankIcon;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            {selectAllCheckboxes && (
                <Button onClick={() => selectAllCheckboxes(false)} sx={buttonStyles}>
                    <CheckboxIcon sx={{color: theme.palette.info.main}} />
                </Button>
            )}
            {hasSelectAllFiltered && (
                <>
                    <Button onClick={handleClick} sx={buttonStyles}>
                        <ArrowDropDownIcon sx={{color: theme.palette.info.main}} />
                    </Button>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <List sx={{backgroundColor: theme.palette.background.default}}>
                            <MenuItem onClick={() => onSelectClicked(true)}>
                                <Typography>{t('common.buttons.selectAllFiltered')}</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => onSelectClicked(false)}>
                                <Typography>{t('common.buttons.selectAllOnPage')}</Typography>
                            </MenuItem>
                        </List>
                    </Popover>
                </>
            )}
        </Box>
    );
};

export default CommonCheckbox;
