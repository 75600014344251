import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';

import CustomImage from 'components/CustomImage/CustomImage';
import PdfPreview from 'components/FilesListWithGallery/PdfPreview';

interface ImagePreviewType {
    documentIsImage: boolean;
    documentIsPdf: boolean;
    documentImageSource: string;
    encryptPrefix?: string | null;
    styles?: React.CSSProperties;
}

const ImagePreview: FC<ImagePreviewType> = ({documentIsImage, documentIsPdf, documentImageSource, styles}) => {
    const [t] = useTranslation();

    if (documentIsImage && documentImageSource) {
        return <CustomImage imageSrc={documentImageSource} galleryImage imgStyle={styles} />;
    }

    if (documentIsPdf && documentImageSource) {
        return <PdfPreview fileSource={documentImageSource} />;
    }

    return <Typography>{t('messages.error.previewImpossible')}</Typography>;
};

export default ImagePreview;
