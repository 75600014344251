import React, {FC, Ref, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {WorkflowStatusTaskItemTypes} from 'appRedux/actions/workflow/types';

import CreateWorkflowTaskEmailTemplateForm from 'components/Forms/Workflow/workflowStatusTaskTemplate/CreateWorkflowTaskEmailTemplateForm';
import UpdateWorkflowTaskEmailTemplateForm from 'components/Forms/Workflow/workflowStatusTaskTemplate/UpdateWorkflowTaskEmailTemplateForm';

import TranslationSelector from 'pages/admin/updateWorkflow/partials/tasks/TranslationSelector';

interface WorkflowTaskSendEmailDetailsType {
    item: WorkflowStatusTaskItemTypes;
    myOrganizationLanguage: LanguageItemTypes;
    selectedLanguage: number;
    setSelectedLanguage: (value: number) => void;
}

const WorkflowTaskSendEmailDetails: FC<WorkflowTaskSendEmailDetailsType> = ({
    item,
    myOrganizationLanguage,
    selectedLanguage,
    setSelectedLanguage,
}) => {
    const [t] = useTranslation();

    const formRef = useRef<Ref<any> | null>(null);

    const {templates} = item;

    const templateForCurrentLanguage = templates
        ? templates.find(template => template.language === selectedLanguage)
        : null;

    const submitStatusEventForm = () => {
        if (formRef && formRef.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formRef.current.handleSubmit();
        }
    };

    return (
        <Box sx={{mt: 2, mb: 1}}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: 1,
                    mb: 1,
                }}
            >
                <Typography sx={{fontWeight: 700}}>
                    {`${t(
                        templateForCurrentLanguage ? 'orguser.mailer.updateTemplate' : 'orguser.mailer.createTemplate',
                    )}:`}
                </Typography>
                <TranslationSelector
                    templates={templates}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    organizationLanguage={myOrganizationLanguage}
                    submitStatusEventForm={submitStatusEventForm}
                />
            </Box>
            {templateForCurrentLanguage ? (
                <UpdateWorkflowTaskEmailTemplateForm
                    formRef={formRef}
                    item={templateForCurrentLanguage}
                    submitStatusEventForm={submitStatusEventForm}
                />
            ) : (
                <CreateWorkflowTaskEmailTemplateForm
                    item={item}
                    formRef={formRef}
                    selectedLanguage={selectedLanguage}
                    submitStatusEventForm={submitStatusEventForm}
                />
            )}
        </Box>
    );
};

export default WorkflowTaskSendEmailDetails;
