import React, {MouseEvent, ChangeEvent, FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

import Menu from '@mui/material/Menu';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';

import {FilterOptionType} from 'components/AdminScreenComponents/types';
import AgentSelectButton from 'components/AgentScreenComponents/_buttons/AgentSelectButton';

import {DEFAULT_FILTER_OPTION} from 'config/index';
import {theme} from 'config/theme';

interface FilterPropsTypes {
    optionList: FilterOptionType[];
    handleFilterChange: any;
    parameter: string;
    filterName?: string;
    filterLabel: string;
    defaultValue?: string;
    useFilterLabel?: boolean;
    local?: boolean;
}

const Filter: FC<FilterPropsTypes> = ({
    optionList,
    defaultValue,
    handleFilterChange,
    filterLabel,
    parameter,
    useFilterLabel,
    local,
}) => {
    const [t] = useTranslation();
    const [searchParams] = useSearchParams();

    const defaultFilterValue = String(defaultValue) ?? DEFAULT_FILTER_OPTION;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [filterValue, setFilterValue] = useState<string>(
        local
            ? localStorage.getItem(parameter) || defaultFilterValue
            : searchParams.get(parameter)
            ? String(searchParams.get(parameter))
            : defaultFilterValue,
    );

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFilterValue((event.target as HTMLInputElement).value);
        handleFilterChange(event);
        setAnchorEl(null);
    };

    useEffect(() => {
        if (local) return;
        setFilterValue(searchParams.get(parameter) || defaultFilterValue);
    }, [searchParams, local]);

    const open = Boolean(anchorEl);

    if (optionList.length === 0) {
        return null;
    }
    return (
        <>
            <AgentSelectButton title={t(useFilterLabel ? filterLabel : parameter)} onClick={handleClick} open={open} />
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        mt: 1,
                        pl: 2,
                        pr: 2,
                        pt: 1,
                        pb: 1,
                        width: 290,
                        borderRadius: 2,
                        overflow: 'visible',
                        boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                        backgroundColor: theme.palette.background.default,
                        '& ul': {
                            padding: 0,
                        },
                        overflowX: 'hidden',
                    },
                }}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            >
                <RadioGroup value={filterValue} onChange={handleChange}>
                    {optionList.map((option: FilterOptionType, index: number) => {
                        return (
                            <FormControlLabel
                                key={`option-${index}`}
                                value={option.name}
                                control={<Radio />}
                                label={
                                    <Typography
                                        style={{
                                            display: 'inline-block',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        {t(option.label)}
                                    </Typography>
                                }
                                sx={{
                                    width: '100%',
                                    overflow: 'hidden',
                                }}
                            />
                        );
                    })}
                </RadioGroup>
            </Menu>
        </>
    );
};

export default Filter;
