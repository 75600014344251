import React, {FC, MouseEvent, ReactNode} from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import {SxProps} from '@mui/material';

interface ThumbIconButtonType {
    onClick: (e: MouseEvent) => void;
    children: ReactNode;
    sx?: SxProps;
}

const ThumbIconButton: FC<ThumbIconButtonType> = ({onClick, sx, children}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 30,
                height: 30,
                borderRadius: 15,
                backgroundColor: 'white',
                ...sx,
            }}
        >
            <IconButton onClick={onClick}>{children}</IconButton>
        </Box>
    );
};

export default ThumbIconButton;
