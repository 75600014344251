import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {PanelItemsTypes} from 'appRedux/actions/analytics/types';
import {SET_HOMEPAGE_PANEL} from 'appRedux/actions/analytics';

import {AlertContext} from 'contexts/alert/context';

import {CustomSwitcher} from 'components/AgentScreenComponents/_form/CustomElements';

interface SetHomepagePanelType {
    item: PanelItemsTypes;
}

const SetHomepagePanel: FC<SetHomepagePanelType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {uuid, isHomepagePanel} = item;

    const {showAlert} = useContext(AlertContext);

    const setHomepagePanel = useCallback(
        data => dispatch({type: SET_HOMEPAGE_PANEL.REQUEST, payload: data}),
        [dispatch],
    );

    const handleChangeStatus = () => {
        setHomepagePanel({
            uuid,
            showAlert,
        });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mt: 1,
                mb: 2,
            }}
        >
            <CustomSwitcher checked={isHomepagePanel} onClick={handleChangeStatus} />
            <Typography sx={{ml: 1}}>{t('orguser.analytics.panels.showAtHomepage')}</Typography>
        </Box>
    );
};

export default SetHomepagePanel;
