import React, {FC} from 'react';

import {PanelItemsTypes} from 'appRedux/actions/analytics/types';

import {DISPLAY_TYPE_BAR_CHART} from 'components/Forms/Dashboard/helper';

import BarStatisticChart from 'pages/admin/analytics/charts/BarStatisticChart';
import CircleStatisticChart from 'pages/admin/analytics/charts/CircleStatisticChart';

interface ChartAreaType {
    item: PanelItemsTypes;
}

const DashboardPanelChartArea: FC<ChartAreaType> = ({item}) => {
    const {displayType} = item;

    if (displayType === DISPLAY_TYPE_BAR_CHART) {
        return <BarStatisticChart item={item} />;
    }
    return <CircleStatisticChart item={item} />;
};

export default DashboardPanelChartArea;
