import React, {FC, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';
import get from 'lodash/get';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {FormHelperText, IconButton, InputAdornment, Typography} from '@mui/material';

import {ImportCryptoKeysTypes} from 'appRedux/actions/auth/types';
import {RootReducer} from 'appRedux/reducers';

import {KeysType} from 'contexts/crypto/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';

import {
    base642Buffer,
    decryptStringWithPasswordKey,
    getPasswordKeyWithIv,
    importPrivateKey,
    importPublicKey,
} from 'helpers/cryptoApiHelper';

import {theme} from 'config/theme';

interface ImportKeysWithPasswordFormType {
    handleImport: (keyPairRecord: KeysType) => void;
}

const ImportKeysWithPasswordForm: FC<ImportKeysWithPasswordFormType> = ({handleImport}) => {
    const [t] = useTranslation();

    const {
        crypto: {currentUserPublicKeys},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const [passwordErrorMessage, setPasswordErrorMessage] = useState<string | null>(null);
    const [fileErrorMessage, setFileErrorMessage] = useState<string | null>(null);

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const toggleShowPassword = () => {
        setShowPassword(previous => !previous);
    };

    const handleSubmit = async (values: ImportCryptoKeysTypes) => {
        let ecnrypted = '';

        try {
            ecnrypted = await new Promise((fulfill, reject) => {
                if (!values.file) reject('messages.error.fileRequired');

                const reader = new FileReader();
                reader.onload = () => {
                    if (reader.result && typeof reader.result === 'string') fulfill(reader.result);
                    reject('messages.error.fileIncompatible');
                };
                values.file && reader.readAsText(values.file);
            });
        } catch (err) {
            console.error(err);
            if (typeof err === 'string') {
                setFileErrorMessage(err);
            } else {
                setFileErrorMessage('messages.error.fileIncompatible');
            }
            return;
        }

        try {
            const ivAndEncryptedData = base642Buffer(ecnrypted);
            const iv = new Uint8Array(ivAndEncryptedData).slice(0, 12);
            const key = await getPasswordKeyWithIv(values.keyPassword, iv);

            const decrypted = await decryptStringWithPasswordKey(ecnrypted, key);
            const keysToImport = JSON.parse(decrypted);

            const publicKey = await importPublicKey(keysToImport.publicKey);
            const privateKey = await importPrivateKey(keysToImport.privateKey);

            const keyPairRecord = {
                name: keysToImport.name,
                publicKey,
                privateKey,
                uuid: keysToImport.uuid,
            };

            if (
                !keysToImport.name ||
                !keysToImport.uuid ||
                !currentUserPublicKeys?.find(key => key.uuid === keysToImport.uuid)
            ) {
                setFileErrorMessage('messages.error.keyIncompatible');
                return;
            }

            handleImport(keyPairRecord);
        } catch (err) {
            console.error(err);
            console.log('wrongPassword');
            setPasswordErrorMessage('messages.error.wrongPassword');
        }
    };

    const onLabelClick = () => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
            }}
        >
            <Formik<ImportCryptoKeysTypes>
                initialValues={{
                    keyPassword: '',
                    file: null,
                }}
                onSubmit={handleSubmit}
            >
                {(formik: FormikProps<ImportCryptoKeysTypes>) => {
                    const filename = get(formik, ['values', 'file', 'name'], null);

                    return (
                        <Form>
                            <Box
                                sx={{
                                    mt: 2,
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    gap: 2,
                                }}
                            >
                                {filename ? (
                                    <>
                                        <IconButton
                                            data-id={`button#chat-file-close`}
                                            onClick={() => {
                                                formik.setFieldValue('file', '');
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <Typography>{filename}</Typography>
                                    </>
                                ) : (
                                    <>
                                        <IconButton data-id={`button#chat-file-add`} onClick={onLabelClick}>
                                            <AddIcon />
                                        </IconButton>
                                        <Typography sx={{color: theme.palette.grey[700]}}>
                                            {t('common.buttons.uploadFile')}
                                        </Typography>
                                    </>
                                )}
                                <input
                                    ref={fileInputRef}
                                    data-id={`input#importCryptoKeysFile`}
                                    style={{display: 'none'}}
                                    name="file"
                                    type="file"
                                    accept=".crypto"
                                    onChange={event => {
                                        const newFile = get(event, ['currentTarget', 'files', '0'], null);
                                        formik.setFieldValue('file', newFile);
                                        event.target.value = '';
                                        setFileErrorMessage(null);
                                    }}
                                />
                                <FormHelperText sx={{color: theme.palette.error.main}}>
                                    {fileErrorMessage && t(fileErrorMessage)}
                                </FormHelperText>
                            </Box>
                            <Field
                                name="keyPassword"
                                data-id={`input#importCryptoKeysPassword`}
                                label={t('common.loginForm.password')}
                                component={FormikTextInput}
                                error={passwordErrorMessage}
                                helperText={passwordErrorMessage && t(passwordErrorMessage)}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const password = get(event, ['target', 'value'], null);
                                    formik.setFieldValue('keyPassword', password);
                                    setPasswordErrorMessage(null);
                                }}
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="off"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={toggleShowPassword}
                                                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                                                    event.preventDefault()
                                                }
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Box
                                sx={{
                                    mt: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <AgentSaveButton
                                    isSubmit
                                    data-id={`button#importCryptoKeysSave`}
                                    title={t('common.buttons.importKeysFile')}
                                />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default ImportKeysWithPasswordForm;
