import React, {FC, useCallback, useContext} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import CloseSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {WorkflowStatusRuleItemTypes, AttachWorkflowStatusRuleApproverFormTypes} from 'appRedux/actions/workflow/types';
import {ATTACH_WORKFLOW_RULE_APPROVER} from 'appRedux/actions/workflow';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import {getAvatarTitle} from 'components/TopBarMenu/helper';
import AvatarImage from 'components/UserAvatar/AvatarImage';

import {theme} from 'config/theme';

interface WorkflowRuleApproverFormType {
    item: WorkflowStatusRuleItemTypes;
    closeForm: () => void;
}

const WorkflowRuleApproverForm: FC<WorkflowRuleApproverFormType> = ({item, closeForm}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        admin: {agentList},
        workflow: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onSubmitClicked = useCallback(
        data => dispatch({type: ATTACH_WORKFLOW_RULE_APPROVER.REQUEST, payload: data}),
        [dispatch],
    );

    const {approvers, uuid} = item;

    const onSaveClicked = (values: AttachWorkflowStatusRuleApproverFormTypes) => {
        onSubmitClicked({
            ...values,
            showAlert,
            uuid,
            callback: closeForm,
        });
    };

    const agentsNotAttached = agentList
        ? agentList.filter(item => !approvers.some(approver => Number(item.id) === Number(approver.id)))
        : [];

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                mt: 2,
                mb: 1,
                '& form': {
                    width: '100%',
                },
            }}
        >
            <Formik
                initialValues={{
                    agentId: 0,
                }}
                onSubmit={onSaveClicked}
            >
                {(formik: FormikProps<AttachWorkflowStatusRuleApproverFormTypes>) => {
                    return (
                        <Form>
                            <FormControl sx={{mt: 1}} required fullWidth>
                                <InputLabel>{t('orguser.workflows.rules.agent')}</InputLabel>
                                <Select
                                    label={t('orguser.workflows.rules.agent')}
                                    name={'agentId'}
                                    value={formik.values.agentId}
                                    onChange={e => {
                                        formik.handleChange(e);
                                        formik.submitForm();
                                    }}
                                    IconComponent={KeyboardArrowDownIcon}
                                    sx={{
                                        '& div': {
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                        },
                                    }}
                                    fullWidth
                                >
                                    {agentsNotAttached &&
                                        agentsNotAttached.map(agent => {
                                            const {id, avatarType, avatarLink, firstName, lastName, email} = agent;
                                            const avatarTitle = getAvatarTitle(firstName, lastName, email);
                                            return (
                                                <MenuItem
                                                    key={email}
                                                    value={id}
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-start',
                                                        mb: 1,
                                                    }}
                                                >
                                                    <AvatarImage
                                                        size={25}
                                                        avatarType={avatarType}
                                                        avatarLink={avatarLink}
                                                        avatarTitle={avatarTitle}
                                                    />
                                                    <Typography sx={{ml: 1, fontWeight: 600}}>
                                                        {firstName && lastName ? `${firstName} ${lastName}` : email}
                                                    </Typography>
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </Form>
                    );
                }}
            </Formik>
            <IconButton
                sx={{
                    backgroundColor: theme.palette.info.main,
                    ml: 2,
                    '&:hover': {
                        backgroundColor: theme.palette.info.main,
                    },
                }}
                title={t('common.buttons.close')}
                onClick={closeForm}
                disabled={isLoading}
            >
                <CloseSvgIcon isActive isDisabled={isLoading} />
            </IconButton>
        </Box>
    );
};

export default WorkflowRuleApproverForm;
