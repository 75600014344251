import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import {DELETE_CHAT_MESSAGE, CHAT_DOCUMENT_URL} from 'appRedux/actions/requestChat';
import {MessageTypes} from 'appRedux/actions/requestChat/types';

import AttachSvgIcon from 'assets/icons/small/AttachSvgIcon';
import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {MediaContext} from 'contexts/media/context';
import {CaseKeyContext} from 'contexts/caseKey/context';

import EmptyArea from 'components/EmptyArea';
import DeleteClientConfirmationModal from 'components/ClientScreenComponents/DeleteClientConfirmationModal';

import {getEncryptedFileData} from 'helpers/cryptoApiHelper';

import {CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface AttachedDocumentLabelType {
    item: MessageTypes;
    userId: number | null;
}

const AttachedDocumentLabel: FC<AttachedDocumentLabelType> = ({item, userId}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {unwrappedCaseKey} = useContext(CaseKeyContext);

    const [onClicked, setOnClicked] = useState<boolean>(false);

    const {uuid, senderId, file, encryptedPrefix} = item;
    const createdAtTS = new Date(item.createdAt).getTime();

    const isCurrentUserSender = senderId === userId;

    const {isMobile} = useContext(MediaContext);

    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    const removeChatMessage = useCallback(
        data => dispatch({type: DELETE_CHAT_MESSAGE.REQUEST, payload: data}),
        [dispatch],
    );

    const getFileUrl = useCallback(data => dispatch({type: CHAT_DOCUMENT_URL.REQUEST, payload: data}), [dispatch]);

    const downloadFile = (url: string, fileName?: string) => {
        const a = document.createElement('a');
        a.href = url;
        document.body.appendChild(a);
        if (fileName) {
            a.download = fileName;
        }
        a.click();
        document.body.removeChild(a);
    };

    const downloadEncryptedFile = async (url: string) => {
        if (encryptedPrefix && file) {
            const decryptedFileSource = await getEncryptedFileData(url, encryptedPrefix, unwrappedCaseKey);
            if (decryptedFileSource) {
                downloadFile(decryptedFileSource, file);
            }
        }
    };

    const handleDownloadFile = () => {
        getFileUrl({
            uuid,
            callback: encryptedPrefix ? downloadEncryptedFile : downloadFile,
        });
    };

    const onDeleteClick = () => {
        setOnClicked(true);
        removeChatMessage({
            uuid,
            callback: () => setIsShowModal(false),
        });
    };

    return (
        <Box
            data-id={`button#chat-attachment-${createdAtTS}`}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                mb: 1,
                px: 1,
                borderRadius: 2,
                cursor: 'pointer',
                backgroundColor: CLIENT_BACKGROUND_COLOR,
            }}
        >
            <AttachSvgIcon />
            <Typography
                onClick={handleDownloadFile}
                sx={{
                    ml: 1,
                    fontWeight: 600,
                    fontSize: isMobile ? 12 : 14,
                }}
            >
                {t('orguser.chat.attachedDocument')}
            </Typography>
            <EmptyArea />
            {file && isCurrentUserSender && (
                <>
                    <IconButton onClick={toggleModal}>
                        <DeleteSvgIcon />
                    </IconButton>
                    <DeleteClientConfirmationModal
                        data-id={`button#chat-attachment-${createdAtTS}-delete`}
                        isShowModal={isShowModal}
                        toggleModal={toggleModal}
                        onClickAction={onDeleteClick}
                        onClicked={onClicked}
                    />
                </>
            )}
        </Box>
    );
};

export default AttachedDocumentLabel;
