import {
    GENERAL_TEMPLATE_LIST,
    GENERAL_TEMPLATE_LIST_DEFAULT,
    GENERAL_TEMPLATE_UPDATE,
} from 'appRedux/actions/generalTemplates';
import {GeneralTemplateTypes, GeneralTemplateDefaultTypes} from 'appRedux/actions/generalTemplates/types';

export const initialState: GeneralTemplatesState = {
    isLoading: false,
    isLoadingUpdate: false,
    generalTemplates: [],
    defaultTemplates: null,
    error: undefined,
};

export interface GeneralTemplatesState {
    isLoading: boolean;
    isLoadingUpdate: boolean;
    generalTemplates: GeneralTemplateTypes[];
    defaultTemplates: GeneralTemplateDefaultTypes | null;
    error: IErrors | undefined;
}

const generalTemplates = (
    state = initialState,
    action: IActionType<GeneralTemplateTypes[] | GeneralTemplateDefaultTypes | IErrors>,
): GeneralTemplatesState => {
    switch (action.type) {
        case GENERAL_TEMPLATE_LIST_DEFAULT.REQUEST:
        case GENERAL_TEMPLATE_LIST.REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        }

        case GENERAL_TEMPLATE_UPDATE.REQUEST: {
            return {
                ...state,
                isLoadingUpdate: true,
                error: undefined,
            };
        }

        case GENERAL_TEMPLATE_LIST.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                generalTemplates: action.payload as GeneralTemplateTypes[],
            };
        }

        case GENERAL_TEMPLATE_UPDATE.SUCCESS: {
            return {
                ...state,
                isLoadingUpdate: false,
                generalTemplates: action.payload as GeneralTemplateTypes[],
            };
        }

        case GENERAL_TEMPLATE_LIST_DEFAULT.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                defaultTemplates: action.payload as GeneralTemplateDefaultTypes,
            };
        }

        case GENERAL_TEMPLATE_LIST.ERROR:
        case GENERAL_TEMPLATE_LIST_DEFAULT.ERROR:
        case GENERAL_TEMPLATE_UPDATE.ERROR: {
            return {
                ...state,
                isLoading: false,
                isLoadingUpdate: false,
                error: (action.payload as IErrors) || {},
            };
        }

        default:
            return state;
    }
};

export default generalTemplates;
