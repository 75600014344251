import React, {FC, useRef} from 'react';
import CodeMirror, {ReactCodeMirrorRef} from '@uiw/react-codemirror';
import {json} from '@codemirror/lang-json';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {MdxEditorCustomButtonType} from 'components/MdxEditorField/helper';
import CustomButtonsRow from 'components/CodeMirrorField/CustomButtonsRow';

import {theme, ERROR_TEXT_COLOR, CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface CodeMirrorFieldType {
    label?: string;
    name: string;
    value?: string;
    customButtons?: MdxEditorCustomButtonType[];
    codeMirrorText: string;
    setCodeMirrorText: (value: string) => void;
}

const CodeMirrorField: FC<CodeMirrorFieldType> = ({label, name, codeMirrorText, setCodeMirrorText, customButtons}) => {
    const [t] = useTranslation();
    const [field, meta] = useField(name);
    const type = 'text';

    const codeMirrorRef = useRef<ReactCodeMirrorRef>({});

    const handleEditorChange = (value: string) => {
        field.onChange({target: {type, name, value}});
        setCodeMirrorText(value);
    };

    const handleBlur = () => {
        field.onBlur({target: {name}});
    };

    return (
        <Box sx={{mt: 2, mb: 2}}>
            {label && <Typography sx={{mb: 1}}>{label}</Typography>}
            <Box
                sx={{
                    borderColor: CLIENT_BACKGROUND_COLOR,
                    backgroundColor: theme.palette.background.default,
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderRadius: 4,
                    p: 1,
                    pt: 2,
                }}
            >
                {customButtons && customButtons.length > 0 && (
                    <CustomButtonsRow codeMirrorRef={codeMirrorRef} customButtons={customButtons} />
                )}
                <CodeMirror
                    ref={codeMirrorRef}
                    value={codeMirrorText}
                    height="200px"
                    extensions={[json()]}
                    onChange={handleEditorChange}
                    onBlur={handleBlur}
                />
            </Box>
            {meta.touched && meta.error && (
                <Typography sx={{fontSize: 12, color: ERROR_TEXT_COLOR, mt: 1}}>{t(meta.error)}</Typography>
            )}
        </Box>
    );
};

export default CodeMirrorField;
