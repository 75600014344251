import get from 'lodash/get';

import {FormBaseInformationTypes} from 'appRedux/actions/auth/types';
import {RequesterAllCaseTypes} from 'appRedux/actions/requestCase/types';
import {RequesterAllCaseItemTypes} from 'appRedux/actions/requestCase/types';

export const getFormDescription = (form: FormBaseInformationTypes) => {
    if (form.description) {
        return form.description;
    }
    return '';
};

export const getFormPrivacyPolicy = (form: FormBaseInformationTypes) => {
    if (form.privacyPolice) {
        return form.privacyPolice;
    }
    return '';
};

export const getErrorMessage = (errors: IErrors) => {
    const passwordsError = get(errors, ['password', 'first', 0], null);
    if (passwordsError) {
        return passwordsError;
    }
    return 'messages.error.somethingWentWrong';
};

export const REGEX_FOR_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?\\[{(+=/\\@#$%^&*])(?=.{8,})/;

export const isUserAlreadyHasRequest = (allCases: RequesterAllCaseTypes[], formId: number) => {
    let result = false;
    Object.entries(allCases).forEach((item: any) => {
        const requesterCases: RequesterAllCaseItemTypes[] = item[1];
        if (requesterCases && Array.isArray(requesterCases)) {
            requesterCases.forEach(requesterCase => {
                if (requesterCase.form.id === formId) {
                    result = true;
                }
            });
        }
    });
    return result;
};
