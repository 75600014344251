import React, {FC, useContext, useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {FormPopupTypes} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';

import DeleteClientConfirmationModal from 'components/ClientScreenComponents/DeleteClientConfirmationModal';

import {ContentItemType} from 'pages/client/form/partials/types';
import PopupModalForm from 'pages/client/form/fieldPopup/PopupModalForm';

import {ALERT_TYPE_SUCCESS} from 'config/index';
import EmptyArea from 'components/EmptyArea';
import {ADMIN_LIGHT_GRAY_COLOR, theme} from 'config/theme';

interface PopupContentRowType {
    formId: number;
    pageId: number;
    sectionId: number;
    popup: FormPopupTypes;
    currentIndex: number;
    disabled: boolean;
    initialItems: ContentItemType[];
    handleChangePopupInformation: (pageId: number, sectionId: number, popupId: number, value: unknown) => void;
    onEditClick: (value: number | null) => void;
    onSaveClicked: (isCallAlert?: boolean) => void;
    isSelected: boolean;
}

const PopupContentRow: FC<PopupContentRowType> = ({
    formId,
    pageId,
    sectionId,
    popup,
    disabled,
    currentIndex,
    initialItems,
    handleChangePopupInformation,
    onEditClick,
    onSaveClicked,
    isSelected,
}) => {
    const [isShowDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

    const {showAlert} = useContext(AlertContext);

    const toggleDeleteConfirmation = () => {
        setShowDeleteConfirmation(previous => !previous);
    };

    const onClickDelete = () => {
        for (let i = 0, n = initialItems.length; i < n; i++) {
            if (i === currentIndex) {
                initialItems.splice(i, 1);
            }
        }
        handleChangePopupInformation(pageId, sectionId, popup.id, initialItems);
        setShowDeleteConfirmation(false);
        onSaveClicked(false);
        showAlert(ALERT_TYPE_SUCCESS);
    };

    useEffect(() => {
        !isSelected && onSaveClicked(false);
    }, [isSelected]);

    const toggleEdit = () => {
        onEditClick(isSelected ? null : currentIndex);
    };

    return (
        <Box
            sx={{
                mx: 1,
                borderBottom: `1px solid ${theme.palette.info.contrastText}`,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 0.5,
                    height: '48px',
                    p: 1,
                    '&:last-child': {
                        borderBottom: `none`,
                    },
                    backgroundColor: 'none',
                }}
            >
                <Typography sx={{fontWeight: 600}}>{`${popup.title} ${currentIndex + 1}`}</Typography>
                <EmptyArea />
                {!disabled && (
                    <>
                        <IconButton onClick={toggleDeleteConfirmation}>
                            <DeleteSvgIcon />
                        </IconButton>
                        <DeleteClientConfirmationModal
                            isShowModal={isShowDeleteConfirmation}
                            toggleModal={toggleDeleteConfirmation}
                            onClickAction={onClickDelete}
                        />
                    </>
                )}
                <Box onClick={toggleEdit}>
                    <IconButton sx={{p: 0.5}}>{isSelected ? <RemoveIcon /> : <AddIcon />}</IconButton>
                </Box>
            </Box>
            <Box
                sx={{
                    height: isSelected ? 'auto' : '0px',
                    pb: isSelected ? 1 : 0,
                    pl: 1,
                    transition: 'height 0.3s ease-in-out',
                    borderTop: `1px solid ${ADMIN_LIGHT_GRAY_COLOR}`,
                }}
            >
                {isSelected && (
                    <PopupModalForm
                        toggleModal={toggleEdit}
                        initialItems={initialItems}
                        formId={formId}
                        pageId={pageId}
                        sectionId={sectionId}
                        popup={popup}
                        clientInformation={clientInformation}
                        handleChangePopupInformation={handleChangePopupInformation}
                        itemIndex={currentIndex}
                        disabled={disabled}
                        onSaveClicked={onSaveClicked}
                    />
                )}
            </Box>
        </Box>
    );
};

export default PopupContentRow;
