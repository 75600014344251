import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import {AGENT_FILTERS_LIST_DELETE} from 'appRedux/actions/agentFilters';
import {AgentSavedFilterTypes} from 'appRedux/actions/agentFilters/types';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {AlertContext} from 'contexts/alert/context';

import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import DeleteClientConfirmationModal from 'components/ClientScreenComponents/DeleteClientConfirmationModal';

interface SavedFilterInfoType {
    item: AgentSavedFilterTypes;
}

const FilterDeleteButton: FC<SavedFilterInfoType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {showAlert} = useContext(AlertContext);

    const {uuid} = item;

    const deleteFilter = useCallback(
        data => dispatch({type: AGENT_FILTERS_LIST_DELETE.REQUEST, payload: data}),
        [dispatch],
    );

    const onFilterDeleteClick = () => {
        deleteFilter({
            uuid,
            showAlert,
            callback: () => setIsOpened(false),
        });
    };

    return (
        <>
            <AgentInverseButton
                title={t('orguser.savedFilters.deleteSavedFilter')}
                icon={<DeleteSvgIcon />}
                onClick={toggleIsOpened}
            />
            <DeleteClientConfirmationModal
                isShowModal={isOpened}
                toggleModal={toggleIsOpened}
                onClickAction={onFilterDeleteClick}
            />
        </>
    );
};

export default FilterDeleteButton;
