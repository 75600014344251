import React, {FC} from 'react';

import Box from '@mui/material/Box';

import {WorkflowEventAgentTypes} from 'appRedux/actions/workflow/types';

import AttachedAgentListItem from 'pages/admin/updateWorkflowEvents/workflowEventAgent/AttachedAgentListItem';

interface AttachedAgentsListType {
    items: WorkflowEventAgentTypes[];
}

const AttachedAgentsList: FC<AttachedAgentsListType> = ({items}) => {
    return (
        <Box sx={{mt: 1, mb: 1}}>
            {items &&
                items.map((agent: WorkflowEventAgentTypes) => {
                    return <AttachedAgentListItem item={agent} key={`attached-agent-${agent.uuid}`} />;
                })}
        </Box>
    );
};

export default AttachedAgentsList;
