import React, {FC} from 'react';

import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';

import CancelSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {theme} from 'config/theme';

interface FilterChipType {
    title: string;
    onDeleteClick?: (value: string) => void;
    backgroundColor?: string;
    color?: string;
}

const FilterChip: FC<FilterChipType> = ({title, onDeleteClick, backgroundColor, color}) => {
    const chipStyles = {
        display: 'inline-flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 25,
        fontSize: 12,
        borderRadius: 13,
        backgroundColor: backgroundColor ?? theme.palette.info.main,
        color: color ?? theme.palette.background.default,
        mr: 1,
        mb: 1,
        pr: 1,
    };

    if (onDeleteClick) {
        return (
            <Chip
                sx={chipStyles}
                label={title}
                onDelete={onDeleteClick}
                deleteIcon={
                    <IconButton sx={{ml: 5, p: 0, width: 16, height: 16}} disableRipple>
                        <CancelSvgIcon isActive />
                    </IconButton>
                }
            />
        );
    }
    return <Chip sx={chipStyles} label={title} />;
};

export default FilterChip;
