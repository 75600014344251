import React, {FC, useEffect, useRef, useState} from 'react';
import {get} from 'lodash';

import AddIcon from '@mui/icons-material/Add';
import {Box, IconButton} from '@mui/material';

import HerupuSpinner from 'assets/herupuSpinner';

import {createThumbnail} from 'pages/client/form/fieldTypes/helper';

interface DeleteOptionType {
    clickFileInput?: boolean;
    loading?: boolean;
    file?: string | null;
    setFile?: React.Dispatch<React.SetStateAction<string | null | undefined>>;
    onClick?: () => void;
}

const UploadOptionFile: FC<DeleteOptionType> = ({clickFileInput, loading, file, setFile, onClick}) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [loadingThumb, setLoadingThumb] = useState<boolean>(false);

    const onLabelClick = () => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    useEffect(() => {
        if (clickFileInput) onLabelClick();
    }, [clickFileInput]);

    return (
        <>
            {loading || loadingThumb ? (
                <Box sx={{width: 40, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <HerupuSpinner size={30} />
                </Box>
            ) : file ? (
                setFile ? (
                    <Box
                        sx={{
                            width: 40,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                        }}
                    >
                        <IconButton data-id={`button#option-file-add`} onClick={onLabelClick} sx={{zIndex: 10}}>
                            <AddIcon />
                        </IconButton>
                        <img
                            src={file}
                            style={{
                                width: 40,
                                height: 40,
                                borderRadius: 4,
                                opacity: 0.3,
                                position: 'absolute',
                                top: 0,
                                left: 0,
                            }}
                            alt="icon"
                        />
                    </Box>
                ) : (
                    <img onClick={onClick} src={file} style={{width: 40, height: 40, borderRadius: 4}} alt="icon" />
                )
            ) : (
                <IconButton data-id={`button#option-file-add`} onClick={onLabelClick}>
                    <AddIcon />
                </IconButton>
            )}
            <input
                ref={fileInputRef}
                style={{display: 'none'}}
                name="file"
                type="file"
                accept="image/png, image/jpeg"
                onChange={value => {
                    setLoadingThumb(true);
                    const newFile = get(value, ['currentTarget', 'files', '0'], null);
                    if (!newFile) return;
                    createThumbnail(newFile)
                        .then(thumb => {
                            thumb && setFile && setFile(thumb);
                        })
                        .finally(() => setLoadingThumb(false));
                }}
            />
        </>
    );
};

export default UploadOptionFile;
