import React from 'react';

export type MediaContextType = {
    isMobile: boolean;
    isTablet: boolean;
    isMiddle: boolean;
    isDesktop: boolean;
    isLargeDesktop: boolean;
    language: number;
    setLanguage: (value: number) => void;
    setIsInitialLanguageUpdated: (value: boolean) => void;
    setLanguageAfterLogin: (value: number) => void;
};

export const MediaContext = React.createContext({} as MediaContextType);
