import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {CreateOrganizationRequestTypes} from 'appRedux/actions/organization/types';
import {ORGANIZATION_TRANSLATIONS_LIST} from 'appRedux/actions/organizationLanguage';

import {AlertContext} from 'contexts/alert/context';

import OrganizationForm from 'components/Forms/OrganizationForm/OrganizationForm';
import TranslationSelector from 'components/Forms/OrganizationForm/TranslationSelector';
import OrganizationTranslationForm from 'components/Forms/OrganizationForm/OrganizationTranslationForm';

interface UpdateOrganizationFormType {
    organizationId: number;
    initialValues: CreateOrganizationRequestTypes;
    onSubmitClicked: (values: CreateOrganizationRequestTypes) => void;
    currentOrganizationLanguage: LanguageItemTypes;
}

const UpdateOrganizationForm: FC<UpdateOrganizationFormType> = ({
    organizationId,
    initialValues,
    onSubmitClicked,
    currentOrganizationLanguage,
}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [currentTab, setCurrentTab] = useState<string>(currentOrganizationLanguage.name);

    const getOrganizationTranslations = useCallback(
        data => dispatch({type: ORGANIZATION_TRANSLATIONS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {isLoading, organizationLanguages},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        if (organizationId && currentOrganizationLanguage) {
            getOrganizationTranslations({
                id: organizationId,
                lang: currentTab,
                showAlert,
            });
        }
    }, [organizationId, currentTab]);

    return (
        <Box>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', mb: 2}}>
                <TranslationSelector
                    selectedLanguage={currentTab}
                    setSelectedLanguage={setCurrentTab}
                    languages={organizationLanguages}
                    organizationLanguage={currentOrganizationLanguage}
                />
            </Box>
            {currentTab === currentOrganizationLanguage.name ? (
                <OrganizationForm
                    initialValues={initialValues}
                    onSubmitClicked={onSubmitClicked}
                    isLoading={isLoading}
                />
            ) : (
                <OrganizationTranslationForm
                    organizationId={organizationId}
                    initialValues={initialValues}
                    currentTab={currentTab}
                />
            )}
        </Box>
    );
};

export default UpdateOrganizationForm;
