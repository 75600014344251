import React, {FC, useCallback, useContext, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {get} from 'lodash';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import {FormHasLanguageTypes, FormPageTypes, FormSectionTypes} from 'appRedux/actions/forms/types';
import {FORM_PAGE_DELETE, FORM_PAGE_COPY, FORM_PAGE_UP, FORM_PAGE_DOWN} from 'appRedux/actions/forms';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {AdminContext} from 'contexts/admin/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import ButtonsPanel from 'pages/admin/updateForm/partials/FormStructure/ButtonsPanel';
import FormSectionInformation from 'pages/admin/updateForm/partials/FormStructure/FormSectionInformation';
import {
    UPDATE_PAGE_KEYWORD,
    ADD_NEW_SECTION_KEYWORD,
    ADD_NEW_POPUP_KEYWORD,
    ADD_NEW_FIELD_KEYWORD,
    ADD_FIELD_POPUP_KEYWORD,
} from 'pages/admin/updateForm/partials/constants';
import {
    CONTENT_POPUP,
    CONTENT_SECTION,
    getPlaceholdersCountForSection,
    getLanguageTabLabels,
} from 'pages/admin/updateForm/partials/FormStructure/helper';
import DragScrollAreas from 'pages/admin/updateForm/partials/FormStructure/DragScrollAreas';
import FormPreview from 'pages/admin/updateForm/partials/FormPreview/FormPreview';
import FormPageTranslate from 'pages/admin/updateForm/partials/FormPreview/FormPageTranslate';
import PreviewTabs from 'pages/admin/updateForm/partials/FormPreview/PreviewTabs';

import {getRelatedSectionsIds} from 'helpers/requesterCaseHelper';

import {CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface FormPageInformationType {
    priority: number;
    setModalTitle: (value: string) => void;
    setCurrentPageId: (value: number) => void;
    setCurrentSectionId: (value: number) => void;
    setEntityId: (value: number) => void;
    toggleModal: () => void;
    isFirst: boolean;
    isLast: boolean;
    isCopyDisabled: boolean;
    item: FormPageTypes;
    setFormFieldType: (value: number | null) => void;
    setNewItemPriority: (value?: number) => void;
    formId: number;
    placeholdersCount?: number;
    viewOnly: boolean;
}

const FormPageInformation: FC<FormPageInformationType> = ({
    item,
    setModalTitle,
    setEntityId,
    toggleModal,
    setCurrentPageId,
    setCurrentSectionId,
    priority,
    isFirst,
    isLast,
    isCopyDisabled,
    setFormFieldType,
    setNewItemPriority,
    formId,
    placeholdersCount = 0,
    viewOnly,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {title, sections, id} = item;

    const [currentTab, setCurrentTab] = useState<string>('preview');

    const {
        admin: {formInfo, organizationList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const relatedSectionsIds = useMemo(() => getRelatedSectionsIds(sections), [sections]);

    const languages: FormHasLanguageTypes[] = get(formInfo, 'languages', []);
    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const handlePageRemove = useCallback(data => dispatch({type: FORM_PAGE_DELETE.REQUEST, payload: data}), [dispatch]);
    const handlePageCopy = useCallback(data => dispatch({type: FORM_PAGE_COPY.REQUEST, payload: data}), [dispatch]);
    const handlePageUpPriority = useCallback(data => dispatch({type: FORM_PAGE_UP.REQUEST, payload: data}), [dispatch]);

    const handlePageDownPriority = useCallback(
        data => dispatch({type: FORM_PAGE_DOWN.REQUEST, payload: data}),
        [dispatch],
    );

    const onEditClick = () => {
        toggleModal();
        setModalTitle(UPDATE_PAGE_KEYWORD);
        setEntityId(id);
    };

    const onCopyClick = () => {
        handlePageCopy({
            id,
            showAlert,
        });
    };

    const onDeleteClick = () => {
        handlePageRemove({
            id,
            showAlert,
        });
    };

    const onUpClick = () => {
        handlePageUpPriority({
            id,
            showAlert,
        });
    };

    const onDownClick = () => {
        handlePageDownPriority({
            id,
            showAlert,
        });
    };

    const onAddNewPopupClick = (sectionId: number, index?: number) => {
        toggleModal();
        setCurrentSectionId(sectionId);
        setModalTitle(ADD_NEW_POPUP_KEYWORD);
        setEntityId(id);
        setNewItemPriority(index);
    };

    const onAddNewFieldClick = (sectionId: number, inputType?: string, index?: number) => {
        toggleModal();
        const inputTypeIndex = inputType !== undefined ? Number(inputType) : null;
        setFormFieldType(inputTypeIndex);
        setCurrentSectionId(sectionId);
        setModalTitle(ADD_NEW_FIELD_KEYWORD);
        setEntityId(id);
        setNewItemPriority(index);
    };

    const onAddFieldPopupClick = (popupId: number, inputType?: string, index?: number) => {
        toggleModal();
        const inputTypeIndex = inputType !== undefined ? Number(inputType) : null;
        setFormFieldType(inputTypeIndex);
        setModalTitle(ADD_FIELD_POPUP_KEYWORD);
        setEntityId(popupId);
        setNewItemPriority(index);
    };

    const onAddNewSectionClick = () => {
        toggleModal();
        setModalTitle(ADD_NEW_SECTION_KEYWORD);
        setEntityId(id);
    };

    const {isDragStart, dragType, dropType} = useContext(AdminContext);

    const onDrop = (index?: number) => {
        if (dragType && dropType) {
            const [dropName, dropId] = dropType.split('-');

            switch (dropName) {
                case CONTENT_SECTION:
                    dragType === CONTENT_POPUP
                        ? onAddNewPopupClick(Number(dropId), index)
                        : onAddNewFieldClick(Number(dropId), dragType, index);
                    break;
                case CONTENT_POPUP:
                    onAddFieldPopupClick(Number(dropId), dragType, index);
                    break;
            }
        }
    };

    if (!myOrganization) return null;

    const availableFormLanguages = languages ? languages.filter(item => item.isAvailableForOrganization) : [];

    return (
        <Box
            sx={{
                mb: 1,
                borderColor: CLIENT_BACKGROUND_COLOR,
                borderWidth: 2,
                borderRadius: 4,
                borderStyle: 'solid',
                pb: 1,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    pl: 2,
                    pr: 1,
                }}
            >
                <Typography variant="body2" sx={{py: 1}}>{`${t(
                    'orguser.forms.page',
                )} ${priority} - ${title}`}</Typography>
                <ButtonsPanel
                    isFirst={isFirst}
                    isLast={isLast}
                    isCopyDisabled={isCopyDisabled}
                    onEditClick={onEditClick}
                    onCopyClick={onCopyClick}
                    onDeleteClick={onDeleteClick}
                    onUpClick={onUpClick}
                    onDownClick={onDownClick}
                    viewOnly={viewOnly}
                />
            </Box>
            <Box
                sx={{
                    pt: 1,
                    px: 2,
                }}
            >
                <Grid container>
                    <Grid item sm={sections.length ? 6 : 12} sx={{pr: 1}}>
                        {sections &&
                            sections.map((section: FormSectionTypes, index: number) => {
                                placeholdersCount += getPlaceholdersCountForSection(section);
                                return (
                                    <FormSectionInformation
                                        priority={index + 1}
                                        pageId={id}
                                        item={section}
                                        sections={sections.filter(s => s.id !== section.id)}
                                        toggleModal={toggleModal}
                                        setModalTitle={setModalTitle}
                                        setCurrentPageId={setCurrentPageId}
                                        onAddNewFieldClick={onAddNewFieldClick}
                                        onAddNewPopupClick={onAddNewPopupClick}
                                        onAddFieldPopupClick={onAddFieldPopupClick}
                                        onDrop={onDrop}
                                        setEntityId={setEntityId}
                                        isFirst={index === 0}
                                        isLast={index === sections.length - 1}
                                        relatedSectionsIds={relatedSectionsIds}
                                        key={`formSection-${index}`}
                                        placeholdersCount={placeholdersCount}
                                        viewOnly={viewOnly}
                                    />
                                );
                            })}

                        {!viewOnly && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    p: 2,
                                }}
                            >
                                <AgentSaveButton
                                    onClick={onAddNewSectionClick}
                                    title={t('orguser.forms.addNewSection')}
                                />
                            </Box>
                        )}
                    </Grid>
                    {!!sections.length && (
                        <Grid item sm={6} display="flex" sx={{width: '100%', pl: 1, flexDirection: 'column'}}>
                            <PreviewTabs
                                tabs={getLanguageTabLabels(availableFormLanguages, myOrganization.language)}
                                currentTab={currentTab}
                                setCurrentTab={setCurrentTab}
                                key={'preview-tab'}
                            />
                            <FormPreview formId={formId} page={item} isActive={currentTab === 'preview'} />
                            {languages.map(language => (
                                <FormPageTranslate
                                    page={item}
                                    formId={formId}
                                    currentFormLanguage={language}
                                    isActive={currentTab === language.language.name}
                                    key={language.id}
                                    viewOnly={viewOnly}
                                />
                            ))}
                        </Grid>
                    )}
                </Grid>
                {isDragStart && <DragScrollAreas />}
            </Box>
        </Box>
    );
};

export default FormPageInformation;
