import React from 'react';
import {useTranslation} from 'react-i18next';
import Countdown from 'react-countdown';

import Typography from '@mui/material/Typography';

import EmptyArea from 'components/EmptyArea';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import LoginPageWrapper from 'pages/start/login/partials/LoginPageWrapper';
import {useNavigate} from 'react-router-dom';

const COUNTDOWN_TIME = 15;

const NotFound = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const renderer = ({seconds, completed}) => {
        if (completed) {
            navigate('/');
        }
        return (
            <Typography align="center" sx={{mt: 1, mb: 4}}>
                {t('messages.error.countdownRedirectText').replace('%NUMBER%', seconds)}
            </Typography>
        );
    };

    return (
        <LoginPageWrapper withoutRedirect>
            <Typography sx={{fontWidth: 600, fontSize: 16}}>{t('common.pageNotFound')}</Typography>
            <Countdown date={Date.now() + COUNTDOWN_TIME * 1000} renderer={renderer} />
            <AgentSaveButton
                isBigButton
                isSubmit={false}
                onClick={() => navigate('/')}
                title={t('common.buttons.redirect')}
            />
            <EmptyArea />
        </LoginPageWrapper>
    );
};

export default NotFound;
