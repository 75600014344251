import React, {FC, ChangeEvent, useState, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {useDispatch} from 'react-redux';
import {createPortal} from 'react-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import CheckIcon from '@mui/icons-material/Check';
import SubjectIcon from '@mui/icons-material/Subject';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import HerupuSpinner from 'assets/herupuSpinner';

import {FieldOptionType} from 'appRedux/actions/forms/types';
import {FORM_FIELD_DELETE_OPTION, FORM_FIELD_CREATE_OPTION, FORM_FIELD_UPDATE_OPTION} from 'appRedux/actions/forms';

import DeleteOption from 'components/Forms/FormBuilder/FieldForm/DeleteOption';
import EmptyArea from 'components/EmptyArea';
import CustomMdxEditorSmall from 'components/MdxEditorField/CustomMdxEditorSmall';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import OptionsImport from 'components/Forms/FormBuilder/FieldForm/OptionsImport';

import {FORM_FIELD_CHECKBOXES, FORM_FIELD_RADIOBUTTONS} from 'pages/admin/updateForm/partials/FormStructure/helper';

import {ERROR_TEXT_COLOR, theme} from 'config/theme';
import {getOptionsListError, OPTION_ERROR_REPEAT} from 'components/Forms/FormBuilder/FieldForm/helper';

interface OptionsListType {
    fieldType: number;
    fieldOptions: FieldOptionType[];
    setShowOptionsError: (value: boolean) => void;
    fieldId?: number;
    validateOptions: (fieldOptions: FieldOptionType[], fieldType: number) => void;
}

const OptionsList: FC<OptionsListType> = ({fieldType, fieldOptions, setShowOptionsError, fieldId, validateOptions}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const [showImportModal, setShowImportModal] = useState<boolean>(false);

    const toggleImportModal = () => {
        setShowImportModal(previous => !previous);
    };

    const [showAddInput, setShowAddInput] = useState<boolean>(false);
    const [showMdxEditor, setShowMdxEditor] = useState<boolean>(false);
    const [editedIndex, setEditedIndex] = useState<number | null>(null);

    const [updatedOptionTitle, setUpdatedOptionTitle] = useState<string>('');
    const [updatedOptionText, setUpdatedOptionText] = useState<string>('');

    const [loadingOptions, setLoadingOptions] = useState<number[]>([]);
    const [optionEditError, setOptionEditError] = useState<number | null>(null);

    useEffect(() => {
        validateOptions(fieldOptions, fieldType);
    }, [validateOptions, fieldOptions, fieldType]);

    const findDuplicates = (title: string): boolean => {
        let options = [...fieldOptions];
        if (editedIndex !== null) options = options.filter((_, i) => editedIndex !== i);
        return !!options.find(opt => opt.title === title);
    };

    const createFieldOption = useCallback(
        data => dispatch({type: FORM_FIELD_CREATE_OPTION.REQUEST, payload: data}),
        [dispatch],
    );
    const updateFieldOption = useCallback(
        data => dispatch({type: FORM_FIELD_UPDATE_OPTION.REQUEST, payload: data}),
        [dispatch],
    );
    const deleteFieldOption = useCallback(
        data => dispatch({type: FORM_FIELD_DELETE_OPTION.REQUEST, payload: data}),
        [dispatch],
    );

    const handleUpdate = (e: ChangeEvent<{value: unknown}>) => {
        const value = e.target.value as string;
        setUpdatedOptionTitle(value);
    };

    const handleTextUpdate = (value: string) => {
        setUpdatedOptionText(value);
    };

    const toggleAddInput = () => {
        setShowOptionsError(false);
        setEditedIndex(null);
        setUpdatedOptionTitle('');
        setUpdatedOptionText('');
        setShowAddInput(previous => !previous);
        setShowMdxEditor(false);
    };

    const toggleEdit = (index: number) => {
        setShowOptionsError(false);
        setShowAddInput(false);
        setEditedIndex(index);
        setUpdatedOptionTitle(fieldOptions?.[index]?.title || '');
        setUpdatedOptionText(fieldOptions?.[index]?.text || '');
        setShowMdxEditor(!!fieldOptions?.[index]?.text);
    };

    const closeEdit = () => {
        setShowOptionsError(false);
        setShowAddInput(false);
        setEditedIndex(null);
        setUpdatedOptionTitle('');
        setUpdatedOptionText('');
    };

    const onAddClick = async (move?: 'new' | number) => {
        if (updatedOptionTitle.length === 0) return;
        const newIndex = fieldOptions.length;
        setLoadingOptions(prev => [...prev, newIndex]);

        createFieldOption({
            fieldId,
            file: null,
            title: String(updatedOptionTitle),
            text: String(updatedOptionText),
            priority: newIndex + 1,
            callback: () => {
                setLoadingOptions(prev => prev.filter(i => i !== newIndex));
                toggleAddInput();

                setTimeout(() => {
                    if (move === 'new') {
                        setShowAddInput(true);
                    } else if (move !== undefined) {
                        toggleEdit(move);
                    } else {
                        closeEdit();
                    }
                }, 10);
            },
        });

        setTimeout(() => {
            if (move === 'new') {
                setShowAddInput(true);
            } else if (move !== undefined) {
                toggleEdit(move);
            } else {
                closeEdit();
            }
        }, 10);
    };

    const onUpdateClick = async (index: number, move?: 'new' | number) => {
        if (editedIndex === null) return;

        if (updatedOptionTitle) {
            if (findDuplicates(updatedOptionTitle)) {
                setOptionEditError(OPTION_ERROR_REPEAT);
                return;
            }
            setLoadingOptions(prev => [...prev, index]);

            await new Promise(resolve => {
                updateFieldOption({
                    file: null,
                    uuid: fieldOptions?.[index]?.uuid,
                    title: String(updatedOptionTitle),
                    text: String(updatedOptionText),
                    priority: index,
                    callback: () => {
                        setLoadingOptions(prev => prev.filter(i => i !== index));
                        resolve('');
                    },
                });
            });
        }

        if (!updatedOptionTitle) {
            await onDeleteClick(index);
        }

        if (move === 'new') {
            toggleAddInput();
        } else if (move !== undefined) {
            toggleEdit(move);
        } else {
            closeEdit();
        }
    };

    const onDeleteClick = async (index: number) => {
        setLoadingOptions(prev => [...prev, index]);

        new Promise(resolve => {
            deleteFieldOption({
                fieldId: fieldOptions?.[index]?.uuid,
                uuid: fieldOptions?.[index]?.uuid,
                optionIndex: index,
                callback: () => {
                    setLoadingOptions(prev => prev.filter(i => i !== index));
                    resolve('');
                },
            });
        });
    };

    const checkTabPress = (e: React.KeyboardEvent<HTMLDivElement>, index?: number) => {
        if (e.shiftKey && e.key === 'Tab') {
            index !== undefined && index >= 0
                ? index === 0
                    ? onUpdateClick(index, 'new')
                    : onUpdateClick(index, index - 1)
                : onAddClick(fieldOptions.length - 1);
        } else if (e.key === 'Tab') {
            index !== undefined && index < fieldOptions.length
                ? index === fieldOptions.length - 1
                    ? onUpdateClick(index, 'new')
                    : onUpdateClick(index, index + 1)
                : onAddClick('new');
        }
    };

    const showRichTextField = [FORM_FIELD_CHECKBOXES, FORM_FIELD_RADIOBUTTONS].includes(fieldType);

    const closeAndSave = async (move?: 'new' | number) => {
        setOptionEditError(null);
        if (editedIndex !== null) return onUpdateClick(editedIndex, move);
        if (updatedOptionTitle.length > 0) return onAddClick(move);
    };

    const onAddNewClick = async () => {
        closeAndSave('new');
        toggleAddInput();
    };

    const onEditClick = async (index: number) => {
        closeAndSave(index);
        toggleEdit(index);
    };

    const onImportNewClick = async () => {
        closeAndSave();
        setShowImportModal(true);
    };

    return (
        <Box sx={{mt: 1, mb: 2}}>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography variant="body2" sx={{mb: 1}}>
                    {t('orguser.forms.formField.options')}
                </Typography>
                <Box>
                    <IconButton
                        sx={{
                            backgroundColor: theme.palette.info.light,
                            '&:hover': {backgroundColor: theme.palette.secondary.contrastText},
                            mr: 1,
                        }}
                        onClick={onAddNewClick}
                    >
                        <AddIcon fontSize="small" sx={{color: theme.palette.background.default}} />
                    </IconButton>
                    <IconButton
                        sx={{
                            backgroundColor: theme.palette.info.light,
                            '&:hover': {backgroundColor: theme.palette.secondary.contrastText},
                            svg: {
                                fillColor: 'white',
                            },
                        }}
                        onClick={onImportNewClick}
                        disabled={!fieldId}
                    >
                        <FileDownloadOutlinedIcon fontSize="small" sx={{color: theme.palette.background.default}} />
                    </IconButton>
                </Box>
            </Box>
            {!!fieldId &&
                createPortal(
                    <ModalWrapper
                        isWideModal={showRichTextField}
                        isShowModal={showImportModal}
                        toggleModal={toggleImportModal}
                        title={t('orguser.forms.formField.optionsImportModal')}
                    >
                        <OptionsImport fieldId={fieldId} fieldType={fieldType} toggleModal={toggleImportModal} />
                    </ModalWrapper>,
                    document.body,
                )}
            {fieldOptions.map((option: FieldOptionType, index: number) => {
                return (
                    <Box
                        key={`option-${index}`}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            backgroundColor: theme.palette.warning.contrastText,
                            borderRadius: 3,
                            p: 1,
                            pl: 2,
                            mt: 1,
                            mb: 1,
                        }}
                    >
                        {editedIndex === index ? (
                            <>
                                <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
                                    <Box
                                        sx={{
                                            width: 'calc(100% - 80px)',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <TextField
                                            sx={{'& div': {height: 40}}}
                                            fullWidth
                                            value={updatedOptionTitle}
                                            onChange={handleUpdate}
                                            onBlur={() => !showRichTextField && onUpdateClick(index)}
                                            autoFocus
                                            onKeyDown={e => {
                                                if (e.key === 'Tab') {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onKeyUp={e => {
                                                e.preventDefault();
                                                checkTabPress(e, index);
                                            }}
                                        />
                                    </Box>
                                    <EmptyArea />
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {loadingOptions.includes(index) ? (
                                            <HerupuSpinner size={30} />
                                        ) : (
                                            showRichTextField && (
                                                <IconButton onClick={() => setShowMdxEditor(prev => !prev)}>
                                                    <SubjectIcon fontSize="small" />
                                                </IconButton>
                                            )
                                        )}
                                        <IconButton
                                            disabled={updatedOptionTitle.length === 0}
                                            onClick={() => onUpdateClick(index)}
                                        >
                                            <CheckIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                </Box>
                                {showRichTextField && showMdxEditor && (
                                    <Box sx={{position: 'relative', width: '100%', mr: 1, my: 1}}>
                                        <CustomMdxEditorSmall value={updatedOptionText} onChange={handleTextUpdate} />
                                        <Box sx={{position: 'absolute', right: '4px', bottom: '8px'}}>
                                            <AgentSaveButton
                                                title={`${t('common.buttons.saveButton')}`}
                                                onClick={() => onUpdateClick(index)}
                                            />
                                        </Box>
                                    </Box>
                                )}
                                {optionEditError && (
                                    <Box sx={{width: '100%', mr: 1, my: 1}}>
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                mb: 2,
                                                color: ERROR_TEXT_COLOR,
                                            }}
                                        >
                                            {t(getOptionsListError(optionEditError))}
                                        </Typography>
                                    </Box>
                                )}
                            </>
                        ) : (
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: 'calc(100% - 80px)',
                                        height: 30,
                                        ml: 1,
                                    }}
                                    onClick={() => onEditClick(index)}
                                >
                                    <Typography variant="body2">{option.title}</Typography>
                                </Box>
                                <EmptyArea />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {showRichTextField && (
                                        <IconButton
                                            onClick={async () => {
                                                await onEditClick(index);
                                                setShowMdxEditor(true);
                                            }}
                                        >
                                            <SubjectIcon
                                                fontSize="small"
                                                color={!option.text ? 'disabled' : 'action'}
                                            />
                                        </IconButton>
                                    )}
                                    <DeleteOption index={index} onDeleteClick={onDeleteClick} />
                                </Box>
                            </>
                        )}
                    </Box>
                );
            })}
            {showAddInput && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        backgroundColor: theme.palette.warning.contrastText,
                        borderRadius: 3,
                        p: 1,
                        pl: 3,
                        mt: 1,
                        mb: 1,
                    }}
                >
                    <Box sx={{width: 'calc(100% - 80px)', display: 'flex', alignItems: 'center'}}>
                        <TextField
                            sx={{'& div': {height: 40}}}
                            fullWidth
                            value={updatedOptionTitle}
                            onChange={handleUpdate}
                            onBlur={() => !showRichTextField && onAddClick()}
                            autoFocus
                            onKeyDown={e => {
                                if (e.key === 'Tab') {
                                    e.preventDefault();
                                }
                            }}
                            onKeyUp={e => {
                                e.preventDefault();
                                checkTabPress(e);
                            }}
                        />
                        <Box
                            sx={{
                                width: 40,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {loadingOptions.includes(fieldOptions.length) && <HerupuSpinner size={30} />}
                        </Box>
                    </Box>
                    <EmptyArea />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {showRichTextField && (
                            <IconButton onClick={() => setShowMdxEditor(prev => !prev)}>
                                <SubjectIcon fontSize="small" />
                            </IconButton>
                        )}
                        <IconButton
                            disabled={updatedOptionTitle.length === 0 || findDuplicates(updatedOptionTitle)}
                            onClick={() => onAddClick()}
                        >
                            <CheckIcon fontSize="small" />
                        </IconButton>
                    </Box>
                    {showRichTextField && showMdxEditor && (
                        <Box sx={{position: 'relative', width: '100%', mr: 1, my: 1}}>
                            <CustomMdxEditorSmall value={updatedOptionText} onChange={handleTextUpdate} />
                            <Box sx={{position: 'absolute', right: '4px', bottom: '8px'}}>
                                <AgentSaveButton
                                    title={`${t('common.buttons.saveButton')}`}
                                    onClick={() => onAddClick()}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default OptionsList;
