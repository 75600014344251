import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {format} from 'date-fns';

import {ResourceFieldExceptionFormTypes, ResourceFieldExceptionItemTypes} from 'appRedux/actions/resourceFields/types';
import {UPDATE_RESOURCE_FIELD_EXCEPTION} from 'appRedux/actions/resourceFields';

import {AlertContext} from 'contexts/alert/context';

import ResourceFieldExceptionForm from 'components/Forms/ResourceField/ResourceFieldExceptionForm';

import {getInitialDate} from 'helpers/datepickerHelper';

import {DATEPICKER_FORMAT} from 'config/index';

interface UpdateResourceFieldExceptionFormType {
    item: ResourceFieldExceptionItemTypes;
    closeModal: () => void;
}

const UpdateResourceFieldExceptionForm: FC<UpdateResourceFieldExceptionFormType> = ({item, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {date, type, startTime, endTime, uuid} = item;

    const [newDate, setNewDate] = useState<Date>(getInitialDate(date));

    const updateResourceFieldException = useCallback(
        data => dispatch({type: UPDATE_RESOURCE_FIELD_EXCEPTION.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: ResourceFieldExceptionFormTypes) => {
        updateResourceFieldException({
            ...values,
            date: newDate,
            uuid,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <ResourceFieldExceptionForm
            initialValues={{
                date: format(newDate, DATEPICKER_FORMAT),
                type,
                startTime,
                endTime,
            }}
            date={newDate}
            setDate={setNewDate}
            onSubmitClicked={onSubmitClicked}
        />
    );
};

export default UpdateResourceFieldExceptionForm;
