import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';

import {CREATE_GROUP} from 'appRedux/actions/groups';
import {GroupFormTypes, GroupHasRoleItemTypes} from 'appRedux/actions/groups/types';

import {AlertContext} from 'contexts/alert/context';

import GroupForm from 'components/Forms/GroupForm/GroupForm';

interface CreateGroupFormType {
    organizationId: number;
    closeModal: () => void;
}

const CreateGroupForm: FC<CreateGroupFormType> = ({organizationId, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [groupHasRoles, setGroupHasRoles] = useState<GroupHasRoleItemTypes[]>([]);

    const createGroup = useCallback(data => dispatch({type: CREATE_GROUP.REQUEST, payload: data}), [dispatch]);

    const onSubmitClicked = (values: GroupFormTypes) => {
        createGroup({
            ...values,
            id: organizationId,
            groupHasRoles,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <GroupForm
            initialValues={{
                title: '',
                description: '',
                groupHasRoles: [],
            }}
            groupHasRoles={groupHasRoles}
            setGroupHasRoles={setGroupHasRoles}
            onSubmitClicked={onSubmitClicked}
        />
    );
};

export default CreateGroupForm;
