import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const PanelSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" fill={getIconColor(isActive, isDisabled)} fillOpacity="0.01" />
            <path
                d="M14 14V3H10V14H8V7H4V14H2V1H1V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H15V14H14ZM11 4H13V9H11V4ZM5 8H7V11H5V8Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default PanelSvgIcon;
