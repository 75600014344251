import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import IconButton from '@mui/material/IconButton';

import GroupSvgIcon from 'assets/icons/buttons/GroupSvgIcon';

import {ResourceFieldSlotItemTypes} from 'appRedux/actions/resourceFields/types';

import {routes} from 'config/index';

interface RelatedCasesButtonType {
    item: ResourceFieldSlotItemTypes;
}

const RelatedCasesButton: FC<RelatedCasesButtonType> = ({item}) => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const redirectToCasesList = () => {
        navigate(`${routes.RESOURCE_FIELD}/slot/${item.uuid}/cases`);
    };

    return (
        <IconButton onClick={redirectToCasesList} title={t('orguser.resourceFields.casesList')}>
            <GroupSvgIcon />
        </IconButton>
    );
};

export default RelatedCasesButton;
