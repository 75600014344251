import {combineReducers} from 'redux';

import auth, {AuthState} from 'appRedux/reducers/auth';
import analytics, {AnalyticsState} from 'appRedux/reducers/analytics';
import profile, {ProfileState} from 'appRedux/reducers/profile';
import admin, {AdminState} from 'appRedux/reducers/admin';
import errors, {ErrorsState} from 'appRedux/reducers/errors';
import generalTemplates, {GeneralTemplatesState} from 'appRedux/reducers/generalTemplates';
import crypto, {CryptoState} from 'appRedux/reducers/crypto';
import groups, {GroupsState} from 'appRedux/reducers/groups';
import notifications, {NotificationState} from 'appRedux/reducers/notifications';
import requestCase, {RequestCaseState} from 'appRedux/reducers/requestCase';
import requestChat, {RequestChatState} from 'appRedux/reducers/requestChat';
import superadmin, {SuperadminState} from 'appRedux/reducers/superadmin';
import resourceFields, {ResourceFieldsState} from 'appRedux/reducers/resourceFields';
import instance, {InstanceSettingsState} from 'appRedux/reducers/instance';
import workflow, {WorkflowState} from 'appRedux/reducers/workflow';

export interface RootReducer {
    auth: AuthState;
    admin: AdminState;
    analytics: AnalyticsState;
    crypto: CryptoState;
    errors: ErrorsState;
    generalTemplates: GeneralTemplatesState;
    groups: GroupsState;
    instance: InstanceSettingsState;
    notifications: NotificationState;
    profile: ProfileState;
    requestCase: RequestCaseState;
    requestChat: RequestChatState;
    superadmin: SuperadminState;
    resourceFields: ResourceFieldsState;
    workflow: WorkflowState;
}

const reducers = {
    auth,
    admin,
    analytics,
    crypto,
    errors,
    generalTemplates,
    groups,
    instance,
    notifications,
    profile,
    requestCase,
    requestChat,
    superadmin,
    resourceFields,
    workflow,
};

export default combineReducers<RootReducer>(reducers);
