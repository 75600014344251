import {TFunction} from 'react-i18next';

import {RequesterDocumentResponseType} from 'appRedux/actions/profile/types';
import {RequestCasePageType, ChatFileType} from 'appRedux/actions/requestCase/types';

import {decryptStringWithKey} from 'helpers/cryptoApiHelper';

export const getFilesLabel = (t: TFunction<'translation'>, totalFilesNumber: number) => {
    if (totalFilesNumber > 1) {
        return `${totalFilesNumber} ${t('requester.casesList.files')}`;
    }
    if (totalFilesNumber === 1) {
        return `${totalFilesNumber} ${t('requester.casesList.file')}`;
    }
    return t('requester.casesList.noFiles');
};

export const isFormChecked = (pages: RequestCasePageType[], checkedSections: number[]): boolean => {
    if (pages.length === 0) {
        return false;
    }
    for (let i = 0, n = pages.length; i < n; i++) {
        const sections = pages[i].sections;
        if (!isPageChecked(sections, checkedSections)) {
            return false;
        }
    }
    return true;
};

export const isPageChecked = (pageSections: number[], checkedSections: number[]): boolean => {
    for (let i = 0, n = pageSections.length; i < n; i++) {
        const pageSection = pageSections[i];
        if (!checkedSections.includes(pageSection)) {
            return false;
        }
    }
    return true;
};

export const getEncryptedChatFiles = async (
    chatFiles: ChatFileType[],
    unwrappedCaseKey: CryptoKey | null,
    isEncryptInfo: boolean,
): Promise<ChatFileType[]> => {
    const results: ChatFileType[] = [];

    for (let i = 0, n = chatFiles.length; i < n; i++) {
        const item = chatFiles[i];
        results.push({
            ...item,
            text:
                isEncryptInfo && unwrappedCaseKey ? await decryptStringWithKey(item.text, unwrappedCaseKey) : item.text,
        });
    }

    return results;
};
