import React, {FC, useEffect, useRef} from 'react';
import {get} from 'lodash';

import AddIcon from '@mui/icons-material/Add';
import {Box, IconButton, Typography} from '@mui/material';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';
import HerupuSpinner from 'assets/herupuSpinner';

import {theme} from 'config/theme';

interface UploadOptionFileType {
    title: string;
    clickFileInput?: boolean;
    loading?: boolean;
    uploadFile?: (file: File) => void;
    fileName?: string;
    deleteFile?: () => void;
}

const UploadOptionFile: FC<UploadOptionFileType> = ({
    title,
    clickFileInput,
    loading,
    uploadFile,
    fileName,
    deleteFile,
}) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const onLabelClick = () => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    useEffect(() => {
        if (clickFileInput) onLabelClick();
    }, [clickFileInput]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 1,
                width: '100%',
                height: deleteFile ? '66px' : '40px',
            }}
        >
            <Box>
                <Typography variant="h6">{title}:</Typography>
                {fileName && <Typography color={{color: theme.palette.info.light}}>{fileName}</Typography>}
            </Box>
            {loading ? (
                <Box sx={{width: 40, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <HerupuSpinner size={30} />
                </Box>
            ) : fileName && deleteFile ? (
                <IconButton data-id={`button#option-file-delete`} onClick={deleteFile} sx={{p: 1.5}}>
                    <DeleteSvgIcon />
                </IconButton>
            ) : (
                <IconButton data-id={`button#option-file-add`} onClick={onLabelClick}>
                    <AddIcon />
                </IconButton>
            )}
            <input
                ref={fileInputRef}
                style={{display: 'none'}}
                name="file"
                type="file"
                accept="image/jpeg,image/gif,image/png,application/pdf"
                onChange={value => {
                    const newFile = get(value, ['currentTarget', 'files', '0'], null);
                    if (!newFile) return;
                    uploadFile && uploadFile(newFile);
                }}
            />
        </Box>
    );
};

export default UploadOptionFile;
