import React, {FC, useState} from 'react';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import FormWhiteListEmailCreateForm from 'components/Forms/FormBuilder/WhiteListEmailForm/FormWhiteListEmailCreateForm';

interface FormAllowListEmailCreateButtonType {
    title: string;
    forDomain?: boolean;
}

const FormAllowListEmailCreateButton: FC<FormAllowListEmailCreateButtonType> = ({title, forDomain}) => {
    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <>
            <AgentSaveButton isSubmit={false} title={title} onClick={toggleModal} />
            <ModalWrapper isShowModal={isOpened} title={title} toggleModal={toggleModal}>
                <FormWhiteListEmailCreateForm closeModal={() => setIsOpened(false)} forDomain={forDomain} />
            </ModalWrapper>
        </>
    );
};

export default FormAllowListEmailCreateButton;
