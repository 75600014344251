import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import MarkdownWithShowAll from 'components/TextWithShowAll/MarkdownWithShowAll';

import LoginIfAlreadyInCase from 'components/RegistrationForm/LoginIfAlreadyInCase';

import {ERROR_TEXT_COLOR} from 'config/theme';

interface FormPausedType {
    alreadyLogin: boolean;
    formId: number;
    reason: string;
}

const FormPaused: FC<FormPausedType> = ({alreadyLogin, formId, reason}) => {
    const [t] = useTranslation();
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2, mb: 1}}>
            <Typography
                align="center"
                sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: ERROR_TEXT_COLOR,
                }}
            >
                {t('common.registrationForm.thisFormIsPaused')}
            </Typography>
            <MarkdownWithShowAll text={reason} />
            <LoginIfAlreadyInCase alreadyLogin={alreadyLogin} />
        </Box>
    );
};

export default FormPaused;
