import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import Typography from '@mui/material/Typography';

import {OPEN_ID_LOGIN} from 'appRedux/actions/auth';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import LoginPageWrapper from 'pages/start/login/partials/LoginPageWrapper';

const LoginSso: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const loginSsoRequest = useCallback(data => dispatch({type: OPEN_ID_LOGIN.REQUEST, payload: data}), [dispatch]);

    const {auth} = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isSsoActive = get(auth, ['organization', 'isSsoActive'], false);

    useEffect(() => {
        if (isSsoActive) {
            loginSsoRequest({
                showAlert,
                callback: (redirectTo: string) => {
                    window.location.href = redirectTo;
                },
            });
        }
    }, [isSsoActive]);

    return (
        <LoginPageWrapper>
            <Typography>{t(isSsoActive ? 'common.pleaseWait' : 'common.loginForm.loginViaSsoImpossible')}</Typography>
        </LoginPageWrapper>
    );
};

export default LoginSso;
