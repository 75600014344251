import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import LoginIfAlreadyInCase from 'components/RegistrationForm/LoginIfAlreadyInCase';

import {ERROR_TEXT_COLOR} from 'config/theme';

interface FormInviteOnlyType {
    alreadyLogin: boolean;
}

const FormInviteOnly: FC<FormInviteOnlyType> = ({alreadyLogin}) => {
    const [t] = useTranslation();
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2}}>
            <Typography
                align="center"
                sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: ERROR_TEXT_COLOR,
                    mb: 1,
                }}
            >
                {t('common.registrationForm.thisFormIsInviteOnly')}
            </Typography>
            <LoginIfAlreadyInCase alreadyLogin={alreadyLogin} />
        </Box>
    );
};

export default FormInviteOnly;
