import {fork, put, take, call} from 'redux-saga/effects';

import {http} from 'services/http';

import {
    FORM_ALLOW_LIST_CREATE,
    createFormAllowList,
    FORM_ALLOW_LIST_DELETE,
    deleteFormAllowList,
} from 'appRedux/actions/forms';
import {
    FormAllowListCreateTypes,
    FormAllowListDeleteTypes,
    FormAllowListResponseTypes,
} from 'appRedux/actions/forms/types';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchFormWhiteListEmailCreation() {
    while (true) {
        const {
            payload: {id, showAlert, callback, setErrors, ...values},
        }: IActionType<FormAllowListCreateTypes> = yield take(FORM_ALLOW_LIST_CREATE.REQUEST);
        try {
            const data: FormAllowListResponseTypes = yield call(http, `form/allow/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createFormAllowList.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(createFormAllowList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
                setErrors(data.errors);
            }
        } catch (e) {
            yield put(createFormAllowList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormWhiteListEmailDeleting() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback},
        }: IActionType<FormAllowListDeleteTypes> = yield take(FORM_ALLOW_LIST_DELETE.REQUEST);
        try {
            const data: FormAllowListResponseTypes = yield call(http, `form/allow/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteFormAllowList.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(deleteFormAllowList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteFormAllowList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [fork(watchFormWhiteListEmailCreation), fork(watchFormWhiteListEmailDeleting)];
