import React, {FC, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';
import {useSelector} from 'react-redux';

import IconButton from '@mui/material/IconButton';

import {RequesterCaseActivitiesTypes} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';
import {FormPageTypes} from 'appRedux/actions/forms/types';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {CaseKeyContext} from 'contexts/caseKey/context';

import ActivityReviewPopup from 'pages/agent/activityPage/ActivityReviewPopup';

interface ActivityReviewButtonType {
    isEncryptInfo: boolean;
    item: RequesterCaseActivitiesTypes;
    activityLogsList?: boolean;
}

const ActivityReviewButton: FC<ActivityReviewButtonType> = ({item, isEncryptInfo, activityLogsList}) => {
    const [t] = useTranslation();

    const {encodedCaseKey} = useContext(CaseKeyContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {
        admin: {formInfo},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const pages: FormPageTypes[] = get(formInfo, 'pages', []);

    return (
        <>
            <IconButton title={t('common.buttons.review')} onClick={toggleIsOpened}>
                <EditSvgIcon />
            </IconButton>
            <ActivityReviewPopup
                isEncryptInfo={isEncryptInfo}
                isOpened={isOpened}
                toggleIsOpened={toggleIsOpened}
                item={item}
                pages={pages}
                encodedCaseKey={encodedCaseKey}
                activityLogsList={activityLogsList}
            />
        </>
    );
};

export default ActivityReviewButton;
