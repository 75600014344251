import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import LockSvgIcon from 'assets/icons/buttons/LockSvgIcon';

import {OrganizationAdminListTypes} from 'appRedux/actions/organizationAdmin/types';
import {ORGANIZATION_ADMIN_UNLOCK} from 'appRedux/actions/organizationAdmin';
import {TwoFactorConfirmationTypes} from 'appRedux/actions/auth/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import GoogleAuthCodeForm from 'components/Forms/SettingsForms/TwoFactorForm/GoogleAuthCodeForm';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {theme, ERROR_TEXT_COLOR} from 'config/theme';

interface UnlockOrganizationAdminType {
    item: OrganizationAdminListTypes;
}

const UnlockOrganizationAdmin: FC<UnlockOrganizationAdminType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {
        id,
        admin: {isLocked},
    } = item;

    const {showAlert} = useContext(AlertContext);
    const {isUserEditEnabled} = useContext(PermissionContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const unlockUser = useCallback(
        data => dispatch({type: ORGANIZATION_ADMIN_UNLOCK.REQUEST, payload: data}),
        [dispatch],
    );

    const confirmTwoFactor = (values: TwoFactorConfirmationTypes) => {
        unlockUser({
            ...values,
            id,
            showAlert,
            callback: () => setIsOpened(false),
        });
    };

    const onUnlockClick = () => {
        unlockUser({
            id,
            showAlert,
            callback: () => setIsOpened(false),
        });
    };

    return (
        <>
            <IconButton
                sx={{
                    mr: 1,
                    backgroundColor: isLocked ? ERROR_TEXT_COLOR : 'initial',
                    '&:hover': {
                        backgroundColor: isLocked ? ERROR_TEXT_COLOR : 'initial',
                    },
                }}
                onClick={toggleIsOpened}
                title={t(isLocked ? 'superadmin.users.unlockUserAccount' : 'superadmin.users.lockUserAccount')}
                disabled={!isUserEditEnabled}
            >
                <LockSvgIcon isActive={isLocked} isDisabled={!isUserEditEnabled} />
            </IconButton>
            <ModalWrapper
                title={t(isLocked ? 'superadmin.users.unlockUserAccount' : 'superadmin.users.lockUserAccount')}
                isShowModal={isOpened}
                toggleModal={toggleIsOpened}
            >
                {profile && profile.isTwoFactorActivated ? (
                    <Box>
                        <Typography>{t('superadmin.users.twoFactorDeactivationDescription')}</Typography>
                        <GoogleAuthCodeForm handleSubmit={confirmTwoFactor} />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 3,
                        }}
                    >
                        <AgentSaveButton isSubmit={false} title={t('common.buttons.confirm')} onClick={onUnlockClick} />
                    </Box>
                )}
            </ModalWrapper>
        </>
    );
};

export default UnlockOrganizationAdmin;
