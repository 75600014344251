import React, {FC, ReactNode, useContext} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import {MediaContext} from 'contexts/media/context';
import {RouteContext} from 'contexts/route/context';

import EmptyArea from 'components/EmptyArea';
import PoweredByHerupu from 'components/StartScreenComponents/PoweredByHerupu';

import {routes} from 'config/index';
import {theme} from 'config/theme';

interface StartScreenWrapperType {
    isAuthenticated?: boolean;
    children: ReactNode;
}

const StartScreenWrapper: FC<StartScreenWrapperType> = ({isAuthenticated, children}) => {
    const [t] = useTranslation();

    const {isMobile} = useContext(MediaContext);
    const {isImprintPage, isPrivacyPolicyPage} = useContext(RouteContext);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
                overflowX: 'hidden',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    minHeight: '100vh',
                    width: isMobile ? '100vw' : '30vw',
                    overflowX: 'hidden',
                    p: 3,
                }}
            >
                <EmptyArea />
                {children}
                <PoweredByHerupu />
                {!isAuthenticated && (
                    <Grid container>
                        <Grid xs={6} item display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                            <Link
                                to={routes.RULE_ROUTE_IMPRINT}
                                style={{textDecoration: 'none', color: theme.palette.info.main}}
                            >
                                <Typography sx={{fontSize: 12, fontWeight: isImprintPage ? 600 : 400, mr: 1}}>
                                    {t('common.imprint')}
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid xs={6} item display={'flex'} flexDirection={'row'} justifyContent={'flex-start'}>
                            <Link
                                to={routes.RULE_ROUTE_PRIVACY_POLICY}
                                style={{textDecoration: 'none', color: theme.palette.info.main}}
                            >
                                <Typography sx={{fontSize: 12, fontWeight: isPrivacyPolicyPage ? 600 : 400, ml: 1}}>
                                    {t('common.privacyPolicy')}
                                </Typography>
                            </Link>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Box>
    );
};

export default StartScreenWrapper;
