import React, {FC, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {InstanceSettingBasic, InstanceSettingsFile} from 'appRedux/actions/instance/types';
import {RootReducer} from 'appRedux/reducers';
import {INSTANCE_SETTING_UPLOAD, INSTANCE_SETTING_UPLOAD_FAVICON} from 'appRedux/actions/instance';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

import {SETTING_FAVICON, SETTING_TITLE, SETTING_LOGO} from 'pages/admin/instanceSettings/constants';
import InstanceLogo from 'pages/admin/instanceSettings/InstanceLogo';
import InstanceSettingUpdateButton from 'pages/admin/instanceSettings/InstanceSettingUpdateButton';
import InstanceSettingUploadLogo from 'pages/admin/instanceSettings/InstanceSettingUploadLogo';

import {EMPTY_DATA} from 'config/index';

const getInstanceValue = (item: InstanceSettingBasic, files: InstanceSettingsFile[]) => {
    switch (item.type) {
        case SETTING_LOGO:
            return <InstanceLogo imageSrc={get(files, 'logo', null)} />;
        case SETTING_FAVICON:
            return <InstanceLogo imageSrc={get(files, 'favicon', null)} />;
        default:
            return <Typography>{item.value ?? EMPTY_DATA}</Typography>;
    }
};

const getInstanceUpdateButton = (item: InstanceSettingBasic, files: InstanceSettingsFile[]) => {
    const dispatch = useDispatch();

    const submitUploadFile = useCallback(
        data => dispatch({type: INSTANCE_SETTING_UPLOAD.REQUEST, payload: data}),
        [dispatch],
    );

    const submitUploadFavicon = useCallback(
        data => dispatch({type: INSTANCE_SETTING_UPLOAD_FAVICON.REQUEST, payload: data}),
        [dispatch],
    );

    switch (item.type) {
        case SETTING_LOGO:
            return (
                <InstanceSettingUploadLogo
                    onSubmitClick={submitUploadFile}
                    item={item}
                    isPresented={get(files, 'logo', false)}
                />
            );
        case SETTING_FAVICON:
            return (
                <InstanceSettingUploadLogo
                    onSubmitClick={submitUploadFavicon}
                    item={item}
                    isPresented={get(files, 'favicon', false)}
                    isFavicon
                />
            );
        default:
            return <InstanceSettingUpdateButton item={item} />;
    }
};

const InstanceSettings: FC = () => {
    const [t] = useTranslation();

    const {
        instance: {settings, files},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <ClientSectionWrapper title={t('superadmin.instance.settings')}>
            {settings &&
                settings.map((item: InstanceSettingBasic, index: number) => {
                    const {title, type} = item;
                    if ([SETTING_TITLE, SETTING_LOGO, SETTING_FAVICON].includes(type)) {
                        return (
                            <Grid
                                container
                                key={`setting-${index}`}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    mb: 2,
                                }}
                            >
                                <Grid item sm={3}>
                                    <Typography sx={{fontWeight: 600}}>{title}</Typography>
                                </Grid>
                                <Grid item sm={4}>
                                    {getInstanceValue(item, files)}
                                </Grid>
                                <Grid item sm={3}>
                                    {getInstanceUpdateButton(item, files)}
                                </Grid>
                            </Grid>
                        );
                    }
                    return null;
                })}
        </ClientSectionWrapper>
    );
};

export default InstanceSettings;
