import {fork, put, take, call} from 'redux-saga/effects';

import {http} from 'services/http';

import {
    FORM_TAG_LIST,
    getFormTagsList,
    FORM_TAG_CREATE,
    createFormTag,
    FORM_TAG_DELETE,
    deleteFormTag,
    FORM_TAG_UPDATE,
    updateFormTag,
} from 'appRedux/actions/forms';
import {FormTagCreateRequestType, FormTagType, FormTagResponseType} from 'appRedux/actions/forms/types';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchFormTagListGetting() {
    while (true) {
        const {
            payload: {id},
        }: IActionType<FormTagCreateRequestType> = yield take(FORM_TAG_LIST.REQUEST);
        try {
            const data: FormTagType[] = yield call(http, `tag/${id}/list`, {
                method: 'GET',
            });
            if (data) {
                yield put(getFormTagsList.success(data));
            } else {
                yield put(getFormTagsList.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getFormTagsList.error({message: String(e)}));
        }
    }
}

function* watchFormTagCreation() {
    while (true) {
        const {
            payload: {id, showAlert, callback, setErrors, ...values},
        }: IActionType<FormTagCreateRequestType> = yield take(FORM_TAG_CREATE.REQUEST);
        try {
            const data: FormTagResponseType = yield call(http, `tag/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createFormTag.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(createFormTag.error({message: 'messages.error.somethingWentWrong'}));
                setErrors && setErrors(data.errors);
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createFormTag.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormTagUpdating() {
    while (true) {
        const {
            payload: {id, showAlert, callback, setErrors, ...values},
        }: IActionType<FormTagCreateRequestType> = yield take(FORM_TAG_UPDATE.REQUEST);
        try {
            const data: FormTagResponseType = yield call(http, `tag/${id}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateFormTag.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(updateFormTag.error({message: 'messages.error.somethingWentWrong'}));
                setErrors && setErrors(data.errors);
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateFormTag.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormTagDeleting() {
    while (true) {
        const {
            payload: {id, showAlert, callback},
        }: IActionType<FormTagCreateRequestType> = yield take(FORM_TAG_DELETE.REQUEST);
        try {
            const data: FormTagResponseType = yield call(http, `tag/${id}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteFormTag.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(deleteFormTag.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteFormTag.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchFormTagListGetting),
    fork(watchFormTagCreation),
    fork(watchFormTagUpdating),
    fork(watchFormTagDeleting),
];
