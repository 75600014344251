import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import moment from 'moment';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import ArrowRightSvgIcon from 'assets/icons/buttons/ArrowRightSvgIcon';

import {RootReducer} from 'appRedux/reducers';
import {RequesterCaseApproveTypes} from 'appRedux/actions/requestCase/types';

import AvatarImage from 'components/UserAvatar/AvatarImage';
import {buttonOutlineStyles, labelOutlineStyles} from 'components/AgentScreenComponents/_buttons/common';
import {getAvatarTitle} from 'components/TopBarMenu/helper';

import ApproverResolutionButton from 'pages/agent/requesterPage/formInformation/approver/ApproverResolutionButton';

import {DATE_TIME_LABEL_FORMAT} from 'config/index';

interface ApproverItemType {
    item: RequesterCaseApproveTypes;
}

const ApproverItem: FC<ApproverItemType> = ({item}) => {
    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {
        initialStatusTitle,
        approverId,
        newStatusTitle,
        avatarType,
        avatarLink,
        firstName,
        lastName,
        email,
        updatedAt,
    } = item;

    const currentUserId = get(profile, 'id', null);

    return (
        <Box sx={{mb: 2}}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <Button sx={{...buttonOutlineStyles, pl: 0.5, mr: 0, mb: 0}}>
                    <AvatarImage
                        size={25}
                        avatarType={avatarType}
                        avatarLink={avatarLink}
                        avatarTitle={getAvatarTitle(firstName, lastName, email)}
                    />
                    <Typography sx={{...labelOutlineStyles, ml: 1}}>
                        {firstName && lastName ? `${firstName} ${lastName}` : email}
                    </Typography>
                </Button>
                <ApproverResolutionButton item={item} isCurrentUserResolution={approverId === Number(currentUserId)} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <Typography sx={{fontWeight: 700, fontSize: 12, mr: 1}}>{initialStatusTitle}</Typography>
                <ArrowRightSvgIcon />
                <Typography sx={{fontWeight: 700, fontSize: 12, ml: 1}}>{newStatusTitle}</Typography>
            </Box>
            <Typography sx={{fontSize: 12}}>{moment(updatedAt).format(DATE_TIME_LABEL_FORMAT)}</Typography>
        </Box>
    );
};

export default ApproverItem;
