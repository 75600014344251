export const LOCAL_STORAGE_DEBUG_MODE = 'debug_mode';

export const isDebug = () => localStorage.getItem(LOCAL_STORAGE_DEBUG_MODE) === 'true';

export const setConsoleLogDebugMode = () => {
    const debug = localStorage.getItem(LOCAL_STORAGE_DEBUG_MODE) === 'true';

    if (!debug) {
        // console.log = function (...args: any[]) {
        //     return args;
        // };
    }
};
