import React, {DragEvent, useRef} from 'react';

import Box from '@mui/material/Box';

import {ADMIN_LIGHT_GRAY_COLOR, CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface FormVersionCardFieldPlaceholderPropsType {
    priority: number;
    setDropTo: React.Dispatch<React.SetStateAction<number | 'delete' | null>>;
    active: boolean;
    onDrop?: () => void;
}

function FormVersionCardFieldPlaceholder({
    active,
    priority,
    setDropTo,
    onDrop,
}: FormVersionCardFieldPlaceholderPropsType) {
    const dropRef = useRef<HTMLDivElement>();

    const onDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
    };

    const onDragEnter = () => {
        setTimeout(() => {
            setDropTo && setDropTo(priority);
        }, 0);
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        onDrop && onDrop();
    };

    return (
        <Box
            ref={dropRef}
            sx={{
                width: '100%',
                mb: 1,
                border: `2px dashed ${ADMIN_LIGHT_GRAY_COLOR}`,
                background: active ? CLIENT_BACKGROUND_COLOR : 'white',
                height: '40px',
                borderRadius: 2,
            }}
            onDragEnter={onDragEnter}
            onDragOver={onDragOver}
            onDrop={handleDrop}
        ></Box>
    );
}

export default FormVersionCardFieldPlaceholder;
