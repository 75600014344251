import React, {FC, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import {CommonDeleteLogoTypes} from 'appRedux/actions/forms/types';
import {OrganizationLogoUrlRequestTypes} from 'appRedux/actions/organization/types';

import {AlertContext} from 'contexts/alert/context';

import CustomImage from 'components/CustomImage/CustomImage';
import DeleteClientConfirmationModal from 'components/ClientScreenComponents/DeleteClientConfirmationModal';

import {theme} from 'config/theme';

interface CurrentLogoType {
    id: number;
    type?: number;
    isFavicon?: boolean;
    logoSource: string;
    setLogoSource: (value: string | null) => void;
    deleteFormLogo: (value: CommonDeleteLogoTypes | OrganizationLogoUrlRequestTypes) => void;
}

const CurrentLogo: FC<CurrentLogoType> = ({id, type, isFavicon, logoSource, setLogoSource, deleteFormLogo}) => {
    const [t] = useTranslation();

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const callbackAfterRemoving = () => {
        setLogoSource(null);
        toggleIsOpened();
    };

    const onDeleteClick = () => {
        deleteFormLogo({
            id,
            type,
            callback: callbackAfterRemoving,
            showAlert,
        });
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                position: 'relative',
            }}
        >
            <CustomImage imageSrc={logoSource} />
            <IconButton
                title={t('common.buttons.deleteLogo')}
                onClick={toggleIsOpened}
                sx={{
                    position: 'absolute',
                    width: isFavicon ? 25 : 45,
                    height: isFavicon ? 25 : 45,
                    top: -10,
                    right: -10,
                    backgroundColor: theme.palette.info.main,
                    '&:hover': {
                        backgroundColor: theme.palette.info.main,
                    },
                }}
            >
                <CloseIcon fontSize={isFavicon ? 'small' : 'inherit'} sx={{color: theme.palette.background.default}} />
            </IconButton>
            {isOpened && (
                <DeleteClientConfirmationModal
                    isShowModal={isOpened}
                    toggleModal={toggleIsOpened}
                    onClickAction={onDeleteClick}
                />
            )}
        </div>
    );
};

export default CurrentLogo;
