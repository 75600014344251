import React, {FC, ReactNode, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Alert from '@mui/material/Alert';

import PlusSvgIcon from 'assets/icons/section/PlusSvgIcon';
import MinusSvgIcon from 'assets/icons/section/MinusSvgIcon';

import {MediaContext} from 'contexts/media/context';

import EmptyArea from 'components/EmptyArea';

import {theme, MAIN_CLIENT_COLOR, HIGHLIGHTED_SECTION_COLOR} from 'config/theme';

interface ClientSectionWrapperType {
    title?: string;
    toggleIdentifier?: string;
    isChecked?: boolean;
    isHighlighted?: boolean;
    children: ReactNode;
}

const ClientSectionWrapper: FC<ClientSectionWrapperType> = ({
    children,
    isHighlighted,
    toggleIdentifier,
    title,
    isChecked,
}) => {
    const [t] = useTranslation();

    const {isMobile} = useContext(MediaContext);

    const [showCheckedAlert, setShowCheckedAlert] = useState<boolean>(false);
    const [isExpanded, setIsExpanded] = useState<boolean>(true);

    const toggleCheckedAlert = () => {
        setShowCheckedAlert(previous => !previous);
    };

    const toggleExpanded = () => {
        setIsExpanded(previous => !previous);
    };

    return (
        <Box
            sx={{
                mb: 3,
                borderRadius: 2,
                backgroundColor: theme.palette.background.default,
                boxShadow: isHighlighted ? 24 : 'none',
            }}
        >
            {title && (
                <Box
                    sx={{
                        height: isMobile ? 48 : 59,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        backgroundColor: isHighlighted ? HIGHLIGHTED_SECTION_COLOR : theme.palette.background.default,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        padding: 2,
                    }}
                >
                    <Typography variant="body1" sx={{fontWeight: 600, fontSize: 16}}>
                        {t(String(title))}
                    </Typography>
                    {isChecked && (
                        <CheckCircleIcon
                            sx={{ml: 1, color: MAIN_CLIENT_COLOR, cursor: 'pointer'}}
                            onClick={toggleCheckedAlert}
                        />
                    )}
                    <EmptyArea />
                    <IconButton
                        data-id={`button#profile-section-lastLoginAttempts-toggle-expanded`}
                        onClick={toggleExpanded}
                    >
                        {isExpanded ? <MinusSvgIcon /> : <PlusSvgIcon />}
                    </IconButton>
                </Box>
            )}
            {showCheckedAlert && isChecked && (
                <Box sx={{width: '100%', pl: 2, pr: 2}}>
                    <Alert
                        icon={false}
                        severity={'success'}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography>{t('messages.warning.sectionIsCheckedByAgent')}</Typography>
                    </Alert>
                </Box>
            )}
            {isExpanded && (
                <Box
                    sx={{
                        padding: 3,
                    }}
                >
                    {children}
                </Box>
            )}
        </Box>
    );
};

export default ClientSectionWrapper;
