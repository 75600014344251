import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';

import IconButton from '@mui/material/IconButton';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

import {RoleTypes} from 'appRedux/actions/roles/types';
import {DELETE_ROLE, TOGGLE_ROLE_STATUS} from 'appRedux/actions/roles';
import {RootReducer} from 'appRedux/reducers';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';
import {RouteContext} from 'contexts/route/context';

import {TableBodyTypography, CustomSwitcher} from 'components/AgentScreenComponents/_form/CustomElements';
import DeleteAdminConfirmationButton from 'components/AdminScreenComponents/DeleteAdminConfirmationButton';
import {
    FILTER_FOR_CASES_OPERATIONS,
    FILTER_FOR_FORMS_OPERATIONS,
    FILTER_FOR_INVITES_OPERATIONS,
    FILTER_FOR_TRANSLATIONS_OPERATIONS,
} from 'components/Forms/RoleForm/helper';
import {requesterModes} from 'components/Forms/RoleForm/helper';

import PermissionItem from 'pages/admin/roles/PermissionItem';
import PermissionItemWrapper from 'pages/admin/roles/PermissionItemWrapper';

import {routes} from 'config/index';

interface RolesTableRowType {
    item: RoleTypes;
}

const RolesTableRow: FC<RolesTableRowType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {showAlert} = useContext(AlertContext);
    const {isSuperAdminPage} = useContext(RouteContext);
    const permissions = useContext(PermissionContext);

    const {
        title,
        requesterMode,
        isActive,
        isOrganizationEditEnabled,
        isOrganizationViewEnabled,
        isRoleViewEnabled,
        isRoleCreateEnabled,
        isRoleDeleteEnabled,
        isRoleEditEnabled,
        isUserViewEnabled,
        isUserCreateEnabled,
        isUserDeleteEnabled,
        isUserEditEnabled,
        isFormViewEnabled,
        isFormCreateEnabled,
        isFormDeleteEnabled,
        isFormEditEnabled,
        isTranslationViewEnabled,
        isTranslationDeleteEnabled,
        isTranslationEditEnabled,
        isInviteViewEnabled,
        isInviteCreateEnabled,
        isInviteDeleteEnabled,
        isCaseViewEnabled,
        isCaseEditEnabled,
        roleFilters,
        uuid,
    } = item;

    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [isRoleActive, setIsRoleActive] = useState<boolean>(isActive);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    const toggleRoleStatus = useCallback(
        data => dispatch({type: TOGGLE_ROLE_STATUS.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {organizationList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const deleteRole = useCallback(data => dispatch({type: DELETE_ROLE.REQUEST, payload: data}), [dispatch]);

    const onClickDelete = () => {
        deleteRole({
            uuid,
            callback: toggleModal,
            showAlert,
        });
    };

    const redirectToRole = () => {
        navigate(`${routes.ROLE}/${uuid}/edit`);
    };

    const handleChangeStatus = () => {
        toggleRoleStatus({
            uuid,
            showAlert,
            callback: () => setIsRoleActive(!isRoleActive),
        });
    };

    return (
        <TableRow>
            <TableCell width={300}>
                <TableBodyTypography text={title} />
            </TableCell>
            <TableCell>
                <PermissionItem
                    title={'orguser.roles.roleType.organization'}
                    hasView={isOrganizationViewEnabled}
                    hasUpdate={isOrganizationEditEnabled}
                />
                {(isSuperAdminPage || (myOrganization && myOrganization.isRequesterModeEnabled)) && (
                    <PermissionItemWrapper title={t('orguser.roles.parameters.requesterMode')}>
                        <Typography>{t(get(requesterModes, requesterMode, 'common.none'))}</Typography>
                    </PermissionItemWrapper>
                )}
                <PermissionItem
                    title={'orguser.roles.roleType.role'}
                    hasView={isRoleViewEnabled}
                    hasCreate={isRoleCreateEnabled}
                    hasUpdate={isRoleEditEnabled}
                    hasDelete={isRoleDeleteEnabled}
                />
                <PermissionItem
                    title={'orguser.roles.roleType.user'}
                    hasView={isUserViewEnabled}
                    hasCreate={isUserCreateEnabled}
                    hasUpdate={isUserEditEnabled}
                    hasDelete={isUserDeleteEnabled}
                />
                <PermissionItem
                    title={'orguser.roles.roleType.form'}
                    hasView={isFormViewEnabled}
                    hasCreate={isFormCreateEnabled}
                    hasUpdate={isFormEditEnabled}
                    hasDelete={isFormDeleteEnabled}
                    filterType={FILTER_FOR_FORMS_OPERATIONS}
                    roleFilters={roleFilters}
                />
                <PermissionItem
                    title={'orguser.roles.roleType.translation'}
                    hasView={isTranslationViewEnabled}
                    hasUpdate={isTranslationEditEnabled}
                    hasDelete={isTranslationDeleteEnabled}
                    filterType={FILTER_FOR_TRANSLATIONS_OPERATIONS}
                    roleFilters={roleFilters}
                />
                <PermissionItem
                    title={'orguser.roles.roleType.invites'}
                    hasView={isInviteViewEnabled}
                    hasCreate={isInviteCreateEnabled}
                    hasDelete={isInviteDeleteEnabled}
                    filterType={FILTER_FOR_INVITES_OPERATIONS}
                    roleFilters={roleFilters}
                />
                <PermissionItem
                    title={'orguser.roles.roleType.cases'}
                    hasView={isCaseViewEnabled}
                    hasUpdate={isCaseEditEnabled}
                    filterType={FILTER_FOR_CASES_OPERATIONS}
                    roleFilters={roleFilters}
                />
            </TableCell>
            {permissions.isRoleEditEnabled && (
                <TableCell width={100}>
                    {<CustomSwitcher checked={isRoleActive} onClick={handleChangeStatus} />}
                </TableCell>
            )}
            <TableCell width={100}>
                <IconButton
                    title={t('common.buttons.update')}
                    onClick={redirectToRole}
                    disabled={!permissions.isRoleEditEnabled}
                >
                    <EditSvgIcon isDisabled={!permissions.isRoleEditEnabled} />
                </IconButton>
                <DeleteAdminConfirmationButton
                    disabled={!permissions.isRoleDeleteEnabled}
                    isShowModal={isShowModal}
                    toggleModal={toggleModal}
                    onClickAction={onClickDelete}
                />
            </TableCell>
        </TableRow>
    );
};

export default RolesTableRow;
