import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const ScaleSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.5 11.5V10.5H12.5V3.5H5.5V6.5H4.5V3.5C4.5 3.23478 4.60536 2.98043 4.79289 2.79289C4.98043 2.60536 5.23478 2.5 5.5 2.5H12.5C12.7652 2.5 13.0196 2.60536 13.2071 2.79289C13.3946 2.98043 13.5 3.23478 13.5 3.5V10.5C13.5 10.7652 13.3946 11.0196 13.2071 11.2071C13.0196 11.3946 12.7652 11.5 12.5 11.5H9.5ZM3.5 8.5H6.5C6.76522 8.5 7.01957 8.60536 7.20711 8.79289C7.39464 8.98043 7.5 9.23478 7.5 9.5V12.5C7.5 12.7652 7.39464 13.0196 7.20711 13.2071C7.01957 13.3946 6.76522 13.5 6.5 13.5H3.5C3.23478 13.5 2.98043 13.3946 2.79289 13.2071C2.60536 13.0196 2.5 12.7652 2.5 12.5V9.5C2.5 9.23478 2.60536 8.98043 2.79289 8.79289C2.98043 8.60536 3.23478 8.5 3.5 8.5ZM3.5 9.5V12.5H6.5V9.5H3.5Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default ScaleSvgIcon;
