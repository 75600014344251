import React, {FC} from 'react';

import Box from '@mui/material/Box';

import {FormPopupTypes} from 'appRedux/actions/forms/types';

import {ContentItemType} from 'pages/client/form/partials/types';
import PopupContentRow from 'pages/client/form/fieldPopup/PopupContentRow';

interface PopupContentType {
    formId: number;
    pageId: number;
    sectionId: number;
    popup: FormPopupTypes;
    disabled: boolean;
    initialItems: ContentItemType[];
    onEditClick: (value: number | null) => void;
    handleChangePopupInformation: (pageId: number, sectionId: number, popupId: number, value: unknown) => void;
    onSaveClicked: (isCallAlert?: boolean) => void;
    selectedIndex: number | null;
}

const PopupContent: FC<PopupContentType> = ({
    formId,
    popup,
    pageId,
    sectionId,
    disabled,
    handleChangePopupInformation,
    initialItems,
    onEditClick,
    onSaveClicked,
    selectedIndex,
}) => {
    return (
        <Box>
            {initialItems.map((item: ContentItemType, index: number) => {
                return (
                    <PopupContentRow
                        key={`popup-${index}`}
                        pageId={pageId}
                        formId={formId}
                        sectionId={sectionId}
                        popup={popup}
                        currentIndex={index}
                        handleChangePopupInformation={handleChangePopupInformation}
                        initialItems={initialItems}
                        onEditClick={onEditClick}
                        disabled={disabled}
                        onSaveClicked={onSaveClicked}
                        isSelected={selectedIndex === index}
                    />
                );
            })}
        </Box>
    );
};

export default PopupContent;
