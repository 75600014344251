import React, {FC} from 'react';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import {WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';

import {TAB_FOR_ORG_USER} from 'components/Forms/Workflow/validation';

import WorkflowStatusTabContentSwitcher from 'pages/admin/updateWorkflow/partials/tabs/WorkflowStatusTabContentSwitcher';

interface WorkflowStatusTabType {
    item: WorkflowStatusItemTypes;
    tab: number | null;
    tabIndex: number;
    title: string;
    currentLanguage: string;
    onTabChange: (value: number) => void;
    closeForm: () => void;
}

const WorkflowStatusTab: FC<WorkflowStatusTabType> = ({
    item,
    tabIndex,
    tab,
    title,
    currentLanguage,
    onTabChange,
    closeForm,
}) => {
    const {colorRequester, color} = item;

    const isActive = tabIndex === tab;
    const currentColor = tabIndex === TAB_FOR_ORG_USER ? color : colorRequester;

    const blockStyles = !tab
        ? {
              borderRadius: 20,
          }
        : {
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              borderBottomLeftRadius: tab ? 0 : 8,
              borderBottomRightRadius: tab ? 0 : 8,
          };

    return (
        <Box
            sx={{
                ...blockStyles,
                position: 'relative',
                cursor: 'pointer',
                height: 40,
                backgroundColor: currentColor,
                mr: 1,
                pt: 2,
                pb: 2,
                flex: '1 1 10px',
                maxWidth: 180,
            }}
            onClick={() => onTabChange(tabIndex)}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    pl: 1,
                    pr: 1,
                }}
            >
                <Tooltip
                    title={title}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                fontSize: 16,
                                backgroundColor: 'rgb(97, 97, 97)',
                            },
                        },
                    }}
                >
                    <WorkflowStatusTabContentSwitcher
                        item={item}
                        tab={tab}
                        tabTitle={title}
                        isActive={isActive}
                        currentColor={currentColor}
                        currentLanguage={currentLanguage}
                        closeForm={closeForm}
                    />
                </Tooltip>
            </Box>
        </Box>
    );
};

export default WorkflowStatusTab;
