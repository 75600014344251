import React, {FC, ReactNode, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import {createStyles, withStyles, WithStyles} from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';

import CopySvgIcon from 'assets/icons/buttons/CopySvgIcon';

import {AdminContext} from 'contexts/admin/context';

import AddButton from 'components/AdminScreenComponents/AddButton';
import Pagination from 'components/AdminScreenComponents/Pagination';
import {TableFilterTypes} from 'components/AdminScreenComponents/types';
import {ItemsListTypes, getPagesNumber} from 'components/AdminScreenComponents/translationsHelper';
import ItemsNumberSelector from 'components/AdminScreenComponents/ItemsNumberSelector';
import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';
import EmptyArea from 'components/EmptyArea';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import {getPerPageForRequest} from 'components/BlockView/helper';

import {
    DEFAULT_PAGE,
    ITEMS_PER_PAGE_OPTIONS_DEFAULT,
    PARAMETER_PAGE,
    PARAMETER_PER_PAGE,
    PARAMETER_VIEW_MODE,
} from 'config/index';

const styles = () =>
    createStyles({
        tableRowsBlock: {
            marginBottom: 20,
            overflowX: 'auto',
        },
        settingsRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 40,
        },
    });

interface TableWrapperType extends WithStyles<typeof styles> {
    addRoute?: string;
    itemsTotalNumber?: number;
    itemsList: ItemsListTypes;
    itemsPerPageOptions?: number[];
    onCopyClicked?: () => void;
    onActivateClicked?: () => void;
    onDeactivateClicked?: () => void;
    children: ReactNode;
    filters?: TableFilterTypes[];
    selectAllCheckboxes?: (value?: boolean) => void;
    hasSearch?: boolean;
    hasCheckboxes?: boolean;
    hasSelectAllFiltered?: boolean;
    withoutFiltersPanel?: boolean;
    footerButtons?: ReactNode;
    leftSideComponent?: ReactNode;
    title?: string;
}

const DataGridWrapper: FC<TableWrapperType> = ({
    children,
    itemsTotalNumber,
    itemsList,
    addRoute,
    classes,
    onCopyClicked,
    onActivateClicked,
    onDeactivateClicked,
    filters,
    itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS_DEFAULT,
    selectAllCheckboxes,
    hasSearch,
    hasCheckboxes,
    hasSelectAllFiltered,
    withoutFiltersPanel,
    leftSideComponent,
    footerButtons,
    title,
}) => {
    const [t] = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const {selectedIds, itemsPerPage, currentPage, totalItems, setTotalItems} = useContext(AdminContext);

    const viewModeFromUrl = searchParams.get(PARAMETER_VIEW_MODE);
    const perPageFromUrl = searchParams.get(PARAMETER_PER_PAGE);

    const perPage = getPerPageForRequest(
        perPageFromUrl,
        viewModeFromUrl,
        itemsPerPage || itemsPerPageOptions?.[0] || ITEMS_PER_PAGE_OPTIONS_DEFAULT[0],
    );

    const totalItemsNumber = totalItems ?? itemsTotalNumber;

    const pagesNumber = getPagesNumber(itemsList, Number(perPage), totalItemsNumber);

    const [currentPageValue, setCurrentPageValue] = useState<number>(currentPage);

    const handleChangePage = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);
        (isNaN(value) || (value > 0 && value <= pagesNumber)) && setCurrentPageValue(value);
    };

    const handleSetCurrentPage = (page: number) => {
        setCurrentPageValue(page);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && currentPageValue > 0) {
            searchParams.set(PARAMETER_PAGE, String(currentPageValue));
            setSearchParams(searchParams);
        }
    };

    const onBLur = () => {
        searchParams.set(PARAMETER_PAGE, String(currentPageValue));
        setSearchParams(searchParams);
    };

    const wrappedFilters =
        filters &&
        filters.map((filter: TableFilterTypes) => {
            const {handleFilterChange} = filter;
            filter.handleFilterChange = e => {
                setCurrentPageValue(DEFAULT_PAGE);
                handleFilterChange(e);
            };
            return filter;
        });

    const isPaginationPresented = totalItemsNumber && Number(perPage) < totalItemsNumber;

    const isTableFooterPresented =
        (selectedIds && (onCopyClicked || onActivateClicked || onDeactivateClicked)) ||
        isPaginationPresented ||
        footerButtons;

    useEffect(() => {
        setTotalItems(null);
    }, []);

    return (
        <>
            {addRoute && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        marginBottom: 4,
                    }}
                >
                    <AddButton label={t('common.buttons.addNew')} link={addRoute} />
                </Box>
            )}
            <ClientSectionWrapper title={title}>
                {!withoutFiltersPanel && (
                    <ItemsNumberSelector
                        itemsList={itemsList}
                        itemsPerPage={Number(perPage)}
                        itemsPerPageOptions={itemsPerPageOptions}
                        currentPage={currentPage}
                        pagesNumber={pagesNumber}
                        hasSearch={hasSearch}
                        filters={wrappedFilters}
                        setCurrentPageValue={setCurrentPageValue}
                        hasCheckboxes={hasCheckboxes}
                        hasSelectAllFiltered={hasSelectAllFiltered}
                        selectAllCheckboxes={selectAllCheckboxes}
                        leftSideComponent={leftSideComponent}
                    />
                )}
                <Box className={classes.tableRowsBlock}>{children}</Box>
                {isTableFooterPresented ? (
                    <Box className={classes.settingsRow}>
                        {footerButtons}
                        {selectedIds && onCopyClicked && (
                            <AgentInverseButton
                                isDisabled={selectedIds.length === 0}
                                title={t('orguser.forms.copyForm')}
                                icon={<CopySvgIcon />}
                                onClick={onCopyClicked}
                            />
                        )}
                        {selectedIds && onActivateClicked && (
                            <AgentInverseButton
                                isDisabled={selectedIds.length === 0}
                                title={t('common.buttons.activateAll')}
                                onClick={onActivateClicked}
                            />
                        )}
                        {selectedIds && onDeactivateClicked && (
                            <AgentInverseButton
                                isDisabled={selectedIds.length === 0}
                                title={t('common.buttons.disableAll')}
                                onClick={onDeactivateClicked}
                            />
                        )}
                        <EmptyArea />
                        {isPaginationPresented ? (
                            <>
                                <Typography variant="body2" sx={{fontWeight: 300, lineHeight: '18px', mr: 2}}>
                                    {t('common.page')}&nbsp;
                                    <Input
                                        value={currentPageValue}
                                        type="number"
                                        sx={{width: '32px'}}
                                        inputProps={{
                                            min: DEFAULT_PAGE,
                                            max: pagesNumber,
                                            style: {textAlign: 'center'},
                                        }}
                                        onChange={handleChangePage}
                                        onKeyDown={handleKeyDown}
                                        onBlur={onBLur}
                                    />
                                    {t('common.of')}&nbsp;
                                    {String(pagesNumber)}
                                </Typography>
                                <Pagination pagesNumber={pagesNumber} setCurrentPage={handleSetCurrentPage} />
                            </>
                        ) : null}
                    </Box>
                ) : null}
            </ClientSectionWrapper>
        </>
    );
};

export default withStyles(styles)(DataGridWrapper);
