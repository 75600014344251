import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';

import WorkflowTasksListItem from 'pages/admin/updateWorkflow/partials/tasks/WorkflowTasksListItem';

interface WorkflowTasksListType {
    item: WorkflowStatusItemTypes;
}

const WorkflowTasksList: FC<WorkflowTasksListType> = ({item}) => {
    const [t] = useTranslation();

    const {tasks} = item;

    if (!tasks || (tasks && tasks.length === 0)) {
        return <Typography sx={{mt: 3, mb: 1}}>{t('orguser.workflows.tasks.noneTasks')}</Typography>;
    }

    return (
        <Box>
            <Typography sx={{fontWeight: 600, mb: 2}}>{`${t('orguser.workflows.tasks.tasksList')}:`}</Typography>
            {tasks &&
                tasks.map(task => {
                    return <WorkflowTasksListItem key={`tasks-${item.uuid}`} item={task} status={item} />;
                })}
        </Box>
    );
};

export default WorkflowTasksList;
