import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import ListSvgIcon from 'assets/icons/buttons/ListSvgIcon';

import {OrganizationPrivacyPolicyItemTypes} from 'appRedux/actions/organization/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import VersionsList from 'pages/admin/organizationPrivacyPolicy/VersionsList';

interface VersionsListButtonType {
    item: OrganizationPrivacyPolicyItemTypes;
}

const VersionsListButton: FC<VersionsListButtonType> = ({item}) => {
    const [t] = useTranslation();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    return (
        <>
            <IconButton
                sx={{mr: 1}}
                title={t('superadmin.organizations.privacyPolicy.versions')}
                onClick={() => setShowModal(true)}
                disabled={item.versions.length === 0}
            >
                <ListSvgIcon />
            </IconButton>
            <ModalWrapper
                isShowModal={showModal}
                isWideModal
                title={t('superadmin.organizations.privacyPolicy.versions')}
                toggleModal={toggleModal}
            >
                <VersionsList item={item} />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 2,
                    }}
                >
                    <AgentSaveButton
                        isSubmit={false}
                        title={t('common.buttons.close')}
                        onClick={() => setShowModal(false)}
                    />
                </Box>
            </ModalWrapper>
        </>
    );
};

export default VersionsListButton;
