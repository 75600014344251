import {LoginAttemptsStatisticItemTypes} from 'appRedux/actions/superadmin/types';

export const getAxisYInterval = (items: LoginAttemptsStatisticItemTypes[]) => {
    let maximal = 0;
    items.forEach(item => {
        if (item.qty > maximal) {
            maximal = item.qty;
        }
    });
    const result: number[] = [];

    let step = 1;
    if (maximal > 20) {
        step = 5;
    } else if (maximal > 50) {
        step = 10;
    } else if (maximal > 70) {
        step = 20;
    } else if (maximal > 100) {
        step = 25;
    }

    for (let i = 0; i < maximal + 1; i += step) {
        result.push(i);
    }

    return result;
};

export const isNoneAttemptFound = (items: LoginAttemptsStatisticItemTypes[]): boolean => {
    for (let i = 0, n = items.length; i < n; i++) {
        if (items[i].qty > 0) {
            return true;
        }
    }
    return false;
};
