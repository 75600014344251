import React, {FC, useCallback, useContext, useState} from 'react';
import {Link as RouterLink, useLocation, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Field, Form, Formik} from 'formik';
import get from 'lodash/get';
import i18n from 'i18next';

import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import Link from '@mui/material/Link';
import {SelectChangeEvent} from '@mui/material';

import {LOGIN} from 'appRedux/actions/auth';
import {GET_USER_INFO} from 'appRedux/actions/profile';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {CryptoContext} from 'contexts/crypto/context';
import {MediaContext} from 'contexts/media/context';

import LoadingStartButton from 'components/StartScreenComponents/LoadingStartButton';
import ClientFormikTextInput from 'components/StartScreenComponents/ClientFormTextInput';

import {loginInitialValues, loginValidationSchema} from 'pages/start/login/validation';
import {LOGIN_SECOND_STEP, getRedirectRoute} from 'pages/start/login/helper';

import {getFormKeyword, getParsedTranslatedLabel} from 'helpers/translationsHelper';

import {LOCAL_STORAGE_REDIRECT_AFTER_LOGIN} from 'services/localStorage';

import {routes} from 'config/index';
import {theme} from 'config/theme';

interface LoginFirstStepType {
    setEmail: (value: string) => void;
    setLoginStep: (value: string) => void;
}

const LoginFirstStep: FC<LoginFirstStepType> = ({setEmail, setLoginStep}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {form} = useParams();
    const {hash} = useLocation();

    const {showAlert} = useContext(AlertContext);
    const {regenerateKeysAfterLogin} = useContext(CryptoContext);
    const {setLanguageAfterLogin} = useContext(MediaContext);

    const {auth} = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const [showPassword, setShowPassword] = useState<boolean>(false);

    const toggleShowPassword = () => {
        setShowPassword(previous => !previous);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const loginRequest = useCallback(data => dispatch({type: LOGIN.REQUEST, payload: data}), [dispatch]);

    const getUserInfo = useCallback(data => dispatch({type: GET_USER_INFO.REQUEST, payload: data}), [dispatch]);

    const navigationCallback = () => {
        getUserInfo({
            callback: async (
                roles: string[],
                organizationMultiRoleType: number,
                languageId: number,
                defaultMode: number,
            ) => {
                setLanguageAfterLogin(languageId);
                const redirectAfterLogin = localStorage.getItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN);
                if (redirectAfterLogin) {
                    localStorage.removeItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN);
                    await regenerateKeysAfterLogin(redirectAfterLogin);
                } else {
                    await regenerateKeysAfterLogin(getRedirectRoute(roles, organizationMultiRoleType, defaultMode));
                }
            },
        });
    };

    const showTwoFactorForm = () => {
        setLoginStep(LOGIN_SECOND_STEP);
    };

    const redirectToCorrectSubdomain = (redirectTo: string) => {
        window.location.href = redirectTo;
    };

    const isEncryptInfo = get(auth, ['form', 'isEncryptInfo'], false);
    const formButtonText = get(auth, ['form', 'formButtonText'], '');
    const formInfo = get(auth, 'form', null);
    const formButtonKeyword = formInfo?.id ? getFormKeyword(formInfo?.id, 'formButtonText') : '';

    const setRedirectToForm = (
        caseId: number | null,
        formId: number | null,
        userHasDraft: boolean,
        formUrl: string | null,
    ) => {
        if (userHasDraft && formUrl) {
            localStorage.setItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN, `/${formUrl}`);
        } else if (caseId && formId) {
            const redirectTo = isEncryptInfo
                ? `${routes.REQUEST}/${caseId}/keysGenerate/${formId}`
                : `${routes.REQUEST}/${caseId}/form/${formId}`;
            localStorage.setItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN, redirectTo);
        }
    };

    return (
        <Box>
            <Formik
                initialValues={loginInitialValues}
                validationSchema={() => loginValidationSchema(t)}
                onSubmit={values => {
                    setEmail(values.username);
                    loginRequest({
                        ...values,
                        form,
                        invite: String(hash).replace('#', ''),
                        showAlert,
                        callback: navigationCallback,
                        callbackTwoFactor: showTwoFactorForm,
                        callbackRedirect: redirectToCorrectSubdomain,
                        callbackSetRedirect: setRedirectToForm,
                    });
                }}
            >
                {() => {
                    return (
                        <Form>
                            <Field
                                name="username"
                                data-id="input#login-username"
                                label={t('common.loginForm.emailOrUsername')}
                                placeholder={t('common.loginForm.emailOrUsername')}
                                component={ClientFormikTextInput}
                            />
                            <Field
                                name="password"
                                data-id="input#login-password"
                                label={t('common.loginForm.password')}
                                placeholder={t('common.loginForm.password')}
                                type={showPassword ? 'text' : 'password'}
                                component={ClientFormikTextInput}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                data-id="input#login-password-visibility"
                                                onClick={toggleShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    mt: 4,
                                    mb: 5,
                                }}
                            >
                                <Link
                                    to={routes.FORGET_NICKNAME}
                                    sx={{
                                        textDecoration: 'none',
                                        fontSize: 12,
                                        color: theme.palette.info.main,
                                    }}
                                    component={RouterLink}
                                >
                                    {`${t('common.loginForm.forgetNickname')}?`}
                                </Link>
                                <Link
                                    to={routes.RESET_PASSWORD}
                                    sx={{
                                        textDecoration: 'none',
                                        fontSize: 12,
                                        color: theme.palette.info.main,
                                    }}
                                    component={RouterLink}
                                    data-id="link#login-passwordreset"
                                >
                                    {`${t('common.loginForm.resetPasswordButton')}?`}
                                </Link>
                            </Box>
                            <LoadingStartButton
                                label={
                                    form || hash
                                        ? formButtonText
                                            ? getParsedTranslatedLabel(t, formButtonKeyword, formButtonText)
                                            : t('common.loginForm.requestHelp')
                                        : t('common.loginForm.login')
                                }
                                data-id="button#login-login"
                            />
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default LoginFirstStep;
