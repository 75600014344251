import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const RulesFilledSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" fill="white" fillOpacity="0.01" />
            <path
                d="M13 1H3C2.73488 1.0003 2.4807 1.10576 2.29323 1.29323C2.10576 1.4807 2.00031 1.73488 2 2V8.5C1.99892 9.49718 2.26942 10.4758 2.78246 11.3309C3.29551 12.186 4.03173 12.8852 4.9121 13.3535L8 15L11.0879 13.3535C11.9683 12.8852 12.7045 12.186 13.2175 11.3309C13.7306 10.4758 14.0011 9.49718 14 8.5V2C13.9997 1.73488 13.8942 1.4807 13.7068 1.29323C13.5193 1.10576 13.2651 1.0003 13 1ZM11.5 9H4.5V8H11.5V9ZM11.5 6H4.5V5H11.5V6Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default RulesFilledSvgIcon;
