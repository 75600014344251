import {isEmpty, get} from 'lodash';

import {ActivityElementType, RequesterCaseActivitiesTypes} from 'appRedux/actions/requestCase/types';
import {FormFieldTypes, FormPageTypes, FormPopupTypes, FormSectionTypes} from 'appRedux/actions/forms/types';

export type FieldsDifferenceType = {
    page: string;
    section: string;
    popup?: string;
    field?: string;
    oldValue?: string | null;
    newValue?: string | null;
    added?: string | null;
    removed?: string | null;
};

export type ActivityItemType = {
    [key: string]: string | ActivityItemType;
};

export const getResultsDifference = (oldObject: any, newObject: any): string | ActivityItemType => {
    const result: ActivityItemType = {};
    if (Object.is(oldObject, newObject)) {
        return {};
    }
    if (!newObject || typeof newObject !== 'object') {
        return newObject;
    }

    Object.keys(oldObject || {})
        .concat(Object.keys(newObject || {}))
        .forEach(key => {
            if (newObject[key] !== oldObject[key] && !Object.is(oldObject[key], newObject[key])) {
                result[key] = newObject[key];
            }
            if (typeof newObject[key] === 'object' && typeof oldObject[key] === 'object') {
                const value = getResultsDifference(oldObject[key], newObject[key]);
                if (value !== undefined) {
                    result[key] = value;
                }
            }
        });

    return result;
};

export const getFieldDifferences = (pages: FormPageTypes[], element: ActivityElementType): FieldsDifferenceType[] => {
    const oldObject = JSON.parse(element.oldValue) ?? {};
    const newObject = JSON.parse(element.newValue) ?? {};

    const oldValueDifference = getResultsDifference(newObject, oldObject);
    const newValueDifference = getResultsDifference(oldObject, newObject);

    const results: FieldsDifferenceType[] = [];

    pages.forEach((page: FormPageTypes) => {
        const sections = page.sections;
        sections.forEach((section: FormSectionTypes) => {
            const fields = section.fields;
            const popups = section.popups;

            fields.forEach((field: FormFieldTypes) => {
                const pageKeyword = `page-${page.id}`;
                const sectionKeyword = `section-${section.id}`;
                const fieldKeyword = `field-${field.id}`;

                const oldFieldValue = get(oldValueDifference, [pageKeyword, sectionKeyword, fieldKeyword], null);
                const newFieldValue = get(newValueDifference, [pageKeyword, sectionKeyword, fieldKeyword], null);
                if (oldFieldValue !== newFieldValue) {
                    results.push({
                        page: page.title,
                        section: section.title,
                        field: field.label,
                        oldValue: oldFieldValue,
                        newValue: newFieldValue,
                    });
                }
            });

            popups.forEach((popup: FormPopupTypes) => {
                const pageKeyword = `page-${page.id}`;
                const sectionKeyword = `section-${section.id}`;
                const popupKeyword = `popup-${popup.id}`;

                const popupFields = popup.fields;

                const oldPopupsArray = Object.values(
                    get(oldValueDifference, [pageKeyword, sectionKeyword, popupKeyword], []),
                );
                const newPopupsArray = Object.values(
                    get(newValueDifference, [pageKeyword, sectionKeyword, popupKeyword], []),
                );

                if (oldPopupsArray.pop() === undefined) {
                    results.push({
                        page: page.title,
                        section: section.title,
                        popup: popup.title,
                        added: getPopupRow(newPopupsArray.pop(), popupFields),
                    });
                } else if (newPopupsArray.pop() === undefined) {
                    results.push({
                        page: page.title,
                        section: section.title,
                        popup: popup.title,
                        removed: getPopupRow(oldPopupsArray.pop(), popupFields),
                    });
                } else {
                    const oldItem = getChangedPopupItem(oldPopupsArray);
                    const newItem = getChangedPopupItem(newPopupsArray);
                    results.push({
                        page: page.title,
                        section: section.title,
                        popup: popup.title,
                        field: getPopupField(newItem, popupFields),
                        oldValue: getPopupRow(oldItem, popupFields),
                        newValue: getPopupRow(newItem, popupFields),
                    });
                }
            });
        });
    });

    return results;
};

const getChangedPopupItem = (items: any[]) => {
    let result = {};
    items.forEach((item: any) => {
        if (!isEmpty(item)) {
            result = item;
        }
    });
    return result;
};

const getPopupField = (item: any, fields: FormFieldTypes[]) => {
    let result = '';
    fields.forEach((field: FormFieldTypes) => {
        const value = get(item, `field-${field.id}`, null);
        if (value) {
            result = field.label;
        }
    });
    return result;
};

const getPopupRow = (item: any, fields: FormFieldTypes[]) => {
    let result = '';
    fields.forEach((field: FormFieldTypes) => {
        const value = get(item, `field-${field.id}`, null);
        if (value) {
            result += `${field.label}: "${value}"; `;
        }
    });
    return result;
};

export const getSearchedActivities = (
    items: RequesterCaseActivitiesTypes[],
    search: string | null,
): RequesterCaseActivitiesTypes[] => {
    if (search && search.length >= 3) {
        return items.filter((item: RequesterCaseActivitiesTypes) => {
            const {person, element} = item;
            const searchLowerCase = search.toLowerCase();
            const parameter = get(element, 'parameter', '');
            const oldValue = get(element, 'oldValue', '');
            const newValue = get(element, 'newValue', '');
            return (
                (person && person.toLowerCase().includes(searchLowerCase)) ||
                String(parameter).toLowerCase().includes(searchLowerCase) ||
                String(oldValue).toLowerCase().includes(searchLowerCase) ||
                String(newValue).toLowerCase().includes(searchLowerCase)
            );
        });
    }
    return items;
};
