import React, {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Markdown from 'react-markdown';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {withStyles, WithStyles} from '@material-ui/styles';

import styles from 'components/TextWithShowAll/styles';

import {MINIMAL_HEIGHT_FOR_READ_MORE} from 'config/index';

interface MarkdownWithShowAllType extends WithStyles<typeof styles> {
    text: string;
    isTable?: boolean;
    ['data-e2e-id']?: string;
}

const MarkdownWithShowAll: FC<MarkdownWithShowAllType> = ({classes, text, isTable, ['data-e2e-id']: dataE2eId}) => {
    const [t] = useTranslation();
    const ref = useRef<HTMLDivElement>(null);

    const [showAll, setShowAll] = useState<boolean>(true);
    const [needShowAll, setNeedShowAll] = useState<boolean>(false);

    useEffect(() => {
        if (ref && ref.current) {
            if (ref.current.offsetHeight >= MINIMAL_HEIGHT_FOR_READ_MORE) {
                setNeedShowAll(true);
                setShowAll(false);
            }
        }
    }, [ref]);

    const toggleShowAll = () => {
        setShowAll(previous => !previous);
    };

    const descriptionShowAllClassname = isTable ? classes.descriptionMarkdownTable : classes.description;

    return (
        <Box sx={{mt: 1, mb: 1}}>
            <Box
                data-e2e-id={dataE2eId}
                ref={ref}
                className={showAll ? classes.descriptionShowAll : descriptionShowAllClassname}
                sx={{
                    '& p': {
                        fontSize: 14,
                        fontWeight: 300,
                        mt: 0,
                    },
                    '& ul': {
                        mt: 0,
                        fontSize: 14,
                        fontWeight: 300,
                    },
                    '& li': {
                        mt: 0,
                        fontSize: 14,
                        fontWeight: 300,
                    },
                }}
            >
                <Markdown>{text}</Markdown>
            </Box>
            {needShowAll && (
                <Typography
                    variant="body2"
                    data-e2e-id={showAll ? `${dataE2eId}-readLess` : `${dataE2eId}-continueReading`}
                    onClick={toggleShowAll}
                    sx={{mt: 1.5, cursor: 'pointer', fontWeight: 600}}
                >
                    {t(showAll ? 'common.readLess' : 'common.continueReading')}
                </Typography>
            )}
        </Box>
    );
};

export default withStyles(styles)(MarkdownWithShowAll);
