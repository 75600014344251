import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {GridColDef} from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {RoleTypes} from 'appRedux/actions/roles/types';
import {OrganizationUserListTypes} from 'appRedux/actions/organizationAdmin/types';

import {PermissionContext} from 'contexts/permission/context';
import {RouteContext} from 'contexts/route/context';

import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';
import UserRole from 'components/OrganizationAdminsList/UserRoles';
import EditOrganizationAdmin from 'components/OrganizationAdminsList/EditOrganizationAdmin';
import ResetAdminPassword from 'components/OrganizationAdminsList/ResetAdminPassword';
import UnlockOrganizationAdmin from 'components/OrganizationAdminsList/UnlockOrganizationAdmin';
import DeactivateTwoFactorButton from 'components/OrganizationAdminsList/DeactivateTwoFactorButton';
import DeleteOrganizationAdmin from 'components/OrganizationAdminsList/DeleteOrganizationAdmin';
import RelateAdminToOrganizationButton from 'components/OrganizationAdminsList/RelateAdminToOrganizationButton';

import {EMPTY_DATA} from 'config/index';

interface OrganizationAdminDataGridType {
    organizationAdminList: OrganizationUserListTypes[];
}

const OrganizationAdminDataGrid: FC<OrganizationAdminDataGridType> = ({organizationAdminList}) => {
    const [t] = useTranslation();

    const {isRoleViewEnabled} = useContext(PermissionContext);
    const {isSuperAdminPage} = useContext(RouteContext);

    const {
        admin: {organizationList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('superadmin.users.name'),
            minWidth: 150,
            flex: 0.5,
            valueGetter: ({row: {firstName, lastName}}) =>
                firstName && lastName ? `${lastName}, ${firstName}` : EMPTY_DATA,
        },
        {
            field: 'email',
            headerName: t('superadmin.users.email'),
            minWidth: 200,
        },
        {
            field: 'casesQty',
            headerName: t('superadmin.users.casesNumber'),
            minWidth: 70,
            flex: 0.5,
        },
        ...(isRoleViewEnabled
            ? [
                  {
                      field: 'roles',
                      headerName: t('superadmin.users.roles'),
                      minWidth: 200,
                      renderCell: ({value: roles}) => (
                          <Typography>
                              {roles && roles.length > 0
                                  ? roles.map((role: RoleTypes, index: number) => (
                                        <UserRole role={role} key={`role-${index}`} />
                                    ))
                                  : t('common.none')}
                          </Typography>
                      ),
                  } as GridColDef,
              ]
            : []),
        ...(myOrganization?.isForcedTwoFactor
            ? [
                  {
                      field: 'isTwoFactorActivated',
                      headerName: t('superadmin.users.twoFactorActivated'),
                      minWidth: 100,
                      valueGetter: ({value: isTwoFactorActivated}) =>
                          t(isTwoFactorActivated ? 'common.yes' : 'common.no'),
                  } as GridColDef,
              ]
            : []),
        {
            field: 'action',
            headerName: t('superadmin.users.action'),
            headerAlign: 'right',
            align: 'right',
            width: 120,
            renderCell: ({row: {item}}) => {
                const {
                    admin: {isTwoFactorActivated},
                } = item;
                return (
                    <>
                        <EditOrganizationAdmin item={item} />
                        <ResetAdminPassword item={item} />
                        {isSuperAdminPage && <RelateAdminToOrganizationButton item={item} />}
                        <UnlockOrganizationAdmin item={item} />
                        {isTwoFactorActivated && <DeactivateTwoFactorButton item={item} />}
                        <DeleteOrganizationAdmin item={item} />
                    </>
                );
            },
        },
    ];

    return (
        <CustomDataGrid
            rows={organizationAdminList}
            columns={columns}
            getRowId={row => row.id}
            checkboxSelection={false}
            enablePaging
            isLarge
        />
    );
};

export default OrganizationAdminDataGrid;
