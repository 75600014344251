import React, {FC, useCallback, useContext} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';
import {SEND_ORGANIZATIONS_STATISTIC} from 'appRedux/actions/organization';
import {OrganizationsStatisticSendRequestTypes} from 'appRedux/actions/organization/types';

import {AlertContext} from 'contexts/alert/context';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import {validationSchema} from 'components/Forms/SendOrganizationStatisticForm/validation';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface SendOrganizationStatisticFormType {
    closeModal: () => void;
}

const SendOrganizationStatisticForm: FC<SendOrganizationStatisticFormType> = ({closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const schema = () => validationSchema(t);

    const {showAlert} = useContext(AlertContext);

    const {
        admin: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const sendOrganizationStatistic = useCallback(
        data => dispatch({type: SEND_ORGANIZATIONS_STATISTIC.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: OrganizationsStatisticSendRequestTypes) => {
        sendOrganizationStatistic({
            ...values,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <Formik
            initialValues={{
                email: '',
            }}
            validationSchema={schema}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<OrganizationsStatisticSendRequestTypes>) => {
                return (
                    <Form>
                        <Field
                            name="email"
                            label={t('superadmin.dashboard.email')}
                            type="email"
                            component={FormikTextInput}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton isLoading={isLoading} isSubmit title={'common.buttons.send'} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default SendOrganizationStatisticForm;
