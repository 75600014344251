import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate, useSearchParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import AttachSvgIcon from 'assets/icons/small/AttachSvgIcon';
import DownloadSvgIcon from 'assets/icons/buttons/DownloadSvgIcon';
import MessagesSvgIcon from 'assets/icons/menu/MessagesSvgIcon';

import {GET_FILE_URL} from 'appRedux/actions/profile';
import {CHAT_DOCUMENT_URL} from 'appRedux/actions/requestChat';

import {RouteContext} from 'contexts/route/context';
import {CaseKeyContext} from 'contexts/caseKey/context';

import EmptyArea from 'components/EmptyArea';
import DeleteFileButton from 'components/FilesListWithGallery/DeleteFileButton';

import {CommonFileType, FILE_CHAT} from 'pages/agent/requesterPage/partials/helper';

import {getEncryptedFileData} from 'helpers/cryptoApiHelper';

import {theme} from 'config/theme';
import {routes, PARAMETER_PAGE_ID, PARAMETER_MESSAGE_ID, PARAMETER_SECTION_ID} from 'config/index';
import ThumbItem, {ThumbSize} from 'components/ClientScreenComponents/ThumbItem';

interface FileListItemType {
    file: CommonFileType;
    caseId: number;
    downloadFile: (url: string, fileName?: string) => void;
    getModalContent: (file: CommonFileType) => void;
    toggleIsModalOpened: () => void;
    deleteNotPossible?: boolean;
    isAttachedFilesList?: boolean;
    sectionId?: number;
}

const FileListItem: FC<FileListItemType> = ({
    file,
    caseId,
    getModalContent,
    deleteNotPossible,
    downloadFile,
    toggleIsModalOpened,
    isAttachedFilesList,
    sectionId,
}) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const {isClientMode} = useContext(RouteContext);
    const {unwrappedCaseKey} = useContext(CaseKeyContext);

    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState<boolean>(false);

    const toggleDeleteModalOpened = () => {
        setIsDeleteModalOpened(previous => !previous);
    };

    const getFileUrl = useCallback(data => dispatch({type: GET_FILE_URL.REQUEST, payload: data}), [dispatch]);

    const getChatFileUrl = useCallback(data => dispatch({type: CHAT_DOCUMENT_URL.REQUEST, payload: data}), [dispatch]);

    const {type, title, extension, uuid, pageId, thumbLink, fieldSectionId, encryptPrefix} = file;

    const onPreviewClicked = (file: CommonFileType) => {
        toggleIsModalOpened();
        getModalContent(file);
    };

    const downloadEncryptedFile = async (url: string) => {
        if (encryptPrefix) {
            const decryptedFileSource = await getEncryptedFileData(url, encryptPrefix, unwrappedCaseKey);
            if (decryptedFileSource) {
                downloadFile(decryptedFileSource, `${title}.${extension}`);
            }
        }
    };

    const redirectToChatMessage = () => {
        navigate(`${routes.REQUESTER_CASE}/${caseId}/chat?${PARAMETER_MESSAGE_ID}=${uuid}`);
    };

    const redirectToFormField = () => {
        if (pageId && fieldSectionId) {
            searchParams.set(PARAMETER_PAGE_ID, String(pageId));
            searchParams.set(PARAMETER_SECTION_ID, String(fieldSectionId));
            setSearchParams(searchParams);
        }
    };

    const handleDownloadFile = async () => {
        const getFileAction = type === FILE_CHAT ? getChatFileUrl : getFileUrl;
        getFileAction({
            uuid,
            callback: encryptPrefix ? await downloadEncryptedFile : downloadFile,
        });
    };

    const [thumbSrc, setThumbSrc] = useState<string | null | undefined>();

    useEffect(() => {
        const downloadEncryptedThumb = async (url: string) => {
            return await getEncryptedFileData(url, 'data:image/jpeg;base64,', unwrappedCaseKey);
        };

        const getThumbLink = async () => {
            if (thumbLink) return unwrappedCaseKey ? downloadEncryptedThumb(thumbLink) : thumbLink;
            return '';
        };

        getThumbLink().then(link => {
            setThumbSrc(link);
        });
    }, [thumbLink]);

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
            {!isAttachedFilesList && (
                <Box sx={{mb: 1}}>
                    <ThumbItem
                        uuid={uuid}
                        currentFile={file}
                        selectFile={onPreviewClicked}
                        downloadFile={handleDownloadFile}
                        isUserOwner={isClientMode && !deleteNotPossible}
                        thumbSrc={thumbSrc}
                        thumbSize={ThumbSize.SMALL}
                    />
                </Box>
            )}
            {!isAttachedFilesList && <AttachSvgIcon color={theme.palette.primary.main} />}
            <Typography
                onClick={() => onPreviewClicked(file)}
                sx={{
                    ml: isAttachedFilesList ? 'initial' : 1,
                    fontWeight: 300,
                    cursor: 'pointer',
                    color: theme.palette.primary.main,
                }}
            >
                {title}
            </Typography>
            <EmptyArea />
            {isClientMode && !deleteNotPossible && (
                <DeleteFileButton
                    file={file}
                    isModalOpened={isDeleteModalOpened}
                    setIsDeleteModalOpened={setIsDeleteModalOpened}
                    toggleIsModalOpened={toggleDeleteModalOpened}
                    sectionId={sectionId}
                />
            )}
            {isAttachedFilesList ? (
                <IconButton onClick={type === FILE_CHAT ? redirectToChatMessage : redirectToFormField}>
                    {type === FILE_CHAT ? <MessagesSvgIcon isActive={false} /> : <AttachSvgIcon />}
                </IconButton>
            ) : (
                <IconButton onClick={handleDownloadFile}>
                    <DownloadSvgIcon />
                </IconButton>
            )}
        </Box>
    );
};

export default FileListItem;
