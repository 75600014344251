import {fork, put, take, call} from 'redux-saga/effects';
import get from 'lodash/get';

import {http} from 'services/http';

import {
    ChatMessageTypes,
    ChatMessagesRequestTypes,
    ChatUploadDocumentRequestTypes,
    ChatUploadDocumentResponseTypes,
    ChatMessageRequestTypes,
    ChatDocumentResponseType,
    ChatContactTypes,
    ChatAdditionalMessagesRequestTypes,
    ChatMessagesResponseTypes,
    ChatMessageDeleteResponseTypes,
    ChatMessageUpdateRequestTypes,
    ChatMessageUpdateResponseTypes,
} from 'appRedux/actions/requestChat/types';
import {
    GET_CHAT_MESSAGES,
    UPLOAD_CHAT_DOCUMENT,
    DELETE_CHAT_MESSAGE,
    CHAT_DOCUMENT_URL,
    CHAT_AGENT_CONTACTS,
    getChatMessages,
    uploadChatDocument,
    chatMessageDelete,
    chatDocumentUrl,
    getAgentChatContacts,
    GET_PREV_CHAT_MESSAGES,
    GET_NEW_CHAT_MESSAGES,
    getPrevChatMessages,
    getNewChatMessages,
    UPDATE_CHAT_MESSAGE,
    chatMessageUpdate,
} from 'appRedux/actions/requestChat';

import {ALERT_TYPE_ERROR} from 'config/index';
import {ERROR_INFECTED_FILE} from 'config/errors';

function* watchGetChatMessagesList() {
    while (true) {
        const {
            payload: {id},
        }: IActionType<ChatMessagesRequestTypes> = yield take(GET_CHAT_MESSAGES.REQUEST);

        try {
            const data: ChatMessagesResponseTypes = yield call(http, `chat/${id}/messages`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getChatMessages.success(data));
            } else {
                yield put(getChatMessages.error({message: 'Something went wrong'}));
            }
        } catch (e) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            yield put(getChatMessages.error(e.toString()));
        }
    }
}

function* watchPrevChatMessagesList() {
    while (true) {
        const {
            payload: {id, lastMessageUuid},
        }: IActionType<ChatAdditionalMessagesRequestTypes> = yield take(GET_PREV_CHAT_MESSAGES.REQUEST);

        try {
            const data: ChatMessagesResponseTypes = yield call(http, `chat/${id}/prev/${lastMessageUuid}`, {
                method: 'GET',
            });

            if (data.success) {
                yield put(getPrevChatMessages.success(data));
            } else {
                yield put(getPrevChatMessages.error({message: 'Something went wrong'}));
            }
        } catch (e) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            yield put(getPrevChatMessages.error(e.toString()));
        }
    }
}

function* watchNewChatMessagesList() {
    while (true) {
        const {
            payload: {id, lastMessageUuid},
        }: IActionType<ChatAdditionalMessagesRequestTypes> = yield take(GET_NEW_CHAT_MESSAGES.REQUEST);

        try {
            const data: ChatUploadDocumentResponseTypes = yield call(http, `chat/${id}/next/${lastMessageUuid}`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getNewChatMessages.success(data.messages));
            } else {
                yield put(getNewChatMessages.error({message: 'Something went wrong'}));
            }
        } catch (e) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            yield put(getNewChatMessages.error(e.toString()));
        }
    }
}

function* watchNewChatMessagesListBackground() {
    while (true) {
        const {
            payload: {id, lastMessageUuid, callback},
        }: IActionType<ChatAdditionalMessagesRequestTypes> = yield take(GET_NEW_CHAT_MESSAGES.REQUEST_BACKGROUND);

        try {
            const data: ChatUploadDocumentResponseTypes = yield call(http, `chat/${id}/next/${lastMessageUuid}`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getNewChatMessages.success(data.messages));
                callback && callback();
            } else {
                yield put(getNewChatMessages.error({message: 'Something went wrong'}));
            }
        } catch (e) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            yield put(getNewChatMessages.error(e.toString()));
        }
    }
}

function* watchChatDocumentUploading() {
    while (true) {
        const {
            payload: {
                newestMessageId,
                caseId,
                file,
                fileName,
                thumb,
                text,
                encryptPrefix,
                section,
                setErrors,
                callback,
                callbackInfectedFileError,
                showAlert,
            },
        }: IActionType<ChatUploadDocumentRequestTypes> = yield take(UPLOAD_CHAT_DOCUMENT.REQUEST);
        try {
            const formData = new FormData();
            if (file) {
                formData.append('media', file, fileName);
            }
            if (thumb) formData.append('thumb', thumb);
            if (encryptPrefix) {
                formData.append('encryptPrefix', encryptPrefix);
            }
            if (section) {
                formData.append('section', String(section));
            }
            formData.append('text', text);
            formData.append('lastMessageId', newestMessageId);
            const data: ChatUploadDocumentResponseTypes = yield call(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                http,
                `chat/document/${caseId}`,
                {
                    method: 'POST',
                    body: formData,
                },
            );

            const infectedFileMessage = get(data, ['errors', 'message'], null);

            if (data.success) {
                const messages: ChatMessageTypes = get(data, 'messages', []);
                yield put(uploadChatDocument.success(messages));
                callback && callback();
            } else if (infectedFileMessage) {
                setErrors &&
                    setErrors({
                        file: ERROR_INFECTED_FILE,
                    });
                const attempts = get(data, ['errors', 'attempts'], 0);
                callbackInfectedFileError && callbackInfectedFileError(attempts ? Number(attempts) : null);
                yield put(uploadChatDocument.error({message: ERROR_INFECTED_FILE}));
                showAlert && showAlert(ALERT_TYPE_ERROR, ERROR_INFECTED_FILE);
            } else {
                setErrors && setErrors(data.errors);
                yield put(uploadChatDocument.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(uploadChatDocument.error({message: String(e)}));
        }
    }
}

function* watchChatMessageDeleting() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert, setErrors},
        }: IActionType<ChatMessageRequestTypes> = yield take(DELETE_CHAT_MESSAGE.REQUEST);
        try {
            const data: ChatMessageDeleteResponseTypes = yield call(http, `chat/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(chatMessageDelete.success(data.removedUuid));
                callback && callback();
            } else {
                setErrors && setErrors(data.errors);
                yield put(chatMessageDelete.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(chatMessageDelete.error({message: String(e)}));
        }
    }
}

function* watchChatMessageUpdating() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert, ...values},
        }: IActionType<ChatMessageUpdateRequestTypes> = yield take(UPDATE_CHAT_MESSAGE.REQUEST);
        try {
            const data: ChatMessageUpdateResponseTypes = yield call(http, `chat/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });

            if (data.success) {
                yield put(chatMessageUpdate.success(data.updatedMessage));
                callback && callback();
            } else {
                yield put(chatMessageUpdate.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(chatMessageUpdate.error({message: String(e)}));
        }
    }
}

function* watchChatDocumentUrlGetting() {
    while (true) {
        const {
            payload: {uuid, callback},
        }: IActionType<ChatMessageRequestTypes> = yield take(CHAT_DOCUMENT_URL.REQUEST);
        try {
            const data: ChatDocumentResponseType = yield call(http, `chat/${uuid}/download`, {
                method: 'GET',
            });
            if (data.fileLink) {
                yield put(chatDocumentUrl.success(data));
                callback && callback(data.fileLink);
            } else {
                yield put(chatDocumentUrl.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(chatDocumentUrl.error({message: String(e)}));
        }
    }
}

function* watchGetChatContacts() {
    while (true) {
        yield take(CHAT_AGENT_CONTACTS.REQUEST);
        try {
            const data: ChatContactTypes[] = yield call(http, `chat/contacts`, {
                method: 'GET',
            });
            if (data) {
                yield put(getAgentChatContacts.success(data));
            } else {
                yield put(getAgentChatContacts.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(chatDocumentUrl.error({message: String(e)}));
        }
    }
}

function* watchGetChatContactsBackground() {
    while (true) {
        yield take(CHAT_AGENT_CONTACTS.REQUEST_BACKGROUND);
        try {
            const data: ChatContactTypes[] = yield call(http, `chat/contacts`, {
                method: 'GET',
            });
            if (data) {
                yield put(getAgentChatContacts.success(data));
            } else {
                yield put(getAgentChatContacts.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(chatDocumentUrl.error({message: String(e)}));
        }
    }
}

export default [
    fork(watchGetChatMessagesList),
    fork(watchPrevChatMessagesList),
    fork(watchNewChatMessagesList),
    fork(watchNewChatMessagesListBackground),
    fork(watchChatDocumentUploading),
    fork(watchChatMessageDeleting),
    fork(watchChatMessageUpdating),
    fork(watchChatDocumentUrlGetting),
    fork(watchGetChatContacts),
    fork(watchGetChatContactsBackground),
];
