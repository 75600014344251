import {
    InstanceSettingBasic,
    InstanceSettingRequestType,
    InstanceSettingUploadRequestType,
    InstanceSettingFileRequestType,
    InstanceSettingFileResponseType,
    InstanceSettingsFile,
    InstanceSettingDeleteFileRequestType,
} from 'appRedux/actions/instance/types';

import {requestTypeCreator} from 'helpers/index';

export const INSTANCE_SETTINGS_LIST = requestTypeCreator('INSTANCE_SETTINGS_LIST');
export const INSTANCE_SETTING_UPDATE = requestTypeCreator('INSTANCE_SETTING_UPDATE');
export const INSTANCE_SETTING_UPLOAD = requestTypeCreator('INSTANCE_SETTING_UPLOAD');
export const INSTANCE_SETTING_FILES = requestTypeCreator('INSTANCE_SETTING_FILES');
export const INSTANCE_SETTING_DELETE_FILE = requestTypeCreator('INSTANCE_SETTING_DELETE_FILE');

export const INSTANCE_SETTING_UPLOAD_FAVICON = requestTypeCreator('INSTANCE_SETTING_UPLOAD_FAVICON');
export const INSTANCE_SETTING_DELETE_FAVICON = requestTypeCreator('INSTANCE_SETTING_DELETE_FAVICON');

export const getInstanceSettings = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: INSTANCE_SETTINGS_LIST.ERROR,
    }),
    request: () => ({
        type: INSTANCE_SETTINGS_LIST.REQUEST,
    }),
    success: (payload: InstanceSettingBasic[]): IActionType<InstanceSettingBasic[]> => ({
        payload,
        type: INSTANCE_SETTINGS_LIST.SUCCESS,
    }),
};

export const updateInstanceSetting = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: INSTANCE_SETTING_UPDATE.ERROR,
    }),
    request: (payload: InstanceSettingRequestType): IActionType<InstanceSettingRequestType> => ({
        payload,
        type: INSTANCE_SETTING_UPDATE.REQUEST,
    }),
    success: (payload: InstanceSettingBasic[]): IActionType<InstanceSettingBasic[]> => ({
        payload,
        type: INSTANCE_SETTING_UPDATE.SUCCESS,
    }),
};

export const uploadInstanceSetting = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: INSTANCE_SETTING_UPLOAD.ERROR,
    }),
    request: (payload: InstanceSettingUploadRequestType): IActionType<InstanceSettingUploadRequestType> => ({
        payload,
        type: INSTANCE_SETTING_UPLOAD.REQUEST,
    }),
    success: (payload: InstanceSettingFileResponseType): IActionType<InstanceSettingFileResponseType> => ({
        payload,
        type: INSTANCE_SETTING_UPLOAD.SUCCESS,
    }),
};

export const getInstanceSettingFiles = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: INSTANCE_SETTING_FILES.ERROR,
    }),
    request: (payload: InstanceSettingFileRequestType): IActionType<InstanceSettingFileRequestType> => ({
        payload,
        type: INSTANCE_SETTING_FILES.REQUEST,
    }),
    success: (payload: InstanceSettingsFile[]): IActionType<InstanceSettingsFile[]> => ({
        payload,
        type: INSTANCE_SETTING_FILES.SUCCESS,
    }),
};

export const deleteInstanceSettingFile = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: INSTANCE_SETTING_DELETE_FILE.ERROR,
    }),
    request: (payload: InstanceSettingDeleteFileRequestType): IActionType<InstanceSettingDeleteFileRequestType> => ({
        payload,
        type: INSTANCE_SETTING_DELETE_FILE.REQUEST,
    }),
    success: (payload: InstanceSettingsFile[]): IActionType<InstanceSettingsFile[]> => ({
        payload,
        type: INSTANCE_SETTING_DELETE_FILE.SUCCESS,
    }),
};

export const uploadInstanceSettingFavicon = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: INSTANCE_SETTING_UPLOAD_FAVICON.ERROR,
    }),
    request: (payload: InstanceSettingUploadRequestType): IActionType<InstanceSettingUploadRequestType> => ({
        payload,
        type: INSTANCE_SETTING_UPLOAD_FAVICON.REQUEST,
    }),
    success: (payload: InstanceSettingFileResponseType): IActionType<InstanceSettingFileResponseType> => ({
        payload,
        type: INSTANCE_SETTING_UPLOAD_FAVICON.SUCCESS,
    }),
};

export const deleteInstanceSettingFavicon = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: INSTANCE_SETTING_DELETE_FAVICON.ERROR,
    }),
    request: (payload: InstanceSettingDeleteFileRequestType): IActionType<InstanceSettingDeleteFileRequestType> => ({
        payload,
        type: INSTANCE_SETTING_DELETE_FAVICON.REQUEST,
    }),
    success: (payload: InstanceSettingsFile[]): IActionType<InstanceSettingsFile[]> => ({
        payload,
        type: INSTANCE_SETTING_DELETE_FAVICON.SUCCESS,
    }),
};
