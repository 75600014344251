import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import AllCasesSvgIcon from 'assets/icons/buttons/AllCasesSvgIcon';
import ListSvgIcon from 'assets/icons/buttons/ListSvgIcon';

import {RootReducer} from 'appRedux/reducers';

import {theme} from 'config/theme';
import {routes} from 'config/index';

interface PagesMobileMenuType {
    redirectToLink: (value: string) => void;
}

const PagesMobileMenu: FC<PagesMobileMenuType> = ({redirectToLink}) => {
    const [t] = useTranslation();

    const {
        auth: {organization},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isShowFormsList = get(organization, 'isShowFormsList', false);

    const mainMenuItems = [
        {
            visible: true,
            icon: AllCasesSvgIcon,
            label: t('common.menu.myCases'),
            onClickAction: () => redirectToLink(routes.REQUESTS_LIST),
        },
        {
            visible: isShowFormsList,
            icon: ListSvgIcon,
            label: t('common.menu.forms'),
            onClickAction: () => redirectToLink(routes.FORMS_LIST_REQUESTER),
        },
    ];

    return (
        <Box>
            {mainMenuItems.map(item => {
                const Icon = item.icon;
                if (!item.visible) {
                    return null;
                }
                return (
                    <Box
                        key={`mainMenu-${item.label}`}
                        onClick={item.onClickAction}
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton sx={{width: 40, height: 40}}>
                            <Icon isActive />
                        </IconButton>
                        <Typography
                            sx={{
                                ml: 1,
                                color: theme.palette.background.default,
                                fontWeight: 600,
                            }}
                        >
                            {item.label}
                        </Typography>
                    </Box>
                );
            })}
        </Box>
    );
};

export default PagesMobileMenu;
