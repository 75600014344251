import React from 'react';

const ChatReadSvgIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 11.75L1 8.27L1.795 7.5L4.5 10.175L10.205 4.5L11 5.29L4.5 11.75Z" fill="#07D889" />
            <path d="M9 11.75L7.5 10.27L8.295 9.5L9 10.175L14.705 4.5L15.5 5.29L9 11.75Z" fill="#07D889" />
        </svg>
    );
};

export default ChatReadSvgIcon;
