import React, {FC} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {RequesterDocumentResponseType} from 'appRedux/actions/profile/types';
import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import FilesListWithGallery from 'components/FilesListWithGallery/FilesListWithGallery';
import {getFormFieldFilesForGallery} from 'components/FilesListWithGallery/helper';

import InformationWrapper from 'pages/agent/requesterPage/wrappers/InformationWrapper';
import {CommonFileType} from 'pages/agent/requesterPage/partials/helper';

import {getFormTranslatedLabel} from 'helpers/translationsHelper';

interface UploadedFilesContentType {
    formId: number;
    keywordTitle: string;
    keywordDescription: string;
    field: FormFieldTypes;
    files: RequesterDocumentResponseType[];
    sectionId: number;
}

const UploadedFilesContent: FC<UploadedFilesContentType> = ({
    formId,
    field,
    keywordTitle,
    keywordDescription,
    files,
    sectionId,
}) => {
    const {caseId} = useParams();

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const uploadedFiles = files.filter(
        (file: RequesterDocumentResponseType) => file.formField === `form-${formId}-field-${field.id}`,
    );

    const allFiles: CommonFileType[] = getFormFieldFilesForGallery(uploadedFiles);

    return (
        <InformationWrapper
            title={getFormTranslatedLabel(translations, keywordTitle, field.label)}
            description={getFormTranslatedLabel(translations, keywordDescription, field.description)}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    width: '100%',
                    mb: 2,
                    gap: 2,
                }}
            >
                <FilesListWithGallery caseId={Number(caseId)} allFiles={allFiles} sectionId={sectionId} />
            </Box>
        </InformationWrapper>
    );
};

export default UploadedFilesContent;
