import React, {FC, useEffect, useState} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {FilterOptionType} from 'components/AdminScreenComponents/types';

import {ContentItemType} from 'pages/client/form/partials/types';

import {preventSendForm} from 'helpers/index';

import {DEFAULT_FILTER_OPTION} from 'config/index';

interface FilterPropsTypes {
    optionList: FilterOptionType[];
    handleFilterChange: any;
    parameter: string;
    filterName?: string;
    filterLabel?: string;
    defaultValue?: string;
    isFormField?: boolean;
}

const Filter: FC<FilterPropsTypes> = ({
    optionList,
    defaultValue,
    handleFilterChange,
    filterName,
    filterLabel,
    parameter,
    isFormField,
}) => {
    const [t] = useTranslation();
    const [searchParams] = useSearchParams();

    const defaultFilterValue = String(defaultValue) ?? DEFAULT_FILTER_OPTION;

    const [filterValue, setFilterValue] = useState<string>(
        searchParams.get(parameter) ? String(searchParams.get(parameter)) : defaultFilterValue,
    );

    useEffect(() => {
        setFilterValue(searchParams.get(parameter) || defaultFilterValue);
    }, [searchParams]);

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2, minWidth: 200}}>
            <Formik
                initialValues={{
                    [String(filterName)]: filterValue,
                }}
                onSubmit={() => {
                    return;
                }}
            >
                {(formik: FormikProps<ContentItemType>) => {
                    return (
                        <Form onKeyDown={preventSendForm} style={{width: '100%'}}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel>
                                    <Typography sx={{textTransform: 'capitalize'}}>
                                        {t(isFormField && filterLabel ? filterLabel : parameter)}
                                    </Typography>
                                </InputLabel>
                                <Select
                                    name={filterName}
                                    label={filterLabel}
                                    onChange={handleFilterChange}
                                    value={filterValue}
                                    IconComponent={ExpandMoreIcon}
                                >
                                    {optionList &&
                                        optionList.map(({label, name}: FilterOptionType) => (
                                            <MenuItem value={name} key={`item-${name}`}>
                                                <Typography>{t(label)}</Typography>
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default Filter;
