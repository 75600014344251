import React, {FC} from 'react';

const FormPendingSvgIcon: FC<{color?: string}> = ({color}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.16652 20.7304C7.89323 21.8842 9.9233 22.5 12 22.5C14.7838 22.4969 17.4527 21.3896 19.4212 19.4212C21.3896 17.4527 22.4969 14.7838 22.5 12C22.5 9.9233 21.8842 7.89323 20.7304 6.16652C19.5767 4.4398 17.9368 3.09399 16.0182 2.29927C14.0996 1.50455 11.9884 1.29661 9.95156 1.70176C7.91476 2.1069 6.04383 3.10693 4.57538 4.57538C3.10693 6.04383 2.1069 7.91476 1.70176 9.95156C1.29661 11.9884 1.50455 14.0996 2.29927 16.0182C3.09399 17.9368 4.4398 19.5767 6.16652 20.7304ZM6.99987 4.51678C8.47992 3.52785 10.22 3 12 3C14.3861 3.00272 16.6737 3.95181 18.361 5.63905C20.0482 7.32629 20.9973 9.61389 21 12C21 13.78 20.4722 15.5201 19.4832 17.0001C18.4943 18.4802 17.0887 19.6337 15.4442 20.3149C13.7996 20.9961 11.99 21.1743 10.2442 20.8271C8.49836 20.4798 6.89472 19.6226 5.63604 18.364C4.37737 17.1053 3.5202 15.5016 3.17294 13.7558C2.82567 12.01 3.0039 10.2004 3.68509 8.55585C4.36628 6.91132 5.51983 5.50571 6.99987 4.51678ZM8.25 12C8.25 12.8284 7.57843 13.5 6.75 13.5C5.92158 13.5 5.25 12.8284 5.25 12C5.25 11.1716 5.92158 10.5 6.75 10.5C7.57843 10.5 8.25 11.1716 8.25 12ZM18.75 12C18.75 12.8284 18.0784 13.5 17.25 13.5C16.4216 13.5 15.75 12.8284 15.75 12C15.75 11.1716 16.4216 10.5 17.25 10.5C18.0784 10.5 18.75 11.1716 18.75 12ZM12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z"
                fill={color ?? '#222222'}
                fillOpacity="0.6"
            />
        </svg>
    );
};

export default FormPendingSvgIcon;
