import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {RESEND_EMAIL, GET_USER_INFO} from 'appRedux/actions/profile';
import {RootReducer} from 'appRedux/reducers';

import {MediaContext} from 'contexts/media/context';
import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {theme} from 'config/theme';

const EmailNotVerified: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {isMobile} = useContext(MediaContext);
    const {showAlert} = useContext(AlertContext);

    const resendEmailAction = useCallback(data => dispatch({type: RESEND_EMAIL.REQUEST, payload: data}), [dispatch]);
    const getUserInfo = useCallback(data => dispatch({type: GET_USER_INFO.REQUEST, payload: data}), [dispatch]);

    const {
        profile: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onSendAgainClicked = () => {
        resendEmailAction({
            showAlert,
        });
    };

    useEffect(() => {
        getUserInfo({});
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                backgroundColor: theme.palette.secondary.dark,
                minHeight: 56,
                p: 1,
            }}
        >
            {isLoading ? (
                <Typography sx={{color: theme.palette.info.main}}>{t('common.pleaseWait')}</Typography>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography sx={{color: theme.palette.info.main}}>
                        {`${t('messages.warning.verifyEmailToAllowUploads')}.`}
                    </Typography>

                    <AgentSaveButton
                        data-id="button#send-again"
                        isSubmit={false}
                        title={t('common.buttons.sendItAgain')}
                        onClick={onSendAgainClicked}
                        sx={{mb: 0, ml: 2}}
                    />
                </Box>
            )}
        </Box>
    );
};

export default EmailNotVerified;
