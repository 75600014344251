import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {WorkflowStatusTranslationItemTypes} from 'appRedux/actions/workflow/types';

import {getLanguageIdByName} from 'helpers/translationsHelper';

export const isStatusTranslationPresented = (
    languageList: LanguageItemTypes[],
    currentLanguage: string,
    translations: WorkflowStatusTranslationItemTypes[],
    currentOrganizationDefaultLanguage: LanguageItemTypes,
): boolean => {
    const translationsArray = Object.values(translations);
    const languageId = getLanguageIdByName(languageList, currentLanguage, currentOrganizationDefaultLanguage.id);
    const workflowStatusTranslation = translationsArray
        ? translationsArray.find(translation => translation.language === languageId)
        : false;
    return Boolean(
        workflowStatusTranslation &&
            workflowStatusTranslation.translation &&
            workflowStatusTranslation.translationRequester,
    );
};
