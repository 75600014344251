import React from 'react';

import {FormTranslationItemTypes} from 'appRedux/actions/forms/types';
import {RequesterDocumentResponseType} from 'appRedux/actions/profile/types';
import {RequesterCaseTypes} from 'appRedux/actions/requestCase/types';

export interface PdfDocumentContextType {
    currentCase: RequesterCaseTypes;
    translations: FormTranslationItemTypes[];
    preview?: boolean;
}

export const PdfDocumentContext = React.createContext({} as PdfDocumentContextType);
