import React, {FC, useContext} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {RequesterCaseItemType} from 'appRedux/actions/requestCase/types';
import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {MediaContext} from 'contexts/media/context';

import {isSelectionFields, OPTIONS_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {EMPTY_DATA} from 'config/index';

interface PopupFieldPrintContentType {
    pageId: number;
    sectionId: number;
    popupId: number;
    item: RequesterCaseItemType;
    fields: FormFieldTypes[];
}

const PopupFieldPrintContent: FC<PopupFieldPrintContentType> = ({fields, item}) => {
    const {formId} = useParams();

    const {isMobile} = useContext(MediaContext);

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Box>
            {fields.map((field: FormFieldTypes, index: number) => {
                const {id, label, type} = field;
                const fieldKeyword = getFieldKeyword(Number(formId), id, 'label');
                const value = get(item, [`field-${id}`], EMPTY_DATA);
                return (
                    <Grid sx={{mb: 1}} key={`popup-field-${index}`} container>
                        <Grid item sm={3}>
                            <Typography sx={{fontSize: 16, fontWeight: 600}}>
                                {`${getFormTranslatedLabel(translations, fieldKeyword, label)}:`}
                            </Typography>
                        </Grid>
                        <Grid item sm={9}>
                            <Typography sx={{fontSize: isMobile ? 12 : 14, mt: 0.5}}>
                                {isSelectionFields(type)
                                    ? String(value).replaceAll(OPTIONS_SEPARATOR, ', ')
                                    : String(value)}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            })}
        </Box>
    );
};

export default PopupFieldPrintContent;
