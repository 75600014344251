import React, {FC, useCallback, useContext} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';

import {UPDATE_ORGANIZATION_SSO_CLIENT_SECRET_CREDENTIALS} from 'appRedux/actions/organization';
import {OrganizationSsoClientSecretTypes} from 'appRedux/actions/organization/types';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import {updateOrganizationSsoClientSecretValidationSchema} from 'components/Forms/OrganizationSsoCredentialsForm/validation';

interface OrganizationSsoCredentialsFormType {
    organizationSsoId: number;
    closeModal: () => void;
}

const OrganizationSsoClientSecretForm: FC<OrganizationSsoCredentialsFormType> = ({organizationSsoId, closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const submitUpdateOrganizationSsoClientSecretCredentials = useCallback(
        data => dispatch({type: UPDATE_ORGANIZATION_SSO_CLIENT_SECRET_CREDENTIALS.REQUEST, payload: data}),
        [dispatch],
    );

    return (
        <Formik
            initialValues={{
                clientSecret: '',
            }}
            validationSchema={updateOrganizationSsoClientSecretValidationSchema}
            onSubmit={(values, {setErrors}) => {
                submitUpdateOrganizationSsoClientSecretCredentials({
                    ...values,
                    id: organizationSsoId,
                    setErrors,
                    showAlert,
                    callback: closeModal,
                });
            }}
        >
            {(formik: FormikProps<OrganizationSsoClientSecretTypes>) => {
                return (
                    <Form>
                        <Box>
                            <Field
                                name="clientSecret"
                                label={t('superadmin.organizations.clientSecret')}
                                type="password"
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box sx={{mt: 2}}>
                            <AgentSaveButton isSubmit={true} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default OrganizationSsoClientSecretForm;
