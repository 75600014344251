import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {CREATE_REQUESTER_CASE_BUDGET, REQUESTER_CASE_BUDGETS_LIST} from 'appRedux/actions/requestCase';
import {RequesterCaseBudgetFormTypes} from 'appRedux/actions/requestCase/types';
import {FormFieldTypes, ResourceFieldBudgetInformationType} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';

import RequesterCaseBudgetForm from 'components/Forms/RequesterCaseBudgetForm/RequesterCaseBudgetForm';
import RequesterCaseBudgetInputCalendarForm from 'components/Forms/RequesterCaseBudgetForm/RequesterCaseBudgetInputCalendarForm';
import {TYPE_BUDGET_INPUT_CALENDAR} from 'components/Forms/FormBuilder/FieldForm/helper';

interface CreateRequesterCaseBudgetFormType {
    caseId: number;
    resourceFieldBudgetInformation: ResourceFieldBudgetInformationType;
    field: FormFieldTypes;
}

const CreateRequesterCaseBudgetForm: FC<CreateRequesterCaseBudgetFormType> = ({
    caseId,
    resourceFieldBudgetInformation,
    field,
}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const createRequesterCaseBudget = useCallback(
        data => dispatch({type: CREATE_REQUESTER_CASE_BUDGET.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequesterCaseBudgets = useCallback(
        data => dispatch({type: REQUESTER_CASE_BUDGETS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: RequesterCaseBudgetFormTypes) => {
        createRequesterCaseBudget({
            ...values,
            id: caseId,
            showAlert,
            callback: () => {
                getRequesterCaseBudgets({
                    id: caseId,
                    showAlert,
                });
            },
        });
    };

    const {resourceField, resourceFieldBudget, typeBudgetInput, id} = field;

    if (!resourceField || !resourceFieldBudget) return null;

    if (typeBudgetInput === TYPE_BUDGET_INPUT_CALENDAR) {
        return (
            <RequesterCaseBudgetInputCalendarForm
                initialValues={{
                    formField: id,
                    resourceField,
                    resourceFieldBudget,
                    amount: 0,
                    amountUser: 0,
                    startAt: null,
                    endAt: null,
                    startUserAt: null,
                    endUserAt: null,
                }}
                availableAmount={resourceFieldBudgetInformation.amount}
                onSubmitClicked={onSubmitClicked}
                isCreate
            />
        );
    }
    return (
        <RequesterCaseBudgetForm
            initialValues={{
                formField: id,
                resourceField,
                resourceFieldBudget,
                amount: 0,
                amountUser: 0,
                startAt: null,
                endAt: null,
                startUserAt: null,
                endUserAt: null,
            }}
            resourceFieldBudgetInformation={resourceFieldBudgetInformation}
            onSubmitClicked={onSubmitClicked}
            isCreate
        />
    );
};

export default CreateRequesterCaseBudgetForm;
