import {requestTypeCreator} from 'helpers/index';

export const SET_ERRORS = requestTypeCreator('SET_ERRORS');

export const setErrors = {
    userFormErrors: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: SET_ERRORS.USER_FORM_ERRORS,
    }),
    generalError: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: SET_ERRORS.GENERAL_ERROR,
    }),
};
