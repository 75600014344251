import React, {FC, ReactNode, useRef, useState} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {Checkbox} from '@mui/material';

import PlusSvgIcon from 'assets/icons/section/PlusSvgIcon';
import MinusSvgIcon from 'assets/icons/section/MinusSvgIcon';

import EmptyArea from 'components/EmptyArea';

import {ADMIN_LIGHT_GRAY_COLOR} from 'config/theme';

interface PageSelectWrapperType {
    title: string;
    id: number;
    isChecked: boolean;
    indeterminate: boolean;
    children?: ReactNode;
    onCheck: (checked: boolean) => void;
}

const PageSelectWrapper: FC<PageSelectWrapperType> = ({children, id, isChecked, indeterminate, title, onCheck}) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(true);

    const pageRef = useRef<HTMLDivElement>();

    const toggleExpanded = () => {
        setIsExpanded(previous => !previous);
    };
    const onCheckboxChange = (e, checked) => {
        onCheck(checked);
    };

    return (
        <Box
            sx={{
                mb: 2,
                borderRadius: 2,
                backgroundColor: ADMIN_LIGHT_GRAY_COLOR,
                scrollMarginTop: '200px',
            }}
            ref={pageRef}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    height: 60,
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    p: 2,
                }}
            >
                <Checkbox
                    checked={!!isChecked}
                    indeterminate={indeterminate}
                    onChange={onCheckboxChange}
                    name={`page-${id}-ckeckbox`}
                />

                <Typography sx={{ml: 1.5, fontSize: 16, fontWeight: 600}}>{title}</Typography>
                <EmptyArea />
                <IconButton onClick={toggleExpanded}>{isExpanded ? <MinusSvgIcon /> : <PlusSvgIcon />}</IconButton>
            </Box>
            {isExpanded && <Box sx={{p: 2, pt: 1}}>{children}</Box>}
        </Box>
    );
};

export default PageSelectWrapper;
