import React, {FC} from 'react';

import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import {TABLE_WIDTH_USERNAME} from 'pages/agent/tableView/helper';

interface UsernameWithTooltipType {
    name: string;
}

const FullNameWithTooltip: FC<UsernameWithTooltipType> = ({name}) => {
    return (
        <Tooltip
            title={name}
            componentsProps={{
                tooltip: {
                    sx: {
                        fontSize: 16,
                        backgroundColor: 'rgb(97, 97, 97)',
                    },
                },
            }}
        >
            <Typography
                align="justify"
                variant="body2"
                sx={{
                    display: 'block',
                    width: TABLE_WIDTH_USERNAME,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    lineHeight: '17.6px',
                    textAlign: 'left',
                    fontWeight: 300,
                    ml: 1,
                    mr: 1,
                }}
            >
                {name}
            </Typography>
        </Tooltip>
    );
};

export default FullNameWithTooltip;
