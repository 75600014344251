import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {WorkflowStatusRuleApproversGroupItemTypes} from 'appRedux/actions/workflow/types';

import ApproverGroupListItem from 'pages/admin/updateWorkflow/partials/rules/groups/ApproverGroupListItem';

interface ApproverGroupsListType {
    items: WorkflowStatusRuleApproversGroupItemTypes[];
}

const ApproverGroupsList: FC<ApproverGroupsListType> = ({items}) => {
    const [t] = useTranslation();

    if (items.length === 0) {
        return <Typography>{t('orguser.workflows.rules.noneApproverGroups')}</Typography>;
    }
    return (
        <Box>
            {items &&
                items.map((item: WorkflowStatusRuleApproversGroupItemTypes) => {
                    return <ApproverGroupListItem key={`approver-group-${item.uuid}`} item={item} />;
                })}
        </Box>
    );
};

export default ApproverGroupsList;
