import React, {FC, MouseEvent, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import i18n from 'i18next';
import {useLocation, useParams} from 'react-router-dom';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import TranslateIcon from '@mui/icons-material/Translate';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';

import {LANGUAGE_LIST} from 'appRedux/actions/admin';
import {
    FORM_BASE_INFORMATION,
    FORM_BASE_INFORMATION_AUTH,
    ORGANIZATION_BASE_INFORMATION_LANGUAGE,
    FORM_BASE_INVITE_INFORMATION,
    FORM_BASE_INVITE_INFORMATION_AUTH,
} from 'appRedux/actions/auth';
import {SET_LANGUAGE} from 'appRedux/actions/profile';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {MediaContext} from 'contexts/media/context';

import ClientOutlinedButton from 'components/ClientScreenComponents/ClientOutlinedButton';

import {LOCAL_STORAGE_CURRENT_LANGUAGE} from 'services/localStorage';

import {getLanguageById} from 'helpers/translationsHelper';

import {theme} from 'config/theme';

export interface LanguageDropdownSwitcherTypes {
    isFormRegistrationPage?: boolean;
    isFormInviteRegistration?: boolean;
}

const LanguageDropdownSwitcher: FC<LanguageDropdownSwitcherTypes> = ({
    isFormRegistrationPage,
    isFormInviteRegistration,
}) => {
    const dispatch = useDispatch();
    const {form} = useParams();
    const {hash} = useLocation();

    const {showAlert} = useContext(AlertContext);
    const {isMobile, language, setLanguage, setIsInitialLanguageUpdated} = useContext(MediaContext);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const {
        admin: {languageList},
        auth,
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getLanguageList = useCallback(() => dispatch({type: LANGUAGE_LIST.REQUEST}), [dispatch]);

    const getOrganizationInfoByLanguage = useCallback(
        data => dispatch({type: ORGANIZATION_BASE_INFORMATION_LANGUAGE.REQUEST, payload: data}),
        [dispatch],
    );

    const getFormBaseInformation = useCallback(
        data => dispatch({type: FORM_BASE_INFORMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getFormBaseInformationAuth = useCallback(
        data => dispatch({type: FORM_BASE_INFORMATION_AUTH.REQUEST, payload: data}),
        [dispatch],
    );

    const getInviteFormBaseInformation = useCallback(
        data => dispatch({type: FORM_BASE_INVITE_INFORMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getInviteFormBaseInformationAuth = useCallback(
        data => dispatch({type: FORM_BASE_INVITE_INFORMATION_AUTH.REQUEST, payload: data}),
        [dispatch],
    );

    const setUserLanguage = useCallback(data => dispatch({type: SET_LANGUAGE.REQUEST, payload: data}), [dispatch]);

    const onSelectLanguageChange = (value: number) => {
        if (isFormInviteRegistration) {
            if (profile) {
                getInviteFormBaseInformationAuth({
                    invite: String(hash).replace('#', ''),
                    lang: value,
                    showAlert,
                });
            } else {
                getInviteFormBaseInformation({
                    invite: String(hash).replace('#', ''),
                    lang: value,
                    showAlert,
                });
            }
        } else if (isFormRegistrationPage) {
            if (profile) {
                getFormBaseInformationAuth({
                    url: form,
                    lang: value,
                });
            } else {
                getFormBaseInformation({
                    url: form,
                    lang: value,
                });
            }
        }

        const selectedLanguage = getLanguageById(languageList, value);
        if (selectedLanguage) {
            getOrganizationInfoByLanguage({
                language: selectedLanguage.name,
                showAlert,
                callback: () => {
                    i18n.changeLanguage(selectedLanguage.name);
                    localStorage.setItem(LOCAL_STORAGE_CURRENT_LANGUAGE, selectedLanguage.name);
                    if (profile) {
                        setUserLanguage({lang: selectedLanguage.name});
                    }
                    setLanguage(value);
                    setIsInitialLanguageUpdated(true);
                },
            });
        }

        handleClose();
    };

    const getCurrentLanguage = (): number => {
        return profile ? profile.languageId : language;
    };

    useEffect(() => {
        const selectedLanguage = getLanguageById(languageList, getCurrentLanguage());
        if (selectedLanguage) {
            i18n.changeLanguage(selectedLanguage.name);
            localStorage.setItem(LOCAL_STORAGE_CURRENT_LANGUAGE, selectedLanguage.name);
        }
    }, []);

    useEffect(() => {
        getLanguageList();
    }, []);

    const enabledLanguages = isFormRegistrationPage
        ? get(auth, ['form', 'availableLanguagesList'], [])
        : get(auth, ['organization', 'availableLanguagesList'], []);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    width: '100%',
                    mt: 3,
                }}
            >
                {isMobile ? (
                    <IconButton
                        sx={{
                            backgroundColor: theme.palette.info.main,
                            '&:hover': {
                                backgroundColor: theme.palette.info.main,
                            },
                        }}
                        onClick={handleClick}
                    >
                        <TranslateIcon sx={{color: theme.palette.background.default}} />
                    </IconButton>
                ) : (
                    <ClientOutlinedButton
                        open={open}
                        dataId="dropdown#language"
                        label={getLanguageById(languageList, language).lang}
                        handleClick={handleClick}
                        startIcon={
                            <TranslateIcon
                                sx={{color: open ? theme.palette.background.default : theme.palette.info.main}}
                            />
                        }
                    />
                )}
            </Box>
            <Menu
                sx={{
                    minWidth: 100,
                    mt: 2,
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {enabledLanguages &&
                    enabledLanguages.map((item: LanguageItemTypes) => {
                        const {name, id, lang} = item;
                        return (
                            <MenuItem
                                key={`language-${name}`}
                                data-id={`dropdown#language-${name}`}
                                value={id}
                                onClick={() => onSelectLanguageChange(id)}
                            >
                                <Typography>{lang}</Typography>
                            </MenuItem>
                        );
                    })}
            </Menu>
        </>
    );
};

export default LanguageDropdownSwitcher;
