import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {LanguageItemTypes} from 'appRedux/actions/admin/types';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AddLanguageForm from 'components/Forms/FormBuilder/FormHasLanguageForm/AddLanguageForm';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

interface AddLanguageButtonType {
    formId: number;
    organizationLanguage: LanguageItemTypes;
}

const AddLanguageButton: FC<AddLanguageButtonType> = ({formId, organizationLanguage}) => {
    const [t] = useTranslation();

    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    return (
        <>
            <AgentSaveButton isSubmit={false} onClick={toggleModal} title={t('orguser.forms.addLanguage')} />
            <ModalWrapper isShowModal={isShowModal} toggleModal={toggleModal} title={t('orguser.forms.addLanguage')}>
                <AddLanguageForm
                    formId={formId}
                    organizationLanguage={organizationLanguage}
                    closeModal={() => setIsShowModal(false)}
                />
            </ModalWrapper>
        </>
    );
};

export default AddLanguageButton;
