import {TFunction} from 'i18next';

import {OrganizationTemplateItemTypes} from 'appRedux/actions/organization/types';

export const TYPE_AGENT_GET_CHAT_MESSAGE = 1;
export const TYPE_INVITE_FOR_REGISTRATION = 2;
export const TYPE_SUCCESSFULLY_SUBMITTED = 3;
export const TYPE_INVITE_ADDITIONAL_FORM = 4;
export const TYPE_APPROVE_STATUS_CHANGING = 5;
export const TYPE_SUBFORM_PAGES_ATTACHED = 6;
export const TYPE_SUBFORM_PAGES_AUTO_APPENDING = 7;

export interface OrganizationTemplateListItemType {
    type: number;
    label: string;
}

export const organizationTemplatesArray: OrganizationTemplateListItemType[] = [
    {
        type: TYPE_AGENT_GET_CHAT_MESSAGE,
        label: 'templates.userGetChatMessage.subject',
    },
    {
        type: TYPE_INVITE_FOR_REGISTRATION,
        label: 'templates.inviteForRegistration.subject',
    },
    {
        type: TYPE_SUCCESSFULLY_SUBMITTED,
        label: 'templates.caseSuccessfullySubmitted.subject',
    },
    {
        type: TYPE_INVITE_ADDITIONAL_FORM,
        label: 'templates.inviteAdditionalForm.subject',
    },
    {
        type: TYPE_APPROVE_STATUS_CHANGING,
        label: 'templates.requesterCaseApprove.subject',
    },
    {
        type: TYPE_SUBFORM_PAGES_ATTACHED,
        label: 'templates.caseSubFormAdded.subject',
    },
    {
        type: TYPE_SUBFORM_PAGES_AUTO_APPENDING,
        label: 'templates.caseSubFormAutoAppending.subject',
    },
];

export const getTemplateTypeLabel = (t: TFunction, item: OrganizationTemplateItemTypes): string => {
    const currentTemplateType = organizationTemplatesArray.find(template => template.type === item.type);
    return currentTemplateType ? t(currentTemplateType.label) : t('common.none');
};
