import {RequesterCaseResponseTypes} from 'appRedux/actions/admin/types';

export const getMergedCases = (
    currentItems: RequesterCaseResponseTypes[],
    items: RequesterCaseResponseTypes[],
    itemsNumberReduced: boolean,
): RequesterCaseResponseTypes[] => {
    if (itemsNumberReduced) {
        const result: RequesterCaseResponseTypes[] = [];
        currentItems.forEach(currentItem => {
            const presentedItem = items.find(item => currentItem.caseId === item.caseId);
            if (presentedItem) {
                result.push(presentedItem);
            }
        });
        return result;
    } else {
        const result: RequesterCaseResponseTypes[] = [...currentItems];
        items.forEach(item => {
            if (!currentItems.find(currentItem => currentItem.caseId === item.caseId)) {
                result.push(item);
            } else {
                const presentedItemKey = currentItems.findIndex(currentItem => currentItem.caseId === item.caseId);
                const presentedItem = currentItems.find(currentItem => currentItem.caseId === item.caseId);
                if (presentedItem) {
                    result[presentedItemKey] = item;
                }
            }
        });
        return result;
    }
};
