import React, {FC, RefObject} from 'react';
import {useTranslation} from 'react-i18next';
import Markdown from 'react-markdown';

import MainLogo from 'assets/mainLogo';

import {FormSnippetUpdateRequestType, FormItemBasic} from 'appRedux/actions/forms/types';

import CustomImage from 'components/CustomImage/CustomImage';
import {BUTTON_HEIGHT} from 'components/AgentScreenComponents/_buttons/common';

import useParsedTranslation from 'hooks/useParsedTranslation';

import {theme, CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface SnippetExampleType {
    snippetRef: RefObject<HTMLDivElement>;
    logoSource: string | null;
    currentForm: FormItemBasic;
    values: FormSnippetUpdateRequestType;
}

const SnippetExample: FC<SnippetExampleType> = ({logoSource, snippetRef, currentForm, values}) => {
    const [t] = useTranslation();

    const {title, description, slogan, isDark} = values;

    const backgroundColor = isDark ? theme.palette.info.main : CLIENT_BACKGROUND_COLOR;
    const color = isDark ? theme.palette.background.default : theme.palette.info.main;

    return (
        <div
            ref={snippetRef}
            style={{
                padding: 12,
                backgroundColor: backgroundColor,
                color: color,
                borderRadius: 16,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                }}
            >
                <div style={{width: '25%', paddingRight: 10, paddingTop: 5}}>
                    {logoSource ? <CustomImage imageSrc={logoSource} snippetImage /> : <MainLogo withBorderRadius />}
                </div>
                <div style={{width: '75%', paddingLeft: 10}}>
                    <p style={{margin: 0, fontSize: 24, fontWeight: 600}} id="formTitle">
                        {title}
                    </p>
                    <p id="formDescription">
                        <Markdown>{description}</Markdown>
                    </p>
                    <div
                        style={{
                            marginTop: 20,
                            marginBottom: 15,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <a
                            style={{cursor: 'pointer'}}
                            href={`${window.location.origin}/${currentForm.url}`}
                            target={'_blank'}
                            rel="noreferrer"
                        >
                            <button
                                type="button"
                                style={{
                                    boxShadow: 'none',
                                    minWidth: 112,
                                    height: BUTTON_HEIGHT,
                                    borderRadius: 16,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    borderColor: color,
                                    backgroundColor: backgroundColor,
                                    borderWidth: 1,
                                    borderStyle: 'solid',
                                }}
                            >
                                <span
                                    style={{
                                        display: 'block',
                                        fontSize: 12,
                                        fontWeight: 600,
                                        textTransform: 'none',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        color: color,
                                    }}
                                    id="formSlogan"
                                >
                                    {slogan && slogan.length > 0 ? slogan : t('orguser.forms.formSnippet.fillOurForm')}
                                </span>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 15,
                }}
            >
                <p style={{fontSize: 12, margin: 0}}>{t('common.poweredByHerupu')}</p>
            </div>
        </div>
    );
};

export default SnippetExample;
