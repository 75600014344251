import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {WorkflowFormTypes} from 'appRedux/actions/workflow/types';
import {CREATE_WORKFLOW} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import WorkflowForm from 'components/Forms/Workflow/workflow/WorkflowForm';
import {initialValues} from 'components/Forms/Workflow/validation';

interface CreateWorkflowFormType {
    organizationId: number;
    closeModal: () => void;
}

const CreateWorkflowForm: FC<CreateWorkflowFormType> = ({organizationId, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const createWorkflow = useCallback(data => dispatch({type: CREATE_WORKFLOW.REQUEST, payload: data}), [dispatch]);

    const onSubmitClicked = (values: WorkflowFormTypes) => {
        createWorkflow({
            ...values,
            organizationId,
            showAlert,
            callback: closeModal,
        });
    };

    return <WorkflowForm initialValues={initialValues} onSubmitClicked={onSubmitClicked} />;
};

export default CreateWorkflowForm;
