import {fork, put, take, call} from 'redux-saga/effects';

import {http} from 'services/http';

import {
    GET_FORM_SNIPPET,
    getFormSnippet,
    UPDATE_FORM_SNIPPET,
    updateFormSnippet,
    UPDATE_FORM_WITH_LOGO_SNIPPET,
    updateFormWithLogoSnippet,
    GET_FORM_SNIPPET_LOGO,
    getFormSnippetLogo,
    GET_FORM_SNIPPET_CODE,
    getFormSnippetCode,
    DELETE_FORM_SNIPPET_LOGO,
    deleteFormSnippetLogo,
} from 'appRedux/actions/forms';

import {
    CommonFormBuilderRequest,
    FormSnippetUpdateRequestType,
    CommonFormBuilderResponseType,
    FormSnippetCodeResponseType,
    FormSnippetUpdateWithLogoRequestType,
    CommonGetLogoUrlTypes,
    CommonLogoResponseType,
    FormSnippetDeleteLogoRequestType,
} from 'appRedux/actions/forms/types';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchFormSnippetGetting() {
    while (true) {
        const {
            payload: {id, showAlert},
        }: IActionType<CommonFormBuilderRequest> = yield take(GET_FORM_SNIPPET.REQUEST);
        try {
            const data: CommonFormBuilderResponseType = yield call(http, `form/${id}/snippet`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getFormSnippet.success(data.result));
            } else {
                yield put(getFormSnippet.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getFormSnippet.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormSnippetCodeGetting() {
    while (true) {
        const {
            payload: {id, callback, showAlert},
        }: IActionType<CommonFormBuilderRequest> = yield take(GET_FORM_SNIPPET_CODE.REQUEST);
        try {
            const data: FormSnippetCodeResponseType = yield call(http, `snippet/${id}/info`, {
                method: 'GET',
            });
            if (data.results) {
                yield put(getFormSnippetCode.success());
                callback && callback(data.results);
            } else {
                yield put(getFormSnippetCode.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getFormSnippetCode.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormSnippetUpdating() {
    while (true) {
        const {
            payload: {id, showAlert, callback, setErrors, ...values},
        }: IActionType<FormSnippetUpdateRequestType> = yield take(UPDATE_FORM_SNIPPET.REQUEST);
        try {
            const data: CommonFormBuilderResponseType = yield call(http, `snippet/${id}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateFormSnippet.success(data.result));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(updateFormSnippet.error({message: 'messages.error.somethingWentWrong'}));
                setErrors && setErrors(data.errors);
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateFormSnippet.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormSnippetWithLogoUpdating() {
    while (true) {
        const {
            payload: {id, showAlert, callback, setErrors, ...values},
        }: IActionType<FormSnippetUpdateWithLogoRequestType> = yield take(UPDATE_FORM_WITH_LOGO_SNIPPET.REQUEST);
        try {
            const {title, description, isDark, slogan, file} = values;
            const formData = new FormData();
            formData.append('title', title);
            formData.append('description', description);
            formData.append('isDark', JSON.stringify(isDark));
            formData.append('slogan', slogan);
            formData.append('media', file);
            const data: CommonFormBuilderResponseType = yield call(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                http,
                `snippet/${id}/update/logo`,
                {
                    method: 'POST',
                    body: formData,
                },
            );
            if (data.success) {
                yield put(updateFormWithLogoSnippet.success(data.result));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(updateFormWithLogoSnippet.error({message: 'messages.error.somethingWentWrong'}));
                setErrors && setErrors(data.errors);
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateFormWithLogoSnippet.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormSnippetLogoGetting() {
    while (true) {
        const {
            payload: {id, callback},
        }: IActionType<CommonGetLogoUrlTypes> = yield take(GET_FORM_SNIPPET_LOGO.REQUEST);
        try {
            const data: CommonLogoResponseType = yield call(http, `snippet/${id}/logo`, {
                method: 'GET',
            });
            if (data.fileLink) {
                yield put(getFormSnippetLogo.success(data));
                callback && callback(data.fileLink);
            } else {
                yield put(getFormSnippetLogo.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getFormSnippetLogo.error({message: String(e)}));
        }
    }
}

function* watchFormSnippetLogoDeleting() {
    while (true) {
        const {
            payload: {id, showAlert, callback},
        }: IActionType<FormSnippetDeleteLogoRequestType> = yield take(DELETE_FORM_SNIPPET_LOGO.REQUEST);
        try {
            const data: CommonFormBuilderResponseType = yield call(http, `snippet/${id}/delete/logo`, {
                method: 'POST',
            });
            if (data.success) {
                yield put(deleteFormSnippetLogo.success(data.result));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteFormSnippetLogo.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteFormSnippetLogo.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchFormSnippetLogoGetting),
    fork(watchFormSnippetGetting),
    fork(watchFormSnippetCodeGetting),
    fork(watchFormSnippetUpdating),
    fork(watchFormSnippetWithLogoUpdating),
    fork(watchFormSnippetLogoDeleting),
];
