import React, {FC, useCallback, useContext} from 'react';
import {Field, FormikProps, Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';

import {UPDATE_WORKFLOW_STATUS_INTERNAL_NAME} from 'appRedux/actions/workflow';
import {WorkflowStatusItemTypes, WorkflowStatusInternalNameFormTypes} from 'appRedux/actions/workflow/types';

import {AlertContext} from 'contexts/alert/context';

import {validationSchemaInternalName} from 'components/Forms/Workflow/validation';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface WorkflowStatusInternalNameFormType {
    item: WorkflowStatusItemTypes;
    closeModal: () => void;
}

const WorkflowStatusInternalNameForm: FC<WorkflowStatusInternalNameFormType> = ({item, closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {internalName, uuid} = item;

    const {showAlert} = useContext(AlertContext);

    const updateWorkflowStatusInternalName = useCallback(
        data => dispatch({type: UPDATE_WORKFLOW_STATUS_INTERNAL_NAME.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values, {setErrors}) => {
        updateWorkflowStatusInternalName({
            uuid,
            ...values,
            setErrors,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <Formik
            initialValues={{
                internalName: internalName ? internalName : '',
            }}
            validationSchema={() => validationSchemaInternalName(t)}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<WorkflowStatusInternalNameFormTypes>) => {
                return (
                    <Form>
                        <Field
                            required
                            name="internalName"
                            placeholder={t('orguser.workflows.workflowStatusParameters.internalName')}
                            component={FormikTextInput}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 3,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default WorkflowStatusInternalNameForm;
