import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import {RootReducer} from 'appRedux/reducers';

import MainLogo from 'assets/mainLogo';

import CustomLogo from 'components/StartScreenComponents/CustomLogo';

const FormLogo: FC = () => {
    const {
        auth: {form},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const logoSource = get(form, 'logo', null);

    if (logoSource) {
        return <CustomLogo logoSource={logoSource} />;
    }
    return <MainLogo />;
};

export default FormLogo;
