import React, {FC, useContext} from 'react';
import {useSelector} from 'react-redux';

import {RootReducer} from 'appRedux/reducers';

import {PermissionContext} from 'contexts/permission/context';

import InvitesFormList from 'components/Invites/InvitesFormList';

const Invites: FC = () => {
    const {isInviteViewEnabled} = useContext(PermissionContext);

    const {
        admin: {organizationList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    if (myOrganization && myOrganization.isInviteRegistration && isInviteViewEnabled) {
        return <InvitesFormList />;
    }
    return null;
};

export default Invites;
