import get from 'lodash/get';

export interface RouteParameterType {
    [key: string]: string | number | undefined;
}

export const getRouteWithParameters = (route: string, parameters: RouteParameterType): string => {
    const parametersArray = Object.values(parameters);
    if (parametersArray.length > 0) {
        const params: string[] = [];
        for (const [key, value] of Object.entries(parameters)) {
            if (value) {
                params.push(`${key}=${value}`);
            }
        }
        return `${route}?${params.join('&')}`;
    }
    return route;
};

export const getStatusTranslationErrorMessage = (errors?: any): string | undefined => {
    if (errors) {
        const errorMessageTranslationRequester = get(errors, 'translationRequester', null);
        if (errorMessageTranslationRequester) return errorMessageTranslationRequester;
        const errorMessageTranslation = get(errors, 'translation', null);
        if (errorMessageTranslation) return errorMessageTranslation;
    }
    return;
};
