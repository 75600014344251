import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {CHANGE_ORGANIZATION_STATUS} from 'appRedux/actions/organization';

import {AlertContext} from 'contexts/alert/context';

import {CustomSwitcher} from 'components/AgentScreenComponents/_form/CustomElements';

interface OrganizationStatusSwitcherType {
    id: number;
    isActive: boolean;
}

const OrganizationStatusSwitcher: FC<OrganizationStatusSwitcherType> = ({id, isActive}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isChecked, setIsChecked] = useState<boolean>(isActive);

    const toggleIsChecked = () => {
        setIsChecked(previous => !previous);
    };

    const changeOrganizationStatus = useCallback(
        data => dispatch({type: CHANGE_ORGANIZATION_STATUS.REQUEST, payload: data}),
        [dispatch],
    );

    const handleChangeStatus = () => {
        toggleIsChecked();
        changeOrganizationStatus({
            organizationId: id,
            showAlert,
        });
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', mb: 2}}>
            <Typography variant="body2" sx={{fontWeight: 300, mr: 2}}>
                {t('superadmin.organizations.organizationStatusSwitcher')}
            </Typography>
            <CustomSwitcher checked={isChecked} onClick={handleChangeStatus} />
        </Box>
    );
};

export default OrganizationStatusSwitcher;
