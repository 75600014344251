import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import PasswordSvgIcon from 'assets/icons/buttons/PasswordSvgIcon';

import {OrganizationAdminListTypes} from 'appRedux/actions/organizationAdmin/types';
import {RootReducer} from 'appRedux/reducers';
import {ORGANIZATION_ADMIN_PASSWORD_RESET} from 'appRedux/actions/organizationAdmin';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

interface UpdateAdminPasswordType {
    item: OrganizationAdminListTypes;
}

const ResetAdminPassword: FC<UpdateAdminPasswordType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isUserEditEnabled} = useContext(PermissionContext);

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    const resetPassword = useCallback(
        data => dispatch({type: ORGANIZATION_ADMIN_PASSWORD_RESET.REQUEST, payload: data}),
        [dispatch],
    );

    const onResetClick = () => {
        resetPassword({
            id: item.id,
            showAlert,
            callback: () => setShowModal(false),
        });
    };

    const {
        admin: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <>
            <IconButton
                title={t('common.buttons.resetPassword')}
                disabled={isLoading || !isUserEditEnabled}
                sx={{mr: 1}}
                onClick={toggleModal}
            >
                <PasswordSvgIcon isDisabled={isLoading || !isUserEditEnabled} />
            </IconButton>
            {showModal && (
                <ModalWrapper
                    isShowModal={showModal}
                    toggleModal={toggleModal}
                    title={t('common.buttons.resetPassword')}
                >
                    <Typography variant="body1" sx={{mb: 3}}>
                        {t('superadmin.users.resetAdminPasswordExplanation')}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mt: 1,
                        }}
                    >
                        <AgentInverseButton title={t('common.buttons.cancel')} onClick={toggleModal} />
                        <AgentSaveButton onClick={onResetClick} title={t('common.buttons.reset')} />
                    </Box>
                </ModalWrapper>
            )}
        </>
    );
};

export default ResetAdminPassword;
