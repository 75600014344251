import React, {FC, useCallback, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Formik, FormikProps} from 'formik';
import get from 'lodash/get';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

import Box from '@mui/material/Box';

import {CREATE_ORGANIZATION_PRIVACY_POLICY_VERSION} from 'appRedux/actions/organization';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {
    OrganizationPrivacyPolicyItemTypes,
    OrganizationPrivacyPolicyVersionFormTypes,
} from 'appRedux/actions/organization/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {AdminContext} from 'contexts/admin/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import MdxEditorField from 'components/MdxEditorField/MdxEditorField';
import {organizationPrivacyPolicyVersionValidationSchema} from 'components/Forms/OrganizationPrivacyPolicyForm/validation';

import {getLanguageIdByName} from 'helpers/translationsHelper';

import {PARAMETER_LANGUAGE} from 'config/index';

interface CreateOrganizationPrivacyPolicyFormType {
    item: OrganizationPrivacyPolicyItemTypes;
    organizationLanguage: LanguageItemTypes;
    closeModal: () => void;
}

const CreateOrganizationPrivacyPolicyForm: FC<CreateOrganizationPrivacyPolicyFormType> = ({
    item,
    closeModal,
    organizationLanguage,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const {showAlert} = useContext(AlertContext);
    const {myOrganization} = useContext(AdminContext);

    const {
        admin: {languageList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const createOrganizationPrivacyPolicyVersion = useCallback(
        data => dispatch({type: CREATE_ORGANIZATION_PRIVACY_POLICY_VERSION.REQUEST, payload: data}),
        [dispatch],
    );

    const {uuid, versions} = item;

    const onSubmitAction = (values: OrganizationPrivacyPolicyVersionFormTypes) => {
        createOrganizationPrivacyPolicyVersion({
            uuid,
            ...values,
            showAlert,
            callback: closeModal,
        });
    };

    if (!myOrganization) return null;

    const currentLanguage = searchParams.get(PARAMETER_LANGUAGE)
        ? searchParams.get(PARAMETER_LANGUAGE)
        : organizationLanguage.name;

    return (
        <Formik
            initialValues={{
                text: get(versions, [0, 'text'], ''),
                language: getLanguageIdByName(languageList, String(currentLanguage), organizationLanguage.id),
            }}
            validationSchema={organizationPrivacyPolicyVersionValidationSchema}
            onSubmit={onSubmitAction}
        >
            {(formik: FormikProps<OrganizationPrivacyPolicyVersionFormTypes>) => {
                return (
                    <Form>
                        <Box>
                            <MdxEditorField
                                name="text"
                                value={formik.values.text ? String(formik.values.text) : ''}
                                label={t('superadmin.organizations.privacyPolicy.text')}
                                customButtons={[]}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CreateOrganizationPrivacyPolicyForm;
