import React, {FC, useContext, useRef, useState} from 'react';

import {MediaContext} from 'contexts/media/context';

interface CustomImageType {
    imageSrc: string;
    galleryImage?: boolean;
    snippetImage?: boolean;
    isMobileMenu?: boolean;
    imgStyle?: React.CSSProperties;
    maxWidth?: number;
    maxHeight?: number;
}

const CustomImage: FC<CustomImageType> = ({
    imageSrc,
    galleryImage,
    snippetImage,
    isMobileMenu,
    maxWidth,
    maxHeight,
    imgStyle,
}) => {
    const imageRef = useRef<HTMLImageElement>(null);

    const {isMobile} = useContext(MediaContext);

    const [logoWidth, setLogoWidth] = useState<number>(0);
    const [logoHeight, setLogoHeight] = useState<number>(0);

    const onImageLoad = () => {
        if (imageRef && imageRef.current) {
            setLogoWidth(imageRef.current.width);
            setLogoHeight(imageRef.current.height);
        }
    };

    const isPortraitMode = logoWidth >= logoHeight;

    if (galleryImage) {
        return (
            <img
                style={imgStyle}
                src={imageSrc}
                ref={imageRef}
                onLoad={onImageLoad}
                height={isPortraitMode ? 'auto' : '90%'}
                width={isPortraitMode ? '95%' : 'auto'}
            />
        );
    }

    const logoMaxWidth = isMobile ? '50%' : '100%';
    const logoMaxHeight = isMobile ? '20vh' : '30vh';

    if (snippetImage) {
        return (
            <img
                id="formLogo"
                src={imageSrc}
                ref={imageRef}
                onLoad={onImageLoad}
                style={{
                    borderRadius: 8,
                    maxWidth: isPortraitMode ? logoMaxWidth : '95%',
                    maxHeight: isPortraitMode ? '95%' : logoMaxHeight,
                }}
            />
        );
    }

    if (isMobileMenu) {
        const mobileLogoMaxWidth = isMobile ? '80%' : '100%';
        const mobileLogoMaxHeight = isMobile ? '20vh' : '30vh';
        return (
            <img
                src={imageSrc}
                ref={imageRef}
                onLoad={onImageLoad}
                style={{
                    maxWidth: isPortraitMode ? mobileLogoMaxWidth : 'initial',
                    maxHeight: isPortraitMode ? 'initial' : mobileLogoMaxHeight,
                }}
            />
        );
    }

    const styles =
        maxWidth && maxHeight
            ? {
                  maxWidth: isPortraitMode ? maxWidth : 'initial',
                  maxHeight: isPortraitMode ? 'initial' : maxHeight,
              }
            : {
                  maxWidth: isPortraitMode ? logoMaxWidth : 'initial',
                  maxHeight: isPortraitMode ? 'initial' : logoMaxHeight,
              };

    return <img src={imageSrc} ref={imageRef} onLoad={onImageLoad} style={styles} />;
};

export default CustomImage;
