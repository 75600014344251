import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {PublicKeyOrganizationTypes} from 'appRedux/actions/crypto/types';
import {REMOVE_ORGANIZATION_PUBLIC_KEYS} from 'appRedux/actions/crypto';

import {AlertContext} from 'contexts/alert/context';
import {CryptoContext} from 'contexts/crypto/context';

import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';

import {exportPublicKey} from 'helpers/cryptoApiHelper';

interface OrganizationPublicKeyDeleteButtonType {
    item: PublicKeyOrganizationTypes;
}

const OrganizationPublicKeyDeleteButton: FC<OrganizationPublicKeyDeleteButtonType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {keys} = useContext(CryptoContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [isCurrentBrowserKey, setIsCurrentBrowserKey] = useState<boolean>(false);

    const {data, uuid} = item;

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const removePublicKey = useCallback(
        data => dispatch({type: REMOVE_ORGANIZATION_PUBLIC_KEYS.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        removePublicKey({
            uuid,
            isCurrent: false,
            showAlert,
        });
    };

    useEffect(() => {
        if (keys) {
            const fetchData = async () => {
                const browserPublicKey = await exportPublicKey(keys.publicKey);
                setIsCurrentBrowserKey(browserPublicKey === data);
            };
            fetchData().catch(console.error);
        }
    }, [keys]);

    return (
        <>
            <IconButton onClick={toggleIsOpened} disabled={isCurrentBrowserKey}>
                <DeleteSvgIcon isDisabled={isCurrentBrowserKey} />
            </IconButton>
            <DeleteAdminConfirmationModal
                toggleModal={toggleIsOpened}
                description={t('superadmin.publicKeys.publicKeyRemovingWarning')}
                descriptionWithAlert
                isShowModal={isOpened}
                onClickAction={onClickDelete}
            />
        </>
    );
};

export default OrganizationPublicKeyDeleteButton;
