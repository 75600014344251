import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import get from 'lodash/get';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';

import mobileLogo from 'assets/images/mobileLogo.png';

import EmptyArea from 'components/EmptyArea';

const PoweredByHerupu: FC = () => {
    const [t] = useTranslation();

    const {
        instance: {files},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const customLogo = get(files, 'logo', null);

    if (customLogo) {
        return (
            <Box sx={{pt: 6, pb: 4}}>
                <Typography sx={{fontSize: 11, mb: 2}}>{t('common.poweredBy')}</Typography>
                <img src={mobileLogo} alt="Herupu logo" />
            </Box>
        );
    }
    return <EmptyArea />;
};

export default PoweredByHerupu;
