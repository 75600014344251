import React, {DragEvent, FC, ReactNode, useRef} from 'react';

import {Box} from '@mui/material';

import DraggableSvgIcon from 'assets/icons/buttons/DraggableSvgIcon';

import {AgentMenuItemTypes} from 'components/AgentScreenComponents/helper';

import {CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface DraggableTableRowType {
    item: AgentMenuItemTypes;
    children: ReactNode;
    dragItem?: string;
    setDragItem: React.Dispatch<React.SetStateAction<string | undefined>>;
    dropTo?: number;
    setDropTo: React.Dispatch<React.SetStateAction<number | undefined>>;
    onDragEnd: () => void;
}

const DraggableMenuItem: FC<DraggableTableRowType> = ({
    item,
    dragItem,
    setDragItem,
    dropTo,
    setDropTo,
    onDragEnd,
    children,
}) => {
    const {uuid, priority} = item;

    const dragEndTimeoutRef = useRef<NodeJS.Timeout>();

    const onDragStart = () => {
        setDragItem(uuid);
        setTimeout(() => {
            setDropTo(priority);
        }, 0);
    };

    const onDrag = () => {
        dragEndTimeoutRef.current && clearTimeout(dragEndTimeoutRef.current);
        dragEndTimeoutRef.current = setTimeout(() => {
            setDragItem && setDragItem('');
        }, 1000);
    };

    const onDragEnter = () => {
        setTimeout(() => {
            setDropTo(priority);
        }, 0);
    };

    const onDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        onDragEnd && onDragEnd();
    };

    return (
        <Box
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDrag={onDrag}
            onDragEnter={onDragEnter}
            onDragOver={onDragOver}
            onDrop={handleDrop}
            draggable
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                overflow: 'hidden',
                opacity: dragItem && dropTo === priority ? 0.5 : 1,
                background: dragItem && dropTo === priority ? CLIENT_BACKGROUND_COLOR : 'white',
            }}
        >
            <Box sx={{width: 'calc(100% - 26px)'}}>{children}</Box>
            <Box sx={{pr: 2, svg: {width: 8, height: 12}, cursor: 'grab'}}>
                <DraggableSvgIcon isDisabled={dragItem !== uuid} />
            </Box>
        </Box>
    );
};

export default DraggableMenuItem;
