import React, {FC, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';

import ClientScreenWrapper from 'components/ClientScreenComponents/ClientScreenWrapper/ClientScreenWrapper';
import UserInformationForm from 'components/Forms/SettingsForms/UserInformationForm/UserInformationForm';
import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';
import {isGeneralInfoPresented} from 'components/ClientScreenComponents/ClientScreenWrapper/helper';

import {routes} from 'config/index';

const GeneralPage: FC = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        if (profile && isGeneralInfoPresented(profile)) {
            const caseId = get(profile, 'redirectToCase', null);
            const formId = get(profile, 'redirectToForm', null);
            if (caseId && formId) {
                navigate(`${routes.REQUEST}/${caseId}/form/${formId}`);
            } else {
                navigate(routes.REQUESTS_LIST, {replace: true});
            }
        }
    }, [profile]);

    return (
        <ClientScreenWrapper>
            <Box sx={{mt: 3}}>
                <ClientSectionWrapper title={t('requester.generalPage.generalInformation')}>
                    <UserInformationForm />
                </ClientSectionWrapper>
            </Box>
        </ClientScreenWrapper>
    );
};

export default GeneralPage;
