import React, {FC, useContext, useRef, useState} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {get, isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import {CommonUploadLogoTypes} from 'appRedux/actions/forms/types';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormFieldErrorMessage from 'components/AdminScreenComponents/FormFieldErrorMessage';

import {theme} from 'config/theme';

interface CommonLogoFormType {
    type?: number;
    initialValues: CommonUploadLogoTypes;
    submitUploadFile: (value: CommonUploadLogoTypes) => void;
    identifier: string;
    label?: string;
    transparentButton?: boolean;
}

const CommonUploadLogoForm: FC<CommonLogoFormType> = ({
    type,
    initialValues,
    transparentButton,
    submitUploadFile,
    identifier,
    label,
}) => {
    const [t] = useTranslation();

    const {showAlert} = useContext(AlertContext);

    const [errors, setErrors] = useState<IErrors>();

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const onLabelClick = () => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, {resetForm}) => {
                setErrors({});
                resetForm();
                submitUploadFile({
                    ...values,
                    type,
                    setErrors,
                    showAlert,
                });
            }}
        >
            {(formik: FormikProps<CommonUploadLogoTypes>) => {
                return (
                    <Form>
                        <Box
                            sx={{
                                mt: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <TextField
                                inputRef={fileInputRef}
                                sx={{display: 'none'}}
                                fullWidth
                                variant="standard"
                                required
                                name="file"
                                type="file"
                                onChange={value => {
                                    formik.setFieldValue('file', get(value, ['currentTarget', 'files', '0'], null));
                                    formik.submitForm();
                                }}
                            />
                            {!isEmpty(errors) && (
                                <Box sx={{mb: 2}}>
                                    <FormFieldErrorMessage errorMessage={String(errors)} />
                                </Box>
                            )}
                            {transparentButton ? (
                                <Button data-id={`upload#logo-${identifier}`} onClick={onLabelClick}>
                                    <IconButton>
                                        <EditSvgIcon />
                                    </IconButton>
                                    <Typography
                                        sx={{
                                            cursor: 'pointer',
                                            color: theme.palette.info.main,
                                            textTransform: 'none',
                                        }}
                                    >
                                        {label ?? t('common.buttons.uploadLogo')}
                                    </Typography>
                                </Button>
                            ) : (
                                <AgentSaveButton
                                    isSubmit={false}
                                    data-id={`upload#logo-${identifier}`}
                                    title={label ?? t('common.buttons.uploadLogo')}
                                    onClick={onLabelClick}
                                />
                            )}
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CommonUploadLogoForm;
