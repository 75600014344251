import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';

import {FormSectionTypes} from 'appRedux/actions/forms/types';
import {
    FORM_SECTION_COPY,
    FORM_SECTION_DELETE,
    FORM_SECTION_DOWN,
    FORM_SECTION_UP,
    FORM_SECTION_RECALCULATE_PRIORITIES,
} from 'appRedux/actions/forms';

import {AlertContext} from 'contexts/alert/context';

import {
    CONTENT_FIELD,
    CONTENT_POPUP,
    CONTENT_SECTION,
    FORM_FIELD_CHECKBOXES,
    FORM_FIELD_DATE_RANGE,
    FORM_FIELD_DATEPICKER,
    FORM_FIELD_DROPDOWN,
    FORM_FIELD_IMAGE_CHECK,
    FORM_FIELD_IMAGE_RADIO,
    FORM_FIELD_RADIOBUTTONS,
    FORM_FIELD_RANGE,
    FORM_FIELD_RATING,
    FORM_FIELD_SIGNATURE,
    FORM_FIELD_TEXT_INPUT,
    FORM_FIELD_TEXTAREA,
    FORM_FIELD_UPLOADER,
    FORM_FIELD_RESOURCE_FIELD,
    getSectionContent,
    SectionContentType,
} from 'pages/admin/updateForm/partials/FormStructure/helper';
import TopPanel from 'pages/admin/updateForm/partials/FormStructure/TopPanel';
import FormFieldInformation from 'pages/admin/updateForm/partials/FormStructure/FormFieldInformation';
import FormFieldSelection from 'pages/admin/updateForm/partials/FormStructure/FormFieldSelection';
import FormPopupInformation from 'pages/admin/updateForm/partials/FormStructure/FormPopupInformation';
import {UPDATE_SECTION_KEYWORD, REPLACE_SECTION_KEYWORD} from 'pages/admin/updateForm/partials/constants';
import DragPlaceholderFormBlock from 'pages/admin/updateForm/partials/FormStructure/DragPlaceholderFormBlock';
import FormDragNewFields from 'pages/admin/updateForm/partials/FormStructure/FormDragNewFields';
import FormFieldFileSelection from 'pages/admin/updateForm/partials/FormStructure/FormFieldFileSelection';

import {CLIENT_BACKGROUND_COLOR, randomColors} from 'config/theme';

interface FormSectionInformationType {
    isFirst: boolean;
    isLast: boolean;
    priority: number;
    pageId: number;
    setModalTitle: (value: string) => void;
    setCurrentPageId: (value: number) => void;
    setEntityId: (value: number) => void;
    toggleModal: () => void;
    item: FormSectionTypes;
    sections: FormSectionTypes[];
    relatedSectionsIds: number[];
    onAddNewPopupClick: (value: number) => void;
    onAddNewFieldClick: (value: number, inputType?: string) => void;
    onAddFieldPopupClick: (value: number) => void;
    onDrop: (index?: number) => void;
    placeholdersCount?: number;
    viewOnly: boolean;
}

const FormSectionInformation: FC<FormSectionInformationType> = ({
    isFirst,
    isLast,
    priority,
    pageId,
    item,
    sections,
    relatedSectionsIds,
    setCurrentPageId,
    setModalTitle,
    setEntityId,
    toggleModal,
    onAddNewPopupClick,
    onAddNewFieldClick,
    onAddFieldPopupClick,
    onDrop,
    placeholdersCount,
    viewOnly,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {title, id, fields, popups, isRequired} = item;

    const {showAlert} = useContext(AlertContext);

    const handleSectionRemove = useCallback(
        data => dispatch({type: FORM_SECTION_DELETE.REQUEST, payload: data}),
        [dispatch],
    );

    const handleSectionCopy = useCallback(
        data => dispatch({type: FORM_SECTION_COPY.REQUEST, payload: data}),
        [dispatch],
    );

    const handleSectionUpPriority = useCallback(
        data => dispatch({type: FORM_SECTION_UP.REQUEST, payload: data}),
        [dispatch],
    );

    const handleSectionDownPriority = useCallback(
        data => dispatch({type: FORM_SECTION_DOWN.REQUEST, payload: data}),
        [dispatch],
    );

    const handleRecalculatePriorities = useCallback(
        data => dispatch({type: FORM_SECTION_RECALCULATE_PRIORITIES.REQUEST, payload: data}),
        [dispatch],
    );

    const sectionContent: SectionContentType[] = getSectionContent(fields, popups);

    const onEditClick = () => {
        toggleModal();
        setModalTitle(UPDATE_SECTION_KEYWORD);
        setEntityId(id);
    };

    const onCopyClick = () => {
        handleSectionCopy({
            id,
            showAlert,
        });
    };

    const onDeleteClick = () => {
        handleSectionRemove({
            id,
            showAlert,
        });
    };

    const onUpClick = () => {
        handleSectionUpPriority({
            id,
            showAlert,
        });
    };

    const onReplaceClick = () => {
        toggleModal();
        setModalTitle(REPLACE_SECTION_KEYWORD);
        setCurrentPageId(pageId);
        setEntityId(id);
    };

    const onDownClick = () => {
        handleSectionDownPriority({
            id,
            showAlert,
        });
    };

    const onRecalculateClick = () => {
        handleRecalculatePriorities({
            id,
            showAlert,
        });
    };

    const optionalIndex = relatedSectionsIds.findIndex(id => id === item.id);

    return (
        <Box
            sx={{
                mb: 2,
                borderColor: optionalIndex >= 0 ? randomColors[optionalIndex] : CLIENT_BACKGROUND_COLOR,
                borderWidth: 2,
                borderRadius: 2,
                borderStyle: 'solid',
                pb: 1,
            }}
        >
            <TopPanel
                isFirst={isFirst}
                isLast={isLast}
                isRequired={isRequired}
                entityId={id}
                typeTitle={t('orguser.forms.section')}
                priority={priority}
                title={title}
                onEditClick={onEditClick}
                onCopyClick={onCopyClick}
                onDeleteClick={onDeleteClick}
                onUpClick={onUpClick}
                onDownClick={onDownClick}
                onRecalculateClick={onRecalculateClick}
                viewOnly={viewOnly}
                showReplaceButton
                onReplaceClick={onReplaceClick}
            />
            <Box
                sx={{
                    pt: 1,
                    px: 2,
                }}
            >
                {sectionContent
                    .sort((item1, item2) => item1.priority - item2.priority)
                    .map((item: SectionContentType, index: number) => {
                        if (item.type === CONTENT_FIELD) {
                            const field = fields[item.index];
                            switch (field.type) {
                                case FORM_FIELD_TEXT_INPUT:
                                case FORM_FIELD_TEXTAREA:
                                case FORM_FIELD_DATEPICKER:
                                case FORM_FIELD_UPLOADER:
                                case FORM_FIELD_DATE_RANGE:
                                case FORM_FIELD_RANGE:
                                case FORM_FIELD_RATING:
                                case FORM_FIELD_SIGNATURE:
                                case FORM_FIELD_RESOURCE_FIELD:
                                    return (
                                        <Box key={`field-${field.type}-${index}`}>
                                            <DragPlaceholderFormBlock
                                                dropType={`${CONTENT_SECTION}-${id}`}
                                                onDrop={onDrop}
                                                index={index}
                                            />
                                            <FormFieldInformation
                                                item={field}
                                                setEntityId={setEntityId}
                                                toggleModal={toggleModal}
                                                setModalTitle={setModalTitle}
                                                isFirst={index === 0}
                                                isLast={index === sectionContent.length - 1}
                                                viewOnly={viewOnly}
                                            />
                                        </Box>
                                    );
                                case FORM_FIELD_DROPDOWN:
                                case FORM_FIELD_CHECKBOXES:
                                case FORM_FIELD_RADIOBUTTONS:
                                    return (
                                        <Box key={`field-${field.type}-${index}`}>
                                            <DragPlaceholderFormBlock
                                                dropType={`${CONTENT_SECTION}-${id}`}
                                                onDrop={onDrop}
                                                index={index}
                                            />
                                            <FormFieldSelection
                                                item={field}
                                                sections={sections}
                                                setEntityId={setEntityId}
                                                toggleModal={toggleModal}
                                                setModalTitle={setModalTitle}
                                                isFirst={index === 0}
                                                isLast={index === sectionContent.length - 1}
                                                relatedSectionsIds={relatedSectionsIds}
                                                viewOnly={viewOnly}
                                            />
                                        </Box>
                                    );
                                case FORM_FIELD_IMAGE_CHECK:
                                case FORM_FIELD_IMAGE_RADIO:
                                    return (
                                        <Box key={`field-${field.type}-${index}`}>
                                            <DragPlaceholderFormBlock
                                                dropType={`${CONTENT_SECTION}-${id}`}
                                                onDrop={onDrop}
                                                index={index}
                                            />
                                            <FormFieldFileSelection
                                                item={field}
                                                sections={sections}
                                                setEntityId={setEntityId}
                                                toggleModal={toggleModal}
                                                setModalTitle={setModalTitle}
                                                isFirst={index === 0}
                                                isLast={index === sectionContent.length - 1}
                                                relatedSectionsIds={relatedSectionsIds}
                                                viewOnly={viewOnly}
                                            />
                                        </Box>
                                    );
                            }
                            return null;
                        } else if (item.type === CONTENT_POPUP) {
                            const popup = popups[item.index];
                            return (
                                <Box key={`popup-${index}`}>
                                    <DragPlaceholderFormBlock
                                        dropType={`${CONTENT_SECTION}-${id}`}
                                        onDrop={onDrop}
                                        index={index}
                                    />
                                    <FormPopupInformation
                                        item={popup}
                                        sections={sections}
                                        toggleModal={toggleModal}
                                        setModalTitle={setModalTitle}
                                        setEntityId={setEntityId}
                                        onAddFieldPopupClick={onAddFieldPopupClick}
                                        isFirst={index === 0}
                                        isLast={index === sectionContent.length - 1}
                                        onDrop={onDrop}
                                        viewOnly={viewOnly}
                                    />
                                </Box>
                            );
                        }
                        return null;
                    })}
                <DragPlaceholderFormBlock dropType={`${CONTENT_SECTION}-${id}`} onDrop={onDrop} />
                {!viewOnly && (
                    <FormDragNewFields
                        sectionId={id}
                        onAddNewFieldClick={onAddNewFieldClick}
                        onAddNewPopupClick={onAddNewPopupClick}
                        placeholdersCount={placeholdersCount}
                    />
                )}
            </Box>
        </Box>
    );
};

export default FormSectionInformation;
