import React, {FC, useCallback, useContext, useState} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';
import {WorkflowItemTypes} from 'appRedux/actions/workflow/types';
import {FORM_WORKFLOW_UPDATE} from 'appRedux/actions/forms';
import {UpdateFormWorkflowFormTypes, UpdateFormWorkflowFormStatusesTypes} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import WorkflowStatusesSelector from 'components/Forms/FormBuilder/FormWorkflowUpdateForm/WorkflowStatusesSelector';

interface FormWorkflowUpdateFormType {
    currentWorkflowId: number;
    closeForm: () => void;
}

const FormWorkflowUpdateForm: FC<FormWorkflowUpdateFormType> = ({currentWorkflowId, closeForm}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {id} = useParams();

    const {showAlert} = useContext(AlertContext);

    const [selectedStatuses, setSelectedStatuses] = useState<UpdateFormWorkflowFormStatusesTypes[]>([]);
    const [, setIsUpdated] = useState<boolean>(false);

    const {
        admin: {isLoading, formWorkflowStatuses},
        workflow: {organizationWorkflows},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const submitForm = useCallback(data => dispatch({type: FORM_WORKFLOW_UPDATE.REQUEST, payload: data}), [dispatch]);

    const updateSelectedStatuses = (oldStatus: number, newStatus: number) => {
        for (let i = 0, n = selectedStatuses.length; i < n; i++) {
            const item = selectedStatuses[i];
            if (item && item.oldStatus === oldStatus) {
                selectedStatuses.splice(i, 1);
            }
        }

        selectedStatuses.push({
            oldStatus,
            newStatus,
        });
        setSelectedStatuses(selectedStatuses);
        setIsUpdated(previous => !previous);
    };

    const onSubmitClicked = values => {
        submitForm({
            id: Number(id),
            statuses: selectedStatuses,
            ...values,
            showAlert,
            callback: closeForm,
        });
    };

    return (
        <Formik
            initialValues={{
                workflow: 0,
            }}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<UpdateFormWorkflowFormTypes>) => {
                return (
                    <Form>
                        <FormControl required fullWidth>
                            <InputLabel>{t('orguser.forms.workflow')}</InputLabel>
                            <Select
                                label={t('orguser.forms.workflow')}
                                name={'workflow'}
                                value={formik.values.workflow}
                                onChange={e => {
                                    formik.handleChange(e);
                                    setSelectedStatuses([]);
                                    setIsUpdated(previous => !previous);
                                }}
                                IconComponent={KeyboardArrowDownIcon}
                                fullWidth
                            >
                                {organizationWorkflows &&
                                    organizationWorkflows.map((item: WorkflowItemTypes, index: number) => {
                                        if (item.id !== currentWorkflowId) {
                                            return (
                                                <MenuItem key={`workflow-${index}`} value={item.id}>
                                                    <Typography sx={{fontWeight: 600}}>{item.title}</Typography>
                                                </MenuItem>
                                            );
                                        }
                                        return null;
                                    })}
                            </Select>
                        </FormControl>
                        <WorkflowStatusesSelector
                            currentWorkflowId={currentWorkflowId}
                            selectedWorkflowId={formik.values.workflow}
                            updateSelectedStatuses={updateSelectedStatuses}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: 3,
                            }}
                        >
                            <AgentSaveButton
                                isSubmit
                                isLoading={isLoading}
                                isDisabled={
                                    formik.values.workflow === 0 ||
                                    selectedStatuses.length !== formWorkflowStatuses.length
                                }
                            />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default FormWorkflowUpdateForm;
