import React, {FC} from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {buttonOutlineStyles, labelOutlineStyles} from 'components/AgentScreenComponents/_buttons/common';
import {getAvatarTitle} from 'components/TopBarMenu/helper';
import AvatarImage from 'components/UserAvatar/AvatarImage';
import AgentCloseIconButton from 'components/AgentScreenComponents/_buttons/AgentCloseIconButton';
import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';

interface AgentChipButtonType {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    toggleModal: () => void;
    avatarType: number;
    avatarLink: string | null;
    firstName: string | null;
    lastName: string | null;
    email: string;
    onRemoveClick: () => void;
}

const AgentChipButton: FC<AgentChipButtonType> = ({
    isOpen,
    setIsOpen,
    toggleModal,
    avatarType,
    avatarLink,
    firstName,
    lastName,
    email,
    onRemoveClick,
}) => {
    return (
        <Button
            sx={{...buttonOutlineStyles, pl: 0.5}}
            endIcon={<AgentCloseIconButton isOutlined onClose={() => setIsOpen(true)} />}
        >
            <AvatarImage
                size={25}
                avatarType={avatarType}
                avatarLink={avatarLink}
                avatarTitle={getAvatarTitle(firstName, lastName, email)}
            />
            <Typography sx={{...labelOutlineStyles, ml: 1}}>
                {firstName && lastName ? `${firstName} ${lastName}` : email}
            </Typography>
            <DeleteAdminConfirmationModal
                toggleModal={toggleModal}
                isShowModal={isOpen}
                onClickAction={onRemoveClick}
            />
        </Button>
    );
};

export default AgentChipButton;
