import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import Typography from '@mui/material/Typography';
import {GridColDef} from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import MarkdownWithShowAll from 'components/TextWithShowAll/MarkdownWithShowAll';

import DeleteResourceFieldCalendarButton from 'pages/admin/updateResourceField/calendar/DeleteResourceFieldCalendarButton';

import {routes} from 'config/index';

const getResourceFieldCalendarColumns = (): GridColDef[] | null => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const onUpdateClick = (uuid: string) => {
        navigate(`${routes.RESOURCE_FIELD}/calendar/${uuid}/edit`);
    };

    return [
        {
            field: 'title',
            headerName: t('orguser.resourceFields.title'),
            minWidth: 280,
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{value}</Typography>;
            },
        },
        {
            field: 'description',
            headerName: t('orguser.resourceFields.description'),
            minWidth: 350,
            flex: 1,
            renderCell: ({value}) => {
                return <MarkdownWithShowAll text={value} isTable />;
            },
        },
        {
            field: 'actions',
            headerName: t('common.action'),
            align: 'right',
            maxWidth: 90,
            flex: 1,
            renderCell: ({row}) => {
                return (
                    <>
                        <IconButton onClick={() => onUpdateClick(row.uuid)} title={t('common.buttons.update')}>
                            <EditSvgIcon />
                        </IconButton>
                        <DeleteResourceFieldCalendarButton item={row} />
                    </>
                );
            },
        },
    ];
};

export default getResourceFieldCalendarColumns;
