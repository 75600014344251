import get from 'lodash/get';
import {fork, put, take, call} from 'redux-saga/effects';
import {FormikErrors} from 'formik';

import {http} from 'services/http';
import {setToken, setRefreshToken} from 'services/localStorage';

import {
    FORMS_LIST,
    FORMS_LIST_FOR_REQUESTER,
    FORM_CHANGE_STATUS,
    FORM_DELETE,
    FORMS_COPY,
    FORM_CREATE,
    FORM_UPDATE,
    FORM_MULTIPLE_STATUS_CHANGING,
    FORM_INFORMATION,
    FORM_BY_CASE_INFORMATION,
    FORM_FIELD_POPUP_ADD,
    FORM_UPLOAD_LOGO,
    FORM_LOGO_GET,
    FORM_LOGO_DELETE,
    FORM_FILTERS_GET,
    FORM_PAUSE_ON,
    FORM_PAUSE_OFF,
    FORM_ORGANIZATION_RELATE,
    FORM_TRANSLATION_IMPORT,
    FORM_TRANSLATION_EXPORT,
    FORM_WORKFLOW_GET_STATUSES,
    FORM_WORKFLOW_UPDATE,
    formChangeStatus,
    getFormsListForRequester,
    formsListInfo,
    deleteForm,
    copyForm,
    createForm,
    updateForm,
    getFormInformation,
    getFormByCaseInformation,
    addFormPopupField,
    multipleFormStatusChanging,
    uploadFormLogo,
    getFormLogo,
    formLogoDelete,
    getFormFilters,
    formPauseOn,
    formPauseOff,
    relateFormToOrganization,
    importFormTranslation,
    exportFormTranslation,
    getFormWorkflowStatuses,
    updateFormWorkflow,
} from 'appRedux/actions/forms';
import {
    FormsListRequestType,
    FormChangeStatusType,
    FormItemBasic,
    FormAddFieldRequest,
    FormMultipleChangeStatusType,
    FormConfigurationRequestTypes,
    FormResponseTypes,
    CommonUploadLogoTypes,
    CommonDeleteLogoTypes,
    CommonGetLogoUrlTypes,
    FormMultipleActionType,
    CommonLogoResponseType,
    FormFieldFilterResponseType,
    FormPauseRequestType,
    FormOrganizationRelateRequestType,
    FormTranslationExportType,
    FormTranslationImportType,
    FormTranslationImportResponseType,
    FormFieldAddResponseType,
    FormWorkflowStatusesRequest,
    UpdateFormWorkflowRequestTypes,
    CurrentFormWorkflowStatusesResponseTypes,
    FormItemBasicResponseTypes,
    FormsListForRequesterListRequestTypes,
    FormsListForRequesterListResponseTypes,
} from 'appRedux/actions/forms/types';

import {getTranslationUploadErrorMessage, getLogoUploadErrorMessage} from 'helpers/documentsUploading';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchGetFormsList() {
    while (true) {
        const {
            payload: {board},
        }: IActionType<FormsListRequestType> = yield take(FORMS_LIST.REQUEST);
        try {
            const data: FormItemBasic[] = yield call(http, `forms?board=${board ? board : ''}`, {
                method: 'GET',
            });

            if (data) {
                yield put(formsListInfo.success(data));
            } else {
                yield put(formsListInfo.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(formsListInfo.error({message: String(e)}));
        }
    }
}

function* watchGetFormsListForRequester() {
    while (true) {
        const {
            payload: {showAlert},
        }: IActionType<FormsListForRequesterListRequestTypes> = yield take(FORMS_LIST_FOR_REQUESTER.REQUEST);
        try {
            const data: FormsListForRequesterListResponseTypes = yield call(http, `forms/requester`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getFormsListForRequester.success(data.results));
            } else {
                yield put(getFormsListForRequester.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(getFormsListForRequester.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormInformation() {
    while (true) {
        const {
            payload: {id},
        }: IActionType<FormChangeStatusType> = yield take(FORM_INFORMATION.REQUEST);
        try {
            const data: FormItemBasic[] = yield call(http, `form/${id}/content`, {
                method: 'GET',
            });
            if (data) {
                yield put(getFormInformation.success(data));
            } else {
                yield put(getFormInformation.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getFormInformation.error({message: String(e)}));
        }
    }
}

function* watchFormByCaseInformation() {
    while (true) {
        const {
            payload: {id, showAlert, callback},
        }: IActionType<FormChangeStatusType> = yield take(FORM_BY_CASE_INFORMATION.REQUEST);
        try {
            const data: FormItemBasic[] = yield call(http, `form/${id}/case`, {
                method: 'GET',
            });
            if (data) {
                yield put(getFormByCaseInformation.success(data));
                callback && callback();
            } else {
                yield put(getFormByCaseInformation.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getFormByCaseInformation.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldFilters() {
    while (true) {
        const {
            payload: {id, callback},
        }: IActionType<FormChangeStatusType> = yield take(FORM_FILTERS_GET.REQUEST);
        try {
            const data: FormFieldFilterResponseType = yield call(http, `form/${id}/filters`, {
                method: 'GET',
            });
            if (data) {
                yield put(getFormFilters.success(data));
                callback && callback();
            } else {
                yield put(getFormFilters.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getFormFilters.error({message: String(e)}));
        }
    }
}

function* watchStatusChanging() {
    while (true) {
        const {
            payload: {id, showAlert, callbackError, callback},
        }: IActionType<FormChangeStatusType> = yield take(FORM_CHANGE_STATUS.REQUEST);
        try {
            const data: FormResponseTypes = yield call(http, `form/active/${id}`, {
                method: 'POST',
                body: JSON.stringify({}),
            });
            if (data.errors) {
                yield put(formChangeStatus.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
                callbackError && callbackError();
            } else {
                const forms = get(data, 'forms', []);
                yield put(formChangeStatus.success(forms));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            }
        } catch (e) {
            yield put(formChangeStatus.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormPauseOn() {
    while (true) {
        const {
            payload: {id, callback, showAlert, ...values},
        }: IActionType<FormPauseRequestType> = yield take(FORM_PAUSE_ON.REQUEST);
        try {
            const data: FormItemBasic[] = yield call(http, `form/${id}/pause`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data) {
                const forms = get(data, 'forms', []);
                yield put(formPauseOn.success(forms));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(formPauseOn.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formPauseOn.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormPauseOff() {
    while (true) {
        const {
            payload: {id, callback, showAlert},
        }: IActionType<FormChangeStatusType> = yield take(FORM_PAUSE_OFF.REQUEST);
        try {
            const data: FormItemBasic[] = yield call(http, `form/${id}/unpause`, {
                method: 'POST',
            });
            if (data) {
                const forms = get(data, 'forms', []);
                yield put(formPauseOff.success(forms));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(formPauseOff.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formPauseOff.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchDeleteForm() {
    while (true) {
        const {
            payload: {id, callback, showAlert},
        }: IActionType<FormChangeStatusType> = yield take(FORM_DELETE.REQUEST);
        try {
            const data: FormResponseTypes = yield call(http, `form/${id}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteForm.success(data.forms));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteForm.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(deleteForm.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormCopying() {
    while (true) {
        const {
            payload: {ids, callback, showAlert},
        }: IActionType<FormMultipleActionType> = yield take(FORMS_COPY.REQUEST);
        try {
            const {success, forms, token, refreshToken}: FormResponseTypes = yield call(
                http,
                `forms/copy?id=${ids.join(',')}`,
                {
                    method: 'GET',
                },
            );
            if (success) {
                yield put(copyForm.success(forms));
                if (token && refreshToken) {
                    yield setToken(token);
                    yield setRefreshToken(refreshToken);
                }
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(copyForm.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(copyForm.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormCreating() {
    while (true) {
        const {
            payload: {callback, setErrors, showAlert, ...values},
        }: IActionType<FormConfigurationRequestTypes> = yield take(FORM_CREATE.REQUEST);
        try {
            const {success, forms, token, refreshToken, errors}: FormResponseTypes = yield call(http, `form/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (success) {
                yield put(createForm.success(forms));
                if (token && refreshToken) {
                    yield setToken(token);
                    yield setRefreshToken(refreshToken);
                }
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                const newFormId = get(forms, [0, 'id'], null);
                if (newFormId) {
                    callback && callback(Number(newFormId));
                }
            } else {
                yield put(createForm.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
                setErrors && setErrors(errors);
            }
        } catch (e) {
            yield put(createForm.error({message: String(e)}));
        }
    }
}

function* watchFormUpdating() {
    while (true) {
        const {
            payload: {id, setErrors, showAlert, ...values},
        }: IActionType<FormConfigurationRequestTypes> = yield take(FORM_UPDATE.REQUEST);
        try {
            const data: FormResponseTypes = yield call(http, `form/edit/${id}`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                const forms: FormItemBasic[] = get(data, 'forms', []);
                yield put(updateForm.success(forms));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateForm.error({message: 'messages.error.somethingWentWrong'}));
                const errors: FormikErrors<FormConfigurationRequestTypes> = get(data, 'errors', {});
                setErrors && setErrors(errors);
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateForm.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormMultipleStatusChanging() {
    while (true) {
        const {
            payload: {ids, isActive, callback, callbackError, showAlert},
        }: IActionType<FormMultipleChangeStatusType> = yield take(FORM_MULTIPLE_STATUS_CHANGING.REQUEST);
        try {
            const data: FormResponseTypes = yield call(
                http,
                `forms/status?id=${ids.join(',')}&isActive=${Number(isActive)}`,
                {
                    method: 'GET',
                },
            );
            if (data.errors) {
                yield put(multipleFormStatusChanging.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
                callbackError && callbackError();
            } else if (data) {
                const forms = get(data, 'forms', []);
                yield put(multipleFormStatusChanging.success(forms));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            }
        } catch (e) {
            yield put(multipleFormStatusChanging.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormFieldPopupAdding() {
    while (true) {
        const {
            payload: {id, callback, showAlert, ...values},
        }: IActionType<FormAddFieldRequest> = yield take(FORM_FIELD_POPUP_ADD.REQUEST);
        try {
            const data: FormFieldAddResponseType = yield call(http, `field/${id}/create/popup`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data) {
                const pages = get(data, 'pages', []);
                yield put(addFormPopupField.success(pages));
                callback && data.fieldId && callback(data.fieldId);
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(addFormPopupField.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(addFormPopupField.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormLogoUploading() {
    while (true) {
        const {
            payload: {id, file, setErrors, callback, showAlert},
        }: IActionType<CommonUploadLogoTypes> = yield take(FORM_UPLOAD_LOGO.REQUEST);
        try {
            const formData = new FormData();
            formData.append('media', file);
            const data: FormResponseTypes = yield call(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                http,
                `form/logo/${id}`,
                {
                    method: 'POST',
                    body: formData,
                },
            );

            if (data.success) {
                const forms: FormItemBasic[] = get(data, 'forms', []);
                yield put(uploadFormLogo.success(forms));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                const errorMessage = getLogoUploadErrorMessage(data.errors);
                setErrors && setErrors(errorMessage);
                yield put(uploadFormLogo.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, errorMessage);
            }
        } catch (e) {
            yield put(uploadFormLogo.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormLogoGetting() {
    while (true) {
        const {
            payload: {id, callback},
        }: IActionType<CommonGetLogoUrlTypes> = yield take(FORM_LOGO_GET.REQUEST);
        try {
            const data: CommonLogoResponseType = yield call(http, `form/logo/${id}`, {
                method: 'GET',
            });
            if (data.fileLink) {
                yield put(getFormLogo.success(data));
                callback && callback(data.fileLink);
            } else {
                yield put(getFormLogo.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getFormLogo.error({message: String(e)}));
        }
    }
}

function* watchFormLogoRemoving() {
    while (true) {
        const {
            payload: {id, callback, showAlert},
        }: IActionType<CommonDeleteLogoTypes> = yield take(FORM_LOGO_DELETE.REQUEST);
        try {
            const data: FormResponseTypes = yield call(http, `form/logo/${id}/delete`, {
                method: 'GET',
            });
            if (data.success) {
                const forms: FormItemBasic[] = get(data, 'forms', []);
                yield put(formLogoDelete.success(forms));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(formLogoDelete.error({message: 'messages.error.somethingWentWrong'}));
                const errors: FormikErrors<FormConfigurationRequestTypes> = get(data, 'errors', {});
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(formLogoDelete.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormToOrganizationRelation() {
    while (true) {
        const {
            payload: {id, callback, showAlert, ...values},
        }: IActionType<FormOrganizationRelateRequestType> = yield take(FORM_ORGANIZATION_RELATE.REQUEST);
        try {
            const data: FormResponseTypes = yield call(http, `form/relate/${id}`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                const forms: FormItemBasic[] = get(data, 'forms', []);
                yield put(relateFormToOrganization.success(forms));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(relateFormToOrganization.error({message: 'messages.error.somethingWentWrong'}));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(relateFormToOrganization.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchFormTranslationImport() {
    while (true) {
        const {
            payload: {showAlert, lang, file, callback, id, setErrors},
        }: IActionType<FormTranslationImportType> = yield take(FORM_TRANSLATION_IMPORT.REQUEST);
        try {
            const formData = new FormData();
            formData.append('media', file);
            const data: FormTranslationImportResponseType = yield call(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                http,
                `form/${id}/translation/import/${lang}`,
                {
                    method: 'POST',
                    body: formData,
                },
            );
            if (data.success) {
                yield put(importFormTranslation.success());
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                const errorMessage = getTranslationUploadErrorMessage(data.errors);
                setErrors && setErrors(errorMessage);
                yield put(importFormTranslation.error({message: errorMessage}));
                showAlert && showAlert(ALERT_TYPE_ERROR, errorMessage);
            }
        } catch (e) {
            yield put(importFormTranslation.error({message: String(e)}));
        }
    }
}

function* watchFormTranslationExport() {
    while (true) {
        const {
            payload: {showAlert, lang, callback, callbackDownload, id},
        }: IActionType<FormTranslationExportType> = yield take(FORM_TRANSLATION_EXPORT.REQUEST);
        try {
            const data: string | Blob = yield call(http, `form/${id}/translation/${lang}/export`, {
                method: 'GET',
                responseType: 'blob',
            });
            if (data) {
                yield put(exportFormTranslation.success());
                callback && callback();
                callbackDownload && callbackDownload(data);
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(exportFormTranslation.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(exportFormTranslation.error({message: String(e)}));
        }
    }
}

function* watchFormWorkflowStatusesGetting() {
    while (true) {
        const {
            payload: {showAlert, callback, id},
        }: IActionType<FormWorkflowStatusesRequest> = yield take(FORM_WORKFLOW_GET_STATUSES.REQUEST);
        try {
            const data: CurrentFormWorkflowStatusesResponseTypes = yield call(http, `form/workflow/${id}/statuses`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getFormWorkflowStatuses.success(data.results));
                callback && callback();
            } else {
                yield put(getFormWorkflowStatuses.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getFormWorkflowStatuses.error({message: String(e)}));
        }
    }
}

function* watchFormWorkflowUpdating() {
    while (true) {
        const {
            payload: {showAlert, callback, id, ...values},
        }: IActionType<UpdateFormWorkflowRequestTypes> = yield take(FORM_WORKFLOW_UPDATE.REQUEST);
        try {
            const data: FormItemBasicResponseTypes = yield call(http, `form/workflow/${id}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateFormWorkflow.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateFormWorkflow.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateFormWorkflow.error({message: String(e)}));
        }
    }
}

export default [
    fork(watchGetFormsList),
    fork(watchGetFormsListForRequester),
    fork(watchFormInformation),
    fork(watchFormByCaseInformation),
    fork(watchStatusChanging),
    fork(watchDeleteForm),
    fork(watchFormPauseOn),
    fork(watchFormPauseOff),
    fork(watchFormCopying),
    fork(watchFormCreating),
    fork(watchFormUpdating),
    fork(watchFormMultipleStatusChanging),
    fork(watchFormFieldPopupAdding),
    fork(watchFormLogoUploading),
    fork(watchFormLogoGetting),
    fork(watchFormLogoRemoving),
    fork(watchFormFieldFilters),
    fork(watchFormToOrganizationRelation),
    fork(watchFormTranslationImport),
    fork(watchFormTranslationExport),
    fork(watchFormWorkflowStatusesGetting),
    fork(watchFormWorkflowUpdating),
];
