import {MainProfileResponseTypes} from 'appRedux/actions/profile/types';

import {userRoles} from 'config/index';

export const isSuperAdminUser = (profile: MainProfileResponseTypes): boolean => {
    return profile.roles.includes(userRoles.ROLE_SUPER_ADMIN);
};

export const isOrgAdminUser = (profile: MainProfileResponseTypes): boolean => {
    return profile.roles.includes(userRoles.ROLE_ADMIN);
};

export const isAgentUser = (profile: MainProfileResponseTypes): boolean => {
    return profile.roles.includes(userRoles.ROLE_AGENT);
};

export const isRequester = (profile: MainProfileResponseTypes): boolean => {
    return profile.roles.includes(userRoles.ROLE_REQUEST);
};
