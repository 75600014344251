import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {FORM_FAQ_CREATE} from 'appRedux/actions/forms';
import {FormFaqFormTypes} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';

import FaqForm from 'components/Forms/FormBuilder/FaqForm/FaqForm';

interface CreateFaqFormType {
    formId: number;
    languageId: number;
    closeModal: () => void;
}

const CreateFaqForm: FC<CreateFaqFormType> = ({formId, languageId, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const submitCreateForm = useCallback(data => dispatch({type: FORM_FAQ_CREATE.REQUEST, payload: data}), [dispatch]);

    const onSubmitAction = (values: FormFaqFormTypes) => {
        submitCreateForm({
            id: formId,
            ...values,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <FaqForm
            initialValues={{
                language: languageId,
                question: '',
                answer: '',
            }}
            onSubmitAction={onSubmitAction}
        />
    );
};

export default CreateFaqForm;
