import React, {FC, useEffect, useState} from 'react';
import './sendMail.css';
import {Box} from '@mui/material';

interface SendMailTypes {
    size?: number;
}

const SendMail: FC<SendMailTypes> = ({size}) => {
    const [opened, setOpened] = useState(true);
    const [closed, setClosed] = useState(false);
    const [hidden, setHidden] = useState(false);
    const [sent, setSent] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setOpened(true);
        }, 300);

        setTimeout(() => {
            setClosed(true);
        }, 1000);

        setTimeout(() => {
            setHidden(true);
        }, 1500);

        setTimeout(() => {
            setSent(true);
        }, 1500);
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'center',
                pb: hidden ? 0 : `${size ? size / 2 : 100}px`,
                height: hidden ? 0 : size ? size * 1.5 : 300,
                transition: 'all 0.5s ease-out',
            }}
        >
            <svg
                version="1.1"
                id="mail-icon"
                viewBox="96.3 -4.7 45.1 46.2"
                xmlSpace="preserve"
                className={
                    (opened ? 'toggled ' : '') +
                    (closed ? 'closed ' : '') +
                    (hidden ? 'hidden ' : '') +
                    (sent ? 'sent ' : '')
                }
                width={size || 200}
                height={size || 200}
            >
                <path
                    className="st5"
                    d="M138.2,10.5H99.5c-1.7,0-3.1,1.4-3.1,3.1v24.8c0,1.7,1.4,3.1,3.1,3.1h38.7c1.7,0,3.1-1.4,3.1-3.1V13.6C141.3,11.9,139.9,10.5,138.2,10.5z"
                />
                <path className="st0" d="M125.6,30.7c-3.7-2.6-6.6-4.6-6.8-4.7l0,0L125.6,30.7z" />
                <path
                    id="env-lid"
                    className="st0"
                    d="M118.9,26L118.9,26c0,0,16-11.1,21.4-14.8c-0.5-0.5-1.2-0.8-2-0.8H99.5c-0.8,0-1.5,0.3-2,0.8L118.9,26L118.9,26z"
                />
                <path
                    id="env-paper"
                    className="st1"
                    d="M135.8,34.2h-33.9c-1.3,0-2.4-1.1-2.4-2.4V0.7c0-1.3,1.1-2.4,2.4-2.4h33.9c1.3,0,2.4,1.1,2.4,2.4v31.1C138.1,33.1,137.1,34.2,135.8,34.2z"
                />
                <path
                    id="env-line-3"
                    className="st2"
                    d="M131.2,6.5h-24.7c-0.9,0-1.6-0.7-1.6-1.6l0,0c0-0.9,0.7-1.6,1.6-1.6h24.6c0.9,0,1.6,0.7,1.6,1.6l0,0C132.8,5.7,132.1,6.5,131.2,6.5z"
                />
                <path
                    id="env-line-2"
                    className="st2"
                    d="M131.2,14.1h-24.7c-0.9,0-1.6-0.7-1.6-1.6l0,0c0-0.9,0.7-1.6,1.6-1.6h24.6c0.9,0,1.6,0.7,1.6,1.6l0,0C132.8,13.4,132.1,14.1,131.2,14.1z"
                />
                <path
                    id="env-line-1"
                    className="st2"
                    d="M131.2,21.5h-24.7c-0.9,0-1.6-0.7-1.6-1.6l0,0c0-0.9,0.7-1.6,1.6-1.6h24.6c0.9,0,1.6,0.7,1.6,1.6l0,0C132.8,20.8,132.1,21.5,131.2,21.5z"
                />
                <path
                    className="st3"
                    d="M97.6,11.1c-0.6,0.5-1.3,1.5-1.3,2.4v24.9c0,1.7,1.4,3.1,3.1,3.1h38.8c0.8,0,1.4-0.3,2-0.7C137.1,38.7,97.6,11.1,97.6,11.1z"
                />
                <path
                    className="st4"
                    d="M140.3,11.2c-5.4,3.7-21.4,14.8-21.4,14.8l0,0c0.2,0.1,18.2,12.7,21.3,14.8c0.7-0.6,1.2-1.4,1.2-2.3v-25C141.3,12.6,140.9,11.8,140.3,11.2z"
                />
            </svg>
        </Box>
    );
};

export default SendMail;
