import React, {FC, ReactNode, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';

import PlusSvgIcon from 'assets/icons/section/PlusSvgIcon';
import MinusSvgIcon from 'assets/icons/section/MinusSvgIcon';

import {FormSectionTypes} from 'appRedux/actions/forms/types';
import {RequesterDocumentResponseType} from 'appRedux/actions/profile/types';

import {FORM_FIELD_UPLOADER} from 'pages/admin/updateForm/partials/FormStructure/helper';

import EmptyArea from 'components/EmptyArea';

import {theme} from 'config/theme';
import {CustomSwitcher} from 'components/AgentScreenComponents/_form/CustomElements';
import {RequesterCaseCommentsTypes} from 'appRedux/actions/comments/types';
import {PrintSectionType} from '../PdfEditor';

interface SectionSelectWrapperType {
    section: FormSectionTypes;
    files: RequesterDocumentResponseType[];
    comments: RequesterCaseCommentsTypes[];
    print: PrintSectionType;
    setPrint: (print: PrintSectionType) => void;
    printAttachments: Record<string, boolean>;
    setPrintAttachments: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
    children?: ReactNode;
}

const SectionSelectWrapper: FC<SectionSelectWrapperType> = ({
    section,
    files,
    print,
    setPrint,
    printAttachments,
    setPrintAttachments,
    children,
}) => {
    const [t] = useTranslation();
    const {formId} = useParams();

    const {title, fields, id} = section;
    const isChecked = print?.printSection;
    const printComments = print?.printComments;

    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const sectionRef = useRef<HTMLDivElement>();

    const toggleExpanded = () => {
        setIsExpanded(previous => !previous);
    };

    const fileIds = useMemo(() => {
        const fileFields = fields.filter(field => field.type === FORM_FIELD_UPLOADER);
        if (!fileFields?.length) return [];

        let uploadedFiles: RequesterDocumentResponseType[] = [];

        for (const field of fileFields) {
            uploadedFiles = [
                ...uploadedFiles,
                ...files.filter(
                    (file: RequesterDocumentResponseType) => file.formField === `form-${formId}-field-${field.id}`,
                ),
            ];
        }

        const fileIds = uploadedFiles.map(file => file.uuid);

        return fileIds;
    }, [fields, files]);

    const attachmentsChecked = fileIds.every(id => printAttachments[id]);

    const updateAttachments = (checked: boolean) => {
        setPrintAttachments(prev => {
            const filesChecked = {};
            for (const id of fileIds) {
                filesChecked[id] = checked;
            }
            return {...prev, ...filesChecked};
        });
    };

    useEffect(() => {
        updateAttachments(!!isChecked);
    }, [isChecked]);

    const onCheckboxChange = (e, checked) => {
        setPrint({...print, printSection: checked});
    };

    const onPrintAttachmentsChange = () => {
        updateAttachments(!attachmentsChecked);
    };

    const onPrintCommentsChange = () => {
        setPrint({...print, printComments: !printComments});
    };

    return (
        <Box
            sx={{
                mb: 1,
                borderRadius: 2,
                backgroundColor: theme.palette.background.default,
                scrollMarginTop: '200px',
            }}
            ref={sectionRef}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    height: 60,
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    p: 2,
                }}
            >
                <Checkbox checked={!!isChecked} onChange={onCheckboxChange} name={`section-${id}-ckeckbox`} />
                <Typography sx={{ml: 1.5, fontSize: 16, fontWeight: 600}}>{title}</Typography>
                <EmptyArea />
                {printComments !== undefined && isChecked && (
                    <>
                        <Typography sx={{fontSize: 14, mr: 1.5}}>{t('orguser.requesterCase.printComments')}</Typography>
                        <CustomSwitcher
                            checked={printComments}
                            onClick={onPrintCommentsChange}
                            disabled={!isChecked}
                            name={`section-${id}-comment-switch`}
                        />
                    </>
                )}
                {!!fileIds.length && isChecked && (
                    <>
                        <Typography sx={{fontSize: 14, ml: 2, mr: 1.5}}>
                            {t('orguser.requesterCase.printAttachments')}
                        </Typography>
                        <CustomSwitcher
                            checked={attachmentsChecked}
                            onClick={onPrintAttachmentsChange}
                            disabled={!isChecked}
                            name={`section-${id}-attachments-switch`}
                        />
                    </>
                )}
                <IconButton sx={{ml: 1}} onClick={toggleExpanded}>
                    {isExpanded ? <MinusSvgIcon /> : <PlusSvgIcon />}
                </IconButton>
            </Box>
            {isExpanded && <Box sx={{p: 2}}>{children}</Box>}
        </Box>
    );
};

export default SectionSelectWrapper;
