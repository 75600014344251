import {
    GET_GROUPS_LIST,
    CREATE_GROUP,
    UPDATE_GROUP,
    DELETE_GROUP,
    GROUP_ATTACH_USER,
    GROUP_DETACH_USER,
} from 'appRedux/actions/groups';

import {GroupItemTypes} from 'appRedux/actions/groups/types';

export interface GroupsState {
    isLoading: boolean;
    groups: GroupItemTypes[];
    errors: IErrors | undefined;
}

export const initialState: GroupsState = {
    isLoading: false,
    groups: [],
    errors: undefined,
};

const groups = (state = initialState, action: IActionType<GroupItemTypes[] | IErrors>): GroupsState => {
    switch (action.type) {
        case GET_GROUPS_LIST.REQUEST:
        case CREATE_GROUP.REQUEST:
        case UPDATE_GROUP.REQUEST:
        case DELETE_GROUP.REQUEST:
        case GROUP_ATTACH_USER.REQUEST:
        case GROUP_DETACH_USER.REQUEST: {
            return {
                ...state,
                isLoading: true,
                errors: undefined,
            };
        }

        case GET_GROUPS_LIST.SUCCESS:
        case CREATE_GROUP.SUCCESS:
        case UPDATE_GROUP.SUCCESS:
        case DELETE_GROUP.SUCCESS:
        case GROUP_ATTACH_USER.SUCCESS:
        case GROUP_DETACH_USER.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                groups: action.payload as GroupItemTypes[],
                errors: undefined,
            };
        }

        case GET_GROUPS_LIST.ERROR:
        case CREATE_GROUP.ERROR:
        case UPDATE_GROUP.ERROR:
        case DELETE_GROUP.ERROR:
        case GROUP_ATTACH_USER.ERROR:
        case GROUP_DETACH_USER.ERROR: {
            return {
                ...state,
                isLoading: false,
                errors: (action.payload as IErrors) || {},
            };
        }

        default:
            return state;
    }
};

export default groups;
