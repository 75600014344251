import React, {FC, useCallback, useContext} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import {UserNicknameUpdateRequestType} from 'appRedux/actions/profile/types';
import {UPDATE_NICKNAME} from 'appRedux/actions/profile';

import {AlertContext} from 'contexts/alert/context';

import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import {validationSchema} from 'components/Forms/SettingsForms/UpdateUsernameForm/validation';

import {preventSendForm} from 'helpers/index';

interface UpdateUsernameFormAnonymous {
    closeModal: () => void;
}

const UpdateUsernameForm: FC<UpdateUsernameFormAnonymous> = ({closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const onSubmitAction = useCallback(data => dispatch({type: UPDATE_NICKNAME.REQUEST, payload: data}), [dispatch]);

    return (
        <Box>
            <Alert sx={{mb: 1}} severity="info">
                <Typography>{t('common.account.updateUsernameExplanation')}</Typography>
            </Alert>
            <Formik
                initialValues={{
                    nickname: '',
                }}
                validationSchema={() => validationSchema(t)}
                onSubmit={(values: UserNicknameUpdateRequestType, {setErrors}) => {
                    onSubmitAction({
                        ...values,
                        showAlert,
                        setErrors,
                        callback: closeModal,
                    });
                }}
            >
                {(formik: FormikProps<UserNicknameUpdateRequestType>) => {
                    return (
                        <Form onKeyDown={preventSendForm}>
                            <Field
                                data-id="input#general-firstname"
                                name="nickname"
                                required
                                label={t('common.registrationForm.nickname')}
                                placeholder={t('common.registrationForm.nickname')}
                                component={FormikTextInput}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mt: 4,
                                }}
                            >
                                <AgentInverseButton title={t('common.buttons.discardChanges')} onClick={closeModal} />
                                <AgentSaveButton isSubmit />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default UpdateUsernameForm;
