import React, {FC, MouseEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import {ClientTranslationTypes} from 'appRedux/actions/clientTranslation/types';

import {getTranslationIcon} from 'pages/agent/requesterPage/partials/helper';

import {theme} from 'config/theme';
import {ERROR_TEXT_COLOR} from 'config/theme';

interface TranslationButtonType {
    currentTranslation?: ClientTranslationTypes;
}

const TranslationButton: FC<TranslationButtonType> = ({currentTranslation}) => {
    const [t] = useTranslation();

    const [popoverText, setPopoverText] = useState<string>('');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: MouseEvent<HTMLElement>, currentTranslation?: ClientTranslationTypes) => {
        if (currentTranslation && currentTranslation.isGoogleTranslation) {
            setAnchorEl(event.currentTarget);
            setPopoverText('orguser.requesterCase.translation.translatedViaGoogleApi');
        }
        if (currentTranslation && currentTranslation.isOutdated) {
            setAnchorEl(event.currentTarget);
            setPopoverText('orguser.requesterCase.translation.translationOutdated');
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setPopoverText('');
    };

    const open = Boolean(anchorEl);

    const Icon = getTranslationIcon(currentTranslation);

    return (
        <>
            <IconButton
                onClick={e => handleClick(e, currentTranslation)}
                sx={{
                    mr: 1,
                    backgroundColor:
                        currentTranslation && currentTranslation.isOutdated
                            ? ERROR_TEXT_COLOR
                            : theme.palette.info.main,
                    '&:hover': {
                        backgroundColor:
                            currentTranslation && currentTranslation.isOutdated
                                ? ERROR_TEXT_COLOR
                                : theme.palette.info.main,
                    },
                }}
            >
                <Icon isActive />
            </IconButton>
            <Popover
                anchorEl={anchorEl}
                open={open}
                onClick={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        mt: 1,
                        p: 2,
                        minWidth: 200,
                        minHeight: 60,
                        borderRadius: 2,
                        overflow: 'visible',
                        boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                        backgroundColor: theme.palette.background.default,
                    },
                }}
            >
                <Typography>{t(popoverText)}</Typography>
            </Popover>
        </>
    );
};

export default TranslationButton;
