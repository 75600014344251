import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const CaseAccessRequestSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 13V9H15V13C14.9997 13.2651 14.8942 13.5193 14.7067 13.7067C14.5193 13.8942 14.2651 13.9997 14 14H2C1.73489 13.9997 1.48072 13.8942 1.29326 13.7067C1.10579 13.5193 1.00033 13.2651 1 13V3C1.00033 2.73489 1.10579 2.48072 1.29326 2.29326C1.48072 2.10579 1.73489 2.00033 2 2H7V3H5V13H14ZM4 3H2V13H4V3ZM8.70715 11H6V8.29285L9.015 5.27785C9.00519 5.18556 9.00018 5.09282 9 5C9 4.40666 9.17595 3.82664 9.50559 3.33329C9.83524 2.83994 10.3038 2.45543 10.8519 2.22836C11.4001 2.0013 12.0033 1.94189 12.5853 2.05765C13.1672 2.1734 13.7018 2.45912 14.1213 2.87868C14.5409 3.29824 14.8266 3.83279 14.9424 4.41473C15.0581 4.99667 14.9987 5.59987 14.7716 6.14805C14.5446 6.69623 14.1601 7.16477 13.6667 7.49441C13.1734 7.82405 12.5933 8 12 8C11.9072 7.99982 11.8144 7.99481 11.722 7.985L8.70715 11ZM7 10H8.29285L11.3781 6.91455L11.6369 6.9624C11.7565 6.98616 11.878 6.99875 12 7C12.4183 7.0052 12.8279 6.88076 13.1726 6.64376C13.5174 6.40676 13.7802 6.06884 13.9251 5.67641C14.0701 5.28399 14.0899 4.85633 13.9819 4.45217C13.874 4.04801 13.6436 3.6872 13.3223 3.41929C13.001 3.15139 12.6046 2.98954 12.1877 2.95599C11.7707 2.92243 11.3535 3.01881 10.9936 3.23189C10.6336 3.44497 10.3484 3.76429 10.1772 4.14598C10.006 4.52767 9.95721 4.95301 10.0375 5.36355L10.0851 5.62185L7 8.70715V10ZM12.25 5.5C12.6642 5.5 13 5.16421 13 4.75C13 4.33579 12.6642 4 12.25 4C11.8358 4 11.5 4.33579 11.5 4.75C11.5 5.16421 11.8358 5.5 12.25 5.5Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default CaseAccessRequestSvgIcon;
