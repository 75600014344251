import React, {FC} from 'react';

import Divider from '@mui/material/Divider';

import {BoardItemTypes} from 'appRedux/actions/workflow/types';

import {
    BOARD_TYPE_BOARD,
    BOARD_TYPE_LIST,
    BOARD_TYPE_OVERVIEW,
    BOARD_TYPE_SWIMLANE,
} from 'components/Forms/BoardForm/helper';
import EditBoardForm from 'components/Forms/BoardForm/EditBoardForm';
import EditBoardListStatusForm from 'components/Forms/BoardForm/EditBoardListStatusForm';
import BoardTypeBoardStatusesList from 'components/Forms/BoardForm/BoardTypeBoardStatusesList';
import UpdateOverviewBoardForm from 'components/Forms/BoardForm/UpdateOverviewBoardForm';
import EditBoardSwimlaneForm from 'components/Forms/BoardForm/EditBoardSwimlaneForm';

interface EditBoardFormSwitcherType {
    item: BoardItemTypes;
    closeModal: () => void;
    toggleIsOpened: () => void;
}

const EditBoardFormSwitcher: FC<EditBoardFormSwitcherType> = ({item, closeModal, toggleIsOpened}) => {
    const {type} = item;

    if (type === BOARD_TYPE_OVERVIEW) {
        return <UpdateOverviewBoardForm item={item} closeModal={closeModal} />;
    }
    return (
        <>
            {type === BOARD_TYPE_SWIMLANE ? <EditBoardSwimlaneForm item={item} /> : <EditBoardForm item={item} />}
            <Divider sx={{mt: 2, mb: 1, borderWidth: 1}} />
            {(type === BOARD_TYPE_BOARD || BOARD_TYPE_SWIMLANE) && (
                <BoardTypeBoardStatusesList item={item} toggleIsOpened={toggleIsOpened} />
            )}
            {type === BOARD_TYPE_LIST && <EditBoardListStatusForm item={item} />}
        </>
    );
};

export default EditBoardFormSwitcher;
