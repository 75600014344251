import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import Typography from '@mui/material/Typography';

const ApiComponent = () => {
    const location = useLocation();
    const apiUrl = `${location.pathname}${location.search}`;

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(apiUrl);
                if (!response.ok && response.status != 422) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result);
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message);
                } else {
                    setError('An unknown error occurred');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiUrl]);

    return (
        <div>
            <Typography
                sx={{
                    fontSize: 40,
                    fontWidth: 300,
                    lineHeight: '48px',
                    padding: '12px',
                }}
            >
                API Request
            </Typography>
            <Typography
                sx={{
                    fontSize: 18,
                    fontWidth: 300,
                    lineHeight: '24px',
                    padding: '12px',
                }}
            >
                Called: {apiUrl}
            </Typography>
            <Typography
                sx={{
                    fontSize: 12,
                    fontWidth: 300,
                    lineHeight: '16px',
                    padding: '12px',
                }}
            >
                {loading ? (
                    <div>Loading...</div>
                ) : error ? (
                    <pre>{error}</pre>
                ) : data ? (
                    <pre>{JSON.stringify(data, null, 2)}</pre>
                ) : (
                    <div>No data available</div>
                )}
            </Typography>
        </div>
    );
};

export default ApiComponent;
