import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import Box from '@material-ui/core/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import WarningSvgIcon from 'assets/icons/buttons/WarningSvgIcon';

import {RootReducer} from 'appRedux/reducers';
import {RESEND_EMAIL} from 'appRedux/actions/profile';
import {TWO_FACTOR_CANCEL} from 'appRedux/actions/auth';
import {SET_ERRORS} from 'appRedux/actions/errors';

import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {getSecurityRoute} from 'helpers/menuHelper';

import {theme, ERROR_TEXT_COLOR} from 'config/theme';

const ProfileNotification: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {showAlert} = useContext(AlertContext);
    const {isClientMode} = useContext(RouteContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const resendEmailAction = useCallback(data => dispatch({type: RESEND_EMAIL.REQUEST, payload: data}), [dispatch]);

    const cancelTwoFactor = useCallback(data => dispatch({type: TWO_FACTOR_CANCEL.REQUEST, payload: data}), [dispatch]);

    const refreshErrors = useCallback(data => dispatch({type: SET_ERRORS.GENERAL_ERROR, payload: data}), [dispatch]);

    const onSendAgainClicked = () => {
        resendEmailAction({
            showAlert,
            callback: () => setIsOpened(false),
        });
    };

    const onTwoFactorCancelClicked = () => {
        cancelTwoFactor({
            showAlert,
        });
    };

    const redirectToSecurity = () => {
        if (profile) {
            refreshErrors(null);
            navigate(getSecurityRoute(profile.roles, isClientMode));
            setIsOpened(false);
        }
    };

    if (!profile) return null;

    const {isVerify, isTwoFactorActivated, secretCodeExpiredTime} = profile;

    return (
        <Box sx={{mr: 1}}>
            <IconButton
                title={t('common.notifications')}
                sx={{
                    backgroundColor: ERROR_TEXT_COLOR,
                    '&:hover': {
                        backgroundColor: ERROR_TEXT_COLOR,
                    },
                }}
                onClick={toggleIsOpened}
            >
                <WarningSvgIcon isActive />
            </IconButton>
            <ModalWrapper isShowModal={isOpened} title={t('common.notifications')} toggleModal={toggleIsOpened}>
                {!isVerify && (
                    <Box
                        sx={{
                            border: `1px solid ${theme.palette.secondary.contrastText}`,
                            borderRadius: 4,
                            p: 2,
                            mb: 2,
                        }}
                    >
                        <Typography>{t('messages.warning.verifyEmailToAllowUploads')}</Typography>
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', mt: 3}}>
                            <AgentSaveButton
                                isSubmit={false}
                                title={t('common.buttons.sendItAgain')}
                                onClick={onSendAgainClicked}
                            />
                        </Box>
                    </Box>
                )}
                {!isTwoFactorActivated && secretCodeExpiredTime && (
                    <Box
                        sx={{
                            border: `1px solid ${theme.palette.secondary.contrastText}`,
                            borderRadius: 4,
                            p: 2,
                            mb: 1,
                        }}
                    >
                        <Typography>{t('messages.warning.twoFactorNotActivated')}</Typography>
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 3}}>
                            <AgentInverseButton title={t('common.buttons.cancel')} onClick={onTwoFactorCancelClicked} />
                            <AgentSaveButton
                                isSubmit={false}
                                title={t('common.buttons.settings')}
                                onClick={redirectToSecurity}
                            />
                        </Box>
                    </Box>
                )}
            </ModalWrapper>
        </Box>
    );
};

export default ProfileNotification;
