import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';

import {MessageTypes} from 'appRedux/actions/requestChat/types';

import {CaseKeyContext} from 'contexts/caseKey/context';

import {decryptStringWithKey} from 'helpers/cryptoApiHelper';

import {PARAMETER_MESSAGE_ID} from 'config/index';
import ChatMessage from './ChatMessage';

interface ChatMessageType {
    previousMessageSenderSame: boolean;
    userId: number | null;
    item: MessageTypes;
    isMessenger?: boolean;
}

const ChatMessageEncrypt: FC<ChatMessageType> = ({item, previousMessageSenderSame, isMessenger, userId}) => {
    const {caseId} = useParams();
    const [searchParams] = useSearchParams();

    const messageRef = useRef<HTMLDivElement>();

    const currentMessageId = searchParams.get(PARAMETER_MESSAGE_ID);

    const {unwrappedCaseKey} = useContext(CaseKeyContext);

    const [value, setValue] = useState<string>('');

    const {text, uuid} = item;

    useEffect(() => {
        if (currentMessageId && currentMessageId === uuid) {
            if (messageRef && messageRef.current) {
                messageRef.current.scrollIntoView({block: 'start', behavior: 'smooth'});
            }
        }
    }, [currentMessageId]);

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (text && unwrappedCaseKey) {
                try {
                    const decodedMessage = await decryptStringWithKey(text, unwrappedCaseKey);
                    setValue(decodedMessage);
                } catch (e) {
                    setValue('');
                }
            }
        }, 200);
        return () => clearTimeout(timer);
    }, [text, unwrappedCaseKey]);

    return (
        <ChatMessage
            item={item}
            caseId={Number(caseId)}
            userId={userId}
            previousMessageSenderSame={previousMessageSenderSame}
            text={value}
            isMessenger={isMessenger}
        />
    );
};

export default ChatMessageEncrypt;
