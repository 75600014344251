import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {WorkflowAttachmentTypes} from 'appRedux/actions/workflow/types';
import {DOWNLOAD_WORKFLOW_ATTACHMENT, DELETE_WORKFLOW_ATTACHMENT} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';

import {theme} from 'config/theme';

interface AttachmentsListRowType {
    item: WorkflowAttachmentTypes;
}

const AttachmentsListRow: FC<AttachmentsListRowType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {title, uuid} = item;

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const removeWorkflowAttachment = useCallback(
        data => dispatch({type: DELETE_WORKFLOW_ATTACHMENT.REQUEST, payload: data}),
        [dispatch],
    );

    const downloadAttachment = useCallback(
        data => dispatch({type: DOWNLOAD_WORKFLOW_ATTACHMENT.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        removeWorkflowAttachment({
            uuid,
            showAlert,
            callback: () => setIsOpened(false),
        });
    };

    const downloadFile = (url: string) => {
        const a = document.createElement('a');
        a.href = url;
        a.download = title;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const onDownloadClick = () => {
        downloadAttachment({
            uuid,
            showAlert,
            callback: downloadFile,
        });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 1,
            }}
        >
            <Typography sx={{color: theme.palette.primary.main, cursor: 'pointer'}} onClick={onDownloadClick}>
                {title}
            </Typography>
            <IconButton title={t('common.buttons.delete')} onClick={toggleIsOpened}>
                <DeleteSvgIcon />
            </IconButton>
            <DeleteAdminConfirmationModal
                toggleModal={toggleIsOpened}
                isShowModal={isOpened}
                onClickAction={onClickDelete}
            />
        </Box>
    );
};

export default AttachmentsListRow;
