import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import IconButton from '@mui/material/IconButton';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {DELETE_REQUESTER_DOCUMENT} from 'appRedux/actions/requestCase';

import DeleteClientConfirmationModal from 'components/ClientScreenComponents/DeleteClientConfirmationModal';

import {CommonFileType} from 'pages/agent/requesterPage/partials/helper';
import {useParams} from 'react-router-dom';
import {ClientFormContext} from 'contexts/clientForm/context';

interface DeleteFileButtonType {
    file: CommonFileType;
    isModalOpened: boolean;
    setIsDeleteModalOpened: (value: boolean) => void;
    toggleIsModalOpened: () => void;
    sectionId?: number;
}

const DeleteFileButton: FC<DeleteFileButtonType> = ({
    file,
    isModalOpened,
    setIsDeleteModalOpened,
    toggleIsModalOpened,
    sectionId,
}) => {
    const dispatch = useDispatch();
    const {requestCase} = useParams();

    const {toggleSectionChecked} = useContext(ClientFormContext);

    const {uuid} = file;

    const deleteDocument = useCallback(
        data => dispatch({type: DELETE_REQUESTER_DOCUMENT.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        deleteDocument({
            uuid,
            sectionId,
            toggleSectionChecked,
            caseId: Number(requestCase),
            callback: () => {
                setIsDeleteModalOpened(false);
            },
        });
    };

    return (
        <>
            <IconButton onClick={toggleIsModalOpened}>
                <DeleteSvgIcon />
            </IconButton>
            <DeleteClientConfirmationModal
                isShowModal={isModalOpened}
                toggleModal={toggleIsModalOpened}
                onClickAction={onClickDelete}
            />
        </>
    );
};

export default DeleteFileButton;
