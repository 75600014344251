import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';

import WorkflowSelectionRow from 'components/Forms/FormBuilder/FormWorkflowUpdateForm/WorkflowSelectionRow';

interface WorkflowStatusesSelectorType {
    currentWorkflowId: number;
    selectedWorkflowId: number;
    updateSelectedStatuses: (oldValue: number, newValue: number) => void;
}

const WorkflowStatusesSelector: FC<WorkflowStatusesSelectorType> = ({
    currentWorkflowId,
    selectedWorkflowId,
    updateSelectedStatuses,
}) => {
    const [t] = useTranslation();

    const {
        admin: {formWorkflowStatuses},
        workflow: {organizationWorkflows},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const selectedWorkflow = organizationWorkflows
        ? organizationWorkflows.find(item => item.id === selectedWorkflowId)
        : null;

    if (!selectedWorkflow) return null;

    return (
        <Box sx={{mt: 2, mb: 2}}>
            <Grid sx={{mb: 2}} container>
                <Grid item sm={5}>
                    <Typography sx={{fontWeight: 700}}>{t('orguser.forms.updateWorkflowForm.originStatus')}</Typography>
                </Grid>
                <Grid item sm={5}>
                    <Typography sx={{fontWeight: 700}}>{t('orguser.forms.updateWorkflowForm.mappedStatus')}</Typography>
                </Grid>
                <Grid item sm={2}>
                    <Typography sx={{fontWeight: 700}}>{t('orguser.forms.updateWorkflowForm.caseCount')}</Typography>
                </Grid>
            </Grid>
            {formWorkflowStatuses &&
                formWorkflowStatuses.map(workflowStatus => {
                    return (
                        <WorkflowSelectionRow
                            item={workflowStatus}
                            selectedWorkflowId={selectedWorkflowId}
                            isNewWorkflowSelected={currentWorkflowId !== selectedWorkflowId}
                            workflowStatuses={selectedWorkflow.workflowStatuses}
                            updateSelectedStatuses={updateSelectedStatuses}
                            key={`workflow-status-${workflowStatus.id}`}
                        />
                    );
                })}
        </Box>
    );
};

export default WorkflowStatusesSelector;
