import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import {GET_CASE_ACCESS_REQUESTS, GET_PUBLIC_KEYS_FOR_OTHER_AGENT} from 'appRedux/actions/crypto';
import {RootReducer} from 'appRedux/reducers';
import {CaseAccessRequestTypes} from 'appRedux/actions/crypto/types';
import {GET_REQUESTER_CASE} from 'appRedux/actions/requestCase';

import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

import CurrentCasePanel from 'pages/agent/chat/CurrentCasePanel';
import BottomPanel from 'pages/admin/updateAccessRequest/BottomPanel';

const UpdateAccessRequestComponent: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {uuid} = useParams();

    const {showAlert} = useContext(AlertContext);
    const {setBackLink, setBackLinkLabel, setTopBarTitle, setTopBarSubtitle} = useContext(RouteContext);

    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

    const getPublicKeysForAgent = useCallback(
        data => dispatch({type: GET_PUBLIC_KEYS_FOR_OTHER_AGENT.REQUEST, payload: data}),
        [dispatch],
    );

    const getCaseAccessRequests = useCallback(
        data => dispatch({type: GET_CASE_ACCESS_REQUESTS.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequesterCaseInfo = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        requestCase: {currentCase},
        crypto: {caseAccessRequests},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const currentCaseAccessRequest: CaseAccessRequestTypes | undefined = caseAccessRequests.find(
        item => item.uuid === uuid,
    );

    useEffect(() => {
        getCaseAccessRequests({
            showAlert,
        });
    }, []);

    useEffect(() => {
        setBackLink(null);
        setBackLinkLabel(null);
        setTopBarTitle('');
        setTopBarSubtitle('');
    }, []);

    useEffect(() => {
        if (currentCaseAccessRequest && currentCaseAccessRequest.caseId) {
            getRequesterCaseInfo({id: currentCaseAccessRequest.caseId});
            getPublicKeysForAgent({
                userId: currentCaseAccessRequest.userId,
                showAlert,
            });
        }
    }, [currentCaseAccessRequest]);

    if (!currentCaseAccessRequest || !currentCase) {
        return null;
    }

    const {firstName, lastName, email, caseLabel} = currentCaseAccessRequest;

    return (
        <>
            <CurrentCasePanel />
            <ClientSectionWrapper>
                <Typography>
                    {t('orguser.accessRequests.caseAccessRequestCreatedNotification')
                        .replace('%USER%', firstName && lastName ? `${lastName}, ${firstName}` : email)
                        .replace('%CASE%', caseLabel)}
                </Typography>
                {showErrorMessage ? (
                    <Alert severity="error" sx={{mt: 3, mb: 3}}>
                        <Typography>{t('orguser.accessRequests.accessRequestAllowImpossible')}</Typography>
                    </Alert>
                ) : (
                    <BottomPanel item={currentCaseAccessRequest} setShowErrorMessage={setShowErrorMessage} />
                )}
            </ClientSectionWrapper>
        </>
    );
};

export default UpdateAccessRequestComponent;
