import React, {FC, useCallback, useContext} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {FORM_ALLOW_LIST_CREATE} from 'appRedux/actions/forms';
import {FormAllowListFormTypes} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';

import FormWhiteListEmailForm from 'components/Forms/FormBuilder/WhiteListEmailForm/FormWhiteListEmailForm';
import {initialValues} from 'components/Forms/FormBuilder/WhiteListEmailForm/validation';

interface FormWhiteListEmailCreateFormType {
    closeModal: () => void;
    forDomain?: boolean;
}

const FormWhiteListEmailCreateForm: FC<FormWhiteListEmailCreateFormType> = ({closeModal, forDomain}) => {
    const {id} = useParams();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const submitCreateEmail = useCallback(
        data => dispatch({type: FORM_ALLOW_LIST_CREATE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: FormAllowListFormTypes, {setErrors}) => {
        submitCreateEmail({
            ...values,
            id,
            isDomain: Boolean(forDomain),
            showAlert,
            setErrors,
            callback: closeModal,
        });
    };

    return (
        <FormWhiteListEmailForm initialValues={initialValues} onSubmitAction={onSubmitClicked} forDomain={forDomain} />
    );
};

export default FormWhiteListEmailCreateForm;
