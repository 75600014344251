import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';
import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import {PanelItemsTypes} from 'appRedux/actions/analytics/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import SelectAnalyticPanelForm from 'components/Forms/Dashboard/SelectAnalyticPanelForm';

import {theme} from 'config/theme';

interface SelectHomepagePanelButtonType {
    homepagePanel?: PanelItemsTypes | null;
}

const SelectHomepagePanelButton: FC<SelectHomepagePanelButtonType> = ({homepagePanel}) => {
    const [t] = useTranslation();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpen(previous => !previous);
    };

    return (
        <>
            <IconButton
                title={t('orguser.dashboard.selectAnalyticPanel')}
                onClick={toggleModal}
                sx={{
                    ml: 2,
                    backgroundColor: theme.palette.info.main,
                    '&:hover': {
                        backgroundColor: theme.palette.info.main,
                    },
                }}
            >
                {homepagePanel ? <EditSvgIcon isActive /> : <PlusSvgIcon isActive />}
            </IconButton>
            <ModalWrapper
                title={t('orguser.dashboard.selectAnalyticPanel')}
                isShowModal={isOpen}
                toggleModal={toggleModal}
            >
                <SelectAnalyticPanelForm homepagePanel={homepagePanel} closeModal={() => setIsOpen(false)} />
            </ModalWrapper>
        </>
    );
};

export default SelectHomepagePanelButton;
