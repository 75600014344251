import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {GridColDef} from '@mui/x-data-grid';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {getResourceFieldTypeLabel, getResourceFieldScopeTypeLabel} from 'components/Forms/ResourceField/helper';

import DeleteResourceFieldButton from 'pages/admin/resourceFields/DeleteResourceFieldButton';

import {routes} from 'config/index';

const getResourceFieldsColumns = (): GridColDef[] | null => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const redirectToUpdatePage = (uuid: string) => {
        navigate(`${routes.RESOURCE_FIELD}/${uuid}/edit`);
    };

    return [
        {
            field: 'title',
            headerName: t('orguser.resourceFields.title'),
            minWidth: 280,
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{value}</Typography>;
            },
        },
        {
            field: 'workflowTitle',
            headerName: t('orguser.resourceFields.workflow'),
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{value}</Typography>;
            },
        },
        {
            field: 'type',
            headerName: t('orguser.resourceFields.type'),
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{t(getResourceFieldTypeLabel(value))}</Typography>;
            },
        },
        {
            field: 'scope',
            headerName: t('orguser.resourceFields.scope'),
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{t(getResourceFieldScopeTypeLabel(value))}</Typography>;
            },
        },
        {
            field: 'forms',
            headerName: t('orguser.resourceFields.relatedForms'),
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{value ? value.join(', ') : t('common.none')}</Typography>;
            },
        },
        {
            field: 'actions',
            headerName: t('common.action'),
            align: 'right',
            maxWidth: 90,
            flex: 1,
            renderCell: ({row}) => {
                return (
                    <Box>
                        <IconButton onClick={() => redirectToUpdatePage(row.uuid)}>
                            <EditSvgIcon />
                        </IconButton>
                        <DeleteResourceFieldButton item={row} />
                    </Box>
                );
            },
        },
    ];
};

export default getResourceFieldsColumns;
