import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {CREATE_ORGANIZATION_PRIVACY_POLICY} from 'appRedux/actions/organization';
import {OrganizationPrivacyPolicyFormTypes} from 'appRedux/actions/organization/types';

import {AlertContext} from 'contexts/alert/context';

import OrganizationPrivacyPolicyForm from 'components/Forms/OrganizationPrivacyPolicyForm/OrganizationPrivacyPolicyForm';

interface CreateOrganizationPrivacyPolicyFormType {
    organizationId: number;
    currentLanguage: string;
    alreadyHasPolicies: boolean;
    closeModal: () => void;
}

const CreateOrganizationPrivacyPolicyForm: FC<CreateOrganizationPrivacyPolicyFormType> = ({
    organizationId,
    currentLanguage,
    alreadyHasPolicies,
    closeModal,
}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const createOrganizationPrivacyPolicy = useCallback(
        data => dispatch({type: CREATE_ORGANIZATION_PRIVACY_POLICY.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitAction = (values: OrganizationPrivacyPolicyFormTypes) => {
        createOrganizationPrivacyPolicy({
            id: organizationId,
            lang: currentLanguage,
            ...values,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <OrganizationPrivacyPolicyForm
            initialValues={{title: '', isDefault: !alreadyHasPolicies}}
            onSubmitAction={onSubmitAction}
        />
    );
};

export default CreateOrganizationPrivacyPolicyForm;
