import {CommonFileType} from 'pages/agent/requesterPage/partials/helper';
import React from 'react';

export interface ChatFilesContextType {
    filesList: CommonFileType[];
    selectFile: (selected: CommonFileType) => void;
    downloadFile: (url: string, fileName?: string) => void;
}

export const ChatFilesContext = React.createContext({} as ChatFilesContextType);
