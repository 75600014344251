import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import BarChartIcon from '@mui/icons-material/BarChart';
import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';
import {GET_PANELS_LIST} from 'appRedux/actions/analytics';

import {AdminContext} from 'contexts/admin/context';
import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import DescriptionPopover from 'components/DescriptionPopover/DescriptionPopover';

import SelectHomepagePanelButton from 'pages/agent/dashboard/SelectHomepagePanelButton';
import DashboardPanelChartArea from 'pages/admin/analytics/panels/DashboardPanelChartArea';

import {checkSubdomainCorrect} from 'helpers/security';

import {theme} from 'config/theme';

const RequesterCaseStatistic: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {myOrganization} = useContext(AdminContext);
    const {showAlert} = useContext(AlertContext);
    const {isDashboardViewEnabled, isCaseViewEnabled} = useContext(PermissionContext);

    const {
        analytics: {panels},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getPanelsList = useCallback(data => dispatch({type: GET_PANELS_LIST.REQUEST, payload: data}), [dispatch]);

    const isSubdomainCorrect = checkSubdomainCorrect(myOrganization);

    useEffect(() => {
        if (isDashboardViewEnabled || isCaseViewEnabled) {
            getPanelsList({
                showAlert,
            });
        }
    }, []);

    const homepagePanel =
        panels && (isDashboardViewEnabled || isCaseViewEnabled) ? panels.find(item => item.isHomepagePanel) : null;

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    mb: 2.5,
                }}
            >
                <Typography sx={{fontWeight: 600, mr: 1}}>
                    {homepagePanel ? homepagePanel.title : t('orguser.dashboard.statistic')}
                </Typography>
                {homepagePanel ? <DescriptionPopover text={homepagePanel.description} /> : <BarChartIcon />}
                {panels && panels.length > 0 ? <SelectHomepagePanelButton homepagePanel={homepagePanel} /> : null}
            </Box>
            <Box sx={{mb: 3, borderRadius: 2, backgroundColor: theme.palette.background.default, padding: 1}}>
                {isSubdomainCorrect ? (
                    homepagePanel ? (
                        <Box sx={{p: 2}}>
                            <DashboardPanelChartArea item={homepagePanel} />
                        </Box>
                    ) : (
                        <Box sx={{p: 2, pl: 1}}>
                            <Typography sx={{fontWeight: 600}}>{t('orguser.dashboard.selectAnalyticPanel')}</Typography>
                        </Box>
                    )
                ) : (
                    <Typography>{t('messages.error.incorrectSubdomain')}</Typography>
                )}
            </Box>
        </Box>
    );
};

export default RequesterCaseStatistic;
