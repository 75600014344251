import React, {FC} from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface PanelTileItemType {
    label: string;
    text: string;
}
const PanelTileItem: FC<PanelTileItemType> = ({label, text}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                mb: 1,
            }}
        >
            <Typography sx={{fontWeight: 700, mr: 1}}>{`${label}:`}</Typography>
            <Typography>{text}</Typography>
        </Box>
    );
};

export default PanelTileItem;
