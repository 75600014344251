import React, {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';
import * as yup from 'yup';
import {useSelector} from 'react-redux';
import UAParser from 'ua-parser-js';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';

interface KeysNameFormType {
    handleSubmit: (value: {keyName: string}) => void;
}

const KeysNameForm: FC<KeysNameFormType> = ({handleSubmit}) => {
    const [t] = useTranslation();
    const {
        crypto: {currentUserPublicKeys},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const validationSchema = (t: (trans: string) => string) =>
        yup.object({
            keyName: yup
                .string()
                .notOneOf(
                    currentUserPublicKeys ? currentUserPublicKeys.map(key => key.title) : [''],
                    t('messages.validation.alreadyUsedKeyName'),
                )
                .required(t('messages.validation.isRequired')),
        });

    const defaultName = useMemo(() => {
        const parser = new UAParser();
        const browser = parser.getBrowser();
        const os = parser.getOS();

        return `${os.name} ${browser.name}.${browser.major}`;
    }, []);

    return (
        <>
            <Typography align="center" variant="body2" sx={{mt: 2}}>
                {t('common.loginForm.keyTitleExplanation')}
            </Typography>
            <Box
                sx={{
                    width: '100%',
                }}
            >
                <Formik
                    initialValues={{
                        keyName: defaultName,
                    }}
                    validationSchema={validationSchema(t)}
                    onSubmit={handleSubmit}
                >
                    {(formik: FormikProps<{keyName: string}>) => {
                        return (
                            <Form>
                                <Field
                                    name="keyName"
                                    label={t('common.loginForm.keyTitle')}
                                    component={FormikTextInput}
                                    data-id={`input#generateCryptoKeysTitle`}
                                />
                                <Box
                                    sx={{
                                        mt: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <AgentSaveButton
                                        isSubmit
                                        data-id={`button#generateCryptoKeysSave`}
                                        title={t('common.buttons.generateKeys')}
                                    />
                                </Box>
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </>
    );
};

export default KeysNameForm;
