import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';

import Divider from '@mui/material/Divider';

import {GET_RESOURCE_FIELD_SLOT_INFO, GET_RESOURCE_FIELD_SLOT_CASES} from 'appRedux/actions/resourceFields';

import {AlertContext} from 'contexts/alert/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

import SlotInformation from 'pages/admin/updateResourceField/slotCases/SlotInformation';
import SlotRelatedCasesList from 'pages/admin/updateResourceField/slotCases/SlotRelatedCasesList';

import {DEFAULT_PAGE, PARAMETER_PAGE, PARAMETER_PER_PAGE, ITEMS_PER_PAGE_FOR_CASES} from 'config/index';

const SlotCasesList: FC = () => {
    const dispatch = useDispatch();
    const {uuid} = useParams();
    const [searchParams] = useSearchParams();

    const {showAlert} = useContext(AlertContext);

    const pageFromUrl = searchParams.get(PARAMETER_PAGE) ? searchParams.get(PARAMETER_PAGE) : DEFAULT_PAGE;
    const perPageFromUrl = searchParams.get(PARAMETER_PER_PAGE)
        ? searchParams.get(PARAMETER_PER_PAGE)
        : ITEMS_PER_PAGE_FOR_CASES;

    const getResourceFieldSlotInfo = useCallback(
        data => dispatch({type: GET_RESOURCE_FIELD_SLOT_INFO.REQUEST, payload: data}),
        [dispatch],
    );

    const getResourceFieldSlotCases = useCallback(
        data => dispatch({type: GET_RESOURCE_FIELD_SLOT_CASES.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        getResourceFieldSlotInfo({
            uuid,
            showAlert,
        });
    }, [uuid]);

    useEffect(() => {
        getResourceFieldSlotCases({
            uuid,
            showAlert,
            page: pageFromUrl,
            perPage: perPageFromUrl,
        });
    }, [uuid, pageFromUrl, perPageFromUrl]);

    return (
        <ClientSectionWrapper>
            <SlotInformation />
            <Divider sx={{mt: 3}} />
            <SlotRelatedCasesList />
        </ClientSectionWrapper>
    );
};

export default SlotCasesList;
