export function getVisibleHeight(element?: HTMLDivElement | null) {
    if (!element) return 0;
    const rect = element.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    const topVisible = rect.top >= 0 && rect.top < viewportHeight;
    const bottomVisible = rect.bottom > 0 && rect.bottom <= viewportHeight;

    if (topVisible && bottomVisible) {
        // Element is completely within viewport
        return rect.height;
    } else if (topVisible) {
        // Top part is visible
        return viewportHeight - rect.top;
    } else if (bottomVisible) {
        // Bottom part is visible
        return rect.bottom;
    } else if (rect.top < 0 && rect.bottom > viewportHeight) {
        // Element is larger than viewport and spans it entirely
        return viewportHeight;
    } else {
        // Element is not visible
        return 0;
    }
}
