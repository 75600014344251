import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';

import {FORM_FILTERS_CLEAR} from 'appRedux/actions/forms';

import {RouteContext} from 'contexts/route/context';

import Filter from 'components/AdminScreenComponents/Filter';
import DaterangeFilter from 'components/AdminScreenComponents/DaterangeFilter';
import {TableFilterTypes} from 'components/AdminScreenComponents/types';
import {isAnyFilterSet} from 'components/AdminScreenComponents/translationsHelper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {theme} from 'config/theme';
import {DEFAULT_PAGE, PARAMETER_TAB, routes} from 'config/index';

interface FiltersBlockType {
    filters: TableFilterTypes[];
    setCurrentPageValue?: (value: number) => void;
}

const FiltersBlock: FC<FiltersBlockType> = ({filters, setCurrentPageValue}) => {
    const [t] = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {isAdminPage, isSuperAdminPage} = useContext(RouteContext);

    const clearFormFieldFilters = useCallback(() => dispatch({type: FORM_FILTERS_CLEAR.REQUEST}), [dispatch]);

    const clearAllFilters = () => {
        setCurrentPageValue && setCurrentPageValue(DEFAULT_PAGE);
        const currentTab = searchParams.get(PARAMETER_TAB);
        let route;
        if (isSuperAdminPage) {
            route = routes.SUPER_ADMIN;
        } else if (isAdminPage) {
            route = routes.ADMIN;
        } else {
            route = routes.AGENT;
        }
        navigate(currentTab ? `${route}/?tab=${currentTab}` : route);
        clearFormFieldFilters();
    };

    if (filters.length === 0) {
        return null;
    }
    return (
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mr: 5, mb: 2}}>
            {filters.map((filter: TableFilterTypes, index: number) => {
                if (filter.type === 'daterangepicker') {
                    const {from, to, label, handleFilterChange} = filter;
                    return (
                        <DaterangeFilter
                            key={`filter-daterangepicker-${index}`}
                            from={from}
                            to={to}
                            label={label}
                            handleFilterChange={handleFilterChange}
                        />
                    );
                } else {
                    const {name, label, defaultValue, parameter, options, handleFilterChange} = filter;
                    return (
                        <Filter
                            key={`filter-${name}-${index}`}
                            optionList={options || []}
                            handleFilterChange={handleFilterChange}
                            filterName={name}
                            filterLabel={label}
                            parameter={parameter || ''}
                            defaultValue={defaultValue}
                        />
                    );
                }
            })}
            {isAnyFilterSet(filters) && (
                <Box sx={{mt: 2.5}}>
                    <AgentSaveButton
                        isSubmit={false}
                        title={t('common.buttons.clearAll')}
                        icon={<CloseIcon sx={{color: theme.palette.background.default}} />}
                        onClick={clearAllFilters}
                    />
                </Box>
            )}
        </Box>
    );
};

export default FiltersBlock;
