import React, {FC, useCallback, useContext} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import CloseSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {
    WorkflowStatusRuleItemTypes,
    AttachWorkflowStatusRuleApproversGroupFormTypes,
} from 'appRedux/actions/workflow/types';
import {ATTACH_WORKFLOW_RULE_APPROVERS_GROUP} from 'appRedux/actions/workflow';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import {theme} from 'config/theme';

interface WorkflowRuleApproversGroupFormType {
    item: WorkflowStatusRuleItemTypes;
    closeForm: () => void;
}

const WorkflowRuleApproversGroupForm: FC<WorkflowRuleApproversGroupFormType> = ({item, closeForm}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        groups: {groups},
        workflow: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onSubmitClicked = useCallback(
        data => dispatch({type: ATTACH_WORKFLOW_RULE_APPROVERS_GROUP.REQUEST, payload: data}),
        [dispatch],
    );

    const {uuid} = item;

    const onSaveClicked = (values: AttachWorkflowStatusRuleApproversGroupFormTypes) => {
        onSubmitClicked({
            ...values,
            showAlert,
            uuid,
            callback: closeForm,
        });
    };

    const groupsNotAttached = groups
        ? groups.filter(group => !item.groups.some(attachedGroup => Number(attachedGroup.id) === Number(group.id)))
        : [];

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                mt: 2,
                mb: 1,
                '& form': {
                    width: '100%',
                },
            }}
        >
            <Formik
                initialValues={{
                    group: 0,
                }}
                onSubmit={onSaveClicked}
            >
                {(formik: FormikProps<AttachWorkflowStatusRuleApproversGroupFormTypes>) => {
                    return (
                        <Form>
                            <FormControl sx={{mt: 1}} required fullWidth>
                                <InputLabel>{t('orguser.workflows.rules.group')}</InputLabel>
                                <Select
                                    label={t('orguser.workflows.rules.group')}
                                    name={'group'}
                                    value={formik.values.group}
                                    onChange={e => {
                                        formik.handleChange(e);
                                        formik.submitForm();
                                    }}
                                    IconComponent={KeyboardArrowDownIcon}
                                    sx={{
                                        '& div': {
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                        },
                                    }}
                                    fullWidth
                                >
                                    {groupsNotAttached &&
                                        groupsNotAttached.map(group => {
                                            const {id, title, uuid} = group;
                                            return (
                                                <MenuItem
                                                    key={`group-${uuid}`}
                                                    value={id}
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-start',
                                                        mb: 1,
                                                    }}
                                                >
                                                    <Typography sx={{ml: 1, fontWeight: 600}}>{title}</Typography>
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </Form>
                    );
                }}
            </Formik>
            <IconButton
                sx={{
                    backgroundColor: theme.palette.info.main,
                    ml: 2,
                    '&:hover': {
                        backgroundColor: theme.palette.info.main,
                    },
                }}
                title={t('common.buttons.close')}
                onClick={closeForm}
                disabled={isLoading}
            >
                <CloseSvgIcon isActive isDisabled={isLoading} />
            </IconButton>
        </Box>
    );
};

export default WorkflowRuleApproversGroupForm;
