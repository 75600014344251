import React, {FC, useContext, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import {RootReducer} from 'appRedux/reducers';
import {PanelItemsTypes} from 'appRedux/actions/analytics/types';

import {RouteContext} from 'contexts/route/context';

import {DISPLAY_TYPE_BAR_CHART} from 'components/Forms/Dashboard/helper';

import BarStatisticChart from 'pages/admin/analytics/charts/BarStatisticChart';
import CircleStatisticChart from 'pages/admin/analytics/charts/CircleStatisticChart';
import InformationAboutPanel from 'pages/admin/analytics/dashboardPage/InformationAboutPanel';

import {theme} from 'config/theme';

const DashboardWithPanelsPage: FC = () => {
    const {uuid} = useParams();

    const {refreshPageInformation, setPageTitle} = useContext(RouteContext);

    const {
        analytics: {dashboards},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const currentDashboard = dashboards ? dashboards.find(item => item.uuid === String(uuid)) : null;

    useEffect(() => {
        refreshPageInformation();
        if (uuid && currentDashboard) {
            setPageTitle(currentDashboard.title);
        }
    }, [currentDashboard, uuid]);

    if (!currentDashboard) return null;

    return (
        <Grid container spacing={3}>
            {currentDashboard.panels.map((item: PanelItemsTypes) => {
                const {title, displayType, uuid} = item;
                return (
                    <Grid item sm={12} md={6} key={`panel-${uuid}`}>
                        <Box
                            sx={{
                                backgroundColor: theme.palette.background.default,
                                borderRadius: 4,
                                p: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    width: '100%',
                                    mb: 1,
                                }}
                            >
                                <Typography sx={{fontWeight: 700}}>{title}</Typography>
                                <InformationAboutPanel item={item} />
                            </Box>
                            <Box>
                                {displayType === DISPLAY_TYPE_BAR_CHART ? (
                                    <BarStatisticChart item={item} />
                                ) : (
                                    <CircleStatisticChart item={item} />
                                )}
                            </Box>
                        </Box>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default DashboardWithPanelsPage;
