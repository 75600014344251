import React, {FC} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import {
    BoardHasStatusItemTypes,
    CreateBoardOverviewRequestTypes,
    UpdateBoardOverviewRequestTypes,
} from 'appRedux/actions/workflow/types';
import {RootReducer} from 'appRedux/reducers';

import {iconTypes} from 'components/Forms/BoardForm/helper';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import StatusesSelectors from 'components/Forms/BoardForm/StatusesSelectors';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationSchemaForOverview} from 'components/Forms/BoardForm/validation';

import {IconImageType} from 'pages/agent/savedFilters/helper';

interface OverviewBoardFormType {
    initialValues: CreateBoardOverviewRequestTypes | UpdateBoardOverviewRequestTypes;
    statuses: BoardHasStatusItemTypes[];
    setStatuses: (values: BoardHasStatusItemTypes[]) => void;
    onSubmitClick: (values: CreateBoardOverviewRequestTypes | UpdateBoardOverviewRequestTypes, {setErrors}) => void;
}

const OverviewBoardForm: FC<OverviewBoardFormType> = ({initialValues, statuses, setStatuses, onSubmitClick}) => {
    const [t] = useTranslation();

    const {
        workflow: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchemaForOverview} onSubmit={onSubmitClick}>
            {(formik: FormikProps<CreateBoardOverviewRequestTypes | UpdateBoardOverviewRequestTypes>) => {
                return (
                    <Form>
                        <Box>
                            <Field
                                required
                                name="title"
                                placeholder={t('orguser.boards.parameters.title')}
                                component={FormikTextInput}
                            />
                        </Box>
                        <FormControl sx={{mt: 2}} required fullWidth>
                            <InputLabel>{t('orguser.boards.parameters.iconType')}</InputLabel>
                            <Select
                                label={t('orguser.boards.parameters.iconType')}
                                name={'iconType'}
                                value={formik.values.iconType}
                                onChange={formik.handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                fullWidth
                            >
                                {iconTypes.map(({type, icon}: IconImageType) => {
                                    const Icon = icon;
                                    return (
                                        <MenuItem value={type} key={`icon-type-${type}`}>
                                            <IconButton>
                                                <Icon />
                                            </IconButton>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <StatusesSelectors statuses={statuses} setStatuses={setStatuses} />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: 3,
                            }}
                        >
                            <AgentSaveButton isSubmit isLoading={isLoading} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default OverviewBoardForm;
