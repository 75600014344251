import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {CryptoModalType, KeysType} from 'contexts/crypto/context';

import ImportKeysWithPasswordForm from 'components/Forms/SettingsForms/AddCryptoKeysForm/ImportKeysWithPasswordForm';
import KeysNameForm from 'components/Forms/SettingsForms/AddCryptoKeysForm/KeysNameForm';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ImportKeysFromServerForm from 'components/Forms/SettingsForms/AddCryptoKeysForm/ImportKeysFromServerForm';

interface AddCryptoKeysFormType {
    handleImport: (keyPairRecord: KeysType) => void;
    handleGenerate: (value: {keyName: string}) => void;
    modalType: CryptoModalType | false;
}

const AddCryptoKeysForm: FC<AddCryptoKeysFormType> = ({handleImport, handleGenerate, modalType}) => {
    const [t] = useTranslation();

    const [showForm, setShowForm] = useState<string>('');

    return (
        <Box>
            <Typography align="center" variant="body2" sx={{mt: 0, mb: 4}}>
                {t('common.loginForm.addKeysDescription')}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    alignItems: 'start',
                    mt: 0,
                    mb: 4,
                }}
            >
                <AgentSaveButton
                    onClick={() => setShowForm('generate')}
                    data-id={`button#generateCryptoKeys`}
                    title={t('common.loginForm.generateCryptoKeysLabel')}
                />
                {modalType && [CryptoModalType.IMPORT, CryptoModalType.DOWNLOAD].includes(modalType) && (
                    <AgentSaveButton
                        onClick={() => setShowForm('import')}
                        data-id={`button#importCryptoKeys`}
                        title={t('common.loginForm.importCryptoKeysLabel')}
                    />
                )}
                {modalType === CryptoModalType.DOWNLOAD && (
                    <AgentSaveButton
                        onClick={() => setShowForm('download')}
                        data-id={`button#restoreCryptoKeysLabel`}
                        title={t('common.loginForm.restoreCryptoKeysLabel')}
                    />
                )}
            </Box>
            {showForm === 'generate' && (
                <>
                    <Typography align="center" variant="body2" sx={{fontWeight: 600, mt: 2}}>
                        {t('common.loginForm.generateCryptoKeysLabel')}
                    </Typography>
                    <Typography align="center" variant="body2" sx={{mt: 2}}>
                        {t('common.loginForm.generateCryptoKeysDescription')}
                    </Typography>
                    <KeysNameForm handleSubmit={handleGenerate} />
                </>
            )}
            {showForm === 'import' && (
                <>
                    <Typography align="center" variant="body2" sx={{fontWeight: 600, mt: 2}}>
                        {t('common.loginForm.importCryptoKeysLabel')}
                    </Typography>
                    <Typography align="center" variant="body2" sx={{mt: 2}}>
                        {t('common.loginForm.importCryptoKeysDescription')}
                    </Typography>
                    <ImportKeysWithPasswordForm handleImport={handleImport} />
                </>
            )}
            {showForm === 'download' && (
                <>
                    <Typography align="center" variant="body2" sx={{fontWeight: 600, mt: 2}}>
                        {t('common.loginForm.restoreCryptoKeysLabel')}
                    </Typography>
                    <Typography align="center" variant="body2" sx={{mt: 2}}>
                        {t('common.loginForm.restoreCryptoKeysDescription')}
                    </Typography>
                    <ImportKeysFromServerForm handleImport={handleImport} />
                </>
            )}
        </Box>
    );
};

export default AddCryptoKeysForm;
