import React, {FC, useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';

import {MediaContext} from 'contexts/media/context';
import {CryptoContext} from 'contexts/crypto/context';

import {theme} from 'config/theme';

import useParsedTranslation from 'hooks/useParsedTranslation';
import {exportPublicKey} from 'helpers/cryptoApiHelper';

const CryptoKeysAccessNeeded: FC = () => {
    const [t] = useTranslation();

    const {isMobile} = useContext(MediaContext);
    const {keys} = useContext(CryptoContext);

    const [currentBrowserKey, setCurrentBrowserKey] = useState<string>('');

    useEffect(() => {
        if (keys) {
            const fetchData = async () => {
                const browserPublicKey = await exportPublicKey(keys.publicKey);
                setCurrentBrowserKey(browserPublicKey);
            };
            fetchData().catch(console.error);
        }
    }, [keys]);

    const {
        auth: {organization},
        crypto: {currentUserPublicKeys},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const hasAllCases = useMemo(() => {
        if (!currentBrowserKey) return true;

        const filteredPublicKeys = currentUserPublicKeys?.filter(key => key.organization === organization?.name);

        const caseKeysIds = filteredPublicKeys?.reduce((acc: number[], key) => {
            const keys = key.caseKeys?.map(caseKey => caseKey.caseId);
            if (!keys) return acc;
            return [...acc, ...keys];
        }, []);

        const currentKeysIds = currentUserPublicKeys
            ?.find(pubKey => pubKey.data === currentBrowserKey)
            ?.caseKeys?.map(caseKey => caseKey.caseId);

        if (!currentKeysIds || !caseKeysIds?.length) return true;

        return !caseKeysIds.find(key => !currentKeysIds?.includes(key));
    }, [currentUserPublicKeys, currentBrowserKey]);

    if (hasAllCases) return null;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                backgroundColor: theme.palette.secondary.dark,
                minHeight: 56,
                p: 1,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography sx={{color: theme.palette.info.main}}>
                    {useParsedTranslation(t, 'requester.casesList.howToGetFullAccessExplanation')}
                </Typography>
            </Box>
        </Box>
    );
};

export default CryptoKeysAccessNeeded;
