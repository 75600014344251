import React, {ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootReducer} from 'appRedux/reducers';
import {GET_REQUESTER_CASE_CHAT_FILES} from 'appRedux/actions/requestCase';

import {ChatFilesContextType, ChatFilesContext} from 'contexts/chatFiles/context';
import {CaseKeyContext} from 'contexts/caseKey/context';
import {AlertContext} from 'contexts/alert/context';

import {getAllChatFiles, getFileData} from 'components/FilesListWithGallery/helper';
import FilesGallery from 'components/FilesListWithGallery/FilesGallery';

import {CommonFileType} from 'pages/agent/requesterPage/partials/helper';

import {getEncryptedFileData} from 'helpers/cryptoApiHelper';

interface ContextType {
    children: ReactNode;
}

const ChatFilesContextWrapper: React.FC<ContextType> = ({children}) => {
    const dispatch = useDispatch();

    const {unwrappedCaseKey} = useContext(CaseKeyContext);
    const {showAlert} = useContext(AlertContext);

    const {
        requestCase: {
            currentCase: {id, isEncryptInfo},
            currentCaseChatFiles,
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const [isPreviewOpened, setIsPreviewOpened] = useState<boolean>(false);
    const [filesList, setFilesList] = useState<CommonFileType[]>([]);
    const [filesCache, setFilesCache] = useState<Map<string, string>>(new Map());
    const [selectedFile, setSelectedFile] = useState<CommonFileType>();
    const [documentIsImage, setDocumentIsImage] = useState<boolean>(false);
    const [documentIsPdf, setDocumentIsPdf] = useState<boolean>(false);
    const [documentImageSource, setDocumentImageSource] = useState<string>();

    const toggleIsPreviewOpened = () => {
        setIsPreviewOpened(previous => !previous);
    };

    const getCaseChatFilesList = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE_CHAT_FILES.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        const timer = setTimeout(async () => {
            try {
                const allFiles: CommonFileType[] = await getAllChatFiles(
                    currentCaseChatFiles,
                    isEncryptInfo,
                    unwrappedCaseKey,
                );
                setFilesList(allFiles);
            } catch (e) {
                setFilesList([]);
            }
        }, 200);
        return () => clearTimeout(timer);
    }, [currentCaseChatFiles.length, isEncryptInfo, unwrappedCaseKey]);

    const selectFile = (selected: CommonFileType) => {
        if (!selected) return;
        const {uuid, fileLink, isImage, extension, encryptPrefix} = selected;

        setDocumentIsImage(isImage);
        setDocumentIsPdf(extension.toLowerCase().includes('pdf'));

        const fileSource = filesCache.get(uuid);
        setDocumentImageSource(fileSource || '');
        setSelectedFile(selected);

        setIsPreviewOpened(true);

        if (!fileLink || fileSource) return;

        if (encryptPrefix) {
            getEncryptedFileData(fileLink, encryptPrefix, unwrappedCaseKey).then(decryptedFileSource => {
                setDocumentImageSource(decryptedFileSource || '');
                decryptedFileSource && setFilesCache(prev => prev.set(uuid, decryptedFileSource));
            });
        } else {
            getFileData(fileLink).then(decryptedFileSource => {
                setDocumentImageSource(decryptedFileSource || '');
                decryptedFileSource && setFilesCache(prev => prev.set(uuid, decryptedFileSource));
            });
        }
    };

    const downloadFile = (url: string, fileName?: string) => {
        const a = document.createElement('a');
        a.href = url;
        document.body.appendChild(a);
        if (fileName) {
            a.download = fileName;
        }
        a.click();
        document.body.removeChild(a);
    };

    const context: ChatFilesContextType = {
        selectFile,
        filesList,
        downloadFile,
    };

    useEffect(() => {
        if (id) {
            getCaseChatFilesList({
                id,
                showAlert,
            });
        }
    }, [id]);

    return (
        <ChatFilesContext.Provider value={context}>
            <>
                {children}
                {isPreviewOpened && selectedFile && (
                    <FilesGallery
                        isModalOpened={isPreviewOpened}
                        toggleIsModalOpened={toggleIsPreviewOpened}
                        documentIsImage={documentIsImage}
                        documentIsPdf={documentIsPdf}
                        selectedFile={selectedFile}
                        documentImageSource={documentImageSource}
                        allFiles={[selectedFile]}
                        downloadFile={downloadFile}
                    />
                )}
            </>
        </ChatFilesContext.Provider>
    );
};

export default ChatFilesContextWrapper;
