import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {DELETE_RESOURCE_FIELD_BOOKFRAME} from 'appRedux/actions/resourceFields';
import {ResourceFieldBookframeItemTypes} from 'appRedux/actions/resourceFields/types';

import {AlertContext} from 'contexts/alert/context';

import {getResourceFieldWeekdayLabel} from 'components/Forms/ResourceField/helper';
import UpdateResourceFieldBookframeForm from 'components/Forms/ResourceField/UpdateResourceFieldBookframeForm';

import CalendarDetailsItem from 'pages/admin/updateResourceField/calendar/CalendarDetailsItem';

interface BookframesListItemType {
    item: ResourceFieldBookframeItemTypes;
}

const BookframesListItem: FC<BookframesListItemType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isShowUpdateModal, setIsShowUpdateModal] = useState<boolean>(false);

    const toggleUpdateModal = () => {
        setIsShowUpdateModal(previous => !previous);
    };

    const {weekday, startTime, endTime, uuid} = item;

    const removeBookframe = useCallback(
        data => dispatch({type: DELETE_RESOURCE_FIELD_BOOKFRAME.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        removeBookframe({
            uuid,
            showAlert,
        });
    };

    return (
        <CalendarDetailsItem
            isShowUpdateModal={isShowUpdateModal}
            toggleUpdateModal={toggleUpdateModal}
            title={t(getResourceFieldWeekdayLabel(weekday))}
            subtitle={`${startTime} - ${endTime}`}
            onClickDelete={onClickDelete}
            updateForm={<UpdateResourceFieldBookframeForm item={item} closeModal={() => setIsShowUpdateModal(false)} />}
            modalTitle={`${t('orguser.resourceFields.updateResourceField')} (${t(
                'orguser.resourceFields.calendarTypes.bookframes',
            )})`}
        />
    );
};

export default BookframesListItem;
