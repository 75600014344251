import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {RootReducer} from 'appRedux/reducers';

import LoginPageContent from 'pages/start/login/partials/LoginPageContent';

const LoginNoSsoScreen = () => {
    const {
        auth: {organization},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        if (organization && !organization.isAutoSsoEnabled) {
            window.location.href = '/';
        }
    }, [organization]);

    return <LoginPageContent />;
};

export default LoginNoSsoScreen;
