import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

import {RootReducer} from 'appRedux/reducers';
import {UPDATE_DEFAULT_ROLE} from 'appRedux/actions/profile';

import {AlertContext} from 'contexts/alert/context';

import StartScreenWrapper from 'components/StartScreenComponents/StartScreenWrapper';
import MainLogoWithLink from 'components/StartScreenComponents/MainLogoWithLink';
import EmptyArea from 'components/EmptyArea';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {getRedirectRoute} from 'pages/start/login/helper';
import LanguageDropdownSwitcher from 'pages/start/login/partials/LanguageDropdownSwitcher';

import {theme} from 'config/theme';
import {routes, userRoles, TYPE_MULTI_ROLE_ORGANIZATION_USER, TYPE_MULTI_ROLE_REQUESTER} from 'config/index';

const SelectDefaultRolePage: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {showAlert} = useContext(AlertContext);

    const [selectRemember, setSelectRemember] = useState<boolean>(false);
    const [, setIsUpdated] = useState<boolean>(false);

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const updateDefaultRole = useCallback(
        data => dispatch({type: UPDATE_DEFAULT_ROLE.REQUEST, payload: data}),
        [dispatch],
    );

    const onRememberMeClick = () => {
        setSelectRemember(previous => !previous);
        setIsUpdated(previous => !previous);
    };

    const redirectToPage = (roles: string[], organizationMultiRoleType: number, defaultMode: number) => {
        const route = getRedirectRoute(roles, organizationMultiRoleType, defaultMode);
        navigate(route);
    };

    const onLoginClick = (isRequester?: boolean) => {
        if (selectRemember) {
            updateDefaultRole({
                typeMultiRole: isRequester ? TYPE_MULTI_ROLE_REQUESTER : TYPE_MULTI_ROLE_ORGANIZATION_USER,
                showAlert,
                callback: redirectToPage,
            });
        } else if (isRequester) {
            navigate(routes.REQUESTS_LIST);
        } else if (profile && profile.roles.includes(userRoles.ROLE_ADMIN)) {
            navigate(routes.ADMIN);
        } else if (profile && profile.roles.includes(userRoles.ROLE_AGENT)) {
            navigate(routes.AGENT);
        }
    };

    return (
        <StartScreenWrapper>
            <MainLogoWithLink />
            <LanguageDropdownSwitcher />
            <EmptyArea />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    mt: 4,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'space-between',
                        justifyContent: 'center',
                        mb: 2,
                    }}
                >
                    <AgentSaveButton
                        isSubmit={false}
                        title={t('common.loginForm.loginAsOrgUser')}
                        onClick={() => onLoginClick()}
                    />
                    <AgentSaveButton
                        isSubmit={false}
                        title={t('common.loginForm.loginAsRequester')}
                        onClick={() => onLoginClick(true)}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mb: 2,
                    }}
                >
                    <Checkbox
                        checked={selectRemember}
                        onChange={onRememberMeClick}
                        sx={{
                            '& svg': {color: theme.palette.info.main},
                        }}
                    />
                    <Typography>{t('common.loginForm.rememberMe')}</Typography>
                </Box>
            </Box>
        </StartScreenWrapper>
    );
};

export default SelectDefaultRolePage;
