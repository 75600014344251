import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {BoardItemTypes} from 'appRedux/actions/workflow/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import {BOARD_TYPE_OVERVIEW} from 'components/Forms/BoardForm/helper';

import EditBoardFormSwitcher from 'pages/admin/boardsList/EditBoardFormSwitcher';

interface EditBoardButtonType {
    item: BoardItemTypes;
}

const EditBoardButton: FC<EditBoardButtonType> = ({item}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {type} = item;

    return (
        <>
            <IconButton title={t('common.buttons.update')} onClick={toggleIsOpened}>
                <EditSvgIcon />
            </IconButton>
            <ModalWrapper
                title={t('orguser.boards.updateBoard')}
                isShowModal={isOpened}
                toggleModal={toggleIsOpened}
                isWideModal={type === BOARD_TYPE_OVERVIEW}
            >
                <EditBoardFormSwitcher
                    item={item}
                    closeModal={() => setIsOpened(false)}
                    toggleIsOpened={toggleIsOpened}
                />
            </ModalWrapper>
        </>
    );
};

export default EditBoardButton;
