import React, {FC, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import {SET_ERRORS} from 'appRedux/actions/errors';
import {RootReducer} from 'appRedux/reducers';

import mobileMenuLogo from 'assets/images/mobileMenuLogo.png';
import CancelSvgIcon from 'assets/icons/buttons/CloseSvgIcon';
import MainLogo from 'assets/mainLogo';

import AccountMobileMenu from 'components/ClientScreenComponents/_mobile/AccountMobileMenu';
import PagesMobileMenu from 'components/ClientScreenComponents/_mobile/PagesMobileMenu';
import AvatarNameAndRole from 'components/TopBarMenu/AvatarNameAndRole';
import EmptyArea from 'components/EmptyArea';

import {theme} from 'config/theme';
import {routes} from 'config/index';

interface ClientMobileMenuType {
    toggleMenu: () => void;
}

const bottomTextStyles = {
    color: theme.palette.background.default,
    fontSize: 18,
};

const ClientMobileMenu: FC<ClientMobileMenuType> = ({toggleMenu}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const refreshErrors = useCallback(data => dispatch({type: SET_ERRORS.GENERAL_ERROR, payload: data}), [dispatch]);

    const {
        auth: {organization},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const redirectToLink = (link: string) => {
        refreshErrors(null);
        navigate(link);
        toggleMenu();
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                overflowX: 'hidden',
                width: '100vw',
                pt: 1,
                pr: 1,
                pl: 4,
                pb: 3,
                height: window.innerHeight,
                backgroundColor: theme.palette.info.main,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    width: '100%',
                    mb: 3,
                }}
            >
                <IconButton onClick={toggleMenu}>
                    <CancelSvgIcon isActive />
                </IconButton>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    ml: 1,
                }}
            >
                <AvatarNameAndRole isMobileMenu />
            </Box>
            <EmptyArea />
            <PagesMobileMenu redirectToLink={redirectToLink} />
            <EmptyArea />
            <AccountMobileMenu redirectToLink={redirectToLink} />
            <EmptyArea />
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Typography sx={{mb: 2, ...bottomTextStyles}}>{t('common.helpRequestFor')}</Typography>
                    <Typography sx={{...bottomTextStyles, fontWeight: 600}}>
                        {organization ? organization.name : 'Herupu'}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <MainLogo isMobileMenu />
                </Grid>
            </Grid>
            <EmptyArea />
            <Grid container>
                <Grid item xs={4}>
                    <img src={mobileMenuLogo} alt="Logo" />
                </Grid>
                <Grid item xs={4}>
                    <Link
                        to={routes.RULE_ROUTE_IMPRINT}
                        style={{textDecoration: 'none', color: theme.palette.background.default}}
                    >
                        <Typography>{t('common.imprint')}</Typography>
                    </Link>
                </Grid>
                <Grid item xs={4}>
                    <Link
                        to={routes.RULE_ROUTE_PRIVACY_POLICY}
                        style={{textDecoration: 'none', color: theme.palette.background.default}}
                    >
                        <Typography>{t('common.privacyPolicy')}</Typography>
                    </Link>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ClientMobileMenu;
