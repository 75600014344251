import React, {FC, Ref, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import Box from '@mui/material/Box';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import CreateWorkflowStatusEventForm from 'components/Forms/Workflow/workflowStatusEvent/CreateWorkflowStatusEventForm';

interface CreateWorkflowStatusEventButtonType {
    statusTitle: string;
    type: number;
    eventTypeLabel: string;
    typeLabel: string;
    forEmailTemplate?: boolean;
    forWebhookTemplate?: boolean;
}

const CreateWorkflowStatusEventButton: FC<CreateWorkflowStatusEventButtonType> = ({
    statusTitle,
    type,
    typeLabel,
    eventTypeLabel,
    forEmailTemplate,
    forWebhookTemplate,
}) => {
    const [t] = useTranslation();
    const {uuid} = useParams();

    const [, isUpdated] = useState<boolean>(false);

    const formRef = useRef<Ref<any> | null>(null);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpened(previous => !previous);
    };

    const submitStatusEventForm = () => {
        if (formRef && formRef.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formRef.current.handleSubmit();
            isUpdated(previous => !previous);
        }
    };

    return (
        <>
            <AgentSaveButton isSubmit={false} title={t('common.buttons.add')} onClick={toggleModal} />
            <ModalWrapper
                title={`${t('orguser.workflows.addWorkflowStatusEventTemplate')} - ${t(
                    eventTypeLabel,
                )} "${statusTitle}" (${t(typeLabel)})`}
                toggleModal={toggleModal}
                isShowModal={isOpened}
                isWideModal
            >
                <CreateWorkflowStatusEventForm
                    formRef={formRef}
                    type={type}
                    workflowStatusUuid={String(uuid)}
                    closeModal={() => setIsOpened(false)}
                    forEmailTemplate={forEmailTemplate}
                    forWebhookTemplate={forWebhookTemplate}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt: 3,
                    }}
                >
                    <AgentSaveButton isSubmit={false} onClick={submitStatusEventForm} />
                </Box>
            </ModalWrapper>
        </>
    );
};

export default CreateWorkflowStatusEventButton;
