import {requestTypeCreator} from 'helpers/index';

import {
    AgentSavedFilterTypes,
    AgentSavedFilterDeleteRequestTypes,
    AgentSavedFilterRequestTypes,
} from 'appRedux/actions/agentFilters/types';
import {FormFieldFilterResponseType} from 'appRedux/actions/forms/types';

export const AGENT_FILTERS_LIST = requestTypeCreator('AGENT_FILTERS_LIST');
export const AGENT_FILTER_TAGS_LIST = requestTypeCreator('AGENT_FILTER_TAGS_LIST');
export const AGENT_FILTERS_LIST_CREATE = requestTypeCreator('AGENT_FILTERS_LIST_CREATE');
export const AGENT_FILTERS_LIST_UPDATE = requestTypeCreator('AGENT_FILTERS_LIST_UPDATE');
export const AGENT_FILTERS_LIST_DELETE = requestTypeCreator('AGENT_FILTERS_LIST_DELETE');

export const getAgentFiltersList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: AGENT_FILTERS_LIST.ERROR,
    }),
    request: () => ({
        type: AGENT_FILTERS_LIST.REQUEST,
    }),
    success: (payload: AgentSavedFilterTypes[]): IActionType<AgentSavedFilterTypes[]> => ({
        payload,
        type: AGENT_FILTERS_LIST.SUCCESS,
    }),
};

export const getAgentTagsFiltersList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: AGENT_FILTER_TAGS_LIST.ERROR,
    }),
    request: () => ({
        type: AGENT_FILTER_TAGS_LIST.REQUEST,
    }),
    success: (payload: FormFieldFilterResponseType): IActionType<FormFieldFilterResponseType> => ({
        payload,
        type: AGENT_FILTER_TAGS_LIST.SUCCESS,
    }),
};

export const createAgentFilter = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: AGENT_FILTERS_LIST_CREATE.ERROR,
    }),
    request: (payload: AgentSavedFilterRequestTypes): IActionType<AgentSavedFilterRequestTypes> => ({
        payload,
        type: AGENT_FILTERS_LIST_CREATE.REQUEST,
    }),
    success: (payload: AgentSavedFilterTypes[]): IActionType<AgentSavedFilterTypes[]> => ({
        payload,
        type: AGENT_FILTERS_LIST_CREATE.SUCCESS,
    }),
};

export const updateAgentFilter = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: AGENT_FILTERS_LIST_UPDATE.ERROR,
    }),
    request: (payload: AgentSavedFilterRequestTypes): IActionType<AgentSavedFilterRequestTypes> => ({
        payload,
        type: AGENT_FILTERS_LIST_UPDATE.REQUEST,
    }),
    success: (payload: AgentSavedFilterTypes[]): IActionType<AgentSavedFilterTypes[]> => ({
        payload,
        type: AGENT_FILTERS_LIST_UPDATE.SUCCESS,
    }),
};

export const deleteAgentFilter = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: AGENT_FILTERS_LIST_DELETE.ERROR,
    }),
    request: (payload: AgentSavedFilterDeleteRequestTypes): IActionType<AgentSavedFilterDeleteRequestTypes> => ({
        payload,
        type: AGENT_FILTERS_LIST_DELETE.REQUEST,
    }),
    success: (payload: AgentSavedFilterTypes[]): IActionType<AgentSavedFilterTypes[]> => ({
        payload,
        type: AGENT_FILTERS_LIST_DELETE.SUCCESS,
    }),
};
