import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const GroupSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" transform="translate(0 0.513184)" fill="white" fillOpacity="0.01" />
            <path
                d="M13 7.51318H12V8.51318H13C13.3977 8.51362 13.779 8.6718 14.0602 8.95301C14.3414 9.23422 14.4996 9.61549 14.5 10.0132V12.0132H15.5V10.0132C15.4992 9.35038 15.2356 8.71494 14.7669 8.24626C14.2982 7.77759 13.6628 7.51395 13 7.51318Z"
                fill={getIconColor(isActive, isDisabled)}
            />
            <path
                d="M12 2.51318C12.2967 2.51318 12.5867 2.60116 12.8334 2.76598C13.08 2.9308 13.2723 3.16507 13.3858 3.43916C13.4994 3.71325 13.5291 4.01485 13.4712 4.30582C13.4133 4.59679 13.2704 4.86407 13.0607 5.07384C12.8509 5.28362 12.5836 5.42648 12.2926 5.48436C12.0017 5.54224 11.7001 5.51253 11.426 5.399C11.1519 5.28547 10.9176 5.09321 10.7528 4.84654C10.588 4.59987 10.5 4.30986 10.5 4.01318C10.5 3.61536 10.658 3.23383 10.9393 2.95252C11.2206 2.67122 11.6022 2.51318 12 2.51318ZM12 1.51318C11.5055 1.51318 11.0222 1.65981 10.6111 1.93451C10.2 2.20921 9.87952 2.59966 9.6903 3.05647C9.50108 3.51329 9.45157 4.01596 9.54804 4.50091C9.6445 4.98586 9.8826 5.43132 10.2322 5.78095C10.5819 6.13058 11.0273 6.36868 11.5123 6.46515C11.9972 6.56161 12.4999 6.5121 12.9567 6.32288C13.4135 6.13366 13.804 5.81323 14.0787 5.40211C14.3534 4.99099 14.5 4.50764 14.5 4.01318C14.5 3.35014 14.2366 2.71426 13.7678 2.24542C13.2989 1.77658 12.663 1.51318 12 1.51318Z"
                fill={getIconColor(isActive, isDisabled)}
            />
            <path
                d="M11.5 15.5132H10.5V14.5132C10.4996 14.1155 10.3414 13.7342 10.0602 13.453C9.77897 13.1718 9.39769 13.0136 9 13.0132H7C6.60231 13.0136 6.22103 13.1718 5.93982 13.453C5.65861 13.7342 5.50044 14.1155 5.5 14.5132V15.5132H4.5V14.5132C4.50078 13.8504 4.76442 13.215 5.2331 12.7463C5.70177 12.2776 6.3372 12.014 7 12.0132H9C9.6628 12.014 10.2982 12.2776 10.7669 12.7463C11.2356 13.215 11.4992 13.8504 11.5 14.5132V15.5132Z"
                fill={getIconColor(isActive, isDisabled)}
            />
            <path
                d="M8 7.01318C8.29667 7.01318 8.58668 7.10116 8.83335 7.26598C9.08003 7.4308 9.27229 7.66507 9.38582 7.93916C9.49935 8.21325 9.52906 8.51485 9.47118 8.80582C9.4133 9.09679 9.27044 9.36407 9.06066 9.57384C8.85088 9.78362 8.58361 9.92648 8.29264 9.98436C8.00166 10.0422 7.70006 10.0125 7.42597 9.899C7.15189 9.78547 6.91762 9.59321 6.7528 9.34654C6.58797 9.09986 6.5 8.80986 6.5 8.51318C6.5 8.11536 6.65804 7.73383 6.93934 7.45252C7.22064 7.17122 7.60218 7.01318 8 7.01318ZM8 6.01318C7.50555 6.01318 7.0222 6.15981 6.61107 6.43451C6.19995 6.70921 5.87952 7.09966 5.6903 7.55648C5.50108 8.01329 5.45157 8.51596 5.54804 9.00091C5.6445 9.48586 5.8826 9.93132 6.23223 10.281C6.58186 10.6306 7.02732 10.8687 7.51227 10.9651C7.99723 11.0616 8.49989 11.0121 8.95671 10.8229C9.41352 10.6337 9.80397 10.3132 10.0787 9.90211C10.3534 9.49099 10.5 9.00764 10.5 8.51318C10.5 7.85014 10.2366 7.21426 9.76777 6.74542C9.29893 6.27658 8.66304 6.01318 8 6.01318Z"
                fill={getIconColor(isActive, isDisabled)}
            />
            <path
                d="M4 7.51318H3C2.3372 7.51396 1.70177 7.77761 1.2331 8.24628C0.764423 8.71495 0.500781 9.35038 0.5 10.0132V12.0132H1.5V10.0132C1.50044 9.61549 1.65861 9.23422 1.93982 8.95301C2.22103 8.6718 2.60231 8.51362 3 8.51318H4V7.51318Z"
                fill={getIconColor(isActive, isDisabled)}
            />
            <path
                d="M4 2.51318C4.29667 2.51318 4.58668 2.60116 4.83336 2.76598C5.08003 2.9308 5.27229 3.16507 5.38582 3.43916C5.49935 3.71325 5.52906 4.01485 5.47118 4.30582C5.4133 4.59679 5.27044 4.86407 5.06066 5.07384C4.85088 5.28362 4.58361 5.42648 4.29264 5.48436C4.00166 5.54224 3.70006 5.51253 3.42597 5.399C3.15189 5.28547 2.91762 5.09321 2.7528 4.84654C2.58797 4.59987 2.5 4.30986 2.5 4.01318C2.5 3.61536 2.65804 3.23383 2.93934 2.95252C3.22064 2.67122 3.60218 2.51318 4 2.51318ZM4 1.51318C3.50555 1.51318 3.0222 1.65981 2.61107 1.93451C2.19995 2.20921 1.87952 2.59966 1.6903 3.05647C1.50108 3.51329 1.45157 4.01596 1.54804 4.50091C1.6445 4.98586 1.8826 5.43132 2.23223 5.78095C2.58186 6.13058 3.02732 6.36868 3.51227 6.46515C3.99723 6.56161 4.49989 6.5121 4.95671 6.32288C5.41352 6.13366 5.80397 5.81323 6.07867 5.40211C6.35338 4.99099 6.5 4.50764 6.5 4.01318C6.5 3.35014 6.23661 2.71426 5.76777 2.24542C5.29893 1.77658 4.66304 1.51318 4 1.51318Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default GroupSvgIcon;
