import {requestTypeCreator} from 'helpers/index';
import {
    HelpStartRequestFormTypes,
    AuthResponseTypes,
    LoginRequestTypes,
    LoginRedirectRequestTypes,
    ForgetNicknameRequestTypes,
    ResetPasswordRequestTypes,
    FormBaseInformationTypes,
    FormBaseInformationInviteRequestTypes,
    FormBaseInformationRequestTypes,
    OrganizationBaseInformationRequestTypes,
    OrganizationBaseInformationTypes,
    TwoFactorCodesRegenerationTypes,
    TwoFactorCodeDownloadRequestTypes,
    TwoFactorConfirmationTypes,
    TwoFactorCancelRequestTypes,
    RefreshTokenRequestTypes,
    RecoveryPasswordRequestTypes,
    LogoutRequestTypes,
    UserSessionTypes,
    UserLoginAttemptsTypes,
    UserSessionsListRequestTypes,
    UserSessionRemoveRequestTypes,
    GetRedirectRequestTypes,
    OpenIdAuthTypes,
    LoginSsoRequestTypes,
    RegistrationSsoRequestTypes,
    RequesterCaseAnonymousCreateRequestTypes,
    UserVerificationRequestTypes,
} from 'appRedux/actions/auth/types';

export const HELP_START = requestTypeCreator('HELP_START');
export const CREATE_ANONYMOUS_REQUESTER_CASE = requestTypeCreator('CREATE_ANONYMOUS_REQUESTER_CASE');
export const LOGIN = requestTypeCreator('LOGIN');
export const LOGIN_REDIRECT = requestTypeCreator('LOGIN_REDIRECT');
export const GET_REDIRECT_URL = requestTypeCreator('GET_REDIRECT_URL');
export const RESET_PASSWORD = requestTypeCreator('RESET_PASSWORD');
export const FORGET_NICKNAME = requestTypeCreator('FORGET_NICKNAME');

export const LOAD_KEYS = requestTypeCreator('LOAD_KEYS');

export const GET_USER_SESSIONS = requestTypeCreator('GET_USER_SESSIONS');
export const REMOVE_USER_SESSION = requestTypeCreator('REMOVE_USER_SESSION');
export const GET_USER_LOGIN_ATTEMPTS = requestTypeCreator('GET_USER_LOGIN_ATTEMPTS');

export const LOGOUT = requestTypeCreator('LOGOUT');

export const FORM_BASE_INFORMATION = requestTypeCreator('FORM_BASE_INFORMATION');
export const FORM_BASE_INFORMATION_AUTH = requestTypeCreator('FORM_BASE_INFORMATION_AUTH');
export const FORM_BASE_INVITE_INFORMATION = requestTypeCreator('FORM_BASE_INVITE_INFORMATION');
export const FORM_BASE_INVITE_INFORMATION_AUTH = requestTypeCreator('FORM_BASE_INVITE_INFORMATION_AUTH');

export const ORGANIZATION_BASE_INFORMATION = requestTypeCreator('ORGANIZATION_BASE_INFORMATION');
export const ORGANIZATION_BASE_INFORMATION_LANGUAGE = requestTypeCreator('ORGANIZATION_BASE_INFORMATION_LANGUAGE');

export const TWO_FACTOR_FIRST_STEP_PASSED = requestTypeCreator('TWO_FACTOR_FIRST_STEP_PASSED');
export const TWO_FACTOR_CONFIRMATION = requestTypeCreator('TWO_FACTOR_CONFIRMATION');
export const TWO_FACTOR_ACTIVATION = requestTypeCreator('TWO_FACTOR_ACTIVATION');
export const TWO_FACTOR_REGENERATION = requestTypeCreator('TWO_FACTOR_REGENERATION');
export const TWO_FACTOR_CODES_DOWNLOAD = requestTypeCreator('TWO_FACTOR_CODES_DOWNLOAD');
export const TWO_FACTOR_DEACTIVATE = requestTypeCreator('TWO_FACTOR_DEACTIVATE');
export const TWO_FACTOR_CANCEL = requestTypeCreator('TWO_FACTOR_CANCEL');

export const REFRESH_USER_TOKEN = requestTypeCreator('REFRESH_USER_TOKEN');

export const RECOVERY_PASSWORD = requestTypeCreator('RECOVERY_PASSWORD');

export const OPEN_ID_LOGIN = requestTypeCreator('OPEN_ID_LOGIN');
export const OPEN_ID_AUTH = requestTypeCreator('OPEN_ID_AUTH');
export const OPEN_ID_REGISTRATION = requestTypeCreator('OPEN_ID_REGISTRATION');

export const USER_VERIFICATION = requestTypeCreator('USER_VERIFICATION');

export const helpStart = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: HELP_START.ERROR,
    }),
    request: (payload: HelpStartRequestFormTypes): IActionType<HelpStartRequestFormTypes> => ({
        payload,
        type: HELP_START.REQUEST,
    }),
    success: (payload: AuthResponseTypes): IActionType<AuthResponseTypes> => ({
        payload,
        type: HELP_START.SUCCESS,
    }),
};

export const createAnonymousRequesterCase = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_ANONYMOUS_REQUESTER_CASE.ERROR,
    }),
    request: (
        payload: RequesterCaseAnonymousCreateRequestTypes,
    ): IActionType<RequesterCaseAnonymousCreateRequestTypes> => ({
        payload,
        type: CREATE_ANONYMOUS_REQUESTER_CASE.REQUEST,
    }),
    success: (payload: AuthResponseTypes): IActionType<AuthResponseTypes> => ({
        payload,
        type: CREATE_ANONYMOUS_REQUESTER_CASE.SUCCESS,
    }),
};

export const login = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: LOGIN.ERROR,
    }),
    request: (payload: LoginRequestTypes): IActionType<LoginRequestTypes> => ({
        payload,
        type: LOGIN.REQUEST,
    }),
    success: (payload: AuthResponseTypes): IActionType<AuthResponseTypes> => ({
        payload,
        type: LOGIN.SUCCESS,
    }),
};

export const loginRedirect = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: LOGIN_REDIRECT.ERROR,
    }),
    request: (payload: LoginRedirectRequestTypes): IActionType<LoginRedirectRequestTypes> => ({
        payload,
        type: LOGIN_REDIRECT.REQUEST,
    }),
    success: (payload: AuthResponseTypes): IActionType<AuthResponseTypes> => ({
        payload,
        type: LOGIN_REDIRECT.SUCCESS,
    }),
};

export const getRedirectUrl = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_REDIRECT_URL.ERROR,
    }),
    request: (payload: GetRedirectRequestTypes): IActionType<GetRedirectRequestTypes> => ({
        payload,
        type: GET_REDIRECT_URL.REQUEST,
    }),
    success: () => ({
        type: GET_REDIRECT_URL.SUCCESS,
    }),
};

export const resetPassword = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: RESET_PASSWORD.ERROR,
    }),
    request: (payload: ResetPasswordRequestTypes): IActionType<ResetPasswordRequestTypes> => ({
        payload,
        type: RESET_PASSWORD.REQUEST,
    }),
    success: () => ({
        type: RESET_PASSWORD.SUCCESS,
    }),
};

export const forgetNickname = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORGET_NICKNAME.ERROR,
    }),
    request: (payload: ForgetNicknameRequestTypes): IActionType<ForgetNicknameRequestTypes> => ({
        payload,
        type: FORGET_NICKNAME.REQUEST,
    }),
    success: () => ({
        type: FORGET_NICKNAME.SUCCESS,
    }),
};

export const logoutAction = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: LOGOUT.ERROR,
    }),
    request: (payload: LogoutRequestTypes): IActionType<LogoutRequestTypes> => ({
        payload,
        type: LOGOUT.REQUEST,
    }),
    success: () => ({
        type: LOGOUT.SUCCESS,
    }),
};

export const getUserSessions = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_USER_SESSIONS.ERROR,
    }),
    request: (payload: UserSessionsListRequestTypes): IActionType<UserSessionsListRequestTypes> => ({
        payload,
        type: GET_USER_SESSIONS.REQUEST,
    }),
    success: (payload: UserSessionTypes[]): IActionType<UserSessionTypes[]> => ({
        payload,
        type: GET_USER_SESSIONS.SUCCESS,
    }),
};

export const removeUserSession = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REMOVE_USER_SESSION.ERROR,
    }),
    request: (payload: UserSessionRemoveRequestTypes): IActionType<UserSessionRemoveRequestTypes> => ({
        payload,
        type: REMOVE_USER_SESSION.REQUEST,
    }),
    success: (payload: UserSessionTypes[]): IActionType<UserSessionTypes[]> => ({
        payload,
        type: REMOVE_USER_SESSION.SUCCESS,
    }),
};

export const getUserLoginAttempts = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_USER_LOGIN_ATTEMPTS.ERROR,
    }),
    request: () => ({
        type: GET_USER_LOGIN_ATTEMPTS.REQUEST,
    }),
    success: (payload: UserLoginAttemptsTypes[]): IActionType<UserLoginAttemptsTypes[]> => ({
        payload,
        type: GET_USER_LOGIN_ATTEMPTS.SUCCESS,
    }),
};

export const getFormBaseInformation = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_BASE_INFORMATION.ERROR,
    }),
    request: (payload: FormBaseInformationRequestTypes): IActionType<FormBaseInformationRequestTypes> => ({
        payload,
        type: FORM_BASE_INFORMATION.REQUEST,
    }),
    success: (payload: FormBaseInformationTypes): IActionType<FormBaseInformationTypes> => ({
        payload,
        type: FORM_BASE_INFORMATION.SUCCESS,
    }),
};

export const getFormBaseInformationAuth = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_BASE_INFORMATION_AUTH.ERROR,
    }),
    request: (payload: FormBaseInformationRequestTypes): IActionType<FormBaseInformationRequestTypes> => ({
        payload,
        type: FORM_BASE_INFORMATION_AUTH.REQUEST,
    }),
    success: (payload: FormBaseInformationTypes): IActionType<FormBaseInformationTypes> => ({
        payload,
        type: FORM_BASE_INFORMATION_AUTH.SUCCESS,
    }),
};

export const getFormBaseInviteInformation = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_BASE_INVITE_INFORMATION.ERROR,
    }),
    request: (payload: FormBaseInformationInviteRequestTypes): IActionType<FormBaseInformationInviteRequestTypes> => ({
        payload,
        type: FORM_BASE_INVITE_INFORMATION.REQUEST,
    }),
    success: (payload: FormBaseInformationTypes): IActionType<FormBaseInformationTypes> => ({
        payload,
        type: FORM_BASE_INVITE_INFORMATION.SUCCESS,
    }),
};

export const getFormBaseInviteInformationAuth = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: FORM_BASE_INVITE_INFORMATION_AUTH.ERROR,
    }),
    request: (payload: FormBaseInformationInviteRequestTypes): IActionType<FormBaseInformationInviteRequestTypes> => ({
        payload,
        type: FORM_BASE_INVITE_INFORMATION_AUTH.REQUEST,
    }),
    success: (payload: FormBaseInformationTypes): IActionType<FormBaseInformationTypes> => ({
        payload,
        type: FORM_BASE_INVITE_INFORMATION_AUTH.SUCCESS,
    }),
};

export const getOrganizationBaseInformation = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_BASE_INFORMATION.ERROR,
    }),
    request: () => ({
        type: ORGANIZATION_BASE_INFORMATION.REQUEST,
    }),
    success: (
        payload: OrganizationBaseInformationTypes | null,
    ): IActionType<OrganizationBaseInformationTypes | null> => ({
        payload,
        type: ORGANIZATION_BASE_INFORMATION.SUCCESS,
    }),
};

export const getOrganizationBaseInformationByLanguage = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_BASE_INFORMATION_LANGUAGE.ERROR,
    }),
    request: (
        payload: OrganizationBaseInformationRequestTypes,
    ): IActionType<OrganizationBaseInformationRequestTypes> => ({
        payload,
        type: ORGANIZATION_BASE_INFORMATION_LANGUAGE.REQUEST,
    }),
    success: (
        payload: OrganizationBaseInformationTypes | null,
    ): IActionType<OrganizationBaseInformationTypes | null> => ({
        payload,
        type: ORGANIZATION_BASE_INFORMATION_LANGUAGE.SUCCESS,
    }),
};

export const twoFactorFirstStepPassed = {
    success: () => ({
        type: TWO_FACTOR_FIRST_STEP_PASSED.SUCCESS,
    }),
};

export const twoFactorConfirmation = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: TWO_FACTOR_CONFIRMATION.ERROR,
    }),
    request: (payload: TwoFactorConfirmationTypes): IActionType<TwoFactorConfirmationTypes> => ({
        payload,
        type: TWO_FACTOR_CONFIRMATION.REQUEST,
    }),
    success: (payload: AuthResponseTypes): IActionType<AuthResponseTypes> => ({
        payload,
        type: TWO_FACTOR_CONFIRMATION.SUCCESS,
    }),
};

export const twoFactorActivation = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: TWO_FACTOR_ACTIVATION.ERROR,
    }),
    request: (payload: TwoFactorConfirmationTypes): IActionType<TwoFactorConfirmationTypes> => ({
        payload,
        type: TWO_FACTOR_ACTIVATION.REQUEST,
    }),
    success: (payload: AuthResponseTypes): IActionType<AuthResponseTypes> => ({
        payload,
        type: TWO_FACTOR_ACTIVATION.SUCCESS,
    }),
};

export const twoFactorDeactivation = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: TWO_FACTOR_DEACTIVATE.ERROR,
    }),
    request: (payload: TwoFactorConfirmationTypes): IActionType<TwoFactorConfirmationTypes> => ({
        payload,
        type: TWO_FACTOR_DEACTIVATE.REQUEST,
    }),
    success: (payload: AuthResponseTypes): IActionType<AuthResponseTypes> => ({
        payload,
        type: TWO_FACTOR_DEACTIVATE.SUCCESS,
    }),
};

export const twoFactorRegeneration = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: TWO_FACTOR_REGENERATION.ERROR,
    }),
    request: (payload: TwoFactorCodesRegenerationTypes): IActionType<TwoFactorCodesRegenerationTypes> => ({
        payload,
        type: TWO_FACTOR_REGENERATION.REQUEST,
    }),
    success: (payload: AuthResponseTypes): IActionType<AuthResponseTypes> => ({
        payload,
        type: TWO_FACTOR_REGENERATION.SUCCESS,
    }),
};

export const twoFactorCodesDownload = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: TWO_FACTOR_CODES_DOWNLOAD.ERROR,
    }),
    request: (payload: TwoFactorCodeDownloadRequestTypes): IActionType<TwoFactorCodeDownloadRequestTypes> => ({
        payload,
        type: TWO_FACTOR_CODES_DOWNLOAD.REQUEST,
    }),
    success: (payload: Blob | string): IActionType<Blob | string> => ({
        payload,
        type: TWO_FACTOR_CODES_DOWNLOAD.SUCCESS,
    }),
};

export const twoFactorCancel = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: TWO_FACTOR_CANCEL.ERROR,
    }),
    request: (payload: TwoFactorCancelRequestTypes): IActionType<TwoFactorCancelRequestTypes> => ({
        payload,
        type: TWO_FACTOR_CANCEL.REQUEST,
    }),
    success: (payload: AuthResponseTypes): IActionType<AuthResponseTypes> => ({
        payload,
        type: TWO_FACTOR_CANCEL.SUCCESS,
    }),
};

export const refreshUserToken = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REFRESH_USER_TOKEN.ERROR,
    }),
    request: (payload: RefreshTokenRequestTypes): IActionType<RefreshTokenRequestTypes> => ({
        payload,
        type: REFRESH_USER_TOKEN.REQUEST,
    }),
    success: () => ({
        type: REFRESH_USER_TOKEN.SUCCESS,
    }),
};

export const recoveryPassword = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: RECOVERY_PASSWORD.ERROR,
    }),
    request: (payload: RecoveryPasswordRequestTypes): IActionType<RecoveryPasswordRequestTypes> => ({
        payload,
        type: RECOVERY_PASSWORD.REQUEST,
    }),
    success: () => ({
        type: RECOVERY_PASSWORD.SUCCESS,
    }),
};

export const openIdLogin = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: OPEN_ID_LOGIN.ERROR,
    }),
    request: (payload: LoginSsoRequestTypes): IActionType<LoginSsoRequestTypes> => ({
        payload,
        type: OPEN_ID_LOGIN.REQUEST,
    }),
    success: () => ({
        type: OPEN_ID_LOGIN.SUCCESS,
    }),
};

export const openIdAuth = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: OPEN_ID_AUTH.ERROR,
    }),
    request: (payload: OpenIdAuthTypes): IActionType<OpenIdAuthTypes> => ({
        payload,
        type: OPEN_ID_AUTH.REQUEST,
    }),
    success: (payload: AuthResponseTypes): IActionType<AuthResponseTypes> => ({
        payload,
        type: OPEN_ID_AUTH.SUCCESS,
    }),
};

export const openIdRegistration = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: OPEN_ID_REGISTRATION.ERROR,
    }),
    request: (payload: RegistrationSsoRequestTypes): IActionType<RegistrationSsoRequestTypes> => ({
        payload,
        type: OPEN_ID_REGISTRATION.REQUEST,
    }),
    success: (payload: AuthResponseTypes): IActionType<AuthResponseTypes> => ({
        payload,
        type: OPEN_ID_REGISTRATION.SUCCESS,
    }),
};

export const userVerification = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: USER_VERIFICATION.ERROR,
    }),
    request: (payload: UserVerificationRequestTypes): IActionType<UserVerificationRequestTypes> => ({
        payload,
        type: USER_VERIFICATION.REQUEST,
    }),
    success: () => ({
        type: USER_VERIFICATION.SUCCESS,
    }),
};
