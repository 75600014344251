import React, {FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import {FORM_PAGE_UPDATE} from 'appRedux/actions/forms';
import {RootReducer} from 'appRedux/reducers';
import {FormPageTypes} from 'appRedux/actions/forms/types';

import PageForm from 'components/Forms/FormBuilder/PageForm/Form';
import {formPageInitialValues} from 'components/Forms/FormBuilder/PageForm/validation';

interface UpdatePageFormType {
    pageId: number;
    toggleModal: () => void;
}

const UpdatePageForm: FC<UpdatePageFormType> = ({pageId, toggleModal}) => {
    const dispatch = useDispatch();

    const {
        admin: {formInfo},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const pages: FormPageTypes[] = get(formInfo, 'pages', []);

    const submitUpdateForm = useCallback(data => dispatch({type: FORM_PAGE_UPDATE.REQUEST, payload: data}), [dispatch]);

    const initialValues = {...formPageInitialValues, ...pages.find(item => item.id === pageId)};

    return (
        <PageForm
            entityId={pageId}
            toggleModal={toggleModal}
            onSubmitClicked={submitUpdateForm}
            initialValues={initialValues}
        />
    );
};

export default UpdatePageForm;
