import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@material-ui/core/Box';

import {RootReducer} from 'appRedux/reducers';
import {CaseWatcherTypes} from 'appRedux/actions/requestCase/types';

import FormInformationRowWrapper from 'pages/agent/requesterPage/wrappers/FormInformationRowWrapper';
import AddWatcherButton from 'pages/agent/requesterPage/watchers/AddWatcherButton';
import WatcherItem from 'pages/agent/requesterPage/watchers/WatcherItem';

const Watchers: FC = () => {
    const [t] = useTranslation();

    const {
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {watchers} = currentCase;

    return (
        <FormInformationRowWrapper title={t('orguser.requesterCase.watchers.watchers')} alignTop>
            <Box sx={{mb: watchers.length > 0 ? 1 : 0}}>
                {watchers &&
                    Array.isArray(watchers) &&
                    watchers.map((watcher: CaseWatcherTypes) => {
                        return <WatcherItem item={watcher} key={`watcher-${watcher.uuid}`} />;
                    })}
            </Box>
            <AddWatcherButton />
        </FormInformationRowWrapper>
    );
};

export default Watchers;
