import React, {FC, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';

import Box from '@mui/material/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';

import {RestoreCryptoKeysTypes} from 'appRedux/actions/auth/types';
import {RootReducer} from 'appRedux/reducers';
import {GET_USER_SAVED_KEYS} from 'appRedux/actions/crypto';

import {KeysType} from 'contexts/crypto/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';

import {
    base642Buffer,
    decryptStringWithPasswordKey,
    getPasswordKeyWithIv,
    importPrivateKey,
    importPublicKey,
} from 'helpers/cryptoApiHelper';
import {theme} from 'config/theme';
import {DATE_TIME_LABEL_FORMAT} from 'config/index';

interface ImportKeysFromServerFormType {
    handleImport: (keyPairRecord: KeysType) => void;
}

const ImportKeysFromServerForm: FC<ImportKeysFromServerFormType> = ({handleImport}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {
        crypto: {currentUserPublicKeys},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;
    const currentUserSavedKeys = currentUserPublicKeys?.filter(item => item.isKeyStored);

    const [passwordErrorMessage, setPasswordErrorMessage] = useState<string | null>(null);
    const [fileErrorMessage, setFileErrorMessage] = useState<string | null>(null);

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const toggleShowPassword = () => {
        setShowPassword(previous => !previous);
    };

    const getUserSavedKeys = useCallback(
        data => dispatch({type: GET_USER_SAVED_KEYS.REQUEST, payload: data}),
        [dispatch],
    );

    const handleSubmit = async (values: RestoreCryptoKeysTypes) => {
        let encryptedKey = '';

        try {
            encryptedKey = await new Promise((fulfill, reject) => {
                if (!values.keyUuid) reject('messages.error.fileRequired');
                getUserSavedKeys({
                    uuid: values.keyUuid,
                    callback: result => {
                        fulfill(result);
                    },
                });
            });
        } catch (err) {
            console.error(err);
            if (typeof err === 'string') {
                setFileErrorMessage(err);
            } else {
                setFileErrorMessage('messages.error.fileRequired');
            }
            return;
        }

        try {
            const ivAndEncryptedData = base642Buffer(encryptedKey);
            const iv = new Uint8Array(ivAndEncryptedData).slice(0, 12);
            const key = await getPasswordKeyWithIv(values.keyPassword, iv);

            const decrypted = await decryptStringWithPasswordKey(encryptedKey, key);
            const keysToImport = JSON.parse(decrypted);

            const publicKey = await importPublicKey(keysToImport.publicKey);
            const privateKey = await importPrivateKey(keysToImport.privateKey);

            const keyPairRecord = {
                name: keysToImport.name,
                publicKey,
                privateKey,
                uuid: keysToImport.uuid,
            };

            handleImport(keyPairRecord);
        } catch (err) {
            console.error(err);
            console.log('wrongPassword');
            setPasswordErrorMessage('messages.error.wrongPassword');
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
            }}
        >
            <Formik<RestoreCryptoKeysTypes>
                initialValues={{
                    keyPassword: '',
                    keyUuid: currentUserSavedKeys?.[0].uuid,
                }}
                onSubmit={handleSubmit}
            >
                {(formik: FormikProps<RestoreCryptoKeysTypes>) => {
                    return (
                        <Form>
                            <FormControl variant="standard" sx={{mt: 2}} required fullWidth>
                                <InputLabel sx={{ml: 0}}>{t('common.crypto.selectKeys')}</InputLabel>
                                <Select
                                    required
                                    name="form"
                                    label={t('common.crypto.selectKeys')}
                                    fullWidth
                                    value={formik.values.keyUuid}
                                    variant="standard"
                                    onChange={formik.handleChange}
                                    IconComponent={KeyboardArrowDownIcon}
                                >
                                    {currentUserSavedKeys &&
                                        currentUserSavedKeys.map(item => {
                                            const {title, createdAt, uuid} = item;
                                            return (
                                                <MenuItem key={`subForm-form-${uuid}`} value={uuid}>
                                                    <Typography>
                                                        {title} - {moment(createdAt).format(DATE_TIME_LABEL_FORMAT)}
                                                    </Typography>
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                                <FormHelperText sx={{color: theme.palette.error.main}}>
                                    {fileErrorMessage && t(fileErrorMessage)}
                                </FormHelperText>
                            </FormControl>
                            <Field
                                name="keyPassword"
                                data-id={`input#importCryptoKeysPassword`}
                                label={t('common.loginForm.password')}
                                component={FormikTextInput}
                                error={passwordErrorMessage}
                                helperText={passwordErrorMessage && t(passwordErrorMessage)}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const password = get(event, ['target', 'value'], null);
                                    formik.setFieldValue('keyPassword', password);
                                    setPasswordErrorMessage(null);
                                }}
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="off"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={toggleShowPassword}
                                                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                                                    event.preventDefault()
                                                }
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Box
                                sx={{
                                    mt: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <AgentSaveButton
                                    isSubmit
                                    data-id={`button#importCryptoKeysSave`}
                                    title={t('common.buttons.confirm')}
                                />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default ImportKeysFromServerForm;
