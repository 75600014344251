import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {RootReducer} from 'appRedux/reducers';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AssignAgentForm from 'components/Forms/RequesterCase/AssignAgentForm';

const AssignAgentButton: FC = () => {
    const [t] = useTranslation();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    const {
        admin: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <>
            <AgentSaveButton
                isLoading={isLoading}
                isSubmit={false}
                title={t('orguser.requesterCase.assignAgent')}
                onClick={toggleModal}
                isBigButton
            />
            <ModalWrapper
                isShowModal={showModal}
                toggleModal={toggleModal}
                title={t('orguser.requesterCase.assignAgent')}
            >
                <AssignAgentForm closeModal={() => setShowModal(false)} />
            </ModalWrapper>
        </>
    );
};

export default AssignAgentButton;
