import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import {RequesterCaseActivitiesTypes} from 'appRedux/actions/requestCase/types';
import {FormPageTypes} from 'appRedux/actions/forms/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import ActivityInformationItem from 'pages/agent/activityPage/ActivityInformationItem';
import ActivityInformationResults from 'pages/agent/activityPage/ActivityInformationResults';

import {routes} from 'config/index';

interface ActivityReviewPopupType {
    pages: FormPageTypes[];
    isEncryptInfo?: boolean;
    isOpened: boolean;
    toggleIsOpened: () => void;
    encodedCaseKey?: string;
    item: RequesterCaseActivitiesTypes;
    activityLogsList?: boolean;
}

const ActivityReviewPopup: FC<ActivityReviewPopupType> = ({
    pages,
    item,
    isEncryptInfo,
    encodedCaseKey,
    toggleIsOpened,
    isOpened,
    activityLogsList,
}) => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const {datetime, person, element, caseId, formId} = item;

    let newValueTitle = 'newValue';
    if (element) {
        if (element.parameter === 'activityCheckedSection' || element.parameter === 'activityUncheckedSection') {
            newValueTitle = 'sectionTitle';
        } else if (element.parameter === 'activityFormTag' || element.parameter === 'activityUncheckedFormTag') {
            newValueTitle = 'tagName';
        } else if (element.parameter === 'activityAgent') {
            newValueTitle = 'agent';
        } else if (element.parameter === 'activityRequesterDocument') {
            newValueTitle = 'filename';
        }
    }

    let newValueText = '';
    if (element) {
        if (element.parameter === 'activityIsChatActivated' || element.parameter === 'activityIsPinned') {
            newValueText = element.newValue ? 'On' : 'Off';
        } else if (element.parameter === 'activityStatus') {
            newValueText = t(element.newValue);
        } else if (
            element.parameter === 'activityUpdatedClientTranslation' ||
            element.parameter === 'activityFormTag' ||
            element.parameter === 'activityUncheckedFormTag' ||
            element.parameter === 'activityCheckedSection' ||
            element.parameter === 'activityUncheckedSection' ||
            element.parameter === 'activityDeleteCaseWatcher' ||
            element.parameter === 'activityAddCaseWatcher' ||
            element.parameter === 'activityRequesterDocument' ||
            element.parameter === 'activityAgent'
        ) {
            newValueText = element.newValue;
        }
    }

    const redirectToCase = () => {
        navigate(`${routes.REQUESTER_CASE}/${caseId}/form/${formId}`);
    };

    return (
        <ModalWrapper isShowModal={isOpened} toggleModal={toggleIsOpened} title={t('orguser.activities.activity')}>
            <ActivityInformationItem title={t('orguser.activities.dateTime')} value={String(datetime)} />
            <ActivityInformationItem title={t('orguser.activities.user')} value={person} />
            {element && (
                <>
                    <ActivityInformationItem
                        title={t('orguser.activities.element')}
                        value={t(`orguser.activities.types.${element.parameter}`)}
                    />
                    {element.parameter === 'activityUpdatedClientTranslation' ||
                        (element.parameter === 'activityStatus' && (
                            <ActivityInformationItem
                                title={t('orguser.activities.oldValue')}
                                value={element.oldValue}
                            />
                        ))}
                    {element.parameter === 'activityResult' ? (
                        <ActivityInformationResults
                            pages={pages}
                            isEncryptInfo={isEncryptInfo}
                            element={element}
                            encodedCaseKey={encodedCaseKey}
                        />
                    ) : (
                        <ActivityInformationItem
                            title={t(`orguser.activities.${newValueTitle}`)}
                            value={newValueText}
                        />
                    )}
                </>
            )}
            {activityLogsList && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 1,
                    }}
                >
                    <AgentSaveButton isSubmit={false} title={t('common.buttons.linkToCase')} onClick={redirectToCase} />
                </Box>
            )}
        </ModalWrapper>
    );
};

export default ActivityReviewPopup;
