import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';

import RulesEmptySvgIcon from 'assets/icons/buttons/RulesEmptySvgIcon';
import RulesFilledSvgIcon from 'assets/icons/buttons/RulesFilledSvgIcon';

import {WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import WorkflowRulesList from 'pages/admin/updateWorkflow/partials/rules/WorkflowRulesList';
import RulesFromSwitcher from 'pages/admin/updateWorkflow/partials/rules/RulesFromSwitcher';
import {checkStatusHasNotCompletedRules} from 'pages/admin/updateWorkflow/partials/rules/helper';

interface WorkflowStatusRulesButtonType {
    item: WorkflowStatusItemTypes;
}

const WorkflowStatusRulesButton: FC<WorkflowStatusRulesButtonType> = ({item}) => {
    const [t] = useTranslation();

    const {rules} = item;

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const hasNotCompletedRules = checkStatusHasNotCompletedRules(rules);

    return (
        <>
            <IconButton
                title={t(
                    hasNotCompletedRules
                        ? 'orguser.workflows.rules.hasNotCompletedRules'
                        : 'orguser.workflows.rules.workflowStatusRules',
                )}
                onClick={toggleIsOpened}
            >
                <Badge color="error" variant={'dot'} invisible={!hasNotCompletedRules}>
                    {rules.length > 0 ? <RulesFilledSvgIcon /> : <RulesEmptySvgIcon />}
                </Badge>
            </IconButton>
            <ModalWrapper
                isShowModal={isOpened}
                isWideModal
                title={`${item.title}: ${t('orguser.workflows.rules.setWorkflowStatusRules')}`}
                toggleModal={toggleIsOpened}
            >
                <RulesFromSwitcher item={item} />
                {rules && rules.length > 0 ? (
                    <WorkflowRulesList item={item} hasNotCompletedRules={hasNotCompletedRules} />
                ) : (
                    <Typography sx={{mt: 2, mb: 1}}>{t('orguser.workflows.rules.noneRules')}</Typography>
                )}
            </ModalWrapper>
        </>
    );
};

export default WorkflowStatusRulesButton;
