import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {GET_ROLE_INFORMATION} from 'appRedux/actions/roles';

import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';
import UpdateRoleForm from 'components/Forms/RoleForm/UpdateRoleForm';
import {roleInitialValues} from 'components/Forms/RoleForm/validation';
import {ROLES_LIST} from 'components/AgentScreenComponents/helper';

import {isOrgAdminUser, isSuperAdminUser} from 'helpers/roles';

import {routes, PARAMETER_TAB} from 'config/index';

const UpdateRole: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {uuid} = useParams();

    const {showAlert} = useContext(AlertContext);
    const {setBackLink, setBackLinkLabel} = useContext(RouteContext);

    const getRoleInformation = useCallback(
        data => dispatch({type: GET_ROLE_INFORMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {currentRole},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const initialValues = currentRole ? {...roleInitialValues, ...currentRole} : roleInitialValues;

    useEffect(() => {
        getRoleInformation({
            uuid,
            showAlert,
        });
    }, []);

    useEffect(() => {
        if (currentRole) {
            const isSuperAdmin = profile ? isSuperAdminUser(profile) : false;
            if (isSuperAdmin) {
                setBackLink(`${routes.UPDATE_ORGANIZATION}/${currentRole.organizationId}/roles`);
            } else if (profile) {
                const route = isOrgAdminUser(profile) ? routes.ADMIN : routes.AGENT;
                setBackLink(`${route}?${PARAMETER_TAB}=${ROLES_LIST}`);
            }
            setBackLinkLabel(t('common.links.backToRoles'));
        }
    }, [currentRole]);

    if (!currentRole) {
        return null;
    }

    const {isCurrentUserRelated, organizationId} = currentRole;

    return (
        <ClientSectionWrapper title={t('orguser.roles.updateRole')}>
            {isCurrentUserRelated && (
                <Alert severity={'error'} sx={{mb: 1}}>
                    <Typography>{t('messages.warning.changeRoleExplanation')}</Typography>
                </Alert>
            )}
            <UpdateRoleForm initialValues={initialValues} organizationId={organizationId} />
        </ClientSectionWrapper>
    );
};

export default UpdateRole;
