import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {GET_CASE_ACCESS_REQUESTS} from 'appRedux/actions/crypto';
import {RootReducer} from 'appRedux/reducers';

import DataGridWrapper from 'components/AdminScreenComponents/DataGridWrapper';

import CaseAccessRequestsDataGrid from 'pages/admin/accessRequests/CaseAccessRequestsDataGrid';

import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';
import {PermissionContext} from 'contexts/permission/context';

const CaseAccessRequestsList: FC = () => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {setBackLink, setBackLinkLabel, setTopBarTitle, setTopBarSubtitle} = useContext(RouteContext);
    const {isCaseViewEnabled} = useContext(PermissionContext);

    const getCaseAccessRequests = useCallback(
        data => dispatch({type: GET_CASE_ACCESS_REQUESTS.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        crypto: {caseAccessRequests},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        if (isCaseViewEnabled) {
            getCaseAccessRequests({
                showAlert,
            });
        }
    }, []);

    useEffect(() => {
        setBackLink(null);
        setBackLinkLabel(null);
        setTopBarTitle('');
        setTopBarSubtitle('');
    }, []);

    if (!isCaseViewEnabled) {
        return null;
    }
    return (
        <DataGridWrapper
            itemsList={caseAccessRequests}
            itemsTotalNumber={caseAccessRequests ? caseAccessRequests.length : 0}
        >
            <CaseAccessRequestsDataGrid items={caseAccessRequests} />
        </DataGridWrapper>
    );
};

export default CaseAccessRequestsList;
