import React, {FC, useContext, useEffect, useState} from 'react';

import Grid from '@mui/material/Grid';

import {RouteContext} from 'contexts/route/context';
import {PermissionContext} from 'contexts/permission/context';

import RequesterCaseStatistic from 'pages/agent/dashboard/RequesterCaseStatistic';
import PinnedCasesList from 'pages/agent/dashboard/PinnedCasesList';
import CasesWaitingApproveList from 'pages/agent/dashboard/CasesWaitingApproveList';
import ActivitiesLog from 'pages/agent/dashboard/ActivitiesLog';

const AgentDashboard: FC = () => {
    const {setPageTitle, setTopBarTitle, setBackLink, setBackTab, setBackLinkLabel} = useContext(RouteContext);
    const {isCaseViewEnabled} = useContext(PermissionContext);

    const [isPinnedListUpdated, setIsPinnedListUpdated] = useState<boolean>(false);
    const [isApproveWaitingListUpdated, setIsApproveWaitingListUpdated] = useState<boolean>(false);

    const togglePinnedListUpdated = () => {
        setIsPinnedListUpdated(previous => !previous);
    };

    const toggleApproveListUpdated = () => {
        setIsApproveWaitingListUpdated(previous => !previous);
    };

    useEffect(() => {
        setPageTitle('');
        setTopBarTitle('');
        setBackLink('');
        setBackTab('');
        setBackLinkLabel('');
    }, []);

    if (!isCaseViewEnabled) {
        return null;
    }
    return (
        <Grid container spacing={2}>
            <Grid item sm={12} md={4}>
                <PinnedCasesList
                    isApproveWaitingListUpdated={isApproveWaitingListUpdated}
                    toggleApproveListUpdated={toggleApproveListUpdated}
                />
                <CasesWaitingApproveList
                    isPinnedListUpdated={isPinnedListUpdated}
                    togglePinnedListUpdated={togglePinnedListUpdated}
                />
            </Grid>
            <Grid item sm={12} md={8}>
                <RequesterCaseStatistic />
                <ActivitiesLog />
            </Grid>
        </Grid>
    );
};

export default AgentDashboard;
