import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {ResourceFieldBudgetFormTypes, ResourceFieldBudgetItemTypes} from 'appRedux/actions/resourceFields/types';
import {UPDATE_RESOURCE_FIELD_BUDGET} from 'appRedux/actions/resourceFields';

import {AlertContext} from 'contexts/alert/context';

import ResourceFieldBudgetForm from 'components/Forms/ResourceField/ResourceFieldBudgetForm';

interface UpdateResourceFieldBudgetFormType {
    item: ResourceFieldBudgetItemTypes;
    closeModal: () => void;
}

const UpdateResourceFieldBudgetForm: FC<UpdateResourceFieldBudgetFormType> = ({item, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {uuid, title, description, type, amount, resetInterval} = item;

    const updateResourceFieldBudget = useCallback(
        data => dispatch({type: UPDATE_RESOURCE_FIELD_BUDGET.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: ResourceFieldBudgetFormTypes) => {
        updateResourceFieldBudget({
            ...values,
            uuid,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <ResourceFieldBudgetForm
            initialValues={{
                title,
                description,
                type,
                amount,
                resetInterval,
            }}
            onSubmitClicked={onSubmitClicked}
        />
    );
};

export default UpdateResourceFieldBudgetForm;
