import React, {FC, MouseEvent, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import {PermissionContext} from 'contexts/permission/context';

import AgentAddMultipleButton from 'components/AgentScreenComponents/_buttons/AgentAddMultipleButton';

import {theme} from 'config/theme';
import {routes, PARAMETER_SUB} from 'config/index';

const AddFormDropdown: FC = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const permissions = useContext(PermissionContext);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSelectClicked = (route: string) => {
        navigate(route);
    };

    const open = Boolean(anchorEl);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                mb: 2,
            }}
        >
            {permissions.isFormCreateEnabled && (
                <AgentAddMultipleButton title={t('orguser.forms.addForm')} onClick={handleClick} open={open} />
            )}
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        mt: 1,
                        pl: 2,
                        pr: 2,
                        pt: 1,
                        pb: 1,
                        width: 290,
                        borderRadius: 2,
                        overflow: 'visible',
                        boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                        backgroundColor: theme.palette.background.default,
                        '& ul': {
                            padding: 0,
                        },
                        overflowX: 'hidden',
                    },
                }}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            >
                <MenuItem onClick={() => onSelectClicked(`${routes.FORM}/create`)}>
                    <Typography>{t('orguser.forms.addForm')}</Typography>
                </MenuItem>
                <MenuItem onClick={() => onSelectClicked(`${routes.FORM}/create?${PARAMETER_SUB}=1`)}>
                    <Typography>{t('orguser.forms.addSubForm')}</Typography>
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default AddFormDropdown;
