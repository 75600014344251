import React, {FC} from 'react';

const Avatar_03: FC = () => {
    return (
        <svg viewBox="0 0 61.7998 61.7998" xmlns="http://www.w3.org/2000/svg">
            <title />
            <g data-name="Layer 2" id="Layer_2">
                <g data-name="—ÎÓÈ 1" id="_ÎÓÈ_1">
                    <path
                        d="M31.129 8.432c21.281 0 12.987 35.266 0 35.266-12.266 0-21.281-35.266 0-35.266z"
                        fill="#ffe8be"
                        fillRule="evenodd"
                    />
                    <circle cx="30.8999" cy="30.8999" fill="#a9cf54" r="30.8999" />
                    <path
                        d="M16.647 25.104s1.394 18.62-1.98 23.645 16.51-.19 16.51-.19l.006-34.863z"
                        fill="#302e33"
                        fillRule="evenodd"
                    />
                    <path
                        d="M45.705 25.104s-1.394 18.62 1.981 23.645-16.51-.19-16.51-.19l-.006-34.863z"
                        fill="#302e33"
                        fillRule="evenodd"
                    />
                    <path
                        d="M52.797 52.701c-.608-1.462-.494-2.918-5.365-5.187-2.293-.542-8.21-1.319-9.328-3.4-.567-1.052-.43-2.535-.43-5.292l-12.93-.142c0 2.777.109 4.258-.524 5.298-1.19 1.957-8.935 3.384-11.338 4.024-4.093 1.819-3.625 2.925-4.165 4.406a30.896 30.896 0 0 0 44.08.293z"
                        fill="#f9dca4"
                        fillRule="evenodd"
                    />
                    <path
                        d="M37.677 38.778l-.015 2.501a5.752 5.752 0 0 0 .55 3.011c-4.452 3.42-12.794 2.595-13.716-5.937z"
                        fillRule="evenodd"
                        opacity="0.11"
                    />
                    <path
                        d="M19.11 24.183c-2.958 1.29-.442 7.41 1.42 7.383a30.842 30.842 0 0 1-1.42-7.383z"
                        fill="#f9dca4"
                        fillRule="evenodd"
                    />
                    <path
                        d="M43.507 24.182c2.96 1.292.443 7.411-1.419 7.384a30.832 30.832 0 0 0 1.419-7.384z"
                        fill="#f9dca4"
                        fillRule="evenodd"
                    />
                    <path
                        d="M31.114 8.666c8.722 0 12.377 6.2 12.601 13.367.307 9.81-5.675 21.43-12.6 21.43-6.56 0-12.706-12.018-12.333-21.928.26-6.953 3.814-12.869 12.332-12.869z"
                        fill="#ffe8be"
                        fillRule="evenodd"
                    />
                    <path
                        d="M31.183 13.697c-.579 2.411-3.3 10.167-14.536 11.407C15.477 5.782 30.182 6.256 31.183 6.311c1.002-.055 15.707-.53 14.536 18.793-11.235-1.24-13.957-8.996-14.536-11.407z"
                        fill="#464449"
                        fillRule="evenodd"
                    />
                    <path
                        d="M52.797 52.701c-14.87 4.578-34.168 1.815-39.915-4.699-4.093 1.819-3.625 2.925-4.165 4.406a30.896 30.896 0 0 0 44.08.293z"
                        fill="#e9573e"
                        fillRule="evenodd"
                    />
                    <path d="M42.797 46.518l1.071.253-.004 8.118h-1.067v-8.371z" fill="#e9573e" fillRule="evenodd" />
                    <path
                        d="M23.834 44.42c.002.013.878 4.451 7.544 4.451 6.641 0 7.046-4.306 7.047-4.318l.188.183c0 .012-.564 4.702-7.235 4.702-6.797 0-7.756-4.83-7.759-4.845z"
                        fill="#464449"
                        fillRule="evenodd"
                    />
                    <ellipse cx="31.3239" cy="49.44545" fill="#464449" rx="1.5127" ry="1.9093" />
                </g>
            </g>
        </svg>
    );
};

export default Avatar_03;
