import {RequesterCaseActivitiesTypes} from 'appRedux/actions/requestCase/types';

import {userRoles} from 'config/index';

export const isTwoFactorDeactivationDisabled = (role: string): boolean => {
    return role === userRoles.ROLE_SUPER_ADMIN;
};

export const getPersonName = (item: RequesterCaseActivitiesTypes): string => {
    const {person, isPersonDeleted} = item;
    if (!person) return '';
    return isPersonDeleted ? `${person} (deleted)` : person;
};
