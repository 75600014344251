import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';

import {BoardHasStatusItemTypes, WorkflowItemTypes} from 'appRedux/actions/workflow/types';
import {RootReducer} from 'appRedux/reducers';

import WorkflowStatusesSelectors from 'components/Forms/BoardForm/WorkflowStatusesSelectors';

interface StatusesSelectorsType {
    statuses: BoardHasStatusItemTypes[];
    setStatuses: (values: BoardHasStatusItemTypes[]) => void;
}

const StatusesSelectors: FC<StatusesSelectorsType> = ({statuses, setStatuses}) => {
    const [t] = useTranslation();

    const {
        workflow: {organizationWorkflows},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Box>
            <Typography sx={{fontWeight: 600, mt: 2, mb: 1}}>{`${t('orguser.workflows.selectStatuses')}:`}</Typography>
            <FormGroup>
                {organizationWorkflows &&
                    organizationWorkflows.map((workflow: WorkflowItemTypes, index: number) => {
                        const {uuid, title, workflowStatuses} = workflow;
                        return (
                            <Box key={`workflow-container-${uuid}`}>
                                <Typography sx={{fontWeight: 700, mt: 2}}>{title}</Typography>
                                <WorkflowStatusesSelectors
                                    statuses={statuses}
                                    setStatuses={setStatuses}
                                    workflowStatuses={workflowStatuses}
                                />
                            </Box>
                        );
                    })}
            </FormGroup>
        </Box>
    );
};

export default StatusesSelectors;
