import React, {FC, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';

import {FormPopupTypes} from 'appRedux/actions/forms/types';

import PopupContent from 'pages/client/form/fieldPopup/PopupContent';
import PopupModalForm from 'pages/client/form/fieldPopup/PopupModalForm';
import FormPopupWrapper from 'pages/client/form/wrappers/FormPopupWrapper';

import {ADMIN_LIGHT_GRAY_COLOR, theme} from 'config/theme';

interface FormFieldWrapperType {
    formId: number;
    pageId: number;
    sectionId: number;
    disabled: boolean;
    popup: FormPopupTypes;
    clientInformation: unknown;
    handleChangePopupInformation: (pageId: number, sectionId: number, popupId: number, value: unknown) => void;
    onSaveClicked: (isCallAlert?: boolean) => void;
    previewMode?: boolean;
}

const FormPopup: FC<FormFieldWrapperType> = ({
    formId,
    pageId,
    sectionId,
    popup,
    clientInformation,
    handleChangePopupInformation,
    disabled,
    onSaveClicked,
    previewMode,
}) => {
    const [t] = useTranslation();

    const ref = useRef<HTMLDivElement | undefined>();

    const {title, id} = popup;

    const [itemIndex, setItemIndex] = useState<number | null>(null);
    const [isShowNewContent, setIsShowNewContent] = useState<boolean>(false);

    const pageKeyword = `page-${pageId}`;
    const sectionKeyword = `section-${sectionId}`;
    const popupKeyword = `popup-${id}`;

    const {fields} = popup;

    const initialItems = get(clientInformation, [pageKeyword, sectionKeyword, popupKeyword], []);

    const toggleModal = () => {
        setIsShowNewContent(previous => !previous);
    };

    const onAddFieldPopupClick = () => {
        toggleModal();
        setItemIndex(null);
    };

    const onEditClick = (index: number | null) => {
        setIsShowNewContent(false);
        setItemIndex(index);
    };

    if (fields.length === 0) {
        return null;
    }
    return (
        <FormPopupWrapper wrapperRef={ref} popup={popup} formId={formId} previewMode={previewMode}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1,
                }}
            >
                <Typography sx={{fontWeight: 600}}>{title}</Typography>
                {initialItems.length > 0 ? (
                    <Typography
                        sx={{
                            textTransform: 'none',
                            color: theme.palette.info.main,
                            pr: 1,
                        }}
                    >
                        {`${initialItems.length} ${t('requester.casePage.entries')}`}
                    </Typography>
                ) : (
                    <Typography
                        sx={{
                            textTransform: 'none',
                            color: theme.palette.info.main,
                            pr: 1,
                        }}
                    >
                        {t('common.none')}
                    </Typography>
                )}
            </Box>
            <Box
                sx={{
                    border: `1px solid ${theme.palette.info.contrastText}`,
                    borderRadius: '10px',
                    overflow: 'hidden',
                    mb: 1,
                }}
            >
                <PopupContent
                    popup={popup}
                    pageId={pageId}
                    formId={formId}
                    sectionId={sectionId}
                    initialItems={initialItems}
                    onEditClick={onEditClick}
                    handleChangePopupInformation={handleChangePopupInformation}
                    disabled={disabled}
                    onSaveClicked={onSaveClicked}
                    selectedIndex={itemIndex}
                />

                {!disabled && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: isShowNewContent ? 'space-between' : 'center',
                            alignItems: 'center',
                            height: '48px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: isShowNewContent ? 'default' : 'pointer',
                                gap: 1,
                                py: 1,
                                px: 2,
                            }}
                            onClick={!isShowNewContent ? onAddFieldPopupClick : undefined}
                        >
                            <Typography
                                sx={{
                                    color: theme.palette.info.main,
                                    fontWeight: 600,
                                }}
                            >
                                {isShowNewContent ? t('requester.casePage.newEntry') : t('requester.casePage.addEntry')}
                            </Typography>

                            {!isShowNewContent && (
                                <Box
                                    sx={{
                                        backgroundColor: theme.palette.info.main,
                                        fontWeight: 600,
                                        height: 24,
                                        width: 24,
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <AddIcon sx={{color: theme.palette.background.default}} />
                                </Box>
                            )}
                        </Box>
                        {isShowNewContent && (
                            <IconButton sx={{p: 0.5, mr: 2}} onClick={onAddFieldPopupClick}>
                                <RemoveIcon />
                            </IconButton>
                        )}
                    </Box>
                )}

                {isShowNewContent && itemIndex === null && (
                    <Box
                        sx={{
                            height: isShowNewContent ? 'auto' : '0px',
                            py: isShowNewContent ? 1 : 0,
                            mx: 1,
                            px: 1,
                            borderTop: `1px solid ${ADMIN_LIGHT_GRAY_COLOR}`,
                        }}
                    >
                        {isShowNewContent && (
                            <PopupModalForm
                                toggleModal={toggleModal}
                                initialItems={initialItems}
                                formId={formId}
                                pageId={pageId}
                                sectionId={sectionId}
                                popup={popup}
                                clientInformation={clientInformation}
                                handleChangePopupInformation={handleChangePopupInformation}
                                itemIndex={null}
                                disabled={disabled}
                                onSaveClicked={onSaveClicked}
                            />
                        )}
                    </Box>
                )}
            </Box>
        </FormPopupWrapper>
    );
};

export default FormPopup;
