import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import Typography from '@mui/material/Typography';

import {WorkflowStatusTranslationItemTypes, WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';
import {RootReducer} from 'appRedux/reducers';

import {TAB_FOR_ORG_USER, TAB_FOR_REQUESTER} from 'components/Forms/Workflow/validation';
import CreateWorkflowStatusTranslationForm from 'components/Forms/Workflow/workflowStatusTranslation/CreateWorkflowStatusTranslationForm';
import UpdateWorkflowStatusTranslationForm from 'components/Forms/Workflow/workflowStatusTranslation/UpdateWorkflowStatusTranslationForm';

import {getTagColor} from 'pages/admin/updateForm/partials/FormTags/helper';

interface WorkflowStatusTabContentSwitcherType {
    item: WorkflowStatusItemTypes;
    tab: number | null;
    tabTitle: string;
    isActive: boolean;
    currentColor: string;
    currentLanguage: string;
    closeForm: () => void;
}

const WorkflowStatusTabContentSwitcher: FC<WorkflowStatusTabContentSwitcherType> = ({
    item,
    tab,
    tabTitle,
    isActive,
    currentColor,
    currentLanguage,
    closeForm,
}) => {
    const {
        admin: {languageList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {translations} = item;

    const currentWorkflowStatusTranslation: WorkflowStatusTranslationItemTypes = get(
        translations,
        [currentLanguage],
        null,
    );

    const language = languageList.find(item => item.name === currentLanguage);

    if (isActive && tab === TAB_FOR_REQUESTER && currentWorkflowStatusTranslation && language) {
        return (
            <UpdateWorkflowStatusTranslationForm
                language={language}
                currentItem={currentWorkflowStatusTranslation}
                currentColor={currentColor}
                formField={'translationRequester'}
                closeForm={closeForm}
            />
        );
    } else if (isActive && tab === TAB_FOR_ORG_USER && currentWorkflowStatusTranslation && language) {
        return (
            <UpdateWorkflowStatusTranslationForm
                language={language}
                currentItem={currentWorkflowStatusTranslation}
                currentColor={currentColor}
                formField={'translation'}
                closeForm={closeForm}
            />
        );
    } else if (isActive && tab === TAB_FOR_REQUESTER && language) {
        return (
            <CreateWorkflowStatusTranslationForm
                language={language}
                item={item}
                currentColor={currentColor}
                formField={'translationRequester'}
                closeForm={closeForm}
            />
        );
    } else if (isActive && tab === TAB_FOR_ORG_USER && language) {
        return (
            <CreateWorkflowStatusTranslationForm
                language={language}
                item={item}
                currentColor={currentColor}
                formField={'translation'}
                closeForm={closeForm}
            />
        );
    }

    return (
        <Typography
            sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 200,
                fontWeight: 600,
                lineHeight: '22px',
                color: getTagColor(currentColor),
            }}
        >
            {tabTitle}
        </Typography>
    );
};

export default WorkflowStatusTabContentSwitcher;
