import React, {FC} from 'react';

import {withStyles, createStyles, WithStyles} from '@material-ui/core';

import CustomMdxEditor from 'components/MdxEditorField/CustomMdxEditor';

const styles = () =>
    createStyles({
        mdxEditorWrapper: {
            display: 'none', // hide mdx editor and placeholder
            width: '100%',
            minHeight: '100px',
            '&:focus': {
                outline: 'none',
            },
            '&:first-child': {
                display: 'block', // show only first child (editor)
            },
        },
    });

interface CustomMdxEditorSmallTypes extends WithStyles<typeof styles> {
    label?: string;
    value?: string;
    onChange: (value: string) => void;
    onBlur?: () => void;
}

const CustomMdxEditorSmall: FC<CustomMdxEditorSmallTypes> = ({classes, ...rest}) => {
    return <CustomMdxEditor mdxEditorClassName={classes.mdxEditorWrapper} {...rest} />;
};

export default withStyles(styles)(CustomMdxEditorSmall);
