import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import {RoleTypes} from 'appRedux/actions/roles/types';

import {PermissionContext} from 'contexts/permission/context';

import {TableHeadTypography} from 'components/AgentScreenComponents/_form/CustomElements';

import RolesTableRow from 'pages/admin/roles/RolesTableRow';

interface RolesTableType {
    itemsPortion: RoleTypes[];
}

const RolesTable: FC<RolesTableType> = ({itemsPortion}) => {
    const [t] = useTranslation();

    const {isRoleEditEnabled} = useContext(PermissionContext);

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width={300}>
                        <TableHeadTypography text={t('orguser.roles.parameters.title')} />
                    </TableCell>
                    <TableCell>
                        <TableHeadTypography text={t('orguser.roles.parameters.permissions')} />
                    </TableCell>
                    {isRoleEditEnabled && (
                        <TableCell width={100}>
                            <TableHeadTypography text={t('orguser.roles.parameters.isActive')} />
                        </TableCell>
                    )}
                    <TableCell width={100}>
                        <TableHeadTypography text={t('orguser.roles.parameters.action')} />
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {itemsPortion &&
                    itemsPortion.map((item: RoleTypes, index: number) => {
                        return <RolesTableRow item={item} key={`role-${index}-${item.uuid}`} />;
                    })}
            </TableBody>
        </Table>
    );
};

export default RolesTable;
