import * as yup from 'yup';

import {REGEX_FOR_TITLES} from 'components/Forms/FormBuilder/helper';

import {TAG_COLOR_1} from 'config/theme';

export const formInitialValues = {
    title: '',
    color: TAG_COLOR_1,
};

export const formValidationSchema = yup.object({
    title: yup
        .string()
        .matches(REGEX_FOR_TITLES, 'messages.validation.valueStructureValidationLatinNumbersSpecialAllowed')
        .nullable(),
});

export const formValidationSchemaNotOnlyLatin = yup.object({
    title: yup.string().nullable(),
});
