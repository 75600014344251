import moment from 'moment';

import {FilterOptionType} from 'components/AdminScreenComponents/types';

import {DATE_FORMAT_FILTER} from 'config/index';

export const DATE_LAST_WEEK = 'lastWeek';
export const DATE_LAST_MONTH = 'lastMonth';
export const DATE_LAST_SIX_MONTHS = 'lastSixMonth';
export const DATE_LAST_YEAR = 'lastYear';

export const VIEW_MODE_BOARD = 'board';
export const VIEW_MODE_TABLE = 'table';
export const VIEW_MODE_LIST = 'list';

export const getPerPageOptions = (): FilterOptionType[] => {
    return [
        {name: '5', label: '5'},
        {name: '15', label: '15'},
        {name: '30', label: '30'},
        {name: '45', label: '45'},
    ];
};

export const getDatePeriodFilters = (): FilterOptionType[] => {
    return [
        {name: DATE_LAST_WEEK, label: `orguser.requesterCase.topbar.${DATE_LAST_WEEK}`},
        {name: DATE_LAST_MONTH, label: `orguser.requesterCase.topbar.${DATE_LAST_MONTH}`},
        {name: DATE_LAST_SIX_MONTHS, label: `orguser.requesterCase.topbar.${DATE_LAST_SIX_MONTHS}`},
        {name: DATE_LAST_YEAR, label: `orguser.requesterCase.topbar.${DATE_LAST_YEAR}`},
    ];
};

export const getDateForFilter = (filterType?: string): string => {
    switch (filterType) {
        case DATE_LAST_WEEK:
            return moment().subtract(1, 'week').format(DATE_FORMAT_FILTER);
        case DATE_LAST_MONTH:
            return moment().subtract(1, 'month').format(DATE_FORMAT_FILTER);
        case DATE_LAST_SIX_MONTHS:
            return moment().subtract(6, 'months').format(DATE_FORMAT_FILTER);
        case DATE_LAST_YEAR:
            return moment().subtract(1, 'year').format(DATE_FORMAT_FILTER);
    }
    return '';
};
