import React, {FC} from 'react';
import {CommonSvgIconType, getIconColor} from './helper';

const SettingsCheckSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <rect width="16" height="16" fill="white" fillOpacity="0.01" />
            <path
                d="M7.99989 11C7.40654 11 6.82652 10.8241 6.33318 10.4944C5.83983 10.1648 5.45531 9.69623 5.22825 9.14805C5.00119 8.59987 4.94178 7.99667 5.05753 7.41473C5.17329 6.83279 5.45901 6.29824 5.87857 5.87868C6.29812 5.45912 6.83267 5.1734 7.41462 5.05764C7.99656 4.94189 8.59976 5.0013 9.14794 5.22836C9.69611 5.45542 10.1647 5.83994 10.4943 6.33329C10.8239 6.82664 10.9999 7.40666 10.9999 8C11.0041 8.39515 10.9295 8.78717 10.7802 9.15307C10.6309 9.51897 10.4101 9.85138 10.1307 10.1308C9.85126 10.4102 9.51885 10.6311 9.15295 10.7803C8.78705 10.9296 8.39503 11.0043 7.99989 11ZM7.99989 6C7.7356 5.99401 7.47285 6.04164 7.22749 6.14003C6.98212 6.23842 6.75925 6.38552 6.57233 6.57244C6.3854 6.75937 6.2383 6.98224 6.13992 7.2276C6.04153 7.47296 5.99389 7.73572 5.99989 8C5.99389 8.26428 6.04153 8.52704 6.13992 8.7724C6.2383 9.01776 6.3854 9.24063 6.57233 9.42756C6.75925 9.61448 6.98212 9.76158 7.22749 9.85997C7.47285 9.95836 7.7356 10.006 7.99989 10C8.26417 10.006 8.52693 9.95836 8.77229 9.85997C9.01765 9.76158 9.24052 9.61448 9.42745 9.42756C9.61437 9.24063 9.76147 9.01776 9.85986 8.7724C9.95825 8.52704 10.0059 8.26428 9.99989 8C10.0059 7.73572 9.95825 7.47296 9.85986 7.2276C9.76147 6.98224 9.61437 6.75937 9.42745 6.57244C9.24052 6.38552 9.01765 6.23842 8.77229 6.14003C8.52693 6.04164 8.26417 5.99401 7.99989 6Z"
                fill={getIconColor(isActive, isDisabled)}
            />
            <path
                d="M14.6522 5.522L13.4719 3.4781C13.3565 3.27775 13.1752 3.12364 12.9588 3.04207C12.7425 2.9605 12.5045 2.95652 12.2855 3.0308L11.0685 3.44245C10.8588 3.30119 10.6396 3.17451 10.4125 3.0633L10.1606 1.804C10.1153 1.57726 9.99283 1.37324 9.81401 1.22665C9.6352 1.08006 9.41111 0.999966 9.17989 1H6.81989C6.58867 0.999966 6.36458 1.08006 6.18576 1.22665C6.00695 1.37324 5.88446 1.57726 5.83914 1.804L5.58729 3.0633C5.35769 3.17331 5.13604 3.29919 4.92394 3.44L3.71424 3.0308C3.49527 2.95652 3.25732 2.9605 3.04096 3.04207C2.82461 3.12364 2.64326 3.27775 2.52784 3.4781L1.34754 5.522C1.23199 5.72224 1.18936 5.95631 1.22691 6.18443C1.26445 6.41254 1.37985 6.62061 1.55349 6.77325L2.51894 7.62165C2.51039 7.74735 2.49989 7.87235 2.49989 8C2.49989 8.1289 2.50489 8.25635 2.51379 8.3828L1.55349 9.2268C1.37985 9.37944 1.26445 9.58751 1.22691 9.81562C1.18936 10.0437 1.23199 10.2778 1.34754 10.4781L2.52784 12.522C2.64326 12.7224 2.82461 12.8765 3.04096 12.958C3.25732 13.0396 3.49527 13.0436 3.71424 12.9693L4.93129 12.5576C5.14095 12.699 5.36014 12.8257 5.58729 12.9368L5.83914 14.1961C5.88448 14.4228 6.00698 14.6268 6.18579 14.7734C6.3646 14.92 6.58868 15 6.81989 15H8.99989V14H6.81989L6.46489 12.2246C5.97386 12.0423 5.51802 11.7765 5.11749 11.4389L3.39379 12.022L2.21379 9.9781L3.57644 8.78055C3.48323 8.2635 3.48204 7.73406 3.57294 7.2166L2.21364 6.022L3.39419 3.9781L5.10754 4.55765C5.51086 4.21985 5.97012 3.95513 6.46459 3.77545L6.81989 2H9.17989L9.53489 3.7754C10.0259 3.95776 10.4817 4.22355 10.8823 4.56105L12.6057 3.97805L13.7857 6.02195L12.3868 7.24805L13.046 8L14.446 6.7732C14.6197 6.6206 14.7351 6.41255 14.7727 6.18445C14.8103 5.95634 14.7677 5.72226 14.6522 5.522Z"
                fill={getIconColor(isActive, isDisabled)}
            />
            <path
                d="M11.4999 13.09L10.2049 11.795L9.49989 12.5L11.4999 14.5L14.9999 11L14.2949 10.295L11.4999 13.09Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default SettingsCheckSvgIcon;
