import React, {FC} from 'react';

import Box from '@mui/material/Box';

import {WorkflowAttachmentTypes} from 'appRedux/actions/workflow/types';

import AttachmentsListRow from 'pages/admin/updateWorkflowEvents/workflowAttachments/AttachmentsListRow';

interface AttachmentsListType {
    items: WorkflowAttachmentTypes[];
}

const AttachmentsList: FC<AttachmentsListType> = ({items}) => {
    return (
        <Box sx={{mt: 2}}>
            {items.map((item: WorkflowAttachmentTypes) => {
                return <AttachmentsListRow item={item} key={`attachment-${item.uuid}`} />;
            })}
        </Box>
    );
};

export default AttachmentsList;
