import React, {FC} from 'react';
import {FieldProps} from 'formik';

import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';

import {theme} from 'config/theme';

const RoleFormCheckbox: FC<FieldProps> = ({field}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
        >
            <Checkbox sx={{'& svg': {color: theme.palette.info.main}}} {...field} />
        </Box>
    );
};

export default RoleFormCheckbox;
