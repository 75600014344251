import React, {FC, MouseEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';

import {GroupAgentTypes} from 'appRedux/actions/workflow/types';

import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';
import AgentCloseIconButton from 'components/AgentScreenComponents/_buttons/AgentCloseIconButton';
import {buttonOutlineStyles, labelOutlineStyles} from 'components/AgentScreenComponents/_buttons/common';

interface GroupChipButtonType {
    title: string;
    agents: GroupAgentTypes[];
    isOpen: boolean;
    toggleModal: () => void;
    setIsOpen: (valus: boolean) => void;
    onClickDelete: () => void;
}

const GroupChipButton: FC<GroupChipButtonType> = ({isOpen, title, agents, setIsOpen, toggleModal, onClickDelete}) => {
    const [t] = useTranslation();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Button
                sx={{...buttonOutlineStyles, pl: 0.5}}
                endIcon={<AgentCloseIconButton isOutlined onClose={() => setIsOpen(true)} />}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                <Typography sx={{...labelOutlineStyles, ml: 1}}>{title}</Typography>
            </Button>
            <DeleteAdminConfirmationModal
                toggleModal={toggleModal}
                isShowModal={isOpen}
                onClickAction={onClickDelete}
            />
            <Popover
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    pointerEvents: 'none',
                }}
                onClose={handlePopoverClose}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        p: 1,
                        pl: 2,
                        pr: 2,
                        borderRadius: 4,
                    }}
                >
                    {agents.length > 0 ? (
                        agents.map((agent: GroupAgentTypes, index: number) => {
                            return <Typography key={`agent-${index}`}>{agent.username}</Typography>;
                        })
                    ) : (
                        <Typography>{t('common.none')}</Typography>
                    )}
                </Box>
            </Popover>
        </>
    );
};

export default GroupChipButton;
