import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';

import {MessageTypes} from 'appRedux/actions/requestChat/types';

import {theme} from 'config/theme';

interface ChatMessageTitleType {
    isCurrentUserSender: boolean;
    previousMessageSenderSame: boolean;
    item: MessageTypes;
}

const ChatMessageTitle: FC<ChatMessageTitleType> = ({item, isCurrentUserSender, previousMessageSenderSame}) => {
    const [t] = useTranslation();

    const {senderName, isSystemMessage} = item;

    if (isSystemMessage) {
        return (
            <Typography
                sx={{
                    mb: 1,
                    fontWeight: 600,
                    color: theme.palette.info.main,
                    width: '100%',
                    textWrap: 'wrap',
                }}
            >
                {isSystemMessage ? t('requester.chat.systemMessage') : senderName}
            </Typography>
        );
    }

    if (!previousMessageSenderSame) {
        return (
            <Typography
                sx={{
                    mb: 1,
                    fontWeight: 600,
                    color: isCurrentUserSender ? theme.palette.info.main : theme.palette.background.default,
                    width: '100%',
                    textWrap: 'wrap',
                }}
            >
                {senderName}
            </Typography>
        );
    }

    return null;
};

export default ChatMessageTitle;
