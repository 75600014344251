import React, {FC, MouseEvent, useCallback, useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import i18n from 'i18next';
import get from 'lodash/get';

import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import TranslateIcon from '@mui/icons-material/Translate';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {FormHasLanguageTypes} from 'appRedux/actions/forms/types';
import {SET_LANGUAGE} from 'appRedux/actions/profile';

import {MediaContext} from 'contexts/media/context';

import ClientOutlinedButton from 'components/ClientScreenComponents/ClientOutlinedButton';

import {getLanguageById} from 'helpers/translationsHelper';

import {LOCAL_STORAGE_CURRENT_LANGUAGE} from 'services/localStorage';

import {DEFAULT_LANGUAGE_LABEL} from 'config/index';
import {theme} from 'config/theme';

const LanguageDropdownSwitcher: FC = () => {
    const dispatch = useDispatch();

    const {isMobile} = useContext(MediaContext);

    const {
        admin: {formInfo, languageList},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const defaultLanguageId: number | null = get(formInfo, 'defaultLanguage', null);
    const organizationLanguage = defaultLanguageId ? getLanguageById(languageList, defaultLanguageId) : null;
    const defaultLanguageLabel = organizationLanguage ? organizationLanguage.lang : DEFAULT_LANGUAGE_LABEL;
    const currentLanguage = profile ? getLanguageById(languageList, profile.languageId).lang : defaultLanguageLabel;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onChange = (value: string) => {
        onLanguageChange(value);
        handleClose();
    };

    const updateLanguage = useCallback(data => dispatch({type: SET_LANGUAGE.REQUEST, payload: data}), [dispatch]);

    const onLanguageChange = (value: string) => {
        i18n.changeLanguage(value);
        localStorage.setItem(LOCAL_STORAGE_CURRENT_LANGUAGE, value);
        updateLanguage({lang: value});
    };

    const formHasLanguages: FormHasLanguageTypes[] = get(formInfo, 'languages', []);

    const availableLanguages = formHasLanguages.filter((item: FormHasLanguageTypes) => {
        const {
            isActive,
            isAvailableForOrganization,
            language: {enabled},
        } = item;
        return isActive && isAvailableForOrganization && enabled;
    });

    if (!availableLanguages || availableLanguages.length === 0 || formHasLanguages.length === 0) {
        return null;
    }

    return (
        <>
            {isMobile ? (
                <IconButton
                    sx={{
                        backgroundColor: theme.palette.info.main,
                        '&:hover': {
                            backgroundColor: theme.palette.info.main,
                        },
                    }}
                    onClick={handleClick}
                >
                    <TranslateIcon sx={{color: theme.palette.background.default}} />
                </IconButton>
            ) : (
                <ClientOutlinedButton
                    open={open}
                    label={currentLanguage}
                    dataId="dropdown#language"
                    handleClick={handleClick}
                    startIcon={
                        <TranslateIcon
                            sx={{color: open ? theme.palette.background.default : theme.palette.info.main}}
                        />
                    }
                />
            )}
            <Menu
                sx={{
                    minWidth: 100,
                    mt: 2,
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {availableLanguages &&
                    availableLanguages.map((language: FormHasLanguageTypes) => {
                        const {
                            language: {name, lang},
                        } = language;
                        return (
                            <MenuItem key={`language-${name}`} value={name} onClick={() => onChange(name)}>
                                <Typography>{lang}</Typography>
                            </MenuItem>
                        );
                    })}
            </Menu>
        </>
    );
};

export default LanguageDropdownSwitcher;
