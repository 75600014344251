import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {FORM_FAQ_UPDATE} from 'appRedux/actions/forms';
import {FormFaqFormTypes, FormFaqItemTypes} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';

import FaqForm from 'components/Forms/FormBuilder/FaqForm/FaqForm';

interface UpdateFaqFormType {
    item: FormFaqItemTypes;
    closeModal: () => void;
}

const UpdateFaqForm: FC<UpdateFaqFormType> = ({item, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const submitUpdateForm = useCallback(data => dispatch({type: FORM_FAQ_UPDATE.REQUEST, payload: data}), [dispatch]);

    const {question, language, answer, uuid} = item;

    const onSubmitAction = (values: FormFaqFormTypes) => {
        submitUpdateForm({
            uuid,
            ...values,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <FaqForm
            initialValues={{
                question,
                language,
                answer,
            }}
            onSubmitAction={onSubmitAction}
        />
    );
};

export default UpdateFaqForm;
