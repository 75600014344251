export const CASE_ACCESS_REQUEST_CREATED = 0;
export const CASE_ACCESS_REQUEST_APPROVED = 1;
export const CASE_ACCESS_REQUEST_DENIED = 2;

export const getAccessRequestStatusLabel = (status: number): string => {
    switch (status) {
        case CASE_ACCESS_REQUEST_CREATED:
            return 'orguser.accessRequests.statusTypes.accessRequestCreated';
        case CASE_ACCESS_REQUEST_APPROVED:
            return 'orguser.accessRequests.statusTypes.accessRequestApproved';
        case CASE_ACCESS_REQUEST_DENIED:
            return 'orguser.accessRequests.statusTypes.accessRequestDenied';
    }
    return '';
};
