import React, {FC} from 'react';

interface IconTypeSvgType {
    isActive?: boolean;
}

const IconType_02: FC<IconTypeSvgType> = ({isActive}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.225 3C11.589 2.99969 11.9495 3.07207 12.2852 3.21287C12.6209 3.35368 12.9251 3.56009 13.18 3.82C13.7052 4.35319 13.9996 5.07158 13.9996 5.82C13.9996 6.56842 13.7052 7.28681 13.18 7.82L8 13.065L2.82 7.82C2.29479 7.28681 2.00039 6.56842 2.00039 5.82C2.00039 5.07158 2.29479 4.35319 2.82 3.82C3.07504 3.56028 3.37929 3.35398 3.71495 3.21315C4.05062 3.07232 4.41099 2.99979 4.775 2.99979C5.13901 2.99979 5.49937 3.07232 5.83504 3.21315C6.17071 3.35398 6.47495 3.56028 6.73 3.82L8 5.12L9.265 3.83C9.51912 3.56699 9.82367 3.35793 10.1604 3.21532C10.4972 3.07271 10.8593 2.99948 11.225 3ZM11.225 2C10.7278 1.99958 10.2355 2.09843 9.77705 2.29075C9.31857 2.48308 8.9031 2.765 8.555 3.12L8 3.68L7.445 3.12C7.09647 2.76564 6.6809 2.48421 6.22249 2.2921C5.76409 2.09999 5.27203 2.00105 4.775 2.00105C4.27797 2.00105 3.78591 2.09999 3.3275 2.2921C2.8691 2.48421 2.45352 2.76564 2.105 3.12C1.39594 3.84181 0.998646 4.81318 0.998646 5.825C0.998646 6.83682 1.39594 7.80819 2.105 8.53L8 14.5L13.895 8.53C14.6041 7.80819 15.0014 6.83682 15.0014 5.825C15.0014 4.81318 14.6041 3.84181 13.895 3.12C13.5466 2.76545 13.131 2.48382 12.6726 2.29153C12.2142 2.09924 11.7221 2.00014 11.225 2Z"
                fill={isActive ? '#ffffff' : '#222222'}
            />
        </svg>
    );
};

export default IconType_02;
