import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import Box from '@mui/material/Box';

import {GENERAL_TEMPLATE_LIST, GENERAL_TEMPLATE_LIST_DEFAULT} from 'appRedux/actions/generalTemplates';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import DataGridWrapper from 'components/AdminScreenComponents/DataGridWrapper';
import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

import {getDefaultGeneralTemplates, getCurrentTemplates} from 'pages/admin/generalTemplate/helper';
import GeneralTemplatesDataGrid from 'pages/admin/generalTemplate/GeneralTemplatesDataGrid';
import TranslationSelector from 'pages/admin/generalTemplate/TranslationSelector';

import {getLanguageIdByName} from 'helpers/translationsHelper';

import {DEFAULT_LANGUAGE_ID, PARAMETER_LANGUAGE} from 'config/index';

const GeneralTemplates: FC = () => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const languageFromUrl = searchParams.get(PARAMETER_LANGUAGE);

    const {showAlert} = useContext(AlertContext);

    const getGeneralTemplates = useCallback(
        data => dispatch({type: GENERAL_TEMPLATE_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const getGeneralDefaultTemplatesList = useCallback(
        data => dispatch({type: GENERAL_TEMPLATE_LIST_DEFAULT.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {languageList},
        generalTemplates: {isLoading, generalTemplates, defaultTemplates},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    if (!profile) return null;

    const languageName = languageFromUrl ?? profile.lang;
    const languageId = getLanguageIdByName(languageList, languageName, DEFAULT_LANGUAGE_ID);
    const defaultTemplatesList = defaultTemplates
        ? getDefaultGeneralTemplates(defaultTemplates, languageName, languageId)
        : [];
    const templates = getCurrentTemplates(defaultTemplatesList, generalTemplates, languageId);

    useEffect(() => {
        getGeneralTemplates({
            language: languageId,
            showAlert,
        });
    }, [languageId]);

    useEffect(() => {
        getGeneralDefaultTemplatesList({
            showAlert,
        });
    }, []);

    return (
        <ClientSectionWrapper>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}
            >
                <TranslationSelector languageName={languageName} languageId={languageId} />
            </Box>
            <DataGridWrapper itemsList={templates} itemsTotalNumber={templates ? templates.length : 0}>
                {templates && !isLoading && <GeneralTemplatesDataGrid language={languageId} items={templates} />}
            </DataGridWrapper>
        </ClientSectionWrapper>
    );
};

export default GeneralTemplates;
