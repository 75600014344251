import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {RootReducer} from 'appRedux/reducers';

import {getCurrentRoleLabel, getUserName} from 'components/TopBarMenu/helper';
import CurrentUserAvatar from 'components/UserAvatar/CurrentUserAvatar';

import {theme} from 'config/theme';

interface AvatarNameAndRoleType {
    isMobileMenu?: boolean;
}

const commonLabelStyles = {
    color: theme.palette.background.default,
    maxWidth: 170,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};

const AvatarNameAndRole: FC<AvatarNameAndRoleType> = ({isMobileMenu}) => {
    const [t] = useTranslation();

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    if (!profile) {
        return null;
    }

    const {firstName, lastName, email, roles, nickname, isAnonymousAccount} = profile;

    if (isMobileMenu) {
        const getUsernameForMobile = () => {
            if (isAnonymousAccount) {
                return (
                    <Typography
                        sx={{
                            ...commonLabelStyles,
                            fontSize: 18,
                            fontWeight: 600,
                            mb: 1,
                        }}
                    >
                        {nickname}
                    </Typography>
                );
            } else if (firstName && lastName) {
                return (
                    <>
                        <Typography
                            sx={{
                                ...commonLabelStyles,
                                fontSize: 18,
                                mb: 1,
                            }}
                        >
                            {firstName}
                        </Typography>
                        <Typography
                            sx={{
                                ...commonLabelStyles,
                                fontSize: 18,
                                fontWeight: 600,
                            }}
                        >
                            {lastName}
                        </Typography>
                    </>
                );
            } else {
                return (
                    <Typography
                        sx={{
                            ...commonLabelStyles,
                            fontSize: 18,
                            fontWeight: 600,
                            mb: 1,
                        }}
                    >
                        {email}
                    </Typography>
                );
            }
        };

        return (
            <Grid container>
                <Grid item xs={4}>
                    <CurrentUserAvatar size={90} />
                </Grid>
                <Grid
                    item
                    xs={6}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                >
                    {getUsernameForMobile()}
                </Grid>
            </Grid>
        );
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'justify-content',
                alignItems: 'center',
            }}
        >
            <CurrentUserAvatar size={80} />
            <Typography
                sx={{
                    ...commonLabelStyles,
                    fontWeight: 600,
                    color: theme.palette.info.main,
                    textAlign: 'center',
                    mt: 2,
                    mb: 1,
                }}
            >
                {isAnonymousAccount ? nickname : getUserName(profile)}
            </Typography>
            <Typography
                sx={{
                    fontSize: 12,
                    color: theme.palette.info.main,
                    mb: 2,
                }}
            >
                {t(`common.roles.${getCurrentRoleLabel(roles)}`)}
            </Typography>
        </Box>
    );
};

export default AvatarNameAndRole;
