import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';
import {DELETE_WORKFLOW_STATUS} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';

import {STATUS_OPENING, STATUS_PREPARED, STATUS_DELETED} from 'config/index';

interface DeleteWorkflowStatusButtonType {
    item: WorkflowStatusItemTypes;
}

const DeleteWorkflowStatusButton: FC<DeleteWorkflowStatusButtonType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {uuid, isInitial, statusType, hasRelatedCases} = item;

    const removeWorkflowStatus = useCallback(
        data => dispatch({type: DELETE_WORKFLOW_STATUS.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        removeWorkflowStatus({
            uuid,
            isCurrent: false,
            showAlert,
            callback: () => setIsOpened(false),
        });
    };

    const isDeleteImpossible =
        isInitial && [STATUS_OPENING, STATUS_PREPARED, STATUS_DELETED].includes(String(statusType));

    return (
        <>
            <IconButton title={t('common.buttons.delete')} onClick={toggleIsOpened} disabled={isDeleteImpossible}>
                <DeleteSvgIcon isDisabled={isDeleteImpossible} />
            </IconButton>
            <DeleteAdminConfirmationModal
                toggleModal={toggleIsOpened}
                deletingRestrict={hasRelatedCases}
                description={hasRelatedCases ? t('orguser.workflows.workflowStatusDeletingRestrict') : ''}
                isShowModal={isOpened}
                onClickAction={onClickDelete}
            />
        </>
    );
};

export default DeleteWorkflowStatusButton;
