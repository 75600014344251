import React, {FC, ReactNode, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';

import PlusSvgIcon from 'assets/icons/section/PlusSvgIcon';
import MinusSvgIcon from 'assets/icons/section/MinusSvgIcon';

import {MediaContext} from 'contexts/media/context';

import AvatarArea from 'components/UserAvatar/AvatarArea';
import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

import {theme} from 'config/theme';

interface AgentSectionWrapperType {
    title: string;
    isEditProfile?: boolean;
    children: ReactNode;
    identifier: string;
}

const ProfileSectionWrapper: FC<AgentSectionWrapperType> = ({title, isEditProfile, children, identifier}) => {
    const [t] = useTranslation();

    const {isMobile} = useContext(MediaContext);

    const [isExpanded, setIsExpanded] = useState<boolean>(true);

    const toggleExpanded = () => {
        setIsExpanded(previous => !previous);
    };

    if (isMobile) {
        return (
            <ClientSectionWrapper title={t(title)}>
                <Box sx={{mb: 1}}>{isEditProfile && <AvatarArea />}</Box>
                {children}
            </ClientSectionWrapper>
        );
    }
    return (
        <Box sx={{mb: 3, pl: 2, pr: 2, borderRadius: 2, backgroundColor: theme.palette.background.default}}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: isExpanded ? 'flex-end' : 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    height: 60,
                }}
            >
                {!isExpanded && (
                    <Typography
                        sx={{
                            fontSize: 16,
                            ml: 2,
                        }}
                    >
                        {t(title)}
                    </Typography>
                )}
                <IconButton data-id={`button#profile-section-${identifier}-toggle-expanded`} onClick={toggleExpanded}>
                    {isExpanded ? <MinusSvgIcon /> : <PlusSvgIcon />}
                </IconButton>
            </Box>
            {isExpanded && (
                <Box sx={{pb: 4}}>
                    <Grid container>
                        <Grid item xs={12} sm={4} sx={{mb: 2}}>
                            {isEditProfile && <AvatarArea />}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography
                                sx={{
                                    fontSize: 24,
                                    lineHeight: '32px',
                                    mb: 2,
                                }}
                            >
                                {t(title)}
                            </Typography>
                            {children}
                        </Grid>
                        <Grid item xs={12} sm={4} sx={{mb: 2}}></Grid>
                    </Grid>
                </Box>
            )}
        </Box>
    );
};

export default ProfileSectionWrapper;
