import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {Link, useNavigate, useLocation, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import {IconButton} from '@mui/material';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import {RootReducer} from 'appRedux/reducers';

import {RouteContext} from 'contexts/route/context';
import {AdminContext} from 'contexts/admin/context';

import TopBarMenu from 'components/TopBarMenu/TopBarMenu';
import EmptyArea from 'components/EmptyArea';
import {AGENT_TOP_BAR_HEIGHT} from 'components/AgentScreenComponents/helper';
import ActiveCasesTopBar from 'components/AgentScreenComponents/_caseBlock/ActiveCasesTopBar';
import ActiveCasesFilterPanel from 'components/AgentScreenComponents/_caseBlock/ActiveCasesFilterPanel';
import BackLinkLabel from 'components/AgentScreenComponents/_topBar/BackLinkLabel';
import ProfileNotification from 'components/AgentScreenComponents/_topBar/ProfileNotification';
import SessionTimer from 'components/SessionTimer/SessionTimer';
import Notifications from 'components/Notifications/Notifications';

import {theme} from 'config/theme';
import {AlertContext} from 'contexts/alert/context';
import {TOGGLE_BOARD_FAVORITE} from 'appRedux/actions/workflow';
import {BoardItemTypes} from 'appRedux/actions/workflow/types';

interface AgentTopBarType {
    setCurrentTab: (value: string) => void;
}

const AgentTopBar: FC<AgentTopBarType> = ({setCurrentTab}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const location = useLocation();
    const {uuid} = useParams();

    const {key} = location;

    const {showAlert} = useContext(AlertContext);

    const {showFiltersPanel} = useContext(AdminContext);
    const {
        isSuperAdminPage,
        backLink,
        backLinkLabel,
        backTab,
        topBarTitle,
        topBarSubtitle,
        isBoardCasesPage,
        isBoardListCasesPage,
        pageBreadcrumbs,
        isSwimlaneCasesPage,
    } = useContext(RouteContext);

    const {
        profile: {profile},
        workflow: {favoriteBoards},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const toggleBoardFavorite = useCallback(
        data => dispatch({type: TOGGLE_BOARD_FAVORITE.REQUEST, payload: data}),
        [dispatch],
    );

    const onLinkClick = () => {
        if (backTab) {
            setCurrentTab(backTab);
        } else if (key !== 'default') {
            navigate(-1);
        }
    };
    const [isFavorite, setIsFavorite] = useState(false);

    const toggleFavorite = () => {
        setIsFavorite(prev => !prev);
        toggleBoardFavorite({
            uuid,
            showAlert,
            callback: () => {
                return;
            },
        });
    };

    useEffect(() => {
        if (!favoriteBoards) return;
        const foundInFavorites = favoriteBoards.find((item: BoardItemTypes) => {
            return item.uuid === uuid;
        });

        setIsFavorite(!!foundInFavorites);
    }, [favoriteBoards, uuid]);

    if (!profile) return null;

    const {isVerify, isTwoFactorActivated, secretCodeExpiredTime} = profile;

    return (
        <>
            <Box
                sx={{
                    top: 0,
                    position: 'sticky',
                    zIndex: 100,
                    backgroundColor: theme.palette.warning.contrastText,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: !!pageBreadcrumbs.length || topBarTitle ? 'space-between' : 'flex-end',
                    alignItems: 'center',
                    pl: 3,
                    pr: 3,
                    pt: 1,
                    pb: 1,
                    minHeight: AGENT_TOP_BAR_HEIGHT,
                }}
            >
                {!pageBreadcrumbs.length && backLinkLabel && (
                    <Box sx={{mr: backLinkLabel ? 1 : 0}}>
                        {backLink && backLinkLabel ? (
                            <Link to={backLink} style={{textDecoration: 'none'}} onClick={onLinkClick}>
                                <BackLinkLabel backLinkLabel={backLinkLabel} onLinkClick={onLinkClick} />
                            </Link>
                        ) : backLinkLabel && key !== 'default' ? (
                            <BackLinkLabel backLinkLabel={backLinkLabel} onLinkClick={onLinkClick} />
                        ) : null}
                    </Box>
                )}
                {topBarTitle && (
                    <Typography
                        sx={{
                            ml: 3,
                            fontSize: 24,
                        }}
                    >
                        {topBarTitle}
                    </Typography>
                )}
                {!!pageBreadcrumbs.length && (
                    <Breadcrumbs aria-label="breadcrumb" sx={{height: '24px', ml: 3}}>
                        {pageBreadcrumbs.map(({href, label, Icon}) => (
                            <Link
                                to={href}
                                key={href}
                                style={{textDecoration: 'none', pointerEvents: href ? 'all' : 'none'}}
                            >
                                <MuiLink
                                    underline={href ? 'hover' : 'none'}
                                    color={theme.palette.info.main}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                    }}
                                >
                                    {Icon && <Icon />}
                                    {t(label)}
                                </MuiLink>
                            </Link>
                        ))}
                    </Breadcrumbs>
                )}
                {topBarSubtitle && <Typography sx={{ml: 3, fontSize: 12}}>{topBarSubtitle}</Typography>}
                {(isBoardCasesPage || isBoardListCasesPage || isSwimlaneCasesPage) && (
                    <IconButton
                        title={t(`common.buttons.${isFavorite ? 'removeFromFavorites' : 'addToFavorites'}`)}
                        onClick={toggleFavorite}
                        size="small"
                        sx={{ml: 1}}
                    >
                        {isFavorite ? <FavoriteIcon fontSize="small" /> : <FavoriteBorderIcon fontSize="small" />}
                    </IconButton>
                )}
                <EmptyArea />
                {(isBoardCasesPage || isBoardListCasesPage || isSwimlaneCasesPage) && <ActiveCasesTopBar />}
                <EmptyArea />
                <EmptyArea />
                <SessionTimer />
                {(!isVerify || (!isTwoFactorActivated && secretCodeExpiredTime)) && <ProfileNotification />}
                {!isSuperAdminPage && <Notifications />}
                <TopBarMenu />
            </Box>
            {showFiltersPanel && (isBoardCasesPage || isBoardListCasesPage) && <ActiveCasesFilterPanel />}
        </>
    );
};

export default AgentTopBar;
