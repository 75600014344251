import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import CreateResourceFieldCalendarForm from 'components/Forms/ResourceField/CreateResourceFieldCalendarForm';

import {theme} from 'config/theme';

const AddResourceFieldCalendar: FC = () => {
    const [t] = useTranslation();
    const {uuid} = useParams();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mt: 1,
            }}
        >
            <Typography sx={{fontWeight: 700}}>{t('orguser.resourceFields.types.calendar')}</Typography>
            <IconButton
                sx={{
                    ml: 1,
                    backgroundColor: theme.palette.info.main,
                    '&:hover': {
                        backgroundColor: theme.palette.info.main,
                    },
                }}
                title={t('common.buttons.add')}
                onClick={() => setShowModal(true)}
            >
                <PlusSvgIcon isActive />
            </IconButton>
            <ModalWrapper
                isShowModal={showModal}
                toggleModal={toggleModal}
                title={`${t('orguser.resourceFields.addResourceField')} (${t(
                    'orguser.resourceFields.types.calendar',
                )})`}
            >
                <CreateResourceFieldCalendarForm uuid={String(uuid)} closeModal={() => setShowModal(false)} />
            </ModalWrapper>
        </Box>
    );
};

export default AddResourceFieldCalendar;
