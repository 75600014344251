import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {BoardItemTypes, CreateSwimlaneBoardRequestTypes} from 'appRedux/actions/workflow/types';
import {UPDATE_BOARD_SWIMLANE} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import SwimlaneBoardForm from 'components/Forms/BoardForm/SwimlaneBoardForm';

interface EditBoardSwimlaneFormType {
    item: BoardItemTypes;
}

const EditBoardSwimlaneForm: FC<EditBoardSwimlaneFormType> = ({item}) => {
    const dispatch = useDispatch();

    const {uuid, iconType, title, workflowId, typeSwimlane} = item;

    const {showAlert} = useContext(AlertContext);

    const updateBoardSwimlane = useCallback(
        data => dispatch({type: UPDATE_BOARD_SWIMLANE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClick = (values: CreateSwimlaneBoardRequestTypes) => {
        updateBoardSwimlane({
            uuid,
            ...values,
            showAlert,
        });
    };

    return (
        <SwimlaneBoardForm
            initialValues={{iconType, title, workflow: Number(workflowId), typeSwimlane}}
            onSubmitClick={onSubmitClick}
        />
    );
};

export default EditBoardSwimlaneForm;
