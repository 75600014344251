import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Markdown from 'react-markdown';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {OrganizationItemBasic} from 'appRedux/actions/organization/types';
import {RootReducer} from 'appRedux/reducers';

import {getLanguageById} from 'helpers/translationsHelper';

import {EMPTY_DATA} from 'config/index';

interface OrganizationInformationType {
    organization: OrganizationItemBasic;
}

const OrganizationInformation: FC<OrganizationInformationType> = ({organization}) => {
    const [t] = useTranslation();

    const {
        admin: {languageList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {name, email, description, address, language} = organization;

    const organizationLanguage = getLanguageById(languageList, language);

    return (
        <Box>
            <Grid container sx={{mb: 2}}>
                <Grid item sm={3}>
                    <Typography sx={{fontWeight: 600}}>{t('superadmin.organizations.organizationName')}</Typography>
                </Grid>
                <Grid item sm={9}>
                    <Typography>{name}</Typography>
                </Grid>
            </Grid>
            <Grid container sx={{mb: 2}}>
                <Grid item sm={3}>
                    <Typography sx={{fontWeight: 600}}>{t('superadmin.organizations.organizationEmail')}</Typography>
                </Grid>
                <Grid item sm={9}>
                    <Typography>{email}</Typography>
                </Grid>
            </Grid>
            {organizationLanguage && (
                <Grid container sx={{mb: 2}}>
                    <Grid item sm={3}>
                        <Typography sx={{fontWeight: 600}}>{t('superadmin.organizations.language')}</Typography>
                    </Grid>
                    <Grid item sm={9}>
                        <Typography>{organizationLanguage.lang}</Typography>
                    </Grid>
                </Grid>
            )}
            <Grid container sx={{mb: 2}}>
                <Grid item sm={3}>
                    <Typography sx={{fontWeight: 600}}>{t('superadmin.organizations.description')}</Typography>
                </Grid>
                <Grid item sm={9}>
                    <Typography sx={{'& p': {m: 0}}}>
                        {description ? <Markdown>{description}</Markdown> : EMPTY_DATA}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container sx={{mb: 2}}>
                <Grid item sm={3}>
                    <Typography sx={{fontWeight: 600}}>{t('superadmin.organizations.address')}</Typography>
                </Grid>
                <Grid item sm={9}>
                    <Typography>{address ?? EMPTY_DATA}</Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default OrganizationInformation;
