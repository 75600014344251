import React, {FC} from 'react';

import {ResourceFieldItemTypes} from 'appRedux/actions/resourceFields/types';

import {
    RESOURCE_FIELD_TYPE_BUDGET,
    RESOURCE_FIELD_TYPE_SLOT_TICKET,
    RESOURCE_FIELD_TYPE_CALENDAR,
} from 'components/Forms/ResourceField/helper';

import ResourceFieldBudgetList from 'pages/admin/updateResourceField/budget/ResourceFieldBudgetList';
import ResourceFieldSlotList from 'pages/admin/updateResourceField/slot/ResourceFieldSlotList';
import ResourceFieldCalendarList from 'pages/admin/updateResourceField/calendar/ResourceFieldCalendarList';

interface ResourceFieldDetailsType {
    item: ResourceFieldItemTypes;
}

const ResourceFieldDetails: FC<ResourceFieldDetailsType> = ({item}) => {
    const {type} = item;

    if (type === RESOURCE_FIELD_TYPE_BUDGET) {
        return <ResourceFieldBudgetList />;
    }
    if (type === RESOURCE_FIELD_TYPE_SLOT_TICKET) {
        return <ResourceFieldSlotList />;
    }
    if (type === RESOURCE_FIELD_TYPE_CALENDAR) {
        return <ResourceFieldCalendarList />;
    }
    return null;
};

export default ResourceFieldDetails;
