import {theme} from 'config/theme';

export interface CommonSvgIconType {
    isActive?: boolean;
    isDisabled?: boolean;
    defaultColor?: string;
}

export const getIconColor = (isActive?: boolean, isDisabled?: boolean, defaultColor?: string) => {
    if (isActive) {
        return '#ffffff';
    }
    if (isDisabled) {
        return theme.palette.info.contrastText;
    }
    return defaultColor ? defaultColor : '#222222';
};
