import jwt_decode from 'jwt-decode';
import get from 'lodash/get';
import moment from 'moment';

import {
    AgentMenuItemTypes,
    ORG_USERS_LIST,
    BOARDS_LIST,
    GROUPS_LIST,
    INVITES_LIST,
    PUBLIC_KEYS_LIST,
    ACCESS_REQUESTS_LIST,
    ROLES_LIST,
    WORKFLOWS_LIST,
    TEMPLATES_LIST,
    ORGANIZATION_POLICY,
    RESOURCE_FIELDS,
    ORGANIZATION_TOKENS,
} from 'components/AgentScreenComponents/helper';

import {routes, userRoles} from 'config/index';

export const TOKEN_EXPIRE = 360;

export const getAccountRoute = (roles: string[], isClientMode: boolean): string => {
    if (!isClientMode) {
        if (roles.includes(userRoles.ROLE_SUPER_ADMIN)) {
            return routes.SUPER_ADMIN_ACCOUNT;
        } else if (roles.includes(userRoles.ROLE_ADMIN)) {
            return routes.ADMIN_ACCOUNT;
        } else if (roles.includes(userRoles.ROLE_AGENT)) {
            return routes.AGENT_ACCOUNT;
        }
    }
    return routes.ACCOUNT;
};

export const getSecurityRoute = (roles: string[], isClientMode: boolean): string => {
    if (!isClientMode) {
        if (roles.includes(userRoles.ROLE_SUPER_ADMIN)) {
            return routes.SUPER_ADMIN_SECURITY;
        } else if (roles.includes(userRoles.ROLE_ADMIN)) {
            return routes.ADMIN_SECURITY;
        } else if (roles.includes(userRoles.ROLE_AGENT)) {
            return routes.AGENT_SECURITY;
        }
    }
    return routes.SECURITY;
};

export const getExpirationAndCurrentTimeDifference = (token: string): number | false => {
    const decodedToken = jwt_decode(token);
    const expirationTime = get(decodedToken, 'exp', null);
    const currentTime = moment().unix();

    if (expirationTime) {
        return expirationTime - currentTime;
    }
    return false;
};

export const getTokenExpirationTime = (token: string): number => {
    const decodedToken = jwt_decode(token);
    const expirationTime = get(decodedToken, 'exp', null);
    const issuedAtTime = get(decodedToken, 'iat', null);

    if (expirationTime && issuedAtTime) {
        return expirationTime - issuedAtTime;
    }
    return 360;
};

export const isUsersListPage = (search: string, subItems: AgentMenuItemTypes[]): boolean => {
    return search.includes(`?tab=${ORG_USERS_LIST}`) && subItems.some(item => item.href.includes(ORG_USERS_LIST));
};

export const isGroupsListPage = (search: string, subItems: AgentMenuItemTypes[]): boolean => {
    return search.includes(`?tab=${GROUPS_LIST}`) && subItems.some(item => item.href.includes(GROUPS_LIST));
};

export const isBoardsListPage = (search: string, subItems: AgentMenuItemTypes[]): boolean => {
    return search.includes(`?tab=${BOARDS_LIST}`) && subItems.some(item => item.href.includes(BOARDS_LIST));
};

export const isInvitePage = (search: string, subItems: AgentMenuItemTypes[]): boolean => {
    return search.includes(`?tab=${INVITES_LIST}`) && subItems.some(item => item.href.includes(INVITES_LIST));
};

export const isPublicKeysPage = (search: string, subItems: AgentMenuItemTypes[]): boolean => {
    return search.includes(`?tab=${PUBLIC_KEYS_LIST}`) && subItems.some(item => item.href.includes(PUBLIC_KEYS_LIST));
};

export const isWorkflowsListPage = (search: string, subItems: AgentMenuItemTypes[]): boolean => {
    return search.includes(`?tab=${WORKFLOWS_LIST}`) && subItems.some(item => item.href.includes(WORKFLOWS_LIST));
};

export const isRolesListPage = (search: string, subItems: AgentMenuItemTypes[]): boolean => {
    return search.includes(`?tab=${ROLES_LIST}`) && subItems.some(item => item.href.includes(ROLES_LIST));
};

export const isAccessRequestsListPage = (search: string, subItems: AgentMenuItemTypes[]): boolean => {
    return (
        search.includes(`?tab=${ACCESS_REQUESTS_LIST}`) &&
        subItems.some(item => item.href.includes(ACCESS_REQUESTS_LIST))
    );
};

export const isResourceFieldsListPage = (search: string, subItems: AgentMenuItemTypes[]): boolean => {
    return search.includes(`?tab=${RESOURCE_FIELDS}`) && subItems.some(item => item.href.includes(RESOURCE_FIELDS));
};

export const inSubPageActive = (tabName: string, search: string, currentSubItem: AgentMenuItemTypes): boolean => {
    return search.includes(`?tab=${tabName}`) && currentSubItem.href.includes(tabName);
};

export const isWorkflowPage = (pathname: string, uuid?: string): boolean => {
    return (
        Boolean(uuid) && pathname.includes('/workflow') && (pathname.includes('/edit') || pathname.includes('/events'))
    );
};

export const isRolePage = (pathname: string, uuid?: string): boolean => {
    return Boolean(uuid) && pathname.includes('/role') && pathname.includes('/edit');
};

export const isCustomBoardPage = (pathname: string, uuid?: string): boolean => {
    return (
        Boolean(uuid) && (pathname.includes('/board') || pathname.includes('/swimlane')) && pathname.includes('/cases')
    );
};

export const isDashboardPanelsPage = (pathname: string, uuid?: string): boolean => {
    return Boolean(uuid) && pathname.includes('/dashboard') && pathname.includes('/panels');
};

export const isAssetRequestsPage = (pathname: string, uuid?: string): boolean => {
    return Boolean(uuid) && pathname.includes(`/${ACCESS_REQUESTS_LIST}`) && pathname.includes('/edit');
};

export const isResourceFieldsPage = (pathname: string, uuid?: string): boolean => {
    return (
        Boolean(uuid) &&
        ((pathname.includes(routes.RESOURCE_FIELD) && pathname.includes('/edit')) ||
            (pathname.includes(routes.RESOURCE_FIELD) && pathname.includes('/slot') && pathname.includes('/cases')))
    );
};

export const isGroupPage = (pathname: string, uuid?: string): boolean => {
    return Boolean(uuid) && pathname.includes(routes.GROUP) && pathname.includes('/edit');
};

export const isOrganizationTemplatesPage = (search: string, subItems: AgentMenuItemTypes[]): boolean => {
    return search.includes(`?tab=${TEMPLATES_LIST}`) && subItems.some(item => item.href.includes(TEMPLATES_LIST));
};

export const isOrganizationPrivacyPolicyPage = (search: string, subItems: AgentMenuItemTypes[]): boolean => {
    return (
        search.includes(`?tab=${ORGANIZATION_POLICY}`) && subItems.some(item => item.href.includes(ORGANIZATION_POLICY))
    );
};

export const isOrganizationTokensPage = (search: string, subItems: AgentMenuItemTypes[]): boolean => {
    return (
        search.includes(`?tab=${ORGANIZATION_TOKENS}`) && subItems.some(item => item.href.includes(ORGANIZATION_TOKENS))
    );
};

export const isCurrentSubItemActive = (
    menuItem: AgentMenuItemTypes,
    subItem: AgentMenuItemTypes,
    path: string,
    pathname: string,
    search: string | null,
    uuid?: string,
): boolean => {
    if (menuItem.subItems) {
        if (uuid && isWorkflowPage(pathname, uuid)) {
            return subItem.href.includes(WORKFLOWS_LIST);
        }
        if (uuid && isRolePage(pathname, uuid)) {
            return subItem.href.includes(ROLES_LIST);
        }
        if (uuid && isCustomBoardPage(pathname, uuid)) {
            return menuItem.href === pathname && subItem.href === search;
        }
        if (uuid && isDashboardPanelsPage(pathname, uuid)) {
            return subItem.href.includes(`/${uuid}/panels`);
        }
        if (uuid && isAssetRequestsPage(pathname, uuid)) {
            return subItem.href.includes(ACCESS_REQUESTS_LIST);
        }
        if (uuid && isResourceFieldsPage(pathname, uuid)) {
            return subItem.href.includes(RESOURCE_FIELDS);
        }
        if (search) {
            return (
                inSubPageActive(TEMPLATES_LIST, search, subItem) ||
                inSubPageActive(ORG_USERS_LIST, search, subItem) ||
                inSubPageActive(INVITES_LIST, search, subItem) ||
                inSubPageActive(BOARDS_LIST, search, subItem) ||
                inSubPageActive(PUBLIC_KEYS_LIST, search, subItem) ||
                inSubPageActive(WORKFLOWS_LIST, search, subItem) ||
                inSubPageActive(ROLES_LIST, search, subItem) ||
                inSubPageActive(ACCESS_REQUESTS_LIST, search, subItem) ||
                inSubPageActive(ORGANIZATION_POLICY, search, subItem) ||
                inSubPageActive(RESOURCE_FIELDS, search, subItem) ||
                inSubPageActive(ORGANIZATION_TOKENS, search, subItem) ||
                subItem.href === path + search
            );
        }
    }
    return false;
};
