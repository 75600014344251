import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import Markdown from 'react-markdown';

import Box from '@mui/material/Box';

import {SEND_EMAIL_UPDATE_REQUEST} from 'appRedux/actions/profile';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

const UpdateEmailPanel: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const submitUploadFile = useCallback(
        data => dispatch({type: SEND_EMAIL_UPDATE_REQUEST.REQUEST, payload: data}),
        [dispatch],
    );

    const onSendClick = () => {
        submitUploadFile({
            showAlert,
        });
    };

    return (
        <Box>
            <Markdown>{t('superadmin.organizations.mailchangeexplanation')}</Markdown>
            <Box
                sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', mt: 3}}
            >
                <AgentSaveButton isSubmit={false} title={t('common.buttons.send')} onClick={onSendClick} />
            </Box>
        </Box>
    );
};

export default UpdateEmailPanel;
