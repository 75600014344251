import React from 'react';

// UI
import Box from '@mui/material/Box';

import HerupuSpinner from 'assets/herupuSpinner';

const Loader = () => {
    return (
        <Box
            style={{
                position: 'fixed',
                inset: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
            }}
        >
            <HerupuSpinner size={150} />
        </Box>
    );
};

export default Loader;
