import {fork, put, take, call} from 'redux-saga/effects';

import {http} from 'services/http';

import {
    ORGANIZATION_ADMIN_LIST,
    ORGANIZATION_ADMIN_CREATE,
    ORGANIZATION_ADMIN_INVITE,
    ORGANIZATION_ADMIN_UPDATE,
    ORGANIZATION_ADMIN_PASSWORD_RESET,
    ORGANIZATION_ADMIN_UNLOCK,
    ORGANIZATION_ADMIN_DELETE,
    ORGANIZATION_ADMIN_2FA_DEACTIVATE,
    ORGANIZATION_AGENT_REASSIGN,
    ORGANIZATION_ADMIN_UPDATE_ROLES,
    ORGANIZATION_ADMIN_RELATE_OTHER_ORGANIZATION,
    getOrganizationAdminsList,
    createOrganizationAdmin,
    inviteOrganizationAdmin,
    updateOrganizationAdmin,
    resetPasswordOrganizationAdmin,
    unlockOrganizationAdmin,
    deleteOrganizationAdmin,
    deactivateOrgAdminTwoFactorAuthorization,
    reassignOrganizationAgent,
    updateOrganizationAdminRoles,
    relateOrganizationAdminToOtherOrganization,
} from 'appRedux/actions/organizationAdmin';
import {
    OrganizationAdminListTypes,
    OrganizationAdminRequestTypes,
    OrganizationAdminDeleteTypes,
    OrganizationAdminCreateTypes,
    OrganizationAdminResponseType,
    OrganizationAdminResetPasswordTypes,
    OrganizationAdminRelateTypes,
    OrganizationAdminReassignTypes,
    OrganizationAdminRolesRequestTypes,
    OrganizationAdminInviteTypes,
    RelateOrganizationAdminOtherOrganizationRequestTypes,
} from 'appRedux/actions/organizationAdmin/types';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchGetOrganizationAdminsList() {
    while (true) {
        const {
            payload: {id},
        }: IActionType<OrganizationAdminRequestTypes> = yield take(ORGANIZATION_ADMIN_LIST.REQUEST);
        try {
            const data: OrganizationAdminListTypes[] = yield call(http, `organization-admin/${id}/list`, {
                method: 'GET',
            });
            if (data) {
                yield put(getOrganizationAdminsList.success(data));
            } else {
                yield put(getOrganizationAdminsList.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getOrganizationAdminsList.error({message: String(e)}));
        }
    }
}

function* watchOrganizationAdminCreation() {
    while (true) {
        const {
            payload: {id, showAlert, callback, setErrors, ...values},
        }: IActionType<OrganizationAdminCreateTypes> = yield take(ORGANIZATION_ADMIN_CREATE.REQUEST);
        try {
            const data: OrganizationAdminResponseType = yield call(http, `organization-admin/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createOrganizationAdmin.success(data.organizationAdmins));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(
                    createOrganizationAdmin.error({
                        message: data.errors,
                    }),
                );
                setErrors && setErrors(data.errors);
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(createOrganizationAdmin.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationAdminInvitation() {
    while (true) {
        const {
            payload: {id, showAlert, callback, ...values},
        }: IActionType<OrganizationAdminInviteTypes> = yield take(ORGANIZATION_ADMIN_INVITE.REQUEST);
        try {
            const data: OrganizationAdminResponseType = yield call(http, `organization-admin/${id}/invite`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(inviteOrganizationAdmin.success(data.organizationAdmins));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(inviteOrganizationAdmin.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(inviteOrganizationAdmin.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationAdminUpdating() {
    while (true) {
        const {
            payload: {id, showAlert, callback, setErrors, ...values},
        }: IActionType<OrganizationAdminCreateTypes> = yield take(ORGANIZATION_ADMIN_UPDATE.REQUEST);
        try {
            const data: OrganizationAdminResponseType = yield call(http, `organization-admin/${id}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateOrganizationAdmin.success(data.organizationAdmins));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(updateOrganizationAdmin.error({message: 'messages.error.somethingWentWrong'}));
                setErrors && setErrors(data.errors);
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateOrganizationAdmin.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationAdminPasswordResetting() {
    while (true) {
        const {
            payload: {id, showAlert, callback, setErrors, ...values},
        }: IActionType<OrganizationAdminResetPasswordTypes> = yield take(ORGANIZATION_ADMIN_PASSWORD_RESET.REQUEST);
        try {
            const data: OrganizationAdminResponseType = yield call(http, `organization-admin/${id}/reset`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(resetPasswordOrganizationAdmin.success(data.organizationAdmins));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(
                    resetPasswordOrganizationAdmin.error({
                        message: data.errors,
                    }),
                );
                setErrors && setErrors(data.errors);
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(resetPasswordOrganizationAdmin.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationAdminUnlocking() {
    while (true) {
        const {
            payload: {id, showAlert, callback, ...values},
        }: IActionType<OrganizationAdminDeleteTypes> = yield take(ORGANIZATION_ADMIN_UNLOCK.REQUEST);
        try {
            const data: OrganizationAdminResponseType = yield call(http, `organization-admin/${id}/unlock`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(unlockOrganizationAdmin.success(data.organizationAdmins));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(unlockOrganizationAdmin.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(unlockOrganizationAdmin.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationAdminDeleting() {
    while (true) {
        const {
            payload: {id, showAlert, callback},
        }: IActionType<OrganizationAdminDeleteTypes> = yield take(ORGANIZATION_ADMIN_DELETE.REQUEST);
        try {
            const data: OrganizationAdminResponseType = yield call(http, `organization-admin/${id}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteOrganizationAdmin.success(data.organizationAdmins));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(deleteOrganizationAdmin.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteOrganizationAdmin.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationAgentReassign() {
    while (true) {
        const {
            payload: {id, showAlert, callback, ...values},
        }: IActionType<OrganizationAdminReassignTypes> = yield take(ORGANIZATION_AGENT_REASSIGN.REQUEST);
        try {
            const data: OrganizationAdminResponseType = yield call(http, `organization-admin/${id}/reassign`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(reassignOrganizationAgent.success(data.organizationAdmins));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(reassignOrganizationAgent.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(reassignOrganizationAgent.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrgAdminTwoFactorDeactivation() {
    while (true) {
        const {
            payload: {id, showAlert, callback, setErrors},
        }: IActionType<OrganizationAdminRelateTypes> = yield take(ORGANIZATION_ADMIN_2FA_DEACTIVATE.REQUEST);
        try {
            const data: OrganizationAdminResponseType = yield call(
                http,
                `organization-admin/${id}/two-factor-deactivate`,
                {
                    method: 'POST',
                },
            );
            if (data.success) {
                yield put(deactivateOrgAdminTwoFactorAuthorization.success(data.organizationAdmins));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(
                    deactivateOrgAdminTwoFactorAuthorization.error({message: 'messages.error.somethingWentWrong'}),
                );
                setErrors && setErrors(data.errors);
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deactivateOrgAdminTwoFactorAuthorization.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationAdminAddRole() {
    while (true) {
        const {
            payload: {id, showAlert, callback, ...values},
        }: IActionType<OrganizationAdminRolesRequestTypes> = yield take(ORGANIZATION_ADMIN_UPDATE_ROLES.REQUEST);
        try {
            const data: OrganizationAdminResponseType = yield call(http, `organization-admin/${id}/roles`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateOrganizationAdminRoles.success(data.organizationAdmins));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(updateOrganizationAdminRoles.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateOrganizationAdminRoles.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationAdminRelateOtherOrganization() {
    while (true) {
        const {
            payload: {id, showAlert, callback, ...values},
        }: IActionType<RelateOrganizationAdminOtherOrganizationRequestTypes> = yield take(
            ORGANIZATION_ADMIN_RELATE_OTHER_ORGANIZATION.REQUEST,
        );
        try {
            const data: OrganizationAdminResponseType = yield call(http, `organization-admin/${id}/relate`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(relateOrganizationAdminToOtherOrganization.success(data.organizationAdmins));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(
                    relateOrganizationAdminToOtherOrganization.error({message: 'messages.error.somethingWentWrong'}),
                );
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(relateOrganizationAdminToOtherOrganization.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchGetOrganizationAdminsList),
    fork(watchOrganizationAdminCreation),
    fork(watchOrganizationAdminInvitation),
    fork(watchOrganizationAdminUpdating),
    fork(watchOrganizationAdminPasswordResetting),
    fork(watchOrganizationAdminUnlocking),
    fork(watchOrganizationAdminDeleting),
    fork(watchOrganizationAgentReassign),
    fork(watchOrgAdminTwoFactorDeactivation),
    fork(watchOrganizationAdminAddRole),
    fork(watchOrganizationAdminRelateOtherOrganization),
];
