import {fork, put, take, call} from 'redux-saga/effects';

import {
    GET_GROUPS_LIST,
    getGroupsList,
    CREATE_GROUP,
    createGroup,
    UPDATE_GROUP,
    updateGroup,
    DELETE_GROUP,
    deleteGroup,
    GROUP_ATTACH_USER,
    attachUserToGroup,
    GROUP_DETACH_USER,
    detachUserToGroup,
} from 'appRedux/actions/groups';

import {
    GroupsListRequestTypes,
    GroupCreateRequestTypes,
    GroupUpdateRequestTypes,
    GroupDeleteRequestTypes,
    GroupAttachUserRequestTypes,
    GroupDetachUserRequestTypes,
    GroupResponseTypes,
} from 'appRedux/actions/groups/types';

import {http} from 'services/http';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchGroupsListGetting() {
    while (true) {
        const {
            payload: {showAlert, callback, id},
        }: IActionType<GroupsListRequestTypes> = yield take(GET_GROUPS_LIST.REQUEST);
        try {
            const data: GroupResponseTypes = yield call(http, `group/${id}/list`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getGroupsList.success(data.results));
                callback && callback();
            } else {
                yield put(getGroupsList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getGroupsList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchGroupCreation() {
    while (true) {
        const {
            payload: {showAlert, callback, id, ...values},
        }: IActionType<GroupCreateRequestTypes> = yield take(CREATE_GROUP.REQUEST);
        try {
            const data: GroupResponseTypes = yield call(http, `group/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createGroup.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createGroup.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createGroup.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchGroupUpdating() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<GroupUpdateRequestTypes> = yield take(UPDATE_GROUP.REQUEST);
        try {
            const data: GroupResponseTypes = yield call(http, `group/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateGroup.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateGroup.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateGroup.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchGroupDeleting() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<GroupDeleteRequestTypes> = yield take(DELETE_GROUP.REQUEST);
        try {
            const data: GroupResponseTypes = yield call(http, `group/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteGroup.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteGroup.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteGroup.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchUserToGroupAttaching() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<GroupAttachUserRequestTypes> = yield take(GROUP_ATTACH_USER.REQUEST);
        try {
            const data: GroupResponseTypes = yield call(http, `group/user/${uuid}/attach`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(attachUserToGroup.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(attachUserToGroup.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(attachUserToGroup.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchUserFromGroupDetaching() {
    while (true) {
        const {
            payload: {showAlert, callback, uuid, ...values},
        }: IActionType<GroupDetachUserRequestTypes> = yield take(GROUP_DETACH_USER.REQUEST);
        try {
            const data: GroupResponseTypes = yield call(http, `group/user/${uuid}/detach`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(detachUserToGroup.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(detachUserToGroup.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(detachUserToGroup.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchGroupsListGetting),
    fork(watchGroupCreation),
    fork(watchGroupUpdating),
    fork(watchGroupDeleting),
    fork(watchUserToGroupAttaching),
    fork(watchUserFromGroupDetaching),
];
