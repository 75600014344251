import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {DELETE_GROUP} from 'appRedux/actions/groups';
import {GroupItemTypes} from 'appRedux/actions/groups/types';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationButton from 'components/AdminScreenComponents/DeleteAdminConfirmationButton';

interface DeleteGroupButtonType {
    item: GroupItemTypes;
}

const DeleteGroupButton: FC<DeleteGroupButtonType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setIsShowModal(previous => !previous);
    };

    const {uuid, users} = item;

    const deleteGroup = useCallback(data => dispatch({type: DELETE_GROUP.REQUEST, payload: data}), [dispatch]);

    const onClickDelete = () => {
        deleteGroup({
            uuid,
            callback: toggleModal,
            showAlert,
        });
    };

    return (
        <DeleteAdminConfirmationButton
            disabled={false}
            deletingRestrict={users.length > 0}
            description={users.length > 0 ? t('orguser.groups.errorRemovingImpossibleRelatedUsers') : ''}
            isShowModal={isShowModal}
            toggleModal={toggleModal}
            onClickAction={onClickDelete}
        />
    );
};

export default DeleteGroupButton;
