import React, {FC, useContext} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';

import {FormSectionRequestType, FormAddSectionRequest} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import {
    formSectionValidationSchema,
    formSectionValidationSchemaNotOnlyLatin,
} from 'components/Forms/FormBuilder/SectionForm/validation';
import MdxEditorField from 'components/MdxEditorField/MdxEditorField';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import FormBuilderCheckboxReverse from 'components/AgentScreenComponents/_form/FormBuilderCheckboxReverse';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {preventSendForm} from 'helpers/index';
import {checkIsLanguageWithLatinLetters} from 'helpers/translationsHelper';

import {theme} from 'config/theme';

interface SectionFormType {
    entityId: number;
    onSubmitClicked: (values: FormAddSectionRequest) => void;
    initialValues: FormSectionRequestType;
    toggleModal: () => void;
    isCreate?: boolean;
}

const SectionForm: FC<SectionFormType> = ({initialValues, isCreate, onSubmitClicked, entityId, toggleModal}) => {
    const [t] = useTranslation();

    const {showAlert} = useContext(AlertContext);

    const {
        admin: {languageList, organizationList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const isLanguageWithLatinLetters = myOrganization
        ? checkIsLanguageWithLatinLetters(languageList, myOrganization.language)
        : false;

    const handleSubmit = (values: FormSectionRequestType) => {
        onSubmitClicked({
            ...values,
            id: entityId,
            callback: toggleModal,
            showAlert,
        });
    };

    const schema = () =>
        isLanguageWithLatinLetters ? formSectionValidationSchemaNotOnlyLatin(t) : formSectionValidationSchema(t);

    return (
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
            {(formik: FormikProps<FormSectionRequestType>) => {
                return (
                    <Form onKeyDown={preventSendForm}>
                        <Box
                            sx={{
                                backgroundColor: theme.palette.warning.contrastText,
                                borderRadius: 3,
                                pl: 3,
                                pr: 3,
                                pb: 3,
                                mb: 2,
                            }}
                        >
                            <Field
                                required
                                name="title"
                                label={t('orguser.forms.formSection.sectionTitle')}
                                placeholder={t('orguser.forms.formSection.sectionTitlePlaceholder')}
                                component={FormikTextInput}
                            />
                            <MdxEditorField
                                name="description"
                                value={formik.values.description ? String(formik.values.description) : ''}
                                label={t('orguser.forms.formSection.sectionDescription')}
                            />
                            <Field
                                name="tooltip"
                                label={t('orguser.forms.formSection.sectionTooltip')}
                                placeholder={t('orguser.forms.formSection.sectionTooltipPlaceholder')}
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box>
                            <Field
                                type="checkbox"
                                name="isRequired"
                                label={t('orguser.forms.formSection.mandatory')}
                                component={FormBuilderCheckboxReverse}
                            />
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mt: 1}}>
                            <AgentSaveButton
                                isSubmit
                                icon={isCreate ? <AddIcon sx={{color: theme.palette.background.default}} /> : undefined}
                                title={t(isCreate ? 'orguser.forms.addSection' : 'common.buttons.saveButton')}
                            />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default SectionForm;
