import React, {FC, useContext} from 'react';

import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import {RouteContext} from 'contexts/route/context';

import {getAvatarByType} from 'components/UserAvatar/helper';

import {theme, MAIN_AGENT_COLOR, MAIN_CLIENT_COLOR} from 'config/theme';

interface AvatarImageType {
    size: number;
    avatarLink: string | null;
    avatarType: number;
    avatarTitle: string;
}

const AvatarImage: FC<AvatarImageType> = ({avatarLink, size, avatarType, avatarTitle}) => {
    const {isClientMode} = useContext(RouteContext);

    const avatarImage = getAvatarByType(avatarType);

    if (avatarLink) {
        return (
            <Avatar sx={{width: size, height: size}}>
                <div
                    style={{
                        width: size,
                        height: size,
                        backgroundImage: `url(${avatarLink})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                    }}
                />
            </Avatar>
        );
    }
    if (avatarType === 0 || !avatarImage) {
        return (
            <Avatar
                sx={{
                    width: size,
                    height: size,
                    borderRadius: 0.5 * size,
                    justifyContent: 'center !important',
                    backgroundColor: isClientMode ? MAIN_CLIENT_COLOR : MAIN_AGENT_COLOR,
                }}
            >
                <Typography
                    sx={{
                        fontSize: 0.5 * size,
                        color: theme.palette.background.default,
                    }}
                >
                    {avatarTitle}
                </Typography>
            </Avatar>
        );
    }
    return (
        <Avatar
            sx={{
                width: size,
                height: size,
            }}
        >
            {avatarImage}
        </Avatar>
    );
};

export default AvatarImage;
