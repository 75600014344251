import React, {FC, useCallback, useContext, useEffect} from 'react';
import get from 'lodash/get';
import {useDispatch} from 'react-redux';

import {CreateOrganizationRequestTypes} from 'appRedux/actions/organization/types';
import {GET_ROLES_LIST} from 'appRedux/actions/roles';

import {PermissionContext} from 'contexts/permission/context';
import {AlertContext} from 'contexts/alert/context';

import OrganizationSsoUpdateForm from 'components/Forms/OrganizationSsoCredentialsForm/OrganizationSsoUpdateForm';
import OrganizationSsoCreateForm from 'components/Forms/OrganizationSsoCredentialsForm/OrganizationSsoCreateForm';

interface OrganizationSsoCredentialsTypes {
    organizationId: number;
    initialValues: CreateOrganizationRequestTypes;
}

const OrganizationSsoCredentials: FC<OrganizationSsoCredentialsTypes> = ({initialValues, organizationId}) => {
    const dispatch = useDispatch();

    const {isRoleViewEnabled} = useContext(PermissionContext);
    const {showAlert} = useContext(AlertContext);

    const getRolesList = useCallback(data => dispatch({type: GET_ROLES_LIST.REQUEST, payload: data}), [dispatch]);

    const organizationSso = get(initialValues, 'organizationSso', null);

    useEffect(() => {
        if (isRoleViewEnabled) {
            getRolesList({
                id: Number(organizationId),
                showAlert,
            });
        }
    }, [organizationId]);

    if (organizationSso) {
        return <OrganizationSsoUpdateForm initialValues={organizationSso} />;
    }
    return <OrganizationSsoCreateForm organizationId={organizationId} />;
};

export default OrganizationSsoCredentials;
