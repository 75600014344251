import {requestTypeCreator} from 'helpers/index';

import {
    OrganizationLanguagesListRequestTypes,
    OrganizationLanguageCreateRequestTypes,
    OrganizationLanguageTypes,
    OrganizationLanguageActivationRequestTypes,
    OrganizationLanguageRemovingRequestTypes,
    OrganizationTranslationTypes,
    OrganizationTranslationsListRequestTypes,
    OrganizationTranslationsUpdateRequestTypes,
} from 'appRedux/actions/organizationLanguage/types';

export const GET_ORGANIZATION_LANGUAGES_LIST = requestTypeCreator('GET_ORGANIZATION_LANGUAGES_LIST');
export const ORGANIZATION_LANGUAGE_ADD = requestTypeCreator('ORGANIZATION_LANGUAGE_ADD');
export const ORGANIZATION_LANGUAGE_ACTIVATE = requestTypeCreator('ORGANIZATION_LANGUAGE_ACTIVATE');
export const ORGANIZATION_LANGUAGE_REMOVE = requestTypeCreator('ORGANIZATION_LANGUAGE_REMOVE');

export const ORGANIZATION_TRANSLATIONS_LIST = requestTypeCreator('ORGANIZATION_TRANSLATIONS_LIST');
export const ORGANIZATION_TRANSLATIONS_UPDATE = requestTypeCreator('ORGANIZATION_TRANSLATIONS_UPDATE');

export const getOrganizationLanguagesList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_ORGANIZATION_LANGUAGES_LIST.ERROR,
    }),
    request: (payload: OrganizationLanguagesListRequestTypes): IActionType<OrganizationLanguagesListRequestTypes> => ({
        payload,
        type: GET_ORGANIZATION_LANGUAGES_LIST.REQUEST,
    }),
    success: (payload: OrganizationLanguageTypes[]): IActionType<OrganizationLanguageTypes[]> => ({
        payload,
        type: GET_ORGANIZATION_LANGUAGES_LIST.SUCCESS,
    }),
};

export const addOrganizationLanguage = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_LANGUAGE_ADD.ERROR,
    }),
    request: (
        payload: OrganizationLanguageCreateRequestTypes,
    ): IActionType<OrganizationLanguageCreateRequestTypes> => ({
        payload,
        type: ORGANIZATION_LANGUAGE_ADD.REQUEST,
    }),
    success: (payload: OrganizationLanguageTypes[]): IActionType<OrganizationLanguageTypes[]> => ({
        payload,
        type: ORGANIZATION_LANGUAGE_ADD.SUCCESS,
    }),
};

export const activateOrganizationLanguage = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_LANGUAGE_ACTIVATE.ERROR,
    }),
    request: (
        payload: OrganizationLanguageActivationRequestTypes,
    ): IActionType<OrganizationLanguageActivationRequestTypes> => ({
        payload,
        type: ORGANIZATION_LANGUAGE_ACTIVATE.REQUEST,
    }),
    success: (payload: OrganizationLanguageTypes[]): IActionType<OrganizationLanguageTypes[]> => ({
        payload,
        type: ORGANIZATION_LANGUAGE_ACTIVATE.SUCCESS,
    }),
};

export const removeOrganizationLanguage = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_LANGUAGE_REMOVE.ERROR,
    }),
    request: (
        payload: OrganizationLanguageRemovingRequestTypes,
    ): IActionType<OrganizationLanguageRemovingRequestTypes> => ({
        payload,
        type: ORGANIZATION_LANGUAGE_REMOVE.REQUEST,
    }),
    success: (payload: OrganizationLanguageTypes[]): IActionType<OrganizationLanguageTypes[]> => ({
        payload,
        type: ORGANIZATION_LANGUAGE_REMOVE.SUCCESS,
    }),
};

export const getOrganizationTranslationsList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_TRANSLATIONS_LIST.ERROR,
    }),
    request: (
        payload: OrganizationTranslationsListRequestTypes,
    ): IActionType<OrganizationTranslationsListRequestTypes> => ({
        payload,
        type: ORGANIZATION_TRANSLATIONS_LIST.REQUEST,
    }),
    success: (payload: OrganizationTranslationTypes[]): IActionType<OrganizationTranslationTypes[]> => ({
        payload,
        type: ORGANIZATION_TRANSLATIONS_LIST.SUCCESS,
    }),
};

export const updateOrganizationTranslations = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_TRANSLATIONS_UPDATE.ERROR,
    }),
    request: (
        payload: OrganizationTranslationsUpdateRequestTypes,
    ): IActionType<OrganizationTranslationsUpdateRequestTypes> => ({
        payload,
        type: ORGANIZATION_TRANSLATIONS_UPDATE.REQUEST,
    }),
    success: (payload: OrganizationTranslationTypes[]): IActionType<OrganizationTranslationTypes[]> => ({
        payload,
        type: ORGANIZATION_TRANSLATIONS_UPDATE.SUCCESS,
    }),
};
