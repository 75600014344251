import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const ExportSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.7055 6.7025L3 6L8 1L13 6L12.2955 6.7025L8.5 2.9125V12H7.5V2.9125L3.7055 6.7025ZM13 14V12H14V14C14 14.2652 13.8946 14.5196 13.7071 14.7071C13.5196 14.8946 13.2652 15 13 15H3C2.86875 14.9997 2.73885 14.9734 2.61774 14.9229C2.49662 14.8723 2.38666 14.7983 2.29414 14.7052C2.20163 14.6121 2.12836 14.5017 2.07854 14.3803C2.02873 14.2589 2.00333 14.1288 2.0038 13.9976L2 14V12H3V14H13Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default ExportSvgIcon;
