import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import IconButton from '@mui/material/IconButton';

import {INVITE_DELETE} from 'appRedux/actions/invites';
import {InviteTypes} from 'appRedux/actions/invites/types';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import DeleteAdminConfirmationModal from 'components/ClientScreenComponents/DeleteClientConfirmationModal';

interface DeleteInviteButtonType {
    formId: number;
    item: InviteTypes;
}

const DeleteInviteButton: FC<DeleteInviteButtonType> = ({item, formId}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {uuid} = item;

    const {showAlert} = useContext(AlertContext);
    const {isInviteDeleteEnabled, filtersInviteDeleteAllowed} = useContext(PermissionContext);

    const deleteInvite = useCallback(payload => dispatch({type: INVITE_DELETE.REQUEST, payload}), [dispatch]);

    const onDeleteClick = () => {
        deleteInvite({
            uuid,
            showAlert,
            callback: () => setIsOpened(false),
        });
    };

    const isDeleteEnabled = isInviteDeleteEnabled && filtersInviteDeleteAllowed.includes(Number(formId));

    return (
        <>
            <IconButton title={t('common.buttons.delete')} onClick={toggleIsOpened} disabled={!isDeleteEnabled}>
                <DeleteSvgIcon isDisabled={!isDeleteEnabled} />
            </IconButton>
            {isOpened && (
                <DeleteAdminConfirmationModal
                    isShowModal={isOpened}
                    toggleModal={toggleIsOpened}
                    onClickAction={onDeleteClick}
                />
            )}
        </>
    );
};

export default DeleteInviteButton;
