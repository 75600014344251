import {
    FORM_FIELD_CHECKBOXES,
    FORM_FIELD_DROPDOWN,
    FORM_FIELD_IMAGE_CHECK,
    FORM_FIELD_IMAGE_RADIO,
    FORM_FIELD_RADIOBUTTONS,
} from 'pages/admin/updateForm/partials/FormStructure/helper';

export const showOptionsList = (type: number) => {
    return [FORM_FIELD_DROPDOWN, FORM_FIELD_CHECKBOXES, FORM_FIELD_RADIOBUTTONS].indexOf(type) !== -1;
};

export const isImageOptionsList = (type: number | string) => {
    const typeNum = Number(type);
    return [FORM_FIELD_IMAGE_CHECK, FORM_FIELD_IMAGE_RADIO].indexOf(typeNum) !== -1;
};

export const REGEX_FOR_TITLES =
    /^[a-zA-Z0-9\-.,?!=:*;"&%$()~`@^*+_°§{}#€\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00df'\][\-/|\\<\\>\\\n ]+$/;
export const REGEX_FOR_URL_KEYWORD = /^[a-zA-Z0-9-]+$/;
export const REGEX_FOR_DESCRIPTION =
    /^[a-zA-Z0-9\-.,?!=:*;"&%$()~`@^*+_°§{}#€\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00df'\][\-/|\\<\\>\\\n ]+$/;
export const REGEX_FOR_TOOLTIP =
    /^[a-zA-Z0-9\-.,?!=:*;"&%$()~`@^*+_°§{}#€\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00df'\][\-/|\\<\\>\\\n ]+$/;
