import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {BoardItemTypes, BoardStatusTypes} from 'appRedux/actions/workflow/types';
import {REQUESTER_CASES_LIST, AGENT_LIST} from 'appRedux/actions/admin';
import {RootReducer} from 'appRedux/reducers';

import ColumnHeader from 'components/BlockView/ColumnHeader';
import CasesColumn from 'components/BlockView/CasesColumn';
import {
    getBoardWidth,
    getCustomBoardsColumnNumber,
    COLUMN_HEADER_HEIGHT,
    DASHBOARD_SCROLLBAR_WIDTH,
    getSwimlaneViewColumns,
    SwimlaneViewCasesTypes,
    SWIMLANE_INITIAL_COLUMN_ITEMS,
} from 'components/BlockView/helper';
import {getParameterFromUrl, getSearchFromUrl} from 'components/AdminScreenComponents/translationsHelper';
import MultipleActionButtonsPanel from 'components/AgentScreenComponents/_caseBlock/MultipleActionsButtonsPanel/MultipleActionButtonsPanel';

import {CLIENT_BACKGROUND_COLOR, theme} from 'config/theme';
import {
    PARAMETER_TAG,
    PARAMETER_FORM,
    PARAMETER_AGENT,
    PARAMETER_SEARCH,
    PARAMETER_LAST_CREATED,
    PARAMETER_LAST_UPDATED,
    PARAMETER_COLUMN_SIZE,
} from 'config/index';
import {Typography} from '@mui/material';
import {getVisibleHeight} from 'helpers/getVisibleHeight';
import {SwimlaneBoardResponseType} from 'appRedux/actions/admin/types';
import CasesSwimlaneColumn from 'components/BlockView/CasesSwimlaneColumn';

interface SwimlaneBoardCasesType {
    currentBoard: BoardItemTypes;
}

const SwimlaneBoardCases: FC<SwimlaneBoardCasesType> = ({currentBoard}) => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const {statuses, uuid} = currentBoard;

    const getAgents = useCallback(() => dispatch({type: AGENT_LIST.REQUEST}), [dispatch]);

    const getRequesterCasesSwimlaneBoard = useCallback(
        data => dispatch({type: REQUESTER_CASES_LIST.REQUEST_SWIMLANE_BOARD, payload: data}),
        [dispatch],
    );

    const {
        admin: {requesterCasesSwimlanes},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const swimlaneViewCases: SwimlaneViewCasesTypes[] = getSwimlaneViewColumns(requesterCasesSwimlanes);

    const agentFromUrl = searchParams.get(PARAMETER_AGENT);
    const formFromUrl = searchParams.get(PARAMETER_FORM);
    const searchFromUrl = searchParams.get(PARAMETER_SEARCH);
    const lastCreatedFromUrl = searchParams.get(PARAMETER_LAST_CREATED);
    const lastUpdatedFromUrl = searchParams.get(PARAMETER_LAST_UPDATED);
    const tagsFromUrl = searchParams.get(PARAMETER_TAG);
    const columnSize = localStorage.getItem(PARAMETER_COLUMN_SIZE);

    useEffect(() => {
        document.body.style.overflowX = 'hidden';
        document.body.style.overflowY = 'hidden';
    }, []);

    useEffect(() => {
        return () => {
            document.body.style.overflowX = 'auto';
            document.body.style.overflowY = 'auto';
        };
    }, []);

    useEffect(() => {
        getRequesterCasesSwimlaneBoard({
            uuid,
            agent: getParameterFromUrl(agentFromUrl),
            form: getParameterFromUrl(formFromUrl),
            tags: getParameterFromUrl(tagsFromUrl),
            lastCreated: getParameterFromUrl(lastCreatedFromUrl),
            lastUpdated: getParameterFromUrl(lastUpdatedFromUrl),
            search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
            perPage: SWIMLANE_INITIAL_COLUMN_ITEMS,
        });
    }, [uuid, agentFromUrl, formFromUrl, searchFromUrl, tagsFromUrl, lastCreatedFromUrl, lastUpdatedFromUrl]);

    useEffect(() => {
        getAgents();
    }, []);

    const columnsNumber = getCustomBoardsColumnNumber(statuses);

    const columnsWrapperRef = useRef<HTMLDivElement>(null);

    const [columnsHeight, setColumnsHeight] = useState(0);

    useEffect(() => {
        if (columnsWrapperRef && columnsWrapperRef.current) {
            setColumnsHeight(getVisibleHeight(columnsWrapperRef.current));
        }

        const onResize = () => {
            console.log('resize', getVisibleHeight(columnsWrapperRef.current));
            setColumnsHeight(getVisibleHeight(columnsWrapperRef.current));
        };

        window.onresize = onResize;

        return () => {
            window.onresize = () => {
                return;
            };
        };
    }, [columnsWrapperRef]);

    return (
        <Box
            sx={{
                ml: -6,
                mr: -4,
                mb: -3,
                pl: 7.2,
                pr: 4,
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                '&::-webkit-scrollbar': {
                    '-webkit-appearance': 'none',
                    '&:horizontal': {
                        height: DASHBOARD_SCROLLBAR_WIDTH + 'px',
                    },
                },
                '&::-webkit-scrollbar-thumb': {
                    borderRadius: '8px',
                    border: '2px solid white' /* should match background, can't be transparent */,
                    backgroundColor: 'rgba(0, 0, 0, .5)',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#fff',
                    height: DASHBOARD_SCROLLBAR_WIDTH + 'px',
                },
            }}
        >
            <Grid
                container
                sx={{
                    pb: 2,
                    zIndex: 100,
                    backgroundColor: CLIENT_BACKGROUND_COLOR,
                    width: `max(${getBoardWidth(columnsNumber, Number(columnSize))}px, 100%)`,
                    height: COLUMN_HEADER_HEIGHT,
                }}
                spacing={2}
                columns={2 * columnsNumber}
            >
                {statuses.map((item: BoardStatusTypes) => {
                    if (!item.isActive) return null;
                    return (
                        <Grid item sm={2} key={`columnHeader-${item.uuid}`}>
                            <ColumnHeader title={item.title} color={item.color} subtitle={''} />
                        </Grid>
                    );
                })}
            </Grid>
            <Box
                ref={columnsWrapperRef}
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    width: `max(${getBoardWidth(columnsNumber, Number(columnSize))}px, 100%)`,
                    overflowX: 'visible',
                }}
            >
                <Box
                    sx={{
                        overflowY: 'auto',
                        ml: -6,
                        mr: -4,
                        overflowX: 'visible',
                        width: `calc(max(${getBoardWidth(columnsNumber, Number(columnSize))}px, 100%) + 80px)`,
                        height: columnsHeight ? columnsHeight + 'px' : 'auto',
                    }}
                >
                    {swimlaneViewCases.map((swimlane, i) => {
                        if (swimlane.casesNumber === 0) return '';

                        return (
                            <Box key={`${swimlane.id}`} sx={{mx: 4}}>
                                <Box
                                    sx={{
                                        width: `calc(max(${getBoardWidth(
                                            columnsNumber,
                                            Number(columnSize),
                                        )}px, 100%) - 8px)`,
                                        ml: 0.5,
                                        borderBottom: `1px solid ${theme.palette.warning.light}`,
                                        backgroundColor: theme.palette.background.paper,
                                        borderRadius: '8px',
                                        py: 1,
                                        px: 2,
                                        position: 'sticky',
                                        top: 0,
                                        zIndex: 1 + i,
                                    }}
                                >
                                    <Typography sx={{fontWeight: 'bold'}}>{swimlane.swimlaneName}</Typography>
                                </Box>
                                <Grid
                                    container
                                    spacing={0}
                                    sx={{
                                        mt: 1,
                                        width: `max(${getBoardWidth(columnsNumber, Number(columnSize))}px, 100%)`,
                                        flexGrow: 1,
                                        display: 'flex',
                                        alignItems: 'stretch',
                                    }}
                                    columns={2 * columnsNumber}
                                >
                                    {swimlane.columns.map(item => (
                                        <CasesSwimlaneColumn
                                            item={item}
                                            key={`${item.status}`}
                                            boardUuid={uuid}
                                            currentSwimlane={swimlane}
                                        />
                                    ))}
                                </Grid>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
            <MultipleActionButtonsPanel />
        </Box>
    );
};

export default SwimlaneBoardCases;
