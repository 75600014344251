import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {REQUEST_CASE_CHAT_ACTIVATE} from 'appRedux/actions/requestCase';

import {AlertContext} from 'contexts/alert/context';

import {CustomSwitcher} from 'components/AgentScreenComponents/_form/CustomElements';

interface ActivateChatByAgentType {
    isChatActivated: boolean;
}

const ActivateChatByAgent: FC<ActivateChatByAgentType> = ({isChatActivated}) => {
    const dispatch = useDispatch();
    const {caseId} = useParams();

    const [isChecked, setIsChecked] = useState<boolean>(isChatActivated);

    const {showAlert} = useContext(AlertContext);

    const toggleIsChecked = () => {
        setIsChecked(previous => !previous);
    };

    const changeFormStatus = useCallback(
        data => dispatch({type: REQUEST_CASE_CHAT_ACTIVATE.REQUEST, payload: data}),
        [dispatch],
    );

    const handleChangeStatus = () => {
        toggleIsChecked();
        changeFormStatus({
            caseId: Number(caseId),
            showAlert,
        });
    };

    return <CustomSwitcher checked={isChecked} onClick={handleChangeStatus} />;
};

export default ActivateChatByAgent;
