import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';

import Box from '@mui/material/Box';

import {OrganizationPrivacyPolicyFormTypes} from 'appRedux/actions/organization/types';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import {organizationPrivacyPolicyValidationSchema} from 'components/Forms/OrganizationPrivacyPolicyForm/validation';

interface OrganizationPrivacyPolicyFormType {
    initialValues: OrganizationPrivacyPolicyFormTypes;
    onSubmitAction: (values: OrganizationPrivacyPolicyFormTypes) => void;
}

const OrganizationPrivacyPolicyForm: FC<OrganizationPrivacyPolicyFormType> = ({initialValues, onSubmitAction}) => {
    const [t] = useTranslation();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={organizationPrivacyPolicyValidationSchema}
            onSubmit={onSubmitAction}
        >
            {(formik: FormikProps<OrganizationPrivacyPolicyFormTypes>) => {
                return (
                    <Form>
                        <Box>
                            <Field
                                name="title"
                                label={t('superadmin.organizations.privacyPolicy.title')}
                                type="text"
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default OrganizationPrivacyPolicyForm;
