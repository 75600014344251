import React, {FC, RefObject} from 'react';
import {ReactCodeMirrorRef} from '@uiw/react-codemirror';

import Box from '@mui/material/Box';

import {MdxEditorCustomButtonType} from 'components/MdxEditorField/helper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import CustomButtonItemsSelector from 'components/CodeMirrorField/CustomButtonItemsSelector';

interface CustomButtonsRowType {
    codeMirrorRef: RefObject<ReactCodeMirrorRef>;
    customButtons: MdxEditorCustomButtonType[];
}

const CustomButtonsRow: FC<CustomButtonsRowType> = ({codeMirrorRef, customButtons}) => {
    const onButtonClick = (markdown: string) => {
        if (codeMirrorRef && codeMirrorRef.current) {
            const view = codeMirrorRef.current.view;
            if (view) {
                const cursor = view.state.selection.main.head;
                const transaction = view.state.update({
                    changes: {
                        from: cursor,
                        insert: markdown,
                    },
                    selection: {anchor: cursor + 1},
                    scrollIntoView: true,
                });
                if (transaction) {
                    view.dispatch(transaction);
                }
            }
        }
    };

    return (
        <Box sx={{mt: 1, mb: 2}}>
            {customButtons &&
                customButtons.map((customButton: MdxEditorCustomButtonType, index: number) => {
                    const {title, markdown, items} = customButton;
                    if (!items || items.length == 0) {
                        return (
                            <AgentSaveButton
                                isSubmit={false}
                                key={`custom-button-${index}`}
                                title={title}
                                onClick={() => onButtonClick(markdown)}
                            />
                        );
                    }
                    return (
                        <CustomButtonItemsSelector
                            button={customButton}
                            key={`custom-button-selector-${index}`}
                            onOptionSelect={onButtonClick}
                        />
                    );
                })}
        </Box>
    );
};

export default CustomButtonsRow;
