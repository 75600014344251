import React, {FC} from 'react';

import Chip from '@mui/material/Chip';

import {getTagColor} from 'pages/admin/updateForm/partials/FormTags/helper';
import {SxProps} from '@mui/material';

interface TagChipType {
    id: string;
    title: string;
    color: string;
    onClickAction?: (value: string) => void;
    onDelete?: () => void;
    hasOpacity?: boolean;
    disabled?: boolean;
    sx?: SxProps;
}

const TagChip: FC<TagChipType> = ({id, title, color, onClickAction, onDelete, hasOpacity, disabled, sx}) => {
    const tagStyles = {
        mr: 0.5,
        mb: 0.5,
        borderRadius: 14,
        height: 28,
        minWidth: 120,
        color: getTagColor(color),
        backgroundColor: hasOpacity ? color : `${color}35`,
        cursor: disabled ? 'default' : 'pointer',
        '&:hover': disabled
            ? {backgroundColor: hasOpacity ? color : `${color}35`}
            : {
                  opacity: 1,
                  backgroundColor: `${color}75`,
              },
        span: {
            mx: 'auto',
        },
        ...sx,
    };
    if (onClickAction) {
        return <Chip label={title} onClick={() => onClickAction(id)} sx={tagStyles} onDelete={onDelete} />;
    }
    return <Chip label={title} sx={tagStyles} onDelete={onDelete} />;
};

export default TagChip;
