import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, Typography} from '@mui/material';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import {ALERT_TYPE_ERROR} from 'config/index';

const UpdateWatcher: React.FC = () => {
    const [t] = useTranslation();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        window.addEventListener('SW-codeUpdated', () => {
            console.log('SW-codeUpdated', ALERT_TYPE_ERROR);
            setShowModal(true);
        });

        return window.removeEventListener('SW-codeUpdated', () => {
            setShowModal(true);
        });
    });

    return (
        <ModalWrapper isShowModal={showModal} title={t('messages.warning.attention')}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Typography align="center" sx={{fontWeight: 600, mb: 2, fontSize: 22}}>
                    {t('messages.warning.newVersionRefreshPage')}
                </Typography>
                <AgentSaveButton
                    isBigButton
                    isSubmit={false}
                    onClick={() => window.location.reload()}
                    title={t('common.buttons.refresh')}
                />
            </Box>
        </ModalWrapper>
    );
};

export default UpdateWatcher;
