import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik} from 'formik';
import {Box} from '@mui/material';

import {CREATE_ORGANIZATION_PDF_TEMPLATE} from 'appRedux/actions/organization';
import {OrganizationPdfTemplatesInitType} from 'appRedux/actions/organization/types';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import {organizationPrivacyPolicyValidationSchema} from 'components/Forms/OrganizationPrivacyPolicyForm/validation';

interface CreatePdfTemplateFormType {
    organizationId: number;
    closeModal: () => void;
}

const CreatePdfTemplateForm: FC<CreatePdfTemplateFormType> = ({organizationId, closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const createOrganizationPdfTemplate = useCallback(
        data => dispatch({type: CREATE_ORGANIZATION_PDF_TEMPLATE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitAction = (values: OrganizationPdfTemplatesInitType) => {
        createOrganizationPdfTemplate({
            id: organizationId,
            ...values,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <Formik
            initialValues={{title: '', description: ''}}
            validationSchema={organizationPrivacyPolicyValidationSchema}
            onSubmit={onSubmitAction}
        >
            {() => {
                return (
                    <Form>
                        <Box>
                            <Field
                                name="title"
                                label={t('superadmin.organizations.pdfTemplates.title')}
                                type="text"
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box>
                            <Field
                                name="description"
                                label={t('superadmin.organizations.pdfTemplates.description')}
                                type="text"
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CreatePdfTemplateForm;
