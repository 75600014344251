import React, {FC} from 'react';
import get from 'lodash/get';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

const LoadingBottomButton: FC = () => {
    const [t] = useTranslation();

    const {admin, profile, requestCase, workflow} = useSelector<RootReducer>(
        (state: RootReducer) => state,
    ) as RootReducer;

    const adminLoading = get(admin, 'isLoading', false);
    const profileLoading = get(profile, 'isLoading', false);
    const requestCaseLoading = get(requestCase, 'isLoading', false);
    const isLoadingWorkflowCloning = get(workflow, 'isLoadingWorkflowCloning', false);

    if (adminLoading || requestCaseLoading || profileLoading || isLoadingWorkflowCloning) {
        return (
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 20,
                    left: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justify: 'center',
                    align: 'center',
                    zIndex: 10000000,
                }}
            >
                <AgentSaveButton isBigButton isSuccess title={t('common.pleaseWait')} />
            </Box>
        );
    }
    return null;
};

export default LoadingBottomButton;
