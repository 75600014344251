import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import {FORM_TRANSLATION_IMPORT} from 'appRedux/actions/forms';

import ImportSvgIcon from 'assets/icons/buttons/ImportSvgIcon';

import {PermissionContext} from 'contexts/permission/context';

import CommonUploadLogoForm from 'components/Forms/CommonLogoForm/CommonUploadLogoForm';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

interface ImportTranslationsButtonType {
    formId: number;
    formTitle: string;
    language: string;
    lang: string;
}

const ImportTranslationsButton: FC<ImportTranslationsButtonType> = ({formId, formTitle, language, lang}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {isTranslationEditEnabled} = useContext(PermissionContext);

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleShowModal = () => {
        setShowModal(previous => !previous);
    };

    const submitUploadFile = useCallback(
        data => dispatch({type: FORM_TRANSLATION_IMPORT.REQUEST, payload: data}),
        [dispatch],
    );

    return (
        <>
            <IconButton
                title={t('orguser.forms.importFormTranslations')}
                onClick={toggleShowModal}
                disabled={!isTranslationEditEnabled}
            >
                <ImportSvgIcon isDisabled={!isTranslationEditEnabled} />
            </IconButton>
            <ModalWrapper
                isShowModal={showModal}
                toggleModal={toggleShowModal}
                title={t('orguser.forms.importFormTranslations')}
            >
                <Typography sx={{mb: 2}}>
                    {t('orguser.forms.selectTranslationsFile').replace('%FORM%', formTitle).replace('%LANGUAGE%', lang)}
                </Typography>
                <CommonUploadLogoForm
                    initialValues={{
                        file: '',
                        id: formId,
                        lang: language,
                        callback: () => setShowModal(false),
                    }}
                    submitUploadFile={submitUploadFile}
                    identifier="upload-translations"
                    label={t('common.buttons.upload')}
                />
            </ModalWrapper>
        </>
    );
};

export default ImportTranslationsButton;
