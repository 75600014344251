import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Box';
import Box from '@mui/material/Box';

import {WorkflowStatusEventItemTypes} from 'appRedux/actions/workflow/types';

import AttachedAgentsList from 'pages/admin/updateWorkflowEvents/workflowEventAgent/AttachedAgentsList';
import AttachAgentButton from 'pages/admin/updateWorkflowEvents/workflowEventAgent/AttachAgentButton';
import AttachedGroupsList from 'pages/admin/updateWorkflowEvents/workflowEventGroup/AttachedGroupsList';
import AttachGroupButton from 'pages/admin/updateWorkflowEvents/workflowEventGroup/AttachGroupButton';

interface WorkflowEventAgentLabelType {
    label: string;
    item?: WorkflowStatusEventItemTypes;
}

const WorkflowEventAgentLabel: FC<WorkflowEventAgentLabelType> = ({label, item}) => {
    const [t] = useTranslation();

    return (
        <Box>
            <Typography sx={{mb: 1, fontWeight: 700}}>{`${t(label)}:`}</Typography>
            {(!item || (item && item.agents.length === 0 && item.groups.length === 0)) && (
                <Typography sx={{mb: 2}}>{t('common.none')}</Typography>
            )}
            {item && (
                <Box>
                    {item.agents.length > 0 && <AttachedAgentsList items={item.agents} />}
                    <AttachAgentButton item={item} />
                    {item.groups.length > 0 && <AttachedGroupsList items={item.groups} />}
                    <AttachGroupButton item={item} />
                </Box>
            )}
        </Box>
    );
};

export default WorkflowEventAgentLabel;
