import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const ActivitySvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.83336 4.2528C4.58668 4.08797 4.29667 4 4 4C3.60218 4 3.22065 4.15803 2.93934 4.43934C2.65804 4.72064 2.5 5.10217 2.5 5.5C2.5 5.79667 2.58797 6.08668 2.7528 6.33335C2.91762 6.58003 3.15189 6.77229 3.42598 6.88582C3.70007 6.99935 4.00167 7.02906 4.29264 6.97118C4.58361 6.9133 4.85088 6.77044 5.06066 6.56066C5.27044 6.35088 5.4133 6.08361 5.47118 5.79264C5.52906 5.50166 5.49935 5.20006 5.38582 4.92597C5.27229 4.65189 5.08003 4.41762 4.83336 4.2528ZM2.61108 3.42133C3.0222 3.14662 3.50555 3 4 3C4.66304 3 5.29893 3.26339 5.76777 3.73223C6.23661 4.20107 6.5 4.83696 6.5 5.5C6.5 5.99445 6.35338 6.4778 6.07867 6.88893C5.80397 7.30005 5.41353 7.62048 4.95671 7.8097C4.49989 7.99892 3.99723 8.04843 3.51228 7.95196C3.02732 7.8555 2.58187 7.6174 2.23223 7.26777C1.8826 6.91814 1.6445 6.47268 1.54804 5.98773C1.45157 5.50277 1.50108 5.00011 1.6903 4.54329C1.87952 4.08648 2.19995 3.69603 2.61108 3.42133ZM12.5 11.4999H12.4893C12.3825 11.4975 12.2792 11.4609 12.1947 11.3957C12.1101 11.3304 12.0487 11.2398 12.0192 11.1371L10.4355 5.5949L9.46825 8.17525C9.43251 8.27058 9.36853 8.35274 9.28486 8.41075C9.20119 8.46876 9.10181 8.49986 9 8.4999H7V7.4999H8.6533L10.0317 3.82435C10.0687 3.72586 10.1359 3.64153 10.2235 3.58337C10.3112 3.52522 10.415 3.49618 10.5201 3.5004C10.6252 3.50462 10.7264 3.54188 10.8091 3.60688C10.8918 3.67188 10.952 3.76131 10.981 3.86245L12.5366 9.3085L13.5259 6.3417C13.559 6.24215 13.6226 6.15555 13.7077 6.0942C13.7928 6.03285 13.8951 5.99985 14 5.9999H16V6.9999H14.3603L12.9741 11.1581C12.941 11.2577 12.8774 11.3443 12.7923 11.4056C12.7072 11.467 12.6049 11.5 12.5 11.4999ZM6.5 15V11.5C6.49956 11.1023 6.34139 10.721 6.06018 10.4398C5.77897 10.1586 5.39769 10.0004 5 10H3C2.60231 10.0004 2.22103 10.1586 1.93982 10.4398C1.65861 10.721 1.50044 11.1023 1.5 11.5V15H0.5V11.5C0.500781 10.8372 0.764423 10.2018 1.2331 9.7331C1.70177 9.26442 2.3372 9.00078 3 9H5C5.6628 9.00078 6.29823 9.26442 6.7669 9.7331C7.23558 10.2018 7.49922 10.8372 7.5 11.5V15H6.5Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default ActivitySvgIcon;
