import * as yup from 'yup';

export const formTranslationsValidationSchema = yup.object({
    title: yup.string().required('messages.validation.isRequired'),
    description: yup.string().required('messages.validation.isRequired'),
    button: yup.string().required('messages.validation.isRequired'),
});

export const subFormTranslationsValidationSchema = yup.object({
    title: yup.string().required('messages.validation.isRequired'),
    description: yup.string().required('messages.validation.isRequired'),
});
