import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import {RootReducer} from 'appRedux/reducers';

import EmailUpdateForm from 'pages/start/emailUpdate/EmailUpdateForm';
import LoginPageWrapper from 'pages/start/login/partials/LoginPageWrapper';

import {theme} from 'config/theme';
import {routes} from 'config/index';

const EmailUpdatePage: FC = () => {
    const [t] = useTranslation();

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <LoginPageWrapper>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    mt: 4,
                }}
            >
                <EmailUpdateForm />
                {!profile && (
                    <Link to={routes.START} sx={{mt: 1, textDecoration: 'none'}} variant="body2" component={RouterLink}>
                        <Typography
                            sx={{
                                color: theme.palette.info.light,
                            }}
                        >
                            {t('common.loginForm.login')}
                        </Typography>
                    </Link>
                )}
            </Box>
        </LoginPageWrapper>
    );
};

export default EmailUpdatePage;
