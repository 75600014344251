import moment from 'moment';

import {DATE_FORMAT, DATEPICKER_FORMAT} from 'config/index';

export const getInitialDate = (dateString: string | null) => {
    if (!dateString) return new Date();
    const dateInFormat = moment(dateString, DATE_FORMAT).format('YYYY-MM-DD HH:mm');
    const dateObject = new Date(dateInFormat);
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth();
    const date = dateObject.getDate();
    const hour = new Date().getHours();
    const minute = new Date().getMinutes();
    return new Date(year, month, date, hour, minute);
};
