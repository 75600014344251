import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {RootReducer} from 'appRedux/reducers';
import {DELETE_FORM_VERSION} from 'appRedux/actions/forms';
import {FormVersionTypes} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import DeleteAdminConfirmationButton from 'components/AdminScreenComponents/DeleteAdminConfirmationButton';

interface DeleteFormVersionButtonType {
    item: FormVersionTypes;
}

const DeleteFormVersionButton: FC<DeleteFormVersionButtonType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    const {showAlert} = useContext(AlertContext);
    const {isFormEditEnabled} = useContext(PermissionContext);

    const {uuid, casesQty} = item;

    const {
        admin: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const deleteOrganizationAdmin = useCallback(
        data => dispatch({type: DELETE_FORM_VERSION.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        deleteOrganizationAdmin({
            uuid,
            showAlert,
            callback: () => setShowModal(false),
        });
    };

    return (
        <DeleteAdminConfirmationButton
            disabled={isLoading || !isFormEditEnabled}
            deletingRestrict={casesQty > 0}
            description={casesQty > 0 ? t('messages.error.formVersionRemovingRestrict') : ''}
            isShowModal={showModal}
            toggleModal={toggleModal}
            onClickAction={onClickDelete}
        />
    );
};

export default DeleteFormVersionButton;
