import React, {FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import {FORM_SECTION_UPDATE} from 'appRedux/actions/forms';
import {RootReducer} from 'appRedux/reducers';
import {FormPageTypes} from 'appRedux/actions/forms/types';

import SectionForm from 'components/Forms/FormBuilder/SectionForm/Form';
import {formSectionInitialValues} from 'components/Forms/FormBuilder/SectionForm/validation';

import {getSectionById} from 'pages/admin/updateForm/partials/FormStructure/helper';

interface UpdateSectionFormType {
    sectionId: number;
    toggleModal: () => void;
}

const UpdateSectionForm: FC<UpdateSectionFormType> = ({sectionId, toggleModal}) => {
    const dispatch = useDispatch();

    const submitUpdateForm = useCallback(
        data => dispatch({type: FORM_SECTION_UPDATE.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {formInfo},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const pages: FormPageTypes[] = get(formInfo, 'pages', []);

    const initialValues = {...formSectionInitialValues, ...getSectionById(pages, sectionId)};

    return (
        <SectionForm
            entityId={sectionId}
            toggleModal={toggleModal}
            initialValues={initialValues}
            onSubmitClicked={submitUpdateForm}
        />
    );
};

export default UpdateSectionForm;
