import {fork, put, take, call} from 'redux-saga/effects';

import {
    GET_ORGANIZATION_LANGUAGES_LIST,
    getOrganizationLanguagesList,
    ORGANIZATION_LANGUAGE_ADD,
    addOrganizationLanguage,
    ORGANIZATION_LANGUAGE_ACTIVATE,
    activateOrganizationLanguage,
    ORGANIZATION_LANGUAGE_REMOVE,
    removeOrganizationLanguage,
    ORGANIZATION_TRANSLATIONS_LIST,
    getOrganizationTranslationsList,
    ORGANIZATION_TRANSLATIONS_UPDATE,
    updateOrganizationTranslations,
} from 'appRedux/actions/organizationLanguage';
import {
    OrganizationLanguagesListRequestTypes,
    OrganizationLanguageCreateRequestTypes,
    OrganizationLanguageActivationRequestTypes,
    OrganizationLanguageRemovingRequestTypes,
    OrganizationTranslationsListRequestTypes,
    OrganizationTranslationsUpdateRequestTypes,
    OrganizationLanguageResponseTypes,
    OrganizationTranslationResponseTypes,
} from 'appRedux/actions/organizationLanguage/types';

import {http} from 'services/http';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchOrganizationLanguagesGetting() {
    while (true) {
        const {
            payload: {id, callback, showAlert},
        }: IActionType<OrganizationLanguagesListRequestTypes> = yield take(GET_ORGANIZATION_LANGUAGES_LIST.REQUEST);
        try {
            const data: OrganizationLanguageResponseTypes = yield call(http, `organization/language/${id}/list`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getOrganizationLanguagesList.success(data.results));
                callback && callback();
            } else if (data.errors) {
                yield put(getOrganizationLanguagesList.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getOrganizationLanguagesList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationLanguageCreation() {
    while (true) {
        const {
            payload: {id, callback, showAlert, ...values},
        }: IActionType<OrganizationLanguageCreateRequestTypes> = yield take(ORGANIZATION_LANGUAGE_ADD.REQUEST);
        try {
            const data: OrganizationLanguageResponseTypes = yield call(http, `organization/language/${id}/add`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(addOrganizationLanguage.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else if (data.errors) {
                yield put(addOrganizationLanguage.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(addOrganizationLanguage.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationLanguageActivation() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert},
        }: IActionType<OrganizationLanguageActivationRequestTypes> = yield take(ORGANIZATION_LANGUAGE_ACTIVATE.REQUEST);
        try {
            const data: OrganizationLanguageResponseTypes = yield call(http, `organization/language/${uuid}/activate`, {
                method: 'POST',
            });
            if (data.success) {
                yield put(activateOrganizationLanguage.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else if (data.errors) {
                yield put(activateOrganizationLanguage.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(activateOrganizationLanguage.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationLanguageRemoving() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert},
        }: IActionType<OrganizationLanguageRemovingRequestTypes> = yield take(ORGANIZATION_LANGUAGE_REMOVE.REQUEST);
        try {
            const data: OrganizationLanguageResponseTypes = yield call(http, `organization/language/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(removeOrganizationLanguage.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else if (data.errors) {
                yield put(removeOrganizationLanguage.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(removeOrganizationLanguage.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationTranslationsListGetting() {
    while (true) {
        const {
            payload: {id, lang, callback, showAlert},
        }: IActionType<OrganizationTranslationsListRequestTypes> = yield take(ORGANIZATION_TRANSLATIONS_LIST.REQUEST);
        try {
            const data: OrganizationTranslationResponseTypes = yield call(
                http,
                `organization/${id}/translations/${lang}`,
                {
                    method: 'GET',
                },
            );
            if (data.success) {
                yield put(getOrganizationTranslationsList.success(data.results));
                callback && callback();
            } else if (data.errors) {
                yield put(getOrganizationTranslationsList.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(getOrganizationTranslationsList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationTranslationsUpdating() {
    while (true) {
        const {
            payload: {id, lang, callback, showAlert, ...values},
        }: IActionType<OrganizationTranslationsUpdateRequestTypes> = yield take(
            ORGANIZATION_TRANSLATIONS_UPDATE.REQUEST,
        );
        try {
            const data: OrganizationTranslationResponseTypes = yield call(
                http,
                `organization/${id}/translations/${lang}/update`,
                {
                    method: 'POST',
                    body: JSON.stringify(values),
                },
            );
            if (data.success) {
                yield put(updateOrganizationTranslations.success(data.results));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else if (data.errors) {
                yield put(updateOrganizationTranslations.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(updateOrganizationTranslations.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchOrganizationLanguagesGetting),
    fork(watchOrganizationLanguageCreation),
    fork(watchOrganizationLanguageActivation),
    fork(watchOrganizationLanguageRemoving),
    fork(watchOrganizationTranslationsListGetting),
    fork(watchOrganizationTranslationsUpdating),
];
