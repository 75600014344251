import React, {FC} from 'react';

import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

import {CommonDeleteLogoTypes} from 'appRedux/actions/forms/types';
import {OrganizationLogoUrlRequestTypes} from 'appRedux/actions/organization/types';

import CurrentLogo from 'components/Forms/CommonLogoForm/CurrentLogo';

import {MAIN_AGENT_COLOR, theme} from 'config/theme';

interface IconPreviewType {
    id: number;
    type?: number;
    isFavicon?: boolean;
    logoSource: string | null;
    setLogoSource: (value: string | null) => void;
    title: string;
    deleteFormLogo: (value: CommonDeleteLogoTypes | OrganizationLogoUrlRequestTypes) => void;
}

const IconPreview: FC<IconPreviewType> = ({id, type, isFavicon, logoSource, setLogoSource, title, deleteFormLogo}) => {
    if (logoSource) {
        return (
            <CurrentLogo
                id={id}
                type={type}
                logoSource={logoSource}
                setLogoSource={setLogoSource}
                deleteFormLogo={deleteFormLogo}
                isFavicon={isFavicon}
            />
        );
    }
    return (
        <Avatar sx={{width: 95, height: 95, backgroundColor: MAIN_AGENT_COLOR}}>
            <Typography
                sx={{
                    fontSize: 50,
                    color: theme.palette.background.default,
                }}
            >
                {`${title[0]}${title[1]}`.toUpperCase()}
            </Typography>
        </Avatar>
    );
};

export default IconPreview;
