import React, {FC, Ref, useCallback, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {WorkflowStatusTaskItemTypes, WorkflowTaskEmailTemplateFormTypes} from 'appRedux/actions/workflow/types';
import {CREATE_WORKFLOW_TASK_EMAIL_TEMPLATE} from 'appRedux/actions/workflow';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import WorkflowTaskEmailTemplateForm from 'components/Forms/Workflow/workflowStatusTaskTemplate/WorkflowTaskEmailTemplateForm';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface CreateWorkflowTaskEmailTemplateFormType {
    formRef: Ref<any> | null;
    selectedLanguage: number;
    item: WorkflowStatusTaskItemTypes;
    submitStatusEventForm: () => void;
}

const CreateWorkflowTaskEmailTemplateForm: FC<CreateWorkflowTaskEmailTemplateFormType> = ({
    formRef,
    item,
    selectedLanguage,
    submitStatusEventForm,
}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        workflow: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const createWorkflowTaskEmailTemplate = useCallback(
        data => dispatch({type: CREATE_WORKFLOW_TASK_EMAIL_TEMPLATE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: WorkflowTaskEmailTemplateFormTypes) => {
        createWorkflowTaskEmailTemplate({
            uuid: item.uuid,
            showAlert,
            ...values,
        });
    };

    if (isLoading) return null;

    return (
        <Box>
            <WorkflowTaskEmailTemplateForm
                formRef={formRef}
                initialValues={{
                    language: selectedLanguage,
                    subject: '',
                    text: '',
                }}
                onSubmitClicked={onSubmitClicked}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 1,
                }}
            >
                <AgentSaveButton isSubmit={false} onClick={submitStatusEventForm} />
            </Box>
        </Box>
    );
};

export default CreateWorkflowTaskEmailTemplateForm;
