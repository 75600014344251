import React, {useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Box} from '@mui/material';

import {OrganizationItemBasic} from 'appRedux/actions/organization/types';
import {RootReducer} from 'appRedux/reducers';
import {ORGANIZATION_LIST} from 'appRedux/actions/organization';

import {RouteContext} from 'contexts/route/context';

import OrganizationTile from 'pages/admin/organizations/OrganizationList/OrganizationTile';

const OrganizationsTiles = () => {
    const dispatch = useDispatch();

    const {setBackLink, setBackLinkLabel} = useContext(RouteContext);

    const getOrganizationList = useCallback(() => dispatch({type: ORGANIZATION_LIST.REQUEST}), [dispatch]);

    const {
        admin: {organizationList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const organizations: OrganizationItemBasic[] = organizationList ?? [];

    useEffect(() => {
        getOrganizationList();
        setBackLink(null);
        setBackLinkLabel(null);
    }, []);

    return (
        <Box sx={{display: 'grid', gridTemplateColumns: 'repeat(2, calc(50% - 12px))', gap: 3}}>
            {Array.isArray(organizations) &&
                organizations.map((item: OrganizationItemBasic) => {
                    return <OrganizationTile item={item} key={`organization-tile-${item.id}`} />;
                })}
        </Box>
    );
};

export default OrganizationsTiles;
