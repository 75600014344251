import React, {FC} from 'react';

import {MessageTypes} from 'appRedux/actions/requestChat/types';

import CurrentUserAvatar from 'components/UserAvatar/CurrentUserAvatar';
import AvatarImage from 'components/UserAvatar/AvatarImage';

interface ChatMessageAvatarType {
    item: MessageTypes;
    isCurrentUserSender: boolean;
}

const ChatMessageAvatar: FC<ChatMessageAvatarType> = ({item, isCurrentUserSender}) => {
    if (isCurrentUserSender) {
        return <CurrentUserAvatar size={40} />;
    }
    const {avatarLink, avatarType, senderName} = item;
    return (
        <AvatarImage
            size={40}
            avatarLink={avatarLink}
            avatarType={avatarType}
            avatarTitle={senderName ? `${senderName[0]}${senderName[0]}`.toUpperCase() : '?'}
        />
    );
};

export default ChatMessageAvatar;
