import React, {FC, useState} from 'react';
import get from 'lodash/get';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';

import MinusSvgIcon from 'assets/icons/buttons/MinusSvgIcon';
import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import {FilterOptionType} from 'components/AdminScreenComponents/types';

import {CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface FilterItemWithSubItemsType {
    selectedValues: string[];
    option: FilterOptionType;
}

const FilterItemWithSubItems: FC<FilterItemWithSubItemsType> = ({option, selectedValues}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const subItems = get(option, 'subItems', []);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    ml: 4,
                    mt: 1,
                    mb: 1,
                }}
            >
                <Typography sx={{fontWeight: 600}}>{t(option.label)}</Typography>
                <IconButton sx={{ml: 2}} onClick={toggleIsOpened}>
                    {isOpened ? <MinusSvgIcon /> : <PlusSvgIcon />}
                </IconButton>
            </Box>
            {isOpened && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        borderBottom: `2px solid ${CLIENT_BACKGROUND_COLOR}`,
                        pb: 1,
                        mb: 1,
                    }}
                >
                    {subItems.map((subItem: FilterOptionType) => {
                        return (
                            <FormControlLabel
                                key={`option-${subItem.name}`}
                                value={subItem.name}
                                control={<Checkbox checked={selectedValues.includes(subItem.name)} />}
                                label={t(subItem.label)}
                            />
                        );
                    })}
                </Box>
            )}
        </>
    );
};

export default FilterItemWithSubItems;
