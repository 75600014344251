import React, {FC, useContext, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {AdminContext} from 'contexts/admin/context';

import {BUTTON_HEIGHT, buttonOutlineStyles, labelOutlineStyles} from 'components/AgentScreenComponents/_buttons/common';

import {CONTENT_POPUP, DROP_PLACEHOLDDER_HEIGHT, formTypes} from 'pages/admin/updateForm/partials/FormStructure/helper';

import {theme} from 'config/theme';

interface FormDragNewFieldsType {
    onAddNewPopupClick: (value: number) => void;
    onAddNewFieldClick: (value: number, inputType?: string) => void;
    sectionId: number;
    placeholdersCount?: number;
}

const FormDragNewFields: FC<FormDragNewFieldsType> = ({
    onAddNewPopupClick,
    onAddNewFieldClick,
    sectionId,
    placeholdersCount = 0,
}) => {
    const [t] = useTranslation();

    const {setIsDragStart, setDragType, setDropType} = useContext(AdminContext);

    const ref = useRef<HTMLDivElement>();
    const dragEndTimeoutRef = useRef<NodeJS.Timeout>();

    const onDragStart = (type: string) => {
        setTimeout(() => {
            setIsDragStart(true);
            window.scrollBy(0, placeholdersCount * DROP_PLACEHOLDDER_HEIGHT);
        }, 50);
        setDragType(type);
        setDropType(null);
    };

    const onDragEnd = () => {
        setIsDragStart(false);
        setDragType(null);
        setDropType(null);
        window.scrollBy(0, -placeholdersCount * DROP_PLACEHOLDDER_HEIGHT);
    };

    const onDrag = () => {
        dragEndTimeoutRef.current && clearTimeout(dragEndTimeoutRef.current);
        dragEndTimeoutRef.current = setTimeout(() => {
            setIsDragStart(false);
            setDragType(null);
            setDropType(null);
        }, 1000);
    };

    const onClick = (type: string) => {
        if (type === CONTENT_POPUP) {
            onAddNewPopupClick(sectionId);
            return;
        }

        onAddNewFieldClick(sectionId, type);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: 1,
                p: 0,
                my: 1,
            }}
            ref={ref}
        >
            {[...Object.entries(formTypes), [CONTENT_POPUP, CONTENT_POPUP]].map(([index, item]) => {
                return (
                    <Box
                        key={item}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flex: '1 1 20%',
                            height: 30,
                        }}
                    >
                        <Box
                            sx={{
                                ...buttonOutlineStyles,
                                borderColor: theme.palette.secondary.contrastText,
                                borderWidth: 2,
                                borderRadius: 2,
                                borderStyle: 'solid',
                                height: BUTTON_HEIGHT,
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            onDragStart={() => onDragStart(index)}
                            onDragEnd={onDragEnd}
                            onDrag={onDrag}
                            onClick={() => onClick(index)}
                            draggable
                        >
                            <Typography sx={labelOutlineStyles}>{t(item)}</Typography>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};

export default FormDragNewFields;
