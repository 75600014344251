import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {ResourceFieldCalendarFormTypes, ResourceFieldCalendarItemTypes} from 'appRedux/actions/resourceFields/types';
import {UPDATE_RESOURCE_FIELD_CALENDAR} from 'appRedux/actions/resourceFields';

import {AlertContext} from 'contexts/alert/context';

import ResourceFieldCalendarForm from 'components/Forms/ResourceField/ResourceFieldCalendarForm';

interface UpdateResourceFieldCalendarFormType {
    item: ResourceFieldCalendarItemTypes;
}

const UpdateResourceFieldCalendarForm: FC<UpdateResourceFieldCalendarFormType> = ({item}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {uuid, title, description} = item;

    const updateResourceFieldCalendar = useCallback(
        data => dispatch({type: UPDATE_RESOURCE_FIELD_CALENDAR.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: ResourceFieldCalendarFormTypes) => {
        updateResourceFieldCalendar({
            ...values,
            uuid,
            showAlert,
        });
    };

    return (
        <ResourceFieldCalendarForm
            initialValues={{
                title,
                description,
            }}
            onSubmitClicked={onSubmitClicked}
        />
    );
};

export default UpdateResourceFieldCalendarForm;
