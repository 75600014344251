import React, {FC, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';
import {FORM_TRANSLATIONS} from 'appRedux/actions/forms';

import EmptyArea from 'components/EmptyArea';
import {showLinkToChat} from 'components/TopBarMenu/helper';

import LanguageDropdownSwitcher from 'pages/client/form/partials/LanguageDropdownSwitcher';
import StatusLabel from 'pages/client/form/partials/StatusLabel';
import ChatButton from 'pages/client/form/partials/ChatButton';
import FaqPageButton from 'pages/client/form/partials/FaqPageButton';

const StatusBar: FC = () => {
    const dispatch = useDispatch();

    const getTranslations = useCallback(data => dispatch({type: FORM_TRANSLATIONS.REQUEST, payload: data}), [dispatch]);

    const {
        admin: {formInfo},
        profile: {profile},
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isUserVerified = get(profile, 'isVerify', false);
    const formId = currentCase.formId;

    const {statusColor, statusTitle, isChatActivatedByAgent, isAssigned} = currentCase;

    const currentLanguage = profile ? profile.lang : null;

    useEffect(() => {
        if (currentLanguage && formId) {
            getTranslations({id: formId, lang: currentLanguage});
        }
    }, [currentLanguage, currentCase.id]);

    const isFormHasFaq: boolean = get(formInfo, 'isFormHasFaq', false);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                mb: 2,
            }}
        >
            <LanguageDropdownSwitcher />
            {isFormHasFaq && <FaqPageButton />}
            <EmptyArea />
            {isUserVerified && <StatusLabel status={statusTitle} color={statusColor} />}
            {showLinkToChat(isUserVerified, isChatActivatedByAgent, isAssigned) && <ChatButton />}
        </Box>
    );
};

export default StatusBar;
