import React, {FC} from 'react';

import {FormPageTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseTypes} from 'appRedux/actions/requestCase/types';

import SectionSelectWrapper from './SectionSelectWrapper';
import PageSelectWrapper from './PageSelectWrapper';
import {SectionContentType, getSectionContent} from 'pages/admin/updateForm/partials/FormStructure/helper';
import SectionContentItem from 'pages/agent/requesterPage/sections/SectionContentItem';
import {get} from 'lodash';
import {PrintSectionType} from '../PdfEditor';

interface SectionsListType {
    page: FormPageTypes;
    currentCase: RequesterCaseTypes;
    printSections: Record<string, PrintSectionType>;
    onPageCheck: (pageId: number) => (checked: boolean) => void;
    onSectionCheck: (pageId: number, sectionId: number) => (checked: PrintSectionType) => void;
    printAttachments: Record<string, boolean>;
    setPrintAttachments: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
    formId?: string;
}

const SectionsList: FC<SectionsListType> = ({
    page,
    currentCase,
    printSections,
    onPageCheck,
    onSectionCheck,
    formId,
    printAttachments,
    setPrintAttachments,
}) => {
    const {result, files, comments} = currentCase;
    const {title, id, sections} = page;
    const pageKeyword = `page-${id}`;

    return (
        <PageSelectWrapper
            title={title}
            id={id}
            isChecked={printSections && Object.values(printSections).every(section => section)}
            indeterminate={
                printSections &&
                !Object.values(printSections).every(section => section.printSection) &&
                !Object.values(printSections).every(section => !section.printSection)
            }
            onCheck={onPageCheck(page.id)}
        >
            {sections.map(section => {
                const {id, fields, popups} = section;
                const sectionKeyword = `section-${id}`;
                const content = get(result, [pageKeyword, sectionKeyword], null);
                const sectionContent: SectionContentType[] = getSectionContent(fields, popups);

                return (
                    <SectionSelectWrapper
                        key={`section-${id}`}
                        section={section}
                        files={files}
                        comments={comments}
                        print={printSections && printSections[section.id]}
                        setPrint={onSectionCheck(page.id, section.id)}
                        printAttachments={printAttachments}
                        setPrintAttachments={setPrintAttachments}
                    >
                        {sectionContent.map((item: SectionContentType, index: number) => {
                            return (
                                <SectionContentItem
                                    key={`section-content-item-${index}`}
                                    formId={Number(formId)}
                                    showTranslation={false}
                                    editTranslation={false}
                                    item={item}
                                    section={section}
                                    content={content}
                                />
                            );
                        })}
                    </SectionSelectWrapper>
                );
            })}
        </PageSelectWrapper>
    );
};

export default SectionsList;
