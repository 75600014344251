import React, {FC, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FORM_TAG_CREATE, FORM_TAG_LIST} from 'appRedux/actions/forms';
import {FormTagType} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import TagForm from 'components/Forms/FormBuilder/TagForm/TagForm';
import {formInitialValues} from 'components/Forms/FormBuilder/TagForm/validation';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import TagItem from 'pages/admin/updateForm/partials/FormTags/TagItem';

interface FormTagsType {
    formId: number;
}

const FormTags: FC<FormTagsType> = ({formId}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const [isShowModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    const getFormTags = useCallback(data => dispatch({type: FORM_TAG_LIST.REQUEST, payload: data}), [dispatch]);

    const onCreateClicked = useCallback(data => dispatch({type: FORM_TAG_CREATE.REQUEST, payload: data}), [dispatch]);

    const {
        admin: {formInfo, isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const tags: FormTagType[] = get(formInfo, 'tags', []);

    useEffect(() => {
        getFormTags({
            id: formId,
        });
    }, []);

    return (
        <Box>
            <Box sx={{mb: 2}}>
                {tags.length > 0 ? (
                    tags.map((item: FormTagType, index: number) => {
                        return <TagItem key={`tag-${index}`} item={item} />;
                    })
                ) : (
                    <Typography>{t('orguser.forms.noneTags')}</Typography>
                )}
            </Box>
            <AgentSaveButton
                isLoading={isLoading}
                title={t('orguser.forms.addNewTag')}
                isSubmit={false}
                onClick={toggleModal}
            />
            <TagForm
                id={formId}
                isShowModal={isShowModal}
                setShowModal={setShowModal}
                toggleModal={toggleModal}
                initialValues={formInitialValues}
                onSubmitClicked={onCreateClicked}
                isCreate
            />
        </Box>
    );
};

export default FormTags;
