import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {DELETE_ALL_STATUS_CHANGE_APPROVE_REQUESTS} from 'appRedux/actions/requestCase';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';
import AgentCloseIconButton from 'components/AgentScreenComponents/_buttons/AgentCloseIconButton';

const DeleteApproveRequestsButton: FC = () => {
    const dispatch = useDispatch();
    const {caseId} = useParams();

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const removeAllApproveRequests = useCallback(
        data => dispatch({type: DELETE_ALL_STATUS_CHANGE_APPROVE_REQUESTS.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        removeAllApproveRequests({
            id: Number(caseId),
            showAlert,
            callback: () => {
                setIsOpened(false);
                window.location.reload();
            },
        });
    };

    return (
        <>
            <AgentCloseIconButton onClose={() => setIsOpened(true)} isOutlined />
            <DeleteAdminConfirmationModal
                toggleModal={toggleIsOpened}
                isShowModal={isOpened}
                onClickAction={onClickDelete}
            />
        </>
    );
};

export default DeleteApproveRequestsButton;
