import React, {FC} from 'react';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface SubmitButtonType {
    isLoading?: boolean;
    title?: string;
    isSubmit?: boolean;
    onClick?: () => void;
}

const ClientSaveButton: FC<SubmitButtonType> = ({isLoading, isSubmit, title, onClick}) => {
    if (isSubmit) {
        return <AgentSaveButton isDisabled={isLoading} isSubmit title={title ? title : 'common.buttons.saveButton'} />;
    }
    return <AgentSaveButton isDisabled={isLoading} isSubmit={false} onClick={onClick} />;
};

export default ClientSaveButton;
