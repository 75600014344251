import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import {RequesterCaseResponseTypes} from 'appRedux/actions/admin/types';
import {RootReducer} from 'appRedux/reducers';
import {FormFieldFilterType} from 'appRedux/actions/forms/types';

import {AdminContext} from 'contexts/admin/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';

import {getFormFieldOptions, getRequesterCases} from 'pages/agent/tableView/helper';

import {PARAMETER_SORT_BY, PARAMETER_SORT_TYPE} from 'config/index';

const SelectAllButton: FC = () => {
    const [t] = useTranslation();
    const [searchParams] = useSearchParams();

    const {selectedIds, setSelectedIds} = useContext(AdminContext);
    const {
        admin: {requesterCasesList, formFieldFilters},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const filters: FormFieldFilterType[] = get(formFieldFilters, 'filters', []);

    const requesterCasesFilteredList = requesterCasesList
        ? getRequesterCases(
              requesterCasesList,
              searchParams.get(PARAMETER_SORT_BY),
              searchParams.get(PARAMETER_SORT_TYPE),
              getFormFieldOptions(filters, searchParams),
          )
        : [];

    const selectAllCases = () => {
        const allIds = requesterCasesFilteredList.map((item: RequesterCaseResponseTypes) => item.caseId);

        setSelectedIds(allIds);
    };

    if (selectedIds.length === requesterCasesFilteredList.length) {
        return (
            <AgentSaveButton
                isSubmit={false}
                title={t('orguser.requesterCase.topbar.clearAll')}
                onClick={() => {
                    setSelectedIds([]);
                }}
            />
        );
    }
    return <AgentInverseButton title={t('orguser.requesterCase.topbar.selectAll')} onClick={selectAllCases} />;
};

export default SelectAllButton;
