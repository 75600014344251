import React, {FC} from 'react';

import {WorkflowStatusTaskItemTypes, WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';

import {
    WORKFLOW_STATUS_TASK_TYPE_EMAIL_SENDING,
    WORKFLOW_STATUS_TASK_TYPE_CHANGE_STATUS,
    WORKFLOW_STATUS_TASK_TYPE_REMOVE_CASE,
} from 'components/Forms/Workflow/workflowStatusTask/helper';

import UpdateWorkflowStatusTaskForm from 'components/Forms/Workflow/workflowStatusTask/UpdateWorkflowStatusTaskForm';
import UpdateWorkflowStatusTaskEmailForm from 'components/Forms/Workflow/workflowStatusTask/UpdateWorkflowStatusTaskEmailForm';
import UpdateWorkflowStatusTaskRemoveCaseForm from 'components/Forms/Workflow/workflowStatusTask/UpdateWorkflowStatusTaskRemoveCaseForm';

interface UpdateWorkflowTaskSwitcherType {
    item: WorkflowStatusTaskItemTypes;
    status: WorkflowStatusItemTypes;
}

const UpdateWorkflowTaskSwitcher: FC<UpdateWorkflowTaskSwitcherType> = ({item, status}) => {
    if (item.type === WORKFLOW_STATUS_TASK_TYPE_EMAIL_SENDING) {
        return <UpdateWorkflowStatusTaskEmailForm item={item} status={status} />;
    }
    if (item.type === WORKFLOW_STATUS_TASK_TYPE_CHANGE_STATUS) {
        return <UpdateWorkflowStatusTaskForm item={item} status={status} />;
    }
    if (item.type === WORKFLOW_STATUS_TASK_TYPE_REMOVE_CASE) {
        return <UpdateWorkflowStatusTaskRemoveCaseForm item={item} />;
    }
    return null;
};

export default UpdateWorkflowTaskSwitcher;
