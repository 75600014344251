import React, {useContext} from 'react';
import {useNavigate} from 'react-router';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import {GridColDef} from '@mui/x-data-grid';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {PermissionContext} from 'contexts/permission/context';

import TextWithShowAll from 'components/TextWithShowAll/TextWithShowAll';

import DeleteFormVersionButton from 'pages/admin/updateForm/partials/FormVersions/DeleteFormVersionButton';

import useParsedTranslation from 'hooks/useParsedTranslation';

import {DATE_TIME_FORMAT, EMPTY_DATA, routes} from 'config/index';

const getFormVersionsTableColumns = (): GridColDef[] => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const {isFormDeleteEnabled} = useContext(PermissionContext);

    return [
        {
            field: 'title',
            headerName: t('orguser.forms.version'),
            minWidth: 150,
            flex: 0.5,
        },
        {
            field: 'description',
            headerName: t('orguser.forms.description'),
            minWidth: 200,
            sortable: false,
            renderCell: ({value}) => (
                <TextWithShowAll text={value ? useParsedTranslation(t, value) : EMPTY_DATA} isTable />
            ),
        },
        {
            field: 'casesQty',
            headerName: t('orguser.forms.casesQty'),
            minWidth: 200,
        },
        {
            field: 'releasedAt',
            headerName: t('orguser.forms.releasedAt'),
            minWidth: 100,
            valueGetter: ({value}) => (value ? moment(value).format(DATE_TIME_FORMAT) : t('common.none')),
        },
        {
            field: 'updatedAt',
            headerName: t('orguser.forms.updatedAt'),
            minWidth: 100,
            valueGetter: ({value}) => (value ? moment(value).format(DATE_TIME_FORMAT) : t('common.none')),
        },
        {
            field: 'formActions',
            headerName: t('orguser.forms.action'),
            headerAlign: 'center',
            align: 'right',
            disableColumnMenu: true,
            width: 110,
            renderCell: ({row}) => {
                const redirectToFormBuilder = () => {
                    navigate(`${routes.FORM}/${row.uuid}/version`);
                };
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mr: 1,
                        }}
                    >
                        <IconButton title={t('orguser.forms.formBuilder')} onClick={redirectToFormBuilder}>
                            <EditSvgIcon />
                        </IconButton>
                        {isFormDeleteEnabled && <DeleteFormVersionButton item={row} />}
                    </Box>
                );
            },
        },
    ];
};

export default getFormVersionsTableColumns;
