import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {UPDATE_WORKFLOW_STATUS_TRANSLATION} from 'appRedux/actions/workflow';
import {WorkflowStatusTranslationItemTypes, WorkflowStatusTranslationFormTypes} from 'appRedux/actions/workflow/types';

import {AlertContext} from 'contexts/alert/context';

import WorkflowStatusTranslationForm from 'components/Forms/Workflow/workflowStatusTranslation/WorkflowStatusTranslationForm';

interface UpdateWorkflowStatusTranslationFormType {
    formField: string;
    currentColor: string;
    language: LanguageItemTypes;
    currentItem: WorkflowStatusTranslationItemTypes;
    closeForm: () => void;
}

const UpdateWorkflowStatusTranslationForm: FC<UpdateWorkflowStatusTranslationFormType> = ({
    formField,
    currentItem,
    language,
    currentColor,
    closeForm,
}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const updateWorkflowStatusTranslation = useCallback(
        data => dispatch({type: UPDATE_WORKFLOW_STATUS_TRANSLATION.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: WorkflowStatusTranslationFormTypes) => {
        updateWorkflowStatusTranslation({
            ...values,
            uuid: currentItem.uuid,
            showAlert,
            callback: closeForm,
        });
    };

    return (
        <WorkflowStatusTranslationForm
            initialValues={{
                translation: currentItem.translation,
                translationRequester: currentItem.translationRequester,
                language: language.id,
            }}
            formField={formField}
            onSubmitClicked={onSubmitClicked}
            currentColor={currentColor}
        />
    );
};

export default UpdateWorkflowStatusTranslationForm;
