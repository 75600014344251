import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Form, Formik, FormikProps} from 'formik';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {CaseWatcherCreatingFormTypes} from 'appRedux/actions/requestCase/types';
import {CREATE_CASE_WATCHER} from 'appRedux/actions/requestCase';

import {AlertContext} from 'contexts/alert/context';

import {getAvatarTitle} from 'components/TopBarMenu/helper';
import AvatarImage from 'components/UserAvatar/AvatarImage';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface CaseWatcherFormType {
    closeModal: () => void;
}

const CaseWatcherForm: FC<CaseWatcherFormType> = ({closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {caseId} = useParams();

    const {showAlert} = useContext(AlertContext);

    const {
        crypto: {agentsWithCaseAccess},
        requestCase: {isLoading, currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const createCaseWatcher = useCallback(
        data => dispatch({type: CREATE_CASE_WATCHER.REQUEST, payload: data}),
        [dispatch],
    );

    const {watchers} = currentCase;

    const agentsFilteredList =
        agentsWithCaseAccess && watchers
            ? agentsWithCaseAccess.filter(item => {
                  return !watchers.some(watcher => Number(watcher.watcherId) === Number(item.id));
              })
            : [];

    return (
        <Formik
            initialValues={{
                watcher: 0,
            }}
            onSubmit={values => {
                createCaseWatcher({
                    ...values,
                    id: Number(caseId),
                    callback: closeModal,
                    showAlert,
                });
            }}
        >
            {(formik: FormikProps<CaseWatcherCreatingFormTypes>) => {
                return (
                    <Form>
                        <FormControl fullWidth>
                            <Select
                                fullWidth
                                name="watcher"
                                IconComponent={KeyboardArrowDownIcon}
                                value={formik.values.watcher}
                                variant="standard"
                                onChange={formik.handleChange}
                                sx={{
                                    '& div': {
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                    },
                                }}
                            >
                                {agentsFilteredList &&
                                    agentsFilteredList.map(agent => {
                                        const {id, avatarType, avatarLink, firstName, lastName, email} = agent;
                                        const avatarTitle = getAvatarTitle(firstName, lastName, email);
                                        return (
                                            <MenuItem
                                                key={email}
                                                value={id}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    mb: 1,
                                                }}
                                            >
                                                <AvatarImage
                                                    size={25}
                                                    avatarType={avatarType}
                                                    avatarLink={avatarLink}
                                                    avatarTitle={avatarTitle}
                                                />
                                                <Typography sx={{ml: 1, fontWeight: 600}}>
                                                    {firstName && lastName ? `${firstName} ${lastName}` : email}
                                                </Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        <Box
                            sx={{
                                mt: 3,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <AgentInverseButton title={t('common.buttons.cancel')} onClick={closeModal} />
                            <AgentSaveButton isLoading={isLoading} isSubmit title={t('common.buttons.confirm')} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CaseWatcherForm;
