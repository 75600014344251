import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';

import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {FormChangeStatusType} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';

interface ActiveFormsNumberQuotaAlertPopupType {
    isOpened: boolean;
    setIsOpened: (value: boolean) => void;
    formId: number;
    toggleIsOpened: () => void;
    toggleIsChecked: () => void;
    changeFormStatus: (value: FormChangeStatusType) => void;
}

const ActiveFormsNumberQuotaAlertPopup: FC<ActiveFormsNumberQuotaAlertPopupType> = ({
    isOpened,
    setIsOpened,
    formId,
    toggleIsOpened,
    toggleIsChecked,
    changeFormStatus,
}) => {
    const [t] = useTranslation();

    const {showAlert} = useContext(AlertContext);

    return (
        <ModalWrapper isShowModal={isOpened} toggleModal={toggleIsOpened} title={t('orguser.forms.activateForm')}>
            <Alert severity={'error'}>
                <Typography sx={{fontWeight: 600, mb: 1}}>
                    {t('messages.error.organizationQuotaActiveFormsNumberReached')}
                </Typography>
                <Typography>{t('messages.warning.additionalCostWillBeGenerated')}</Typography>
            </Alert>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: 3,
                }}
            >
                <AgentInverseButton title={t('common.buttons.cancel')} onClick={() => setIsOpened(false)} />
                <AgentSaveButton
                    isSubmit={false}
                    title={t('common.buttons.activate')}
                    onClick={() => {
                        toggleIsChecked();
                        changeFormStatus({
                            id: formId,
                            showAlert,
                            callback: () => setIsOpened(false),
                        });
                    }}
                />
            </Box>
        </ModalWrapper>
    );
};

export default ActiveFormsNumberQuotaAlertPopup;
