import React, {FC, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {SelectChangeEvent} from '@mui/material';

import {LoginAttemptTypes, LoginAttemptRequest} from 'appRedux/actions/superadmin/types';

import {getParameterFromUrl, getSearchFromUrl} from 'components/AdminScreenComponents/translationsHelper';

import {getUserRolesForSelect} from 'components/SecureInformation/helper';
import DataGridWrapper from 'components/AdminScreenComponents/DataGridWrapper';
import SecureInformationDataGrid from 'components/SecureInformation/SecureInformationDataGrid';

import {
    PARAMETER_STATUS,
    PARAMETER_ROLE,
    DEFAULT_PAGE,
    ITEMS_PER_PAGE_DEFAULT,
    PARAMETER_PAGE,
    PARAMETER_PER_PAGE,
    ITEMS_PER_PAGE_OPTIONS_DEFAULT,
    PARAMETER_SEARCH,
} from 'config/index';

interface SecureInformationType {
    getAllAttempts: (value: LoginAttemptRequest) => void;
    itemsList: LoginAttemptTypes[];
    itemsNumber: number;
}

const SecureInformation: FC<SecureInformationType> = ({getAllAttempts, itemsList, itemsNumber}) => {
    const [t] = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const pageFromUrl = searchParams.get(PARAMETER_PAGE);
    const perPageFromUrl = searchParams.get(PARAMETER_PER_PAGE);
    const statusFromUrl = searchParams.get(PARAMETER_STATUS);
    const roleFromUrl = searchParams.get(PARAMETER_ROLE);
    const searchFromUrl = searchParams.get(PARAMETER_SEARCH);

    const setSearch = (searchParams: URLSearchParams) => {
        searchParams.set(PARAMETER_PAGE, String(DEFAULT_PAGE));
        setSearchParams(searchParams);
    };

    const handleSelectStatus = (event: SelectChangeEvent<string>) => {
        searchParams.set(PARAMETER_STATUS, event.target.value);
        setSearch(searchParams);
    };

    const handleSelectUserRole = (event: SelectChangeEvent<string>) => {
        searchParams.set(PARAMETER_ROLE, event.target.value);
        setSearch(searchParams);
    };

    useEffect(() => {
        getAllAttempts({
            page: pageFromUrl ?? DEFAULT_PAGE,
            perPage: perPageFromUrl ?? ITEMS_PER_PAGE_DEFAULT,
            status: getParameterFromUrl(statusFromUrl),
            role: getParameterFromUrl(roleFromUrl),
            search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
        });
    }, [pageFromUrl, perPageFromUrl, statusFromUrl, roleFromUrl, searchFromUrl]);

    return (
        <DataGridWrapper
            itemsList={itemsList}
            itemsTotalNumber={itemsNumber}
            itemsPerPageOptions={ITEMS_PER_PAGE_OPTIONS_DEFAULT}
            filters={[
                {
                    name: 'status',
                    label: t('superadmin.secureInfo.status'),
                    parameter: PARAMETER_STATUS,
                    options: [
                        {
                            name: '1',
                            label: t('superadmin.secureInfo.result.success'),
                        },
                        {
                            name: '0',
                            label: t('superadmin.secureInfo.result.failure'),
                        },
                    ],
                    handleFilterChange: handleSelectStatus,
                },
                {
                    name: 'role',
                    label: t('superadmin.secureInfo.role'),
                    parameter: PARAMETER_ROLE,
                    options: getUserRolesForSelect(),
                    handleFilterChange: handleSelectUserRole,
                },
            ]}
            hasSearch
        >
            <SecureInformationDataGrid itemsList={itemsList} />
        </DataGridWrapper>
    );
};

export default SecureInformation;
