import React, {FC} from 'react';

interface IconTypeSvgType {
    isActive?: boolean;
}

const IconType_14: FC<IconTypeSvgType> = ({isActive}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15 8V4.5C14.999 3.57206 14.6299 2.68242 13.9737 2.02627C13.3176 1.37011 12.4279 1.00103 11.5 1H1V8H4.2324L6.0211 10.6831L6.44375 13.6413C6.4787 13.8792 6.5978 14.0967 6.77943 14.2543C6.96106 14.4118 7.19316 14.499 7.4336 14.5H8.5C8.89769 14.4996 9.27897 14.3414 9.56018 14.0602C9.84139 13.779 9.99956 13.3977 10 13V10H13C13.5303 9.9994 14.0386 9.7885 14.4136 9.41356C14.7885 9.03861 14.9994 8.53025 15 8ZM4 7H2V2H4V7ZM14 8C13.9997 8.26511 13.8942 8.51928 13.7067 8.70674C13.5193 8.89421 13.2651 8.99967 13 9H9V13C8.99989 13.1326 8.94718 13.2597 8.85344 13.3534C8.75969 13.4472 8.63258 13.4999 8.5 13.5H7.4336L6.9789 10.3169L5 7.34865V2H11.5C12.1628 2.00075 12.7983 2.26439 13.2669 2.73307C13.7356 3.20174 13.9992 3.83719 14 4.5V8Z"
                fill={isActive ? '#ffffff' : '#222222'}
            />
        </svg>
    );
};

export default IconType_14;
