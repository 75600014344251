import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {AgentSavedFilterTypes} from 'appRedux/actions/agentFilters/types';
import {AGENT_FILTERS_LIST_CREATE} from 'appRedux/actions/agentFilters';

import {AlertContext} from 'contexts/alert/context';

import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import EmptyArea from 'components/EmptyArea';

interface FilterSaveButtonType {
    item: AgentSavedFilterTypes;
    updatedTitle: string;
    updatedBoard: number;
    updatedIconType: number;
    updatedFilterString: string;
    onSaveCurrentClick: () => void;
    onDiscardClick: () => void;
    setActiveFilter: (value: string | null) => void;
}

const FilterSaveButton: FC<FilterSaveButtonType> = ({
    item,
    onSaveCurrentClick,
    onDiscardClick,
    setActiveFilter,
    updatedTitle,
    updatedBoard,
    updatedIconType,
    updatedFilterString,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {showAlert} = useContext(AlertContext);

    const {uuid} = item;

    const createNewFilter = useCallback(
        data => dispatch({type: AGENT_FILTERS_LIST_CREATE.REQUEST, payload: data}),
        [dispatch],
    );

    const onCreateNewClick = () => {
        onDiscardClick();
        createNewFilter({
            uuid,
            board: updatedBoard,
            title: updatedTitle,
            iconType: updatedIconType,
            text: updatedFilterString,
            showAlert,
            callback: () => setActiveFilter(null),
        });
    };

    return (
        <>
            <AgentSaveButton title={t('orguser.savedFilters.saveChanges')} isSubmit={false} onClick={toggleIsOpened} />
            {isOpened && (
                <ModalWrapper
                    title={t('orguser.savedFilters.saveChanges')}
                    isShowModal={isOpened}
                    toggleModal={toggleIsOpened}
                >
                    <Typography sx={{mt: 2, mb: 4}}>{t('orguser.savedFilters.saveFilterTwoVariants')}</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <AgentInverseButton title={t('common.buttons.cancel')} onClick={toggleIsOpened} />
                        <EmptyArea />
                        <AgentSaveButton
                            onClick={onSaveCurrentClick}
                            isSubmit={false}
                            title={t('orguser.savedFilters.updateCurrent')}
                        />
                        <AgentSaveButton
                            onClick={onCreateNewClick}
                            isSubmit={false}
                            title={t('orguser.savedFilters.createNew')}
                        />
                    </Box>
                </ModalWrapper>
            )}
        </>
    );
};

export default FilterSaveButton;
