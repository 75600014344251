import React, {FC, MouseEvent, ReactElement} from 'react';

import {buttonStyles} from 'components/AgentScreenComponents/_buttons/common';
import {Button, Tooltip} from '@mui/material';

interface IconButtonType {
    onClick: (e?: MouseEvent<HTMLElement>) => void;
    icon: ReactElement;
    title?: string;
}

const AgentIconSaveButton: FC<IconButtonType> = ({onClick, icon, title}) => {
    const button = (
        <Button
            onClick={onClick}
            startIcon={icon}
            sx={{
                ...buttonStyles,
                borderRadius: 16,
                width: 32,
                minWidth: 32,
                height: 32,
                ' .MuiButton-startIcon': {
                    mx: 0,
                },
                mr: 1,
                mb: 0.5,
            }}
            variant="contained"
        ></Button>
    );

    if (title)
        return (
            <Tooltip placement="top" arrow title={title}>
                {button}
            </Tooltip>
        );

    return button;
};

export default AgentIconSaveButton;
