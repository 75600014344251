import {fork, put, take, call} from 'redux-saga/effects';

import {http} from 'services/http';

import {
    ORGANIZATION_PDF_TEMPLATES_LIST,
    getOrganizationPdfTemplatesList,
    CREATE_ORGANIZATION_PDF_TEMPLATE,
    createOrganizationPdfTemplate,
    UPDATE_ORGANIZATION_PDF_TEMPLATE,
    updateOrganizationPdfTemplate,
    DELETE_ORGANIZATION_PDF_TEMPLATE,
    deleteOrganizationPdfTemplate,
    UPLOAD_ORGANIZATION_PDF_ATTACHMENT,
    DELETE_ORGANIZATION_PDF_ATTACHMENT,
    uploadOrganizationPdfAttachment,
    deleteOrganizationPdfAttachment,
} from 'appRedux/actions/organization';
import {
    OrganizationPdfAttachmentUploadRequestTypes,
    OrganizationPdfTemplateCreateRequestTypes,
    OrganizationPdfTemplateDeleteRequestTypes,
    OrganizationPdfTemplateUpdateRequestTypes,
    OrganizationPdfTemplatesRequestTypes,
    OrganizationPdfTemplatesResponseTypes,
} from 'appRedux/actions/organization/types';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchOrganizationPdfTemplatesListRequest() {
    while (true) {
        const {
            payload: {id},
        }: IActionType<OrganizationPdfTemplatesRequestTypes> = yield take(ORGANIZATION_PDF_TEMPLATES_LIST.REQUEST);
        try {
            const data: OrganizationPdfTemplatesResponseTypes = yield call(http, `pdf/template/${id}/list`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getOrganizationPdfTemplatesList.success(data.results));
            } else {
                yield put(getOrganizationPdfTemplatesList.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getOrganizationPdfTemplatesList.error({message: String(e)}));
        }
    }
}

function* watchOrganizationPdfTemplateCreation() {
    while (true) {
        const {
            payload: {id, showAlert, callback, ...values},
        }: IActionType<OrganizationPdfTemplateCreateRequestTypes> = yield take(
            CREATE_ORGANIZATION_PDF_TEMPLATE.REQUEST,
        );
        try {
            const data: OrganizationPdfTemplatesResponseTypes = yield call(http, `pdf/template/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createOrganizationPdfTemplate.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(createOrganizationPdfTemplate.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(createOrganizationPdfTemplate.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationPdfTemplateUpdating() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback, ...values},
        }: IActionType<OrganizationPdfTemplateUpdateRequestTypes> = yield take(
            UPDATE_ORGANIZATION_PDF_TEMPLATE.REQUEST,
        );
        try {
            const data: OrganizationPdfTemplatesResponseTypes = yield call(http, `pdf/template/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateOrganizationPdfTemplate.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(updateOrganizationPdfTemplate.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(updateOrganizationPdfTemplate.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationPdfTemplateDeleting() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback},
        }: IActionType<OrganizationPdfTemplateDeleteRequestTypes> = yield take(
            DELETE_ORGANIZATION_PDF_TEMPLATE.REQUEST,
        );
        try {
            const data: OrganizationPdfTemplatesResponseTypes = yield call(http, `pdf/template/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteOrganizationPdfTemplate.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(deleteOrganizationPdfTemplate.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(deleteOrganizationPdfTemplate.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationPdfAttachmentUpdating() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback, file, title, isFrontPage, isContentPage, language},
        }: IActionType<OrganizationPdfAttachmentUploadRequestTypes> = yield take(
            UPLOAD_ORGANIZATION_PDF_ATTACHMENT.REQUEST,
        );
        try {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('language', String(language));
            formData.append('isFrontPage', JSON.stringify(isFrontPage ? 1 : 0));
            formData.append('isContentPage', JSON.stringify(isContentPage ? 1 : 0));
            file && formData.append('media', file);

            const data: OrganizationPdfTemplatesResponseTypes = yield call(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                http,
                `pdf/attachment/${uuid}/upload`,
                {
                    method: 'POST',
                    body: formData,
                },
            );
            if (data.success) {
                yield put(uploadOrganizationPdfAttachment.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(uploadOrganizationPdfAttachment.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(uploadOrganizationPdfAttachment.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchOrganizationPdfAttachmentDeleting() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback},
        }: IActionType<OrganizationPdfTemplateDeleteRequestTypes> = yield take(
            DELETE_ORGANIZATION_PDF_ATTACHMENT.REQUEST,
        );
        try {
            const data: OrganizationPdfTemplatesResponseTypes = yield call(http, `pdf/attachment/${uuid}/delete`, {
                method: 'POST',
            });
            if (data.success) {
                yield put(deleteOrganizationPdfAttachment.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(deleteOrganizationPdfAttachment.error(data.errors));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(deleteOrganizationPdfAttachment.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchOrganizationPdfTemplatesListRequest),
    fork(watchOrganizationPdfTemplateCreation),
    fork(watchOrganizationPdfTemplateUpdating),
    fork(watchOrganizationPdfTemplateDeleting),
    fork(watchOrganizationPdfAttachmentUpdating),
    fork(watchOrganizationPdfAttachmentDeleting),
];
