import {OrganizationItemBasic} from 'appRedux/actions/organization/types';

export const TYPE_MAXIMAL_ORGANIZATION_USERS_NUMBER = 0;
export const TYPE_MAXIMAL_ACTIVE_FORMS_NUMBER = 1;
export const TYPE_MAXIMAL_REQUESTS_PER_MONTH_NUMBER = 2;

export interface OrganizationQuotasArrayType {
    type: number;
    title: string;
}

export const organizationQuotasList = [
    {
        type: TYPE_MAXIMAL_ORGANIZATION_USERS_NUMBER,
        title: 'superadmin.organizations.maximalOrganizationUsersNumber',
    },
    {
        type: TYPE_MAXIMAL_ACTIVE_FORMS_NUMBER,
        title: 'superadmin.organizations.maximalActiveFormsNumber',
    },
    {
        type: TYPE_MAXIMAL_REQUESTS_PER_MONTH_NUMBER,
        title: 'superadmin.organizations.maximalRequestsPerMonthNumber',
    },
];

export const getOrganizationQuotaLabel = (type: number): string => {
    const quota = organizationQuotasList.find(item => item.type === type);
    return quota ? quota.title : '';
};

export const isMaximalOrganizationUsersNumberReached = (
    myOrganization: OrganizationItemBasic,
    organizationUsersNumber: number,
    isHard: boolean,
): boolean => {
    const quotas = myOrganization.quotas;
    const organizationUsersQuota = quotas?.find(
        item => item.type === TYPE_MAXIMAL_ORGANIZATION_USERS_NUMBER && item.isHard === isHard,
    );
    return organizationUsersQuota ? Number(organizationUsersQuota.value) <= organizationUsersNumber : false;
};

export const isMaximalActiveFormsNumberReached = (
    myOrganization: OrganizationItemBasic,
    activeFormsNumber: number,
    isHard: boolean,
): boolean => {
    const quotas = myOrganization.quotas;
    const organizationUsersQuota = quotas?.find(
        item => item.type === TYPE_MAXIMAL_ACTIVE_FORMS_NUMBER && item.isHard === isHard,
    );
    return organizationUsersQuota ? Number(organizationUsersQuota.value) < activeFormsNumber : false;
};
