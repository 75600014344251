import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import DeactivateTwoFactorSvgIcon from 'assets/icons/buttons/DeactivateTwoFactorSvgIcon';

import {OrganizationAdminListTypes} from 'appRedux/actions/organizationAdmin/types';
import {ORGANIZATION_ADMIN_2FA_DEACTIVATE} from 'appRedux/actions/organizationAdmin';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import {isTwoFactorDeactivationDisabled} from 'components/OrganizationAdminsList/helper';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

interface DeactivateTwoFactorButtonType {
    item: OrganizationAdminListTypes;
}

const DeactivateTwoFactorButton: FC<DeactivateTwoFactorButtonType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {id} = item;

    const {showAlert} = useContext(AlertContext);
    const {isUserEditEnabled} = useContext(PermissionContext);

    const deactivate2FA = useCallback(
        data => dispatch({type: ORGANIZATION_ADMIN_2FA_DEACTIVATE.REQUEST, payload: data}),
        [dispatch],
    );

    const {profile} = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const role = get(profile, ['profile', 'roles', 0], null);

    const onClickAction = () => {
        deactivate2FA({
            id,
            showAlert,
            callback: () => setIsOpened(false),
        });
    };

    const isDisabled = (role && isTwoFactorDeactivationDisabled(role)) || !isUserEditEnabled;

    return (
        <>
            <IconButton
                sx={{mr: 1}}
                disabled={isDisabled}
                onClick={toggleIsOpened}
                title={t('superadmin.users.deactivateTwoFactorAuth')}
            >
                <DeactivateTwoFactorSvgIcon isDisabled={isDisabled} />
            </IconButton>
            <ModalWrapper
                title={t('superadmin.users.deactivateTwoFactorAuth')}
                isShowModal={isOpened}
                toggleModal={toggleIsOpened}
            >
                <Typography variant="body1" sx={{mb: 3}}>
                    {t('superadmin.users.deactivateTwoFactorAuthDescription')}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 1,
                    }}
                >
                    <AgentInverseButton title={t('common.buttons.cancel')} onClick={toggleIsOpened} />
                    <AgentSaveButton isSubmit={false} onClick={onClickAction} title={t('common.buttons.deactivate')} />
                </Box>
            </ModalWrapper>
        </>
    );
};

export default DeactivateTwoFactorButton;
