import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';
import CloseSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {WorkflowStatusEventItemTypes} from 'appRedux/actions/workflow/types';

import WorkflowAttachmentForm from 'components/Forms/Workflow/workflowAttachment/WorkflowAttachmentForm';

import AttachmentsList from 'pages/admin/updateWorkflowEvents/workflowAttachments/AttachmentsList';
import {WORKFLOW_ATTACHMENTS_MAXIMAL_NUMBER} from 'pages/admin/updateWorkflowEvents/helper';

import {theme} from 'config/theme';

interface WorkflowAttachmentsType {
    item: WorkflowStatusEventItemTypes;
    languageId: number;
}

const WorkflowAttachments: FC<WorkflowAttachmentsType> = ({item, languageId}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleForm = () => {
        setIsOpened(previous => !previous);
    };

    const {attachments} = item;

    const currentLanguageAttachments = attachments.filter(item => Number(item.language) === languageId);

    return (
        <Box>
            {currentLanguageAttachments.length < WORKFLOW_ATTACHMENTS_MAXIMAL_NUMBER && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 1,
                    }}
                >
                    <Typography sx={{fontWeight: 700}}>
                        {t('orguser.workflows.workflowAttachments.attachments')}
                    </Typography>
                    <IconButton
                        title={t('common.buttons.add')}
                        onClick={toggleForm}
                        sx={{
                            backgroundColor: theme.palette.info.main,
                            '&:hover': {
                                backgroundColor: theme.palette.info.main,
                            },
                        }}
                    >
                        {isOpened ? <CloseSvgIcon isActive /> : <PlusSvgIcon isActive />}
                    </IconButton>
                </Box>
            )}
            {isOpened && (
                <WorkflowAttachmentForm item={item} languageId={languageId} closeForm={() => setIsOpened(false)} />
            )}
            {currentLanguageAttachments.length > 0 ? (
                <AttachmentsList items={currentLanguageAttachments} />
            ) : (
                <Typography>{t('orguser.workflows.workflowAttachments.noneAttachment')}</Typography>
            )}
        </Box>
    );
};

export default WorkflowAttachments;
