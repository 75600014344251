import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {RequesterCaseApproveTypes} from 'appRedux/actions/requestCase/types';

import FormInformationRowWrapper from 'pages/agent/requesterPage/wrappers/FormInformationRowWrapper';
import ApproverItem from 'pages/agent/requesterPage/formInformation/approver/ApproverItem';

interface ApproverType {
    items: RequesterCaseApproveTypes[];
}

const Approver: FC<ApproverType> = ({items}) => {
    const [t] = useTranslation();
    return (
        <FormInformationRowWrapper title={t('orguser.requesterCase.approves.approver')} alignTop>
            {items.map(item => {
                return <ApproverItem key={`approver-${item.uuid}`} item={item} />;
            })}
        </FormInformationRowWrapper>
    );
};

export default Approver;
