import React, {ChangeEvent, FC, useContext, useState} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {FormTagCreateRequestType, FormTagType} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import {formValidationSchema, formValidationSchemaNotOnlyLatin} from 'components/Forms/FormBuilder/TagForm/validation';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import TagChip from 'components/TagChip/TagChip';

import {checkIsLanguageWithLatinLetters} from 'helpers/translationsHelper';

import {tagColors} from 'config/theme';

interface TagFormType {
    id: number;
    isShowModal: boolean;
    setShowModal: (value: boolean) => void;
    toggleModal: () => void;
    initialValues: FormTagType;
    callback?: () => void;
    onSubmitClicked: (values: FormTagCreateRequestType) => void;
    isCreate?: boolean;
}

const TagForm: FC<TagFormType> = ({
    id,
    isShowModal,
    setShowModal,
    toggleModal,
    initialValues,
    callback,
    onSubmitClicked,
    isCreate,
}) => {
    const [t] = useTranslation();

    const {color} = initialValues;

    const {showAlert} = useContext(AlertContext);

    const [currentColor, setCurrentColor] = useState<string>(color);

    const onColorSelect = (e: ChangeEvent<{value: string}>) => {
        setCurrentColor(e.target.value);
    };

    const {
        admin: {isLoading, languageList, organizationList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const isLanguageWithLatinLetters = myOrganization
        ? checkIsLanguageWithLatinLetters(languageList, myOrganization.language)
        : false;

    return (
        <ModalWrapper title={t('orguser.forms.addNewTag')} isShowModal={isShowModal} toggleModal={toggleModal}>
            <Formik
                initialValues={initialValues}
                validationSchema={isLanguageWithLatinLetters ? formValidationSchemaNotOnlyLatin : formValidationSchema}
                onSubmit={(values, {resetForm, setErrors}) => {
                    onSubmitClicked({
                        ...values,
                        id,
                        color: currentColor,
                        callback: callback
                            ? callback
                            : () => {
                                  resetForm();
                                  setShowModal(false);
                              },
                        setErrors,
                        showAlert,
                    });
                }}
            >
                {(formik: FormikProps<FormTagCreateRequestType>) => {
                    return (
                        <Form>
                            <Field
                                required
                                name="title"
                                placeholder={t('orguser.forms.title')}
                                component={FormikTextInput}
                                fullwidth
                            />
                            <Typography sx={{mt: 3, mb: 1}}>{t('orguser.forms.selectTagColor')}</Typography>
                            <RadioGroup
                                value={currentColor}
                                row
                                sx={{
                                    p: 1,
                                }}
                            >
                                {tagColors.map((tagColor: string, index: number) => {
                                    return (
                                        <FormControlLabel
                                            key={`color-${index}`}
                                            value={tagColor}
                                            control={
                                                <Radio
                                                    sx={{mb: 1.5, display: 'none'}}
                                                    checked={currentColor === tagColor}
                                                    onChange={onColorSelect}
                                                />
                                            }
                                            label={
                                                <TagChip
                                                    id={String(index)}
                                                    title={tagColor}
                                                    color={tagColor}
                                                    hasOpacity={currentColor === tagColor}
                                                />
                                            }
                                        />
                                    );
                                })}
                            </RadioGroup>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 2,
                                }}
                            >
                                <AgentSaveButton
                                    isLoading={isLoading}
                                    title={isCreate ? 'common.buttons.add' : 'common.buttons.saveButton'}
                                    isSubmit
                                />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </ModalWrapper>
    );
};

export default TagForm;
