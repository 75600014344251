import React, {FC, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import {RootReducer} from 'appRedux/reducers';

import {RouteContext} from 'contexts/route/context';

import CreateConfigurationForm from 'components/Forms/FormBuilder/ConfigurationForm/CreateConfigurationForm';
import {FORMS_LIST} from 'components/AgentScreenComponents/helper';
import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

import {isOrgAdminUser} from 'helpers/roles';

import {PARAMETER_TAB, PARAMETER_SUB, routes} from 'config/index';

const CreateFormComponent: FC = () => {
    const [t] = useTranslation();
    const [searchParams] = useSearchParams();

    const {setBackLink, setBackLinkLabel, setPageTitle} = useContext(RouteContext);

    const {
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isSubForm = searchParams.get(PARAMETER_SUB);

    useEffect(() => {
        if (profile) {
            const route = isOrgAdminUser(profile) ? routes.ADMIN : routes.AGENT;
            setBackLink(`${route}?${PARAMETER_TAB}=${FORMS_LIST}`);
        }
        setBackLinkLabel(t('common.links.backToForms'));
        setPageTitle(t(isSubForm ? 'orguser.forms.addSubForm' : 'orguser.forms.addForm'));
    }, [isSubForm]);

    return (
        <ClientSectionWrapper title={t('orguser.forms.formConfiguration')}>
            <CreateConfigurationForm isSubForm={Boolean(isSubForm)} />
        </ClientSectionWrapper>
    );
};

export default CreateFormComponent;
