import {fork, put, take, call} from 'redux-saga/effects';

import {http} from 'services/http';

import {
    AGENT_FILTERS_LIST,
    AGENT_FILTER_TAGS_LIST,
    AGENT_FILTERS_LIST_CREATE,
    AGENT_FILTERS_LIST_UPDATE,
    AGENT_FILTERS_LIST_DELETE,
    getAgentFiltersList,
    getAgentTagsFiltersList,
    createAgentFilter,
    updateAgentFilter,
    deleteAgentFilter,
} from 'appRedux/actions/agentFilters';
import {
    AgentSavedFilterTagsTypes,
    AgentSavedFilterResponseTypes,
    AgentSavedFilterRequestTypes,
    AgentSavedFilterDeleteRequestTypes,
} from 'appRedux/actions/agentFilters/types';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchAgentSavedFiltersGetting() {
    while (true) {
        yield take(AGENT_FILTERS_LIST.REQUEST);
        try {
            const data: AgentSavedFilterResponseTypes = yield call(http, `filters/list`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getAgentFiltersList.success(data.results));
            } else {
                yield put(getAgentFiltersList.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getAgentFiltersList.error({message: String(e)}));
        }
    }
}

function* watchAgentTagsFiltersGetting() {
    while (true) {
        yield take(AGENT_FILTER_TAGS_LIST.REQUEST);
        try {
            const data: AgentSavedFilterTagsTypes = yield call(http, `filters/tags`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(
                    getAgentTagsFiltersList.success({
                        tags: data.tags,
                        filters: data.filters,
                    }),
                );
            } else {
                yield put(getAgentTagsFiltersList.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getAgentTagsFiltersList.error({message: String(e)}));
        }
    }
}

function* watchAgentSavedFiltersCreating() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert, ...values},
        }: IActionType<AgentSavedFilterRequestTypes> = yield take(AGENT_FILTERS_LIST_CREATE.REQUEST);
        try {
            const data: AgentSavedFilterResponseTypes = yield call(http, `filter/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createAgentFilter.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createAgentFilter.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createAgentFilter.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchAgentSavedFiltersUpdating() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert, ...values},
        }: IActionType<AgentSavedFilterRequestTypes> = yield take(AGENT_FILTERS_LIST_UPDATE.REQUEST);
        try {
            const data: AgentSavedFilterResponseTypes = yield call(http, `filter/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateAgentFilter.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateAgentFilter.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateAgentFilter.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchAgentSavedFiltersRemoving() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert},
        }: IActionType<AgentSavedFilterDeleteRequestTypes> = yield take(AGENT_FILTERS_LIST_DELETE.REQUEST);
        try {
            const data: AgentSavedFilterResponseTypes = yield call(http, `filter/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteAgentFilter.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteAgentFilter.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteAgentFilter.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchAgentSavedFiltersGetting),
    fork(watchAgentTagsFiltersGetting),
    fork(watchAgentSavedFiltersCreating),
    fork(watchAgentSavedFiltersUpdating),
    fork(watchAgentSavedFiltersRemoving),
];
