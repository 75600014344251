import {fork, put, take, call} from 'redux-saga/effects';
import get from 'lodash/get';

import {http} from 'services/http';

import {
    INSTANCE_SETTINGS_LIST,
    getInstanceSettings,
    INSTANCE_SETTING_UPDATE,
    updateInstanceSetting,
    INSTANCE_SETTING_UPLOAD,
    uploadInstanceSetting,
    INSTANCE_SETTING_FILES,
    getInstanceSettingFiles,
    INSTANCE_SETTING_DELETE_FILE,
    deleteInstanceSettingFile,
    INSTANCE_SETTING_UPLOAD_FAVICON,
    uploadInstanceSettingFavicon,
    INSTANCE_SETTING_DELETE_FAVICON,
    deleteInstanceSettingFavicon,
} from 'appRedux/actions/instance';
import {
    InstanceSettingBasic,
    InstanceSettingRequestType,
    InstanceSettingResponseType,
    InstanceSettingFileResponseType,
    InstanceSettingUploadRequestType,
    InstanceSettingDeleteFaviconRequestType,
    InstanceSettingsFile,
} from 'appRedux/actions/instance/types';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchGetInstanceSettingsList() {
    while (true) {
        yield take(INSTANCE_SETTINGS_LIST.REQUEST);
        try {
            const data: InstanceSettingBasic[] = yield call(http, `instance/settings`, {
                method: 'GET',
            });
            if (data) {
                yield put(getInstanceSettings.success(data));
            } else {
                yield put(getInstanceSettings.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getInstanceSettings.error({message: String(e)}));
        }
    }
}

function* watchUpdateInstanceSetting() {
    while (true) {
        const {
            payload: {id, showAlert, callback, setErrors, ...values},
        }: IActionType<InstanceSettingRequestType> = yield take(INSTANCE_SETTING_UPDATE.REQUEST);
        try {
            const data: InstanceSettingResponseType = yield call(http, `instance/${id}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(updateInstanceSetting.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(updateInstanceSetting.error({message: 'messages.error.somethingWentWrong'}));
                setErrors && setErrors(data.errors);
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateInstanceSetting.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchInstanceSettingUploadFile() {
    while (true) {
        const {
            payload: {id, file, setErrors, showAlert},
        }: IActionType<InstanceSettingUploadRequestType> = yield take(INSTANCE_SETTING_UPLOAD.REQUEST);
        try {
            const formData = new FormData();
            formData.append('media', file);
            const data: InstanceSettingFileResponseType = yield call(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                http,
                `instance/upload/${id}`,
                {
                    method: 'POST',
                    body: formData,
                },
            );
            const errors = get(data, 'errors', null);
            if (errors) {
                setErrors && setErrors(errors);
                yield put(uploadInstanceSetting.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            } else if (data) {
                yield put(uploadInstanceSetting.success(data));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            }
        } catch (e) {
            yield put(uploadInstanceSetting.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchInstanceSettingUploadFavicon() {
    while (true) {
        const {
            payload: {id, file, setErrors, showAlert},
        }: IActionType<InstanceSettingUploadRequestType> = yield take(INSTANCE_SETTING_UPLOAD_FAVICON.REQUEST);
        try {
            const formData = new FormData();
            formData.append('media', file);
            const data: InstanceSettingFileResponseType = yield call(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                http,
                `instance/upload/${id}/favicon`,
                {
                    method: 'POST',
                    body: formData,
                },
            );
            const errors = get(data, 'errors', null);
            if (errors) {
                setErrors && setErrors(errors);
                yield put(uploadInstanceSettingFavicon.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            } else if (data) {
                yield put(uploadInstanceSettingFavicon.success(data));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            }
        } catch (e) {
            yield put(uploadInstanceSettingFavicon.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchGetInstanceFileName() {
    while (true) {
        yield take(INSTANCE_SETTING_FILES.REQUEST);
        try {
            const data: InstanceSettingsFile[] = yield call(http, `instance/files`, {
                method: 'GET',
            });
            if (data) {
                yield put(getInstanceSettingFiles.success(data));
            } else {
                yield put(getInstanceSettingFiles.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getInstanceSettingFiles.error({message: String(e)}));
        }
    }
}

function* watchDeleteInstanceFile() {
    while (true) {
        const {
            payload: {id, callback, showAlert},
        }: IActionType<InstanceSettingUploadRequestType> = yield take(INSTANCE_SETTING_DELETE_FILE.REQUEST);
        try {
            const data: InstanceSettingsFile[] = yield call(http, `instance/${id}/delete`, {
                method: 'DELETE',
            });
            const errors = get(data, 'errors', null);
            if (errors) {
                yield put(deleteInstanceSettingFile.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            } else if (data) {
                yield put(deleteInstanceSettingFile.success(data));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            }
        } catch (e) {
            yield put(deleteInstanceSettingFile.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchDeleteInstanceFavicon() {
    while (true) {
        const {
            payload: {callback, showAlert},
        }: IActionType<InstanceSettingDeleteFaviconRequestType> = yield take(INSTANCE_SETTING_DELETE_FAVICON.REQUEST);
        try {
            const data: InstanceSettingsFile[] = yield call(http, `instance/favicon/delete`, {
                method: 'POST',
            });
            const errors = get(data, 'errors', null);
            if (errors) {
                yield put(deleteInstanceSettingFavicon.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            } else if (data) {
                yield put(deleteInstanceSettingFavicon.success(data));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            }
        } catch (e) {
            yield put(deleteInstanceSettingFavicon.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchGetInstanceSettingsList),
    fork(watchUpdateInstanceSetting),
    fork(watchInstanceSettingUploadFile),
    fork(watchInstanceSettingUploadFavicon),
    fork(watchGetInstanceFileName),
    fork(watchDeleteInstanceFile),
    fork(watchDeleteInstanceFavicon),
];
