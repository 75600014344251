import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import {GroupItemTypes} from 'appRedux/actions/groups/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AttachUserToGroupForm from 'components/Forms/GroupForm/AttachUserToGroupForm';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';

interface AddUserButtonType {
    item: GroupItemTypes;
}

const AddUserButton: FC<AddUserButtonType> = ({item}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <>
            <AgentInverseButton
                icon={<PlusSvgIcon />}
                title={t('orguser.groups.addUserToGroup')}
                onClick={() => setIsOpened(true)}
            />
            <ModalWrapper
                isShowModal={isOpened}
                toggleModal={toggleIsOpened}
                title={t('orguser.groups.addUserToGroup')}
            >
                <AttachUserToGroupForm item={item} closeModal={() => setIsOpened(false)} />
            </ModalWrapper>
        </>
    );
};

export default AddUserButton;
