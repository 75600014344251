import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const PopupSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 2H14C14.265 2.00078 14.5189 2.10638 14.7062 2.29375C14.8936 2.48112 14.9992 2.73502 15 3V10C14.9992 10.265 14.8936 10.5189 14.7062 10.7062C14.5189 10.8936 14.265 10.9992 14 11H5C4.73502 10.9992 4.48112 10.8936 4.29375 10.7062C4.10639 10.5189 4.00078 10.265 4 10V3C4.00078 2.73502 4.10639 2.48112 4.29375 2.29375C4.48112 2.10638 4.73502 2.00078 5 2ZM5 10H14V3H5V10ZM2 13H9V12H10V13C9.99922 13.265 9.89362 13.5189 9.70625 13.7062C9.51888 13.8936 9.26498 13.9992 9 14H2C1.73502 13.9992 1.48112 13.8936 1.29375 13.7062C1.10638 13.5189 1.00078 13.265 1 13V8C1.00078 7.73502 1.10638 7.48112 1.29375 7.29375C1.48112 7.10639 1.73502 7.00078 2 7H3V8H2V13Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default PopupSvgIcon;
