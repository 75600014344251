import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import {TOGGLE_TWO_FACTOR_ORGANIZATION} from 'appRedux/actions/organization';

import {AlertContext} from 'contexts/alert/context';

import {CustomSwitcher} from 'components/AgentScreenComponents/_form/CustomElements';

interface OrganizationTwoFactorAuthSwitcherType {
    id: number;
    isForcedTwoFactor: boolean;
}

const OrganizationTwoFactorAuthSwitcher: FC<OrganizationTwoFactorAuthSwitcherType> = ({id, isForcedTwoFactor}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isChecked, setIsChecked] = useState<boolean>(isForcedTwoFactor);

    const toggleIsChecked = () => {
        setIsChecked(previous => !previous);
    };

    const toggleOrganizationTwoFactor = useCallback(
        data => dispatch({type: TOGGLE_TWO_FACTOR_ORGANIZATION.REQUEST, payload: data}),
        [dispatch],
    );

    const handleChangeStatus = () => {
        toggleIsChecked();
        toggleOrganizationTwoFactor({
            id,
            showAlert,
        });
    };

    return (
        <Box sx={{mb: 2}}>
            <Alert severity="error" sx={{mb: 4}}>
                <Typography>{t('superadmin.organizations.forcedTwoFactorSuperAdminExplanation')}</Typography>
            </Alert>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                <CustomSwitcher checked={isChecked} onClick={handleChangeStatus} />
                <Typography variant="body2" sx={{fontWeight: 300, ml: 2}}>
                    {t('superadmin.organizations.forcedTwoFactorAuth')}
                </Typography>
            </Box>
        </Box>
    );
};

export default OrganizationTwoFactorAuthSwitcher;
