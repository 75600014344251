import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const NoEditSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.7 1L15 14.3L14.3 15L9.25 9.95L5.2 14H2V10.8L6.05 6.75L1 1.7L1.7 1ZM3 13H4.8L8.55 9.25L6.75 7.45L3 11.2V13ZM12.9 1.3L14.7 3.1C15.1 3.5 15.1 4.1 14.7 4.5L10.7 8.5L10 7.8L11.8 6L10 4.2L8.2 6L7.5 5.3L11.5 1.3C11.9 0.9 12.5 0.9 12.9 1.3ZM10.7 3.5L12.5 5.3L14 3.8L12.2 2L10.7 3.5Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default NoEditSvgIcon;
