import React, {FC, useContext} from 'react';
import {useSelector} from 'react-redux';

import {RootReducer} from 'appRedux/reducers';

import {RouteContext} from 'contexts/route/context';

import InviteOrganizationAdminForm from 'components/Forms/OrganizationAdminForm/InviteOrganizationAdminForm';
import AddOrganizationUserAlertContent from 'components/OrganizationAdminsList/AddOrganizationUserAlertContent';

import {isMaximalOrganizationUsersNumberReached} from 'pages/admin/updateOrganization/partials/OrganizationQuotas/helper';

interface InviteOrganizationAdminModalType {
    organizationId: number;
    closeModal: () => void;
}

const InviteOrganizationAdminModal: FC<InviteOrganizationAdminModalType> = ({organizationId, closeModal}) => {
    const {isSuperAdminPage} = useContext(RouteContext);

    const {
        admin: {organizationList, organizationAdminList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const showForm =
        myOrganization && !isMaximalOrganizationUsersNumberReached(myOrganization, organizationAdminList.length, true);

    return (
        <>
            {isSuperAdminPage || showForm ? (
                <InviteOrganizationAdminForm organizationId={organizationId} closeModal={closeModal} />
            ) : (
                <AddOrganizationUserAlertContent closeModal={closeModal} />
            )}
        </>
    );
};

export default InviteOrganizationAdminModal;
