import React, {FC, ElementType} from 'react';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';

import {theme} from 'config/theme';

interface TopBarSidebarMenuItemType {
    title: string;
    icon: ElementType;
    onClickAction: () => void;
    'data-id'?: string;
}

const TopBarSidebarMenuItem: FC<TopBarSidebarMenuItemType> = ({title, icon, onClickAction, 'data-id': dataId}) => {
    const MenuIcon = icon;
    return (
        <MenuItem sx={{pt: 1, pb: 1}} onClick={onClickAction}>
            <IconButton data-id={dataId}>
                <MenuIcon />
            </IconButton>
            <Typography variant="body2" sx={{ml: 1, color: theme.palette.info.main}}>
                {title}
            </Typography>
        </MenuItem>
    );
};

export default TopBarSidebarMenuItem;
