import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';
import get from 'lodash/get';

import Box from '@mui/material/Box';

import {WorkflowStatusFormTypes} from 'appRedux/actions/workflow/types';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationSchemaCreate} from 'components/Forms/Workflow/validation';
import StatusColorRadioGroup from 'components/Forms/Workflow/workflowStatus/StatusColorRadioGroup';

interface WorkflowStatusFormInterface {
    initialValues: WorkflowStatusFormTypes;
    onSubmitClicked: (values: WorkflowStatusFormTypes) => void;
    isCreate?: boolean;
}

const WorkflowStatusForm: FC<WorkflowStatusFormInterface> = ({initialValues, onSubmitClicked, isCreate}) => {
    const [t] = useTranslation();

    const [currentColor, setCurrentColor] = useState<string>(initialValues.color);
    const [currentColorRequester, setCurrentColorRequester] = useState<string>(initialValues.colorRequester);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={() => validationSchemaCreate(t)}
            onSubmit={(values, {setErrors}) => {
                onSubmitClicked({
                    ...values,
                    setErrors,
                    color: currentColor,
                    colorRequester: currentColorRequester,
                });
            }}
        >
            {(formik: FormikProps<WorkflowStatusFormTypes>) => {
                return (
                    <Form>
                        <Field
                            required
                            name="title"
                            placeholder={t('orguser.workflows.workflowStatusParameters.title')}
                            component={FormikTextInput}
                        />
                        {isCreate && (
                            <StatusColorRadioGroup
                                title={t('orguser.workflows.workflowStatusParameters.selectColor')}
                                parameter={'color'}
                                value={currentColor}
                                setValue={setCurrentColor}
                                isTouched={get(formik, ['touched', 'color'], false)}
                                errorMessage={get(formik, ['errors', 'color'], null)}
                            />
                        )}
                        <Field
                            required
                            name="titleRequester"
                            placeholder={t('orguser.workflows.workflowStatusParameters.titleRequester')}
                            component={FormikTextInput}
                        />
                        {isCreate && (
                            <StatusColorRadioGroup
                                title={t('orguser.workflows.workflowStatusParameters.selectColorRequester')}
                                parameter={'colorRequester'}
                                value={currentColorRequester}
                                setValue={setCurrentColorRequester}
                                isTouched={get(formik, ['touched', 'colorRequester'], false)}
                                errorMessage={get(formik, ['errors', 'colorRequester'], null)}
                            />
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 3,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default WorkflowStatusForm;
