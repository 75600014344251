import React, {FC, useCallback, useContext} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import FormControl from '@mui/material/FormControl';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';

import {CREATE_ADDITIONAL_FORM_INVITE} from 'appRedux/actions/requestCase';
import {InviteAdditionalFormCreateRequestTypes} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import {validationSchema} from 'components/Forms/RequesterCase/validation';
import MdxEditorField from 'components/MdxEditorField/MdxEditorField';

interface CreateAdditionalRequestFormType {
    closeModal: () => void;
}

const CreateAdditionalRequestForm: FC<CreateAdditionalRequestFormType> = ({closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {caseId} = useParams();

    const {showAlert} = useContext(AlertContext);

    const createAdditionalFormInvite = useCallback(
        data => dispatch({type: CREATE_ADDITIONAL_FORM_INVITE.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {
            formInfo: {additionalForms},
        },
        requestCase: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onSendClicked = (values: InviteAdditionalFormCreateRequestTypes) => {
        createAdditionalFormInvite({
            ...values,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <Formik
            initialValues={{
                caseId: Number(caseId),
                form: 0,
                text: '',
            }}
            validationSchema={validationSchema}
            onSubmit={onSendClicked}
        >
            {(formik: FormikProps<InviteAdditionalFormCreateRequestTypes>) => {
                return (
                    <Form>
                        <FormControl fullWidth>
                            <InputLabel>{t('orguser.requesterCase.additionalForms.selectForm')}</InputLabel>
                            <Select
                                required
                                name="form"
                                fullWidth
                                value={formik.values.form}
                                variant="standard"
                                onChange={formik.handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                            >
                                {additionalForms &&
                                    additionalForms.map(item => {
                                        const {id, title, isSubForm} = item;
                                        if (!isSubForm) {
                                            return (
                                                <MenuItem key={`additionalForms-form-${id}`} value={id}>
                                                    <Typography>{title}</Typography>
                                                </MenuItem>
                                            );
                                        }
                                        return null;
                                    })}
                            </Select>
                            <MdxEditorField
                                name={'text'}
                                label={t('orguser.requesterCase.additionalForms.text')}
                                value={''}
                                customButtons={[]}
                            />
                            <Box
                                sx={{
                                    mt: 3,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <AgentInverseButton title={t('common.buttons.cancel')} onClick={closeModal} />
                                <AgentSaveButton isLoading={isLoading} isSubmit title={t('common.buttons.send')} />
                            </Box>
                        </FormControl>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CreateAdditionalRequestForm;
