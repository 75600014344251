import * as yup from 'yup';

import {REGEX_FOR_PASSWORD} from 'components/RegistrationForm/helper';

import {MINIMAL_PASSWORD_LENGTH, MAXIMAL_PASSWORD_LENGTH, getPasswordLengthErrorMessage} from 'helpers/security';
import {htmlTagsPresented} from 'helpers/validation';

import {QR_CODE_VERIFICATION_MAX_LENGTH, QR_CODE_VERIFICATION_MIN_LENGTH} from 'config/index';

export const initialValues = {
    currentPassword: '',
    password: {
        first: '',
        second: '',
    },
    code: '',
};

export const validationSchema = (t: (trans: string) => string) =>
    yup.object({
        currentPassword: yup
            .string()
            .test('unwanted', t('messages.validation.htmlTagsDenied'), value => htmlTagsPresented(value))
            .required(t('messages.validation.passwordIsRequired')),
        code: yup
            .string()
            .test('unwanted', t('messages.validation.htmlTagsDenied'), value => htmlTagsPresented(value))
            .min(
                QR_CODE_VERIFICATION_MIN_LENGTH,
                `Authentication code consists of ${QR_CODE_VERIFICATION_MIN_LENGTH} symbols minimal`,
            )
            .max(
                QR_CODE_VERIFICATION_MAX_LENGTH,
                `Authentication code consists of ${QR_CODE_VERIFICATION_MAX_LENGTH} symbols maximal`,
            ),
        password: yup.object({
            first: yup
                .string()
                .test('unwanted', t('messages.validation.htmlTagsDenied'), value => htmlTagsPresented(value))
                .min(
                    MINIMAL_PASSWORD_LENGTH,
                    getPasswordLengthErrorMessage(t, 'messages.validation.passwordMinLength', MINIMAL_PASSWORD_LENGTH),
                )
                .max(
                    MAXIMAL_PASSWORD_LENGTH,
                    getPasswordLengthErrorMessage(t, 'messages.validation.passwordMaxLength', MAXIMAL_PASSWORD_LENGTH),
                )
                .required(t('messages.validation.passwordIsRequired'))
                .matches(REGEX_FOR_PASSWORD, t('messages.validation.passwordShouldContain')),
            second: yup
                .string()
                .test('unwanted', t('messages.validation.htmlTagsDenied'), value => htmlTagsPresented(value))
                .min(
                    MINIMAL_PASSWORD_LENGTH,
                    getPasswordLengthErrorMessage(t, 'messages.validation.passwordMinLength', MINIMAL_PASSWORD_LENGTH),
                )
                .max(
                    MAXIMAL_PASSWORD_LENGTH,
                    getPasswordLengthErrorMessage(t, 'messages.validation.passwordMaxLength', MAXIMAL_PASSWORD_LENGTH),
                )
                .required(t('messages.validation.passwordIsRequired'))
                .matches(REGEX_FOR_PASSWORD, t('messages.validation.passwordShouldContain')),
        }),
    });
