import React, {FC, useCallback, useContext} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {InstanceSettingBasic} from 'appRedux/actions/instance/types';
import {InstanceSettingRequestType} from 'appRedux/actions/instance/types';
import {INSTANCE_SETTING_UPDATE} from 'appRedux/actions/instance';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationSchema} from 'components/Forms/Instance/InstanceSetting/validation';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';

interface InstanceSettingFormType {
    item: InstanceSettingBasic;
    setShowModal: (value: boolean) => void;
}

const InstanceSettingForm: FC<InstanceSettingFormType> = ({item, setShowModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {id, title, value} = item;

    const onSubmitClicked = useCallback(
        payload => dispatch({type: INSTANCE_SETTING_UPDATE.REQUEST, payload}),
        [dispatch],
    );

    const {
        instance: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    return (
        <Formik
            initialValues={{
                value: value ?? '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, {setErrors}) => {
                onSubmitClicked({
                    ...values,
                    id,
                    callback: () => {
                        setShowModal(false);
                    },
                    setErrors,
                    showAlert,
                });
            }}
        >
            {(formik: FormikProps<InstanceSettingRequestType>) => {
                return (
                    <Form>
                        <Field required name="value" placeholder={title} component={FormikTextInput} fullwidth />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton isLoading={isLoading} isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default InstanceSettingForm;
