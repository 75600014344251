import React, {FC} from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RequesterCaseHasBudgetTypes} from 'appRedux/actions/requestCase/types';
import {FormFieldTypes} from 'appRedux/actions/forms/types';

import {TYPE_BUDGET_INPUT_CALENDAR} from 'components/Forms/FormBuilder/FieldForm/helper';

import {getBudgetAmountDaysDifference, getBudgetAmountValue} from 'helpers/resourceFieldsHelper';

import {DATE_LABEL_FORMAT} from 'config/index';

interface BudgetAskedAmountType {
    field: FormFieldTypes;
    currentBudget: RequesterCaseHasBudgetTypes;
}

const BudgetAskedAmount: FC<BudgetAskedAmountType> = ({field, currentBudget}) => {
    const [t] = useTranslation();

    const {type, amountUser, startUserAt, endUserAt} = currentBudget;

    const isInputCalendarType = field.typeBudgetInput === TYPE_BUDGET_INPUT_CALENDAR;

    const amountUserLabel =
        isInputCalendarType && startUserAt && endUserAt
            ? getBudgetAmountDaysDifference(startUserAt, endUserAt)
            : getBudgetAmountValue(type, amountUser);

    return (
        <Box
            sx={{
                mb: 1,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',

                    mb: isInputCalendarType ? 1 : 0,
                }}
            >
                <Typography sx={{fontWeight: 700, mr: 1}}>{`${t('orguser.resourceFields.askedAmount')}:`}</Typography>
                <Typography>{amountUserLabel}</Typography>
            </Box>
            {isInputCalendarType && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        mt: 1,
                    }}
                >
                    <Typography sx={{fontWeight: 700, mr: 1}}>{`${t(
                        'orguser.resourceFields.askedDateRange',
                    )}:`}</Typography>
                    <Typography>{`${moment(startUserAt).format(DATE_LABEL_FORMAT)} - ${moment(endUserAt).format(
                        DATE_LABEL_FORMAT,
                    )}`}</Typography>
                </Box>
            )}
        </Box>
    );
};

export default BudgetAskedAmount;
