import React, {FC, useCallback} from 'react';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';

import {FORM_FIELD_ADD} from 'appRedux/actions/forms';

import FieldForm from 'components/Forms/FormBuilder/FieldForm/Form';
import SectionSelector from 'components/Forms/FormBuilder/FieldForm/SectionSelector';
import {formFieldInitialValues} from 'components/Forms/FormBuilder/FieldForm/validation';
import {UPDATE_FIELD_KEYWORD} from 'pages/admin/updateForm/partials/constants';

interface CreateFormFieldType {
    pageId: number;
    currentSectionId: number;
    setCurrentSectionId: (value: number) => void;
    fieldType?: number | null;
    newItemPriority?: number;
    setModalTitle: (value: string) => void;
    setEntityId: (value: number) => void;
}

const CreateFormField: FC<CreateFormFieldType> = ({
    pageId,
    currentSectionId,
    setCurrentSectionId,
    fieldType,
    newItemPriority,
    setModalTitle,
    setEntityId,
}) => {
    const dispatch = useDispatch();

    const submitCreateForm = useCallback(data => dispatch({type: FORM_FIELD_ADD.REQUEST, payload: data}), [dispatch]);

    const initialValues = fieldType ? {...formFieldInitialValues, type: fieldType} : formFieldInitialValues;

    const continueEditing = (fieldId: number) => {
        setModalTitle(UPDATE_FIELD_KEYWORD);
        setEntityId(fieldId);
    };

    return (
        <Box>
            <SectionSelector
                currentSectionId={currentSectionId}
                pageId={pageId}
                setCurrentSectionId={setCurrentSectionId}
            />
            <FieldForm
                entityId={currentSectionId}
                newItemPriority={newItemPriority}
                initialValues={initialValues}
                onSubmitAction={submitCreateForm}
                isDisabled={currentSectionId === 0}
                continueEditing={continueEditing}
                isCreate
            />
        </Box>
    );
};

export default CreateFormField;
