import {WRONG_LOGO_TYPE, WRONG_LOGO_SIZE, WRONG_FAVICON_SIZE} from 'config/index';
import {
    ERROR_MESSAGE_WRONG_TRANSLATED_DOCUMENT_TYPE,
    ERROR_MESSAGE_WRONG_TRANSLATED_DOCUMENT_SIZE,
    ERROR_MESSAGE_WRONG_TRANSLATED_DOCUMENT_FORM,
    ERROR_MESSAGE_WRONG_TRANSLATED_DOCUMENT_LANGUAGE,
    ERROR_MESSAGE_WRONG_TRANSLATED_DOCUMENT_HTML,
    ERROR_MESSAGE_WRONG_DOCUMENT_TYPE,
    ERROR_MESSAGE_WRONG_DOCUMENT_SIZE,
} from 'config/errors';

export const getTranslationUploadErrorMessage = (errors: string) => {
    switch (errors) {
        case ERROR_MESSAGE_WRONG_TRANSLATED_DOCUMENT_TYPE:
            return 'messages.error.wrongTranslatedDocumentTypeMessage';
        case ERROR_MESSAGE_WRONG_TRANSLATED_DOCUMENT_SIZE:
            return 'messages.error.wrongTranslatedDocumentSizeMessage';
        case ERROR_MESSAGE_WRONG_TRANSLATED_DOCUMENT_FORM:
            return 'messages.error.wrongTranslatedDocumentFormMessage';
        case ERROR_MESSAGE_WRONG_TRANSLATED_DOCUMENT_LANGUAGE:
            return 'messages.error.wrongTranslatedDocumentLanguageMessage';
        case ERROR_MESSAGE_WRONG_TRANSLATED_DOCUMENT_HTML:
            return 'messages.error.wrongTranslatedDocumentHtmlTagsPresentedMessage';
        default:
            return 'messages.error.somethingWentWrong';
    }
};

export const getFileUploadErrorMessage = (errors: string) => {
    switch (errors) {
        case ERROR_MESSAGE_WRONG_DOCUMENT_TYPE:
            return 'messages.error.wrongDocumentTypeMessage';
        case ERROR_MESSAGE_WRONG_DOCUMENT_SIZE:
            return 'messages.error.wrongDocumentSizeMessage';
        default:
            return 'messages.error.somethingWentWrong';
    }
};

export const getLogoUploadErrorMessage = (errors: string) => {
    switch (errors) {
        case WRONG_LOGO_TYPE:
            return 'messages.error.wrongLogoTypeMessage';
        case WRONG_LOGO_SIZE:
            return 'messages.error.wrongLogoSizeMessage';
        case WRONG_FAVICON_SIZE:
            return 'messages.error.wrongFaviconSize';
        default:
            return 'messages.error.somethingWentWrong';
    }
};
