import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';

import {TWO_FACTOR_CODES_DOWNLOAD, TWO_FACTOR_REGENERATION, TWO_FACTOR_DEACTIVATE} from 'appRedux/actions/auth';
import {TwoFactorConfirmationTypes} from 'appRedux/actions/auth/types';

import CopySvgIcon from 'assets/icons/buttons/CopySvgIcon';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import GoogleAuthCodeForm from 'components/Forms/SettingsForms/TwoFactorForm/GoogleAuthCodeForm';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import {ALERT_TYPE_SUCCESS} from 'config/index';

interface TwoFactorActivatedType {
    recoveryCodes: string[];
    setRecoveryCodes: (values: string[]) => void;
    setIsQrCodeGenerated: (value: boolean) => void;
}

const MODAL_ACTION_DEACTIVATE = 'MODAL_ACTION_DEACTIVATE';
const MODAL_ACTION_REGENERATE = 'MODAL_ACTION_REGENERATE';

const TwoFactorActivated: FC<TwoFactorActivatedType> = ({recoveryCodes, setRecoveryCodes, setIsQrCodeGenerated}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalActionType, setModalActionType] = useState<string | null>(null);

    const toggleShowModal = (actionType: string | null) => {
        setShowModal(previous => !previous);
        setModalActionType(actionType);
    };

    const regenerateRecoveryCodes = useCallback(
        data => dispatch({type: TWO_FACTOR_REGENERATION.REQUEST, payload: data}),
        [dispatch],
    );

    const downloadRecoveryCodes = useCallback(
        data => dispatch({type: TWO_FACTOR_CODES_DOWNLOAD.REQUEST, payload: data}),
        [dispatch],
    );

    const deactivateTwoFactorAuthorization = useCallback(
        data => dispatch({type: TWO_FACTOR_DEACTIVATE.REQUEST, payload: data}),
        [dispatch],
    );

    const onRegenerateClick = (values: TwoFactorConfirmationTypes) => {
        regenerateRecoveryCodes({
            ...values,
            showAlert,
            callback: (recoveryCodes: string[]) => {
                setRecoveryCodes(recoveryCodes);
                setShowModal(false);
            },
        });
    };

    const downloadFile = (blob: Blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'herupu_recovery_codes.txt';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const onCopyClick = () => {
        navigator.clipboard.writeText(recoveryCodes.join('; '));
        showAlert(ALERT_TYPE_SUCCESS, t('messages.success.codesCopiedToBuffer'));
    };

    const onDownloadClick = () => {
        downloadRecoveryCodes({
            showAlert,
            callback: downloadFile,
        });
    };

    const confirmDeactivation = (values: TwoFactorConfirmationTypes) => {
        setIsQrCodeGenerated(false);
        deactivateTwoFactorAuthorization({
            ...values,
            callback: () => setShowModal(false),
            showAlert,
        });
    };

    return (
        <Box>
            {recoveryCodes.length > 0 && (
                <Box>
                    <Alert severity="info">
                        <Typography sx={{fontWeight: 600}}>{t('common.security.recoveryCodesTitle')}</Typography>
                    </Alert>
                    <Typography sx={{mt: 2, mb: 3}}>{t('common.security.recoveryCodesExplanation')}</Typography>
                    <Box sx={{position: 'relative', mb: 2, p: 2, backgroundColor: `rgba(34, 34, 34, 0.05)`}}>
                        {recoveryCodes.map((code: string, index: number) => {
                            return (
                                <Typography key={`code-${index}`} sx={{fontWeight: 600}}>
                                    {code}
                                </Typography>
                            );
                        })}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'absolute',
                                top: 10,
                                right: 10,
                            }}
                        >
                            <IconButton onClick={onCopyClick} title={t('common.buttons.copy')}>
                                <CopySvgIcon />
                            </IconButton>
                            <IconButton onClick={onDownloadClick} sx={{ml: 1}} title={t('common.buttons.download')}>
                                <DownloadIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    mt: recoveryCodes.length > 0 ? 4 : 0,
                }}
            >
                <AgentSaveButton
                    onClick={() => toggleShowModal(MODAL_ACTION_DEACTIVATE)}
                    title={t('common.buttons.deactivate')}
                    isDanger
                />
                <AgentSaveButton
                    onClick={() => toggleShowModal(MODAL_ACTION_REGENERATE)}
                    title={t('common.security.regenerateRecoveryCodes')}
                />
            </Box>
            {showModal && (
                <ModalWrapper
                    title={t(
                        modalActionType === MODAL_ACTION_DEACTIVATE
                            ? 'common.security.twoFactorDeactivation'
                            : 'common.security.regenerateRecoveryCodes',
                    )}
                    isShowModal={showModal}
                    toggleModal={() => toggleShowModal(null)}
                >
                    <Typography sx={{mb: 2}}>{t('common.security.twoFactorDeactivationDescription')}</Typography>
                    <GoogleAuthCodeForm
                        handleSubmit={
                            modalActionType === MODAL_ACTION_DEACTIVATE ? confirmDeactivation : onRegenerateClick
                        }
                    />
                </ModalWrapper>
            )}
        </Box>
    );
};

export default TwoFactorActivated;
