import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import CreateOrganizationPrivacyPolicyForm from 'components/Forms/OrganizationPrivacyPolicyForm/CreateOrganizationPrivacyPolicyForm';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';

interface AddPrivacyPolicyButtonType {
    organizationId: number;
    currentLanguage: string;
    alreadyHasPolicies: boolean;
}

const AddPrivacyPolicyButton: FC<AddPrivacyPolicyButtonType> = ({
    organizationId,
    currentLanguage,
    alreadyHasPolicies,
}) => {
    const [t] = useTranslation();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                mb: 2,
            }}
        >
            <AgentInverseButton
                icon={<PlusSvgIcon />}
                title={t('superadmin.organizations.privacyPolicy.addPrivacyPolicy')}
                onClick={() => setShowModal(true)}
            />
            <ModalWrapper
                isShowModal={showModal}
                title={t('superadmin.organizations.privacyPolicy.addPrivacyPolicy')}
                toggleModal={toggleModal}
            >
                <CreateOrganizationPrivacyPolicyForm
                    organizationId={organizationId}
                    currentLanguage={currentLanguage}
                    alreadyHasPolicies={alreadyHasPolicies}
                    closeModal={() => setShowModal(false)}
                />
            </ModalWrapper>
        </Box>
    );
};

export default AddPrivacyPolicyButton;
