import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Formik, FormikProps} from 'formik';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import {AlertContext} from 'contexts/alert/context';

import {RootReducer} from 'appRedux/reducers';
import {FORM_ORGANIZATION_RELATE} from 'appRedux/actions/forms';
import {FormOrganizationRelateRequestType} from 'appRedux/actions/forms/types';
import {OrganizationItemBasic} from 'appRedux/actions/organization/types';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationRelateSchema} from 'components/Forms/FormBuilder/FormOrganizationRelateForm/validation';

import {theme} from 'config/theme';

interface FormOrganizationRelateFormType {
    formId: number;
    toggleModal: () => void;
}

const FormOrganizationRelateForm: FC<FormOrganizationRelateFormType> = ({formId, toggleModal}) => {
    const [t] = useTranslation();
    const schema = () => validationRelateSchema(t);
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        admin: {isLoading, organizationList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const relateFormToOrganization = useCallback(
        data => dispatch({type: FORM_ORGANIZATION_RELATE.REQUEST, payload: data}),
        [dispatch],
    );

    return (
        <Formik
            initialValues={{
                organization: '',
            }}
            validationSchema={schema}
            onSubmit={values => {
                relateFormToOrganization({
                    ...values,
                    id: formId,
                    callback: toggleModal,
                    showAlert,
                });
            }}
        >
            {(formik: FormikProps<FormOrganizationRelateRequestType>) => {
                return (
                    <Form>
                        <Box>
                            <Typography variant="body2" sx={{mb: 1}}>
                                {t('orguser.forms.selectOrganization')}
                            </Typography>
                            <FormControl
                                sx={{backgroundColor: theme.palette.warning.dark, pl: 3, pr: 3, pb: 2, mb: 1}}
                                required
                                fullWidth
                            >
                                <Select
                                    sx={{
                                        height: 50,
                                    }}
                                    name={'organization'}
                                    variant="standard"
                                    onChange={formik.handleChange}
                                >
                                    {organizationList &&
                                        organizationList.map((item: OrganizationItemBasic, index: number) => {
                                            const {id, name} = item;
                                            return (
                                                <MenuItem key={`menuItem-${index}`} value={id}>
                                                    <Typography>{name}</Typography>
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </Box>
                        <AgentSaveButton isLoading={isLoading} isSubmit />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default FormOrganizationRelateForm;
