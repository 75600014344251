import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import RelateSvgIcon from 'assets/icons/buttons/RelateSvgIcon';

import {OrganizationAdminListTypes} from 'appRedux/actions/organizationAdmin/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import RelateAdminToOrganizationForm from 'components/Forms/OrganizationAdminForm/RelateAdminToOrganizationForm';

interface RelateAdminToOrganizationButtonType {
    item: OrganizationAdminListTypes;
}

const RelateAdminToOrganizationButton: FC<RelateAdminToOrganizationButtonType> = ({item}) => {
    const [t] = useTranslation();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    return (
        <>
            <IconButton title={t('superadmin.users.relateToOtherOrganization')} onClick={toggleModal}>
                <RelateSvgIcon />
            </IconButton>
            <ModalWrapper
                isShowModal={showModal}
                toggleModal={toggleModal}
                title={t('superadmin.users.relateToOtherOrganization')}
            >
                <RelateAdminToOrganizationForm item={item} closeModal={() => setShowModal(false)} />
            </ModalWrapper>
        </>
    );
};

export default RelateAdminToOrganizationButton;
