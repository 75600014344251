import get from 'lodash/get';

export const getErrorMessage = (errors: IErrors) => {
    if (errors && errors === 'invalidPassword') {
        return 'invalidPassword';
    }
    const passwordsError = get(errors, ['password', 'first', 0], null);
    if (passwordsError) {
        return passwordsError;
    }
    return null;
};
