import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import SearchIcon from '@mui/icons-material/Search';

import CancelSvgIcon from 'assets/icons/buttons/CloseSvgIcon';

import {MINIMAL_SEARCH_LENGTH} from 'helpers/search';

import {theme} from 'config/theme';

interface SearchType {
    searchValue: string;
    setSearchValue: (value: string) => void;
}

const Search: FC<SearchType> = ({searchValue, setSearchValue}) => {
    const [t] = useTranslation();

    const onIconClicked = () => {
        if (searchValue) {
            setSearchValue('');
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <FormControl fullWidth>
                <Input
                    fullWidth
                    inputProps={{className: 'search'}}
                    placeholder={t('common.searchFieldPlaceholder')}
                    onChange={handleSearchChange}
                    sx={{
                        backgroundColor: theme.palette.background.default,
                        borderRadius: 4,
                        width: 300,
                        height: 45,
                        pl: 4,
                        p: 2,
                    }}
                    value={searchValue}
                    disableUnderline
                    endAdornment={
                        <InputAdornment position="start" onClick={onIconClicked} sx={{cursor: 'pointer'}}>
                            {searchValue && searchValue.length >= MINIMAL_SEARCH_LENGTH ? (
                                <CancelSvgIcon />
                            ) : (
                                <SearchIcon />
                            )}
                        </InputAdornment>
                    }
                />
            </FormControl>
        </Box>
    );
};

export default Search;
