import React, {ReactNode, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import get from 'lodash/get';
import i18n from 'i18next';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import {RootReducer} from 'appRedux/reducers';

import {MediaContext, MediaContextType} from 'contexts/media/context';

import {SETTING_TITLE} from 'pages/admin/instanceSettings/constants';

import {LOCAL_STORAGE_CURRENT_LANGUAGE} from 'services/localStorage';

import {getLanguageById} from 'helpers/translationsHelper';

import {theme} from 'config/theme';
import {DEFAULT_LANGUAGE_ID} from 'config/index';

interface ContextType {
    children: ReactNode;
}

const MediaContextWrapper: React.FC<ContextType> = ({children}) => {
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isTablet = useMediaQuery(theme.breakpoints.only('sm'));
    const isMiddle = useMediaQuery(theme.breakpoints.only('md'));
    const isDesktop = useMediaQuery(theme.breakpoints.only('lg'));
    const isLargeDesktop = useMediaQuery(theme.breakpoints.only('xl'));

    const [language, setLanguage] = useState<number>(DEFAULT_LANGUAGE_ID);
    const [isInitialLanguageUpdated, setIsInitialLanguageUpdated] = useState<boolean>(false);

    const {
        admin: {languageList},
        auth: {organization},
        profile: {profile},
        instance: {settings, files},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const browserTitleSetting = Array.isArray(settings) ? settings.find(item => item.type === SETTING_TITLE) : null;
    const customFavicon = get(files, 'favicon', null);

    const setLanguageAfterLogin = (languageId: number) => {
        const selectedLanguage = getLanguageById(languageList, languageId);
        if (selectedLanguage) {
            i18n.changeLanguage(selectedLanguage.name);
            localStorage.setItem(LOCAL_STORAGE_CURRENT_LANGUAGE, selectedLanguage.name);
            setLanguage(languageId);
        }
    };

    useEffect(() => {
        if (languageList && organization && !profile && !isInitialLanguageUpdated) {
            const currentLanguageId = get(organization, 'language', DEFAULT_LANGUAGE_ID);
            setLanguageAfterLogin(currentLanguageId);
        }
    }, [languageList, profile, organization]);

    useEffect(() => {
        const browserTitle = document.getElementById('browserTitle') as HTMLAnchorElement | null;
        if (browserTitleSetting && browserTitle) {
            browserTitle.text = browserTitleSetting.value ?? 'Herupu';
        }
        const favicon = document.getElementById('favicon') as HTMLAnchorElement | null;
        if (customFavicon && favicon) {
            favicon.href = customFavicon;
        }
    }, [browserTitleSetting, customFavicon]);

    const context: MediaContextType = {
        isMobile,
        isTablet,
        isMiddle,
        isDesktop,
        isLargeDesktop,
        language,
        setLanguage,
        setIsInitialLanguageUpdated,
        setLanguageAfterLogin,
    };

    return <MediaContext.Provider value={context}>{children}</MediaContext.Provider>;
};

export default MediaContextWrapper;
