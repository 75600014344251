export const LOCAL_STORAGE_TOKEN = 'TOKEN';
export const LOCAL_STORAGE_REFRESH_TOKEN = 'REFRESH_TOKEN';
export const LOCAL_STORAGE_PERSIST_ROOT = 'persist:root';
export const LOCAL_STORAGE_FILTER = 'agentFilter';
export const LOCAL_STORAGE_REDIRECT_AFTER_LOGIN = 'redirectAfterLogin';
export const LOCAL_STORAGE_ACTIVE_CASES_VIEW = 'activeCasesView';
export const LOCAL_STORAGE_CURRENT_LANGUAGE = 'language';
export const LOCAL_STORAGE_LOGOUT_TIME = 'LOGOUT_TIME';
export const LOCAL_STORAGE_NICKNAME_POPUP_STATUS = 'nicknamePopupStatus';

export const keys: {[key: string]: string} = {
    TOKEN: LOCAL_STORAGE_TOKEN,
    REFRESH_TOKEN: LOCAL_STORAGE_REFRESH_TOKEN,
};

const storeData = async (key: string, value: string) => {
    await localStorage.setItem(key, value);
};

const getData = async (key: string): Promise<string | null> => {
    return localStorage.getItem(key);
};

export const setToken = async (token: string): Promise<void> => {
    await storeData(keys.TOKEN, token);
};

export const getToken = async (): Promise<unknown | undefined> => {
    const data = await getData(keys.TOKEN);
    return data as string;
};

export const setRefreshToken = async (refreshToken: string): Promise<void> => {
    await storeData(keys.REFRESH_TOKEN, refreshToken);
};

export const getRefreshToken = async (): Promise<unknown | undefined> => {
    const data = await getData(keys.REFRESH_TOKEN);
    return data as string;
};

export const storeLogoutTime = async (time: number): Promise<void> => {
    await storeData(LOCAL_STORAGE_LOGOUT_TIME, String(time));
};

export const getLogoutTime = async (): Promise<number | undefined> => {
    const data = await getData(LOCAL_STORAGE_LOGOUT_TIME);
    return data ? Number(data) : undefined;
};
