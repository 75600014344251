import {fork, put, take, call} from 'redux-saga/effects';

import {RuleRequestType, RulesListResponse, RulesListRequestType} from 'appRedux/actions/rules/types';
import {RULE_CREATE, createRule, GET_RULES_LIST, getRulesList} from 'appRedux/actions/rules';

import {http} from 'services/http';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';

function* watchGetRulesList() {
    while (true) {
        const {
            payload: {type, language, showAlert},
        }: IActionType<RulesListRequestType> = yield take(GET_RULES_LIST.REQUEST);
        try {
            const data: RulesListResponse = yield call(http, `rules/${language}/list/${type}`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(getRulesList.success(data.results));
            } else {
                yield put(getRulesList.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getRulesList.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchRuleCreation() {
    while (true) {
        const {
            payload: {showAlert, callback, ...values},
        }: IActionType<RuleRequestType> = yield take(RULE_CREATE.REQUEST);
        try {
            const data: RulesListResponse = yield call(http, 'rule/create', {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createRule.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createRule.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createRule.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [fork(watchGetRulesList), fork(watchRuleCreation)];
