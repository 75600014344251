import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';

import {WorkflowStatusEventItemTypes} from 'appRedux/actions/workflow/types';

import {
    WORKFLOW_EVENT_CHAT_NOTIFICATION_CHANGE_TO_STATUS,
    WORKFLOW_EVENT_CHAT_NOTIFICATION_LEAVE_STATUS,
    WORKFLOW_EVENT_EMAIL_NOTIFICATION_CHANGE_TO_STATUS,
    WORKFLOW_EVENT_EMAIL_NOTIFICATION_CHANGE_TO_STATUS_AGENT,
    WORKFLOW_EVENT_EMAIL_NOTIFICATION_LEAVE_STATUS,
    WORKFLOW_EVENT_EMAIL_NOTIFICATION_LEAVE_STATUS_AGENT,
    WORKFLOW_EVENT_WEBHOOK_CHANGE_TO_STATUS,
    WORKFLOW_EVENT_WEBHOOK_LEAVE_STATUS,
    WORKFLOW_EVENT_EMAIL_NOTIFICATION_CHANGE_TO_STATUS_AGENT_FIXED,
    WORKFLOW_EVENT_EMAIL_NOTIFICATION_LEAVE_STATUS_AGENT_FIXED,
    getWorkflowEventItem,
} from 'pages/admin/updateWorkflowEvents/helper';
import WorkflowStatusEventTableRow from 'pages/admin/updateWorkflowEvents/WorkflowStatusEventTableRow';

interface WorkflowStatusEventsTableType {
    items: WorkflowStatusEventItemTypes[];
    statusTitle: string;
    currentLanguage: string;
}

const WorkflowStatusEventsTable: FC<WorkflowStatusEventsTableType> = ({items, statusTitle, currentLanguage}) => {
    const [t] = useTranslation();

    const eventEmailNotificationChangeToStatus = getWorkflowEventItem(
        items,
        WORKFLOW_EVENT_EMAIL_NOTIFICATION_CHANGE_TO_STATUS,
    );
    const eventEmailNotificationChangeToStatusAgent = getWorkflowEventItem(
        items,
        WORKFLOW_EVENT_EMAIL_NOTIFICATION_CHANGE_TO_STATUS_AGENT,
    );
    const eventEmailNotificationChangeToStatusAgentFixed = getWorkflowEventItem(
        items,
        WORKFLOW_EVENT_EMAIL_NOTIFICATION_CHANGE_TO_STATUS_AGENT_FIXED,
    );
    const eventEmailNotificationLeaveStatus = getWorkflowEventItem(
        items,
        WORKFLOW_EVENT_EMAIL_NOTIFICATION_LEAVE_STATUS,
    );
    const eventEmailNotificationLeaveStatusAgent = getWorkflowEventItem(
        items,
        WORKFLOW_EVENT_EMAIL_NOTIFICATION_LEAVE_STATUS_AGENT,
    );
    const eventEmailNotificationLeaveStatusAgentFixed = getWorkflowEventItem(
        items,
        WORKFLOW_EVENT_EMAIL_NOTIFICATION_LEAVE_STATUS_AGENT_FIXED,
    );
    const eventChatNotificationChangeToStatus = getWorkflowEventItem(
        items,
        WORKFLOW_EVENT_CHAT_NOTIFICATION_CHANGE_TO_STATUS,
    );
    const eventChatNotificationLeaveStatus = getWorkflowEventItem(items, WORKFLOW_EVENT_CHAT_NOTIFICATION_LEAVE_STATUS);

    const eventWebhookNotificationLeaveStatus = getWorkflowEventItem(items, WORKFLOW_EVENT_WEBHOOK_LEAVE_STATUS);
    const eventWebhookNotificationChangeToStatus = getWorkflowEventItem(items, WORKFLOW_EVENT_WEBHOOK_CHANGE_TO_STATUS);

    return (
        <Table>
            <TableHead>
                <TableCell width={300}>
                    <Typography sx={{fontWeight: 600}}>
                        {t('orguser.workflows.workflowStatusEventParameters.eventType')}
                    </Typography>
                </TableCell>
                <TableCell width={200}>
                    <Typography sx={{fontWeight: 600}}>
                        {t('orguser.workflows.workflowStatusEventParameters.type')}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography sx={{fontWeight: 600}}>
                        {t('orguser.workflows.workflowStatusEventParameters.subjectAndText')}
                    </Typography>
                </TableCell>
                <TableCell width={100}>
                    <Typography sx={{fontWeight: 600}}>
                        {t('orguser.workflows.workflowStatusEventParameters.isActive')}
                    </Typography>
                </TableCell>
                <TableCell width={150}>
                    <Typography sx={{fontWeight: 600}}>
                        {t('orguser.workflows.workflowStatusEventParameters.action')}
                    </Typography>
                </TableCell>
            </TableHead>
            <TableBody>
                <WorkflowStatusEventTableRow
                    key={`changeToStatus-email`}
                    eventTypeLabel={'orguser.workflows.workflowStatusEventParameters.eventTypes.changeToStatus'}
                    typeLabel={'orguser.workflows.workflowStatusEventParameters.types.emailRequester'}
                    type={WORKFLOW_EVENT_EMAIL_NOTIFICATION_CHANGE_TO_STATUS}
                    item={eventEmailNotificationChangeToStatus}
                    statusTitle={statusTitle}
                    currentLanguage={currentLanguage}
                />
                <WorkflowStatusEventTableRow
                    key={`changeToStatus-agent-email`}
                    eventTypeLabel={'orguser.workflows.workflowStatusEventParameters.eventTypes.changeToStatus'}
                    typeLabel={'orguser.workflows.workflowStatusEventParameters.types.emailAgent'}
                    type={WORKFLOW_EVENT_EMAIL_NOTIFICATION_CHANGE_TO_STATUS_AGENT}
                    item={eventEmailNotificationChangeToStatusAgent}
                    statusTitle={statusTitle}
                    currentLanguage={currentLanguage}
                />
                <WorkflowStatusEventTableRow
                    key={`changeToStatus-fixed-agent-email`}
                    eventTypeLabel={'orguser.workflows.workflowStatusEventParameters.eventTypes.changeToStatus'}
                    typeLabel={'orguser.workflows.workflowStatusEventParameters.types.emailFixedAgent'}
                    type={WORKFLOW_EVENT_EMAIL_NOTIFICATION_CHANGE_TO_STATUS_AGENT_FIXED}
                    item={eventEmailNotificationChangeToStatusAgentFixed}
                    statusTitle={statusTitle}
                    currentLanguage={currentLanguage}
                    forFixedAgent
                />
                <WorkflowStatusEventTableRow
                    key={`leaveStatus-event`}
                    eventTypeLabel={'orguser.workflows.workflowStatusEventParameters.eventTypes.leaveStatus'}
                    typeLabel={'orguser.workflows.workflowStatusEventParameters.types.emailRequester'}
                    type={WORKFLOW_EVENT_EMAIL_NOTIFICATION_LEAVE_STATUS}
                    item={eventEmailNotificationLeaveStatus}
                    currentLanguage={currentLanguage}
                    statusTitle={statusTitle}
                />
                <WorkflowStatusEventTableRow
                    key={`leaveStatus-agent-event`}
                    eventTypeLabel={'orguser.workflows.workflowStatusEventParameters.eventTypes.leaveStatus'}
                    typeLabel={'orguser.workflows.workflowStatusEventParameters.types.emailAgent'}
                    type={WORKFLOW_EVENT_EMAIL_NOTIFICATION_LEAVE_STATUS_AGENT}
                    item={eventEmailNotificationLeaveStatusAgent}
                    statusTitle={statusTitle}
                    currentLanguage={currentLanguage}
                />
                <WorkflowStatusEventTableRow
                    key={`leaveStatus-fixed-agent-email`}
                    eventTypeLabel={'orguser.workflows.workflowStatusEventParameters.eventTypes.leaveStatus'}
                    typeLabel={'orguser.workflows.workflowStatusEventParameters.types.emailFixedAgent'}
                    type={WORKFLOW_EVENT_EMAIL_NOTIFICATION_LEAVE_STATUS_AGENT_FIXED}
                    item={eventEmailNotificationLeaveStatusAgentFixed}
                    statusTitle={statusTitle}
                    currentLanguage={currentLanguage}
                    forFixedAgent
                />
                <WorkflowStatusEventTableRow
                    key={`changeToStatus-chat`}
                    eventTypeLabel={'orguser.workflows.workflowStatusEventParameters.eventTypes.changeToStatus'}
                    typeLabel={'orguser.workflows.workflowStatusEventParameters.types.chat'}
                    type={WORKFLOW_EVENT_CHAT_NOTIFICATION_CHANGE_TO_STATUS}
                    item={eventChatNotificationChangeToStatus}
                    currentLanguage={currentLanguage}
                    statusTitle={statusTitle}
                />
                <WorkflowStatusEventTableRow
                    key={`leaveStatus-chat`}
                    eventTypeLabel={'orguser.workflows.workflowStatusEventParameters.eventTypes.leaveStatus'}
                    typeLabel={'orguser.workflows.workflowStatusEventParameters.types.chat'}
                    type={WORKFLOW_EVENT_CHAT_NOTIFICATION_LEAVE_STATUS}
                    item={eventChatNotificationLeaveStatus}
                    currentLanguage={currentLanguage}
                    statusTitle={statusTitle}
                />
                <WorkflowStatusEventTableRow
                    key={`leaveStatus-webhook`}
                    eventTypeLabel={'orguser.workflows.workflowStatusEventParameters.eventTypes.leaveStatus'}
                    typeLabel={'orguser.workflows.workflowStatusEventParameters.types.webhook'}
                    type={WORKFLOW_EVENT_WEBHOOK_LEAVE_STATUS}
                    item={eventWebhookNotificationLeaveStatus}
                    currentLanguage={currentLanguage}
                    statusTitle={statusTitle}
                />
                <WorkflowStatusEventTableRow
                    key={`changeToStatus-webhook`}
                    eventTypeLabel={'orguser.workflows.workflowStatusEventParameters.eventTypes.changeToStatus'}
                    typeLabel={'orguser.workflows.workflowStatusEventParameters.types.webhook'}
                    type={WORKFLOW_EVENT_WEBHOOK_CHANGE_TO_STATUS}
                    item={eventWebhookNotificationChangeToStatus}
                    currentLanguage={currentLanguage}
                    statusTitle={statusTitle}
                />
            </TableBody>
        </Table>
    );
};

export default WorkflowStatusEventsTable;
