import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import {FORM_FIELD_GDPR_SETTINGS_UPDATE} from 'appRedux/actions/forms';
import {FormFieldGdprSettingsFormType, FormFieldTypes} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';

import FormBuilderCheckboxReverse from 'components/AgentScreenComponents/_form/FormBuilderCheckboxReverse';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FieldGdprCheckboxWrapper from 'components/Forms/FormBuilder/FieldForm/partials/FieldGdprCheckboxWrapper';

import {preventSendForm} from 'helpers/index';

interface FieldGdprSettingsFormType {
    fieldId: number;
    initialValues: FormFieldTypes;
}

const FieldGdprSettingsForm: FC<FieldGdprSettingsFormType> = ({fieldId, initialValues}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const submitUpdateForm = useCallback(
        data => dispatch({type: FORM_FIELD_GDPR_SETTINGS_UPDATE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: FormFieldGdprSettingsFormType) => {
        submitUpdateForm({
            id: fieldId,
            ...values,
            showAlert,
        });
    };

    const {
        isContactInformation,
        isPersonalData,
        isEducationalInformation,
        isProfessionalInformation,
        isMediaData,
        isLocationData,
        isLegalInformation,
        isPermissionPreferences,
        isFinancialInformation,
        isHealthData,
        isPersonalNotes,
        isBiometricData,
        isSensitiveInformation,
    } = initialValues;

    return (
        <Formik
            initialValues={{
                isContactInformation,
                isPersonalData,
                isEducationalInformation,
                isProfessionalInformation,
                isMediaData,
                isLocationData,
                isLegalInformation,
                isPermissionPreferences,
                isFinancialInformation,
                isHealthData,
                isPersonalNotes,
                isBiometricData,
                isSensitiveInformation,
            }}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<FormFieldGdprSettingsFormType>) => {
                return (
                    <Form onKeyDown={preventSendForm}>
                        <FieldGdprCheckboxWrapper
                            explanationText={t('orguser.forms.fieldGdprSettings.isContactInformationExplanation')}
                        >
                            <Field
                                type="checkbox"
                                name="isContactInformation"
                                label={t('orguser.forms.fieldGdprSettings.isContactInformation')}
                                component={FormBuilderCheckboxReverse}
                            />
                        </FieldGdprCheckboxWrapper>
                        <FieldGdprCheckboxWrapper
                            explanationText={t('orguser.forms.fieldGdprSettings.isPersonalDataExplanation')}
                        >
                            <Field
                                type="checkbox"
                                name="isPersonalData"
                                label={t('orguser.forms.fieldGdprSettings.isPersonalData')}
                                component={FormBuilderCheckboxReverse}
                            />
                        </FieldGdprCheckboxWrapper>
                        <FieldGdprCheckboxWrapper
                            explanationText={t('orguser.forms.fieldGdprSettings.isEducationalInformationExplanation')}
                        >
                            <Field
                                type="checkbox"
                                name="isEducationalInformation"
                                label={t('orguser.forms.fieldGdprSettings.isEducationalInformation')}
                                component={FormBuilderCheckboxReverse}
                            />
                        </FieldGdprCheckboxWrapper>
                        <FieldGdprCheckboxWrapper
                            explanationText={t('orguser.forms.fieldGdprSettings.isProfessionalInformationExplanation')}
                        >
                            <Field
                                type="checkbox"
                                name="isProfessionalInformation"
                                label={t('orguser.forms.fieldGdprSettings.isProfessionalInformation')}
                                component={FormBuilderCheckboxReverse}
                            />
                        </FieldGdprCheckboxWrapper>
                        <FieldGdprCheckboxWrapper
                            explanationText={t('orguser.forms.fieldGdprSettings.isMediaDataExplanation')}
                        >
                            <Field
                                type="checkbox"
                                name="isMediaData"
                                label={t('orguser.forms.fieldGdprSettings.isMediaData')}
                                component={FormBuilderCheckboxReverse}
                            />
                        </FieldGdprCheckboxWrapper>
                        <FieldGdprCheckboxWrapper
                            explanationText={t('orguser.forms.fieldGdprSettings.isLocationDataExplanation')}
                        >
                            <Field
                                type="checkbox"
                                name="isLocationData"
                                label={t('orguser.forms.fieldGdprSettings.isLocationData')}
                                component={FormBuilderCheckboxReverse}
                            />
                        </FieldGdprCheckboxWrapper>
                        <FieldGdprCheckboxWrapper
                            explanationText={t('orguser.forms.fieldGdprSettings.isLegalInformationExplanation')}
                        >
                            <Field
                                type="checkbox"
                                name="isLegalInformation"
                                label={t('orguser.forms.fieldGdprSettings.isLegalInformation')}
                                component={FormBuilderCheckboxReverse}
                            />
                        </FieldGdprCheckboxWrapper>
                        <FieldGdprCheckboxWrapper
                            explanationText={t('orguser.forms.fieldGdprSettings.isPermissionPreferencesExplanation')}
                        >
                            <Field
                                type="checkbox"
                                name="isPermissionPreferences"
                                label={t('orguser.forms.fieldGdprSettings.isPermissionPreferences')}
                                component={FormBuilderCheckboxReverse}
                            />
                        </FieldGdprCheckboxWrapper>
                        <FieldGdprCheckboxWrapper
                            explanationText={t('orguser.forms.fieldGdprSettings.isFinancialInformationExplanation')}
                        >
                            <Field
                                type="checkbox"
                                name="isFinancialInformation"
                                label={t('orguser.forms.fieldGdprSettings.isFinancialInformation')}
                                component={FormBuilderCheckboxReverse}
                            />
                        </FieldGdprCheckboxWrapper>
                        <FieldGdprCheckboxWrapper
                            explanationText={t('orguser.forms.fieldGdprSettings.isHealthDataExplanation')}
                        >
                            <Field
                                type="checkbox"
                                name="isHealthData"
                                label={t('orguser.forms.fieldGdprSettings.isHealthData')}
                                component={FormBuilderCheckboxReverse}
                            />
                        </FieldGdprCheckboxWrapper>
                        <FieldGdprCheckboxWrapper
                            explanationText={t('orguser.forms.fieldGdprSettings.isPersonalNotesExplanation')}
                        >
                            <Field
                                type="checkbox"
                                name="isPersonalNotes"
                                label={t('orguser.forms.fieldGdprSettings.isPersonalNotes')}
                                component={FormBuilderCheckboxReverse}
                            />
                        </FieldGdprCheckboxWrapper>
                        <FieldGdprCheckboxWrapper
                            explanationText={t('orguser.forms.fieldGdprSettings.isBiometricDataExplanation')}
                        >
                            <Field
                                type="checkbox"
                                name="isBiometricData"
                                label={t('orguser.forms.fieldGdprSettings.isBiometricData')}
                                component={FormBuilderCheckboxReverse}
                            />
                        </FieldGdprCheckboxWrapper>
                        <FieldGdprCheckboxWrapper
                            explanationText={t('orguser.forms.fieldGdprSettings.isSensitiveInformationExplanation')}
                        >
                            <Field
                                type="checkbox"
                                name="isSensitiveInformation"
                                label={t('orguser.forms.fieldGdprSettings.isSensitiveInformation')}
                                component={FormBuilderCheckboxReverse}
                            />
                        </FieldGdprCheckboxWrapper>
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mt: 1}}>
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default FieldGdprSettingsForm;
