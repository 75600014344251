import React, {FC} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {RequesterCaseActivitiesTypes} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import DataGridWrapper from 'components/AdminScreenComponents/DataGridWrapper';

import {getSearchedActivities} from 'pages/agent/activityPage/helper';
import ActivityHeader from 'pages/agent/activityPage/ActivityHeader';
import ActivityPageDataGrid from 'pages/agent/activityPage/ActivityPageDataGrid';

import {PARAMETER_SEARCH} from 'config/index';

const ActivityPageContent: FC = () => {
    const [searchParams] = useSearchParams();

    const searchFormUrl = searchParams.get(PARAMETER_SEARCH);

    const {
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {activities} = currentCase;

    const searchedActivities: RequesterCaseActivitiesTypes[] = getSearchedActivities(activities, searchFormUrl);

    return (
        <Box>
            <ActivityHeader />
            <DataGridWrapper
                hasSearch
                itemsList={searchedActivities}
                itemsTotalNumber={searchedActivities ? searchedActivities.length : 0}
            >
                <ActivityPageDataGrid items={searchedActivities} />
            </DataGridWrapper>
        </Box>
    );
};

export default ActivityPageContent;
