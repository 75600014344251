import React, {FC, useCallback, useContext} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {GeneralTemplateTypes, GeneralTemplateFormTypes} from 'appRedux/actions/generalTemplates/types';
import {GENERAL_TEMPLATE_UPDATE} from 'appRedux/actions/generalTemplates';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import {validationSchema} from 'components/Forms/GeneralTemplateForm/validation';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import FormBuilderCheckboxReverse from 'components/AgentScreenComponents/_form/FormBuilderCheckboxReverse';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import MdxEditorField from 'components/MdxEditorField/MdxEditorField';
import {getCustomButtonsByType} from 'components/Forms/GeneralTemplateForm/helper';

interface GeneralTemplateFormType {
    language: number;
    item: GeneralTemplateTypes;
    closeModal: () => void;
}

const GeneralTemplateForm: FC<GeneralTemplateFormType> = ({item, language, closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        instance: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const updateTemplate = useCallback(
        payload => dispatch({type: GENERAL_TEMPLATE_UPDATE.REQUEST, payload}),
        [dispatch],
    );

    const {type, subject, text, isActive} = item;

    return (
        <Formik
            initialValues={{
                type,
                language,
                subject,
                text,
                isActive,
            }}
            validationSchema={validationSchema}
            onSubmit={values => {
                updateTemplate({
                    ...values,
                    callback: closeModal,
                    showAlert,
                });
            }}
        >
            {(formik: FormikProps<GeneralTemplateFormTypes>) => {
                return (
                    <Form>
                        <Box sx={{mb: 1}}>
                            <Field
                                required
                                name="subject"
                                placeholder={t('superadmin.mailer.subject')}
                                component={FormikTextInput}
                                fullwidth
                            />
                        </Box>
                        <MdxEditorField
                            name="text"
                            value={formik.values.text ? String(formik.values.text) : ''}
                            label={t('superadmin.mailer.templateText')}
                            customButtons={getCustomButtonsByType(type)}
                        />
                        <Box sx={{mt: 2, mb: 3}}>
                            <Field
                                type="checkbox"
                                name="isActive"
                                label={t('superadmin.mailer.isActive')}
                                component={FormBuilderCheckboxReverse}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <AgentSaveButton isLoading={isLoading} isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default GeneralTemplateForm;
