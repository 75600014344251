import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Markdown from 'react-markdown';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';

import LoginPageWrapper from 'pages/start/login/partials/LoginPageWrapper';

const ImprintPage: FC = () => {
    const [t] = useTranslation();

    const {
        auth: {organization},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    if (!organization) return null;

    const {imprint} = organization;

    return (
        <LoginPageWrapper>
            {imprint && (
                <Box sx={{mb: 2}}>
                    <Typography sx={{fontSize: 18, fontWeight: 600, mt: 1, mb: 0.5, textAlign: 'center'}}>
                        {t('common.imprint')}
                    </Typography>
                    <Markdown>{imprint}</Markdown>
                </Box>
            )}
        </LoginPageWrapper>
    );
};

export default ImprintPage;
