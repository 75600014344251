import {theme} from 'config/theme';

export const getTagColor = (backgroundColor: string) => {
    return isDarkColor(backgroundColor) ? theme.palette.info.main : theme.palette.background.paper;
};

export const isDarkColor = (backgroundColor: string): boolean => {
    const hex = backgroundColor.replace('#', '');
    const red = parseInt(hex.substr(0, 2), 16);
    const green = parseInt(hex.substr(2, 2), 16);
    const blue = parseInt(hex.substr(4, 2), 16);
    const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
    return brightness > 155;
};
