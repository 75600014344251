import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {GridColDef} from '@mui/x-data-grid';

import {ORGANIZATION_INTEGRATION_TOKENS_LIST} from 'appRedux/actions/organizationIntegrationTokens';
import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';
import {PermissionContext} from 'contexts/permission/context';
import {AlertContext} from 'contexts/alert/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';
import DataGridWrapper from 'components/AdminScreenComponents/DataGridWrapper';
import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';

import AddOrganizationToken from 'pages/admin/organizationIntegrationTokens/AddOrganizationToken';
import DeleteOrganizationTokenButton from 'pages/admin/organizationIntegrationTokens/DeleteOrganizationTokenButton';
import EditOrganizationTokenButton from 'pages/admin/organizationIntegrationTokens/EditOrganizationTokenButton';
import CopyTokenButton from 'pages/admin/organizationIntegrationTokens/CopyTokenButton';

import {DATE_TIME_FORMAT} from 'config/index';

const OrganizationIntegrationTokens: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {myOrganization} = useContext(AdminContext);
    const {isOrganizationEditEnabled} = useContext(PermissionContext);
    const {showAlert} = useContext(AlertContext);

    const getOrganizationTokensList = useCallback(
        data => dispatch({type: ORGANIZATION_INTEGRATION_TOKENS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {organizationIntegrationTokens},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    if (!myOrganization) return null;

    const columns: GridColDef[] = [
        {
            field: 'title',
            headerName: t('superadmin.organizations.organizationTokens.title'),
        },
        {
            field: 'agent',
            headerName: t('superadmin.organizations.organizationTokens.agent'),
        },
        {
            field: 'tokenHint',
            headerName: t('superadmin.organizations.organizationTokens.token'),
        },
        {
            field: 'createdAt',
            headerName: t('superadmin.organizations.organizationTokens.createdAt'),
            minWidth: 150,
            valueGetter: ({value}) => moment(value).format(DATE_TIME_FORMAT),
        },
        {
            field: 'actions',
            headerName: t('common.action'),
            width: 120,
            renderCell: ({row}) => {
                if (!myOrganization) return null;
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        <EditOrganizationTokenButton item={row} />
                        <DeleteOrganizationTokenButton item={row} />
                        {row.token && <CopyTokenButton item={row} />}
                    </Box>
                );
            },
        },
    ];

    useEffect(() => {
        if (myOrganization && profile) {
            getOrganizationTokensList({
                id: myOrganization.id,
                showAlert,
            });
        }
    }, []);

    return (
        <>
            {isOrganizationEditEnabled && myOrganization.isIntegrationFormEnabled && (
                <AddOrganizationToken organizationId={myOrganization.id} />
            )}
            <ClientSectionWrapper>
                {!myOrganization.isIntegrationFormEnabled ? (
                    <Typography>{t('messages.error.optionImpossibleForOrganization')}</Typography>
                ) : (
                    <DataGridWrapper
                        itemsList={organizationIntegrationTokens}
                        itemsTotalNumber={organizationIntegrationTokens ? organizationIntegrationTokens.length : 0}
                    >
                        {organizationIntegrationTokens && (
                            <CustomDataGrid
                                rows={organizationIntegrationTokens}
                                columns={columns}
                                getRowId={row => row.uuid}
                                checkboxSelection={false}
                                enablePaging
                                isLarge
                            />
                        )}
                    </DataGridWrapper>
                )}
            </ClientSectionWrapper>
        </>
    );
};

export default OrganizationIntegrationTokens;
