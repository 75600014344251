import React, {FC} from 'react';
import get from 'lodash/get';
import {useSelector} from 'react-redux';

import {RootReducer} from 'appRedux/reducers';

import {DEFAULT_AVATAR_TYPE} from 'components/UserAvatar/helper';
import AvatarImage from 'components/UserAvatar/AvatarImage';
import {getAvatarTitle} from 'components/TopBarMenu/helper';

interface CurrentUserAvatarType {
    size: number;
}

const CurrentUserAvatar: FC<CurrentUserAvatarType> = ({size}) => {
    const {
        profile: {profile, avatarLink},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const avatarType = get(profile, 'avatarType', DEFAULT_AVATAR_TYPE);
    const firstName = get(profile, 'firstName', null);
    const lastName = get(profile, 'lastName', null);
    const email = get(profile, 'email', null);

    return (
        <AvatarImage
            size={size}
            avatarType={Number(avatarType)}
            avatarLink={avatarLink}
            avatarTitle={getAvatarTitle(firstName, lastName, email)}
        />
    );
};

export default CurrentUserAvatar;
