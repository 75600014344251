import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const NotificationSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.3535 9.6465L13 8.29295V6.5C12.9984 5.26098 12.5374 4.06655 11.7062 3.1477C10.875 2.22884 9.73266 1.65082 8.5 1.5254V0.5H7.5V1.5254C6.26734 1.65082 5.12496 2.22884 4.29377 3.1477C3.46257 4.06655 3.0016 5.26098 3 6.5V8.29295L1.64645 9.6465C1.55269 9.74025 1.50002 9.86741 1.5 10V11.5C1.5 11.6326 1.55268 11.7598 1.64645 11.8536C1.74021 11.9473 1.86739 12 2 12H5.5V12.3884C5.48916 13.0227 5.71276 13.6387 6.12794 14.1183C6.54313 14.598 7.1207 14.9076 7.75 14.9878C8.09757 15.0222 8.44852 14.9836 8.78026 14.8743C9.112 14.765 9.4172 14.5875 9.67622 14.3532C9.93524 14.1189 10.1423 13.8329 10.2842 13.5138C10.4261 13.1946 10.4996 12.8493 10.5 12.5V12H14C14.1326 12 14.2598 11.9473 14.3536 11.8536C14.4473 11.7598 14.5 11.6326 14.5 11.5V10C14.5 9.86741 14.4473 9.74025 14.3535 9.6465ZM9.5 12.5C9.5 12.8978 9.34196 13.2794 9.06066 13.5607C8.77936 13.842 8.39782 14 8 14C7.60218 14 7.22064 13.842 6.93934 13.5607C6.65804 13.2794 6.5 12.8978 6.5 12.5V12H9.5V12.5ZM13.5 11H2.5V10.2071L3.8535 8.8535C3.94727 8.75975 3.99997 8.6326 4 8.5V6.5C4 5.43913 4.42143 4.42172 5.17157 3.67157C5.92172 2.92143 6.93913 2.5 8 2.5C9.06087 2.5 10.0783 2.92143 10.8284 3.67157C11.5786 4.42172 12 5.43913 12 6.5V8.5C12 8.6326 12.0527 8.75975 12.1465 8.8535L13.5 10.2071V11Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default NotificationSvgIcon;
