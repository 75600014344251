import {TFunction} from 'react-i18next';

export const getRequesterCaseTitle = (t: TFunction, userName: string | null, userEmail: string | null): string => {
    if (userName) {
        return `${userName} ${t('orguser.requesterCase.case')}`;
    }
    if (userEmail) {
        return `${userEmail} ${t('orguser.requesterCase.case')}`;
    }
    return t('orguser.requesterCase.requesterInfo');
};
