import React, {FC} from 'react';
import {View, Text} from '@react-pdf/renderer';
import {useTranslation} from 'react-i18next';

import {pdfStyles} from 'pages/agent/pdfExport/pdfPartials/PdfDocumentStyles';

interface PageFooterType {
    text: string;
}

const PageFooter: FC<PageFooterType> = ({text}) => {
    const [t] = useTranslation();
    return (
        <View fixed style={pdfStyles.footer}>
            <Text style={pdfStyles.footerText}>{t(text.replace(/<[^>]*>/g, ''))}</Text>
        </View>
    );
};

export default PageFooter;
