import React, {FC, MouseEvent, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {get} from 'lodash';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Menu, MenuItem} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import {WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';
import {RootReducer} from 'appRedux/reducers';

import {getTagColor} from 'pages/admin/updateForm/partials/FormTags/helper';

import {theme} from 'config/theme';
import {routes} from 'config/index';

interface WorkflowStatusSelectType {
    currentWorkflowStatus: WorkflowStatusItemTypes;
    currentLanguage: string;
}

const WorkflowStatusSelect: FC<WorkflowStatusSelectType> = ({currentWorkflowStatus, currentLanguage}) => {
    const navigate = useNavigate();

    const {
        workflow: {workflowStatusesList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const getTranslation = (item: WorkflowStatusItemTypes) => {
        return (currentLanguage && get(item.translations, [currentLanguage, 'translation'], null)) || item.title;
    };

    return (
        <>
            {currentWorkflowStatus && (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            position: 'relative',
                            height: 40,
                            borderRadius: 20,
                            px: 2,
                            py: 1,
                            width: 200,
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: currentWorkflowStatus.color,
                            cursor: 'pointer',
                            color: getTagColor(currentWorkflowStatus.color),
                        }}
                        onClick={handleClick}
                    >
                        <Typography
                            sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: 200,
                                fontWeight: 600,
                                lineHeight: '22px',
                            }}
                        >
                            {getTranslation(currentWorkflowStatus)}
                        </Typography>
                        {open ? (
                            <ExpandLessIcon sx={{position: 'absolute', right: 10, top: 8}} />
                        ) : (
                            <ExpandMoreIcon sx={{position: 'absolute', right: 10, top: 8}} />
                        )}
                    </Box>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                mt: 1,
                                p: 1,
                                width: 200,
                                borderRadius: 2,
                                overflow: 'visible',
                                boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                                backgroundColor: theme.palette.background.default,
                                '& ul': {
                                    padding: 0,
                                },
                                overflowX: 'hidden',
                            },
                        }}
                        transformOrigin={{horizontal: 'left', vertical: 'top'}}
                        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                    >
                        {workflowStatusesList.map(item => {
                            return (
                                <MenuItem
                                    key={item.uuid}
                                    onClick={() => navigate(`${routes.WORKFLOW}/${item.uuid}/events`)}
                                >
                                    <Typography>{getTranslation(item)}</Typography>
                                </MenuItem>
                            );
                        })}
                    </Menu>
                </>
            )}
        </>
    );
};

export default WorkflowStatusSelect;
