import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import ExportSvgIcon from 'assets/icons/buttons/ExportSvgIcon';

import {REQUEST_CASE_EXPORT_MULTIPLE} from 'appRedux/actions/admin';

import {AlertContext} from 'contexts/alert/context';
import {AdminContext} from 'contexts/admin/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

const ExportCasesButton: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {selectedIds, setSelectedIds, setSelectAllFiltered} = useContext(AdminContext);
    const {showAlert} = useContext(AlertContext);

    const exportCases = useCallback(
        data => dispatch({type: REQUEST_CASE_EXPORT_MULTIPLE.REQUEST, payload: data}),
        [dispatch],
    );

    const downloadFile = (blob: Blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Export_${selectedIds.join(',')}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const onExportClicked = () => {
        exportCases({
            ids: selectedIds,
            showAlert,
            callback: () => {
                setSelectAllFiltered(false);
                setSelectedIds([]);
            },
            callbackDownload: downloadFile,
        });
    };

    return (
        <AgentSaveButton
            icon={<ExportSvgIcon isActive />}
            title={t('orguser.requesterCase.exportSelected')}
            isSubmit={false}
            onClick={onExportClicked}
            isBigButton
        />
    );
};

export default ExportCasesButton;
