import React from 'react';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import {RootReducer} from 'appRedux/reducers';

import LoginSso from 'pages/start/loginSso/LoginSso';
import LoginPageContent from 'pages/start/login/partials/LoginPageContent';
import {Navigate} from 'react-router-dom';
import {routes, userRoles} from 'config/index';

const LoginScreen = () => {
    const {
        auth: {organization},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isAutoSsoEnabled = get(organization, 'isAutoSsoEnabled', false);

    if (profile?.roles.includes(userRoles.ROLE_SUPER_ADMIN)) {
        <Navigate to={routes.SUPER_ADMIN} />;
    } else if (profile?.roles.includes(userRoles.ROLE_ADMIN)) {
        console.log('navigate ', routes.ADMIN);
        <Navigate to={routes.ADMIN} />;
    } else if (profile?.roles.includes(userRoles.ROLE_AGENT)) {
        <Navigate to={routes.AGENT} />;
    }

    if (isAutoSsoEnabled) {
        return <LoginSso />;
    }
    return <LoginPageContent />;
};

export default LoginScreen;
