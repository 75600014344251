import {
    OrganizationIntegrationTokenRequestTypes,
    OrganizationIntegrationTokenCreateRequestTypes,
    OrganizationIntegrationTokenUpdateRequestTypes,
    OrganizationIntegrationTokenDeleteRequestTypes,
    OrganizationIntegrationTokenTypes,
} from 'appRedux/actions/organizationIntegrationTokens/types';

import {requestTypeCreator} from 'helpers/index';

export const ORGANIZATION_INTEGRATION_TOKENS_LIST = requestTypeCreator('ORGANIZATION_INTEGRATION_TOKENS_LIST');
export const ORGANIZATION_INTEGRATION_TOKEN_CREATE = requestTypeCreator('ORGANIZATION_INTEGRATION_TOKEN_CREATE');
export const ORGANIZATION_INTEGRATION_TOKEN_UPDATE = requestTypeCreator('ORGANIZATION_INTEGRATION_TOKEN_UPDATE');
export const ORGANIZATION_INTEGRATION_TOKEN_DELETE = requestTypeCreator('ORGANIZATION_INTEGRATION_TOKEN_DELETE');

export const getOrganizationIntegrationTokensList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_INTEGRATION_TOKENS_LIST.ERROR,
    }),
    request: (
        payload: OrganizationIntegrationTokenRequestTypes,
    ): IActionType<OrganizationIntegrationTokenRequestTypes> => ({
        payload,
        type: ORGANIZATION_INTEGRATION_TOKENS_LIST.REQUEST,
    }),
    success: (payload: OrganizationIntegrationTokenTypes[]): IActionType<OrganizationIntegrationTokenTypes[]> => ({
        payload,
        type: ORGANIZATION_INTEGRATION_TOKENS_LIST.SUCCESS,
    }),
};

export const createOrganizationIntegrationToken = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_INTEGRATION_TOKEN_CREATE.ERROR,
    }),
    request: (
        payload: OrganizationIntegrationTokenCreateRequestTypes,
    ): IActionType<OrganizationIntegrationTokenCreateRequestTypes> => ({
        payload,
        type: ORGANIZATION_INTEGRATION_TOKEN_CREATE.REQUEST,
    }),
    success: (payload: OrganizationIntegrationTokenTypes[]): IActionType<OrganizationIntegrationTokenTypes[]> => ({
        payload,
        type: ORGANIZATION_INTEGRATION_TOKEN_CREATE.SUCCESS,
    }),
};

export const updateOrganizationIntegrationToken = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_INTEGRATION_TOKEN_UPDATE.ERROR,
    }),
    request: (
        payload: OrganizationIntegrationTokenUpdateRequestTypes,
    ): IActionType<OrganizationIntegrationTokenUpdateRequestTypes> => ({
        payload,
        type: ORGANIZATION_INTEGRATION_TOKEN_UPDATE.REQUEST,
    }),
    success: (payload: OrganizationIntegrationTokenTypes[]): IActionType<OrganizationIntegrationTokenTypes[]> => ({
        payload,
        type: ORGANIZATION_INTEGRATION_TOKEN_UPDATE.SUCCESS,
    }),
};

export const deleteOrganizationIntegrationToken = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: ORGANIZATION_INTEGRATION_TOKEN_DELETE.ERROR,
    }),
    request: (
        payload: OrganizationIntegrationTokenDeleteRequestTypes,
    ): IActionType<OrganizationIntegrationTokenDeleteRequestTypes> => ({
        payload,
        type: ORGANIZATION_INTEGRATION_TOKEN_DELETE.REQUEST,
    }),
    success: (payload: OrganizationIntegrationTokenTypes[]): IActionType<OrganizationIntegrationTokenTypes[]> => ({
        payload,
        type: ORGANIZATION_INTEGRATION_TOKEN_DELETE.SUCCESS,
    }),
};
