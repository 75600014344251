import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import {RootReducer} from 'appRedux/reducers';
import {RequesterCaseCommentsTypes} from 'appRedux/actions/comments/types';

import CommentEncryptRow from 'pages/client/form/comments/CommentEncryptRow';
import CommentRow from 'pages/client/form/comments/CommentRow';

interface CommentsToSectionType {
    sectionId: number;
}

const CommentsToSection: FC<CommentsToSectionType> = ({sectionId}) => {
    const [t] = useTranslation();

    const {
        requestCase: {
            currentCase: {comments},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const commentsBySection = comments.filter(item => item.section === sectionId);

    if (commentsBySection.length > 0) {
        return (
            <Box sx={{mt: 2}}>
                <Divider />
                <Typography sx={{fontWeight: 600, mt: 2, mb: 2}}>{`${t(
                    'orguser.requesterCase.comments.comments',
                )}:`}</Typography>
                {commentsBySection &&
                    commentsBySection.map((item: RequesterCaseCommentsTypes, index: number) => {
                        if (item.isEncryptInfo) {
                            return <CommentEncryptRow item={item} key={`comment-encrypt-${index}`} />;
                        }
                        return <CommentRow item={item} key={`comment-${index}`} />;
                    })}
            </Box>
        );
    }
    return null;
};

export default CommentsToSection;
