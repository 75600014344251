import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import IconButton from '@mui/material/IconButton';

import DeleteSvgIcon from 'assets/icons/buttons/DeleteSvgIcon';

import {OrganizationIntegrationTokenTypes} from 'appRedux/actions/organizationIntegrationTokens/types';
import {ORGANIZATION_INTEGRATION_TOKEN_DELETE} from 'appRedux/actions/organizationIntegrationTokens';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationModal from 'components/AdminScreenComponents/DeleteAdminConfirmationModal';

interface DeleteOrganizationTokenButtonType {
    item: OrganizationIntegrationTokenTypes;
}

const DeleteOrganizationTokenButton: FC<DeleteOrganizationTokenButtonType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const deleteOrganizationIntegrationToken = useCallback(
        data => dispatch({type: ORGANIZATION_INTEGRATION_TOKEN_DELETE.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickDelete = () => {
        deleteOrganizationIntegrationToken({
            uuid: item.uuid,
            showAlert,
            callback: () => setIsOpened(false),
        });
    };

    return (
        <>
            <IconButton onClick={toggleIsOpened} title={t('common.buttons.delete')}>
                <DeleteSvgIcon />
            </IconButton>
            <DeleteAdminConfirmationModal
                toggleModal={toggleIsOpened}
                isShowModal={isOpened}
                onClickAction={onClickDelete}
            />
        </>
    );
};

export default DeleteOrganizationTokenButton;
