import React, {FC, useCallback, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {CREATE_FORM_VERSION} from 'appRedux/actions/forms';
import {RootReducer} from 'appRedux/reducers';
import {FormVersionTypes} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';

import {routes} from 'config/index';

interface CreateNewVersionButtonType {
    formId: number;
}

const CreateNewVersionButton: FC<CreateNewVersionButtonType> = ({formId}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {showAlert} = useContext(AlertContext);
    const {isFormEditEnabled} = useContext(PermissionContext);

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    const {
        admin: {formInfo},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const formVersions: FormVersionTypes[] = get(formInfo, 'versions', []);

    const createFormVersion = useCallback(
        data => dispatch({type: CREATE_FORM_VERSION.REQUEST, payload: data}),
        [dispatch],
    );

    const onCreateNewVersion = () => {
        createFormVersion({
            id: formId,
            showAlert,
            callback: (uuid: string) => {
                navigate(`${routes.FORM}/${uuid}/version`);
            },
        });
    };

    const isVersionCreationPossible =
        formVersions.length > 0 ? formVersions[0].isActive && formVersions[0].releasedAt : true;

    if (!isFormEditEnabled) return null;
    return (
        <>
            <AgentSaveButton
                isSubmit={false}
                title={'orguser.forms.createNewVersion'}
                onClick={isVersionCreationPossible ? onCreateNewVersion : toggleModal}
            />
            <ModalWrapper isShowModal={showModal} title={t('orguser.forms.createNewVersion')} toggleModal={toggleModal}>
                <Alert severity={'error'}>
                    <Typography>{t('orguser.forms.createNewVersionAlert')}</Typography>
                </Alert>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt: 2,
                    }}
                >
                    <AgentSaveButton isSubmit={false} title={'common.buttons.close'} onClick={toggleModal} />
                </Box>
            </ModalWrapper>
        </>
    );
};

export default CreateNewVersionButton;
