import * as yup from 'yup';

import {REGEX_FOR_PASSWORD} from 'components/RegistrationForm/helper';

import {MINIMAL_PASSWORD_LENGTH, MAXIMAL_PASSWORD_LENGTH, getPasswordLengthErrorMessage} from 'helpers/security';

export const initialValues = {
    plainPassword: {
        first: '',
        second: '',
    },
    token: '',
};

export const validationSchema = (t: (trans: string) => string) =>
    yup.object({
        plainPassword: yup.object({
            first: yup
                .string()
                .min(
                    MINIMAL_PASSWORD_LENGTH,
                    getPasswordLengthErrorMessage(t, 'messages.validation.passwordMinLength', MINIMAL_PASSWORD_LENGTH),
                )
                .max(
                    MAXIMAL_PASSWORD_LENGTH,
                    getPasswordLengthErrorMessage(t, 'messages.validation.passwordMaxLength', MAXIMAL_PASSWORD_LENGTH),
                )
                .required(t('messages.validation.passwordIsRequired'))
                .matches(REGEX_FOR_PASSWORD, t('messages.validation.passwordShouldContain')),
            second: yup
                .string()
                .min(
                    MINIMAL_PASSWORD_LENGTH,
                    getPasswordLengthErrorMessage(t, 'messages.validation.passwordMinLength', MINIMAL_PASSWORD_LENGTH),
                )
                .max(
                    MAXIMAL_PASSWORD_LENGTH,
                    getPasswordLengthErrorMessage(t, 'messages.validation.passwordMaxLength', MAXIMAL_PASSWORD_LENGTH),
                )
                .required(t('messages.validation.passwordIsRequired'))
                .matches(REGEX_FOR_PASSWORD, t('messages.validation.passwordShouldContain'))
                .oneOf([yup.ref('first'), null], t('messages.validation.passwordShouldMatch')),
        }),
    });
