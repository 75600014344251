import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import Grid from '@mui/material/Grid';

import {RootReducer} from 'appRedux/reducers';
import {UPDATE_ORGANIZATION} from 'appRedux/actions/organization';
import {CreateOrganizationRequestTypes} from 'appRedux/actions/organization/types';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import UpdateOrganizationForm from 'components/Forms/OrganizationForm/UpdateOrganizationForm';
import {createOrganizationInitialValues} from 'components/Forms/OrganizationForm/validation';
import OrganizationTeam from 'components/OrganizationAdminsList/OrganizationTeam';
import OrganizationMediaForms from 'components/Forms/OrganizationForm/OrganizationMediaForms';
import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';
import AddUserDropdown from 'components/OrganizationAdminsList/AddUserDropdown';

import OrganizationTwoFactorAuthSwitcher from 'pages/admin/updateOrganization/partials/OrganizationTwoFactorAuthSwitcher';
import OrganizationStatusSwitcher from 'pages/admin/updateOrganization/partials/OrganizationStatusSwitcher';
import OrganizationQuotas from 'pages/admin/updateOrganization/partials/OrganizationQuotas/OrganizationQuotas';
import OrganizationSsoCredentials from 'pages/admin/updateOrganization/partials/OrganizationSsoCredentials';
import OrganizationLanguages from 'pages/admin/updateOrganization/partials/OrganizationLanguages/OrganizationLanguages';

import {getLanguageById} from 'helpers/translationsHelper';

const UpdateOrganizationComponent: FC = () => {
    const [t] = useTranslation();
    const {id} = useParams();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isRoleViewEnabled} = useContext(PermissionContext);

    const {
        admin: {organizationList, languageList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const submitUpdateOrganization = useCallback(
        data => dispatch({type: UPDATE_ORGANIZATION.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: CreateOrganizationRequestTypes) => {
        submitUpdateOrganization({
            ...values,
            id,
            showAlert,
        });
    };

    const initialValues =
        id && organizationList
            ? {...createOrganizationInitialValues, ...organizationList.find(item => Number(item.id) === Number(id))}
            : createOrganizationInitialValues;

    const organizationId = get(initialValues, 'id', null);
    const isActive = get(initialValues, 'isActive', null);
    const isForcedTwoFactor = get(initialValues, 'isForcedTwoFactor', null);

    const currentOrganizationLanguage = getLanguageById(languageList, initialValues.language);

    if (!currentOrganizationLanguage) return null;

    return (
        <>
            <OrganizationStatusSwitcher id={Number(organizationId)} isActive={Boolean(isActive)} />
            <ClientSectionWrapper title={t('superadmin.organizations.configuration')}>
                <Grid container>
                    <Grid item sm={8}>
                        <UpdateOrganizationForm
                            organizationId={Number(id)}
                            initialValues={initialValues}
                            onSubmitClicked={onSubmitClicked}
                            currentOrganizationLanguage={currentOrganizationLanguage}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <OrganizationMediaForms id={Number(id)} organization={initialValues} />
                    </Grid>
                </Grid>
            </ClientSectionWrapper>
            <ClientSectionWrapper title={t('superadmin.organizations.organizationLanguages')}>
                <OrganizationLanguages id={Number(id)} organizationLanguage={currentOrganizationLanguage} />
            </ClientSectionWrapper>
            <ClientSectionWrapper title={t('superadmin.organizations.twoFactorAuthTitle')}>
                <OrganizationTwoFactorAuthSwitcher
                    id={Number(organizationId)}
                    isForcedTwoFactor={Boolean(isForcedTwoFactor)}
                />
            </ClientSectionWrapper>
            <ClientSectionWrapper title={t('superadmin.organizations.organizationQuotas')}>
                <OrganizationQuotas />
            </ClientSectionWrapper>
            {isRoleViewEnabled && (
                <ClientSectionWrapper title={t('superadmin.organizations.organizationSsoCredentials')}>
                    <OrganizationSsoCredentials organizationId={Number(id)} initialValues={initialValues} />
                </ClientSectionWrapper>
            )}
            <AddUserDropdown organizationId={Number(id)} />
            <OrganizationTeam organizationId={Number(id)} />
        </>
    );
};

export default UpdateOrganizationComponent;
