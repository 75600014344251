import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import get from 'lodash/get';
import {useParams} from 'react-router';

import Box from '@mui/material/Box';
import {GridColDef} from '@mui/x-data-grid';

import {RootReducer} from 'appRedux/reducers';
import {FormVersionTypes} from 'appRedux/actions/forms/types';

import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';

import getFormVersionsTableColumns from 'pages/admin/updateForm/partials/FormVersions/getFormVersionsTableColumns';
import CreateNewVersionButton from 'pages/admin/updateForm/partials/FormVersions/CreateNewVersionButton';

const FormVersionsList: FC = () => {
    const {id} = useParams();

    const {
        admin: {formInfo},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const formVersions: FormVersionTypes[] = get(formInfo, 'versions', []);

    const columns: GridColDef[] = getFormVersionsTableColumns();

    return (
        <Box>
            <CustomDataGrid
                rows={formVersions}
                columns={columns}
                getRowId={row => row.uuid}
                getRowClassName={({row}) => {
                    const {isActive, releasedAt} = row;
                    return isActive && releasedAt ? 'highlighted' : '';
                }}
                checkboxSelection={false}
                isLarge
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    mt: 2,
                }}
            >
                <CreateNewVersionButton formId={Number(id)} />
            </Box>
        </Box>
    );
};

export default FormVersionsList;
