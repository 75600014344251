import React, {FC} from 'react';

interface IconTypeSvgType {
    isActive?: boolean;
}

const IconType_03: FC<IconTypeSvgType> = ({isActive}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.8536 5.35358C13.7598 5.44735 13.6326 5.50003 13.5 5.50003H13V12H14V13H2V12H3V5.50003H2.5C2.36739 5.50003 2.24021 5.44735 2.14645 5.35358C2.05268 5.25982 2 5.13264 2 5.00003V3.50003C2.00004 3.39727 2.03175 3.29703 2.09079 3.21293C2.14984 3.12883 2.23336 3.06497 2.33 3.03003L7.83 1.03003C7.93985 0.990315 8.06015 0.990315 8.17 1.03003L13.67 3.03003C13.7666 3.06497 13.8502 3.12883 13.9092 3.21293C13.9683 3.29703 14 3.39727 14 3.50003V5.00003C14 5.13264 13.9473 5.25982 13.8536 5.35358ZM8 2.03003L3 3.85003V4.50003H13V3.85003L8 2.03003ZM7 5.50003V12H9V5.50003H7ZM6 5.50003H4V12H6V5.50003ZM10 12H12V5.50003H10V12ZM1 15.0001V14.0001H15V15.0001H1Z"
                fill={isActive ? '#ffffff' : '#222222'}
            />
        </svg>
    );
};

export default IconType_03;
