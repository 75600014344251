import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';

import {REMOVE_REQUESTER_CASE_COMMENT} from 'appRedux/actions/comments';

import {AlertContext} from 'contexts/alert/context';

import DeleteAdminConfirmationModal from 'components/ClientScreenComponents/DeleteClientConfirmationModal';

interface RemoveCommentType {
    uuid: string;
}

const RemoveComment: FC<RemoveCommentType> = ({uuid}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const removeComment = useCallback(
        data => dispatch({type: REMOVE_REQUESTER_CASE_COMMENT.REQUEST, payload: data}),
        [dispatch],
    );

    const onDeleteClick = () => {
        removeComment({
            uuid,
            callback: () => setIsOpened(false),
            showAlert,
        });
    };

    return (
        <>
            <Typography onClick={toggleIsOpened} sx={{cursor: 'pointer', fontWeight: 600, fontSize: 12}}>
                {t('common.buttons.delete')}
            </Typography>
            <DeleteAdminConfirmationModal
                isShowModal={isOpened}
                toggleModal={toggleIsOpened}
                onClickAction={onDeleteClick}
            />
        </>
    );
};

export default RemoveComment;
