import {MdxEditorCustomButtonType} from 'components/MdxEditorField/helper';

import {
    TYPE_AGENT_GET_CHAT_MESSAGE,
    TYPE_INVITE_FOR_REGISTRATION,
    TYPE_SUCCESSFULLY_SUBMITTED,
    TYPE_APPROVE_STATUS_CHANGING,
    TYPE_SUBFORM_PAGES_ATTACHED,
    TYPE_SUBFORM_PAGES_AUTO_APPENDING,
} from 'pages/admin/organizationTemplates/helper';

export const customButtonsAssignedToCase: MdxEditorCustomButtonType[] = [
    {
        title: 'orguser.mailer.buttons.username',
        markdown: '{{ username }}',
    },
    {
        title: 'orguser.mailer.buttons.caseLink',
        markdown: '{{ url }}',
    },
];

export const customButtonsInviteForRegistration: MdxEditorCustomButtonType[] = [
    {
        title: 'orguser.mailer.buttons.inviteLink',
        markdown: '{{ link }}',
    },
    {
        title: 'orguser.mailer.buttons.formTitle',
        markdown: '{{ form }}',
    },
];

export const customButtonsSuccessfullySubmitted: MdxEditorCustomButtonType[] = [
    {
        title: 'orguser.mailer.buttons.username',
        markdown: '{{ username }}',
    },
    {
        title: 'orguser.mailer.buttons.caseLink',
        markdown: '{{ url }}',
    },
    {
        title: 'orguser.mailer.buttons.formTitle',
        markdown: '{{ title }}',
    },
];

export const customButtonsSubformPagesAttached: MdxEditorCustomButtonType[] = [
    {
        title: 'orguser.mailer.buttons.username',
        markdown: '{{ username }}',
    },
    {
        title: 'orguser.mailer.buttons.caseLink',
        markdown: '{{ url }}',
    },
    {
        title: 'orguser.mailer.buttons.message',
        markdown: '{{ message }}',
    },
];

export const getCustomButtonsByType = (type: number): MdxEditorCustomButtonType[] => {
    switch (type) {
        case TYPE_AGENT_GET_CHAT_MESSAGE:
        case TYPE_APPROVE_STATUS_CHANGING:
        case TYPE_SUBFORM_PAGES_AUTO_APPENDING:
            return customButtonsAssignedToCase;
        case TYPE_INVITE_FOR_REGISTRATION:
            return customButtonsInviteForRegistration;
        case TYPE_SUCCESSFULLY_SUBMITTED:
            return customButtonsSuccessfullySubmitted;
        case TYPE_SUBFORM_PAGES_ATTACHED:
            return customButtonsSubformPagesAttached;
    }
    return [];
};
