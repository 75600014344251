import {WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';

import {MdxEditorCustomButtonType} from 'components/MdxEditorField/helper';

export const customButtons: MdxEditorCustomButtonType[] = [
    {
        title: 'orguser.requesterCase.name',
        markdown: '{{ username }}',
    },
    {
        title: 'orguser.requesterCase.caseLink',
        markdown: '{{ url }}',
    },
    {
        title: 'orguser.requesterCase.caseLabel',
        markdown: '{{ caseLabel }}',
    },
    {
        title: 'orguser.requesterCase.status',
        markdown: '{{ statusTitle }}',
    },
];

export const getCustomButtonsForWebhook = (
    statusesList: WorkflowStatusItemTypes[],
    statusUuid: string,
): MdxEditorCustomButtonType[] => {
    const results: MdxEditorCustomButtonType[] = [
        {
            title: 'orguser.requesterCase.name',
            markdown: '{{ username }}',
        },
        {
            title: 'orguser.requesterCase.caseLink',
            markdown: '{{ url }}',
        },
        {
            title: 'orguser.requesterCase.caseLabel',
            markdown: '{{ caseLabel }}',
        },
        {
            title: 'orguser.requesterCase.status',
            markdown: '{{ statusTitle }}',
        },
    ];
    const internalNameSubItems: MdxEditorCustomButtonType[] = [];

    const callbackPlaceholder = "{{ callback('%INTERNAL_NAME%') }}";
    statusesList.forEach(status => {
        const {title, internalName, uuid} = status;
        if (internalName && statusUuid !== uuid) {
            internalNameSubItems.push({
                title,
                markdown: callbackPlaceholder.replace('%INTERNAL_NAME%', internalName),
            });
        }
    });

    if (internalNameSubItems.length > 0) {
        results.push({
            title: 'orguser.requesterCase.statusCallback',
            markdown: '',
            items: internalNameSubItems,
        });
    }

    return results;
};
