import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FORM_FAQ_LIST} from 'appRedux/actions/forms';
import {FormFaqItemTypes} from 'appRedux/actions/forms/types';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';
import {AlertContext} from 'contexts/alert/context';

import TranslationSelector from 'pages/admin/updateForm/partials/FormFaq/TranslationSelector';
import FormFaqRow from 'pages/admin/updateForm/partials/FormFaq/FormFaqRow';
import AddFormFaqButton from 'pages/admin/updateForm/partials/FormFaq/AddFormFaqButton';

import {getLanguageIdByName} from 'helpers/translationsHelper';

interface FormFaqComponentType {
    organizationLanguage: LanguageItemTypes;
}

const FormFaqComponent: FC<FormFaqComponentType> = ({organizationLanguage}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {id} = useParams();

    const {myOrganization} = useContext(AdminContext);
    const {showAlert} = useContext(AlertContext);

    const [selectedLanguage, setSelectedLanguage] = useState<string>(organizationLanguage.name);
    const [dragItem, setDragItem] = useState<string>('');
    const [dropTo, setDropTo] = useState<number>(0);

    const getFormFaqInformation = useCallback(
        data => dispatch({type: FORM_FAQ_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {formFaq, languageList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        if (myOrganization) {
            getFormFaqInformation({
                id,
                language: selectedLanguage,
                showAlert,
            });
        }
    }, [id, selectedLanguage]);

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    mb: 1,
                }}
            >
                <TranslationSelector
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    organizationLanguage={organizationLanguage}
                />
            </Box>
            {formFaq.length > 0 ? (
                formFaq.map((item: FormFaqItemTypes) => {
                    return (
                        <FormFaqRow
                            item={item}
                            dragItem={dragItem}
                            setDragItem={setDragItem}
                            dropTo={dropTo}
                            setDropTo={setDropTo}
                            key={`form-faq-${item.uuid}`}
                        />
                    );
                })
            ) : (
                <Typography>{t('common.none')}</Typography>
            )}
            <AddFormFaqButton
                formId={Number(id)}
                languageId={getLanguageIdByName(languageList, selectedLanguage, organizationLanguage.id)}
            />
        </Box>
    );
};

export default FormFaqComponent;
