import React, {FC, useCallback, useContext} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {FORM_HAS_ADDITIONAL_APPEND} from 'appRedux/actions/forms';
import {
    FormHasAdditionalAppendFormTypes,
    FormWorkflowStatusTypes,
    FormHasAdditionalTypes,
} from 'appRedux/actions/forms/types';

import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import {preventSendForm} from 'helpers/index';

interface ComponentType {
    item: FormHasAdditionalTypes;
}

const FormHasAdditionalAppendForm: FC<ComponentType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {workflowStatus, uuid, isSubForm} = item;

    const {showAlert} = useContext(AlertContext);

    const {
        admin: {formInfo},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const submitForm = useCallback(
        data => dispatch({type: FORM_HAS_ADDITIONAL_APPEND.REQUEST, payload: data}),
        [dispatch],
    );

    const {statuses} = formInfo;

    if (!isSubForm) return null;

    return (
        <Formik
            initialValues={{
                workflowStatus,
            }}
            onSubmit={values => {
                submitForm({
                    uuid,
                    workflowStatus: values.workflowStatus === 0 ? null : values.workflowStatus,
                    showAlert,
                });
            }}
        >
            {(formik: FormikProps<FormHasAdditionalAppendFormTypes>) => {
                return (
                    <Form onKeyDown={preventSendForm}>
                        <FormControl required fullWidth>
                            <Select
                                name={'workflowStatus'}
                                value={formik.values.workflowStatus}
                                IconComponent={KeyboardArrowDownIcon}
                                onChange={e => {
                                    formik.handleChange(e);
                                    formik.submitForm();
                                }}
                                sx={{
                                    '& div': {
                                        display: 'flex',
                                    },
                                }}
                                fullWidth
                            >
                                <MenuItem key={`menuItem-${uuid}`} value={0}>
                                    <Typography>{t('common.none')}</Typography>
                                </MenuItem>
                                {statuses &&
                                    statuses.map((status: FormWorkflowStatusTypes) => {
                                        const {uuid, id, title, color} = status;
                                        return (
                                            <MenuItem
                                                key={`status-${uuid}`}
                                                value={id}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    pl: 1,
                                                }}
                                            >
                                                <TripOriginIcon sx={{color: color}} />
                                                <Typography sx={{ml: 1, fontWeight: 600}}>{title}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default FormHasAdditionalAppendForm;
