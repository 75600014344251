import React, {FC} from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import {
    buttonStyles,
    buttonOutlineStyles,
    labelStyles,
    labelOutlineStyles,
} from 'components/AgentScreenComponents/_buttons/common';

interface SubmitButtonType {
    isLoading?: boolean;
    title: string;
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
    open: boolean;
}

const AgentAddMultipleButton: FC<SubmitButtonType> = ({isLoading, open, title, onClick}) => {
    return (
        <Button
            disabled={isLoading}
            sx={open ? buttonStyles : buttonOutlineStyles}
            onClick={onClick}
            endIcon={<PlusSvgIcon isActive={open} />}
        >
            <Typography sx={open ? labelStyles : labelOutlineStyles}>{title}</Typography>
        </Button>
    );
};

export default AgentAddMultipleButton;
