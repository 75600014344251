import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import CustomImage from 'components/CustomImage/CustomImage';

interface InstanceLogoType {
    imageSrc: string | null;
}

const InstanceLogo: FC<InstanceLogoType> = ({imageSrc}) => {
    const [t] = useTranslation();
    if (!imageSrc) {
        return <Typography>{t('common.none')}</Typography>;
    }
    return (
        <Box sx={{pt: 1, pb: 1}}>
            <CustomImage imageSrc={imageSrc} />
        </Box>
    );
};

export default InstanceLogo;
