import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import IconButton from '@mui/material/IconButton';

import SaveSvgIcon from 'assets/icons/buttons/SaveSvgIcon';

import {UPDATE_CLIENT_TRANSLATION} from 'appRedux/actions/clientTranslation';
import {ClientTranslationUpdatedType} from 'appRedux/actions/clientTranslation/types';

import {AlertContext} from 'contexts/alert/context';

import {theme} from 'config/theme';

interface SaveTranslationButtonType {
    clientTranslations?: ClientTranslationUpdatedType;
    setEditTranslation: (value: boolean) => void;
}

const SaveTranslationButton: FC<SaveTranslationButtonType> = ({clientTranslations, setEditTranslation}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {caseId} = useParams();

    const {showAlert} = useContext(AlertContext);

    const updateClientTranslations = useCallback(
        data => dispatch({type: UPDATE_CLIENT_TRANSLATION.REQUEST, payload: data}),
        [dispatch],
    );

    const onSaveClicked = () => {
        updateClientTranslations({
            id: caseId,
            showAlert,
            ...clientTranslations,
            callback: () => {
                setEditTranslation(false);
            },
        });
    };

    return (
        <IconButton
            title={t('common.buttons.update')}
            onClick={onSaveClicked}
            sx={{
                mr: 1,
                backgroundColor: theme.palette.info.main,
                '&:hover': {
                    backgroundColor: theme.palette.info.main,
                },
            }}
        >
            <SaveSvgIcon isActive />
        </IconButton>
    );
};

export default SaveTranslationButton;
