import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import queryString from 'query-string';
import get from 'lodash/get';
import {useLocation} from 'react-router-dom';
import {useSearchParams} from 'react-router-dom';

import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {OPEN_ID_REGISTRATION} from 'appRedux/actions/auth';
import {GET_USER_INFO} from 'appRedux/actions/profile';

import {AlertContext} from 'contexts/alert/context';
import {CryptoContext} from 'contexts/crypto/context';
import {MediaContext} from 'contexts/media/context';

import LoginPageWrapper from 'pages/start/login/partials/LoginPageWrapper';

import {routes, PARAMETER_ID_TOKEN} from 'config/index';

const RegistrationSso: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {hash} = useLocation();
    const [searchParams] = useSearchParams();

    const {showAlert} = useContext(AlertContext);
    const {regenerateKeysAfterLogin} = useContext(CryptoContext);
    const {setLanguageAfterLogin} = useContext(MediaContext);

    const parsedHash = queryString.parse(hash);

    const idToken = get(parsedHash, PARAMETER_ID_TOKEN, null);
    const form = searchParams.get('form');

    const openIdRegistration = useCallback(
        data => dispatch({type: OPEN_ID_REGISTRATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getUserInfo = useCallback(data => dispatch({type: GET_USER_INFO.REQUEST, payload: data}), [dispatch]);

    const {auth} = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isEncryptInfo = get(auth, ['form', 'isEncryptInfo'], false);

    const navigationCallback = (redirectToCase: number, redirectToForm: number) => {
        getUserInfo({
            callback: async (roles: string[], typeMultiRole: number, languageId: number) => {
                setLanguageAfterLogin(languageId);
                const redirectTo = isEncryptInfo
                    ? `${routes.REQUEST}/${redirectToCase}/keysGenerate/${redirectToForm}`
                    : `${routes.REQUEST}/${redirectToCase}/form/${redirectToForm}`;
                await regenerateKeysAfterLogin(redirectTo);
            },
        });
    };

    useEffect(() => {
        if (idToken && form) {
            openIdRegistration({
                showAlert,
                idToken,
                form,
                callbackRegistration: navigationCallback,
            });
        }
    }, [idToken, form]);

    return (
        <LoginPageWrapper>
            <Typography>{t('common.pleaseWait')}</Typography>
        </LoginPageWrapper>
    );
};

export default RegistrationSso;
