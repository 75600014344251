import React, {FC, useCallback, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {CREATE_WORKFLOW_STATUS} from 'appRedux/actions/workflow';
import {WorkflowStatusFormTypes} from 'appRedux/actions/workflow/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import WorkflowStatusForm from 'components/Forms/Workflow/workflowStatus/WorkflowStatusForm';

import {getLanguageIdByName} from 'helpers/translationsHelper';

import {DEFAULT_LANGUAGE_ID} from 'config/index';
import {TAG_COLOR_3} from 'config/theme';

interface CreateWorkflowStatusFormType {
    workflowUuid: string;
    currentLanguage: string;
    closeModal: () => void;
}

const CreateWorkflowStatusForm: FC<CreateWorkflowStatusFormType> = ({workflowUuid, currentLanguage, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        admin: {languageList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const createWorkflowStatus = useCallback(
        data => dispatch({type: CREATE_WORKFLOW_STATUS.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: WorkflowStatusFormTypes) => {
        createWorkflowStatus({
            ...values,
            uuid: workflowUuid,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <WorkflowStatusForm
            initialValues={{
                title: '',
                titleRequester: '',
                language: getLanguageIdByName(languageList, currentLanguage, DEFAULT_LANGUAGE_ID),
                color: TAG_COLOR_3,
                colorRequester: TAG_COLOR_3,
            }}
            onSubmitClicked={onSubmitClicked}
            isCreate
        />
    );
};

export default CreateWorkflowStatusForm;
