import React, {FC, useEffect, useRef} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import {CommentFormType} from 'appRedux/actions/comments/types';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import CommentTestField from 'components/Forms/CommentForm/CommentTextField';
import {commentValidation} from 'components/Forms/CommentForm/validation';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import FormBuilderCheckboxReverse from 'components/AgentScreenComponents/_form/FormBuilderCheckboxReverse';

interface ComponentType {
    initialValues: CommentFormType;
    handleSubmit: (values: CommentFormType) => void;
    closeForm: () => void;
    isModal?: boolean;
}

const CommentForm: FC<ComponentType> = ({initialValues, closeForm, handleSubmit, isModal}) => {
    const [t] = useTranslation();
    const validationSchema = () => commentValidation(t);

    const commentFieldInput = useRef<HTMLInputElement | null>(null);

    const onFocusOut = () => {
        if (!isModal && commentFieldInput && commentFieldInput.current && !commentFieldInput.current.value) {
            closeForm();
        }
    };

    useEffect(() => {
        if (commentFieldInput && commentFieldInput.current) {
            commentFieldInput.current.focus();
        }
    }, []);

    return (
        <Box sx={{width: '100%'}}>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {(formik: FormikProps<CommentFormType>) => {
                    return (
                        <Form>
                            <Field
                                inputRef={commentFieldInput}
                                required
                                name="text"
                                label={t('orguser.requesterCase.comments.comment')}
                                placeholder={t('orguser.requesterCase.comments.addComment')}
                                component={CommentTestField}
                                multiline
                                onBlur={onFocusOut}
                                rows={4}
                            />
                            {initialValues.section && (
                                <Box sx={{mt: 1}}>
                                    <Field
                                        type="checkbox"
                                        name="isPublic"
                                        label={t('orguser.requesterCase.comments.visibleForRequester')}
                                        component={FormBuilderCheckboxReverse}
                                    />
                                </Box>
                            )}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: isModal ? 'flex-end' : 'flex-start',
                                    alignItems: 'center',
                                    mt: 3,
                                }}
                            >
                                {!isModal && (
                                    <AgentInverseButton title={t('common.buttons.close')} onClick={closeForm} />
                                )}
                                <AgentSaveButton isSubmit />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default CommentForm;
