import {FilterOptionType} from 'components/AdminScreenComponents/types';

import {userRoles} from 'config/index';

export const getUserRolesForSelect = () => {
    const result: FilterOptionType[] = Object.values(userRoles).map((item: string) => {
        return {
            name: item,
            label: item,
        };
    });
    return result;
};
