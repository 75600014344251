import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';
import {useSearchParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RuleTypes} from 'appRedux/actions/rules/types';
import {GET_RULES_LIST} from 'appRedux/actions/rules';
import {RootReducer} from 'appRedux/reducers';

import {RouteContext} from 'contexts/route/context';
import {AlertContext} from 'contexts/alert/context';
import {AdminContext} from 'contexts/admin/context';

import TableWrapper from 'components/AdminScreenComponents/TableWrapper';
import AddRuleButton from 'components/Rules/AddRuleButton';
import RulesTable from 'components/Rules/RulesTable';
import LanguageSelector from 'components/Rules/LanguageSelector';

import {getItemsPortionForPage} from 'pages/admin/formsList/helper';

import {getLanguageIdByName} from 'helpers/translationsHelper';

import {
    DEFAULT_LANGUAGE,
    DEFAULT_LANGUAGE_ID,
    ITEMS_PER_PAGE_OPTIONS_DEFAULT,
    RULE_TYPE_IMPRINT,
    ITEMS_PER_PAGE_FOR_CASES,
    PARAMETER_PER_PAGE,
    PARAMETER_LANGUAGE,
} from 'config/index';

interface RulesListType {
    type: number;
}

const RulesList: FC<RulesListType> = ({type}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const getImprintsList = useCallback(payload => dispatch({type: GET_RULES_LIST.REQUEST, payload}), [dispatch]);

    const {showAlert} = useContext(AlertContext);
    const {itemsPerPage, currentPage} = useContext(AdminContext);
    const {setPageTitle, setTopBarTitle, setBackLink, setBackTab, setBackLinkLabel} = useContext(RouteContext);

    const {
        admin: {rules, languageList},
        profile,
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const itemsPortion: RuleTypes[] = rules
        ? (getItemsPortionForPage(rules, currentPage, itemsPerPage) as RuleTypes[])
        : [];

    const lastVersionText = rules && rules.length > 0 ? rules[0].text : '';

    const itemsTotalNumber = rules ? rules.length : 0;

    const currentLanguage = get(profile, ['profile', 'lang'], DEFAULT_LANGUAGE);
    const languageFromUrl = searchParams.get(PARAMETER_LANGUAGE) ?? currentLanguage;
    const languageId = getLanguageIdByName(languageList, languageFromUrl, DEFAULT_LANGUAGE_ID);

    useEffect(() => {
        setTopBarTitle('');
        setPageTitle(t(type === RULE_TYPE_IMPRINT ? 'superadmin.rules.imprint' : 'superadmin.rules.privacyPolicy'));
        setBackLink('');
        setBackTab('');
        setBackLinkLabel('');
    }, [type]);

    useEffect(() => {
        getImprintsList({
            type,
            language: languageId,
            showAlert,
        });
        searchParams.set(PARAMETER_PER_PAGE, String(ITEMS_PER_PAGE_FOR_CASES));
        setSearchParams(searchParams);
    }, [type, languageId]);

    return (
        <TableWrapper
            itemsList={rules}
            itemsTotalNumber={itemsTotalNumber}
            itemsPerPageOptions={ITEMS_PER_PAGE_OPTIONS_DEFAULT}
            withoutFiltersPanel
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    mt: 1,
                    mb: 3,
                }}
            >
                <AddRuleButton type={type} text={lastVersionText} language={languageId} />
                <LanguageSelector languageFromUrl={languageFromUrl} />
            </Box>
            {itemsTotalNumber > 0 ? (
                <RulesTable rules={itemsPortion} itemsTotalNumber={itemsTotalNumber} />
            ) : (
                <Typography sx={{mb: 1, ml: 1}}>{t('superadmin.rules.noneInformationInDatabase')}</Typography>
            )}
        </TableWrapper>
    );
};

export default RulesList;
