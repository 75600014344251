import React, {FC, useContext} from 'react';
import {View, Text} from '@react-pdf/renderer';
import get from 'lodash/get';

import {FieldOptionType, FormFieldTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseItemType} from 'appRedux/actions/requestCase/types';

import {pdfStyles} from 'pages/agent/pdfExport/pdfPartials/PdfDocumentStyles';
import {OPTIONS_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';
import {ImageComponent} from 'pages/agent/pdfExport/pdfPartials/ImagePdfComponent';
import {PdfDocumentContext} from 'pages/agent/pdfExport/pdfPartials/pdfDocumentContext';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {EMPTY_DATA} from 'config/index';

interface ImgSelectFieldPdfContentType {
    formId: number;
    pageId: number;
    sectionId: number;
    field: FormFieldTypes;
    result: RequesterCaseItemType;
}

const ImgSelectFieldPdfContent: FC<ImgSelectFieldPdfContentType> = ({formId, pageId, sectionId, field, result}) => {
    const {translations} = useContext(PdfDocumentContext);

    const {label, id} = field;

    const keyword = getFieldKeyword(formId, id, 'label');

    const pageKeyword = `page-${pageId}`;
    const sectionKeyword = `section-${sectionId}`;
    const fieldKeyword = `field-${id}`;

    const selectedIds = get(result, [pageKeyword, sectionKeyword, fieldKeyword], '').split(OPTIONS_SEPARATOR);

    const selectedFiles = (field.fieldOptions || [])?.filter((file: FieldOptionType) =>
        selectedIds.includes(file?.uuid),
    );

    return (
        <View style={pdfStyles.field}>
            <View style={pdfStyles.fieldTitle}>
                <Text>{`${getFormTranslatedLabel(translations, keyword, label)}:`}</Text>
            </View>

            <View style={pdfStyles.fieldValue}>
                {selectedFiles.length ? (
                    selectedFiles.map(file => {
                        return (
                            <View style={pdfStyles.fieldImage} key={file.uuid}>
                                <ImageComponent src={file.fileLink || ''} />
                                <Text>{file.title}</Text>
                            </View>
                        );
                    })
                ) : (
                    <Text>{EMPTY_DATA}</Text>
                )}
            </View>
        </View>
    );
};

export default ImgSelectFieldPdfContent;
