import React from 'react';

export type ClientFormContextType = {
    errorField: number | null;
    setErrorField: (value: number | null) => void;
    errorPopup: number | null;
    setErrorPopup: (value: number | null) => void;
    toggleSectionChecked: () => void;
};

export const ClientFormContext = React.createContext({} as ClientFormContextType);
