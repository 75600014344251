import React, {FC} from 'react';

interface HelpRequestSvgIconType {
    isActive?: boolean;
}

const HelpRequestSvgIcon: FC<HelpRequestSvgIconType> = ({isActive}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5 21V16.5H18V21C18 21.3978 17.842 21.7794 17.5607 22.0607C17.2794 22.342 16.8978 22.5 16.5 22.5H4.5C4.10218 22.5 3.72064 22.342 3.43934 22.0607C3.15804 21.7794 3 21.3978 3 21V3C3 2.60218 3.15804 2.22064 3.43934 1.93934C3.72064 1.65804 4.10218 1.5 4.5 1.5H12V3H4.5V21H16.5ZM19.68 1.84486L22.155 4.31986H22.1475C22.3674 4.54417 22.4905 4.84576 22.4905 5.15986C22.4905 5.47396 22.3674 5.77554 22.1475 5.99986L11.6475 16.4999H7.5V12.3449L18 1.84486C18.2243 1.62499 18.5259 1.50183 18.84 1.50183C19.1541 1.50183 19.4557 1.62499 19.68 1.84486ZM9 14.9999H11.025L18.1125 7.91986L16.08 5.88736L9 12.9749V14.9999ZM17.1375 4.82986L19.17 6.86236L20.8725 5.15986L18.84 3.12736L17.1375 4.82986Z"
                fill={isActive ? '#ffffff' : '#222222'}
                fillOpacity="0.9"
            />
        </svg>
    );
};

export default HelpRequestSvgIcon;
