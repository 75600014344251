import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {GridColDef} from '@mui/x-data-grid';

import {GET_RESOURCE_FIELD_CALENDAR_LIST} from 'appRedux/actions/resourceFields';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import DataGridWrapper from 'components/AdminScreenComponents/DataGridWrapper';
import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';

import getResourceFieldCalendarColumns from 'pages/admin/updateResourceField/calendar/getResourceFieldCalendarColumns';
import AddResourceFieldCalendar from 'pages/admin/updateResourceField/calendar/AddResourceFieldCalendar';

const ResourceFieldCalendarList: FC = () => {
    const dispatch = useDispatch();
    const {uuid} = useParams();

    const {showAlert} = useContext(AlertContext);

    const {
        resourceFields: {resourceFieldCalendarItems},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getResourceFieldCalendarList = useCallback(
        data => dispatch({type: GET_RESOURCE_FIELD_CALENDAR_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        getResourceFieldCalendarList({
            uuid,
            showAlert,
        });
    }, [uuid]);

    const columns: GridColDef[] | null = getResourceFieldCalendarColumns();

    return (
        <DataGridWrapper
            leftSideComponent={<AddResourceFieldCalendar />}
            itemsList={resourceFieldCalendarItems}
            itemsTotalNumber={resourceFieldCalendarItems ? resourceFieldCalendarItems.length : 0}
        >
            {resourceFieldCalendarItems && columns && (
                <CustomDataGrid
                    rows={resourceFieldCalendarItems}
                    columns={columns}
                    getRowId={row => row.uuid}
                    checkboxSelection={false}
                    enablePaging
                />
            )}
        </DataGridWrapper>
    );
};

export default ResourceFieldCalendarList;
