import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {RequesterCaseHasBudgetTypes} from 'appRedux/actions/requestCase/types';
import {FormFieldTypes, ResourceFieldBudgetInformationType} from 'appRedux/actions/forms/types';

import UpdateRequesterCaseBudgetForm from 'components/Forms/RequesterCaseBudgetForm/UpdateRequesterCaseBudgetForm';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import {TYPE_BUDGET_INPUT_CALENDAR} from 'components/Forms/FormBuilder/FieldForm/helper';

import {getBudgetAmountValue} from 'helpers/resourceFieldsHelper';

import {theme} from 'config/theme';
import {DATE_LABEL_FORMAT} from 'config/index';

interface UpdateReservedBudgetType {
    caseId: number;
    resourceFieldBudgetInformation: ResourceFieldBudgetInformationType;
    currentBudget: RequesterCaseHasBudgetTypes;
    field: FormFieldTypes;
}

const UpdateReservedBudget: FC<UpdateReservedBudgetType> = ({
    caseId,
    currentBudget,
    field,
    resourceFieldBudgetInformation,
}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpened(previous => !previous);
    };

    const {amount, startAt, endAt} = currentBudget;

    const amountValue = getBudgetAmountValue(resourceFieldBudgetInformation.type, amount);

    const isInputCalendarType = field.typeBudgetInput === TYPE_BUDGET_INPUT_CALENDAR;

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', mb: 1}}>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        mt: 1,
                        mb: isInputCalendarType ? 1 : 0,
                    }}
                >
                    <Typography sx={{fontWeight: 700, mr: 1}}>{`${t('orguser.resourceFields.amount')}:`}</Typography>
                    <Typography>{amountValue ? amountValue : t('common.none')}</Typography>
                </Box>
                {isInputCalendarType && startAt && endAt && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <Typography sx={{fontWeight: 700, mr: 1}}>{`${t('requester.casePage.dateRange')}:`}</Typography>
                        <Typography>{`${moment(startAt).format(DATE_LABEL_FORMAT)} - ${moment(endAt).format(
                            DATE_LABEL_FORMAT,
                        )}`}</Typography>
                    </Box>
                )}
            </Box>
            <IconButton
                sx={{
                    ml: 2,
                    backgroundColor: theme.palette.info.main,
                    '&:hover': {
                        backgroundColor: theme.palette.info.main,
                    },
                }}
                onClick={() => setIsOpened(true)}
                title={t('orguser.resourceFields.updateAmount')}
            >
                <EditSvgIcon isActive />
            </IconButton>
            <ModalWrapper
                title={t('orguser.resourceFields.updateAmount')}
                isShowModal={isOpened}
                toggleModal={toggleModal}
            >
                <UpdateRequesterCaseBudgetForm
                    caseId={caseId}
                    currentBudget={currentBudget}
                    field={field}
                    resourceFieldBudgetInformation={resourceFieldBudgetInformation}
                    closeModal={() => setIsOpened(false)}
                />
            </ModalWrapper>
        </Box>
    );
};

export default UpdateReservedBudget;
