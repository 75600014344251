import React from 'react';
import {GridColDef} from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {OrganizationQuotasItemBasic} from 'appRedux/actions/organization/types';

import {truncate} from 'pages/admin/formsList/helper';
import {
    TYPE_MAXIMAL_ACTIVE_FORMS_NUMBER,
    TYPE_MAXIMAL_ORGANIZATION_USERS_NUMBER,
    TYPE_MAXIMAL_REQUESTS_PER_MONTH_NUMBER,
} from 'pages/admin/updateOrganization/partials/OrganizationQuotas/helper';

const getFormsColumns = (): GridColDef[] => {
    const [t] = useTranslation();
    return [
        {
            field: 'name',
            headerName: t('superadmin.dashboard.title'),
            minWidth: 200,
            renderCell: ({value}) => (
                <Tooltip
                    title={t(String(value))}
                    arrow
                    componentsProps={{
                        tooltip: {
                            sx: {
                                backgroundColor: 'rgb(97, 97, 97)',
                            },
                        },
                    }}
                >
                    <Box>{truncate(value, 30)}</Box>
                </Tooltip>
            ),
        },
        {
            field: 'qtyOrgUsers',
            headerName: t('superadmin.dashboard.maxActiveUsers'),
            minWidth: 70,
            renderCell: ({row}) => {
                const {quotas, qtyOrgUsers} = row;
                const quota = quotas
                    ? quotas.find(
                          (item: OrganizationQuotasItemBasic) => item.type === TYPE_MAXIMAL_ORGANIZATION_USERS_NUMBER,
                      )
                    : null;
                return (
                    <Typography>{quota && quota.maxUserMonth ? quota.maxUserMonth : Number(qtyOrgUsers)}</Typography>
                );
            },
        },
        {
            field: 'quotaOrgUsers',
            headerName: t('superadmin.dashboard.quotaUsers'),
            minWidth: 70,
            renderCell: ({row}) => {
                const {quotas} = row;
                const quota = quotas
                    ? quotas.find(
                          (item: OrganizationQuotasItemBasic) => item.type === TYPE_MAXIMAL_ORGANIZATION_USERS_NUMBER,
                      )
                    : null;
                return <Typography>{quota ? quota.value : t('common.none')}</Typography>;
            },
        },
        {
            field: 'qtyActiveForms',
            headerName: t('superadmin.dashboard.maxActiveForms'),
            minWidth: 70,
            renderCell: ({row}) => {
                const {quotas, qtyActiveForms} = row;
                const quota = quotas
                    ? quotas.find((item: OrganizationQuotasItemBasic) => item.type === TYPE_MAXIMAL_ACTIVE_FORMS_NUMBER)
                    : null;
                return (
                    <Typography>{quota && quota.maxUserMonth ? quota.maxUserMonth : Number(qtyActiveForms)}</Typography>
                );
            },
        },
        {
            field: 'quotaActiveForms',
            headerName: t('superadmin.dashboard.quotaForms'),
            minWidth: 70,
            renderCell: ({row}) => {
                const {quotas} = row;
                const quota = quotas
                    ? quotas.find((item: OrganizationQuotasItemBasic) => item.type === TYPE_MAXIMAL_ACTIVE_FORMS_NUMBER)
                    : null;
                return <Typography>{quota ? quota.value : t('common.none')}</Typography>;
            },
        },
        {
            field: 'qtyRequesterCasesPerMonth',
            headerName: t('superadmin.dashboard.requests'),
            minWidth: 70,
            renderCell: ({row}) => {
                const {quotas, qtyRequesterCasesPerMonth} = row;
                const quota = quotas
                    ? quotas.find(
                          (item: OrganizationQuotasItemBasic) => item.type === TYPE_MAXIMAL_REQUESTS_PER_MONTH_NUMBER,
                      )
                    : null;
                return (
                    <Typography>
                        {quota && quota.maxUserMonth ? quota.maxUserMonth : Number(qtyRequesterCasesPerMonth)}
                    </Typography>
                );
            },
        },
        {
            field: 'quotaRequesterCasesPerMonth',
            headerName: t('superadmin.dashboard.quotaRequests'),
            minWidth: 70,
            renderCell: ({row}) => {
                const {quotas} = row;
                const quota = quotas
                    ? quotas.find(
                          (item: OrganizationQuotasItemBasic) => item.type === TYPE_MAXIMAL_REQUESTS_PER_MONTH_NUMBER,
                      )
                    : null;
                return <Typography>{quota ? quota.value : t('common.none')}</Typography>;
            },
        },
    ];
};

export default getFormsColumns;
