import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {FormVersionTypes} from 'appRedux/actions/forms/types';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import UpdateFormVersionForm from 'components/Forms/FormBuilder/FormVersion/UpdateFormVersionForm';

interface ReleaseVersionButtonType {
    item: FormVersionTypes;
}

const ReleaseVersionButton: FC<ReleaseVersionButtonType> = ({item}) => {
    const [t] = useTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpen(previous => !previous);
    };

    return (
        <>
            <AgentSaveButton title={t('common.buttons.release')} isSubmit={false} onClick={toggleModal} />
            <ModalWrapper isShowModal={isOpen} toggleModal={toggleModal} title={t('orguser.forms.describeChanges')}>
                <UpdateFormVersionForm item={item} closeModal={() => setIsOpen(false)} toRelease={true} />
            </ModalWrapper>
        </>
    );
};

export default ReleaseVersionButton;
