import React, {FC} from 'react';
import get from 'lodash/get';
import {FieldProps} from 'formik';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import {createStyles, WithStyles, withStyles} from '@material-ui/core';

import FormFieldErrorMessage from 'components/AdminScreenComponents/FormFieldErrorMessage';

const styles = () =>
    createStyles({
        input: {
            WebkitBoxShadow: `0 0 0 1000px rgba(0,0,0,0.001) inset`,
            '&:-webkit-autofill': {
                WebkitBoxShadow: `0 0 0 1000px rgba(0,0,0,0.001) inset`,
            },
        },
    });

interface FormikTextInputTypes extends WithStyles<typeof styles> {
    name: string;
    label?: string;
    placeholder?: string;
}

const FormBuilderTextInput: FC<FieldProps & FormikTextInputTypes> = ({
    classes,
    field,
    form: {touched, errors},
    name,
    ...props
}) => {
    const fieldName = name || field.name;
    const isTouched = get(touched, fieldName, false);
    const errorMessage = get(errors, fieldName, false);
    return (
        <Box sx={{width: '100%'}}>
            <FormControl fullWidth>
                <TextField
                    fullWidth
                    variant="standard"
                    margin="normal"
                    inputProps={{className: classes.input}}
                    id={name}
                    {...field}
                    {...props}
                />
            </FormControl>
            {isTouched && errorMessage && <FormFieldErrorMessage errorMessage={String(errorMessage)} />}
        </Box>
    );
};

export default withStyles(styles)(FormBuilderTextInput);
