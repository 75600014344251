import React, {FC} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

import {ResourceFieldBookframeFormTypes} from 'appRedux/actions/resourceFields/types';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationBookframeSchema} from 'components/Forms/ResourceField/validation';

import {resourceFieldWeekdayTypes, ResourceFieldOptionType} from 'components/Forms/ResourceField/helper';

interface ResourceFieldBookframeFormType {
    initialValues: ResourceFieldBookframeFormTypes;
    onSubmitClicked: (values: ResourceFieldBookframeFormTypes) => void;
}

const ResourceFieldBookframeForm: FC<ResourceFieldBookframeFormType> = ({initialValues, onSubmitClicked}) => {
    const [t] = useTranslation();

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationBookframeSchema}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<ResourceFieldBookframeFormTypes>) => {
                return (
                    <Form>
                        <FormControl sx={{mt: 1, mb: 1}} required fullWidth>
                            <InputLabel>{t('orguser.resourceFields.weekday')}</InputLabel>
                            <Select
                                label={t('orguser.resourceFields.weekday')}
                                name={'weekday'}
                                value={formik.values.weekday}
                                onChange={formik.handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                fullWidth
                            >
                                {resourceFieldWeekdayTypes &&
                                    resourceFieldWeekdayTypes.map((item: ResourceFieldOptionType) => {
                                        const {label, value} = item;
                                        return (
                                            <MenuItem key={`weekday-${value}`} value={value}>
                                                <Typography sx={{fontWeight: 600}}>{t(label)}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        <Field
                            required
                            name="startTime"
                            type="time"
                            placeholder={t('orguser.resourceFields.startTime')}
                            component={FormikTextInput}
                        />
                        <Field
                            required
                            name="endTime"
                            type="time"
                            placeholder={t('orguser.resourceFields.endTime')}
                            component={FormikTextInput}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 3,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ResourceFieldBookframeForm;
