import {ReactElement} from 'react';

import {theme} from 'config/theme';

import Avatar_01 from 'assets/avatars/Avatar_01';
import Avatar_02 from 'assets/avatars/Avatar_02';
import Avatar_03 from 'assets/avatars/Avatar_03';
import Avatar_04 from 'assets/avatars/Avatar_04';
import Avatar_05 from 'assets/avatars/Avatar_05';
import Avatar_06 from 'assets/avatars/Avatar_06';
import Avatar_07 from 'assets/avatars/Avatar_07';
import Avatar_08 from 'assets/avatars/Avatar_08';
import Avatar_09 from 'assets/avatars/Avatar_09';
import Avatar_10 from 'assets/avatars/Avatar_10';
import Avatar_11 from 'assets/avatars/Avatar_11';
import Avatar_12 from 'assets/avatars/Avatar_12';

export const DEFAULT_AVATAR_TYPE = 0;

export const buttonStyles = {
    fontWeight: 300,
    textTransform: 'none',
    color: theme.palette.info.main,
};

export interface StandardAvatarTypes {
    type: number;
    image: ReactElement;
}

export const standardAvatars: StandardAvatarTypes[] = [
    {type: 1, image: <Avatar_01 />},
    {type: 2, image: <Avatar_02 />},
    {type: 3, image: <Avatar_03 />},
    {type: 4, image: <Avatar_04 />},
    {type: 5, image: <Avatar_05 />},
    {type: 6, image: <Avatar_06 />},
    {type: 7, image: <Avatar_07 />},
    {type: 8, image: <Avatar_08 />},
    {type: 9, image: <Avatar_09 />},
    {type: 10, image: <Avatar_10 />},
    {type: 11, image: <Avatar_11 />},
    {type: 12, image: <Avatar_12 />},
];

export const getAvatarByType = (type: number): ReactElement | null => {
    const result = standardAvatars.find((item: StandardAvatarTypes) => item.type === type);
    if (result) {
        return result.image;
    }
    return null;
};
