import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const PlusSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.5 7.5V4H7.5V7.5H4V8.5H7.5V12H8.5V8.5H12V7.5H8.5Z" fill={getIconColor(isActive, isDisabled)} />
        </svg>
    );
};

export default PlusSvgIcon;
