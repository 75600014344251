import React, {FC, MouseEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';

import AgentSelectButton from 'components/AgentScreenComponents/_buttons/AgentSelectButton';

import {getLanguageById} from 'helpers/translationsHelper';

import {theme} from 'config/theme';
import {PARAMETER_LANGUAGE} from 'config/index';

interface TranslationSelectorType {
    languageName: string;
    languageId: number;
}

const TranslationSelector: FC<TranslationSelectorType> = ({languageName, languageId}) => {
    const [t] = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const {
        admin: {languageList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = value => {
        searchParams.set(PARAMETER_LANGUAGE, value);
        setSearchParams(searchParams);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const currentLanguage = getLanguageById(languageList, languageId);

    return (
        <>
            <AgentSelectButton
                title={`${t('orguser.forms.selectLanguage')}: ${currentLanguage.name}`}
                onClick={handleClick}
                open={open}
            />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        mt: 1,
                        pl: 2,
                        pr: 2,
                        pt: 1,
                        pb: 1,
                        width: 290,
                        borderRadius: 2,
                        overflow: 'visible',
                        boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                        backgroundColor: theme.palette.background.default,
                        '& ul': {
                            padding: 0,
                        },
                        overflowX: 'hidden',
                    },
                }}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            >
                {languageList &&
                    languageList.map((item: LanguageItemTypes) => {
                        const {lang, id, name, enabled} = item;
                        if (!enabled) return null;

                        return (
                            <MenuItem value={name} key={`current-language-${name}`} onClick={() => handleChange(name)}>
                                <Typography>{lang}</Typography>
                            </MenuItem>
                        );
                    })}
            </Menu>
        </>
    );
};

export default TranslationSelector;
