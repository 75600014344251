import React, {FC} from 'react';

const UploadSvgIcon: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 16 16" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 11H11.75C12.5518 10.995 13.3234 10.6937 13.9165 10.1541C14.5096 9.6145 14.8823 8.87472 14.9629 8.07697C15.0436 7.27922 14.8265 6.47982 14.3534 5.83246C13.8803 5.1851 13.1846 4.73547 12.4 4.57003C12.1841 3.56329 11.6296 2.66102 10.8288 2.01376C10.0281 1.36651 9.02962 1.01343 8 1.01343C6.97038 1.01343 5.97189 1.36651 5.17115 2.01376C4.37042 2.66102 3.81585 3.56329 3.6 4.57003C2.81544 4.73547 2.11973 5.1851 1.64662 5.83246C1.17352 6.47982 0.95642 7.27922 1.03706 8.07697C1.11771 8.87472 1.4904 9.6145 2.08349 10.1541C2.67658 10.6937 3.4482 10.995 4.25 11H4.5V10H4.25C3.65326 10.0239 3.07148 9.80974 2.63265 9.40466C2.19381 8.99958 1.93387 8.43677 1.91 7.84003C1.88613 7.24329 2.10029 6.66151 2.50537 6.22268C2.91045 5.78384 3.47326 5.5239 4.07 5.50003H4.5L4.51 5.09003C4.62093 4.24825 5.03406 3.4755 5.67245 2.91572C6.31084 2.35594 7.13094 2.0473 7.98 2.0473C8.82905 2.0473 9.64916 2.35594 10.2875 2.91572C10.9259 3.4755 11.3391 4.24825 11.45 5.09003L11.5 5.50003H11.93C12.5267 5.5239 13.0896 5.78384 13.4946 6.22268C13.8997 6.66151 14.1139 7.24329 14.09 7.84003C14.0661 8.43677 13.8062 8.99958 13.3674 9.40466C12.9285 9.80974 12.3467 10.0239 11.75 10H11.5V11ZM6.205 9.70503L5.5 9.00003L8 6.50003L10.5 9.00003L9.795 9.70503L8.5 8.41503V14.5H7.5V8.41503L6.205 9.70503Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default UploadSvgIcon;
