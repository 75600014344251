import React, {FC} from 'react';
import Markdown from 'react-markdown';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import {FormFaqItemTypes} from 'appRedux/actions/forms/types';

interface FormFaqItemRowType {
    item: FormFaqItemTypes;
}

const FormFaqItemRow: FC<FormFaqItemRowType> = ({item}) => {
    const {question, answer} = item;

    return (
        <Box sx={{mt: 3, mb: 3}}>
            <Box
                sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', mb: 1}}
            >
                <HelpOutlineIcon />
                <Typography sx={{fontSize: 16, fontWeight: 700, ml: 1}}>{question}</Typography>
            </Box>
            <Markdown>{answer}</Markdown>
            <Divider sx={{mt: 3, mb: 2}} />
        </Box>
    );
};

export default FormFaqItemRow;
