import React, {FC, useCallback, useContext} from 'react';
import {Formik, Form, Field, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {ForgetNicknameRequestTypes} from 'appRedux/actions/auth/types';
import {FORGET_NICKNAME} from 'appRedux/actions/auth';

import {AlertContext} from 'contexts/alert/context';

import LoadingStartButton from 'components/StartScreenComponents/LoadingStartButton';
import ClientFormikTextInput from 'components/StartScreenComponents/ClientFormTextInput';

import {forgetNicknameValidationSchema} from 'pages/start/forgetNickname/validation';

import {preventSendForm} from 'helpers/index';

const ForgetNicknameForm: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const forgetNicknameRequest = useCallback(
        data => dispatch({type: FORGET_NICKNAME.REQUEST, payload: data}),
        [dispatch],
    );

    return (
        <Box sx={{width: '100%'}}>
            <Typography align={'center'} sx={{mb: 2, fontWeight: 600}}>
                {t('common.resetNickname.remindMyNickname')}
            </Typography>
            <Formik
                initialValues={{
                    email: '',
                }}
                validationSchema={() => forgetNicknameValidationSchema(t)}
                onSubmit={(values, {resetForm}) => {
                    forgetNicknameRequest({
                        ...values,
                        showAlert,
                    });
                    resetForm();
                }}
            >
                {(formik: FormikProps<ForgetNicknameRequestTypes>) => {
                    return (
                        <Form onKeyDown={preventSendForm}>
                            <Box sx={{mb: 3}}>
                                <Field
                                    name="email"
                                    label={t('common.registrationForm.email')}
                                    placeholder={t('common.registrationForm.email')}
                                    component={ClientFormikTextInput}
                                />
                            </Box>
                            <LoadingStartButton label={t('common.resetPasswordForm.continue')} />
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default ForgetNicknameForm;
