import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const TasksAddSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" fill="white" fillOpacity="0.01" />
            <path
                d="M15.5 12H13.5V10H12.5V12H10.5V13H12.5V15H13.5V13H15.5V12Z"
                fill={getIconColor(isActive, isDisabled)}
            />
            <path
                d="M12.5 2.5H11V2C10.9992 1.73502 10.8936 1.48111 10.7063 1.29374C10.5189 1.10637 10.265 1.00077 10 1H6C5.73502 1.00077 5.48111 1.10637 5.29374 1.29374C5.10637 1.48111 5.00077 1.73502 5 2V2.5H3.5C3.23502 2.50077 2.98111 2.60637 2.79374 2.79374C2.60637 2.98111 2.50077 3.23502 2.5 3.5V14C2.50077 14.265 2.60637 14.5189 2.79374 14.7063C2.98111 14.8936 3.23502 14.9992 3.5 15H8.5V14H3.5V3.5H5V5H11V3.5H12.5V8H13.5V3.5C13.4992 3.23502 13.3936 2.98111 13.2063 2.79374C13.0189 2.60637 12.765 2.50077 12.5 2.5ZM10 4H6V2H10V4Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default TasksAddSvgIcon;
