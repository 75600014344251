import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Formik, FormikProps} from 'formik';

import Box from '@mui/material/Box';

import {UserHasRolesTypes, OrganizationAdminInviteTypes} from 'appRedux/actions/organizationAdmin/types';
import {RootReducer} from 'appRedux/reducers';
import {ORGANIZATION_ADMIN_INVITE} from 'appRedux/actions/organizationAdmin';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import RolesSelectors from 'components/Forms/OrganizationAdminForm/partials/RolesSelectors';
import EmailsList from 'components/Forms/OrganizationAdminForm/partials/EmailsList';
import OrganizationUsersNumberQuotaAlert from 'components/Forms/OrganizationAdminForm/partials/OrganizationUsersNumberQuotaAlert';

interface InviteOrganizationAdminFormType {
    organizationId: number;
    closeModal: () => void;
}

const InviteOrganizationAdminForm: FC<InviteOrganizationAdminFormType> = ({organizationId, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [emails, setEmails] = useState<string[]>([]);
    const [userHasRoles, setUserHasRoles] = useState<UserHasRolesTypes[]>([]);
    const [, setIsUpdated] = useState<boolean>(false);

    const toggleIsUpdated = () => {
        setIsUpdated(previous => !previous);
    };

    const {
        admin: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const inviteOrgUsers = useCallback(
        data => dispatch({type: ORGANIZATION_ADMIN_INVITE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = () => {
        inviteOrgUsers({
            id: organizationId,
            emails,
            userHasRoles,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <Formik
            initialValues={{
                emails,
            }}
            onSubmit={onSubmitClicked}
            enableReinitialize
        >
            {(formik: FormikProps<OrganizationAdminInviteTypes>) => {
                return (
                    <Form>
                        <OrganizationUsersNumberQuotaAlert />
                        <EmailsList emails={emails} setEmails={setEmails} toggleIsUpdated={toggleIsUpdated} />
                        <RolesSelectors
                            fieldName={'userHasRoles'}
                            userHasRoles={userHasRoles}
                            setUserHasRoles={setUserHasRoles}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton
                                isLoading={isLoading}
                                isDisabled={emails.length === 0}
                                isSubmit
                                title={'invite'}
                            />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default InviteOrganizationAdminForm;
