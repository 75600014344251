import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import PlusSvgIcon from 'assets/icons/buttons/PlusSvgIcon';

import {WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';

import WorkflowStatusTaskForm from 'components/Forms/Workflow/workflowStatusTask/WorkflowStatusTaskForm';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface RulesFromSwitcherType {
    item: WorkflowStatusItemTypes;
    isShowForm: boolean;
    setIsShowForm: (value: boolean) => void;
}

const RulesFromSwitcher: FC<RulesFromSwitcherType> = ({item, isShowForm, setIsShowForm}) => {
    const [t] = useTranslation();

    if (isShowForm) {
        return <WorkflowStatusTaskForm item={item} closeForm={() => setIsShowForm(false)} />;
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mb: 1,
                }}
            >
                <AgentSaveButton
                    isSubmit={false}
                    icon={<PlusSvgIcon isActive />}
                    title={t('orguser.workflows.tasks.addTask')}
                    onClick={() => setIsShowForm(true)}
                />
            </Box>
            <Divider sx={{mt: 2, mb: 2}} />
        </>
    );
};

export default RulesFromSwitcher;
