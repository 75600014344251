import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';

import Box from '@mui/material/Box';

import {OrganizationIntegrationTokenFormTypes} from 'appRedux/actions/organizationIntegrationTokens/types';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import {validationSchema} from 'components/Forms/OrganizationIntegrationTokenForm/validation';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface ComponentFormType {
    initialValues: OrganizationIntegrationTokenFormTypes;
    onSubmitClicked: (values: OrganizationIntegrationTokenFormTypes) => void;
}

const OrganizationIntegrationTokenForm: FC<ComponentFormType> = ({initialValues, onSubmitClicked}) => {
    const [t] = useTranslation();

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={() => validationSchema(t)}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<OrganizationIntegrationTokenFormTypes>) => {
                return (
                    <Form>
                        <Field
                            required
                            name="title"
                            placeholder={t('superadmin.organizations.organizationTokens.title')}
                            component={FormikTextInput}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default OrganizationIntegrationTokenForm;
