import React, {FC, ReactNode, useContext, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import RequiredSvgIcon from 'assets/icons/section/RequiredSvgIcon';
import CompletedSvgIcon from 'assets/icons/section/CompletedSvgIcon';
import PlusSvgIcon from 'assets/icons/section/PlusSvgIcon';
import MinusSvgIcon from 'assets/icons/section/MinusSvgIcon';

import {RootReducer} from 'appRedux/reducers';
import {ClientTranslationUpdatedType} from 'appRedux/actions/clientTranslation/types';

import {RouteContext} from 'contexts/route/context';

import EmptyArea from 'components/EmptyArea';
import DescriptionPopover from 'components/DescriptionPopover/DescriptionPopover';

import TranslationSelector from 'pages/agent/requesterPage/translation/TranslationSelector';
import SaveTranslationButton from 'pages/agent/requesterPage/translation/SaveTranslationButton';

import {theme, HIGHLIGHTED_SECTION_COLOR} from 'config/theme';

interface SectionWrapperType {
    id: number;
    title: string;
    description: string;
    completedItems: number;
    totalItems: number;
    isChecked: boolean;
    showTranslation: boolean;
    setShowTranslation: (value: boolean) => void;
    editTranslation: boolean;
    setEditTranslation: (value: boolean) => void;
    clientTranslations?: ClientTranslationUpdatedType;
    children: ReactNode;
}

const SectionWrapper: FC<SectionWrapperType> = ({
    id,
    showTranslation,
    editTranslation,
    setEditTranslation,
    setShowTranslation,
    completedItems,
    totalItems,
    clientTranslations,
    children,
    isChecked,
    title,
    description,
}) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(true);

    const sectionRef = useRef<HTMLDivElement>();

    const {formSection, setFormSection} = useContext(RouteContext);

    const [isHighlighted, setIsHighlighted] = useState<boolean>(false);

    const {
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const toggleExpanded = () => {
        setIsExpanded(previous => !previous);
    };

    useEffect(() => {
        if (sectionRef && sectionRef.current) {
            if (formSection && Number(formSection) === Number(id)) {
                sectionRef.current.scrollIntoView();
                setFormSection(null);
                setIsHighlighted(true);
            }
        }
    }, [formSection]);

    useEffect(() => {
        setIsExpanded(true);
    }, [id]);

    const {formPrefix, userName} = currentCase;

    return (
        <Box
            sx={{
                mb: 1,
                boxShadow: isHighlighted ? 24 : 'none',
                borderRadius: 2,
                backgroundColor: theme.palette.background.default,
                scrollMarginTop: '200px',
            }}
            ref={sectionRef}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                    width: '100%',
                    height: 60,
                    backgroundColor: isHighlighted ? HIGHLIGHTED_SECTION_COLOR : 'initial',
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    p: 2,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    onClick={toggleExpanded}
                >
                    {isChecked ? <CompletedSvgIcon /> : <RequiredSvgIcon />}
                    <Typography sx={{ml: 1.5, fontSize: 16, fontWeight: 600}}>{title}</Typography>
                    <Typography sx={{fontWeight: 300, ml: 1}}>{`(${completedItems}/${totalItems})`}</Typography>
                </Box>
                {description && description !== '' && <DescriptionPopover text={description} />}
                <EmptyArea onClick={toggleExpanded} />
                {editTranslation && (
                    <SaveTranslationButton
                        clientTranslations={clientTranslations}
                        setEditTranslation={setEditTranslation}
                    />
                )}
                <TranslationSelector
                    sectionId={id}
                    casePrefix={`${formPrefix}-${currentCase.id} | ${userName}`}
                    sectionTitle={title}
                    showTranslation={showTranslation}
                    setShowTranslation={setShowTranslation}
                    setEditTranslation={setEditTranslation}
                />
                <IconButton onClick={toggleExpanded}>{isExpanded ? <MinusSvgIcon /> : <PlusSvgIcon />}</IconButton>
            </Box>
            {isExpanded && <Box sx={{p: 2}}>{children}</Box>}
        </Box>
    );
};

export default SectionWrapper;
