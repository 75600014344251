import {FormItemBasic, FormTranslationItemTypes, FormLanguageTypes} from 'appRedux/actions/forms/types';
import {OrganizationItemBasic} from 'appRedux/actions/organization/types';
import {AgentItemBasic, RequesterCaseResponseTypes, RequesterItemBasic} from 'appRedux/actions/admin/types';
import {PublicKeyOrganizationTypes, CaseAccessRequestTypes} from 'appRedux/actions/crypto/types';
import {RuleTypes} from 'appRedux/actions/rules/types';
import {RequesterCaseActivitiesTypes} from 'appRedux/actions/requestCase/types';
import {RoleTypes} from 'appRedux/actions/roles/types';
import {InviteTypes} from 'appRedux/actions/invites/types';
import {BoardItemTypes} from 'appRedux/actions/workflow/types';

import {FormTranslationType} from 'helpers/translationsHelper';

/**
 * This function is necessary to provide portion of items for the current page
 *
 * @param items
 * @param currentPage
 * @param pageItemsNumber
 */
export const getItemsPortionForPage = (
    items:
        | FormItemBasic[]
        | FormTranslationType[]
        | FormTranslationItemTypes[]
        | RequesterItemBasic[]
        | AgentItemBasic[]
        | RequesterCaseResponseTypes[]
        | OrganizationItemBasic[]
        | RoleTypes[]
        | RuleTypes[]
        | PublicKeyOrganizationTypes[]
        | CaseAccessRequestTypes[]
        | RequesterCaseActivitiesTypes[]
        | InviteTypes[]
        | BoardItemTypes[],
    currentPage: number,
    pageItemsNumber: number,
) => {
    if (items.length > pageItemsNumber) {
        const fromItem = (currentPage - 1) * pageItemsNumber;
        const toItem = fromItem + pageItemsNumber;
        return items.slice(fromItem, toItem);
    }
    return items;
};

export const getInitialSelectedItems = (itemsPerPage: number, value: boolean): boolean[] => {
    const result: boolean[] = [];
    for (let i = 0; i < itemsPerPage; i++) {
        result.push(value);
    }
    return result;
};

export const truncate = (str: string, n: number) => {
    return str.length > n ? str.slice(0, n - 1) + '…' : str;
};

export const getFormLanguagesListRow = (organizationLanguageName: string, languages: FormLanguageTypes[]): string => {
    const result = [organizationLanguageName];
    languages.forEach(item => {
        if (item.name !== organizationLanguageName) {
            result.push(item.name);
        }
    });
    return result.join(', ');
};
