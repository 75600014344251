import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import {RootReducer} from 'appRedux/reducers';

import {isMaximalOrganizationUsersNumberReached} from 'pages/admin/updateOrganization/partials/OrganizationQuotas/helper';

const OrganizationUsersNumberQuotaAlert: FC = () => {
    const [t] = useTranslation();

    const {
        admin: {organizationList, organizationAdminList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const showAlert = myOrganization
        ? isMaximalOrganizationUsersNumberReached(myOrganization, organizationAdminList.length, false)
        : false;

    if (!showAlert) return null;
    return (
        <Alert severity={'error'}>
            <Typography sx={{fontWeight: 600, mb: 1}}>
                {t('superadmin.users.organizationQuotaOrgUsersNumberReached')}
            </Typography>
            <Typography>{t('messages.warning.additionalCostWillBeGenerated')}</Typography>
        </Alert>
    );
};

export default OrganizationUsersNumberQuotaAlert;
