import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {OrganizationPrivacyPolicyItemTypes} from 'appRedux/actions/organization/types';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import UpdateOrganizationPrivacyPolicyForm from 'components/Forms/OrganizationPrivacyPolicyForm/UpdateOrganizationPrivacyPolicyForm';

interface EditPrivacyPolicyButtonType {
    item: OrganizationPrivacyPolicyItemTypes;
    currentLanguage: string;
}

const EditPrivacyPolicyButton: FC<EditPrivacyPolicyButtonType> = ({item, currentLanguage}) => {
    const [t] = useTranslation();

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    return (
        <>
            <IconButton
                sx={{mr: 1}}
                title={t('superadmin.organizations.privacyPolicy.updatePrivacyPolicy')}
                onClick={() => setShowModal(true)}
            >
                <EditSvgIcon />
            </IconButton>
            <ModalWrapper
                isShowModal={showModal}
                title={t('superadmin.organizations.privacyPolicy.updatePrivacyPolicy')}
                toggleModal={toggleModal}
            >
                <UpdateOrganizationPrivacyPolicyForm
                    item={item}
                    currentLanguage={currentLanguage}
                    closeModal={() => setShowModal(false)}
                />
            </ModalWrapper>
        </>
    );
};

export default EditPrivacyPolicyButton;
