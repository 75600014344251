import React, {FC, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Field, Form, Formik, FormikProps} from 'formik';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import {RootReducer} from 'appRedux/reducers';
import {CREATE_BOARD_LIST} from 'appRedux/actions/workflow';
import {CreateBoardListRequestTypes, WorkflowItemTypes, WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';

import {iconTypes} from 'components/Forms/BoardForm/helper';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {initialValuesForList, validationSchemaForList} from 'components/Forms/BoardForm/validation';

import {IconImageType} from 'pages/agent/savedFilters/helper';

interface CreateListBoardFormType {
    closeModal: () => void;
}

const CreateListBoardForm: FC<CreateListBoardFormType> = ({closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const [workflowStatuses, setWorkflowStatuses] = useState<WorkflowStatusItemTypes[]>([]);

    const {
        workflow: {isLoading, organizationWorkflows},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const createBoard = useCallback(data => dispatch({type: CREATE_BOARD_LIST.REQUEST, payload: data}), [dispatch]);

    return (
        <Formik
            initialValues={initialValuesForList}
            validationSchema={validationSchemaForList}
            onSubmit={(values, {setErrors}) => {
                createBoard({
                    ...values,
                    setErrors,
                    callback: closeModal,
                });
            }}
        >
            {(formik: FormikProps<CreateBoardListRequestTypes>) => {
                return (
                    <Form>
                        <Box>
                            <Field
                                required
                                name="title"
                                placeholder={t('orguser.boards.parameters.title')}
                                component={FormikTextInput}
                            />
                        </Box>
                        <FormControl sx={{mt: 1}} required fullWidth>
                            <InputLabel>{t('orguser.boards.parameters.workflow')}</InputLabel>
                            <Select
                                label={t('orguser.boards.parameters.workflow')}
                                name={'workflow'}
                                value={formik.values.workflow}
                                onChange={e => {
                                    formik.handleChange(e);
                                    const selectedWorkflowId = Number(e.target.value);
                                    const selectedWorkflow = organizationWorkflows.find(
                                        (item: WorkflowItemTypes) => item.id === selectedWorkflowId,
                                    );
                                    if (selectedWorkflow) {
                                        setWorkflowStatuses(selectedWorkflow.workflowStatuses);
                                    }
                                }}
                                IconComponent={KeyboardArrowDownIcon}
                                fullWidth
                            >
                                {organizationWorkflows &&
                                    organizationWorkflows.map((item: WorkflowItemTypes, index: number) => {
                                        return (
                                            <MenuItem key={`workflow-${index}`} value={item.id}>
                                                <Typography sx={{fontWeight: 600}}>{item.title}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        {workflowStatuses && workflowStatuses.length > 0 && (
                            <FormControl sx={{mt: 2}} required fullWidth>
                                <InputLabel>{t('orguser.boards.parameters.status')}</InputLabel>
                                <Select
                                    label={t('orguser.boards.parameters.status')}
                                    name={'status'}
                                    value={formik.values.status}
                                    onChange={formik.handleChange}
                                    IconComponent={KeyboardArrowDownIcon}
                                    fullWidth
                                >
                                    {workflowStatuses &&
                                        workflowStatuses.map((item: WorkflowStatusItemTypes, index: number) => {
                                            return (
                                                <MenuItem key={`workflow-status-${index}`} value={item.id}>
                                                    <Typography sx={{fontWeight: 600}}>{item.title}</Typography>
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        )}
                        <FormControl sx={{mt: 2}} required fullWidth>
                            <InputLabel>{t('orguser.boards.parameters.iconType')}</InputLabel>
                            <Select
                                label={t('orguser.boards.parameters.iconType')}
                                name={'iconType'}
                                value={formik.values.iconType}
                                onChange={formik.handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                fullWidth
                            >
                                {iconTypes.map(({type, icon}: IconImageType) => {
                                    const Icon = icon;
                                    return (
                                        <MenuItem value={type} key={`icon-type-${type}`}>
                                            <IconButton>
                                                <Icon />
                                            </IconButton>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: 3,
                            }}
                        >
                            <AgentSaveButton isSubmit isLoading={isLoading} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CreateListBoardForm;
