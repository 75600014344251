import React, {FC, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface PermissionItemWrapperType {
    title: string;
    children: ReactNode;
}

const PermissionItemWrapper: FC<PermissionItemWrapperType> = ({title, children}) => {
    const [t] = useTranslation();
    return (
        <Box
            sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', mb: 1}}
        >
            <Typography sx={{fontWeight: 600, mr: 1}}>{`${t(title)}:`}</Typography>
            {children}
        </Box>
    );
};

export default PermissionItemWrapper;
