import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';

import Menu from '@mui/material/Menu';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';

import {RootReducer} from 'appRedux/reducers';
import {AgentSavedFilterTypes} from 'appRedux/actions/agentFilters/types';

import AgentSelectButton from 'components/AgentScreenComponents/_buttons/AgentSelectButton';

import {theme} from 'config/theme';

const SavedFiltersSelector: FC = () => {
    const [t] = useTranslation();
    const {uuid} = useParams();
    const {pathname} = useLocation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const {
        admin: {savedFilters},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const url = event.target.value;
        window.location.href = `${pathname}${url}`;
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    if (savedFilters.length === 0) {
        return null;
    }

    const savedFiltersByPage: AgentSavedFilterTypes[] = savedFilters
        ? savedFilters.filter(item => item.boardUuid === uuid)
        : [];

    if (savedFiltersByPage.length === 0) return null;

    return (
        <>
            <AgentSelectButton title={t('orguser.savedFilters.savedFilters')} onClick={handleClick} open={open} />
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        mt: 1,
                        pl: 2,
                        pr: 2,
                        pt: 1,
                        pb: 1,
                        width: 290,
                        borderRadius: 2,
                        overflow: 'visible',
                        boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                        backgroundColor: theme.palette.background.default,
                        '& ul': {
                            padding: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            >
                <RadioGroup onChange={handleChange}>
                    {savedFiltersByPage.map((option: AgentSavedFilterTypes, index: number) => {
                        return (
                            <FormControlLabel
                                key={`option-${index}`}
                                value={option.text}
                                control={<Radio />}
                                label={option.title}
                            />
                        );
                    })}
                </RadioGroup>
            </Menu>
        </>
    );
};

export default SavedFiltersSelector;
