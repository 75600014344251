import {ChatContactTypes} from 'appRedux/actions/requestChat/types';

export const isSearchValue = (contact: ChatContactTypes, searchValue: string): boolean => {
    const {firstName, lastName, caseLabel} = contact;
    const modifySearchValue = searchValue.toLowerCase();

    const firstNameLowerCase = firstName ? firstName.toLowerCase() : '';
    const lastNameLowerCase = lastName ? lastName.toLowerCase() : '';
    const caseLabelLowerCase = caseLabel ? caseLabel.toLowerCase() : '';

    const isSearchPresentedInContactInfo =
        firstNameLowerCase.includes(modifySearchValue) ||
        lastNameLowerCase.toLowerCase().includes(modifySearchValue) ||
        caseLabelLowerCase.toLowerCase().includes(modifySearchValue);

    const isContactInfoPresentedInSearchValue =
        modifySearchValue.includes(firstNameLowerCase) ||
        modifySearchValue.includes(lastNameLowerCase) ||
        modifySearchValue.includes(caseLabelLowerCase);

    return isSearchPresentedInContactInfo || isContactInfoPresentedInSearchValue;
};
