import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';

import Box from '@mui/material/Box';

import {GroupFormTypes, GroupHasRoleItemTypes} from 'appRedux/actions/groups/types';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationSchema} from 'components/Forms/GroupForm/validation';
import RolesSelectors from 'components/Forms/OrganizationAdminForm/partials/RolesSelectors';
import MdxEditorSmallField from 'components/MdxEditorField/MdxEditorSmallField';

interface GroupFormType {
    initialValues: GroupFormTypes;
    onSubmitClicked: (values: GroupFormTypes) => void;
    groupHasRoles: GroupHasRoleItemTypes[];
    setGroupHasRoles: (values: GroupHasRoleItemTypes[]) => void;
}

const GroupForm: FC<GroupFormType> = ({initialValues, onSubmitClicked, groupHasRoles, setGroupHasRoles}) => {
    const [t] = useTranslation();
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<GroupFormTypes>) => {
                return (
                    <Form>
                        <Field
                            required
                            name="title"
                            placeholder={t('orguser.groups.title')}
                            component={FormikTextInput}
                        />
                        <MdxEditorSmallField
                            name="description"
                            label={t('orguser.groups.description')}
                            value={initialValues.description ? initialValues.description : ''}
                            customButtons={[]}
                        />
                        {/*<RolesSelectors*/}
                        {/*    fieldName={'groupHasRoles'}*/}
                        {/*    userHasRoles={groupHasRoles}*/}
                        {/*    setUserHasRoles={setGroupHasRoles}*/}
                        {/*/>*/}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default GroupForm;
