import React, {FC, useCallback} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import {UPDATE_BOARD} from 'appRedux/actions/workflow';
import {BoardItemTypes, UpdateBoardRequestTypes} from 'appRedux/actions/workflow/types';

import {validationUpdateSchema} from 'components/Forms/BoardForm/validation';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {iconTypes} from 'components/Forms/BoardForm/helper';

import {IconImageType} from 'pages/agent/savedFilters/helper';

interface EditBoardFormType {
    item: BoardItemTypes;
}

const EditBoardForm: FC<EditBoardFormType> = ({item}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {uuid, title, iconType} = item;

    const updateBoard = useCallback(data => dispatch({type: UPDATE_BOARD.REQUEST, payload: data}), [dispatch]);

    return (
        <Formik
            initialValues={{
                title,
                iconType,
                uuid,
            }}
            validationSchema={validationUpdateSchema}
            onSubmit={(values, {setErrors}) => {
                updateBoard({
                    ...values,
                    setErrors,
                });
            }}
        >
            {(formik: FormikProps<UpdateBoardRequestTypes>) => {
                const isChanged = formik.values.title !== title || formik.values.iconType !== iconType;
                return (
                    <Form>
                        <FormControl sx={{mt: 2}} required fullWidth>
                            <Field
                                required
                                name="title"
                                placeholder={t('orguser.boards.parameters.title')}
                                component={FormikTextInput}
                            />
                        </FormControl>
                        <FormControl sx={{mt: 2}} required fullWidth>
                            <InputLabel>{t('orguser.boards.parameters.iconType')}</InputLabel>
                            <Select
                                label={t('orguser.boards.parameters.iconType')}
                                name={'iconType'}
                                value={formik.values.iconType}
                                onChange={formik.handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                fullWidth
                            >
                                {iconTypes.map(({type, icon}: IconImageType) => {
                                    const Icon = icon;
                                    return (
                                        <MenuItem value={type} key={`icon-type-${type}`}>
                                            <IconButton>
                                                <Icon />
                                            </IconButton>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton isSubmit={true} isDisabled={!isChanged} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default EditBoardForm;
