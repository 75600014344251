import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {UPDATE_PANEL} from 'appRedux/actions/analytics';
import {
    PanelFormTypes,
    PanelItemsTypes,
    PanelHasStatusItemTypes,
    PanelHasFieldItemTypes,
    PanelHasStatusTypes,
    PanelHasFieldTypes,
} from 'appRedux/actions/analytics/types';

import {AlertContext} from 'contexts/alert/context';

import PanelForm from 'components/Forms/Dashboard/PanelForm';

interface PanelUpdateFormType {
    item: PanelItemsTypes;
    closeModal: () => void;
}

const PanelUpdateForm: FC<PanelUpdateFormType> = ({item, closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [panelStatuses, setPanelStatuses] = useState<PanelHasStatusItemTypes[]>([]);
    const [panelFields, setPanelFields] = useState<PanelHasFieldItemTypes[]>([]);
    const [, setIsUpdated] = useState<boolean>(false);

    const updatePanel = useCallback(data => dispatch({type: UPDATE_PANEL.REQUEST, payload: data}), [dispatch]);

    const {
        workflow,
        version,
        title,
        description,
        type,
        datefilterType,
        displayType,
        uuid,
        isIncludeCurrentStatus,
        statuses,
        fields,
    } = item;

    const onSubmitClicked = (values: PanelFormTypes) => {
        updatePanel({
            ...values,
            version: values.version === 0 ? null : values.version,
            dashboardPanelHasStatuses: panelStatuses,
            dashboardPanelHasFields: panelFields,
            uuid,
            showAlert,
            callback: closeModal,
        });
    };

    useEffect(() => {
        statuses.forEach((status: PanelHasStatusTypes) => {
            panelStatuses.push({
                workflowStatus: status.id,
            });
        });
        fields.forEach((field: PanelHasFieldTypes) => {
            panelFields.push({
                field: field.id,
            });
        });
        setPanelStatuses(panelStatuses);
        setIsUpdated(previous => !previous);
    }, [uuid]);

    return (
        <PanelForm
            initialValues={{
                title,
                workflow,
                version,
                description,
                type,
                datefilterType,
                displayType,
                isIncludeCurrentStatus,
                dashboardPanelHasStatuses: panelStatuses,
                dashboardPanelHasFields: panelFields,
            }}
            statuses={panelStatuses}
            setStatuses={setPanelStatuses}
            panelFields={panelFields}
            setPanelFields={setPanelFields}
            onSubmitClicked={onSubmitClicked}
        />
    );
};

export default PanelUpdateForm;
