import get from 'lodash/get';

import {FormFieldTypes, FormSectionTypes, FieldOptionType} from 'appRedux/actions/forms/types';
import {
    RequesterCaseOptionTypes,
    RequesterCaseHasSlotTypes,
    RequesterCaseHasBudgetTypes,
} from 'appRedux/actions/requestCase/types';

import {FORM_FIELD_RESOURCE_FIELD} from 'pages/admin/updateForm/partials/FormStructure/helper';

export const getRelatedSectionsIds = (sections: FormSectionTypes[]) =>
    Array.from(
        sections.reduce((acc, section) => {
            section.fields?.forEach(field => {
                if (field.fieldOptions) {
                    field.fieldOptions.forEach(opt => {
                        opt.relatedSection && acc.add(Number(opt.relatedSection));
                    });
                }
            });

            return acc;
        }, new Set<number>()),
    );

interface SelectedOptionsType {
    allRelatedSections: number[];
    selectedRelatedSections: number[];
}

const getSelectedOptionsArray = (
    sections: FormSectionTypes[],
    options: RequesterCaseOptionTypes[],
): SelectedOptionsType => {
    const allRelatedSections: number[] = [];
    const selectedRelatedSections: number[] = [];
    sections.forEach((section: FormSectionTypes) => {
        const {fields} = section;
        fields.forEach((field: FormFieldTypes) => {
            const fieldOptions: FieldOptionType[] = get(field, 'fieldOptions', []);
            fieldOptions.forEach((option: FieldOptionType) => {
                const {relatedSection, id} = option;
                if (relatedSection) {
                    allRelatedSections.push(relatedSection);
                    const selectedFieldOptions = options.filter(item => Number(item.fieldId) === Number(field.id));
                    selectedFieldOptions.forEach(selectedOption => {
                        if (selectedOption.optionId === id) {
                            selectedRelatedSections.push(relatedSection);
                        }
                    });
                }
            });
        });
    });

    return {
        allRelatedSections,
        selectedRelatedSections,
    };
};

export const getSelectedRelatedSectionsIds = (
    sections: FormSectionTypes[],
    options: RequesterCaseOptionTypes[],
): number[] => {
    const ids: number[] = [];
    const selectedOptions = getSelectedOptionsArray(sections, options);
    selectedOptions.selectedRelatedSections.forEach(item => {
        ids.push(item);
    });
    return ids;
};

export const getNotSelectedRelatedSectionsIds = (
    sections: FormSectionTypes[],
    options: RequesterCaseOptionTypes[],
): number[] => {
    const ids: number[] = [];
    const selectedOptions = getSelectedOptionsArray(sections, options);
    selectedOptions.allRelatedSections.forEach(item => {
        if (!selectedOptions.selectedRelatedSections.includes(item)) {
            ids.push(item);
        }
    });
    return ids;
};

export interface UnavailableResourceFieldSlotsTypes {
    slotId: number;
    fieldId: number;
}

export const getUnavailableResourceFieldSlots = (
    sections: FormSectionTypes[],
    slots: RequesterCaseHasSlotTypes[],
): UnavailableResourceFieldSlotsTypes[] => {
    const unavailableSlots: UnavailableResourceFieldSlotsTypes[] = [];
    const requesterCaseSlots: number[] = [];

    slots.forEach((slot: RequesterCaseHasSlotTypes) => {
        requesterCaseSlots.push(slot.slotId);
    });

    sections.forEach((section: FormSectionTypes) => {
        const {fields} = section;
        fields.forEach((field: FormFieldTypes) => {
            const {type, resourceFieldSlot, hasAvailableSlots, id} = field;
            if (
                type === FORM_FIELD_RESOURCE_FIELD &&
                resourceFieldSlot &&
                !hasAvailableSlots &&
                !requesterCaseSlots.includes(resourceFieldSlot)
            ) {
                unavailableSlots.push({
                    fieldId: id,
                    slotId: resourceFieldSlot,
                });
            }
        });
    });

    return unavailableSlots;
};

export interface UnavailableResourceFieldBudgetsTypes {
    budgetId: number;
    fieldId: number;
}

export const getUnavailableResourceFieldBudgets = (
    sections: FormSectionTypes[],
    budgets: RequesterCaseHasBudgetTypes[],
): UnavailableResourceFieldBudgetsTypes[] => {
    const unavailableBudgets: UnavailableResourceFieldBudgetsTypes[] = [];
    const requesterCaseBudgets: number[] = [];

    budgets.forEach((budget: RequesterCaseHasBudgetTypes) => {
        requesterCaseBudgets.push(budget.formField);
    });

    sections.forEach((section: FormSectionTypes) => {
        const {fields} = section;
        fields.forEach((field: FormFieldTypes) => {
            const {resourceFieldBudget, resourceFieldBudgetInformation, id} = field;
            if (
                resourceFieldBudget &&
                resourceFieldBudgetInformation &&
                resourceFieldBudgetInformation.amount === 0 &&
                !requesterCaseBudgets.includes(id)
            ) {
                unavailableBudgets.push({
                    fieldId: id,
                    budgetId: Number(resourceFieldBudget),
                });
            }
        });
    });

    return unavailableBudgets;
};
