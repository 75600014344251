import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';

import Box from '@mui/material/Box';

import {OrganizationTemplateFormType} from 'appRedux/actions/organization/types';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import MdxEditorField from 'components/MdxEditorField/MdxEditorField';
import {validationSchema} from 'components/Forms/OrganizationTemplateForm/validation';
import {getCustomButtonsByType} from 'components/Forms/OrganizationTemplateForm/helper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface ComponentFormType {
    initialValues: OrganizationTemplateFormType;
    onSubmitClicked: (values: OrganizationTemplateFormType) => void;
}

const OrganizationTemplateForm: FC<ComponentFormType> = ({initialValues, onSubmitClicked}) => {
    const [t] = useTranslation();

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={() => validationSchema(t)}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<OrganizationTemplateFormType>) => {
                return (
                    <Form>
                        <Field
                            required
                            name="subject"
                            placeholder={t('orguser.mailer.subject')}
                            component={FormikTextInput}
                        />
                        <MdxEditorField
                            name="text"
                            label={t('orguser.mailer.text')}
                            value={initialValues.text ? initialValues.text : ''}
                            customButtons={getCustomButtonsByType(initialValues.type)}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default OrganizationTemplateForm;
